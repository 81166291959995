var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', [_c('div', {
    staticClass: "interactive-field-constructor"
  }, [_c('div', {
    staticClass: "interactive-field-constructor__scalable-wrapper"
  }, [_c('div', {
    staticClass: "interactive-field-constructor__main-wrapper"
  }, [_c('div', {
    staticClass: "interactive-field-constructor__general-field-view"
  }, [_c('div', {
    staticClass: "interactive-field-constructor__field-number interactive-field-constructor__field-number--desktop"
  }, [_vm._v(" " + _vm._s(_vm.fieldNumber) + " ")]), _c('div', {
    staticClass: "interactive-field-constructor__field-number interactive-field-constructor__field-number--mobile"
  }, [_vm._v(" Question " + _vm._s(_vm.fieldNumber) + " ")]), _c('div', {
    staticClass: "interactive-field-constructor__field-view-wrapper"
  }, [_c('div', {
    staticClass: "interactive-field-constructor__field-view"
  }, [_vm._t("field-view")], 2), _c('div', {
    staticClass: "interactive-field-constructor__action-wrapper"
  }, [_c('InteractiveFormActionButtonBlock', {
    attrs: {
      "with-press-enter": _vm.fieldTypeConst.WITH_PRESS_ENTER
    },
    on: {
      "click": _vm.actionButtonClicked
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._t("button-content")];
      },
      proxy: true
    }], null, true)
  })], 1)])])])]), _vm._t("extension")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }