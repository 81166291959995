var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', _vm._g({
    directives: [{
      name: "mousedown-outside",
      rawName: "v-mousedown-outside",
      value: _vm.blur,
      expression: "blur"
    }, {
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.blur,
      expression: "blur"
    }],
    ref: "contenteditable",
    class: ['simple-contenteditable-input', _vm.multiline && 'simple-contenteditable-input--multiline', !_vm.overflowWrap && 'simple-contenteditable-input--nowrap', _vm.readonly && 'simple-contenteditable-input--readonly'],
    attrs: {
      "contenteditable": !_vm.readonly,
      "data-placeholder": _vm.placeholder
    },
    on: {
      "paste": _vm.processPaste,
      "keydown": _vm.handleKeydown,
      "focus": function ($event) {
        return _vm.$emit('update:focused', true);
      },
      "blur": function ($event) {
        return _vm.$emit('update:focused', false);
      }
    }
  }, Object.assign({}, _vm.$listeners, {
    input: _vm.input
  })));
}
var staticRenderFns = []

export { render, staticRenderFns }