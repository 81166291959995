var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "#03A9F4"
    }
  }), _c('path', {
    attrs: {
      "d": "M32.375 15.0356C31.5941 15.3781 30.7619 15.6052 29.8944 15.7154C30.7869 15.1826 31.4681 14.3452 31.7883 13.3359C30.9562 13.832 30.0374 14.1824 29.0583 14.378C28.2682 13.5367 27.1421 13.0156 25.9136 13.0156C23.5301 13.0156 21.6112 14.9503 21.6112 17.3219C21.6112 17.6632 21.6401 17.9913 21.7109 18.3037C18.1318 18.1291 14.9647 16.4137 12.8371 13.8005C12.4657 14.4449 12.2478 15.1826 12.2478 15.9766C12.2478 17.4676 13.0156 18.7893 14.1601 19.5545C13.4684 19.5414 12.7899 19.3406 12.215 19.0243C12.215 19.0374 12.215 19.0544 12.215 19.0715C12.215 21.1636 13.7073 22.9014 15.6642 23.3017C15.3138 23.3975 14.9319 23.4434 14.5355 23.4434C14.2599 23.4434 13.9816 23.4277 13.7204 23.3699C14.2782 25.0749 15.8611 26.3283 17.7433 26.369C16.2785 27.5148 14.4187 28.2052 12.4053 28.2052C12.0523 28.2052 11.7136 28.1894 11.375 28.1461C13.2821 29.3759 15.5422 30.0781 17.9795 30.0781C25.9017 30.0781 30.233 23.5156 30.233 17.8273C30.233 17.6369 30.2264 17.4532 30.2173 17.2708C31.0717 16.6644 31.7896 15.9071 32.375 15.0356Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }