<template>
  <div class="smart-paragraph-field-view" ref="fieldView">
    <div
        v-if="popupModel"
        class="smart-paragraph-field-view__add-variable-popup generic-field__escape-form-font"
        :style="popupStyle"
    >
      <div class="smart-paragraph-field-view__add-variable-popup__button" @mousedown="addVariable">
        <div class="smart-paragraph-field-view__add-variable-popup__plus-icon">
          <PlusIcon/>
        </div>
        <span>Add input</span>
      </div>

      <f-hover-tooltip right text="Add variable" v-slot="{ on }">
        <div class="smart-paragraph-field-view__add-variable-popup__info" v-on="on">
          <InfoIcon/>
        </div>
      </f-hover-tooltip>
    </div>
    <span
        class="smart-paragraph-field-view__content"
        :class="fieldObj.content.length || 'smart-paragraph-field-view__content--placeholder'"
        data-placeholder="Add paragraph text"
        contenteditable="true"
        ref="content"
        @mousedown="handlePopupPosition"
        @mousemove="handlePopupPosition"
        @keydown="handleKeyDown"
        @paste="processPaste"
        @input="input"
        @blur="blur"
    ></span>
  </div>
</template>

<script>
import {
  editable_field_view_mixin,
  useEditableFieldViewExtension
} from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'
import {onMounted, reactive, ref} from '@vue/composition-api'
import Vue from 'vue'

export default {
  name: "Editable__SmartParagraph",
  components: {
    PlusIcon: () => import('vue-feather-icons/icons/PlusIcon'),
    InfoIcon: () => import('vue-feather-icons/icons/InfoIcon'),
  },
  mixins: [editable_field_view_mixin],
  setup(props, context) {
    const {refs} = context
    const extension = useEditableFieldViewExtension(props, context)

    /** POPUP **/
    const popupModel = ref(false)
    const popupStyle = reactive({
      transform: '',
    })
    const showAddVariablePopup = () => popupModel.value = true
    const hideAddVariablePopup = () => popupModel.value = false
    const popupModule = {popupModel, popupStyle, hideAddVariablePopup}

    const contentValue = ref('')

    const content = 'ref([{text: adsdasdadadsdasd}, {text: fdsafadsfasdfsdf}])'

    onMounted(() => {
      props.fieldObj.content.forEach(element => {
        if (element.type === 'text')
          refs.content.appendChild(document.createTextNode(element.value))
        else if (element.type === 'break')
          refs.content.appendChild(document.createElement('br'))
        else if (element.type === 'variable')
          refs.content.appendChild(new Vue({
            ...require('./Components/EditableVariableContainer').default,
            propsData: {
              id: element.variable_id,
              variableName: props.fieldObj.properties.variables_list[element.variable_id]
            },
            input,
            removeVariable
          }).$mount().$el)
      })
      if (refs.content?.lastChild?.tagName !== 'BR')
        refs.content.appendChild(document.createElement('br'))
      input()
    })


    const currentCaretPos = ref()
    const currentCaretNode = ref()

    const processPaste = e => {
      e.preventDefault()
      let data = (e.originalEvent || e).clipboardData.getData('text/plain')
      if (data)
        document.execCommand("insertText", false, data.replace('\n', '<br/>'))
      handlePopupPosition()
    }

    const handleKeyDown = e => {
      if (!e.ctrlKey) return handlePopupPosition()
      let code = e.which || e.keyCode

      let hotkeysToPrevent = {
        b: 66, // bold
        i: 73, // italic
        u: 85 // underline
      }

      if (Object.values(hotkeysToPrevent).includes(code)) {
        e.preventDefault()
        e.stopPropagation()
      }

      handlePopupPosition()
    }

    const handlePopupPosition = () => {
      setTimeout(() => {
        const selection = window.getSelection()
        if (selection.type === 'Caret') {
          showAddVariablePopup()
          let bounding = selection.getRangeAt(0).getBoundingClientRect()
          const boundingWrapper = refs.fieldView.getBoundingClientRect()
          if (selection.anchorNode.nodeType === 1 && selection.anchorNode.getAttribute('data-empty-text-block')) {
            bounding = selection.anchorNode.getBoundingClientRect();
          } else if (selection.anchorNode === refs.content) {
            let range = document.createRange()
            range.setStart(refs.content, selection.anchorOffset)
            range.setEnd(refs.content, selection.anchorOffset)
            let tempNode = document.createElement('span')
            range.insertNode(tempNode)
            bounding = tempNode.getBoundingClientRect()
            tempNode.remove()
          } else if (selection.anchorNode.parentElement !== refs.content)
            return hideAddVariablePopup()
          currentCaretNode.value = selection.anchorNode
          currentCaretPos.value = selection.anchorOffset
          popupStyle.transform = `translate(calc(-50% + ${bounding.left - boundingWrapper.left}px), ${bounding.top - boundingWrapper.top}px)`
        } else
          hideAddVariablePopup()
      }, 10)
    }

    const addVariable = () => {
      hideAddVariablePopup()
      let range = document.createRange()
      range.setStart(currentCaretNode.value, currentCaretPos.value)
      range.setEnd(currentCaretNode.value, currentCaretPos.value)
      let variableContainer = new Vue({
        ...require('./Components/EditableVariableContainer').default,
        propsData: {
          newCreated: true
        },
        input,
        removeVariable
      }).$mount().$el

      range.insertNode(variableContainer)

      input()
    }
    const removeVariable = id => {
      document.querySelectorAll(`[data-variable-id="${id}"]`)[0].remove()
      input()
    }

    const input = () => {
      props.fieldObj.properties.variables_list = {}
      refs.content.normalize()
      let contentList = Array.from(refs.content.childNodes)
          .reduce((accumulator, node) => {
            if (node.nodeName === '#text') {
              accumulator.push({type: 'text', value: node.wholeText})
            } else if (node.getAttribute('data-empty-text-block')) {
              accumulator.push({type: 'text', value: node.innerText})
              refs.content.replaceChild(document.createTextNode(node.innerText), node)
            } else if (node.nodeName === 'BR') {
              accumulator.push({type: 'break'})
            } else if (node.getAttribute('data-smart-paragraph-variable')) {
              if (!node.previousSibling
                  || (node.previousSibling?.nodeType === 3 && node.previousSibling.wholeText === "")
                  || (node.previousSibling?.nodeType !== 3
                      && (node.previousSibling.getAttribute('data-smart-paragraph-variable')
                          || node.previousSibling.tagName === 'BR'))
              ) {
                let a = document.createElement('div')
                a.setAttribute('data-empty-text-block', true)
                a.classList.add('smart-paragraph-field-view__empty-text-block')
                refs.content.insertBefore(a, node)
              }
              accumulator.push({type: 'variable', variable_id: node.getAttribute('data-variable-id')})
              props.fieldObj.properties.variables_list[node.getAttribute('data-variable-id')] = node.firstChild.innerText
            }
            return accumulator
          }, [])
      if (contentList.length === 0 || (contentList.length === 1 && contentList[0].type === 'break'))
        contentList = []
      props.fieldObj.content = contentList
    }

    const blur = e => {
      if (!e.relatedTarget?.parentElement?.getAttribute('data-smart-paragraph-variable')) {
        window.getSelection().removeAllRanges()
        hideAddVariablePopup()
      }
    }

    return {
      ...extension,
      ...popupModule,
      contentValue,
      addVariable,
      content,
      processPaste,
      handleKeyDown,
      handlePopupPosition,
      input,
      blur
    }
  }
}
</script>