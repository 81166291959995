<template>
    <div class="static-form-editor-navigation">
        <v-btn
                @click="prevPage"
                :disabled="isFirstPage"
                class="mr-1"
                depressed
                text
                icon
        >
            <ChevronLeftIcon color="bluish_gray_500--text" size="20"/>
        </v-btn>
        <FileIcon class="mr-2 gray_400--text" size="18"/>
        <span>
            {{currentPageNumber}}
        </span>
        <span class="mx-1 gray_400--text">
            /
        </span>
        <span class="gray_400--text">
            {{pagesCount}}
        </span>
        <v-btn
                @click="nextPage"
                :disabled="isLastPage"
                class="ml-1"
                depressed
                text
                icon
        >
            <ChevronRightIcon size="20"/>
        </v-btn>
    </div>
</template>

<script>
    import {ChevronLeftIcon, ChevronRightIcon, FileIcon} from 'vue-feather-icons'
    import {editable_form_navigation_mixin} from "@/components/FormEditor/FormNavigation/editable_form_navigation_mixin"

    export default {
        name: "StaticFormNavigation",
        components: {
            ChevronLeftIcon, ChevronRightIcon, FileIcon
        },
        mixins: [editable_form_navigation_mixin]
    }
</script>

<style lang="scss" scoped>
    .static-form-editor-navigation {
        z-index: 10;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 6px 7px;
        border-radius: 1000px;
        box-shadow: 0px 8px 18px -6px rgb(24 39 75 / 12%), 0px 2px 4px rgb(24 39 75 / 8%);
    }
</style>