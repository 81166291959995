import { Analytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { GenericOptionsConditionalLogic } from '@/components/Fields/FieldConstClasses/conditional_logic'
import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import { GenericInteractiveTransparentBlurryCardFSP } from '@/components/Fields/GenericFieldViews/GenericInteractiveTransparentBlurryCardFSP'
import FSP from '@/constants/fsp_constants'

export class SelectionFieldTypeConst extends FieldTypeConst {
  constructor(options) {
    const { CONDITIONAL_LOGIC_MULTIPLE, ...restOptions } = options
    super({
      ...restOptions,
      WITH_PRESS_ENTER: true,
      FIELD_OBJ_EXTENSION: () => ({
        content: {
          options: ['Choice'],
        },
      }),
      SUBMISSION_TEMPLATE: () => ({
        value: [],
      }),

      IS_SUBMISSION_EMPTY: submissionObj => !submissionObj.value?.length,

      SUBMIT_VALIDATION: new FieldSubmitValidation({
        RULES: fieldObj => ({
          value: FieldSubmitValidation.REQUIRED(fieldObj),
        }),
      }),

      FSP: [
        GenericInteractiveTransparentBlurryCardFSP,
        new FieldFSPDefinition({
          FSP_FIELD_TYPE: 'Selection',
          FIELD_VIEW_CLASS: 'selection-field-view',
          GENERIC_STATES_CONNECTORS: {
            'option-hovered': {
              internal: '.selection-field-view__selection-field-option:hover',
            },
            'option-active': {
              internal:
                '.selection-field-view__selection-field-option.selection-field-view__selection-field-option--active',
            },
          },
          VARIABLES: [
            {
              selector: ['layout'],
              properties: {
                gap: {
                  'default--interactive': {
                    fn: '8px',
                  },
                },
              },
            },

            /** STATIC FORM **/
            {
              selector: ['option-wrapper'],
              properties: {
                'margin-bottom': {
                  'default--static': {
                    fn: '18px',
                  },
                },
              },
            },
            {
              selector: ['.f-checkbox .f-checkbox__input-control'],
              external: true,
              properties: {
                'background-color': {
                  'option-active--static': {
                    fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply(),
                  },
                },
              },
            },

            /** INTERACTIVE FORM **/
            {
              selector: ['selection-field-option__wrapper'],
              properties: {
                padding: {
                  default: {
                    fn: '6px 12px',
                  },
                },
                'column-gap': {
                  default: {
                    fn: '12px',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__anchor-letter-wrapper'],
              properties: {
                background: {
                  default: {
                    fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_COLOR.apply(),
                  },
                  'option-active': {
                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                  },
                },
                'border-radius': {
                  default: {
                    fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({ px: true }),
                  },
                },
                'box-shadow': {
                  default: {
                    fn: `0px 0px 0px 1px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(
                      { opacity: 0.5 }
                    )} inset`,
                  },
                  'option-active': {
                    fn: `0px 0px 0px 1px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()} inset`,
                  },
                },
                width: {
                  default: {
                    fn: '20px',
                  },
                },
                height: {
                  default: {
                    fn: '20px',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__anchor-letter'],
              properties: {
                color: {
                  default: {
                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                  },
                  'option-active': {
                    fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_COLOR.apply(),
                  },
                },
                'font-weight': {
                  default: {
                    fn: 700,
                  },
                  'option-active': {
                    fn: 700,
                  },
                },
                width: {
                  default: {
                    fn: '100%',
                  },
                },
                'text-align': {
                  default: {
                    fn: 'center',
                  },
                },
                'font-size': {
                  default: {
                    fn: '12px',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__label'],
              properties: {
                color: {
                  'default--interactive': {
                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                  },
                },
                'font-size': {
                  default: {
                    fn: '16px',
                  },
                },
                'line-height': {
                  default: {
                    fn: '24px',
                  },
                },
                'font-weight': {
                  default: {
                    fn: 400,
                  },
                },
                cursor: {
                  'default--submittable': {
                    fn: 'pointer',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__label:before'],
              properties: {
                cursor: {
                  'default--submittable': {
                    fn: 'pointer',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__option-append-wrapper'],
              properties: {
                width: {
                  default: {
                    fn: '20px',
                  },
                },
                height: {
                  default: {
                    fn: '20px',
                  },
                },
              },
            },
            {
              selector: ['selection-field-option__option-append-wrapper svg'],
              properties: {
                width: {
                  default: {
                    fn: '20px',
                  },
                },
                height: {
                  default: {
                    fn: '20px',
                  },
                },
                color: {
                  'default--interactive': {
                    fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply(
                      {
                        asColors: {
                          true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                            asList: true,
                          }),
                          false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                            asList: true,
                          }),
                        },
                      }
                    ),
                  },
                },
              },
            },
          ],
        }),
      ],
      CONDITIONAL_LOGIC: new GenericOptionsConditionalLogic({
        PARAMS: {
          MULTIPLE: CONDITIONAL_LOGIC_MULTIPLE,
          VALUE_FORMATTER: fieldObj =>
            fieldObj.content.options.map((option, index) => ({
              index,
              text: option || '',
            })),
        },
      }),
      ANALYTICS: new Analytics({
        SORT: ({ value: a }, { value: b }) => a - b,
        FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
          return submissionObj.value
            .map(option => fieldObj.content.options[option])
            .join(', ')
        },
        FULL_SINGLE_ANSWER_VIEW: () =>
          import(
            '@/components/Fields/AnalyticsField/GenericSelectionComponents/GenericSelectionFullSingleAnswerFieldView'
          ),
        SHORT_SINGLE_ANSWER_VIEW: () =>
          import(
            '@/components/Fields/AnalyticsField/GenericSelectionComponents/GenericSelectionShortSingleAnswerFieldView'
          ),
        SUMMARY_ANSWER_VIEW: () =>
          import(
            '@/components/Fields/AnalyticsField/GenericSelectionComponents/GenericSelectionSummaryFieldView'
          ),
      }),
      BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
        // new CustomFieldPropertyConst({
        //     key: 'other_option',
        //     name: '"Other" option',
        //     tooltip: 'Let others add their own response'
        // }),
        new CustomFieldPropertyConst({
          key: 'randomize',
          name: 'Randomize answers',
        }),
        // new CustomFieldPropertyConst({
        //     key: 'ready_answers',
        //     name: 'Ready Answers',
        //     enabled_by_default: true,
        //     input_group: new FieldPropertyInputGroupWithSimpleConstructor(
        //         new FieldPropertySelectInput({
        //             items: [
        //                 {key: 'customer_satisfaction', readable_name: 'Customer Satisfaction'},
        //                 {key: 'product_quality', readable_name: 'Product Quality'}
        //             ]
        //         })
        //     )
        // })
      ]),
    })
  }
}
