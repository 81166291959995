/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getForm = /* GraphQL */ `
  query GetForm($id: String!) {
    getForm(id: $id) {
      id
      name
      path
      previewImage
      publishedVersionID
      ownerID
      createdAt
      updatedAt
      FSPThemeID
      webhook_url
      zapier_status
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms {
    listForms {
      id
      name
      path
      previewImage
      publishedVersionID
      ownerID
      createdAt
      updatedAt
      FSPThemeID
      webhook_url
      zapier_status
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions($formID: String!, $limit: Int!, $offset: Int!) {
    listSubmissions(formID: $formID, limit: $limit, offset: $offset) {
      items {
        id
        formID
        publishedVersionID
        rawData
        formOwnerID
        createdAt
      }
      count
    }
  }
`;
export const getFormMetrics = /* GraphQL */ `
  query GetFormMetrics($formID: String!) {
    getFormMetrics(formID: $formID) {
      totalResponses
      allViews
      completionRate
      averageTime
      dropOffs
    }
  }
`;
export const getFSPTheme = /* GraphQL */ `
  query GetFSPTheme($id: String!) {
    getFSPTheme(id: $id) {
      id
      name
      ownerID
      rawData
      relatedForms {
        id
        name
        path
        previewImage
        publishedVersionID
        ownerID
        createdAt
        updatedAt
        FSPThemeID
      }
    }
  }
`;
export const listFSPThemes = /* GraphQL */ `
  query ListFSPThemes($private: Boolean!) {
    listFSPThemes(private: $private) {
      id
      name
      ownerID
      rawData
      relatedForms {
        id
        name
        path
        previewImage
        publishedVersionID
        ownerID
        createdAt
        updatedAt
        FSPThemeID
      }
    }
  }
`;
export const getStripeProduct = /* GraphQL */ `
  query GetStripeProduct($id: String!) {
    getStripeProduct(id: $id) {
      id
      name
      features
      prices {
        id
        productID
        name
        amount
        currency
        recurring_interval
      }
    }
  }
`;
export const listStripeProducts = /* GraphQL */ `
  query ListStripeProducts {
    listStripeProducts {
      id
      name
      features
      prices {
        id
        productID
        name
        amount
        currency
        recurring_interval
      }
    }
  }
`;
export const listStripePrices = /* GraphQL */ `
  query ListStripePrices {
    listStripePrices {
      id
      productID
      name
      amount
      currency
      recurring_interval
    }
  }
`;
export const listFormTemplates = /* GraphQL */ `
  query ListFormTemplates {
    listFormTemplates {
      id
      title
      subtitle
      thumbnailUrl
      category
      FSPThemeID
    }
  }
`;
export const getUserPlanLimitStats = /* GraphQL */ `
  query GetUserPlanLimitStats(
    $userID: String!
    $periodStart: Int!
    $periodEnd: Int!
  ) {
    getUserPlanLimitStats(
      userID: $userID
      periodStart: $periodStart
      periodEnd: $periodEnd
    ) {
      submissionsCount
    }
  }
`;
