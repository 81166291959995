import { API } from 'aws-amplify'
import _ from 'lodash'

import constants from '@/constants/constants'
import form_submit_store from '@/store/form_submit/form_submit_store'
import form_template_data_store from '@/store/form_templates/modules/form_template_data_store'
import FSPThemesActions from '@/utils/actions/fsp_themes_actions'
import OwnersFormActions from '@/utils/actions/owners_form_actions'
import { executeGraphQLQueryList } from '@/utils/graphql_executors'
import Utils, { generateShortId } from '@/utils/misc'
import { ArrayAndObjectGettersExtensions } from '@/xstore/utils/array_and_object_getters'
import {
  ModuleableExtensions,
  ModuleableUtils,
} from '@/xstore/utils/moduleable'
import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { StoreableExtensions, StoreableUtils } from '@/xstore/utils/storeable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'
import { listFormTemplates } from '@formsly/shared/api/graphql'

const FORM_TEMPLATES_CATEGORIES = Object.fromEntries(
  Object.entries({
    all: {
      prettyName: 'All templates',
    },
    orders: {
      prettyName: 'Orders',
    },
    feedback: {
      prettyName: 'Feedback',
    },
    job_application: {
      prettyName: 'Job Application',
    },
  }).map(([id, obj]) => [id, { ...obj, id }])
)

const FORM_TEMPLATES_CATEGORIES_ORDER = [
  FORM_TEMPLATES_CATEGORIES.all,
  FORM_TEMPLATES_CATEGORIES.orders,
  FORM_TEMPLATES_CATEGORIES.feedback,
  FORM_TEMPLATES_CATEGORIES.job_application,
]

export default ({ useExtension }) => {
  {
    const state = {
      form_templates: undefined,
    }

    useExtension(SMAGableExtensions.SMGable, { state })
    useExtension(ToggleableExtensions.Loadable, { state })
    useExtension(ArrayAndObjectGettersExtensions.ById, { state })
  }

  {
    const state = {
      fsp_themes: {},
    }

    useExtension(SMAGableExtensions.SMGable, { state })
  }

  useExtension(StoreableExtensions.Default)
  useExtension(PathableExtensions.Default)
  useExtension(ModuleableExtensions.Default)

  return {
    actions: {
      tryToFetchFormTemplates: ({ commit, getters, toggleIsLoading }) => {
        if (!getters.getFormTemplates)
          return toggleIsLoading(
            executeGraphQLQueryList(listFormTemplates).then(response =>
              commit('SET_FORM_TEMPLATES', response)
            ),
            'form_templates'
          )
      },

      regTemplateDataModuleIfNotExist: async (
        { getters, dispatch },
        { templateId, parallelAction = () => null }
      ) => {
        if (
          !(templateId in getters[ModuleableUtils.moduleGetterName]._children)
        ) {
          getters[StoreableUtils.storeGetterName].registerModule(
            [...getters[PathableUtils.pathGetterName], templateId],
            form_template_data_store
          )
          await Promise.all([
            dispatch(`${templateId}/fetchTemplateData`),
            Promise.resolve(parallelAction()),
          ])
        }
      },

      showTemplatePreview: async (ctx, { templateId, isStatic }) => {
        const { getters, dispatch, commit } = ctx
        const formTemplateObj = getters.getFormTemplatesAsObject[templateId]

        if (
          !('preview' in getters[ModuleableUtils.moduleGetterName]._children)
        ) {
          getters[StoreableUtils.storeGetterName].registerModule(
            [...getters[PathableUtils.pathGetterName], 'preview'],
            form_submit_store
          )
        }

        await dispatch('regTemplateDataModuleIfNotExist', {
          templateId,
          parallelAction: () => {
            if (!(formTemplateObj.FSPThemeID in getters.getFspThemes))
              return FSPThemesActions.getTheme(formTemplateObj.FSPThemeID).then(
                FSPThemeObj => {
                  commit('SET_FSP_THEMES', {
                    ...getters.getFspThemes,
                    [FSPThemeObj.id]: FSPThemeObj,
                  })
                }
              )
          },
        })

        return dispatch('preview/pushFormSource', {
          formJSON:
            ctx.getters[
              formTemplateObj.id +
                (isStatic
                  ? '/getStaticFormSourceJson'
                  : '/getInteractiveFormSourceJson')
            ],
          formData: undefined,
          themeObj: getters.getFspThemes[formTemplateObj.FSPThemeID],
          withSubmit: false,
        })
      },
      closeTemplatePreview: ({ getters }) => {
        getters[StoreableUtils.storeGetterName].unregisterModule([
          ...getters[PathableUtils.pathGetterName],
          'preview',
        ])
      },

      afterSyncFormCreation: ({ dispatch }, createFormResponse) => {
        dispatch('edit/FSP_themes/fetchAllThemes', null, { root: true })
        dispatch(
          'edit/FSP_themes/fetchCurrentFormTheme',
          createFormResponse.formData,
          { root: true }
        )
        dispatch('edit/simulateFormFetching', createFormResponse, {
          root: true,
        })

        return {
          formID: createFormResponse.formData.id,
        }
      },
      createBlankForm: async ({ dispatch }, { isStatic }) => {
        const createFormResponse = await OwnersFormActions.syncCreateForm({
          formType: isStatic
            ? constants.FORM_TYPES.STATIC_FORM.FORM_TYPE
            : constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE,
        })

        return await dispatch('afterSyncFormCreation', createFormResponse)
      },
      createAiForm: async ({ dispatch }, { isStatic, formPrompt }) => {
        const {
          fieldObjects: fields,
          formTitle,
          formSubtitle,
        } = await API.post('core', '/ai-prompt', {
          body: {
            formPrompt,
          },
        })

        const formType = isStatic
          ? constants.FORM_TYPES.STATIC_FORM.FORM_TYPE
          : constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE

        const formId = generateShortId()
        const formJson = constants.FORM_FROM_TYPE[formType].TEMPLATE(formId)

        fields.forEach(field => {
          const fieldConst = constants.FIELD_FROM_TYPE[field.field_type]
          if (!fieldConst) {
            return
          }

          const fieldId = Utils.generateUUID()

          const fieldObj = fieldConst.generateFieldObj()

          if (field.title) {
            fieldObj.title = field.title
          }

          if (field.description) {
            fieldObj.description = field.description
          }

          if (field.content) {
            fieldObj.content = field.content
          }

          if (
            field.required &&
            typeof fieldObj?.properties?.required === 'boolean'
          ) {
            fieldObj.properties.required = field.required
          }

          formJson.fields[fieldId] = fieldObj

          if (isStatic) {
            formJson.all_pages.main_page.rows.push({
              fields: [
                {
                  field_id: fieldId,
                  width: 2,
                },
              ],
            })
          } else {
            const pageId = Utils.generateUUID()
            formJson.main_pages.push({ page_id: pageId })
            formJson.all_pages[pageId] = {
              type: 'MAIN_PAGE',
              field_id: fieldId,
            }
          }
        })

        if (isStatic) {
          formJson.cover_text.content = `<p>${formTitle}</p>`
        } else {
          formJson.all_pages.home_page.title = formTitle
          formJson.all_pages.home_page.subtitle = formSubtitle
        }

        const createFormResponse = await OwnersFormActions.syncCreateForm({
          formType,
          formJson,
        })

        return await dispatch('afterSyncFormCreation', createFormResponse)
      },
      createFormFromTemplate: async (ctx, { templateId, isStatic }) => {
        const { dispatch, getters } = ctx

        await dispatch('regTemplateDataModuleIfNotExist', { templateId })

        const formJson = _.cloneDeep(
          ctx.getters[
            templateId +
              (isStatic
                ? '/getStaticFormSourceJson'
                : '/getInteractiveFormSourceJson')
          ]
        )

        const createFormResponse = await OwnersFormActions.syncCreateForm({
          formJson,
          formID: formJson.id,
          FSPThemeID: getters.getFormTemplatesAsObject[templateId].FSPThemeID,
        })

        return await dispatch('afterSyncFormCreation', createFormResponse)
      },
    },
    getters: {
      getFormTemplatesGroupedByCategories: ({ getters }) => {
        if (getters.getFormTemplatesAsArray)
          return getters.getFormTemplatesAsArray.reduce(
            (accumulator, formTemplate) => {
              accumulator[formTemplate.category] = [
                ...(accumulator[formTemplate.category] || []),
                formTemplate,
              ]
              accumulator.all = [...(accumulator.all || []), formTemplate]
              return accumulator
            },
            {}
          )
        return {}
      },
      getFormTemplatesCategoriesOrder: () => FORM_TEMPLATES_CATEGORIES_ORDER,
    },
  }
}
