var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "checkout-customer-details-setup-page"
  }, [_c('div', {
    staticClass: "checkout-page-heading checkout-page-heading--offset-lg"
  }, [_c('div', {
    staticClass: "checkout-page-heading__title"
  }, [_c('span', [_vm._v("Complete your purchase")])]), _c('div', {
    staticClass: "checkout-page-heading__subtitle"
  }, [_c('span', [_vm._v("Powered by Stripe")])])]), _c('div', {
    staticClass: "checkout-customer-details-setup-page__layout"
  }, [_c('div', {
    staticClass: "checkout-customer-details-setup-page__details-card-wrapper"
  }, [_c('div', {
    staticClass: "checkout-customer-details-setup-details-card"
  }, [_c('div', {
    staticClass: "checkout-customer-details-setup-details-card__billing-address-wrapper"
  }, [_c('div', {
    staticClass: "checkout-customer-details-setup-details-card__title"
  }, [_c('span', [_vm._v("1. Billing details")])]), _c('div', {
    staticClass: "checkout-customer-details-setup-details-card__content-wrapper"
  }, [_c('CheckoutBillingAddressSetup')], 1)]), _c('div', {
    staticClass: "checkout-customer-details-setup-details-card__divider-wrapper"
  }, [_c('v-divider')], 1), _c('div', {
    staticClass: "checkout-customer-details-setup-details-card__payment-method-wrapper"
  }, [_c('div', {
    staticClass: "checkout-customer-details-setup-details-card__title"
  }, [_c('span', [_vm._v("2. Payment method")])]), _c('div', {
    staticClass: "checkout-customer-details-setup-details-card__content-wrapper"
  }, [_c('CheckoutPaymentMethodCardSetup')], 1)])])]), _c('div', {
    staticClass: "checkout-customer-details-setup-page__selected-plan-amount-wrapper"
  }, [_c('SelectedPlanAmount', {
    scopedSlots: _vm._u([{
      key: "button",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('f-template-data-transfer', {
          attrs: {
            "loading": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var dataStash = _ref2.dataStash,
                promiseProcessor = _ref2.promiseProcessor;
              return [_c('v-btn', _vm._b({
                attrs: {
                  "disabled": !_vm.getPurchaseSubmittingAllowed,
                  "loading": dataStash.loading
                },
                on: {
                  "click": function () {
                    return promiseProcessor(_vm.$store.dispatch('user/plan/checkout/submitSubscriptionChange'));
                  }
                }
              }, 'v-btn', attrs, false), [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "size": "15"
                }
              }, [_vm._v("mdi-lock")]), _vm._v(" Submit purchase ")], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "hint",
      fn: function (_ref3) {
        var _orderSummary$renewal;
        var component = _ref3.component,
          orderSummary = _ref3.orderSummary,
          selectedPriceObj = _ref3.selectedPriceObj;
        return [_c(component, {
          tag: "component"
        }, [_vm._v(" By purchasing, you accept the "), _c('TermOfUse', {
          attrs: {
            "is-visible": _vm.dialogTermsVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('span', _vm._g({
                staticClass: "blue_400--text cursor-pointer d-inline"
              }, on), [_vm._v("Terms of Use")]), _vm._v(" and acknowledge reading ")];
            }
          }], null, true)
        }), _c('PrivacyPolicy', {
          attrs: {
            "is-visible": _vm.dialogPolicyVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var on = _ref5.on;
              return [_vm._v(" the "), _c('span', _vm._g({
                staticClass: "blue_400--text cursor-pointer"
              }, on), [_vm._v("Privacy Policy")]), _vm._v(". ")];
            }
          }], null, true)
        }), _vm._v(" You also agree to auto renewal of your " + _vm._s((selectedPriceObj === null || selectedPriceObj === void 0 ? void 0 : selectedPriceObj.recurring_interval) + 'ly') + " subscription for $" + _vm._s((orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$renewal = orderSummary.renewal) === null || _orderSummary$renewal === void 0 ? void 0 : _orderSummary$renewal.price) / 100) + ", which can be disabled at any time through your account. Your card details will be saved for future purchases and subscription renewals. ")], 1)];
      }
    }])
  })], 1)]), _c('DividedDisclaimer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }