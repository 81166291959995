var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "interactive-finish-page"
  }, [_c('div', {
    staticClass: "interactive-finish-page__content"
  }, [_c('div', {
    staticClass: "interactive-finish-page__content-wrapper"
  }, [_c('div', {
    staticClass: "interactive-finish-page__content-emoji"
  }, [_vm._t("emoji")], 2), _c('div', {
    staticClass: "interactive-finish-page__content-title"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "interactive-finish-page__content-description"
  }, [_vm._t("description")], 2)])]), _vm.$listeners.redirect && _vm.getFormSourceJSON.settings.general_settings.formsly_branding ? _c('div', {
    staticClass: "interactive-finish-page__footer"
  }, [_c('span', {
    staticClass: "interactive-finish-page__footer-text"
  }, [_vm._v("Create your own - it's free, easy & beautifulll")]), _c('button', {
    staticClass: "interactive-finish-page__footer-button",
    on: {
      "click": _vm.redirect
    }
  }, [_vm._v("Create a Form")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }