<template>
    <div class="static-main-page__footer-wrapper">
        <div class="static-main-page-footer">
            <div class="static-page-action-button-wrapper">
                <div
                        class="static-page-action-button"
                        @click="executeButtonAction"
                >
                    <span class="static-page-action-button__text static-page-action-button__text--readonly">{{getCurrentPage.submit_button.properties.button_text || 'Next'}}</span>
                </div>
            </div>
            <div class="static-main-page-footer__disclaimer">
                Never submit passwords through Forms
            </div>
        </div>
        <div class="static-main-page-footer-transition-helper"></div>
    </div>
</template>

<script>
    import {useMapGetters, useMapActions} from '@/xstore'
    import {inject} from '@vue/composition-api/dist/vue-composition-api'

    export default {
        name: "StaticMainPageSubmitButtonComponent",
        setup() {
            return {
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getCurrentPage',
                    'getFormSourceJSON'
                ]),
                ...useMapActions(inject('formSubmitStoreModulePath'), [
                    'goToNextPage',
                    'goToFinishPage'
                ])
            }
        },
        methods: {
            executeButtonAction() {
                this.$emit('validate', this.pageValidationPassed)
            },
            pageValidationPassed() {
                const action = this.getCurrentPage.submit_button.properties.on_click_action

                if (action === 'go_to_next_page') {
                    this.goToNextPage()
                } else if (action === 'submit_form') {
                    this.goToFinishPage()
                }
            }
        },
    }
</script>

<style scoped>

</style>