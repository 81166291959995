import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants'



const INPUT_COLOR__EDITABLE_STATIC = '#737E81'
const INPUT_COLOR__SUBMITTABLE_STATIC = 'black'
const PLACEHOLDER_COLOR__EDITABLE_STATIC = '#CED2D8'
const PLACEHOLDER_COLOR__SUBMITTABLE_STATIC = INPUT_COLOR__EDITABLE_STATIC

const INPUT_COLOR__EDITABLE_INTERACTIVE = FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
    asColors: {
        true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.5, asList: true}),
        false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.7, asList: true})
    }
})
const INPUT_COLOR__SUBMITTABLE_INTERACTIVE = FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
const PLACEHOLDER_COLOR__EDITABLE_INTERACTIVE = FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.2})
const PLACEHOLDER_COLOR__SUBMITTABLE_INTERACTIVE = INPUT_COLOR__EDITABLE_INTERACTIVE



const INPUT_ELEMENT_PROPERTIES = {
    'max-height': {
        default: {
            fn: 'unset'
        }
    },
    padding: {
        'default--interactive': {
            fn: '12px 0'
        }
    },
    'color': {
        'default--editable--interactive': {
            fn: INPUT_COLOR__EDITABLE_INTERACTIVE
        },
        'default--submittable--interactive': {
            fn: INPUT_COLOR__SUBMITTABLE_INTERACTIVE
        },
        'default--editable--static': {
            fn: INPUT_COLOR__EDITABLE_STATIC,
        },
        'default--submittable--static': {
            fn: INPUT_COLOR__SUBMITTABLE_STATIC
        }
    },
    'font-size': {
        'default--static': {
            fn: '16px'
        },
        'default--interactive': {
            fn: '24px'
        }
    },
    'line-height': {
        'default--static': {
            fn: '20px'
        },
        'default--interactive': {
            fn: '29px'
        }
    },
    'font-weight': {
        'default--editable--interactive': {
            fn: 300,
        },
        'default--submittable--interactive': {
            fn: 400,
        }
    }
}


const INPUT_PLACEHOLDER_PROPERTIES = {
    'color': {
        'default--editable--interactive': {
            fn: PLACEHOLDER_COLOR__EDITABLE_INTERACTIVE
        },
        'default--submittable--interactive': {
            fn: PLACEHOLDER_COLOR__SUBMITTABLE_INTERACTIVE,
        },
        'default--editable--static': {
            fn: PLACEHOLDER_COLOR__EDITABLE_STATIC
        },
        'default--submittable--static': {
            fn: PLACEHOLDER_COLOR__SUBMITTABLE_STATIC,
        }
    },
    'font-weight': {
        'default--interactive': {
            fn: 300
        }
    },
    'line-height': {
        'default--interactive': {
            fn: '29px'
        }
    }
}


export const GenericTextFieldFSPDefinition = new FieldFSPDefinition({
    FSP_FIELD_TYPE: 'TextField',
    FIELD_VIEW_CLASS: 'generic-text-field-view',
    VARIABLES: [
        {
            selector: ['input-field'],
            properties: {
                'caret-color': {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                        important: true,
                    },
                    'default--static': {
                        fn: '#000 !important',
                        important: true,
                    }
                },
                color: {
                    'default--submittable--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                        important: true,
                    },
                    'default--submittable--static': {
                        fn: 'rgba(0, 0, 0, 0.87) !important',
                        important: true,
                    }
                },
            }
        },
        {
            selector: ['input-field.v-text-field--outlined .v-input__slot'],
            properties: {
                'box-shadow': {
                    'default--static': {
                        fn: '0 1px 2px 0 rgb(0 0 0 / 6%)'
                    },
                }
            }
        },
        {
            selector: ['input-field.v-text-field--outlined > .v-input__control > .v-input__slot > fieldset'],
            properties: {
                'border-color': {
                    'default--editable--static': {
                        fn: '#D0D5DD',
                    }
                },
            },
        },
        {
            selector: ['input-field.v-text-field > .v-input__control > .v-text-field__details .v-messages__wrapper'],
            properties: {
                color: {
                    'default--submittable--interactive': {
                        fn: INPUT_COLOR__SUBMITTABLE_INTERACTIVE,
                    }
                },
                height: {
                    'default--submittable--static': {
                        fn: '20px',
                    }
                }
            }
        },
        {
            selector: ['input-field .v-text-field--outlined'],
            properties: {
                'border-radius': {
                    'default--static': {
                        fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({px: true, max: 16})
                    },
                },
            }
        },
        {
            selector: ['input-field > .v-input__control > .v-input__slot::before'],
            properties: {
                'border-color': {
                    'default--interactive': {
                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                            asColors: {
                                true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.2, asList: true}),
                                false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR
                            }
                        })
                    }
                }
            }
        },
        {
            selector: ['input-field.v-input--is-focused > .v-input__control > .v-input__slot > fieldset'],
            properties: {
                border: {
                    'default--static--submittable': {
                        fn: '2px solid var(--v-blue_400-base)'
                    }
                }
            }
        },
        {
            selector: ['input-field > .v-input__control > .v-input__slot > ', {oneOf: ['.v-text-field__slot', '.v-select__slot > .v-select__selections']}, ' ', {oneOf: ['input', 'textarea', 'span']}],
            properties: INPUT_ELEMENT_PROPERTIES
        },
        {
            selector: ['input-field > .v-input__control > .v-input__slot ', {oneOf: ['input', 'textarea']}, '::placeholder'],
            properties: INPUT_PLACEHOLDER_PROPERTIES
        },
        {
            selector: ['input-field > .v-input__control > .v-input__slot > .v-input__prepend-inner'],
            properties: {
                'margin-right': {
                    'default--interactive': {
                        fn: '10px'
                    },
                },
                'padding-right': {
                    'default--static': {
                        fn: '8px',
                    }
                }
            }
        },
        {
            selector: [
                'input-field > .v-input__control > .v-input__slot > ',
                {oneOf: ['', '.v-select__slot > ']},
                {oneOf: ['.v-input__prepend-inner', '.v-input__append-inner']},
                ' > svg'
            ],
            properties: {
                width: {
                    'default--interactive': {
                        fn: '26px'
                    }
                },
                height: {
                    'default--interactive': {
                        fn: '26px'
                    }
                },
                'stroke-width': {
                    'default--interactive': {
                        fn: 1.5
                    },
                    'default--static': {
                        fn: 1,
                    },
                },
                color: {
                    'default--interactive': {
                        fn: INPUT_COLOR__EDITABLE_INTERACTIVE,
                    }
                }
            }
        },
        // {
        //     selector: ['input-field.v-select > .v-input__control > .v-input__slot'],
        //     properties: {
        //         cursor: {
        //             'default--submittable': {
        //                 fn: 'pointer',
        //             }
        //         }
        //     }
        // },
        // {
        //     selector: ['input-field.v-select > .v-input__control > .v-input__slot ', {oneOf: ['input', 'textarea']}],
        //     properties: {
        //         cursor: {
        //             'default--submittable': {
        //                 fn: 'pointer',
        //             }
        //         }
        //     }
        // },
    ]
})
GenericTextFieldFSPDefinition.INPUT_ELEMENT_PROPERTIES = INPUT_ELEMENT_PROPERTIES
GenericTextFieldFSPDefinition.INPUT_PLACEHOLDER_PROPERTIES = INPUT_PLACEHOLDER_PROPERTIES
GenericTextFieldFSPDefinition.FSPDefinitionForClickablePrependIcon = new FieldFSPDefinition({
    FSP_FIELD_TYPE: 'GenericTextFieldFSPDefinition-ClickablePrependIcon',
    FIELD_VIEW_CLASS: GenericTextFieldFSPDefinition.FIELD_VIEW_CLASS,
    VARIABLES: [
        {
            selector: ['input-field > .v-input__control > .v-input__slot > .v-input__prepend-inner > svg'],
            properties: {
                cursor: {
                    'default--submittable': {
                        fn: 'pointer'
                    }
                }
            }
        }
    ]
})


export class GenericTextFieldConst extends FieldTypeConst {
    constructor(options) {
        const {
            useGenericEditableView = true,
            ...restOptions
        } = options
        super({
            WITH_PRESS_ENTER: true,

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            ...(useGenericEditableView && {
                EDITABLE_VIEW: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericEditableTextFieldViewForPlaceholderInput'),
                PREVIEW_VIEW: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericPreviewTextFieldView'),
            }),

            IS_SUBMISSION_EMPTY: submissionObj => !submissionObj.value || !submissionObj.value?.toLocaleString()?.length,

            FSP: GenericTextFieldFSPDefinition,

            ...restOptions,
        })
    }
}