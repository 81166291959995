<template>
    <span></span>
<!--    <v-menu-->
<!--            content-class="submissions-export__dropdown-menu dropdown-menu-list"-->
<!--            nudge-bottom="8"-->
<!--            min-width="222"-->
<!--            offset-y-->
<!--    >-->
<!--        <template #activator="{ on, value }">-->
<!--            <v-btn-->
<!--                    rounded-->
<!--                    outlined-->
<!--                    v-on="on"-->
<!--                    :disabled="disabled"-->
<!--            >-->
<!--                <span class="grey_900&#45;&#45;text">Export</span>-->
<!--                <ChevronDownIcon-->
<!--                        size="16"-->
<!--                        class="dropdown-menu-chevron ml-3 mr-n2 gray_400&#45;&#45;text"-->
<!--                        :class="{'dropdown-menu-chevron&#45;&#45;up': value}"-->
<!--                />-->
<!--            </v-btn>-->
<!--        </template>-->
<!--        <template #default>-->
<!--            <v-list dense>-->
<!--                <v-list-item :disabled="!isSelected">Export Selected</v-list-item>-->
<!--                <v-list-item>Export All Responses</v-list-item>-->
<!--                <v-divider/>-->
<!--                <v-list-item>Print</v-list-item>-->
<!--                <v-list-item>Send via Email</v-list-item>-->
<!--                <v-list-item>Share as a Link</v-list-item>-->
<!--            </v-list>-->
<!--        </template>-->
<!--    </v-menu>-->
</template>

<script>
    // import { ChevronDownIcon } from 'vue-feather-icons'

    export default {
        name: "SubmissionsExportComponent",
        components: {
            // ChevronDownIcon
        },
        props: {
            disabled: Boolean,
            isSelected: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .submissions-export__dropdown-menu {
        .v-list-item {
            &:hover {
                background-color: var(--v-gray_100-base) !important;
            }

            font-size: .875rem;
        }
    }
</style>