<template>
    <GenericFrontView :field-properties="fieldProperties"/>
</template>

<script>
    import editable_field_view_mixin from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: "Editable__StarRating",
        components: {
            GenericFrontView: () => import('./GenericFrontView')
        },
        mixins: [editable_field_view_mixin],
    }
</script>