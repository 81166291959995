var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full d-flex align-center justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.resetStage === _vm.resetStages.ENTER_EMAIL ? _c('LoginLayoutWithImage', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Reset Your password ")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_vm._v(" Enter your user account's verified email address and we will send you a password reset code ")];
      },
      proxy: true
    }, {
      key: "form",
      fn: function () {
        return [_c('ErrorAlertComponent'), _c('v-form', {
          ref: "enterEmailForm",
          attrs: {
            "lazy-validation": ""
          },
          model: {
            value: _vm.enterEmailFormValid,
            callback: function ($$v) {
              _vm.enterEmailFormValid = $$v;
            },
            expression: "enterEmailFormValid"
          }
        }, [_c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Email")]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Enter your email",
            "rules": _vm.emailRules,
            "outlined": "",
            "required": "",
            "disabled": _vm.$store.getters.isLoading
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.enterEmailForm.resetValidation();
            }
          },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('v-btn', {
          staticClass: "mb-4",
          attrs: {
            "color": "blue_400",
            "depressed": "",
            "large": "",
            "rounded": "",
            "block": "",
            "disabled": _vm.$store.getters.isLoading
          },
          on: {
            "click": _vm.sendPasswordResetEmail
          }
        }, [_vm._v(" Send password reset email ")])], 1)];
      },
      proxy: true
    }, {
      key: "jump-to",
      fn: function () {
        return [_vm._v(" Remember your password? "), _c('span', {
          staticClass: "ml-2 blue_400--text font-weight-bold cursor-pointer",
          on: {
            "click": _vm.goToLogin
          }
        }, [_vm._v("Log in")])];
      },
      proxy: true
    }], null, false, 1062435753)
  }) : _vm._e(), _vm.resetStage === _vm.resetStages.EMAIL_SENT ? _c('VerificationTemplate', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-6 d-flex rounded-circle green_100"
        }, [_c('CheckCircleIcon', {
          staticClass: "green_500--text"
        })], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Reset password link has been successfully sent to your email address ")];
      },
      proxy: true
    }], null, false, 237544641)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }