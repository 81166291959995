import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {Analytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'

import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants';
import constants from "@/constants/constants";

class SignatureFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'SIGNATURE',
            READABLE_NAME: 'Signature',
            ICON_NAME: 'signature',
            FOLDER_NAME: 'Signature',

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                  if (fieldObj.properties.allow_typing) {
                      return submissionObj.value;
                  } else if (!fieldObj.properties.allow_typing && submissionObj.value) {
                      return `${constants.S3_BASE_URL}${constants.S3_UPLOADS_FOLDER_PATH}${submissionObj.value}`
                  }
                  return null;
                },
            }),

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            IS_SUBMISSION_EMPTY: ({value}) => !value,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            ASSOCIATE_WITH_PAID_FEATURE: 'signature_field',
            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: 'Type your signature here...',
                }
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'allow_typing',
                    name: 'Allow typing'
                })
            ]),
            FSP: new FieldFSPDefinition({
                FSP_FIELD_TYPE: 'Signature',
                FIELD_VIEW_CLASS: 'signature-field-view',
                VARIABLES: [
                    {
                        selector: ['layout-wrapper'],
                        properties: {
                            'background-color': {
                                default: {
                                    fn: '#F2F4F7',
                                },
                            },
                            'border-radius': {
                                default: {
                                    fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                                        px: true,
                                        calc: v => `${v} * 2`,
                                        max: 16
                                    })
                                }
                            },
                            padding: {
                                'default--static': {
                                    fn: '25px',
                                },
                                'default--interactive': {
                                    fn: '5px 25px',
                                },
                            },
                            height: {
                                default: {
                                    fn: '120px',
                                }
                            },
                        }
                    },
                    {
                        selector: ['layout-wrapper--editing-active'],
                        properties: {
                            'background-color': {
                                'default--editable': {
                                    fn: '#fff',
                                },
                            }
                        }
                    },
                    {
                        selector: ['.v-text-field .v-input__slot input'],
                        external: true,
                        properties: {
                            'font-weight': {
                                'default--editable--interactive': {
                                    fn: 300,
                                },
                                'default--submittable--interactive': {
                                    fn: 400,
                                }
                            },
                            'color': {
                                'default--submittable--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                                },
                                'default--submittable--static': {
                                    fn: 'black',
                                },
                                'default--editable': {
                                    fn: '#737E81',
                                },
                            },
                            'font-size': {
                                default: {
                                    fn: '16px'
                                }
                            },
                        }
                    },
                    {
                        selector: ['.v-text-field .v-input__slot input::placeholder'],
                        properties: {
                            'color': {
                                'default--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.2}),
                                },
                                'default--static': {
                                    fn: '#CED2D8',
                                },
                            },
                            'font-weight': {
                                'default--interactive': {
                                    fn: 300
                                }
                            },
                        }
                    }
                ]
            }),
        })
    }
}

export default new SignatureFieldTypeConst()