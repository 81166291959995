import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";
import GenericFrontView from './GenericSelectionFieldFrontView'

export default {
    name: 'GenericSelectionPreviewFieldView',
    functional: true,
    mixins: [preview_field_view_mixin],
    props: {
        radio: {
            type: Boolean,
            default: false,
        }
    },
    render(h,ctx) {
        return h(
            GenericFrontView,
            {
                props: {
                    ...ctx.props,
                    isPreview: true,
                    optionsList: ctx.props.fieldObj.content.options.map((_, index) => index)
                }
            },
        )
    }
}