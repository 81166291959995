<template>
    <v-app-bar height="80" app color="white" flat >
        <div class="app-bar-part justify-start">
            <slot name="left"></slot>
        </div>
        <div class="app-bar-part justify-center">
            <slot name="center"></slot>
        </div>
        <div class="app-bar-part justify-end">
            <slot name="right"></slot>
        </div>
        <v-progress-linear
                indeterminate
                absolute
                bottom
                v-if="isLoading"
        ></v-progress-linear>

    </v-app-bar>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "AppBarTemplate",
        computed: {
            ...mapGetters([ 'isLoading']),
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    header.v-app-bar {
        z-index: 20;
        border-bottom: var(--v-grey_200-base) 1px solid !important;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
            ::v-deep.v-toolbar__content {
                justify-content: space-between;
            }
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            .app-bar-part {
                width: calc(100% / 3);
            }
        }

        .app-bar-part {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
</style>