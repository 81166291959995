<template>
    <component
            :is="$options.BillingDetailsForm.form"
            v-slot="{ attrs, submit, loading, reset }"
    >
        <f-dialog
                v-model="dialogModel"
                width="600"
                eager

                with-close-btn
        >
            <template #title>Update Your Billing Address</template>
            <template #content>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.name" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.line1" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.postal_code" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.country" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.city" v-bind="attrs"/>
                    </v-col>
                </v-row>
            </template>
            <template #actions>
                <v-spacer/>
                <v-btn
                        color="gray_100"
                        rounded
                        :disabled="loading"
                        @click="() => {
                            reset()
                            closeDialog()
                        }"
                >Cancel
                </v-btn>
                <v-btn
                        color="blue_400"
                        rounded
                        :loading="loading"
                        @click="submit(billingDetails => $store.dispatch('user/plan/customer_details/updateBillingDetails', billingDetails).then(() => {
                            reset()
                            closeDialog()
                        }))"
                >Confirm
                </v-btn>
            </template>
        </f-dialog>
    </component>
</template>

<script>
    import {ref} from '@vue/composition-api'
    import BillingDetailsForm from '@/components/Settings/PlansAndBilling/BillingDetails/BillingDetailsForm'

    export default {
        name: "UpdateBillingDetailsDialog",
        BillingDetailsForm,
        setup() {
            const dialogModel = ref(false)
            const openDialog = () => dialogModel.value = true
            const closeDialog = () => dialogModel.value = false


            return {
                dialogModel,
                openDialog,
                closeDialog,
            }
        }
    }
</script>