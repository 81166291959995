<template>
    <div class="circle-loader-container">
        <div
                class="circle-loader"
                :style="{fontSize: size + 'px'}"
        ></div>
    </div>
</template>

<script>
    export default {
        name: "CircleLoader",
        props: {
            size: {
                validator: v => {
                    return typeof v === 'number' ? v > 0 : !isNaN(v)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .circle-loader-container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        .circle-loader,
        .circle-loader:after {
            border-radius: 50%;
            width: 10em;
            height: 10em;
        }

        .circle-loader {
            /*margin: 60px auto;*/
            font-size: 10px;
            position: relative;
            text-indent: -9999em;
            border-top: 1.1em solid var(--v-blue_200-base);
            border-right: 1.1em solid var(--v-blue_200-base);
            border-bottom: 1.1em solid var(--v-blue_200-base);
            border-left: 1.1em solid var(--v-blue_400-base);
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: circle-load 1.1s infinite linear;
            animation: circle-load 1.1s infinite linear;
        }

        @-webkit-keyframes circle-load {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @keyframes circle-load {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }
</style>