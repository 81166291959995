<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex mt-3">
        <v-icon size="36">
          {{ app.icon }}
        </v-icon>
        <div class="d-flex flex-column ml-4">
          <span class="integrations-app-title">
            {{ app.title }}
          </span>
          <span class="integrations-app-subtitle">
            {{ app.connectDescription }}
          </span>
        </div>
      </div>
    </div>
    <v-btn
      v-if="app.connected"
      @click="disconnect"
      height="32"
      :class="[app.connected && 'disconnect-btn']"
      class="mt-6 rounded-pill font-weight-medium connect-btn"
      color="gray_700"
      outlined
      text
    >
      Disconnect <f-circle-loader v-if="loader"  size="1.5" style="margin-left: 5px; filter: hue-rotate(45deg);" />
    </v-btn>
    <v-btn
      v-else
      @click="connection"
      height="32"
      :class="[app.connected && 'disconnect-btn']"
      class="mt-6 rounded-pill font-weight-medium connect-btn"
      color="gray_700"
      outlined
      text
    >
      Connect
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'IntegrateSettings',
  props: {
    app: {
      type: Object,
      required: true,
    },
    loader:{
      type: Boolean,
    }
  },
  setup() {
    function connection() {
      this.$emit('connection', !this.app.connected)
    }

    function disconnect() {
      this.$emit('disconnect', !this.app.connected)
    }

    return {
      connection,
      disconnect,
    }
  },
}
</script>
<style lang="scss" scoped>
.connect-btn {
  width: 120px !important;
  height: 32px !important;
}
.disconnect-btn {
  color: var(--v-red_400-base) !important;
  background: var(--v-red_100_2-base);
  border: 1px solid var(--v-red_400-base) !important;
}
.integrations-app-title {
  color: var(--v-gray_700-base);
  font-size: 16px;
  font-weight: 500;
}

.integrations-app-subtitle {
  color: var(--v-grey_500_2-base);
  font-size: 14px;
  font-weight: 400;
}
</style>
