<template>
    <f-select
            v-model="model"
            class="flex-grow-0 summary-change-sort-order"
            style="width: 80px"
            :items="options"
            :item-value="item => item.value"
            solo flat dense hide-details
            :menu-props="{
                nudgeBottom: 38,
                left: true
            }"
    >
        <template #selection="{item}">
            <v-icon>{{item.icon}}</v-icon>
        </template>
        <template #item="{item, on, attrs}">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </f-select>
</template>

<script>
    export default {
        name: "SummaryChangeSortOrder",
        props: {
            value: String
        },
        data: () => ({
            options: [
                {
                    text: 'Preference order',
                    icon: '$sort_default_order',
                    value: 'default'
                },
                {
                    text: 'Descending order',
                    icon: '$sort_descending_order',
                    value: 'descending'
                }
            ]
        }),
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v)
                }
            }
        }
    }
</script>