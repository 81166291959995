import constants from '@/constants/constants'

export default () => {
    return {
        getters: {
            isEnabled: ({parentGetters}) => parentGetters.getFormSourceJSON?.settings?.general_settings?.progress_bar,

            getValue: ({getters, parentGetters}) => {
                if (!getters.isEnabled)
                    return 0

                const fieldsWithSubmission = Object.keys(parentGetters['conditional/getExistingFields']).filter(fieldID => constants.FIELD_FROM_TYPE[parentGetters.getFormSourceJSON.fields[fieldID].field_type].WITH_SUBMISSION)

                const totalFieldsCount = fieldsWithSubmission.length
                const filledFieldsCount = fieldsWithSubmission
                    .reduce((accumulator, fieldID) => {
                        const currentFieldSubmissionObj = parentGetters['submission/getFormSubmission'][fieldID]
                        const fieldConst = constants.FIELD_FROM_TYPE[parentGetters.getFormSourceJSON.fields[fieldID].field_type]

                        if (fieldConst.IS_SUBMISSION_EMPTY && !fieldConst.IS_SUBMISSION_EMPTY(currentFieldSubmissionObj))
                            return accumulator + 1

                        return accumulator
                    }, 0)

                return filledFieldsCount / totalFieldsCount
            }
        }
    }
}