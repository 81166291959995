var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.submitButtonObj ? _c('div', {
    staticClass: "static-main-page__footer-wrapper",
    class: [_vm.getFormJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && 'static-main-page__footer-wrapper--full-screen']
  }, [_c('div', {
    staticClass: "static-main-page-footer"
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('div', {
          staticClass: "static-page-action-button-padding-wrapper"
        }, [_c('div', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.disableEditing,
            expression: "disableEditing"
          }],
          staticClass: "static-page-action-button-wrapper",
          class: (_vm.isEditingActive || hover) && 'static-page-action-button-wrapper--editing-active',
          on: {
            "click": _vm.enableEditing
          }
        }, [_c('div', {
          staticClass: "static-page-action-button"
        }, [_c('f-simple-contenteditable-input', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.confirmBtnText,
            expression: "confirmBtnText"
          }],
          ref: "actionButtonTextInput",
          staticClass: "static-page-action-button__text static-page-action-button__text--editable",
          attrs: {
            "placeholder": "Submit",
            "prevent-highlighting": "",
            "overflow-wrap": "",
            "mask": /^.{0,20}$/g,
            "readonly": !_vm.isBtnTextEditing
          },
          on: {
            "dblclick": _vm.editBtnText,
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.confirmBtnText.apply(null, arguments);
            }
          },
          model: {
            value: _vm.submitButtonObj.properties.button_text,
            callback: function ($$v) {
              _vm.$set(_vm.submitButtonObj.properties, "button_text", $$v);
            },
            expression: "submitButtonObj.properties.button_text"
          }
        })], 1), _c('v-slide-y-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [(_vm.isEditingActive || hover) && !_vm.isPropertiesVisible ? _c('div', {
          staticClass: "static-main-page-footer__popup-actions"
        }, [_c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "Edit button properties here"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                staticClass: "v-btn--primary grey_900--text",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.openProperties
                }
              }, on), [_c('Edit3Icon', {
                attrs: {
                  "stroke-width": "1",
                  "size": "20"
                }
              })], 1)];
            }
          }], null, true)
        })], 1) : _vm.isEditingActive && _vm.isPropertiesVisible ? _c('EditableFieldPropertiesWindowTemplate', {
          staticClass: "static-main-page-footer__popup-properties",
          on: {
            "close-properties": _vm.closeProperties
          }
        }, [_c('EditableFieldPropertiesListGenerator', {
          attrs: {
            "field-properties-obj": _vm.submitButtonObj.properties,
            "field-base-properties": _vm.constants.STATIC_PAGE_SUBMIT_BUTTON.BASE_PROPERTIES
          }
        })], 1) : _vm._e()], 1)], 1)])];
      }
    }], null, false, 3028990606)
  }), _c('div', {
    staticClass: "static-main-page-footer__disclaimer"
  }, [_vm._v(" Never submit passwords through Forms ")])], 1), _c('div', {
    staticClass: "static-main-page-footer-transition-helper"
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }