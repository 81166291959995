var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-dialog', _vm._b({
    attrs: {
      "value": _vm.getDialogModel,
      "width": "690",
      "retain-focus": false
    },
    on: {
      "input": function (v) {
        return !v && _vm.closeDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c(_vm.getDialogTitle, {
          tag: "component"
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(_vm.getDialogContent, {
          tag: "component"
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c(_vm.getDialogActions, {
          tag: "component"
        })];
      },
      proxy: true
    }])
  }, 'f-dialog', _vm.getDialogProps, false));
}
var staticRenderFns = []

export { render, staticRenderFns }