<template>
    <div :class="[
        'static-form',
        'static-form--submittable',
        (getFormSourceJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (getFormSourceJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--full-screen visible-header',
        (getFormSourceJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (getFormSourceJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--full-screen invisible-header',
        (getFormSourceJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (getFormSourceJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--compact visible-header',
        (getFormSourceJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (getFormSourceJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--compact invisible-header',
    ]" ref="scroller">
        <div class="static-form__container">
            <div class="static-form__cover-wrapper">
                <div class="static-form__cover"
                    :style="{ backgroundImage: 'url(' + getFormSourceJSON.cover_image + ')' }"></div>
                <div class="moveable-area h-full d-flex justify-center align-center text-h3">
                    <span
                        :style="{ transform: getFormSourceJSON.cover_text.translate, height: getFormSourceJSON.cover_text.height, width: getFormSourceJSON.cover_text.width }"
                        class="text-container text-container--submittable" v-html="getFormSourceJSON.cover_text.content"
                        ref="textContainer">

                    </span>
                </div>
            </div>
            <StaticPagesDisplayManager @page-changed="scrollToTop" />
        </div>
        <div class="static-form__footer noselect">
        </div>
        <FormslyBadge v-if="getFormSourceJSON.settings.general_settings.formsly_branding" />
    </div>
</template>

<script>
import { useMapGetters } from '@/xstore'
import { inject } from '@vue/composition-api'

export default {
    name: "StaticFormSubmit",
    components: {
        StaticPagesDisplayManager: () => import('./Pages/StaticPagesDisplayManager'),
        FormslyBadge: () => import('@/components/Elements/FormslyBadge'),
    },
    setup(props, { refs }) {
        const scrollToTop = () => {
            refs.scroller.scrollTop = 0;
        }
        return {
            ...useMapGetters(inject('formSubmitStoreModulePath'), [
                'getFormSourceJSON'
            ]),
            scrollToTop,
            goToHomePage: () => {
                this.$router.push({ name: this.constants.PAGE_NAME_DASHBOARD })
            }
        }
    },
    mounted() {
        const usedFonts = Array.from(this.$refs.textContainer.querySelectorAll('font')).filter(elem => elem.hasAttribute('face')).map(elem => elem.getAttribute('face'));
        usedFonts.forEach(font => {
            this.constants.TEXT_EDITOR_CONSTANTS.FONTS.forEach(item => {
                if (font === item.name) {
                    this.Utils.addFontFamilyURL(item.url);
                }
            })
        })
    },
}
</script>
<style lang="scss" scoped>
.static-form--layout-style--compact.invisible-header {
    padding-top: 100px;
}
.visible-header .static-form__container .static-form__cover-wrapper {
    display: block;
}

.invisible-header .static-form__container .static-form__cover-wrapper {
    display: none;
}
</style>