var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "36",
      "height": "36",
      "rx": "11",
      "fill": "#F5F6F8"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.59521 15.2509V20.1914H10.5H12.4047V19.2985V18.4057H11.75C11.1309 18.4057 11.0952 18.4176 11.0952 18.7033C11.0952 18.9771 11.0595 19.0009 10.5 19.0009H9.90474V15.2509V11.5009H10.5C11.0595 11.5009 11.0952 11.5247 11.0952 11.7985C11.0952 12.0843 11.1309 12.0962 11.75 12.0962C12.369 12.0962 12.4047 12.0843 12.4047 11.7985V11.5009H15.2024H18V11.7985C18 12.0724 18.0357 12.0962 18.5952 12.0962C19.1547 12.0962 19.1905 12.0724 19.1905 11.7985C19.1905 11.5128 19.2262 11.5009 19.8452 11.5009H20.5V13.0485V14.5962H21.0952H21.6905V12.4533V10.3105H15.1428H8.59521V15.2509Z",
      "fill": "#18273A"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.0952 14.0005V14.5957H11.75H12.4047V14.0005V13.4052H11.75H11.0952V14.0005Z",
      "fill": "#18273A"
    }
  }), _c('path', {
    attrs: {
      "d": "M18 14.0005V14.5957H18.5952H19.1905V14.0005V13.4052H18.5952H18V14.0005Z",
      "fill": "#18273A"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.0952 16.5005V17.0957H11.75H12.4047V16.5005V15.9052H11.75H11.0952V16.5005Z",
      "fill": "#18273A"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.5952 21.5005V27.0957H20.1428H26.6905V21.5005V15.9052H20.1428H13.5952V21.5005ZM25.5 20.2743V23.4648L23.6547 21.6195L21.7976 19.7743L20.2619 21.3814L18.7262 22.9886L17.8333 22.0957L16.9285 21.2028L15.9166 22.2148L14.9047 23.2267V20.1552V17.0957H20.2024H25.5V20.2743ZM23.6785 23.429C25.1547 24.8933 25.5 25.31 25.5 25.5719V25.9052H20.2024H14.9047V25.4528C14.9047 25.0481 15 24.9052 15.9524 23.9648L17.0119 22.9171L17.8452 23.81L18.6785 24.7028L20.2143 23.1552C21.0595 22.31 21.7738 21.6195 21.8095 21.6195C21.8452 21.6195 22.6785 22.4409 23.6785 23.429Z",
      "fill": "#18273A"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.6666 18.6795C17.119 19.2628 17.5238 20.1914 18.3333 20.1914C18.8452 20.1914 19.1905 19.8343 19.1905 19.2986C19.1905 18.489 18.2143 18.0962 17.6666 18.6795Z",
      "fill": "#18273A"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }