<template>
    <InteractiveFinishPageTemplate>
        <template #emoji>
            👍
        </template>
        <template #title>
            <f-simple-contenteditable-input
                    oveflow-wrap
                    prevent-highlighting
                    multiline
                    v-model="finishTitle"
                    placeholder="Title"
            />
        </template>
        <template #description>
            <f-simple-contenteditable-input
                    oveflow-wrap
                    prevent-highlighting
                    multiline
                    v-model="finishDescription"
                    placeholder="Additional description"
            />
        </template>
    </InteractiveFinishPageTemplate>
</template>

<script>
    import {finish_page_edit_mixin} from "@/components/FormGenericComponents/Mixins/finish_page_mixin";

    export default {
        mixins: [finish_page_edit_mixin],
        name: "InteractiveFinishPage",
        components: {
            InteractiveFinishPageTemplate: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveFinishPageTemplate')
        },
    }
</script>

<style scoped>

</style>