import InteractiveHomePageContentHolder from './Elements/InteractiveHomePageContentHolder'
import InteractiveHomePageImageHolder from './Elements/InteractiveHomePageImageHolder'
import _ from 'lodash'

export default {
    name: "ContentLeftImageRight",
    functional: true,
    render: (h, ctx) => h(
        'div',
        {class: 'interactive-form-home-page interactive-form-home-page--half-content-half-image'},
        [
            h(
                'div',
                {class: 'interactive-form-home-page__content-wrapper'},
                [h(InteractiveHomePageContentHolder, _.defaultsDeepWithClone({props: {align: 'left'}}, ctx.data))]
            ),
            h(
                'div',
                {class: 'interactive-form-home-page__image-wrapper'},
                [h(InteractiveHomePageImageHolder, ctx.data)]
            )
        ]
    )
}