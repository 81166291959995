var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "submissions-analytics__content"
  }, [!_vm.getSubmissionsInitiallyAllFetched ? _c('f-circle-loader', {
    attrs: {
      "size": "5"
    }
  }) : [_vm.areSubmissionsExist ? _c('div', {
    staticClass: "summary-analytics-view"
  }, [_vm._l(_vm.sortedSubmissionsGroupedByFieldID, function (_ref, index) {
    var fieldID = _ref.fieldID,
      fieldAttrs = _ref.fieldAttrs;
    return [_c('div', {
      key: fieldID,
      staticClass: "summary-analytics-view__answers-block-wrapper"
    }, [_c('div', {
      staticClass: "summary-analytics-view__answers-block",
      attrs: {
        "data-index": index + 1
      }
    }, [_c(fieldAttrs.fieldTypeConst.ANALYTICS.VIEWS.SUMMARY_ANSWER_VIEW, _vm._b({
      tag: "component",
      attrs: {
        "totalResponse": _vm.totalResponse
      }
    }, 'component', fieldAttrs, false))], 1)])];
  })], 2) : _c('NoResponses')]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }