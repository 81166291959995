<template>
  <div class="matrix-field-view__layout matrix-field-view__layout--mobile">
    <div class="matrix-field-view__wrapper">
      <div class="matrix-field-view__list">
        <div class="matrix-field-view__list-section" v-for="(section, rowIndex) in rows" :key="rowIndex + section.text">
          <div class="matrix-field-view__list-section-header">{{ section.text }}</div>
          <div class="matrix-field-view__list-section-row">
            <span v-for="(option, columnIndex) in columns"
                  :key="columnIndex + option.text"
                  @click="$emit('toggle-selection', {state: !submissionObjValue[rowIndex].includes(columnIndex), rowIndex, columnIndex})"
                  :class="['matrix-field-view__list-section-row-item',
                            submissionObjValue[rowIndex].includes(columnIndex) && 'matrix-field-view__list-section-row-item--selected'
                  ]"
            >
              {{ option.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileView__OpinionScale',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    submissionObjValue: {
      type: Array,
      default: () => [],
    }
  },
}
</script>

<style scoped>

</style>