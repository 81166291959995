var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center justify-space-between mt-6",
    class: [!_vm.disabled && 'integrations-app'],
    on: {
      "click": _vm.navigate
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    class: [_vm.disabled && 'integrations-app-icon'],
    attrs: {
      "size": "36"
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]), _c('div', {
    staticClass: "d-flex flex-column ml-4"
  }, [_c('span', {
    staticClass: "integrations-app-title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('span', {
    staticClass: "integrations-app-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.disabled ? 'Coming soon' : _vm.descriptions) + " ")])])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_vm.connected ? _c('div', {
    staticClass: "integrations-connected-badge"
  }, [_vm._v(" Connected ")]) : _vm._e(), !_vm.disabled ? _c('ChevronRightIcon', {
    attrs: {
      "size": "20"
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }