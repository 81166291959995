<template>
    <component
            :is="$options.PaymentMethodCardForm.form"
            v-slot="{ attrs, submit, loading, reset }"
    >
        <f-dialog
                v-model="dialogModel"
                width="600"
                eager

                with-close-btn
        >
            <template #title>
                Update Your Payment Method
            </template>
            <template #content>

                <v-row>
                    <v-col>
                        <component :is="$options.PaymentMethodCardForm.inputs.name" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.PaymentMethodCardForm.inputs.card" v-bind="attrs"/>
                    </v-col>
                </v-row>
            </template>
            <template #actions>
                <v-spacer/>
                <v-btn
                        color="gray_100"
                        rounded
                        :disabled="loading"
                        @click="() => {
                                    reset()
                                    closeDialog()
                                }"
                >
                    Cancel
                </v-btn>
                    <v-btn
                            color="blue_400"
                            rounded
                            :loading="loading"
                            @click="submit(paymentMethod => $store.dispatch('user/plan/customer_details/updatePaymentMethod', paymentMethod).then(() => {
                                reset()
                                closeDialog()
                            }))"
                    >
                        Confirm card
                    </v-btn>
            </template>
        </f-dialog>
    </component>
</template>

<script>
    import {ref} from '@vue/composition-api'
    import PaymentMethodCardForm from '@/components/Settings/PlansAndBilling/PaymentMethodCard/PaymentMethodCardForm'

    export default {
        name: "UpdatePaymentMethodDialog",
        PaymentMethodCardForm,
        setup() {
            const dialogModel = ref(false)
            const openDialog = () => dialogModel.value = true
            const closeDialog = () => dialogModel.value = false


            return {
                dialogModel,
                openDialog,
                closeDialog,
            }
        }
    }
</script>

<style lang="scss">
    //.stripe-card-element {
    //    padding: 12px;
    //
    //    border-radius: 8px;
    //    border-width: 1px;
    //    border-style: solid;
    //    border-color: var(--v-gray_300-base);
    //
    //    &--focus {
    //        border-color: var(--v-blue_400-base);
    //    }
    //}
</style>