<template>
    <AppBarTemplate>
        <template #left>
            <AppBarLogoComponent/>
        </template>
        <template #center>
            <v-btn
                    class="checkout-navigation-btn"
                    active-class="checkout-navigation-btn--active"
                    exact text
                    :disabled="getPurchaseCompleted"
                    :to="{name: constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN}"
            >
                1. Choose a plan
            </v-btn>
            <v-btn
                    class="checkout-navigation-btn"
                    active-class="checkout-navigation-btn--active"
                    :disabled="!getIsCustomerDetailsPageUnlocked || getPurchaseCompleted"
                    exact text
                    :to="{name: constants.PAGE_NAME_CHECKOUT_CUSTOMER_DETAILS}"
            >
                2. Payment
            </v-btn>
            <v-btn
                    class="checkout-navigation-btn"
                    active-class="checkout-navigation-btn--active"
                    :disabled="!getPurchaseCompleted"
                    exact text
                    :to="{name: constants.PAGE_NAME_CHECKOUT_PURCHASE_COMPLETED}"
            >
                3. Confirmation
            </v-btn>
        </template>
        <template #right>
            <XBtnToDashboard/>
        </template>
    </AppBarTemplate>
</template>

<script>
    import { mapGetters } from '@/xstore'

    export default {
        name: "AppBarCheckout",
        components: {
            AppBarTemplate: () => import("../AppBarTemplate"),
            AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
            XBtnToDashboard: () => import('../Elements/XBtnToDashboard')
        },
        computed: {
            ...mapGetters('user/plan/checkout/navigation', [
                'getIsCustomerDetailsPageUnlocked',
                'getPurchaseCompleted'
            ])
        }
    }
</script>

<style lang="scss">
    .checkout-navigation-btn.v-btn.v-btn--router {
        padding: 6px 17px !important;
        height: auto !important;
        border-radius: 100px;

        & > span {
            font-weight: 500 !important;
        }

        &::before {
            background-color: var(--v-gray_100-base) !important;
            opacity: 0 !important;
        }

        &:not(.checkout-navigation-btn--active) {
            color: var(--v-gray_400-base);
        }

        &.checkout-navigation-btn--active {
            &::before {
                opacity: 1 !important;
            }
        }
    }
</style>