import { API } from 'aws-amplify'

import user_plan_customer_details_module from './util_modules/user_plan_customer_details_module'

import user_plan_checkout_selected_plan from './checkout_modules/user_plan_checkout_selected_plan'
import user_plan_checkout_navigation_module from './checkout_modules/user_plan_checkout_navigation_module'

import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

export default ({useExtension}) => {
    {
        const state = {
            order_summary: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
        useExtension(ToggleableExtensions.Loadable, {state})
    }

    {
        const state = {
            selected_price_id: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        modules: {
            navigation: user_plan_checkout_navigation_module,
            selected_plan: user_plan_checkout_selected_plan,
            customer_details: user_plan_customer_details_module
        },
        actions: {
            initialFetch: ({dispatch}) => {
                dispatch('selected_plan/setCheckBeforePriceInitialSelection', {key: 'checkout_selected'})
                return dispatch('customer_details/fetchDetails')
            },
            calculateOrderSummary: ({dispatch, commit, toggleIsLoading}, {priceID}) => toggleIsLoading(
                (async () => {
                    const token = await dispatch('user/getUserJwtToken', null, {root: true})

                    return API
                        .post('core', '/upgradePlan/getOrderSummary', {
                            headers: {Authorization: token},
                            body: {priceID}
                        })
                        .then(currentSubscription => commit('SET_ORDER_SUMMARY', currentSubscription))
                })(),
                'order_summary'
            ),
            submitSubscriptionChange: async ({dispatch, getters, commit}) => {
                if (!getters['selected_plan/getSelectedPrice'])
                    throw 'price not selected'

                const token = await dispatch('user/getUserJwtToken', null, {root: true})

                return API
                    .post('core', '/upgradePlan/submitSubscriptionChange', {
                        headers: {Authorization: token},
                        body: {priceID: getters['selected_plan/getSelectedPrice']}
                    })
                    .then((response) => {
                        commit('user/plan/current_subscription/SET_CURRENT_SUBSCRIPTION', response.body, {root: true})
                        commit('user/plan/current_subscription/SET_PLAN_LIMITS_STATS', 0, {root: true})
                        dispatch('navigation/purchaseCompleted')
                    })
            }
        },
        getters: {
            getOrderSummaryLineItems: ({getters}) => getters.getOrderSummary?.line_items,
            getOrderSummaryRenewal: ({getters}) => getters.getOrderSummary?.renewal
        }
    }
}