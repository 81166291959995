import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            current_page_id: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        getters: {
            getCurrentPage: ({getters, parentGetters}) => parentGetters.getFormJSON?.all_pages?.[getters.getCurrentPageId],
            getCurrentMainPageIndex: ({getters, parentGetters}) => parentGetters.getFormJSON?.main_pages.findIndex(p => p.page_id === getters.getCurrentPageId),
        }
    }
}