var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F8F0E2"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.58334 11.7964H18.4167V17.8149C18.4167 17.9745 18.3533 18.1276 18.2404 18.2405C18.1275 18.3533 17.9744 18.4168 17.8148 18.4168H8.18519C8.02557 18.4168 7.87248 18.3533 7.75961 18.2405C7.64675 18.1276 7.58334 17.9745 7.58334 17.8149V11.7964Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.18519 8.78711H17.8148C17.9744 8.78711 18.1275 8.85052 18.2404 8.96339C18.3533 9.07626 18.4167 9.22934 18.4167 9.38896V11.7964H7.58334V9.38896C7.58334 9.22934 7.64675 9.07626 7.75961 8.96339C7.87248 8.85052 8.02557 8.78711 8.18519 8.78711V8.78711Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.4074 7.5835V9.9909",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.5926 7.5835V9.9909",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }