import constants from '@/constants/constants'
import { watch } from '@vue/composition-api'
import Utils from '@/utils/misc'

import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { FieldsEnumerationGettersUtils } from '@/store/util_modules/fields_enumeration_getters'
import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'
import { ModuleableExtensions, ModuleableUtils } from '@/xstore/utils/moduleable'
import _ from 'lodash'

const deepEqual = require('deep-equal')

const styleSheetIdComposer = path => 'FSP_StyleSheet_' + path

export const useFSPStoreModule = ({
    stylePreferencesGetterName,

    excludeGenericFieldClassConnectors
}) => ({useExtension}) => {
    {
        const state = {
            primary_variables: {},
            secondary_fields_variables: {}
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }

    useExtension(ModuleableExtensions.Default)
    useExtension(PathableExtensions.Default)

    return {
        created: ({localContext, parentContext}) => {
            if (!(FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName in parentContext.getters))
                throw new Error('[FSP store module]: FieldsEnumerationGettersExtension not found in parent module')

            watch(
                () => localContext.getters.getUniqueFieldsFSPDefinitions,
                (n, o) => {
                    if (!deepEqual(Utils.deepCloneObject(n), Utils.deepCloneObject(o)))
                        localContext.dispatch('updateFieldsFSPDefinitionsWatchers')
                },
                {deep: true, immediate: true}
            )
        },
        actions: {
            initializeFSP: ({commit, getters}) => {
                commit('SET_PRIMARY_VARIABLES', Object.fromEntries(
                    constants.FSP.ALL_PRIMARY_VARIABLES_ARRAY
                        .map(({GETTERS, JSON_NAME, PARENT_GETTER, VALUE_GETTER}) => {
                            return Object.entries(GETTERS)
                                .map(([, {
                                    name: getterName,
                                    value: getterProcessor,
                                    basedOnExternalGetter
                                }]) => {
                                    let finalGetterValue
                                    if (basedOnExternalGetter) {
                                        finalGetterValue = getterProcessor(require('@/store').default)
                                    } else {
                                        finalGetterValue = {
                                            value: '',
                                        }

                                        if (JSON_NAME) {
                                            getters[ModuleableUtils.moduleGetterName]._moduleScope.run(() => {
                                                watch(
                                                    () => getters['getStylePreferences']?.[JSON_NAME],
                                                    () => {
                                                        if (getters['getPrimaryVariables']?.[getterName])
                                                            return getters['getPrimaryVariables'][getterName].value = getterProcessor(getters['getStylePreferences'][JSON_NAME])
                                                    },
                                                    {deep: true}
                                                )
                                            })

                                            finalGetterValue.value = getterProcessor(getters['getStylePreferences'][JSON_NAME])
                                        } else {
                                            getters[ModuleableUtils.moduleGetterName]._moduleScope.run(() => {
                                                watch(
                                                    () => getters['getPrimaryVariables']?.[PARENT_GETTER.name]?.value,
                                                    newValue => Promise.resolve(getterProcessor(VALUE_GETTER(newValue)))
                                                        .then(r => {
                                                            if (getters['getPrimaryVariables']?.[getterName])
                                                                return getters['getPrimaryVariables'][getterName].value = r
                                                        }),
                                                    {deep: true}
                                                )
                                            })
                                        }
                                    }

                                    return [
                                        getterName,
                                        finalGetterValue
                                    ]
                                })
                        })
                        .flat()
                ))
            },
            updateFieldsFSPDefinitionsWatchers: ({commit, getters}) => {
                const finalVariables = {}

                document.getElementById(styleSheetIdComposer(getters[PathableUtils.namespaceGetterName]))?.remove()

                let sheetElement = document.createElement('style')
                let sheet

                sheetElement.id = styleSheetIdComposer(getters[PathableUtils.namespaceGetterName])

                document.head.appendChild(sheetElement)

                sheet = sheetElement.sheet

                getters.getUniqueFieldsFSPDefinitions
                    .forEach(FSP_CONST => {
                        const {
                            FSP_FIELD_TYPE,
                            FIELD_VIEW_CLASS,
                            VARIABLES,
                            GENERIC_STATES_CONNECTORS
                        } = FSP_CONST

                        VARIABLES
                            .forEach(({
                                selector: rawElementSelector,
                                properties: elementProperties,
                                external: isElementExternal = false
                            }) => {
                                if (!_.isArray(rawElementSelector) || !rawElementSelector[0])
                                    throw '[fsp_store_module]: selector must be array and it must contain at least one item'

                                const parseSelector = (selector, parentBranch = []) => {
                                    let branches = [parentBranch]

                                    selector.forEach(selectorItem => {
                                        if (_.isString(selectorItem)) {
                                            branches = branches.map(branch => [...branch, selectorItem])
                                        } else if (_.isPlainObject(selectorItem)) {
                                            if ('oneOf' in selectorItem) {
                                                branches = branches.map(branch => selectorItem.oneOf.map(b => parseSelector(Array.isArray(b) ? b : [b], branch))).flat(2)
                                            }
                                        }
                                    })

                                    return branches
                                }

                                let generatedElementSelectors = parseSelector(rawElementSelector).map(selector => selector.join(''))

                                if (!isElementExternal)
                                    generatedElementSelectors = generatedElementSelectors.map(selector => `.${FIELD_VIEW_CLASS}__${selector}`)

                                Object.entries(elementProperties)
                                    .forEach(([propertyKey, variableStates]) => {

                                        Object.entries(variableStates)
                                            .forEach(([stateKey, {
                                                important,
                                                watch: stateWatch,
                                                fn: stateFn,
                                                connector: stateCustomConnector,
                                            }]) => {
                                                let connector = {}

                                                const genericFieldModifiersMentionedInStateKey = constants.FSP.GENERIC_FIELD_CLASS._allowedModifiers.filter(modifier => stateKey.includes(modifier))
                                                Object.assign(connector, constants.FSP.GENERIC_FIELD_CLASS.applyAsFSPConnector(Object.fromEntries(genericFieldModifiersMentionedInStateKey.map(m => [m, true]))))

                                                if (GENERIC_STATES_CONNECTORS) {
                                                    let usedGenericConnectors = Object.entries(GENERIC_STATES_CONNECTORS).filter(([key]) => stateKey.includes(key))
                                                    usedGenericConnectors.forEach(([, usedGenericConnector]) => Object.assign(connector, usedGenericConnector))
                                                }
                                                else if (stateCustomConnector)
                                                    Object.assign(connector, stateCustomConnector)

                                                if (excludeGenericFieldClassConnectors) {
                                                    let genericFieldRawModifiers = connector?.[constants.FSP.GENERIC_FIELD_CLASS._FSPKeyName]?.raw

                                                    if (genericFieldRawModifiers && Object.keys(genericFieldRawModifiers).some(m => excludeGenericFieldClassConnectors.includes(m)))
                                                        return
                                                }


                                                const baseCssVariableName = constants.FSP.SECONDARY_VARIABLE_NAME_COMPOSER({
                                                    parentKey: FSP_FIELD_TYPE,
                                                    elementKey: generatedElementSelectors[0],
                                                    propertyKey,
                                                    stateKey
                                                })

                                                const callStateFn = () => stateFn(getters)

                                                finalVariables[baseCssVariableName] = {
                                                    value: ''
                                                }

                                                if ({}.toString.call(stateFn) !== '[object Function]')
                                                    finalVariables[baseCssVariableName].value = stateFn
                                                else
                                                    finalVariables[baseCssVariableName].value = (() => {
                                                        const value = callStateFn()
                                                        if (stateWatch) {
                                                            getters[ModuleableUtils.moduleGetterName]._moduleScope.run(() => {
                                                                watch(
                                                                    (stateWatch || []).map(({GETTERS: {RAW}}) => {
                                                                        if (getters)
                                                                            return () => getters[RAW.baseCssVariableName]
                                                                    }),
                                                                    () => {
                                                                        if (getters.getSecondaryFieldsVariables[baseCssVariableName])
                                                                            getters.getSecondaryFieldsVariables[baseCssVariableName].value = callStateFn()
                                                                    }
                                                                )
                                                            })
                                                        }
                                                        return value
                                                    })()

                                                const insertRule = ({
                                                    [constants.FSP.GENERIC_FIELD_CLASS._FSPKeyName]: {
                                                        class: genericFieldConnector = ''
                                                    } = {},
                                                    ['field']: fieldConnector = '',
                                                    ['internal']: internalConnector = ''
                                                }) => {
                                                    generatedElementSelectors.forEach(elementSelector => {
                                                        const cssVariableName = constants.FSP.SECONDARY_VARIABLE_NAME_COMPOSER({
                                                            parentKey: FSP_FIELD_TYPE,
                                                            elementKey: elementSelector,
                                                            propertyKey,
                                                            stateKey
                                                        })

                                                        if (cssVariableName !== baseCssVariableName)
                                                            finalVariables[cssVariableName] = {value: `var(${baseCssVariableName})`}

                                                        const rule = `.${constants.FSP.VARIABLES_INJECTOR_CLASS(getters[PathableUtils.namespaceGetterName])} .generic-field${genericFieldConnector} .${FIELD_VIEW_CLASS}${fieldConnector} ${internalConnector} ${elementSelector} {${propertyKey}: var(${baseCssVariableName}) ${important ? '!important' : ''};}`
                                                        sheet.insertRule(rule, (sheet.cssRules || sheet.rules).length)
                                                        finalVariables[cssVariableName].rule = rule
                                                    })


                                                }

                                                insertRule(connector)
                                            })
                                    })
                            })
                    })

                commit('SET_SECONDARY_FIELDS_VARIABLES', finalVariables)
            }
        },
        getters: {
            getStylePreferences: ({parentGetters}) => parentGetters[stylePreferencesGetterName] || {},

            ...(() => {
                const composedPrimaryVariables = {}
                const getterStylePrimaryVariables = Object.fromEntries(
                    constants.FSP.ALL_PRIMARY_VARIABLES_ARRAY
                        .map(({GETTERS, COMPOSED_NAMES}) => Object.entries(GETTERS)
                            .map(([getterKey, {
                                name: getterName
                            }]) => {
                                composedPrimaryVariables[COMPOSED_NAMES[getterKey]] = getterName

                                return [getterName, ({getters}) => getters.getPrimaryVariables[getterName]?.value]
                            }))
                        .flat()
                )

                return {
                    getComposedPrimaryVariables: ({getters}) => Object.fromEntries(
                        Object.entries(composedPrimaryVariables)
                            .map(([composedVarGetterName, composedVarInitialGetterName]) => [composedVarGetterName, getters[composedVarInitialGetterName]])
                    ),
                    ...getterStylePrimaryVariables
                }
            })(),


            getUniqueFieldsFSPDefinitions: ({parentGetters}) => _.uniqWith(
                parentGetters[FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName]
                    .filter(({fieldConst: {FSP}}) => FSP)
                    .map(({fieldConst: {FSP}}) => FSP)
                    .flat(Infinity),
                (a, b) => a.FSP_FIELD_TYPE === b.FSP_FIELD_TYPE
            ),
            getComposedSecondaryFieldsVariables: ({getters}) => Object.fromEntries(
                Object.entries(getters.getSecondaryFieldsVariables)
                    .map(([name, {value: ccc}]) => [name, ccc])
            ),
        }
    }
}