var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "submissions-analytics__content"
  }, [!_vm.getSubmissionsInitiallyFetched ? _c('f-circle-loader', {
    attrs: {
      "size": "5"
    }
  }) : [_vm.areSubmissionsExist ? [_vm.getCurrentAllResponsesView === _vm.getAllResponsesViews.TABLE ? _c('TableView', {
    attrs: {
      "search-query": _vm.searchQuery,
      "formatted-submissions": _vm.formattedSubmissions
    },
    model: {
      value: _vm.selectedSubmissions,
      callback: function ($$v) {
        _vm.selectedSubmissions = $$v;
      },
      expression: "selectedSubmissions"
    }
  }) : _vm.getCurrentAllResponsesView === _vm.getAllResponsesViews.SPLITTED ? _c('SplittedView', {
    attrs: {
      "search-query": _vm.searchQuery,
      "formatted-submissions": _vm.formattedSubmissions,
      "get-is-submissions-list-extending-in-progress": _vm.getIsSubmissionsListExtendingInProgress
    },
    model: {
      value: _vm.selectedSubmissions,
      callback: function ($$v) {
        _vm.selectedSubmissions = $$v;
      },
      expression: "selectedSubmissions"
    }
  }) : _vm._e()] : _c('NoResponses')]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }