<template>
  <SharedView
      editable
      :address-fields="fieldProperties.full_address_fields"
      :placeholders="fieldConst.UTIL.placeholders"
  />
</template>

<script>
import editable_field_view_mixin
  from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

export default {
  name: 'Editable__Address',
  mixins: [editable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  },
}
</script>