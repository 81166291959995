<template>
    <component :is="renderer"/>
</template>

<script>
    import store from '@/store'
    import form_submit_store from '@/store/form_submit/form_submit_store'

    import { useVuelidate } from '@vuelidate/core'
    import { provide, computed } from '@vue/composition-api'

    import FormSubmitProgressBar from '@/components/FormSubmit/ProgressBar/FormSubmitProgressBar'
    import StaticFormSubmit from '@/components/FormSubmit/StaticForm/StaticFormSubmit'
    import InteractiveFormSubmit from '@/components/FormSubmit/InteractiveForm/InteractiveFormSubmit'
    import FSPVariablesInjector from '@/FSP/FSPVariablesInjector'
    import FormslyFormWrapper from '@/components/FormGenericComponents/FormslyFormWrapper'
    import MiniLogoComponent from '@/components/Elements/MiniLogoComponent'
    import { VFadeTransition } from 'vuetify/lib/components'
    import { useMapGetters } from '@/xstore'

    export default {
        name: "FormSubmit",
        props: {
            formId: String,
            storeModulePath: {
                type: String,
                default: 'submit',
            },
            embedded: {
                type: Boolean,
                default: false
            },
        },
        setup(props, {root}) {
            const formSubmitStoreModulePath = props.storeModulePath
            provide('formSubmitStoreModulePath', formSubmitStoreModulePath)

            if (!props.embedded && !('submit/' in store._modulesNamespaceMap)) {
                store.registerModule(['submit'], form_submit_store)
                store.dispatch('submit/fetchFormSourceJSON', props.formId)
            }

            const $v = useVuelidate(
                computed(() => root.$store.getters[`${formSubmitStoreModulePath}/fields_validation/getVuelidateRules`]),
                computed(() => root.$store.getters[`${formSubmitStoreModulePath}/fields_validation/getVuelidateState`]),
                {
                    $autoDirty: true,
                    $lazy: true,
                    $rewardEarly: true
                }
            )


            provide('fieldsValidation', $v)

            root.$store.dispatch(`${formSubmitStoreModulePath}/fields_validation/registerVuelidateInstance`, $v)

            return {
                $v,
                ...useMapGetters(formSubmitStoreModulePath, [
                    'getFormSourceJSON',
                    'isMainPage',
                    'getCurrentPageID'
                ]),
                formSubmitStoreModulePath
            }
        },
        computed: {
            renderer() {
                return  {
                    functional: true,
                    render: h => {
                        const content = h(
                            FSPVariablesInjector,
                            {props: {fspModuleLocationPath: this.formSubmitStoreModulePath + '/FSP'}},
                            [
                                ...((this.isMainPage && this.isInteractiveForm) ? [h(FormSubmitProgressBar)] : []),
                                h(VFadeTransition, {props: {mode: 'out-in'}}, [
                                    ...((!this.isStaticForm && !this.isInteractiveForm) ? [h('f-linear-loader', {}, [h(MiniLogoComponent)])] : []),
                                    ...(this.isStaticForm ? [h(StaticFormSubmit)] : []),
                                    ...(this.isInteractiveForm ? [h(InteractiveFormSubmit)] : []),
                                ])
                            ]
                        )

                        if (this.embedded)
                            return content

                        return h(FormslyFormWrapper, {}, [content])
                    }
                }
            },

            isStaticForm() {
                return this.getFormSourceJSON?.form_type === this.constants.FORM_TYPES.STATIC_FORM.FORM_TYPE
            },
            isInteractiveForm() {
                return this.getFormSourceJSON?.form_type === this.constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE
            },
        },
    }
</script>