<template>
  <div class="submissions-toolbar-actions">
    <template v-if="isSelected">
      <f-hover-tooltip top
                       text="Delete submissions"
                       v-slot="{on}"
      >
        <v-btn v-on="on" class="v-btn--error v-btn--thin-icon" icon :disabled="isAnyLoading"
               @click="$emit('delete-selected')">
          <TrashIcon/>
        </v-btn>
      </f-hover-tooltip>
    </template>
    <template v-else>
      <SearchBarComponent
          :value="value"
          :disabled="isAnyLoading"
          @input="$emit('input', $event)"
      />
      <f-hover-tooltip top
                       text="Refresh"
                       v-slot="{on}"
      >
        <v-btn v-on="on" class="v-btn--primary v-btn--thin-icon" icon :disabled="isAnyLoading"
               @click="refreshSubmissions">
          <RefreshCcwIcon/>
        </v-btn>
      </f-hover-tooltip>
      <f-hover-tooltip top
                       text="Table view"
                       v-slot="{on}"
                       v-if="getCurrentAllResponsesView === getAllResponsesViews.SPLITTED"
      >
        <v-btn
            v-on="on"
            class="v-btn--primary v-btn--thin-icon" icon :disabled="isAnyLoading"
            @click="$store.dispatch('submissions/setCurrentAllResponsesView', getAllResponsesViews.TABLE)"
        >
          <GridIcon/>
        </v-btn>
      </f-hover-tooltip>
      <v-btn
          v-else-if="getCurrentAllResponsesView === getAllResponsesViews.TABLE"
          class="v-btn--primary v-btn--thin-icon" icon :disabled="isAnyLoading"
          @click="$store.dispatch('submissions/setCurrentAllResponsesView', getAllResponsesViews.SPLITTED)"
      >
        <ColumnsIcon/>
      </v-btn>
    </template>
    <v-menu
        offset-y
        nudge-left="55"
        nudge-bottom="10"
        min-width="240"
        transition="slide-y-transition"
    >
      <template #activator="{on}">
        <v-btn v-on="on"
               outlined
               rounded
               :disabled="isAnyLoading"
               class="v-btn--transparent-with-icon v-btn--text-hoverable ml-2"
        >
          Export
          <v-icon class="ml-2" size="18">
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template>
        <ActionsList :selected-submissions-for-csv="selectedSubmissionsForCsv"
                     :is-selected="isSelected"
                     :selected-submission-title="selectedSubmissionTitle"
                     :submissions-for-csv="submissionsForCsv"

        />
      </template>
    </v-menu>
  </div>
</template>

<script>
import {mapGetters} from '@/xstore'
import {ColumnsIcon, GridIcon, RefreshCcwIcon, TrashIcon} from 'vue-feather-icons'

export default {
  name: "SubmissionsToolbarActionsComponent",
  components: {
    RefreshCcwIcon,
    ColumnsIcon,
    TrashIcon,
    GridIcon,
    ActionsList: () => import('@/components/SubmissionsAnalytics/ActionsList'),
    SearchBarComponent: () => import('@/components/Elements/SearchBarComponent')
  },
  props: {
    isSelected: {
      type: Boolean,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    selectedSubmissionsForCsv: {
      type: Array,
      required: true,
    },
    selectedSubmissionTitle: {
      type: String,
      required: true,
    },
    submissionsForCsv: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters('submissions', [
      'isAnyLoading',
      'getCurrentAllResponsesView',
      'getAllResponsesViews'
    ])
  },
  methods: {
    refreshSubmissions() {
      this.$store.dispatch('submissions/fetchAnalytics', this.$route.params.formId)
    }
  }
}
</script>

<style lang="scss" scoped>
.submissions-toolbar-actions {
  display: flex;
  align-items: center;
  min-height: 42px;
}
</style>