var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "disabled": "",
      "limit-file-size": _vm.fieldProperties.limit_file_size.max
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }