<template>
    <v-btn exact :to="dashboardPage" depressed text class="v-btn--text-hoverable">
        <ChevronLeftIcon class="v-btn__prepend-icon"/>
        <span>Back</span>
    </v-btn>
</template>

<script>
    export default {
        name: "BackToTypeSelectionComponent",
        components: {
            ChevronLeftIcon: () => import('vue-feather-icons/icons/ChevronLeftIcon')
        },
        computed: {
            dashboardPage() {
                return {name: this.constants.PAGE_NAME_DASHBOARD}
            },
        }
    }
</script>