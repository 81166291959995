<template>
    <v-dialog
            v-model="dialog"
            width="650"
            :persistent="isLoading"
            @click:outside="closeDialog"
    >
        <template #activator="{ on }">
            <v-btn
                    v-on="on"
                    class="font-weight-medium"
                    color="grey_900"
                    text
            >Change
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Change Your password
                <v-spacer/>
                <v-btn
                    icon
                    :disabled="isLoading"
                    @click="closeDialog"
                ><XIcon/></v-btn>
            </v-card-title>
            <v-card-text>
                <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent
                >
                    <p class="v-card__subheader">Current:</p>
                    <v-text-field
                            v-model="currentPassword"
                            :type="showCurrentPassword ? 'text' : 'password'"
                            :rules="[
                                v => !!v || 'Current password is required',
                                v => v !== null && v.length >= constants.MIN_PASS_LENGTH || 'Incorrect password'
                            ]"
                            :error-messages="currentPasswordErrors"
                            hide-details="auto"
                            validate-on-blur
                            outlined
                            @keydown="() => currentPasswordErrors = []"
                    >
                        <template #append>
                            <EyeIcon
                                    v-if="!showCurrentPassword"
                                    @click="showCurrentPassword = true"
                                    class="cursor-pointer"
                                    size="16"
                            />
                            <EyeOffIcon
                                    v-else
                                    @click="showCurrentPassword = false"
                                    class="cursor-pointer"
                                    size="16"
                            />
                        </template>
                    </v-text-field>

                    <p class="v-card__subheader">New:</p>
                    <v-text-field
                            v-model="newPassword"
                            :rules="[
                                v => !!v || 'New password is required',
                                v => v !== null && v.length >= constants.MIN_PASS_LENGTH || `Make it more than ${constants.MIN_PASS_LENGTH} characters for extra security`
                            ]"
                            type="password"
                            hide-details="auto"
                            validate-on-blur
                            outlined
                    />

                    <p class="v-card__subheader">Confirm:</p>
                    <v-text-field
                            v-model="confirmPassword"
                            :rules="[newPassword === confirmPassword || 'Passwords must match']"
                            type="password"
                            hide-details="auto"
                            validate-on-blur
                            outlined
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                        color="gray_100"
                        class="mr-5 px-7"
                        depressed
                        rounded
                        :disabled="isLoading"
                        @click="closeDialog"
                >
                    Cancel
                </v-btn>
                <v-btn
                        color="blue_400"
                        class="px-7"
                        depressed
                        rounded
                        :loading="isLoading"
                        @click="updatePassword"
                >
                    Update Password
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


    export default {
        name: "ChangePasswordModal",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
            EyeIcon: () => import('vue-feather-icons/icons/EyeIcon'),
            EyeOffIcon: () => import('vue-feather-icons/icons/EyeOffIcon'),
        },
        data: () => ({
            dialog: false,

            valid: true,
            isLoading: false,

            currentPassword: '',
            currentPasswordErrors: [],
            showCurrentPassword: false,
            newPassword: '',
            confirmPassword: '',
        }),
        methods: {
            closeDialog() {
              this.dialog = false
              this.$refs.form.reset()
            },
            updatePassword() {
              if (this.$refs.form.validate())
                this.promiseWithVariableToggler(
                    'isLoading',
                    this.AuthActions.changePassword(this.currentPassword, this.newPassword)
                        .then(() => {
                          this.closeDialog()
                        })
                        .then(() => this.snackbarEventBus.$emit('snackbar', {
                          emoji: this.constants.EMOJI.HANDS_UP,
                          color: this.constants.SNACKBAR_CONSTANTS.COLORS.SECONDARY,
                          text: 'Your password successfully changed'
                        }))
                        .catch(e => {
                          if (e.code === 'NotAuthorizedException' || e.code === 'InvalidParameterException')
                            this.currentPasswordErrors.push('Incorrect password')
                          else
                            console.log(e)
                        })
                )
            }
        },
    }
</script>

<style scoped>

</style>