var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', _vm._g({
    ref: "input",
    attrs: {
      "placeholder": _vm.sourceObj.content.placeholder,
      "country-code": _vm.submissionObj.value.country,
      "mask": /^\d{0,11}$/
    },
    on: {
      "change-country": function (v) {
        return _vm.submissionObj.value.country = v;
      }
    },
    model: {
      value: _vm.submissionObj.value.phone,
      callback: function ($$v) {
        _vm.$set(_vm.submissionObj.value, "phone", $$v);
      },
      expression: "submissionObj.value.phone"
    }
  }, _vm.inputAutofocusOn));
}
var staticRenderFns = []

export { render, staticRenderFns }