<template>
    <div
            class="smart-paragraph-field-view__editable-variable-container"
            :class="focused && 'smart-paragraph-field-view__editable-variable-container--focused'"
            contenteditable="false"
            data-smart-paragraph-variable="true"
            :data-variable-id="finalId"
    >
        <div
                class="smart-paragraph-field-view__editable-variable-field"
                ref="variable"
                contenteditable="true"
                :class="placeholderShown && 'smart-paragraph-field-view__editable-variable-field--placeholder'"
                :data-placeholder="placeholderShown && 'Variable name'"

                @paste="processPaste"
                @input="input"
                @keydown="keydown"

                @focus="focus"
                @blur="blur"
        >{{variableName}}</div>
        <XIcon @click="removeVariable"/>
    </div>
</template>

<script>
    export default {
        name: "EditableVariableContainer",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            id: String,
            variableName: {
                type: String,
                default: ''
            },
            newCreated: Boolean
        },
        data: vm => ({
            placeholderShown: false,
            focused: false,
            generatedId: vm.Utils.generateUUID()
        }),
        mounted() {
            if (this.newCreated)
                setTimeout(() => this.$refs.variable.focus(), 10)
            if (!this.$refs.variable.innerText)
                this.placeholderShown = true
        },
        computed: {
            finalId() {
                return this.id || this.generatedId
            }
        },
        methods: {
            input() {
                this.placeholderShown = !this.$refs.variable.innerText
                if (this.$refs.variable.innerText.length >= 40) {
                    let selection = window.getSelection()
                    let offset = selection.focusOffset > 40 ? 40 : selection.focusOffset
                    this.$refs.variable.innerText = this.$refs.variable.innerText.slice(0, 40)
                    let range = document.createRange()
                    range.setStart(this.$refs.variable.firstChild, offset)
                    range.setEnd(this.$refs.variable.firstChild, offset)
                    selection.removeAllRanges()
                    selection.addRange(range)
                }
                this.$options.input()
            },
            focus() {
                this.focused = true
            },
            blur() {
                this.focused = false
            },
            keydown(e) {
                if (e.ctrlKey && e.keyCode === 65) {
                    e.preventDefault()
                    e.stopPropagation()

                    let selection = window.getSelection()
                    selection.removeAllRanges()
                    let range = document.createRange()
                    range.selectNodeContents(e.target)
                    selection.addRange(range)
                } else if (e.keyCode === 13) {
                    e.preventDefault()
                    e.stopPropagation()
                }
            },
            processPaste(e) {
                e.preventDefault()
                e.stopPropagation()
                let data = (e.originalEvent || e).clipboardData.getData('text/plain')
                if (data)
                    document.execCommand("insertText", false, data.replace('\n', ' '))
            },
            removeVariable() {
                this.$options.removeVariable(this.finalId)
            }
        }
    }
</script>