import { isFunction } from 'lodash'

export const CustomSBGenerator = (...rules) => {
    const generator = ({state: statePairs, varNameCustomizer}) => {
        const moduleExtension = {}

        const applyRulesForPair = (_variableName, variableValue) => rules.forEach(rule => {
            let variableName = _variableName
            if (isFunction(varNameCustomizer))
                variableName = varNameCustomizer(variableName)

            if (rule.category)
                Object.assign(moduleExtension[rule.category] || (moduleExtension[rule.category] = {}), {[rule.name(variableName, variableValue)]: rule.value(variableName, variableValue)})
        })
        Object.entries(statePairs)
            .forEach(pair => applyRulesForPair(...pair))

        return moduleExtension
    }

    generator.requiresStateArg = true

    return generator
}