var render = function () {
  var _vm$getFormSourceJSON, _vm$getFormSourceJSON2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    class: ['interactive-page-scroller', ((_vm$getFormSourceJSON = _vm.getFormSourceJSON) === null || _vm$getFormSourceJSON === void 0 ? void 0 : (_vm$getFormSourceJSON2 = _vm$getFormSourceJSON.all_pages[_vm.currentPageId]) === null || _vm$getFormSourceJSON2 === void 0 ? void 0 : _vm$getFormSourceJSON2.type) === _vm.constants.FORM_PAGES_TYPES.HOME_PAGE && 'interactive-page-scroller--homepage'],
    on: {
      "wheel": _vm.wheel,
      "touchmove": _vm.onTouchMove
    }
  }, [_vm.previousPageId ? _c('f-fluid-container', {
    style: _vm.previousPageStyle
  }, [_vm._t("default", null, {
    "pageId": _vm.previousPageId
  })], 2) : _vm._e(), _c('f-fluid-container', {
    ref: "currentPage",
    style: _vm.currentPageStyle
  }, [_vm._t("default", null, {
    "pageId": _vm.currentPageId
  })], 2), _vm.nextPageId ? _c('f-fluid-container', {
    style: _vm.nextPageStyle
  }, [_vm._t("default", null, {
    "pageId": _vm.nextPageId
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }