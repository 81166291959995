export class FieldConditionalLogic {
    constructor({
        EXPRESSION_COMPONENT_NAME,
        VALIDATE,
        PARAMS = {}
    }) {
        this.EXPRESSION_COMPONENT = () => import(`@/components/FormEditor/ConditionalLogic/ExpressionsComponents/${EXPRESSION_COMPONENT_NAME}`)
        this.VALIDATE = VALIDATE
        this.PARAMS = PARAMS
    }
}

export class GenericOptionsConditionalLogic extends FieldConditionalLogic {
    static DEFAULT_VALIDATE(conditionObj, {value}) {
        const operator = conditionObj.expression.operator
        const statement = conditionObj.expression.statement

        if (operator === 'matches') {
            if (!value)
                return false
            return statement.length === value.length && statement.every(s => value.includes(s))
        } else if (operator === 'matches_one_of') {
            if (!value)
                return false
            return statement.some(s => value.includes(s))
        } else if (operator === 'doesnt_match') {
            if (!value)
                return true
            return !(statement.length === value.length && statement.every(s => value.includes(s)))
        }
    }

    constructor({
        PARAMS,
        VALIDATE
    }) {
        super({
            EXPRESSION_COMPONENT_NAME: 'Options',
            VALIDATE: VALIDATE || GenericOptionsConditionalLogic.DEFAULT_VALIDATE,
            PARAMS
        })
    }
}

export class GenericStringConditionalLogic extends FieldConditionalLogic {
    static DEFAULT_VALIDATE(conditionObj, submissionObj) {
        const operator = conditionObj.expression.operator
        const statement = conditionObj.expression.statement
        const case_sensitive = conditionObj.expression.options.case_sensitive
        const submissionValue = submissionObj.value

        if (operator === 'equals') {
            if (!submissionValue)
                return false
            return statement.some(s => case_sensitive ? s === submissionValue : s.toLocaleLowerCase() === submissionValue.toLocaleLowerCase())
        } else if (operator === 'not_equals') {
            if (!submissionValue)
                return true
            return statement.every(s => case_sensitive ? s !== submissionValue : s.toLocaleLowerCase() !== submissionValue.toLocaleLowerCase())
        } else if (operator === 'matches') {
            if (!submissionValue)
                return false
            return RegExp(`(?<![\\d\\p{L}])(${statement.join('|')})(?![\\d\\p{L}])`, `gmu${case_sensitive ? '' : 'i'}`).test(submissionValue)
        } else if (operator === 'contains') {
            if (!submissionValue)
                return false
            return statement.some(s => case_sensitive ? submissionValue.includes(s) : submissionValue.toLocaleLowerCase().includes(s.toLocaleLowerCase()))
        } else if (operator === 'doesnt_contain') {
            if (!submissionValue)
                return true
            return statement.every(s => case_sensitive ? !submissionValue.includes(s) : !submissionValue.toLocaleLowerCase().includes(s.toLocaleLowerCase()))
        } else if (operator === 'starts_with') {
            if (!submissionValue)
                return false
            return statement.some(s => case_sensitive ? submissionValue.startsWith(s) : submissionValue.toLocaleLowerCase().startsWith(s.toLocaleLowerCase()))
        } else if (operator === 'ends_with') {
            if (!submissionValue)
                return false
            return statement.some(s => case_sensitive ? submissionValue.endsWith(s) : submissionValue.toLocaleLowerCase().endsWith(s.toLocaleLowerCase()))
        }
    }

    constructor({
        PARAMS: {
            REGEX = /.*/,
            CASE_SENSITIVE = true,
            ERROR_MESSAGE = '',
            } = {}
        } = {}) {
        super({
            EXPRESSION_COMPONENT_NAME: 'String',
            VALIDATE: GenericStringConditionalLogic.DEFAULT_VALIDATE,
            PARAMS: {
                REGEX,
                CASE_SENSITIVE,
                ERROR_MESSAGE
            }
        })
    }
}

export class GenericNumberConditionalLogic extends FieldConditionalLogic {
    static DEFAULT_VALIDATE(conditionObj, submissionObj) {
        const operator = conditionObj.expression.operator
        const statement = conditionObj.expression.statement
        const submissionValue = submissionObj.value

        if (operator === 'equals') {
            if (!submissionValue)
                return false
            return submissionValue === statement
        } else if (operator === 'not_equals') {
            if (!submissionValue)
                return true
            return submissionValue !== statement
        } else if (operator === 'greater_than') {
            if (!submissionValue)
                return false
            return submissionValue > statement
        } else if (operator === 'lower_than') {
            if (!submissionValue)
                return false
            return submissionValue < statement
        } else if (operator === 'greater_or_equal') {
            if (!submissionValue)
                return false
            return submissionValue >= statement
        } else if (operator === 'lower_or_equal') {
            if (!submissionValue)
                return false
            return submissionValue <= statement
        }
    }

    constructor({
        VALIDATE,
        PARAMS: {
            ALLOWED_RANGE,
        } = {}
    } = {}) {
        super({
            EXPRESSION_COMPONENT_NAME: 'Number',
            VALIDATE: VALIDATE || GenericNumberConditionalLogic.DEFAULT_VALIDATE,
            PARAMS: {
                ALLOWED_RANGE: ALLOWED_RANGE || (() => false),
            }
        })
    }
}