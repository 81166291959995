var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isEnabled ? _c('v-progress-linear', {
    attrs: {
      "absolute": "",
      "height": "8",
      "buffer-value": 100,
      "value": _vm.currentProgress,
      "background-color": "none",
      "background-opacity": "none",
      "color": "none"
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }