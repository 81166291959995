<template>
    <v-dialog
            :value="getMonitoringFeaturesDialogModel"
            max-width="850"
            @input="v => v ? null : closeDialog()"
    >
        <v-card>
            <div class="dialog-pro-offer">
                <XIcon class="dialog-pro-offer-x-icon" @click="closeDialog"/>
                <div class="dialog-pro-offer__advertising">
                    <div class="dialog-pro-offer__advertising-logo">
                        <LogoComponent/>
                    </div>
                    <div class="dialog-pro-offer__advertising-header">
                        <h2 class="dialog-pro-offer__advertising-header-title">Build Forms like a real Pro.</h2>
                        <h3 class="dialog-pro-offer__advertising-header-subtitle">High-performing teams win work. Build
                            awesome form with rich endless possibilities</h3>
                    </div>
                </div>
                <div class="dialog-pro-offer__services">
                    <div class="dialog-pro-offer__services-header">
                        <h2 class="dialog-pro-offer__services-header-title">{{getMonitoringFeaturesDialogTitle}}</h2>
                        <h3 class="dialog-pro-offer__services-header-subtitle">{{getMonitoringFeaturesDialogSubtitle}}</h3>
                    </div>
                    <div class="dialog-pro-offer__services-details">
                        <div class="dialog-pro-offer__services-details-item" v-for="(service, index) in services"
                             :key="index">
                            <div class="dialog-pro-offer__services-details-item-icon">
                                <component :is="service.icon"/>
                            </div>
                            <div class="dialog-pro-offer__services-details-item-info">
                                <span class="dialog-pro-offer__services-details-item-info-name">
                                    {{service.name}}
                                </span>
                                <span class="dialog-pro-offer__services-details-item-info-description">
                                    {{service.description}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dialog-pro-offer__services-action">
                        <v-btn rounded dark @click="routeToCheckout">
                            See Pro Pricing
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: 'ProOfferDialog',
        components: {
            LogoComponent: () => import('@/components/Elements/LogoComponent'),
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
        },
        data: () => ({
            services: [
                {
                    name: 'More responses, unlesh potential',
                    description: 'Unlock potential with increased responses on paid plan.',
                    icon: () => import('vue-feather-icons/icons/UsersIcon'),
                },
                {
                    name: 'Paid features, on steroid',
                    description: 'Power up your forms and survey with powerful features.',
                    icon: () => import('vue-feather-icons/icons/ZapIcon'),
                },
                {
                    name: 'Premium Themes',
                    description: 'Get premium support with our paid plan for quick assistance.',
                    icon: () => import('vue-feather-icons/icons/AwardIcon'),
                },
                {
                    name: 'Premium support',
                    description: 'Carefully selected and designed templates.',
                    icon: () => import('vue-feather-icons/icons/StarIcon'),
                },
            ],
        }),
        methods: {
            routeToCheckout() {
                this.closeDialog();
                this.$router.push({name: this.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN});
            },
            closeDialog() {
                this.$store.dispatch('user/plan/current_subscription/monitoring_features/monitoring_features_dialog/closeDialog');
            },

        },
        computed: {
            ...mapGetters('user/plan/current_subscription/monitoring_features/monitoring_features_dialog', [
                'getMonitoringFeaturesDialogModel',
                'getMonitoringFeaturesDialogTitle',
                'getMonitoringFeaturesDialogSubtitle'
            ])
        }
    }
</script>

<style scoped lang="scss">
    .dialog-pro-offer {
        display: flex;
        position: relative;

        &-x-icon {
            position: absolute;
            top: 25px;
            right: 25px;
            color: #8A8F99;
            stroke-width: 1px;
            cursor: pointer;
        }

        &__advertising {
            flex: 1 1 40%;
            padding: 40px;
            background-image: url("../../../public/images/ProOfferDialogImage.svg");
            border-radius: 20px 0 0 20px;

            &-logo {
                margin-bottom: 70px;
            }

            &-header {
                &-title {
                    font-size: 26px;
                    line-height: 31px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                &-subtitle {
                    font-size: 16px;
                    color: var(--v-gray_400-base);
                    font-weight: 400;
                }
            }
        }

        &__services {
            flex: 1 1 60%;
            padding: 50px;

            &-header {
                margin-bottom: 40px;

                &-title {
                    font-weight: 500;
                    font-size: 18px;
                    margin-bottom: 8px;
                }

                &-subtitle {
                    font-size: 15px;
                    color: #767676;
                    font-weight: 400;
                }
            }

            &-details {
                margin-bottom: 45px;

                &-item {
                    display: flex;
                    column-gap: 25px;
                    align-items: center;
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &-icon {
                        color: #BA65FD;
                    }

                    &-info {
                        display: flex;
                        flex-direction: column;

                        &-name {
                            font-size: 15px;
                            font-weight: 500;
                        }

                        &-description {
                            font-size: 13px;
                            color: #767676;
                        }
                    }
                }
            }

            &-action {
                .v-btn {
                    margin: 0 auto;
                    display: block;
                    background: linear-gradient(266.04deg, #2FBBEB 22.52%, #BA65FD 87.84%) !important;
                    padding: 10px 85px !important;
                }
            }
        }
    }
</style>