<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="address-field-view-summary">
                <div
                        v-for="({submissionObj: {value}}, index) in filteredSubmissions"
                        class="address-field-view-summary__row-wrapper"
                        :key="index"
                >
                    <f-country-flag
                            v-if="value.country"
                            :code="value.country"
                            size="l"
                    />
                    <span>{{Object.entries(value).filter(item => item[0] !== 'country' && item[1]).map(item => item[1]).join(', ')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__Address",
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => Object.values(value).filter(item => item).length)
            },
        },
    }
</script>