var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-template-data-transfer', {
    attrs: {
      "imageLoaded": false,
      "errorWhileLoading": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var dataStash = _ref.dataStash;
        return [_vm.logoUrl && !dataStash.errorWhileLoading ? _c('div', {
          staticClass: "static-form-logo-badge"
        }, [_c('div', {
          staticClass: "static-form-logo-badge__background"
        }, [_c('v-img', {
          staticClass: "static-form-logo-badge__image",
          attrs: {
            "src": _vm.logoUrl
          },
          on: {
            "error": function ($event) {
              dataStash.errorWhileLoading = false;
            },
            "load": function ($event) {
              dataStash.imageLoaded = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('f-circle-loader', {
                attrs: {
                  "size": "3"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }