var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.enabled,
      "always-enabled": _vm.alwaysEnabled
    },
    scopedSlots: _vm._u([_vm.$slots.default ? {
      key: "header",
      fn: function () {
        return [_c('FormParametersHeader', {
          attrs: {
            "always-enabled": _vm.alwaysEnabled,
            "allowedOnlyWithPayment": _vm.allowedOnlyWithPayment
          },
          model: {
            value: _vm.enabled,
            callback: function ($$v) {
              _vm.enabled = $$v;
            },
            expression: "enabled"
          }
        }, [_vm._t("default")], 2)];
      },
      proxy: true
    } : null, {
      key: "extension",
      fn: function () {
        return [_vm._t("input")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }