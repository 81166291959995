import { isArray } from 'lodash'

/**
 * Emits the passed events to parent component
 * @example
 * v-bubble.eventname
 * @example
 * v-bubble="'event-name'"
 * @example
 * v-bubble="['event-name']"
 */

export const Bubble = (el, {value, modifiers}, {context, componentInstance}) => {
    const events = [...Object.keys(modifiers), ...(isArray(value) ? value : (value ? [value] : []))]

    if (!componentInstance || !context || !events.length)
        return

    events.forEach(evt => {
        evt = evt.trim()

        if (componentInstance._events?.[evt])
            componentInstance._events[evt].forEach(fn => {
                if (fn?.name === 'callBubbleListener')
                    componentInstance.$off(evt, fn)
            })

        function callBubbleListener(e) {
            context.$emit.apply(context, [evt, e])
        }

        componentInstance.$on(evt, callBubbleListener)
    })
}

export default Bubble