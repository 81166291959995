var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full pa-0",
    class: _vm.getIsFormLoadingScreenShown && 'h-100vh-without-appbar overflow-hidden',
    attrs: {
      "fluid": ""
    }
  }, [_c('FormslyFormWrapper', {
    attrs: {
      "width": "1920"
    }
  }, [_c('FSPVariablesInjector', {
    attrs: {
      "fsp-module-location-path": "edit/FSP"
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.getIsFormLoadingScreenShown ? _c('LoadingFormScreen') : _vm._e()], 1), _vm.getFormJSON ? [_vm.getOverlay ? _c('v-overlay', {
    attrs: {
      "value": _vm.getOverlay,
      "z-index": "15"
    }
  }) : _vm._e(), _vm.getFormJSON.form_type === _vm.constants.FORM_TYPES.STATIC_FORM.FORM_TYPE ? _c('StaticFormEditor') : _vm.getFormJSON.form_type === _vm.constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE ? _c('InteractiveFormEditor') : _vm._e()] : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }