var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-left": "110",
      "nudge-bottom": "10",
      "min-width": "240",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "form-preview-card__actions-btn",
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('svg', {
          attrs: {
            "width": "12",
            "height": "2",
            "viewBox": "0 0 12 2",
            "fill": "currentColor",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('circle', {
          attrs: {
            "cx": "1",
            "cy": "1",
            "r": "1"
          }
        }), _c('circle', {
          attrs: {
            "cx": "6",
            "cy": "1",
            "r": "1"
          }
        }), _c('circle', {
          attrs: {
            "cx": "11",
            "cy": "1",
            "r": "1"
          }
        })])])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": {
        name: _vm.constants.PAGE_NAME_FORM_EDITOR,
        params: {
          formId: _vm.formData.id
        }
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Edit")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "to": {
        name: _vm.constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
        params: {
          formId: _vm.formData.id
        }
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Responses")])], 1)], 1), _c('RenameFormModal', {
    attrs: {
      "form-data": _vm.formData
    }
  }), _c('v-list-item', {
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.duplicateForm
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Duplicate")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "disabled": !_vm.isFormOpenForSubmissions
    },
    on: {
      "click": function ($event) {
        _vm.isFormOpenForSubmissions && _vm.$emit('share');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Share")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "disabled": !_vm.isFormOpenForSubmissions,
      "to": {
        name: _vm.constants.PAGE_NAME_FORM_SUBMIT,
        params: {
          formId: _vm.formData.id
        }
      },
      "target": "_blank"
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("View")])], 1)], 1), _c('v-divider'), _c('DeleteFormModal', {
    attrs: {
      "form-data": _vm.formData
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }