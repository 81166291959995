<template>
    <input
            ref="input"
            type="file"
            style="display: none"
            :multiple="multiple"
            :accept="accept"

            @change="input"
    />
</template>

<script>
    import file_inputs_validator_mixin from '@/components/Elements/FileInputs/file_inputs_validator_mixin'

    export default {
        name: "FileInput",
        mixins: [file_inputs_validator_mixin],
        data: () => ({
            accept: undefined,
            callback: undefined
        }),
        created() {
            this.fileInputEventBus.$on('trigger-input', this.triggerInput)
        },
        beforeDestroy() {
            this.fileInputEventBus.$off('trigger-input', this.triggerInput)
        },
        methods: {
            triggerInput({mimeTypes, extensions, maxFilesCount, maxFileSize, matchBothMimeAndExtension, callback}) {
                this.loadValidationParams({mimeTypes, extensions, maxFilesCount, maxFileSize: maxFileSize * 1024 * 1024, matchBothMimeAndExtension})

                this.accept = [...this._acceptMimeTypesList, ...this._acceptExtensionsList].join(', ')
                this.callback = callback

                this.$nextTick(() => this.$refs.input.click())
            },
            input(event) {
                let filesList = Array.from(event.target.files)

                if (!this.validate(filesList)) return

                if (this.callback)
                    this.callback(this.multiple ? filesList : filesList[0])

                for (let dataName of Object.keys(this.$data)) this[dataName] = undefined

                this.$refs.input.value = null
            }
        }
    }
</script>

<style scoped>

</style>