var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-composition-pages-tabs"
  }, [_vm.homePage ? _c('div', {
    staticClass: "page-tab-container page-tab-container--home",
    class: _vm.getFormJSON.home_page.page_id === _vm.getCurrentPageId && 'page-tab-container--active',
    on: {
      "click": function ($event) {
        return _vm.goToPage(_vm.getFormJSON.home_page.page_id);
      }
    }
  }, [_vm._t("home-page")], 2) : _vm._e(), _c('div', {
    class: ['form-composition-pages-tabs__main-pages-shadow', 'form-composition-pages-tabs__main-pages-shadow--top', ("form-composition-pages-tabs__main-pages-shadow--" + (_vm.wrapperTopShadow))]
  }), _c('div', {
    ref: "mainPagesWrapper",
    staticClass: "main-pages-wrapper thin-scrollbar",
    on: {
      "scroll": _vm.handleMainPagesContainerSize
    }
  }, [_c('div', {
    ref: "mainPagesContainer",
    staticClass: "main-pages"
  }, [_vm._l(_vm.enumeratedPages, function (pageData, key) {
    return [_c('v-hover', {
      key: pageData.randomKey,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var hover = _ref.hover;
          return [_c('div', {
            key: pageData.randomKey,
            ref: "pageTab",
            refInFor: true,
            staticClass: "page-tab-container page-tab-container--main page-tab-container--translate",
            class: [pageData.pageId === _vm.currentEditingPageId && 'page-tab-container--active', pageData.pageId === _vm.currentDraggablePageId && 'page-tab-container--draggable'],
            on: {
              "mousedown": function ($event) {
                return _vm.mouseDownOnMainPageTab($event, key, pageData.pageId);
              },
              "click": function ($event) {
                return _vm.goToPage(pageData.pageId);
              }
            }
          }, [_c('div', {
            staticClass: "page-tab-container page-tab-container--scale"
          }, [_vm._t("main-page", null, {
            "pageData": pageData,
            "hover": hover,
            "pageIndex": key,
            "isDragging": _vm.isDragging
          })], 2)])];
        }
      }], null, true)
    })];
  })], 2)]), _c('div', {
    class: ['form-composition-pages-tabs__main-pages-shadow', 'form-composition-pages-tabs__main-pages-shadow--bottom', ("form-composition-pages-tabs__main-pages-shadow--" + (_vm.wrapperBottomShadow))]
  }), _vm.finishPage ? _c('div', {
    staticClass: "page-tab-container page-tab-container--finish",
    class: _vm.getFormJSON.finish_page.page_id === _vm.getCurrentPageId && 'page-tab-container--active',
    on: {
      "click": function ($event) {
        return _vm.goToPage(_vm.getFormJSON.finish_page.page_id);
      }
    }
  }, [_vm._t("finish-page")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }