<template>
    <GenericField
            :modifiers="['interactive', 'submittable', `width-${fieldWidth}`, isErrorState && 'generic-field--error-state']"
            :field-id="fieldId"
            :field-obj="sourceObj"
            :field-type-const="fieldTypeConst"
    >
        <SubmittableFieldViewsGenerator
                :field-id="fieldId"
                :source-obj="sourceObj"
                :submission-obj="submissionObj"
        />
    </GenericField>
</template>

<script>
    import submittable_field_watcher_mixin from '@/components/FormSubmit/SubmittableFields/submittable_field_watcher_mixin'

    export default {
        name: "SubmittableInteractiveFieldConstructor",
        mixins: [submittable_field_watcher_mixin]
    }
</script>