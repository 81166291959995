<template>
    <v-progress-linear
            v-if="isEnabled"
            absolute
            height="8"
            :buffer-value="100"
            :value="currentProgress"
            background-color="none"
            background-opacity="none"
            color="none"
    />
</template>

<script>
    import { computed, inject } from '@vue/composition-api'

    export default {
        name: "FormSubmitProgressBar",
        setup(props, {root}) {
            return {
                isEnabled: computed(() => root.$store.getters[inject('formSubmitStoreModulePath') + '/progress_bar/isEnabled']),
                currentProgress: computed(() => {
                    return root.$store.getters[inject('formSubmitStoreModulePath') + '/progress_bar/getValue'] * 100
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-progress-linear {
        ::v-deep .v-progress-linear__background {
            background-color: rgba(0, 0, 0, 0.09);
        }

        ::v-deep .v-progress-linear__determinate {
            background-color: var(--FSP__Primary__ButtonBackgroundColor);
        }
    }
</style>