var render = function () {
  var _vm$getCurrentSubscri2, _vm$getCurrentSubscri3, _vm$getCurrentSubscri4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormParametersSubheader', [_vm._v("General Settings")]), 'progress_bar' in _vm.settingsObj.general_settings ? _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.settingsObj.general_settings.progress_bar
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('FormParametersHeader', {
          model: {
            value: _vm.settingsObj.general_settings.progress_bar,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.general_settings, "progress_bar", $$v);
            },
            expression: "settingsObj.general_settings.progress_bar"
          }
        }, [_vm._v(" Progress bar ")])];
      },
      proxy: true
    }], null, false, 2334515081)
  }) : _vm._e(), _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.settingsObj.general_settings.formsly_branding
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$getCurrentSubscri;
        return [_c('FormParametersHeader', {
          attrs: {
            "allowed-only-with-payment": !((_vm$getCurrentSubscri = _vm.getCurrentSubscriptionFeatures) !== null && _vm$getCurrentSubscri !== void 0 && _vm$getCurrentSubscri.disable_formsly_branding)
          },
          model: {
            value: _vm.settingsObj.general_settings.formsly_branding,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.general_settings, "formsly_branding", $$v);
            },
            expression: "settingsObj.general_settings.formsly_branding"
          }
        }, [_vm._v(" Formsly branding ")])];
      },
      proxy: true
    }])
  }), 'navigation_arrows' in _vm.settingsObj.general_settings ? _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.settingsObj.general_settings.navigation_arrows
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('FormParametersHeader', {
          model: {
            value: _vm.settingsObj.general_settings.navigation_arrows,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.general_settings, "navigation_arrows", $$v);
            },
            expression: "settingsObj.general_settings.navigation_arrows"
          }
        }, [_vm._v(" Navigation arrows ")])];
      },
      proxy: true
    }], null, false, 3574789161)
  }) : _vm._e(), 'auto_scroll' in _vm.settingsObj.general_settings ? _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.settingsObj.general_settings.auto_scroll
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('FormParametersHeader', {
          model: {
            value: _vm.settingsObj.general_settings.auto_scroll,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.general_settings, "auto_scroll", $$v);
            },
            expression: "settingsObj.general_settings.auto_scroll"
          }
        }, [_vm._v(" Auto scroll ")])];
      },
      proxy: true
    }], null, false, 3944523625)
  }) : _vm._e(), _c('FormSettingsOptionWithInput', {
    attrs: {
      "default-value": "",
      "allowed-only-with-payment": !((_vm$getCurrentSubscri2 = _vm.getCurrentSubscriptionFeatures) !== null && _vm$getCurrentSubscri2 !== void 0 && _vm$getCurrentSubscri2.redirect_upon_completion)
    },
    on: {
      "reset": function ($event) {
        return _vm.resetFieldState('redirectUponCompletionURL');
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "Add URL",
            "outlined": "",
            "error-messages": _vm.redirectUponCompletionErrors,
            "hide-details": "auto"
          },
          model: {
            value: _vm.settingsObj.general_settings.redirect_upon_completion,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.general_settings, "redirect_upon_completion", $$v);
            },
            expression: "settingsObj.general_settings.redirect_upon_completion"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.settingsObj.general_settings.redirect_upon_completion,
      callback: function ($$v) {
        _vm.$set(_vm.settingsObj.general_settings, "redirect_upon_completion", $$v);
      },
      expression: "settingsObj.general_settings.redirect_upon_completion"
    }
  }, [_vm._v(" Redirect on completion ")]), _c('FormParametersSubheader', [_vm._v("Notifications")]), _c('FormSettingsOptionNavigator', {
    on: {
      "navigate": _vm.openEmailNotifications
    }
  }, [_vm._v("Email notifications ")]), _c('FormParametersSubheader', [_vm._v("Publishing")]), _c('FormSettingsOptionWithInput', {
    attrs: {
      "allowed-only-with-payment": !((_vm$getCurrentSubscri3 = _vm.getCurrentSubscriptionFeatures) !== null && _vm$getCurrentSubscri3 !== void 0 && _vm$getCurrentSubscri3.auto_close_forms)
    },
    on: {
      "reset": function ($event) {
        return _vm.resetFieldState('closeDate');
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-menu', {
          attrs: {
            "min-width": "340",
            "max-width": "340",
            "close-on-content-click": false,
            "close-on-click": false,
            "offset-y": "",
            "value": _vm.isDateTimePickerOpened,
            "offset-overflow": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [on ? _c('v-text-field', {
                attrs: {
                  "value": _vm.readableDate,
                  "placeholder": "Add Date and time",
                  "outlined": "",
                  "readonly": "",
                  "error-messages": _vm.closeDateErrors,
                  "hide-details": "auto"
                },
                on: {
                  "click": function ($event) {
                    _vm.isDateTimePickerOpened = true;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "prepend-inner",
                  fn: function () {
                    return [_c('CalendarIcon')];
                  },
                  proxy: true
                }], null, true)
              }) : _vm._e()];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.settingsObj.publishing.close_date,
      callback: function ($$v) {
        _vm.$set(_vm.settingsObj.publishing, "close_date", $$v);
      },
      expression: "settingsObj.publishing.close_date"
    }
  }, [_vm._v(" Schedule end date ")]), _c('FormSettingsOptionWithInput', {
    attrs: {
      "default-value": 20,
      "allowed-only-with-payment": !((_vm$getCurrentSubscri4 = _vm.getCurrentSubscriptionFeatures) !== null && _vm$getCurrentSubscri4 !== void 0 && _vm$getCurrentSubscri4.limit_responses_number)
    },
    on: {
      "reset": function ($event) {
        return _vm.resetFieldState('responsesNumberLimit');
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "type": "number",
            "placeholder": "Add Number",
            "outlined": "",
            "error-messages": _vm.responsesNumberLimitErrors,
            "hide-details": "auto"
          },
          model: {
            value: _vm.settingsObj.publishing.responses_number_limit,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.publishing, "responses_number_limit", $$v);
            },
            expression: "settingsObj.publishing.responses_number_limit"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.settingsObj.publishing.responses_number_limit,
      callback: function ($$v) {
        _vm.$set(_vm.settingsObj.publishing, "responses_number_limit", $$v);
      },
      expression: "settingsObj.publishing.responses_number_limit"
    }
  }, [_vm._v(" Limit response number ")]), _c('FormSettingsOptionNavigator', {
    on: {
      "navigate": _vm.openEmbedForm
    }
  }, [_vm._v("Embed your form ")]), _c('FormParametersSubheader', [_vm._v("Access")]), _c('FormSettingsOptionTemplate', {
    attrs: {
      "value": _vm.settingsObj.access.open_to_responses
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('FormParametersHeader', {
          model: {
            value: _vm.settingsObj.access.open_to_responses,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.access, "open_to_responses", $$v);
            },
            expression: "settingsObj.access.open_to_responses"
          }
        }, [_vm._v(" Open to responses ")])];
      },
      proxy: true
    }])
  }), _c('FormSettingsOptionWithInput', {
    on: {
      "reset": function ($event) {
        return _vm.resetFieldState('accessPassword');
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "Add Password",
            "outlined": "",
            "error-messages": _vm.accessPasswordErrors,
            "hide-details": "auto"
          },
          model: {
            value: _vm.settingsObj.access.access_password,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.access, "access_password", $$v);
            },
            expression: "settingsObj.access.access_password"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.settingsObj.access.access_password,
      callback: function ($$v) {
        _vm.$set(_vm.settingsObj.access, "access_password", $$v);
      },
      expression: "settingsObj.access.access_password"
    }
  }, [_vm._v(" Require password ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }