<script>
    export default {
        name: "TemplateDataTransfer",
        data: vm => ({
            ...vm.$attrs
        }),
        mounted() {
            this.$emit('mounted', this)
        },
        methods: {
            wait(fn ,duration) {
                setTimeout(fn, duration)
            }
        },
        render() {
            return this.$scopedSlots.default({
                dataStash: this,
                promiseProcessor: (promise, variable = Object.keys(this.$data)[0]) => {
                    this[variable] = !this[variable]
                    return Promise.resolve(promise).finally(() => {
                        this[variable] = !this[variable]
                    })
                },
                console
            })
        }
    }
</script>