var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormParametersContentTemplate', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "form-settings-header"
        }, [_c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.emailNotificationsModel ? _c('ChevronLeftIcon', {
          key: "email-notifications-icon",
          staticClass: "cursor-pointer ml-n1",
          on: {
            "click": _vm.closeEmailNotifications
          }
        }) : _vm.embededCodeModel ? _c('ChevronLeftIcon', {
          key: "embeded-code-icon",
          staticClass: "cursor-pointer ml-n1",
          on: {
            "click": _vm.closeEmbededCode
          }
        }) : _c('SettingsIcon', {
          key: "general-settings-icon",
          staticClass: "ml-n1"
        })], 1), _c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.embededCodeModel ? _c('span', {
          key: "embeded-code-header"
        }, [_vm._v(" Embed your form ")]) : _vm.emailNotificationsModel ? _c('span', {
          key: "email-notifications-header"
        }, [_vm._v(" Email notifications ")]) : _c('span', {
          key: "general-settings-header"
        }, [_vm._v(" Settings ")])])], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "form-settings-content thin-scrollbar"
        }, [_c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.embededCodeModel ? _c('FormEmbededCode', {
          key: "embeded-code-content",
          attrs: {
            "settings-obj": _vm.settingsObj
          }
        }) : _vm.emailNotificationsModel ? _c('FormEmailNotificationsSettings', {
          key: "email-notifications-content",
          attrs: {
            "settings-obj": _vm.settingsObj
          }
        }) : _c('GeneralFormSettings', {
          key: "general-settings-content",
          attrs: {
            "settings-obj": _vm.settingsObj
          },
          on: {
            "open-email-notifications": _vm.openEmailNotifications,
            "open-enbed-form": _vm.openEmbededCode
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [!_vm.embededCodeModel ? _c('div', {
          staticClass: "form-settings-actions"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mr-5 px-7 background-light",
          attrs: {
            "depressed": "",
            "plain": "",
            "rounded": "",
            "large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "px-10",
          attrs: {
            "color": "blue_400",
            "dark": "",
            "depressed": "",
            "rounded": "",
            "large": ""
          },
          on: {
            "click": _vm.saveSettings
          }
        }, [_vm._v(" Save ")])], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }