<template>
    <AlertCircleIcon
            v-if="visibilityControlledByConditionalLogic"
            class="red_400--text mr-2"
            size="16"
    />
</template>

<script>
    import { mapGetters } from '@/xstore'

    export default {
        name: "FieldVisibilityControlledByConditionalLogicIcon",
        components: {
            AlertCircleIcon: () => import('vue-feather-icons/icons/AlertCircleIcon')
        },
        props: {
            fieldId: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('edit/conditional', ['getTargetedAndConditionedFields']),
            visibilityControlledByConditionalLogic() {
                return this.getTargetedAndConditionedFields[this.fieldId]?.targeted
            }
        }
    }
</script>