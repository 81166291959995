const EMOJI = {
  HANDS_UP: '🙌',
  WINKING_EYES: '😜',
  RAINBOW: '🌈',
  GIRL: '👩🏽‍🦱',
  TURTLE: '🐢',
  CRYING_FACE: '😥',
  PARTY: '🎉',
}

const COLORS = {
  SECONDARY: '#101A26',
  ERROR: '#F53131',
}

const DURATION = 3000

export default {
  COLORS,
  EMOJI,
  DURATION,
}

