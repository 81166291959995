import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            selected_product: undefined,
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    {
        const state = {
            selected_billing_period: undefined,
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }
    {
        const state = {
            selected_price: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }

    const checksBeforeInitialSelection = {
        subscription_fetched: false,
        plans_fetched: false,
        checkout_selected: false
    }

    return {
        actions: {
            setCheckBeforePriceInitialSelection: ({dispatch}, {key}) => {
                checksBeforeInitialSelection[key] = true

                if (Object.values(checksBeforeInitialSelection).every(Boolean))
                    dispatch('initialSelection')
            },

            initialSelection: async ({dispatch, getters, rootGetters}) => {
                await dispatch('setSelectedBillingPeriod', "year")

                await dispatch('setSelectedProduct', {
                    pro: 'business',
                    business: 'pro',
                }[rootGetters['user/plan/current_subscription/getCurrentSubscriptionPrice'].productID] || 'pro')

                await dispatch('setSelectedPrice', rootGetters['user/plan/getPlansObject'][getters.getSelectedProduct].prices[getters.getSelectedBillingPeriod].id)
            },
            setSelectedPrice: async ({commit, parentGetters, parentDispatch}, priceID) => {
                commit('SET_SELECTED_PRICE', priceID)

                if (parentGetters['navigation/getPlanConfirmed'])
                    parentDispatch('navigation/confirmPlan', false)

                return parentDispatch('calculateOrderSummary', {priceID})
            },
            setSelectedBillingPeriod: async ({commit, getters, rootGetters, dispatch}, billingPeriod) => {
                if (
                    !getters.getSelectedProduct === 'free' && (
                    rootGetters['user/plan/getPlansObject'][getters.getSelectedProduct]
                        && rootGetters['user/plan/getPlansObject'][getters.getSelectedProduct].prices[billingPeriod].id
                        === rootGetters['user/plan/current_subscription/getCurrentSubscriptionPrice'].id
                    )
                ) {
                    commit('SET_SELECTED_BILLING_PERIOD', billingPeriod)
                    await dispatch('setSelectedProduct', {
                        pro: 'business',
                        business: 'pro',
                    }[ rootGetters['user/plan/current_subscription/getCurrentSubscriptionPrice'].productID ] || 'pro')
                    await dispatch('setSelectedPrice', rootGetters['user/plan/getPlansObject'][getters.getSelectedProduct].prices[getters.getSelectedBillingPeriod].id)
                } else {
                    commit('SET_SELECTED_BILLING_PERIOD', billingPeriod)
                }
            }
        },
        getters: {
            getPlanSelectionDone: ({getters}) => [
                getters.getSelectedBillingPeriod,
                getters.getSelectedProduct,
                getters.getSelectedPrice
            ].every(v => v)
        }
    }
}
