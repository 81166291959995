var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "draggableField",
    staticClass: "static-editable-field-constructor static-editable-field-constructor--dragging-active",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('div', {
    staticClass: "static-editable-field-constructor__drag-hook"
  }, [_c('DragHook')], 1), _c(_vm.fieldConst.ICON_COMPONENT, {
    tag: "component",
    staticClass: "static-editable-field-constructor__field-type-icon",
    attrs: {
      "width": "36",
      "height": "36"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.fieldConst.READABLE_NAME))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }