<template> 
    <v-toolbar-items class="justify-center">
        <v-btn class="navigation-btn" exact :disabled="isLoading" text active-class="navigation-btn--active"
               :to="dashboardPage">
            <HomeIcon stroke-width="1.5"/>
            <span>Dashboard</span>
        </v-btn>
        <!--<v-btn class="navigation-btn" exact :disabled="isLoading" text active-class="navigation-btn--active"
               :to="'/template_selection/static'">
            <TrelloIcon stroke-width="1.5"/>
            <span>Templates</span>
        </v-btn>-->
    </v-toolbar-items>
</template>

<script>
    import {HomeIcon, TrelloIcon} from "vue-feather-icons";
    import {mapGetters} from '@/xstore'


    export default {
        name: "AppNavigationComponent",
        components: {
            HomeIcon,
            TrelloIcon
        },
        computed: {
            ...mapGetters(['isLoading']),

            dashboardPage() {
                return {name: this.constants.PAGE_NAME_DASHBOARD}
            },
            templatesPage() {
                return {name: this.constants.PAGE_NAME_FORM_TEMPLATE_SELECTION, params: 'static'}
            }

        },
    }
</script>