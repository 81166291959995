<template>
    <img v-if="light" src="/images/LogoLight.svg" :draggable="false" alt="logo" width="77" height="20"/>
    <img v-else src="/images/Logo.svg" :draggable="false" alt="logo" width="77" height="20"/>
</template>

<script>
    export default {
        name: "LogoComponent",
        props: {
            light: {
                type: Boolean,
                default: false
            }
        }
    }
</script>