<template>
  <div class="signature-field-view">
    <div :class="[
            'signature-field-view__layout-wrapper',
            editable && isEditingActive && 'signature-field-view__layout-wrapper--editing-active',
          ]"
    >
      <div :class="[
              !editable && !allowTyping && 'signature-field-view__layout-canvas-wrapper'
            ]"
      >
        <v-text-field
            :value="value"
            :placeholder="placeholder"
            background-color="transparent"
            flat
            solo
            dense
            hide-details
            :disabled="!allowTyping || preview"
            v-on="{
            ...$listeners
          }"
        />
        <slot name="pad"></slot>
        <v-divider/>
      </div>
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharedView__Signature',
  components: {},
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    allowTyping: {
      type: Boolean,
      default: true,
    },
    isEditingActive: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>