<template>
    <div
            class="lottie-icon-container"
            ref="lottieContainer"
    />
</template>

<script>
    import lottie from 'lottie-web'

    export default {
        name: "LottieIconPlayer",
        props: {
            json: {
                type: Function,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            animation: undefined
        }),
        watch: {
            active(v) {
                if (v) {
                    this.animation.play()
                } else
                    this.animation.stop()
            }
        },
        async mounted() {
            const {default: json} = await this.json()
            this.animation = lottie.loadAnimation({
                container: this.$refs.lottieContainer,
                renderer: 'svg',
                animationData: json,
                autoplay: false,
                loop: false
            })

            if (this.active)
                this.animation.goToAndStop(json.op - 1, true)
        },
        beforeDestroy() {
            this.animation.destroy()
        }
    }
</script>

<style lang="scss" scoped>
    .lottie-icon-container {
        display: flex;
    }
</style>