var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-parameters-option"
  }, [_vm._t("header"), _vm.alwaysEnabled || _vm.value && _vm.$slots.extension ? _c('div', {
    staticClass: "mt-2"
  }, [_vm._t("extension")], 2) : _vm._e(), _vm._t("hint")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }