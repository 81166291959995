import { onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api'
import { stringify } from 'csv-stringify/lib/sync'
import { FastAverageColor } from 'fast-average-color'
import _ from 'lodash'

import constants from '@/constants/constants'
import store from '@/store/index'
import StorageActions from '@/utils/actions/storage_actions'

const deepCloneObject = require('lodash/cloneDeep')

const createCsvFromArray = array => {
  const csvContent = stringify(array)
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
  return URL.createObjectURL(blob)
}

const getAbsoluteBoundingRect = element => {
  const boundingClientRect = element.getBoundingClientRect()
  return {
    top: boundingClientRect.top + window.scrollY,
    bottom: boundingClientRect.bottom + window.scrollY,
    left: boundingClientRect.left + window.scrollX,
    right: boundingClientRect.right + window.scrollX,
    height: boundingClientRect.height,
    width: boundingClientRect.width,
  }
}

const getFixedBoundingRect = element => {
  const boundingClientRect = element.getBoundingClientRect()
  return {
    top: boundingClientRect.top,
    bottom: boundingClientRect.bottom,
    left: boundingClientRect.left,
    right: boundingClientRect.right,
    height: boundingClientRect.height,
    width: boundingClientRect.width,
  }
}

const isCursorInsideBoundingRect = (left, top, bounding) => {
  return (
    left >= bounding.left &&
    left <= bounding.right &&
    top >= bounding.top &&
    top <= bounding.bottom
  )
}

const stripHtml = string => {
  const elem = document.createElement('span')
  elem.innerHTML = string
  return elem.textContent
}

const getFileExtension = fileName => {
  return fileName.slice(
    (Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1
  )
}

const trimFileExtension = fileName => {
  const extension = getFileExtension(fileName)
  const indexOfExtension = fileName.lastIndexOf(`.${extension}`)
  return fileName.slice(0, indexOfExtension)
}

const convertBytesToMb = bytes => {
  const mb = +(Math.round(bytes / (1024 * 1024) + 'e+1') + 'e-1')
  return mb === 0 ? 0.1 : mb
}

const convertBytesToKb = bytes => {
  const kb = +(Math.round(bytes / 1024 + 'e+1') + 'e-1')
  return kb === 0 ? 0.1 : kb
}

// if >= 1mb -> to mb. if < 1mb -> to kb
const convertBytesToReadable = bytes => {
  return bytes >= 1024 * 1024
    ? convertBytesToMb(bytes) + 'mb'
    : convertBytesToKb(bytes) + 'kb'
}

const getFileFolderPath = fileName => {
  return fileName.slice(
    0,
    (Math.max(0, fileName.lastIndexOf('/')) || Infinity) + 1
  )
}

const getFileNameFromPath = path => {
  return path.substring(path.lastIndexOf('/') + 1)
}

const generateUUID = length =>
  [...Array(length || 1)]
    .map(() =>
      ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    )
    .join('-')

const generateShortId = () => {
  let f = c => {
    let r = (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
    if (isNaN(parseInt(r)))
      return crypto.getRandomValues(new Uint32Array(1))[0] * Math.pow(2, -32) >
        0.5
        ? r.toLocaleUpperCase()
        : r
    return r
  }
  return Array(6)
    .fill(null)
    .map(() => f(1))
    .join('')
}

const subscribeMutation = function (callback) {
  const mutationsList = Array.prototype.slice.call(arguments, 1)

  return store.subscribe(mutation => {
    if (mutationsList.includes(mutation.type)) {
      callback(mutation)
    }
  })
}

const concatTypedArrays = (a, b) => {
  var c = new a.constructor(a.length + b.length)
  c.set(a, 0)
  c.set(b, a.length)
  return c
}

const convertIndexToLetter = index =>
  String.fromCharCode(65 + (index % 26)).repeat(~~(index / 26) + 1)

const matrixToArray = strMatrix => {
  return strMatrix.match(/(-?[0-9.]+)/g)
}

const addFontFamilyURL = url =>
  new Promise(resolve => {
    if (document.querySelectorAll(`[href='${url}']`).length) return resolve()
    const link = document.createElement('link')
    link.onload = () => resolve()
    link.rel = 'stylesheet'
    link.href = url
    document.getElementsByTagName('head')[0].appendChild(link)
  })

const capitalizeString = str => str.charAt(0).toUpperCase() + str.slice(1)

const getRangedLetterOrNumberRegex = ({
  expression,
  to,
  from,
  isSpaceAllowed,
}) => {
  let range = `{${from}${isNaN(to) ? '' : ','}${isFinite(to) ? to : ''}}`

  let space_regex = '\\s'

  return new RegExp(
    `${'^['}${expression.source}${
      isSpaceAllowed ? space_regex + ']' : ']'
    }${range}$`
  )
}

const getBrightnessOfColor = color => {
  color = '' + color
  let isHEX = color.indexOf('#') === 0,
    isRGB = color.indexOf('rgb') === 0
  let m, r, g, b

  if (isHEX) {
    const hasFullSpec = color.length === 7
    m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)
    if (m) r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16)
    g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16)
    b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16)
  } else if (isRGB) {
    m = color.match(/(\d+){3}/g)
    if (m) {
      r = m[0]
      g = m[1]
      b = m[2]
    }
  }
  if (typeof r !== 'undefined') return (r * 299 + g * 587 + b * 114) / 1000
}
const HEXtoRGB = (hex, options = { list: false, object: false }) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (options?.list) return [r, g, b]
  else if (options?.object) return { r, g, b }
  else return `rgb(${r}, ${g}, ${b})`
}
const getRandomHEX = () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16)

const getRandomRGB = () => {
  const num = Math.round(0xffffff * Math.random())
  return `rgb(${num >> 16}, ${(num >> 8) & 255}, ${num & 255})`
}
const stringToColor = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++)
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  let color = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

const getRandomNumberBetween = ({ min, max }) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
const getRandomHSL = (saturation = 50, lightness = 50) => {
  const hue = Math.floor(Math.random() * 360)
  saturation = (saturation || Math.floor(Math.random() * (100 + 1))) + '%'
  lightness = (lightness || Math.floor(Math.random() * (100 + 1))) + '%'
  return `hsl(${hue}, ${saturation}, ${lightness})`
}
const getAverageColorFromImageURL = async url => {
  if (url.startsWith(constants.S3_BASE_URL))
    return StorageActions.getImageByS3Path(
      constants.GET_S3_OBJECT_RELATIVE_PATH_BY_URL(url)
    ).then(
      ({ dataURL }) =>
        new Promise(resolve => {
          let img = new Image()
          img.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height

            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            resolve(new FastAverageColor().getColor(canvas).hex)
          }
          img.src = dataURL
        })
    )

  const fac = new FastAverageColor()
  const { hex } = await fac.getColorAsync(url)
  return hex.toLocaleUpperCase()
}

const getBase64ImageFromColor = hex => {
  let canvas = document.createElement('canvas')
  canvas.width = 1
  canvas.height = 1

  let ctx = canvas.getContext('2d')
  ctx.fillStyle = hex
  ctx.fillRect(0, 0, 1, 1)

  let encoded = canvas.toDataURL()
  canvas.remove()
  return encoded
}

const trimPngImage = dataURI =>
  new Promise(resolve => {
    let img = document.createElement('img')
    img.src = dataURI
    img.onload = () => {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height),
        pixelsLength = pixels.data.length,
        bound = {
          top: null,
          left: null,
          right: null,
          bottom: null,
        },
        x,
        y

      for (let i = 0; i < pixelsLength; i += 4) {
        if (pixels.data[i + 3] !== 0) {
          x = (i / 4) % canvas.width
          y = ~~(i / 4 / canvas.width)

          if (bound.top === null) bound.top = y

          if (bound.left === null) bound.left = x
          else if (x < bound.left) bound.left = x

          if (bound.right === null) bound.right = x
          else if (bound.right < x) bound.right = x

          if (bound.bottom === null) bound.bottom = y
          else if (bound.bottom < y) bound.bottom = y
        }
      }

      let trimHeight = bound.bottom - bound.top,
        trimWidth = bound.right - bound.left,
        trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

      canvas.width = trimWidth
      canvas.height = trimHeight
      ctx.putImageData(trimmed, 0, 0)

      resolve(canvas.toDataURL())
    }
  })

const base64DataURLToBlob = dataURL => {
  let mimeType = dataURL
    .split(',')[0]
    .match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
  let binary = atob(dataURL.split(',')[1]),
    array = []
  for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i))
  return new Blob([new Uint8Array(array)], { type: mimeType })
}

const blobToBase64Data = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const saveFile = (blob, fileName = '') => {
  let a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = fileName
  a.click()
  a.remove()
}

const getImageDimensions = dataURL =>
  new Promise(resolve => {
    const image = new Image()
    image.onload = () =>
      resolve({ width: image.naturalWidth, height: image.naturalHeight })
    image.src = dataURL
  })

const trimImage = (dataURL, width, height) =>
  new Promise(resolve => {
    let img = document.createElement('img')
    img.src = dataURL
    img.onload = () => {
      if (img.height <= height || img.width <= width) resolve(dataURL)

      let canvas = document.createElement('canvas')

      canvas.height = height > img.height ? img.height : height
      canvas.width = width > img.width ? img.width : width

      const startPointX = (img.width - width) / 2
      const startPointY = (img.height - height) / 2

      let ctx = canvas.getContext('2d')
      ctx.drawImage(
        img,
        startPointX > 0 ? startPointX : 0,
        startPointY > 0 ? startPointY : 0,
        width,
        height,
        0,
        0,
        width,
        height
      )
      resolve(canvas.toDataURL())
    }
  })

const scaleImage = (dataURL, width, height) =>
  new Promise(resolve => {
    const img = document.createElement('img')
    img.src = dataURL
    img.onload = () => {
      if (img.height <= height || img.width <= width) resolve(dataURL)

      const canvas = document.createElement('canvas')
      const aspectRatioByHeight = img.height / img.width
      const aspectRatioByWidth = img.width / img.height

      let ctx = canvas.getContext('2d')

      if (aspectRatioByHeight > aspectRatioByWidth) {
        canvas.width = width
        canvas.height = width * aspectRatioByHeight
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          width,
          width * aspectRatioByHeight
        )
      } else {
        canvas.width = height * aspectRatioByWidth
        canvas.height = height
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          height * aspectRatioByWidth,
          height
        )
      }
      resolve(canvas.toDataURL())
    }
  })

const vuexPluginSubscribeActionOnce = (fn, ...actionTypes) => {
  if (!actionTypes.length) throw Error('there must be at least one action')

  return store => {
    let unsubscribe
    unsubscribe = store.subscribeAction(action => {
      if (actionTypes.includes(action.type)) {
        unsubscribe()
        fn(store)
      }
    })
  }
}

export const useResizeObserver = cb => {
  let isMounted = ref(false)

  const resizeObserver = new ResizeObserver(cb)

  const getTarget = target => (_.isFunction(target) ? target() : target)

  const observe = (target, options) =>
    executeAfterMounted(() =>
      resizeObserver.observe(getTarget(target), options)
    )
  const unobserve = target =>
    executeAfterMounted(() => resizeObserver.unobserve(getTarget(target)))
  const unobserveAll = () => resizeObserver.disconnect()

  onMounted(() => {
    isMounted.value = true
  })

  onBeforeUnmount(() => {
    unobserveAll()
  })

  const executeAfterMounted = fn => {
    const watchHandler = v => {
      if (v) {
        fn()
        unwatch()
      }
    }

    const unwatch = watch(isMounted, v => {
      if (v) {
        fn()
        unwatch()
      }
    })

    watchHandler(isMounted.value)
  }

  return {
    observe,
    unobserve,
    unobserveAll,
  }
}

export default {
  getAbsoluteBoundingRect,
  getFixedBoundingRect,
  isCursorInsideBoundingRect,

  subscribeMutation,

  deepCloneObject,
  convertIndexToLetter,
  capitalizeString,
  getRangedLetterOrNumberRegex,

  getFileExtension,
  trimFileExtension,
  getFileFolderPath,
  getFileNameFromPath,
  generateUUID,
  generateShortId,

  getBrightnessOfColor,
  HEXtoRGB,
  stringToColor,
  getRandomHEX,
  getRandomRGB,
  getRandomHSL,
  getBase64ImageFromColor,
  trimPngImage,
  base64DataURLToBlob,
  saveFile,
  blobToBase64Data,
  getImageDimensions,
  getAverageColorFromImageURL,

  concatTypedArrays,
  matrixToArray,

  convertBytesToMb,
  convertBytesToKb,
  convertBytesToReadable,

  addFontFamilyURL,
  stripHtml,

  trimImage,
  scaleImage,

  vuexPluginSubscribeActionOnce,
  useResizeObserver,
  getRandomNumberBetween,

  createCsvFromArray,
}

export {
  getAbsoluteBoundingRect,
  getFixedBoundingRect,
  isCursorInsideBoundingRect,
  subscribeMutation,
  deepCloneObject,
  convertIndexToLetter,
  capitalizeString,
  getRangedLetterOrNumberRegex,
  getFileExtension,
  trimFileExtension,
  getFileFolderPath,
  getFileNameFromPath,
  generateUUID,
  generateShortId,
  getBrightnessOfColor,
  HEXtoRGB,
  stringToColor,
  getRandomHEX,
  getRandomRGB,
  getRandomHSL,
  getBase64ImageFromColor,
  trimPngImage,
  base64DataURLToBlob,
  saveFile,
  blobToBase64Data,
  getImageDimensions,
  getAverageColorFromImageURL,
  concatTypedArrays,
  matrixToArray,
  convertBytesToMb,
  convertBytesToKb,
  convertBytesToReadable,
  addFontFamilyURL,
  stripHtml,
  trimImage,
  scaleImage,
  vuexPluginSubscribeActionOnce,
  getRandomNumberBetween,
  createCsvFromArray,
}
