var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }