var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-fab-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm._t("activator", null, {
    "on": {
      click: _vm.openDialog
    }
  })], 2), _vm._t("remove-btn", null, {
    "on": {
      click: _vm.clearImage
    }
  }), _c('v-dialog', {
    attrs: {
      "content-class": "overflow-y-hidden",
      "transition": "slide-y-transition",
      "width": "686"
    },
    on: {
      "input": _vm.dialogModelUpdated
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._t("title", function () {
    return [_vm._v("Change Image")];
  })], 2), _c('v-spacer'), _c('XIcon', {
    staticClass: "cursor-pointer gray_400--text",
    attrs: {
      "stroke-width": "2.2"
    },
    on: {
      "click": _vm.openConfirmationDialog
    }
  })], 1), _c('v-container', {
    staticClass: "pa-10"
  }, [_c('div', [_c('v-tabs', {
    staticClass: "blue-tabs",
    attrs: {
      "height": "36",
      "hide-slider": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    staticClass: "mr-1",
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("Formsly")])]), _c('v-tab', {
    staticClass: "mx-1",
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("Unsplash")])]), _c('v-tab', {
    staticClass: "ml-1",
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("Upload")])])], 1), _c('v-tabs-items', {
    staticClass: "overflow-visible",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('FormslyBrowser', {
    ref: "formsly",
    attrs: {
      "value": 0,
      "active-image": _vm.activeImage
    },
    on: {
      "set-active-image": _vm.setImagePreview
    }
  }), _c('UnsplashBrowser', {
    ref: "unsplash",
    attrs: {
      "value": 1,
      "active-image": _vm.activeImage
    },
    on: {
      "set-active-image": _vm.setImagePreview,
      "set-download-url": function (v) {
        return _vm.unsplashImageDownloadUrl = v;
      }
    }
  }), _c('UploadYoursBrowser', {
    ref: "uploadYours",
    attrs: {
      "value": 2,
      "active-image": _vm.activeImage
    },
    on: {
      "set-active-image": _vm.setImagePreview,
      "set-active-image:active-url-replaced": _vm.activeUrlReplaced
    }
  })], 1), _c('div', {
    staticClass: "cover-image-select-footer",
    class: _vm.activeImage && 'cover-image-select-footer--active'
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100 mr-2",
      "rounded": ""
    },
    on: {
      "click": _vm.cancelCover
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": _vm.selectCover
    }
  }, [_vm._v(" Select ")])], 1)], 1)])], 1)], 1), _c('f-dialog', {
    attrs: {
      "width": "690"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Set selected image")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._v(" Please confirm if the selected image should be set. ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": _vm.cancelCover
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "blue_400",
            "rounded": ""
          },
          on: {
            "click": _vm.selectCover
          }
        }, [_vm._v(" Confirm ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationDialog,
      callback: function ($$v) {
        _vm.confirmationDialog = $$v;
      },
      expression: "confirmationDialog"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }