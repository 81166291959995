const DefaultResettableSBGenerator = ({module}) => ({
    actions: {
        RESET: () => {
            const store = require('@/store').default

            store.unregisterModule(module._path)
            store.registerModule(module._path, module._moduleDefinition)
        },
    }
})

DefaultResettableSBGenerator.key = 'Resettable'

export const ResettableExtensions = Object.freeze({
    Default: {
        SBGenerator: DefaultResettableSBGenerator
    }
})