var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editable-field-properties"
  }, [_c('div', {
    staticClass: "editable-field-properties__header"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('Edit2Icon', {
    staticClass: "mr-2",
    attrs: {
      "stroke-width": "1.8",
      "size": "18"
    }
  }), _vm._v(" Edit ")], 1), _c('v-btn', {
    attrs: {
      "color": "gray_400",
      "text": "",
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.closeProperties
    }
  }, [_c('XIcon', {
    attrs: {
      "stroke-width": "2.2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "editable-field-properties__container"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }