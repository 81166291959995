import StorageActions from '@/utils/actions/storage_actions'
import constants from '@/constants/constants'
import axios from 'axios'

const fetchFormData = formID => new Promise((resolve, reject) => {
    axios.post('/getFormData', {
        formID: formID
    })
        .then(resolve)
        .catch(reject)
})

const fetchFormSourceJSON = (formFolderPath, formVersionID) => new Promise((resolve, reject) => {
    StorageActions.getJSONByS3Path(formFolderPath + constants.S3_FORM_FILE_NAME, formVersionID)
        .then(({json}) => resolve(json))
        .catch(reject)
})

const submitForm = (id, formID, publishedVersionID, submissionJSON, zapier_status, webhook_url) => new Promise((resolve, reject) => {
    axios.post('/submitForm', {
        id: id,
        formID: formID,
        publishedVersionID: publishedVersionID,
        rawData: submissionJSON
    })
        .then(response => {
            if (zapier_status === 'true' && webhook_url){
                sendDataToZapier(submissionJSON, webhook_url);
            }
            resolve
        })
        .catch(reject)
})

const sendDataToZapier = (formData, webhook_url) => new Promise((resolve, reject) => {
    const zapierWebhookUrl = webhook_url;
    axios.post(zapierWebhookUrl, formData)
        .then(resolve)
        .catch(reject)
  })

const sendSubmissionMetrics = ({id, formID, started}) => new Promise((resolve, reject) => {
    axios.post('/submissionMetrics', {id, formID, started})
        .then(resolve)
        .catch(reject)
})

const uploadAttachment = (file, fileName) => StorageActions.putObject({file, filePath: constants.S3_UPLOADS_FOLDER_PATH + fileName, contentType: file.type, initialFilename: file.name})

const duplicateForm = formID => new Promise((resolve, reject) => {
    axios.post('/duplicateForm', {
        formID: formID
    },)
    .then(response => resolve(response.data))
    .catch(error => {
        reject(error);
    });
})

const disconnectZapier = formID => new Promise((resolve, reject) => {
    axios.delete('/deleteFormWebhook', {
        data: {
            formID: formID
        }
    })
    .then(response => resolve(response.data))
    .catch(error => {
        reject(error);
    });
})

export default {
    fetchFormData,
    fetchFormSourceJSON,
    submitForm,
    uploadAttachment,
    sendSubmissionMetrics,
    duplicateForm,
    disconnectZapier,
}