var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "dashboard",
    attrs: {
      "fluid": ""
    }
  }, [_c('LoaderOverlay', {
    attrs: {
      "visible": _vm.isLoading
    }
  }), _c('v-sheet', {
    staticClass: "faded"
  }), _c('v-row', {
    staticClass: "dashboard-head"
  }, [_c('v-col', [_c('p', {
    staticClass: "dashboard-head__title"
  }, [_vm._v("Your forms")]), _c('p', {
    staticClass: "dashboard-head__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.getFormsListIsLoading ? !_vm.getFormsList.length ? 'No forms to display' : _vm.getFormsList.length + ' Forms' : '') + " ")])]), _c('v-col', {
    staticClass: "d-flex justify-end align-start"
  }, [_c('SearchBarComponent', {
    staticClass: "mr-6",
    attrs: {
      "lowercase": ""
    },
    model: {
      value: _vm.formsSearchQuery,
      callback: function ($$v) {
        _vm.formsSearchQuery = $$v;
      },
      expression: "formsSearchQuery"
    }
  }), _c('v-btn', {
    attrs: {
      "rounded": "",
      "color": "blue_400",
      "depressed": "",
      "dark": ""
    },
    on: {
      "click": _vm.routeToFormCreationPage
    }
  }, [_c('PlusIcon', {
    staticClass: "v-btn__prepend-icon"
  }), _vm._v(" New Form ")], 1)], 1)], 1), _c('v-slide-y-transition', {
    staticClass: "dashboard-body",
    style: _vm.isFormListEmpty,
    attrs: {
      "tag": "div",
      "mode": "out-in",
      "group": "",
      "hide-on-leave": ""
    }
  }, [_vm.isDashboardLoading ? _c('div', {
    key: "loader",
    staticClass: "dashboard-body__loader"
  }, [_c('f-circle-loader', {
    attrs: {
      "size": "5"
    }
  })], 1) : !_vm.getFormsList.length ? _c('no-forms-view', {
    key: "no-forms"
  }) : _c('div', {
    key: "form-preview-grid",
    staticClass: "dashboard-body__form-previews-grid"
  }, _vm._l(_vm.filteredFormsList, function (formData) {
    return _c('span', {
      key: formData.id,
      ref: "formCardWrappers",
      refInFor: true,
      staticStyle: {
        "display": "contains"
      }
    }, [_c('FormPreviewCard', {
      attrs: {
        "form-data": formData,
        "static-form-scale": _vm.staticFormScale,
        "interactive-form-scale": _vm.interactiveFormScale
      },
      on: {
        "update-loader-from-child1": _vm.handleLoaderUpdate
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }