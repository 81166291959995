var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "show-file-uploader": !_vm.submissionObj.value,
      "limit-file-size": _vm.sourceObj.properties.limit_file_size.max,
      "accept-extensions": _vm.sourceObj.properties.advanced_file_types
    },
    on: {
      "input": _vm.inputFile
    }
  }, [_vm.submissionObj.value ? _c('div', {
    staticClass: "file-upload-field-view__selected-file"
  }, [_c('div', {
    staticClass: "file-upload-field-view__selected-file__preview-wrapper"
  }, [_c('v-img', {
    staticClass: "file-upload-field-view__selected-file__preview",
    attrs: {
      "src": _vm.submissionObj.value._fileDataURL
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_vm.isImage ? _c('f-circle-loader', {
          attrs: {
            "size": "3"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [!_vm.isImage ? _c('FileIcon') : _vm._e()];
      },
      proxy: true
    }], null, false, 1831479634)
  })], 1), _c('div', {
    staticClass: "file-upload-field-view__selected-file__description"
  }, [_c('div', {
    staticClass: "file-upload-field-view__selected-file__fullname"
  }, [_c('span', {
    staticClass: "file-upload-field-view__selected-file__name"
  }, [_vm._v(_vm._s(_vm.getFileNameWithoutExtension))]), _c('span', {
    staticClass: "file-upload-field-view__selected-file__extension"
  }, [_vm._v(_vm._s(_vm.getFileExtension))])]), _c('span', {
    staticClass: "file-upload-field-view__selected-file__size"
  }, [_vm._v(_vm._s(_vm.submissionObj.value.size))])]), _c('XIcon', {
    staticClass: "file-upload-field-view__selected-file__remove-btn",
    on: {
      "click": _vm.removeFile
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }