<script>
    import { XIcon } from 'vue-feather-icons'
    import router from '@/router'
    import constants from '@/constants/constants'

    export default {
        name: 'CloseTemplateSelectionComponent',
        functional: true,
        render: h => h(
            XIcon,
            {
                class: 'x-btn-to-dashboard',
                on: {
                    click: () => router.push({name: constants.PAGE_NAME_DASHBOARD})
                }
            }
        )
    }
</script>

<style scoped>
    .x-btn-to-dashboard {
        color: #6e6e6e;
        stroke-width: 1.5px;
        cursor: pointer;
    }
</style>