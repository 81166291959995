import { API } from 'aws-amplify'
import stripe from '@/utils/stripe'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

export default ({useExtension}) => {
    {
        const state = {
            billing_details: undefined,
            payment_method: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
        useExtension(ToggleableExtensions.Loadable, {state})
    }

    return {
        actions: {
            fetchDetails: ({dispatch, toggleIsLoading}) => toggleIsLoading(
                (async () => {
                    const token = await dispatch('user/getUserJwtToken', null, {root: true})

                    return API
                        .get('core', '/upgradePlan/getCustomerDetails', {
                            headers: {
                                Authorization: token
                            }
                        })
                        .then(response => {
                            const toReturn = []

                            if (response?.billing_details)
                                toReturn.push(dispatch('setBillingDetails', response.billing_details))

                            if (response?.payment_method)
                                toReturn.push(dispatch('setPaymentMethod', response.payment_method))

                            return Promise.all(toReturn)
                        })
                })(),
                'billing_details',
                'payment_method'
            ),
            updatePaymentMethod: ({dispatch}, {name, cardElement}) => stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {name},
            })
                .then(result => new Promise((resolve, reject) => {
                    if (result.error) {
                        console.log(result.error)
                        reject(result.error)
                    } else {
                        resolve(result.paymentMethod.id)
                    }
                }))
                .then(paymentMethodID => dispatch('user/getUserJwtToken', null, {root: true})
                    .then(token => API.post('core', '/upgradePlan/updateDefaultPaymentMethod', {
                        headers: {Authorization: token},
                        body: {paymentMethodID}
                    })))
                .then(response => dispatch('setPaymentMethod', {card: response})),
            updateBillingDetails: ({dispatch, toggleIsLoading}, {name, ...billingAddress}) => toggleIsLoading(
                dispatch('user/getUserJwtToken', null, {root: true})
                    .then(token => API.post('core', '/upgradePlan/updateBillingDetails', {
                        headers: {Authorization: token},
                        body: {name, address: billingAddress}
                    }))
                    .then(response => {
                        if (response?.billing_details)
                            return dispatch('setBillingDetails', response.billing_details)
                    }),
                'billing_details'
            )
        },
        getters: {
            getSubscriptionIsExist: ({getters}) => !!getters.getSubscription,
            getPaymentMethodIsExist: ({getters}) => !!getters.getPaymentMethod,
            getBillingDetailsIsExist: ({getters}) => !!getters.getBillingDetails
        }
    }
}