export const preview_field_view_mixin = {
    props: {
        fieldObj: {
            type: Object,
            required: true
        },
        isFormStatic: {
            required: true,
            type: Boolean,
        },
        isFormInteractive: {
            required: true,
            type: Boolean,
        }
    },
}
