var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-field-view generic-text-field-view"
  }, _vm._l(_vm.getAddressFields, function (_ref, index) {
    var _vm$submissionObj, _vm$submissionObj2, _vm$submissionObj2$va;
    var key = _ref.key,
      mask = _ref.mask;
    return _c('div', {
      key: index,
      staticClass: "address-field-view__item__wrapper"
    }, [_vm.editable || _vm.preview || key !== 'country' ? _c('GenericStringSingleLineFieldViewInput', _vm._g({
      directives: [{
        name: "input-mask",
        rawName: "v-input-mask",
        value: mask || /.*/,
        expression: "mask || /.*/"
      }],
      key: index,
      ref: _vm.editable || _vm.preview ? '' : _vm.genRef(key),
      refInFor: true,
      staticClass: "address-field-view__item",
      attrs: {
        "autocomplete": "off",
        "is-form-static-prop": _vm.isFormStaticProp,
        "placeholder": _vm.placeholders[key],
        "disabled": _vm.editable || _vm.preview,
        "value": (_vm$submissionObj = _vm.submissionObj) === null || _vm$submissionObj === void 0 ? void 0 : _vm$submissionObj.value[key]
      },
      on: {
        "input": function ($event) {
          return _vm.setValue($event, key);
        }
      },
      scopedSlots: _vm._u([key === 'country' ? {
        key: "append",
        fn: function () {
          return [_c('ChevronDownIcon')];
        },
        proxy: true
      } : null], null, true)
    }, Object.assign({}, _vm.$listeners))) : _c('f-autocomplete', _vm._g({
      ref: _vm.editable || _vm.preview ? '' : _vm.genRef('country'),
      refInFor: true,
      staticClass: "address-field-view__item generic-text-field-view__input-field",
      attrs: {
        "placeholder": _vm.placeholders.country,
        "items": _vm.constants.COUNTRY_LIST,
        "item-value": function (item) {
          return item.code;
        },
        "filter": function (item, search) {
          return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        },
        "value": (_vm$submissionObj2 = _vm.submissionObj) === null || _vm$submissionObj2 === void 0 ? void 0 : (_vm$submissionObj2$va = _vm$submissionObj2.value) === null || _vm$submissionObj2$va === void 0 ? void 0 : _vm$submissionObj2$va.country,
        "hide-details": "",
        "disabled": _vm.preview,
        "outlined": _vm.isFormStatic.value || _vm.isFormStaticProp,
        "dense": ""
      },
      on: {
        "input": function ($event) {
          return _vm.setValue($event, 'country');
        }
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function (_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs,
            item = _ref2.item;
          return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c('f-country-flag', {
            attrs: {
              "size": "m",
              "code": item.code
            }
          })], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
        }
      }, {
        key: "selection",
        fn: function (_ref3) {
          var item = _ref3.item;
          return [_c('span', {
            staticClass: "d-flex align-center"
          }, [_c('f-country-flag', {
            staticClass: "mr-2",
            attrs: {
              "size": "l",
              "code": item.code
            }
          }), _vm._v(" " + _vm._s(item.name) + " ")], 1)];
        }
      }, {
        key: "append",
        fn: function () {
          return [_c('ChevronDownIcon')];
        },
        proxy: true
      }], null, true)
    }, Object.assign({}, _vm.$listeners)))], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }