<template>
    <span>{{answer}}</span>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__GenericSelection",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
        computed: {
            answer() {
                return this.submissionObj.value.length
                    ? this.sourceObj.content.options.filter((item, index) => this.submissionObj.value.includes(index)).join(', ')
                    : '-'
            }
        }
    }
</script>