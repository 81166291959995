<template>
    <span>
        {{ answer }}
    </span>
</template>

<script>
    export default {
        name: "GenericSingleAnswer__OpinionScale",
        props: {
            submissionObj: Object,
            sourceObj: Object
        },
        computed: {
            answer() {
                return this.submissionObj.value !== null
                    ? (this.submissionObj.value + +this.sourceObj.properties.scale_poles.from).toString() + '/' + this.sourceObj.properties.scale_poles.to
                    : '-'
            }
        }
    }
</script>