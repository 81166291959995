<template>
    <div class="static-editable-field-constructor__popup">
        <transition :name="transitionName" @after-leave="changeNameTransition()" mode="out-in">
            <StaticFieldActions
                    v-if="currentPopup === allPopups.actions"
                    :conditional-logic-support="!!fieldTypeConst.CONDITIONAL_LOGIC"
                    @open-properties="openProperties"
                    @duplicate-field="duplicateField"
                    @open-conditional-logic-editor="openConditionalLogicEditor"
                    @remove-field="removeField"
            />
            <EditableFieldPropertiesDefaultWindow
                    v-else-if="currentPopup === allPopups.properties"
                    :field-obj="fieldObj"
                    :field-id="fieldId"
                    @close-properties="() => closeProperties() || openActions()"
                    @change-field-type="changeFieldType"
            />
        </transition>
    </div>
</template>

<script>
    import { computed, ref, watch } from '@vue/composition-api'

    export default {
        name: "StaticFieldPopup",
        components: {
            StaticFieldActions: () => import('./StaticFieldActions'),
            EditableFieldPropertiesDefaultWindow: () => import('@/components/Fields/EditableField/EditableFieldProperties/EditableFieldPropertiesDefaultWindow')
        },
        props: {
            fieldObj: Object,
            fieldId: String,

            show: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            transitionName: 'actions'
        }),
        methods: {
            changeNameTransition() {
                this.transitionName === 'actions' && this.currentPopup === this.allPopups.properties ? this.transitionName = 'editable-field' : this.transitionName = 'actions';
            }
        },
        setup(props, {root, emit}) {
            /** POPUP HANDLERS **/
            const currentPopup = ref(undefined)
            const allPopups = {
                actions: 'actions',
                properties: 'properties'
            }
            const popupTogglers = Object.fromEntries(
                Object.keys(allPopups).map(popupKey => [
                    ['open' + root.Utils.capitalizeString(popupKey), () => currentPopup.value = allPopups[popupKey]],
                    ['close' + root.Utils.capitalizeString(popupKey), () => currentPopup.value = undefined]]).flat()
            )
            watch(
                () => props.show,
                v => {
                    if (v && !currentPopup.value)
                        currentPopup.value = allPopups.actions
                    else if (!v)
                        currentPopup.value = undefined
                }
            )

            /** SIDE FUNCTIONS **/
            const duplicateField = () => emit('duplicate-field')
            const removeField = () => emit('remove-field')
            const openConditionalLogicEditor = () => root.$store.dispatch('edit/conditional/openConditionalLogicEditor', props.fieldId)
            const changeFieldType = fieldType => emit('change-field-type', fieldType)

            const fieldTypeConst = computed(() => root.constants.FIELD_FROM_TYPE[props.fieldObj.field_type])

            return {
                currentPopup,
                allPopups,
                ...popupTogglers,

                duplicateField,
                removeField,
                openConditionalLogicEditor,
                changeFieldType,

                fieldTypeConst
            }
        }
    }
</script>

<style lang="scss" scoped>
    .static-editable-field-constructor__popup {
        position: absolute;
        z-index: 15;
        top: 0;
        left: 100%;
        padding-left: 12px;

        & > div {
            background-color: white;
            box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.08);
        }
    }
</style>