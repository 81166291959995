import { ref, reactive } from '@vue/composition-api'

const useDragWorkflow = (
    props,
    {root},
    mousePos
) => {
    const cleanupDragWorkflowBind = () => {
        dragWorkflowBind.finalDropzoneBounding = ref(false)
        dragWorkflowBind.fadeOut = ref(false)
        dragWorkflowBind.messagesToDragManager = reactive({})
    }

    const dragWorkflowBind = reactive({
        mousePos,
        finalDropzoneBounding: ref(false),
        fadeOut: ref(false),
        messagesToDragManager: reactive({
            // draggableFieldCenterCoords: reactive({
            //     x: mousePos.x,
            //     y: mousePos.y
            // })
        })
    })

    const startDragging = ({event, fieldType, bounding: initialBounding}, callbackBeforeStopDragging, callbackAfterStopDragging) => {
        dragWorkflowBind.event = event
        dragWorkflowBind.fieldType = fieldType
        dragWorkflowBind.initialBounding = initialBounding

        root.draggableEventBus.$emit('start', {
            el: {
                component: () => import('@/components/FormEditor/StaticForm/StaticPages/MainPage/StaticFieldsDragManager/DraggableField'),
                bind: dragWorkflowBind
            },
            event: event,
            boundingRect: {
                width: 300,
                height: 100
            },
            scroll: {
                element: window,
                offset: 50,
                bottomScrollActivationZone: {
                    left: 0,
                    right: window.innerWidth,
                    top: window.innerHeight - 100,
                    bottom: window.innerHeight
                },
                topScrollActivationZone: {
                    left: 0,
                    right: window.innerWidth,
                    top: 0,
                    bottom: 100
                }
            },
            customOffsets: {
                left: 0,
                top: 0
            },
            enabledAxles: {
                x: true,
                y: true
            },
            pauseBeforeStopDragging: root.constants.DRAGGABLE_FIELD_DROP_DURATION,
            callbackBeforeStopDragging: callbackBeforeStopDragging,
            callbackAfterStopDragging: () => {
                callbackAfterStopDragging()
                cleanupDragWorkflowBind()
            }
        })
    }

    return {
        dragWorkflowBind,
        startDragging,
    }
}

export default useDragWorkflow