<template>
    <v-card>
        <v-card-title>
            Conditional logic
            <v-spacer/>
            <v-btn
                    icon
                    @click="$emit('close-dialog')"
            >
                <XIcon/>
            </v-btn>
        </v-card-title>
        <v-card-subtitle>
            Personalize your form's experience based on people's responses
        </v-card-subtitle>
        <v-card-text>
            <div class="conditional-logic-editor mt-4">
                <v-text-field
                        class="conditional-logic-editor__conditional-field"
                        :value="getConditionalField.title"
                        outlined
                        disabled
                        dense
                        hide-details
                >
                    <template #prepend-inner>
                        <component
                                :is="getConditionalFieldConst.ICON_COMPONENT"
                        />
                        {{ allFieldsWithEnumeration[getCurrentConditionalFieldId].formattedLocation }}
                    </template>
                </v-text-field>

                <div class="conditional-logic-editor__expression-wrapper">
                    <component
                            :is="getConditionalFieldConst.CONDITIONAL_LOGIC.EXPRESSION_COMPONENT"
                            :expression-obj="newFieldCondition.expression"
                    />
                </div>

                <div class="conditional-logic-editor__target-top-container">
                    <div class="conditional-logic-editor__target-action-wrapper">
                        Then
                        <ConditionalThenActionSelect
                                :target-obj="newFieldCondition.if_target"
                                :actions="targetActions"
                        />
                    </div>

                    <span
                            v-if="!elseTargetEnabled"
                            class="conditional-logic-editor__target-secondary-action"
                            @click="enableElseTarget"
                    >
                            + Else Condition
                    </span>
                </div>
                <ConditionalTargetSelect
                        :condition-field-id="getCurrentConditionalFieldId"
                        :target-obj="newFieldCondition.if_target"
                        :another-targets="[...(newFieldCondition.else_target ? [newFieldCondition.else_target] : [])]"
                />

                <template v-if="elseTargetEnabled">
                    <div class="conditional-logic-editor__target-top-container mt-4">
                        <div class="conditional-logic-editor__target-action-wrapper">
                            Else
                            <ConditionalThenActionSelect
                                    :target-obj="newFieldCondition.else_target"
                                    :actions="targetActions"
                            />
                        </div>
                        <span
                                class="conditional-logic-editor__target-secondary-action"
                                @click="disableElseTarget"
                        >
                            <Trash2Icon/>
                            Remove else
                        </span>
                    </div>
                    <ConditionalTargetSelect
                            :condition-field-id="getCurrentConditionalFieldId"
                            :target-obj="newFieldCondition.else_target"
                            :another-targets="[...(newFieldCondition.if_target ? [newFieldCondition.if_target] : [])]"
                    />
                </template>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-dialog v-model="removeConditionDialogModel" width="600">
                <template #activator="{on}">
                    <span
                            v-if="getFieldCondition"
                            class="conditional-logic-editor__target-secondary-action"
                            v-on="on"
                    >
<!--                        @click="removeCondition"-->
                        <TrashIcon/>
                        Remove condition
                    </span>
                </template>
                <template #default>
                    <v-card>
                        <v-card-title>
                            Delete Conditional Logic?
                            <v-spacer/>
                            <v-btn
                                    icon
                                    @click="removeConditionDialogModel = false"
                            >
                                <XIcon/>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            Are you sure you want to delete this Conditional Logic statement?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="gray_100" rounded @click="removeConditionDialogModel = false">
                                Cancel
                            </v-btn>
                            <v-btn color="red_400" rounded
                                   @click="() => {removeConditionDialogModel = false; removeCondition()}">
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
            <v-spacer/>
            <v-btn
                    color="gray_100" rounded
                    @click="$emit('close-dialog')"
            >Cancel
            </v-btn>
            <v-btn
                    v-if="getFieldCondition"
                    color="blue_400" rounded
                    :disabled="$v.$invalid || !isCurrentConditionChanged"
                    @click="applyCondition"
            >Save
            </v-btn>
            <v-btn
                    v-else
                    color="blue_400" rounded
                    :disabled="$v.$invalid"
                    @click="applyCondition"
            >Set Condition
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {computed, onBeforeUnmount, reactive, ref, watch} from '@vue/composition-api'
    import {useVuelidate} from '@vuelidate/core'
    import {required} from '@vuelidate/validators'

    const deepEqual = require('deep-equal')
    import Vue from 'vue'
    import {FieldsEnumerationGettersUtils} from '@/store/util_modules/fields_enumeration_getters'

    export default {
        name: "ConditionalLogicEditor",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
            Trash2Icon: () => import('vue-feather-icons/icons/Trash2Icon'),
            TrashIcon: () => import('vue-feather-icons/icons/TrashIcon'),
            ConditionalThenActionSelect: () => import('./Elements/ConditionalThenActionSelect'),
            ConditionalTargetSelect: () => import('./Elements/ConditionalTargetSelect')
        },
        computed: {
            ...mapGetters('edit/conditional', [
                'getCurrentConditionalFieldId',
                'getConditionalField',
                'getConditionalFieldConst',
                'getFieldCondition'
            ]),
            allFieldsWithEnumeration() {
                return this.$store.getters['edit/' + FieldsEnumerationGettersUtils.allFieldsWithEnumerationGetterName]
            }
        },
        setup(props, {root}) {
            const targetActions = computed(() => [
                ...(root.$store.getters['edit/isFormInteractive'] ? [{
                    icon: () => import('vue-feather-icons/icons/LogInIcon'),
                    text: 'Jump to',
                    value: 'jump_to'
                }] : []),
                {
                    icon: () => import('vue-feather-icons/icons/EyeOffIcon'),
                    text: 'Hide',
                    value: 'hide'
                },
                {
                    icon: () => import('vue-feather-icons/icons/EyeIcon'),
                    text: 'Show',
                    value: 'show'
                }
            ])

            const initialTargetObj = {
                action: root.$store.getters['edit/isFormInteractive'] ? 'jump_to' : 'hide',
                field_id: undefined
            }

            const currentFieldCondition = computed(() => root.$store.getters['edit/conditional/getFieldCondition'])

            const newFieldCondition = reactive(
                currentFieldCondition.value
                    ? root.Utils.deepCloneObject(currentFieldCondition.value)
                    :
                    {
                        expression: {
                            operator: undefined,
                            statement: [],
                            options: undefined
                        },
                        if_target: {...initialTargetObj}
                    }
            )


            const elseTargetEnabled = ref(newFieldCondition.else_target)
            const enableElseTarget = () => {
                elseTargetEnabled.value = true
                Vue.set(newFieldCondition, 'else_target', reactive({...initialTargetObj}))
            }
            const disableElseTarget = () => {
                elseTargetEnabled.value = false
                Vue.delete(newFieldCondition, 'else_target')
            }

            const applyCondition = () => {
                root.$store.dispatch('edit/conditional/updateConditionForField', {
                    fieldID: root.$store.getters['edit/conditional/getCurrentConditionalFieldId'],
                    condition: newFieldCondition
                })
                root.$store.dispatch('edit/conditional/closeConditionalLogicEditor')
            }
            const removeConditionDialogModel = ref(false)
            const removeCondition = () => {
                root.$store.dispatch('edit/conditional/removeConditionForField', root.$store.getters['edit/conditional/getCurrentConditionalFieldId'])
                root.$store.dispatch('edit/conditional/closeConditionalLogicEditor')
            }

            const isCurrentConditionChanged = ref(false)
            const unwatch = watch(
                newFieldCondition,
                v => {
                    isCurrentConditionChanged.value = !deepEqual(v, currentFieldCondition.value)
                },
                {deep: true}
            )
            onBeforeUnmount(() => {
                unwatch()
            })


            /** VALIDATION **/
            const $v = useVuelidate(computed(() => ({
                newFieldCondition: {
                    expression: {
                        operator: {required},
                        statement: {required}
                    }
                }
            })), {
                newFieldCondition
            }, {
                $stopPropagation: true
            })

            return {
                targetActions,
                newFieldCondition,
                elseTargetEnabled,
                enableElseTarget,
                disableElseTarget,

                isCurrentConditionChanged,
                applyCondition,
                removeConditionDialogModel,
                removeCondition,
                $v
            }
        }
    }
</script>

<style lang="scss">
    .conditional-logic-editor {
        padding-left: 34px;

        &__conditional-field {
            margin-bottom: 20px !important;

            &:before {
                content: "If";
                position: absolute;
                align-self: center;
                right: calc(100% + 20px);

                font-size: 1.25rem;
                color: var(--v-bluish_gray_500-base);
            }

            .v-input__control {
                .v-input__slot {
                    .v-input__prepend-inner {
                        display: flex;
                        align-items: center;
                        color: var(--v-grey_700-base);

                        svg {
                            margin-right: 12px;
                        }
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 12px;
                        }

                        &:after {
                            content: ':';
                            align-self: center;
                        }
                    }

                    .v-text-field__slot {
                        input {
                            color: var(--v-grayscale_black-base);
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &__expression-wrapper {
            margin-bottom: 24px;

            .conditional-logic-expression {
                &__expression-input {
                    & > .v-input__control > .v-input__slot {
                        padding-left: 0 !important;

                        & > .v-input__prepend-inner {
                            padding-right: 4px;
                            margin: 0 !important;
                            align-self: auto !important;
                        }
                    }

                    margin-bottom: 2px !important;

                    .conditional-logic-expression__operator-picker {
                        align-items: stretch;

                        &:not(.v-select--is-menu-active) .v-input__control .v-input__append-inner i.v-icon {
                            transform: none;
                        }

                        .v-input__control {
                            min-height: 42px !important;
                            border-radius: 8px 0 0 8px;

                            .v-input__slot {
                                background-color: var(--v-blue_100-base) !important;
                                height: 100% !important;

                                .v-select__slot {
                                    .v-select__selections {
                                        .v-select__selection {
                                            max-width: 100%;
                                            margin: 0;
                                            font-size: .875rem;
                                            font-weight: 500;
                                        }

                                        input {
                                            width: 0;
                                            min-width: 0;
                                        }
                                    }
                                }

                            }
                        }
                    }

                    .v-select__slot {
                        .v-select__selections {
                            .v-chip {
                                border-radius: 20px !important;
                                background: #f3f3f3 !important;
                            }
                            .v-chip__content {
                                .text-truncate {
                                    max-width: 230px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__target-top-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
        }

        &__target-action-wrapper {
            display: flex;
            align-items: center;
            color: var(--v-bluish_gray_500-base);

            .conditional-logic-editor__target-action-picker {
                .v-input__control {
                    flex-grow: 0;
                    width: auto;

                    .v-input__slot {
                        .v-select__slot .v-select__selections {
                            svg {
                                color: var(--v-dark_gray-base);
                                margin-right: 6px;
                            }

                            input {
                                width: 0;
                                flex-grow: 0;
                            }
                        }
                    }
                }
            }
        }

        &__target-field {
            .v-input__control {
                .v-input__slot {
                    .v-select__slot .v-select__selections {
                        flex-wrap: nowrap;

                        & > span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        input {
                            width: 0;
                        }
                    }
                }
            }
        }

        &__target-items {
            .v-list-item {
                max-width: 600px;

                &.v-list-item--disabled {
                    pointer-events: auto;

                    .v-list-item__icon, .v-list-item__content {
                        opacity: 0.3;
                    }
                }

                .v-list-item__content {
                    & > span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .v-list-item__action {
                    font-size: .75rem;
                    color: var(--v-gray_400-base);
                }
            }
        }

        &__target-secondary-action {
            font-size: .875rem;
            color: var(--v-gray_500-base);
            font-weight: 500;
            cursor: pointer;

            display: flex;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
                stroke-width: 1px;
            }
        }
    }
</style>