<template>
  <AppBarTemplate>
    <template #left>
      <BackToDashboardComponent />
      <v-divider class="app-bar-form-editor__divider" vertical />
      <FormEditorAndResponsesNavigationComponent />
    </template>
    <template #center>
      <FormNameEditor />
    </template>
    <template #right>
      <FormAutosaveComponent />
      <PreviewButton v-if="!getOverlay" />
      <PublishButton
        v-show="!getOverlay && $route.name === constants.PAGE_NAME_FORM_EDITOR && isPublishedFormWasChanged"
        @published="shareFormVisible = true" />
      <LockedPublishButton :share-form-visible="shareFormVisible"
        v-if="!getOverlay && $route.name === constants.PAGE_NAME_FORM_EDITOR && !isPublishedFormWasChanged" />
      <transition mode="out-in" name="slide-y-transition">
        <div v-if="shareFormVisible" class="share-form-menu__wrapper">
          <ShareFormMenu v-model="shareFormVisible" :shareLink="shareLink" :formJSON="getFormJSON"
            :is-form-static="isFormStatic" :is-form-interactive="isFormInteractive" fsp-module-location-path="edit/FSP">
            <template #subtitle>
              <div class="success pa-1 rounded-circle mr-1"></div>
              <div class="mr-5">
                It’s live and public
              </div>
              <!--                  <SettingsIcon color="#9EA6A8" size="15"/>-->
            </template>
            <template #title>
              Woohoo! your form has been published
              <div style="{font-size: 24px}" class="ml-2">{{ constants.EMOJI.PARTY }}</div>
            </template>
          </ShareFormMenu>
        </div>
      </transition>
    </template>
  </AppBarTemplate>
</template>

<script>
import { mapGetters } from "@/xstore"

export default {
  name: "AppBar",
  components: {
    AppBarTemplate: () => import('../AppBarTemplate'),
    PreviewButton: () => import('../Elements/PreviewButton'),
    ShareFormMenu: () => import('../../Elements/ShareFormMenu'),
    FormNameEditor: () => import('../Elements/FormNameEditor'),
    BackToDashboardComponent: () => import('../Elements/BackToDashboardComponent'),
    FormEditorAndResponsesNavigationComponent: () => import('../Elements/FormEditorAndResponsesNavigationComponent'),
    PublishButton: () => import('../Elements/PublishButton'),
    FormAutosaveComponent: () => import('../Elements/FormAutosaveComponent'),
    LockedPublishButton: () => import('../Elements/LockedPublishButton'),
    // SettingsIcon: () => import('vue-feather-icons/icons/SettingsIcon')
  },
  data: () => ({
    shareFormVisible: false,
  }),
  computed: {
    ...mapGetters('edit/overlay', [
      'getOverlay'
    ]),
    ...mapGetters('edit/publish', [
      'isPublishedFormWasChanged'
    ]),
    ...mapGetters('edit', [
      'getFormDBData',
      'getFormJSON',
      'isFormStatic',
      'isFormInteractive'
    ]),
    shareLink() {
      return window.location.origin + this.$router.resolve({
        name: this.constants.PAGE_NAME_FORM_SUBMIT,
        params: { formId: this.getFormDBData.id }
      }).href
    },
  },
}
</script>

<style scoped lang="scss">
.share-form-menu__wrapper {
  position: absolute !important;
  top: 80px;
  left: 0;
  right: 0;
  height: 384px;
}
</style>