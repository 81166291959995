import Vue from 'vue'
import GenericStringSingleLineFieldViewInput
    from '@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput'

export default Vue.extend({
    name: 'FormattedDateTimeInput',
    extends: GenericStringSingleLineFieldViewInput,
    props: {
        layout: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        assignGenericViewClass: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        genInput() {
            return this.layout.map((layoutItem, index) => {
                if (layoutItem.type === 'input') {
                    const inputIndex = this.layout.slice(0, index).filter(({type}) => type === 'input').length
                    return this.$createElement('div', {class: 'date-time-field__input-wrapper'}, [
                        this.$createElement('div', {class: 'date-time-field__input-placeholder'}, [layoutItem.PLACEHOLDER]),
                        this.$createElement('input', {
                            class: 'date-time-field__input',
                            domProps: {
                                value: this.value[inputIndex] || ''
                            },
                            attrs: {
                                autofocus: this.autofocus,
                                disabled: this.isDisabled,
                                placeholder: layoutItem.PLACEHOLDER,
                                readonly: this.isReadonly
                            },
                            on: {
                                input: this.composeValueAndEmitInput,
                                focus: this.onFocus,
                                blur: this.onBlur,
                                keydown: e => {
                                    const target = e.target
                                    const caretPosition = target.selectionStart
                                    const prevent = () => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }

                                    const previousInput = this.$refs?.['input_' + (inputIndex - 1)]
                                    const nextInput = this.$refs?.['input_' + (inputIndex + 1)]

                                    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
                                        const valueBefore = target.value
                                        const valueAfter = target.value.slice(0, target.selectionStart) + e.key + target.value.slice(target.selectionEnd)
                                        const key = e.key
                                        const complete = (v, goToNextInput = true) => {
                                            prevent()
                                            target.value = v

                                            target.setSelectionRange(caretPosition + 1, caretPosition + 1)

                                            if (goToNextInput) {
                                                if (nextInput) {
                                                    nextInput?.focus?.()
                                                    nextInput?.setSelectionRange(0, 0)
                                                } else {
                                                    target?.blur()
                                                }
                                            }

                                            this.composeValueAndEmitInput()
                                        }
                                        layoutItem.VALIDATE({
                                            target,
                                            valueBefore,
                                            valueAfter,
                                            caretPosition,
                                            key,
                                            prevent,
                                            complete
                                        })
                                    } else if (
                                        [
                                            37, // left arrow
                                            39, // right arrow,

                                            8, // backspace
                                            46, // delete,

                                            9, // tab
                                        ].includes(e.keyCode)
                                            ||
                                        (
                                            e.ctrlKey
                                            &&
                                            [
                                                65, // a
                                            ].includes(e.keyCode)
                                        )
                                    ) {
                                        if ([37, 8].includes(e.keyCode) && caretPosition === 0) {
                                            prevent()
                                            previousInput?.focus?.()

                                        }

                                        if ([39].includes(e.keyCode) && caretPosition === target.value.length) {
                                            prevent()
                                            nextInput?.focus?.()
                                        }
                                    } else {
                                        prevent()
                                    }
                                }
                            },
                            ref: 'input_' + inputIndex,
                            key: 'input' + inputIndex,
                            directives: [{
                                name: 'resize',
                                modifiers: {
                                    quiet: true
                                },
                                value: this.onResize
                            }]
                        })
                    ])
                } else if (layoutItem.type === 'divider')
                    return this.$createElement(layoutItem, {key: 'divider' + index})
            })
        },

        composeValueAndEmitInput() {
            const values = Object.entries(this.$refs)
                .filter(([refName]) => refName.startsWith('input_'))
                .sort(([refName1], [refName2]) => refName1.slice(6) > refName2.slice(6))
                .map(([, inputEl]) => inputEl.value)

            this.$emit('input', values)
        },
        onFocus() {
            this.isFocused = true
        },
        onBlur() {
            this.isFocused = false
        },
        onMouseDown() {},
        onMouseUp() {},
        onClick() {},
    }
})