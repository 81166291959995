var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-method-card-block"
  }, [_c('p', {
    staticClass: "settings-view__header"
  }, [_vm._v("Payment method")]), _c('p', {
    staticClass: "settings-view__subheader mb-5"
  }, [_vm._v("Add or edit a payment method.")]), _vm.getPaymentMethodIsLoading || _vm.getPaymentMethod ? _c('div', {
    staticClass: "payment-method-card-block__content"
  }, [_c('div', {
    staticClass: "payment-method-card-block__card-brand"
  }, [_vm.getPaymentMethodIsLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  }) : _c('f-card-brand-icon', {
    attrs: {
      "brand": _vm.getPaymentMethod.card.brand
    }
  })], 1), _c('div', {
    staticClass: "payment-method-card-block__info"
  }, [_c('div', {
    staticClass: "payment-method-card-block__card-number"
  }, [_vm.getPaymentMethodIsLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.startCase(_vm.getPaymentMethod.card.brand)) + " ending in " + _vm._s(_vm.getPaymentMethod.card.last4))])], 1), _c('div', {
    staticClass: "payment-method-card-block__card-expiry"
  }, [_vm.getPaymentMethodIsLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  }) : _c('span', [_vm._v("Expiry " + _vm._s(_vm.getPaymentMethod.card.exp_month) + "/" + _vm._s(_vm.getPaymentMethod.card.exp_year))])], 1)]), !_vm.getPaymentMethodIsLoading ? _c('v-btn', {
    attrs: {
      "rounded": "",
      "outlined": ""
    },
    on: {
      "click": _vm.addPaymentMethod
    }
  }, [_vm._v("Edit ")]) : _vm._e()], 1) : _c('v-btn', {
    attrs: {
      "rounded": "",
      "outlined": ""
    },
    on: {
      "click": _vm.addPaymentMethod
    }
  }, [_vm._v("Add Payment Method ")]), _c('UpdatePaymentMethodDialog', {
    ref: "UpdatePaymentMethodDialog"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }