<template>
  <SharedView
    :labels="fieldProperties.labels"
    :scale-poles="sourceObj.properties.scale_poles"
    :submission-value="submissionObj.value"
    @select="handleSelect($event)"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

export default {
  name: "Submittable__OpinionScale",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  },
  methods: {
    handleSelect(index) {
      this.submissionObj.value = index;
    }
  }
}
</script>