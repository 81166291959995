var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-bar",
    class: {
      'search-bar-active': _vm.isSearchActive
    }
  }, [_c('v-fade-transition', [_c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Search"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [!_vm.isSearchActive ? _c('v-btn', _vm._g({
          staticClass: "v-btn--primary v-btn--thin-icon search-bar__button",
          attrs: {
            "disabled": _vm.disabled,
            "icon": ""
          },
          on: {
            "click": _vm.activateSearch
          }
        }, on), [_c('SearchIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "18"
          },
          on: {
            "click": _vm.activateSearch
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('v-expand-x-transition', [_vm.isSearchActive ? _c('v-text-field', {
    ref: "searchField",
    staticClass: "search-bar__input",
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchInput,
      "focusout": _vm.deactivateSearch
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('XIcon', {
          staticClass: "cursor-pointer gray_400--text",
          attrs: {
            "size": "16"
          },
          on: {
            "click": _vm.clearQuery
          }
        })];
      },
      proxy: true
    }], null, false, 3518813706),
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }