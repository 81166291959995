var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "settings",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "settings-header"
  }, [_c('v-col', [_c('span', [_vm._v(" Settings ")])])], 1), _c('v-divider', {
    staticClass: "my-6"
  }), _c('div', {
    staticClass: "settings-body"
  }, [_c('div', {
    staticClass: "settings-tabs-container"
  }, [_c('v-tabs', {
    attrs: {
      "vertical": "",
      "hide-slider": ""
    }
  }, _vm._l(_vm.settingsPagesList, function (settingPage) {
    return _c('v-tab', {
      key: settingPage.to,
      attrs: {
        "exact-path": "",
        "to": settingPage.to
      }
    }, [_vm._v(_vm._s(settingPage.name))]);
  }), 1)], 1), _c('v-container', {
    staticClass: "settings-view",
    attrs: {
      "fluid": ""
    }
  }, [_c('router-view')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }