var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormSettingsOptionWithInput', {
    attrs: {
      "always-enabled": ""
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('div', {
          staticClass: "gray_100 bluish_gray_500--text pa-5 rounded-lg"
        }, [_c('pre', {
          staticClass: "iframe-code"
        }, [_vm._v(_vm._s(_vm.iframeCode))])])];
      },
      proxy: true
    }])
  }, [_vm._v(" Embedding Code ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }