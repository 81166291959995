var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "single-emoji-select-property"
  }, _vm._l(_vm.inputConst.emojis, function (emoji, index) {
    return _c('div', {
      key: index,
      staticClass: "single-emoji-select-property__item background-gray_100-selectable",
      class: _vm.inputValue === index && 'background-gray_100-selectable--selected',
      on: {
        "click": function ($event) {
          return _vm.input(index);
        }
      }
    }, [_c('LottieIconPlayer', {
      attrs: {
        "json": emoji,
        "active": ""
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }