<template>
    <div class="conditional-logic-expression conditional-logic-expression--String">
        <v-combobox
                v-model="expressionObj.statement"
                class="conditional-logic-expression__expression-input"
                placeholder="Add statement"
                :error-messages="stringErrors"
                multiple
                outlined
                append-icon=""
                deletable-chips
                v-input-mask="regex"
                dense
                @change="handleStatementsCount"
                @update:search-input="updateStringInputModel"
        >
            <template #prepend-inner>
                <f-select
                        v-model="expressionObj.operator"
                        class="conditional-logic-expression__operator-picker"
                        :items="expressionOperators"
                        :item-text="item => expressionObj.options.multiple_statements ? item.text_multiple : item.text_single"
                        :item-value="item => item.value"
                        :menu-props="{
                            nudgeBottom: 54
                        }"
                        hide-details
                        solo flat
                        dense
                        @click.stop
                />
            </template>
            <template v-slot:selection="{ item, parent }">
                <v-chip
                        class="my-1"
                        :key="JSON.stringify(item)"
                        label
                        small
                >
                    {{ item }}
                    <XIcon class="cursor-pointer ml-1" size="12" @click="parent.selectItem(item)"/>
                </v-chip>
            </template>
        </v-combobox>
        <v-checkbox
                v-if="is_case_sensitive"
                v-model="expressionObj.options.case_sensitive"
                class="mb-2"
                :ripple="false"
                hide-details
                
        >
            <template #label>
                <span class="bluish_gray_500--text d-flex align-center ml-2">
                    Capital case sensitive
                </span>
                <f-hover-tooltip
                        right
                        text="Allowing this will create different responses for the same word (Ex: 'Joe' and 'joe')"
                        v-slot="{on}"
                >
                    <InfoIcon class="ml-2" size="16" v-on="on"/>
                </f-hover-tooltip>
            </template>
        </v-checkbox>
        <v-checkbox
                v-model="expressionObj.options.multiple_statements"
                :ripple="false"
                hide-details
        >
            <template #label>
                <span class="bluish_gray_500--text d-flex align-center ml-2">
                    Multiple options
                </span>
                <f-hover-tooltip
                        right
                        text="This allows you to collect more response options"
                        v-slot="{on}"
                >
                    <InfoIcon class="ml-2" size="16" v-on="on"/>
                </f-hover-tooltip>
            </template>
        </v-checkbox>
    </div>
</template>

<script>
    import {onBeforeUnmount, reactive, watch, computed, ref} from '@vue/composition-api'
    import {useVuelidate} from '@vuelidate/core'
    import {helpers} from '@vuelidate/validators'

    export default {
        name: "String__ConditionalExpression",
        components: {
            InfoIcon: () => import('vue-feather-icons/icons/InfoIcon'),
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            expressionObj: Object
        },
        setup(props, {root}) {
            const expressionOperators = [
                {
                    text_single: 'Is equal to',
                    text_multiple: 'Is equal any of',
                    value: 'equals'
                },
                {
                    text_single: 'Is not equal to',
                    text_multiple: 'Is not equal any of',
                    value: 'not_equals'
                },
                {
                    text_single: 'Matches',
                    text_multiple: 'Matches any of',
                    value: 'matches'
                },
                {
                    text_single: 'Contains',
                    text_multiple: 'Contains any of',
                    value: 'contains'
                },
                {
                    text_single: 'Doesn\'t contain',
                    text_multiple: 'Doesn\'t contain any of',
                    value: 'doesnt_contain'
                },
                {
                    text_single: 'Begins with',
                    text_multiple: 'Begins with any of',
                    value: 'starts_with'
                },
                {
                    text_single: 'Ends with',
                    text_multiple: 'Ends with any of',
                    value: 'ends_with'
                }
            ]

            if (!props.expressionObj.operator)
                props.expressionObj.operator = expressionOperators[0].value
            if (!props.expressionObj.options)
                props.expressionObj.options = reactive({
                    case_sensitive: true,
                    multiple_statements: false
                })

            const handleStatementsCount = () => {
                props.expressionObj.statement = props.expressionObj.statement.filter(v => regexValidator(v))
                if (!props.expressionObj.options.multiple_statements && props.expressionObj.statement.length > 1)
                    props.expressionObj.statement.shift()
            }

            // Dynamic props

            const regex = computed(
                () => root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.REGEX
            )
            const is_case_sensitive = computed(
                () => root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.CASE_SENSITIVE
            )

            const error_message = computed(
                () => root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.ERROR_MESSAGE
            )


            // Validation
            const regexValidator = (v) => !helpers.req(v) || regex.value.test(v)

            const stringInputModel = ref('');

            const rules = computed(() => ({
                stringInputModel: {phone_format: helpers
                    .withMessage(
                        error_message.value,
                        regexValidator,
                    )}
            }))

            const updateStringInputModel = v => {
                if ($v.value?.stringInputModel?.$reset) $v.value.stringInputModel.$reset()
                stringInputModel.value = v
            }

            const stringErrors = computed(() => [
                ...$v.value.$errors.map(e => e.$message),
                ...($v.value?.stringInputModel?.$silentErrors?.map(e => e.$message) || [])
            ])

            const $v = useVuelidate(rules, {
                stringInputModel: computed(() => stringInputModel.value)
            });

            const unwatch = watch(
                () => props.expressionObj.options.multiple_statements,
                v => {
                    if (!v)
                        props.expressionObj.statement = props.expressionObj.statement.slice(0, 1)
                }
            )
            onBeforeUnmount(() => {
                unwatch()
            })

            return {
                expressionOperators,
                handleStatementsCount,
                updateStringInputModel,
                regex,
                is_case_sensitive,
                stringInputModel,
                stringErrors,
            }
        }
    }
</script>