import { CustomSBGenerator } from '@/xstore/utils/state_based_generation'
import _ from 'lodash'

const defaultFormatters = {
    getterNameFormatter: variableName => `get${_.upperFirst(_.camelCase(variableName))}`,
}

const genExtension = (extractor, assignor = extractor, valueCustomizer = () => null) => {
    const extractKey = obj => {
        if (_.isString(extractor))
            return _.get(obj, extractor, null)
        else if (_.isFunction(extractor))
            return extractor(obj)

        throw new Error('[ArrayAndObjectGettersExtensions]: extractor key must be string or function')
    }

    const assignKey = (obj, keyValue) => {
        if (_.isString(assignor))
            return {...obj, ..._.set({}, assignor, keyValue)}
        else if (_.isFunction(assignor))
            return {...obj, ...assignor({}, keyValue)}

        throw new Error('[ArrayAndObjectGettersExtensions]: assignor key must be string or function')
    }

    const generator = CustomSBGenerator(
        {
            category: 'getters',
            name: variableName => defaultFormatters.getterNameFormatter(variableName + '_as_object'),
            value: variableName => ({state}) => {
                const stateVar = state[variableName]

                if (_.isEmpty(stateVar))
                    return undefined

                if (_.isArray(stateVar))
                    return Object.fromEntries(
                        stateVar.map(arrayItem => {
                            if (!_.isPlainObject(arrayItem))
                                throw new Error('[ArrayAndObjectGettersExtensions]: array item isn\'t object')

                            return [
                                extractKey(arrayItem),
                                {
                                    ...arrayItem,
                                    ...valueCustomizer(arrayItem)
                                }
                            ]
                        })
                    )
                else if (_.isPlainObject(stateVar))
                    return stateVar
            }
        },
        {
            category: 'getters',
            name: variableName => defaultFormatters.getterNameFormatter(variableName + '_as_array'),
            value: variableName => ({state}) => {
                const stateVar = state[variableName]

                if (_.isEmpty(stateVar))
                    return undefined

                if (_.isArray(stateVar))
                    return stateVar
                else if (_.isPlainObject(stateVar))
                    return Object.entries(stateVar)
                        .map(([objItemKey, objItem]) => {
                            const objWithAssignedKey = assignKey(objItem, objItemKey)

                            return {
                                ...objWithAssignedKey,
                                ...valueCustomizer(objWithAssignedKey)
                            }
                        })
            }
        }
    )

    generator.key = `ArrayAndObjectGettersExtensions`

    return {
        SBGenerator: generator
    }
}

export const ArrayAndObjectGettersExtensions = Object.freeze({
    ById: genExtension('id'),
    ByCustomKey: genExtension
})