var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signature-field-view"
  }, [_c('div', {
    class: ['signature-field-view__layout-wrapper', _vm.editable && _vm.isEditingActive && 'signature-field-view__layout-wrapper--editing-active']
  }, [_c('div', {
    class: [!_vm.editable && !_vm.allowTyping && 'signature-field-view__layout-canvas-wrapper']
  }, [_c('v-text-field', _vm._g({
    attrs: {
      "value": _vm.value,
      "placeholder": _vm.placeholder,
      "background-color": "transparent",
      "flat": "",
      "solo": "",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.allowTyping || _vm.preview
    }
  }, Object.assign({}, _vm.$listeners))), _vm._t("pad"), _c('v-divider')], 2), _vm._t("icon")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }