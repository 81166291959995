<template>
    <div class="interactive-editable-field-footer">
        <div class="interactive-editable-field-footer__actions" :key="fieldObj.field_type">
            <span class="subtitle-2 font-weight-bold">
                {{getCurrentMainPageIndex + 1}}
            </span>
            <component
                :is="constants.FIELD_FROM_TYPE[fieldObj.field_type].ICON_COMPONENT"
                class="ml-3"
            />

            <v-divider class="my-1 mx-2" vertical/>

            <f-hover-tooltip
                    top
                    text="Edit"
                    v-slot="{on}"
            >
                <v-btn
                        v-on="on"
                        @click="openProperties"
                        class="v-btn--primary bluish_gray_500--text " text icon small
                >
                    <Edit2Icon stroke-width="1.6" size="20"/>
                </v-btn>
            </f-hover-tooltip>

            <f-hover-tooltip
                    top
                    text="Duplicate"
                    v-slot="{on}"
            >
                <v-btn
                        v-on="on"
                        class="v-btn--primary bluish_gray_500--text mx-2"
                        text icon small
                        @click="duplicateField"
                >
                    <CopyIcon stroke-width="1.6" size="20"/>
                </v-btn>
            </f-hover-tooltip>

            <f-hover-tooltip
                    v-if="!!fieldTypeConst.CONDITIONAL_LOGIC"
                    top
                    text="Conditional logic"
                    v-slot="{on}"
            >
                <v-btn
                        v-on="on"
                        class="v-btn--primary bluish_gray_500--text"
                        text icon small
                        @click="addConditionalLogic"
                >
                    <GitMergeIcon stroke-width="1.6" size="20"/>
                </v-btn>
            </f-hover-tooltip>
            <v-divider class="my-1 mx-2" vertical/>
            <f-hover-tooltip
                    top
                    text="Delete"
                    v-slot="{on}"
            >
                <v-btn
                        v-on="on"
                        @click="removeField"
                        class="v-btn--error"
                        text
                        icon
                        small
                >
                    <TrashIcon stroke-width="1.6" size="20"/>
                </v-btn>
            </f-hover-tooltip>

            <template v-if="'required' in fieldObj.properties">
                <v-divider class="my-1 mx-2" vertical/>

                <span class="interactive-editable-field-footer__required mx-2">Required</span>
                <f-switch class="ml-2" v-model="requiredModel"/>
            </template>
        </div>

        <div class="interactive-editable-field-footer__properties">
            <transition mode="out-in" name="editable-field">
                <EditableFieldPropertiesDefaultWindow
                        v-if="showProperties"
                        :field-obj="fieldObj"
                        :field-id="fieldId"
                        @close-properties="closeProperties"
                        @change-field-type="changeFieldType"
                />
            </transition>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {Edit2Icon, CopyIcon, TrashIcon, GitMergeIcon} from 'vue-feather-icons'

    export default {
        name: "InteractiveFieldActions",
        components: {
            Edit2Icon, CopyIcon, TrashIcon, GitMergeIcon,
            EditableFieldPropertiesDefaultWindow: () => import('@/components/Fields/EditableField/EditableFieldProperties/EditableFieldPropertiesDefaultWindow')
        },
        props: {
            fieldObj: Object,
            fieldId: String
        },
        data: () => ({
            showProperties: false
        }),
        computed: {
            ...mapGetters('edit/pages_navigation', ['getCurrentMainPageIndex']),
            ...mapGetters('edit/conditional', ['getTargetedAndConditionedFields']),
            fieldTypeConst() {
                return this.constants.FIELD_FROM_TYPE[this.fieldObj.field_type]
            },
            requiredModel: {
                get() {
                    return this.fieldObj.properties.required
                },
                set(v) {
                    if (this.getTargetedAndConditionedFields[this.fieldId].targeted)
                        this.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                            header: 'Make field Required',
                            text: 'This block visibility is controlled by a conditional logic, by enabling "Required" property it will remove the conditional logic applied to it',
                            confirmBtnText: 'Yes, make it Required',
                            callback: () => {
                                this.$store.dispatch('edit/conditional/removeConditionForField', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
                                this.fieldObj.properties.required = v
                            }
                        })
                    else
                        this.fieldObj.properties.required = v
                }
            }
        },
        methods: {
            addConditionalLogic() {
                this.$store.dispatch('edit/conditional/openConditionalLogicEditor', this.fieldId)
            },

            openProperties() {
                this.showProperties = true
            },
            closeProperties() {
                this.showProperties = false
            },

            removeField() {
                this.$emit('remove-field')
            },
            changeFieldType(fieldType) {
                this.$emit('change-field-type', fieldType)
            },
            duplicateField() {
                this.$emit('duplicate-field')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .interactive-editable-field-footer {
        bottom: 0;
        position: absolute;
        transform-origin: bottom;

        &__actions {
            background-color: white;
            box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.08);
            border-radius: 100px;
            padding: 7px 15px;
            display: flex;
            align-items: center;

            margin-bottom: 20px;

            span {
                min-width: 18px;
                text-align: end;
            }
        }

        &__required {
            font-size: .8rem;
        }

        &__properties {
            position: absolute;
            left: 100%;
            bottom: 20px;
            margin-left: 12px;
            z-index: 15;
        }
    }
</style>