var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" Conditional logic "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_c('XIcon')], 1)], 1), _c('v-card-subtitle', [_vm._v(" Personalize your form's experience based on people's responses ")]), _c('v-card-text', [_c('div', {
    staticClass: "conditional-logic-editor mt-4"
  }, [_c('v-text-field', {
    staticClass: "conditional-logic-editor__conditional-field",
    attrs: {
      "value": _vm.getConditionalField.title,
      "outlined": "",
      "disabled": "",
      "dense": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c(_vm.getConditionalFieldConst.ICON_COMPONENT, {
          tag: "component"
        }), _vm._v(" " + _vm._s(_vm.allFieldsWithEnumeration[_vm.getCurrentConditionalFieldId].formattedLocation) + " ")];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "conditional-logic-editor__expression-wrapper"
  }, [_c(_vm.getConditionalFieldConst.CONDITIONAL_LOGIC.EXPRESSION_COMPONENT, {
    tag: "component",
    attrs: {
      "expression-obj": _vm.newFieldCondition.expression
    }
  })], 1), _c('div', {
    staticClass: "conditional-logic-editor__target-top-container"
  }, [_c('div', {
    staticClass: "conditional-logic-editor__target-action-wrapper"
  }, [_vm._v(" Then "), _c('ConditionalThenActionSelect', {
    attrs: {
      "target-obj": _vm.newFieldCondition.if_target,
      "actions": _vm.targetActions
    }
  })], 1), !_vm.elseTargetEnabled ? _c('span', {
    staticClass: "conditional-logic-editor__target-secondary-action",
    on: {
      "click": _vm.enableElseTarget
    }
  }, [_vm._v(" + Else Condition ")]) : _vm._e()]), _c('ConditionalTargetSelect', {
    attrs: {
      "condition-field-id": _vm.getCurrentConditionalFieldId,
      "target-obj": _vm.newFieldCondition.if_target,
      "another-targets": [].concat(_vm.newFieldCondition.else_target ? [_vm.newFieldCondition.else_target] : [])
    }
  }), _vm.elseTargetEnabled ? [_c('div', {
    staticClass: "conditional-logic-editor__target-top-container mt-4"
  }, [_c('div', {
    staticClass: "conditional-logic-editor__target-action-wrapper"
  }, [_vm._v(" Else "), _c('ConditionalThenActionSelect', {
    attrs: {
      "target-obj": _vm.newFieldCondition.else_target,
      "actions": _vm.targetActions
    }
  })], 1), _c('span', {
    staticClass: "conditional-logic-editor__target-secondary-action",
    on: {
      "click": _vm.disableElseTarget
    }
  }, [_c('Trash2Icon'), _vm._v(" Remove else ")], 1)]), _c('ConditionalTargetSelect', {
    attrs: {
      "condition-field-id": _vm.getCurrentConditionalFieldId,
      "target-obj": _vm.newFieldCondition.else_target,
      "another-targets": [].concat(_vm.newFieldCondition.if_target ? [_vm.newFieldCondition.if_target] : [])
    }
  })] : _vm._e()], 2)]), _c('v-card-actions', [_c('v-dialog', {
    attrs: {
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_vm.getFieldCondition ? _c('span', _vm._g({
          staticClass: "conditional-logic-editor__target-secondary-action"
        }, on), [_c('TrashIcon'), _vm._v(" Remove condition ")], 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-card', [_c('v-card-title', [_vm._v(" Delete Conditional Logic? "), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.removeConditionDialogModel = false;
            }
          }
        }, [_c('XIcon')], 1)], 1), _c('v-card-text', [_vm._v(" Are you sure you want to delete this Conditional Logic statement? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": function ($event) {
              _vm.removeConditionDialogModel = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "red_400",
            "rounded": ""
          },
          on: {
            "click": function () {
              _vm.removeConditionDialogModel = false;
              _vm.removeCondition();
            }
          }
        }, [_vm._v(" Delete ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.removeConditionDialogModel,
      callback: function ($$v) {
        _vm.removeConditionDialogModel = $$v;
      },
      expression: "removeConditionDialogModel"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_vm._v("Cancel ")]), _vm.getFieldCondition ? _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": "",
      "disabled": _vm.$v.$invalid || !_vm.isCurrentConditionChanged
    },
    on: {
      "click": _vm.applyCondition
    }
  }, [_vm._v("Save ")]) : _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": "",
      "disabled": _vm.$v.$invalid
    },
    on: {
      "click": _vm.applyCondition
    }
  }, [_vm._v("Set Condition ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }