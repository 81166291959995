var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-bar_profile"
  }, [_c('div', {
    staticClass: "support-btn"
  }, [_c('v-fade-transition', [_c('f-hover-tooltip', {
    attrs: {
      "left": "",
      "text": "Support"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "navigation-btn support",
          attrs: {
            "active-class": "navigation-btn--active",
            "exact": "",
            "text": ""
          },
          on: {
            "click": _vm.toggleCrispChat
          }
        }, on), [_c('MessageCircleIcon', {
          attrs: {
            "stroke-width": "1.5",
            "size": "16"
          }
        })], 1)];
      }
    }])
  })], 1)], 1), _c('v-menu', {
    attrs: {
      "min-width": "240",
      "nudge-bottom": "25px",
      "offset-y": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({
          staticClass: "app-bar_profile__user-info"
        }, on), [_c('span', {
          staticClass: "app-bar_profile__name"
        }, [_vm._v(" " + _vm._s(_vm.shortenedName) + " ")]), _c('v-avatar', {
          attrs: {
            "size": "40px"
          }
        }, [_c('img', {
          attrs: {
            "src": _vm.getCurrentCognitoUser ? _vm.getCurrentCognitoUser.attributes.picture : ''
          }
        })]), _c('ChevronDownIcon', {
          staticClass: "dropdown-menu-chevron",
          class: {
            'dropdown-menu-chevron--up': _vm.menuModel
          },
          attrs: {
            "size": "16"
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.menuModel,
      callback: function ($$v) {
        _vm.menuModel = $$v;
      },
      expression: "menuModel"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.profileDropdownOptions, function (option, key) {
    return [option === 'divider' ? _c('v-divider', {
      key: key
    }) : _c('v-list-item', {
      key: key,
      on: {
        "click": option.action
      }
    }, [_c('v-list-item-icon', [_c(option.icon, {
      tag: "component",
      attrs: {
        "stroke-width": "1.5",
        "size": "16"
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(option.name))])], 1)], 1)];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }