var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditableFieldPropertiesWindowTemplate', {
    on: {
      "close-properties": _vm.closeProperties
    }
  }, [_c('f-select', {
    staticClass: "editable-field-properties__change-field-type-select rounded-lg mb-6",
    attrs: {
      "attach": ".editable-field-properties__change-field-type-select",
      "value": _vm.fieldObj.field_type,
      "menu-props": {
        nudgeBottom: 42
      },
      "item-color": "blue_100",
      "items": _vm.constants.ALL_FIELD_TYPES,
      "item-value": function (item) {
        return item.FIELD_TYPE;
      },
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "input": _vm.changeFieldType
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(item.ICON_COMPONENT, {
          tag: "component",
          attrs: {
            "width": "26",
            "height": "26"
          }
        }), _c('span', {
          staticClass: "ml-3 black--text"
        }, [_vm._v(_vm._s(item.READABLE_NAME))])];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item,
          attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-list-item', _vm._b({
          on: {
            "click": function ($event) {
              return _vm.processFieldTypeChanging(on.click);
            }
          }
        }, 'v-list-item', attrs, false), [_c('v-list-item-icon', [_c(item.ICON_COMPONENT, {
          tag: "component",
          attrs: {
            "width": "26",
            "height": "26"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.READABLE_NAME))])], 1)], 1)];
      }
    }])
  }), _c('EditableFieldPropertiesListGenerator', {
    key: _vm.fieldObj.field_type,
    attrs: {
      "field-id": _vm.fieldId,
      "field-properties-obj": _vm.fieldObj.properties,
      "field-base-properties": _vm.constants.FIELD_FROM_TYPE[_vm.fieldObj.field_type].BASE_PROPERTIES
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }