import constants from '@/constants/constants'
import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            form_submission: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }


    return {
        actions: {
            generateFormSubmission: ({commit}, formSourceJSON) => {
                let submission = {}

                Object.entries(formSourceJSON.fields)
                    .forEach(([fieldID, fieldObj]) => {
                        if (constants.FIELD_FROM_TYPE[fieldObj.field_type].WITH_SUBMISSION)
                            submission[fieldID] = constants.FIELD_FROM_TYPE[fieldObj.field_type].generateSubmissionObj(fieldObj)
                    })

                commit('SET_FORM_SUBMISSION', submission)
            },
            updateFormSubmissionField: ({commit, parentDispatch, getters, parentGetters}, {fieldID, updatedSubmission}) => {
                commit('SET_FORM_SUBMISSION', {...getters.getFormSubmission, [fieldID]: updatedSubmission})
                parentDispatch('submission_metrics/formSubmissionChanged')

                
                // auto scroll
                {
                    if (parentGetters.isFormInteractive && parentGetters.getFormSourceJSON.settings.general_settings.auto_scroll) {
                        const fieldTypeConst = constants.FIELD_FROM_TYPE[parentGetters.getFormSourceJSON.fields[fieldID].field_type]

                        if (fieldTypeConst.AUTO_SCROLL_HANDLER && fieldTypeConst.AUTO_SCROLL_HANDLER?.(updatedSubmission, parentGetters.getFormSourceJSON.fields[fieldID]))
                           parentDispatch('goToNextPage')
                    }
                }
            },
        }
    }
}