<script>
  import {
    computed,
    inject,
    onBeforeUnmount,
    onMounted,
  } from '@vue/composition-api'

  import {
    submittable_field_view_mixin,
    useSubmittableFieldViewExtension,
  } from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
  import { useMapActions } from '@/xstore'

  export default {
    name: 'GenericSubmittable__Selection',
    components: {
      GenericFrontView: () => import('./GenericSelectionFieldFrontView'),
      CheckIcon: () => import('vue-feather-icons/icons/CheckIcon'),
    },
    mixins: [submittable_field_view_mixin],
    setup(props) {
      const extension = useSubmittableFieldViewExtension(props)
      const isFormInteractive = extension.isFormInteractive

      const { goToNextPage } = useMapActions(
        inject('formSubmitStoreModulePath'),
        ['goToNextPage']
      )

      function keydownListener(e) {
        if ((e.which || e.keyCode) === 13) goToNextPage()
      }

      onMounted(() => {
        if (isFormInteractive.value)
          document.body.addEventListener('keydown', keydownListener)
      })

      onBeforeUnmount(() => {
        if (isFormInteractive.value)
          document.body.removeEventListener('keydown', keydownListener)
      })
      const sortedOptions = computed(() => {
        const formattedOptions = props.sourceObj.content.options.map(
          (_, index) => index
        )
        if (props.sourceObj.properties.randomize)
          return formattedOptions.sort(() => Math.random() - 0.5)
        return formattedOptions
      })

      return {
        sortedOptions,
        ...extension,
      }
    },
    computed: {
      radio() {
        return false
      },
    },
    methods: {
      optionInput() {
        throw '[Selection]: method "optionInput" must be overwritten'
      },
    },
  }
</script>

<template>
  <GenericFrontView
    :options-list="sortedOptions"
    :model="submissionObj.value"
    :radio="radio"
    :option-input="index => optionInput(index, submissionObj)"
    :field-obj="sourceObj"
    :is-form-static="isFormStatic"
    :is-form-interactive="isFormInteractive"
  >
    <template
      v-if="isFormInteractive"
      #option-append="{ active }"
    >
      <CheckIcon
        v-if="active"
        class="selection-field-view__selection-field-option__check-icon"
      />
    </template>
  </GenericFrontView>
</template>
