var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericSelectionSummaryFieldView', _vm._b({
    attrs: {
      "optionsFormatter": _vm.optionsFormatter
    }
  }, 'GenericSelectionSummaryFieldView', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }