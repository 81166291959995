import {mapGetters} from '@/xstore'
const deepEqual = require('deep-equal')
import { debounce } from 'lodash'

export const editable_field_watcher_mixin = {
    components: {
        GenericField: () => import('@/components/FormGenericComponents/GenericField/GenericField'),
        EditableFieldViewsGenerator: () => import('@/components/FormEditor/EditableFields/EditableFieldViewsGenerator'),
    },
    props: {
        fieldId: {
            type: String,
            required: true
        }
    },
    data: vm => ({
        fieldObj: vm.Utils.deepCloneObject(vm.$store.getters['edit/getFormJSON'].fields[vm.fieldId]),
        unwatch: vm.$watch(
            () => vm.$store.getters['edit/getFormJSON'].fields[vm.fieldId],
            v => {
                if (!deepEqual(v, vm.fieldObj))
                    vm.fieldObj = vm.Utils.deepCloneObject(v)
            },
            {deep: true}
        )
    }),
    computed: {
        ...mapGetters('edit', ['getFormJSON']),
        ...mapGetters('edit/conditional', ['getTargetedAndConditionedFields']),
        fieldTypeConst() {
            return this.constants.FIELD_FROM_TYPE[this.fieldObj.field_type]
        },
    },
    watch: {
        fieldObj: {
            deep: true,
            handler: debounce(function (v) {
                if (!deepEqual(this.getFormJSON.fields[this.fieldId], v))
                    this.$store.dispatch('edit/fields/changeFormField', {
                        fieldID: this.fieldId,
                        fieldObj: this.Utils.deepCloneObject(v)
                    })
            }, 300, {maxWait: 400})
        },
    },
    beforeDestroy() {
        this.unwatch()
    },
    methods: {
        changeFieldType(fieldType) {
            if (fieldType !== this.fieldObj.field_type)
                this.$store.dispatch('edit/fields/changeFieldType', {fieldID: this.fieldId, newType: fieldType})
        },
        removeField() {
            if (this.getTargetedAndConditionedFields[this.fieldId].conditioned)
                this.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                    header: 'Remove Block',
                    text: 'This block has a conditional logic statement, by removing block it will remove the conditional logic applied to it',
                    confirmBtnText: 'Yes, remove it',
                    callback: () => this._removeField()
                })
            else if (this.getTargetedAndConditionedFields[this.fieldId].targeted)
                this.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                    header: 'Remove Block',
                    text: 'This block is controlled by a conditional logic, by removing this block it will remove the conditional logic applied to it',
                    confirmBtnText: 'Yes, remove it',
                    callback: () => {
                        this.$store.dispatch('edit/conditional/removeConditionForField', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
                        this._removeField()
                    }
                })
            else
                this._removeField()
        },
        _removeField() {
            this.$store.dispatch('edit/fields/removeFormField', this.fieldId)
        },
        duplicateField() {
            this.$store.dispatch('edit/fields/duplicateField', this.fieldId)
        }
    }
}