var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('LogoComponent', {
    staticClass: "logo-link ml-6",
    nativeOn: {
      "click": function ($event) {
        return _vm.goToDashboard.apply(null, arguments);
      }
    }
  }), _c('f-badge', {
    staticStyle: {
      "margin-left": "5px",
      "font-size": "0.6rem"
    },
    attrs: {
      "gray": ""
    }
  }, [_vm._v(" BETA ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }