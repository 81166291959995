import OwnersFormActions from '@/utils/actions/owners_form_actions'
import constants from "@/constants/constants"
import Utils from '@/utils/misc'
import router from '@/router'
const deepEqual = require('deep-equal')

import form_edit_hovered_static_field_constructor from './modules/form_edit_hovered_static_field_constructor'
import form_edit_fsp_themes_store from './modules/form_edit_fsp_themes_store'
import form_edit_save_store from './modules/form_edit_save_store'
import form_edit_conditional_logic_store from './modules/form_edit_conditional_logic_store'
import form_edit_background_image_preview_store from './modules/form_edit_background_image_preview_store'
import form_edit_cover_image_preview_store from './modules/form_edit_cover_image_preview_store'
import form_edit_publish_store from './modules/form_edit_publish_store'
import form_edit_pages_navigation_store from './modules/form_edit_pages_navigation_store'
import form_edit_fields_edit_store from '@/store/form_edit/modules/form_edit_fields_edit_store'
import form_edit_pages_edit_store from '@/store/form_edit/modules/form_edit_pages_edit_store'
import form_submit_store from '@/store/form_submit/form_submit_store'
import form_edit_paid_features_monitoring_store from '@/store/form_edit/modules/form_edit_paid_features_monitoring_store'
import form_edit_interactive_homepage_image_store
    from "@/store/form_edit/modules/form_edit_interactive_homepage_image_store";

import { useFieldsEnumerationGettersExtension } from '@/store/util_modules/fields_enumeration_getters'
import { useFSPStoreModule } from '@/FSP/fsp_store_module'
import { SMAGableExtensions, SMAGableUtils } from '@/xstore/utils/smagable'
import { CustomSBGenerator } from '@/xstore/utils/state_based_generation'
import { ResettableExtensions } from '@/xstore/utils/resettable'
import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'
import { ModuleableExtensions, ModuleableUtils } from '@/xstore/utils/moduleable'
import { StoreableExtensions, StoreableUtils } from '@/xstore/utils/storeable'

export default ({useExtension}) => {
    {
        const state = {
            form_db_data: undefined,
            form_json: undefined
        }
        useExtension(SMAGableExtensions.SMable, {state})
        useExtension({
            SBGenerator: (() => {
                const CustomGenerator = CustomSBGenerator(
                    {
                        category: 'state',
                        name: v => SMAGableUtils.stateNameFormatter(v) + '_initial',
                        value: (_, v) => v
                    },
                    {
                        category: 'mutations',
                        name: variableName => SMAGableUtils.mutationNameFormatter(variableName) + '_INITIAL',
                        value: variableName => (state, v) => {
                            state[SMAGableUtils.stateNameFormatter(variableName) + '_initial'] = Utils.deepCloneObject(v)
                            state[SMAGableUtils.stateNameFormatter(variableName)] = v
                        }
                    },
                    {
                        category: 'actions',
                        name: variableName => SMAGableUtils.actionNameFormatter(variableName) + 'Initial',
                        value: variableName => ({commit}, v) => commit(SMAGableUtils.mutationNameFormatter(variableName) + '_INITIAL', v)
                    }
                )
                CustomGenerator.key = 'Custom form_edit generator'
                return CustomGenerator
            })()
        }, {state})
    }

    {
        const state = {
            form_version_id: undefined,
            is_form_fetching: false
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }


    useExtension(StoreableExtensions.Default)
    useExtension(ModuleableExtensions.Default)
    useExtension(PathableExtensions.Default)
    useExtension(ResettableExtensions.Default)

    useExtension(useFieldsEnumerationGettersExtension({
        isFormStaticGetterName: 'isFormStatic',
        isFormInteractiveGetterName: 'isFormInteractive',
        formJSONGetterName: 'getFormJSON'
    }))

    return {
        modules: {
            FSP: useFSPStoreModule({
                stylePreferencesGetterName: 'FSP_themes/getCurrentStylePreferences',
                excludeGenericFieldClassConnectors: ['submittable']
            }),
            hovered_static_field_constructor: form_edit_hovered_static_field_constructor,
            FSP_themes: form_edit_fsp_themes_store,
            save: form_edit_save_store,
            publish: form_edit_publish_store,
            conditional: form_edit_conditional_logic_store,
            pages_navigation: form_edit_pages_navigation_store,
            fields: form_edit_fields_edit_store,
            pages: form_edit_pages_edit_store,
            paid_features_monitoring: form_edit_paid_features_monitoring_store,

            overlay: ({useExtension}) => useExtension(SMAGableExtensions.SMAGable, {state: {overlay: false}}),

            loading_screen: ({useExtension}) => {
                useExtension(SMAGableExtensions.SMGable, {state: {is_form_loading_screen_shown: false}})

                return {
                    actions: {
                        showFormLoadingScreen: ({commit}) => commit('SET_IS_FORM_LOADING_SCREEN_SHOWN', true),
                        hideFormLoadingScreen: ({commit}) => commit('SET_IS_FORM_LOADING_SCREEN_SHOWN', false)
                    }
                }
            },

            // only for static forms
            cover_image_preview: form_edit_cover_image_preview_store,

            // only for interactive forms
            background_image_preview: form_edit_background_image_preview_store,
            homepage_image_preview: form_edit_interactive_homepage_image_store,
        },
        mutations: {
            UPDATE_STYLE_PREFERENCES: (state, update) => Object.assign(state.form_json.style_preferences, update),
        },
        actions: {
            updateFormDBData: ({commit, getters}, dataToUpdate) => commit('SET_FORM_DB_DATA', {...getters.getFormDBData, ...dataToUpdate}),
            processFormEditorEntry: async ({dispatch, getters}, {previousRoute, formID}) => {
                if (
                    (previousRoute.name === constants.PAGE_NAME_FORM_TYPE_SELECTION || previousRoute.name === constants.PAGE_NAME_FORM_TEMPLATE_SELECTION)
                    && getters.getFormDBData
                    && getters.getFormJSON
                ) {
                    //
                } else if (previousRoute.name !== constants.PAGE_NAME_SUBMISSIONS_ANALYTICS) {
                    dispatch('loading_screen/showFormLoadingScreen')
                    await dispatch('fetchFormWithFormExistenceCheckAndLoadingScreenDelay', formID)
                    dispatch('submissions/prefetchSubmissionsAfterFormEditorEntry', null, {root: true})
                } else {
                    await dispatch('fetchFormWithFormExistenceCheckAndLoadingScreenDelay', formID)
                }
            },
            prefetchFormBeforeEntrySubmissionsAnalytics: ({dispatch}, formID) => dispatch('fetchFormWithFormExistenceCheckAndLoadingScreenDelay', formID),
            fetchFormWithFormExistenceCheckAndLoadingScreenDelay: async ({commit, getters, dispatch}, formID) => {
                commit('SET_IS_FORM_FETCHING', true)
                const formData = await OwnersFormActions.fetchFormData(formID)

                if (formData === null) return router.push({name: constants.PAGE_NAME_NOT_FOUND})

                commit('SET_FORM_DB_DATA_INITIAL', formData)
                dispatch('FSP_themes/fetchCurrentFormTheme', formData)
                dispatch('FSP_themes/fetchAllThemes')
                const {versionID, json: formJson} = await OwnersFormActions.fetchFormJson(formData.path)
                commit('SET_FORM_JSON_INITIAL', formJson)
                dispatch('FSP/initializeFSP')

                commit('SET_FORM_VERSION_ID', versionID)

                let formLoadingScreenDuration

                /** navigating from SubmissionsAnalytics to FormEditor and form was changed **/
                if (
                    !deepEqual(formJson, getters.getFormJSON)
                    && router.currentRoute.name === constants.PAGE_NAME_FORM_EDITOR
                    && router.prevRoute.name === constants.PAGE_NAME_SUBMISSIONS_ANALYTICS
                ) formLoadingScreenDuration = 3000
                /** navigating to FormEditor from each place except SubmissionsAnalytics **/
                else if (
                    ![router.prevRoute.name, router.currentRoute.name].includes(constants.PAGE_NAME_SUBMISSIONS_ANALYTICS)
                    && router.currentRoute.name === constants.PAGE_NAME_FORM_EDITOR
                ) formLoadingScreenDuration = 1000
                /** navigating from FormEditor to SubmissionsAnalytics and form was changed **/
                else if (
                    (
                        router.prevRoute.name !== constants.PAGE_NAME_FORM_EDITOR
                        && !('id' in getters.getFormJSON)
                    ) || (
                        !deepEqual(formJson, getters.getFormJSON)
                        && router.currentRoute.name === constants.PAGE_NAME_SUBMISSIONS_ANALYTICS
                        && router.prevRoute.name === constants.PAGE_NAME_FORM_EDITOR
                    )
                ) formLoadingScreenDuration = 1000

                if (formLoadingScreenDuration) {
                    dispatch('loading_screen/showFormLoadingScreen')
                    await Promise.all([
                        new Promise(resolve => setTimeout(() => {
                            commit('SET_IS_FORM_FETCHING', false)
                            resolve()
                        }, Math.round(formLoadingScreenDuration / 2))),
                        new Promise(resolve => setTimeout(resolve, formLoadingScreenDuration)),
                    ])
                } else
                    commit('SET_IS_FORM_FETCHING', false)

                dispatch('loading_screen/hideFormLoadingScreen')
            },
            simulateFormFetching: ({commit, dispatch}, {formData, formJson}) => {
                dispatch('loading_screen/showFormLoadingScreen')
                commit('SET_FORM_DB_DATA_INITIAL', formData)
                commit('SET_FORM_JSON_INITIAL', formJson)

                dispatch('FSP/initializeFSP')
                setTimeout(() => {
                    dispatch('loading_screen/hideFormLoadingScreen')
                }, 5000)
            },

            openFormSubmitPreview: async ({getters, dispatch}, cb) => {
                if (!('submit_preview' in getters[ModuleableUtils.moduleGetterName]._children)) {
                    getters[StoreableUtils.storeGetterName].registerModule([...getters[PathableUtils.pathGetterName], 'submit_preview'], form_submit_store)
                }
                await dispatch('submit_preview/pushFormSource', {
                    formJSON: getters.getFormJSON,
                    formData: getters.getFormDBData,
                    themeObj: getters['FSP_themes/selected/getCurrentSelectedTheme'],
                    withSubmit: true
                })

                cb()

                await dispatch('save/saveFormJSON')
                await dispatch('submit_preview/setFormVersionId', getters.getFormVersionId)
            },
            closeFormSubmitPreview: ({getters}) => {
                getters[StoreableUtils.storeGetterName].unregisterModule([...getters[PathableUtils.pathGetterName], 'submit_preview'])
            },

            updateStylePreferences: ({commit}, update) => commit('UPDATE_STYLE_PREFERENCES', update),
            changeFormAttrs: ({commit, getters}, newAttrs) => {
                commit('SET_FORM_JSON', {...getters.getFormJSON, ...newAttrs})
            },
        },
        getters: {
            getFormDBData: ({state}) => state.form_db_data,
            getInitialFormDBData: ({state}) => state.form_db_data_initial,
            //getFormJSON: ({state}) => state.form_json,
            getFormJSON: ({ state }) => {
                const formJSON = state.form_json;
                if (formJSON && !formJSON?.layout_header_style) {
                  formJSON.layout_header_style = 'visible_header';
                }
                return formJSON;
              },
            getInitialFormJSON: ({state}) => state.form_json_initial,

            isFormDbDataChangedAfterSaving: ({getters}) => !deepEqual(getters.getInitialFormDBData, getters.getFormDBData),
            isFormJsonChangedAfterSaving: ({getters}) => !deepEqual(getters.getInitialFormJSON, getters.getFormJSON),

            isFormStatic: ({getters}) => getters.getFormJSON?.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE,
            isFormInteractive: ({getters}) => getters.getFormJSON?.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE,
            isHomePageHasImage: ({getters}) => !!getters.getFormJSON?.all_pages?.home_page?.imageUrl,
            getPagesList: ({getters}) => {
                let allPagesList = []
                if (getters.getFormJSON?.home_page)
                    allPagesList.push(getters.getFormJSON.home_page)
                if (getters.getFormJSON?.main_pages?.length)
                    allPagesList.push(...getters.getFormJSON.main_pages)
                if (getters.getFormJSON?.finish_page)
                    allPagesList.push(getters.getFormJSON.finish_page)
                return allPagesList
            },
            getCurrentPageAbsoluteIndex: ({getters}) => getters.getPagesList.findIndex(p => p.page_id === getters['pages_navigation/getCurrentPageId']),
        }
    }
}