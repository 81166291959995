import { FieldsEnumerationGettersUtils } from '@/store/util_modules/fields_enumeration_getters'

export default () => {
    return {
        getters: {
            // unpaid field types
            getUnpaidFields: ({parentGetters, rootGetters}) => parentGetters[FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName]
                    ?.filter(({fieldConst}) => fieldConst?.ASSOCIATE_WITH_PAID_FEATURE)
                    ?.filter(({fieldConst: {ASSOCIATE_WITH_PAID_FEATURE}}) => !rootGetters['user/plan/current_subscription/getCurrentSubscriptionFeatures']?.[ASSOCIATE_WITH_PAID_FEATURE]),
            getUnpaidFieldIsInForm: ({getters}) => !!getters.getUnpaidFields.length,

            // conditional logics count
            getFieldsWithConditionalLogic: ({parentGetters}) => Object.values(parentGetters['conditional/getTargetedAndConditionedFields']).filter(fieldConditionalObj => fieldConditionalObj?.conditioned),
            getCountFieldsWithConditionalLogic: ({getters}) => getters.getFieldsWithConditionalLogic?.length,
            getCountFieldsWithConditionalLogicIsMoreThanUserPaid: ({getters, rootGetters}) => getters.getCountFieldsWithConditionalLogic > rootGetters['user/plan/current_subscription/getCurrentSubscriptionFeatures']?.conditional_logic_per_form,

            // for static only: main pages count
            getMainPagesInForm: ({parentGetters}) => parentGetters.getFormJSON?.main_pages,
            getCountMainPagesInForm: ({getters}) => getters.getMainPagesInForm?.length,
            getCountMainPagesInFormMoreThanUserPaid: ({getters, rootGetters, parentGetters}) => parentGetters.isFormStatic ? getters.getCountMainPagesInForm > rootGetters['user/plan/current_subscription/getCurrentSubscriptionFeatures']?.static_form_pages : false,

            // overall fields count
            getFieldsInForm: ({parentGetters}) => parentGetters[FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName],
            getCountFieldsInForm: ({getters}) => getters.getFieldsInForm?.length,
            getCountFieldsInFormMoreThanUserPaid: ({getters, rootGetters}) => getters.getCountFieldsInForm > rootGetters['user/plan/current_subscription/getCurrentSubscriptionFeatures']?.questions_per_form,



            // result
            getIsFormPublicationAllowedWithUsedPaidFeatures: ({getters}) => !getters.getCountFieldsInFormMoreThanUserPaid && !getters.getCountMainPagesInFormMoreThanUserPaid && !getters.getCountFieldsWithConditionalLogicIsMoreThanUserPaid && !getters.getUnpaidFieldIsInForm
        }
    }
}