import GenericFrontView from "@/components/Fields/FieldsViews/StarRating/GenericFrontView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__StarRating',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        const fieldProperties = ctx.props.fieldObj.properties;
        return h(
            GenericFrontView,
            {
                props: {
                    fieldProperties,
                }
            }
        )
    }
}