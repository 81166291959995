import form_metrics_module from '@/store/util_modules/form_metrics_module'
import forms_list_form_preview_store from '@/store/forms_list/modules/forms_list_form_preview_store'

import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            form_db_data: undefined
        }
        useExtension(SMAGableExtensions.SMGable, {state})
    }
    
    return {
        modules: {
            metrics: form_metrics_module,
            preview: forms_list_form_preview_store
        },
        actions: {
            initialSetFormDbData: ({commit, dispatch}, formDbData) => {
                commit('SET_FORM_DB_DATA', formDbData)
                
                dispatch('metrics/setFormId', formDbData.id)
                    .then(() => dispatch('metrics/fetchFormMetrics'))

                return dispatch('preview/initializeFormPreview')
            },
            replaceFormDbData: ({commit, dispatch}, formDbData) => {
                commit('SET_FORM_DB_DATA', formDbData)
                dispatch('metrics/fetchFormMetrics')
                return dispatch('preview/refreshFormPreview')
            }
        }
    }
}