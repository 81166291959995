<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
            <v-spacer/>
            <SummaryChangeSortOrder v-model="sortOrder"/>
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="star-rating-field-view-summary">
                <div class="star-rating-field-view-summary__layout">
                    <template v-if="isEmojiScale">
                        <SummaryRowWithProgress
                                v-for="key in filteredOptions.shown"
                                :key="key"
                                :count="optionsWithPercentage[key].count"
                                :percentage="optionsWithPercentage[key].percentage"
                        >
                            <template #prepend-inner>
                                <div class="mr-3 star-rating-field-view-summary__layout-icon">
                                    {{ fieldTypeConst.BASE_PROPERTIES.emoji_scale.input_group.default.sets[latestSourceObj.properties.emoji_scale][optionsWithPercentage[key].index] }}
                                </div>
                            </template>
                        </SummaryRowWithProgress>
                    </template>
                    <template v-else-if="isScaleIcon">
                        <SummaryRowWithProgress
                                v-for="key in filteredOptions.shown"
                                :key="key"
                                :count="optionsWithPercentage[key].count"
                                :percentage="optionsWithPercentage[key].percentage"
                        >
                            <template #prepend-inner>
                                <div class="d-flex star-rating-field-view__item-container align-center mr-3">
                                    <span class="mr-2 bluish_gray_500--text font-weight-semi-bold">{{ optionsWithPercentage[key].index + 1 }}</span>
                                    <LottieIconPlayer
                                            :json="fieldTypeConst.BASE_PROPERTIES.scale_icon.input_group.default.emojis[latestSourceObj.properties.scale_icon]"
                                            active
                                    />
                                </div>
                            </template>
                        </SummaryRowWithProgress>
                    </template>
                    <div v-if="filteredOptions.hidden.length">
                        <SummaryHiddenAnswers :hidden-count="filteredOptions.hidden.length">
                            <template v-for="key in filteredOptions.hidden">
                                <div
                                        v-if="optionsWithPercentage[key].count"
                                        class="d-flex align-center my-2 star-rating-field-view-summary__hidden-area"
                                        :key="key"
                                >
                                    <span>{{ optionsWithPercentage[key].count }} responses</span>
                                    <span class="mx-1">·</span>
                                    <span v-if="optionsWithPercentage[key].label"
                                          class="mr-2">{{ optionsWithPercentage[key].label }}</span>
                                    <div class="star-rating-field-view-summary__hidden-area-icon" v-if="optionsWithPercentage[key].type === EMOJI_KEY">
                                        {{ optionsWithPercentage[key].emoji }}
                                    </div>

                                    <template v-else-if="optionsWithPercentage[key].type === ICON_KEY">
                                        <span class="mr-1">{{ optionsWithPercentage[key].index + 1 }}/5</span>
                                        <LottieIconPlayer
                                                :json="optionsWithPercentage[key].icon"
                                                active
                                        />
                                    </template>
                                </div>
                            </template>
                        </SummaryHiddenAnswers>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__StarRating",
        components: {
            SummaryRowWithProgress: () => import('@/components/Fields/AnalyticsField/Summary/Utils/SummaryRowWithProgress'),
            LottieIconPlayer: () => import('../Icons/LottieIconPlayer')
        },
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        data: () => ({
            sortOrder: summary_answer_field_view_mixin.data().SORT_ORDER.DEFAULT,
            EMOJI_KEY: 'emoji',
            ICON_KEY: 'icon'
        }),
        computed: {
            isEmojiScale() {
                return this.latestSourceObj.properties.emoji_scale !== null
            },
            isScaleIcon() {
                return this.latestSourceObj.properties.scale_icon !== null
            },
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => value !== null)
            },
            filteredOptions() {
                const result = {
                    shown: [],
                    hidden: []
                }

                Object.entries(this.optionsWithPercentage)
                    .forEach(
                        ([key]) => {
                            if (this.isEmojiScale)
                                if (key.startsWith(this.getKey(this.EMOJI_KEY, this.latestSourceObj.properties.emoji_scale, '')))
                                    result.shown.push(key)
                                else
                                    result.hidden.push(key)
                            else if (this.isScaleIcon)
                                if (key.startsWith(this.getKey(this.ICON_KEY, this.latestSourceObj.properties.scale_icon, '')))
                                    result.shown.push(key)
                                else
                                    result.hidden.push(key)
                        }
                    )

                if (this.sortOrder === this.SORT_ORDER.DESCENDING)
                    result.shown = result.shown
                        .sort((a, b) => this.optionsWithPercentage[b].count - this.optionsWithPercentage[a].count)
                else
                    if (this.isScaleIcon)
                        result.shown = result.shown
                            .sort((a, b) => this.optionsWithPercentage[b].index - this.optionsWithPercentage[a].index)

                return result
            },
            optionsWithPercentage() {
                const result = {}

                this.submissions.forEach(
                    ({submissionObj, versionID}) => {
                        if (submissionObj.value === null)
                            return

                        const inspectingVersion = this.versionedSources[versionID]

                        if (inspectingVersion.properties.emoji_scale !== null) {
                            if (!(this.getKey(this.EMOJI_KEY, inspectingVersion.properties.emoji_scale, 0) in result))
                                Object.assign(result, Object.fromEntries(
                                    this.fieldTypeConst.BASE_PROPERTIES.emoji_scale.input_group.default.sets[inspectingVersion.properties.emoji_scale]
                                        .map((emoji, index) => [this.getKey(this.EMOJI_KEY, inspectingVersion.properties.emoji_scale, index), {
                                            count: 0,
                                            percentage: 0,
                                            index,
                                            type: this.EMOJI_KEY,
                                            emoji
                                        }])
                                ))
                            result[this.getKey(this.EMOJI_KEY, inspectingVersion.properties.emoji_scale, submissionObj.value)].count++
                        } else if (inspectingVersion.properties.scale_icon !== null) {
                            if (!(this.getKey(this.ICON_KEY, inspectingVersion.properties.scale_icon, 0) in result))
                                Object.assign(result, Object.fromEntries(
                                    Array(5).fill(null)
                                        .map((_, index) => [this.getKey(this.ICON_KEY, inspectingVersion.properties.scale_icon, index), {
                                            count: 0,
                                            percentage: 0,
                                            index,
                                            type: this.ICON_KEY,
                                            icon: this.fieldTypeConst.BASE_PROPERTIES.scale_icon.input_group.default.emojis[inspectingVersion.properties.scale_icon]
                                        }])
                                ))
                            result[this.getKey(this.ICON_KEY, inspectingVersion.properties.scale_icon, submissionObj.value)].count++
                        }
                    }
                )

                const totalChoices = Object.values(result).reduce((accum, {count}) => accum + count, 0)

                Object.values(result)
                    .forEach((optionData) => {
                        optionData.percentage = Math.round(optionData.count / totalChoices * 100) || 0
                    })

                return result
            }
        },
        methods: {
            getKey(type, setIndex, index) {
                return `${type}-${setIndex}-${index}`
            }
        }
    }
</script>

<style scoped>

</style>