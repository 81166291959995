export const MousedownOutside = {
    bind: function (el, binding, vnode) {
        el.mousedownOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('mousedown', el.mousedownOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mousedown', el.mousedownOutsideEvent)
    },
}

export default MousedownOutside