<template>
    <router-view/>
</template>

<script>
    export default {
        name: "Checkout",
        created() {
            this.$store.dispatch('user/plan/checkout/initialFetch')
        },
        beforeDestroy() {
            this.$store.dispatch('user/plan/checkout/navigation/handleLeavingCheckoutPage')
        }
    }
</script>

<style lang="scss">
    .checkout-page-heading {
        &--offset-lg {
            margin: 60px auto 36px;
        }

        &__title {
            margin-bottom: 12px;
            text-align: center;

            span {
                font-size: 30px;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: -0.04em;
            }
        }

        &__subtitle {
            text-align: center;

            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #606F7B;
            }
        }
    }
</style>