var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "smart-paragraph-field-view"
  }, [_c('span', {
    staticClass: "smart-paragraph-field-view__content"
  }, [_vm._l(_vm.fieldObj.content, function (element, key) {
    var _vm$submissionObj;
    return [element.type === 'text' ? [_vm._v(_vm._s(element.value))] : element.type === 'break' ? _c('br', {
      key: key
    }) : element.type === 'variable' ? _c('SubmittableVariableContainer', {
      key: key,
      attrs: {
        "value": (_vm$submissionObj = _vm.submissionObj) === null || _vm$submissionObj === void 0 ? void 0 : _vm$submissionObj.value[element.variable_id]
      },
      on: {
        "input": function ($event) {
          return _vm.setValue($event, element.variable_id);
        }
      }
    }) : _vm._e()];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }