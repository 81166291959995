var render = function () {
  var _vm$getCurrentSubscri;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "current-plan-card-block pa-6"
  }, [!_vm.getCurrentSubscriptionPlan ? _c('f-circle-loader', {
    attrs: {
      "size": "3"
    }
  }) : [_c('div', {
    staticClass: "current-plan-card-block__header"
  }, [_c('div', {
    staticClass: "current-plan-card-block__header-plan-name"
  }, [_c('div', {
    staticClass: "settings-view__header current-plan-card-block__header-plan-name-title"
  }, [_c('span', [_vm._v(_vm._s(_vm.getCurrentSubscriptionPlan.name))]), _c('f-badge', {
    attrs: {
      "purple": ""
    }
  }, [_vm._v(_vm._s(_vm.paymentPeriod))])], 1), _c('p', {
    staticClass: "settings-view__subheader"
  }, [_vm._v("Our most popular plan for ....")])]), _c('div', {
    staticClass: "current-plan-card-block__header-plan-price"
  }, [_c('span', [_vm._v("$")]), _c('span', [_vm._v(_vm._s(_vm.getCurrentSubscriptionPrice.amount / 100))]), _c('span', [_vm._v("per " + _vm._s(_vm.getCurrentSubscriptionPrice.recurring_interval))])])]), _c('div', {
    staticClass: "current-plan-card-block__responses"
  }, [_c('div', {
    staticClass: "current-plan-card-block__responses-count"
  }, [_vm._v(" " + _vm._s(_vm.getPlanLimitsStats.submissionsCount) + " of " + _vm._s((_vm$getCurrentSubscri = _vm.getCurrentSubscriptionFeatures) === null || _vm$getCurrentSubscri === void 0 ? void 0 : _vm$getCurrentSubscri.responses_per_month) + " answers ")]), _c('v-progress-linear', {
    attrs: {
      "value": _vm.responsesPercent,
      "color": _vm.progressLinearColor,
      "rounded": "",
      "height": "8"
    }
  })], 1), _c('v-divider'), _c('div', {
    staticClass: "current-plan-card-block__actions"
  }, [_vm.showApplyCouponButton ? _c('div', {
    staticClass: "current-plan-card-block__actions-btn",
    on: {
      "click": _vm.redeemAppSumoPromocode
    }
  }, [_c('span', [_vm._v("Apply coupon")])]) : _vm._e(), _c('v-spacer'), _c('div', {
    staticClass: "current-plan-card-block__actions-btn",
    on: {
      "click": _vm.upgradePlan
    }
  }, [_c('span', [_vm._v("Upgrade plan")]), _c('ArrowUpRightIcon', {
    attrs: {
      "size": "20"
    }
  })], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }