var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveFieldConstructorTemplate', {
    attrs: {
      "field-number": _vm.getExistingFields[_vm.fieldId].index + 1,
      "field-type-const": _vm.constants.FIELD_FROM_TYPE[_vm.getFormSourceJSON.fields[_vm.fieldId].field_type],
      "submittable": ""
    },
    scopedSlots: _vm._u([{
      key: "field-view",
      fn: function () {
        return [_c('SubmittableInteractiveFieldConstructor', {
          attrs: {
            "field-id": _vm.fieldId
          }
        })];
      },
      proxy: true
    }, {
      key: "button-content",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.getExistingFields[_vm.fieldId].index + 1 === Object.keys(_vm.getExistingFields).length ? 'Submit' : 'OK') + " "), _c('ChevronRightIcon')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }