<script>
  export default {
    name: 'StarsIcon',
  }
</script>

<template>
  <svg
    width="23"
    height="26"
    viewBox="0 0 23 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.43526 19.6977C6.98299 19.6977 6.61238 19.3576 6.54956 18.8763C5.92768 14.5896 5.35606 13.9928 1.32329 13.5243C0.858456 13.4666 0.500406 13.0687 0.500406 12.6067C0.500406 12.1382 0.852174 11.7532 1.31701 11.6826C5.32465 11.1115 5.8209 10.6109 6.54956 6.33062C6.63122 5.85574 6.98927 5.51562 7.43526 5.51562C7.89382 5.51562 8.25815 5.85574 8.33353 6.33703C8.94284 10.6173 9.51446 11.2141 13.5409 11.6826C14.0183 11.7468 14.3701 12.1318 14.3701 12.6067C14.3701 13.0687 14.0309 13.4538 13.5535 13.5243C9.53959 14.1083 9.04963 14.6024 8.33353 18.8827C8.2393 19.364 7.87497 19.6977 7.43526 19.6977Z"
      fill="url(#paint0_linear_8110_41433)"
    />
    <path
      d="M18.2611 8.66685C17.9847 8.66685 17.7582 8.459 17.7198 8.16488C17.3398 5.54521 16.9904 5.1805 14.526 4.89422C14.2419 4.85892 14.0231 4.61578 14.0231 4.33342C14.0231 4.04714 14.2381 3.81184 14.5221 3.76871C16.9712 3.41968 17.2745 3.11379 17.7198 0.49805C17.7697 0.207847 17.9885 0 18.2611 0C18.5413 0 18.7639 0.207847 18.81 0.501971C19.1824 3.11771 19.5317 3.48243 21.9923 3.76871C22.2841 3.80792 22.499 4.04322 22.499 4.33342C22.499 4.61578 22.2917 4.85108 22 4.89422C19.547 5.25109 19.2476 5.55306 18.81 8.1688C18.7524 8.46292 18.5298 8.66685 18.2611 8.66685Z"
      fill="url(#paint1_linear_8110_41433)"
    />
    <path
      d="M18.2611 25.9999C17.9847 25.9999 17.7582 25.792 17.7198 25.4979C17.3398 22.8782 16.9904 22.5135 14.526 22.2272C14.2419 22.1919 14.0231 21.9488 14.0231 21.6664C14.0231 21.3802 14.2381 21.1449 14.5221 21.1017C16.9712 20.7527 17.2745 20.4468 17.7198 17.8311C17.7697 17.5409 17.9885 17.333 18.2611 17.333C18.5413 17.333 18.7639 17.5409 18.81 17.835C19.1824 20.4507 19.5317 20.8154 21.9923 21.1017C22.2841 21.1409 22.499 21.3762 22.499 21.6664C22.499 21.9488 22.2917 22.1841 22 22.2272C19.547 22.5841 19.2476 22.8861 18.81 25.5018C18.7524 25.7959 18.5298 25.9999 18.2611 25.9999Z"
      fill="url(#paint2_linear_8110_41433)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8110_41433"
        x1="11.78"
        y1="14.2022"
        x2="0.927111"
        y2="13.6118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#949494" />
        <stop
          offset="0.784737"
          stop-color="#949494"
        />
        <stop
          offset="1"
          stop-color="#949494"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8110_41433"
        x1="20.9162"
        y1="5.30844"
        x2="14.2839"
        y2="4.94764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#949494" />
        <stop
          offset="0.784737"
          stop-color="#949494"
        />
        <stop
          offset="1"
          stop-color="#949494"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8110_41433"
        x1="20.9162"
        y1="22.6414"
        x2="14.2839"
        y2="22.2806"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#949494" />
        <stop
          offset="0.784737"
          stop-color="#949494"
        />
        <stop
          offset="1"
          stop-color="#949494"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
