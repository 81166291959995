var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-composition-card form-composition-card--static"
  }, [_c('div', {
    staticClass: "form-composition-card__header"
  }, [_vm._v(" Pages ")]), _c('div', {
    staticClass: "form-composition-card__content"
  }, [_c('FormPagesListDragManager', {
    attrs: {
      "page-name-getter": _vm.pageNameGetter,
      "page-icon-getter": _vm.pageIconGetter
    },
    on: {
      "current-page-changed": _vm.blurAllNameEditors
    },
    scopedSlots: _vm._u([{
      key: "main-page",
      fn: function (_ref) {
        var _ref$pageData = _ref.pageData,
          number = _ref$pageData.number,
          name = _ref$pageData.name,
          pageId = _ref$pageData.pageId,
          pageIndex = _ref.pageIndex,
          hover = _ref.hover,
          isDragging = _ref.isDragging;
        return [_c('div', {
          staticClass: "page-tab static-page-tab"
        }, [_c('div', {
          staticClass: "static-page-tab-prepend static-page-tab-number"
        }, [_vm._v(" " + _vm._s(number) + " ")]), _c('div', {
          staticClass: "static-page-tab-name"
        }, [_c('PageNameEditor', {
          ref: 'pageNameEditor' + pageIndex,
          attrs: {
            "page-name": name
          },
          on: {
            "input": function ($event) {
              return _vm.changePageName(pageId, $event);
            }
          }
        }), !isDragging && _vm.getFormJSON.main_pages.length !== 1 ? _c('PageActions', {
          attrs: {
            "hover": hover
          },
          on: {
            "rename-page": function ($event) {
              _vm.$refs['pageNameEditor' + pageIndex].startEditing();
            },
            "remove-page": function ($event) {
              return _vm.removePage(pageId);
            }
          }
        }) : _vm._e()], 1)])];
      }
    }, {
      key: "finish-page",
      fn: function () {
        return [_c('div', {
          staticClass: "page-tab static-page-tab"
        }, [_c('div', {
          staticClass: "static-page-tab-prepend static-page-tab-icon"
        }, [_vm._v(" " + _vm._s(_vm.constants.FORM_PAGES_ICONS.FINISH_PAGE) + " ")]), _c('div', {
          staticClass: "static-page-tab-name"
        }, [_vm._v(" " + _vm._s(_vm.getFormJSON.all_pages[_vm.getFormJSON.finish_page.page_id].name) + " ")])])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "form-composition-card__actions"
  }, [_c('v-btn', {
    staticClass: "justify-start px-0",
    attrs: {
      "color": "blue_400",
      "block": "",
      "depressed": "",
      "text": "",
      "tile": "",
      "height": "36"
    },
    on: {
      "click": _vm.addNewPage
    }
  }, [_c('span', {
    staticClass: "subtitle-2 d-flex justify-center align-center static-page-tab-prepend"
  }, [_c('PlusIcon', {
    attrs: {
      "size": "22"
    }
  })], 1), _c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v(" New Page ")])])], 1), _c('GenericStoreDialog', {
    attrs: {
      "store-module-path": "edit/pages/confirmation_dialog"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }