var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.fieldBaseProperties.propertiesArray, function (propertyObj, index) {
    return [propertyObj.key === 'divider' ? _c('v-divider', {
      key: index
    }) : _c('EditableFieldPropertyConstructor', {
      key: index,
      attrs: {
        "field-id": _vm.fieldId,
        "property-name": propertyObj.key,
        "property-value": _vm.fieldPropertiesObj[propertyObj.key],
        "property-const": propertyObj,
        "field-properties-obj": _vm.fieldPropertiesObj
      },
      on: {
        "input": function ($event) {
          return _vm.propertyValueChange(propertyObj.key, $event);
        }
      }
    })];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }