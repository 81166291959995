<template>
    <div>
        <SummaryFieldViewHeading
                :source-obj="latestSourceObj"
                :answered="filteredSubmissions.length"
                :submissions-count="totalResponse"
        />

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="matrix-field-view-summary">
                <div
                        v-for="chartName in Object.keys(formattedSummary)"
                        class="matrix-field-view-summary__row-wrapper"
                        :key="chartName"
                >
                    <div class="matrix-field-view-summary__row-title">
                        {{ chartName }}
                    </div>
                    <div class="matrix-field-view-summary__chart-container">
                        <canvas :ref="chartName"></canvas>
                    </div>
                    <div class="matrix-field-view-summary__legend">
                        <div
                                v-for="(columnData, columnName) in formattedSummary[chartName]"
                                class="matrix-field-view-summary__column-data"
                                :key="columnName + columnData.color + chartName"
                        >
                            <div
                                    class="matrix-field-view-summary__column-color"
                                    :style="{backgroundColor: columnData.color}"
                            >

                            </div>
                            <span class="matrix-field-view-summary__column-name">
                            {{ columnName }}
                        </span>
                            <span>
                            {{ columnData.count }} resp..
                        </span>
                            <span class="matrix-field-view-summary__column-percentage">
                            {{ columnData.percentage }}%
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'
    import Chart from 'chart.js/auto'

    const chartColors = [
      '#FE7F2D',
      '#A1C181',
      '#619B8A',
      '#219ebc',
      '#7678ed',
      '#2176ff',
      '#ff7f51',
      '#f52f57',
      '#4cb6e1',
      '#166281']
    export default {
        name: "Summary__Matrix",
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => Object.values(value).some(a => a.length))
            },
            formattedSummary() {
                const result = {}

                this.submissions.forEach(
                    ({submissionObj, versionID}) => {
                        this.versionedSources[versionID].content.rows
                            .forEach((row) => {
                                if (!(row.text in result))
                                    result[row.text] = {}

                                this.versionedSources[versionID].content.columns
                                    .forEach(
                                        (column, index) => {
                                            if (!(column.text in result[row.text]))
                                                result[row.text][column.text] = {
                                                    count: 0,
                                                    percentage: 0,
                                                    color: index > chartColors.length - 1 ? this.Utils.getRandomHSL() : chartColors[index],
                                                }
                                        }
                                    )
                            })

                        Object.entries(submissionObj.value)
                            .forEach(([submissionRowIndex, submissionRow]) => {
                                submissionRow.forEach(columnIndex => {
                                    result[this.versionedSources[versionID].content.rows[submissionRowIndex].text][this.versionedSources[versionID].content.columns[columnIndex].text].count++
                                })
                            })

                        Object.values(result)
                            .forEach(row => {
                                Object.values(row)
                                    .forEach(column => {
                                        column.percentage = Math.round(column.count / Object.values(row).reduce((accum, {count}) => accum + count, 0) * 100) || 0
                                    })

                            })
                    }
                )


                return result
            }
        },
        watch: {
            formattedSummary: {
                deep: true,
                immediate: true,
                async handler() {
                    await this.$nextTick()

                    Object.entries(this.formattedSummary)
                        .forEach(([chartName, data]) => {
                            new Chart(this.$refs[chartName][0], {
                                type: 'doughnut',
                                data: {
                                    datasets: [{
                                        data: Object.values(data).map(({count}) => count).filter(count => count).length ? Object.values(data).map(({count}) => count) : [1],
                                        ...(() => {
                                            const colors = Object.values(data).map(({color}) => color)
                                            return {
                                                backgroundColor: Object.values(data).map(({count}) => count).filter(count => count).length ? colors : ['#8A8F99'],
                                                borderColor: 'transparent',
                                            }
                                        })()
                                    }]
                                },
                                options: {
                                    animation: {
                                        duration: 0
                                    },
                                    animations: {
                                        colors: false
                                    },
                                    hover: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: {
                                            enabled: false
                                        }
                                    }
                                }
                            })
                        })

                }
            }
        }
    }
</script>