<template>
    <InteractiveFieldConstructorTemplate
            :field-number="getCurrentMainPageIndex + 1"
            :field-type-const="fieldTypeConst"
            editable
    >
        <template #field-view>
            <GenericStoreDialog store-module-path="edit/fields/confirmation_dialog"/>
            <GenericField
                    :modifiers="['interactive', 'editable']"
                    :field-id="fieldId"
                    :field-obj="fieldObj"
                    :field-type-const="fieldTypeConst"
            >
                <EditableFieldViewsGenerator :field-obj="fieldObj" :field-id="fieldId"/>
            </GenericField>
        </template>
        <template #extension>
            <InteractiveFieldActions
                    :field-id="fieldId"
                    :field-obj="fieldObj"
                    @remove-field="removePageWithField"
                    @change-field-type="changeFieldType"
                    @duplicate-field="duplicateField"
            />
        </template>
        <template #button-content>
          <slot name="button-content">
            <span class="mr-2">{{actionButtonName}}</span>
            <ChevronRightIcon/>
          </slot>
        </template>
    </InteractiveFieldConstructorTemplate>
</template>

<script>
    import {editable_field_watcher_mixin} from '@/components/FormEditor/EditableFields/editable_field_watcher_mixin'
    import {mapGetters} from '@/xstore'
    import { computed, provide } from '@vue/composition-api'

    export default {
        name: "InteractiveFieldConstructor",
        components: {
            InteractiveFieldConstructorTemplate: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveFieldConstructorTemplate'),
            EditableFieldViewsGenerator: () => import('@/components/FormEditor/EditableFields/EditableFieldViewsGenerator'),
            InteractiveFieldActions: () => import('./InteractiveFieldActions'),
            GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog'),
            ChevronRightIcon: () => import('vue-feather-icons/icons/ChevronRightIcon'),
        },
        mixins: [editable_field_watcher_mixin],
        computed: {
          ...mapGetters('edit', ['getFormJSON', 'getCurrentPageAbsoluteIndex', 'getCurrentPageID']),
          ...mapGetters('edit/pages_navigation', ['getCurrentMainPageIndex']),
          actionButtonName() {
            if (this.getFormJSON.main_pages.length === this.getCurrentPageAbsoluteIndex) {
              return 'Submit'
            } else {
              return 'OK'
            }
          },
        },
        setup(props) {
            provide('fieldId', computed(() => props.fieldId))
        },
        methods: {
            removePageWithField() {
                this.removeField()
            }
        }
    }
</script>