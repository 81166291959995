import {inject, onMounted} from '@vue/composition-api'
import {isArray} from 'lodash'

export const use_submittable_field_input_autofocus_and_press_enter_mixin = (inputRef, insideRef) => ({
    setup(props, {refs, root}) {
        const storeModulePath = inject('formSubmitStoreModulePath')

        const inputAutofocusOn = {}

        if (root.$store.getters[storeModulePath + '/isFormInteractive']) {
            const scrollingActive = inject('scroller:scrollingActive')

            Object.assign(inputAutofocusOn, {
                keydown: e => {
                    let code = e.which || e.keyCode

                    if (code === 13) { // enter
                        e.preventDefault()
                        e.stopPropagation()

                        root.$store.dispatch(storeModulePath + '/validateCurrentMainPage')
                            .then(r => {
                                if (r)
                                    root.$store.dispatch(storeModulePath + '/goToNextPage')
                            })
                    }
                }
            })

            onMounted(() => {
                if (!scrollingActive.value && inputRef) {
                    if (insideRef && isArray(refs[inputRef].$refs[insideRef]))
                        refs[inputRef]?.$refs[insideRef][0]?.focus();
                    else if (insideRef)
                        refs[inputRef]?.$refs[insideRef]?.focus();
                    else if (isArray(refs[inputRef]))
                        refs[inputRef][0]?.focus()
                    else
                        refs[inputRef]?.focus()
                }
            })
        }

        return {
            inputAutofocusOn
        }
    }
})