<template>
    <div v-if="visible" class="overlay">
      <div class="loader"></div>
    </div>
  </template>
  <script>
  export default {
        name: "LoaderOverlay",
        props: {
          visible: {
            type: Boolean,
            default: false
          }
        },
    }
  </script>

  <style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3d66f6;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>