<template>
    <v-slide-y-transition>
        <div
                v-if="menuModel"
                class="form-parameters-menu"
        >
            <slot :active="menuModel"></slot>
        </div>
    </v-slide-y-transition>
</template>

<script>
    export default {
        name: "FormParametersMenuTemplate",
        props: {
            value: {
                type: Boolean,
                requited: true
            },
            minWidth: {
                type: Number,
                required: true
            }
        },
        computed: {
            menuModel: {
                get: function () {
                    return this.value
                },
                set: function (v) {
                    this.$emit('input', v)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-parameters-menu {
        position: absolute;
        right: calc(100% + 20px);
        top: 0;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 82px -4px rgba(24, 39, 75, 0.12);
        border-radius: 10px;
        overflow: hidden;
    }
</style>