<script>
  export default {
    name: 'FormTypeSelection',
    components: {
      GenericFormCreationLayout: () =>
        import('@/components/FormCreation/GenericFormCreationLayout.vue'),
    },
    computed: {
      cards() {
        const staticFormConst = this.constants.FORM_TYPES.STATIC_FORM
        const interactiveFormConst = this.constants.FORM_TYPES.INTERACTIVE_FORM

        return [
          {
            title: 'Classic Form',
            subtitle: 'Multiple questions on single page',
            imageComponent: staticFormConst.IMG_PATH,
            onSelect: () => this.goToPresetSelectionPage('static'),
          },
          {
            title: 'Interactive Survey',
            subtitle: 'One question at a time',
            imageComponent: interactiveFormConst.IMG_PATH,
            onSelect: () => this.goToPresetSelectionPage('interactive'),
          },
        ]
      },
    },
    methods: {
      goToPresetSelectionPage(formType) {
        this.$router.push({
          name: this.constants.PAGE_NAME_FORM_PRESET_SELECTION,
          params: { form_type: formType },
        })
      },
    },
  }
</script>

<template>
  <GenericFormCreationLayout
    :cards="cards"
    title="What do you want to create?"
    subtitle="Select the type of Form you want to create"
    caption-above-the-title="Step 1 of 3"
  />
</template>
