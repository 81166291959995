import {
    executeGraphQLQueryList,
    executeGraphQLMutationCreate,
    executeGraphQLMutationUpdate,
    unescapeRawData, executeGraphQLQueryGet, executeGraphQLMutationDelete
} from '@/utils/graphql_executors'

import constants from '@/constants/constants'
import {addFontFamilyURL} from '@/utils/misc'

import {
    getFSPTheme,
    listFSPThemes
} from '@formsly/shared/api/graphql'

import {
    createFSPTheme, deleteFSPTheme, updateFSPTheme
} from '@formsly/shared/api/graphql'
import { isString, isArray } from 'lodash'

const unescapeThemesRawData = themeOrThemes => {
    if (!themeOrThemes)
        return themeOrThemes

    if (isArray(themeOrThemes))
        return themeOrThemes.map(unescapeThemesRawData)

    const rawData = isString(themeOrThemes.rawData) ? unescapeRawData(themeOrThemes.rawData) : themeOrThemes.rawData

    const fontUrl = constants.TEXT_EDITOR_CONSTANTS.FONT_URL_BY_NAME?.[rawData?.[constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]]
    if (fontUrl)
        addFontFamilyURL(fontUrl)

    return {...themeOrThemes, rawData}
}


const listPublicThemes = () => executeGraphQLQueryList(listFSPThemes, {private: false})
    .then(unescapeThemesRawData)
const listPrivateThemes = () => executeGraphQLQueryList(listFSPThemes, {private: true})
    .then(unescapeThemesRawData)

const getTheme = id => executeGraphQLQueryGet(getFSPTheme, {id})
    .then(unescapeThemesRawData)

const createTheme = ({name, rawData}) => executeGraphQLMutationCreate(createFSPTheme, {
    name,
    rawData: JSON.stringify(rawData)
}).then(unescapeThemesRawData)

const updateTheme = ({id, name, rawData}) => executeGraphQLMutationUpdate(updateFSPTheme, {
    id,
    name,
    rawData: JSON.stringify(rawData)
}).then(unescapeThemesRawData)

const deleteTheme = id => executeGraphQLMutationDelete(deleteFSPTheme, {id}).then(unescapeThemesRawData)


export default {
    unescapeThemesRawData,

    listPublicThemes,
    listPrivateThemes,

    getTheme,

    createTheme,
    updateTheme,

    deleteTheme
}
