import { RequiredFieldPropertyConst, HideFieldPropertyConst, DividerFieldPropertyConst } from './property'

export class FieldTypeBasePropertiesConst {
    constructor(propertiesArray) {
        this.propertiesArray = propertiesArray
        this.propertiesArray
            .map(propertyDefinition => Object.assign(this, {[propertyDefinition.key]: propertyDefinition}))
    }

    generatePropertiesObj() {
        const propertiesObj = {}

        this.propertiesArray
            .forEach(propertyConst => Object.assign(propertiesObj, propertyConst.generatePropertyObj()))

        return propertiesObj
    }
}

export class FieldTypeBasePropertiesConstWithHide extends FieldTypeBasePropertiesConst {
    constructor(propertiesArray) {
        super([
            ...propertiesArray,
            new DividerFieldPropertyConst(),
            new HideFieldPropertyConst()
        ])
    }
}

export class FieldTypeBasePropertiesConstWithRequired extends FieldTypeBasePropertiesConstWithHide {
    constructor(propertiesArray) {
        super([
            new RequiredFieldPropertyConst(),
            ...propertiesArray
        ])
    }
}