<template>
    <svg width="47" height="58" viewBox="0 0 47 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="46.166" y="1" width="55.693" height="45.1657" rx="6" transform="rotate(90 46.166 1)"
              fill="url(#paint0_linear_2362_29934)" stroke="#E2E8F4"/>
        <rect x="7.70117" y="15.6548" width="5.49576" height="5.49576" rx="2.74788" fill="#2FBBEB"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.3582 17.5632C11.4456 17.6594 11.4394 17.8091 11.3444 17.8976L10.0014 19.1485C9.95413 19.1926 9.89097 19.215 9.82683 19.2105C9.76269 19.2059 9.70323 19.1748 9.66244 19.1245L9.2015 18.5559C9.1196 18.4549 9.13412 18.3058 9.23392 18.2229C9.33372 18.14 9.48102 18.1547 9.56291 18.2557L9.86692 18.6307L11.0279 17.5493C11.1229 17.4608 11.2707 17.4671 11.3582 17.5632Z"
              fill="white"/>
        <rect width="12.0066" height="1.83192" rx="0.91596" transform="matrix(-1 0 0 1 27.6465 17.4868)"
              fill="#E3E9F7"/>
        <rect width="14.942" height="1.83192" rx="0.91596" transform="matrix(-1 0 0 1 23.0723 8.32764)" fill="#E3E9F7"/>
        <rect width="14.942" height="1.5266" rx="0.7633" transform="matrix(-1 0 0 1 23.0723 39.373)" fill="#E3E9F7"/>
        <rect width="6.79183" height="1.83192" rx="0.91596" transform="matrix(-1 0 0 1 14.9219 12.2061)"
              fill="#E3E9F7"/>
        <rect width="14.942" height="1.5266" rx="0.7633" transform="matrix(-1 0 0 1 23.0742 25.4502)" fill="#E3E9F7"/>
        <rect width="6.79183" height="1.5266" rx="0.7633" transform="matrix(-1 0 0 1 14.9238 28.167)" fill="#E3E9F7"/>
        <rect x="8.13086" y="31.9023" width="4.41469" height="2.03755" rx="1.01877" fill="#E3E9F7"/>
        <rect x="14.584" y="31.9023" width="4.41469" height="2.03755" rx="1.01877" fill="#E3E9F7"/>
        <rect x="21.0371" y="31.9023" width="4.41469" height="2.03755" rx="1.01877" fill="#E3E9F7"/>
        <rect x="8.13086" y="46.5054" width="8.1502" height="2.71673" rx="1.35837"
              fill="url(#paint1_linear_2362_29934)"/>
        <defs>
            <linearGradient id="paint0_linear_2362_29934" x1="74.7436" y1="1" x2="74.7436" y2="47.0171"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#FEFEFF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2362_29934" x1="8.11372" y1="47.8639" x2="16.2791" y2="47.8639"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#3D66F6"/>
                <stop offset="1" stop-color="#6789FF"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "StaticFormTemplateImage"
    }
</script>