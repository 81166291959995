<template>
    <v-menu
            v-model="menuModel"
            :open-on-click="false"
            open-on-hover
            @input="resetCopyState"
            :close-on-content-click="false"
            content-class="app-bar-form-editor-form-published-popup"
            offset-y
            bottom
    >
        <template #activator="{on}">
            <div
                    v-on="shareFormVisible ? {} : on"
            >
                <v-btn
                        rounded
                        disabled
                >
                    Published
                </v-btn>
            </div>
        </template>
        <template>
            <v-card class="app-bar-form-editor-form-published-popup__card">
                <v-card-title class="app-bar-form-editor-form-published-popup__card-title">
                    Link
                </v-card-title>
                <v-card-text class="app-bar-form-editor-form-published-popup__card-body">
                                    <span ref="publishedLink"
                                          class="app-bar-form-editor-form-published-popup__card-body-link"
                                          @click="openLink"
                                    >
                                        {{ submitLink }}
                                    </span>
                    <v-btn @click="copy" class="app-bar-form-editor-form-published-popup__card-body-btn" width="60"
                           max-height="24"
                           color="blue_400" rounded
                    >
                        {{ copied ? 'Copied' : 'Copy' }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </template>
    </v-menu>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "LockedPublishButton",
        props: {
            shareFormVisible: Boolean
        },
        data: () => ({
            menuModel: false,
            copied: false
        }),
        computed: {
            ...mapGetters('edit', [
                'getFormDBData',
            ]),
            ...mapGetters('edit/publish', [
                'isPublishedFormWasChanged'
            ]),
            submitLink() {
                if (this.getFormDBData?.id)
                    return window.location.origin + this.$router.resolve({
                        name: this.constants.PAGE_NAME_FORM_SUBMIT,
                        params: {formId: this.getFormDBData.id}
                    }).href
                return ''
            }
        },
        watch: {
            isPublishedFormWasChanged(v) {
                if (v)
                    this.menuModel = false
            },
            menuModel(v) {
                if (!v)
                    this.resetCopyState()
            }
        },
        methods: {
            copy() {
                const range = new Range()
                range.selectNodeContents(this.$refs.publishedLink);
                const getSel = window.getSelection()
                getSel.removeAllRanges();
                getSel.addRange(range);
                document.execCommand('copy');
                getSel.removeAllRanges();
                this.copied = true;
            },
            resetCopyState() {
                this.copied = false
            },
            openLink() {
                window.open(this.submitLink, '_blank')
            }
        }
    }
</script>

<style lang="scss">
    .app-bar-form-editor-form-published-popup {
        &__card-title {
            color: var(--v-gray_500-base);
            font-size: 14px;
            padding-bottom: 0;
        }

        &__card-body {
            display: flex;
            gap: 25px;
            align-items: center;
        }

        &__card-body-link {
            display: block;
            color: #3D66F6;
            width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 13px;
            cursor: pointer;
        }

        &__card-body-btn {
            .v-btn__content {
                font-size: 12px !important;
            }
        }
    }

    div.app-bar-form-editor-form-published-popup.v-menu__content {
        padding-top: 10px;
        box-shadow: 0 8px 0px -6px rgb(24 39 75 / 12%), 0 12px 20px -4px rgb(24 39 75 / 12%) !important;
    }
</style>