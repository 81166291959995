var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveFinishPageTemplate', {
    on: {
      "redirect": _vm.redirect
    },
    scopedSlots: _vm._u([{
      key: "emoji",
      fn: function () {
        return [_vm._v(" 👍 ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.finishTitle)
          }
        })];
      },
      proxy: true
    }, _vm.finishDescription ? {
      key: "description",
      fn: function () {
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.finishDescription)
          }
        })];
      },
      proxy: true
    } : null], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }