import _ from 'lodash'
import { CustomSBGenerator } from '@/xstore/utils/state_based_generation'

const defaultFormatters = {
    stateNameFormatter: variableName => variableName,
    mutationNameFormatter: variableName => `SET_${_.toUpper(variableName)}`,
    actionNameFormatter: variableName => `set${_.upperFirst(_.camelCase(variableName))}`,
    getterNameFormatter: variableName => `get${_.upperFirst(_.camelCase(variableName))}`,
}


const genCollection = (collectionElements, formatters) => {
    const elementsConnector = {
        'S': {
            category: 'state',
            name: formatters.stateNameFormatter,
            value: (_, variableValue) => variableValue
        },
        'M': {
            category: 'mutations',
            name: formatters.mutationNameFormatter,
            value: variableName => (state, v) => state[formatters.stateNameFormatter(variableName)] = v
        },
        'A': {
            category: 'actions',
            name: formatters.actionNameFormatter,
            value: variableName => ({commit}, v) => commit(formatters.mutationNameFormatter(variableName), v)
        },
        'G': {
            category: 'getters',
            name: formatters.getterNameFormatter,
            value: variableName => ({state}) => state[formatters.stateNameFormatter(variableName)]
        }
    }

    const SBGenerator = CustomSBGenerator(...Array.from(collectionElements).map(letter => elementsConnector[letter]))

    SBGenerator.key = `SMAGable (${collectionElements})`

    return {
        SBGenerator
    }
}

const references = {
    SMAGable: 'SMAG',
    SMGable: 'SMG',
    SAGable: 'SAG',
    SMable: 'SM',
    SGable: 'SG',
    Sable: 'S',
}

export const SMAGableExtensions = Object.freeze({
    SMAGable: genCollection(references.SMAGable, defaultFormatters),
    SMGable: genCollection(references.SMGable, defaultFormatters),
    SAGable: genCollection(references.SAGable, defaultFormatters),
    SMable: genCollection(references.SMable, defaultFormatters),
    SGable: genCollection(references.SGable, defaultFormatters),
    Sable: genCollection(references.Sable, defaultFormatters),

    WithNameCustomizer(extension, nameCustomizerFn) {
        const allowedExtensions = Object.fromEntries(
            Object.entries(this)
                .filter(([, v]) => v !== this.WithNameCustomizer)
        )

        let selectedExtensionName

        const extensionAllowed = Object.entries(allowedExtensions)
            .some(([name, fn]) => {
                if (fn === extension) {
                    selectedExtensionName = name
                    return true
                }

                return false
            })


        if (!extensionAllowed)
            throw new Error(`WithNameCustomizer: extension must be one of: ${Object.keys(allowedExtensions).join(', ')}`)

        const selectedExtensionCollectionContent = references[selectedExtensionName]
        const customizedFormatters = Object.fromEntries(
            Object.entries(defaultFormatters)
                .map(([formatterName, formatterFn]) => [formatterName, varName => formatterFn(nameCustomizerFn(varName))])
        )

        return genCollection(selectedExtensionCollectionContent, customizedFormatters)
    }
})

export const SMAGableUtils = Object.freeze({
    ...defaultFormatters
})