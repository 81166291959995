<script>
  import { getBase64ImageFromColor } from '@/utils/misc'
  import { mapActions, mapGetters } from '@/xstore'

  export default {
    name: 'StylePreferencesThemesStore',
    components: {
      MoreHorizontalIcon: () =>
        import('vue-feather-icons/icons/MoreHorizontalIcon'),
      StylePreferencesThemePreview: () =>
        import('./ThemesStoreElements/StylePreferencesThemePreview'),
    },
    computed: {
      ...mapGetters('edit', ['isFormInteractive']),
      ...mapGetters('edit/FSP_themes', ['getPrivateThemes', 'getPublicThemes']),
      ...mapGetters('edit/FSP_themes/selected', [
        'getCurrentSelectedTheme',
        'getCurrentThemesStoreTab',
      ]),
      activeTab: {
        get() {
          return this.getCurrentThemesStoreTab
        },
        set(v) {
          this.setCurrentThemesStoreTab(v)
        },
      },
      getPublicThemesBasedOnFormType() {
        if (this.isFormInteractive) {
          return this.getPublicThemes
        } else {
          return this.getPublicThemes.map(theme => {
            return {
              ...theme,
              rawData: {
                ...theme.rawData,
                answer_color: '#000',
                background_color: '#DCDCDC',
                background_image: getBase64ImageFromColor('#DCDCDC'),
                question_color: '#000',
              },
            }
          })
        }
      },
      getPrivateThemesBasedOnFormType() {
        if (this.isFormInteractive) {
          return this.getPrivateThemes
        } else {
          return this.getPrivateThemes.map(theme => {
            return {
              ...theme,
              rawData: {
                ...theme.rawData,
                answer_color: '#000',
                background_color: '#DCDCDC',
                background_image: getBase64ImageFromColor('#DCDCDC'),
                question_color: '#000',
              },
            }
          })
        }
      },
    },
    methods: {
      ...mapActions('edit/FSP_themes', ['deleteTheme']),
      ...mapActions('edit/FSP_themes/selected', [
        'selectTheme',
        'setCurrentThemesStoreTab',
      ]),
      ...mapActions('edit/FSP_themes/editing', [
        'startPrivateThemeEditing',
        'startNewPrivateThemeEditing',
        'startPublicThemeEditing',
      ]),
    },
  }
</script>

<template>
  <div class="style-preferences-templates-store">
    <v-tabs
      v-model="activeTab"
      grow
      class="blue-tabs"
      height="36"
      hide-slider
      :show-arrow="false"
    >
      <v-tab :ripple="false">
        <span>Gallery</span>
      </v-tab>
      <v-tab :ripple="false">
        <span>My Themes</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="activeTab"
      class="thin-scrollbar"
    >
      <v-tab-item>
        <StylePreferencesThemePreview
          v-for="themeObj in getPublicThemesBasedOnFormType"
          :key="themeObj.id"
          :theme-obj="themeObj"
          action-button-text="Customize"
        >
          <template #action>
            <v-btn
              class="style-preferences-template-preview__action"
              color="blue_400"
              text
              @click="startPublicThemeEditing(themeObj)"
            >
              Customize
            </v-btn>
          </template>
        </StylePreferencesThemePreview>
      </v-tab-item>
      <v-tab-item>
        <v-btn
          color="gray_500"
          text
          class="font-weight-semi-bold"
          @click="startNewPrivateThemeEditing"
        >
          + New Theme
        </v-btn>
        <StylePreferencesThemePreview
          v-for="themeObj in getPrivateThemesBasedOnFormType"
          :key="themeObj.id"
          :theme-obj="themeObj"
          action-button-text="Edit"
        >
          <template #action>
            <v-menu
              transition="scale-transition"
              bottom
              offset-y
            >
              <template #activator="{ on }">
                <v-btn
                  color="blue_400"
                  icon
                  small
                  v-on="on"
                >
                  <MoreHorizontalIcon size="18" />
                </v-btn>
              </template>
              <template #default>
                <v-list dense>
                  <v-list-item @click="startPrivateThemeEditing(themeObj)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="deleteTheme(themeObj)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-menu>
          </template>
        </StylePreferencesThemePreview>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
  .style-preferences-templates-store {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .v-tabs {
      margin: 22px 0;
    }

    .v-tabs-items {
      display: flex;
      flex-direction: column;
      min-height: 0;
      overflow-y: auto;
    }
  }
</style>
