<template>
    <f-dialog
            :value="getDialogModel"
            v-bind="getDialogProps"
            width="690"
            @input="v => !v && closeDialog()"
            :retain-focus="false"
    >
        <template #title>
            <component :is="getDialogTitle"/>
        </template>
        <template #content>
            <component :is="getDialogContent"/>
        </template>
        <template #actions>
            <component :is="getDialogActions"/>
        </template>
    </f-dialog>
</template>

<script>
    import { computed } from '@vue/composition-api'

    export default {
        name: "GenericConfirmationDialog",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            storeModulePath: {
                type: String,
                required: true
            }
        },
        setup(props, {root}) {
            const modulePath = props.storeModulePath.slice(-1) === '/' ? props.storeModulePath : props.storeModulePath + '/'

            return {
                getDialogModel: computed(() => root.$store.getters[modulePath + 'getDialogModel']),
                getDialogTitle: computed(() => root.$store.getters[modulePath + 'getDialogTitle']),
                getDialogContent: computed(() => root.$store.getters[modulePath + 'getDialogContent']),
                getDialogActions: computed(() => root.$store.getters[modulePath + 'getDialogActions']),
                getDialogProps: computed(() => root.$store.getters[modulePath + 'getDialogProps']),

                closeDialog: () => root.$store.dispatch(modulePath + 'closeDialog')
            }
        }
    }
</script>