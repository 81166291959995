<template>
    <f-fluid-container style="max-height: 100vh;">
        <InteractivePageScroller
                ref="pageScroller"
                :current-page-id="cachedPageID"
                :previous-page-id="previousPageID"
                :next-page-id="nextPageID"
                :scrolling-active="scrollingActive"

                @scroll-started="scrollingActive = true"
                @scrolled="scrolled"

                #default="{ pageId }"
        >
            <InteractiveHomePageInterlayer
                    v-if="pageId && getFormSourceJSON.all_pages[pageId].type === constants.FORM_PAGES_TYPES.HOME_PAGE"
                    :page-id="pageId"
                    :key="'home' + pageId"
            />
            <InteractiveMainPage
                    v-else-if="pageId && getFormSourceJSON.all_pages[pageId].type === constants.FORM_PAGES_TYPES.MAIN_PAGE"
                    :page-id="pageId"
                    :key="'main' + pageId"
            />
            <InteractiveFinishPage
                    v-else-if="pageId && getFormSourceJSON.all_pages[pageId].type === constants.FORM_PAGES_TYPES.FINISH_PAGE"
                    :key="'finish' + pageId"
            />
        </InteractivePageScroller>
        <NavigationArrows :disabled="scrollingActive"/>
    </f-fluid-container>
</template>

<script>
    import { useMapActions, useMapGetters } from '@/xstore'
    import { inject } from '@vue/composition-api'

    export default {
        name: "InteractiveFormPagesDisplayManager",
        components: {
            InteractivePageScroller: () => import('./InteractivePageScroller'),
            InteractiveHomePageInterlayer: () => import('./HomePage/InteractiveHomePageInterlayer'),
            InteractiveMainPage: () => import('./MainPage/InteractiveMainPage'),
            InteractiveFinishPage: () => import('./FinishPage/InteractiveFinishPage'),
            NavigationArrows: () => import('../../../Elements/NavigationArrows')
        },
        data: () => ({
            cachedPageID: undefined,
            cachedPageIndex: undefined,
            previousPageID: undefined,
            nextPageID: undefined,

            scrollingActive: false,
            onScrollEndCallbacks: []
        }),
        setup() {
            return {
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getFormSourceJSON',
                    'getCurrentPageID',
                    'getMainPagesWithIndexes',
                ]),
                ...useMapActions(inject('formSubmitStoreModulePath'), [
                    'setCurrentPageID'
                ])
            }
        },
        watch: {
            nextPageID(v) {
                if (v)
                    this.previousPageID = undefined
            },
            previousPageID(v) {
                if (v)
                    this.nextPageID = undefined
            },
            getCurrentPageID(v, oldV) {
                if (!oldV)
                    return
                if (this.checkIsMainPage(v)) {
                    if (this.getMainPagesWithIndexes[v] > this.cachedPageIndex)
                        this.nextPageID = v
                    else
                        this.previousPageID = v
                } else if (this.checkIsHomePage(v)) {
                    this.previousPageID = v
                } else if (this.checkIsFinishPage(v)) {
                    this.nextPageID = v
                }
            }
        },
        async created() {
            await this.setCurrentPageID(this.getFormSourceJSON.home_page.page_id)
            this.clearSiblingPages()
            this.cachedPageID = this.getFormSourceJSON.home_page.page_id
            this.cachedPageIndex = -1
        },
        methods: {
            scrolled(pageID) {
                this.cachedPageID = pageID

                if (this.checkIsHomePage(pageID))
                    this.cachedPageIndex = -1
                else if (this.checkIsMainPage(pageID))
                    this.cachedPageIndex = this.getMainPagesWithIndexes[pageID]
                else if (this.checkIsFinishPage(pageID))
                    this.cachedPageIndex = Infinity

                this.clearSiblingPages()

                this.scrollingActive = false
            },
            clearSiblingPages() {
                this.previousPageID = undefined
                this.nextPageID = undefined
            },
            checkIsHomePage(pageId) {
                return this.getFormSourceJSON.all_pages[pageId].type === this.constants.FORM_PAGES_TYPES.HOME_PAGE
            },
            checkIsMainPage(pageId) {
                return this.getFormSourceJSON.all_pages[pageId].type === this.constants.FORM_PAGES_TYPES.MAIN_PAGE
            },
            checkIsFinishPage(pageId) {
                return this.getFormSourceJSON.all_pages[pageId].type === this.constants.FORM_PAGES_TYPES.FINISH_PAGE
            }
        }
    }
</script>