<template>
  <v-card class="share-form-menu">
    <v-card-title>
      <v-spacer></v-spacer>
      <XIcon @click="$emit('input', false)" size="24" class="cursor-pointer mr-4 mt-2"/>
    </v-card-title>
    <v-card-text>
      <div class="d-flex flex-no-wrap menu-content">
        <div ref="preview-wrapper" class="form-preview__wrapper">
          <f-aspect-ratio-keeper width="16" height="9">
            <div class="form-preview">
              <div class="form-preview-scaler">
                <EmbeddedFormPreview
                    :formJSON="formJSON"
                    :static-form-scale="staticFormScale"
                    :is-form-static="isFormStatic"
                    :is-form-interactive="isFormInteractive"
                    :fsp-module-location-path="fspModuleLocationPath"
                    :interactive-form-scale="interactiveFormScale"
                />
              </div>
            </div>
          </f-aspect-ratio-keeper>
        </div>
        <div class="share-content">
          <v-card-subtitle class="align-center pb-0 grayscale_black--text d-flex flex-row">
            <slot name="subtitle">
            </slot>
          </v-card-subtitle>
          <v-card-title
              class="grayscale_black--text pt-2"

          >
            <slot name="title">

            </slot>
          </v-card-title>
          <v-card-subtitle class="grayscale_black--text">
            Get the link

          </v-card-subtitle>
          <v-text-field class="px-4" ref="link" flat solo outlined persistent-hint :value="shareLink" @copy="onCopy"
                        :hint="copied? 'Copied!' : ''" readonly
                        @focus="$event.target.select()">
            <template slot="append">
              <v-btn fab icon @click="copy">
                <CopyIcon/>
              </v-btn>
            </template>
          </v-text-field>
          <v-card-subtitle class="grayscale_black--text">
            Or share with the world
          </v-card-subtitle>
          <div class="share-content__icons icons">
            <v-btn v-for="(item, index) in shareIcons"
                   :key="index"
                   :href="item.URL"
                   target="_blank"
                   class="pa-0 icons__social"
            >
              <v-icon
                  size="42"
              >
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {computed, ref, onMounted} from "@vue/composition-api";

export default {
  name: "ShareFormMenu",
  components: {
    XIcon: () => import('vue-feather-icons/icons/XIcon'),
    CopyIcon: () => import('vue-feather-icons/icons/CopyIcon'),
    EmbeddedFormPreview: () => import('@/components/Dashboard/EmbeddedFormPreview/EmbeddedFormPreview'),
  },
  props: {
    value: Boolean,

    shareLink: {
      type: String,
      required: true,
    },
    formJSON: {
      type: Object,
      required: true,
    },
    isFormStatic: {
      type: Boolean,
      required: true,
    },
    isFormInteractive: {
      type: Boolean,
      required: true,
    },
    fspModuleLocationPath: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    copied: false,
  }),
  setup(props, {root, refs}) {
    const containerWidth = ref(1920)

    const resizeObserver = root.Utils.useResizeObserver(([{contentRect: {width}}]) => containerWidth.value = width)
    onMounted(() => {
      if (refs['preview-wrapper']) {
        resizeObserver.unobserveAll()
        resizeObserver.observe(refs['preview-wrapper'])
      }
    })
    const formatScaleToStylelike = scale => ({transform: `scale(${scale})`})


    const staticFormScale = computed(() => formatScaleToStylelike(containerWidth.value / 1300))
    const interactiveFormScale = computed(() => formatScaleToStylelike(containerWidth.value / 1920))
    const encodedURIComponentURL =  window.encodeURIComponent(`${props.shareLink}`)
    //console.log(process.env.VUE_APP_FACEBOOK_APP_ID);
    const shareIcons = [
      // {
      //   icon: '$linkedin',
      //   URL: `https://www.linkedin.com/shareArticle?url=${encodedURIComponentURL}`
      // },
      {
        icon: '$twitter',
        URL: `https://twitter.com/intent/tweet?url=${encodedURIComponentURL}`,
      },
      {
        icon: '$whatsapp',
        URL: `https://api.whatsapp.com/send?text=${encodedURIComponentURL}`
      },
      // {
      //   icon: '$facebook',
      //   URL: `https://www.facebook.com/dialog/share?app_id=${window.encodeURIComponent(process.env.VUE_APP_FACEBOOK_APP_ID)}&display=${window.encodeURIComponent('popup')}&href=${window.encodeURIComponent(props.shareLink)}`,
      // },
      {
        icon: '$telegram',
        URL: `https://t.me/share/url?url=${encodedURIComponentURL}`
      },
    ];


    return {
      staticFormScale,
      interactiveFormScale,
      shareIcons
    }
  },
  watch: {
    value(v) {
      if (v)
        document.addEventListener('keyup', this.listenKeypress)
      else
        document.removeEventListener('keyup', this.listenKeypress)
    }
  },
  methods: {
    onCopy() {
      this.copied = true
    },
    copy() {
      this.$refs.link.focus();
      document.execCommand('copy');
      this.copied = true
    },
    listenKeypress(evt) {
      if (evt.key === 'Escape')
        this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.share-form-menu {
  height: 100%;
  border-radius: 0 !important;
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(50px);
  box-shadow: 0px 12px 16px -12px rgba(16, 24, 40, 0.07),
  0px 4px 6px -4px rgba(16, 24, 40, 0.05) !important;

  .menu-content {
    place-content: center;
    column-gap: 58px;

    .share-content {
      & > * {
        padding: 0 !important;
      }

      .v-card__title {
        margin: 0 0 24px;

        & + .v-card__subtitle {
          margin: 0 0 9px;
        }
      }

      .v-text-field {
        ::v-deep .v-input__slot {
          margin-bottom: 0;
        }

        ::v-deep input {
          color: var(--v-blue_400-base);
          font-weight: 500;
        }
      }

      &__icons {
        margin: 8px 0 0;
        display: flex;
        column-gap: 10px;

        .v-icon {
          cursor: pointer;
        }
      }
      .icons {
        &__social {
          min-width: 0;
          padding: 0 !important;
        }
      }
    }

    .form-preview__wrapper {
      flex: 0 1 350px;
      display: flex;
    }

    .form-preview {
      display: flex;
      height: 100%;
      justify-content: center;

      &-scaler {
        width: 1920px;
        height: 1080px;
        transform-origin: top;

        flex: 1 0 1920px;
      }
    }
  }

}
</style>
