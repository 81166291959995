<template>
    <div class="editable-field-property__caption">
        You can hide this question for now.
        <BlockVisibilityIsControlledByConditionalLogicCaption v-bind="$attrs"/>
    </div>
</template>

<script>
    export default {
        name: "HidePropertyCaption",
        components: {
            BlockVisibilityIsControlledByConditionalLogicCaption: () => import('./BlockVisibilityIsControlledByConditionalLogicCaption')
        }
    }
</script>