<template>
    <StaticFinishPageTemplate @redirect="redirect">
        <template #emoji>
            👍
        </template>
        <template #title>
            <span v-html="finishTitle"></span>
        </template>
        <template #description v-if="finishDescription">
            <span v-html="finishDescription"></span>
        </template>
    </StaticFinishPageTemplate>
</template>

<script>
    import {finish_page_submit_mixin} from "../../../../FormGenericComponents/Mixins/finish_page_mixin";

    export default {
        name: "StaticFinishPage",
        mixins: [finish_page_submit_mixin],
        components: {
            StaticFinishPageTemplate: () => import('@/components/FormGenericComponents/StaticForm/StaticFinishPageTemplate'),
        }
    }
</script>

<style scoped>

</style>