var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visibilityControlledByConditionalLogic ? _c('div', {
    staticClass: "editable-field-property__caption red_400--text"
  }, [_vm._v(" Block visibility is controlled by a "), _c('span', {
    staticClass: "blue_400--text cursor-pointer",
    on: {
      "click": _vm.showConditionOwningThisField
    }
  }, [_vm._v(" conditional logic. ")])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }