import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants'

export const GenericSubmittableDropdownFSP = new FieldFSPDefinition({
    FSP_FIELD_TYPE: 'GenericSubmittableDropdown',
    FIELD_VIEW_CLASS: 'generic-submittable-dropdown',
    VARIABLES: [
        {
            selector: ['search-field > .v-input__control > .v-input__slot'],
            properties: {
                background: {
                    default: {
                        fn: 'transparent'
                    }
                }
            }
        },
        {
            selector: ['search-field > .v-input__control > .v-input__slot > .v-input__prepend-inner svg'],
            properties: {
                'margin-right': {
                    default: {
                        fn: '12px'
                    }
                },
                'width': {
                    default: {
                        fn: '16px'
                    }
                },
                'height': {
                    default: {
                        fn: '16px'
                    }
                },
                color: {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                    }
                }
            }
        },
        {
            selector: ['search-field > .v-input__control > .v-input__slot > .v-text-field__slot input'],
            properties: {
                'caret-color': {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                    }
                },
                color: {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                    }
                }
            }
        },
        {
            selector: ['search-field > .v-input__control > .v-input__slot > .v-text-field__slot input::placeholder'],
            properties: {
                color: {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.7})
                    }
                }
            }
        },

        {
            selector: ['menu'],
            properties: {
                'box-shadow': {
                    'default--interactive': {
                        important: true,
                        fn: `0 0 0 1px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()}`
                    }
                },
                'backdrop-filter': {
                    'default--interactive': {
                        fn: 'blur(3px)'
                    }
                },
            }
        },
        {
            selector: ['menu > .v-list.v-sheet'],
            properties: {
                'background': {
                    'default--interactive': {
                        fn: 'none'
                    }
                },
                'background-color': {
                    'default--interactive': {
                        fn: `rgba(0, 0, 0, 0.1)`
                    }
                }
            }
        },
        {
            selector: ['menu > .v-list.v-sheet .v-virtual-scroll'],
            properties: {
                margin: {
                    default: {
                        fn: `0 6px 0 12px`
                    }
                }
            }
        },
        {
            selector: ['menu > .v-list.v-sheet .v-list-item'],
            properties: {
                'margin': {
                    default: {
                        fn: '5px 6px 5px 0'
                    }
                },
                'height': {
                    default: {
                        fn: '40px'
                    }
                },
            }
        },
        {
            selector: ['menu > .v-list.v-sheet .v-list-item::before'],
            properties: {
                'border-radius': {
                    default: {
                        fn: '6px'
                    }
                },
                'background-color': {
                    'default--interactive': {
                        fn: `rgba(0, 0, 0, 0.1)`
                    }
                }
            }
        },
        {
            selector: ['menu > .v-list.v-sheet .v-list-item:hover::before'],
            properties: {
                'background-color': {
                    'default--interactive': {
                        fn: `rgba(0, 0, 0, 0.2)`
                    }
                }
            }
        },
        {
            selector: ['menu > .v-list.v-sheet .v-list-item.v-list-item--active::before'],
            properties: {
                'background-color': {
                    'default--interactive': {
                        important: true,
                        fn: `rgba(0, 0, 0, 0.2)`
                    }
                }
            }
        },

        {
            selector: ['menu > .v-list.v-sheet .v-list-item > .v-list-item__content'],
            properties: {
                'font-size': {
                    default: {
                        fn: '16px'
                    }
                },
                'font-weight': {
                    default: {
                        fn: 400
                    }
                },
                'color': {
                    'default--interactive': {
                        important: true,
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                    }
                }
            }
        },

        {
            selector: ['item-check-icon'],
            properties: {
                'color': {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                    }
                },
                'opacity': {
                    default: {
                        fn: 0
                    },
                    active: {
                        fn: 1,
                        connector: {
                            internal: '.v-list-item.v-list-item--active'
                        }
                    }
                }
            }
        }
    ]
})