<script>
  import { mapGetters } from '@/xstore'

  export default {
    name: 'CurrentPlanCard',
    components: {
      ArrowUpRightIcon: () =>
        import('vue-feather-icons/icons/ArrowUpRightIcon'),
    },
    data() {
      return {
        isCurrentPlanDialogOpened: false,
      }
    },
    computed: {
      ...mapGetters('user/plan/current_subscription', [
        'getCurrentSubscriptionPlan',
        'getCurrentSubscriptionPrice',
        'getCurrentSubscriptionFeatures',

        'getPlanLimitsStats',

        'getAppSumoPromocodeActivated',
      ]),
      ...mapGetters('user', ['getCurrentCognitoUser']),
      paymentPeriod() {
        if (this.getCurrentSubscriptionPlan.name === 'AppSumo') {
          return 'Lifetime'
        }

        return this.getCurrentSubscriptionPrice.recurring_interval === 'month'
          ? 'Monthly'
          : 'Annually'
      },
      progressLinearColor() {
        return this.responsesPercent >= 90 ? '#FF4545' : '#3D66F6'
      },
      responsesPercent() {
        return (
          (this.getPlanLimitsStats.submissionsCount /
            this.getCurrentSubscriptionFeatures?.responses_per_month) *
          100
        )
      },
      showApplyCouponButton() {
        const isGoogleUser =
          this.getCurrentCognitoUser?.username?.startsWith &&
          this.getCurrentCognitoUser.username.startsWith('google_')
        return !this.getAppSumoPromocodeActivated && isGoogleUser
      },
    },
    methods: {
      redeemAppSumoPromocode() {
        this.$store.dispatch(
          'user/plan/current_subscription/showAppSumoPromocodeDialog',
          { closable: true }
        )
      },
      upgradePlan() {
        window.open(
          this.$router.resolve({
            name: this.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN,
          }).href,
          '_blank'
        )
      },
    },
  }
</script>

<template>
  <div class="current-plan-card-block pa-6">
    <f-circle-loader
      v-if="!getCurrentSubscriptionPlan"
      size="3"
    />
    <template v-else>
      <div class="current-plan-card-block__header">
        <div class="current-plan-card-block__header-plan-name">
          <div
            class="settings-view__header current-plan-card-block__header-plan-name-title"
          >
            <span>{{ getCurrentSubscriptionPlan.name }}</span>
            <f-badge purple>{{ paymentPeriod }}</f-badge>
          </div>
          <p class="settings-view__subheader">Our most popular plan for ....</p>
        </div>
        <div class="current-plan-card-block__header-plan-price">
          <span>$</span>
          <span>{{ getCurrentSubscriptionPrice.amount / 100 }}</span>
          <span>per {{ getCurrentSubscriptionPrice.recurring_interval }}</span>
        </div>
      </div>
      <div class="current-plan-card-block__responses">
        <div class="current-plan-card-block__responses-count">
          {{ getPlanLimitsStats.submissionsCount }} of
          {{ getCurrentSubscriptionFeatures?.responses_per_month }}
          answers
        </div>
        <v-progress-linear
          :value="responsesPercent"
          :color="progressLinearColor"
          rounded
          height="8"
        />
      </div>
      <v-divider />
      <div class="current-plan-card-block__actions">
        <div
          v-if="showApplyCouponButton"
          class="current-plan-card-block__actions-btn"
          @click="redeemAppSumoPromocode"
        >
          <span>Apply coupon</span>
        </div>
        <v-spacer />
        <div
          class="current-plan-card-block__actions-btn"
          @click="upgradePlan"
        >
          <span>Upgrade plan</span>
          <ArrowUpRightIcon size="20" />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .current-plan-card-block {
    border: 1px solid #f3f4f6;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);

    &__header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      column-gap: 32px;
      margin-bottom: 28px;

      &-plan-name {
        &-title {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }

      &-plan-price {
        display: flex;
        align-items: center;

        span:first-child {
          font-weight: 600;
          font-size: 27px;
        }

        span:nth-child(2) {
          font-weight: 600;
          font-size: 40px;
        }

        span:nth-child(3) {
          font-size: 16px;
          color: var(--v-grey_500_2-base);
          align-self: flex-end;
        }
      }
    }

    &__responses {
      &-count {
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    .v-divider {
      margin: 24px 0 !important;
    }

    &__actions {
      display: flex;

      &-btn {
        display: flex;
        cursor: pointer;
        align-items: center;
        column-gap: 5px;
        font-weight: 500;
        font-size: 14px;
        color: var(--v-blue_400-base);
      }
    }
  }
</style>
