<template>
    <f-select
            :value="internalValue"
            :class="['rounded-lg', selfId]"
            :attach="'.' + selfId"
            :items="inputConst.items"
            :item-value="item => item.key"
            :item-text="item => item.readable_name"
            :label="label"
            :menu-props="{
                nudgeBottom:42
            }"
            outlined
            hide-details
            dense
            @input="changeSelectValue"
    />
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "Select",
        mixins: [field_property_input_mixin],
        data: vm => ({
            internalValue: vm.inputValue
        }),
        computed: {
            selfId() {
                return `editable_field_properties_item_select_${this.propertyName}_${this.inputName}`
            }
        },
        watch: {
            inputValue(v) {
                this.internalValue = v
            }
        },
        methods: {
            async changeSelectValue(e) {
                this.input(e)
                this.internalValue = e
                await this.$nextTick()
                this.internalValue = this.inputValue
            }
        }
    }
</script>