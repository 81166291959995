<template>
    <AppBarTemplate>
        <template #left>
            <BackToDashboardComponent/>
            <v-divider class="app-bar-form-editor__divider" vertical/>
            <FormEditorAndResponsesNavigationComponent/>
        </template>
        <template #center>
            <span v-if="getFormDBData && ('name' in getFormDBData) && !getIsFormLoadingScreenShown">{{getFormDBData.name}}</span>
        </template>
        <template #right>
            <PreviewButton/>
        </template>
    </AppBarTemplate>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "AppBarSubmissionsAnalytics",
        components: {
            AppBarTemplate: () => import('../AppBarTemplate'),
            BackToDashboardComponent: () => import('../Elements/BackToDashboardComponent'),
            FormEditorAndResponsesNavigationComponent: () => import('../Elements/FormEditorAndResponsesNavigationComponent'),
            PreviewButton: () => import('../Elements/PreviewButton'),
        },
        computed: {
            ...mapGetters('edit', [
                'getFormDBData'
            ]),
            ...mapGetters('edit/loading_screen', [
                'getIsFormLoadingScreenShown'
            ])
        }
    }
</script>