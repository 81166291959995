import { Auth } from 'aws-amplify'

import { setCurrentUserIdentityForAnalytics } from '@/analytics'
import user_plan_store from '@/store/user/modules/user_plan_store'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

let getAuthCredentialsPromise

export default ({ useExtension }) => {
  {
    const state = { current_cognito_user: undefined }

    useExtension(SMAGableExtensions.SMAGable, { state })
    useExtension(ToggleableExtensions.Loadable, { state })
  }

  {
    const state = { cached_auth_credentials: undefined }

    useExtension(SMAGableExtensions.SMGable, { state })
  }

  return {
    modules: {
      plan: user_plan_store,
    },
    actions: {
      authUser: (() => {
        let plansFetched = false
        let currentSubscriptionFetched = false

        return async ({ dispatch, toggleIsLoading, commit }) => {
          await toggleIsLoading(
            new Promise((resolve, reject) =>
              Auth.currentAuthenticatedUser()
                .then(() => Auth.currentUserInfo())
                .then(
                  cognito_user_data => {
                    setCurrentUserIdentityForAnalytics({
                      identityId: cognito_user_data.id,
                      userSub: cognito_user_data.attributes.sub,
                      email: cognito_user_data.attributes.email,
                    })

                    return dispatch(
                      'setCurrentCognitoUser',
                      cognito_user_data
                    ).then(() => resolve(cognito_user_data))
                  },
                  e => {
                    setCurrentUserIdentityForAnalytics(null)

                    return Promise.all([
                      dispatch('setCurrentCognitoUser'),
                      commit('SET_CACHED_AUTH_CREDENTIALS'),
                    ]).then(() => reject(e))
                  }
                )
            ),
            'current_cognito_user'
          )

          if (!plansFetched) {
            dispatch('plan/fetchPlans')
            plansFetched = true
          }

          if (!currentSubscriptionFetched) {
            dispatch('plan/current_subscription/fetchCurrentSubscription')
            currentSubscriptionFetched = true
          }

          return dispatch('getAuthCredentials')
        }
      })(),
      getUserJwtToken: () =>
        Auth.currentAuthenticatedUser().then(user =>
          user.getSignInUserSession().getIdToken().getJwtToken()
        ),
      getAuthCredentials: async ({ commit, getters }, options) => {
        const { forceRefresh } = options || {}

        function refreshCredentials() {
          getAuthCredentialsPromise = Auth.currentUserCredentials().then(
            creds => {
              commit('SET_CACHED_AUTH_CREDENTIALS', creds)
              getAuthCredentialsPromise = undefined
              return creds
            }
          )

          return getAuthCredentialsPromise
        }

        if (getAuthCredentialsPromise) {
          return getAuthCredentialsPromise
        }

        if (!forceRefresh) {
          let creds = getters.getCachedAuthCredentials
          let timeAfter5mins = (() => {
            const timeNow = new Date()
            timeNow.setMinutes(timeNow.getMinutes() + 5)
            return timeNow
          })()
          if (creds && creds.expiration > timeAfter5mins) {
            return creds
          }
        }

        return refreshCredentials()
      },
    },
  }
}
