var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "editable-field-property-input-number rounded-lg",
    attrs: {
      "type": "number",
      "min": _vm.minValue,
      "label": _vm.label,
      "step": "1",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.inputNumber
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.inputConst.append_inner) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }