<script>
  export default {
    name: 'AppBarBackToFormTypeSelection',
    components: {
      AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
      AppBarTemplate: () => import('../AppBarTemplate'),
      ChevronLeftIcon: () => import('vue-feather-icons/icons/ChevronLeftIcon'),
    },
  }
</script>

<template>
  <AppBarTemplate>
    <template #left>
      <v-btn
        exact
        :to="{ name: constants.PAGE_NAME_FORM_TYPE_SELECTION }"
        depressed
        text
        class="v-btn--text-hoverable"
      >
        <ChevronLeftIcon class="v-btn__prepend-icon" />
        <span>Back</span>
      </v-btn>
    </template>
    <template #center>
      <AppBarLogoComponent />
    </template>
  </AppBarTemplate>
</template>
