<template>
    <div class="image-choice-field-view-full-single-answer">
        <p v-if="!submissionObj.selected_options.length">-</p>
        <div
                v-for="optionIndex in [...submissionObj.selected_options].sort()"
                class="image-choice-field-view-full-single-answer__card"
                :key="optionIndex"
        >
              <img
                  :src="sourceObj.content.options[optionIndex].image"
s              >
            <p>{{ sourceObj.content.options[optionIndex].label }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FullSingleAnswer__ImageChoice",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        }
    }
</script>