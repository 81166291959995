<template>
    <div class="static-main-page-fields-layout static-main-page-fields-layout--submittable">
        <v-row
                v-for="(row, rowIndex) in getCurrentPage.rows"
                :key="rowIndex"
                :style="rowIndex === 0 ? {'padding-top': '30px'} : {}"
        >
            <v-col
                    v-for="field in row.fields"
                    :cols="field.width * (12 / constants.STATIC_FORM_COLS_COUNT)"
                    :key="field.field_id"
            >
                <SubmittableStaticFieldConstructor
                        v-if="!(field.field_id in getFieldsWithExistenceStatus) || getFieldsWithExistenceStatus[field.field_id].exist"
                        :field-id="field.field_id"
                        :field-width="field.width"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { useMapGetters } from '@/xstore'
    import { inject } from '@vue/composition-api/dist/vue-composition-api'

    export default {
        name: "StaticMainPageFieldsLayout",
        components: {
            SubmittableStaticFieldConstructor: () => import('../../Field/SubmittableStaticFieldConstructor')
        },
        setup() {
            return {
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getCurrentPage'
                ]),
                ...useMapGetters(inject('formSubmitStoreModulePath') + '/conditional', [
                    'getFieldsWithExistenceStatus'
                ])
            }
        }
    }
</script>

<style scoped>

</style>