<template>
    <v-container fluid>
        <p class="settings-view__title">Your account</p>
        <f-circle-loader size="5" v-if="getCurrentCognitoUserIsLoading"/>
        <template v-else>
            <div class="setting-input-row-with-append">
                <div class="setting-input-row-with-append__name">
                    Full name
                </div>
                <div class="setting-input-row-with-append__input">
                    <v-form
                            ref="nameInputForm"
                            lazy-validation
                            @submit.prevent
                    >
                        <v-text-field
                                v-model="localNameCopy"
                                ref="nameInput"
                                name="asdsada"
                                :rules="nameRules"
                                hide-details="auto"
                                outlined
                        />
                    </v-form>
                </div>
                <div class="setting-input-row-with-append__button">
                    <v-btn
                            v-if="showSaveNameButton"
                            class="font-weight-medium"
                            color="blue_400"
                            text
                            :loading="isNameSaving"
                            @click="saveName"
                    >
                        Save
                    </v-btn>
                </div>
            </div>
            <div class="setting-input-row-with-append">
                <div class="setting-input-row-with-append__name">
                    Your photo
                    <span>
                    You'll see this on your profile.
                </span>
                </div>
                <div class="setting-input-row-with-append__input">
                    <div class="d-flex align-center">
                        <v-avatar class="mr-4" size="64px">
                            <f-circle-loader v-if="isAvatarSaving" size="3"/>
                            <img v-else :src="getCurrentCognitoUser.attributes.picture"/>
                        </v-avatar>
                        <v-btn
                                class="font-weight-medium"
                                text
                                :disabled="isAvatarSaving"
                                @click="startNewAvatarSelecting"
                        >Change
                        </v-btn>
                        <v-btn
                                v-if="!isDefaultAvatar"
                                class="font-weight-medium"
                                color="red_400"
                                text
                                :disabled="isAvatarSaving"
                                @click="deleteAvatar"
                        >Remove
                        </v-btn>
                    </div>
                </div>
                <div class="setting-input-row-with-append__button">

                </div>
            </div>

            <v-divider/>

            <p class="settings-view__header">Login details</p>
            <div class="setting-input-row-with-append">
                <div class="setting-input-row-with-append__name">
                    Email
                </div>
                <div class="setting-input-row-with-append__input">
                    <v-text-field
                            :value="getCurrentCognitoUser.attributes.email"
                            readonly
                            outlined
                            hide-details
                    >
                        <template #prepend-inner>
                            <MailIcon/>
                        </template>
                    </v-text-field>
                </div>
                <div class="setting-input-row-with-append__button" :style="{transform: 'scale(0, 0)'}">
                    <v-btn
                            class="font-weight-medium"
                            color="grey_900"
                            text
                    >
                        Change
                    </v-btn>
                </div>
            </div>
            <div class="setting-input-row-with-append">
                <div class="setting-input-row-with-append__name">
                    Password
                </div>
                <div class="setting-input-row-with-append__input">
                    <v-text-field
                            value="1111111111"
                            type="password"
                            outlined
                            readonly
                            disabled
                            hide-details
                    />
                </div>
                <div class="setting-input-row-with-append__button">
                    <ChangePasswordModal/>
                </div>
            </div>

            <v-dialog width="600" v-model="saveChangesDialog">
                <v-card>
                    <v-card-title>
                        Save Changes?
                        <v-spacer/>
                        <v-btn
                                icon
                                @click="saveChangesDialog = false"
                        >
                            <XIcon/>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>Do you want to save your changes before?</v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                                color="gray_100"
                                rounded
                                @click="nextRoute"
                        >Discard
                        </v-btn>
                        <v-btn
                                color="blue_400"
                                rounded
                                @click="() => {saveName(); nextRoute()}"
                        >Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {MailIcon, XIcon} from 'vue-feather-icons'

    export default {
        name: "AccountDetails",
        components: {
            MailIcon, XIcon,
            ChangePasswordModal: () => import('@/components/Settings/ChangePasswordModal')
        },
        data: () => ({
            saveChangesDialog: false,
            nextRoute: undefined,

            localNameCopy: '',
            nameRules: [
                v => !!v || 'Name is required',
                v => /^[\p{L} ]+$/gu.test(v) || 'Name must contain only letters'
            ],

            isNameSaving: false,
            isAvatarSaving: false
        }),
        computed: {
            ...mapGetters('user', [
                'getCurrentCognitoUser',
                'getCurrentCognitoUserIsLoading'
            ]),
            showSaveNameButton() {
                return this.isNameChanged && this.nameRules.every(rule => rule(this.localNameCopy) === true)
            },

            isNameChanged() {
                return this.localNameCopy !== this.getCurrentCognitoUser.attributes.name
            },
            isDefaultAvatar() {
                return this.getCurrentCognitoUser.attributes.picture === this.constants.DEFAULT_AVATAR
            },

        },
        watch: {
            getCurrentCognitoUser: {
              handler() {
                if (!this.localNameCopy) {
                    this.localNameCopy = this.getCurrentCognitoUser.attributes.name;
                }
              },
              immediate: true,
            }
        },
        beforeRouteLeave(to, from, next) {
            if (this.isNameChanged) {
                this.nextRoute = next
                this.saveChangesDialog = true
            } else
                next()
        },
        methods: {
            saveName() {
                if (this.$refs.nameInputForm.validate()) {
                    let name = this.localNameCopy
                    this.promiseWithVariableToggler(
                        'isNameSaving',
                        this.AuthActions.updateUserAttrs({name: name})
                            .then(response => {
                                if (response === 'SUCCESS') {
                                    this.$store.dispatch('user/setCurrentCognitoUser', {
                                        ...this.getCurrentCognitoUser,
                                        attributes: {
                                            ...this.getCurrentCognitoUser.attributes,
                                            name: name
                                        }
                                    })
                                    this.localNameCopy = name
                                }
                            })
                    )
                }
            },
            startNewAvatarSelecting() {
                this.fileInputEventBus.$emit('trigger-input', {
                    mimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    maxFilesCount: 1,
                    maxFileSize: 10,
                    callback: this.changeAvatar
                })
            },
            changeAvatar(file) {
                this.promiseWithVariableToggler(
                    'isAvatarSaving',
                    Promise.all([
                        this.getCurrentCognitoUser.attributes.picture.startsWith(this.constants.S3_BASE_URL) && !this.isDefaultAvatar
                            ? this.StorageActions.deleteObjectsByFullS3Paths(
                            this.constants.GET_S3_OBJECT_RELATIVE_PATH_BY_URL(this.getCurrentCognitoUser.attributes.picture)
                            )
                            : null,
                        this.Utils.blobToBase64Data(file)
                            .then(dataURL => this.Utils.scaleImage(dataURL, 200, 200))
                            .then(dataURL => this.Utils.trimImage(dataURL, 200, 200))
                            .then(dataURL => this.Utils.base64DataURLToBlob(dataURL))
                            .then(blob => this.StorageActions.uploadPublicAttachment(blob))
                            .then(avatarUrl => this.AuthActions.updateUserAttrs({picture: avatarUrl}))
                    ])
                        .then(() => this.$store.dispatch('user/authUser'))
                        .then(() => this.snackbarEventBus.$emit('snackbar', {
                            emoji: this.constants.EMOJI.HANDS_UP,
                            color: this.constants.SNACKBAR_CONSTANTS.COLORS.SECONDARY,
                            text: 'New Avatar Successfully Saved'
                        }))
                )
            },
            deleteAvatar() {
                this.promiseWithVariableToggler(
                    'isAvatarSaving',
                    Promise.all([
                        this.StorageActions.deleteObjectsByFullS3Paths(
                            this.constants.GET_S3_OBJECT_RELATIVE_PATH_BY_URL(this.getCurrentCognitoUser.attributes.picture)
                        ),
                        this.AuthActions.updateUserAttrs({picture: this.constants.DEFAULT_AVATAR})
                    ])
                        .then(() => {
                            return this.$store.dispatch('user/authUser')
                        })
                        .then(() => this.snackbarEventBus.$emit('snackbar', {
                            emoji: this.constants.EMOJI.HANDS_UP,
                            color: this.constants.SNACKBAR_CONSTANTS.COLORS.SECONDARY,
                            text: 'Avatar Successfully Deleted'
                        }))
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
    .setting-input-row-with-append {
        /*display: grid;*/
        /*grid-template-columns: 3.5fr 5fr 1fr;*/
        margin: 36px 0;

        display: flex;

        &__name {
            padding-right: 64px;
            flex-shrink: 0;
            min-width: 35%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            font-size: .875rem;
            line-height: 1.25rem;

            font-weight: 500;
            color: var(--v-dark_gray-base);

            span {
                font-weight: 400;
                color: var(--v-grey_500_2-base);
            }
        }

        &__input {
            flex-grow: 5;
            flex-shrink: 3;

            .v-text-field {
                &.v-text-field--outlined {
                    border-radius: 8px;
                }

                ::v-deep .v-input__slot {
                    /*margin: 0!important;*/

                    .v-input__prepend-inner {
                        svg {
                            width: 20px;
                            height: 20px;
                            color: var(--v-grey_500_2-base);
                            margin-right: 4px;
                        }
                    }
                }

                /*::v-deep .v-text-field__details {*/
                /*    position: absolute;*/
                /*    top: calc(100% + 6px);*/
                /*}*/
            }
        }

        &__button {
            flex-shrink: 0;
            min-width: 70px;

            display: flex;
            align-items: center;
            justify-content: end;

            ::v-deep.v-btn {
                padding-right: 0;
            }
        }
    }
</style>