var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-parameters-option-header d-flex align-center pb-2"
  }, [_c('span', {
    staticClass: "bluish_gray_500--text"
  }, [_vm._t("default")], 2), _c('v-spacer'), _vm.allowedOnlyWithPayment ? _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "max-width": "250",
      "text": "You need to upgrade to a paid plan in order to use this feature"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('f-badge', _vm._g({
          attrs: {
            "purple": ""
          },
          on: {
            "click": _vm.showDialog
          }
        }, on), [_vm._v(" Paid ")])];
      }
    }], null, false, 125324960)
  }) : _vm._e(), !_vm.alwaysEnabled ? _c('f-switch', {
    attrs: {
      "value": _vm.value,
      "disabled": _vm.allowedOnlyWithPayment
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }