<template>
    <div class="form-parameters-option" @click="navigate">
        <div class="form-parameters-option-header d-flex align-center cursor-pointer">
            <span class="bluish_gray_500--text">
                <slot/>
            </span>
            <v-spacer/>
            <ChevronRightIcon size="20"/>
        </div>
    </div>
</template>

<script>
    import {ChevronRightIcon} from 'vue-feather-icons'

    export default {
        name: "FormSettingsOptionNavigator",
        components: {
            ChevronRightIcon,
        },
        methods: {
            navigate() {
                this.$emit('navigate')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-parameters-option {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        .form-parameters-option-header {
            font-size: 1rem;
            font-weight: 400;
            height: 20px;
            line-height: 19px;
        }
    }
</style>