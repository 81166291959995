<template>
  <SharedView
      :placeholder="sourceObj.content.placeholder"
      v-model="submissionObj.value.phone"
      :country-code="submissionObj.value.country"
      @change-country="(v) => submissionObj.value.country = v"
      :mask="/^\d{0,11}$/"
      v-on="inputAutofocusOn"
      ref="input"
  />
</template>

<script>
import {
  submittable_field_view_mixin,
  useSubmittableFieldViewExtension
} from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
import {
  use_submittable_field_input_autofocus_and_press_enter_mixin
} from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_input_autofocus_and_press_enter_mixin'

export default {
  name: "Submittable__Phone",
  components: {
    SharedView: () => import('./SharedView'),
  },
  mixins: [submittable_field_view_mixin, use_submittable_field_input_autofocus_and_press_enter_mixin('input', 'phone')],
  setup(props, {root}) {
    const extension = useSubmittableFieldViewExtension(props)

    extension.beforeSubmit(() => {
      let value = null

      if (props.submissionObj.value.country?.length && props.submissionObj.value.phone?.length)
        value = '+' + root.constants.COUNTRY_BY_CODE[props.submissionObj.value.country].countryCallingCode + ' ' + props.submissionObj.value.phone

      return root.$store.dispatch(extension.formSubmitStoreModulePath + '/submission/updateFormSubmissionField', {
        fieldID: props.fieldId,
        updatedSubmission: {value}
      })
    })

    return {
      ...extension
    }
  },
}
</script>