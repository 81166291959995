var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFormCreationLayout', {
    attrs: {
      "cards": _vm.cards,
      "title": "What do you want to create?",
      "subtitle": "Select the type of Form you want to create",
      "caption-above-the-title": "Step 1 of 3"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }