<template>
  <div>
    <div class="moveable-target" ref="moveableTarget" v-click-outside="closeEditor">
      <v-scale-transition>
        <div class="moveable-target__remove-icon" v-if="isMoveableControlBoxVisible" @click="clearCoverText">
          <XIcon size="16"/>
        </div>
      </v-scale-transition>
      <v-menu
          :value="isMoveableControlBoxVisible && (isEditorOpenbyClick || isEditorOpenBySelection)"
          offset-y
          transition="scale-transition"
          origin="center"
          :nudge-bottom="isTextOverflowContainer ? -10 : 10"
          :top="isTextOverflowContainer"
          :nudge-left="nudgeLeft"
          :close-on-content-click="false"
          :close-on-click="false"
          content-class="moveable-container"
          min-width="509"
      >
        <template #activator="{on}">
                    <span class="text-container">
                        <span
                            v-if="on"
                            @click="editorVisibleControl"
                            class="text"
                            :class="placeholder"
                            ref="moveableText"
                            :contenteditable="isContentEditable"
                            @paste="processPaste"
                            @input="onInput"
                            @keydown.8="handleKeyDown"
                            data-placeholder="Add Form Title"
                        >
                            <p ref="moveableTextContainer"><br></p>
                        </span>
                    </span>
        </template>
        <template #default>
          <v-card
              height="43"
              :class="['rounded-pill']"
              width="509"
              v-click-outside="closeEditor"
              class="moveable-editor"
              ref="moveableEditor"
          >
            <v-slide-x-transition mode="out-in">
              <v-text-field
                  placeholder="Add a URL"
                  v-model="createdLink"
                  class="moveable-editor__link-input"
                  solo
                  dense
                  flat
                  height="43"
                  hide-details
                  v-if="isLinkCreatorOpened"
              >
                <template #append>
                  <v-btn dark
                         @click="createLink(createdLink)"
                         width="88"
                         color="#3D66F6"
                         rounded
                  >
                    Confirm
                  </v-btn>
                </template>
              </v-text-field>
              <div class="moveable-editor__parameters" @click="restoreSelection" v-else>
                <FontPickerComponent
                    v-model="selectedFont"
                    @change="setSelectedFont"
                    attach=".moveable-editor__parameters-font-family-select"
                    class="moveable-editor__parameters-font-family-select"
                    solo
                    flat
                    :menu-props="{
                                            nudgeBottom: 45,
                                            minWidth: 250,
                                            nudgeLeft: 20,
                                            maxHeight: 'auto'
                                        }"
                />
                <v-divider
                    vertical
                />
                <f-select
                    :items="constants.TEXT_EDITOR_CONSTANTS.FONT_SIZES"
                    v-model="selectedFontSize"
                    @change="setSelectedFontSize"
                    solo
                    flat
                    dense
                    height="17"
                    append-icon=""
                    hide-details
                    attach=".moveable-editor__parameters-font-size-select"
                    class="moveable-editor__parameters-font-size-select"
                    :menu-props="{
                                            nudgeBottom: 35,
                                            minWidth: 83,
                                            nudgeLeft: 10,
                                            auto: true,
                                            contentClass: 'font-size-select__v-menu'
                                        }"
                >
                  <template #append-outer>
                    <div class="moveable-editor__parameters-font-size-select__controls">
                      <ChevronUpIcon size="12" @click="increaseFontSize"
                                     class="moveable-editor__parameters-icon--hover"/>
                      <ChevronDownIcon size="12" @click="decreaseFontSize"
                                       class="moveable-editor__parameters-icon--hover"/>
                    </div>
                  </template>
                  <template #item="{on, attrs, item}">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content v-if="fontSizesToShow.includes(item)">
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                      <span v-else></span>
                    </v-list-item>
                  </template>
                </f-select>
                <v-divider
                    vertical
                />
                <f-select
                    :items="constants.TEXT_EDITOR_CONSTANTS.TEXT_ALIGN"
                    v-model="selectedTextAlign"
                    :item-value="item => item.value"
                    @change="setSelectedTextAlign"
                    solo
                    flat
                    dense
                    height="17"
                    append-icon=""
                    hide-details
                    attach=".moveable-editor__parameters-text-align-select"
                    class="moveable-editor__parameters-text-align-select"
                    :menu-props="{
                                        nudgeBottom: 35,
                                        minWidth: 180,
                                        nudgeLeft: 90,
                                    }"
                >
                  <template v-slot:item="{on, attrs, item}">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{item}">
                    <v-icon class="moveable-editor__parameters-icon--hover">{{ item.icon }}
                    </v-icon>
                  </template>
                </f-select>
                <v-divider
                    vertical
                />
                <v-icon
                    @click="setFontStyle('bold')"
                    class="moveable-editor__parameters-icon--hover"
                    :class="isBold"
                >$format_bold
                </v-icon>
                <v-icon
                    @click="setFontStyle('italic')"
                    class="moveable-editor__parameters-icon--hover"
                    :class="isItalic"
                >$format_italic
                </v-icon>
                <v-icon
                    @click="setFontStyle('underline')"
                    class="moveable-editor__parameters-icon--hover"
                    :class="isUnderline"
                >$format_underline
                </v-icon>
                <v-divider
                    vertical
                />
                <div class="moveable-editor__parameters-link" v-click-outside="closeLinkCreator">
                  <v-icon
                      @click="isSelectedTextInsideTheLink ? removeLink() : isLinkCreatorOpened = !isLinkCreatorOpened"
                      class="moveable-editor__parameters-icon--hover"
                      :class="isInsideLink"
                  >$create_link
                  </v-icon>
                </div>
                <v-divider
                    vertical
                />
                <v-menu
                    offset-y
                    bottom
                    :close-on-content-click="false"
                    :close-on-click="false"
                    nudge-bottom="27"
                    nudge-left="180"
                    attach=".moveable-editor__parameters-color-picker"
                    max-width="290"
                    min-width="290"
                    :value="isColorPickerVisible"
                >
                  <template #activator="{ on }">
                                        <span v-if="on"
                                              @click.self="isColorPickerVisible = !isColorPickerVisible"
                                              class="moveable-editor__parameters-color-picker"
                                              :style="{backgroundColor: selectedColor || selectedCustomColor}"
                                              v-click-outside="() => isColorPickerVisible = false"
                                        >
                                        </span>
                  </template>
                  <template>
                    <v-card class="colors-container">
                      <div class="colors-palette-list">
                        <div v-for="color in palette"
                             class="colors-palette-list__color-tile"
                             :key="color"
                             :style="{background: color}"
                             :class="color === selectedColor  && 'colors-palette-list__color-tile--active'"
                             @click="setActiveColor(color)"
                        >
                          <div
                              v-if="color === '#FFFFFF'"
                              :style="{border: '1px solid #DCDCDC'}"
                              class="colors-palette-list__color-tile--white"
                          >

                          </div>
                        </div>
                        <v-menu
                            content-class="white"
                            offset-y
                            nudge-bottom="50"
                            :close-on-content-click="false"
                            :close-on-click="false"
                            attach=".colors-container"
                            :value="isColorPickerVisible && isCustomColorPickerVisible"

                        >
                          <template #activator="{on}">
                            <div
                                v-if="on"
                                @click="isCustomColorPickerVisible = !isCustomColorPickerVisible"
                                class="colors-palette-list__color-tile color-tile--picker"
                                :style="{backgroundColor: selectedCustomColor}"
                            >
                              <PlusIcon class="plus-icon"/>
                            </div>
                          </template>
                          <template #default>
                            <v-card>
                              <v-color-picker
                                  v-model="selectedCustomColor"
                                  hide-mode-switch
                                  mode="hexa"
                              />
                              <div class="d-flex pa-4 pt-0">
                                <v-btn
                                    color="blue_400 mr-2" rounded
                                    @click="setCustomActiveColor"
                                >Add
                                </v-btn>
                                <v-btn
                                    color="gray_100" rounded
                                    @click="unsetCustomActiveColor"
                                >Cancel
                                </v-btn>
                              </div>
                            </v-card>
                          </template>
                        </v-menu>
                      </div>
                    </v-card>
                  </template>
                </v-menu>
              </div>
            </v-slide-x-transition>
          </v-card>
        </template>
      </v-menu>
    </div>
    <Moveable
        :target="['.moveable-target']"
        :draggable="true"
        :resizable="true"
        :origin="false"
        :snappable="true"
        :renderDirections="['e', 'w']"
        @drag="onDrag"
        @resize="onResize"
        @resizeStart="onResizeDragStart"
        @resizeEnd="onResizeDragEnd"
        @dragStart="onResizeDragStart"
        @dragEnd="onResizeDragEnd"
        v-if="isMoveableControlBoxVisible"
        :key="'key' + moveableKey"
    />
  </div>
</template>

<script>

import {ref, reactive, onMounted, onBeforeUnmount, computed} from '@vue/composition-api'
import Moveable from 'vue-moveable';
import {ChevronDownIcon, ChevronUpIcon} from 'vue-feather-icons'
import Vue from 'vue';

export default {
  name: 'MoveableComponentWithEditor',
  components: {
    FontPickerComponent: () => import('@/components/FontPickerComponent'),
    PlusIcon: () => import('vue-feather-icons/icons/PlusIcon'),
    Moveable,
    ChevronDownIcon,
    ChevronUpIcon,
    XIcon: () => import('vue-feather-icons/icons/XIcon'),
  },
  props: {
    selector: {
      type: String,
    },
    content: {
      type: String,
      required: true,
    },
    translate: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    openedFromOutside: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit, root}) {


    // DATA

    const palette = reactive(['#000000', '#FFFFFF', '#6C48FF', '#39C0C7', '#F96300', '#017BFF', '#F34970', '#FF9381', '#F5B000']);
    const fontSizesToShow = ['18px', '24px', '28px', '32px', '36px', '48px', '80px', '120px', '180px'];


    const moveableText = ref(null);
    const moveableTextContainer = ref(null);
    const moveableTarget = ref(null);
    const moveableEditor = ref(null);
    const moveableKey = ref(false);
    const selectedFontSize = ref('48px');
    const selectedFont = ref('Inter');
    const selectedTextAlign = ref('JustifyCenter');
    const isEditorOpenbyClick = ref(false);
    const isEditorOpenBySelection = ref(false);
    const isMoveableControlBoxVisible = ref(false);
    const isColorPickerVisible = ref(false);
    const isCustomColorPickerVisible = ref(false);
    const isSelectedTextInsideTheLink = ref(false);
    const openBy = ref(null);
    const range = ref(null);
    const createdLink = ref(null);
    const isLinkCreatorOpened = ref(false);
    const selectedColor = ref('#FFF');
    const selectedCustomColor = ref('#FFF');
    const textContent = ref(null);
    const isSelectedTextBold = ref(false);
    const isSelectedTextItalic = ref(false);
    const isSelectedTextUnderline = ref(false);
    const isContentEditable = ref(false);
    const isTextOverflowContainer = ref(false);

    const placeholder = computed(() => ({'placeholder-visible': !textContent.value && !isContentEditable.value}));
    const isBold = computed(() => ({'moveable-editor__parameters-icon-active': isSelectedTextBold.value}));
    const isItalic = computed(() => ({'moveable-editor__parameters-icon-active': isSelectedTextItalic.value}));
    const isUnderline = computed(() => ({'moveable-editor__parameters-icon-active': isSelectedTextUnderline.value}));
    const isInsideLink = computed(() => ({'moveable-editor__parameters-icon-active': isSelectedTextInsideTheLink.value}));

    const nudgeLeft = ref(null);

    let isTextFocused = false;

    // METHODS FOR CONTROL THE VISIBILITY OF THE EDITOR

    const editorVisibleControl = () => {
      isMoveableControlBoxVisible.value = true;
      if (isEditorOpenbyClick.value || !moveableText.value.textContent || isTextFocused) {
        isEditorOpenbyClick.value = false;
        isContentEditable.value = true;
        Vue.nextTick(() => {
          moveableText.value.focus();
          isTextFocused = true;
          openBy.value = openBy.value === 'click' ? null : openBy.value;
        })
      } else if (!isTextFocused) {
        const allSpanRange = new Range();
        allSpanRange.selectNodeContents(moveableText.value);
        range.value = allSpanRange;
        setStyledRelatedValues();
        isContentEditable.value = false;
        isEditorOpenbyClick.value = true;
        isLinkCreatorOpened.value = false;
        openBy.value = 'click';
      }
    };

    const selectListener = () => {
      const getSel = window.getSelection();
      if (!isLinkCreatorOpened.value && !moveableText.value.contains(getSel.anchorNode) && moveableEditor.value?.$el.contains(getSel.anchorNode) && isEditorOpenBySelection.value) {
        restoreSelection();
      }
      if (!getSel.isCollapsed && moveableTarget.value.contains(getSel.anchorNode)) {
        range.value = getSel.getRangeAt(0);
        setStyledRelatedValues()
        isEditorOpenBySelection.value = true;
        openBy.value = 'selection';
      } else if (!isLinkCreatorOpened.value) {
        isEditorOpenBySelection.value = false;
        openBy.value = openBy.value === 'selection' ? null : openBy.value;
      }
    };

    const closeEditor = (e) => {
      if (!e.target.contains(moveableText.value) && !e.target.closest('.text') && !e.target.closest('.moveable-container')) {
        if (!root.Utils.stripHtml(moveableText.value.innerHTML)) {
          emit('close-editor');
        }
        isEditorOpenbyClick.value = false;
        isMoveableControlBoxVisible.value = false;
        isCustomColorPickerVisible.value = false;
        isContentEditable.value = false;
        isLinkCreatorOpened.value = false;
        isTextFocused = false;
        openBy.value = openBy.value === 'click' ? null : openBy.value;
      }
    };

    const clearCoverText = () => {
      emit('place', {
        translate: 'translate(0px ,0px)',
        height: '59px',
        width: '350px',
      })
      emit('content', '');
      emit('close-editor');
    }

    const closeLinkCreator = () => {
      isLinkCreatorOpened.value = false;
    };

    const restoreSelection = () => {
      if (!range.value.collapsed && !isEditorOpenbyClick.value && !isLinkCreatorOpened.value) {
        const getSel = window.getSelection();
        getSel.removeAllRanges();
        getSel.addRange(range.value);
      }
    };

    // MOVEABLE METHODS

    const onDrag = e => {
      e.target.style.transform = e.transform;
    };

    const onResize = ({target, width, height, drag}) => {
      target.style.width = `${width}px`;
      target.style.height = height > 50 ? `${height}px` : '50px';
      target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
      const allSpanRange = new Range();
      allSpanRange.selectNodeContents(moveableText.value);
      const textHeight = allSpanRange.getBoundingClientRect().height;
      moveableTarget.value.style.height = textHeight > 0 && textHeight !== moveableTarget.value.style.height ? textHeight + 'px' : moveableTarget.value.style.height;
    };

    const onResizeDragStart = () => {
      isEditorOpenBySelection.value = false;
      isEditorOpenbyClick.value = false;
    };
    const onResizeDragEnd = () => {
      if (openBy.value) {
        openBy.value === 'selection' ? isEditorOpenBySelection.value = true : isEditorOpenbyClick.value = true;
        Vue.nextTick(() => {
          nudgeLeft.value = (509 - moveableText.value.getBoundingClientRect().width) / 2 + 'px';
        })
      }
      emit('place', {
        translate: moveableTarget.value.style.transform,
        height: moveableTarget.value.style.height,
        width: moveableTarget.value.style.width,
      })

    }

    // CONTENTEDITABLE HEIGHT CONTROL

    const heightControl = () => {
      lineHeightControl();
      const previousHeight = moveableTarget.value.style.height;
      const matrix = root.Utils.matrixToArray(moveableTarget.value.style.transform);

      const allSpanRange = new Range();
      allSpanRange.selectNodeContents(moveableText.value);
      Vue.nextTick(() => {
        const textHeight = allSpanRange.getBoundingClientRect().height;
        const difference = parseInt(previousHeight) - textHeight;
        const isHeightShouldChange = difference && textHeight > 10;
        const newHeight = isHeightShouldChange ? textHeight + 'px' : previousHeight;
        moveableTarget.value.style.height = newHeight;
        if (isHeightShouldChange) {
          moveableTarget.value.style.transform = `translate(${matrix[0]}px, ${+matrix[1] - difference / 2}px)`
        }
        moveableKey.value = !moveableKey.value;
        emit('place', {
          translate: moveableTarget.value.style.transform,
          height: moveableTarget.value.style.height,
          width: moveableTarget.value.style.width,
        })
      })
    };

    const lineHeightControl = () => {
      Vue.nextTick(() => {
        moveableText.value.firstElementChild.style.lineHeight = '';
        Array.from(moveableText.value.firstElementChild.querySelectorAll('font'))?.forEach(elem => {
          if (elem.style.fontSize && parseInt(elem.style.fontSize) > (parseInt(moveableText.value.firstElementChild.style.lineHeight || 0))) {
            moveableText.value.firstElementChild.style.lineHeight = elem.style.fontSize;
          }
        })
      })
    }

    // CONTENTEDITABLE METHODS

    const processPaste = e => {
      e.preventDefault();
      e.stopPropagation();
      let data = (e.originalEvent || e).clipboardData.getData('text/plain')
      if (data)
        document.execCommand("insertText", false, data.replace('\n', ' '))
      heightControl();
      emit('content', moveableText.value.innerHTML.replace('\u200B', '') || '');
    };
    const onInput = () => {
      textContent.value = moveableText.value.textContent;
      heightControl();
      emit('content', root.Utils.stripHtml(moveableText.value.innerHTML) ? moveableText.value.innerHTML.replace('\u200B', '') : '');
    };

    const handleKeyDown = (e) => {
      if (!textContent.value) {
        e.preventDefault();
      }
    }

    // "STYLE TEXT" METHODS

    const setStyleCommonHandler = (command, value) => {
      const getSel = window.getSelection()
      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          if (value) {
            document.execCommand(command, false, value);
          } else {
            document.execCommand(command);
          }
          getSel.removeAllRanges();
          isContentEditable.value = false;
          emit('content', moveableText.value.innerHTML);
        })
      } else {
        if (value) {
          document.execCommand(command, false, value);
        } else {
          document.execCommand(command);
        }
        range.value = getSel.getRangeAt(0);
        emit('content', moveableText.value.innerHTML);
      }
    }

    const setFontSizeCommonHandler = (operation, value = '') => {

      const methods = {
        '=': (font, value) => font.style.fontSize = value,
        '+': (font) => {
          font.style.fontSize = parseInt(selectedFontSize.value) === 180 ? selectedFontSize.value : parseInt(selectedFontSize.value) + 2 + 'px';
          selectedFontSize.value = font.style.fontSize;
        },
        '-': (font) => {
          font.style.fontSize = parseInt(selectedFontSize.value) === 12 ? selectedFontSize.value : parseInt(selectedFontSize.value) - 2 + 'px';
          selectedFontSize.value = font.style.fontSize;
        }
      }

      const getSel = window.getSelection()

      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          document.execCommand('fontSize', false, '1');
          getSel.removeAllRanges();
          isContentEditable.value = false;
          moveableTarget.value.querySelectorAll('font').forEach(font => {
            if (font.hasAttribute('size')) {
              methods[operation](font, value);
              font.removeAttribute('size')
            }
          });
          emit('content', moveableText.value.innerHTML);
        })
      } else {
        document.execCommand('fontSize', false, '1');
        const font = Array.from(moveableTarget.value.querySelectorAll('font')).find(font => font.hasAttribute('size'));
        methods[operation](font, value);
        font.removeAttribute('size')
        range.value = getSel.getRangeAt(0);
        emit('content', moveableText.value.innerHTML);
      }
    }

    const setSelectedFont = (value) => {
      setStyleCommonHandler('fontName', value);

    };
    const setSelectedFontSize = (value) => {
      setFontSizeCommonHandler('=', value);
    };
    const increaseFontSize = () => {
      setFontSizeCommonHandler('+');
    };

    const decreaseFontSize = () => {
      setFontSizeCommonHandler('-');
    };
    const setSelectedTextAlign = (command) => {
      setStyleCommonHandler(command)
    };

    const setFontStyle = (command) => {
      setStyleCommonHandler(command);
      if (command === 'bold')
        isSelectedTextBold.value = !isSelectedTextBold.value;
      else if (command === 'italic')
        isSelectedTextItalic.value = !isSelectedTextItalic.value;
      else
        isSelectedTextUnderline.value = !isSelectedTextUnderline.value;
    };

    const createLink = async (link) => {
      isContentEditable.value = true;
      Vue.nextTick(() => {
        const getSel = window.getSelection();
        getSel.removeAllRanges();
        getSel.addRange(range.value);
        document.execCommand('createLink', false, link);
        isContentEditable.value = false;
        moveableTarget.value.querySelectorAll('a').forEach(tag => {
          if (!tag.classList.contains('link')) {
            getSel.removeAllRanges();
            range.value.selectNode(tag);
            getSel.addRange(range.value);
            tag.classList.add('link');
            isLinkCreatorOpened.value = false;
          }
        })
        createdLink.value = null;
        emit('content', moveableText.value.innerHTML);
        isSelectedTextInsideTheLink.value = checkTextInsideTheTag('a');
      })
    };

    const removeLink = () => {
      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          const getSel = window.getSelection()
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          document.execCommand('unlink');
          getSel.removeAllRanges();
          isContentEditable.value = false;
          emit('content', moveableText.value.innerHTML);
          isSelectedTextInsideTheLink.value = checkTextInsideTheTag('a');
        })

      } else {
        document.execCommand('unlink');
        emit('content', moveableText.value.innerHTML);
        isSelectedTextInsideTheLink.value = checkTextInsideTheTag('a');
      }
    };

    const setActiveColor = (color) => {
      selectedColor.value = color;
      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          const getSel = window.getSelection()
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          document.execCommand('foreColor', false, color);
          getSel.removeAllRanges();
          isContentEditable.value = false;
          isColorPickerVisible.value = false;
          emit('content', moveableText.value.innerHTML);
        })

      } else {
        document.execCommand('foreColor', false, color);
        isColorPickerVisible.value = false;
        emit('content', moveableText.value.innerHTML);
      }

    }

    const setCustomActiveColor = () => {
      selectedColor.value = null;
      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          const getSel = window.getSelection()
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          document.execCommand('foreColor', false, selectedCustomColor.value);
          getSel.removeAllRanges();
          isContentEditable.value = false;
          isCustomColorPickerVisible.value = false;
          emit('content', moveableText.value.innerHTML);
        })
      } else {
        document.execCommand('foreColor', false, selectedCustomColor.value);
        isCustomColorPickerVisible.value = false;
        emit('content', moveableText.value.innerHTML);
      }
    };
    const unsetCustomActiveColor = () => {
      selectedCustomColor.value = '#FFF';
      if (isEditorOpenbyClick.value) {
        isContentEditable.value = true;
        Vue.nextTick(() => {
          const getSel = window.getSelection()
          getSel.removeAllRanges();
          getSel.addRange(range.value);
          document.execCommand('foreColor', false, selectedCustomColor.value);
          getSel.removeAllRanges();
          isContentEditable.value = false;
          isCustomColorPickerVisible.value = false;
          emit('content', moveableText.value.innerHTML);
        })
      } else {
        document.execCommand('foreColor', false, selectedCustomColor.value);
        isCustomColorPickerVisible.value = false;
        emit('content', moveableText.value.innerHTML);
      }
    };

    const checkTextInsideTheTag = (tag, attr, property) => {
      const getSel = window.getSelection()
      const textContent = range.value.toString();
      if (moveableText.value.querySelectorAll(tag).length) {
        return property ? Array.from(moveableText.value.querySelectorAll(tag))?.find(elem => elem.textContent.includes(textContent) && getSel.containsNode(elem, true) && elem[attr][property]) &&
            Array.from(moveableText.value.querySelectorAll(tag))?.find(elem => elem[attr][property])[attr][property] :
            attr ? Array.from(moveableText.value.querySelectorAll(tag))?.find(elem => elem.textContent.includes(textContent) && getSel.containsNode(elem, true) && elem.hasAttribute(attr)) &&
                Array.from(moveableText.value.querySelectorAll(tag))?.find(elem => elem.hasAttribute(attr)).getAttribute(attr) :
                !!Array.from(moveableText.value.querySelectorAll(tag))?.find(elem => elem.textContent.includes(textContent) && getSel.containsNode(elem, true));
      }

    };

    const setStyledRelatedValues = () => {
      if (!isEditorOpenbyClick.value && !isEditorOpenBySelection.value) {
        const textAlign = moveableText.value.querySelector('p')?.style.textAlign;
        selectedTextAlign.value = textAlign ? root.constants.TEXT_EDITOR_CONSTANTS.TEXT_ALIGN.find(item => item.text.toLowerCase() === textAlign).value : 'JustifyLeft';
        selectedFont.value = checkTextInsideTheTag('font', 'face') || selectedFont.value;
        selectedColor.value = checkTextInsideTheTag('font', 'color') || selectedColor.value;
        selectedFontSize.value = checkTextInsideTheTag('font', 'style', 'fontSize') || selectedFontSize.value;
        isSelectedTextBold.value = checkTextInsideTheTag('b');
        isSelectedTextItalic.value = checkTextInsideTheTag('i');
        isSelectedTextUnderline.value = checkTextInsideTheTag('u');
        isSelectedTextInsideTheLink.value = checkTextInsideTheTag('a');
        isTextOverflowContainer.value = moveableTarget.value.getBoundingClientRect().bottom > document.querySelector(props.selector).getBoundingClientRect().bottom;
        Vue.nextTick(() => {
          nudgeLeft.value = (509 - moveableText.value.getBoundingClientRect().width) / 2 + 'px';
        })
      }
    }

    // HOOKS

    onMounted(() => {
      document.addEventListener('selectionchange', selectListener)
      moveableTarget.value.style.transform = props.translate;
      moveableTarget.value.style.width = props.width;
      moveableTarget.value.style.height = props.height;
      if (props.content) {
        moveableText.value.innerHTML = props.content;
      }
      textContent.value = moveableText.value.textContent;

      const usedFonts = Array.from(moveableText.value.querySelectorAll('font')).filter(elem => elem.hasAttribute('face')).map(elem => elem.getAttribute('face'));
      usedFonts.forEach(font => {
        root.constants.TEXT_EDITOR_CONSTANTS.FONTS.forEach(item => {
          if (font === item.name) {
            root.Utils.addFontFamilyURL(item.url);
          }
        })
      })
      if (props.openedFromOutside) {
        setTimeout(() => moveableText.value.click(), 400)
      }

    });

    onBeforeUnmount(() => {
      document.removeEventListener('selectionchange', selectListener);
    });

    // OVERALL

    const methods = {
      setSelectedFont,
      setSelectedFontSize,
      restoreSelection,
      onDrag,
      onResize,
      handleKeyDown,
      onInput,
      processPaste,
      editorVisibleControl,
      setFontStyle,
      createLink,
      removeLink,
      closeEditor,
      closeLinkCreator,
      onResizeDragStart,
      onResizeDragEnd,
      setActiveColor,
      setCustomActiveColor,
      unsetCustomActiveColor,
      setSelectedTextAlign,
      increaseFontSize,
      decreaseFontSize,
      clearCoverText
    };
    const controls = {
      palette,
      moveableTarget,
      selectedFontSize,
      selectedFont,
      isEditorOpenbyClick,
      isEditorOpenBySelection,
      createdLink,
      isSelectedTextInsideTheLink,
      isLinkCreatorOpened,
      selectedColor,
      selectedCustomColor,
      moveableEditor,
      moveableText,
      moveableTextContainer,
      openBy,
      selectedTextAlign,
      nudgeLeft,
      isMoveableControlBoxVisible,
      placeholder,
      isCustomColorPickerVisible,
      isColorPickerVisible,
      isBold,
      isItalic,
      isUnderline,
      isInsideLink,
      moveableKey,
      fontSizesToShow,
      isContentEditable,
      isTextOverflowContainer,
    };

    return {
      ...controls,
      ...methods,
    }
  },
}
</script>