var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('BackToDashboardComponent'), _c('v-divider', {
          staticClass: "app-bar-form-editor__divider",
          attrs: {
            "vertical": ""
          }
        }), _c('FormEditorAndResponsesNavigationComponent')];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_vm.getFormDBData && 'name' in _vm.getFormDBData && !_vm.getIsFormLoadingScreenShown ? _c('span', [_vm._v(_vm._s(_vm.getFormDBData.name))]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('PreviewButton')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }