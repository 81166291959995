var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-answers-block-heading"
  }, [_c(_vm.fieldTypeConst.ICON_COMPONENT, {
    tag: "component",
    staticClass: "summary-answers-block-heading__icon"
  }), _c('div', {
    staticClass: "summary-answers-block-heading__text-block"
  }, [_c('p', {
    staticClass: "summary-answers-block-heading__title"
  }, [_vm._v(" " + _vm._s(_vm.Utils.stripHtml(_vm.sourceObj.title) || '-') + " ")]), _c('p', {
    staticClass: "summary-answers-block-heading__subtitle"
  }, [_c('span', {
    staticClass: "summary-answers-block-heading__answers-count"
  }, [_vm._v(_vm._s(_vm.answered))]), _vm._v(" of " + _vm._s(_vm.submissionsCount) + " answered ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }