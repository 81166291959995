<template>
    <div style="display: contents">
        <slot :attrs="attrs" :submit="validate" :reset="reset" :loading="loading"/>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import { ref, computed, reactive, set } from '@vue/composition-api'

    export default {
        name: "BillingDetailsForm",
        setup(props, {root}) {
            const billingDetails = reactive({})

            const genBillingDetailsState = () => Object.entries({
                country: undefined,
                name: undefined,
                city: undefined,
                line1: undefined,
                postal_code: undefined,
            }).map(([key, value]) => set(billingDetails, key, value))

            genBillingDetailsState()

            const genBillingDetailsClone = valueFormatter => Object.fromEntries(Object.keys(billingDetails).map(billingDetailKey => [billingDetailKey, valueFormatter(billingDetailKey)]))


            const loading = ref(false)


            const $v = useVuelidate(
                computed(() => genBillingDetailsClone(() => ({required}))),
                billingDetails,
                {$rewardEarly: true}
            )

            const attrs = {
                bind: genBillingDetailsClone(billingDetailKey => computed(() => ({
                        props: {
                            value: billingDetails[billingDetailKey],
                            dense: true,
                            outlined: true,
                            hideDetails: 'auto',
                            disabled: loading.value,
                            errorMessages: $v.value[billingDetailKey].$errors.map(({$message}) => $message)
                        },
                        on: {
                            input: v => billingDetails[billingDetailKey] = v
                        }
                    }))
                )
            }

            const validate = fn => {
                loading.value = true
                return $v.value.$validate()
                    .then(result => {
                        if (result)
                            return fn(root.Utils.deepCloneObject(billingDetails))
                    })
                    .finally(() => loading.value = false)
            }

            const reset = genBillingDetailsState

            return {
                $v,
                attrs,
                loading,
                validate,
                reset
            }
        }
    }
</script>