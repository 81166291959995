<template>
    <form
            class="file-upload-area"
            :class="[
                isActive ? 'file-upload-area--active ' + (activeClass ? activeClass : '') : '',
                disabled && 'file-upload-area--disabled'
            ]"
            ref="fileform"

            @drag.prevent.stop
            @dragstart.prevent.stop
            @dragend.prevent.stop
            @dragover.prevent.stop

            @click="inputFileFromExplorer"
            @dragenter.prevent.stop="dragEnter"
            @dragleave.prevent.stop="dragLeave"
            @drop.prevent.stop="drop"
    >
        <div
                :class="[
                    'file-upload-area__content',
                    vertical && 'file-upload-area__content--vertical'
                ]"
        >
            <div class="file-upload-area__icon-container">
                <UploadIcon/>
            </div>
            <div class="file-upload-area__text-container">
                <span class="file-upload-area__header">
                    <slot name="header">Choose File(s) or Drag here</slot>
                </span>
                <span class="file-upload-area__subheader">
                    <slot name="subheader">Max size {{ maxFileSize }}mb</slot>
                </span>
            </div>
        </div>
    </form>
</template>

<script>
    import file_inputs_validator_mixin from '@/components/Elements/FileInputs/file_inputs_validator_mixin'

    export default {
        name: "FileUploadArea",
        components: {
            UploadIcon: () => import('vue-feather-icons/icons/UploadIcon')
        },
        mixins: [file_inputs_validator_mixin],
        props: {
            maxFileSize: { // MB
                type: Number,
                default: 10
            },
            maxFilesCount: {
                type: Number,
                default: 1
            },
            acceptMime: {
                type: Array,
                default: () => []
            },
            acceptExtensions: {
                type: Array,
                default: () => []
            },
            matchBothMimeAndExtension: { // if unset then match mime or extension
                type: Boolean,
                default: false
            },
            vertical: Boolean,
            activeClass: String,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            isActive: false
        }),
        created() {
            this.loadValidationParams({
                mimeTypes: this.acceptMime,
                extensions: this.acceptExtensions,
                maxFilesCount: this.maxFilesCount,
                maxFileSize: this.maxFileSize * 1024 * 1024,
                matchBothMimeAndExtension: this.matchBothMimeAndExtension
            })
        },
        methods: {
            inputFileFromExplorer() {
                if (!this.disabled)
                    this.fileInputEventBus.$emit('trigger-input', {
                        mimeTypes: this.acceptMime,
                        extensions: this.acceptExtensions,
                        maxFilesCount: this.maxFilesCount,
                        maxFileSize: this.maxFileSize,
                        matchBothMimeAndExtension: this.matchBothMimeAndExtension,
                        callback: files => this.$emit('input', files)
                    })
            },
            dragEnter(e) {
                if (!this.disabled && e.toElement === this.$refs.fileform && !this.$refs.fileform.contains(e.fromElement))
                    this.isActive = true
            },
            dragLeave(e) {
                if (!this.disabled && e.toElement === this.$refs.fileform && !this.$refs.fileform.contains(e.fromElement))
                    this.isActive = false
            },
            drop(event) {
                if (!this.disabled) {
                    this.isActive = false

                    const fileList = Array.from(event.dataTransfer.files)
                    if (!this.validate(fileList)) return

                    this.multiple ? this.$emit('input', fileList) : this.$emit('input', fileList[0])
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .file-upload-area {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        background-color: var(--v-gray_100-base);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%239CA2AEFF' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 8px;

        &--disabled {
            cursor: default;
        }

        &--active {
            background-color: var(--v-blue_100-base);
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%233D66F6FF' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 8px;
        }

        input {
            display: none;
        }

        &__content {
            display: flex;
            align-items: center;

            &--vertical {
                flex-direction: column;

                .file-upload-area__icon-container {
                    margin: 0 0 10px 0;
                }

                .file-upload-area__text-container {
                    align-items: center;
                }
            }
        }

        &__icon-container {
            svg {
                color: var(--v-dark_gray-base);
            }

            margin-right: 10px;
            display: flex;
            align-items: center;
        }

        &__text-container {
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        &__header {
            color: var(--v-bluish_gray_400-base);
            font-size: .875rem;
            line-height: 1.3;
            margin-bottom: 3px;
        }

        &__subheader {
            font-size: .625rem;
            line-height: 1.2;
            color: var(--v-gray_400-base);
            margin: 0;
        }
    }
</style>