<template>
  <f-fluid-container
    style="display: flex; justify-content: center; align-items: center">
    <div class="closed-form-wrapper">
      <div class="closed-form-wrapper__title">Sorry, but this form is no longer
        available!
      </div>
      <div class="closed-form-wrapper__description">It was crafted with Formsly.
        Want to give it a try?
      </div>
      <v-btn
        color="blue_400"
        rounded
        @click="routeToDashboard"
      >Create a free form
      </v-btn>
    </div>
  </f-fluid-container>
</template>

<script>

import router from "@/router";
import constants from "@/constants/constants";

export default {
  name: "ClosedForm",
  components: {
    LogoComponent: () => import("@/components/Elements/LogoComponent")
  },
  methods: {
    routeToDashboard() {
      return router.push({ name: constants.PAGE_NAME_DASHBOARD });
    }
  }
};
</script>

<style lang="scss">

.closed-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 0;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #5D6471;
    margin: 24px 0 30px 0;
  }

  .v-btn {
    border-radius: 39px;

    span {
      font-size: 14px;
      padding: 10px 50px;
    }

  }

}
</style>
