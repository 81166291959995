<template>
  <div class="file-upload-field-view-full-single-answer" v-if="submissionObj.value">
    <v-menu v-if="submissionObj.value.isImage"
            nudge-top="25"
            nudge-right="75"
            open-on-hover
            top
            eager
            transition="slide-y-reverse-transition"
            open-delay="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <img
            class="file-upload-field-view-full-single-answer__img-sub"
            v-bind="attrs"
            v-on="on"
            :src="imgDataURL"
        />
<!--        <f-circle-loader  size="3"/>-->
      </template>
      <v-card class="pa-6">
        <v-img
            contain
            :src="imgDataURL"
            max-width="300"
            max-height="300"
        >
          <template #placeholder>
            <f-circle-loader size="3"/>
          </template>
        </v-img>
      </v-card>
    </v-menu>
    <span class="file-upload-field-view-full-single-answer__file-name">
            {{ submissionObj.value.originalName }}
        </span>
    <span class="file-upload-field-view-full-single-answer__file-size">
            {{ submissionObj.value.size }}
        </span>
    <DownloadIcon
        class="file-upload-field-view-full-single-answer__download"
        @click="saveFile(submissionObj.value.fileName)"
        size="18"
    />
  </div>
  <span v-else>
        -
    </span>
</template>

<script>

export default {
  name: "FullSingleAnswer__FileUpload",
  components: {
    DownloadIcon: () => import('vue-feather-icons/icons/DownloadIcon'),
  },
  data: () => ({
    imgDataURL: null,
  }),
  props: {
    submissionObj: {
      type: Object,
      required: true
    },
    sourceObj: {
      type: Object,
      required: true
    },
    allowUploading: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    saveFile(file) {
      this.SubmissionsActions.getAttachment(file)
          .then(({blob, name}) => {
            this.Utils.saveFile(blob, name);
          })
    },
  },
  watch: {
    allowUploading: {
      immediate: true,
      handler(v) {
        if (v && this.submissionObj.value?.isImage) {
          this.StorageActions.getImageByS3Path(this.constants.S3_UPLOADS_FOLDER_PATH + this.submissionObj.value.fileName, true)
              .then(response => {
                this.imgDataURL = response.dataURL;
              })
        }
      }
    },
  }
}
</script>