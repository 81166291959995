import awsconfig from '@/aws-exports'

const S3_BASE_URL = `https://${awsconfig.aws_user_files_s3_bucket}.s3.amazonaws.com/`
const S3_FORM_FILE_NAME = 'form.json'

const GET_S3_OBJECT_RELATIVE_PATH_BY_URL = url => {
    let re = new RegExp(`${S3_BASE_URL}(.+)`)
    return url.match(re) ? url.match(re)[1] : undefined
}
const GET_S3_FORM_FOLDER_PATH = formID => `forms/${formID}/`
const GET_S3_FORM_FILE_PATH = formID => GET_S3_FORM_FOLDER_PATH(formID) + S3_FORM_FILE_NAME
const GET_S3_FORM_ATTACHMENT_PATH = (formID, attachmentName) => GET_S3_FORM_FOLDER_PATH(formID) + attachmentName
const S3_UPLOADS_FOLDER_PATH = `uploads/`

const DEFAULT_AVATAR = S3_BASE_URL + 'public/default-avatar.png'

export default {
    S3_BASE_URL,
    S3_FORM_FILE_NAME,
    S3_UPLOADS_FOLDER_PATH,

    GET_S3_OBJECT_RELATIVE_PATH_BY_URL,
    GET_S3_FORM_FOLDER_PATH,
    GET_S3_FORM_FILE_PATH,
    GET_S3_FORM_ATTACHMENT_PATH,

    DEFAULT_AVATAR
}

export {
    S3_BASE_URL,
    S3_FORM_FILE_NAME,
    S3_UPLOADS_FOLDER_PATH,

    GET_S3_OBJECT_RELATIVE_PATH_BY_URL,
    GET_S3_FORM_FOLDER_PATH,
    GET_S3_FORM_FILE_PATH,
    GET_S3_FORM_ATTACHMENT_PATH,

    DEFAULT_AVATAR
}