var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    staticClass: "change-cover-browser-window"
  }, [_vm._t("header"), _c('v-container', {
    staticClass: "change-cover-browser-container",
    on: {
      "scroll": function ($event) {
        return _vm.$emit('infinite-scroll', $event);
      }
    }
  }, [_vm._t("default")], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }