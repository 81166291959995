import SharedView from "@/components/Fields/FieldsViews/Phone/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__Phone',
    mixins: [preview_field_view_mixin],
    functional: true,
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    preview: true,
                    placeholder: ctx.props.fieldObj.content.placeholder,
                    countryCode: ctx.props.fieldObj.content.country,
                    isFormStatic: ctx.props.isFormStatic
                }
            }
        )
    }
}