<template>
  <div class="flex-grow-1 d-flex flex-column">
    <v-divider style="width: 100%"/>
    <div class="flex-grow-1 d-flex flex-column justify-center align-center">
      <img class="mb-4" width="135" src="/images/analytics_icons/NoResponses.svg" :draggable="false"/>
      <p class="title mb-3">Let's collect some answers!</p>
      <p class="subtitle-2 grey_500--text font-weight-regular">Try sharing this form with more people so we can begin
        analyzing the results</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResponses"
}
</script>

<style lang="scss" scoped>
//.no-responses-container {
//    width: 100%;
//    flex-grow: 1;
//}
</style>