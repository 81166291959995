var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": "",
      "options": _vm.fieldObj.content.options,
      "is-editing-active": _vm.isEditingActive,
      "current-loading-image-index": _vm.currentLoadingImageIndex
    },
    on: {
      "remove-option": function ($event) {
        return _vm.removeOption($event);
      },
      "start-adding-image": function ($event) {
        return _vm.startAddingImage($event);
      },
      "change-image": function ($event) {
        return _vm.startAddingImage($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "addOptionCard",
      fn: function () {
        return [_vm.isGridHasEmptySpace ? _c('div', {
          class: ['generic-field__escape-form-font', 'image-choice-field-view__card-wrapper', 'visible-when-field-editing', !(_vm.isGridHasEmptySpace && _vm.isEditingActive && !_vm.isExpandingTransitionGoing) && 'visible-when-field-editing--hidden']
        }, [_c('div', {
          staticClass: "image-choice-field-view__add-option-card",
          on: {
            "click": _vm.addOption
          }
        }, [_c('PlusIcon', {
          attrs: {
            "size": "26",
            "stroke-width": "1.5"
          }
        })], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "addButton",
      fn: function () {
        return [_c('AddOptionButton', {
          attrs: {
            "is-editing-active": _vm.isEditingActive,
            "hidden": !(!_vm.isGridHasEmptySpace && _vm.isEditingActive && !_vm.isExpandingTransitionGoing)
          },
          on: {
            "add-option": _vm.addOption
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }