var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['checkout-billing-plan-card', _vm.selected && 'checkout-billing-plan-card--selected'],
    attrs: {
      "data-characteristic": _vm.characteristic
    }
  }, [_c('div', {
    staticClass: "checkout-billing-plan-card__content",
    on: {
      "click": function ($event) {
        !_vm.selected && !_vm.currentPlan ? _vm.$emit('select') : null;
      }
    }
  }, [_c('div', {
    staticClass: "checkout-billing-plan-card__info"
  }, [_c('div', {
    staticClass: "checkout-billing-plan-card__heading"
  }, [_c('div', {
    staticClass: "checkout-billing-plan-card__name"
  }, [_c('span', [_vm._v(_vm._s(_vm.name))])]), _vm.currentPlan ? _c('div', {
    staticClass: "checkout-billing-plan-card__current-plan"
  }, [_c('span', [_vm._v("Current Plan")])]) : _vm._e()]), _c('div', {
    staticClass: "checkout-billing-plan-card__description"
  }, [_c('span', [_vm._v(_vm._s(_vm.description))])]), _c('div', {
    staticClass: "checkout-billing-plan-card__price-layout"
  }, [_c('div', {
    staticClass: "checkout-billing-plan-card__price",
    attrs: {
      "data-currency": "$"
    }
  }, [_vm._v(_vm._s(_vm.price))]), _c('div', {
    staticClass: "checkout-billing-plan-card__price-annotation"
  }, [_vm._v("Billed yearly")])])]), _c('div', {
    staticClass: "checkout-billing-plan-card__included-features"
  }, _vm._l(_vm.features, function (feature, index) {
    return _c('div', {
      key: 'feature' + index,
      staticClass: "checkout-billing-plan-card-included-feature"
    }, [_c('div', {
      staticClass: "checkout-billing-plan-card-included-feature__icon"
    }, [_c('CheckIcon')], 1), _c('span', {
      staticClass: "checkout-billing-plan-card-included-feature__name"
    }, [_vm._v(_vm._s(feature))])]);
  }), 0), _c('div', {
    staticClass: "checkout-billing-plan-card__see-all-features"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('see-all-features');
      }
    }
  }, [_vm._v("See all features")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }