<template>
    <div>
        <v-dialog
            v-model="dialogVisible"
            max-width="850"
        >
            <template #activator="{on}">
                <slot name="activator" :on="on">
                </slot>
            </template>
            <v-card>
                <div class="dialog-container">

                    <div style="cursor: pointer; position: absolute; top: 20px; right: 20px">
                        <XIcon @click="closeDialog"/>

                    </div>
                    <div style="padding: 20px; font-size: 20px;">
                        Terms and Conditions
                        <v-spacer/>
                    </div>
                    <div style="height: 800px; overflow-y: scroll; padding: 20px">

                        Last updated: 7 April 2023<br>
                        <br>
                        These terms and conditions will regulate your usage of the Formsly App, accessible through
                        <a href="https://www.formsly.app/">https://www.formsly.app/</a>
                        (hereinafter referred to as “the Site”. By accessing the Site and using
                        the App You agree to be bound to these terms and conditions. You must cease to use and/or browse
                        through the Site if you do not want to be bound to these terms.<br>
                        <br>
                        <b>Definitions</b><br>
                        "Services" means any and all services provided by us, including but not limited to forms
                        creation and data collection.<br>
                        "Site" means our website and any related applications, software, or platforms.<br>
                        <br>
                        <b>Updates and Modifications</b><br>

                        We reserve the right to modify, amend, update or delete the Services, the Site, and the
                        information provided through them from time to time, without prior notice.<br>
                        <br>
                        <b>Use of Services and Site</b><br>

                        You are permitted to use the Services and Site for personal and business use. You agree not
                        to<br>
                        use the Services and Site for any illegal, unauthorized, or prohibited purposes.<br>
                        <br>
                        <b>Account Registration and Use</b><br>

                        To access our services, you must create an account and provide accurate and complete<br>
                        registration information. You are solely responsible for maintaining the confidentiality of your<br>
                        account login information and for all activities that occur under your account.<br>
                        <br>
                        <b>User Content:</b><br>

                        You are responsible for any content You upload or submit to the website, and You must have
                        the<br>
                        necessary rights and permissions to do so in case that content is copyrighted or requires a<br>
                        license to be used.<br>
                        <br>
                        <b>Plans Prices and Description</b> <br>

                        The available Plans pricing ad description can be found at the following link:<br>
                        <a href="https://www.formsly.app/plans">www.formsly.app/plans</a><br>
                        <br>
                        <b>Payment and sales taxes</b><br>

                        Payment for Paid Plans is collected via debit or credit card through third-party payment gateway<br>
                        processors. These third-party payment gateway processors may charge VAT or GST depending on<br>
                        sales tax laws in your specific region.<br>
                        You warrant and state to be the owner of the card used to subscribe to our paid plans, or
                        that<br>
                        you are duly authorized to use the card in the event the card is not under your name. Any<br>
                        unauthorized use of a card shall be the sole responsibility and liability of the User.<br>
                        <br>
                        <b> Forms Creation and Data Collection</b><br>
                        You are solely responsible for the creation and content of your forms, and the collection
                        and<br>
                        disclosure of data through the Services and Site. You agree to comply with all applicable
                        laws,<br>
                        regulations, and ethical standards in the creation and use of your forms, and the collection and<br>
                        disclosure of data.<br>
                        <br>
                        <b>Subscription Recurring Billing and Payment</b> <br>

                        Formsly’s subscription is charged in advance on a regular basis. At the end of each billing<br>
                        period, your monthly or yearly subscription will renew automatically unless you cancel
                        before<br>
                        the next billing cycle. You can find more information about canceling or downgrading your<br>
                        subscription on this page or in your account billing settings.<br>
                        To cancel your subscription, please email the following email address requesting your
                        account<br>
                        subscription Support@formsly.app<br>
                        <br>
                        <b>Changing, upgrading or downgrading your subscription plan:</b><br>

                        The act of upgrading your subscription plan will result in an addition or increase in
                        features<br>
                        like the number of forms allowed, monthly responses, submissions, available space, and
                        storage.<br>
                        On the other hand, downgrading your plan will lead to a reduction in some or all of those<br>
                        features from your next billing date, which could also cause a loss of forms and
                        submissions.<br>
                        <br>
                        <b>Refund and 14 days money-back policy:</b> <br>

                        Formsly does not use refunds for paid plans. If you are a resident of the European Union,
                        you<br>
                        can request a refund for your paid plan only within 14 days after your contracting of a paid<br>
                        plan.<br>
                        <br>
                        Under DIRECTIVE 2011/83/EU OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 25 October 2011
                        on<br>
                        consumer rights, amending Council Directive 93/13/EEC and Directive 1999/44/EC of the
                        European<br>
                        Parliament and of the Council and repealing Council Directive 85/577/EEC and Directive
                        97/7/EC<br>
                        of the European Parliament and of the Council, you will not be eligible for the 14 days
                        money<br>
                        back guarantee if you use Formsly for business purposes such as your trade, business, or<br>
                        exercise of your profession.<br>
                        <br>
                        <b>Cancellation</b><br>

                        To cancel your annual plan subscription, please send an email to help@formsly.app requesting<br>
                        your plan cancellation. The cancellation of your subscribed plan will be effective for the<br>
                        following recurring payment Billing Period.<br>
                        <br>
                        <b>Intellectual Property</b> <br>
                        All intellectual property rights, including but not limited to patents, trademarks, and<br>
                        copyrights, in the Services, Site, and any related materials, are owned by us Megamoon
                        Ventures<br>
                        FZCO, or our licensors. You are granted a limited, non-exclusive, non-transferable license
                        to<br>
                        use the Services and Site for personal and business use.<br>
                        <br>
                        <b>Indemnification</b><br>

                        You agree to indemnify, defend, and hold harmless us, our officers, directors, employees,<br>
                        agents, partners, suppliers, and licensors from and against any claim, loss, expense, liability,<br>
                        damage or demand, including reasonable attorney’s fees, relating to, arising from, or
                        allegedly<br>
                        arising from your use of the Services and/or the Site, your breach of these Terms, or your<br>
                        violation of any laws or the legal rights of any third party.<br>
                        <br>
                        <b>Termination of Access</b><br>
                        We reserve the right to terminate your access to the Services and/or the Site at any time,<br>
                        without prior notice, if we believe that you have violated these Terms or any applicable
                        laws.<br>
                        <br>
                        <b>Disclaimers</b><br>

                        The Services and Site are provided on an "as is" and "as available" basis, without warranties of<br>
                        any kind, express or implied. We make no representations or warranties of any kind, express
                        or<br>
                        implied, as to the operation of the Services or Site, or the information, content, materials, or<br>
                        products included therein.<br>
                        <br>
                        <b>Limitation of Liability</b><br>

                        In no event shall we or our officers, directors, employees, agents, partners, suppliers, or<br>
                        licensors be liable for any indirect, incidental, special, or consequential damages arising from<br>
                        or in connection with your use of the Services or Site, even if we have been advised of the<br>
                        possibility of such damages.<br>
                        <br>
                        <b>Governing Law and Jurisdiction</b><br>

                        These Terms shall be governed by and construed in accordance with the laws of the
                        jurisdiction<br>
                        where our company is located. Any dispute arising out of or related to these Terms shall be<br>
                        resolved through binding arbitration, and the decision of the arbitrator shall be final and<br>
                        binding.<br>
                        <br>
                        <b>Entire Agreement</b><br>

                        These Terms constitute the entire agreement between you and us with respect to the Services
                        and<br>
                        Site, and supersede all prior or contemporaneous communications and proposals, whether oral
                        or<br>
                        written, between you and us.<br>
                        By accessing and using our Services and Site, you acknowledge that you have read,
                        understood,<br>
                        and agreed to these Terms and Conditions.<br>
                        <br>
                        <b>Miscellaneous</b><br>
                        The contract and sections’ headings of this Agreement are included for the convenience only
                        and<br>
                        shall not affect the construction or interpretation of these provisions.
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {XIcon} from 'vue-feather-icons'

    export default {
        name: "TermOfUse",
        components: {
            XIcon
        },
        data() {
            return {
                dialogVisible: this.isVisible
            };
        },
        methods: {
            closeDialog() {
                this.dialogVisible = false;
                this.$emit('update:isVisible', false);
            }
        },
        props: {
            isVisible: {
                type: Boolean,
            },
        }
    }
</script>

<style lang="scss">
    dialog-container {
        display: flex;
        padding: 20px;
        position: relative;

        &-x-icon {
            position: absolute;
            top: 25px;
            right: 25px;
            color: #8A8F99;
            stroke-width: 1px;
            cursor: pointer;
        }

        &-title {
            font-size: 26px;
            line-height: 31px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }

</style>