<script>
  import { mapActions, mapGetters } from '@/xstore'

  export default {
    name: 'FormPresetSelection',
    components: {
      GenericFormCreationLayout: () =>
        import('@/components/FormCreation/GenericFormCreationLayout.vue'),
    },
    computed: {
      ...mapGetters('forms_list', ['getFormsList']),
      isStatic() {
        return this.$route.params.form_type === 'static'
      },
      cards() {
        const formTypeConst = this.isStatic
          ? this.constants.FORM_TYPES.STATIC_FORM
          : this.constants.FORM_TYPES.INTERACTIVE_FORM

        return [
          {
            title: 'Start from Scratch',
            subtitle: 'Start with a blank Survey',
            imageComponent: formTypeConst.BLANK_IMG_PATH,
            onSelect: () => this.createBlankForm(),
          },
          {
            title: 'with Formsly AI',
            subtitle: 'Describe it and Formsly AI will build it for you',
            imageComponent: formTypeConst.AI_IMG_PATH,
            onSelect: () =>
              this.$router.push({
                name: this.constants.PAGE_NAME_FORM_AI_PROMPT,
                params: { form_type: this.$route.params.form_type },
              }),
          },
        ]
      },
    },
    methods: {
      ...mapActions('form_templates', {
        _createBlankForm: 'createBlankForm',
      }),
      checkIfFormCreationIsAllowed(cb) {
        return new Promise(resolve => {
          this.$store.dispatch(
            'user/plan/current_subscription/monitoring_features/validatePaidFeature',
            {
              featureName: 'forms_count',
              actualValue: this.getFormsList.length,
              initialMethod: () => Promise.resolve(cb()).then(resolve),
              validator: (actualValue, featureValue) =>
                actualValue < featureValue,
            }
          )
        })
      },
      afterFormCreation(formID) {
        this.SequenceDirector.createBlankSequence(
          this.constants.NEW_FORM_TUTORIAL,
          2
        )
        this.$nextTick(() =>
          this.$router.push({
            name: this.constants.PAGE_NAME_FORM_EDITOR,
            params: {
              formId: formID,
            },
          })
        )
      },
      async createBlankForm() {
        const { formID } = await this._createBlankForm({
          isStatic: this.isStatic,
        })
        this.afterFormCreation(formID)
      },
    },
  }
</script>

<template>
  <GenericFormCreationLayout
    :cards="cards"
    title="How would you like it to be created?"
    caption-above-the-title="Step 2 of 3"
    subtitle="Start from scratch, or let our AI wizard build it."
  />
</template>
