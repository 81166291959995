export default {
  "v": "5.7.1",
  "fr": 60,
  "ip": 0,
  "op": 40,
  "w": 1000,
  "h": 1000,
  "nm": "ui-star",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "blue Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500.001,
            474.549,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            285.442,
            271.484,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 3,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 19,
              "s": [
                120,
                120,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 25,
              "s": [
                90,
                90,
                100
              ]
            },
            {
              "t": 29,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -4.284,
                      -13.053
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -6.132,
                      -0.018
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      11.091,
                      -8.108
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.878,
                      -5.838
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      11.139,
                      8.042
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.971,
                      -3.59
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.207,
                      13.078
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.95,
                      3.619
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -13.737,
                      0.041
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.912,
                      5.826
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      1.912,
                      5.826
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      13.739,
                      0.041
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.95,
                      3.619
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.206,
                      13.078
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.971,
                      -3.59
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.138,
                      8.042
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.878,
                      -5.838
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.09,
                      -8.108
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.132,
                      -0.018
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.284,
                      -13.053
                    ]
                  ],
                  "v": [
                    [
                      13.516,
                      -258.181
                    ],
                    [
                      70.27,
                      -85.251
                    ],
                    [
                      83.744,
                      -75.462
                    ],
                    [
                      265.747,
                      -74.924
                    ],
                    [
                      274.101,
                      -49.214
                    ],
                    [
                      127.173,
                      58.2
                    ],
                    [
                      122.027,
                      74.04
                    ],
                    [
                      177.757,
                      247.302
                    ],
                    [
                      155.887,
                      263.192
                    ],
                    [
                      8.327,
                      156.648
                    ],
                    [
                      -8.328,
                      156.648
                    ],
                    [
                      -155.888,
                      263.192
                    ],
                    [
                      -177.758,
                      247.302
                    ],
                    [
                      -122.028,
                      74.04
                    ],
                    [
                      -127.174,
                      58.2
                    ],
                    [
                      -274.102,
                      -49.214
                    ],
                    [
                      -265.749,
                      -74.924
                    ],
                    [
                      -83.745,
                      -75.462
                    ],
                    [
                      -70.271,
                      -85.251
                    ],
                    [
                      -13.517,
                      -258.181
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  285.442,
                  271.484
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 40,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "gray Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500.001,
            474.549,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            285.442,
            271.484,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 0,
              "s": [
                100,
                100,
                100
              ]
            },
            {
              "t": 3,
              "s": [
                0,
                0,
                100
              ]
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -4.284,
                      -13.053
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -6.132,
                      -0.018
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      11.091,
                      -8.108
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.878,
                      -5.838
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      11.139,
                      8.042
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.971,
                      -3.59
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.207,
                      13.078
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.95,
                      3.619
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -13.737,
                      0.041
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.912,
                      5.826
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      1.912,
                      5.826
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      13.739,
                      0.041
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.95,
                      3.619
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.206,
                      13.078
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.971,
                      -3.59
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.138,
                      8.042
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.878,
                      -5.838
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.09,
                      -8.108
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.132,
                      -0.018
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.284,
                      -13.053
                    ]
                  ],
                  "v": [
                    [
                      13.516,
                      -258.181
                    ],
                    [
                      70.27,
                      -85.251
                    ],
                    [
                      83.744,
                      -75.462
                    ],
                    [
                      265.747,
                      -74.924
                    ],
                    [
                      274.101,
                      -49.214
                    ],
                    [
                      127.173,
                      58.2
                    ],
                    [
                      122.027,
                      74.04
                    ],
                    [
                      177.757,
                      247.302
                    ],
                    [
                      155.887,
                      263.192
                    ],
                    [
                      8.327,
                      156.648
                    ],
                    [
                      -8.328,
                      156.648
                    ],
                    [
                      -155.888,
                      263.192
                    ],
                    [
                      -177.758,
                      247.302
                    ],
                    [
                      -122.028,
                      74.04
                    ],
                    [
                      -127.174,
                      58.2
                    ],
                    [
                      -274.102,
                      -49.214
                    ],
                    [
                      -265.749,
                      -74.924
                    ],
                    [
                      -83.745,
                      -75.462
                    ],
                    [
                      -70.271,
                      -85.251
                    ],
                    [
                      -13.517,
                      -258.181
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.9058823529411765,
                  0.9058823529411765,
                  0.9058823529411765,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  285.442,
                  271.484
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 40,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "lines Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500.626,
            540.002,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            402.414,
            382.521,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -89.464,
                      -28.406
                    ],
                    [
                      89.464,
                      28.407
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 15,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  707.864,
                  440.118
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      401.789,
                      569.855
                    ],
                    [
                      401.789,
                      757.541
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 15,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      55.197,
                      -75.35
                    ],
                    [
                      -55.197,
                      75.35
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 15,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  592.376,
                  83.424
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 180,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      89.199,
                      -29.232
                    ],
                    [
                      -89.199,
                      29.232
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 15,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  96.699,
                  443.168
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -55.399,
                      -75.753
                    ],
                    [
                      55.398,
                      75.753
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9725490196078431,
                  0.9058823529411765,
                  0.10980392156862745,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 15,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  212.184,
                  83.252
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 180,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 21,
                "s": [
                  0
                ]
              },
              {
                "t": 27,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 16,
                "s": [
                  0
                ]
              },
              {
                "t": 22,
                "s": [
                  100
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 6,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 40,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Shape Layer 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 15.008,
              "s": [
                100
              ]
            },
            {
              "t": 25.01953125,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500,
            500,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 7,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 29.0234375,
              "s": [
                150,
                150,
                100
              ]
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "d": 1,
              "ty": "el",
              "s": {
                "a": 0,
                "k": [
                  934,
                  934
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "nm": "Ellipse Path 1",
              "mn": "ADBE Vector Shape - Ellipse",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.9607843137254902,
                  0.6509803921568628,
                  0.13725490196078433,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 4,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "gf",
              "o": {
                "a": 0,
                "k": 100,
                "ix": 10
              },
              "r": 1,
              "bm": 0,
              "g": {
                "p": 3,
                "k": {
                  "a": 0,
                  "k": [
                    0,
                    0,
                    0.294,
                    1,
                    0.499,
                    0,
                    0.388,
                    1,
                    0.999,
                    0,
                    0.482,
                    1,
                    0,
                    0,
                    0.303,
                    0.5,
                    0.606,
                    1
                  ],
                  "ix": 9
                }
              },
              "s": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 5
              },
              "e": {
                "a": 0,
                "k": [
                  1581,
                  1162
                ],
                "ix": 6
              },
              "t": 2,
              "h": {
                "a": 0,
                "k": 0,
                "ix": 7
              },
              "a": {
                "a": 0,
                "k": 0,
                "ix": 8
              },
              "nm": "Gradient Fill 1",
              "mn": "ADBE Vector Graphic - G-Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  2
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Ellipse 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 40,
      "op": 40,
      "st": 0,
      "bm": 0,
      "hidden": 0
    }
  ],
  "markers": []
}