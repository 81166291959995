<template>
  <SharedView
    editable
    :from="fieldProperties.scale_poles.from"
    :to="fieldProperties.scale_poles.to"
    :labels="fieldProperties.range_labels"
  />
</template>

<script>
import editable_field_view_mixin
  from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

export default {
  name: "Editable__Slider",
  mixins: [editable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  }
}
</script>