import { executeGraphQLQueryGet } from '@/utils/graphql_executors'
import { getFormMetrics } from '@formsly/shared/api/graphql'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

export default ({useExtension}) => {
    {
        const state = {form_id: undefined}
        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    {
        const state = {form_metrics: undefined}
        useExtension(ToggleableExtensions.Loadable, {state})
    }

    {
        const state = {
            total_responses: undefined,
            all_views: undefined,
            completion_rate: undefined,
            average_time: undefined,
            drop_offs: undefined
        }
        useExtension(SMAGableExtensions.SGable, {state, varNameCustomizer: variableName => variableName + '_metric'})
    }

    return {
        mutations: {
            SET_FORM_METRICS: (state, payload) => Object.entries(payload)
                .forEach(([key, value]) => {
                    if (key in state)
                        state[key] = value
                }),
        },
        actions: {
            fetchFormMetrics: ({commit, getters, toggleIsLoading}) => toggleIsLoading(
                executeGraphQLQueryGet(getFormMetrics, {formID: getters.getFormId})
                    .then(({
                               totalResponses,
                               allViews,
                               completionRate,
                               averageTime,
                               dropOffs
                           }) => commit('SET_FORM_METRICS', {
                        total_responses_metric: totalResponses,
                        all_views_metric: allViews,
                        completion_rate_metric: completionRate,
                        average_time_metric: averageTime,
                        drop_offs_metric: dropOffs
                    })),
                'form_metrics'
            )
        }
    }
}
