var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M109.097 62.1048C109.097 88.3651 87.809 109.653 61.5486 109.653C35.2882 109.653 14 88.3651 14 62.1048C14 35.8444 35.2882 14.5562 61.5486 14.5562C87.809 14.5562 109.097 35.8444 109.097 62.1048Z",
      "fill": "#E3EAFF"
    }
  }), _c('rect', {
    attrs: {
      "x": "97.9766",
      "y": "14",
      "width": "91.2043",
      "height": "73.9645",
      "rx": "6",
      "transform": "rotate(90 97.9766 14)",
      "fill": "url(#paint0_linear_8104_41337)",
      "stroke": "black",
      "stroke-opacity": "0.08"
    }
  }), _c('rect', {
    attrs: {
      "width": "24.4695",
      "height": "3",
      "rx": "1.5",
      "transform": "matrix(-1 0 0 1 60.1602 44)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "11.1225",
      "height": "3",
      "rx": "1.5",
      "transform": "matrix(-1 0 0 1 46.8105 50.3511)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "x": "35.6895",
      "y": "66",
      "width": "13.347",
      "height": "4.44899",
      "rx": "2.2245",
      "fill": "url(#paint1_linear_8104_41337)"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_8104_41337",
      "x1": "144.776",
      "y1": "14",
      "x2": "144.776",
      "y2": "89.3587",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_8104_41337",
      "x1": "35.6614",
      "y1": "68.2248",
      "x2": "49.0332",
      "y2": "68.2248",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3D66F6"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#6789FF"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }