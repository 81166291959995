export default {
    "v": "5.7.1",
    "fr": 60,
    "ip": 0,
    "op": 40,
    "w": 1000,
    "h": 1000,
    "nm": "ui-like",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "Gray Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [501.802, 500.749, 0], "ix": 2},
            "a": {"a": 0, "k": [252.06, 245.339, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 0,
                    "s": [100, 100, 100]
                }, {"t": 3, "s": [0, 0, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0.048, -31.802], [19.242, -0.032], [26.671, 0.065], [0, 18.628], [-0.032, 64.153], [-18.126, 0.033], [-26.947, -0.032], [-0.033, -19.421], [0, -31.802]],
                        "o": [[-0.032, 19.308], [-26.688, 0.065], [-18.903, -0.032], [-0.032, -64.137], [0, -18.207], [26.946, -0.032], [19.113, 0.033], [0.064, 31.801], [0.016, 31.8]],
                        "v": [[66.217, 95.437], [39.902, 121.849], [-40.144, 121.849], [-66.233, 96.084], [-66.233, -96.343], [-40.808, -121.882], [40.048, -121.882], [66.201, -95.372], [66.217, 0.033]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.7529411764705882, 0.7529411764705882, 0.7529411764705882, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [66.515, 325.072], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": {
                        "i": [[-13.476, -0.005], [-12.394, -0.171], [-11.983, -21.479], [13.481, -21.619], [-2.286, -8.956], [9.993, -15.492], [-0.708, -3.966], [12.989, -16.383], [0.097, -4.48], [49.628, -2.545], [49.474, 18.284], [21.801, 5.012], [0.005, 13.847], [-0.048, 64.411], [-16.311, 4.19], [-3.687, 4.032], [-24.613, 30.347], [-3.585, 17.112], [-9.425, 13.142], [-10.378, -0.777], [-8.935, -3.213], [-3.284, -24.517], [11.881, -23.27], [3.734, -7.462], [-6.306, 0.162]],
                        "o": [[12.396, 0.005], [24.049, 0.331], [12.397, 22.225], [-4.752, 7.62], [4.537, 17.776], [-2.208, 3.424], [3.654, 20.461], [-2.546, 3.211], [-1.067, 49.453], [-52.759, 2.708], [-20.941, -7.74], [-13.483, -3.099], [-0.029, -64.41], [0.014, -17.058], [5.478, -1.407], [26.374, -28.843], [11.471, -14.143], [3.212, -15.324], [6.05, -8.438], [9.439, 0.707], [25.118, 9.036], [3.471, 25.92], [-3.794, 7.43], [-2.426, 4.851], [13.466, -0.346]],
                        "v": [[74.23, -82.286], [111.419, -82.251], [167.376, -51.547], [165.484, 15.238], [161.119, 38.416], [152.381, 88.676], [150.523, 99.39], [136.832, 154.827], [132.303, 165.645], [56.098, 242.381], [-96.895, 218.998], [-161.261, 200.436], [-179.732, 177.082], [-179.724, -16.151], [-159.601, -42.261], [-146.298, -50.964], [-69.997, -139.914], [-52.314, -188.785], [-36.425, -232.431], [-12.399, -244.312], [15.27, -238.936], [52.872, -185.307], [40.441, -111.635], [29.509, -89.116], [33.806, -82.185]],
                        "c": true
                    }, "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.7529411764705882, 0.7529411764705882, 0.7529411764705882, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [324.098, 245.339], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }], "nm": "Group 2", "np": 2, "cix": 2, "bm": 0, "ix": 2, "mn": "ADBE Vector Group", "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "green Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500.429, 500.278, 0], "ix": 2},
            "a": {"a": 0, "k": [252.06, 245.339, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 3,
                    "s": [0, 0, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 19,
                    "s": [120, 120, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 25,
                    "s": [90, 90, 100]
                }, {"t": 29, "s": [100, 100, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0.048, -31.802], [19.242, -0.032], [26.671, 0.065], [0, 18.628], [-0.032, 64.153], [-18.126, 0.033], [-26.947, -0.032], [-0.033, -19.421], [0, -31.802]],
                        "o": [[-0.032, 19.308], [-26.688, 0.065], [-18.903, -0.032], [-0.032, -64.137], [0, -18.207], [26.946, -0.032], [19.113, 0.033], [0.064, 31.801], [0.016, 31.8]],
                        "v": [[66.217, 95.437], [39.902, 121.849], [-40.144, 121.849], [-66.233, 96.084], [-66.233, -96.343], [-40.808, -121.882], [40.048, -121.882], [66.201, -95.372], [66.217, 0.033]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.13725490196078433, 0.7529411764705882, 0.9725490196078431, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [66.515, 325.072], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": {
                        "i": [[-13.476, -0.005], [-12.394, -0.171], [-11.983, -21.479], [13.481, -21.619], [-2.286, -8.956], [9.993, -15.492], [-0.708, -3.966], [12.989, -16.383], [0.097, -4.48], [49.628, -2.545], [49.474, 18.284], [21.801, 5.012], [0.005, 13.847], [-0.048, 64.411], [-16.311, 4.19], [-3.687, 4.032], [-24.613, 30.347], [-3.585, 17.112], [-9.425, 13.142], [-10.378, -0.777], [-8.935, -3.213], [-3.284, -24.517], [11.881, -23.27], [3.734, -7.462], [-6.306, 0.162]],
                        "o": [[12.396, 0.005], [24.049, 0.331], [12.397, 22.225], [-4.752, 7.62], [4.537, 17.776], [-2.208, 3.424], [3.654, 20.461], [-2.546, 3.211], [-1.067, 49.453], [-52.759, 2.708], [-20.941, -7.74], [-13.483, -3.099], [-0.029, -64.41], [0.014, -17.058], [5.478, -1.407], [26.374, -28.843], [11.471, -14.143], [3.212, -15.324], [6.05, -8.438], [9.439, 0.707], [25.118, 9.036], [3.471, 25.92], [-3.794, 7.43], [-2.426, 4.851], [13.466, -0.346]],
                        "v": [[74.23, -82.286], [111.419, -82.251], [167.376, -51.547], [165.484, 15.238], [161.119, 38.416], [152.381, 88.676], [150.523, 99.39], [136.832, 154.827], [132.303, 165.645], [56.098, 242.381], [-96.895, 218.998], [-161.261, 200.436], [-179.732, 177.082], [-179.724, -16.151], [-159.601, -42.261], [-146.298, -50.964], [-69.997, -139.914], [-52.314, -188.785], [-36.425, -232.431], [-12.399, -244.312], [15.27, -238.936], [52.872, -185.307], [40.441, -111.635], [29.509, -89.116], [33.806, -82.185]],
                        "c": true
                    }, "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.13725490196078433, 0.7529411764705882, 0.9725490196078431, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [324.098, 245.339], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }], "nm": "Group 2", "np": 2, "cix": 2, "bm": 0, "ix": 2, "mn": "ADBE Vector Group", "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "lines Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500.431, 483.004, 0], "ix": 2},
            "a": {"a": 0, "k": [468.475, 469.562, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[-62.001, -73.045], [62.002, 73.046]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.20392156862745098, 0.7294117647058823, 0.996078431372549, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15.444, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [744.329, 833.406], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[55.356, -80.326], [-55.356, 80.326]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.20392156862745098, 0.7294117647058823, 0.996078431372549, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15.444, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [240.281, 851.076], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[-84.999, 37.571], [84.999, -37.571]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.20392156862745098, 0.7294117647058823, 0.996078431372549, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15.444, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [844.23, 355.506], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 3",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[86.25, 38.145], [-86.25, -38.145]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.20392156862745098, 0.7294117647058823, 0.996078431372549, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15.444, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [93.972, 340.137], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 4",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[0.049, 99.482], [-0.049, -99.482]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.20392156862745098, 0.7294117647058823, 0.996078431372549, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15.444, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [481.062, 107.205], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 5",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "tm",
            "s": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 21, "s": [0]}, {
                    "t": 27,
                    "s": [100]
                }],
                "ix": 1
            },
            "e": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 16, "s": [0]}, {
                    "t": 22,
                    "s": [100]
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "m": 1,
            "ix": 6,
            "nm": "Trim Paths 1",
            "mn": "ADBE Vector Filter - Trim",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 4,
        "ty": 4,
        "nm": "Shape Layer 1",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 15.008,
                    "s": [100]
                }, {"t": 25.01953125, "s": [0]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500, 536, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 7,
                    "s": [0, 0, 100]
                }, {"t": 29.0234375, "s": [150, 150, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "d": 1,
                "ty": "el",
                "s": {"a": 0, "k": [934, 934], "ix": 2},
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "nm": "Ellipse Path 1",
                "mn": "ADBE Vector Shape - Ellipse",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.23921568627450981, 0.796078431372549, 0.9647058823529412, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 4, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "gf",
                "o": {"a": 0, "k": 100, "ix": 10},
                "r": 1,
                "bm": 0,
                "g": {
                    "p": 3,
                    "k": {
                        "a": 0,
                        "k": [0, 0.275, 0.769, 0.408, 0.262, 0.137, 0.808, 0.318, 0.525, 0, 0.847, 0.229, 0, 0, 0.214, 0.5, 0.429, 1],
                        "ix": 9
                    }
                },
                "s": {"a": 0, "k": [0, 0], "ix": 5},
                "e": {"a": 0, "k": [1581, 1162], "ix": 6},
                "t": 2,
                "h": {"a": 0, "k": 0, "ix": 7},
                "a": {"a": 0, "k": 0, "ix": 8},
                "nm": "Gradient Fill 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 2], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Ellipse 1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 40,
        "op": 40,
        "st": 0,
        "bm": 0,
        "hidden": 0
    }],
    "markers": []
}