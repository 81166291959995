var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm._t("header"), _c('v-spacer'), _c('div', {
    staticClass: "switcher d-flex align-center"
  }, [_c('span', {
    staticClass: "caption mr-2 gray_500--text"
  }, [_vm._v("Show Full Text")]), _c('f-switch', {
    model: {
      value: _vm.showFullText,
      callback: function ($$v) {
        _vm.showFullText = $$v;
      },
      expression: "showFullText"
    }
  })], 1)], 2), _c('div', {
    staticClass: "smart-paragraph-field-view-full-single-answer"
  }, [_vm.showFullText ? _c('span', {
    staticClass: "smart-paragraph-field-view-full-single-answer__content"
  }, [_vm._l(_vm.sourceObj.content, function (element, key) {
    return [element.type === 'text' ? [_vm._v(_vm._s(element.value))] : element.type === 'break' ? _c('br', {
      key: key
    }) : element.type === 'variable' ? _c('span', {
      key: key,
      staticClass: "smart-paragraph-field-view-full-single-answer-variable-container",
      domProps: {
        "innerHTML": _vm._s(_vm.submissionObj.value[element.variable_id])
      }
    }) : _vm._e()];
  })], 2) : _c('div', {
    staticClass: "smart-paragraph-field-view-full-single-answer__variables-table-wrapper"
  }, [_c('table', [_c('tbody', _vm._l(_vm.variablesList, function (_ref) {
    var variable_id = _ref.variable_id;
    return _c('tr', {
      key: 'tr-' + variable_id
    }, [_c('td', {
      staticClass: "smart-paragraph-field-view-full-single-answer__table-variable-name"
    }, [_vm._v(" " + _vm._s(_vm.sourceObj.properties.variables_list[variable_id] || 'Untitled variable') + " ")]), _c('td', {
      staticClass: "smart-paragraph-field-view-full-single-answer__table-variable-value",
      domProps: {
        "innerHTML": _vm._s(_vm.submissionObj.value[variable_id])
      }
    })]);
  }), 0)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }