var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormslyFormWrapper', {
    attrs: {
      "width": "1920"
    }
  }, [_c('FSPVariablesInjector', {
    attrs: {
      "fsp-module-location-path": _vm.fspModuleLocationPath
    }
  }, [_vm.formJSON ? [_vm.isFormStatic ? _c('div', {
    class: ['static-form', _vm.formJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.formJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--full-screen visible-header', _vm.formJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.formJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--full-screen invisible-header', _vm.formJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.formJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--compact visible-header', _vm.formJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.formJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--compact invisible-header'],
    style: Object.assign({}, _vm.staticFormBackgroundColor && {
      '--static-form-background-color': _vm.staticFormBackgroundColor
    }, {
      transformOrigin: 'top'
    }, _vm.staticFormScale)
  }, [_c('div', {
    staticClass: "static-form__container"
  }, [_c('div', {
    staticClass: "static-form__cover-wrapper"
  }, [_c('div', {
    staticClass: "static-form__cover",
    style: {
      backgroundImage: 'url(' + _vm.formJSON.cover_image + ')'
    }
  }), _c('div', {
    staticClass: "moveable-area h-full d-flex justify-center align-center text-h3"
  }, [_c('span', {
    ref: "textContainer",
    staticClass: "text-container text-container--submittable",
    style: {
      transform: _vm.formJSON.cover_text.translate,
      height: _vm.formJSON.cover_text.height,
      width: _vm.formJSON.cover_text.width
    },
    domProps: {
      "innerHTML": _vm._s(_vm.formJSON.cover_text.content)
    }
  })])]), _c('div', {
    class: ['static-form__page-wrapper', _vm.formJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE && ' visible-logo', _vm.formJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE && 'invisible-logo']
  }, [_c('LogoBadge', {
    attrs: {
      "logo-url": _vm.formJSON.logo_url
    }
  }), _c('div', {
    staticClass: "static-form__page"
  }, [_c('div', {
    staticClass: "static-main-page"
  }, [_c('div', {
    staticClass: "static-main-page__content"
  }, [_c('div', {
    staticClass: "static-main-page-fields-layout"
  }, _vm._l(_vm.currentPageObj.rows, function (row, rowIndex) {
    return _c('v-row', {
      key: rowIndex
    }, _vm._l(row.fields, function (field) {
      return _c('v-col', {
        key: field.field_id
      }, [_c('div', {
        staticClass: "static-submittable-field-constructor"
      }, [_c('GenericField', {
        attrs: {
          "modifiers": ['static', 'submittable', ("width-" + (field.width))],
          "field-id": field.field_id,
          "field-obj": _vm.formJSON.fields[field.field_id],
          "field-type-const": _vm.constants.FIELD_FROM_TYPE[_vm.formJSON.fields[field.field_id].field_type]
        }
      }, [_c('div', {
        staticClass: "generic-field__view"
      }, [_c(_vm.constants.FIELD_FROM_TYPE[_vm.formJSON.fields[field.field_id].field_type].PREVIEW_VIEW, {
        tag: "component",
        attrs: {
          "field-obj": _vm.formJSON.fields[field.field_id],
          "is-form-static": _vm.isFormStatic,
          "is-form-interactive": _vm.isFormInteractive
        }
      }), _c('div', {
        staticClass: "generic-field__error-messages-wrapper"
      })], 1)])], 1)]);
    }), 1);
  }), 1)])])])], 1)])]) : _vm.isFormInteractive ? _c('div', {
    staticClass: "interactive-form interactive-form--submittable",
    style: Object.assign({
      transformOrigin: 'top'
    }, _vm.interactiveFormScale)
  }, [_c('InteractiveHomePage', {
    attrs: {
      "page-obj": _vm.formJSON.all_pages[_vm.formJSON.home_page.page_id]
    }
  })], 1) : _vm._e()] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }