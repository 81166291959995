<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
            <v-spacer/>
            <SummaryChangeSortOrder v-model="sortOrder"/>
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="selection-field-view-summary">
                <Iterator v-if="!!visibleOptions" :limitation="visibleOptions" :key="'shown-iterator-' + sortOrder">
                    <SummaryRowWithProgress
                            v-for="optionKey in sortedOptionsWithPercentage?.shown"
                            :label="optionsWithPercentage[optionKey]?.label"
                            :key="optionKey"
                            :count="optionsWithPercentage[optionKey]?.count"
                            :percentage="optionsWithPercentage[optionKey]?.percentage"
                    >
                        <template #prepend>
                            <slot :option="optionsWithPercentage[optionKey]" name="prepend"></slot>
                        </template>
                    </SummaryRowWithProgress>
                </Iterator>
                <div v-if="sortedOptionsWithPercentage?.hidden.length">
                    <SummaryHiddenAnswers :hidden-count="sortedOptionsWithPercentage?.hidden.length">
                        <div
                                v-for="optionKey in sortedOptionsWithPercentage?.hidden"
                                class="d-flex align-center my-2"
                                :key="optionKey"
                        >
                            <span>{{ optionsWithPercentage[optionKey].count }} responses</span>
                            <span class="mx-1">·</span>
                            <span v-if="optionsWithPercentage[optionKey]?.label"
                                  class="mr-2">{{ optionsWithPercentage[optionKey]?.label }}</span>
                        </div>
                    </SummaryHiddenAnswers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "GenericSelectionSummaryFieldView",
        components: {
            SummaryRowWithProgress: () => import('@/components/Fields/AnalyticsField/Summary/Utils/SummaryRowWithProgress'),
            Iterator: () => import ('./Iterator'),

        },
        props: {
            optionsFormatter: {
                type: Function,
            },
            totalResponse:
            {
                type: Number,
                default: 0
            },

        },
        mixins: [summary_answer_field_view_mixin],
        data: () => ({
            sortOrder: summary_answer_field_view_mixin.data().SORT_ORDER.DEFAULT
        }),
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => value.length)
            },
            sortedOptionsWithPercentage() {
                const latestVersionOptionsKeys = this.optionsFormatter(this.latestSourceObj);

                const result = {
                    shown: Array(latestVersionOptionsKeys.length),
                    hidden: []
                }

                Object.keys(this.optionsWithPercentage)
                    .forEach(key => {
                        if (latestVersionOptionsKeys.includes(key))
                            result.shown[latestVersionOptionsKeys.indexOf(key)] = key
                        else if (this.optionsWithPercentage[key].count)
                            result.hidden.push(key)
                    })

                if (this.sortOrder === this.SORT_ORDER.DESCENDING || result.shown.length >= 16)
                    result.shown.sort((a, b) => this.optionsWithPercentage[b].count - this.optionsWithPercentage[a].count)

                return result
            },
            optionsWithPercentage() {
                const result = {}

                this.submissions.forEach(
                    ({submissionObj, versionID}) => {
                        this.optionsFormatter(this.versionedSources[versionID])
                            .forEach((option, index) => {
                                if (!(option in result)) {
                                    result[option] = {
                                        count: 0,
                                        percentage: 0,
                                        label: option,
                                        index,
                                    }
                                }
                            })
                        submissionObj.value
                            .forEach(optionIndex => {
                                result[this.optionsFormatter(this.versionedSources[versionID])[optionIndex]].count++
                            })
                    }
                )

                Object.values(result)
                    .forEach((optionData, _, self) => {
                        optionData.percentage = Math.round(optionData.count / self.reduce((accum, {count}) => accum + count, 0) * 100) || 0
                    })

                return result
            },
            visibleOptions() {
                return this.sortedOptionsWithPercentage.shown.length >= 16 ? Object.values(this.optionsWithPercentage).filter(item => item.count).length : this.sortedOptionsWithPercentage.shown.length;
            },

        },
        mounted() {
            this.$emit('mounted');
        }
    }
</script>