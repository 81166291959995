var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "conditional-logic-expression conditional-logic-expression--Number"
  }, [_c('v-text-field', {
    staticClass: "conditional-logic-expression__expression-input",
    attrs: {
      "placeholder": "Add statement",
      "type": "number",
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    nativeOn: {
      "input": function ($event) {
        return _vm.handleInput.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('f-select', {
          staticClass: "conditional-logic-expression__operator-picker",
          attrs: {
            "items": _vm.expressionOperators,
            "item-text": function (item) {
              return item.text;
            },
            "item-value": function (item) {
              return item.value;
            },
            "menu-props": {
              nudgeBottom: 54
            },
            "hide-details": "",
            "solo": "",
            "flat": "",
            "dense": ""
          },
          model: {
            value: _vm.expressionObj.operator,
            callback: function ($$v) {
              _vm.$set(_vm.expressionObj, "operator", $$v);
            },
            expression: "expressionObj.operator"
          }
        })];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          key: JSON.stringify(item),
          staticClass: "my-1",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item) + " "), _c('XIcon', {
          staticClass: "cursor-pointer ml-1",
          attrs: {
            "size": "12"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.expressionObj.statement,
      callback: function ($$v) {
        _vm.$set(_vm.expressionObj, "statement", _vm._n($$v));
      },
      expression: "expressionObj.statement"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }