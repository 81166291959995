var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "nudge-top": "25",
      "nudge-right": "75",
      "open-on-hover": "",
      "top": "",
      "eager": "",
      "transition": "slide-y-reverse-transition",
      "open-delay": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "address-field-view-short-single-answer"
        }, on), [_vm._v(_vm._s(_vm.getAddressValues || '-'))])];
      }
    }])
  }, [_c('v-card', {
    staticClass: "pa-6"
  }, [_c('FullSingleAnswer', {
    attrs: {
      "submissionObj": _vm.submissionObj
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }