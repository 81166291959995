var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "label": _vm.label,
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "input": _vm.handleInput
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }