var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFrontView', {
    ref: "genericFrontView",
    attrs: {
      "options-list": _vm.fieldObj.content.options.map(function (_, index) {
        return index;
      }),
      "radio": _vm.radio,
      "editable": "",
      "field-obj": _vm.fieldObj,
      "is-form-static": _vm.isFormStatic,
      "is-form-interactive": _vm.isFormInteractive
    },
    on: {
      "insert-option": _vm.insertOption
    },
    scopedSlots: _vm._u([{
      key: "option-append",
      fn: function (_ref) {
        var hover = _ref.hover,
          focused = _ref.focused,
          index = _ref.index;
        return [_c('v-scale-transition', {
          attrs: {
            "origin": "center center 0"
          }
        }, [(hover || focused) && _vm.fieldObj.content.options.length > 1 ? _c('XIcon', {
          key: "remove-option-btn",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.removeOption(index);
            }
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: "extension",
      fn: function () {
        return [_c('AddOptionButton', {
          attrs: {
            "is-editing-active": _vm.isEditingActive
          },
          on: {
            "add-option": function ($event) {
              return _vm.insertOption(_vm.fieldObj.content.options.length);
            }
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }