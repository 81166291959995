var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "30",
      "viewBox": "0 0 26 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13 1.5L1 7.14706C1.56471 20.7 9.23529 27.3824 13 29.0294C13 29.0294 24.2941 24.7941 25 7.14706L13 1.5Z",
      "stroke": "#15B57F"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.0001 5.02942L5.23535 9.08372C5.60075 18.814 10.5641 23.6116 13.0001 24.7941C13.0001 24.7941 20.308 21.7534 20.7648 9.08372L13.0001 5.02942Z",
      "fill": "url(#paint0_linear_5994_39182)"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.1194 13.326C10.0587 13.2577 9.98644 13.2035 9.90691 13.1665C9.82739 13.1295 9.74212 13.1104 9.65599 13.1104C9.56987 13.1104 9.4846 13.1295 9.40507 13.1665C9.32555 13.2035 9.25333 13.2577 9.19257 13.326C9.13154 13.394 9.08311 13.4749 9.05006 13.5639C9.01701 13.6529 9 13.7484 9 13.8448C9 13.9412 9.01701 14.0366 9.05006 14.1257C9.08311 14.2147 9.13154 14.2955 9.19257 14.3636L11.8345 17.2539L11.8728 17.2967C11.9303 17.3611 11.9985 17.4123 12.0737 17.4472C12.1488 17.482 12.2294 17.5 12.3107 17.5C12.3921 17.5 12.4726 17.482 12.5478 17.4472C12.6229 17.4123 12.6912 17.3611 12.7486 17.2967L16.8184 12.7409C16.8759 12.6765 16.9216 12.6001 16.9528 12.516C16.984 12.4319 17 12.3417 17 12.2506C17 12.1596 16.984 12.0694 16.9528 11.9853C16.9216 11.9012 16.8759 11.8247 16.8184 11.7604L16.7674 11.7033C16.7099 11.6389 16.6416 11.5877 16.5665 11.5528C16.4914 11.518 16.4108 11.5 16.3295 11.5C16.2481 11.5 16.1675 11.518 16.0924 11.5528C16.0173 11.5877 15.949 11.6389 15.8915 11.7033L12.3098 15.7108L10.1194 13.326Z",
      "fill": "#057A55"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_5994_39182",
      "x1": "10.1765",
      "y1": "7.14707",
      "x2": "18.6471",
      "y2": "26.2059",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#BCF0DA"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#BCF0DA",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }