<template>
    <div class="dropdown-field-view">
        <GenericStringSingleLineFieldViewInput assign-generic-view-class v-model="fieldObj.content.placeholder">
            <template #append>
                <ChevronDownIcon/>
            </template>
        </GenericStringSingleLineFieldViewInput>
        <div class="d-flex justify-space-between">
            <div>
                <AddOptionButton
                        @add-option="isDialogOpened = true"
                        :is-editing-active="isEditingActive"
                >
                  <template #default>
                    {{fieldObj.content.options.length ? 'Edit options' : 'Add options'}}
                  </template>
                  <template #icon v-if="fieldObj.content.options.length">
                    <EditIcon size="16" strokeWidth="1.5"/>
                  </template>
                </AddOptionButton>
            </div>
            <div
                    :class="[
                        'dropdown-field-view__options-count',
                        'visible-when-field-editing',
                        !isEditingActive && 'visible-when-field-editing--hidden',
                        'mt-2'
                    ]"
            >
                {{optionsCount}}
            </div>
        </div>
        <EditableDialog
                v-if="isDialogOpened"
                :is-conditioned="isConditioned"
                :conditional-statement="isConditioned ? fieldObj.condition.expression.statement : []"
                :field-obj="fieldObj"
                :field-const="fieldConst"
                :is-dialog-opened="isDialogOpened"
                :options-external="fieldObj.content.options"
                @close="isDialogOpened = false"
                @save="saveList"
        />
    </div>
</template>

<script>
    import {ref, computed} from '@vue/composition-api';

    import {
        editable_field_view_mixin,
        useEditableFieldViewExtension
    } from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: 'Editable__Dropdown',
        mixins: [editable_field_view_mixin],
        components: {
            EditableDialog: () => import ('./Сomponents/EditableDialog'),
            GenericStringSingleLineFieldViewInput: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput'),
            ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon'),
            AddOptionButton: () => import('@/components/Fields/EditableField/EditableFieldViewUtils/GenericAddOptionButton'),
            EditIcon: () => import('vue-feather-icons/icons/EditIcon'),
        },
        setup(props, context) {
            const extension = useEditableFieldViewExtension(props, context);
            const isDialogOpened = ref(false);

            const saveList = (v) => {
                props.fieldObj.content.options = v;
                isDialogOpened.value = false;
            }

            const optionsCount = computed(() => {
                let length

                if (Array.isArray(props.fieldObj.content.options)) {
                    length = props.fieldObj.content.options.length
                } else {
                    length = extension.fieldConst.UTIL.READY_LISTS[props.fieldObj.content.options].LIST.length
                }

                return `${length} ${length === 1 ? 'option' : 'options'} in list`
            })

            return {
                isDialogOpened,
                saveList,
                ...extension,
                extension,
                optionsCount
            }
        },

    }
</script>