<template>
  <div class="mobile-warning-container">
    <AppBarEmpty/>
    <div class="mobile-warning">
      The Mobile version of Formsly is not ready yet. Please sign in from from your computer or tablet to access the
      app.
    </div>
  </div>
</template>

<script>

export default {
  name: 'MobileWarning',
  components: {
    AppBarEmpty: () => import('@/components/AppBar/AppBarTypes/AppBarEmpty')
  }
}
</script>

<style scoped lang="scss">
.mobile-warning-container {
  width: 100%;
  height: 100%;

  .mobile-warning {
    font-size: 14px;
    color: #545B66;
    background: #F8F9FE;
    padding: 120px 30px;
    height: 100%;
  }
}
</style>