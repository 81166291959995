<template>
    <span>
        <v-dialog v-model="conditionalLogicEditorDialog" width="714">
            <ConditionalLogicEditor
                    v-if="conditionalLogicEditorDialog"
                    @close-dialog="conditionalLogicEditorDialog = false"
            />
        </v-dialog>
        <GenericStoreDialog store-module-path="edit/conditional/confirmation_dialog"/>
    </span>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "ConditionalLogicHandler",
        components: {
            ConditionalLogicEditor: () => import('./ConditionalLogicEditor'),

            GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog')
        },
        computed: {
            ...mapGetters('edit/conditional', [
                'getConditionalLogicEditorModel',
            ]),
            conditionalLogicEditorDialog: {
                get() {
                    return this.getConditionalLogicEditorModel
                },
                set(v) {
                    if (!v)
                        this.$store.dispatch('edit/conditional/closeConditionalLogicEditor')
                }
            },
        },
    }
</script>