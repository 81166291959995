<template>
    <div v-if="visibilityControlledByConditionalLogic" class="editable-field-property__caption red_400--text">
        Block visibility is controlled by a
        <span class="blue_400--text cursor-pointer" @click="showConditionOwningThisField">
            conditional logic.
        </span>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'

    export default {
        name: "BlockVisibilityIsControlledByConditionalLogicCaption",
        props: {
            fieldId: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('edit/conditional', ['getTargetedAndConditionedFields']),
            visibilityControlledByConditionalLogic() {
                return this.getTargetedAndConditionedFields[this.fieldId]?.targeted
            }
        },
        methods: {
            showConditionOwningThisField() {
                this.$store.dispatch('edit/conditional/openConditionalLogicEditor', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
            }
        }
    }
</script>

<style scoped>

</style>