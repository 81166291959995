var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "closed-form-wrapper"
  }, [_c('div', {
    staticClass: "closed-form-wrapper__title"
  }, [_vm._v("Sorry, but this form is no longer available! ")]), _c('div', {
    staticClass: "closed-form-wrapper__description"
  }, [_vm._v("It was crafted with Formsly. Want to give it a try? ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": _vm.routeToDashboard
    }
  }, [_vm._v("Create a free form ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }