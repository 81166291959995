<template>
  <SharedView
      :src="fieldProperties.image_content.url"
      :lazy-src="fieldProperties.image_content.url"
      :width="fieldProperties.image_size.width + '%'"
      :aspect-ratio="(fieldProperties.image_content.naturalWidth / fieldProperties.image_content.naturalHeight) / (fieldProperties.image_size.height / 100)"
  />
</template>

<script>
    import submittable_field_view_mixin from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

    export default {
        name: "Submittable__Image",
        mixins: [submittable_field_view_mixin],
        components: {
          SharedView: () => import('./SharedView'),
        }
    }
</script>