export default {
    "v": "5.7.1",
    "fr": 60,
    "ip": 0,
    "op": 40,
    "w": 1000,
    "h": 1000,
    "nm": "ui-heart",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "gray Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [491.901, 494.01, 0], "ix": 2},
            "a": {"a": 0, "k": [291.597, 260.454, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 0,
                    "s": [100, 100, 100]
                }, {"t": 3, "s": [0, 0, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, -42.459], [32.405, -32.404], [0, 0], [16.858, 16.858], [0, 0], [0, 0], [-64.792, 64.792], [-42.459, 0], [-32.388, -32.404], [-64.808, -64.792]],
                        "o": [[0, 42.459], [0, 0], [-16.857, 16.858], [0, 0], [0, 0], [-64.792, -64.792], [32.403, -32.404], [42.459, 0], [64.808, -64.792], [32.405, 32.404]],
                        "v": [[291.347, -78.091], [242.749, 39.231], [38.634, 243.346], [-22.441, 243.346], [-109.234, 156.554], [-226.555, 39.231], [-226.555, -195.413], [-109.234, -244.011], [8.089, -195.413], [242.749, -195.413]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.7686274509803922, 0.7686274509803922, 0.7686274509803922, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [291.597, 260.455], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "red Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [491.901, 494.01, 0], "ix": 2},
            "a": {"a": 0, "k": [291.597, 260.454, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 3,
                    "s": [0, 0, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 19,
                    "s": [120, 120, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 25,
                    "s": [90, 90, 100]
                }, {"t": 29, "s": [100, 100, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, -42.459], [32.405, -32.404], [0, 0], [16.858, 16.858], [0, 0], [0, 0], [-64.792, 64.792], [-42.459, 0], [-32.388, -32.404], [-64.808, -64.792]],
                        "o": [[0, 42.459], [0, 0], [-16.857, 16.858], [0, 0], [0, 0], [-64.792, -64.792], [32.403, -32.404], [42.459, 0], [64.808, -64.792], [32.405, 32.404]],
                        "v": [[291.347, -78.091], [242.749, 39.231], [38.634, 243.346], [-22.441, 243.346], [-109.234, 156.554], [-226.555, 39.231], [-226.555, -195.413], [-109.234, -244.011], [8.089, -195.413], [242.749, -195.413]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [291.597, 260.455], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "lines Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [502.216, 511.739, 0], "ix": 2},
            "a": {"a": 0, "k": [493.366, 467.117, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[-89.464, -28.406], [89.464, 28.406]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [889.767, 544.902], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[491.15, 739.047], [491.15, 926.733]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[55.197, -75.35], [-55.197, 75.35]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [736.771, 83.252], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 180, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 4",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[89.199, -29.232], [-89.199, 29.232]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [96.699, 547.748], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 5",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0]],
                        "v": [[-55.399, -75.753], [55.399, 75.753]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.987999949736, 0.368999974868, 0.349000010771, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [246.279, 83.253], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 180, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 6",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "tm",
            "s": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 21, "s": [0]}, {
                    "t": 27,
                    "s": [100]
                }],
                "ix": 1
            },
            "e": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 16, "s": [0]}, {
                    "t": 22,
                    "s": [100]
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "m": 1,
            "ix": 6,
            "nm": "Trim Paths 1",
            "mn": "ADBE Vector Filter - Trim",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 4,
        "ty": 4,
        "nm": "Shape Layer 1",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 15.008,
                    "s": [100]
                }, {"t": 25.01953125, "s": [0]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500, 500, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 7,
                    "s": [0, 0, 100]
                }, {"t": 29.0234375, "s": [150, 150, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "d": 1,
                "ty": "el",
                "s": {"a": 0, "k": [934, 934], "ix": 2},
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "nm": "Ellipse Path 1",
                "mn": "ADBE Vector Shape - Ellipse",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.909803926945, 0.265406697989, 0.189096495509, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 4, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "gf",
                "o": {"a": 0, "k": 100, "ix": 10},
                "r": 1,
                "bm": 0,
                "g": {
                    "p": 3,
                    "k": {
                        "a": 0,
                        "k": [0, 1, 0.287, 0.278, 0.499, 0.995, 0.266, 0.253, 0.999, 0.99, 0.245, 0.227, 0, 0, 0.303, 0.5, 0.606, 1],
                        "ix": 9
                    }
                },
                "s": {"a": 0, "k": [0, 0], "ix": 5},
                "e": {"a": 0, "k": [1581, 1162], "ix": 6},
                "t": 2,
                "h": {"a": 0, "k": 0, "ix": 7},
                "a": {"a": 0, "k": 0, "ix": 8},
                "nm": "Gradient Fill 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 2], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Ellipse 1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }],
    "markers": []
}