var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "generic-field__view"
  }, [_c(_vm.fieldTypeConst.EDITABLE_VIEW, _vm._b({
    tag: "component",
    attrs: {
      "field-obj": _vm.fieldObj,
      "is-editing-active": _vm.isEditingActive
    }
  }, 'component', _vm.$attrs, false)), _c('div', {
    staticClass: "generic-field__error-messages-wrapper"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }