var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full d-flex align-center justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.currentStage === _vm.stages.CREATE_NEW_PASSWORD ? _c('VerificationTemplate', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('ErrorAlertComponent')];
      },
      proxy: true
    }, {
      key: "code-input",
      fn: function () {
        return [_c('div', {
          staticClass: "text-start mb-2"
        }, [_vm._v("New Password "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Create a password",
            "type": "password",
            "outlined": "",
            "disabled": _vm.$store.getters.isLoading,
            "error-messages": _vm.$v.newPassword.$errors.map(function (_ref) {
              var $message = _ref.$message;
              return $message;
            })
          },
          model: {
            value: _vm.$v.newPassword.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.newPassword, "$model", $$v);
            },
            expression: "$v.newPassword.$model"
          }
        }), _c('div', {
          staticClass: "text-start mb-2"
        }, [_vm._v("Confirm New Password "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Create a password",
            "type": "password",
            "outlined": "",
            "disabled": _vm.$store.getters.isLoading,
            "error-messages": _vm.$v.confirmNewPassword.$errors.map(function (_ref2) {
              var $message = _ref2.$message;
              return $message;
            })
          },
          model: {
            value: _vm.$v.confirmNewPassword.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.confirmNewPassword, "$model", $$v);
            },
            expression: "$v.confirmNewPassword.$model"
          }
        })];
      },
      proxy: true
    }, {
      key: "action",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "block": "",
            "depressed": "",
            "rounded": "",
            "color": "blue_400",
            "disabled": _vm.$store.getters.isLoading || !!_vm.$v.$silentErrors.length
          },
          on: {
            "click": _vm.resetPassword
          }
        }, [_vm._v(" Reset Password ")])];
      },
      proxy: true
    }], null, false, 1798160197)
  }) : _vm._e(), _vm.currentStage === _vm.stages.PASSWORD_SUCCESSFULLY_RESET ? _c('VerificationTemplate', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-6 d-flex rounded-circle green_100"
        }, [_c('CheckCircleIcon', {
          staticClass: "green_500--text"
        })], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Password has been successfully reset ")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_vm._v(" Click below to log in magically. ")];
      },
      proxy: true
    }, {
      key: "action",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "block": "",
            "depressed": "",
            "rounded": "",
            "color": "blue_400"
          },
          on: {
            "click": _vm.continueLogin
          }
        }, [_vm._v(" Continue ")])];
      },
      proxy: true
    }], null, false, 2945082394)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }