import { VTextField } from 'vuetify/lib/components'

export default {
    name: 'PaymentMethodCardForm_Name',
    functional: true,
    props: {
        bind: {
            type: Object,
            required: true
        }
    },
    render(h, ctx) {
        return [
            h('p', {class: {'subtitle-2': true, 'mb-1': true}}, 'Name on card'),
            h(VTextField, {...ctx.props.bind.name.value})
        ]
    }
}