var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "share-form-menu"
  }, [_c('v-card-title', [_c('v-spacer'), _c('XIcon', {
    staticClass: "cursor-pointer mr-4 mt-2",
    attrs: {
      "size": "24"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', false);
      }
    }
  })], 1), _c('v-card-text', [_c('div', {
    staticClass: "d-flex flex-no-wrap menu-content"
  }, [_c('div', {
    ref: "preview-wrapper",
    staticClass: "form-preview__wrapper"
  }, [_c('f-aspect-ratio-keeper', {
    attrs: {
      "width": "16",
      "height": "9"
    }
  }, [_c('div', {
    staticClass: "form-preview"
  }, [_c('div', {
    staticClass: "form-preview-scaler"
  }, [_c('EmbeddedFormPreview', {
    attrs: {
      "formJSON": _vm.formJSON,
      "static-form-scale": _vm.staticFormScale,
      "is-form-static": _vm.isFormStatic,
      "is-form-interactive": _vm.isFormInteractive,
      "fsp-module-location-path": _vm.fspModuleLocationPath,
      "interactive-form-scale": _vm.interactiveFormScale
    }
  })], 1)])])], 1), _c('div', {
    staticClass: "share-content"
  }, [_c('v-card-subtitle', {
    staticClass: "align-center pb-0 grayscale_black--text d-flex flex-row"
  }, [_vm._t("subtitle")], 2), _c('v-card-title', {
    staticClass: "grayscale_black--text pt-2"
  }, [_vm._t("title")], 2), _c('v-card-subtitle', {
    staticClass: "grayscale_black--text"
  }, [_vm._v(" Get the link ")]), _c('v-text-field', {
    ref: "link",
    staticClass: "px-4",
    attrs: {
      "flat": "",
      "solo": "",
      "outlined": "",
      "persistent-hint": "",
      "value": _vm.shareLink,
      "hint": _vm.copied ? 'Copied!' : '',
      "readonly": ""
    },
    on: {
      "copy": _vm.onCopy,
      "focus": function ($event) {
        return $event.target.select();
      }
    }
  }, [_c('template', {
    slot: "append"
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "icon": ""
    },
    on: {
      "click": _vm.copy
    }
  }, [_c('CopyIcon')], 1)], 1)], 2), _c('v-card-subtitle', {
    staticClass: "grayscale_black--text"
  }, [_vm._v(" Or share with the world ")]), _c('div', {
    staticClass: "share-content__icons icons"
  }, _vm._l(_vm.shareIcons, function (item, index) {
    return _c('v-btn', {
      key: index,
      staticClass: "pa-0 icons__social",
      attrs: {
        "href": item.URL,
        "target": "_blank"
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "42"
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")])], 1);
  }), 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }