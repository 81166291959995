var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('v-spacer'), _c('SummaryChangeSortOrder', {
    model: {
      value: _vm.sortOrder,
      callback: function ($$v) {
        _vm.sortOrder = $$v;
      },
      expression: "sortOrder"
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "image-choice-field-view-summary"
  }, [_vm._l(_vm.sortedOptionsWithPercentage.shown, function (optionKey) {
    return _c('SummaryRowWithProgress', {
      key: optionKey,
      attrs: {
        "label": _vm.optionsWithPercentage[optionKey].label,
        "count": _vm.optionsWithPercentage[optionKey].count,
        "percentage": _vm.optionsWithPercentage[optionKey].percentage
      },
      scopedSlots: _vm._u([{
        key: "prepend",
        fn: function () {
          return [_c('v-img', {
            staticClass: "image-choice-field-view-summary__image",
            attrs: {
              "src": _vm.optionsWithPercentage[optionKey].image,
              "aspect-ratio": "1"
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  }), _vm.sortedOptionsWithPercentage.hidden.length ? _c('div', [_c('SummaryHiddenAnswers', {
    attrs: {
      "hidden-count": _vm.sortedOptionsWithPercentage.hidden.length
    }
  }, _vm._l(_vm.sortedOptionsWithPercentage.hidden, function (optionKey) {
    return _c('div', {
      key: optionKey,
      staticClass: "d-flex align-center my-2"
    }, [_c('span', [_vm._v(_vm._s(_vm.optionsWithPercentage[optionKey].count) + " responses")]), _c('span', {
      staticClass: "mx-1"
    }, [_vm._v("·")]), _vm.optionsWithPercentage[optionKey].label ? _c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.optionsWithPercentage[optionKey].label))]) : _vm._e(), _c('v-img', {
      staticClass: "flex-grow-0 rounded-sm",
      attrs: {
        "width": "50",
        "src": _vm.optionsWithPercentage[optionKey].image,
        "aspect-ratio": "1"
      }
    })], 1);
  }), 0)], 1) : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }