var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('AppBarLogoComponent')];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('AppNavigationComponent')];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_vm.isFreePlan ? _c('v-btn', {
          staticClass: "btn-upgrade",
          attrs: {
            "color": "#EBE8FF",
            "rounded": ""
          },
          on: {
            "click": _vm.goToCheckout
          }
        }, [_vm._v(" Upgrade plan ")]) : _vm._e(), _c('ProfileComponent')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }