var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "generic-text-field-view phone-field-view"
  }, [_c('GenericStringSingleLineFieldViewInput', _vm._g({
    directives: [{
      name: "input-mask",
      rawName: "v-input-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    ref: "phone",
    attrs: {
      "value": _vm.value,
      "placeholder": _vm.placeholder,
      "disabled": _vm.preview,
      "is-form-static-prop": _vm.isFormStatic
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('SubmittableFieldGenericDropdown', {
          attrs: {
            "value": _vm.countryCode,
            "filter": _vm.countryFilter,
            "item-value": function (item) {
              return item.code;
            },
            "items": _vm.constants.COUNTRY_LIST,
            "menu-props": {
              minWidth: 355,
              nudgeLeft: 12
            },
            "input-class": "phone-field-view__country-select generic-text-field-view__input-field",
            "flat": "",
            "solo": "",
            "background-color": "transparent",
            "hide-details": "",
            "dense": "",
            "disabled": _vm.editable || _vm.preview
          },
          on: {
            "change": function ($event) {
              return _vm.$emit('change-country', $event);
            }
          },
          nativeOn: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs,
                item = _ref.item,
                component = _ref.component;
              return [_c(component, _vm._g(_vm._b({
                tag: "component",
                scopedSlots: _vm._u([{
                  key: "icon",
                  fn: function () {
                    return [_c('f-country-flag', {
                      attrs: {
                        "code": item.code,
                        "size": "m"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "content",
                  fn: function () {
                    return [_c('span', {
                      staticClass: "phone-field-view__country-name"
                    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('span', {
                      staticClass: "phone-field-view__country-phone-code"
                    }, [_vm._v(" (+" + _vm._s(item.countryCallingCode) + ") ")])];
                  },
                  proxy: true
                }], null, true)
              }, 'component', attrs, false), on))];
            }
          }, {
            key: "selection",
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('f-country-flag', {
                attrs: {
                  "code": item.code,
                  "size": "l"
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  }, _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }