var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "style-preferences-background-image"
  }, [_c('div', {
    staticClass: "style-preferences-background-image__preview-wrapper"
  }, [_c('v-img', {
    staticClass: "style-preferences-background-image__preview",
    attrs: {
      "src": _vm.getCurrentBackgroundImage,
      "aspect-ratio": 1920 / 1080
    }
  }, [_c('ImagePicker', {
    key: "cover",
    attrs: {
      "initial-image": _vm.getThemeBackgroundImage
    },
    on: {
      "preview-image": _vm.setBackgroundImagePreview,
      "cancel": function ($event) {
        return _vm.setBackgroundImagePreview(undefined);
      },
      "select-image": _vm.setNewBackgroundImage
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "style-preferences-background-image__edit-image-btn v-btn--primary",
          attrs: {
            "icon": "",
            "small": ""
          }
        }, on), [_c('Edit2Icon', {
          attrs: {
            "size": "14"
          }
        })], 1)];
      }
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Edit background ")];
      },
      proxy: true
    }])
  })], 1)], 1), false ? _c('v-btn', [_c('RotateCwIcon'), _vm._v(" Reset to original ")], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }