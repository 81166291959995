<template>
    <InteractiveFieldConstructor
            :field-id="fieldId"
    />
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "InteractiveMainPage",
        components: {
            InteractiveFieldConstructor: () => import('../../InteractiveField/InteractiveFieldConstructor')
        },
        props: {
            pageId: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
            fieldId() {
                return this.getFormJSON.all_pages[this.pageId].field_id
            }
        },
    }
</script>