<template>
  <div
    class="d-flex align-center justify-space-between mt-6"
    :class="[!disabled && 'integrations-app']"
    @click="navigate"
  >
    <div class="d-flex align-center">
      <v-icon
        size="36"
        :class="[disabled && 'integrations-app-icon']"
      >
        {{ icon }}
      </v-icon>
      <div class="d-flex flex-column ml-4">
        <span class="integrations-app-title">
          {{ title }}
        </span>
        <span class="integrations-app-subtitle">
          {{ disabled ? 'Coming soon' : descriptions }}
        </span>
      </div>
    </div>
    <div class="d-flex">
      <div
        v-if="connected"
        class="integrations-connected-badge"
      >
        Connected
      </div>
      <ChevronRightIcon
        v-if="!disabled"
        size="20"
      />
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import { computed } from '@vue/composition-api'

export default {
  name: 'IntegrationApp',
  components: {
    ChevronRightIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    connected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      if (!this.disabled) {
        this.$emit('navigate', this.title)
      }
    },
  },
  setup(props) {
    const descriptions = computed(() => {
      return props.subtitle ? props.subtitle : 'Coming soon'
    })

    return { descriptions }
  },
}
</script>

<style lang="scss" scoped>
.integrations-app {
  cursor: pointer;
}

.integrations-app-title {
  color: var(--v-gray_700-base);
  font-size: 16px;
  font-weight: 500;
}

.integrations-app-subtitle {
  color: var(--v-grey_500_2-base);
  font-size: 14px;
  font-weight: 400;
}

.integrations-app-icon {
  opacity: 0.3;
}

.integrations-connected-badge {
  padding: 0 8px;
  color: #0ca08f;
  background: #e9f6f5;
  border-radius: 17px;
  font-size: 10px;
  font-weight: 500;
  margin-right: 12px;
}
</style>
