<template>
    <div class="form-parameters-option">
        <slot name="header"></slot>
        <div v-if="alwaysEnabled || (value && $slots.extension)" class="mt-2">
            <slot name="extension"></slot>
        </div>
        <slot name="hint"></slot>
    </div>
</template>

<script>
    export default {
        name: "FormSettingsOptionTemplate",
        props: {
            value: Boolean,
            alwaysEnabled: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .form-parameters-option {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
</style>