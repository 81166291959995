var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": "",
      "placeholder": "Add placeholder",
      "is-editing-active": _vm.isEditingActive
    },
    model: {
      value: _vm.fieldObj.content.placeholder,
      callback: function ($$v) {
        _vm.$set(_vm.fieldObj.content, "placeholder", $$v);
      },
      expression: "fieldObj.content.placeholder"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }