class FieldPropertyInput {
    constructor(componentName, {
        half_width = false
    } = {}) {
        this.component = () => import(`@/components/Fields/EditableField/EditableFieldProperties/FieldPropertiesComponents/${componentName}`)

        this.half_width = half_width
    }

    generatePropertyInputValue() {
        return this.default
    }
}

export class FieldPropertySelectInput extends FieldPropertyInput {
    constructor({
        items,
        default: defaultValue,
        ...restOptions
    }) {
        super('Select', restOptions)

        this.items = items

        this.#validateAfterInitialization(defaultValue)

        this.default = defaultValue || this.items[0].key
    }

    #validateAfterInitialization(defaultValue) {
        if (!this.items.length)
            throw new Error('"items" must be at list 1 item')
        if (defaultValue && !this.items.some(({key}) => key === defaultValue))
            throw new Error('"defaultValue" must be a valid item key')
    }
}

export class FieldPropertyNumberInput extends FieldPropertyInput {
    constructor({
        default: defaultValue,
        label,
        min_value,
        validator,
        append_inner,
        ...restOptions
    }) {
        super('Number', restOptions)

        this.label = label
        this.min_value = min_value
        this.validator = validator
        this.default = defaultValue
        this.append_inner = append_inner
    }
}

export class FieldPropertyStringInput extends FieldPropertyInput {
    constructor({
        default: defaultValue = null,
        dynamic_label,
        validator,
        ...restOptions
    }) {
        super('String', restOptions)

        this.default = defaultValue
        this.dynamic_label = dynamic_label
        this.validator = validator
    }
}

export class FieldPropertyMultipleStringsInput extends FieldPropertyInput {
    constructor({
        default: defaultValue = null,
        default_enabled = [],
        placeholder,
        validator = () => true,
        ...restOptions
    }) {
        super('MultipleStrings', restOptions)

        this.default = defaultValue
        this.default_enabled = default_enabled
        this.placeholder = placeholder
        this.validator = validator
    }
}

export class FieldPropertyEmojiSetSelectInput extends FieldPropertyInput {
    constructor({
        sets,
        default: defaultValue,
        ...restOptions
    }) {
        super('EmojiSetSelect', restOptions)

        this.sets = sets

        this.#validateAfterInitialization()

        this.default = defaultValue !== undefined ? defaultValue : 0
    }

    #validateAfterInitialization(defaultValue) {
        if (!this.sets.length)
            throw new Error('"sets" must be at list 1 item')
        if (defaultValue && (defaultValue - 1 > this.sets.length || defaultValue - 1 < 0))
            throw new Error('"default" must be a valid item key')
    }
}

export class FieldPropertySingleEmojiSelectInput extends FieldPropertyInput {
    constructor({
        emojis,
        default: defaultValue,
        ...restOptions
    }) {
        super('SingleEmojiSelect', restOptions)

        this.emojis = emojis

        this.#validateAfterInitialization()

        this.default = defaultValue !== undefined ? defaultValue : 0
    }

    #validateAfterInitialization(defaultValue) {
        if (!this.emojis.length)
            throw new Error('"emojis" must be at list 1 item')
        if (defaultValue && (defaultValue - 1 > this.emojis.length || defaultValue - 1 < 0))
            throw new Error('"default" must be a valid item key')
    }
}

export class FieldPropertyImageUploadInput extends FieldPropertyInput {
    constructor({
        default: defaultValue,
        max_size
    }) {
        super('ImageUpload')

        this.default = defaultValue
        this.max_size = max_size
    }
}

export class FieldPropertyCheckboxInput extends FieldPropertyInput {
    constructor({
        default: defaultValue,
        label,
        validator
    }) {
        super('Checkbox')

        this.default = defaultValue
        this.label = label
        this.validator = validator
    }
}

export class FieldPropertySmartParagraphVariables extends FieldPropertyInput {
    constructor({
        default: defaultValue = []
    } = {}) {
        super('SmartParagraphVariables')

        this.default = defaultValue
    }
}