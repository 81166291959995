var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.light ? _c('img', {
    attrs: {
      "src": "/images/LogoLight.svg",
      "draggable": false,
      "alt": "logo",
      "width": "77",
      "height": "20"
    }
  }) : _c('img', {
    attrs: {
      "src": "/images/Logo.svg",
      "draggable": false,
      "alt": "logo",
      "width": "77",
      "height": "20"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }