var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown-field-view"
  }, [_c('GenericStringSingleLineFieldViewInput', {
    attrs: {
      "assign-generic-view-class": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('ChevronDownIcon')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.fieldObj.content.placeholder,
      callback: function ($$v) {
        _vm.$set(_vm.fieldObj.content, "placeholder", $$v);
      },
      expression: "fieldObj.content.placeholder"
    }
  }), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_c('AddOptionButton', {
    attrs: {
      "is-editing-active": _vm.isEditingActive
    },
    on: {
      "add-option": function ($event) {
        _vm.isDialogOpened = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.fieldObj.content.options.length ? 'Edit options' : 'Add options') + " ")];
      },
      proxy: true
    }, _vm.fieldObj.content.options.length ? {
      key: "icon",
      fn: function () {
        return [_c('EditIcon', {
          attrs: {
            "size": "16",
            "strokeWidth": "1.5"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('div', {
    class: ['dropdown-field-view__options-count', 'visible-when-field-editing', !_vm.isEditingActive && 'visible-when-field-editing--hidden', 'mt-2']
  }, [_vm._v(" " + _vm._s(_vm.optionsCount) + " ")])]), _vm.isDialogOpened ? _c('EditableDialog', {
    attrs: {
      "is-conditioned": _vm.isConditioned,
      "conditional-statement": _vm.isConditioned ? _vm.fieldObj.condition.expression.statement : [],
      "field-obj": _vm.fieldObj,
      "field-const": _vm.fieldConst,
      "is-dialog-opened": _vm.isDialogOpened,
      "options-external": _vm.fieldObj.content.options
    },
    on: {
      "close": function ($event) {
        _vm.isDialogOpened = false;
      },
      "save": _vm.saveList
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }