<template>
  <div class="form-parameters-option-header d-flex align-center pb-2">
    <span class="bluish_gray_500--text">
        <slot></slot>
    </span>
    <v-spacer/>
    <f-hover-tooltip  v-if="allowedOnlyWithPayment"
                      top
                      max-width="250"
                      text="You need to upgrade to a paid plan in order to use this feature"
                      v-slot="{on}"
    >
          <f-badge purple
                   v-on="on"
                   @click="showDialog"
          >
            Paid
          </f-badge>
    </f-hover-tooltip>
    <f-switch
        v-if="!alwaysEnabled"
        :value="value"
        @input="$emit('input', $event)"
        :disabled="allowedOnlyWithPayment"
    />
  </div>
</template>

<script>
export default {
  name: 'FormSettingsOptionTemplateHeader',

  data: () => ({
    shareModalVisible: false,
  }),
  props: {
    value: Boolean,
    alwaysEnabled: {
      type: Boolean,
      default: false
    },
    allowedOnlyWithPayment: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showDialog() {
      this.$store.dispatch('user/plan/current_subscription/monitoring_features/monitoring_features_dialog/showDialog', true);
    }
  }
}
</script>

<style scoped lang="scss">
.form-parameters-option-header {
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  height: 20px;

  .f-badge {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>