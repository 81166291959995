var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#E9F5F8"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.8148 8.18509C17.5696 7.95958 17.245 7.84053 16.9121 7.85407C16.5793 7.86761 16.2654 8.01263 16.0393 8.25732L10.6949 13.6018L9.99072 16.0092L12.3981 15.305L17.7426 9.99065C17.8682 9.87831 17.97 9.74185 18.0419 9.5894C18.1138 9.43694 18.1543 9.27159 18.1611 9.10317C18.1678 8.93474 18.1406 8.76668 18.0811 8.60897C18.0217 8.45126 17.9311 8.30711 17.8148 8.18509V8.18509Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.4167 18.4165H7.58334",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }