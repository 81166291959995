var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-submittable-field-constructor"
  }, [_c('GenericField', {
    attrs: {
      "modifiers": ['static', 'submittable', ("width-" + (_vm.fieldWidth)), _vm.isErrorState && 'generic-field--error-state'],
      "field-id": _vm.fieldId,
      "field-obj": _vm.sourceObj,
      "field-type-const": _vm.fieldTypeConst
    }
  }, [_c('SubmittableFieldViewsGenerator', {
    attrs: {
      "field-id": _vm.fieldId,
      "source-obj": _vm.sourceObj,
      "submission-obj": _vm.submissionObj,
      "field-width": _vm.fieldWidth
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }