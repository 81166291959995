<template>
    <div class="f-badge f-badge--rounded"
         :class="[green && 'f-badge--success',
                  gray && 'f-badge--secondary',
                  purple && 'f-badge--warn',
                  white && 'f-badge--white',
         ]"
         v-on="$listeners"
    >
        <div v-if="dot" class="f-badge__dot"></div>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            green: {
                type: Boolean,
                default: false,
            },
            gray: {
                type: Boolean,
                default: false,
            },
            purple: {
                type: Boolean,
                default: false,
            },
            dot: {
                type: Boolean,
                default: false,
            },
            white: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

<style scoped lang="scss">
    .f-badge {
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: .875rem;
        line-height: 134%;
        padding: 4px 8px;
        height: min-content;
        border-radius: 4px;
        cursor: default;

        &.f-badge--success {
            background-color: var(--v-green_100-base);
            color: var(--v-green_500-base);

            .f-badge__dot {
                color: var(--v-green_400-base);
            }
        }

        &.f-badge--secondary {
            background-color: var(--v-grey_100-base);
            color: var(--v-gray_700-base);

            .f-badge__dot {
                color: var(--v-grey_500_2-base);
            }
        }
        &.f-badge--warn {
            color: #7D63FF;
            background: #F2EFFF;
            font-size: 10px;
        }
        &.f-badge--white {
            color: #000;
            background: #fff;
            border: 1px solid #e8e8e8;
            padding: 0 7px;
            line-height: 18px;
            font-size: 12px;
        }

        &.f-badge--rounded {
            border-radius: 1000px;
        }

        &__dot {
            width: 6px;
            height: 6px;
            border-radius: 1000px;
            background-color: currentColor;
        }
    }
</style>