import { computed, inject, provide, watch } from '@vue/composition-api'

const useEditableFieldViewExtension = (props, {root}) => {
    const subOnPropertyUpdate = (propertyName, callback) => watch(() => props.fieldObj.properties[propertyName], callback)
    const isConditioned = computed(() => 'condition' in props.fieldObj)
    const fieldConst = root.constants.FIELD_FROM_TYPE[props.fieldObj.field_type]
    const fieldProperties = computed(() => props.fieldObj.properties)

    const formJSON = inject('formJSON')

    const isFormStatic = computed(() => formJSON.value.form_type === root.constants.FORM_TYPES.STATIC_FORM.FORM_TYPE)
    const isFormInteractive = computed(() => formJSON.value.form_type === root.constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE)
    provide('isFormStatic', isFormStatic)

    const removeOption = (nextAction) => {
        root.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
            header: 'Remove Condition',
            text: 'This option is controlling a conditional logic, by removing this option(s) it will remove the conditional logic applied to it',
            confirmBtnText: 'Remove anyway',
            callback: () => {
                if (nextAction)
                    nextAction()
            }
        })
    }
    const changeCondition = (nextAction) => {
        root.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
            header: 'Change Condition',
            text: 'This option is controlling a conditional logic, by removing this option it will change the conditional logic applied to it',
            confirmBtnText: 'Yes, change it',
            callback: () => {
                nextAction()
            }
        })
    }

    return {
        subOnPropertyUpdate,
        isConditioned,
        fieldProperties,
        fieldConst,

        isFormStatic,
        isFormInteractive,

        removeOption,
        changeCondition,
    }
}

const editable_field_view_mixin = {
    props: {
        fieldObj: {
            type: Object,
            required: true
        },
        isEditingActive: {
            type: Boolean,
            required: true
        },
        fieldWidth: Number // only for static forms
    },
}

export default {
    ...editable_field_view_mixin,
    setup(props, context) {
        return {
            ...useEditableFieldViewExtension(props, context)
        }
    }
}

export {
    editable_field_view_mixin,
    useEditableFieldViewExtension
}