var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "matrix-field-view-full-single-answer"
  }, [_c('div', {
    staticClass: "matrix-field-view__table-wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('td'), _vm._l(_vm.sourceObj.content.columns, function (columnHeader, columnIndex) {
    return _c('td', {
      key: 'column' + columnIndex,
      staticClass: "matrix-field-view__column-cell"
    }, [_c('span', [_vm._v(_vm._s(columnHeader.text))])]);
  })], 2)]), _c('tbody', _vm._l(_vm.sourceObj.content.rows, function (rowHeader, rowIndex) {
    return _c('tr', {
      key: 'row' + rowIndex
    }, [_c('td', {
      staticClass: "matrix-field-view__row-name matrix-field-view__sticky-left-cell"
    }, [_c('span', [_vm._v(_vm._s(rowHeader.text))])]), _vm._l(_vm.sourceObj.content.columns.length, function (_, columnIndex) {
      return _c('td', {
        key: 'row' + rowIndex + 'column' + columnIndex,
        staticClass: "matrix-field-view__column-cell"
      }, [_vm.submissionObj.value[rowIndex].includes(columnIndex) ? _c('CheckIcon') : _vm._e()], 1);
    })], 2);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }