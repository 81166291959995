<template>
  <SharedView
      editable
      :scale-poles="fieldProperties.scale_poles"
      :labels="fieldProperties.labels"
  />
</template>

<script>
import editable_field_view_mixin
  from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

export default {
  name: 'Editable__OpinionScale',
  mixins: [editable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  },
}
</script>