<template>
    <v-container fluid>
        <p class="settings-view__title">Plan and payments</p>
        <p class="settings-view__subtitle mb-8">Easily manage your plan and payments</p>

        <v-row class="mb-15">
            <v-col>
                <CurrentPlanCard/>
            </v-col>
            <v-col>
                <PaymentMethodCard/>
            </v-col>
        </v-row>

        <p class="settings-view__header mb-4">Your billing details</p>
        <BillingDetails/>
    </v-container>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "PlanBilling",
        components: {
            CurrentPlanCard: () => import('@/components/Settings/PlansAndBilling/CurrentPlan/CurrentPlanCard'),
            PaymentMethodCard: () => import('@/components/Settings/PlansAndBilling/PaymentMethodCard/PaymentMethodCard'),
            BillingDetails: () => import('@/components/Settings/PlansAndBilling/BillingDetails/BillingDetails')
        },
        computed: {
            ...mapGetters('user', [
                'getCurrentCognitoUser'
            ])
        },
        created() {
            this.$store.dispatch('user/plan/customer_details/fetchDetails');
        },
    }
</script>

<style scoped>

</style>