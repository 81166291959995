var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', _vm._b({
    attrs: {
      "content-class": _vm.position,
      "open-delay": _vm.delay,
      "open-on-hover": "",
      "transition": "none"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_vm._t("default", null, {
          "on": on
        })];
      }
    }], null, true)
  }, 'v-tooltip', _vm.$props, false), [_vm._v(" " + _vm._s(_vm.text) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }