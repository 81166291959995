<template>
    <BrowserTemplate>
        <div class="mt-5 my-3 gray_400--text subtitle-2 font-weight-regular">
            Solid Colors
        </div>
        <div class="color-palette-list">
            <div
                    v-for="color in palette"
                    class="color-tile"
                    :class="color === activeImage && 'color-tile--active'"
                    :key="color"
                    :style="'background: url('+color+')'"
                    @click="setActiveColor(color)"
            ></div>
            <v-menu
                    v-model="colorPickerModel"
                    transition="slide-y-transition"
                    content-class="white"
                    offset-y
                    nudge-bottom="10"
                    :close-on-content-click="false"
            >
                <template #activator="{on}">
                    <div
                            v-on="on"
                            class="color-tile color-tile--picker"
                            :class="activeImage === customColorBase64 && 'color-tile--active'"
                            :style="{backgroundColor: customColor}"
                    >
                        <PlusIcon class="plus-icon mix-blend-mode-difference white--text"/>
                    </div>
                </template>
                <template #default>
                    <v-color-picker
                            v-model="customColor"
                            hide-mode-switch
                            mode="hexa"
                    />
                    <div class="d-flex pa-4 pt-0">
                        <v-btn
                                color="blue_400 mr-2" rounded
                                @click="setCustomActiveColor"
                        >Add</v-btn>
                        <v-btn
                                color="gray_100" rounded
                                @click="unsetCustomColor"
                        >Cancel</v-btn>
                    </div>
                </template>
            </v-menu>
        </div>
        <div class="mt-5 my-3 gray_400--text subtitle-2 font-weight-regular">
            Backgrounds
        </div>
        <v-row class="backgrounds-list">
            <v-col
                    v-for="background in backgrounds"
                    :key="background"
            >
                <v-img
                        class="rounded-lg result-image"
                        :class="activeImage === background && 'active'"
                        width="100px" height="80px"
                        :src="background"
                         @click="setActiveImage(background)"
                >
                    <template v-slot:placeholder>
                        <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                        >
                            <v-skeleton-loader width="100" type="image"/>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>
    </BrowserTemplate>
</template>

<script>
    export default {
        name: "FormslyBrowser",
        components: {
            PlusIcon: () => import('vue-feather-icons/icons/PlusIcon'),
            BrowserTemplate: () => import('./BrowserTemplate'),
        },
        props: {
            activeImage: String
        },
        data: vm => ({
            palette: ['#2DA8E0', '#4172F5', '#934AE8', '#D85599', '#FC8BA8', '#EB9146', '#C52953', '#9EC82A', '#65C2AC', '#F0F0F0']
                .map(color => vm.Utils.getBase64ImageFromColor(color)),
            colorPickerModel: false,
            customColor: '#FFF',
            customColorBase64: vm.Utils.getBase64ImageFromColor('#FFF'),

            backgrounds: [
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp01-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp02-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp03-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp04-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp05-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp06-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp07-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp08-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp09-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp10-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp11-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp12-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp13-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp14-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp15-min.jpg',
                vm.constants.S3_BASE_URL + 'public/form_covers/BgTemp16-min.jpg',
            ]
        }),
        methods: {
            setCustomActiveColor() {
                this.customColorBase64 = this.Utils.getBase64ImageFromColor(this.customColor)
                this.$emit('set-active-image', this.customColorBase64)
                this.colorPickerModel = false
            },
            unsetCustomColor() {
                this.$emit('set-active-image', '')
                this.colorPickerModel = false
            },
            setActiveColor(color) {
                if (color === this.activeImage)
                    this.$emit('set-active-image', '')
                else
                    this.$emit('set-active-image', color)
            },
            setActiveImage(url) {
                if (this.activeImage === url)
                    this.$emit('set-active-image', '')
                else
                    this.$emit('set-active-image', url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-palette-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        .color-tile {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 1000px;
            border: 3px solid white;
            transition: box-shadow .2s;

            &--active {
                box-shadow: 0 0 0 3px var(--v-blue_400-base)
            }

            &:hover {
                box-shadow: 0 0 0 3px var(--v-blue_400-base)
            }

            &--picker {
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D1D1D1FF' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                background-color: white;
                position: relative;

                .plus-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    stroke-width: 1px;
                }

            }
        }
    }
    .backgrounds-list {
      margin: 0;
      row-gap: 24px;
      padding-bottom: 32px;
      .col {
        padding: 0;
      }
    }
</style>