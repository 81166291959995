import { render, staticRenderFns } from "./FormPreview.vue?vue&type=template&id=7183a2a8&"
import script from "./FormPreview.vue?vue&type=script&lang=js&"
export * from "./FormPreview.vue?vue&type=script&lang=js&"
import style0 from "./FormPreview.vue?vue&type=style&index=0&id=7183a2a8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_react@18.2.0_vue-templa_wkiss7gp4bcqnlea7oiy3v6evi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.6.14_vuetify@2.6.3_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCol,VDialog,VIcon,VOverlay,VRow,VSlideYTransition})
