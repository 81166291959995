var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveHomePageStylesProvider', {
    attrs: {
      "page-obj": _vm.pageObj
    }
  }, [_vm.pageObj.layout ? _c('InteractiveHomePageDisplayManager', {
    attrs: {
      "image-url": _vm.imageUrl,
      "highlight-image-area": _vm.highlightImageArea,
      "page-obj": _vm.pageObj
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('f-simple-contenteditable-input', {
          attrs: {
            "placeholder": "Add title",
            "overflow-wrap": "",
            "multiline": ""
          },
          model: {
            value: _vm.titleModel,
            callback: function ($$v) {
              _vm.titleModel = $$v;
            },
            expression: "titleModel"
          }
        })];
      },
      proxy: true
    }, {
      key: "subtitle",
      fn: function () {
        return [_c('f-simple-contenteditable-input', {
          attrs: {
            "placeholder": "Add subtitle",
            "overflow-wrap": "",
            "multiline": ""
          },
          model: {
            value: _vm.subtitleModel,
            callback: function ($$v) {
              _vm.subtitleModel = $$v;
            },
            expression: "subtitleModel"
          }
        })];
      },
      proxy: true
    }, {
      key: "btn",
      fn: function () {
        return [_c('InteractiveFormActionButtonBlock', {
          attrs: {
            "with-press-enter": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('f-simple-contenteditable-input', {
                model: {
                  value: _vm.buttonTextModel,
                  callback: function ($$v) {
                    _vm.buttonTextModel = $$v;
                  },
                  expression: "buttonTextModel"
                }
              })];
            },
            proxy: true
          }], null, false, 541139704)
        })];
      },
      proxy: true
    }, {
      key: "image-content",
      fn: function () {
        return [_vm.imageModel ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "Remove"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var tooltipOn = _ref.on;
              return [_c('v-btn', _vm._g({
                staticClass: "interactive-form-home-page__cover-image-btn interactive-form-home-page__cover-image-btn--remover v-btn--primary",
                attrs: {
                  "icon": "",
                  "large": ""
                },
                on: {
                  "click": _vm.clearImageArea,
                  "mouseover": function ($event) {
                    _vm.highlightImageArea = true;
                  },
                  "mouseout": function ($event) {
                    _vm.highlightImageArea = false;
                  }
                }
              }, tooltipOn), [_c('TrashIcon', {
                attrs: {
                  "size": "20"
                }
              })], 1)];
            }
          }], null, false, 3913786370)
        }) : _vm._e(), _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "Change Image"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var tooltipOn = _ref2.on;
              return [_c('v-btn', _vm._g({
                staticClass: "interactive-form-home-page__cover-image-btn interactive-form-home-page__cover-image-btn--changer v-btn--primary",
                attrs: {
                  "icon": "",
                  "large": ""
                },
                on: {
                  "mouseover": function ($event) {
                    _vm.highlightImageArea = true;
                  },
                  "mouseout": function ($event) {
                    _vm.highlightImageArea = false;
                  },
                  "click": function ($event) {
                    return _vm.$store.dispatch('edit/homepage_image_preview/openImagePicker');
                  }
                }
              }, tooltipOn), [_c('v-icon', {
                attrs: {
                  "size": "20px"
                }
              }, [_vm._v(" $image ")])], 1)];
            }
          }], null, false, 3244279422)
        })];
      },
      proxy: true
    }], null, false, 1517694420)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }