var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "labels": _vm.fieldProperties.labels,
      "scale-poles": _vm.sourceObj.properties.scale_poles,
      "submission-value": _vm.submissionObj.value
    },
    on: {
      "select": function ($event) {
        return _vm.handleSelect($event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }