<template>
    <f-template-data-transfer :isMounted="false" #default="{dataStash}">
        <GenericSelectionSummaryFieldView
                v-bind="$attrs"
                :optionsFormatter="optionsFormatter"
                @mounted="dataStash.isMounted = true"
        >
            <template #prepend="{option}">
                <component
                        v-if="optionsType !== 'SimpleArray'"
                        :is="$options.Dropdown.UTIL.READY_LISTS[optionsType].ICON_COMPONENT.NAME"
                        class="mr-2"
                        v-bind="$options.Dropdown.UTIL.READY_LISTS[optionsType].ICON_COMPONENT.BIND($options.Dropdown.UTIL.READY_LISTS[optionsType].LIST[option.index])"
                />
            </template>
        </GenericSelectionSummaryFieldView>
    </f-template-data-transfer>
</template>

<script>
    import { isString } from 'lodash'

    import Dropdown from '@/components/Fields/FieldsViews/Dropdown'

    export default {
        name: "Summary__Dropdown",
        Dropdown,
        inheritAttrs: false,
        components: {
            GenericSelectionSummaryFieldView: () => import('@/components/Fields/AnalyticsField/Summary/GenericSelectionSummaryFieldView'),
        },
        data: () => ({
            optionsType: null,
        }),
        methods: {
            optionsFormatter(fieldObj) {
                if (isString(fieldObj.content.options))
                    return Dropdown.UTIL.READY_LISTS[fieldObj.content.options].LIST.map(item => item.name)

                return fieldObj.content.options
            }
        },
        mounted() {
            this.optionsType = typeof this.$attrs.versionedSources[this.$attrs.latestVersion].content.options === 'string' ? this.$attrs.versionedSources[this.$attrs.latestVersion].content.options : 'SimpleArray';
        },
    }
</script>