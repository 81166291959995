var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.getMonitoringFeaturesDialogModel,
      "max-width": "850"
    },
    on: {
      "input": function (v) {
        return v ? null : _vm.closeDialog();
      }
    }
  }, [_c('v-card', [_c('div', {
    staticClass: "dialog-pro-offer"
  }, [_c('XIcon', {
    staticClass: "dialog-pro-offer-x-icon",
    on: {
      "click": _vm.closeDialog
    }
  }), _c('div', {
    staticClass: "dialog-pro-offer__advertising"
  }, [_c('div', {
    staticClass: "dialog-pro-offer__advertising-logo"
  }, [_c('LogoComponent')], 1), _c('div', {
    staticClass: "dialog-pro-offer__advertising-header"
  }, [_c('h2', {
    staticClass: "dialog-pro-offer__advertising-header-title"
  }, [_vm._v("Build Forms like a real Pro.")]), _c('h3', {
    staticClass: "dialog-pro-offer__advertising-header-subtitle"
  }, [_vm._v("High-performing teams win work. Build awesome form with rich endless possibilities")])])]), _c('div', {
    staticClass: "dialog-pro-offer__services"
  }, [_c('div', {
    staticClass: "dialog-pro-offer__services-header"
  }, [_c('h2', {
    staticClass: "dialog-pro-offer__services-header-title"
  }, [_vm._v(_vm._s(_vm.getMonitoringFeaturesDialogTitle))]), _c('h3', {
    staticClass: "dialog-pro-offer__services-header-subtitle"
  }, [_vm._v(_vm._s(_vm.getMonitoringFeaturesDialogSubtitle))])]), _c('div', {
    staticClass: "dialog-pro-offer__services-details"
  }, _vm._l(_vm.services, function (service, index) {
    return _c('div', {
      key: index,
      staticClass: "dialog-pro-offer__services-details-item"
    }, [_c('div', {
      staticClass: "dialog-pro-offer__services-details-item-icon"
    }, [_c(service.icon, {
      tag: "component"
    })], 1), _c('div', {
      staticClass: "dialog-pro-offer__services-details-item-info"
    }, [_c('span', {
      staticClass: "dialog-pro-offer__services-details-item-info-name"
    }, [_vm._v(" " + _vm._s(service.name) + " ")]), _c('span', {
      staticClass: "dialog-pro-offer__services-details-item-info-description"
    }, [_vm._v(" " + _vm._s(service.description) + " ")])])]);
  }), 0), _c('div', {
    staticClass: "dialog-pro-offer__services-action"
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "dark": ""
    },
    on: {
      "click": _vm.routeToCheckout
    }
  }, [_vm._v(" See Pro Pricing ")])], 1)])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }