import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants'

export const GenericInteractiveTransparentBlurryCardFSP = new FieldFSPDefinition({
    FSP_FIELD_TYPE: 'GenericInteractiveTransparentBlurryCard',
    FIELD_VIEW_CLASS: 'generic-interactive-transparent-blurry-card',
    VARIABLES: [
        {
            selector: ['card'],
            properties: {
                'backdrop-filter': {
                    'default--interactive': {
                        fn: 'blur(1px)'
                    }
                },
                'background-color': {
                    'default--interactive': {
                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                            asColors: {
                                true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.10, asList: true}),
                                false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.15, asList: true})
                            }
                        })
                    },
                    'hovered--interactive': {
                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                            asColors: {
                                true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.30, asList: true}),
                                false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.30, asList: true})
                            }
                        }),
                        connector: {
                            internal: '.generic-interactive-transparent-blurry-card__hover'
                        }
                    },
                    'active--interactive': {
                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                            asColors: {
                                true: [0, 0, 0, 0],
                                false: [0, 0, 0, 0.2]
                            }
                        }),
                        connector: {
                            internal: '.generic-interactive-transparent-blurry-card__active'
                        }
                    }
                },
                'border-radius': {
                    'default--interactive': {
                        fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                            px: true,
                            max: 22
                        })
                    }
                },
                'box-shadow': {
                    'default--interactive': {
                        fn: `0px 0px 0px 1px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.5})} inset`
                    }
                },
            }
        }
    ]
})