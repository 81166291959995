var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "mousedown-outside",
      rawName: "v-mousedown-outside",
      value: _vm.handleMouseDownOutside,
      expression: "handleMouseDownOutside"
    }],
    ref: "editableField",
    staticClass: "static-editable-field-constructor",
    class: _vm.isEditingActive && 'static-editable-field-constructor--editing-active',
    on: {
      "mousedown": _vm.turnOnEditingActive,
      "mouseenter": function ($event) {
        return _vm.fieldConstructorHoverIn(_vm.fieldId);
      },
      "mouseleave": function ($event) {
        return _vm.fieldConstructorHoverOut(_vm.fieldId);
      }
    }
  }, [_c('div', {
    staticClass: "static-editable-field-constructor__drag-hook",
    attrs: {
      "draggable": "false",
      "ondragstart": "return false"
    },
    on: {
      "mousedown": _vm.startDragging
    }
  }, [_c('DragHook', {
    class: _vm.isEditingActive || 'opacity-0'
  })], 1), _c('div', {
    staticClass: "static-editable-field-constructor__generic-field-wrapper"
  }, [_c('GenericField', {
    attrs: {
      "modifiers": ['static', 'editable', ("width-" + (_vm.fieldWidth)), _vm.isEditingActive && 'editing-active'],
      "field-id": _vm.fieldId,
      "field-obj": _vm.fieldObj,
      "field-type-const": _vm.fieldTypeConst
    }
  }, [_c('EditableFieldViewsGenerator', {
    attrs: {
      "field-obj": _vm.fieldObj,
      "is-editing-active": _vm.isEditingActive,
      "field-width": _vm.fieldWidth,
      "is-expanding-transition-going": _vm.isExpandingTransitionGoing
    }
  })], 1), 'required' in _vm.fieldObj.properties ? _c('div', {
    staticClass: "static-editable-field-constructor__required",
    class: _vm.isEditingActive || 'opacity-0'
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Required")]), _c('f-switch', {
    model: {
      value: _vm.requiredModel,
      callback: function ($$v) {
        _vm.requiredModel = $$v;
      },
      expression: "requiredModel"
    }
  })], 1) : _vm._e()], 1), _c('StaticFieldPopup', {
    attrs: {
      "show": _vm.isEditingActive && !_vm.isExpandingActive,
      "field-obj": _vm.fieldObj,
      "field-id": _vm.fieldId
    },
    on: {
      "duplicate-field": _vm.duplicateField,
      "remove-field": _vm.removeField,
      "change-field-type": _vm.changeFieldType
    }
  }), _c('div', {
    staticClass: "static-editable-field-constructor__expander",
    class: [_vm.isExpandingActive && 'static-editable-field-expander-active', _vm.isEditingActive || 'opacity-0'],
    on: {
      "mousedown": _vm.startExpanding
    }
  }), _c('GenericStoreDialog', {
    attrs: {
      "store-module-path": "edit/fields/confirmation_dialog"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }