var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "v-btn--text-hoverable",
    attrs: {
      "exact": "",
      "to": _vm.dashboardPage,
      "depressed": "",
      "text": ""
    }
  }, [_c('ChevronLeftIcon', {
    staticClass: "v-btn__prepend-icon"
  }), _c('span', [_vm._v("Dashboard")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }