<script>
  import { computed, ref } from '@vue/composition-api'
  import { PlusIcon } from 'vue-feather-icons'

  import {
    editable_field_view_mixin,
    useEditableFieldViewExtension,
  } from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

  export default {
    name: 'Editable__ImageChoice',
    components: {
      PlusIcon,
      AddOptionButton: () =>
        import(
          '@/components/Fields/EditableField/EditableFieldViewUtils/GenericAddOptionButton'
        ),
      SharedView: () => import('./SharedView'),
    },
    mixins: [editable_field_view_mixin],
    props: {
      isExpandingTransitionGoing: Boolean,
    },
    setup(props, context) {
      const { root } = context
      const extension = useEditableFieldViewExtension(props, context)

      /** WHEN IMAGE LABEL PROPERTY ENABLED/DISABLED **/
      extension.subOnPropertyUpdate('image_label', propertyValue =>
        propertyValue
          ? (props.fieldObj.content.options =
              props.fieldObj.content.options.map(option => ({
                ...option,
                label: '',
              })))
          : // eslint-disable-next-line no-unused-vars
            (props.fieldObj.content.options =
              props.fieldObj.content.options.map(
                ({ label, ...other }) => other
              ))
      )

      /** ADDING/REMOVING IMAGE OPTIONS */
      const addOption = () => {
        props.fieldObj.content.options.splice(
          props.fieldObj.content.options.length,
          0,
          props.fieldObj.properties.image_label
            ? { image: null, label: '' }
            : { image: null }
        )
      }
      const removeOption = index => {
        if (extension.isConditioned.value) {
          if (
            !props.fieldObj.condition.expression.statement.filter(
              s => s !== index
            ).length
          ) {
            extension.removeOption(() => {
              props.fieldObj.content.options.splice(index, 1)
              context.root.$delete(props.fieldObj, 'condition')
            })
          } else if (
            props.fieldObj.condition.expression.statement.includes(index)
          ) {
            extension.changeCondition(() => {
              props.fieldObj.condition.expression.statement =
                props.fieldObj.condition.expression.statement
                  .filter(s => s !== index)
                  .map(s => (s > index ? s - 1 : s))
              props.fieldObj.content.options.splice(index, 1)
            })
          } else {
            props.fieldObj.condition.expression.statement =
              props.fieldObj.condition.expression.statement
                .filter(s => s !== index)
                .map(s => (s > index ? s - 1 : s))
            props.fieldObj.content.options.splice(index, 1)
          }
          return
        }
        props.fieldObj.content.options.splice(index, 1)
      }
      const optionsActionsModule = { addOption, removeOption }

      /** ADDING NEW IMAGE **/
      const currentLoadingImageIndex = ref(undefined)
      const startAddingImage = optionIndex =>
        root.fileInputEventBus.$emit('trigger-input', {
          mimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
          maxFilesCount: 1,
          maxFileSize: 10,
          callback: file => {
            currentLoadingImageIndex.value = optionIndex

            root.Utils.blobToBase64Data(file)
              .then(dataURL => root.Utils.scaleImage(dataURL, 250, 250))
              .then(dataURL => root.Utils.trimImage(dataURL, 250, 250))
              .then(dataURL => root.Utils.base64DataURLToBlob(dataURL))
              .then(blob => root.StorageActions.uploadPublicAttachment(blob))
              .then(url => {
                props.fieldObj.content.options[optionIndex].image = url

                let image = new Image()
                image.onload = () =>
                  (currentLoadingImageIndex.value = undefined)
                image.src = url
              })
          },
        })
      const addingNewImageModule = {
        currentLoadingImageIndex,
        startAddingImage,
      }
      const isGridHasEmptySpace = computed(
        () => props.fieldObj.content.options.length % (props.fieldWidth * 2)
      )

      return {
        ...extension,
        ...optionsActionsModule,
        ...addingNewImageModule,
        isGridHasEmptySpace,
      }
    },
  }
</script>

<template>
  <SharedView
    editable
    :options="fieldObj.content.options"
    :is-editing-active="isEditingActive"
    :current-loading-image-index="currentLoadingImageIndex"
    @remove-option="removeOption($event)"
    @start-adding-image="startAddingImage($event)"
    @change-image="startAddingImage($event)"
  >
    <template #addOptionCard>
      <div
        v-if="isGridHasEmptySpace"
        :class="[
          'generic-field__escape-form-font',
          'image-choice-field-view__card-wrapper',
          'visible-when-field-editing',
          !(
            isGridHasEmptySpace &&
            isEditingActive &&
            !isExpandingTransitionGoing
          ) && 'visible-when-field-editing--hidden',
        ]"
      >
        <div
          class="image-choice-field-view__add-option-card"
          @click="addOption"
        >
          <PlusIcon
            size="26"
            stroke-width="1.5"
          />
        </div>
      </div>
    </template>
    <template #addButton>
      <AddOptionButton
        :is-editing-active="isEditingActive"
        :hidden="
          !(
            !isGridHasEmptySpace &&
            isEditingActive &&
            !isExpandingTransitionGoing
          )
        "
        @add-option="addOption"
      />
    </template>
  </SharedView>
</template>
