<template>
  <div class="rounded-pill white form-parameters" :class="{
    'form-parameters-style-preferences-btn-hovered':
      stylePreferencesButtonHovered,
    'form-parameters-settings-btn-hovered': settingsButtonHovered,
    'form-parameters-integrations-btn-hovered': integrationsButtonHovered,
  }">
    <f-hover-tooltip left text="Style" v-slot="{ on }">
      <v-btn v-on="on" class="form-parameters-style-preferences-btn v-btn--primary" color="gray_3" depressed text icon
        @click="setStylePreferencesMenuModel(true)" @mouseover="stylePreferencesButtonHovered = true"
        @mouseout="stylePreferencesButtonHovered = false">
        <v-icon class="gray_3--text">$feather</v-icon>
      </v-btn>
    </f-hover-tooltip>
    <br />
    <f-hover-tooltip left text="Settings" v-slot="{ on }">
      <v-btn v-on="on" class="mt-3 form-parameters-settings-btn v-btn--primary" color="gray_3" depressed text icon @click="
        $store.dispatch(
          'edit/FSP_themes/processStylePreferencesExit',
          () => (settingsMenuValue = true)
        )
        " @mouseover="settingsButtonHovered = true" @mouseout="settingsButtonHovered = false">
        <SettingsIcon stroke-width="1.6" />
      </v-btn>
    </f-hover-tooltip>
    <br />
    <f-hover-tooltip left text="Integrations" v-slot="{ on }">
      <v-btn v-on="on" class="mt-3 form-parameters-integrations-btn v-btn--primary" color="gray_3" depressed text icon
        @click="
          $store.dispatch(
            'edit/FSP_themes/processStylePreferencesExit',
            () => (integrationsMenuValue = true)
          )
          " @mouseover="integrationsButtonHovered = true" @mouseout="integrationsButtonHovered = false">
          <span class="smalldot"></span>
        <BoxIcon stroke-width="1.6" />
      </v-btn>
    </f-hover-tooltip>
    <FormParametersMenuTemplate :value="getStylePreferencesMenuModel" :min-width="320">
      <FormStylePreferences @close="
        $store.dispatch('edit/FSP_themes/processStylePreferencesExit', () =>
          setStylePreferencesMenuModel(false)
        )
        " />
    </FormParametersMenuTemplate>
    <FormParametersMenuTemplate v-model="settingsMenuValue" :min-width="420" v-slot="{ active }">
      <FormSettings :active="active" @close="settingsMenuValue = false" />
    </FormParametersMenuTemplate>
    <FormParametersMenuTemplate v-model="integrationsMenuValue" :min-width="420" v-slot="{ active }">
      <FormIntegrations :active="active" @close="integrationsMenuValue = false" :formId="$route.params.formId" />
    </FormParametersMenuTemplate>
  </div>
</template>

<script>
import { mapGetters, mapActions } from '@/xstore'

export default {
  name: 'FormParameters',
  components: {
    SettingsIcon: () => import('vue-feather-icons/icons/SettingsIcon'),
    BoxIcon: () => import('vue-feather-icons/icons/BoxIcon'),
    FormParametersMenuTemplate: () =>
      import('./Elements/FormParametersMenuTemplate'),
    FormStylePreferences: () => import('./FormStylePreferences'),
    FormSettings: () => import('./FormSettings'),
    FormIntegrations: () => import('./FormIntegrations'),
  },
  data: () => ({
    stylePreferencesButtonHovered: false,
    settingsButtonHovered: false,
    integrationsButtonHovered: false,

    stylePreferencesMenuValue: false,
    settingsMenuValue: false,
    integrationsMenuValue: false,
  }),
  computed: {
    ...mapGetters('edit/FSP_themes', ['getStylePreferencesMenuModel']),
    ...mapGetters('user', [
            'getCurrentCognitoUser'
        ]),
  },
  watch: {
    getStylePreferencesMenuModel(v) {
      if (v) {
        this.settingsMenuValue = false
        this.integrationsMenuValue = false
      }
    },
    settingsMenuValue(v) {
      if (v) {
        this.setStylePreferencesMenuModel(false)
        this.integrationsMenuValue = false
      }
    },
    integrationsMenuValue(v) {
      if (v) {
        this.setStylePreferencesMenuModel(false)
        this.settingsMenuValue = false
      }
    }
  },
  methods: {
    ...mapActions('edit/FSP_themes', ['setStylePreferencesMenuModel']),
  },
}
</script>

<style lang="scss" scoped>
.form-parameters {
  z-index: 10;
  position: fixed;
  right: 30px;
  top: 120px;
  padding: 16px 8px;

  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 2px 4px rgba(24, 39, 75, 0.08);
  transition: all 0.5s ease-out;

  &-style-preferences-btn-hovered {
    box-shadow: 0px -8px 18px -6px rgba(24, 39, 75, 0.08),
      0px -7px 28px rgba(24, 39, 75, 0.08) !important;

    .form-parameters-style-preferences-btn svg {
      color: var(--v-blue_400-base);
    }
  }

  &-settings-btn-hovered {
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 7px 12px rgba(24, 39, 75, 0.08) !important;

    .form-parameters-settings-btn svg {
      color: var(--v-blue_400-base);
    }
  }

  &-integrations-btn-hovered {
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 7px 12px rgba(24, 39, 75, 0.08) !important;

    .form-parameters-integrations-btn svg {
      color: var(--v-blue_400-base);
    }
  }

  span.smalldot {
    width: 6px;
    height: 6px;
    background: #fa5f5f;
    border-radius: 1000px;
    position: absolute;
    top: -4px;
    right: 2px;
}
}
</style>
