<script>
  import { base64DataURLToBlob, scaleImage } from '@/utils/misc'

  export default {
    name: 'UploadYoursBrowser',
    components: {
      TrashIcon: () => import('vue-feather-icons/icons/TrashIcon'),
      FileUploadArea: () =>
        import('@/components/Elements/FileInputs/FileUploadArea'),
      BrowserTemplate: () => import('./BrowserTemplate'),
    },
    props: {
      activeImage: String,
    },
    data: () => ({
      uploadedCoversList: [],
    }),
    beforeCreate() {
      this.StorageActions.listPublicObjects('form_covers/_').then(list => {
        if (list)
          this.uploadedCoversList = list
            .sort(({ LastModified: a }, { LastModified: b }) => b - a)
            .map(({ Key: relativePath }) => ({
              url: this.constants.S3_BASE_URL + relativePath,
            }))
      })
    },
    methods: {
      setActiveImage(image) {
        if (image.url) {
          if (image.url === this.activeImage) this.$emit('set-active-image', '')
          else this.$emit('set-active-image', image.url)
        }
      },
      inputCover(file) {
        let hash = this.Utils.generateUUID()
        this.uploadedCoversList.splice(0, 0, { hash })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          scaleImage(reader.result, 1000, 1000)
            .then(dataUrl => base64DataURLToBlob(dataUrl))
            .then(scaledFile =>
              this.StorageActions.putPublicObject({
                file: scaledFile,
                filePath: 'form_covers/_' + hash,
              })
            )
            .then(
              ({ fileUrl }) =>
                (this.uploadedCoversList.find(({ hash: h }) => h === hash).url =
                  fileUrl)
            )
        }
      },
      removeCover(coverIndex) {
        let [removedImage] = this.uploadedCoversList.splice(coverIndex, 1)
        const relativePath = this.constants.GET_S3_OBJECT_RELATIVE_PATH_BY_URL(
          removedImage.url
        )
        const splittedPath = relativePath.split('/')
        const folderPath =
          splittedPath.slice(0, splittedPath.length - 1).join('/') + '/'
        const currentImageName = splittedPath[splittedPath.length - 1]
        this.StorageActions.moveObject(
          relativePath,
          folderPath + currentImageName.slice(1)
        ).then(({ currentPath }) => {
          if (removedImage.url === this.activeImage)
            this.$emit(
              'set-active-image:active-url-replaced',
              this.constants.S3_BASE_URL + currentPath
            )
        })
      },
    },
  }
</script>

<template>
  <BrowserTemplate>
    <v-row>
      <v-col
        align-self="center"
        cols="12"
        sm="6"
      >
        <div class="upload-yours-cover-area-wrapper">
          <FileUploadArea
            vertical
            :max-file-size="10"
            :max-files-count="1"
            :accept-mime="['image/jpeg', 'image/png']"
            @input="inputCover"
          >
            <template #header>Drop Image or choose file...</template>
            <template #subheader>Max size 10mb</template>
          </FileUploadArea>
        </div>
      </v-col>
      <v-col
        v-for="(image, index) in uploadedCoversList"
        :key="image.url ? image.url.toLocaleString() : index"
        align-self="center"
        cols="12"
        sm="6"
      >
        <v-hover v-slot="{ hover }">
          <v-img
            class="rounded-lg result-image"
            width="288px"
            height="160px"
            :class="activeImage === image.url && 'active'"
            :src="image.url"
            @load="image.loaded = true"
            @click="setActiveImage(image)"
          >
            <template #default>
              <v-scale-transition origin="center">
                <v-btn
                  v-if="hover && image.loaded"
                  class="image-floating-remove-button v-btn--error"
                  icon
                  small
                  @click.stop="removeCover(index)"
                >
                  <TrashIcon size="18" />
                </v-btn>
              </v-scale-transition>
            </template>
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <f-circle-loader size="5" />
              </v-row>
            </template>
          </v-img>
        </v-hover>
      </v-col>
    </v-row>
  </BrowserTemplate>
</template>

<style lang="scss">
  .upload-yours-cover-area-wrapper {
    height: 160px;
    padding: 3px;
  }

  .image-floating-remove-button {
    position: absolute;
    background-color: white;
    bottom: 10px;
    right: 10px;
  }
</style>
