<template>
    <svg width="43" height="32" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="43" height="32" rx="8" :fill="backgroundFill"/>
        <rect x="9" y="10" width="26" height="4" rx="2" :fill="blocksFill"/>
        <rect x="9" y="17" width="15" height="4" rx="2" :fill="blocksFill"/>
    </svg>
</template>

<script>
    export default {
        name: "LayoutCompactImage",
        props: ['active'],
        computed: {
            backgroundFill() {
                return this.active ? this.$vuetify.theme.themes.light.blue_100 : this.$vuetify.theme.themes.light.gray_100
            },
            blocksFill() {
                return this.active ? this.$vuetify.theme.themes.light.blue_400 : this.$vuetify.theme.themes.light.gray_400
            }
        }
    }
</script>

<style scoped>

</style>