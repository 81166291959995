var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selection-field-view"
  }, [_c('div', {
    staticClass: "selection-field-view__layout-wrapper"
  }, [_c('div', {
    staticClass: "selection-field-view__layout"
  }, [_vm.isFormStatic ? _vm._l(_vm.optionsList, function (index) {
    return _c('div', {
      key: index,
      staticClass: "selection-field-view__option-wrapper"
    }, [_c('div', {
      class: ['selection-field-view__selection-field-option', _vm.model.includes(index) && 'selection-field-view__selection-field-option--active']
    }, [_c('f-checkbox', {
      attrs: {
        "value": _vm.model.includes(index),
        "radio": _vm.radio,
        "disabled": _vm.editable || _vm.isPreview
      },
      on: {
        "input": function ($event) {
          return _vm.optionInput(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('f-template-data-transfer', {
            attrs: {
              "focused": false
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref) {
                var dataStash = _ref.dataStash;
                return [_c('v-hover', {
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function (_ref2) {
                      var hover = _ref2.hover;
                      return [_c('div', {
                        staticClass: "selection-field-view__selection-field-option__label-wrapper"
                      }, [_c('div', {
                        staticClass: "selection-field-view__selection-field-option__label-input-wrapper"
                      }, [_c('f-simple-contenteditable-input', {
                        ref: "optionNameInput",
                        refInFor: true,
                        staticClass: "selection-field-view__selection-field-option__label",
                        attrs: {
                          "placeholder": "Choice",
                          "prevent-highlighting": "",
                          "overflow-wrap": "",
                          "mask": /^.{0,240}$/g,
                          "readonly": !_vm.editable
                        },
                        on: {
                          "update:focused": function ($event) {
                            dataStash.focused = $event;
                          },
                          "keydown": function ($event) {
                            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                            return _vm.$emit('insert-option', index + 1);
                          }
                        },
                        model: {
                          value: _vm.fieldObj.content.options[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.fieldObj.content.options, index, $$v);
                          },
                          expression: "fieldObj.content.options[index]"
                        }
                      })], 1), _c('div', {
                        staticClass: "selection-field-view__selection-field-option__option-append-wrapper"
                      }, [_vm._t("option-append", null, {
                        "index": index,
                        "hover": hover,
                        "focused": dataStash.focused,
                        "active": _vm.model.includes(index)
                      })], 2)])];
                    }
                  }], null, true)
                })];
              }
            }], null, true)
          })];
        },
        proxy: true
      }], null, true)
    })], 1)]);
  }) : _vm.isFormInteractive ? _vm._l(_vm.optionsList, function (index) {
    return _c('div', {
      key: index,
      staticClass: "selection-field-view__selection-field-view__option-wrapper generic-interactive-transparent-blurry-card"
    }, [_c('f-template-data-transfer', {
      attrs: {
        "focused": false
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          var dataStash = _ref3.dataStash;
          return [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref4) {
                var hover = _ref4.hover;
                return [_c('div', {
                  class: ['selection-field-view__selection-field-option', _vm.model.includes(index) && 'selection-field-view__selection-field-option--active', _vm.model.includes(index) && 'generic-interactive-transparent-blurry-card__active', hover && 'generic-interactive-transparent-blurry-card__hover'],
                  on: {
                    "click": function ($event) {
                      return _vm.optionInput(index);
                    }
                  }
                }, [_c('div', {
                  staticClass: "selection-field-view__selection-field-option__wrapper generic-interactive-transparent-blurry-card__card"
                }, [_c('div', {
                  staticClass: "selection-field-view__selection-field-option__anchor-letter-wrapper"
                }, [_c('div', {
                  staticClass: "selection-field-view__selection-field-option__anchor-letter"
                }, [_vm._v(" " + _vm._s(_vm.Utils.convertIndexToLetter(index)) + " ")])]), _c('div', {
                  staticClass: "selection-field-view__selection-field-option__label-wrapper"
                }, [_c('f-simple-contenteditable-input', {
                  ref: "optionNameInput",
                  refInFor: true,
                  staticClass: "selection-field-view__selection-field-option__label",
                  attrs: {
                    "placeholder": "Choice",
                    "prevent-highlighting": "",
                    "overflow-wrap": "",
                    "mask": /^.{0,240}$/g,
                    "readonly": !_vm.editable
                  },
                  on: {
                    "update:focused": function ($event) {
                      dataStash.focused = $event;
                    },
                    "keydown": function ($event) {
                      if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                      return _vm.$emit('insert-option', index + 1);
                    }
                  },
                  model: {
                    value: _vm.fieldObj.content.options[index],
                    callback: function ($$v) {
                      _vm.$set(_vm.fieldObj.content.options, index, $$v);
                    },
                    expression: "fieldObj.content.options[index]"
                  }
                })], 1), _c('div', {
                  staticClass: "selection-field-view__selection-field-option__option-append-wrapper"
                }, [_vm._t("option-append", null, {
                  "index": index,
                  "hover": hover,
                  "focused": dataStash.focused,
                  "active": _vm.model.includes(index)
                })], 2)])])];
              }
            }], null, true)
          })];
        }
      }], null, true)
    })], 1);
  }) : _vm._e()], 2)]), _vm._t("extension")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }