export class FieldFSPDefinition {
    constructor({
        FSP_FIELD_TYPE,
        FIELD_VIEW_CLASS,
        GENERIC_STATES_CONNECTORS,
        VARIABLES
    }) {
        this.FSP_FIELD_TYPE = FSP_FIELD_TYPE
        this.FIELD_VIEW_CLASS = FIELD_VIEW_CLASS
        this.GENERIC_STATES_CONNECTORS = GENERIC_STATES_CONNECTORS
        this.VARIABLES = VARIABLES
    }

    initializeAsPartOfFieldType(fieldTypeConst) {
        if (!this.FSP_FIELD_TYPE)
            this.FSP_FIELD_TYPE = fieldTypeConst.FIELD_TYPE
    }
}

