var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "file-upload-field-view-summary"
  }, _vm._l(_vm.filteredSubmissions, function (item, index) {
    return _c('f-template-data-transfer', {
      key: index,
      attrs: {
        "isIntersected": false
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var dataStash = _ref.dataStash;
          return [_c('FullSingleAnswer', {
            directives: [{
              name: "intersect",
              rawName: "v-intersect.once",
              value: function () {
                return dataStash.isIntersected = true;
              },
              expression: "() => dataStash.isIntersected = true",
              modifiers: {
                "once": true
              }
            }],
            staticClass: "file-upload-field-view-summary__item",
            attrs: {
              "submissionObj": item.submissionObj,
              "sourceObj": _vm.versionedSources[item.versionID],
              "allowUploading": dataStash.isIntersected
            }
          })];
        }
      }], null, true)
    });
  }), 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }