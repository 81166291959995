var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-choice-field-view-full-single-answer"
  }, [!_vm.submissionObj.selected_options.length ? _c('p', [_vm._v("-")]) : _vm._e(), _vm._l([].concat(_vm.submissionObj.selected_options).sort(), function (optionIndex) {
    return _c('div', {
      key: optionIndex,
      staticClass: "image-choice-field-view-full-single-answer__card"
    }, [_c('img', {
      attrs: {
        "src": _vm.sourceObj.content.options[optionIndex].image,
        "s": ""
      }
    }), _c('p', [_vm._v(_vm._s(_vm.sourceObj.content.options[optionIndex].label))])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }