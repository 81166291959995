var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "content-class": "page-actions-menu",
      "value": _vm.menuModel,
      "close-on-content-click": false,
      "rounded": "lg",
      "nudge-top": "17",
      "nudge-right": "17",
      "offset-x": "",
      "transition": "slide-y-transition"
    },
    on: {
      "input": _vm.toggleMenu
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.hover || _vm.menuModel,
            expression: "hover || menuModel"
          }],
          staticClass: "page-actions-menu__activator-btn",
          attrs: {
            "icon": ""
          }
        }, on), [_c('svg', {
          attrs: {
            "width": "2",
            "height": "12",
            "viewBox": "0 0 2 12",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('circle', {
          attrs: {
            "cx": "1",
            "cy": "1",
            "r": "1",
            "fill": "currentColor"
          }
        }), _c('circle', {
          attrs: {
            "cx": "1",
            "cy": "6",
            "r": "1",
            "fill": "currentColor"
          }
        }), _c('circle', {
          attrs: {
            "cx": "1",
            "cy": "11",
            "r": "1",
            "fill": "currentColor"
          }
        })])])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": _vm.renamePage
    }
  }, [_c('v-list-item-icon', [_c('Edit3Icon', {
    attrs: {
      "stroke-width": "1.5",
      "size": "22"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Rename ")])], 1)], 1), _c('v-divider', {
    staticClass: "gray_100"
  }), _c('v-list-item', {
    staticClass: "red_400--text",
    on: {
      "click": _vm.removePage
    }
  }, [_c('v-list-item-icon', [_c('Trash2Icon', {
    attrs: {
      "stroke-width": "1.5",
      "size": "22"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Remove ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }