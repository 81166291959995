<template>
    <div class="editable-field-property-smart-paragraph-variables">
        <div
                v-for="(variableName, variableId) in fieldPropertiesObj.variables_list"
                class="editable-field-property-smart-paragraph-variables__item"
                :key="variableId"
        ><p>{{variableName}}</p></div>
    </div>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "SmartParagraphVariables",
        mixins: [field_property_input_mixin],
    }
</script>

<style lang="scss" scoped>
    .editable-field-property-smart-paragraph-variables {
        border-radius: 4px;
        border: 1px solid var(--v-gray_1-base);
        background-color: white;
        padding: 20px;

        display: flex;
        flex-wrap: wrap;
        column-gap: 14px;
        row-gap: 11px;

        &__item {
            padding: 3px 18px;
            background-color: var(--v-bluish_gray_100-base);
            border-radius: 100px;
            max-width: 100%;

                p {
                    min-height: 22px;
                    font-size: .875rem;
                    line-height: 22px;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
        }
    }
</style>