import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { Analytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithComplexConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyNumberInput
} from '@/components/Fields/FieldConstClasses/field_property_input'
import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'

import FSP from '@/constants/fsp_constants'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import constants from "@/constants/constants";


class FileUploadFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({

            FIELD_TYPE: 'FILE_UPLOAD',
            READABLE_NAME: 'File uploader',
            ICON_NAME: 'file_upload',
            FOLDER_NAME: 'FileUpload',

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: (submissionObj) => {
                    if (submissionObj.value?.fileName) {
                        return `${constants.S3_BASE_URL}${constants.S3_UPLOADS_FOLDER_PATH}${submissionObj.value.fileName}`
                    } else {
                        return null;
                    }

                }
            }),

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            IS_SUBMISSION_EMPTY: ({value}) => !value,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),
            ASSOCIATE_WITH_PAID_FEATURE: 'file_uploader_field',

            FSP: [
                (() => {
                    const LAYOUT_AREA_BORDER_RADIUS = FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({px: true, max: 16})

                    const LAYOUT_AREA_BEFORE_Z_INDEX = 7
                    const LAYOUT_AREA_AFTER_Z_INDEX = LAYOUT_AREA_BEFORE_Z_INDEX + 1
                    const LAYOUT_AREA_FIRST_CHILD_Z_INDEX = LAYOUT_AREA_AFTER_Z_INDEX + 1


                    return new FieldFSPDefinition({
                        FSP_FIELD_TYPE: 'FileUploader',
                        FIELD_VIEW_CLASS: 'file-upload-field-view',
                        VARIABLES: [
                            {
                                selector: ['layout-area'],
                                properties: {
                                    height: {
                                        default: {
                                            fn: '186px'
                                        }
                                    },
                                    'border-radius': {
                                        default: {
                                            fn: LAYOUT_AREA_BORDER_RADIUS
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['layout-area::before'],
                                properties: {
                                    'z-index': {
                                        default: {
                                            fn: LAYOUT_AREA_BEFORE_Z_INDEX
                                        }
                                    },
                                    'border-radius': {
                                        default: {
                                            fn: LAYOUT_AREA_BORDER_RADIUS
                                        }
                                    },
                                    'backdrop-filter': {
                                        'default--interactive': {
                                            fn: 'blur(1px)'
                                        }
                                    },
                                    'background-color': {
                                        'default--interactive': {
                                            fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                                                asColors: {
                                                    true: [0, 0, 0, 0.05],
                                                    false: [0, 0, 0, 0.1]
                                                }
                                            })
                                        },
                                        'default--static': {
                                            fn: 'rgba(0, 0, 0, 0.1)'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['layout-area::after'],
                                properties: {
                                    'z-index': {
                                        default: {
                                            fn: LAYOUT_AREA_AFTER_Z_INDEX
                                        }
                                    },
                                    'border-color': {
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'border-radius': {
                                        default: {
                                            fn: LAYOUT_AREA_BORDER_RADIUS
                                        }
                                    },
                                    'border-style': {
                                        default: {
                                            fn: 'dashed'
                                        }
                                    },
                                    'border-width': {
                                        default: {
                                            fn: '2px'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['layout-area > *'],
                                properties: {
                                    'z-index': {
                                        default: {
                                            fn: LAYOUT_AREA_FIRST_CHILD_Z_INDEX
                                        }
                                    },
                                }
                            },
                            {
                                selector: ['file-input .file-upload-area__content .file-upload-area__icon-container svg'],
                                properties: {
                                    color: {
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'stroke-width': {
                                        default: {
                                            fn: 1.6
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['file-input .file-upload-area__content .file-upload-area__text-container .file-upload-area__header'],
                                properties: {
                                    'font-size': {
                                        'default--interactive': {
                                            fn: '16px'
                                        }
                                    },
                                    'line-height': {
                                        'default--interactive': {
                                            fn: '21px'
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 400
                                        }
                                    },
                                    color: {
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'margin-bottom': {
                                        'default--interactive': {
                                            fn: '2px'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['file-input .file-upload-area__content .file-upload-area__text-container .file-upload-area__subheader'],
                                properties: {
                                    'font-size': {
                                        'default--interactive': {
                                            fn: '12px'
                                        }
                                    },
                                    'line-height': {
                                        'default--interactive': {
                                            fn: '14px'
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 700
                                        }
                                    },
                                    color: {
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.7})
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file'],
                                properties: {
                                    width: {
                                        default: {
                                            fn: '388px'
                                        }
                                    },
                                    height: {
                                        default: {
                                            fn: '137px'
                                        }
                                    },
                                    padding: {
                                        default: {
                                            fn: '16px'
                                        }
                                    },
                                    'background-color': {
                                        'default--interactive': {
                                            fn: 'white'
                                        },
                                        'default--static': {
                                            fn: 'white'
                                        }
                                    },
                                    'border-radius': {
                                        default: {
                                            fn: `calc(${LAYOUT_AREA_BORDER_RADIUS} * (3/4))`
                                        }
                                    },
                                    filter: {
                                        default: {
                                            fn: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__preview-wrapper'],
                                properties: {
                                    'margin-right': {
                                        default: {
                                            fn: '20px'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__preview'],
                                properties: {
                                    width: {
                                        default: {
                                            fn: '114px'
                                        }
                                    },
                                    'background-color': {
                                        default: {
                                            fn: '#ECECEC'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__preview .v-responsive__content svg'], properties: {
                                    width: {
                                        default: {
                                            fn: '34px'
                                        }
                                    },
                                    height: {
                                        default: {
                                            fn: '34px'
                                        }
                                    },
                                    color: {
                                        default: {
                                            fn: 'var(--v-gray_400-base)'
                                        }
                                    },
                                    'stroke-width': {
                                        default: {
                                            fn: 1
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__fullname'], properties: {
                                    color: {
                                        'default--interactive': {
                                            fn: 'black'
                                            // fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'font-size': {
                                        default: {
                                            fn: '14px'
                                        }
                                    },
                                    'line-height': {
                                        default: {
                                            fn: '18px'
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 700
                                        }
                                    },
                                    'margin-bottom': {
                                        default: {
                                            fn: '2px'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__size'],
                                properties: {
                                    color: {
                                        'default--interactive': {
                                            fn: 'black'
                                            // fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'font-size': {
                                        default: {
                                            fn: '12px'
                                        }
                                    },
                                    'line-height': {
                                        default: {
                                            fn: '16px'
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 700
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['selected-file__remove-btn'], properties: {
                                    color: {
                                        'default--interactive': {
                                            fn: 'rgba(0, 0, 0, 0.4)'
                                            // fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                        }
                                    },
                                    'stroke-width': {
                                        default: {
                                            fn: 1
                                        }
                                    },
                                    top: {
                                        default: {
                                            fn: '12px'
                                        }
                                    },
                                    right: {
                                        default: {
                                            fn: '17px'
                                        }
                                    }
                                }
                            }
                        ]
                    })
                })()
            ],

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'limit_file_size',
                    name: 'Limit File Size',
                    enabled_by_default: true,
                    link_in_caption: true,
                    linked_caption_part: '(Upgrade to Pro)',
                    caption: 'Need more that 10mb?',
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        min: new FieldPropertyNumberInput({
                            label: 'Minimum',
                            half_width: true,
                            default: 0,
                            min_value: 0,
                            append_inner: 'MB',
                            validator: (selfValue, propValue) => selfValue <= propValue.max
                        }),
                        max: new FieldPropertyNumberInput({
                            label: 'Maximum',
                            half_width: true,
                            default: 10,
                            append_inner: 'MB',
                            validator: (selfValue, propValue) => selfValue >= propValue.min && selfValue <= 10
                        })
                    })
                }),
                // new CustomFieldPropertyConst({
                //     key: 'advanced_file_types',
                //     name: 'Advanced File Types',
                //     enabled_by_default: true,
                //     always_enabled: true,
                //     caption: 'Write the name extensions of the allowed file types. Write and hit Enter.',
                //     input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                //         new FieldPropertyMultipleStringsInput({
                //             default: [],
                //             placeholder: '+ Add type...'
                //         })
                //     )
                // })
            ])
        })
    }
}

export default new FileUploadFieldTypeConst()