<template>
    <div class="summary-row-with-progress">
        <slot name="prepend"/>
        <div class="summary-row-with-progress__attrs">
            <span
                    v-if="label"
                    class="summary-row-with-progress__label"
            >{{ label }}</span>
            <v-spacer/>
            <span class="summary-row-with-progress__count">{{ count }} resp..</span>
            <span class="summary-row-with-progress__percentage">{{ percentage }}%</span>
            <div class="summary-row-with-progress__progress-row">
                <slot name="prepend-inner"/>
                <v-progress-linear
                        height="8"
                        color="blue_400"
                        background-color="blue_100"
                        :value="percentage"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SummaryRowWithProgress",
        props: {
            label: String,
            count: {
                type: [Number, String],
                required: true
            },
            percentage: {
                type: [Number, String],
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .summary-row-with-progress {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        display: flex;

        &__image {
            width: 74px;
            border-radius: 6px;
            flex-grow: 0 !important;
            margin-right: 20px;
        }

        &__attrs {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            width: 410px;
            align-content: center;
            align-items: center;
        }

        &__label {
            line-height: 1.6;
            color: var(--v-bluish_gray_500-base);
            font-weight: 500;
        }

        &__count {
            font-size: .875rem;
            color: var(--v-gray_500-base);
            margin-right: 10px;
        }

        &__percentage {
            font-weight: 600;
        }

        &__progress-row {
            width: 100%;
            flex-grow: 1;
            display: flex;
            align-items: center;

            .v-progress-linear {
                margin: 9px 0;
                & > * {
                    border-radius: 40px;
                }
            }
        }
    }
</style>