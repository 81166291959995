<script>
  import constants from '@/constants/constants'
  import { mapGetters } from '@/xstore'

  const APP_BARS = {
    [constants.APP_BAR_NAVIGATION]: () =>
      import('./AppBarTypes/AppBarNavigation'),
    [constants.APP_BAR_EMPTY]: () => import('./AppBarTypes/AppBarEmpty'),
    [constants.APP_BAR_FORM_EDITOR]: () =>
      import('./AppBarTypes/AppBarFormEditor'),
    [constants.APP_BAR_BACK_TO_DASHBOARD]: () =>
      import('./AppBarTypes/AppBarBackToDashboard'),
    [constants.APP_BAR_BACK_TO_FORM_TYPE_SELECTION]: () =>
      import('./AppBarTypes/AppBarBackToFormTypeSelection'),
    [constants.APP_BAR_SUBMISSIONS_ANALYTICS]: () =>
      import('./AppBarTypes/AppBarSubmissionsAnalytics'),
    [constants.APP_BAR_CHECKOUT]: () => import('./AppBarTypes/AppBarCheckout'),
    [constants.APP_BAR_TEMPLATE_SELECTION]: () =>
      import('./AppBarTypes/AppBarTemplateSelection'),
  }

  export default {
    name: 'AppBar',
    components: {
      AppBarNavigation: () => import('./AppBarTypes/AppBarNavigation'),
      AppBarEmpty: () => import('./AppBarTypes/AppBarEmpty'),
      AppBarFormEditor: () => import('./AppBarTypes/AppBarFormEditor'),
      AppBarBackToDashboard: () =>
        import('./AppBarTypes/AppBarBackToDashboard'),
      AppBarSubmissionsAnalytics: () =>
        import('./AppBarTypes/AppBarSubmissionsAnalytics'),
    },
    computed: {
      ...mapGetters('edit/loading_screen', ['getIsFormLoadingScreenShown']),

      currentAppBarKey() {
        if (
          this.$route.name === null ||
          (this.getIsFormLoadingScreenShown &&
            this.$route.name === this.constants.PAGE_NAME_FORM_EDITOR)
        )
          return this.constants.APP_BAR_EMPTY
        else if (!this.$route.meta.appBar)
          return this.constants.APP_BAR_NAVIGATION
        else return this.$route.meta.appBar
      },
      currentAppBarComponent() {
        return APP_BARS[this.currentAppBarKey]
      },
    },
  }
</script>

<template>
  <component :is="currentAppBarComponent" />
</template>

<style lang="scss">
  .app-bar-form-editor {
    &__divider {
      height: 16px !important;
      min-height: 16px !important;
      margin: auto 12px !important;
    }
  }
</style>

