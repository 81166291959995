<template>
    <f-fluid-container class="checkout-purchase-completed-page">
        <div class="checkout-purchase-completed-page__confetti">
          <LottieDefaultContainer :json="json"/>
        </div>
        <div class="checkout-purchase-completed-page__layout">
            <div class="checkout-purchase-completed-page__logo">
              <img src="../../../public/images/SuccessLogo.svg"/>
            </div>

          <div class="checkout-purchase-completed-page__title">
                <span>Thank you for your purchase</span>
            </div>
            <div class="checkout-purchase-completed-page__subtitle" v-if="getPlansObject && getSelectedProduct && getSelectedBillingPeriod">
                <span>Your account been upgraded to {{ getPlansObject[getSelectedProduct]?.name }} {{ upperFirst(getSelectedBillingPeriod) + 'ly' }}, You’ll be receiving an email shortly with purchase details</span>
            </div>
            <div class="checkout-purchase-completed-page__continue-btn-wrapper">
                <v-btn
                        color="gray_100"
                        rounded
                        :to="{name: constants.PAGE_NAME_DASHBOARD}"
                >
                    Go to Homepage
                    <ArrowRightIcon size="20" class="ml-2"/>
                </v-btn>
            </div>
        </div>
    </f-fluid-container>
</template>

<script>
    import store from '@/store'
    import { mapGetters } from '@/xstore'
    import { upperFirst } from 'lodash'
    import constants from "@/constants/constants";

    export default {
        name: "CheckoutPurchaseCompleted",
        components: {
            ArrowRightIcon: () => import('vue-feather-icons/icons/ArrowRightIcon'),
            LottieDefaultContainer: () => import('@/components/Elements/LottieDefaultContainer'),
        },
        computed: {
            ...mapGetters('user/plan', [
                'getPlansObject'
            ]),
            ...mapGetters('user/plan/checkout/selected_plan', [
                'getSelectedProduct',
                'getSelectedBillingPeriod'
            ]),
            json() {
              return this.constants.CONFETTI_CONSTANTS
            },
        },
        methods: {
            upperFirst
        },
        beforeRouteEnter(to, from, next) {
            if (store.getters['user/plan/checkout/navigation/getIsPurchaseCompletePageUnlocked'])
                return next()
            next({name: constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN})
        }
    }
</script>

<style lang="scss">
    .checkout-purchase-completed-page {
        display: flex;
        align-items: center;
        justify-content: center;

        &__confetti {
          position: absolute;
          bottom: 20px;
        }

        &__layout {
            text-align: center;
            max-width: 535px;
            position: relative;
        }

        &__logo {

        }

        &__title {
            margin-top: 10px;
            font-size: 30px;
            line-height: 48px;
            letter-spacing: -0.04em;
            color: black;
            font-weight: 600;

            margin-bottom: 12px;
        }

        &__subtitle {
            font-size: 16px;
            line-height: 24px;
            color: var(--v-gray_500-base);

            margin-bottom: 40px;
        }
    }

    .lottie-icon-container {
      display: flex;
    }

</style>