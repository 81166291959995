var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex mt-3"
  }, [_c('v-icon', {
    attrs: {
      "size": "36"
    }
  }, [_vm._v(" " + _vm._s(_vm.app.icon) + " ")]), _c('div', {
    staticClass: "d-flex flex-column ml-4"
  }, [_c('span', {
    staticClass: "integrations-app-title"
  }, [_vm._v(" " + _vm._s(_vm.app.title) + " ")]), _c('span', {
    staticClass: "integrations-app-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.app.connectDescription) + " ")])])], 1)]), _vm.app.connected ? _c('v-btn', {
    staticClass: "mt-6 rounded-pill font-weight-medium connect-btn",
    class: [_vm.app.connected && 'disconnect-btn'],
    attrs: {
      "height": "32",
      "color": "gray_700",
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.disconnect
    }
  }, [_vm._v(" Disconnect "), _vm.loader ? _c('f-circle-loader', {
    staticStyle: {
      "margin-left": "5px",
      "filter": "hue-rotate(45deg)"
    },
    attrs: {
      "size": "1.5"
    }
  }) : _vm._e()], 1) : _c('v-btn', {
    staticClass: "mt-6 rounded-pill font-weight-medium connect-btn",
    class: [_vm.app.connected && 'disconnect-btn'],
    attrs: {
      "height": "32",
      "color": "gray_700",
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.connection
    }
  }, [_vm._v(" Connect ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }