var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "27",
      "height": "38",
      "viewBox": "0 0 27 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "id": "Page-1"
    }
  }, [_c('g', {
    attrs: {
      "id": "Consumer-Apps-Sheets-Large-VD-R8-"
    }
  }, [_c('g', {
    attrs: {
      "id": "Hero"
    }
  }, [_c('g', {
    attrs: {
      "id": "Personal"
    }
  }, [_c('g', {
    attrs: {
      "id": "Sheets-icon"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group"
    }
  }, [_c('g', {
    attrs: {
      "id": "Clipped"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask0_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-2"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask0_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L21.0938 5.92388L16.875 0Z",
      "fill": "#0F9D58"
    }
  })])]), _c('g', {
    attrs: {
      "id": "Clipped_2"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask1_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-4"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_2",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask1_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Shape",
      "d": "M6.75 18.1948V30.4657H20.25V18.1948H6.75ZM12.6563 28.7732H8.4375V26.6575H12.6563V28.7732ZM12.6563 25.3881H8.4375V23.2724H12.6563V25.3881ZM12.6563 22.003H8.4375V19.8874H12.6563V22.003ZM18.5625 28.7732H14.3438V26.6575H18.5625V28.7732ZM18.5625 25.3881H14.3438V23.2724H18.5625V25.3881ZM18.5625 22.003H14.3438V19.8874H18.5625V22.003Z",
      "fill": "#F1F1F1"
    }
  })])]), _c('g', {
    attrs: {
      "id": "Clipped_3"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask2_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-6"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_3",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask2_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_2",
      "d": "M17.6154 9.4126L27 18.8231V10.1552L17.6154 9.4126Z",
      "fill": "url(#paint0_linear_9080_8923)"
    }
  })])]), _c('g', {
    attrs: {
      "id": "Clipped_4"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask3_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-9"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_4",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask3_9080_8923)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_2"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_3"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_3",
      "d": "M16.8749 0V7.61642C16.8749 9.01911 18.0077 10.1552 19.4062 10.1552H26.9999L16.8749 0Z",
      "fill": "#87CEAC"
    }
  })])])])]), _c('g', {
    attrs: {
      "id": "Clipped_5"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask4_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-11"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_5",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask4_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_4",
      "d": "M2.53125 0C1.13906 0 0 1.14246 0 2.53881V2.75037C0 1.35403 1.13906 0.211567 2.53125 0.211567H16.875V0H2.53125Z",
      "fill": "white",
      "fill-opacity": "0.2"
    }
  })])]), _c('g', {
    attrs: {
      "id": "Clipped_6"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask5_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-13"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_6",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask5_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_5",
      "d": "M24.4688 37.0242H2.53125C1.13906 37.0242 0 35.8817 0 34.4854V34.6969C0 36.0933 1.13906 37.2357 2.53125 37.2357H24.4688C25.8609 37.2357 27 36.0933 27 34.6969V34.4854C27 35.8817 25.8609 37.0242 24.4688 37.0242Z",
      "fill": "#263238",
      "fill-opacity": "0.2"
    }
  })])]), _c('g', {
    attrs: {
      "id": "Clipped_7"
    }
  }, [_c('mask', {
    staticStyle: {
      "mask-type": "alpha"
    },
    attrs: {
      "id": "mask6_9080_8923",
      "maskUnits": "userSpaceOnUse",
      "x": "0",
      "y": "0",
      "width": "27",
      "height": "38"
    }
  }, [_c('g', {
    attrs: {
      "id": "mask-15"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector_7",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "white"
    }
  })])]), _c('g', {
    attrs: {
      "mask": "url(#mask6_9080_8923)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_6",
      "d": "M19.4062 10.1553C18.0077 10.1553 16.8749 9.01915 16.8749 7.61646V7.82802C16.8749 9.23071 18.0077 10.3668 19.4062 10.3668H26.9999V10.1553H19.4062Z",
      "fill": "#263238",
      "fill-opacity": "0.1"
    }
  })])])]), _c('path', {
    attrs: {
      "id": "Path_7",
      "d": "M16.875 0H2.53125C1.13906 0 0 1.14246 0 2.53881V34.697C0 36.0934 1.13906 37.2358 2.53125 37.2358H24.4688C25.8609 37.2358 27 36.0934 27 34.697V10.1552L16.875 0Z",
      "fill": "url(#paint1_radial_9080_8923)"
    }
  })])])])])]), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_9080_8923",
      "x1": "22.3082",
      "y1": "10.2206",
      "x2": "22.3082",
      "y2": "18.8244",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#263238",
      "stop-opacity": "0.2"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#263238",
      "stop-opacity": "0.02"
    }
  })], 1), _c('radialGradient', {
    attrs: {
      "id": "paint1_radial_9080_8923",
      "cx": "0",
      "cy": "0",
      "r": "1",
      "gradientUnits": "userSpaceOnUse",
      "gradientTransform": "translate(0.855373 0.740139) scale(43.5371 43.6671)"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.1"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }