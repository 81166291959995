<template>
    <InteractiveFinishPageTemplate @redirect="redirect">
        <template #emoji>
            👍
        </template>
        <template #title>
            <span v-html="finishTitle"></span>
        </template>
        <template #description v-if="finishDescription">
            <span v-html="finishDescription"></span>
        </template>
    </InteractiveFinishPageTemplate>
</template>

<script>

    import {finish_page_submit_mixin} from "../../../../FormGenericComponents/Mixins/finish_page_mixin";

    export default {
        mixins: [finish_page_submit_mixin],
        name: "InteractiveFinishPage",
        components: {
            InteractiveFinishPageTemplate: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveFinishPageTemplate')
        },
    }
</script>

<style scoped>

</style>