<template>
    <div class="conditional-logic-expression conditional-logic-expression--Datetime">
        <f-date-time-picker
                as-menu
                :menu-props="{nudgeBottom: 10}"

                return-timestamp
                v-bind="fieldConst.UTIL.GEN_PARTS_BIND_FOR_DATETIME_PICKER(getConditionalField)"
                @input="expressionObj.statement = $event"
        >
            <template #activator="{ on }">
                <v-text-field
                        :value="readableDatetime"
                        placeholder="Choose date/time"
                        class="conditional-logic-expression__expression-input cursor-pointer"
                        readonly
                        outlined
                        hide-details
                        dense
                        v-on="on"
                >
                    <template #prepend-inner>
                        <f-select
                                v-model="expressionObj.operator"
                                class="conditional-logic-expression__operator-picker"
                                ref="operatorPicker"
                                :items="expressionOperators"
                                :item-text="item => item.text"
                                :item-value="item => item.value"
                                :menu-props="{
                                    nudgeBottom: 54
                                }"
                                hide-details
                                solo flat
                                dense
                                @click.stop
                        />
                    </template>
                </v-text-field>
            </template>
        </f-date-time-picker>
    </div>
</template>

<script>
    import {computed} from '@vue/composition-api';
    import _ from 'lodash'

    export default {
        name: "Datetime__ConditionalExpression",
        props: {
            expressionObj: Object
        },
        setup(props, {root}) {
            const expressionOperators = [
                {
                    text: 'Is',
                    value: 'is'
                },
                {
                    text: 'Before',
                    value: 'before'
                },
                {
                    text: 'After',
                    value: 'after'
                }
            ]

            const getConditionalField = computed(() => root.$store.getters['edit/conditional/getConditionalField'])
            const fieldConst = computed(() => root.constants.FIELD_FROM_TYPE[getConditionalField.value?.field_type])

            const readableDatetime = computed(() => (props.expressionObj.statement || props.expressionObj.statement === 0) ? fieldConst.value.UTIL.GEN_READABLE(getConditionalField.value, props.expressionObj.statement) : props.expressionObj.statement)

            if (!props.expressionObj.operator)
                props.expressionObj.operator = expressionOperators[0].value

            if (_.isArray(props.expressionObj.statement))
                props.expressionObj.statement = null


            return {
                expressionOperators,
                readableDatetime,
                fieldConst,
                getConditionalField,
            }
        }
    }
</script>