var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "src": _vm.fieldProperties.image_content.url,
      "lazy-src": _vm.fieldProperties.image_content.url,
      "width": _vm.fieldProperties.image_size.width + '%',
      "aspect-ratio": _vm.fieldProperties.image_content.naturalWidth / _vm.fieldProperties.image_content.naturalHeight / (_vm.fieldProperties.image_size.height / 100)
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }