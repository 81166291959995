<template>
  <div
      :class="[
                  'form-composition-card',
                  'form-composition-card--interactive',
                  !persistentExpanded && 'form-composition-card--collapsed'
              ]"
  >

    <div class="form-composition-card__header">
      <span class="form-composition-card__title">Questions</span>
      <div class="form-composition-card__header-btn">
        <InteractiveNewFieldSelectionDialog/>
      </div>
    </div>
    <div class="form-composition-card__content">
      <FormPagesListDragManager
          :page-name-getter="pageNameGetter"
          :page-icon-getter="pageIconGetter"
      >
        <template #home-page>
          <div class="page-tab interactive-page-tab">
            <div class="interactive-page-tab-prepend interactive-page-tab-number"></div>
            <div class="interactive-page-tab-prepend interactive-page-tab-icon">
              {{ constants.FORM_PAGES_ICONS.HOME_PAGE }}
            </div>
            <div class="interactive-page-tab-name">
              {{ getFormJSON.all_pages[getFormJSON.home_page.page_id].name }}
            </div>
          </div>
        </template>
        <template #main-page="{ pageData: { icon, number, name }}">
          <div class="page-tab interactive-page-tab">
            <div class="interactive-page-tab-prepend interactive-page-tab-number">
              {{ number }}
            </div>
            <f-hover-tooltip right :text="Utils.stripHtml(name) | tooltipFieldName" v-slot="{ on }">
              <div class="interactive-page-tab-prepend interactive-page-tab-icon"
                   v-on="!persistentExpanded && Utils.stripHtml(name) && on"
              >
                <componenet :is="icon"/>
              </div>
            </f-hover-tooltip>
            <div class="interactive-page-tab-name">
              {{ Utils.stripHtml(name) }}
            </div>
          </div>
        </template>
        <template #finish-page>
          <div class="page-tab interactive-page-tab">
            <div class="interactive-page-tab-prepend interactive-page-tab-number"></div>
            <div class="interactive-page-tab-prepend interactive-page-tab-icon">
              {{ constants.FORM_PAGES_ICONS.FINISH_PAGE }}
            </div>
            <div class="interactive-page-tab-name">
              {{ getFormJSON.all_pages[getFormJSON.finish_page.page_id].name }}
            </div>
          </div>
        </template>
      </FormPagesListDragManager>
    </div>
    <div class="form-composition-card__actions">
      <v-divider class="form-composition-card__divider-visible-when-collapsed"/>
      <div class="form-composition-card__arrows">
        <ChevronUpIcon @click="prevPage"/>
        <ChevronDownIcon @click="nextPage"/>
      </div>
      <v-divider class="form-composition-card__persistent-divider"/>
      <div class="form-composition-card__minimize-bar-btn" @click="toggleCollapse">
        <v-icon>
          $load_circle
        </v-icon>
        <div>
          Minimize
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {editable_form_navigation_mixin} from "@/components/FormEditor/FormNavigation/editable_form_navigation_mixin"

    export default {
        name: "InteractiveFormComposition",
        components: {
            ChevronUpIcon: () => import('vue-feather-icons/icons/ChevronUpIcon'),
            ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon'),
            InteractiveNewFieldSelectionDialog: () => import('./InteractiveNewFieldSelectionDialog'),
            FormPagesListDragManager: () => import('@/components/FormEditor/FormComposition/FormPagesListDragManager')
        },
        mixins: [editable_form_navigation_mixin],
        data: vm => ({
            persistentExpanded: vm.$vuetify.breakpoint.width > 1320
        }),
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
            ...mapGetters('edit/pages_navigation', ['getCurrentPageId']),
        },
        filters: {
            tooltipFieldName(value) {
              return value.length <= 4 ? value : `${value.slice(0, 4)}...`
            },
        },
        methods: {
            pageNameGetter(pageObj) {
                if (pageObj.name)
                    return pageObj.name
                return this.getFormJSON.fields[pageObj.field_id].title
            },
            pageIconGetter(pageObj) {
                return this.constants.FIELD_FROM_TYPE[this.getFormJSON.fields[pageObj.field_id].field_type].ICON_COMPONENT
            },
            toggleCollapse() {
                this.persistentExpanded = !this.persistentExpanded
            },
        },
    }
</script>

<style lang="scss">
    .form-composition-card.form-composition-card--interactive {
        .interactive-page-tab {
            display: flex;
            height: 100%;

            &, & > * {
                display: flex;
                align-items: center;
            }

            .interactive-page-tab-prepend {
                justify-content: center;
            }

            .interactive-page-tab-number {
                max-width: 30px;
                width: 100%;
            }

            .interactive-page-tab-icon {
                margin-right: 10px;
                font-size: 20px;
            }

            .interactive-page-tab-name {
                padding-right: 10px;
                flex-basis: 140px;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: font-weight 100ms linear;
            }
        }
    }
</style>