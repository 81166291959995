var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M15.8117 18.7615C17.3002 18.3627 18.6154 17.4838 19.5535 16.2613C20.4915 15.0388 21 13.5409 21 12C21 10.4591 20.4915 8.96118 19.5535 7.73867C18.6154 6.51616 17.3002 5.63734 15.8117 5.23852",
      "stroke": "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 12L13.5857 12.4685L13.9605 12L13.5857 11.5315L13 12ZM4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75L4 11.25ZM9.58565 17.4685L13.5857 12.4685L12.4143 11.5315L8.41435 16.5315L9.58565 17.4685ZM13.5857 11.5315L9.58565 6.53148L8.41435 7.46852L12.4143 12.4685L13.5857 11.5315ZM13 11.25L4 11.25L4 12.75L13 12.75L13 11.25Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }