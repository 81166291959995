<script>
    export default {
        name: "CheckoutCustomerDetailsSetupInfoGroup",
        functional: true,
        props: {
            name: String,
            value: [String, Number]
        },
        render(h, ctx) {
            return h(
                'div',
                {class: {'checkout-payment-method-setup-info-group': true}},
                [
                    h(
                        'p',
                        {class: {'checkout-payment-method-setup-info-group__name': true}},
                        ctx.props.name
                    ),
                    h(
                        'p',
                        {class: {'checkout-payment-method-setup-info-group__value': true}},
                        ctx.props.value
                    )
                ]
            )
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-payment-method-setup-info-group {
        & + & {
            margin-top: 24px;
        }

        &__name, &__value {
            line-height: 20px;
        }

        &__name {
            color: var(--v-gray_400-base);
            margin-bottom: 6px !important;
        }
    }
</style>