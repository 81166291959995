export default {
    components: {
        SummaryFieldViewHeading: () => import('./SummaryFieldViewHeading'),
        SummaryHiddenAnswers: () => import('./SummaryHiddenAnswers'),
        SummaryChangeSortOrder: () => import('./SummaryChangeSortOrder')
    },
    props: {
        latestVersion: String,
        fieldTypeConst: Object,
        versionedSources: Object,
        submissions: Array
    },
    data: () => ({
        SORT_ORDER: {
            DEFAULT: 'default',
            DESCENDING: 'descending'
        }
    }),
    computed: {
        latestSourceObj() {
            return this.versionedSources[this.latestVersion]
        }
    }
}