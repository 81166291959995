import Utils from '@/utils/misc'
import {EditFormJsonManageExtension} from './form_edit_form_json_manage_mixin'
import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import {VBtn, VSpacer} from 'vuetify/lib/components'


export default ({useExtension}) => {
    useExtension(EditFormJsonManageExtension)

    return {
        modules: {
            confirmation_dialog: generic_dialog_module,
        },
        mutations: {
            CHANGE_MAIN_PAGE_INDEX: (state, {formJson, payload: {prevIndex, newIndex}}) => formJson.main_pages.splice(newIndex, 0, formJson.main_pages.splice(prevIndex, 1)[0]),
            CREATE_FORM_PAGE: (state, {formJson, payload: {pageID, pageObj}}) => formJson.all_pages = {
                ...formJson.all_pages,
                [pageID]: pageObj
            },
            UPDATE_FORM_PAGE: (state, {formJson, payload: {pageID, pageObj}}) => Object.assign(formJson.all_pages[pageID], pageObj),
            DELETE_FORM_PAGE: (state, {formJson, payload: pageID}) => delete formJson.all_pages[pageID],
            ADD_MAIN_PAGE: (state, {formJson, payload: {index, pageObj}}) => formJson.main_pages.splice(index, 0, pageObj),
            DELETE_MAIN_PAGE: (state, {formJson, payload: pageIndex}) => formJson.main_pages.splice(pageIndex, 1),
        },
        actions: {
            moveFormPage: ({commitFormJsonManageMutation}, {prevIndex, newIndex}) => commitFormJsonManageMutation('CHANGE_MAIN_PAGE_INDEX', {
                prevIndex,
                newIndex
            }),

            addNewFormMainPage: ({commitFormJsonManageMutation, getters}, pageObject) => {
                let newPageId = Utils.generateUUID()
                commitFormJsonManageMutation('CREATE_FORM_PAGE', {pageID: newPageId, pageObj: pageObject})
                commitFormJsonManageMutation('ADD_MAIN_PAGE', {
                    index: getters.getFormJSON.main_pages.length,
                    pageObj: {page_id: newPageId}
                })
                return newPageId
            },
            changeCurrentFormPage: ({commitFormJsonManageMutation, rootGetters}, pageObject) => {
                commitFormJsonManageMutation('UPDATE_FORM_PAGE', {
                    pageID: rootGetters['edit/pages_navigation/getCurrentPageId'],
                    pageObj: pageObject
                })
            },
            setPreviousMainPageButtonSettings: ({commitFormJsonManageMutation, rootGetters}) => {
                const pageID = rootGetters['edit/getFormJSON'].main_pages[rootGetters['edit/pages_navigation/getCurrentMainPageIndex'] - 1].page_id
                commitFormJsonManageMutation('UPDATE_FORM_PAGE', {
                    pageID,
                    pageObj: {
                        ...rootGetters['edit/getFormJSON'].all_pages[pageID],
                        submit_button: {
                            properties: {
                                button_text: 'Next',
                                on_click_action: "go_to_next_page"
                            }
                        }
                    }
                })
            },
            changeFormPage: ({commitFormJsonManageMutation}, {pageID, pageObj}) => {
                commitFormJsonManageMutation('UPDATE_FORM_PAGE', {pageID, pageObj})
            },
            shallowRemoveFormPage: ({commitFormJsonManageMutation}, {pageID, pageIndex}) => {
                commitFormJsonManageMutation('DELETE_MAIN_PAGE', pageIndex)
                commitFormJsonManageMutation('DELETE_FORM_PAGE', pageID)
            },
            removeFormPage: async ({getters, dispatch, parentGetters, parentDispatch}, pageID) => {

                dispatch('confirmation_dialog/showDialog', ({closeDialog}) => {

                    const dialogProps = {
                        withCloseBtn: true,
                    }
                    return {
                        title: 'Remove page?',
                        content: 'If you remove this page all fields inside will be removed as well.',
                        actions: {
                            functional: true,
                            render: h => [
                                h(VSpacer),
                                h(VBtn, {
                                    props: {
                                        color: 'gray_100',
                                        rounded: true,
                                    },
                                    on: {
                                        click: closeDialog
                                    }
                                }, 'Cancel'),
                                h(VBtn, {
                                    props: {
                                        color: 'red_400',
                                        rounded: true,
                                    },
                                    on: {
                                        click: async () => {
                                            for (let [pageIndex, page] of Object.entries(getters.getFormJSON.main_pages))
                                                if (page.page_id === pageID) {
                                                    let fieldIdsToDelete = []
                                                    if (parentGetters.isFormStatic)
                                                        for (let row of getters.getFormJSON.all_pages[pageID].rows)
                                                            for (let field of row.fields)
                                                                fieldIdsToDelete.push(field.field_id)
                                                    else if (parentGetters.isFormInteractive)
                                                        fieldIdsToDelete.push(getters.getFormJSON.all_pages[page.page_id].field_id)

                                                    await dispatch('shallowRemoveFormPage', {pageIndex, pageID})
                                                    fieldIdsToDelete.forEach(fieldID => parentDispatch('fields/shallowRemoveFormField', fieldID))

                                                    break
                                                }
                                            if (pageID === parentGetters['pages_navigation/getCurrentPageId'] && getters.getFormJSON.main_pages.length)
                                                parentDispatch('pages_navigation/setCurrentPageId', getters.getFormJSON.main_pages[0].page_id)
                                            else
                                                parentDispatch('pages_navigation/setCurrentPageId', Object.keys(getters.getFormJSON.all_pages)[0])
                                            closeDialog()
                                        }
                                    }
                                }, 'Remove')
                            ]
                        },
                        props: dialogProps
                    }
                })
            }
        }
    }
}