<template>
    <v-container class="h-full d-flex align-center justify-center flex-column">
        <img
                src="/images/EmptyInteractiveForm.svg"
                width="220"
                :draggable="false"
        />
        <p class="mt-2 dark_gray--text">
            Start creating
        </p>
        <p class="subtitle-2 grey_500--text font-weight-regular">
          Add questions to bring your form to life
        </p>
    </v-container>
</template>

<script>
    export default {
        name: "InteractiveEmptyPage",
    }
</script>

<style scoped lang="scss">
</style>