<template>
    <div
            ref="confettiContainer"
    />
</template>

<script>
    import lottie from 'lottie-web'

    export default {
        name: "LottieDefaultContainer",
        data: () => ({
            animation: undefined,
        }),
        props: {
          json: {
            type: Object,
            required: true
          }
        },
        async mounted() {
             this.animation =  await lottie.loadAnimation({
                container: this.$refs.confettiContainer,
                renderer: 'svg',
                animationData: this.json,
                autoplay: true,
                loop: false,
            })
           await this.animation.goToAndPlay(1, true)
        },
        beforeDestroy() {
            this.animation.destroy()
        },
    }
</script>