<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 8.25C7 7.83579 7.33579 7.5 7.75 7.5H22.25C22.6642 7.5 23 7.83579 23 8.25C23 8.66421 22.6642 9 22.25 9H7.75C7.33579 9 7 8.66421 7 8.25Z"
              fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 14.25C7 13.8358 7.33579 13.5 7.75 13.5H18.25C18.6642 13.5 19 13.8358 19 14.25C19 14.6642 18.6642 15 18.25 15H7.75C7.33579 15 7 14.6642 7 14.25Z"
              fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 20.25C7 19.8358 7.33579 19.5 7.75 19.5H12.25C12.6642 19.5 13 19.8358 13 20.25C13 20.6642 12.6642 21 12.25 21H7.75C7.33579 21 7 20.6642 7 20.25Z"
              fill="currentColor"/>
    </svg>
</template>

<script>
    export default {}
</script>

<style scoped>

</style>