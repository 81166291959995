var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "height": "80",
      "app": "",
      "color": "white",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "app-bar-part justify-start"
  }, [_vm._t("left")], 2), _c('div', {
    staticClass: "app-bar-part justify-center"
  }, [_vm._t("center")], 2), _c('div', {
    staticClass: "app-bar-part justify-end"
  }, [_vm._t("right")], 2), _vm.isLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "absolute": "",
      "bottom": ""
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }