var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "id": "airtable-svgrepo-com 1"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector",
      "d": "M16.0676 3.26264L2.65221 8.81376C1.90617 9.12251 1.9139 10.1822 2.66462 10.4797L16.1361 15.822C17.3199 16.2914 18.638 16.2914 19.8216 15.822L33.2933 10.4797C34.0438 10.1822 34.0517 9.12251 33.3055 8.81376L19.8903 3.26264C18.6663 2.7562 17.2914 2.7562 16.0676 3.26264Z",
      "fill": "#FFBF00"
    }
  }), _c('path', {
    attrs: {
      "id": "Vector_2",
      "d": "M19.1741 18.7393V32.0849C19.1741 32.7197 19.8141 33.1544 20.4042 32.9205L35.4156 27.0938C35.7583 26.9579 35.983 26.6268 35.983 26.2582V12.9126C35.983 12.2778 35.343 11.8432 34.7529 12.0771L19.7415 17.9038C19.399 18.0397 19.1741 18.3708 19.1741 18.7393Z",
      "fill": "#26B5F8"
    }
  }), _c('path', {
    attrs: {
      "id": "Vector_3",
      "d": "M15.6688 19.4277L11.2138 21.5788L10.7614 21.7974L1.35708 26.3035C0.760942 26.5911 6.10352e-05 26.1567 6.10352e-05 25.4944V12.9685C6.10352e-05 12.7289 0.122908 12.522 0.287654 12.3662C0.3564 12.2973 0.434298 12.2405 0.515247 12.1956C0.739993 12.0607 1.06054 12.0247 1.33308 12.1325L15.594 17.7829C16.3188 18.0705 16.3758 19.0862 15.6688 19.4277Z",
      "fill": "#ED3049"
    }
  }), _c('path', {
    attrs: {
      "id": "Vector_4",
      "d": "M15.6688 19.4277L11.2138 21.5788L0.287659 12.3662C0.356404 12.2973 0.434303 12.2405 0.515252 12.1956C0.739998 12.0607 1.06054 12.0247 1.33308 12.1325L15.594 17.7829C16.3188 18.0705 16.3758 19.0862 15.6688 19.4277Z",
      "fill": "black",
      "fill-opacity": "0.25"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }