<template>
    <div class="style-preferences-theme-editor-radius-style">
        <div class="style-preferences-theme-editor-radius-style__layout">
            <div
                    v-for="radius in allowedRadii"
                    class="style-preferences-theme-editor-radius-style__option-wrapper"
                    :key="radius"
                    @click="$emit('input', radius)"
            >
                <div
                        :class="[
                            'style-preferences-theme-editor-radius-style__option',
                            radius === value && 'style-preferences-theme-editor-radius-style__option--active'
                        ]"
                >
                    <div
                            class="style-preferences-theme-editor-radius-style__option-content"
                            :style="{
                                borderRadius: radius + 'px'
                            }"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StylePreferencesThemeEditorBorderRadiusStyle",
        props: {
            value: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            allowedRadii: [0, 6, 100]
        })
    }
</script>

<style lang="scss" scoped>
    .style-preferences-theme-editor-radius-style {
        margin-bottom: 22px;

        &__layout {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__option-wrapper {
            cursor: pointer;

            * {
                transition: all .1s;
            }
        }

        &__option {
            padding: 11px 15px;
            border-radius: 8px;
            border: 2px solid white;
            background-color: var(--v-gray_100-base);

            &--active {
                & {
                    box-shadow: 0 0 0 2px var(--v-blue_400-base);
                    background-color: var(--v-blue_100-base);
                }

                .style-preferences-theme-editor-radius-style__option-content {
                    background-color: var(--v-blue_400-base);
                }
            }
        }

        &__option-content {
            width: 48px;
            height: 16px;
            background-color: var(--v-gray_300-base);
        }
    }
</style>