<template>
    <div class="media-upload-property">
        <div v-if="inputValue.url || loading" class="media-upload-property-image-manage">
            <v-img
                    :src="loading ? '' : inputValue.url"
                    height="180"
                    class="media-upload-property-image-manage__image"
            >
                <template #placeholder>
                    <f-circle-loader size="4"/>
                </template>
            </v-img>
            <div class="media-upload-property-image-manage__info-row">
                <span v-if="filename" class="media-upload-property-image-manage__filename">{{filename}}</span>
                <v-skeleton-loader v-else type="text" width="90"/>
                <span v-if="filename" class="media-upload-property-image-manage__replace-btn" @click="change">Change</span>
            </div>
            <div class="media-upload-property-image-manage__info-row">
                <span v-if="width" class="media-upload-property-image-manage__secondary-info">{{width}}x{{height}}px</span>
                <v-skeleton-loader v-else type="text" width="70"/>
                <span v-if="size" class="media-upload-property-image-manage__secondary-info">{{size}}</span>
                <v-skeleton-loader v-else type="text" width="60"/>
            </div>
        </div>
        <FileUploadArea
                v-else
                class="media-upload-property-empty-area"
                :accept-mime="['image/jpeg', 'image/png']"
                :max-file-size="inputConst.max_size"
                match-both-mime-and-extension
                @input="addFile"
        />
    </div>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "ImageUpload",
        components: {
            FileUploadArea: () => import('@/components/Elements/FileInputs/FileUploadArea')
        },
        mixins: [field_property_input_mixin],
        data: () => ({
            filename: undefined,
            width: undefined,
            height: undefined,
            size: undefined,

            loading: false
        }),
        async created() {
            if (this.inputValue.url) {
                const response = await this.StorageActions.getImageByS3Path(this.constants.GET_S3_OBJECT_RELATIVE_PATH_BY_URL(this.inputValue.url), true)
                this.filename = response.initialFilename
                this.size = this.Utils.convertBytesToReadable(response.size)
                const {width, height} = await this.Utils.getImageDimensions(response.dataURL)
                this.width = width
                this.height = height
            }
        },
        methods: {
            change() {
                this.fileInputEventBus.$emit('trigger-input', {
                    mimeTypes: ['image/png', 'image/jpeg'],
                    maxFileSize: this.inputConst.max_size,
                    maxFilesCount: 1,
                    callback: this.addFile,
                    matchBothMimeAndExtension: true
                })
            },
            async addFile(file) {
                this.loading = true
                this.dataURL = await this.Utils.blobToBase64Data(file)
                const {width, height} = await this.Utils.getImageDimensions(this.dataURL)
                this.filename = file.name
                this.width = width
                this.height = height
                this.size = this.Utils.convertBytesToReadable(file.size)

                const url = await this.StorageActions.uploadPublicAttachment(file)
                this.input({
                    url,
                    naturalWidth: width,
                    naturalHeight: height
                })
                this.loading = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .media-upload-property {
        .media-upload-property-image-manage {
            &__image {
                border-radius: 8px;
                margin-bottom: 6px;
            }

            &__info-row {
                display: flex;
                justify-content: space-between;

                .v-skeleton-loader .v-skeleton-loader__text {
                    margin: 0;
                }
            }

            &__filename {
                color: var(--v-blish_gray_400-base);
            }

            &__replace-btn {
                font-weight: 500;
                color: var(--v-blue_400-base);
                cursor: pointer;
            }

            &__secondary-info {
                color: var(--v-gray_400-base);
                font-size: .875rem;
            }
        }

        .media-upload-property-empty-area {
            height: 170px;
        }
    }
</style>