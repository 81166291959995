<template>
    <div class="aspect-ratio-keeper">
        <component :is="widthComp">
            <component :is="heightComp"><slot/></component>
        </component>
    </div>
</template>

<script>
    import { isFinite } from 'lodash'
    import { ref, watch } from '@vue/composition-api'

    export default {
        name: "AspectRatioKeeper",
        props: {
            width: {
                type: [Number, String],
                required: true
            },
            height: {
                type: [Number, String],
                required: true
            },
        },
        setup(props) {
            const sizerWidthValue = ref(null)
            const sizerHeightValue = ref(null)

            watch(
                [() => props.width, () => props.height],
                ([width, height]) => {
                    width = Math.round(isFinite(width) ? width : parseFloat(width))
                    height = Math.round(isFinite(height) ? height : parseFloat(height))

                    /* WIDTH */
                    sizerWidthValue.value = ((height / width) * 100).toString() + '%'


                    /* HEIGHT */
                    let canvas = document.createElement("canvas")
                    canvas.width = width
                    canvas.height = height

                    let ctx = canvas.getContext("2d")
                    ctx.fillStyle = 'transparent'
                    ctx.fillRect(0, 0, width, height)

                    let encoded = canvas.toDataURL()
                    canvas.remove()

                    sizerHeightValue.value = encoded
                },
                {immediate: true}
            )


            const widthComp = {
                functional: true,
                render: (h, ctx) => h('div', {class: 'aspect-ratio-keeper__wrapper-width'}, [
                    h('div', {class: 'aspect-ratio-keeper__sizer-width', style: {paddingTop: sizerWidthValue.value}}),
                    h('div', {class: 'aspect-ratio-keeper__responsive-width'}, ctx.children)
                ])
            }

            const heightComp = {
                functional: true,
                render: (h, ctx) => h('div', {class: 'aspect-ratio-keeper__wrapper-height'}, [
                    h('img', {class: 'aspect-ratio-keeper__sizer-height', attrs: {src: sizerHeightValue.value}}),
                    h('div', {class: 'aspect-ratio-keeper__responsive-height'}, ctx.children)
                ])
            }


            return {
                widthComp,
                heightComp
            }
        }
    }
</script>

<style lang="scss">
    .aspect-ratio-keeper {
        display: contents;

        &__wrapper-width {
            display: flex;
            flex-grow: 1;
            align-self: center;
            width: 100%;
            min-width: 0;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
        }

        &__sizer-width {
            height: 0;
            flex-grow: 1
        }

        &__responsive-width {
            margin-left: -100%;
            max-width: 100%;
            flex-grow: 1;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
        }

        &__wrapper-height {
            position: relative;
        }

        &__sizer-height {
            height: 100%;
        }

        &__responsive-height {
            width: 100%;
            height: 100%;
            //display: table;
            top: 0;
            position: absolute;
            //zoom: 0.3;
            //max-height: 100%;
            left: 0;
            //pointer-events: none;
        }
    }
</style>