<template>
  <div class="slider-field-view">
    <div class="slider-field-view__value">
      {{ submissionObj?.value || from }}
    </div>
    <v-slider
        :value="submissionObj?.value || 0"
        @input="submissionObj.value = $event"
        class="slider-field-view__slider"
        track-color="none"
        track-fill-color="none"
        thumb-color="none"
        :disabled="editable || preview"
        hide-details
        dense
        :step="scaleUnits"
        :min="!editable ? from : 0"
        :max="to"
    >
    </v-slider>
    <div v-if="labels" class="slider-field-view__labels">
      <f-simple-contenteditable-input
          :readonly="!editable"
          v-model="labels.left_label"
          :placeholder="from.toLocaleString()"
          class="slider-field-view__label-from"
      />
      <f-simple-contenteditable-input
          :readonly="!editable"
          v-model="labels.right_label"
          :placeholder="to.toLocaleString()"
          class="slider-field-view__label-to"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharedView__Slider',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    submissionObj: {
      type: Object,
      default: () => {},
    },
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    labels: {
      type: Object,
    },
    scaleUnits: {
      type: Number,
    }
  },
}
</script>