var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "scale-units": _vm.fieldProperties.scale_units,
      "from": _vm.fieldProperties.scale_poles.from,
      "to": _vm.fieldProperties.scale_poles.to,
      "labels": _vm.fieldProperties.range_labels,
      "submission-obj": _vm.submissionObj
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }