<template>
    <svg width="139" height="99" viewBox="0 0 139 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116.192 50.4465C116.192 77.2027 94.5016 98.893 67.7453 98.893C40.9891 98.893 19.2988 77.2027 19.2988 50.4465C19.2988 23.6902 40.9891 2 67.7453 2C94.5016 2 116.192 23.6902 116.192 50.4465Z"
              fill="#E3EAFF"/>
        <path d="M9.80655 61.3333L12.5319 58.0206C12.686 57.8333 12.5787 57.5495 12.3393 57.5108L8.02371 56.8138C7.78296 56.775 7.59161 57.0132 7.68152 57.2399L9.2718 61.2495C9.36001 61.4719 9.65453 61.5181 9.80655 61.3333Z"
              fill="#2FBAEA"/>
        <path d="M134.001 78.6972C134.001 80.4389 132.589 81.8509 130.847 81.8509C129.105 81.8509 127.693 80.4389 127.693 78.6972C127.693 76.9554 129.105 75.5435 130.847 75.5435C132.589 75.5435 134.001 76.9554 134.001 78.6972Z"
              fill="#E6EBFB"/>
        <path d="M11 24.5C11 26.433 9.433 28 7.5 28C5.567 28 4 26.433 4 24.5C4 22.567 5.567 21 7.5 21C9.433 21 11 22.567 11 24.5Z"
              fill="#E6EBFB"/>
        <path d="M31.8845 92.4364C31.8845 93.5646 30.9699 94.4792 29.8416 94.4792C28.7134 94.4792 27.7988 93.5646 27.7988 92.4364C27.7988 91.3082 28.7134 90.3936 29.8416 90.3936C30.9699 90.3936 31.8845 91.3082 31.8845 92.4364Z"
              fill="#C55BFC"/>
        <circle cx="105.993" cy="3.69966" r="1.13325" fill="url(#paint0_linear_3361_30630)"/>
        <rect x="108.826" y="17.2993" width="69.695" height="83.294" rx="6" transform="rotate(90 108.826 17.2993)"
              fill="url(#paint1_linear_3361_30630)" stroke="black" stroke-opacity="0.08"/>
        <rect x="115.625" y="11.6333" width="70.2616" height="96.893" rx="6" transform="rotate(90 115.625 11.6333)"
              fill="url(#paint2_linear_3361_30630)" stroke="black" stroke-opacity="0.08"/>
        <circle cx="44.7971" cy="49.03" r="5.66626" fill="#2FBBEB"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M47.6563 46.9674C47.8894 47.2238 47.873 47.623 47.6197 47.859L44.0384 51.195C43.9122 51.3125 43.7438 51.3722 43.5727 51.3601C43.4017 51.348 43.2431 51.2651 43.1344 51.1309L41.9052 49.6146C41.6868 49.3452 41.7255 48.9476 41.9916 48.7265C42.2578 48.5054 42.6506 48.5446 42.8689 48.814L43.6796 49.8141L46.7755 46.9304C47.0288 46.6944 47.4232 46.711 47.6563 46.9674Z"
              fill="white"/>
        <rect width="32.0179" height="3.4614" rx="1.7307" transform="matrix(-1 0 0 1 87.127 47.2588)" fill="#E3E9F7"/>
        <rect width="40.2304" height="2.83313" rx="1.41656" transform="matrix(-1 0 0 1 79.9277 24.666)" fill="#E3E9F7"/>
        <rect width="18.6986" height="3.39975" rx="1.69988" transform="matrix(-1 0 0 1 58.3965 32.0322)"
              fill="#E3E9F7"/>
        <rect x="39.1309" y="63.7632" width="16.4321" height="4.533" rx="2.2665" fill="url(#paint3_linear_3361_30630)"/>
        <defs>
            <linearGradient id="paint0_linear_3361_30630" x1="104.917" y1="3.68344" x2="107.116" y2="3.68344"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF66A9"/>
                <stop offset="1" stop-color="#F53689"/>
            </linearGradient>
            <linearGradient id="paint1_linear_3361_30630" x1="144.588" y1="17.2993" x2="144.588" y2="102.163"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#FEFEFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_3361_30630" x1="151.678" y1="11.6333" x2="151.678" y2="110.353"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#FEFEFF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_3361_30630" x1="39.0963" y1="66.03" x2="55.559" y2="66.03"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#3D66F6"/>
                <stop offset="1" stop-color="#6789FF"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "InteractiveFormImage"
    }
</script>