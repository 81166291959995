<template>
    <div class="conditional-logic-expression conditional-logic-expression--Number">
        <v-text-field
                v-model.number="expressionObj.statement"
                class="conditional-logic-expression__expression-input"
                placeholder="Add statement"
                type="number"
                outlined
                hide-details
                dense
                @input.native="handleInput"
        >
            <template #prepend-inner>
                <f-select
                        v-model="expressionObj.operator"
                        class="conditional-logic-expression__operator-picker"
                        :items="expressionOperators"
                        :item-text="item => item.text"
                        :item-value="item => item.value"
                        :menu-props="{
                            nudgeBottom: 54
                        }"
                        hide-details
                        solo flat
                        dense
                />
            </template>
            <template v-slot:selection="{ item, parent }">
                <v-chip
                        class="my-1"
                        :key="JSON.stringify(item)"
                        label
                        small
                >
                    {{ item }}
                    <XIcon class="cursor-pointer ml-1" size="12" @click="parent.selectItem(item)"/>
                </v-chip>
            </template>
        </v-text-field>
    </div>
</template>

<script>

    import {computed} from '@vue/composition-api'
    import Vue from 'vue';

    export default {
        name: "Number__ConditionalExpression",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            expressionObj: Object
        },
        setup(props, {root}) {
            const expressionOperators = [
                {
                    text: 'Is equal to',
                    value: 'equals'
                },
                {
                    text: 'Is not equal to',
                    value: 'not_equals'
                },
                {
                    text: 'Is greater than',
                    value: 'greater_than'
                },
                {
                    text: 'Is lower than',
                    value: 'lower_than'
                },
                {
                    text: 'Greater or equal to',
                    value: 'greater_or_equal'
                },
                {
                    text: 'Lower or equal to',
                    value: 'lower_or_equal'
                }
            ]
            let previousValue = null;

            if (!props.expressionObj.operator)
                props.expressionObj.operator = expressionOperators[0].value

            const allowed_range = computed(() => {
                return root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.ALLOWED_RANGE(root.$store.getters['edit/conditional/getConditionalField'])
            })

            const handleInput = e => {
                Vue.nextTick(() => {
                    if (!allowed_range.value) {
                        return
                    } else if (e.target.value < allowed_range.value[0] || e.target.value > allowed_range.value[1]) {
                        if ('inputType' in e) {
                            props.expressionObj.statement = +(String(props.expressionObj.statement).slice(0, String(props.expressionObj.statement).length - 1))
                        } else {
                            props.expressionObj.statement = previousValue;
                        }
                    }
                    previousValue = props.expressionObj.statement;
                })

            };
            return {
                expressionOperators,
                handleInput,
            }
        }
    }
</script>