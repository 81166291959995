var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "checkout-choose-plan-page"
  }, [_vm.getPlansIsLoading || !_vm.getPlanSelectionDone ? _c('v-row', [_c('v-col', [_c('f-circle-loader', {
    attrs: {
      "size": "5"
    }
  })], 1)], 1) : [_c('div', {
    staticClass: "checkout-page-heading checkout-page-heading--offset-lg"
  }, [_c('div', {
    staticClass: "checkout-page-heading__title"
  }, [_c('span', [_vm._v("Upgrade your plan, unleash your potential.")])]), _c('div', {
    staticClass: "checkout-page-heading__subtitle"
  }, [_c('span', [_vm._v("Choose the best plan for your business.")])])]), _c('div', {
    staticClass: "checkout-choose-plan-page__billing-period-switcher-wrapper"
  }, [_c('BillingPeriodSwitcher', {
    attrs: {
      "value": _vm.getSelectedBillingPeriod
    },
    on: {
      "input": function ($event) {
        _vm.switchSelectedOption(function () {
          return _vm.setSelectedBillingPeriod($event);
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "checkout-choose-plan-page__plan-selection-container"
  }, [_c('div', {
    staticClass: "checkout-choose-plan-page__plans-gallery-wrapper"
  }, [_c('PlansGallery', {
    attrs: {
      "selected-product": _vm.getSelectedProduct,
      "selected-price": _vm.getSelectedPrice,
      "is-disclaimer": "",
      "selected-billing-period": _vm.getSelectedBillingPeriod,
      "switchSelectedOptionTo": _vm.switchSelectedOption
    },
    on: {
      "update:product": function ($event) {
        _vm.switchSelectedOption(function () {
          return _vm.setSelectedProduct($event);
        });
      },
      "update:price": function ($event) {
        _vm.switchSelectedOption(function () {
          return _vm.setSelectedPrice($event);
        });
      },
      "see-all-features": function ($event) {
        return _vm.$vuetify.goTo(_vm.$refs.plansFeaturesTable);
      }
    }
  })], 1), _c('div', {
    staticClass: "checkout-choose-plan-page__selected-plan-amount-wrapper"
  }, [_c('SelectedPlanAmount', {
    scopedSlots: _vm._u([{
      key: "button",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "disabled": !_vm.getPlanSelectionConfirmationAllowed
          },
          on: {
            "click": _vm.confirmPlan
          }
        }, 'v-btn', attrs, false), [_vm._v(" Continue ")])];
      }
    }, {
      key: "hint",
      fn: function (_ref2) {
        var _orderSummary$renewal, _orderSummary$renewal2, _orderSummary$renewal3, _orderSummary$renewal4, _orderSummary$renewal5;
        var component = _ref2.component,
          orderSummary = _ref2.orderSummary,
          selectedPriceObj = _ref2.selectedPriceObj;
        return [_c(component, {
          tag: "component"
        }, [_vm._v(" Your plan is billed " + _vm._s((selectedPriceObj === null || selectedPriceObj === void 0 ? void 0 : selectedPriceObj.recurring_interval) + 'ly') + " and will renew for " + _vm._s((orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$renewal = orderSummary.renewal) === null || _orderSummary$renewal === void 0 ? void 0 : _orderSummary$renewal.price) / 100) + " USD (plus any applicable taxes and minus any discounts) on " + _vm._s((orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$renewal2 = orderSummary.renewal) === null || _orderSummary$renewal2 === void 0 ? void 0 : (_orderSummary$renewal3 = _orderSummary$renewal2.next_invoice) === null || _orderSummary$renewal3 === void 0 ? void 0 : _orderSummary$renewal3.timestamp) && _vm.$options.moment.unix(orderSummary === null || orderSummary === void 0 ? void 0 : (_orderSummary$renewal4 = orderSummary.renewal) === null || _orderSummary$renewal4 === void 0 ? void 0 : (_orderSummary$renewal5 = _orderSummary$renewal4.next_invoice) === null || _orderSummary$renewal5 === void 0 ? void 0 : _orderSummary$renewal5.timestamp).format('MMM D, YYYY')) + ". ")]), _c(component, {
          tag: "component"
        }, [_vm._v(" You can cancel any time before this date. ")])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "checkout-choose-plan-page__plans-features-table-wrapper"
  }, [_c('PlansFeaturesTable', {
    ref: "plansFeaturesTable",
    attrs: {
      "selected-product": _vm.getSelectedProduct
    }
  })], 1), _c('v-divider'), _c('div', {
    staticClass: "checkout-choose-plan-page__security-information"
  }, [_c('div', {
    staticClass: "accepted-payment-methods"
  }, [_c('span', [_vm._v("ACCEPTED PAYMENT METHODS")]), _c('div', {
    staticClass: "accepted-payment-methods__brands"
  }, _vm._l(_vm.stripeSupportedBrands, function (brand, index) {
    return _c('div', {
      key: index,
      staticClass: "brand"
    }, [_c('f-card-brand-icon', {
      attrs: {
        "brand": brand
      }
    })], 1);
  }), 0)]), _c('div', {
    staticClass: "secure-payment-wrapper"
  }, [_c('div', {
    staticClass: "secure-payment"
  }, [_c('SecurePaymentIcon'), _c('div', {
    staticClass: "secure-payment-info"
  }, [_c('span', [_vm._v("SSL SECURE PAYMENT")]), _c('p', [_vm._v("Your information is protected by 256-bit "), _c('br'), _vm._v(" SSL encryption.")])])], 1)])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }