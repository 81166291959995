<template>
    <div>
        <f-circle-loader v-if="getBillingDetailsIsLoading && !editingActive" size="4"/>
        <template v-else>
            <div v-if="getBillingDetailsIsExist && !editingActive">
                <div class="mb-6">
                    <CheckoutCustomerDetailsSetupInfoGroup
                            name="Name or company"
                            :value="getBillingDetails.name"
                    />
                    <CheckoutCustomerDetailsSetupInfoGroup
                            name="Address"
                            :value="getBillingDetails.billing_address.line1"
                    />
                    <CheckoutCustomerDetailsSetupInfoGroup
                            name="Zip, city, country"
                            :value="`${getBillingDetails.billing_address.postal_code}, ${getBillingDetails.billing_address.city}, ${constants.COUNTRY_NAMES?.[getBillingDetails.billing_address.country]}`"
                    />
                </div>

                <v-btn
                        color="gray_100"
                        rounded
                        @click="editBillingDetails"
                >Edit payment method</v-btn>
            </div>

            <component
                    v-else
                    :is="$options.BillingDetailsForm.form"
                    v-slot="{attrs, submit, reset, loading}"
            >
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.name" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.BillingDetailsForm.line1" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <component :is="$options.BillingDetailsForm.country" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <component :is="$options.BillingDetailsForm.postal_code" v-bind="attrs"/>
                    </v-col>
                    <v-col cols="6">
                        <component :is="$options.BillingDetailsForm.city" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                                class="mr-4"
                                color="blue_400"
                                rounded
                                :loading="loading"
                                @click="submit(billingDetails => $store.dispatch('user/plan/checkout/customer_details/updateBillingDetails', billingDetails).then(cancelEditing))"
                        >Save</v-btn>
                        <v-btn
                                color="gray_100"
                                rounded
                                :disabled="loading"
                                @click="() => {
                                    reset()
                                    cancelEditing()
                                }"
                        >Cancel</v-btn>
                    </v-col>
                </v-row>
            </component>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import BillingDetailsForm from '@/components/Settings/PlansAndBilling/BillingDetails/BillingDetailsForm'

    export default {
        name: "CheckoutBillingAddressSetup",
        BillingDetailsForm,
        components: {
            CheckoutCustomerDetailsSetupInfoGroup: () => import('./Elements/CheckoutCustomerDetailsSetupInfoGroup')
        },
        data: () => ({
            editingActive: false
        }),
        computed: {
            ...mapGetters('user/plan/checkout/customer_details', [
                'getBillingDetailsIsExist',
                'getBillingDetails',
                'getBillingDetailsIsLoading'
            ])
        },
        methods: {
            editBillingDetails() {
                this.editingActive = true
            },
            cancelEditing() {
                this.editingActive = false
            }
        }
    }
</script>