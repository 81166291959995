var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F5F0FA"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 6.5L14.8339 10.4759L19.1819 10.9914L15.9673 13.9641L16.8206 18.2586L13 16.12L9.1794 18.2586L10.0327 13.9641L6.81813 10.9914L11.1661 10.4759L13 6.5Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }