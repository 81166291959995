const moduleGetterName = 'getSelfModule'

const DefaultModuleableSBGenerator = ({module}) => ({getters: {[moduleGetterName]: () => module}})
DefaultModuleableSBGenerator.key = 'Moduleable'

export const ModuleableExtensions = Object.freeze({
    Default: {
        SBGenerator: DefaultModuleableSBGenerator
    }
})

export const ModuleableUtils = Object.freeze({
    moduleGetterName
})
