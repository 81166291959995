var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "v-btn--text-hoverable",
          attrs: {
            "exact": "",
            "to": {
              name: _vm.constants.PAGE_NAME_FORM_TYPE_SELECTION
            },
            "depressed": "",
            "text": ""
          }
        }, [_c('ChevronLeftIcon', {
          staticClass: "v-btn__prepend-icon"
        }), _c('span', [_vm._v("Back")])], 1)];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('AppBarLogoComponent')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }