<template>
  <InteractiveHomePage :page-obj="pageObj" @go-to-next-page="goToNextPage"/>
</template>

<script>
    import { computed, inject, onBeforeUnmount, onMounted } from '@vue/composition-api'
    import {useMapActions} from '@/xstore'

    export default {
        name: "InteractiveHomePageInterlayer",
        components: {
            InteractiveHomePage: () => import('./InteractiveHomePage'),
        },
        props: {
            pageId: {
                type: String,
                required: true
            }
        },
        setup(props, {root}) {
            const {
                goToNextPage
            } = useMapActions(inject('formSubmitStoreModulePath'), [
                'goToNextPage'
            ])

            function keydownListener(e) {
                if ((e.which || e.keyCode) === 13)
                    goToNextPage()
            }

            onMounted(() => {
                document.body.addEventListener('keydown', keydownListener)
            })

            onBeforeUnmount(() => {
                document.body.removeEventListener('keydown', keydownListener)
            })

            const pageObj = computed(() => root.$store.getters[inject('formSubmitStoreModulePath') + '/getFormSourceJSON'].all_pages[props.pageId]);

            return {
                goToNextPage,
                pageObj,
            }
        }
    }
</script>