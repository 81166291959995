<template>
  <div class="submissions-analytics__content">
    <f-circle-loader v-if="!getSubmissionsInitiallyAllFetched" size="5"/>
    <template v-else>
      <div
          v-if="areSubmissionsExist"
          class="summary-analytics-view"
      >
        <template v-for="({fieldID, fieldAttrs}, index) in sortedSubmissionsGroupedByFieldID">
          <div
              class="summary-analytics-view__answers-block-wrapper"
              :key="fieldID"
          >
            <div class="summary-analytics-view__answers-block" :data-index="index + 1">
              <component
                  :is="fieldAttrs.fieldTypeConst.ANALYTICS.VIEWS.SUMMARY_ANSWER_VIEW"
                  v-bind="fieldAttrs"
                  :totalResponse="totalResponse"
              />
            </div>
          </div>
        </template>
      </div>
      <NoResponses v-else/>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from '@/xstore'
import {computed} from '@vue/composition-api'
import {FieldsEnumerationGettersUtils} from '@/store/util_modules/fields_enumeration_getters'

export default {
  name: "SummaryView",
  components: {
    NoResponses: () => import('../NoResponses'),
  },
  props: {
    totalResponse: {
      type: Number,
      required: true,
    },
    formID: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('submissions', [
      'getSubmissionsInitiallyFetched',
      'areSubmissionsExist',
      'getAllSubmissionsList',
      'getSubmissionsInitiallyAllFetched',
    ])
  },
  setup(props, { root }) {
    const submissionsGroupedByFieldID = computed(() => {
      const result = {}
      // if (root.$store.getters['submissions/getSubmissionsInitiallyAllFetched'])
      // console.log('testing1');
      //   return result

      const allSubmissionsList = root.$store.getters['submissions/getAllSubmissionsList']
      if (!allSubmissionsList) return result

      allSubmissionsList.forEach(submission => {
        Object.entries(submission.rawData).forEach(([fieldID, submissionObj]) => {
          if (!(fieldID in result))
            result[fieldID] = {
              latestVersion: submission.publishedVersionID,
              fieldTypeConst: root.constants.FIELD_FROM_TYPE[root.$store.getters['submissions/getVersionedAllFormSources'][submission.publishedVersionID]?.fields[fieldID]?.field_type],
              versionedSources: {},
              submissions: []
            }

          result[fieldID].versionedSources[submission.publishedVersionID] = root.$store.getters['submissions/getVersionedAllFormSources'][submission.publishedVersionID]?.fields[fieldID]
          result[fieldID].submissions.push({
            versionID: submission.publishedVersionID,
            submissionObj,
            submissionID: submission.id
          })
        })
      })
      return result
    })

    const sortedSubmissionsGroupedByFieldID = computed(() => {
      const result = []

      const enumeratedFieldsList = root.$store.getters['edit/' + FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName]
      if (!enumeratedFieldsList) return result

      enumeratedFieldsList.forEach(({ fieldId }) => {
        if (fieldId in submissionsGroupedByFieldID.value)
          result.push({
            fieldID: fieldId,
            fieldAttrs: submissionsGroupedByFieldID.value[fieldId]
          })
      })

      Object.entries(submissionsGroupedByFieldID.value).forEach(([fieldID, fieldAttrs]) => {
        if (!result.find(v => v.fieldID === fieldID))
          result.push({
            fieldID,
            fieldAttrs
          })
      })

      return result

    })

    return {
      submissionsGroupedByFieldID,
      sortedSubmissionsGroupedByFieldID
    }
  },
  methods: {
    ...mapActions('submissions', ['fetchAllSubmissionsList']),
  },
  created() {
    this.fetchAllSubmissionsList( this.formID ).then(() => {
      // Ensure the loader hides by updating the fetched state
      this.$store.commit('submissions/SET_SUBMISSIONS_INITIALLY_ALL_FETCHED', true);
    });
  }
}
</script>

<style lang="scss">
.summary-analytics-view {
  &__answers-block-wrapper {
    padding: 32px;
    padding-left: 40px;
    border: 1px solid var(--v-gray_200-base);
    border-radius: 8px;

    &:not(&:last-of-type) {
      margin-bottom: 24px;
    }
  }

  &__answers-block {
    position: relative;

    &:before {
      content: attr(data-index) '.';
      position: absolute;
      right: 100%;
      font-weight: 400;
    }

    .summary-answers-block-heading {
      display: flex;
      padding-left: 8px;
      margin-bottom: 17px;

      &__icon {
        width: 26px;
        height: 26px;
        margin-right: 14px;
      }

      &__text-block {
        display: flex;
        flex-direction: column;
      }

      &__title {
        line-height: 1.125;
        color: var(--v-grey_900-base);
        font-weight: 500;
        margin-bottom: 7px !important;
      }

      &__subtitle {
        color: var(--v-gray_400-base);
        font-size: .875rem;
        line-height: 1.6;
      }

      &__answers-count {
        color: black;
      }
    }

    .summary-analytics-view__field-view-wrapper {
      padding-left: 8px + 26px + 14px;
    }
  }

  &__divider {
    margin: 30px 0;
  }
}
</style>