<template>
    <div class="checkout-choose-plan-plans-gallery">
        <div class="checkout-choose-plan-plans-gallery__layout">
            <div class="checkout-choose-plan-plans-gallery__cards-row">
                <BillingPlanCard
                        name="Free"
                        description="For individuals and teams getting started"
                        price="0"
                        :features="['20 responses/mo', 'Unlimited questions']"

                        :selected="selectedProduct === 'free'"
                        :current-plan="getCurrentSubscription?.priceID === getPlansObject?.free?.prices?.month?.id"
                        v-bubble="'see-all-features'"
                        @select="() => {
                            $emit('update:product', 'free')
                            $emit('update:price', getPlansObject?.free?.prices?.month?.id)
                        }"
                />
                <BillingPlanCard
                        name="Pro"
                        description="Make your forms more beautiful and on brand"
                        :price="Math.round(getPlansObject?.pro?.prices?.[selectedBillingPeriod]?.amount / (selectedBillingPeriod === 'year' ? 12 : 1) / 100)"
                        :features="['200 responses/mo', 'Unlimited questions']"

                        :selected="selectedProduct === 'pro'"
                        :current-plan="getCurrentSubscription?.priceID === getPlansObject?.pro?.prices?.[selectedBillingPeriod]?.id"
                        characteristic="most famous"
                        v-bubble="'see-all-features'"
                        @select="() => {
                            $emit('update:product', 'pro')
                            $emit('update:price', getPlansObject?.pro?.prices?.[selectedBillingPeriod]?.id)
                        }"
                />
                <BillingPlanCard
                        name="Business"
                        description="Make your forms more beautiful and on brand"
                        :price="Math.round(getPlansObject?.business?.prices?.[selectedBillingPeriod]?.amount / (selectedBillingPeriod === 'year' ? 12 : 1) / 100)"
                        :features="['1000 responses/mo', 'Unlimited questions']"

                        :selected="selectedProduct === 'business'"
                        :current-plan="getCurrentSubscription?.priceID === getPlansObject?.business?.prices?.[selectedBillingPeriod]?.id"
                        v-bubble="'see-all-features'"
                        @select="() => {
                            $emit('update:product', 'business')
                            $emit('update:price', getPlansObject?.business?.prices?.[selectedBillingPeriod]?.id)
                        }"
                />
            </div>
            <div v-show="isDisclaimer">
              <DividedDisclaimer/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import  DividedDisclaimer  from '../../DividedDisclaimer'

    export default {
        name: "PlansGallery",
        components: {
            BillingPlanCard: () => import('./Elements/BillingPlanCard'),
            // DividedDisclaimer: () => import('../../DividedDisclaimer')
          DividedDisclaimer
        },
        props: {
            selectedProduct: String,
            selectedPrice: String,
            selectedBillingPeriod: {
                type: String,
                required: true
            },
            isDisclaimer: {
              type: Boolean,
              required: true
            }
        },
        computed: {
            ...mapGetters('user/plan', [
                'getPlansIsLoading',
                'getPlansObject'
            ]),
            ...mapGetters('user/plan/current_subscription', [
                'getCurrentSubscription',
            ]),
        },
        watch: {
            selectedBillingPeriod: {
                handler() {
                    let priceID
                    if (this.selectedProduct === 'free')
                        priceID = this.getPlansObject?.free?.prices?.month?.id
                    else if (['pro', 'business'].includes(this.selectedProduct))
                        priceID = this.getPlansObject?.[this.selectedProduct]?.prices?.[this.selectedBillingPeriod]?.id

                    if (priceID)
                        this.$emit('update:price', priceID)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-choose-plan-plans-gallery {
        &__layout {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
        }

        &__cards-row {
            display: flex;
            align-items: start;
            column-gap: 20px;
        }
    }
</style>
