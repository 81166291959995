var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M110.459 62.4465C110.459 89.2027 88.7691 110.893 62.0129 110.893C35.2566 110.893 13.5664 89.2027 13.5664 62.4465C13.5664 35.6902 35.2566 14 62.0129 14C88.7691 14 110.459 35.6902 110.459 62.4465Z",
      "fill": "#E3EAFF"
    }
  }), _c('rect', {
    attrs: {
      "x": "103.094",
      "y": "29.2993",
      "width": "69.695",
      "height": "83.294",
      "rx": "6",
      "transform": "rotate(90 103.094 29.2993)",
      "fill": "url(#paint0_linear_8104_41336)",
      "stroke": "black",
      "stroke-opacity": "0.08"
    }
  }), _c('rect', {
    attrs: {
      "x": "109.893",
      "y": "23.6333",
      "width": "70.2616",
      "height": "96.893",
      "rx": "6",
      "transform": "rotate(90 109.893 23.6333)",
      "fill": "url(#paint1_linear_8104_41336)",
      "stroke": "black",
      "stroke-opacity": "0.08"
    }
  }), _c('rect', {
    attrs: {
      "width": "40.2304",
      "height": "2.83313",
      "rx": "1.41656",
      "transform": "matrix(-1 0 0 1 74.1953 54.0005)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "18.6986",
      "height": "3.39975",
      "rx": "1.69988",
      "transform": "matrix(-1 0 0 1 52.6641 61.3667)",
      "fill": "#E3E9F7"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_8104_41336",
      "x1": "138.856",
      "y1": "29.2993",
      "x2": "138.856",
      "y2": "114.163",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_8104_41336",
      "x1": "145.946",
      "y1": "23.6333",
      "x2": "145.946",
      "y2": "122.353",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }