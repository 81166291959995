<template>
    <div class="f-us-state-flag">
        <img
                class="f-us-state-flag__flag"
                :src="'/images/us_states_icons/' + svgName + '.svg'"
        />
    </div>
</template>

<script>
    import { US_STATE_BY_CODE } from '@/constants/us_states_constants'

    export default {
        name: "UsStateFlag",
        props: {
            code: {
                type: String,
                required: true,
                validator: v => v in US_STATE_BY_CODE
            }
        },
        computed: {
            svgName() {
                return US_STATE_BY_CODE[this.code].svg
            }
        }
    }
</script>

<style lang="scss">
    .f-us-state-flag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;

        &__flag {
            max-width: 100%;
            flex-grow: 1;
        }
    }
</style>