import {ref, onBeforeUnmount} from '@vue/composition-api'

const useDragWorkflow = (props, {root, refs}) => {
    const SCALE_MULTIPLIER = 1.1
    const TAB_SCALE_DURATION = 100
    const DRAGGABLE_TAB_OUTING_OFFSET = 10
    const TIMEOUT_HOLDING_MOUSEDOWN_TO_START_DRAGGING = 1000

    root.draggableEventBus.$on('mousemove', mouseMoved)
    root.draggableEventBus.$on('mouseup', mouseUpped)
    onBeforeUnmount(() => {
        root.draggableEventBus.$off('mousemove', mouseMoved)
    })

    let mouseDownTimestamp = undefined
    let preparedStartDraggingFunc = undefined

    const isDragging = ref(false)
    let draggablePageTabIndex = undefined
    const currentDraggablePageId = ref(undefined)
    let draggablePageTabTranslatable = undefined
    let draggablePageTabScalable = undefined
    let draggablePageTabInitialBounding = undefined
    let draggableTabTopOffset = undefined

    let draggableTabListPosition = undefined

    let resetMissingOffsetCompensator = undefined
    let mainPagesTabContainerInitialScrollTop = undefined


    let lastUpdatedMouseTop = undefined

    function recursiveScroll(initialTopValue, scrollBottom) {
        if (initialTopValue === lastUpdatedMouseTop) {
            refs.mainPagesWrapper.scrollBy({left: 0, top: (scrollBottom ? 1 : -1) * 1, behavior: 'instant'})
            requestAnimationFrame(() => recursiveScroll(initialTopValue, scrollBottom))
        }
    }

    function mouseMoved({fixedTop: top}) {
        lastUpdatedMouseTop = top

        if (mouseDownTimestamp && !isDragging.value && preparedStartDraggingFunc)
            return preparedStartDraggingFunc()
        if (!isDragging.value) return

        const mainPagesWrapperBounding = root.Utils.getFixedBoundingRect(refs.mainPagesWrapper)
        if (top - draggableTabTopOffset * SCALE_MULTIPLIER + DRAGGABLE_TAB_OUTING_OFFSET <= mainPagesWrapperBounding.top)
            draggablePageTabTranslatable.style.transform = `translate3d(0, ${mainPagesWrapperBounding.top - draggablePageTabInitialBounding.top - DRAGGABLE_TAB_OUTING_OFFSET}px, 0)`
        else if (top + (draggablePageTabInitialBounding.height - draggableTabTopOffset) * SCALE_MULTIPLIER - DRAGGABLE_TAB_OUTING_OFFSET >= mainPagesWrapperBounding.bottom)
            draggablePageTabTranslatable.style.transform = `translate3d(0, ${mainPagesWrapperBounding.bottom - draggablePageTabInitialBounding.bottom + DRAGGABLE_TAB_OUTING_OFFSET}px, 0)`
        else
            draggablePageTabTranslatable.style.transform = `translate3d(0, ${top - draggablePageTabInitialBounding.top - draggableTabTopOffset}px, 0)`

        const tabCenterPosition = top - draggableTabTopOffset + draggablePageTabInitialBounding.height / 2
        let _draggableTabListPosition = undefined

        refs.pageTab.forEach((tabElement, index) => {
            if (index !== draggablePageTabIndex) {
                const bounding = root.Utils.getFixedBoundingRect(tabElement)
                const isDraggableTabHigher = (bounding.top + bounding.height / 2) <= tabCenterPosition

                if (!isDraggableTabHigher && _draggableTabListPosition === undefined) {
                    if (index > draggablePageTabIndex)
                        _draggableTabListPosition = index - 1
                    else
                        _draggableTabListPosition = index
                }

                if (index < draggablePageTabIndex && isDraggableTabHigher)
                    tabElement.style.transform = ''
                else if (index < draggablePageTabIndex && !isDraggableTabHigher)
                    tabElement.style.transform = `translate3d(0, ${bounding.height}px, 0)`
                else if (index > draggablePageTabIndex && isDraggableTabHigher)
                    tabElement.style.transform = `translate3d(0, ${-bounding.height}px, 0)`
                else if (index > draggablePageTabIndex && !isDraggableTabHigher)
                    tabElement.style.transform = ''
            }
        })

        draggableTabListPosition = _draggableTabListPosition === undefined ? refs.pageTab.length - 1 : _draggableTabListPosition

        const SCROLL_AREA_OFFSET = 50

        if (refs.mainPagesWrapper.scrollHeight > refs.mainPagesWrapper.clientHeight) {
            if (top > mainPagesWrapperBounding.bottom - SCROLL_AREA_OFFSET)
                recursiveScroll(top, true)
            else if (top < mainPagesWrapperBounding.top + SCROLL_AREA_OFFSET)
                recursiveScroll(top, false)
        }
    }

    function mouseUpped() {
        mouseDownTimestamp = undefined

        if (isDragging.value) {
            isDragging.value = false
            draggablePageTabTranslatable.style.transition = `transform ${TAB_SCALE_DURATION}ms linear`
            draggablePageTabTranslatable.style.transform = `translate3d(0, ${draggablePageTabInitialBounding.height * (draggableTabListPosition - draggablePageTabIndex) - (refs.mainPagesWrapper.scrollTop - mainPagesTabContainerInitialScrollTop)}px, 0)`
            draggablePageTabScalable.style.transform = ''

            setTimeout(() => {
                currentDraggablePageId.value = false
                resetMissingOffsetCompensator?.()
                root.$store.dispatch('edit/pages/moveFormPage', {
                    prevIndex: draggablePageTabIndex,
                    newIndex: draggableTabListPosition
                })
            }, TAB_SCALE_DURATION)
        }
    }

    const startDragging = (event, index, pageId) => {

        isDragging.value = true
        draggablePageTabIndex = index
        draggablePageTabTranslatable = refs.pageTab[index]
        draggablePageTabInitialBounding = root.Utils.getFixedBoundingRect(draggablePageTabTranslatable)
        draggablePageTabTranslatable.style.width = draggablePageTabInitialBounding.width + 'px'
        currentDraggablePageId.value = pageId

        const mainPagesWrapperBounding = root.Utils.getFixedBoundingRect(refs.mainPagesWrapper)
        draggablePageTabTranslatable.style.marginTop = draggablePageTabInitialBounding.top - mainPagesWrapperBounding.top + 'px'

        if ((index + 1) in refs.pageTab) {
            refs.pageTab[index + 1].style.marginTop = draggablePageTabInitialBounding.height + 'px'
            resetMissingOffsetCompensator = () => refs.pageTab[index + 1].style.marginTop = ''
        } else if ((index - 1) in refs.pageTab) {
            refs.pageTab[index - 1].style.marginBottom = draggablePageTabInitialBounding.height + 'px'
            resetMissingOffsetCompensator = () => refs.pageTab[index - 1].style.marginBottom = ''
        } else {
            refs.mainPagesWrapper.style.height = draggablePageTabInitialBounding.height + 'px'
            resetMissingOffsetCompensator = () => refs.mainPagesWrapper.style.height = ''
        }

        mainPagesTabContainerInitialScrollTop = refs.mainPagesWrapper.scrollTop

        draggablePageTabScalable = draggablePageTabTranslatable.firstElementChild
        draggablePageTabScalable.style.transform = `scale(${SCALE_MULTIPLIER})`
        draggableTabTopOffset = SCALE_MULTIPLIER * (event.clientY - draggablePageTabInitialBounding.top)

        root.draggableEventBus.$emit('changecursor', 'grabbing')

        mouseMoved({fixedTop: event.clientY})

        preparedStartDraggingFunc = undefined
    }

    const mouseDownOnMainPageTab = (event, index, pageId) => {
        mouseDownTimestamp = performance.now()
        let localCopyOfMouseDownTimestamp = mouseDownTimestamp
        preparedStartDraggingFunc = () => startDragging(event, index, pageId)

        setTimeout(() => {
            if (localCopyOfMouseDownTimestamp === mouseDownTimestamp && preparedStartDraggingFunc)
                preparedStartDraggingFunc()
        }, TIMEOUT_HOLDING_MOUSEDOWN_TO_START_DRAGGING)
    }

    return {
        isDragging,
        mouseDownOnMainPageTab,
        currentDraggablePageId
    }
}

export default useDragWorkflow