var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": ""
    },
    scopedSlots: _vm._u([{
      key: "editor",
      fn: function () {
        return [_c('VueEditor', {
          attrs: {
            "editor-toolbar": _vm.$options.toolbar
          },
          model: {
            value: _vm.fieldObj.content.text,
            callback: function ($$v) {
              _vm.$set(_vm.fieldObj.content, "text", $$v);
            },
            expression: "fieldObj.content.text"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }