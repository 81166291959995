<template>
    <div style="display: contents">
        <v-dialog
                :value="true"
                hide-overlay
                transition="none"
                eager
                content-class="form-preview-dialog"
                persistent
                no-click-animation
        >
            <template #default>
                <v-overlay
                        absolute
                        class="form-preview-overlay"
                        color="#00061C"
                        opacity="0.7"
                        z-index="1"
                        :value="dialog"
                />
                <v-slide-y-transition>
                    <div style="position: relative; z-index: 5" v-if="dialog" class="form-preview-dialog__container">
                        <div class="form-preview-dialog__header">
                            <div class="form-preview-dialog__live-preview-hint">Live Preview</div>
                            <div>
                                <v-row justify="center" align="center" align-content="center">
                                    <v-col class="d-flex justify-center">
                                        <f-hover-tooltip
                                                v-for="(view, index) in deviceViews"
                                                top
                                                :key="view.type"
                                                :text="view.text"
                                                v-slot="{on}"
                                        >
                                            <div
                                                    class="form-preview-dialog__device-icon-btn"
                                                    :class="selectedView === index && 'form-preview-dialog__device-icon-btn--active'"
                                                    @click="changeDeviceView(index)"
                                                    v-on="on"
                                            >
                                                <v-icon>
                                                    {{ view.icon }}
                                                </v-icon>
                                            </div>
                                        </f-hover-tooltip>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="d-flex flex-row justify-end">
                                <div
                                        v-if="!externalDisabled"
                                        :class="[
                                            'form-preview-dialog__external-link-btn'
                                        ]"
                                        @click="externalOpen"
                                >
                                    <ExternalLinkIcon stroke-width="1.5"/>
                                </div>
                                <div @click="dialog = false; $emit('close')" class="form-preview-dialog__close-btn">
                                    <XIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="form-preview-dialog__form-view-container">
                            <f-aspect-ratio-keeper
                                    :width="deviceViews[selectedView].aspectRatio.w"
                                    :height="deviceViews[selectedView].aspectRatio.h"
                                    depends-on="height"
                            >
                                <div
                                        class="form-preview-dialog__form-view-content"
                                        :class="`form-preview-dialog__form-view-content--${deviceViews[selectedView].type}`"
                                >
                                    <FormslyFormWrapper :width="deviceViews[selectedView].width">
                                        <slot :active="dialog"/>
                                    </FormslyFormWrapper>
                                </div>
                            </f-aspect-ratio-keeper>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>
        </v-dialog>
        <slot name="activator" :on="{click: () => dialog = !dialog}"/>
    </div>
</template>

<script>
    export default {
        name: "FormPreview",
        components: {
            ExternalLinkIcon: () => import('vue-feather-icons/icons/ExternalLinkIcon'),
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
            FormslyFormWrapper: () => import('@/components/FormGenericComponents/FormslyFormWrapper')
        },
        props: {
            externalDisabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            dialog: false,
            deviceViews: [
                {
                    icon: 'mdi-cellphone',
                    text: 'Mobile',
                    width: 350,
                    aspectRatio: {
                        w: 9,
                        h: 16
                    },
                    type: 'Phone'
                },
                {
                    icon: '$desktop',
                    text: 'Desktop',
                    width: 1920,
                    aspectRatio: {
                        w: 16,
                        h: 9
                    },
                    type: 'Computer'
                },
                // {
                //     icon: '$tablet',
                //     text: 'Tablet',
                //     width: 800,
                //     aspectRatio: {
                //         w: 3,
                //         h: 4
                //     },
                //     type: device_types.Tablet
                // },
            ],
            selectedView: 1

        }),
        methods: {
            externalOpen() {
                if (!this.externalDisabled)
                    this.$emit('external-open')
            },
            changeDeviceView(index) {
                this.selectedView = index
                this.$emit('device-change', this.deviceViews[index].type)
            },
        }
    }
</script>

<style lang="scss">
    .form-preview-dialog {
        box-shadow: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-radius: 0 !important;

        &__container {
            height: 90vh;

            display: flex;
            flex-direction: column;
        }

        &__header {
            display: grid;
            grid-template-columns: 2fr 8fr 2fr;
            align-items: center;
            margin: 0 16px 20px;
        }

        &__live-preview-hint {
            font-size: 14px;
            font-weight: 400;
            color: white;
        }

        &__device-icon-btn {
            width: 43px;
            height: 43px;
            border-radius: 1000px;
            display: flex;
            justify-content: center;
            align-items: center;

            .v-icon {
                color: white;
                opacity: 0.5;
            }

            &--active {
                background: rgba(0, 0, 0, 0.37);
                border: 1px solid rgba(255, 255, 255, 0.2);

                .v-icon {
                    opacity: 1;
                }
            }

            &:hover {
                .v-icon {
                    opacity: 1;
                }
            }
        }

        &__external-link-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            color: white;

            &--disabled {
                cursor: default;
                color: var(--v-gray_400-base);
            }
        }

        &__close-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 1000px;
            color: white;
        }


        &__form-view-container {
            display: flex;
            flex-grow: 1;
            min-height: 0;
            align-items: center;
            justify-content: center;
        }

        &__form-view-content {
            overflow-y: hidden;
            position: relative;
            transition-property: width, height, border;
            transition-duration: .2s;
            border-style: solid;
            border-color: var(--v-grey_700-base);
            width: auto;
            height: 100%;
            background-color: #fff;

            .static-form {
              overflow-y: auto;
            }

            &--Phone {
                //width: 420px + 15px * 2;
                //height: 708px;
                border-width: 15px;
                border-radius: 44px;
            }

            &--Computer {
                //width: 1200px;
                //height: 702px;
                border-width: 32px 4px 4px;
                border-radius: 4px;
            }

            &--Tablet {
                //width: 568px;
                //width: auto;
                //height: 773px;
                border-width: 17px;
                border-radius: 22px;
            }
        }
    }

    .form-preview-overlay {
        height: 100vh;
        transition: .2s;
        backdrop-filter: blur(10px) opacity(0);

        .v-overlay__scrim {
            backdrop-filter: blur(10px) opacity(0);

        }

        &.v-overlay--active {
            backdrop-filter: blur(10px) opacity(1);

            .v-overlay__scrim {
                backdrop-filter: blur(10px) opacity(1);

            }
        }
    }
</style>