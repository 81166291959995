<template>
    <div class="matrix-field-view-full-single-answer">
        <div class="matrix-field-view__table-wrapper">
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td
                                v-for="(columnHeader, columnIndex) in sourceObj.content.columns"
                                class="matrix-field-view__column-cell"
                                :key="'column' + columnIndex"
                        >
                            <span>{{columnHeader.text}}</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                            v-for="(rowHeader, rowIndex) in sourceObj.content.rows"
                            :key="'row' + rowIndex"
                    >
                        <td class="matrix-field-view__row-name matrix-field-view__sticky-left-cell">
                            <span>{{rowHeader.text}}</span>
                        </td>
                        <td
                                v-for="(_, columnIndex) in sourceObj.content.columns.length"
                                class="matrix-field-view__column-cell"
                                :key="'row' + rowIndex + 'column' + columnIndex"
                        >
                            <CheckIcon v-if="submissionObj.value[rowIndex].includes(columnIndex)"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { CheckIcon } from 'vue-feather-icons'
    export default {
        name: "FullSingleAnswer__Matrix",
        components: {
            CheckIcon
        },
        props: {
            submissionObj: Object,
            sourceObj: Object
        }
    }
</script>