var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "#0077B5"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.6788 15.6406H11.374V28.875H15.6788V15.6406Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.4045 15.7958C27.3587 15.7814 27.3154 15.7657 27.2673 15.7525C27.2096 15.7393 27.1518 15.7285 27.0929 15.7188C26.8643 15.6731 26.614 15.6406 26.3204 15.6406C23.8107 15.6406 22.219 17.4658 21.6944 18.1708V15.6406H17.3896V28.875H21.6944V21.6562C21.6944 21.6562 24.9477 17.1253 26.3204 20.4531C26.3204 23.4236 26.3204 28.875 26.3204 28.875H30.624V19.9442C30.624 17.9446 29.2537 16.2783 27.4045 15.7958Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.4795 13.8359C14.6423 13.8359 15.585 12.8933 15.585 11.7305C15.585 10.5677 14.6423 9.625 13.4795 9.625C12.3167 9.625 11.374 10.5677 11.374 11.7305C11.374 12.8933 12.3167 13.8359 13.4795 13.8359Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }