import {
    GenericTextFieldConst,
    GenericTextFieldFSPDefinition
} from '@/components/Fields/GenericFieldViews/GenericTextField'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertySelectInput,
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {
    FieldConditionalLogic,
} from '@/components/Fields/FieldConstClasses/conditional_logic'
import { Analytics } from '@/components/Fields/FieldConstClasses/analytics'
import store from '@/store'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import { FieldFSPDefinition } from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import moment from 'moment'
import FSP from '@/constants/fsp_constants'
import constants from "@/constants/constants";


const DATE_PARTS_DIVIDER = {
    type: 'divider',
    content: '/',
    render: h => h('div', {class: 'date-time-field__divider date-time-field__divider--date'})
}
const TIME_PARTS_DIVIDER = {
    type: 'divider',
    content: ':',
    render: h => h('div', {class: 'date-time-field__divider date-time-field__divider--time'})
}
const DISPLAY_PARTS_DIVIDER = {
    type: 'divider',
    content: ' ',
    render: h => h('div', {class: 'date-time-field__divider date-time-field__divider--display'})
}

const applyPadStart = (value, maxLength) => value.toString().padStart(maxLength, '0')

const genValidationForTwoDigitDatePart = (minValue, maxValue) => ({
    valueBefore,
    valueAfter,
    caretPosition,
    key,
    prevent,
    complete
}) => {
    if (valueAfter.length >= 3) {
        valueAfter = valueBefore.slice(0, caretPosition) + key + valueBefore.slice(caretPosition + 1)
    }

    const valueLength = valueAfter.length
    const intValue = parseInt(valueAfter)

    if (valueLength === 1) {
        if (intValue >= Math.floor(maxValue / 10) + 1)
            complete('0' + valueAfter)
    } else if (valueLength === 2) {
        if (minValue <= intValue && intValue <= maxValue) {
            if (caretPosition + 1 >= 2)
                complete(valueAfter)
            else
                complete(valueAfter, false)
        } else
            prevent()
    } else {
        complete(valueBefore)
    }
}

const DISPLAY_PARTS = {
    TIME: 'TIME',
    DATE: 'DATE'
}

const DATE_PARTS = {
    DD: {
        type: 'input',
        PLACEHOLDER: 'DD',
        VALIDATE: genValidationForTwoDigitDatePart(1, 31),
        SET_MOMENT_DURATION: v => moment.duration(v - 1, 'days'),
        EXTRACT_FROM_MOMENT: m => applyPadStart(m.date(), 2)
    },
    MM: {
        type: 'input',
        PLACEHOLDER: 'MM',
        VALIDATE: genValidationForTwoDigitDatePart(1, 12),
        SET_MOMENT_DURATION: v => moment.duration(v - 1, 'months'),
        EXTRACT_FROM_MOMENT: m => applyPadStart(m.month() + 1, 2)
    },
    YYYY: {
        type: 'input',
        PLACEHOLDER: 'YYYY',
        VALIDATE: ({
            valueBefore,
            valueAfter,
            caretPosition,
            key,
            prevent,
            complete
        }) => {
            if (valueAfter.length > 4)
                valueAfter = valueBefore.slice(0, caretPosition) + key + valueBefore.slice(caretPosition + 1)

            if (valueAfter.length === 4) {
                if (caretPosition + 1 >= 4)
                    complete(valueAfter)
                else
                    complete(valueAfter, false)
            } else if (valueAfter.length > 4) {
                prevent()
            }
        },
        SET_MOMENT_DURATION: v => moment.duration(v - 1970, 'years'),
        EXTRACT_FROM_MOMENT: m => applyPadStart(m.year(), 4)
    }
}

const TIME_PARTS = {
    HH: {
        type: 'input',
        PLACEHOLDER: 'HH',
        VALIDATE: genValidationForTwoDigitDatePart(0, 23),
        MOMENT_UNITS_DURATION: 'hours',
        SET_MOMENT_DURATION: v => moment.duration(v, 'hours'),
        EXTRACT_FROM_MOMENT: m => applyPadStart(m.hours(), 2)
    },
    mm: {
        type: 'input',
        PLACEHOLDER: 'mm',
        VALIDATE: genValidationForTwoDigitDatePart(0, 59),
        MOMENT_UNITS_DURATION: 'minutes',
        SET_MOMENT_DURATION: v => moment.duration(v, 'minutes'),
        EXTRACT_FROM_MOMENT: m => applyPadStart(m.minutes(), 2)
    }
}

const DEFAULT_TIME_ORDER = [
    TIME_PARTS.HH,
    TIME_PARTS.mm
]

class DatetimeFieldTypeConst extends GenericTextFieldConst {
    constructor() {
        super({
            FIELD_TYPE: 'DATETIME',
            READABLE_NAME: 'Date & time',
            ICON_NAME: 'datetime',
            FOLDER_NAME: 'Datetime',

            CONDITIONAL_LOGIC: new FieldConditionalLogic({
                EXPRESSION_COMPONENT_NAME: 'Datetime',
                VALIDATE: (conditionObj, {value}) => {
                    const operator = conditionObj.expression.operator
                    let statementDate = conditionObj.expression.statement
                    let selectedByUserDate = value

                    if (!value)
                        return false

                    if (operator === 'is') {
                        return statementDate === selectedByUserDate
                    } else if (operator === 'before') {
                        return statementDate > selectedByUserDate
                    } else if (operator === 'after') {
                        return statementDate < selectedByUserDate
                    }
                }
            }),

            useGenericEditableView: false,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: {
                        ...FieldSubmitValidation.REQUIRED(fieldObj)
                    }
                })
            }),

            UTIL: {
                DISPLAY_PARTS,
                DEFAULT_TIME_ORDER,
                DISPLAYS: {
                    date_only: {
                        PARTS_ORDER: [
                            DISPLAY_PARTS.DATE
                        ]
                    },
                    time_only: {
                        PARTS_ORDER: [
                            DISPLAY_PARTS.TIME
                        ]
                    },
                    date_and_time: {
                        PARTS_ORDER: [
                            DISPLAY_PARTS.DATE,
                            DISPLAY_PARTS.TIME
                        ]
                    }
                },
                DATE_FORMATS: {
                    yyyy_mm_dd: {
                        PARTS_ORDER: [
                            DATE_PARTS.YYYY,
                            DATE_PARTS.MM,
                            DATE_PARTS.DD,
                        ]
                    },
                    dd_mm_yyyy: {
                        PARTS_ORDER: [
                            DATE_PARTS.DD,
                            DATE_PARTS.MM,
                            DATE_PARTS.YYYY,
                        ]
                    },
                    mm_dd_yyyy: {
                        PARTS_ORDER: [
                            DATE_PARTS.MM,
                            DATE_PARTS.DD,
                            DATE_PARTS.YYYY,
                        ]
                    }
                },
                GEN_INPUT_LAYOUT(fieldObj) {
                    return this.DISPLAYS[fieldObj.properties.display].PARTS_ORDER.reduce((layoutAccum, displayPart) => {
                        let result = []

                        const genResultFromPartsOrder = (partsOrder, divider) => partsOrder
                            .reduce((accum, datePart, index, self) => {
                                const result = [...accum, datePart]
                                if (index !== self.length - 1)
                                    result.push(divider)

                                return result
                            }, [])

                        if (displayPart === DISPLAY_PARTS.DATE)
                            result = genResultFromPartsOrder(this.DATE_FORMATS[fieldObj.properties.date_format].PARTS_ORDER, DATE_PARTS_DIVIDER)
                        else if (displayPart === DISPLAY_PARTS.TIME)
                            result = genResultFromPartsOrder(DEFAULT_TIME_ORDER, TIME_PARTS_DIVIDER)

                        return [...layoutAccum, ...(layoutAccum.length ? [DISPLAY_PARTS_DIVIDER] : []), ...result]
                    }, [])
                },
                GEN_ALL_INPUTS_WITH_ORDER(fieldObj) {
                    return this.GEN_INPUT_LAYOUT(fieldObj).filter(({type}) => type === 'input')
                },
                GEN_READABLE(fieldObj, value) {
                    if (value !== null) {
                        return this.GEN_INPUT_LAYOUT(fieldObj).map(element => {
                            if (element.type === 'input') {
                                return element.EXTRACT_FROM_MOMENT(moment.unix(value).utc())
                            } else if (element.type === 'divider') {
                                return element.content
                            }
                        }).join('')
                    }
                    return value
                },
                GEN_READABLE_FROM_SUBMISSION_VALUE(fieldObj, submissionObj) {
                    return this.GEN_READABLE(fieldObj, submissionObj.value)
                },
                GEN_PARTS_BIND_FOR_DATETIME_PICKER(fieldObj) {
                    let date, time = date = false
                    const display = fieldObj.properties.display

                    if (['date_only', 'date_and_time'].includes(display))
                        date = true

                    if (['time_only', 'date_and_time'].includes(display))
                        time = true

                    return {
                        date,
                        time
                    }
                }
            },

            FSP: [
                GenericTextFieldFSPDefinition,
                GenericTextFieldFSPDefinition.FSPDefinitionForClickablePrependIcon,
                new FieldFSPDefinition({
                    FSP_FIELD_TYPE: 'DateTime',
                    FIELD_VIEW_CLASS: 'date-time-field',
                    VARIABLES: [
                        {
                            selector: ['divider'],
                            properties: {
                                color: {
                                    'default--static': {
                                        fn: 'black'
                                    },
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    }
                                },
                                ...(() => {
                                    const propertiesToExtract = [
                                        'font-weight',
                                        'line-height',
                                        'font-size',
                                    ]

                                    return Object.fromEntries(
                                        propertiesToExtract.map(p => [p, GenericTextFieldFSPDefinition.INPUT_ELEMENT_PROPERTIES[p]])
                                    )
                                })()
                            }
                        },

                        {
                            selector: ['divider--display'],
                            properties: {
                                'min-width': {
                                    default: {
                                        fn: '20px'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['divider--date'],
                            properties: {
                                'min-width': {
                                    'default--static': {
                                        fn: '10px'
                                    },
                                    'default--interactive': {
                                        fn: '20px'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['divider--time'],
                            properties: {
                                'min-width': {
                                    'default--static': {
                                        fn: '10px'
                                    },
                                    'default--interactive': {
                                        fn: '20px'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['input-placeholder'],
                            properties: GenericTextFieldFSPDefinition.INPUT_ELEMENT_PROPERTIES
                        },

                        /** MENU WITH PICKERS **/
                        {
                            selector: ['picker-menu .f-date-time-picker .v-tabs .v-tabs-bar'],
                            properties: {
                                'color': {
                                    'default--static': {
                                        important: true,
                                        fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply(),
                                    },
                                    'default--interactive': {
                                        important: true,
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    }
                                }
                            }
                        },
                        {
                            selector: [
                                'picker-menu .f-date-time-picker .v-picker ',
                                {
                                    oneOf: [
                                        '.v-picker__title',
                                        '.v-time-picker-clock .v-time-picker-clock__item--active',
                                        '.v-time-picker-title'
                                    ]
                                }
                            ],
                            properties: {
                                ...(() => {
                                    const states = {
                                        'default--interactive': {
                                            important: true,
                                            fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_COLOR.apply(),
                                        },
                                        'default--static': {
                                            important: true,
                                            fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply()
                                        }
                                    }

                                    const properties = [
                                        'background-color',
                                        'border-color'
                                    ]

                                    return Object.fromEntries(properties.map(p => [p, states]))
                                })(),
                                color: {
                                    "default--interactive": {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    },
                                    'default--static': {
                                        fn: FSP.PRIMARY_VARIABLES.BUTTON_TEXT_COLOR.apply()
                                    },
                                }
                            }
                        },
                        {
                            selector: [
                                'picker-menu .f-date-time-picker .v-picker ',
                                {
                                    oneOf: [
                                        '.v-date-picker-table table .v-btn.v-btn--active',
                                        '.v-time-picker-clock .v-time-picker-clock__hand',
                                    ]
                                }
                            ],
                            properties: {
                                ...(() => {
                                    const states = {
                                        'default--static': {
                                            important: true,
                                            fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply(),
                                        },
                                        'default--interactive': {
                                            important: true,
                                            fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_COLOR.apply()
                                        }
                                    }

                                    const properties = [
                                        'background-color',
                                        'border-color'
                                    ]

                                    return Object.fromEntries(properties.map(p => [p, states]))
                                })(),
                                color: {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    },
                                    'default--static': {
                                        fn: FSP.PRIMARY_VARIABLES.BUTTON_TEXT_COLOR.apply()
                                    }
                                }
                            }
                        },
                        {
                            selector: ['picker-menu .f-date-time-picker .v-picker .v-date-picker-header .v-date-picker-header__value > div'],
                            properties: {
                                'color': {
                                    'default--static': {
                                        important: true,
                                        fn: 'var(--v-accent-base)',
                                    },
                                    'default--interactive': {
                                        important: true,
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.5})
                                    }
                                }
                            }
                        },
                        {
                            selector: [
                                'picker-menu .f-date-time-picker .v-picker ',
                                {
                                    oneOf: [
                                        '.v-date-picker-years li.active',
                                        '.v-time-picker-clock__ampm'
                                    ]
                                }
                            ],
                            properties: {
                                'color': {
                                    'default--static': {
                                        important: true,
                                        fn: '#000',
                                    },
                                    'default--interactive': {
                                        important: true,
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    }
                                }
                            }
                        }
                    ]
                })
            ],

            ANALYTICS: new Analytics({
                SORT: ({value: a}, {value: b}) => a - b,
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                    return constants.FIELD_TYPES.DATETIME.UTIL.GEN_READABLE_FROM_SUBMISSION_VALUE(fieldObj, submissionObj)
                },
                FILTER: false,
                SHORT_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/Datetime/Analytics/GenericDatetimeSingleAnswer'),
                FULL_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/Datetime/Analytics/GenericDatetimeSingleAnswer')
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'display',
                    name: 'Display',
                    caption: 'Choose what kind of date you want to collect',
                    always_enabled: true,
                    enabled_by_default: true,
                    validator: (newValue, fieldId, cb) => {
                        if ('condition' in store.getters['edit/getFormJSON'].fields[fieldId]) {
                            store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                                header: 'Change Display format',
                                text: 'This block is controlled by a conditional logic, by changing "Display" property it will remove the conditional logic applied to this block',
                                confirmBtnText: 'Yes, change Display',
                                callback: () => store.dispatch('edit/conditional/removeConditionForField', fieldId).then(cb)
                            })
                            return false
                        }

                        return true
                    },
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySelectInput({
                            items: [
                                {key: 'date_only', readable_name: 'Date Only'},
                                {key: 'time_only', readable_name: 'Time Only'},
                                {key: 'date_and_time', readable_name: 'Date and Time'},
                            ]
                        })
                    ),
                }),
                new CustomFieldPropertyConst({
                    key: 'date_format',
                    name: 'Date Format',
                    caption: 'Choose how the date will be displayed',
                    always_enabled: true,
                    enabled_by_default: true,
                    existence_condition: fieldProperties => ['date_only', 'date_and_time'].includes(fieldProperties.display),
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySelectInput({
                            items: [
                                {key: 'mm_dd_yyyy', readable_name: 'MM-DD-YYYY'},
                                {key: 'dd_mm_yyyy', readable_name: 'DD-MM-YYYY'},
                                {key: 'yyyy_mm_dd', readable_name: 'YYYY-MM-DD'},
                            ]
                        })
                    )
                })
            ])
        })
    }
}

export default new DatetimeFieldTypeConst()