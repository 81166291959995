<template>
    <GenericSelectionSummaryFieldView
            v-bind="$attrs"
            :optionsFormatter="optionsFormatter"
    />
</template>

<script>

    export default {
        name: "Summary__GenericSelection",
        components: {
            GenericSelectionSummaryFieldView: () => import('@/components/Fields/AnalyticsField/Summary/GenericSelectionSummaryFieldView'),
        },
        methods: {
            optionsFormatter(fieldObj) {
                return fieldObj.content.options;
            }
        },
    }
</script>