var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PropsRenderer', {
    attrs: {
      "vnodes": _vm.shown
    }
  }), !_vm.showAll && _vm.$slots.default.length > _vm.limitation ? _c('div', {
    staticClass: "action action--show"
  }, [_c('InfoIcon', {
    attrs: {
      "size": "20"
    }
  }), _c('p', [_vm._v("You see options that got response here. But you can check "), _c('span', {
    staticClass: "action__show-button",
    on: {
      "click": function ($event) {
        _vm.showAll = true;
      }
    }
  }, [_vm._v("All Options")])])], 1) : _vm._e(), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAll,
      expression: "showAll"
    }]
  }, [_c('PropsRenderer', {
    attrs: {
      "vnodes": _vm.hidden
    }
  })], 1)]), _vm.showAll && _vm.$slots.default.length > _vm.limitation ? _c('span', {
    staticClass: "action action--hide",
    on: {
      "click": function ($event) {
        _vm.showAll = false;
      }
    }
  }, [_vm._v("Hide")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }