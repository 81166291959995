var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFrontView', {
    attrs: {
      "field-properties": _vm.fieldProperties,
      "model": _vm.submissionObj.value
    },
    on: {
      "select-option": _vm.selectOption
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }