var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.cover ? _c('span', {
    attrs: {
      "id": "cover"
    }
  }) : _vm._e(), _c('span', {
    style: _vm.style,
    attrs: {
      "id": "draggable-node"
    }
  }, [_c('span', {
    attrs: {
      "id": "draggable-component"
    }
  }, [_vm.draggableComponent ? _c(_vm.draggableComponent, _vm._b({
    tag: "component"
  }, 'component', _vm.draggableComponentBind, false)) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }