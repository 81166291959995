import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import FSP from '@/constants/fsp_constants'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import {Analytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyEmojiSetSelectInput,
    FieldPropertySingleEmojiSelectInput
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'

class StarRatingFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'STAR_RATING',
            READABLE_NAME: 'Star rating',
            ICON_NAME: 'star_rating',
            FOLDER_NAME: 'StarRating',

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                    const isEmojiScale = fieldObj.properties.emoji_scale !== null

                    if (isEmojiScale)
                        return submissionObj.value;

                    return submissionObj.value !== null ? `${submissionObj.value + 1}/5` : submissionObj.value
                }
            }),

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            IS_SUBMISSION_EMPTY: ({value}) => value === null,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            AUTO_SCROLL_HANDLER: submissionObj => submissionObj.value !== null,

            FSP: new FieldFSPDefinition({
                FSP_FIELD_TYPE: 'StarRating',
                FIELD_VIEW_CLASS: 'star-rating-field-view',
                GENERIC_STATES_CONNECTORS: {
                    'emoji-scale': {
                        field: '.star-rating-field-view--emoji-scale'
                    },
                    'scale-icon': {
                        field: '.star-rating-field-view--scale-icon'
                    },
                },
                VARIABLES: [
                    {
                        selector: ['layout'],
                        properties: {
                            'column-gap': {
                                'default--emoji-scale': {
                                    fn: '16px'
                                }
                            },
                            'font-size': {
                                'default--emoji-scale': {
                                    fn: '32px',
                                }
                            },
                        }
                    },
                    {
                        selector: ['item'],
                        properties: {
                            'border-radius': {
                                'default--emoji-scale': {
                                    fn: '100px',
                                }
                            }
                        }
                    },
                    {
                        selector: ['item:hover'],
                        properties: {
                            scale: {
                                'default--submittable--emoji-scale': {
                                    fn: 1.3,
                                }
                            }
                        }
                    },
                    {
                        selector: ['item-wrapper'],
                        properties: {
                            'flex-grow': {
                                'default--is-scale-icon': {
                                    fn: 1,
                                }
                            },
                            'max-width': {
                                'default--s-scale-icon': {
                                    fn: '72px',
                                }
                            },
                        }
                    },
                    {
                        selector: ['item--selected'],
                        properties: {
                            'background-color': {
                                'emoji-scale--interactive': {
                                    fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                                        asColors: {
                                            true: [0, 0, 0, 0],
                                            false: [0, 0, 0, 0.2]
                                        }
                                    }),
                                },
                            },
                            'box-shadow': {
                                'emoji-scale--interactive': {
                                    fn: `0 0 0 2px ${FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply()}`,
                                },
                                'emoji-scale--static': {
                                    fn: '0 0 0 2px #1676F3',
                                },
                            },
                        }
                    },
                    {
                        selector: ['item'],
                        properties: {
                            'padding-left': {
                                'default--is-emoji-scale': {
                                    fn: '1px',
                                },
                            },
                            width: {
                                'default--is-emoji-scale': {
                                    fn: '52px'
                                }
                            },
                            height: {
                                'default--emoji-scale': {
                                    fn: '52px'
                                }
                            },
                        }
                    },
                    {
                        selector: ['item img'],
                        properties: {
                            width: {
                                'default--emoji-scale': {
                                    fn: '30px'
                                }
                            }
                        }
                    },
                    {
                        selector: ['item svg'],
                        properties: {
                            stroke: {
                                'default--scale-icon--interactive': {
                                    fn: 'rgba(0, 0, 0, 0.2)',
                                }
                            },
                            'stroke-width': {
                                'default--scale-icon--interactive': {
                                    fn: '10px',
                                }
                            },
                        }
                    },
                ]
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'emoji_scale',
                    name: 'Emoji scale',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyEmojiSetSelectInput({
                            sets: [
                                ['😄', '😊', '😐', '😟', '😩'],
                                ['😡', '😟', '😊', '😇', '😍'],
                                ['👍', '👎'],
                                ['✅', '❎']
                            ]
                        })
                    )
                }),
                new CustomFieldPropertyConst({
                    key: 'scale_icon',
                    name: 'Scale Icons',
                    always_enabled: true,
                    enabled_by_default: true,
                    existence_condition: fieldProperties => fieldProperties.emoji_scale === null,
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySingleEmojiSelectInput({
                            emojis: [
                                () => import('@/components/Fields/FieldsViews/StarRating/Icons/star_data'),
                                () => import('@/components/Fields/FieldsViews/StarRating/Icons/heart_data'),
                                () => import('@/components/Fields/FieldsViews/StarRating/Icons/lightning_data'),
                                () => import('@/components/Fields/FieldsViews/StarRating/Icons/like_data'),
                            ]
                        })
                    )
                })
            ])
        })
    }
}

export default new StarRatingFieldTypeConst()