var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "linear-loader-container"
  }, [_c('div', {
    staticClass: "progress-linear__image"
  }, [_vm._t("default")], 2), _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#5D6471"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }