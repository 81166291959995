import Utils from '@/utils/misc'
import constants from '@/constants/constants'

import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { VBtn, VSpacer } from 'vuetify/lib/components'
import { FieldsEnumerationGettersUtils } from '@/store/util_modules/fields_enumeration_getters'

export default ({useExtension}) => {
    {
        const state = {
            current_conditional_field_id: undefined,
            conditional_logic_editor_model: false
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }

    return {
        modules: {
            confirmation_dialog: generic_dialog_module
        },
        actions: {
            openConditionalLogicEditor: ({commit}, fieldID) => {
                commit('SET_CURRENT_CONDITIONAL_FIELD_ID', fieldID)
                commit('SET_CONDITIONAL_LOGIC_EDITOR_MODEL', true)
            },
            closeConditionalLogicEditor: ({commit}) => {
                commit('SET_CURRENT_CONDITIONAL_FIELD_ID', undefined)
                commit('SET_CONDITIONAL_LOGIC_EDITOR_MODEL', false)
            },
            updateConditionForField: ({parentDispatch, parentGetters}, {fieldID, condition}) => {
                parentDispatch('fields/changeFormField', {
                    fieldID,
                    fieldObj: {
                        ...parentGetters.getFormJSON.fields[fieldID],
                        condition
                    }
                })
            },
            removeConditionForField: ({parentDispatch, parentGetters}, fieldID) => {
                const fieldObj = Utils.deepCloneObject(parentGetters.getFormJSON.fields[fieldID])
                delete fieldObj.condition
                parentDispatch('fields/changeFormField', {
                    fieldID,
                    fieldObj
                })
            },

            openConfirmVisibilityChangeDialog: ({dispatch}, {header, text, confirmBtnText, callback}) => dispatch('confirmation_dialog/showDialog', ({closeDialog}) => ({
                title: header,
                content: text,
                actions: {
                    functional: true,
                    render: h => [
                        h(VSpacer),
                        h(VBtn, {props: {color: 'gray_100', rounded: true}, on: {click: closeDialog}}, 'Not now'),
                        h(VBtn, {props: {color: 'blue_400', rounded: true}, on: {
                            click: () => {
                                closeDialog()
                                callback()
                            }
                        }}, confirmBtnText),
                    ]
                }
            })),
        },
        getters: {
            getConditionalField: ({getters, parentGetters}) => parentGetters.getFormJSON?.fields[getters.getCurrentConditionalFieldId],
            getFieldCondition: ({getters}) => getters.getConditionalField?.condition,
            getConditionalFieldConst: ({getters}) => constants.FIELD_FROM_TYPE?.[getters.getConditionalField?.field_type],

            getTargetedAndConditionedFields: ({parentGetters}) => {
                const toReturn = Utils.deepCloneObject(parentGetters[FieldsEnumerationGettersUtils.allFieldsWithEnumerationGetterName])
                Object.entries(parentGetters[FieldsEnumerationGettersUtils.allFieldsWithEnumerationGetterName])
                    .forEach(([fieldID, {fieldObj}]) => {
                        if (fieldObj.condition) {
                            toReturn[fieldID].conditioned = true

                            if (fieldObj.condition?.if_target?.field_id) {
                                toReturn[fieldObj.condition.if_target.field_id].targeted = true
                                toReturn[fieldObj.condition.if_target.field_id].targeted_if = true
                                toReturn[fieldObj.condition.if_target.field_id].target_owner = fieldID
                            }
                            if (fieldObj.condition?.else_target?.field_id) {
                                toReturn[fieldObj.condition.else_target.field_id].targeted = true
                                toReturn[fieldObj.condition.else_target.field_id].targeted_else = true
                                toReturn[fieldObj.condition.else_target.field_id].target_owner = fieldID
                            }
                        }
                    })

                return toReturn
            }
        }
    }
}