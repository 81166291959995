var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-field-view-full-single-answer"
  }, [_vm.isAddressFieldEmpty ? _c('span', [_vm._v("-")]) : _vm._l(_vm.getActiveAddressFields, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "address-field-view-full-single-answer__item"
    }, [_c('span', [_vm._v(_vm._s(key[0].toUpperCase() + key.slice(1)) + ": ")]), key === 'country' ? [_c('span', [_vm._v(_vm._s(_vm.constants.COUNTRY_NAMES[value]))]), _c('f-country-flag', {
      attrs: {
        "size": "l",
        "code": value
      }
    })] : _c('span', [_vm._v(_vm._s(value))])], 2);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }