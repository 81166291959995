var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-main-page-fields-layout static-main-page-fields-layout--submittable"
  }, _vm._l(_vm.getCurrentPage.rows, function (row, rowIndex) {
    return _c('v-row', {
      key: rowIndex,
      style: rowIndex === 0 ? {
        'padding-top': '30px'
      } : {}
    }, _vm._l(row.fields, function (field) {
      return _c('v-col', {
        key: field.field_id,
        attrs: {
          "cols": field.width * (12 / _vm.constants.STATIC_FORM_COLS_COUNT)
        }
      }, [!(field.field_id in _vm.getFieldsWithExistenceStatus) || _vm.getFieldsWithExistenceStatus[field.field_id].exist ? _c('SubmittableStaticFieldConstructor', {
        attrs: {
          "field-id": field.field_id,
          "field-width": field.width
        }
      }) : _vm._e()], 1);
    }), 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }