import SharedView from "@/components/Fields/FieldsViews/Signature/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__Signature',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    preview: true,
                    placeholder: ctx.props.fieldObj.content.placeholder,
                }
            }
        )
    }
}