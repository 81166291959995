var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "#26D367"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.4867 10.5121C14.8954 10.7762 10.4831 15.395 10.5 20.9474C10.5052 22.6385 10.9168 24.2347 11.6429 25.645L10.5281 31.0125C10.4678 31.3029 10.7318 31.5571 11.0226 31.4888L16.3249 30.2427C17.6872 30.9158 19.2173 31.3046 20.8367 31.3291C26.5515 31.4157 31.3166 26.9146 31.4948 21.2481C31.6857 15.174 26.6317 10.2219 20.4867 10.5121ZM26.8135 26.6817C25.2607 28.222 23.196 29.0702 21 29.0702C19.7142 29.0702 18.4831 28.7841 17.3409 28.2197L16.6024 27.8549L13.3516 28.6188L14.0359 25.3239L13.6721 24.6172C13.0791 23.4654 12.7784 22.2198 12.7784 20.9151C12.7784 18.7368 13.6336 16.6889 15.1865 15.1486C16.7254 13.6221 18.8237 12.76 21.0002 12.76C23.1962 12.76 25.2607 13.6083 26.8136 15.1485C28.3664 16.6888 29.2216 18.7367 29.2216 20.915C29.2216 23.0739 28.3525 25.1552 26.8135 26.6817Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.0962 23.1735L24.0624 22.5943C23.795 22.5181 23.5071 22.5934 23.3122 22.7903L22.8149 23.2929C22.6051 23.5049 22.2869 23.573 22.0094 23.4616C21.0472 23.0754 19.0234 21.2904 18.5065 20.3976C18.3574 20.1401 18.3821 19.8181 18.5651 19.5831L18.9994 19.0259C19.1695 18.8076 19.2054 18.5144 19.0929 18.262L18.2372 16.3423C18.0322 15.8825 17.4399 15.7487 17.0529 16.0733C16.4853 16.5495 15.8118 17.2732 15.7299 18.0749C15.5855 19.4884 16.1967 21.2702 18.5077 23.4097C21.1775 25.8814 23.3155 26.208 24.7075 25.8735C25.4971 25.6838 26.1281 24.9233 26.5263 24.3007C26.7978 23.8761 26.5834 23.3123 26.0962 23.1735Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }