import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import {GenericOptionsConditionalLogic} from '@/components/Fields/FieldConstClasses/conditional_logic'
import FSP from '@/constants/fsp_constants'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import {Analytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import store from '@/store'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import {
    GenericInteractiveTransparentBlurryCardFSP
} from '@/components/Fields/GenericFieldViews/GenericInteractiveTransparentBlurryCardFSP'
import Utils from '@/utils/misc'

class ImageChoiceFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'IMAGE_CHOICE',
            READABLE_NAME: 'Image choice',
            ICON_NAME: 'image_choice',
            FOLDER_NAME: 'ImageChoice',

            CONDITIONAL_LOGIC: new GenericOptionsConditionalLogic({
                PARAMS: {
                    MULTIPLE: fieldObj => fieldObj.properties.multiple_selection,
                    VALUE_FORMATTER: fieldObj => fieldObj.content.options.map((option, index) => ({
                        index,
                        text: option.label || ''
                    }))
                },
                VALIDATE: (conditionObj, submissionObj) => GenericOptionsConditionalLogic.DEFAULT_VALIDATE(conditionObj, {value: submissionObj.selected_options})
            }),

            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    options: [{image: null, label: ''}]
                }
            }),

            SUBMISSION_TEMPLATE: () => ({
                selected_options: []
            }),

            AUTO_SCROLL_HANDLER: (submissionObj, sourceObj) => !sourceObj.properties.multiple_selection && submissionObj.selected_options.length,

            IS_SUBMISSION_EMPTY: ({selected_options}) => !selected_options?.length,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    selected_options: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            FSP: [
                GenericInteractiveTransparentBlurryCardFSP,
                (() => {
                    const CARD_WRAPPER_PADDING = '10px'
                    const CARD_WRAPPER_BORDER_RADIUS = FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                        px: true,
                        max: 12
                    })
                    const FLOATING_EL_OFFSET = '8px'
                    const FLOATING_EL_SIZE = '24px'

                    const CARD_INSET_OFFSET = '12px'


                    const IMAGE_CONTENT_Z_INDEX = 7
                    const FLOATING_EL_Z_INDEX = IMAGE_CONTENT_Z_INDEX + 1
                    const REMOVE_BTN_Z_INDEX = FLOATING_EL_Z_INDEX


                    return new FieldFSPDefinition({
                        FSP_FIELD_TYPE: 'ImageChoice',
                        FIELD_VIEW_CLASS: 'image-choice-field-view',
                        GENERIC_STATES_CONNECTORS: {
                            'generic-option-active': {
                                internal: '.image-choice-field-view__image-choice-card--active'
                            }
                        },
                        VARIABLES: [
                            {
                                selector: ['card-wrapper'],
                                properties: {
                                    padding: {
                                        default: {
                                            fn: CARD_WRAPPER_PADDING
                                        }
                                    },
                                    'max-width': {
                                        default: {
                                            fn: '185px'
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['layout'],
                                properties: {
                                    margin: {
                                        default: {
                                            fn: `calc(-1 * ${CARD_WRAPPER_PADDING})`
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__wrapper'],
                                properties: {
                                    'background-color': {
                                        'default--static': {
                                            fn: '#fff',
                                        },
                                    },
                                    'border-radius': {
                                        default: {
                                            fn: CARD_WRAPPER_BORDER_RADIUS
                                        }
                                    },
                                    'box-shadow': {
                                        'default--static': {
                                            fn: `0px 0px 0px 1.5px var(--v-gray_300-base) inset`
                                        },
                                        'generic-option-active--static': {
                                            fn: `0px 0px 0px 1.5px ${FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply()} inset`
                                        }
                                    },
                                }
                            },
                            {
                                selector: ['image-choice-card__image-wrapper'],
                                properties: {
                                    padding: {
                                        default: {
                                            fn: CARD_INSET_OFFSET
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__image'],
                                properties: {
                                    'border-radius': {
                                        'default--static': {
                                            fn: `calc(${CARD_WRAPPER_BORDER_RADIUS} * (2 / 3))`
                                        },
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                                                px: true,
                                                max: 16,
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__image-edit-icon'],
                                properties: {
                                    "background-color": {
                                        default: {
                                            fn: '#fff',
                                        }
                                    },
                                    "box-shadow": {
                                        default: {
                                            fn: 'box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.19)'
                                        }
                                    },
                                    "border-radius": {
                                        default: {
                                            fn: '50%'
                                        }
                                    },
                                    bottom: {
                                        default: {
                                            fn: '5px',
                                        }
                                    },
                                    right: {
                                        default: {
                                            fn: '5px',
                                        }
                                    },
                                    "z-index": {
                                        default: {
                                            fn: FLOATING_EL_Z_INDEX
                                        }
                                    },
                                    width: {
                                        default: {
                                            fn: '24px'
                                        }
                                    },
                                    height: {
                                        default: {
                                            fn: '24px'
                                        }
                                    },
                                },
                            },
                            {
                                selector: ['image-choice-card__image-content'],
                                properties: {
                                    'z-index': {
                                        default: {
                                            fn: IMAGE_CONTENT_Z_INDEX
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__image-content--empty svg'],
                                properties: {
                                    color: {
                                        default: {
                                            fn: '#8A8F99',
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__anchor-letter-wrapper'],
                                properties: {
                                    'border-radius': {
                                        default: {
                                            fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({px: true})
                                        }
                                    },
                                    'background-color': {
                                        default: {
                                            fn: 'rgba(0, 0, 0, 0.4)'
                                        }
                                    },
                                    top: {
                                        default: {
                                            fn: FLOATING_EL_OFFSET
                                        }
                                    },
                                    left: {
                                        default: {
                                            fn: FLOATING_EL_OFFSET
                                        }
                                    },
                                    width: {
                                        default: {
                                            fn: FLOATING_EL_SIZE
                                        }
                                    },
                                    height: {
                                        default: {
                                            fn: FLOATING_EL_SIZE
                                        }
                                    },
                                    'z-index': {
                                        default: {
                                            fn:FLOATING_EL_Z_INDEX
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__anchor-letter'],
                                properties: {
                                    color: {
                                        default: {
                                            fn: 'white'
                                        }
                                    },
                                    'font-size': {
                                        default: {
                                            fn: '12px'
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 700
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__active-checkbox-wrapper'],
                                properties: {
                                    top: {
                                        default: {
                                            fn: FLOATING_EL_OFFSET
                                        }
                                    },
                                    right: {
                                        default: {
                                            fn: FLOATING_EL_OFFSET
                                        }
                                    },
                                    'z-index': {
                                        default: {
                                            fn: FLOATING_EL_Z_INDEX
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['.f-checkbox .f-checkbox__input-control'],
                                external: true,
                                properties: {
                                    'border-radius': {
                                        'generic-option-active': {
                                            fn: '100px'
                                        }
                                    },
                                    "background-color": {
                                        'generic-option-active': {
                                            fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply(),
                                        }
                                    },
                                    width: {
                                        'generic-option-active': {
                                            fn: FLOATING_EL_SIZE
                                        }
                                    },
                                    height: {
                                        'generic-option-active': {
                                            fn: FLOATING_EL_SIZE
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['.f-checkbox .f-checkbox__input-control .f-checkbox__icon'],
                                external: true,
                                properties: {
                                    color: {
                                        'generic-option-active': {
                                            fn: FSP.PRIMARY_VARIABLES.BUTTON_TEXT_COLOR.apply(),
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__label-wrapper'],
                                properties: {
                                    padding: {
                                        default: {
                                            fn: `0 ${CARD_INSET_OFFSET} ${CARD_INSET_OFFSET}`
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['image-choice-card__label'],
                                properties: {
                                    'text-align': {
                                        default: {
                                            fn: 'left'
                                        }
                                    },
                                    color: {
                                        'default--interactive': {
                                            fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                                        },
                                        'default--static': {
                                            fn: 'black',
                                        }
                                    },
                                    'font-weight': {
                                        default: {
                                            fn: 400
                                        },
                                        'generic-option-active': {
                                            fn: 600
                                        }
                                    },
                                    'font-size': {
                                        default: {
                                            fn: '1rem'
                                        }
                                    },
                                    'line-height': {
                                        default: {
                                            fn: '28px'
                                        }
                                    }
                                }
                            },

                            {
                                selector: ['image-choice-card__remove-btn'],
                                properties: {
                                    'z-index': {
                                        default: {
                                            fn: REMOVE_BTN_Z_INDEX
                                        }
                                    }
                                }
                            },
                            {
                                selector: ['add-option-card'],
                                properties: {
                                    'border-radius': {
                                        'default--editable--static': {
                                            fn: CARD_WRAPPER_BORDER_RADIUS,
                                        },
                                    }
                                }
                            }
                        ]
                    })
                })()
            ],

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: (submissionObj) => {
                    return submissionObj.selected_options.map(optionIndex => Utils.convertIndexToLetter(optionIndex)).join(', ')
                },
                SORT: ({selected_options: a}, {selected_options: b}) => a.length < b.length ? a.length > b.length ? 0 : -1 : 1
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'image_label',
                    name: 'Image Label',
                    enabled_by_default: true
                }),
                // new CustomFieldPropertyConst({
                //     key: 'other_option',
                //     name: '"Other" option',
                //     tooltip: 'Let others add their own response'
                // }),
                new CustomFieldPropertyConst({
                    key: 'multiple_selection',
                    name: 'Multiple Selection',
                    validator: (newValue, fieldId, cb) => {
                        if (newValue === false && store.getters['edit/getFormJSON'].fields[fieldId]?.condition?.expression?.statement?.length > 1) {
                            store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                                header: 'Disable Multiple Selection',
                                text: 'Options of this block are controlled by a conditional logic, by disabling "Multiple Selection" property it will remove the conditional logic applied to this block',
                                confirmBtnText: 'Yes, disable Multiple Selection',
                                callback: () => store.dispatch('edit/conditional/removeConditionForField', fieldId).then(cb)
                            })
                            return false
                        }

                        return true
                    }
                }),
                new CustomFieldPropertyConst({
                    key: 'randomize',
                    name: 'Randomize answers'
                })
            ])
        })
    }
}

export default new ImageChoiceFieldTypeConst()