<template>
    <f-aspect-ratio-keeper width="16" height="9">
        <div
                class="interactive-form-editor-pages-display-manager-wrapper"
                ref="pagesDisplayManagerWrapper"
        >
            <div
                    :class="[
                        'interactive-form-editor-pages-display-manager',
                        getStylePreferencesMenuModel && 'interactive-form-editor-pages-display-manager--zoomed'
                    ]"
                    ref="pagesDisplayManager"
                    :style="{backgroundImage: `url(${getCurrentBackgroundImage})`, ...style}"
            >
                <InteractiveEmptyPage
                        v-if="getCurrentPageId === undefined"
                />
                <InteractiveHomePage
                        v-else-if="getCurrentPage.type === constants.FORM_PAGES_TYPES.HOME_PAGE"
                        :page-id="getCurrentPageId"
                        :page-obj="getFormJSON.all_pages[getCurrentPageId]"
                        :key="getCurrentPageId"
                />
                <InteractiveMainPage
                        v-else-if="getCurrentPage.type === constants.FORM_PAGES_TYPES.MAIN_PAGE"
                        :page-id="getCurrentPageId"
                        :key="getCurrentPageId"
                />
                <InteractiveFinishPage
                        v-else-if="getCurrentPage.type === constants.FORM_PAGES_TYPES.FINISH_PAGE"
                        :key="getCurrentPageId"
                />
            </div>
        </div>
    </f-aspect-ratio-keeper>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import { computed, ref } from '@vue/composition-api'

    export default {
        name: "InteractivePagesDisplayManager",
        components: {
            InteractiveEmptyPage: () => import('./InteractiveEmptyPage'),
            InteractiveHomePage: () => import('./HomePage/InteractiveHomePage'),
            InteractiveMainPage: () => import('./MainPage/InteractiveMainPage'),
            InteractiveFinishPage: () => import('./FinishPage/InteractiveFinishPage'),
        },
        computed: {
            ...mapGetters('edit', [
                'getFormJSON'
            ]),
            ...mapGetters('edit/pages_navigation', [
                'getCurrentPageId',
                'getCurrentPage'
            ]),
            ...mapGetters('edit/background_image_preview', [
                'getCurrentBackgroundImage'
            ]),
            ...mapGetters('edit/FSP_themes', [
                'getStylePreferencesMenuModel'
            ])
        },
        setup(props, {root, refs}) {
            const scale = ref(1)
            const width = ref(null)
            const height = ref(null);

            const handleFormSize = event => {
                const [{borderBoxSize: [{blockSize: localHeight}]}] = event
                const localScale =  localHeight / 765
                scale.value = localScale <= 1 ? localScale : 1
                width.value = localScale > 1 ? '100%' : null;
                height.value = localScale > 1 ? '100%' : null;
            }

            root.Utils.useResizeObserver(handleFormSize).observe(() => refs.pagesDisplayManagerWrapper)

            return {
                style: computed(() => ({width: width.value, height: height.value,transform: `scale(${scale.value})`}))
            }
        },
        created() {
            this.$store.dispatch('edit/pages_navigation/setCurrentPageId', this.getFormJSON.main_pages.length ?
                this.getFormJSON.main_pages[0].page_id : undefined)
        }
    }
</script>

<style lang="scss" scoped>
    .interactive-form-editor-pages-display-manager-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .interactive-form-editor-pages-display-manager {
        position: absolute;
        transform-origin: center;

        width: 1360px;
        height: 765px;

        border-radius: inherit;
        box-shadow: 0px 2px 4px rgba(24, 39, 75, 0.15);

        background-size: cover;
        background-position: center;
        margin: auto;
        //height: 100%;
    }
</style>