var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "dropdown-field-view-editable-dialog",
      "value": _vm.isDialogOpened,
      "max-width": "714"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [[_c('v-card', [_c('v-card-title', [_vm._v(" List Choices "), _c('v-spacer'), _c('XIcon', {
    attrs: {
      "size": "24"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1), _c('v-card-subtitle', [_vm._v(" Add or paste in your choices each on a separate line. ")]), _c('v-card-text', [_c('div', {
    staticClass: "dropdown-field-view-editable-dialog__textarea"
  }, _vm._l(_vm.optionsList, function (option, index) {
    return _c('div', {
      key: index,
      staticClass: "dropdown-field-view-editable-dialog__option"
    }, [_c('span', {
      staticClass: "dropdown-field-view-editable-dialog__option-dot"
    }), _vm.selectedReadyListKey ? _c('span', [_vm._v(_vm._s(option.name))]) : _c('f-template-data-transfer', {
      attrs: {
        "focused": false
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var dataStash = _ref.dataStash;
          return [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref2) {
                var hover = _ref2.hover;
                return [_c('div', {
                  staticClass: "dropdown-field-view-editable-dialog__option-value"
                }, [_c('f-simple-contenteditable-input', {
                  ref: "optionNameInput",
                  refInFor: true,
                  attrs: {
                    "placeholder": index === 0 && _vm.options.length === 1 ? 'Start typing or choose one of the ready lists below' : '',
                    "prevent-highlighting": "",
                    "overflow-wrap": "",
                    "mask": /^.{0,60}$/g
                  },
                  on: {
                    "update:focused": function ($event) {
                      dataStash.focused = $event;
                    },
                    "keydown": [function ($event) {
                      if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                      return _vm.insertOption(index + 1);
                    }, function ($event) {
                      if (!$event.type.indexOf('key') && $event.keyCode !== 8) { return null; }
                      return _vm.removeOption($event, index);
                    }]
                  },
                  model: {
                    value: _vm.options[index],
                    callback: function ($$v) {
                      _vm.$set(_vm.options, index, $$v);
                    },
                    expression: "options[index]"
                  }
                }), (hover || dataStash.focused) && _vm.options.length > 1 ? _c('v-btn', {
                  attrs: {
                    "icon": "",
                    "small": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.removeOption($event, index);
                    }
                  }
                }, [_c('XIcon', {
                  attrs: {
                    "size": "18"
                  }
                })], 1) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }], null, true)
    })], 1);
  }), 0)]), _c('v-card-actions', [_c('f-select', {
    staticClass: "dropdown-field-view-editable-dialog__set-select",
    attrs: {
      "attach": ".dropdown-field-view-editable-dialog__set-select",
      "items": _vm.readyLists,
      "item-text": function (item) {
        return item.READABLE_NAME;
      },
      "item-value": function (item) {
        return item.KEY;
      },
      "solo": "",
      "flat": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "Ready Lists",
      "menu-props": {
        nudgeBottom: 40,
        maxWidth: 220
      },
      "value": _vm.selectedReadyListKey
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref3) {
        var item = _ref3.item,
          attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('v-list-item', _vm._g(_vm._b({
          class: item.EMOJI === '🌎' && 'list-item-planet'
        }, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', {
          staticClass: "list-icon"
        }, [_vm._v(" " + _vm._s(item.EMOJI) + " ")]), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.READABLE_NAME) + " ")])], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          key: item.READABLE_NAME,
          staticClass: "mr-2 list-icon"
        }, [_vm._v(_vm._s(item.EMOJI))]), _c('span', {
          staticClass: "selection-text"
        }, [_vm._v(_vm._s(item.READABLE_NAME))])];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_vm.selectedReadyListKey ? _c('XIcon', {
          attrs: {
            "size": "20"
          },
          on: {
            "click": _vm.removeSelectedReadyList
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "gray_100",
      "rounded": "",
      "width": "110"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": "",
      "width": "138"
    },
    on: {
      "click": _vm.saveOptionsList
    }
  }, [_vm._v(" Save ")])], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }