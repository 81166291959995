<template>
    <span
            :class="[
                'simple-contenteditable-input',
                multiline && 'simple-contenteditable-input--multiline',
                !overflowWrap && 'simple-contenteditable-input--nowrap',
                readonly && 'simple-contenteditable-input--readonly'
            ]"
            ref="contenteditable"
            :contenteditable="!readonly"
            :data-placeholder="placeholder"

            @paste="processPaste"
            @keydown="handleKeydown"

            v-mousedown-outside="blur"
            v-click-outside="blur"
            @focus="$emit('update:focused', true)"
            @blur="$emit('update:focused', false)"
            v-on="{
                ...$listeners,
                input: input,
            }"
    >

    </span>
</template>

<script>
    import {onBeforeUnmount, onMounted, watch, ref} from '@vue/composition-api'
    import Vue from 'vue'

    export default {
        name: "SimpleContenteditableInput",
        props: {
            value: String,
            placeholder: String,
            multiline: {
                type: Boolean,
                default: false
            },
            preventHighlighting: {
                type: Boolean,
                default: true
            },
            overflowWrap: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            mask: RegExp
        },
        setup(props, {refs, emit}) {
            const preventAndStopEvent = e => {
                e.preventDefault()
                e.stopPropagation()
            }

            const convertToReadable = str => {
                let translate = {
                    nbsp: ' ',
                    amp: '&',
                    quot: '"',
                    lt: '<',
                    gt: '>'
                };
                return str.toLocaleString().replace(RegExp(`&(${Object.keys(translate).join('|')});`, 'g'), (match, entity) => translate[entity])
            }

            const placeholderShown = ref(false)
            const handlePlaceholerVisibility = () => {
                placeholderShown.value = !refs.contenteditable.innerHTML.length
            }

            const processPaste = e => {
                e.preventDefault()
                e.stopPropagation()
                let data = (e.originalEvent || e).clipboardData.getData('text/plain')
                if (data)
                    document.execCommand("insertText", false, data.replace('\n', ' '))
            }
            const handleKeydown = e => {
                let code = e.which || e.keyCode

                if (props.preventHighlighting) {
                    if (e.ctrlKey) {
                        let hotkeysToPrevent = {
                            b: 66, // bold
                            i: 73, // italic
                            u: 85 // underline
                        }

                        if (Object.values(hotkeysToPrevent).includes(code))
                            preventAndStopEvent(e)
                    }
                }

                if (!props.multiline) {
                    if (code === 13) {
                        preventAndStopEvent(e)
                    }

                }
            }

            const input = () => {
                let invalid = props.mask && !props.mask.test(convertToReadable(refs.contenteditable.innerHTML))
                if (invalid) {
                    let selection = window.getSelection()
                    let offset = selection.focusOffset > props.value.length ? props.value.length : selection.focusOffset

                    refs.contenteditable.innerHTML = props.value

                    let range = document.createRange()
                    range.setStart(refs.contenteditable.firstChild, offset)
                    range.setEnd(refs.contenteditable.firstChild, offset)
                    selection.removeAllRanges()
                    selection.addRange(range)
                }
                emit('input', convertToReadable(refs.contenteditable.innerHTML))
                handlePlaceholerVisibility()
            }

            let unwatchValue
            onMounted(() => {
                unwatchValue = watch(
                    () => props.value,
                    v => {
                        if (v !== convertToReadable(refs.contenteditable.innerHTML)) {
                            refs.contenteditable.innerHTML = v
                            handlePlaceholerVisibility()
                        }
                    },
                    {immediate: true}
                )
            })
            onBeforeUnmount(() => {
                unwatchValue()
            })


            const focus = async (toStartOfString = false) => {
                refs.contenteditable.focus()
                if (toStartOfString)
                    return

                let range = document.createRange()
                range.selectNodeContents(refs.contenteditable)
                range.collapse(false)
                let selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(range)

                await Vue.nextTick()
            }

            const blur = () => {
                refs?.contenteditable?.blur()
            }

            return {
                placeholderShown,
                blur,
                processPaste,
                handleKeydown,
                input,
                focus
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-contenteditable-input {
        outline: none;
        white-space: normal;
        overflow-wrap: break-word;
        display: inline-block;
        cursor: text;

        &--nowrap {
            white-space: nowrap;
            overflow-wrap: normal;
        }

        &:empty:before {
            content: attr(data-placeholder);
            color: inherit;
            opacity: 0.5;
            text-align: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            cursor: text;
        }

        &.simple-contenteditable-input--multiline {
            white-space: normal;
        }
    }
</style>