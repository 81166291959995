<template>
    <span v-if="!submissionObj.value">-</span>
    <span v-else-if="isTypingAllowed">{{ submissionObj.value }}</span>
    <img v-else-if="signatureDataURL" :src="signatureDataURL" style="{height: 34px; contain: style; position: center; max-width: 20%}">
    <f-circle-loader v-else size="3" style="justify-content: left; padding-left: 110px"/>
</template>

<script>
    export default {
        name: "FullSingleAnswer__Signature",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            },
            allowUploading: {
                type: Boolean,
                default: true,
            }
        },
        data: () => ({
            signatureDataURL: undefined
        }),
        computed: {
            isTypingAllowed() {
                return this.sourceObj.properties.allow_typing
            }
        },
        watch: {
            allowUploading: {
                immediate: true,
                handler(v) {
                    if (v && !this.isTypingAllowed && this.submissionObj.value !== null) {
                        this.StorageActions.getImageByS3Path(this.constants.S3_UPLOADS_FOLDER_PATH + this.submissionObj.value)
                            .then(response => {
                                this.signatureDataURL = response.dataURL;
                            })
                    }
                }
            },
        },
    }
</script>