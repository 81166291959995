<template>
    <div class="form-composition-pages-tabs">
        <div
                v-if="homePage"
                class="page-tab-container page-tab-container--home"
                :class="getFormJSON.home_page.page_id === getCurrentPageId && 'page-tab-container--active'"
                @click="goToPage(getFormJSON.home_page.page_id)"
        >
            <slot name="home-page"></slot>
        </div>
        <div
                :class="[
                    'form-composition-pages-tabs__main-pages-shadow',
                    'form-composition-pages-tabs__main-pages-shadow--top',
                    `form-composition-pages-tabs__main-pages-shadow--${wrapperTopShadow}`
                ]"
        ></div>
        <div
                class="main-pages-wrapper thin-scrollbar"
                ref="mainPagesWrapper"
                @scroll="handleMainPagesContainerSize"
        >
            <div
                    class="main-pages"
                    ref="mainPagesContainer"
            >
                <template v-for="(pageData, key) in enumeratedPages">
                    <v-hover v-slot="{ hover }" :key="pageData.randomKey">
                        <div
                                class="page-tab-container page-tab-container--main page-tab-container--translate"
                                :class="[
                                    pageData.pageId === currentEditingPageId && 'page-tab-container--active',
                                    pageData.pageId === currentDraggablePageId && 'page-tab-container--draggable'
                                ]"
                                ref="pageTab"
                                :key="pageData.randomKey"
                                @mousedown="mouseDownOnMainPageTab($event, key, pageData.pageId)"
                                @click="goToPage(pageData.pageId)"
                        >
                            <div class="page-tab-container page-tab-container--scale">
                                <slot
                                        name="main-page"
                                        :page-data="pageData"
                                        :hover="hover"
                                        :page-index="key"
                                        :is-dragging="isDragging"
                                ></slot>
                            </div>
                        </div>
                    </v-hover>
                </template>
            </div>
        </div>
        <div
                :class="[
                    'form-composition-pages-tabs__main-pages-shadow',
                    'form-composition-pages-tabs__main-pages-shadow--bottom',
                    `form-composition-pages-tabs__main-pages-shadow--${wrapperBottomShadow}`
                ]"
        ></div>
        <div
                v-if="finishPage"
                class="page-tab-container page-tab-container--finish"
                :class="getFormJSON.finish_page.page_id === getCurrentPageId && 'page-tab-container--active'"
                @click="goToPage(getFormJSON.finish_page.page_id)"
        >
            <slot name="finish-page"></slot>
        </div>
    </div>
</template>

<script>
    import useDragWorkflow from './drag_workflow'
    import {mapGetters} from '@/xstore'
    import {computed, onBeforeUnmount, ref, watch} from '@vue/composition-api'
    import Vue from 'vue'

    export default {
        name: "FormPagesListDragManager",
        props: {
            pageNameGetter: {
                type: Function,
                required: true
            },
            pageIconGetter: {
                type: Function,
                required: true
            }
        },
        setup(props, context) {
            const {root, emit, refs} = context
            onBeforeUnmount(() => {
                unwatchCurrentEditingForm()
                unwatchPageOrderInterference()
            })
            const currentEditingPageId = computed(() => root.$store.getters['edit/pages_navigation/getCurrentPageId'])


            /** MAIN PAGES ENUMERATING **/
            const enumeratedPages = ref([])
            const calculateEnumeratedPages = (currentEditingForm, generateNewRandomKeys) => {
                if (!currentEditingForm) return
                let pagesList = []
                for (let [pageIndex, {page_id: pageId}] of Object.entries(currentEditingForm.main_pages)) {
                    let pageObj = currentEditingForm.all_pages[pageId]

                    let randomKey = root.Utils.generateUUID()
                    if (!generateNewRandomKeys) {
                        let oldRandomKey = enumeratedPages.value.find(p => p.pageId === pageId)?.randomKey
                        if (oldRandomKey)
                            randomKey = oldRandomKey
                    }

                    pagesList.push({
                        number: parseInt(pageIndex) + 1,
                        icon: props.pageIconGetter(pageObj),
                        name: props.pageNameGetter(pageObj),
                        pageObj: pageObj,
                        pageId: pageId,
                        randomKey: randomKey
                    })
                }
                enumeratedPages.value = pagesList

                Vue.nextTick(() => handleMainPagesContainerSize())
            }
            calculateEnumeratedPages(undefined, true)
            const unwatchCurrentEditingForm = watch(
                () => root.$store.getters['edit/getFormJSON'],
                v => calculateEnumeratedPages(v, false),
                {deep: true, immediate: true}
            )
            const unwatchPageOrderInterference = root.Utils.subscribeMutation(
                () => calculateEnumeratedPages(root.$store.getters['edit/getFormJSON'], true),
                'edit/pages/CHANGE_MAIN_PAGE_INDEX'
            )
            const mainPagesEnumeratingModule = {enumeratedPages}


            /** HOME & FINISH PAGES **/
            const homePage = computed(() => root.$store.getters['edit/getFormJSON']?.home_page)
            const finishPage = computed(() => root.$store.getters['edit/getFormJSON']?.finish_page)
            const homeAndFinishPagesModule = {
                homePage,
                finishPage
            }


            /** DRAG WORKFLOW **/
            const DragWorkflow = useDragWorkflow(props, context)


            const goToPage = pageId => {
                if (root.$store.getters['edit/pages_navigation/getCurrentPageId'] !== pageId)
                    emit('current-page-changed')
                root.$store.dispatch('edit/pages_navigation/setCurrentPageId', pageId)
            }


            const wrapperTopShadow = ref(0)
            const wrapperBottomShadow = ref(0)

            // let recursiveScrollInitialValue = undefined
            //
            // function recursiveScroll(initialValue, scrollBottom) {
            //     if (initialValue === recursiveScrollInitialValue) {
            //         refs.mainPagesWrapper.scrollBy({left: 0, top: (scrollBottom ? 1 : -1) * 1, behavior: 'instant'})
            //         requestAnimationFrame(() => recursiveScroll(initialValue, scrollBottom))
            //     }
            // }

            const handleMainPagesContainerSize = () => {
                if (refs.mainPagesWrapper.clientHeight < refs.mainPagesWrapper.scrollHeight) {
                    const {height: tabHeight} = root.Utils.getAbsoluteBoundingRect(refs.mainPagesContainer.firstChild)
                    const wrapperBounding = root.Utils.getAbsoluteBoundingRect(refs.mainPagesWrapper)
                    const containerBounding = root.Utils.getAbsoluteBoundingRect(refs.mainPagesContainer)
                    containerBounding.height = refs.mainPagesWrapper.scrollHeight
                    containerBounding.bottom = containerBounding.top + refs.mainPagesWrapper.scrollHeight

                    // const activePageTab = document.querySelector('.page-tab-container--active')
                    // if (activePageTab && refs.mainPagesContainer.contains(activePageTab)) {
                    //     const activePageTabBounding = root.Utils.getAbsoluteBoundingRect(activePageTab)
                    //
                    //     if (activePageTabBounding.top < wrapperBounding.top) {
                    //         recursiveScrollInitialValue = activePageTabBounding.top
                    //         recursiveScroll(activePageTabBounding.top, false)
                    //     }
                    //     else if (activePageTabBounding.bottom > wrapperBounding.bottom) {
                    //         recursiveScrollInitialValue = activePageTabBounding.top
                    //         recursiveScroll(activePageTabBounding.top, true)
                    //     }
                    // }

                    if ((wrapperBounding.top - containerBounding.top) <= 0)
                        wrapperTopShadow.value = 0
                    else if ((wrapperBounding.top - containerBounding.top) <= tabHeight * 3)
                        wrapperTopShadow.value = 1
                    else
                        wrapperTopShadow.value = 2

                    if ((containerBounding.bottom - wrapperBounding.bottom) <= 0)
                        wrapperBottomShadow.value = 0
                    else if ((containerBounding.bottom - wrapperBounding.bottom) <= tabHeight * 3)
                        wrapperBottomShadow.value = 1
                    else
                        wrapperBottomShadow.value = 2
                } else {
                    wrapperTopShadow.value = wrapperBottomShadow.value = 0
                }
            }

            root.Utils.useResizeObserver(handleMainPagesContainerSize).observe(() => refs.mainPagesContainer)

            return {
                currentEditingPageId,

                ...mainPagesEnumeratingModule,
                ...homeAndFinishPagesModule,
                ...DragWorkflow,
                goToPage,

                wrapperTopShadow,
                wrapperBottomShadow,
                handleMainPagesContainerSize,
            }
        },
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
            ...mapGetters('edit/pages_navigation', ['getCurrentPageId']),
        }
    }
</script>

<style lang="scss">
    .form-composition-pages-tabs {
        display: flex;
        flex-direction: column;
        min-height: 0;
        max-height: 100%;
        width: 100%;

        &__main-pages-shadow {
            position: relative;
            width: 100%;

            &::before {
                content: "";
                width: 100%;
                height: 12px;
                position: absolute;
                z-index: 1;
                background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 79.17%, rgba(0, 0, 0, 0) 79.17%);
                opacity: 0;
                transition: opacity .2s;
            }

            &--top::before {
                transform: rotate(180deg);
                top: 100%;
            }

            &--bottom::before {
                bottom: 100%;
            }

            &--1::before {
                opacity: 0.1;
            }

            &--2::before {
                opacity: 0.2;
            }
        }

        .main-pages-wrapper {
            display: flex;
            min-height: 0;
            overflow-y: auto;
            //box-shadow: inset 0px 12px 8px -6px rgba(0,0,0,0.1),
            //    inset 0px -12px 8px -6px rgba(0,0,0,0.1);

            //&::before, &::after {
            //    content: "";
            //    position: absolute;
            //    width: 100%;
            //
            //    background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 79.17%, rgba(0, 0, 0, 0) 79.17%);
            //
            //    height: 12px;
            //
            //    transition: opacity .1s;
            //
            //    opacity: 0;
            //
            //    &:first-child {
            //        transform: rotate(180deg);
            //    }
            //
            //    &:last-child {
            //        bottom: 0;
            //    }
            //
            //    &--1 {
            //        opacity: 0.1;
            //    }
            //
            //    &--2 {
            //        opacity: 0.2;
            //    }
            //}
        }

        .main-pages {
            display: flex;
            flex-direction: column;
            width: 100%;

            min-height: 0;
            //overflow-y: auto;
        }

        .page-tab-container {
            position: relative;
            cursor: pointer;

            &, & > * {
                color: var(--v-dark_gray-base) !important;
                letter-spacing: normal !important;
            }

            &--home {
                margin-bottom: 4px;
            }

            &--finish {
                margin-top: 4px;
            }

            &--main {
                padding: 4px 0;
            }

            &--translate {
                &:not(.page-tab-container--draggable) {
                    transition: transform 100ms linear;
                }

                &.page-tab-container--draggable {
                    position: absolute;
                    //width: 100%;
                    z-index: 500;

                    .page-tab-container--scale {

                        box-shadow: 0px 2px 2px rgb(158 166 168 / 25%);
                        filter: drop-shadow(0px 4px 10px rgba(57, 60, 70, 0.12));
                    }
                }

                .page-tab-container--scale {
                    transition-property: box-shadow, transform, filter;
                    transition-duration: 100ms;
                    transition-timing-function: linear;
                }
            }


            .page-tab {
                background-color: white;
                height: 36px !important;
                font-size: .875rem;
                color: var(--v-dark_gray-base);
                line-height: 1.375rem;
            }

            &--active {
                position: relative;

                .page-tab {
                    &::before {
                        content: "";
                        background-color: var(--v-blue_400-base);
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: calc(100% - 3px);
                        bottom: 0;
                        pointer-events: none;
                    }

                    &::after {
                        content: "";
                        background-color: var(--v-blue_400-base);
                        opacity: 0.05;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                    }

                    &, & > * {
                        font-weight: 600 !important;
                    }
                }
            }
        }
    }
</style>