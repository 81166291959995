<template>
  <div class="image-choice-field-view">
    <div class="image-choice-field-view__layout">
      <v-hover
          v-for="({option, index}) in formattedOptions"
          v-slot="{ hover }"
          :key="index"
      >
        <div class="image-choice-field-view__card-wrapper generic-interactive-transparent-blurry-card">
          <div
              :class="[
                          'image-choice-field-view__image-choice-card',
                          hover && 'generic-interactive-transparent-blurry-card__hover',
                          !editable && selectedOptions.includes(index) && `image-choice-field-view__image-choice-card--active`,
                          !editable && selectedOptions.includes(index) && `generic-interactive-transparent-blurry-card__active`,
                      ]"
              @click="preview ? '' : $emit('toggle-options', index)"
          >
            <div
                class="image-choice-field-view__image-choice-card__wrapper generic-interactive-transparent-blurry-card__card">
              <v-scale-transition origin="center center 0">
                <v-btn
                    v-if="editable && hover && isEditingActive"
                    class="image-choice-field-view__image-choice-card__remove-btn"
                    icon
                    @click="$emit('remove-option', index)"
                >
                  <XIcon size="14" stroke-width="2.9"/>
                </v-btn>
              </v-scale-transition>
              <div class="image-choice-field-view__image-choice-card__image-wrapper">
                <div class="image-choice-field-view__image-choice-card__image">
                  <v-scale-transition origin="center center 0">
                    <div  v-if="editable && hover && option.image"
                          @click="$emit('change-image', index)"
                          class="image-choice-field-view__image-choice-card__image-edit-icon"
                    >
                      <Edit2Icon size="16" strokeWidth="1.5"/>
                    </div>
                  </v-scale-transition>
                  <div class="image-choice-field-view__image-choice-card__anchor-letter-wrapper">
                    <div class="image-choice-field-view__image-choice-card__anchor-letter">
                      {{ Utils.convertIndexToLetter(index) }}
                    </div>
                  </div>
                  <div v-if="!editable && selectedOptions.includes(index)"
                       class="image-choice-field-view__image-choice-card__active-checkbox-wrapper">
                    <f-checkbox
                        :value="true"
                        readonly
                    />
                  </div>
                  <div
                      v-if="editable && currentLoadingImageIndex === index"
                      class="image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--loading"
                  >
                    <f-circle-loader size="3"/>
                  </div>
                  <div
                      v-else-if="editable && option.image || !editable"
                      class="image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--loaded"
                      :style="{backgroundImage: 'url(' + option.image + ')'}"
                  ></div>
                  <div
                      v-else-if="editable"
                      class="image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--empty"
                      @click="$emit('start-adding-image', index)"
                  >
                    <v-icon>$add_image</v-icon>
                  </div>
                </div>
              </div>
              <div class="image-choice-field-view__image-choice-card__label-wrapper">
                <f-simple-contenteditable-input
                    v-if="option.label !== undefined"
                    v-model="option.label"
                    class="image-choice-field-view__image-choice-card__label"
                    :placeholder="editable ? 'Label' : ''"
                    overflow-wrap
                    prevent-highlighting
                    :readonly="!editable"
                />
              </div>
            </div>
          </div>
        </div>
      </v-hover>
      <slot name="addOptionCard"></slot>
    </div>
    <slot name="addButton"></slot>
  </div>
</template>

<script>
export default {
  name: 'SharedView__ImageChoice',
  components: {
    XIcon: () => import('vue-feather-icons/icons/XIcon'),
    Edit2Icon: () => import('vue-feather-icons/icons/Edit2Icon'),
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    randomizeOptions: {
      type: Boolean,
      default: false,
    },
    isEditingActive: {
      type: Boolean,
      default: false,
    },
    currentLoadingImageIndex: {
      type: Number,
    }
  },
  computed: {
    formattedOptions() {
      const formattedOptions = this.options
          .map((option, index) => ({
            option,
            index
          }))
      if (this.randomizeOptions)
        return formattedOptions.sort(() => Math.random() - 0.5)
      return formattedOptions
    },
  }
}
</script>

<style scoped>

</style>