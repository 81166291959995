<template>
    <div class="f-card-brand-icon">
        <img class="f-card-brand-icon__icon" :src="$options.brands[brand].icon"/>
    </div>
</template>

<script>

    // cartes_bancaires from  https://github.com/datatrans/payment-logos/blob/master/assets/logos/cartes-bancaires.svg
    // rest icons from  https://github.com/aaronfagan/svg-credit-card-payment-icons

    const brands = {
        alipay: {
            icon: require('./icons/alipay.svg')
        },
        amex: { // supported by Stripe
            icon: require('./icons/amex.svg')
        },
        cartes_bancaires: { // supported by Stripe
            icon: require('./icons/cartes_bancaires.svg')
        },
        diners: { // supported by Stripe
            icon: require('./icons/diners.svg')
        },
        discover: { // supported by Stripe
            icon: require('./icons/discover.svg')
        },
        elo: {
            icon: require('./icons/elo.svg')
        },
        hiper: {
            icon: require('./icons/hiper.svg')
        },
        hipercard: {
            icon: require('./icons/hipercard.svg')
        },
        jcb: { // supported by Stripe
            icon: require('./icons/jcb.svg')
        },
        maestro: {
            icon: require('./icons/maestro.svg')
        },
        mastercard: { // supported by Stripe
            icon: require('./icons/mastercard.svg')
        },
        mir: {
            icon: require('./icons/mir.svg')
        },
        paypal: {
            icon: require('./icons/paypal.svg')
        },
        unionpay: { // supported by Stripe
            icon: require('./icons/unionpay.svg')
        },
        visa: { // supported by Stripe
            icon: require('./icons/visa.svg')
        },
    }

    export const stripeSupportedBrands = [
        'amex',
        'cartes_bancaires',
        'diners',
        'discover',
        'jcb',
        'mastercard',
        'unionpay',
        'visa',
    ]

    export default {
        name: "CardBrandIcon",
        brands,
        props: {
            brand: {
                type: String,
                required: true,
                validator: v => {
                    const validationResult = v in brands
                    if (!validationResult)
                        console.error(`'brand' must be one of:\n- ${Object.keys(brands).join('\n- ')}`)

                    return validationResult
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .f-card-brand-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
            max-width: 100%;
            flex-grow: 1;
        }
    }
</style>