var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F1F1F1"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip0_3_6355)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.94444 9.02696V8.66682C7.94432 8.52447 7.97224 8.38349 8.02663 8.25195C8.08102 8.1204 8.16079 8.00086 8.2614 7.90016C8.36201 7.79946 8.48148 7.71958 8.61298 7.66508C8.74448 7.61057 8.88543 7.58252 9.02778 7.58252H16.9722C17.1146 7.58252 17.2555 7.61057 17.387 7.66508C17.5185 7.71958 17.638 7.79946 17.7386 7.90016C17.8392 8.00086 17.919 8.1204 17.9734 8.25195C18.0278 8.38349 18.0557 8.52447 18.0556 8.66682V9.02793",
      "stroke": "black",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 7.58252V18.4159",
      "stroke": "black",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.8333 18.4155H15.1667",
      "stroke": "black",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_3_6355"
    }
  }, [_c('rect', {
    attrs: {
      "width": "11.5556",
      "height": "11.5556",
      "fill": "white",
      "transform": "translate(7.22222 7.22217)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }