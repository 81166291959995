var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": "",
      "address-fields": _vm.fieldProperties.full_address_fields,
      "placeholders": _vm.fieldConst.UTIL.placeholders
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }