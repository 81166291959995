<template>
    <div
            class="f-fluid-container"
            :class="[
                hAuto && 'f-fluid-container--h-auto'
            ]"
            v-on="$listeners"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FluidContainer",
        props: {
            hAuto: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .f-fluid-container {
        max-width: 100%;
        width: 100%;
        min-height: 100%;
        height: 100%;

        &.f-fluid-container--h-auto {
            height: auto;
        }
    }
</style>