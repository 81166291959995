var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "matrix-field-view__layout matrix-field-view__layout--mobile"
  }, [_c('div', {
    staticClass: "matrix-field-view__wrapper"
  }, [_c('div', {
    staticClass: "matrix-field-view__list"
  }, _vm._l(_vm.rows, function (section, rowIndex) {
    return _c('div', {
      key: rowIndex + section.text,
      staticClass: "matrix-field-view__list-section"
    }, [_c('div', {
      staticClass: "matrix-field-view__list-section-header"
    }, [_vm._v(_vm._s(section.text))]), _c('div', {
      staticClass: "matrix-field-view__list-section-row"
    }, _vm._l(_vm.columns, function (option, columnIndex) {
      return _c('span', {
        key: columnIndex + option.text,
        class: ['matrix-field-view__list-section-row-item', _vm.submissionObjValue[rowIndex].includes(columnIndex) && 'matrix-field-view__list-section-row-item--selected'],
        on: {
          "click": function ($event) {
            _vm.$emit('toggle-selection', {
              state: !_vm.submissionObjValue[rowIndex].includes(columnIndex),
              rowIndex: rowIndex,
              columnIndex: columnIndex
            });
          }
        }
      }, [_vm._v(" " + _vm._s(option.text) + " ")]);
    }), 0)]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }