import OwnersFormActions from '@/utils/actions/owners_form_actions'
import constants from '@/constants/constants'


import { useFSPStoreModule } from '@/FSP/fsp_store_module'
import { useFieldsEnumerationGettersExtension } from '@/store/util_modules/fields_enumeration_getters'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { StoreableExtensions, StoreableUtils } from '@/xstore/utils/storeable'
import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'

export default ({useExtension}) => {
    {
        const state = {
            form_id: undefined
        }
        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    {
        const state = {
            form_json: undefined
        }
        useExtension(SMAGableExtensions.SMGable, {state})
    }

    useExtension(useFieldsEnumerationGettersExtension({
        isFormStaticGetterName: 'isFormStatic',
        isFormInteractiveGetterName: 'isFormInteractive',
        formJSONGetterName: 'getFormJson'
    }))

    useExtension(StoreableExtensions.Default)
    useExtension(PathableExtensions.Default)


    const cropFormJson = formJson => {
        let unusedMainPages = [],
            usedFieldIds = []

        if (formJson.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE) {
            unusedMainPages = formJson.main_pages.slice(1)
            formJson.main_pages = formJson.main_pages.slice(0, 1)

            formJson.main_pages.forEach(({page_id}) => {
                formJson.all_pages[page_id].rows = formJson.all_pages[page_id].rows.slice(0, 3)

                formJson.all_pages[page_id].rows.forEach(({fields}) => {
                    fields.forEach(({field_id}) => usedFieldIds.push(field_id))
                })
            })
        } else if (formJson.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE) {
            unusedMainPages = formJson.main_pages.slice()

            formJson.main_pages = []
        }


        unusedMainPages.forEach(({page_id}) => delete formJson.all_pages[page_id])

        Object.keys(formJson.fields)
            .forEach(fieldID => {
                if (!usedFieldIds.includes(fieldID))
                    delete formJson.fields[fieldID]
            })

        return formJson
    }


    return {
        actions: {
            initializeFormPreview: ({commit, dispatch, parentGetters, getters}) => OwnersFormActions.fetchFormJson(parentGetters.getFormDbData.path)
                .then(({json: formJson}) => {
                    cropFormJson(formJson)

                    let excludeFormTypeFromFSP

                    if (formJson.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE)
                        excludeFormTypeFromFSP = 'interactive'
                    else if (formJson.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE)
                        excludeFormTypeFromFSP = 'static'


                    const fsp_module = useFSPStoreModule({
                        stylePreferencesGetterName: 'getStylePreferences',
                        excludeGenericFieldClassConnectors: ['submittable', 'editing-active', excludeFormTypeFromFSP]
                    })

                    getters[StoreableUtils.storeGetterName].registerModule([...getters[PathableUtils.pathGetterName], 'FSP'], fsp_module)

                    commit('SET_FORM_JSON', formJson)
                    return dispatch('FSP/initializeFSP')
                }),
            refreshFormPreview: ({commit, parentGetters}) => OwnersFormActions.fetchFormJson(parentGetters.getFormDbData.path)
                .then(({json: formJson}) => {
                    cropFormJson(formJson)

                    commit('SET_FORM_JSON', formJson)
                })
        },
        getters: {
            isFormStatic: ({getters}) => getters.getFormJson?.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE,
            isFormInteractive: ({getters}) => getters.getFormJson?.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE,
            getStylePreferences: ({rootGetters, parentGetters}) => rootGetters['FSP_themes/getFspThemesRegistry']?.[parentGetters.getFormDbData.FSPThemeID]?.rawData,
        }
    }
}