<template>
    <div class="generic-text-field-view">
        <GenericStringSingleLineFieldViewInput v-model="fieldObj.content.placeholder"/>
    </div>
</template>

<script>
    import editable_field_view_mixin from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: "Editable__GenericTextFieldForPlaceholderInput",
        components: {
            GenericStringSingleLineFieldViewInput: () => import('./GenericStringSingleLineFieldViewInput')
        },
        mixins: [editable_field_view_mixin]
    }
</script>