<template>
  <SharedView
    :field-obj="sourceObj"
    :submission-obj="submissionObj"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

export default {
  name: "Submittable__SmartParagraph",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  }
}
</script>