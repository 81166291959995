export default {
    "v": "5.7.1",
    "fr": 60,
    "ip": 0,
    "op": 40,
    "w": 1000,
    "h": 1000,
    "nm": "ui-lightning",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "gray Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500.429, 500.278, 0], "ix": 2},
            "a": {"a": 0, "k": [253.18, 302.375, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 0,
                    "s": [100, 100, 100]
                }, {"t": 3, "s": [0, 0, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": {
                        "i": [[9.744, -8.51], [31.421, -27.166], [86.71, -74.826], [6.118, -5.06], [0, 0], [-8.787, 17.094], [-34.87, 67.877], [-3.374, 7.351], [0, 0], [59.569, 0.302], [4.884, 3.122], [-9.744, 8.485], [-31.447, 27.141], [-86.71, 74.827], [-6.118, 5.061], [0, 0], [8.762, -17.02], [34.87, -67.878], [3.373, -7.352], [0, 0], [-59.568, -0.302], [-4.885, -3.122]],
                        "o": [[-31.295, 27.292], [-86.685, 74.827], [-6.017, 5.187], [0, 0], [-13.394, -12.638], [34.895, -67.853], [2.417, -4.683], [0, 0], [-59.594, 0], [-6.017, -0.025], [-8.435, -5.389], [31.295, -27.292], [86.659, -74.852], [5.993, -5.186], [0, 0], [13.32, 12.539], [-34.87, 67.852], [-2.392, 4.683], [0, 0], [59.569, 0], [6.017, 0.05], [8.409, 5.363]],
                        "v": [[241.675, -19.109], [147.463, 62.414], [-112.592, 286.918], [-130.92, 302.125], [-138.473, 302.125], [-143.509, 269.698], [-38.823, 66.116], [-31.017, 49.197], [-47.962, 49.197], [-226.694, 48.995], [-244.494, 44.741], [-241.674, 19.11], [-147.436, -62.388], [112.617, -286.918], [130.946, -302.125], [138.499, -302.125], [143.535, -269.671], [38.848, -66.114], [31.044, -49.195], [48.012, -49.195], [226.72, -49.019], [244.52, -44.739]],
                        "c": true
                    }, "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.803921568627451, 0.803921568627451, 0.803921568627451, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [253.179, 302.375], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }], "nm": "Group 1", "np": 2, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "yellow Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500.429, 500.278, 0], "ix": 2},
            "a": {"a": 0, "k": [253.18, 302.375, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 3,
                    "s": [0, 0, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 19,
                    "s": [120, 120, 100]
                }, {
                    "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
                    "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
                    "t": 25,
                    "s": [90, 90, 100]
                }, {"t": 29, "s": [100, 100, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": {
                        "i": [[9.744, -8.51], [31.421, -27.166], [86.71, -74.826], [6.118, -5.06], [0, 0], [-8.787, 17.094], [-34.87, 67.877], [-3.374, 7.351], [0, 0], [59.569, 0.302], [4.884, 3.122], [-9.744, 8.485], [-31.447, 27.141], [-86.71, 74.827], [-6.118, 5.061], [0, 0], [8.762, -17.02], [34.87, -67.878], [3.373, -7.352], [0, 0], [-59.568, -0.302], [-4.885, -3.122]],
                        "o": [[-31.295, 27.292], [-86.685, 74.827], [-6.017, 5.187], [0, 0], [-13.394, -12.638], [34.895, -67.853], [2.417, -4.683], [0, 0], [-59.594, 0], [-6.017, -0.025], [-8.435, -5.389], [31.295, -27.292], [86.659, -74.852], [5.993, -5.186], [0, 0], [13.32, 12.539], [-34.87, 67.852], [-2.392, 4.683], [0, 0], [59.569, 0], [6.017, 0.05], [8.409, 5.363]],
                        "v": [[241.675, -19.109], [147.463, 62.414], [-112.592, 286.918], [-130.92, 302.125], [-138.473, 302.125], [-143.509, 269.698], [-38.823, 66.116], [-31.017, 49.197], [-47.962, 49.197], [-226.694, 48.995], [-244.494, 44.741], [-241.674, 19.11], [-147.436, -62.388], [112.617, -286.918], [130.946, -302.125], [138.499, -302.125], [143.535, -269.671], [38.848, -66.114], [31.044, -49.195], [48.012, -49.195], [226.72, -49.019], [244.52, -44.739]],
                        "c": true
                    }, "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fill 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [253.179, 302.375], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }], "nm": "Group 1", "np": 2, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "lines Outlines",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [493.378, 491.089, 0], "ix": 2},
            "a": {"a": 0, "k": [520.771, 497.392, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[-56.083, -75.27], [-42.384, -16.853], [7.071, 9.491], [12.68, 60.996], [56.083, 75.27]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [772.739, 879.763], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[54.754, -76.214], [-15.811, -66.262], [0.939, -1.307], [-54.754, 17.917], [-54.754, 76.214]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [280.976, 881.07], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 2",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 2,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[-88.791, 19.604], [-31.731, 38.374], [7.436, -11.813], [61.939, -0.235], [88.791, -38.374]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [915.251, 425.803], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 3",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 3,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[89.497, 40.788], [62.218, -1.548], [-0.842, 12.217], [-13.099, -40.788], [-89.497, -15.82]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [126.997, 406.863], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 4",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 4,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[28.835, 93.844], [-28.835, 44.857], [27.956, -10.212], [-2.253, -51.979], [27.25, -93.844]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Path 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0.961000031116, 0.816000007181, 0.097999999102, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 15, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 10,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [491.469, 131.345], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Group 5",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 5,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "tm",
            "s": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 21, "s": [0]}, {
                    "t": 27,
                    "s": [100]
                }],
                "ix": 1
            },
            "e": {
                "a": 1,
                "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 16, "s": [0]}, {
                    "t": 22,
                    "s": [100]
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "m": 1,
            "ix": 6,
            "nm": "Trim Paths 1",
            "mn": "ADBE Vector Filter - Trim",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 4,
        "ty": 4,
        "nm": "Shape Layer 1",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 15.008,
                    "s": [100]
                }, {"t": 25.01953125, "s": [0]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [500, 500, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
                    "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
                    "t": 7,
                    "s": [0, 0, 100]
                }, {"t": 29.0234375, "s": [150, 150, 100]}],
                "ix": 6
            }
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "d": 1,
                "ty": "el",
                "s": {"a": 0, "k": [934, 934], "ix": 2},
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "nm": "Ellipse Path 1",
                "mn": "ADBE Vector Shape - Ellipse",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [1, 0.909065783024, 0.141176462173, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 4, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "bm": 0,
                "nm": "Stroke 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "gf",
                "o": {"a": 0, "k": 100, "ix": 10},
                "r": 1,
                "bm": 0,
                "g": {
                    "p": 3,
                    "k": {
                        "a": 0,
                        "k": [0, 0.99, 0.882, 0.227, 0.262, 0.496, 0.859, 0.614, 0.525, 0.002, 0.836, 1, 0, 0, 0.214, 0.5, 0.429, 1],
                        "ix": 9
                    }
                },
                "s": {"a": 0, "k": [0, 0], "ix": 5},
                "e": {"a": 0, "k": [1581, 1162], "ix": 6},
                "t": 2,
                "h": {"a": 0, "k": 0, "ix": 7},
                "a": {"a": 0, "k": 0, "ix": 8},
                "nm": "Gradient Fill 1",
                "mn": "ADBE Vector Graphic - G-Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 2], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transform"
            }],
            "nm": "Ellipse 1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 0,
        "op": 40,
        "st": 0,
        "bm": 0
    }],
    "markers": []
}