var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "date-time-field"
  }, [_c('FormattedDateTimeField', {
    attrs: {
      "layout": _vm.formattedDateTimeFieldLayout,
      "value": _vm.formattedDateTimeFieldValue
    },
    on: {
      "input": _vm.handleDateTimeInputFromField
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('f-date-time-picker', _vm._b({
          attrs: {
            "as-menu": "",
            "menu-props": {
              contentClass: 'date-time-field__picker-menu prevent-interactive-page-scrolling',
              attach: true,
              bottom: false,
              top: true,
              nudgeBottom: 0,
              nudgeTop: 50
            },
            "return-object": "",
            "return-partially": "",
            "return-timestamp": ""
          },
          on: {
            "input": _vm.handleDateTimeInputFromPickerOrSubmissionValue
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('CalendarIcon', _vm._g({}, on))];
            }
          }])
        }, 'f-date-time-picker', _vm.dateTimePickerBind, false))];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }