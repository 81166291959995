import { effectScope } from '@vue/composition-api'
import { isEmpty, isFunction, isObject, isString } from 'lodash'
import Vue from 'vue'
import { VBtn } from 'vuetify/lib/components'

import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({ useExtension }) => {
  let scope

  const disposeScope = () => {
    if (scope) {
      scope.stop()
      scope = null
    }
  }

  {
    const state = {
      model: false,

      title: undefined,
      content: undefined,
      actions: undefined,
      props: undefined,
    }

    useExtension(SMAGableExtensions.SMGable, {
      state,
      varNameCustomizer: variableName => 'dialog_' + variableName,
    })
  }

  return {
    actions: {
      showDialog: ({ commit, dispatch }, dialogDefinition) => {
        disposeScope()

        scope = effectScope()

        scope.run(() => {
          let {
            title = {},
            content = {},
            actions = {},
            props = {},
          } = dialogDefinition({
            closeDialog: () => dispatch('closeDialog'),
            genActionButton: ({
              color = 'blue_400',
              text = 'Text',
              onClick = () => null,
            }) => ({
              render: h =>
                h(
                  VBtn,
                  {
                    props: {
                      color,
                      rounded: true,
                    },
                    on: {
                      click: onClick,
                    },
                  },
                  text
                ),
            }),
          })

          const genDialogPart = (name, renderFn) => ({
            name: `GenericStoreDialog__Autogenerated__${name}`,
            functional: true,
            render: renderFn,
          })

          const checkIfPartAcceptableAsVueComponent = part =>
            (isObject(part) || isFunction(part)) &&
            ('render' in part || part instanceof Vue)

          const titleRenderFn = (() => {
            if (isString(title)) return h => h('span', {}, title)
            else if (checkIfPartAcceptableAsVueComponent(title))
              return h => h(Vue.extend(title))
            else throw new Error('Dialog title must be string or Vue component')
          })()

          commit('SET_DIALOG_TITLE', genDialogPart('Title', titleRenderFn))

          const contentRenderFn = (() => {
            if (isString(content)) return h => h('span', {}, content)
            else if (checkIfPartAcceptableAsVueComponent(content))
              return h => h(Vue.extend(content))
            else
              throw new Error('Dialog content must be string or Vue component')
          })()

          commit(
            'SET_DIALOG_CONTENT',
            genDialogPart('Content', contentRenderFn)
          )

          const actionsRenderFn = (() => {
            if (checkIfPartAcceptableAsVueComponent(actions))
              return h => h(Vue.extend(actions))
            else throw new Error('Dialog actions must have render function')
          })()

          commit(
            'SET_DIALOG_ACTIONS',
            genDialogPart('Actions', actionsRenderFn)
          )

          if (!isEmpty(props)) commit('SET_DIALOG_PROPS', props)

          commit('SET_DIALOG_MODEL', true)
        })
      },
      closeDialog: ({ commit }) => {
        commit('SET_DIALOG_MODEL', false)

        disposeScope()

        commit('SET_DIALOG_TITLE', undefined)
        commit('SET_DIALOG_CONTENT', undefined)
        commit('SET_DIALOG_ACTIONS', undefined)
        commit('SET_DIALOG_PROPS', undefined)
      },
    },
  }
}

