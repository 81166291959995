<template>
    <div
            ref="editableField"
            class="static-editable-field-constructor"
            :class="isEditingActive && 'static-editable-field-constructor--editing-active'"
            @mousedown="turnOnEditingActive"
            v-mousedown-outside="handleMouseDownOutside"
            @mouseenter="fieldConstructorHoverIn(fieldId)"
            @mouseleave="fieldConstructorHoverOut(fieldId)"
    >
        <div draggable="false" ondragstart="return false" class="static-editable-field-constructor__drag-hook" @mousedown="startDragging">
            <DragHook :class="isEditingActive || 'opacity-0'"/>
        </div>
        <div class="static-editable-field-constructor__generic-field-wrapper">
            <GenericField
                    :modifiers="['static', 'editable', `width-${fieldWidth}`, isEditingActive && 'editing-active']"
                    :field-id="fieldId"
                    :field-obj="fieldObj"
                    :field-type-const="fieldTypeConst"
            >
                <EditableFieldViewsGenerator
                        :field-obj="fieldObj"
                        :is-editing-active="isEditingActive"
                        :field-width="fieldWidth"
                        :is-expanding-transition-going="isExpandingTransitionGoing"
                />
            </GenericField>
            <div
                    v-if="'required' in fieldObj.properties"
                    class="static-editable-field-constructor__required"
                    :class="isEditingActive || 'opacity-0'"
            >
                <span class="mr-2">Required</span>
                <f-switch v-model="requiredModel"/>
            </div>
        </div>
        <StaticFieldPopup
                :show="isEditingActive && !isExpandingActive"
                :field-obj="fieldObj"
                :field-id="fieldId"
                @duplicate-field="duplicateField"
                @remove-field="removeField"
                @change-field-type="changeFieldType"
        />
        <div
                class="static-editable-field-constructor__expander"
                :class="[
                    isExpandingActive && 'static-editable-field-expander-active',
                    isEditingActive || 'opacity-0'
                ]"
                @mousedown="startExpanding"
        ></div>
        <GenericStoreDialog store-module-path="edit/fields/confirmation_dialog"/>
    </div>
</template>

<script>
    import {editable_field_watcher_mixin} from '@/components/FormEditor/EditableFields/editable_field_watcher_mixin'
    import { mapGetters, mapActions } from '@/xstore'
    import { provide, computed } from '@vue/composition-api'

    export default {
        name: "StaticFieldConstructor",
        components: {
            StaticFieldPopup: () => import('./StaticFieldPopup/StaticFieldPopup'),
            DragHook: () => import('@/components/Elements/Icons/DragHook'),
            GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog')

        },
        mixins: [editable_field_watcher_mixin],
        props: {
            isExpandingActive: Boolean,
            fieldWidth: Number,
            isExpandingTransitionGoing: Boolean
        },
        setup(props) {
            provide('fieldId', computed(() => props.fieldId))
        },
        data: () => ({
            isSelected: false
        }),
        computed: {
            ...mapGetters('edit/conditional', [
                'getTargetedAndConditionedFields'
            ]),
            ...mapGetters('edit/hovered_static_field_constructor', [
                'getCurrentHoveredFieldConstructorID'
            ]),
            isHovered() {
                return this.getCurrentHoveredFieldConstructorID === this.fieldId
            },
            isEditingActive() {
                return !!(this.isHovered || (!this.getCurrentHoveredFieldConstructorID && this.isSelected))
            },
            requiredModel: {
                get() {
                    return this.fieldObj.properties.required
                },
                set(v) {
                    if (this.getTargetedAndConditionedFields[this.fieldId].targeted)
                        this.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                            header: 'Make field Required',
                            text: 'This block visibility is controlled by a conditional logic, by enabling "Required" property it will remove the conditional logic applied to it',
                            confirmBtnText: 'Yes, make it Required',
                            callback: () => {
                                this.$store.dispatch('edit/conditional/removeConditionForField', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
                                this.fieldObj.properties.required = v
                            }
                        })
                    else
                        this.fieldObj.properties.required = v
                }
            }
        },
        methods: {
            ...mapActions('edit/hovered_static_field_constructor', [
                'fieldConstructorHoverIn',
                'fieldConstructorHoverOut',
            ]),
            turnOnEditingActive() {
                this.isSelected = true
            },
            handleMouseDownOutside() {
                if (!this.$store.getters['edit/conditional/getConditionalLogicEditorModel'] && !this.$store.getters['edit/conditional/confirmation_dialog/getDialogModel'])
                    this.turnOffEditingActive()
            },
            turnOffEditingActive() {
                this.isSelected = false
            },

            startExpanding() {
                this.$emit('start-expanding', {fieldType: this.fieldObj.field_type})
            },

            startDragging(event) {
                this.$emit('start-dragging', {
                    event: event,
                    fieldType: this.fieldObj.field_type,
                    bounding: this.Utils.getAbsoluteBoundingRect(this.$refs.editableField)
                })
            }
        }
    }
</script>