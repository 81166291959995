<template>
    <div>
        <FormSettingsOptionTemplate class="mb-4" :value="settingsObj.notifications.email_notifications.enabled">
          <template #header>
            <FormParametersHeader v-model="settingsObj.notifications.email_notifications.enabled"
                                  :allowed-only-with-payment="!currentSubscriptionFeatures?.email_notifications"
            >
              Get notifications by email
            </FormParametersHeader>
          </template>
          <template #hint>
            <FormParametersHint>Receive an email when someone submits a form</FormParametersHint>
          </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionWithInput always-enabled>
            <template #input>
                <v-combobox
                        v-model="emailsList"
                        :disabled="!settingsObj.notifications.email_notifications.enabled"
                        :error-messages="emailsErrors"
                        multiple
                        deletable-chips
                        placeholder="Add email"
                        persistent-placeholder
                        class="rounded-lg pa-0"
                        append-icon=""
                        outlined
                        dense
                        hide-details="auto"
                        @input="handleEmailsList"
                        @update:search-input="updateEmailsInputModel"
                >
                    <template v-slot:selection="{ item, parent }">
                        <v-chip
                                class="my-1 bluish_gray_100"
                                :key="JSON.stringify(item)"
                                small
                                label
                        >
                            {{ item }}
                            <XIcon class="cursor-pointer ml-3" size="16" @click="parent.selectItem(item)"/>
                        </v-chip>
                    </template>
                </v-combobox>
            </template>
        </FormSettingsOptionWithInput>
        <FormSettingsOptionWithInput always-enabled>
            Your email subject
            <template #input>
                <v-text-field
                        v-model="settingsObj.notifications.email_notifications.email_subject"
                        :disabled="!settingsObj.notifications.email_notifications.enabled"
                        outlined
                        class="m-0"
                />
            </template>
        </FormSettingsOptionWithInput>

        <FormSettingsOptionTemplate class="mb-8" :value="settingsObj.notifications.email_notifications.include_responses_content">
          <template #header>
            <FormParametersHeader v-model="settingsObj.notifications.email_notifications.include_responses_content"
                                  :allowed-only-with-payment="!currentSubscriptionFeatures?.email_notifications"
                                  :disabled="!settingsObj.notifications.email_notifications.enabled"
            >
              Include responses content
            </FormParametersHeader>
          </template>
          <template #hint>
            <FormParametersHint>Include response content in notification email</FormParametersHint>
          </template>
        </FormSettingsOptionTemplate>

        
        <FormSettingsOptionWithInput always-enabled>
            Email preview
            <template #input>
                <div class="gray_100 bluish_gray_500--text pa-5 rounded-lg">
                    <p class="mb-3">Your form has a new response:</p>
                    <p class="mb-3">Log in to view or download your responses at {{responsesPageURL}}</p>
                    <p>🤍 Formsly Team</p>
                </div>
            </template>
        </FormSettingsOptionWithInput>
    </div>
</template>

<script>
    import { computed, ref, watch } from '@vue/composition-api'
    import { useVuelidate } from '@vuelidate/core'
    import { email, required } from '@vuelidate/validators'

    export default {
        name: "FormEmailNotificationsSettings",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
            FormParametersHeader: () => import('../Elements/FormParametersHeader'),
            FormSettingsOptionTemplate: () => import('./FormSettingsOptions/FormSettingsOptionTemplate'),
            FormParametersHint: () => import('../Elements/FormParametersHint'),
            FormSettingsOptionWithInput: () => import('./FormSettingsOptions/FormSettingsOptionWithInput'),
        },
        props: {
            settingsObj: {
                type: Object,
                required: true
            }
        },
        data: vm => ({
            responsesPageURL: window.location.origin + vm.$router.resolve({
                name: vm.constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
                params: {formId: vm.$route.params.formId}
            }).href
        }),
        setup(props, {root}) {
            const emailsList = computed({
                get: () => props.settingsObj.notifications.email_notifications.emails,
                set: v => props.settingsObj.notifications.email_notifications.emails = v
            })
            const emailsInputModel = ref('')
            const updateEmailsInputModel = v => {
                if ($v.value?.emails?.$reset) $v.value.emails.$reset()
                emailsInputModel.value = v
            }
            const emailsErrors = computed(() => [
                ...$v.value.$errors.map(e => e.$message),
                ...($v.value?.emailsInputValue?.$silentErrors?.map(e => e.$message) || [])
            ])
            const handleEmailsList = () => {
                emailsList.value = emailsList.value.filter(v => email.$validator(v))
            }

            const $v = useVuelidate(
                computed(() => ({
                    ...(props.settingsObj.notifications.email_notifications.enabled && ({
                        emails: {required},
                        emailsInputValue: {email}
                    }))
                })),
                {
                    emails: emailsList,
                    emailsInputValue: computed(() => emailsInputModel.value)
                }
            )

            const currentSubscriptionFeatures = computed(() => root.$store.getters['user/plan/current_subscription/getCurrentSubscriptionFeatures']);

            watch(() => props.settingsObj.notifications.email_notifications.include_responses_content, (newValue) => {
                if (newValue) {
                props.settingsObj.notifications.email_notifications.enabled = true;
                }
            });

            return {
                emailsList,
                emailsInputModel,
                emailsErrors,
                updateEmailsInputModel,
                handleEmailsList,
                currentSubscriptionFeatures,
                $v
            }
        },
    }
</script>
