var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "width": "9",
      "height": "15",
      "viewBox": "0 0 9 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('circle', {
    attrs: {
      "cx": "7.43896",
      "cy": "1.5",
      "r": "1.5",
      "transform": "rotate(90 7.43896 1.5)",
      "fill": "currentColor"
    }
  }), _c('circle', {
    attrs: {
      "cx": "2.43896",
      "cy": "1.5",
      "r": "1.5",
      "transform": "rotate(90 2.43896 1.5)",
      "fill": "currentColor"
    }
  }), _c('circle', {
    attrs: {
      "cx": "7.43896",
      "cy": "7.5",
      "r": "1.5",
      "transform": "rotate(90 7.43896 7.5)",
      "fill": "currentColor"
    }
  }), _c('circle', {
    attrs: {
      "cx": "2.43896",
      "cy": "7.5",
      "r": "1.5",
      "transform": "rotate(90 2.43896 7.5)",
      "fill": "currentColor"
    }
  }), _c('circle', {
    attrs: {
      "cx": "7.43896",
      "cy": "13.5",
      "r": "1.5",
      "transform": "rotate(90 7.43896 13.5)",
      "fill": "currentColor"
    }
  }), _c('circle', {
    attrs: {
      "cx": "2.43896",
      "cy": "13.5",
      "r": "1.5",
      "transform": "rotate(90 2.43896 13.5)",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }