<template>
    <div class="style-preferences-theme-editor-color-option">
        <div class="style-preferences-theme-editor-color-option__layout">
            <div class="style-preferences-theme-editor-color-option__name">
                <slot/>
            </div>
            <div class="style-preferences-theme-editor-color-option__color-preview-wrapper">
                <v-menu
                        transition="slide-y-transition"
                        content-class="white"
                        offset-y
                        nudge-bottom="10"
                        :close-on-content-click="false"
                >
                    <template #activator="{on}">
                        <div
                                class="style-preferences-theme-editor-color-option__color-preview"
                                v-on="on"
                                :style="colorPreviewStyle"
                        >
                        </div>
                    </template>
                    <template #default>
                        <v-color-picker
                                v-model="colorModel"
                                hide-mode-switch
                                mode="hexa"
                        />
                    </template>
                </v-menu>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StylePreferencesThemeEditorColorOption",
        props: {
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            colorPreviewStyle() {
                return {
                    backgroundColor: this.colorModel
                }
            },
            colorModel: {
                get() {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .style-preferences-theme-editor-color-option {
        &:not(&:last-of-type) {
            margin-bottom: 12px;
        }

        &__layout {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__name {
            font-weight: 400;
            font-size: 1rem;
            color: var(--v-bluish_gray_500-base);
        }

        &__color-preview-wrapper {
            border-radius: 1000px;
            background-color: white;
            box-shadow: 0px 1px 4px 0px var(--v-gray_400-base);

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 3px;
        }

        &__color-preview {
            border-radius: 100px;

            cursor: pointer;

            width: 24px;
            height: 24px;
            background-color: red;
            border: 1px solid #e6e6e6;
        }
    }
</style>