<template>
  <SharedView
      editable
      :columns="columns"
      :rows="rows"
      :radio="!fieldProperties.multiple_selection"
      @remove-column="removeColumn($event)"
      @remove-row="removeRow($event)"
  >
    <template #addColumnButton>
      <div
          :class="[
                        'generic-field__escape-form-font',
                        'matrix-field-view__add-column-button',
                        'visible-when-field-editing',
                        !isEditingActive && 'visible-when-field-editing--hidden'
                    ]"
          @click="appendColumn"
      >
        <span>+</span>
      </div>
    </template>
    <template #addOptionButton>
      <GenericAddOptionButton
          :is-editing-active="isEditingActive"
          @add-option="appendRow"
      >Add question
      </GenericAddOptionButton>
    </template>
  </SharedView>
</template>

<script>
import {
  editable_field_view_mixin,
  useEditableFieldViewExtension
} from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

export default {
  name: "Editable__Matrix",
  components: {
    GenericAddOptionButton: () => import('@/components/Fields/EditableField/EditableFieldViewUtils/GenericAddOptionButton'),
    SharedView: () => import('./SharedView'),
  },
  mixins: [editable_field_view_mixin],
  setup(props, context) {
    const extension = useEditableFieldViewExtension(props, context)

    const rows = props.fieldObj.content.rows
    const columns = props.fieldObj.content.columns

    const appendRow = () => rows.push(extension.fieldConst.UTIL.EMPTY_ROW_TEMPLATE())
    const appendColumn = () => columns.push(extension.fieldConst.UTIL.EMPTY_COLUMN_TEMPLATE())

    const removeRow = index => rows.splice(index, 1)
    const removeColumn = index => columns.splice(index, 1)

    return {
      ...extension,

      rows,
      columns,

      appendRow,
      appendColumn,

      removeRow,
      removeColumn
    }
  }
}
</script>