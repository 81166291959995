<template>
    <div>
        <SummaryFieldViewHeading
                :source-obj="latestSourceObj"
                :answered="filteredSubmissions.length"
                :submissions-count="totalResponse"
        />


        <div class="summary-analytics-view__field-view-wrapper">
            <div class="generic-text-summary-field-view">
                <p
                        v-for="({submissionObj}, submissionIndex) in filteredSubmissions"
                        class="generic-text-summary-field-view__answer"
                        :key="submissionIndex"
                >
                    {{ '&bull; ' + submissionObj.value}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin from './Utils/summary_answer_field_view_mixin'
    export default {
        name: "GenericTextSummaryFieldView",
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => value?.toLocaleString()?.length)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .generic-text-summary-field-view {
        &__answer {
            font-size: .875rem;
            color: var(--v-grayscale_black-base);
            line-height: 1.6;
            margin-bottom: 11px !important;

            overflow-y: auto;
            max-height: 200px;
        }
    }
</style>