var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F8F0E2"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.1067 18.1747C14.0546 18.1747 15.1502 17.9161 15.6591 17.6781L15.3718 16.7795C15.0271 16.9026 14.0792 17.2021 13.16 17.2021C10.3983 17.2021 8.91696 15.6797 8.91696 12.955C8.91696 10.3451 10.3819 8.65856 13.0082 8.65856C15.4088 8.65856 16.8409 10.2015 16.8368 12.2861C16.8409 14.067 16.6111 14.8672 15.8643 14.8672C15.4416 14.8672 15.2077 14.5225 15.2077 14.0916V10.2836H14.1982V10.7432H14.1449C14.0218 10.382 13.3077 10.0579 12.4501 10.1605C11.2642 10.3 10.2465 11.2807 10.2465 12.8975C10.2465 14.5841 11.1083 15.7125 12.4911 15.7659C13.4268 15.8069 14.0341 15.3719 14.2269 14.8877H14.2762C14.3747 15.5484 15.0887 15.8398 15.7904 15.8151C17.5426 15.7823 17.875 14.0588 17.875 12.3271C17.875 9.8568 16.1843 7.70654 13.0492 7.70654C9.76229 7.70654 7.82953 9.71317 7.82542 12.9468C7.82953 16.2747 9.71305 18.1747 13.1067 18.1747ZM12.7291 14.7605C11.6581 14.7605 11.2888 13.8413 11.2847 12.8483C11.2888 11.9045 11.8182 11.1535 12.7456 11.1535C13.7509 11.1535 14.2023 11.5967 14.2064 12.8442C14.2105 14.1245 13.7961 14.7605 12.7291 14.7605Z",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }