var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-date-time-picker"
  }, [_c('div', {
    staticClass: "f-date-time-picker__body"
  }, [_vm.date && _vm.time ? [_c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab', [_c('v-icon', [_vm._v("mdi-calendar")])], 1), _c('v-tab', {
    attrs: {
      "disabled": !_vm.selectedDate
    }
  }, [_c('v-icon', [_vm._v("mdi-clock-time-four-outline")])], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab-item', [_c('DatePickerComponent', {
    on: {
      "input": function ($event) {
        _vm.activeTab = 1;
      }
    },
    model: {
      value: _vm.selectedDate,
      callback: function ($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('v-tab-item', [_c('TimePickerComponent', {
    model: {
      value: _vm.selectedTime,
      callback: function ($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  })], 1)], 1)] : [_vm.date ? _c('DatePickerComponent', {
    model: {
      value: _vm.selectedDate,
      callback: function ($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  }) : _vm.time ? _c('TimePickerComponent', {
    model: {
      value: _vm.selectedTime,
      callback: function ($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  }) : _vm._e()]], 2), _vm.mandatory ? _c('div', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": ""
    }
  }, [_vm._v("Clear ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    }
  }, [_vm._v("OK ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }