<template>
    <div class="form-saving-status">
        <v-fade-transition hide-on-leave group>
            <div v-if="isFormSaving" class="form-saving-status" key="saving">Saving...</div>
            <div v-else-if="isFormSaved" class="form-saving-status__saved" key="saved">
                <CheckIcon size="20" stroke-width="1.5"/>
                Changes were saved
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {CheckIcon} from 'vue-feather-icons'

    export default {
        name: "FormAutosaveComponent",
        components: {
            CheckIcon
        },
        computed: {
            ...mapGetters('edit/save', ['isFormSaving', 'isFormSaved'])
        }
    }
</script>

<style lang="scss" scoped>
    .form-saving-status {
        margin-right: 4px;

        font-weight: 400;
        font-size: .875rem;
        color: var(--v-gray_400-base);
        line-height: 1.0625rem;

        &__saving, &__saved {
            display: flex;
        }

        &__saved {
            svg {
                margin-right: 6px;
            }
        }
    }
</style>