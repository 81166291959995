import billing_details_form_mixin from './billing_details_form_mixin'
import constants from '@/constants/constants'
import { VListItem, VListItemIcon, VListItemContent } from 'vuetify/lib/components'

export default {
    name: "BillingDetailsForm_Country",
    mixins: [billing_details_form_mixin],
    render(h, ctx) {
        return [
            h('p', {class: {'subtitle-2': true, 'mb-1': true}}, 'Country'),
            h('f-autocomplete', {
                attrs: {
                    items: constants.COUNTRY_LIST,
                    itemValue: item => item.code,
                    filter: (item, search) => item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
                    ...ctx.props.bind.country.value.props
                },
                on: ctx.props.bind.country.value.on,
                scopedSlots: {
                    selection: ({item}) => h(
                        'span',
                        {class: {'d-flex': true, 'align-center': true}},
                        [
                            h('f-country-flag', {class: {'mr-2': true}, props: {code: item.code}}),
                            item.name
                        ]
                    ),
                    item: ({on, attrs, item}) => h(
                        VListItem,
                        {
                            props: attrs,
                            on: on,
                        },
                        [
                            h(
                                VListItemIcon,
                                {},
                                [
                                    h('f-country-flag', {
                                        class: {'mr-2': true},
                                        props: {code: item.code}
                                    })
                                ]
                            ),
                            h(
                                VListItemContent,
                                {},
                                item.name
                            )
                        ]
                    )
                }
            })
        ]
    }
}