<template>
    <div
            class="FSPVariablesInjector"
            :class="constants.FSP.VARIABLES_INJECTOR_CLASS(fspModuleLocationPath)"
            :style="finalVariablesObject"
    >
        <slot/>
    </div>
</template>

<script>
    import { computed } from '@vue/composition-api'

    export default {
        name: "FSPVariablesInjector",
        props: {
            fspModuleLocationPath: {
                type: String,
                required: true
            }
        },
        setup(props, {root}) {
            const finalVariablesObject = computed(() => ({
                ...root.$store.getters[`${props.fspModuleLocationPath}/getComposedPrimaryVariables`],
                ...root.$store.getters[`${props.fspModuleLocationPath}/getComposedSecondaryFieldsVariables`]
            }))

            return {
                finalVariablesObject
            }
        },
    }
</script>

<style lang="scss">
    .FSPVariablesInjector {
        display: contents;
    }
</style>