<template>
  <AppBarTemplate>
    <template #left>
      <AppBarLogoComponent/>
    </template>
    <template #center>
      <AppNavigationComponent/>
    </template>
    <template #right>
      <v-btn class="btn-upgrade"
             @click="goToCheckout"
             v-if="isFreePlan"
             color="#EBE8FF"
             rounded
      >
        Upgrade plan
      </v-btn>
      <ProfileComponent/>
    </template>
  </AppBarTemplate>
</template>

<script>
import {mapGetters} from "@/xstore";

export default {
  name: "AppBar",
  components: {
    AppBarTemplate: () => import("../AppBarTemplate"),
    AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
    AppNavigationComponent: () => import("../Elements/AppNavigationComponent"),
    ProfileComponent: () => import('../Elements/ProfileComponent')
  },
  methods: {
    goToCheckout() {
      return this.$router.push({name: this.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN})
    },
  },
  computed: {
    ...mapGetters('user/plan/current_subscription', [
      'getCurrentSubscription',
    ]),
    isFreePlan() {
      return this.getCurrentSubscription?.productID === "free"
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-upgrade {
  margin-right: 31px;

  ::v-deep span {
    color: #6C4BFF;
    font-weight: 600 !important;
  }
}
</style>