<template>
    <svg width="78" height="55" viewBox="0 0 78 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.739136" y="1.47852" width="75.913" height="52.0435" rx="7.45652" fill="#F5F6F8"/>
        <rect x="28.75" y="18.2168" width="19.8913" height="2.65217" rx="1.32609" fill="currentColor"/>
        <rect x="28.75" y="23.5215" width="19.8913" height="7.95652" rx="1.32609" fill="currentColor"/>
        <rect x="35.3805" y="34.1289" width="6.63043" height="2.65217" rx="1.32609" fill="currentColor"/>
        <rect class="border" stroke-width="1" x="0.739136" y="1.47852" width="75.913" height="52.0435" rx="7.45652" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: 'MiddleLayoutIcon'
    }
</script>

<style scoped>

</style>