<template>
    <f-fluid-container>
        <div class="interactive-field-constructor">
            <div class="interactive-field-constructor__scalable-wrapper">
                <div class="interactive-field-constructor__main-wrapper">
                    <div class="interactive-field-constructor__general-field-view">
                        <div class="interactive-field-constructor__field-number interactive-field-constructor__field-number--desktop">
                            {{fieldNumber}}
                        </div>
                        <div class="interactive-field-constructor__field-number interactive-field-constructor__field-number--mobile">
                          Question {{fieldNumber}}
                        </div>
                        <div class="interactive-field-constructor__field-view-wrapper">
                            <div class="interactive-field-constructor__field-view">
                                <slot name="field-view"></slot>
                            </div>
                            <div class="interactive-field-constructor__action-wrapper">
                                <InteractiveFormActionButtonBlock
                                        :with-press-enter="fieldTypeConst.WITH_PRESS_ENTER"
                                        @click="actionButtonClicked"
                                >
                                  <template #button-content>
                                    <slot name="button-content">
                                    </slot>
                                  </template>
                                </InteractiveFormActionButtonBlock>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <slot name="extension"></slot>
        </div>
    </f-fluid-container>
</template>

<script>
    import { inject } from '@vue/composition-api'
    import {mapGetters, useMapActions} from '@/xstore'

    export default {
        name: "InteractiveFieldConstructorTemplate",
        components: {
            InteractiveFormActionButtonBlock: () => import('./InteractiveFormActionButtonBlock')
        },
        computed: {
          ...mapGetters('edit/submit_preview', [
            'getMainPagesWithIndexes', 'getCurrentMainPageIndex'
          ]),
        },
        props: {
            fieldNumber: {
                type: [Number, String],
                required: true
            },
            editable: {
                type: Boolean,
                default: false
            },
            submittable: {
                type: Boolean,
                default: false
            },
            fieldTypeConst: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            if (props.submittable)
                return {
                    ...useMapActions(inject('formSubmitStoreModulePath'), [
                        'goToNextPage'
                    ]),

                    ...useMapActions(inject('formSubmitStoreModulePath'), [
                        'validateCurrentMainPage'
                    ])
                }
        },
        methods: {
            actionButtonClicked() {
                if (this.validateCurrentMainPage)
                    this.validateCurrentMainPage()
                        .then(r => {
                            if (r)
                                this.goToNextPage()
                        })
            }
        }
    }
</script>