<template>
    <div class="dropdown-field-view generic-text-field-view">
      <f-autocomplete
          :placeholder="sourceObj.content.placeholder"
          :items="formattedOptions"
          :item-value="item => item.index"
          :filter="(item, search) => item.optionName.toLocaleLowerCase().includes(search.toLocaleLowerCase())"
          class="generic-text-field-view__input-field"
          :value="submissionObj.value[0]"
          @input="submissionObj.value = [$event]"
          hide-details
          :outlined="isFormStatic"
          dense
          :menu-props="{
            bottom: true,
          }"
          attach
      >
        <template #item="{on, attrs, item}">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-icon v-if="isReadyList">
              <component
                  :is="currentSelectedReadyList.ICON_COMPONENT.NAME"
                  v-bind="currentSelectedReadyList.ICON_COMPONENT.BIND(item.initialItem, {overrideCountryFlagSize: 'm'})"
              />
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.optionName }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #selection="{item}">
                <span class="d-flex align-center">
                    <component
                        v-if="isReadyList"
                        :is="currentSelectedReadyList.ICON_COMPONENT.NAME"
                        class="mr-2"
                        v-bind="currentSelectedReadyList.ICON_COMPONENT.BIND(item.initialItem)"
                    />
                    {{ item.optionName }}
                </span>
        </template>
        <template #append>
          <ChevronDownIcon/>
        </template>
      </f-autocomplete>
    </div>
</template>

<script>
    import {
        submittable_field_view_mixin,
        useSubmittableFieldViewExtension
    } from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
    import { computed } from '@vue/composition-api/dist/vue-composition-api'
    import { isString } from 'lodash'

    export default {
        name: "Submittable__Dropdown",
        components: {
            ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon'),
        },
        mixins: [submittable_field_view_mixin],
        setup(props, ctx) {
            const extension = useSubmittableFieldViewExtension(props, ctx)

            const isReadyList = computed(() => isString(props.sourceObj.content.options))
            const currentSelectedReadyList = computed(() => isReadyList.value ? extension.fieldConst.UTIL.READY_LISTS[props.sourceObj.content.options] : undefined)

            const formattedOptions = computed(() => {
                let formattedOptions

                if (isReadyList.value)
                    formattedOptions = currentSelectedReadyList.value.LIST.map((item, index) => ({
                        optionName: item.name,
                        initialItem: item,
                        index
                    }))
                else
                    formattedOptions = props.sourceObj.content.options.map((optionName, index) => ({
                        optionName,
                        index,
                    }))

                if (props.sourceObj.properties.randomize)
                    return formattedOptions.sort(() => Math.random() - 0.5)

                if (props.sourceObj.properties.sort_options === 'alphabet')
                    return formattedOptions.sort((a, b) => a.optionName.localeCompare(b.optionName))

                return formattedOptions
            })

            return {
                ...extension,

                isReadyList,
                currentSelectedReadyList,

                formattedOptions
            }
        }
    }
</script>