var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "matrix-field-view"
  }, [_c('div', {
    staticClass: "matrix-field-view__layout matrix-field-view__layout--desktop"
  }, [_c('div', {
    staticClass: "matrix-field-view__table-wrapper"
  }, [_c('table', [_c('thead', {
    staticClass: "matrix-field-view__table-head"
  }, [_c('tr', {
    staticClass: "matrix-field-view__table-row"
  }, [_c('td', {
    staticStyle: {
      "width": "300px"
    }
  }), _vm._l(_vm.columns, function (column, columnIndex) {
    return _c('f-template-data-transfer', {
      key: 'column' + columnIndex,
      attrs: {
        "focused": false
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var dataStash = _ref.dataStash;
          return [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref2) {
                var hover = _ref2.hover;
                return [_c('td', {
                  class: ['matrix-field-view__column-cell', _vm.editable && 'matrix-field-view__remove-column-btn']
                }, [_c('div', [_c('XIcon', {
                  class: ['matrix-field-view__remove-icon', _vm.editable && _vm.rows.length !== 1 && (dataStash.focused || hover) && 'matrix-field-view__remove-icon--visible'],
                  on: {
                    "click": function ($event) {
                      return _vm.$emit('remove-column', columnIndex);
                    }
                  }
                }), _c('f-simple-contenteditable-input', {
                  attrs: {
                    "prevent-highlighting": "",
                    "placeholder": _vm.editable ? 'Title' : '',
                    "readonly": !_vm.editable
                  },
                  on: {
                    "update:focused": function ($event) {
                      dataStash.focused = $event;
                    }
                  },
                  model: {
                    value: column.text,
                    callback: function ($$v) {
                      _vm.$set(column, "text", $$v);
                    },
                    expression: "column.text"
                  }
                })], 1)])];
              }
            }], null, true)
          })];
        }
      }], null, true)
    });
  })], 2)]), _c('tbody', _vm._l(_vm.rows, function (row, rowIndex) {
    return _c('f-template-data-transfer', {
      key: 'row' + rowIndex,
      attrs: {
        "focused": false
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          var dataStash = _ref3.dataStash;
          return [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref4) {
                var hover = _ref4.hover;
                return [_c('tr', {
                  staticClass: "matrix-field-view__table-row"
                }, [_c('td', {
                  staticClass: "matrix-field-view__column-cell matrix-field-view__row-text"
                }, [_c('f-simple-contenteditable-input', {
                  attrs: {
                    "prevent-highlighting": "",
                    "readonly": !_vm.editable,
                    "placeholder": _vm.editable ? 'Question here..' : ''
                  },
                  on: {
                    "update:focused": function ($event) {
                      dataStash.focused = $event;
                    }
                  },
                  model: {
                    value: row.text,
                    callback: function ($$v) {
                      _vm.$set(row, "text", $$v);
                    },
                    expression: "row.text"
                  }
                }), _c('XIcon', {
                  class: ['matrix-field-view__remove-row-button', 'matrix-field-view__remove-icon', _vm.editable && _vm.rows.length !== 1 && (dataStash.focused || hover) && 'matrix-field-view__remove-icon--visible'],
                  on: {
                    "click": function ($event) {
                      return _vm.$emit('remove-row', rowIndex);
                    }
                  }
                })], 1), _vm._l(_vm.columns.length, function (_, columnIndex) {
                  return _c('td', {
                    key: 'row' + rowIndex + 'column' + columnIndex,
                    staticClass: "matrix-field-view__column-cell"
                  }, [_c('f-checkbox', {
                    attrs: {
                      "radio": _vm.radio,
                      "disabled": _vm.editable || _vm.preview,
                      "value": _vm.editable || _vm.preview ? false : _vm.submissionObjValue[rowIndex].includes(columnIndex)
                    },
                    on: {
                      "input": function (e) {
                        return e ? _vm.$emit('toggle-selection:add', {
                          rowIndex: rowIndex,
                          columnIndex: columnIndex
                        }) : _vm.$emit('toggle-selection:remove', {
                          rowIndex: rowIndex,
                          columnIndex: columnIndex
                        });
                      }
                    }
                  })], 1);
                })], 2)];
              }
            }], null, true)
          })];
        }
      }], null, true)
    });
  }), 1)])]), _vm._t("addColumnButton")], 2), _vm._t("addOptionButton"), !_vm.editable && !_vm.preview ? _c('MobileView', {
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "submission-obj-value": _vm.submissionObjValue
    },
    on: {
      "toggle-selection": function (e) {
        return e.state ? _vm.$emit('toggle-selection:add', e) : _vm.$emit('toggle-selection:remove', e);
      }
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }