<template>
    <div v-if="layout && layout.rows.length" class="static-main-page-fields-layout static-main-page-fields-layout--editable" :ref="layout.refID">
        <v-row
                v-for="row in layout.rows"
                :ref="row.refID"
                :key="row.refID"
        >
            <v-col
                    v-for="element in row.elements"
                    :cols="element.width * (12 / constants.STATIC_FORM_COLS_COUNT)"
                    :ref="element.refID"
                    :key="element.refID"
            >
                <StaticFieldConstructor
                        v-if="element instanceof Field"
                        :key="element.fieldID"
                        :field-id="element.fieldID"
                        :field-width="element.width"
                        :is-expanding-transition-going="element.isExpandingTransitionGoing"

                        @start-dragging="startDragging($event, row, element)"
                        @start-expanding="startExpanding($event, row, element)"
                />
                <div
                        v-else-if="element instanceof ForbiddenDropzone"
                        class="static-main-page-fields-layout__dropzone static-main-page-fields-layout__dropzone--forbidden"
                ></div>
                <div
                        v-else-if="element instanceof Dropzone"
                        class="static-main-page-fields-layout__dropzone"
                        :class="closeToDropzoneArea && 'static-main-page-fields-layout__dropzone--close-to-area'"
                ></div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import useBaseClasses from './base_classes'
    import useDragWorkflow from './drag_workflow'
    import {  onBeforeMount, onBeforeUnmount, reactive, ref, watch } from '@vue/composition-api'
    const deepEqual = require('deep-equal')

    export default {
        name: "StaticFieldsDragManager",
        components: {
            StaticFieldConstructor: () => import('../../../StaticField/StaticFieldConstructor')
        },
        props: {
            pageObj: {
                type: Object,
                required: true
            }
        },
        setup(props, context) {
            const {root, emit} = context
            onBeforeMount(() => {
                root.draggableEventBus.$on('mousemove', mouseMoved)
                root.draggableEventBus.$on('mouseup', mouseUpped)
                root.draggableEventBus.$on('start-adding-new-field', startAddingNewField)

            })
            onBeforeUnmount(() => {
                root.draggableEventBus.$off('mousemove', mouseMoved)
                root.draggableEventBus.$off('mouseup', mouseUpped)
                root.draggableEventBus.$off('start-adding-new-field', startAddingNewField)
            })
            const mousePos = reactive({x: 0, y: 0})


            const BaseClasses = useBaseClasses(props, context, mousePos)
            const {Field, Layout} = BaseClasses


            let layout = new Layout()
            watch(
                () => props.pageObj?.rows,
                (newV, oldV) => {
                    if (!deepEqual(newV, oldV)) {
                        layout.refresh()
                    }
                }
            )


            function mouseMoved({absoluteLeft: x, absoluteTop: y}) {
                if (isExpandingActive.value || isDraggingActive.value) {
                    mousePos.x = x
                    mousePos.y = y
                }

                if (isExpandingActive.value)
                    layout.resizeExpandingField()
                else if (isDraggingActive.value) {
                    layout.insertDropzone()
                }
            }

            function mouseUpped() {
                if (isExpandingActive.value) {
                    isExpandingActive.value = false
                    root.draggableEventBus.$emit('changecursor')
                    layout.stopExpanding()
                }
            }


            const isExpandingActive = ref(false)
            const startExpanding = ({fieldType}, row, field) => {
                isExpandingActive.value = true
                root.draggableEventBus.$emit('changecursor', 'e-resize')
                layout.setExpandingField(field, row, root.constants.FIELD_FROM_TYPE[fieldType].STATIC_FORM_MIN_WIDTH)
            }


            const DragWorkflow = useDragWorkflow(props, context, mousePos)
            const isDraggingActive = ref(false)
            const startDragging = ({event, fieldType, bounding}, row, element) => {
                isDraggingActive.value = true
                root.draggableEventBus.$emit('changecursor', 'grabbing')
                layout.setDraggingField(element, row, root.constants.FIELD_FROM_TYPE[fieldType].STATIC_FORM_MIN_WIDTH)
                DragWorkflow.startDragging(
                    {event, fieldType, bounding},
                    () => {
                        isDraggingActive.value = false
                        layout.prepareStopDragging()
                            .then(fieldBounding => {
                                DragWorkflow.dragWorkflowBind.finalDropzoneBounding = fieldBounding
                            })
                    },
                    () => layout.stopDragging()
                )
            }
            function startAddingNewField({event, fieldType, bounding}) {
                isDraggingActive.value = true
                root.draggableEventBus.$emit('changecursor', 'grabbing')
                emit('start-adding-new-field')
                root.$store.dispatch('edit/fields/createNewFormField', fieldType)
                    .then(newFieldId => {
                        layout.draggingField = new Field(newFieldId, root.constants.FIELD_FROM_TYPE[fieldType].STATIC_FORM_MIN_WIDTH)
                        layout.draggingField.minWidth = root.constants.FIELD_FROM_TYPE[fieldType].STATIC_FORM_MIN_WIDTH
                        DragWorkflow.startDragging({event, fieldType, bounding},
                            () => {
                                isDraggingActive.value = false
                                if (layout.isCursorInside)
                                    layout.prepareStopDragging()
                                        .then(fieldBounding => {
                                            DragWorkflow.dragWorkflowBind.finalDropzoneBounding = fieldBounding
                                        })
                                else
                                    DragWorkflow.dragWorkflowBind.fadeOut = true
                            },
                            () => {
                                emit('stop-adding-new-field')
                                if (layout.isCursorInside)
                                    layout.stopDragging()
                                else
                                    root.$store.dispatch('edit/fields/removeFormField', layout.draggingField.fieldID)
                            })
                    })
            }


            const closeToDropzoneArea = ref('')
            watch(
                [
                    () => DragWorkflow.dragWorkflowBind.messagesToDragManager.draggableFieldCenterCoords?.x,
                    () => DragWorkflow.dragWorkflowBind.messagesToDragManager.draggableFieldCenterCoords?.y,
                ],
                ([x, y]) => {
                    const areaHeight = 70
                    const areaWidth = 90
                    const dropzoneBounding = layout.draggingField?.dropzoneObj?.bounding
                    if (!dropzoneBounding)
                        return closeToDropzoneArea.value = false
                    if (dropzoneBounding.left + dropzoneBounding.width / 2 - areaWidth / 2 <= x && x <= dropzoneBounding.left + dropzoneBounding.width / 2 + areaWidth / 2
                        && dropzoneBounding.top + dropzoneBounding.height / 2 - areaHeight / 2 <= y && y <= dropzoneBounding.top + dropzoneBounding.height / 2 + areaHeight / 2)
                        return closeToDropzoneArea.value = true
                    return closeToDropzoneArea.value = false
                }
            )

            return {
                ...BaseClasses,
                layout,
                startExpanding,
                startDragging,
                closeToDropzoneArea,
                isDraggingActive
            }
        }
    }
</script>

<style lang="scss" scoped>
    .static-form-editor__layout {
        height: 100%;
        margin: 40px 43px;
        flex-grow: 1;

        .row {
            margin: 0 !important;
        }

        .col {
            max-width: 100% !important;
            padding: 20px 17px;
        }

        .static-form-editor__dropzone {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            min-height: 120px;

            &:not(.static-form-editor__dropzone--forbidden) {
                border-width: 1px;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23473DD6FF' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                background-color: white;
                transition: background-color .2s ease;
            }

            &.static-form-editor__dropzone--forbidden {
                background-color: var(--v-red_100-base);
                opacity: 0.5;
            }

            &--close-to-area {
                border-color: var(--v-blue_400-base);
                background-color: var(--v-blue_100-base);
            }

            &--releasing {
                border-style: solid;
                border-color: var(--v-blue_300-base);
                background-color: var(--v-blue_100-base);
            }
        }
    }
</style>