<template>
  <FormParametersContentTemplate>
    <template #title>
      <div class="form-settings-header">
        <v-slide-x-reverse-transition mode="out-in">
          <ChevronLeftIcon v-if="integrateModel" class="cursor-pointer ml-n1" key="email-notifications-icon"
            @click="integrateModel = false" />
          <BoxIcon v-else class="ml-n1" key="general-settings-icon" />
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition mode="out-in">
          <span key="app-connections-header" v-if="integrateModel">
            Integrate {{ integrationsApp[selectedIndex].title }}
          </span>
          <span key="integration-header" v-else>
            Integrations
          </span>
        </v-slide-x-reverse-transition>
      </div>
    </template>
    <template #content>
      <div class="form-settings-content thin-scrollbar">
        <v-slide-x-reverse-transition mode="out-in">
          <IntegrateSettings v-if="integrateModel" :app="integrationsApp[selectedIndex]" @connection="connection"
            @disconnect="disconnect" :loader="showLoader" />
          <div v-else class="thin-scrollbar">
            <GeneralFormIntegrations :apps-list="integrationsApp" @open-connect-settings="openConnectApp" />
          </div>
        </v-slide-x-reverse-transition>
      </div>
    </template>
  </FormParametersContentTemplate>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import OwnersFormActions from '@/utils/actions/owners_form_actions'
import FormSubmitActions from '@/utils/actions/form_submit_actions'

export default {
  name: 'FormSettings',
  components: {
    BoxIcon: () => import('vue-feather-icons/icons/BoxIcon'),
    ChevronLeftIcon: () => import('vue-feather-icons/icons/ChevronLeftIcon'),
    IntegrateSettings: () =>
      import('./FormIntegrationsElements/IntegrateSettings.vue'),
    FormParametersContentTemplate: () =>
      import('./Elements/FormParametersContentTemplate'),
    GeneralFormIntegrations: () =>
      import('./FormIntegrationsElements/GeneralFormIntergrations.vue'),
  },
  props: {
    formId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const formId = ref(props.formId)
    const showLoader = ref(false)
    const selectedIndex = ref(1)
    const integrateModel = ref(false)
    const integrationsApp = ref([
      {
        title: 'Zapier',
        subtitle: 'Sync Formsly to 3000+ platforms',
        connectDescription:
          'Forward incoming form submissions to other services using Zapier.',
        icon: '$zapier',
        connected: false,
      },
      {
        title: 'Google Sheets',
        //subtitle: 'Sync submissions with a Google Sheet',
        subtitle: 'Coming soon',
        icon: '$google_sheets',
        connected: false,
        disabled: true,
      },
      {
        title: 'Whatsapp',
        subtitle: 'Coming soon',
        icon: '$whatsapp_circle',
        connected: false,
        disabled: true,
      },
      {
        title: 'Airtable',
        subtitle: 'Coming soon',
        icon: '$airtable',
        connected: false,
        disabled: true,
      },
      {
        title: 'Notion',
        subtitle: 'Coming soon',
        icon: '$notion',
        connected: false,
        disabled: true,
      },
    ])
    let newWindow;
    console.log('formId', formId);
    function connection(status) {
      const formData = OwnersFormActions.fetchFormData(this.$route.params.formId);
      console.log('formdata', formData);
      this.$store.dispatch('fetchFormWithFormExistenceCheckAndLoadingScreenDelay', this.$route.params.formId)
      this.initializeSseEvent(status);
      console.log('connect');
      const url = 'https://zapier.com/apps/formsly/integrations';
      newWindow = window.open(url, '_blank', 'width=800,height=600');
      if (newWindow) {
        newWindow.focus();
      }
    }

    function initializeSseEvent(status) {

      const eventSource = new EventSource('https://jvkgtqiv26nwifmbhyyxonrowe0zilrs.lambda-url.us-east-1.on.aws/sseHandler');
      console.log('EventSource initialized', eventSource);

      function isValidJSON(data) {
        try {
          JSON.parse(data);
          return true;
        } catch {
          return false;
        }
      }

      eventSource.onopen = () => {
        console.log('Connection to server opened.');
      };

      eventSource.onmessage = (event) => {
        try {
          if (event.data === "Connection established") {
            console.log('Connection established message received');
            return; // Ignore the connection message
          }
          // Check if event.data is valid JSON
          if (isValidJSON(event.data)) {
            // Parse the outer JSON string
            const outerData = JSON.parse(event.data);
            console.log('Outer parsed:', outerData);

            // Parse the inner JSON string if it exists
            if (outerData.data && isValidJSON(outerData.data)) {
              const innerData = JSON.parse(outerData.data);
              console.log('Inner parsed:', innerData);

              if (innerData.message) {
                console.log('Message received:', innerData.message);
                integrationsApp.value[selectedIndex.value].connected = status;
                OwnersFormActions.fetchFormData(this.$route.params.formId)
                  .then(response => {
                    if (response.zapier_status === 'true') {
                      integrationsApp.value[selectedIndex.value].connected = true;
                    } else {
                      integrationsApp.value[selectedIndex.value].connected = false;
                    }
                  })
                  .catch(error => {
                    console.log('error', error);
                  });
                if (newWindow) {
                  newWindow.close();
                }
                // Close the connection after processing the response
                eventSource.close();
                console.log('Connection to server closed.');
              }
            } else {
              console.error('Received data is not valid JSON:', outerData.data);
            }
          } else {
            console.error('Received data is not valid JSON:', event.data);
          }
        } catch (error) {
          console.error('Error processing message:', error);
          // Close the connection in case of an error
          eventSource.close();
          console.log('Connection to server closed due to error.');
        }
      };

      eventSource.onerror = (event) => {
        if (event.readyState === EventSource.CLOSED) {
          console.log('Connection was closed by the server.');
        }
      };

    }


    function disconnect(status) {
      showLoader.value = true

      FormSubmitActions.disconnectZapier(this.$route.params.formId)
        .then(response => {
          showLoader.value = false
          integrationsApp.value[selectedIndex.value].connected = status;
        })
        .catch(error => {
          showLoader.value = false
          integrationsApp.value[selectedIndex.value].connected = status;
        });

    }


    function openConnectApp(title) {
      selectedIndex.value = integrationsApp.value.findIndex(
        el => el.title === title
      )
      integrateModel.value = true
    }

    //this.getFormData();
    onMounted(() => {
      console.log('formID', formId.value);
      OwnersFormActions.fetchFormData(formId.value)
        .then(response => {
          if (response.zapier_status == 'true') {
            integrationsApp.value[0].connected = true;
          } else {
            integrationsApp.value[0].connected = false;
          }

        })
        .catch(error => {
          console.log('error', error);
        });

    });
    // onMounted(async () => {
    //   await router.isReady()
    //   console.log('route.path', route.path)
    //   getFormData();
    // })

    return {
      selectedIndex,
      showLoader,
      integrationsApp,
      integrateModel,
      connection,
      disconnect,
      openConnectApp,
      initializeSseEvent,
    }
  },
}
</script>

<style lang="scss" scoped>
.background-light {
  background-color: #f5f6f8;
}

.form-settings-header,
.form-settings-content,
.form-settings-actions {
  padding: 0 18px;
}

.form-settings-header {
  padding-top: 10px;
}

.form-settings-content {
  width: calc(514px - 2 * 20px);
  overflow-y: auto;
  padding: 18px;
}

.form-settings-actions {
  flex-grow: 1;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
</style>
