import SharedView from "@/components/Fields/FieldsViews/FileUpload/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__FileUpload',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    limitFileSize: ctx.props.fieldObj.properties.limit_file_size.max,
                    disabled: true,
                }
            }
        )
    }
}