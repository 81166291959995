<template>
    <v-alert
            transition="fade"
            v-model="alertModel"
            color="red"
            class="mx-auto  mb-3"
            dense
            dark
            text
            dismissible
            max-width="300"
    >{{alertText}}
    </v-alert>
</template>

<script>
    import {ErrorAlertEventBus} from "./alert_mixin"

    export default {
        name: "ErrorAlertComponent",
        data: () => ({
            alertModel: false,
            alertText: ''
        }),
        created() {
            ErrorAlertEventBus.$on('show', errorText => {
                this.alertModel = true
                this.alertText = errorText
            })
        }
    }
</script>

<style scoped>

</style>