<template>
    <SharedView
      disabled
      :limit-file-size="fieldProperties.limit_file_size.max"
    />
</template>

<script>
    import editable_field_view_mixin from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: 'Editable__FileUpload',
        components: {
            SharedView: () => import('./SharedView')
        },
        mixins: [editable_field_view_mixin],
    }
</script>