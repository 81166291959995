var render = function () {
  var _vm$getPlansObject$_v;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    staticClass: "checkout-purchase-completed-page"
  }, [_c('div', {
    staticClass: "checkout-purchase-completed-page__confetti"
  }, [_c('LottieDefaultContainer', {
    attrs: {
      "json": _vm.json
    }
  })], 1), _c('div', {
    staticClass: "checkout-purchase-completed-page__layout"
  }, [_c('div', {
    staticClass: "checkout-purchase-completed-page__logo"
  }, [_c('img', {
    attrs: {
      "src": require("../../../public/images/SuccessLogo.svg")
    }
  })]), _c('div', {
    staticClass: "checkout-purchase-completed-page__title"
  }, [_c('span', [_vm._v("Thank you for your purchase")])]), _vm.getPlansObject && _vm.getSelectedProduct && _vm.getSelectedBillingPeriod ? _c('div', {
    staticClass: "checkout-purchase-completed-page__subtitle"
  }, [_c('span', [_vm._v("Your account been upgraded to " + _vm._s((_vm$getPlansObject$_v = _vm.getPlansObject[_vm.getSelectedProduct]) === null || _vm$getPlansObject$_v === void 0 ? void 0 : _vm$getPlansObject$_v.name) + " " + _vm._s(_vm.upperFirst(_vm.getSelectedBillingPeriod) + 'ly') + ", You’ll be receiving an email shortly with purchase details")])]) : _vm._e(), _c('div', {
    staticClass: "checkout-purchase-completed-page__continue-btn-wrapper"
  }, [_c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": "",
      "to": {
        name: _vm.constants.PAGE_NAME_DASHBOARD
      }
    }
  }, [_vm._v(" Go to Homepage "), _c('ArrowRightIcon', {
    staticClass: "ml-2",
    attrs: {
      "size": "20"
    }
  })], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }