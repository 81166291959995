<template>
    <label
            :class="[
                'f-switch',
                disabled && 'f-switch--disabled',
                readonly && 'f-switch--readonly'
            ]"
    >
        <input
                v-model="internalValue"
                :disabled="!!(disabled || readonly)"
                type="checkbox"
                checked
        />
        <span class="f-switch__slider"></span>
    </label>
</template>

<script>
    export default {
        name: "Switch",
        props: {
            value: {
                type: Boolean,
                required: true
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            internalValue: vm.value,
            internalDisabled: false,
        }),
        watch: {
            internalValue(v) {
                if (v !== this.value)
                    this.$emit('input', v)
                this.$nextTick(() => {
                    if (this.value !== v)
                        this.internalValue = this.value
                })
            },
            value(v) {
                if (this.internalValue !== v)
                    this.$nextTick(() => {
                        this.internalValue = this.value
                    })
            }
        }
    }
</script>

<style lang="scss">
    .f-switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 20px;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .f-switch__slider {
                background-color: #3d66f6;
            }

            &:focus + .f-switch__slider {
                box-shadow: 0 0 1px #3d66f6;
            }

            &:checked + .f-switch__slider::before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }
        }

        &__slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #E5EAED;
            -webkit-transition: 0.2s;
            transition: 0.2s;

            border-radius: 34px;

            &::before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: 0.2s;
                transition: 0.2s;

                border-radius: 50%;
            }
        }

        &.f-switch--disabled, &.f-switch--readonly {
            .f-switch__slider {
                cursor: default;
            }
        }
    }
</style>