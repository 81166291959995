<template>
    <div class="splitted-analytics-table__inspecting-item__section">
        <p v-if="!builtinHeader" class="splitted-analytics-table__inspecting-item__section__header">
            {{ Utils.stripHtml(fieldTitle) }}
        </p>
        <component
                :is="componentView"
                :submission-obj="submissionObj"
                :source-obj="sourceObj"
        >
            <template #header>
                <p class="splitted-analytics-table__inspecting-item__section__header splitted-analytics-table__inspecting-item__section__header--builtin">
                    {{ Utils.stripHtml(fieldTitle) }}
                </p>
            </template>
        </component>
    </div>
</template>

<script>
    import {ref} from '@vue/composition-api'

    export default {
        name: "InspectingItemSection",
        props: {
            fieldTitle: {
                type: String,
                required: true
            },
            componentView: {
                type: Function,
                required: true
            },
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const builtinHeader = ref(false)

            props.componentView()
                .then(({default: componentModule}) => {
                    builtinHeader.value = !!componentModule?.BUILTIN_HEADER
                })

            return {
                builtinHeader
            }
        }
    }
</script>