var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "#039BE5"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.8665 23.7831L17.5192 28.6688C18.0162 28.6688 18.2314 28.4553 18.4895 28.199L20.8195 25.9722L25.6475 29.5079C26.5329 30.0014 27.1568 29.7415 27.3956 28.6933L30.5647 13.8436L30.5656 13.8428C30.8465 12.5338 30.0922 12.022 29.2295 12.3431L10.6018 19.4748C9.3305 19.9683 9.34975 20.677 10.3857 20.9981L15.1481 22.4794L26.2101 15.5577C26.7307 15.2129 27.204 15.4037 26.8147 15.7484L17.8665 23.7831Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }