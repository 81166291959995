var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BrowserTemplate', {
    on: {
      "infinite-scroll": function ($event) {
        return _vm.handleScroll($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('f-solo-search-input', {
          staticClass: "mt-6 mb-4",
          attrs: {
            "placeholder": "Search free high-resolution photos from Unsplash",
            "height": "40"
          },
          on: {
            "input": _vm.debounceUnsplashSearch
          },
          model: {
            value: _vm.searchUnsplashQuery,
            callback: function ($$v) {
              _vm.searchUnsplashQuery = $$v;
            },
            expression: "searchUnsplashQuery"
          }
        }), _vm.isErrorLimitRequest ? _c('v-input', {
          attrs: {
            "error-messages": "An error occurred, please try again later"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, _vm._l(_vm.unsplashResults, function (image, index) {
          return _c('v-col', {
            key: image.id + index,
            attrs: {
              "align-self": "center",
              "cols": "12",
              "md": "6",
              "lg": "6",
              "xl": "6",
              "sm": "6"
            }
          }, [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref) {
                var hover = _ref.hover;
                return [_c('v-img', {
                  staticClass: "rounded-lg result-image",
                  class: _vm.activeImage !== _vm.imageIdentifier(image) || 'active',
                  attrs: {
                    "width": "288px",
                    "height": "160px",
                    "src": image.urls.raw + '&w=300'
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.setActiveImage(image);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "placeholder",
                    fn: function () {
                      return [_c('v-row', {
                        staticClass: "fill-height ma-0",
                        attrs: {
                          "align": "center",
                          "justify": "center"
                        }
                      }, [_c('f-circle-loader', {
                        attrs: {
                          "size": "5"
                        }
                      })], 1)];
                    },
                    proxy: true
                  }], null, true)
                }, [[hover ? _c('span', {
                  staticClass: "image-author-name"
                }, [_vm._v("by "), _c('a', {
                  attrs: {
                    "href": image.user.links.html + '?' + _vm.unsplashUtmAttribution,
                    "target": "_blank"
                  }
                }, [_vm._v(" " + _vm._s(image.user.first_name) + " " + _vm._s(image.user.last_name))])]) : _vm._e()]], 2)];
              }
            }], null, true)
          })], 1);
        }), 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }