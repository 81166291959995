<template>
  <SharedView
      :show-file-uploader="!submissionObj.value"
      :limit-file-size="sourceObj.properties.limit_file_size.max"
      :accept-extensions="sourceObj.properties.advanced_file_types"
      @input="inputFile"
  >
    <div v-if="submissionObj.value" class="file-upload-field-view__selected-file">
      <div class="file-upload-field-view__selected-file__preview-wrapper">
        <v-img
            class="file-upload-field-view__selected-file__preview"
            :src="submissionObj.value._fileDataURL"
        >
          <template #placeholder>
            <f-circle-loader v-if="isImage" size="3"/>
          </template>
          <template #default>
            <FileIcon v-if="!isImage"/>
          </template>
        </v-img>
      </div>
      <div class="file-upload-field-view__selected-file__description">
        <div class="file-upload-field-view__selected-file__fullname">
          <span class="file-upload-field-view__selected-file__name">{{ getFileNameWithoutExtension }}</span>
          <span class="file-upload-field-view__selected-file__extension">{{ getFileExtension }}</span>
        </div>
        <span class="file-upload-field-view__selected-file__size">{{ submissionObj.value.size }}</span>
      </div>
      <XIcon
          class="file-upload-field-view__selected-file__remove-btn"
          @click="removeFile"
      />
    </div>
  </SharedView>
</template>

<script>
    import {
        submittable_field_view_mixin,
        useSubmittableFieldViewExtension
    } from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
    import { computed, inject } from '@vue/composition-api'

    export default {
        name: 'Submittable__FileUpload',
        mixins: [submittable_field_view_mixin],
        components: {
            FileIcon: () => import('vue-feather-icons/icons/FileIcon'),
            XIcon: () => import('vue-feather-icons/icons/XIcon'),
            SharedView: () => import('./SharedView')
        },
        setup(props, {root}) {
            const extension = useSubmittableFieldViewExtension(props)

            const storeModulePath = inject('formSubmitStoreModulePath')

            extension.beforeSubmit(() => {
                if (props.submissionObj.value) {
                    const submissionObjValueCopy = root.Utils.deepCloneObject(props.submissionObj.value)

                    return Promise.all([
                        root.$store.dispatch(storeModulePath + '/submission/updateFormSubmissionField', {
                            fieldID: props.fieldId,
                            updatedSubmission: (() => {
                                const submissionObjValueDoubleCopy = root.Utils.deepCloneObject(submissionObjValueCopy)

                                delete submissionObjValueDoubleCopy._fileDataURL
                                delete submissionObjValueDoubleCopy._fileType
                                delete submissionObjValueDoubleCopy._fileObj

                                return {value: submissionObjValueDoubleCopy}
                            })()
                        }),
                        root.FormSubmitActions.uploadAttachment(submissionObjValueCopy._fileObj, submissionObjValueCopy.fileName)
                    ])
                }
            })

            const inputFile = async file => {
                const fileName = root.Utils.generateUUID(2) + '.' + root.Utils.getFileExtension(file.name)

                const fileDataURL = await root.Utils.blobToBase64Data(file)

                props.submissionObj.value = {
                    fileName,
                    _fileObj: file,
                    _fileDataURL: fileDataURL,
                    _fileType: file.type,
                    size: root.Utils.convertBytesToReadable(file.size),
                    originalName: file.name,
                    isImage: file.type.slice(0, 5) === 'image'
                }
            }

            const getFileNameWithoutExtension = computed(() => props.submissionObj.value?.originalName ? root.Utils.trimFileExtension(props.submissionObj.value?.originalName) + '.' : '')

            const getFileExtension = computed(() => props.submissionObj.value?.originalName ? root.Utils.getFileExtension(props.submissionObj.value?.originalName) : '')

            const isImage = computed(() => props.submissionObj.value?._fileType?.slice(0, 5) === 'image')

            const removeFile = () => props.submissionObj.value = null

            const fileHandling = {
                getFileNameWithoutExtension,
                getFileExtension,
                inputFile,
                isImage,
                removeFile,
            }

            return {
                ...extension,
                ...fileHandling,
            }
        },
    }
</script>