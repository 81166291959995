var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-toolbar-items', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    staticClass: "navigation-btn",
    attrs: {
      "exact": "",
      "disabled": _vm.isLoading,
      "text": "",
      "active-class": "navigation-btn--active",
      "to": _vm.dashboardPage
    }
  }, [_c('HomeIcon', {
    attrs: {
      "stroke-width": "1.5"
    }
  }), _c('span', [_vm._v("Dashboard")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }