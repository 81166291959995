<template>
  <SharedView
      :value="sourceObj.properties.allow_typing ? submissionObj.value : ''"
      :placeholder="isPaintingStarted && !sourceObj.properties.allow_typing ? '' : sourceObj.content.placeholder"
      :allow-typing="sourceObj.properties.allow_typing"
      @input="sourceObj.properties.allow_typing ? setValue($event) : ''"
  >
    <template #pad>
      <VueSignaturePad
          v-if="!sourceObj.properties.allow_typing"
          class="signature-field-view__layout-canvas"
          width="100%"
          height="100%"
          ref="signaturePad"
          :options="{
                          onBegin,
                          onEnd,
                          penColor: '#000',
                          maxWidth: 1.5,
                      }"
      />
    </template>
    <template #icon>
      <v-icon v-show="submissionObj.value && !sourceObj.properties.allow_typing"
              class="signature-field-view__layout-reset-btn"
              @pointerdown="resetSignature"
      >
        mdi-arrow-u-left-top
      </v-icon>
    </template>
  </SharedView>
</template>

<script>
import {
  submittable_field_view_mixin,
  useSubmittableFieldViewExtension
} from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
import {ref, onMounted, inject} from '@vue/composition-api'

export default {
  name: "Submittable__Signature",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView')
  },
  setup(props, {root, refs}) {
    const extension = useSubmittableFieldViewExtension(props)

    const isPaintingStarted = ref(false)

    const storeModulePath = inject('formSubmitStoreModulePath')

    const setValue = (v) => {
      props.submissionObj.value = v;
    }

    if (!props.sourceObj.properties.allow_typing)
      extension.beforeSubmit(() => {
        if (props.submissionObj.value)
          return root.Utils.trimPngImage(props.submissionObj.value._dataURL)
              .then(trimmedSignatureDataURL => {
                let blob = root.Utils.base64DataURLToBlob(trimmedSignatureDataURL)
                return Promise.all([
                  root.$store.dispatch(storeModulePath + '/submission/updateFormSubmissionField', {
                    fieldID: props.fieldId,
                    updatedSubmission: {value: props.submissionObj.value._imageName}
                  }),
                  root.FormSubmitActions.uploadAttachment(blob, props.submissionObj.value._imageName)
                ])
              })
      })


    onMounted(() => {
      if (!props.sourceObj.properties.allow_typing && props.submissionObj.value) {
        refs.signaturePad.fromDataURL(props.submissionObj.value._dataURL)
        isPaintingStarted.value = true
      }
    })


    const onBegin = () => {
      isPaintingStarted.value = true
    }

    const onEnd = () => {
      const {data} = refs.signaturePad.saveSignature()

      props.submissionObj.value = {
        _imageName: root.Utils.generateUUID(2) + '.png',
        _dataURL: data
      }
    }

    const resetSignature = () => {
      props.submissionObj.value = null
      refs.signaturePad.clearSignature()
      isPaintingStarted.value = false
    }

    return {
      onBegin,
      onEnd,
      isPaintingStarted,
      resetSignature,
      setValue,
      ...extension
    }
  }
}
</script>