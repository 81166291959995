<template>
  <div class="smart-paragraph-field-view">
    <span class="smart-paragraph-field-view__content">
      <template v-for="(element, key) in fieldObj.content">
          <template v-if="element.type === 'text'">{{ element.value }}</template>
          <br v-else-if="element.type === 'break'" :key="key">
          <SubmittableVariableContainer
              v-else-if="element.type === 'variable'"
              :value="submissionObj?.value[element.variable_id]"
              @input="setValue($event, element.variable_id)"
              :key="key"
          />
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SharedView__SmartParagraph',
  components: {
    SubmittableVariableContainer: () => import('./Components/SubmittableVariableContainer'),
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    fieldObj: {
      type: Object,
    },
    submissionObj: {
      type: Object,
    }
  },
  methods: {
    setValue(v, id) {
      this.submissionObj.value[id] = v;
    }
  }
}
</script>