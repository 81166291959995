import SharedView from "@/components/Fields/FieldsViews/Slider/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__Slider',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        const fieldProperties = ctx.props.fieldObj.properties
        return h(
            SharedView,
            {
                props: {
                    preview: true,
                    from: fieldProperties.scale_poles.from,
                    to: fieldProperties.scale_poles.to,
                    labels: fieldProperties.range_labels,
                }
            }
        )
    }
}