var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "78",
      "height": "53",
      "viewBox": "0 0 78 53",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.695679",
      "y": "0.5",
      "width": "76",
      "height": "52",
      "rx": "7.45652",
      "fill": "white"
    }
  }), _c('rect', {
    attrs: {
      "x": "15",
      "y": "21",
      "width": "19.8913",
      "height": "2.65217",
      "rx": "1.32609",
      "fill": "currentColor"
    }
  }), _c('rect', {
    attrs: {
      "x": "15",
      "y": "25.6523",
      "width": "20",
      "height": "2.65",
      "rx": "1.325",
      "fill": "currentColor"
    }
  }), _c('rect', {
    attrs: {
      "x": "15",
      "y": "30.3027",
      "width": "6.63043",
      "height": "2.65217",
      "rx": "1.32609",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M63 23C63 21.8954 62.1046 21 61 21H45C43.8954 21 43 21.8954 43 23V31C43 32.1046 43.8954 33 45 33H61C62.1046 33 63 32.1046 63 31V23Z",
      "fill": "currentColor"
    }
  }), _c('rect', {
    staticClass: "border",
    attrs: {
      "x": "0.695679",
      "y": "0.5",
      "width": "76",
      "height": "52",
      "rx": "7.45652",
      "stroke-width": "1",
      "stroke": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }