import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";
import GenericStringSingleLineFieldViewInput
    from "@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput";

export default {
    name: 'GenericPreviewTextFieldView',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            GenericStringSingleLineFieldViewInput,
            {
                props: {
                    assignGenericViewClass: true,
                    placeholder: ctx.props.fieldObj.content.placeholder,
                    readonly: true,
                    isFormStaticProp: ctx.props.isFormStatic,
                }
            }
        )
    }

}