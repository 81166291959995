<template>
    <v-snackbar
            v-if="show"
            :value="show"
            :color="color"
            rounded="pill"
            bottom
            min-width="1"
    >
        <span class="snackbar-icon mr-2">
            {{ emoji }}
        </span>
        {{ text }}
    </v-snackbar>
</template>

<script>

    export default {
        name: "Snackbar",
        data() {
            return {
                show: false,
                text: '',
                emoji: '',
                color: '',
            }
        },
        created() {
            this.snackbarEventBus.$on('snackbar', ({color, emoji, text}) => {
                this.text = text;
                this.color = color;
                this.emoji = emoji;
                this.show = true;
                setTimeout(() => this.show = false, this.constants.SNACKBAR_CONSTANTS.DURATION)
            })
        },

    }
</script>

<style scoped lang="scss">
    .snackbar-icon {
        font-size: 18px;
    }
    ::v-deep .v-snack__content {
        display: flex;
        align-items: center;
    }
</style>