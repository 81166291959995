var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#E9F5F8"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.3333 7.6665H19V8.99984H12.3333V7.6665ZM12.3333 10.3332H16.3333V11.6665H12.3333V10.3332ZM12.3333 14.3332H19V15.6665H12.3333V14.3332ZM12.3333 16.9998H16.3333V18.3332H12.3333V16.9998ZM7 7.6665H11V11.6665H7V7.6665ZM8.33333 8.99984V10.3332H9.66667V8.99984H8.33333ZM7 14.3332H11V18.3332H7V14.3332ZM8.33333 15.6665V16.9998H9.66667V15.6665H8.33333Z",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }