var render = function () {
  var _vm$getFormJSON, _vm$getFormDBData, _vm$getFormDBData$pub, _vm$getFormJSON2, _vm$getFormJSON2$sett, _vm$getFormJSON2$sett2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "submissions-analytics"
  }, [_c('div', {
    staticClass: "submissions-analytics__head"
  }, [_c('div', {
    staticClass: "submissions-analytics__head__left-part"
  }, [_c('span', {
    staticClass: "submissions-analytics__form-name mr-4",
    staticStyle: {
      "height": "36px"
    }
  }, [!_vm.getFormDBData || _vm.getIsFormLoadingScreenShown ? _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "width": "250",
      "height": "32"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.getFormDBData.name || 'Untitled'))])], 1), !_vm.getIsFormLoadingScreenShown && _vm.getFormDBData && (_vm$getFormJSON = _vm.getFormJSON) !== null && _vm$getFormJSON !== void 0 && _vm$getFormJSON.id ? [!!((_vm$getFormDBData = _vm.getFormDBData) !== null && _vm$getFormDBData !== void 0 && (_vm$getFormDBData$pub = _vm$getFormDBData.publishedVersionID) !== null && _vm$getFormDBData$pub !== void 0 && _vm$getFormDBData$pub.length && (_vm$getFormJSON2 = _vm.getFormJSON) !== null && _vm$getFormJSON2 !== void 0 && (_vm$getFormJSON2$sett = _vm$getFormJSON2.settings) !== null && _vm$getFormJSON2$sett !== void 0 && (_vm$getFormJSON2$sett2 = _vm$getFormJSON2$sett.access) !== null && _vm$getFormJSON2$sett2 !== void 0 && _vm$getFormJSON2$sett2.open_to_responses) ? _c('f-badge', {
    attrs: {
      "green": "",
      "dot": ""
    }
  }, [_vm._v(" Active ")]) : _c('f-badge', {
    attrs: {
      "gray": "",
      "dot": ""
    }
  }, [_vm._v(" Closed ")])] : _vm._e()], 2), _c('div')]), _c('div', {
    staticClass: "submissions-analytics__brief-information"
  }, _vm._l(_vm.briefInformation, function (brief) {
    return _c('div', {
      key: brief.icon,
      staticClass: "brief-card"
    }, [_c('img', {
      attrs: {
        "src": brief.icon
      }
    }), _c('div', {
      staticClass: "brief_card__info-container"
    }, [_c('span', {
      staticClass: "brief-card__name"
    }, [_vm._v(_vm._s(brief.name))]), _c('span', {
      staticClass: "brief-card__value"
    }, [_vm.isAnyLoading || _vm.getFormMetricsIsLoading ? _c('v-skeleton-loader', {
      attrs: {
        "type": "image",
        "tile": "",
        "width": "60",
        "height": "21"
      }
    }) : _c('span', [_vm._v(_vm._s(brief.value))])], 1)])]);
  }), 0), _c('div', {
    staticClass: "submissions-analytics__body"
  }, [_c('div', {
    staticClass: "submissions-analytics__toolbar"
  }, [_c('v-tabs', {
    staticClass: "blue-tabs submissions-analytics__analytics-type-choice",
    attrs: {
      "value": _vm.getAnalyticsView,
      "hide-slider": "",
      "height": "36",
      "show-arrow": false
    },
    on: {
      "change": function (v) {
        return _vm.$store.dispatch('submissions/setAnalyticsView', v);
      }
    }
  }, [_c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("All responses")])]), _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("Overview")])])], 1), _vm.getAnalyticsView === 0 ? _c('div', {
    staticClass: "d-flex"
  }, [_c('SubmissionsToolbarActionsComponent', {
    attrs: {
      "is-selected": _vm.isSelected,
      "selected-submissions-for-csv": _vm.selectedSubmissionsForCsv,
      "selected-submission-title": _vm.selectedSubmissionTitle,
      "submissions-for-csv": _vm.submissionsForCsv
    },
    on: {
      "delete-selected": _vm.deleteSelectedSubmissions
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('span', {
    staticClass: "ml-0"
  }, [_c('SubmissionsExportComponent', {
    attrs: {
      "disabled": _vm.isAnyLoading,
      "is-selected": _vm.isSelected
    }
  })], 1)], 1) : _vm._e()], 1), _vm.getAnalyticsView === 0 ? _c('AllResponsesView', {
    attrs: {
      "is-selected": _vm.isSelected,
      "search-query": _vm.searchQuery,
      "formatted-submissions": _vm.formattedSubmissions
    },
    model: {
      value: _vm.selectedSubmissions,
      callback: function ($$v) {
        _vm.selectedSubmissions = $$v;
      },
      expression: "selectedSubmissions"
    }
  }) : _vm.getAnalyticsView === 1 ? _c('SummaryView', {
    attrs: {
      "total-response": this.getTotalResponsesMetric,
      "formID": _vm.$route.params.formId
    }
  }) : _vm._e()], 1), !_vm.isAnyLoading && _vm.areThereLeftUnloadedSubmissions && _vm.getCurrentAllResponsesView === _vm.getAllResponsesViews.TABLE && _vm.getAnalyticsView !== 1 ? _c('div', {
    staticClass: "submissions-analytics__pagination"
  }, [_c('v-btn', {
    staticClass: "submissions-analytics__pagination-btn v-btn--text-hoverable",
    attrs: {
      "outlined": "",
      "loading": _vm.getIsSubmissionsListExtendingInProgress
    },
    on: {
      "click": function () {
        return _vm.$store.dispatch('submissions/loadMoreSubmissions', {
          formID: _vm.$route.params.formId
        });
      }
    }
  }, [_vm._v(" Load more submissions ")])], 1) : _vm._e(), _c('GenericStoreDialog', {
    attrs: {
      "store-module-path": "submissions/confirmation_dialog"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }