var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.submissionObj.value ? _c('span', [_vm._v("-")]) : _vm.isTypingAllowed ? _c('span', [_vm._v(_vm._s(_vm.submissionObj.value))]) : _c('v-menu', {
    attrs: {
      "nudge-top": "25",
      "nudge-right": "75",
      "open-on-hover": "",
      "top": "",
      "eager": "",
      "transition": "slide-y-reverse-transition",
      "open-delay": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "cursor-pointer",
          attrs: {
            "max-width": "200",
            "max-height": "34",
            "contain": "",
            "src": _vm.signatureDataURL
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('f-circle-loader', {
                attrs: {
                  "size": "3"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, 'v-img', attrs, false), on))];
      }
    }])
  }, [_c('v-card', {
    staticClass: "pa-6"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": _vm.signatureDataURL
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('f-circle-loader', {
          attrs: {
            "size": "3"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }