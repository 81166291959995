var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['form-composition-card', 'form-composition-card--interactive', !_vm.persistentExpanded && 'form-composition-card--collapsed']
  }, [_c('div', {
    staticClass: "form-composition-card__header"
  }, [_c('span', {
    staticClass: "form-composition-card__title"
  }, [_vm._v("Questions")]), _c('div', {
    staticClass: "form-composition-card__header-btn"
  }, [_c('InteractiveNewFieldSelectionDialog')], 1)]), _c('div', {
    staticClass: "form-composition-card__content"
  }, [_c('FormPagesListDragManager', {
    attrs: {
      "page-name-getter": _vm.pageNameGetter,
      "page-icon-getter": _vm.pageIconGetter
    },
    scopedSlots: _vm._u([{
      key: "home-page",
      fn: function () {
        return [_c('div', {
          staticClass: "page-tab interactive-page-tab"
        }, [_c('div', {
          staticClass: "interactive-page-tab-prepend interactive-page-tab-number"
        }), _c('div', {
          staticClass: "interactive-page-tab-prepend interactive-page-tab-icon"
        }, [_vm._v(" " + _vm._s(_vm.constants.FORM_PAGES_ICONS.HOME_PAGE) + " ")]), _c('div', {
          staticClass: "interactive-page-tab-name"
        }, [_vm._v(" " + _vm._s(_vm.getFormJSON.all_pages[_vm.getFormJSON.home_page.page_id].name) + " ")])])];
      },
      proxy: true
    }, {
      key: "main-page",
      fn: function (_ref) {
        var _ref$pageData = _ref.pageData,
          icon = _ref$pageData.icon,
          number = _ref$pageData.number,
          name = _ref$pageData.name;
        return [_c('div', {
          staticClass: "page-tab interactive-page-tab"
        }, [_c('div', {
          staticClass: "interactive-page-tab-prepend interactive-page-tab-number"
        }, [_vm._v(" " + _vm._s(number) + " ")]), _c('f-hover-tooltip', {
          attrs: {
            "right": "",
            "text": _vm._f("tooltipFieldName")(_vm.Utils.stripHtml(name))
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('div', _vm._g({
                staticClass: "interactive-page-tab-prepend interactive-page-tab-icon"
              }, !_vm.persistentExpanded && _vm.Utils.stripHtml(name) && on), [_c(icon, {
                tag: "componenet"
              })], 1)];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "interactive-page-tab-name"
        }, [_vm._v(" " + _vm._s(_vm.Utils.stripHtml(name)) + " ")])], 1)];
      }
    }, {
      key: "finish-page",
      fn: function () {
        return [_c('div', {
          staticClass: "page-tab interactive-page-tab"
        }, [_c('div', {
          staticClass: "interactive-page-tab-prepend interactive-page-tab-number"
        }), _c('div', {
          staticClass: "interactive-page-tab-prepend interactive-page-tab-icon"
        }, [_vm._v(" " + _vm._s(_vm.constants.FORM_PAGES_ICONS.FINISH_PAGE) + " ")]), _c('div', {
          staticClass: "interactive-page-tab-name"
        }, [_vm._v(" " + _vm._s(_vm.getFormJSON.all_pages[_vm.getFormJSON.finish_page.page_id].name) + " ")])])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "form-composition-card__actions"
  }, [_c('v-divider', {
    staticClass: "form-composition-card__divider-visible-when-collapsed"
  }), _c('div', {
    staticClass: "form-composition-card__arrows"
  }, [_c('ChevronUpIcon', {
    on: {
      "click": _vm.prevPage
    }
  }), _c('ChevronDownIcon', {
    on: {
      "click": _vm.nextPage
    }
  })], 1), _c('v-divider', {
    staticClass: "form-composition-card__persistent-divider"
  }), _c('div', {
    staticClass: "form-composition-card__minimize-bar-btn",
    on: {
      "click": _vm.toggleCollapse
    }
  }, [_c('v-icon', [_vm._v(" $load_circle ")]), _c('div', [_vm._v(" Minimize ")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }