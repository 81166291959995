<template>
    <div class="payment-method-card-block">
        <p class="settings-view__header">Payment method</p>
        <p class="settings-view__subheader mb-5">Add or edit a payment method.</p>

        <div
                v-if="getPaymentMethodIsLoading || getPaymentMethod"
                class="payment-method-card-block__content"
        >
            <div class="payment-method-card-block__card-brand">
                <v-skeleton-loader v-if="getPaymentMethodIsLoading" type="image"/>
                <f-card-brand-icon v-else :brand="getPaymentMethod.card.brand"/>
            </div>
            <div class="payment-method-card-block__info">
                <div class="payment-method-card-block__card-number">
                    <v-skeleton-loader v-if="getPaymentMethodIsLoading" type="text"/>
                    <span v-else>{{ startCase(getPaymentMethod.card.brand) }} ending in {{ getPaymentMethod.card.last4 }}</span>
                </div>
                <div class="payment-method-card-block__card-expiry">
                    <v-skeleton-loader v-if="getPaymentMethodIsLoading" type="text"/>
                    <span v-else>Expiry {{ getPaymentMethod.card.exp_month }}/{{ getPaymentMethod.card.exp_year }}</span>
                </div>
            </div>
            <v-btn
                    v-if="!getPaymentMethodIsLoading"
                    rounded
                    outlined
                    @click="addPaymentMethod"
            >Edit
            </v-btn>
        </div>

        <v-btn
                v-else
                rounded
                outlined
                @click="addPaymentMethod"
        >Add Payment Method
        </v-btn>

        <UpdatePaymentMethodDialog ref="UpdatePaymentMethodDialog"/>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import { startCase } from 'lodash'

    export default {
        name: "PaymentMethodCard",
        components: {
            UpdatePaymentMethodDialog: () => import('./UpdatePaymentMethodDialog')
        },
        computed: {
            ...mapGetters('user/plan/customer_details', [
                'getPaymentMethodIsExist',
                'getPaymentMethod',
                'getPaymentMethodIsLoading'
            ])
        },
        methods: {
            startCase,
            addPaymentMethod() {
                this.$refs.UpdatePaymentMethodDialog.openDialog()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .payment-method-card-block {
        min-width: 400px;
        padding: 24px;
        border: 1px solid #F3F4F6;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);

        &__content {
            border-radius: 8px;
            box-shadow: 0 0 0 1px var(--v-gray_200-base);
            align-items: flex-start;
            display: flex;
            column-gap: 16px;

            padding: 16px;
        }

        &__card-brand {
            border-radius: 6px;
            box-shadow: 0 0 0 1px var(--v-grey_100-base);

            flex-basis: 58px;
            height: 40px;

            padding: 7px;

            .v-skeleton-loader {
                height: 100%;
            }
        }

        &__info {
            flex-grow: 1;
        }

        &__card-number, &__card-expiry {
            height: 20px;
            display: flex;
            align-items: center;

            .v-skeleton-loader {
                ::v-deep.v-skeleton-loader__bone {
                    margin: 0;
                }
            }
        }

        &__card-number {
            font-size: 14px;
            font-weight: 500;
            color: var(--v-gray_700-base);

            .v-skeleton-loader {
                width: 130px;

                ::v-deep.v-skeleton-loader__bone {
                    height: 12px;
                }
            }
        }

        &__card-expiry {
            font-size: 14px;
            font-weight: 400;
            color: var(--v-grey_500_2-base);

            .v-skeleton-loader {
                width: 100px;

                ::v-deep.v-skeleton-loader__bone {
                    height: 10px;
                }
            }
        }
    }
</style>