var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editable-field-property-smart-paragraph-variables"
  }, _vm._l(_vm.fieldPropertiesObj.variables_list, function (variableName, variableId) {
    return _c('div', {
      key: variableId,
      staticClass: "editable-field-property-smart-paragraph-variables__item"
    }, [_c('p', [_vm._v(_vm._s(variableName))])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }