<template>
  <SharedView
      editable
  >
    <template #editor>
      <VueEditor
          v-model="fieldObj.content.text"
          :editor-toolbar="$options.toolbar"
      />
    </template>
  </SharedView>
</template>

<script>
import editable_field_view_mixin
  from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

import {VueEditor} from "vue2-editor"

export default {
  name: "Editable__Text",
  mixins: [editable_field_view_mixin],
  toolbar: [
    [
      {header: [false, 1, 2, 3, 4, 5, 6]}
    ],
    ["bold", "italic", "underline", "strike"],
    [
      "link"
    ],
    [
      {align: ""},
      {align: "center"},
      {align: "right"},
      {align: "justify"}
    ],
    [{'color': []}, {'background': []}],
    [
      {list: "ordered"},
      {list: "bullet"}
    ],
    ['video'],
  ],
  components: {
    VueEditor,
    SharedView: () => import('./SharedView'),
  }
}
</script>