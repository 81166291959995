var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    class: ['interactive-form', 'interactive-form--submittable']
  }, [_c('InteractiveFormPagesDisplayManager')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }