<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
            <v-spacer/>
            <SummaryChangeSortOrder v-model="sortOrder"/>
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="opinion-scale-field-view-summary">
                <div class="opinion-scale-field-view-summary__average-answer">
                    <span>Average Answer:</span>
                    <span class="opinion-scale-field-view-summary__average-value">{{ averageAnswer }}</span>
                </div>
                <div class="opinion-scale-field-view-summary__container">
                    <div v-for="optionIndex in filteredOptions.shown" class="opinion-scale-field-view-summary__bar-wrapper" :key="'shown' + optionIndex">
                        <div class="opinion-scale-field-view-summary__responses-count">{{optionsWithPercentage[optionIndex].count}}</div>
                        <div class="opinion-scale-field-view-summary__percentage">{{optionsWithPercentage[optionIndex].percentage}}%</div>
                        <VerticalProgressBar height="138" width="50" :value="optionsWithPercentage[optionIndex].percentage"/>
                        <div class="opinion-scale-field-view-summary__pole-number">{{ optionIndex }}</div>
                    </div>
                </div>

                <div v-if="filteredOptions.hidden.length">
                    <SummaryHiddenAnswers :hidden-count="filteredOptions.hidden.length">
                        <div
                                v-for="poleNumber in filteredOptions.hidden"
                                class="d-flex align-center my-2"
                                :key="'hidden' + poleNumber"
                        >
                            <span>{{optionsWithPercentage[poleNumber].count}} responses</span>
                            <span class="mx-1">·</span>
                            <span class="font-weight-bold">{{poleNumber}}</span>
                        </div>
                    </SummaryHiddenAnswers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__OpinionScale",
        components: {
            VerticalProgressBar: () => import('@/components/Elements/VerticalProgressBar')
        },
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        data: () => ({
            sortOrder: summary_answer_field_view_mixin.data().SORT_ORDER.DEFAULT
        }),
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => value !== null)
            },
            averageAnswer() {
                return Math.round(
                    Object.values(this.optionsWithPercentage).reduce((accum, {count, number}) => accum + count * number, 0)
                        /
                    this.filteredSubmissions.length
                ) || '-'

            },
            filteredOptions() {
                const result = {
                    shown: [],
                    hidden: []
                }

                for (
                    let optionNumber = +this.latestSourceObj.properties.scale_poles.from;
                    optionNumber <= this.latestSourceObj.properties.scale_poles.to;
                    optionNumber++
                )
                    result.shown.push(optionNumber)

                Object.entries(this.optionsWithPercentage)
                    .forEach(([number, {count}]) => {
                        if (!result.shown.includes(+number) && count !== 0)
                            result.hidden.push(+number)
                    })

                if (this.sortOrder === this.SORT_ORDER.DESCENDING) {
                    result.shown = result.shown
                        .sort((a, b) => this.optionsWithPercentage[b].percentage - this.optionsWithPercentage[a].percentage)
                    result.hidden = result.hidden
                        .sort((a, b) => this.optionsWithPercentage[b].percentage - this.optionsWithPercentage[a].percentage)
                }

                return result
            },
            optionsWithPercentage() {
                const result = {}

                this.submissions.forEach(
                    ({submissionObj, versionID}) => {
                        for (
                            let optionNumber = +this.versionedSources[versionID].properties.scale_poles.from;
                            optionNumber <= this.versionedSources[versionID].properties.scale_poles.to;
                            optionNumber++
                        ) {
                            if (!(optionNumber in result))
                                result[optionNumber] = {
                                    count: 0,
                                    percentage: 0,
                                    number: optionNumber
                                }
                        }


                        if (submissionObj.value !== null)
                            result[submissionObj.value + parseInt(this.versionedSources[versionID].properties.scale_poles.from)].count++
                    }
                )

                const total = Object.values(result).reduce((accum, {count}) => accum + count, 0)
                Object.values(result).forEach(option => option.percentage = Math.round(option.count / total * 100) || 0)

                return result
            }
        }
    }
</script>