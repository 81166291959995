<template>
    <div class="interactive-finish-page">
        <div class="interactive-finish-page__content">
            <div class="interactive-finish-page__content-wrapper">
                <div class="interactive-finish-page__content-emoji">
                    <slot name="emoji"></slot>
                </div>
                <div class="interactive-finish-page__content-title">
                    <slot name="title"></slot>
                </div>
                <div class="interactive-finish-page__content-description">
                    <slot name="description"></slot>
                </div>
            </div>
        </div>
        <div class="interactive-finish-page__footer" v-if="$listeners.redirect && getFormSourceJSON.settings.general_settings.formsly_branding">
            <span class="interactive-finish-page__footer-text">Create your own - it's free, easy & beautifulll</span>
            <button class="interactive-finish-page__footer-button" @click="redirect">Create a Form</button>
        </div>
    </div>
</template>

<script>
import { useMapGetters } from '@/xstore'
import { inject } from '@vue/composition-api'

    export default {
        name: 'InteractiveFinishPageTemplate',
        methods: {
            redirect() {
                if (this.$listeners.redirect) {
                    this.$emit('redirect');
                }
            }
        },
        setup() {
            return {
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getFormSourceJSON',
                ]),
            }
        },
    }
</script>

<style scoped>

</style>