var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-field-view"
  }, [_vm._t("editor"), !_vm.editable ? _c('div', {
    staticClass: "ql-editor",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }