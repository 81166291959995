var render = function () {
  var _vm$sortedOptionsWith, _vm$sortedOptionsWith2, _vm$sortedOptionsWith3, _vm$sortedOptionsWith4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('v-spacer'), _c('SummaryChangeSortOrder', {
    model: {
      value: _vm.sortOrder,
      callback: function ($$v) {
        _vm.sortOrder = $$v;
      },
      expression: "sortOrder"
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "selection-field-view-summary"
  }, [!!_vm.visibleOptions ? _c('Iterator', {
    key: 'shown-iterator-' + _vm.sortOrder,
    attrs: {
      "limitation": _vm.visibleOptions
    }
  }, _vm._l((_vm$sortedOptionsWith = _vm.sortedOptionsWithPercentage) === null || _vm$sortedOptionsWith === void 0 ? void 0 : _vm$sortedOptionsWith.shown, function (optionKey) {
    var _vm$optionsWithPercen, _vm$optionsWithPercen2, _vm$optionsWithPercen3;
    return _c('SummaryRowWithProgress', {
      key: optionKey,
      attrs: {
        "label": (_vm$optionsWithPercen = _vm.optionsWithPercentage[optionKey]) === null || _vm$optionsWithPercen === void 0 ? void 0 : _vm$optionsWithPercen.label,
        "count": (_vm$optionsWithPercen2 = _vm.optionsWithPercentage[optionKey]) === null || _vm$optionsWithPercen2 === void 0 ? void 0 : _vm$optionsWithPercen2.count,
        "percentage": (_vm$optionsWithPercen3 = _vm.optionsWithPercentage[optionKey]) === null || _vm$optionsWithPercen3 === void 0 ? void 0 : _vm$optionsWithPercen3.percentage
      },
      scopedSlots: _vm._u([{
        key: "prepend",
        fn: function () {
          return [_vm._t("prepend", null, {
            "option": _vm.optionsWithPercentage[optionKey]
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _vm._e(), (_vm$sortedOptionsWith2 = _vm.sortedOptionsWithPercentage) !== null && _vm$sortedOptionsWith2 !== void 0 && _vm$sortedOptionsWith2.hidden.length ? _c('div', [_c('SummaryHiddenAnswers', {
    attrs: {
      "hidden-count": (_vm$sortedOptionsWith3 = _vm.sortedOptionsWithPercentage) === null || _vm$sortedOptionsWith3 === void 0 ? void 0 : _vm$sortedOptionsWith3.hidden.length
    }
  }, _vm._l((_vm$sortedOptionsWith4 = _vm.sortedOptionsWithPercentage) === null || _vm$sortedOptionsWith4 === void 0 ? void 0 : _vm$sortedOptionsWith4.hidden, function (optionKey) {
    var _vm$optionsWithPercen4, _vm$optionsWithPercen5;
    return _c('div', {
      key: optionKey,
      staticClass: "d-flex align-center my-2"
    }, [_c('span', [_vm._v(_vm._s(_vm.optionsWithPercentage[optionKey].count) + " responses")]), _c('span', {
      staticClass: "mx-1"
    }, [_vm._v("·")]), (_vm$optionsWithPercen4 = _vm.optionsWithPercentage[optionKey]) !== null && _vm$optionsWithPercen4 !== void 0 && _vm$optionsWithPercen4.label ? _c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s((_vm$optionsWithPercen5 = _vm.optionsWithPercentage[optionKey]) === null || _vm$optionsWithPercen5 === void 0 ? void 0 : _vm$optionsWithPercen5.label))]) : _vm._e()]);
  }), 0)], 1) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }