var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "form-preview-card",
    attrs: {
      "tile": ""
    }
  }, [_c('f-aspect-ratio-keeper', {
    attrs: {
      "width": "16",
      "height": "9"
    }
  }, [_c('div', {
    staticClass: "embedded-dashboard-form-preview__form-container",
    on: {
      "click": _vm.startEditingForm
    }
  }, [_c('div', {
    staticClass: "embedded-dashboard-form-preview__form-scaler"
  }, [_vm.getFormsLoadingStatuses[_vm.formData.id] ? _c('EmbeddedFormPreview', {
    attrs: {
      "fsp-module-location-path": ("forms_list/" + (_vm.formData.id) + "/preview/FSP"),
      "form-j-s-o-n": _vm.formJson,
      "is-form-static": _vm.$store.getters[("forms_list/" + (_vm.formData.id) + "/preview/isFormStatic")],
      "is-form-interactive": _vm.$store.getters[("forms_list/" + (_vm.formData.id) + "/preview/isFormInteractive")],
      "static-form-scale": _vm.staticFormScale,
      "interactive-form-scale": _vm.interactiveFormScale
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "form-preview-card__body"
  }, [_c('div', {
    staticClass: "form-preview-card__info-container"
  }, [_c('div', {
    staticClass: "form-preview-card__info",
    on: {
      "click": _vm.startEditingForm
    }
  }, [_c('div', {
    staticClass: "form-preview-card__date"
  }, [_vm._v(" " + _vm._s(_vm.formCreationDate) + " ")]), _c('div', {
    staticClass: "form-preview-card__name"
  }, [_vm._v(" " + _vm._s(_vm.formData.name.length ? _vm.formData.name : 'Untitled') + " ")])]), _vm.getFormsLoadingStatuses[_vm.formData.id] ? _c('FormActionsComponent', {
    attrs: {
      "form-data": _vm.formData,
      "form-json": _vm.formJson
    },
    on: {
      "share": function ($event) {
        _vm.shareModalVisible = true;
      },
      "update-loader": _vm.handleLoaderUpdate
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "form-preview-card__footer"
  }, [_c('f-hover-tooltip', {
    attrs: {
      "bottom": "",
      "text": "View Responses"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [_c('div', _vm._g({
          staticClass: "form-preview-card__responses-btn",
          on: {
            "click": _vm.goToSubmissionsAnalytics
          }
        }, slotProps.on), [_c('span', {
          directives: [{
            name: "visible",
            rawName: "v-visible",
            value: _vm.responsesCount !== undefined,
            expression: "responsesCount !== undefined"
          }],
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(_vm.responsesCount))]), _vm._v(" Responses ")]), _c('ArrowRightIcon', {
          attrs: {
            "size": "12"
          }
        })], 1)];
      }
    }])
  })], 1)]), _c('v-dialog', {
    attrs: {
      "width": "776"
    },
    model: {
      value: _vm.shareModalVisible,
      callback: function ($$v) {
        _vm.shareModalVisible = $$v;
      },
      expression: "shareModalVisible"
    }
  }, [_c('ShareFormMenu', {
    attrs: {
      "share-link": _vm.shareLink,
      "fsp-module-location-path": ("forms_list/" + (_vm.formData.id) + "/preview/FSP"),
      "form-j-s-o-n": _vm.formJson,
      "is-form-static": _vm.$store.getters[("forms_list/" + (_vm.formData.id) + "/preview/isFormStatic")],
      "is-form-interactive": _vm.$store.getters[("forms_list/" + (_vm.formData.id) + "/preview/isFormInteractive")]
    },
    on: {
      "input": function ($event) {
        _vm.shareModalVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.name.length ? _vm.formData.name : 'Untitled') + " ")];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }