<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="whatsapp">
      <path id="border" fill-rule="evenodd" clip-rule="evenodd"
        d="M18 34.875C26.6985 34.875 33.75 27.8235 33.75 19.125C33.75 10.4265 26.6985 3.375 18 3.375C9.30152 3.375 2.25 10.4265 2.25 19.125C2.25 21.9497 2.99362 24.6008 4.29578 26.8931L2.25 34.875L10.4792 32.9668C12.714 34.1836 15.2763 34.875 18 34.875ZM18 32.4519C25.3603 32.4519 31.3269 26.4853 31.3269 19.125C31.3269 11.7647 25.3603 5.79808 18 5.79808C10.6397 5.79808 4.67308 11.7647 4.67308 19.125C4.67308 21.9668 5.56256 24.6009 7.07832 26.764L5.88462 31.2404L10.4399 30.1016C12.588 31.5839 15.1926 32.4519 18 32.4519Z"
        fill="#BFC8D0" />
      <path id="bg"
        d="M31.5 18C31.5 25.4558 25.4558 31.5 18 31.5C15.1562 31.5 12.5177 30.6207 10.3417 29.1191L5.72727 30.2727L6.93648 25.7382C5.40104 23.547 4.5 20.8787 4.5 18C4.5 10.5442 10.5442 4.5 18 4.5C25.4558 4.5 31.5 10.5442 31.5 18Z"
        fill="url(#paint0_linear_9080_8969)" />
      <path id="border_2" fill-rule="evenodd" clip-rule="evenodd"
        d="M18 33.75C26.6985 33.75 33.75 26.6985 33.75 18C33.75 9.30152 26.6985 2.25 18 2.25C9.30152 2.25 2.25 9.30152 2.25 18C2.25 20.8247 2.99362 23.4758 4.29578 25.7681L2.25 33.75L10.4792 31.8418C12.714 33.0586 15.2763 33.75 18 33.75ZM18 31.3269C25.3603 31.3269 31.3269 25.3603 31.3269 18C31.3269 10.6397 25.3603 4.67308 18 4.67308C10.6397 4.67308 4.67308 10.6397 4.67308 18C4.67308 20.8418 5.56256 23.4759 7.07832 25.639L5.88462 30.1154L10.4399 28.9766C12.588 30.4589 15.1926 31.3269 18 31.3269Z"
        fill="white" />
      <path id="phone"
        d="M14.0625 10.6876C13.6881 9.93541 13.1136 10.002 12.5333 10.002C11.4961 10.002 9.87891 11.2443 9.87891 13.5564C9.87891 15.4512 10.7139 17.5255 13.5275 20.6283C16.2428 23.6228 19.8106 25.1718 22.7725 25.1191C25.7344 25.0664 26.3438 22.5176 26.3438 21.6568C26.3438 21.2753 26.107 21.0849 25.9439 21.0332C24.9346 20.5488 23.073 19.6462 22.6494 19.4766C22.2259 19.3071 22.0047 19.5364 21.8672 19.6612C21.4831 20.0273 20.7217 21.1061 20.461 21.3487C20.2002 21.5914 19.8116 21.4686 19.6498 21.3768C19.0546 21.138 17.4408 20.4201 16.1544 19.1731C14.5635 17.6309 14.4701 17.1003 14.1704 16.628C13.9306 16.2502 14.1066 16.0184 14.1944 15.917C14.5371 15.5215 15.0104 14.9109 15.2227 14.6075C15.4349 14.304 15.2664 13.8433 15.1653 13.5564C14.7305 12.3223 14.3621 11.2893 14.0625 10.6876Z"
        fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_9080_8969" x1="29.8125" y1="7.875" x2="4.5" y2="31.5"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#5BD066" />
        <stop offset="1" stop-color="#27B43E" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'WhatsAppIconCircle',
}
</script>

<style scoped></style>
