<template>
    <div class="linear-loader-container">
        <div class="progress-linear__image">
            <slot></slot>
        </div>
        <v-progress-linear
                indeterminate
                color="#5D6471"
        ></v-progress-linear>
    </div>
</template>

<script>
    export default {
        name: 'LinearLoader',
    }
</script>

<style scoped lang="scss">
    .linear-loader-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        .progress-linear__image {
            margin-bottom: 30px;
        }
        .v-progress-linear {
            width: 150px;
        }
    }
</style>