export class FieldPropertyInputGroup {
    constructor(inputGroup) {
        this.inputGroup = inputGroup
        Object.assign(this, inputGroup)
    }

    generatePropertyValue() {
        if ('default' in this.inputGroup)
            return this.inputGroup.default.generatePropertyInputValue()

        return Object.fromEntries(
            Object.entries(this.inputGroup)
                .map(([inputKey, inputDefinition]) => [inputKey, inputDefinition.generatePropertyInputValue()])
        )
    }
}

export class FieldPropertyInputGroupWithSimpleConstructor extends FieldPropertyInputGroup {
    constructor(defaultInputDefinition) {
        super({default: defaultInputDefinition})
    }
}

export class FieldPropertyInputGroupWithComplexConstructor extends FieldPropertyInputGroup {
    constructor(inputGroupDefinition) {
        super(inputGroupDefinition)
    }
}