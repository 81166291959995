var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormParametersContentTemplate', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "form-settings-header"
        }, [_c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.integrateModel ? _c('ChevronLeftIcon', {
          key: "email-notifications-icon",
          staticClass: "cursor-pointer ml-n1",
          on: {
            "click": function ($event) {
              _vm.integrateModel = false;
            }
          }
        }) : _c('BoxIcon', {
          key: "general-settings-icon",
          staticClass: "ml-n1"
        })], 1), _c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.integrateModel ? _c('span', {
          key: "app-connections-header"
        }, [_vm._v(" Integrate " + _vm._s(_vm.integrationsApp[_vm.selectedIndex].title) + " ")]) : _c('span', {
          key: "integration-header"
        }, [_vm._v(" Integrations ")])])], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "form-settings-content thin-scrollbar"
        }, [_c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.integrateModel ? _c('IntegrateSettings', {
          attrs: {
            "app": _vm.integrationsApp[_vm.selectedIndex],
            "loader": _vm.showLoader
          },
          on: {
            "connection": _vm.connection,
            "disconnect": _vm.disconnect
          }
        }) : _c('div', {
          staticClass: "thin-scrollbar"
        }, [_c('GeneralFormIntegrations', {
          attrs: {
            "apps-list": _vm.integrationsApp
          },
          on: {
            "open-connect-settings": _vm.openConnectApp
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }