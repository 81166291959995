var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "flex-grow-1",
    on: {
      "dblclick": _vm.startEditing
    }
  }, [_c('v-text-field', {
    ref: "pageNameInput",
    staticClass: "page-name-input",
    attrs: {
      "value": _vm.pageName,
      "placeholder": "Add page name",
      "disabled": !_vm.isEditing,
      "background-color": "transparent",
      "dense": "",
      "full-width": "",
      "flat": "",
      "solo": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.changePageName,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.stopEditing.apply(null, arguments);
      },
      "blur": _vm.stopEditing
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }