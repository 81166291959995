var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select-with-virtual-scroll', _vm._g(_vm._b({
    attrs: {
      "items": _vm.filteredFonts,
      "item-value": function (item) {
        return item.name;
      },
      "item-text": function (item) {
        return item.name;
      },
      "value": _vm.value,
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "Search fonts",
            "height": "40",
            "solo": "",
            "flat": "",
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "prepend-inner",
            fn: function () {
              return [_c('SearchIcon', {
                staticClass: "gray_400--text mr-2",
                attrs: {
                  "size": "20"
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.fontFilter,
            callback: function ($$v) {
              _vm.fontFilter = $$v;
            },
            expression: "fontFilter"
          }
        })];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs,
          item = _ref.item;
        return [_c('f-template-data-transfer', {
          attrs: {
            "loaded": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var dataStash = _ref2.dataStash;
              return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-content', {
                directives: [{
                  name: "intersect",
                  rawName: "v-intersect.once",
                  value: function () {
                    if (item.url) {
                      _vm.Utils.addFontFamilyURL(item.url).then(function () {
                        return dataStash.loaded = true;
                      });
                    } else { dataStash.loaded = true; }
                  },
                  expression: "() => {\n                            if (item.url) {\n                                Utils.addFontFamilyURL(item.url).then(() => dataStash.loaded = true)\n                            } else\n                                dataStash.loaded = true\n                        }",
                  modifiers: {
                    "once": true
                  }
                }]
              }, [dataStash.loaded ? _c('v-list-item-title', {
                style: {
                  fontFamily: item.name
                }
              }, [_vm._v(_vm._s(item.name))]) : _c('v-skeleton-loader', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }])
  }, 'f-select-with-virtual-scroll', Object.assign({}, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }