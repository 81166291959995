var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEditor,
      expression: "closeEditor"
    }],
    ref: "moveableTarget",
    staticClass: "moveable-target"
  }, [_c('v-scale-transition', [_vm.isMoveableControlBoxVisible ? _c('div', {
    staticClass: "moveable-target__remove-icon",
    on: {
      "click": _vm.clearCoverText
    }
  }, [_c('XIcon', {
    attrs: {
      "size": "16"
    }
  })], 1) : _vm._e()]), _c('v-menu', {
    attrs: {
      "value": _vm.isMoveableControlBoxVisible && (_vm.isEditorOpenbyClick || _vm.isEditorOpenBySelection),
      "offset-y": "",
      "transition": "scale-transition",
      "origin": "center",
      "nudge-bottom": _vm.isTextOverflowContainer ? -10 : 10,
      "top": _vm.isTextOverflowContainer,
      "nudge-left": _vm.nudgeLeft,
      "close-on-content-click": false,
      "close-on-click": false,
      "content-class": "moveable-container",
      "min-width": "509"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', {
          staticClass: "text-container"
        }, [on ? _c('span', {
          ref: "moveableText",
          staticClass: "text",
          class: _vm.placeholder,
          attrs: {
            "contenteditable": _vm.isContentEditable,
            "data-placeholder": "Add Form Title"
          },
          on: {
            "click": _vm.editorVisibleControl,
            "paste": _vm.processPaste,
            "input": _vm.onInput,
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && $event.keyCode !== 8) { return null; }
              return _vm.handleKeyDown.apply(null, arguments);
            }
          }
        }, [_c('p', {
          ref: "moveableTextContainer"
        }, [_c('br')])]) : _vm._e()])];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-card', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeEditor,
            expression: "closeEditor"
          }],
          ref: "moveableEditor",
          staticClass: "moveable-editor",
          class: ['rounded-pill'],
          attrs: {
            "height": "43",
            "width": "509"
          }
        }, [_c('v-slide-x-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.isLinkCreatorOpened ? _c('v-text-field', {
          staticClass: "moveable-editor__link-input",
          attrs: {
            "placeholder": "Add a URL",
            "solo": "",
            "dense": "",
            "flat": "",
            "height": "43",
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function () {
              return [_c('v-btn', {
                attrs: {
                  "dark": "",
                  "width": "88",
                  "color": "#3D66F6",
                  "rounded": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.createLink(_vm.createdLink);
                  }
                }
              }, [_vm._v(" Confirm ")])];
            },
            proxy: true
          }], null, false, 1292198184),
          model: {
            value: _vm.createdLink,
            callback: function ($$v) {
              _vm.createdLink = $$v;
            },
            expression: "createdLink"
          }
        }) : _c('div', {
          staticClass: "moveable-editor__parameters",
          on: {
            "click": _vm.restoreSelection
          }
        }, [_c('FontPickerComponent', {
          staticClass: "moveable-editor__parameters-font-family-select",
          attrs: {
            "attach": ".moveable-editor__parameters-font-family-select",
            "solo": "",
            "flat": "",
            "menu-props": {
              nudgeBottom: 45,
              minWidth: 250,
              nudgeLeft: 20,
              maxHeight: 'auto'
            }
          },
          on: {
            "change": _vm.setSelectedFont
          },
          model: {
            value: _vm.selectedFont,
            callback: function ($$v) {
              _vm.selectedFont = $$v;
            },
            expression: "selectedFont"
          }
        }), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('f-select', {
          staticClass: "moveable-editor__parameters-font-size-select",
          attrs: {
            "items": _vm.constants.TEXT_EDITOR_CONSTANTS.FONT_SIZES,
            "solo": "",
            "flat": "",
            "dense": "",
            "height": "17",
            "append-icon": "",
            "hide-details": "",
            "attach": ".moveable-editor__parameters-font-size-select",
            "menu-props": {
              nudgeBottom: 35,
              minWidth: 83,
              nudgeLeft: 10,
              auto: true,
              contentClass: 'font-size-select__v-menu'
            }
          },
          on: {
            "change": _vm.setSelectedFontSize
          },
          scopedSlots: _vm._u([{
            key: "append-outer",
            fn: function () {
              return [_c('div', {
                staticClass: "moveable-editor__parameters-font-size-select__controls"
              }, [_c('ChevronUpIcon', {
                staticClass: "moveable-editor__parameters-icon--hover",
                attrs: {
                  "size": "12"
                },
                on: {
                  "click": _vm.increaseFontSize
                }
              }), _c('ChevronDownIcon', {
                staticClass: "moveable-editor__parameters-icon--hover",
                attrs: {
                  "size": "12"
                },
                on: {
                  "click": _vm.decreaseFontSize
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "item",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs,
                item = _ref2.item;
              return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_vm.fontSizesToShow.includes(item) ? _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item))])], 1) : _c('span')], 1)];
            }
          }]),
          model: {
            value: _vm.selectedFontSize,
            callback: function ($$v) {
              _vm.selectedFontSize = $$v;
            },
            expression: "selectedFontSize"
          }
        }), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('f-select', {
          staticClass: "moveable-editor__parameters-text-align-select",
          attrs: {
            "items": _vm.constants.TEXT_EDITOR_CONSTANTS.TEXT_ALIGN,
            "item-value": function (item) {
              return item.value;
            },
            "solo": "",
            "flat": "",
            "dense": "",
            "height": "17",
            "append-icon": "",
            "hide-details": "",
            "attach": ".moveable-editor__parameters-text-align-select",
            "menu-props": {
              nudgeBottom: 35,
              minWidth: 180,
              nudgeLeft: 90
            }
          },
          on: {
            "change": _vm.setSelectedTextAlign
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs,
                item = _ref3.item;
              return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1)], 1)];
            }
          }, {
            key: "selection",
            fn: function (_ref4) {
              var item = _ref4.item;
              return [_c('v-icon', {
                staticClass: "moveable-editor__parameters-icon--hover"
              }, [_vm._v(_vm._s(item.icon) + " ")])];
            }
          }]),
          model: {
            value: _vm.selectedTextAlign,
            callback: function ($$v) {
              _vm.selectedTextAlign = $$v;
            },
            expression: "selectedTextAlign"
          }
        }), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('v-icon', {
          staticClass: "moveable-editor__parameters-icon--hover",
          class: _vm.isBold,
          on: {
            "click": function ($event) {
              return _vm.setFontStyle('bold');
            }
          }
        }, [_vm._v("$format_bold ")]), _c('v-icon', {
          staticClass: "moveable-editor__parameters-icon--hover",
          class: _vm.isItalic,
          on: {
            "click": function ($event) {
              return _vm.setFontStyle('italic');
            }
          }
        }, [_vm._v("$format_italic ")]), _c('v-icon', {
          staticClass: "moveable-editor__parameters-icon--hover",
          class: _vm.isUnderline,
          on: {
            "click": function ($event) {
              return _vm.setFontStyle('underline');
            }
          }
        }, [_vm._v("$format_underline ")]), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('div', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeLinkCreator,
            expression: "closeLinkCreator"
          }],
          staticClass: "moveable-editor__parameters-link"
        }, [_c('v-icon', {
          staticClass: "moveable-editor__parameters-icon--hover",
          class: _vm.isInsideLink,
          on: {
            "click": function ($event) {
              _vm.isSelectedTextInsideTheLink ? _vm.removeLink() : _vm.isLinkCreatorOpened = !_vm.isLinkCreatorOpened;
            }
          }
        }, [_vm._v("$create_link ")])], 1), _c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('v-menu', {
          attrs: {
            "offset-y": "",
            "bottom": "",
            "close-on-content-click": false,
            "close-on-click": false,
            "nudge-bottom": "27",
            "nudge-left": "180",
            "attach": ".moveable-editor__parameters-color-picker",
            "max-width": "290",
            "min-width": "290",
            "value": _vm.isColorPickerVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var on = _ref5.on;
              return [on ? _c('span', {
                directives: [{
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: function () {
                    return _vm.isColorPickerVisible = false;
                  },
                  expression: "() => isColorPickerVisible = false"
                }],
                staticClass: "moveable-editor__parameters-color-picker",
                style: {
                  backgroundColor: _vm.selectedColor || _vm.selectedCustomColor
                },
                on: {
                  "click": function ($event) {
                    if ($event.target !== $event.currentTarget) { return null; }
                    _vm.isColorPickerVisible = !_vm.isColorPickerVisible;
                  }
                }
              }) : _vm._e()];
            }
          }])
        }, [[_c('v-card', {
          staticClass: "colors-container"
        }, [_c('div', {
          staticClass: "colors-palette-list"
        }, [_vm._l(_vm.palette, function (color) {
          return _c('div', {
            key: color,
            staticClass: "colors-palette-list__color-tile",
            class: color === _vm.selectedColor && 'colors-palette-list__color-tile--active',
            style: {
              background: color
            },
            on: {
              "click": function ($event) {
                return _vm.setActiveColor(color);
              }
            }
          }, [color === '#FFFFFF' ? _c('div', {
            staticClass: "colors-palette-list__color-tile--white",
            style: {
              border: '1px solid #DCDCDC'
            }
          }) : _vm._e()]);
        }), _c('v-menu', {
          attrs: {
            "content-class": "white",
            "offset-y": "",
            "nudge-bottom": "50",
            "close-on-content-click": false,
            "close-on-click": false,
            "attach": ".colors-container",
            "value": _vm.isColorPickerVisible && _vm.isCustomColorPickerVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [on ? _c('div', {
                staticClass: "colors-palette-list__color-tile color-tile--picker",
                style: {
                  backgroundColor: _vm.selectedCustomColor
                },
                on: {
                  "click": function ($event) {
                    _vm.isCustomColorPickerVisible = !_vm.isCustomColorPickerVisible;
                  }
                }
              }, [_c('PlusIcon', {
                staticClass: "plus-icon"
              })], 1) : _vm._e()];
            }
          }, {
            key: "default",
            fn: function () {
              return [_c('v-card', [_c('v-color-picker', {
                attrs: {
                  "hide-mode-switch": "",
                  "mode": "hexa"
                },
                model: {
                  value: _vm.selectedCustomColor,
                  callback: function ($$v) {
                    _vm.selectedCustomColor = $$v;
                  },
                  expression: "selectedCustomColor"
                }
              }), _c('div', {
                staticClass: "d-flex pa-4 pt-0"
              }, [_c('v-btn', {
                attrs: {
                  "color": "blue_400 mr-2",
                  "rounded": ""
                },
                on: {
                  "click": _vm.setCustomActiveColor
                }
              }, [_vm._v("Add ")]), _c('v-btn', {
                attrs: {
                  "color": "gray_100",
                  "rounded": ""
                },
                on: {
                  "click": _vm.unsetCustomActiveColor
                }
              }, [_vm._v("Cancel ")])], 1)], 1)];
            },
            proxy: true
          }])
        })], 2)])]], 2)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _vm.isMoveableControlBoxVisible ? _c('Moveable', {
    key: 'key' + _vm.moveableKey,
    attrs: {
      "target": ['.moveable-target'],
      "draggable": true,
      "resizable": true,
      "origin": false,
      "snappable": true,
      "renderDirections": ['e', 'w']
    },
    on: {
      "drag": _vm.onDrag,
      "resize": _vm.onResize,
      "resizeStart": _vm.onResizeDragStart,
      "resizeEnd": _vm.onResizeDragEnd,
      "dragStart": _vm.onResizeDragStart,
      "dragEnd": _vm.onResizeDragEnd
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }