var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('BackToDashboardComponent'), _c('v-divider', {
          staticClass: "app-bar-form-editor__divider",
          attrs: {
            "vertical": ""
          }
        }), _c('FormEditorAndResponsesNavigationComponent')];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('FormNameEditor')];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('FormAutosaveComponent'), !_vm.getOverlay ? _c('PreviewButton') : _vm._e(), _c('PublishButton', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.getOverlay && _vm.$route.name === _vm.constants.PAGE_NAME_FORM_EDITOR && _vm.isPublishedFormWasChanged,
            expression: "!getOverlay && $route.name === constants.PAGE_NAME_FORM_EDITOR && isPublishedFormWasChanged"
          }],
          on: {
            "published": function ($event) {
              _vm.shareFormVisible = true;
            }
          }
        }), !_vm.getOverlay && _vm.$route.name === _vm.constants.PAGE_NAME_FORM_EDITOR && !_vm.isPublishedFormWasChanged ? _c('LockedPublishButton', {
          attrs: {
            "share-form-visible": _vm.shareFormVisible
          }
        }) : _vm._e(), _c('transition', {
          attrs: {
            "mode": "out-in",
            "name": "slide-y-transition"
          }
        }, [_vm.shareFormVisible ? _c('div', {
          staticClass: "share-form-menu__wrapper"
        }, [_c('ShareFormMenu', {
          attrs: {
            "shareLink": _vm.shareLink,
            "formJSON": _vm.getFormJSON,
            "is-form-static": _vm.isFormStatic,
            "is-form-interactive": _vm.isFormInteractive,
            "fsp-module-location-path": "edit/FSP"
          },
          scopedSlots: _vm._u([{
            key: "subtitle",
            fn: function () {
              return [_c('div', {
                staticClass: "success pa-1 rounded-circle mr-1"
              }), _c('div', {
                staticClass: "mr-5"
              }, [_vm._v(" It’s live and public ")])];
            },
            proxy: true
          }, {
            key: "title",
            fn: function () {
              return [_vm._v(" Woohoo! your form has been published "), _c('div', {
                staticClass: "ml-2",
                staticStyle: {
                  "{font-size": "24px}"
                }
              }, [_vm._v(_vm._s(_vm.constants.EMOJI.PARTY))])];
            },
            proxy: true
          }], null, false, 2837292717),
          model: {
            value: _vm.shareFormVisible,
            callback: function ($$v) {
              _vm.shareFormVisible = $$v;
            },
            expression: "shareFormVisible"
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }