export const Visible = {
    inserted: (el, binding) => {
        if (binding.value)
            el.classList.remove('scale-0')
        else
            el.classList.add('scale-0')
    },
    update: (el, binding) => {
        if (binding.value)
            el.classList.remove('scale-0')
        else
            el.classList.add('scale-0')
    }
}

export default Visible