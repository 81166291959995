<template>
    <div
            :class="[
                'star-rating-field-view',
                isEmojiScale && 'star-rating-field-view--emoji-scale',
                isScaleIcon && 'star-rating-field-view--scale-icon',
            ]"
    >
        <div class="star-rating-field-view__layout">
            <template v-if="isEmojiScale">
                <div
                        v-for="(emoji, index) in fieldConst.BASE_PROPERTIES.emoji_scale.input_group.default.sets[fieldProperties.emoji_scale]"
                        class="star-rating-field-view__item-wrapper"
                        :key="'emoji-scale' + fieldProperties.emoji_scale + index"
                >
                    <div
                            class="star-rating-field-view__item"
                            :class="index === model && 'star-rating-field-view__item--selected'"
                            @click="$emit('select-option', index)"
                    >{{ emoji }}</div>
                </div>
            </template>
            <template v-else-if="isScaleIcon">
                <div
                        v-for="index in 5"
                        class="star-rating-field-view__item-wrapper"
                        :key="'scale-icon' + fieldProperties.scale_icon + (index - 1)"
                >
                    <div
                            class="star-rating-field-view__item"
                            @click="$emit('select-option', index - 1)"
                    >
                        <LottieIconPlayer
                                :json="fieldConst.BASE_PROPERTIES.scale_icon.input_group.default.emojis[fieldProperties.scale_icon]"
                                :active="model !== null && (index - 1) <= model"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GenericFrontView",
        components: {
            LottieIconPlayer: () => import('./Icons/LottieIconPlayer')
        },
        props: {
            fieldProperties: {
                type: Object,
                required: true
            },
            model: {
                type: Number,
                default: null
            },
        },
        computed: {
            isEmojiScale() {
                return this.fieldProperties.emoji_scale !== null
            },
            isScaleIcon() {
                return this.fieldProperties.scale_icon !== null
            },
            fieldConst() {
                return this.constants.FIELD_TYPES.STAR_RATING
            }
        }
    }
</script>
