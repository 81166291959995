<template>
    <div class="search-bar" :class="{'search-bar-active': isSearchActive}">
        <v-fade-transition>
            <f-hover-tooltip top
                           text="Search"
                           v-slot="{on}"
          >
            <v-btn  v-on="on"
                    v-if="!isSearchActive"
                    @click="activateSearch"
                    class="v-btn--primary v-btn--thin-icon search-bar__button"
                    :disabled="disabled"
                    icon
            >
                <SearchIcon @click="activateSearch" class="cursor-pointer" size="18"/>
            </v-btn>
          </f-hover-tooltip>
        </v-fade-transition>
        <v-expand-x-transition>
            <v-text-field
                    v-if="isSearchActive"
                    v-model="searchQuery"
                    class="search-bar__input"
                    ref="searchField"
                    rounded
                    outlined
                    dense
                    hide-details
                    @input="searchInput"
                    @focusout="deactivateSearch"
            >
                <template #append>
                    <XIcon
                            class="cursor-pointer gray_400--text"
                            size="16"
                            @click="clearQuery"
                    />
                </template>
            </v-text-field>
        </v-expand-x-transition>
    </div>
</template>

<script>
    import {SearchIcon, XIcon} from 'vue-feather-icons'

    export default {
        name: "SearchBarComponent",
        components: {
            SearchIcon,
            XIcon
        },
        props: {
            value: {
                type: String,
                required: true
            },
            lowercase: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            isSearchActive: false,
            searchQuery: ''
        }),
        watch: {
            disabled(v) {
                if (!v) {
                    this.searchQuery = ''
                    this.searchInput()
                    this.deactivateSearch()
                }
            }
        },
        methods: {
            activateSearch() {
                this.isSearchActive = true
                setTimeout(() => this.$refs.searchField.focus(), 10)
            },
            deactivateSearch() {
                if (!this.searchQuery)
                    this.isSearchActive = false
            },
            searchInput() {
                this.$emit('input', this.lowercase ? this.searchQuery.toLocaleLowerCase() : this.searchQuery)
            },
            clearQuery() {
                this.searchQuery = ''
                this.searchInput()
                this.$refs.searchField.focus()
            }
        }
    }
</script>

<style lang="scss">
    .search-bar {
        display: flex;
        align-items: center;
        height: 42px;

        .v-text-field {
            .v-input__slot {
                padding-right: 10px !important;
            }
        }
    }
</style>