<template>
    <div>
        <v-dialog
            v-model="dialogVisible"
            max-width="850"
        >
            <template #activator="{on}">
                <slot name="activator" :on="on">
                </slot>
            </template>
            <v-card>
                <div class="dialog-container">
                    <div style="cursor: pointer; position: absolute; top: 20px; right: 20px">
                        <XIcon @click="closeDialog"/>
                    </div>
                    <div style="padding: 20px; font-size: 20px;">
                        Privacy Policy
                        <v-spacer/>
                    </div>
                    <div style="height: 800px; overflow-y: scroll; padding: 20px">

                        This privacy policy ("Policy") explains how we collect, use, disclose, and protect
                        personal<br>
                        information of users of our website and services ("Services").<br>
                        We are committed to protecting your privacy and your personal data. This Privacy Policy
                        describes<br>
                        how we collect, use, and share your personal information in compliance with the General Data<br>
                        Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).<br>
                        <br>

                        <b>Collection of Information</b><br>
                        We may collect personal information from you, such as your name, email address, mailing
                        address,<br>
                        phone number, or payment information when you use our Services. We may also collect
                        demographic<br>
                        information such as your age, gender, and interests. We collect this information when
                        you<br>
                        voluntarily provide it to us or when you use our Services with your consent.<br>
                        <br>
                        We may obtain information about you from outside sources, which include third-party sources
                        and<br>
                        publicly available data. The information we obtain may include Technical Data from analytics<br>
                        providers, advertising networks, and search information providers. We may also receive
                        Contact,<br>
                        Financial, and Transaction Data from technical and payment service providers. Additionally,
                        we
                        may obtain Identity and Contact Data from data brokers or aggregators, as well as from
                        publicly<br>
                        available sources<br>
                        <br>
                        We will only use your personal information when it is legally allowed. The most common
                        situations in<br>
                        which we will use your personal information include performing a contract we have with you,
                        pursuing<br>
                        our legitimate interests (or those of a third party) while ensuring that your fundamental
                        rights and<br>
                        interests are not compromised, and complying with legal obligations.<br>

                        <br>
                        <b> We collect data from you using various methods, which include:</b><br>
                        Direct interactions where you provide us with Identity, Contact, and Financial Data by
                        filling
                        in forms or corresponding with us via mail, phone, email, or other means. This includes
                        personal<br>
                        information you provide when you apply for our services, upload audio or video files on
                        the<br>
                        Applications, create an account, subscribe to our services or publications, request
                        marketing<br>
                        material, complete a survey, or provide feedback.<br>
                        <br>
                        Automated technologies or interactions, where we automatically collect Technical Data about
                        your<br>
                        equipment, browsing actions, and patterns as you interact with the Applications. We gather
                        this<br>
                        personal data using cookies, server logs, and other similar technologies. We may also
                        receive<br>
                        Technical Data about you from other websites that use our cookies. Please see our cookie
                        policy
                        for more information.<br>
                        <br>
                        <b>Use of Information</b><br>
                        We use the information we collect to provide, maintain, and improve our Services, to
                        personalize<br>
                        your experience, to communicate with you, to process your transactions, to analyze how our
                        Services are used, and to comply with legal obligations. We may also use the information to
                        send you<br>
                        promotional messages and offers that may be of interest to you. You may opt out of receiving
                        such messages at any time.<br>
                        <br>
                        <b>Disclosure of Information</b><br>
                        We may share your personal information with our affiliates, service providers, and other
                        third<br>
                        parties as necessary to provide our Services and to comply with legal obligations. We may
                        also share<br>
                        your information with your consent or as otherwise permitted by law. We will not sell your
                        personal information to third parties.<br>
                        <br>
                        <b>Cookies and Tracking Technologies</b><br>
                        We use cookies and other tracking technologies to collect information about your use of
                        our<br>
                        Services, such as your browsing history, the pages you visit, and your preferences. We may
                        also
                        use cookies and tracking technologies to display personalized advertisements and to measure
                        the<br>
                        effectiveness of our marketing campaigns. You can disable cookies in your browser settings,
                        but this<br>
                        may limit your ability to use certain features of our Services.<br>
                        <br>
                        <b>Under the GDPR and CCPA, you have the right to:</b><br>
                        ● Request access to and receive information about the personal information we hold about you<br>
                        ● Request correction or deletion of your personal information<br>
                        ● Object to the processing of your personal information<br>
                        ● Request that we restrict the processing of your personal information<br>
                        ● Request that we transfer your personal information to another organization<br>
                        ● Withdraw your consent to the processing of your personal information at any time<br>
                        <br>
                        <b>Security</b><br>
                        We take reasonable measures to protect your personal information from unauthorized
                        access,<br>
                        disclosure, or destruction. However, no method of transmission over the internet or
                        electronic<br>
                        storage is completely secure, and we cannot guarantee absolute security.<br>
                        <br>
                        <b>Children's Privacy</b><br>
                        Our Services are not directed to children under the age of 16, and we do not knowingly
                        collect<br>
                        personal information from children. If we become aware that we have collected personal
                        information from a child without verification of parental consent, we will take steps to
                        remove the
                        information from our servers.<br>
                        <br>
                        <b>Changes to this Policy</b><br>
                        We may update this Policy from time to time by posting a new version on our website. If we
                        make<br>
                        material changes to the Policy, we will notify you by email or by posting a notice on our
                        website<br>
                        prior to the effective date of the changes.<br>
                        <br>
                        <b>Contact Us</b><br>
                        If you have any questions about this Policy or our practices, please contact us at [insert
                        contact information].<br>
                        <br>
                        <b>California Consumer Privacy Act (CCPA) Supplement:</b><br>
                        <br>
                        <b>Collection of Personal Information</b><br>
                        In addition to the personal information collected, we may collect additional categories of
                        personal information from California residents, including identifiers such as IP address,
                        geolocation
                        data, and commercial information such as records of products or services purchased.<br>
                        <br>
                        <b>Disclosure of Personal Information</b><br>
                        In addition to the disclosure of personal information, we may share your personal
                        information
                        with third parties for business purposes or as otherwise required by law. We do not sell
                        personal
                        information to third parties.<br>
                        Your CCPA Rights<br>
                        If you are a California resident, you have the right to request that we disclose to you
                        the<br>
                        categories of personal information we have collected about you, the categories of sources
                        from which<br>
                        the personal information is collected, the business or commercial purpose for collecting or
                        selling<br>
                        <br>
                        <b>We Do Not Sell Your Information</b><br>
                        We do not sell your personal information to any third parties. Under the CCPA, you have the
                        right to<br>
                        request that we do not sell your information, and we respect that right. We only collect and
                        use<br>
                        your information for the purposes specified in our privacy policy, which include providing
                        you
                        with the products and services you have requested, improving our offerings, and
                        communicating with
                        you about our company and products.<br>
                        If you have any questions or concerns about how we handle your personal information, or if
                        you would<br>
                        like to exercise your rights under the CCPA, please refer to our privacy policy or contact
                        us directly.<br>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {XIcon} from 'vue-feather-icons'

    export default {
        name: "TermOfUse",
        components: {
            XIcon
        },
        data() {
            return {
                dialogVisible: this.isVisible
            };
        },
        methods: {
            closeDialog() {
                this.dialogVisible = false;
                this.$emit('update:isVisible', false);
            }
        },
        props: {
            isVisible: {
                type: Boolean,
            },
        }
    }
</script>

<style lang="scss">
    dialog-container {
        display: flex;
        padding: 20px;
        position: relative;

        &-x-icon {
            position: absolute;
            top: 25px;
            right: 25px;
            color: #8A8F99;
            stroke-width: 1px;
            cursor: pointer;
        }

        &-title {
            font-size: 26px;
            line-height: 31px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }

</style>