<template>
    <div
            :class="[
                'checkout-billing-plan-card',
                selected && 'checkout-billing-plan-card--selected'
            ]"

            :data-characteristic="characteristic"
    >
        <div
                class="checkout-billing-plan-card__content"
                @click="(!selected && !currentPlan) ? $emit('select') : null"
        >
            <div class="checkout-billing-plan-card__info">
                <div class="checkout-billing-plan-card__heading">
                    <div class="checkout-billing-plan-card__name">
                        <span>{{ name }}</span>
                    </div>
                    <div v-if="currentPlan" class="checkout-billing-plan-card__current-plan">
                        <span>Current Plan</span>
                    </div>
                </div>
                <div class="checkout-billing-plan-card__description">
                    <span>{{ description }}</span>
                </div>
                <div class="checkout-billing-plan-card__price-layout">
                    <div class="checkout-billing-plan-card__price" data-currency="$">{{ price }}</div>
                    <div class="checkout-billing-plan-card__price-annotation">Billed yearly</div>
                </div>
            </div>
            <div class="checkout-billing-plan-card__included-features">
                <div
                        v-for="(feature, index) in features"
                        class="checkout-billing-plan-card-included-feature"
                        :key="'feature' + index"
                >
                    <div class="checkout-billing-plan-card-included-feature__icon">
                        <CheckIcon/>
                    </div>
                    <span class="checkout-billing-plan-card-included-feature__name">{{ feature }}</span>
                </div>
            </div>
            <div class="checkout-billing-plan-card__see-all-features">
                <span @click.stop="$emit('see-all-features')">See all features</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BillingPlanCard",
        components: {
            CheckIcon: () => import('vue-feather-icons/icons/CheckIcon')
        },
        props: {
            name: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            price: {
                type: [String, Number],
                required: true
            },
            features: {
                type: Array,
                required: true
            },

            selected: {
                type: Boolean,
                required: true
            },
            characteristic: String,
            currentPlan: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-billing-plan-card {
        $characteristic-container-height: 27px;

        margin-top: $characteristic-container-height;

        position: relative;

        &[data-characteristic]::before {
            content: attr(data-characteristic);

            position: absolute;
            top: -$characteristic-container-height;
            height: calc(100% / 2);
            width: 100%;
            left: 0;
            z-index: 0;

            border-radius: 10px;
            background-color: #EAF3FF;
            color: var(--v-blue_500-base);
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: -0.04em;

            line-height: 12px;
            padding-top: calc((#{$characteristic-container-height} - 12px) / 2);
        }

        &--selected .checkout-billing-plan-card__content {
            box-shadow: 0 0 0 2px var(--v-blue_400-base);
        }

        &__content {
            cursor: pointer;

            width: calc(176px + 2 * 24px);
            padding: 24px;
            background-color: white;
            border-radius: 15px;
            box-shadow: 0 0 0 1px var(--v-gray_200-base);

            position: relative;
            z-index: 1;

            &:hover {
                background-color: var(--v-blue_100-base);
            }
        }

        &__info {
            margin-bottom: 24px;
        }

        &__heading {
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__name {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: -0.04em;
            color: black;
        }

        &__current-plan {
            font-size: 12px;
            color: var(--v-gray_400-base);
        }

        &__description {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: -0.04em;
            color: black;
            margin-bottom: 28px;
        }

        &__price-layout {
            display: flex;
            align-items: center;
        }

        &__price {
            &::before {
                content: attr(data-currency);
                vertical-align: top;
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
                letter-spacing: -0.04em;
                margin-right: 2px;
            }

            font-size: 48px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: -0.04em;

            margin-right: 9px;
        }

        &__price-annotation {
            width: min-content;

            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            white-space: break-spaces;
            color: var(--v-gray_400-base);
        }

        &__included-features {
            margin-bottom: 24px;
        }

        .checkout-billing-plan-card-included-feature {
            display: flex;
            align-items: center;

            row-gap: 4px;

            &__icon {
                display: flex;
                margin-right: 4px;

                svg {
                    width: 20px;
                    height: 20px;

                    color: var(--v-blue_400-base);
                }
            }

            &__name {
                font-size: 14px;
                font-weight: 300;
                line-height: 17px;
                letter-spacing: -0.04em;
            }

            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
        }

        &__see-all-features {
            color: var(--v-gray_500-base);
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.04em;
            font-weight: 500;
            text-align: center;
            text-decoration: underline;
        }
    }
</style>