<template>
  <SharedView
      editable
      placeholder="Add placeholder"
      v-model="fieldObj.content.placeholder"
      :is-editing-active="isEditingActive"
  />
</template>

<script>
import editable_field_view_mixin
  from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

export default {
  name: "Editable__Signature",
  mixins: [editable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  }
}
</script>