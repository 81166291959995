var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    staticStyle: {
      "max-height": "100vh"
    }
  }, [_c('InteractivePageScroller', {
    ref: "pageScroller",
    attrs: {
      "current-page-id": _vm.cachedPageID,
      "previous-page-id": _vm.previousPageID,
      "next-page-id": _vm.nextPageID,
      "scrolling-active": _vm.scrollingActive
    },
    on: {
      "scroll-started": function ($event) {
        _vm.scrollingActive = true;
      },
      "scrolled": _vm.scrolled
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var pageId = _ref.pageId;
        return [pageId && _vm.getFormSourceJSON.all_pages[pageId].type === _vm.constants.FORM_PAGES_TYPES.HOME_PAGE ? _c('InteractiveHomePageInterlayer', {
          key: 'home' + pageId,
          attrs: {
            "page-id": pageId
          }
        }) : pageId && _vm.getFormSourceJSON.all_pages[pageId].type === _vm.constants.FORM_PAGES_TYPES.MAIN_PAGE ? _c('InteractiveMainPage', {
          key: 'main' + pageId,
          attrs: {
            "page-id": pageId
          }
        }) : pageId && _vm.getFormSourceJSON.all_pages[pageId].type === _vm.constants.FORM_PAGES_TYPES.FINISH_PAGE ? _c('InteractiveFinishPage', {
          key: 'finish' + pageId
        }) : _vm._e()];
      }
    }])
  }), _c('NavigationArrows', {
    attrs: {
      "disabled": _vm.scrollingActive
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }