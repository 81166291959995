import {
    VListItem,
    VList,
    VVirtualScroll
} from 'vuetify/lib/components'
import VSelectList from 'vuetify/lib/components/VSelect/VSelectList'
import { Select } from '@/components/UIKit/components'


const SelectListWithVirtualScroll = {
    extends: VSelectList,
    props: {
        itemHeight: {
            type: Number
        },
        listHeight: {
            type: Number
        }
    },
    methods: {
        _genTile({
            item,
            index,
            disabled = null,
            value = false
        }) {
            if (!value) value = this.hasItem(item);

            if (item === Object(item)) {
                disabled = disabled !== null ? disabled : this.getDisabled(item);
            }

            const tile = {
                attrs: {
                    // Default behavior in list does not
                    // contain aria-selected by default
                    'aria-selected': String(value),
                    id: `list-item-${this._uid}-${index}`,
                    role: 'option'
                },
                on: {
                    mousedown: e => {
                        // Prevent onBlur from being called
                        e.preventDefault();
                    },
                    click: () => disabled || this.$emit('select', item)
                },
                props: {
                    activeClass: this.tileActiveClass,
                    disabled,
                    ripple: true,
                    inputValue: value
                }
            };

            if (!this.$scopedSlots.item) {
                return this.$createElement(VListItem, tile, [this.action && !this.hideSelected && this.items.length > 0 ? this.genAction(item, value) : null, this.genTileContent(item, index)]);
            }

            const parent = this;
            const scopedSlot = this.$scopedSlots.item({
                parent,
                item,
                attrs: {
                    ...tile.attrs,
                    ...tile.props
                },
                on: tile.on
            });
            return this.needsTile(scopedSlot) ? this.$createElement(VListItem, tile, scopedSlot) : scopedSlot;
        },
    },
    render() {
        return this.$createElement(
            VList,
            {
                staticClass: 'v-select-list',
                class: this.themeClasses,
                attrs: {
                    role: 'listbox',
                    tabindex: -1
                },
                props: {
                    dense: true
                }
            },
            [
                ...(this.$slots['prepend-item'] ? [this.$slots['prepend-item']] : []),
                this.$createElement(
                    VVirtualScroll,
                    {
                        class: 'thin-scrollbar',
                        props: {
                            height: this.listHeight,
                            itemHeight: this.itemHeight,
                            items: this.items,
                            bench: 3
                        },
                        scopedSlots: {
                            default: this._genTile
                        }
                    }
                )
            ]
        )
    }
}


export default {
    extends: Select,
    props: {
        itemHeight: {
            type: [String, Number],
            default: 40
        },
        listHeight: {
            type: [String, Number],
            default: 300
        }
    },
    computed: {
        staticList() {
            return this.$createElement(VSelectList, this.listData)
        },
        virtualizedItems() {
            return this.computedItems
        },
        listData() {
            const scopeId = this.$vnode && this.$vnode.context.$options._scopeId;
            const attrs = scopeId ? {
                [scopeId]: true
            } : {};
            return {
                attrs: {
                    ...attrs,
                    id: this.computedOwns
                },
                props: {
                    action: this.multiple,
                    color: this.itemColor,
                    dense: this.dense,
                    hideSelected: this.hideSelected,
                    items: this.virtualizedItems,
                    itemDisabled: this.itemDisabled,
                    itemText: this.itemText,
                    itemValue: this.itemValue,
                    noDataText: this.noDataText,
                    selectedItems: this.selectedItems,

                    itemHeight: parseInt(this.itemHeight),
                    listHeight: parseInt(this.listHeight)
                },
                on: {
                    select: this.selectItem
                },
                scopedSlots: {
                    item: this.$scopedSlots.item
                }
            };
        },
    },
    methods: {
        genList() {
            return this.genListWithSlot()
        },
        genListWithSlot() {
            const slots = ['prepend-item', 'no-data', 'append-item'].filter(slotName => this.$slots[slotName]).map(slotName => this.$createElement('template', {
                slot: slotName
            }, this.$slots[slotName]))

            return this.$createElement(SelectListWithVirtualScroll, {...this.listData}, slots)
        }
    }
}