<template>
    <div
            v-if="isExist"
            class="editable-field-property"
    >
        <div class="editable-field-property__switcher-row">
            <div class="editable-field-property__header">
                <span class="editable-field-property__name">
                    {{ propertyConst.name }}
                </span>
                <v-tooltip
                    v-if="propertyConst.tooltip"
                    bottom
                    attach=""
                    style="position: absolute"
                >
                    <template v-slot:activator="{ on }">
                        <AlertCircleIcon
                            size="19"
                            v-on="on"
                            class="gray_400--text ml-2 cursor-pointer"
                        />
                    </template>
                    {{ propertyConst.tooltip }}
                </v-tooltip>
            </div>
            <div class="editable-field-property__switch-wrapper">
                <component
                        v-if="propertyConst.switch_prepend"
                        :is="propertyConst.switch_prepend"
                        :field-id="fieldId"
                />
                <f-switch
                        v-if="!propertyConst.always_enabled"
                        :value="isPropertyEnabled"
                        @input="v => v ? enableProperty() : disableProperty()"
                />
            </div>
        </div>
        <div v-if="isPropertyEnabled && !isPropertyWithoutConstructor" class="editable-field-property__input-group">
            <div
                    v-if="isPropertyEnabled && !isPropertyWithoutConstructor"
                    class="editable-field-property__input-group__grid"
            >
                <div
                        v-for="([inputName, inputConst], index) in Object.entries(propertyConst.input_group.inputGroup)"
                        :key="index"
                        class="editable-field-property__input-group__grid__item"
                        :class="[
                            inputConst.half_width && 'editable-field-property__input-group__grid__item--half-width',
                            inputConst.new_line && 'editable-field-property__input-group__grid__item--new-line',
                        ]"
                >
                    <component
                            :is="inputConst.component"

                            :property-name="propertyName"
                            :property-value="propertyValue"
                            :field-properties-obj="fieldPropertiesObj"
                            :input-name="inputName"
                            :input-const="inputConst"
                            :input-value="isPropertyWithSimpleConstructor ? propertyValue : propertyValue[inputName]"

                            :key="propertyName + inputName"

                            @input="changePropertyInputValue(inputName, $event)"
                    />
                </div>
            </div>
        </div>
        <template v-if="propertyConst.caption">
            <div v-if="propertyConst.link_in_caption" class="editable-field-property__caption" @click="upgradeToProFromModal">
                {{ propertyConst.caption }}
              <span  class="editable-field-property__caption--link">  {{ propertyConst.linked_caption_part}} </span>
            </div>
            <component
                    v-else-if="typeof propertyConst.caption === 'function'"
                    :is="propertyConst.caption"
                    :field-id="fieldId"
                    :property-value="propertyValue"
            />
        </template>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'

    export default {
        name: "EditableFieldPropertyConstructor",
        components: {
            AlertCircleIcon: () => import('vue-feather-icons/icons/AlertCircleIcon'),
        },
        props: {
            propertyName: {
                type: String,
                required: true
            },
            propertyValue: [String, Number, Boolean, Array, Object],
            propertyConst: {
                type: Object,
                required: true
            },
            fieldPropertiesObj: {
                type: Object,
                required: true
            },
            fieldId: String
        },
        data: () => ({
            isExist: true,
            some: false,
        }),
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
            isPropertyEnabled() {
                return this.propertyConst.always_enabled || (this.isPropertyWithoutConstructor ? !!this.propertyValue : this.propertyValue !== null)
            },
            isPropertyWithSimpleConstructor() {
                return !!this.propertyConst?.input_group?.default
            },
            isPropertyWithoutConstructor() {
                return !this.propertyConst.input_group
            },
        },
        watch: {
            fieldPropertiesObj: {
                deep: true,
                immediate: true,
                handler() {
                    if (this.propertyConst.existence_condition) {
                        let newExistenceStatus = !!this.propertyConst.existence_condition(this.fieldPropertiesObj)
                        if (newExistenceStatus !== this.isExist && newExistenceStatus)
                            this.changePropertyValue(this.propertyConst.input_group.default.default)
                        else if (newExistenceStatus !== this.isExist)
                            this.changePropertyValue(null)
                        this.isExist = newExistenceStatus
                    }
                }
            }
        },
        methods: {
            enableProperty() {
                if (this.isPropertyWithoutConstructor)
                    this.changePropertyValue(true)
                else {
                    if (this.isPropertyWithSimpleConstructor)
                        this.changePropertyValue(this.propertyConst.input_group.default.default_enabled || this.propertyConst.input_group.default.default)
                    else
                        this.changePropertyValue(
                            Object.fromEntries(
                                Object.entries(this.propertyConst.input_group)
                                    .map(([inputName, inputConst]) => [inputName, inputConst.default_enabled || inputConst.default])
                            )
                        )
                }
            },
            disableProperty() {
                if (this.isPropertyWithoutConstructor)
                    this.changePropertyValue(false)
                else
                    this.changePropertyValue(null)
            },
            changePropertyValue(value) {
                if (this.propertyConst.validator && !this.propertyConst.validator(value, this.fieldId, () => this.$emit('input', value)))
                    return
                this.$emit('input', value)
            },
            changePropertyInputValue(inputName, value) {
                if (this.isPropertyWithSimpleConstructor)
                    this.changePropertyValue(value)
                else
                    this.changePropertyValue({...this.propertyValue, [inputName]: value})
            },
            upgradeToProFromModal() {
              this.$store.dispatch('user/plan/current_subscription/monitoring_features/monitoring_features_dialog/showDialog', true);
            }
        }
    }
</script>