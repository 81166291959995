import {SMAGableExtensions} from '@/xstore/utils/smagable'

export default ({useExtension}) => {

    {
        const state = {
            model: false,

            title: undefined,
            subtitle: undefined,
        }

        useExtension(SMAGableExtensions.SMGable, {
            state,
            varNameCustomizer: variableName => 'monitoring_features_dialog_' + variableName
        })
    }

    return {
        actions: {
            showDialog: ({commit}, {title, subtitle}) => {
                commit('SET_MONITORING_FEATURES_DIALOG_TITLE', title)
                commit('SET_MONITORING_FEATURES_DIALOG_SUBTITLE', subtitle)
                commit('SET_MONITORING_FEATURES_DIALOG_MODEL', true)
            },
            closeDialog: ({commit}) => {
                commit('SET_MONITORING_FEATURES_DIALOG_MODEL', false)

                commit('SET_MONITORING_FEATURES_DIALOG_TITLE', undefined)
                commit('SET_MONITORING_FEATURES_DIALOG_SUBTITLE', undefined)
            }
        }
    }
}