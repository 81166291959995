<template>
  <InteractiveHomePageStylesProvider :page-obj="pageObj">
    <InteractiveHomePageDisplayManager
        :image-url="imageUrl"
        :highlight-image-area="highlightImageArea"
        :page-obj="pageObj"
        v-if="pageObj.layout"
    >
      <template #title>
        <f-simple-contenteditable-input
            v-model="titleModel"
            placeholder="Add title"
            overflow-wrap
            multiline
        />
      </template>
      <template #subtitle>
        <f-simple-contenteditable-input
            v-model="subtitleModel"
            placeholder="Add subtitle"
            overflow-wrap
            multiline
        />
      </template>
      <template #btn>
        <InteractiveFormActionButtonBlock with-press-enter>
          <template #button-content>
            <f-simple-contenteditable-input
                v-model="buttonTextModel"
            />
          </template>
        </InteractiveFormActionButtonBlock>
      </template>
      <template #image-content>
        <f-hover-tooltip v-if="imageModel" top text="Remove" v-slot="{on: tooltipOn}">
          <v-btn v-on="tooltipOn"
                icon large
                class="interactive-form-home-page__cover-image-btn interactive-form-home-page__cover-image-btn--remover v-btn--primary"
                @click="clearImageArea"
                @mouseover="highlightImageArea = true"
                @mouseout="highlightImageArea = false"
          >
            <TrashIcon size="20"></TrashIcon>
          </v-btn>
        </f-hover-tooltip>
        <f-hover-tooltip top text="Change Image" v-slot="{on: tooltipOn}">
          <v-btn
              class="interactive-form-home-page__cover-image-btn interactive-form-home-page__cover-image-btn--changer v-btn--primary"
              icon large
              v-on="tooltipOn"
              @mouseover="highlightImageArea = true"
              @mouseout="highlightImageArea = false"
              @click="$store.dispatch('edit/homepage_image_preview/openImagePicker')"
          >
            <v-icon size="20px">
              $image
            </v-icon>
          </v-btn>
        </f-hover-tooltip>
      </template>
    </InteractiveHomePageDisplayManager>
  </InteractiveHomePageStylesProvider>
</template>

<script>
import {computed, ref} from '@vue/composition-api'

export default {
  name: "InteractiveHomePage",
  components: {
    InteractiveHomePageDisplayManager: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageDisplayManager'),
    InteractiveHomePageStylesProvider: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageStylesProvider'),
    InteractiveFormActionButtonBlock: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveFormActionButtonBlock'),
    TrashIcon: () => import('vue-feather-icons/icons/TrashIcon')
  },
  props: {
    pageId: String,
    pageObj: Object
  },
  setup(props, {root}) {

    const genVModel = attrName => computed({
      get: () => props.pageObj[attrName],
      set: v => root.$store.dispatch('edit/pages/changeFormPage', {
        pageID: props.pageId,
        pageObj: {...props.pageObj, [attrName]: v}
      })
    })
    const highlightImageArea = ref(false);
    const previewImageUrl = computed(() => root.$store.getters['edit/homepage_image_preview/getHomepageImagePreview']);
    const imageUrl = computed(() => previewImageUrl.value || props.pageObj.imageUrl)
    const imageModel = computed({
      get: () => imageUrl.value,
      set: v => root.$store.dispatch('edit/pages/changeFormPage', {
        pageID: props.pageId,
        pageObj: {...props.pageObj, imageUrl: v}
      })
    })

    const clearImageArea = () => {
      root.$store.dispatch('edit/homepage_image_preview/setHomepageImagePreview', null)
      root.$store.dispatch('edit/pages/changeFormPage', {pageID: props.pageId, pageObj: {...props.pageObj, layout: 'ContentLeftImageRight'}})
      imageModel.value = null;
      highlightImageArea.value = false;
    }

    return {
      titleModel: genVModel('title'),
      subtitleModel: genVModel('subtitle'),
      buttonTextModel: genVModel('buttonText'),

      previewImageUrl,
      imageUrl,
      imageModel,
      highlightImageArea,
      clearImageArea
    }
  },
}
</script>
