import SharedView from "@/components/Fields/FieldsViews/OpinionScale/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: "Preview__OpinionScale",
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    scalePoles: ctx.props.fieldObj.properties.scale_poles,
                    labels: ctx.props.fieldObj.properties.labels,
                }
            }
        )
    }
}