import { SelectionFieldTypeConst } from '@/components/Fields/GenericFieldViews/GenericSelectionFieldView'

class MultipleSelectionFieldTypeConst extends SelectionFieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'MULTIPLE_SELECTION',
            READABLE_NAME: 'Checkboxes',
            ICON_NAME: 'multiple_selection',
            FOLDER_NAME: 'MultipleSelection',
            CONDITIONAL_LOGIC_MULTIPLE: () => true
        })
    }
}

export default new MultipleSelectionFieldTypeConst()