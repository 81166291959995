var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "style-preferences-theme-editor-color-option"
  }, [_c('div', {
    staticClass: "style-preferences-theme-editor-color-option__layout"
  }, [_c('div', {
    staticClass: "style-preferences-theme-editor-color-option__name"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "style-preferences-theme-editor-color-option__color-preview-wrapper"
  }, [_c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "content-class": "white",
      "offset-y": "",
      "nudge-bottom": "10",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "style-preferences-theme-editor-color-option__color-preview",
          style: _vm.colorPreviewStyle
        }, on))];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-color-picker', {
          attrs: {
            "hide-mode-switch": "",
            "mode": "hexa"
          },
          model: {
            value: _vm.colorModel,
            callback: function ($$v) {
              _vm.colorModel = $$v;
            },
            expression: "colorModel"
          }
        })];
      },
      proxy: true
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }