var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "form-integrarion-title"
  }, [_vm._v("INTEGRATE WITH")]), _vm._l(_vm.appsList, function (item) {
    return _c('IntegrationApp', {
      key: item.title,
      attrs: {
        "icon": item.icon,
        "title": item.title,
        "subtitle": item.subtitle,
        "disabled": item.disabled,
        "connected": item.connected
      },
      on: {
        "navigate": _vm.openConnectSettings
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }