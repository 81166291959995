import GenericStringSingleLineFieldViewInput
    from "@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";
import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon'

const makeSlot = fn => {
  fn.proxy = true // workaround to copy into $slots
  return fn
}

export default {
    name: 'Preview__Dropdown',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            GenericStringSingleLineFieldViewInput,
            {
                props: {
                    placeholder: ctx.props.fieldObj.placeholder,
                    readonly: true,
                    isFormStaticProp: ctx.props.isFormStatic
                },
                scopedSlots: {
                    append: makeSlot(() => h(ChevronDownIcon)),
                },
            }
        )
    }
}