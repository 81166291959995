import FSP from './fsp_constants'
import { S3_BASE_URL } from './storage_constants'

const FORM_PAGES_TYPES = {
  MAIN_PAGE: 'MAIN_PAGE',
  HOME_PAGE: 'HOME_PAGE',
  FINISH_PAGE: 'FINISH_PAGE',
}

const FORM_PAGES_ICONS = {
  HOME_PAGE: '👋',
  FINISH_PAGE: '🎉',
}

const FORM_TEMPLATE = (id, formType) => {
  return {
    id,
    FSPThemeID: 'default_theme',
    form_type: formType,
    fields: {},
    settings: {
      general_settings: {
        formsly_branding: true,
        redirect_upon_completion: false,
      },
      notifications: {
        email_notifications: {
          enabled: false,
          emails: [],
          email_subject: 'Formsly: New response for {{Form_name}}',
        },
      },
      publishing: {
        close_date: false,
        responses_number_limit: false,
      },
      access: {
        open_to_responses: true,
        access_password: false,
      },
    },
  }
}

const INTERACTIVE_HOME_PAGE_LAYOUTS = [
  {
    key: 'ContentRightSmallImageLeft',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentRightSmallImageLeft'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentRightSmallImageLeft'
      ),
  },
  {
    key: 'ContentLeftSmallImageRight',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentLeftSmallImageRight'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentLeftSmallImageRight'
      ),
  },
  {
    key: 'ContentCenterSmallImageCenter',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentCenterSmallImageCenter'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentCenterSmallImageCenter'
      ),
  },
  {
    key: 'ContentLeftImageRight',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentLeftImageRight'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentLeftImageRight'
      ),
  },
  {
    key: 'ContentRightImageLeft',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentRightImageLeft'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentRightImageLeft'
      ),
  },
  {
    key: 'ContentCenterImageBg',
    component: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/ContentCenterImageBg'
      ),
    icon: () =>
      import(
        '@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageLayouts/Icons/ContentCenterImageBg'
      ),
  },
]
const INTERACTIVE_HOME_PAGE_LAYOUTS_BY_KEY = Object.fromEntries(
  INTERACTIVE_HOME_PAGE_LAYOUTS.map(l => [l.key, l])
)

const FORM_TYPES = {
  STATIC_FORM: {
    FORM_TYPE: 'static',
    NAME: 'Classic',
    DESCRIPTION: 'Multiple questions on a single static page',
    IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/StaticFormImage'),
    TEMPLATE_IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/StaticFormTemplateImage'),
    AI_IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/StaticFormAiImage'),
    BLANK_IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/StaticFormBlankImage'),

    LAYOUT_STYLES: {
      FULL_SCREEN: 'full_screen',
      COMPACT: 'compact'
    },

    LAYOUT_HEADER_STYLES: {
      VISIBLE: 'visible_header',
      INVISIBLE: 'invisible_header'
    },
    LAYOUT_LOGO_STYLES: {
      VISIBLE: 'visible_logo',
      INVISIBLE: 'invisible_logo',
    },


    MAIN_PAGE_TEMPLATE: () => ({
      name: 'New Page',
      type: FORM_PAGES_TYPES.MAIN_PAGE,
      rows: [],
      submit_button: {
        properties: {
          button_text: 'Submit',
          on_click_action: 'submit_form',
        },
      },
    }),

    TEMPLATE(id) {
      const toReturn = {
        ...FORM_TEMPLATE(id, 'static'),
        all_pages: {
          finish_page: {
            name: 'Thank you page',
            type: FORM_PAGES_TYPES.FINISH_PAGE,
            title: 'Thank you for completing the form',
            description: '',
          },
          main_page: {
            name: 'Main page',
            type: FORM_PAGES_TYPES.MAIN_PAGE,
            rows: [],
            submit_button: {
              properties: {
                button_text: 'Submit',
                on_click_action: 'submit_form',
              },
            },
          },
        },
        main_pages: [
          {
            page_id: 'main_page',
          },
        ],
        finish_page: {
          page_id: 'finish_page',
        },
        cover_image: S3_BASE_URL + 'public/form_covers/BgTemp14-min.jpg',
        cover_text: {
          translate: 'translate(0px ,0px)',
          content: '',
          width: '350px',
          height: '59px',
        },
        layout_style: this.LAYOUT_STYLES.COMPACT,
        layout_header_style: this.LAYOUT_HEADER_STYLES.VISIBLE,
        layout_logo_style: this.LAYOUT_LOGO_STYLES.VISIBLE,
        logo_url: null,
      }

      return toReturn
    },
  },
  INTERACTIVE_FORM: {
    FORM_TYPE: 'interactive',
    NAME: 'Interactive',
    DESCRIPTION: 'One question per page',
    IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/InteractiveFormImage'),
    TEMPLATE_IMG_PATH: () =>
      import(
        '@/components/Elements/FormTypesImages/InteractiveFormTemplateImage'
      ),
    AI_IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/InteractiveFormAiImage'),
    BLANK_IMG_PATH: () =>
      import('@/components/Elements/FormTypesImages/InteractiveFormBlankImage'),

    MAIN_PAGE_TEMPLATE: () => ({
      type: FORM_PAGES_TYPES.MAIN_PAGE,
      field_id: undefined,
    }),

    INTERACTIVE_HOME_PAGE_LAYOUTS,
    INTERACTIVE_HOME_PAGE_LAYOUTS_BY_KEY,

    TEMPLATE: id => {
      const toReturn = {
        ...FORM_TEMPLATE(id, 'interactive'),
        all_pages: {
          home_page: {
            name: 'Welcome page',
            type: FORM_PAGES_TYPES.HOME_PAGE,
            layout:
              INTERACTIVE_HOME_PAGE_LAYOUTS_BY_KEY['ContentLeftImageRight'].key,
            title: 'Title',
            subtitle: 'Subtitle',
            buttonText: 'Start',
            imageUrl:
              S3_BASE_URL + `public/form_covers/default_form_image.jpeg`,
            styles: {
              TITLE_COLOR: '#000000',
              DESCRIPTION_COLOR: '#000000',
              BUTTON_BACKGROUND_COLOR: '#42ABE0',
              BUTTON_TEXT_COLOR: '#FFFFFF',
            },
          },
          finish_page: {
            name: 'Thank you page',
            type: FORM_PAGES_TYPES.FINISH_PAGE,
            title: 'Thank you for completing the form',
            description: '',
          },
        },
        home_page: {
          page_id: 'home_page',
        },
        main_pages: [],
        finish_page: {
          page_id: 'finish_page',
        },
      }

      toReturn.settings.general_settings = {
        ...toReturn.settings.general_settings,
        navigation_arrows: true,
        auto_scroll: true,
        progress_bar: false,
      }

      return toReturn
    },
  },
}

const FORM_FROM_TYPE = Object.fromEntries(
  Object.values(FORM_TYPES).map(value => [value.FORM_TYPE, value])
)

export default {
  FSP,

  FORM_TYPES,
  FORM_FROM_TYPE,
  FORM_PAGES_TYPES,
  FORM_PAGES_ICONS,
}
