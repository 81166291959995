import {GenericTextFieldFSPDefinition} from '@/components/Fields/GenericFieldViews/GenericTextField'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    GenericStringConditionalLogic,
} from '@/components/Fields/FieldConstClasses/conditional_logic'
import {GenericTextAnalytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import {maxLength, minLength} from '@vuelidate/validators'
import {GenericSubmittableDropdownFSP} from '@/components/Fields/GenericFieldViews/GenericSubmittableDropdown'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants'
import {FieldTypeConst} from "@/components/Fields/FieldConstClasses/field_type_const";

class PhoneFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'PHONE',
            READABLE_NAME: 'Phone',
            ICON_NAME: 'phone',
            FOLDER_NAME: 'Phone',
            WITH_PRESS_ENTER: true,

            SUBMISSION_TEMPLATE: () => ({
                value: {
                    country: localStorage.getItem('countryCode') || 'US',
                    phone: ''
                }
            }),

            IS_SUBMISSION_EMPTY: submissionObj => !Object.values(submissionObj.value).every(item => !!item),

            CONDITIONAL_LOGIC: new GenericStringConditionalLogic({
                PARAMS: {
                    REGEX: /^\+\d{0,15}$/,
                    CASE_SENSITIVE: false,
                    ERROR_MESSAGE: 'The number must starts with "+" and be maximum 15 digits long',
                }
            }),

            ANALYTICS: new GenericTextAnalytics(),

            FSP: [
                GenericSubmittableDropdownFSP,
                GenericTextFieldFSPDefinition,
                new FieldFSPDefinition({
                    FSP_FIELD_TYPE: 'Phone',
                    FIELD_VIEW_CLASS: 'phone-field-view',
                    VARIABLES: [
                        {
                            selector: ['country-phone-code'],
                            properties: {
                                color: {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.7})
                                    }
                                }
                            }
                        },
                        {
                            selector: ['.v-input__prepend-inner'],
                            external: true,
                            properties: {
                                flex: {
                                    default: {
                                        fn: '0 0 0',
                                    }
                                }
                            },
                        },
                        {
                            selector: ['.v-input__prepend-inner .v-input__slot'],
                            external: true,
                            properties: {
                                'padding-right': {
                                    default: {
                                        fn: '0'
                                    }
                                },
                            },
                        },
                        {
                            selector: ['.v-input > .v-input__control > .v-input__slot .v-text-field__slot > input::placeholder'],
                            external: true,
                            properties: {
                                color: {
                                    'default--submittable--interactive': {
                                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                                            asColors: {
                                                true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                                                    opacity: 0.5,
                                                    asList: true
                                                }),
                                                false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                                                    opacity: 0.7,
                                                    asList: true
                                                })
                                            }
                                        }),
                                        important: true,
                                    },
                                    'default--submittable--static': {
                                        fn: '#737E81',
                                        important: true,
                                    },
                                }
                            }
                        },
                        {
                            selector: ['.v-input.v-input--is-dirty > .v-input__control > .v-input__slot .v-text-field__slot > input::placeholder'],
                            external: true,
                            properties: {
                                color: {
                                    default: {
                                        fn: 'transparent',
                                        important: true,
                                    }
                                }
                            }
                        }
                    ]
                })
            ],

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: {
                        country: {...FieldSubmitValidation.REQUIRED(fieldObj)},
                        phone: {
                            ...FieldSubmitValidation.REQUIRED(fieldObj),
                            maxLength: maxLength(11),
                            minLength: minLength(5)
                        }
                    }
                })
            }),
            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: '(201) 555-0123',
                    country: localStorage.getItem('countryCode') || 'US',
                }
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'phone_validation',
                    name: 'Phone Validation'
                })
            ])
        })
    }
}

export default new PhoneFieldTypeConst()