<script>
import FormSubmitActions from '@/utils/actions/form_submit_actions'
  export default {
    name: 'FormActionsComponent',
    components: {
      RenameFormModal: () => import('./Elements/RenameFormModal'),
      DeleteFormModal: () => import('./Elements/DeleteFormModal'),
    },
    props: {
      formData: {
        type: Object,
        required: true,
      },
      formJson: {
        type: Object,
        required: true,
      },
    },
    computed: {
      isFormOpenForSubmissions() {
        return (
          this.formData.publishedVersionID &&
          this.formJson.settings.access.open_to_responses
        )
      },
    },
    methods:{

      duplicateForm() {
        this.sendDataToParent(true);
        FormSubmitActions.duplicateForm(this.formData.id)
          .then(response => {
              this.$store.dispatch('forms_list/fetchFormsList');
              this.sendDataToParent(false);
          })
          .catch(error => {
            this.$store.dispatch('forms_list/fetchFormsList');
            this.sendDataToParent(false);
          });
      },

      sendDataToParent(loaderValue) {
        this.$emit('update-loader', loaderValue)
      }

    },
  }
</script>

<template>
  <v-menu
    offset-y
    nudge-left="110"
    nudge-bottom="10"
    min-width="240"
    transition="slide-y-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="form-preview-card__actions-btn"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <svg
          width="12"
          height="2"
          viewBox="0 0 12 2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="1"
            cy="1"
            r="1"
          />
          <circle
            cx="6"
            cy="1"
            r="1"
          />
          <circle
            cx="11"
            cy="1"
            r="1"
          />
        </svg>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        :to="{
          name: constants.PAGE_NAME_FORM_EDITOR,
          params: { formId: formData.id },
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{
          name: constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
          params: { formId: formData.id },
        }"
      >
        <v-list-item-content>
          <v-list-item-title>Responses</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <RenameFormModal :form-data="formData" />
      <v-list-item
        @click="duplicateForm"
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-title>Duplicate</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :disabled="!isFormOpenForSubmissions"
        @click="isFormOpenForSubmissions && $emit('share')"
      >
        <v-list-item-content>
          <v-list-item-title>Share</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :disabled="!isFormOpenForSubmissions"
        :to="{
          name: constants.PAGE_NAME_FORM_SUBMIT,
          params: { formId: formData.id },
        }"
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-title>View</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <DeleteFormModal :form-data="formData" />
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped>
  .form-preview-card__actions-btn {
    height: 30px;
    width: 30px;
    border: 1px solid var(--v-gray_200-base);

    svg {
      color: var(--v-gray_400-base);
    }

    &:hover {
      background-color: var(--v-blue_100-base);
      border-color: #d8e0ff;

      svg {
        color: var(--v-blue_400-base);
      }
    }
  }
</style>
