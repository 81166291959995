<template>
    <v-dialog
            content-class="dropdown-field-view-editable-dialog"
            :value="isDialogOpened"
            max-width="714"
            @click:outside="$emit('close')"
    >
        <template>
            <v-card>
                <v-card-title>
                    List Choices
                    <v-spacer/>
                    <XIcon size="24" @click="$emit('close')"/>
                </v-card-title>
                <v-card-subtitle>
                    Add or paste in your choices each on a separate line.
                </v-card-subtitle>
                <v-card-text>
                    <div class="dropdown-field-view-editable-dialog__textarea">
                        <div
                                v-for="(option, index) in optionsList"
                                class="dropdown-field-view-editable-dialog__option"
                                :key="index"
                        >
                            <span class="dropdown-field-view-editable-dialog__option-dot"></span>
                            <span v-if="selectedReadyListKey">{{ option.name }}</span>
                            <f-template-data-transfer v-else :focused="false" v-slot="{ dataStash }">
                                <v-hover v-slot="{ hover }">
                                    <div class="dropdown-field-view-editable-dialog__option-value">
                                        <f-simple-contenteditable-input
                                                v-model="options[index]"
                                                :placeholder="index === 0 && options.length === 1 ? 'Start typing or choose one of the ready lists below' : ''"
                                                ref="optionNameInput"
                                                prevent-highlighting
                                                overflow-wrap
                                                :mask="/^.{0,60}$/g"
                                                @update:focused="dataStash.focused = $event"
                                                @keydown.enter="insertOption(index + 1)"
                                                @keydown.8="removeOption($event, index)"
                                        />
                                        <v-btn
                                                v-if="(hover || dataStash.focused) && options.length > 1"
                                                icon small
                                                @click="removeOption($event, index)"
                                        >
                                            <XIcon size="18"/>
                                        </v-btn>
                                    </div>
                                </v-hover>
                            </f-template-data-transfer>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <f-select
                            class="dropdown-field-view-editable-dialog__set-select"
                            attach=".dropdown-field-view-editable-dialog__set-select"
                            :items="readyLists"
                            :item-text="item => item.READABLE_NAME"
                            :item-value="item => item.KEY"
                            solo
                            flat
                            hide-details
                            dense
                            placeholder="Ready Lists"
                            :menu-props="{
                                nudgeBottom: 40,
                                maxWidth: 220
                            }"
                            :value="selectedReadyListKey"
                            @change="onChange"
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item :class="item.EMOJI === '🌎' && 'list-item-planet'" v-on="on" v-bind="attrs">
                                <v-list-item-icon class="list-icon">
                                    {{item.EMOJI}}
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.READABLE_NAME }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{item}">
                            <span class="mr-2 list-icon" :key="item.READABLE_NAME">{{item.EMOJI}}</span>
                            <span class="selection-text">{{ item.READABLE_NAME }}</span>
                        </template>
                        <template #append-outer>
                            <XIcon @click="removeSelectedReadyList"
                                   v-if="selectedReadyListKey"
                                   size="20"
                            />
                        </template>
                    </f-select>

                    <v-spacer/>

                    <v-btn
                            class="mr-4"
                            color="gray_100"
                            rounded
                            width="110"
                            @click="$emit('close')"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="blue_400"
                            rounded
                            width="138"
                            @click="saveOptionsList"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>

    import Vue from 'vue';

    export default {
        name: 'EditableDialog',
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            isDialogOpened: {
                type: Boolean,
                required: true,
            },
            isConditioned: {
                type: Boolean,
                required: true,
            },
            conditionalStatement: {
                type: Array,
                required: true,
            },
            fieldObj: {
                type: Object,
                required: true,
            },
            fieldConst: {
                type: Object,
                required: true
            },
            optionsExternal: {
                type: [String, Array],
                required: true,
            }
        },
        data: () => ({
            options: [''],
            selectedReadyListKey: null,
            clonedConditionStatement: null,
        }),
        computed: {
            optionsList() {
                return this.selectedReadyListKey ? this.fieldConst.UTIL.READY_LISTS[this.selectedReadyListKey].LIST : this.options;
            },
            readyLists() {
                return Object.entries(this.fieldConst.UTIL.READY_LISTS)
                    .map(([key, listDefinition]) => ({KEY: key, ...listDefinition}))
            }
        },
        mounted() {
            this.clonedConditionStatement = this.conditionalStatement.slice()
            typeof this.optionsExternal === 'string' ?
                this.selectedReadyListKey = this.optionsExternal
                : this.optionsExternal.length ? this.options = this.optionsExternal.slice()
                : ''
        },
        methods: {
            onChange(v) {
                if (this.isConditioned) {
                    if (this.selectedReadyListKey !== v) {
                        this.$parent.extension.removeOption(() => {
                            this.selectedReadyListKey = v;
                            this.options.splice(0, this.options.length, '')
                            this.$delete(this.fieldObj, 'condition');
                        })
                    }
                } else {
                    this.selectedReadyListKey = v;
                    this.options.splice(0, this.options.length, '')
                }
            },
            insertOption(insertIndex) {
                if (this.isConditioned) {
                    this.clonedConditionStatement = this.clonedConditionStatement
                        .map(s => s >= insertIndex ? (s + 1) : s)
                }
                this.options.splice(insertIndex, 0, '')
                Vue.nextTick(() => {
                    this.$refs.optionNameInput[insertIndex].focus()
                })
            },
            removeOption(e, index) {
                if (e.type === 'keydown' && !this.options[index] && this.options.length > 1 || e.type === 'click') {
                    if (this.isConditioned) {
                        if (!this.clonedConditionStatement.filter(s => s !== index).length) {
                            this.$parent.extension.removeOption(() => {
                                this.handleRemovingOptionWithCondition(index);
                                this.$delete(this.fieldObj, 'condition');
                            })
                        } else if (this.clonedConditionStatement.includes(index)) {
                            this.$parent.extension.changeCondition(() => {
                                this.handleRemovingOptionWithCondition(index);
                            })
                        } else {
                            this.handleRemovingOptionWithCondition(index);
                        }
                        return
                    }
                    this.options.splice(index, 1)
                    Vue.nextTick(() => {
                        this.$refs.optionNameInput[!index ? index : index - 1].focus()
                    })

                }
            },
            removeSelectedReadyList() {
                if (this.isConditioned) {
                    this.$parent.extension.removeOption(() => {
                        this.selectedReadyListKey = null;
                        this.$delete(this.fieldObj, 'condition');
                    })
                } else {
                    this.selectedReadyListKey = null;
                }
            },
            saveOptionsList() {
                if (!this.selectedReadyListKey && this.isConditioned) {
                    this.fieldObj.condition.expression.statement = this.clonedConditionStatement;
                }
                this.$emit('save', this.selectedReadyListKey ? this.selectedReadyListKey : this.options.filter(item => item))
            },
            handleRemovingOptionWithCondition(index) {
                this.clonedConditionStatement = this.clonedConditionStatement
                    .filter(s => s !== index)
                if (this.clonedConditionStatement.length) {
                    this.clonedConditionStatement = this.clonedConditionStatement
                        .map(s => s > index ? (s - 1) : s)
                }
                this.options.splice(index, 1)
                Vue.nextTick(() => {
                    this.$refs.optionNameInput[!index ? index : index - 1].focus()
                })
            },

        }
    }
</script>
<style scoped lang="scss">
    .list-icon {
        font-size: 18px;
        width: 23px;
    }

    .list-item-planet {
        padding: 0 16px 0 14px !important;
    }
</style>