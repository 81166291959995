var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "generic-field__view"
  }, [_c(_vm.fieldTypeConst.SUBMITTABLE_VIEW, {
    tag: "component",
    attrs: {
      "field-id": _vm.fieldId,
      "source-obj": _vm.sourceObj,
      "submission-obj": _vm.submissionObj,
      "field-width": _vm.fieldWidth
    }
  }), _c('div', {
    staticClass: "generic-field__error-messages-wrapper"
  }, [_c('v-slide-y-transition', [_vm.currentErrorMessage ? _c('div', {
    staticClass: "generic-field-error-message generic-field__escape-form-font"
  }, [_c('span', {
    staticClass: "generic-field-error-message__text"
  }, [_vm._v(" " + _vm._s(_vm.currentErrorMessage) + " ")])]) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }