var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select', {
    class: ['rounded-lg', _vm.selfId],
    attrs: {
      "value": _vm.internalValue,
      "attach": '.' + _vm.selfId,
      "items": _vm.inputConst.items,
      "item-value": function (item) {
        return item.key;
      },
      "item-text": function (item) {
        return item.readable_name;
      },
      "label": _vm.label,
      "menu-props": {
        nudgeBottom: 42
      },
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "input": _vm.changeSelectValue
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }