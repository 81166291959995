<template>
    <div class="star-rating-field-view star-rating-field-view--short-single-answer-analytics">
        <div
                v-if="submissionObj.value === null"
                class="star-rating-field-view__layout"
        >
            <span class="star-rating-field-view__item">-</span>
        </div>
        <div
                v-else-if="isEmojiScale"
                class="star-rating-field-view__layout star-rating-field-view__layout--emoji-scale"
        >
            <span class="star-rating-field-view__item">
                {{fieldConstProperties.emoji_scale.input_group.default.sets[sourceObj.properties.emoji_scale][submissionObj.value]}}
            </span>
        </div>
        <div
                v-else-if="isScaleIcon"
                class="star-rating-field-view__layout star-rating-field-view__layout--scale-icon"
        >
            <span class="star-rating-field-view__item">
                <span class="mr-2">{{submissionObj.value + 1}}/5</span>
                <LottieIconPlayer
                        :json="fieldConstProperties.scale_icon.input_group.default.emojis[sourceObj.properties.scale_icon]"
                        active
                />
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__StarRating",
        components: {
            LottieIconPlayer: () => import('../Icons/LottieIconPlayer')
        },
        props: {
            sourceObj: Object,
            submissionObj: Object
        },
        computed: {
            isEmojiScale() {
                return this.sourceObj.properties.emoji_scale !== null
            },
            isScaleIcon() {
                return this.sourceObj.properties.scale_icon !== null
            },
            fieldConstProperties() {
                return this.constants.FIELD_FROM_TYPE[this.sourceObj.field_type].BASE_PROPERTIES
            }
        }
    }
</script>