var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "43",
      "height": "32",
      "viewBox": "0 0 43 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "43",
      "height": "32",
      "rx": "8",
      "fill": _vm.backgroundFill
    }
  }), _c('rect', {
    attrs: {
      "x": "9",
      "y": "10",
      "width": "26",
      "height": "4",
      "rx": "2",
      "fill": _vm.blocksFill
    }
  }), _c('rect', {
    attrs: {
      "x": "9",
      "y": "17",
      "width": "15",
      "height": "4",
      "rx": "2",
      "fill": _vm.blocksFill
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }