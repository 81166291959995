var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BrowserTemplate', [_c('div', {
    staticClass: "mt-5 my-3 gray_400--text subtitle-2 font-weight-regular"
  }, [_vm._v(" Solid Colors ")]), _c('div', {
    staticClass: "color-palette-list"
  }, [_vm._l(_vm.palette, function (color) {
    return _c('div', {
      key: color,
      staticClass: "color-tile",
      class: color === _vm.activeImage && 'color-tile--active',
      style: 'background: url(' + color + ')',
      on: {
        "click": function ($event) {
          return _vm.setActiveColor(color);
        }
      }
    });
  }), _c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "content-class": "white",
      "offset-y": "",
      "nudge-bottom": "10",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "color-tile color-tile--picker",
          class: _vm.activeImage === _vm.customColorBase64 && 'color-tile--active',
          style: {
            backgroundColor: _vm.customColor
          }
        }, on), [_c('PlusIcon', {
          staticClass: "plus-icon mix-blend-mode-difference white--text"
        })], 1)];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-color-picker', {
          attrs: {
            "hide-mode-switch": "",
            "mode": "hexa"
          },
          model: {
            value: _vm.customColor,
            callback: function ($$v) {
              _vm.customColor = $$v;
            },
            expression: "customColor"
          }
        }), _c('div', {
          staticClass: "d-flex pa-4 pt-0"
        }, [_c('v-btn', {
          attrs: {
            "color": "blue_400 mr-2",
            "rounded": ""
          },
          on: {
            "click": _vm.setCustomActiveColor
          }
        }, [_vm._v("Add")]), _c('v-btn', {
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": _vm.unsetCustomColor
          }
        }, [_vm._v("Cancel")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.colorPickerModel,
      callback: function ($$v) {
        _vm.colorPickerModel = $$v;
      },
      expression: "colorPickerModel"
    }
  })], 2), _c('div', {
    staticClass: "mt-5 my-3 gray_400--text subtitle-2 font-weight-regular"
  }, [_vm._v(" Backgrounds ")]), _c('v-row', {
    staticClass: "backgrounds-list"
  }, _vm._l(_vm.backgrounds, function (background) {
    return _c('v-col', {
      key: background
    }, [_c('v-img', {
      staticClass: "rounded-lg result-image",
      class: _vm.activeImage === background && 'active',
      attrs: {
        "width": "100px",
        "height": "80px",
        "src": background
      },
      on: {
        "click": function ($event) {
          return _vm.setActiveImage(background);
        }
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-row', {
            staticClass: "fill-height ma-0",
            attrs: {
              "align": "center",
              "justify": "center"
            }
          }, [_c('v-skeleton-loader', {
            attrs: {
              "width": "100",
              "type": "image"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }