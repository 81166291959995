var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormSettingsOptionTemplate', {
    staticClass: "mb-4",
    attrs: {
      "value": _vm.settingsObj.notifications.email_notifications.enabled
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$currentSubscripti;
        return [_c('FormParametersHeader', {
          attrs: {
            "allowed-only-with-payment": !((_vm$currentSubscripti = _vm.currentSubscriptionFeatures) !== null && _vm$currentSubscripti !== void 0 && _vm$currentSubscripti.email_notifications)
          },
          model: {
            value: _vm.settingsObj.notifications.email_notifications.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.notifications.email_notifications, "enabled", $$v);
            },
            expression: "settingsObj.notifications.email_notifications.enabled"
          }
        }, [_vm._v(" Get notifications by email ")])];
      },
      proxy: true
    }, {
      key: "hint",
      fn: function () {
        return [_c('FormParametersHint', [_vm._v("Receive an email when someone submits a form")])];
      },
      proxy: true
    }])
  }), _c('FormSettingsOptionWithInput', {
    attrs: {
      "always-enabled": ""
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-combobox', {
          staticClass: "rounded-lg pa-0",
          attrs: {
            "disabled": !_vm.settingsObj.notifications.email_notifications.enabled,
            "error-messages": _vm.emailsErrors,
            "multiple": "",
            "deletable-chips": "",
            "placeholder": "Add email",
            "persistent-placeholder": "",
            "append-icon": "",
            "outlined": "",
            "dense": "",
            "hide-details": "auto"
          },
          on: {
            "input": _vm.handleEmailsList,
            "update:search-input": _vm.updateEmailsInputModel
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function (_ref) {
              var item = _ref.item,
                parent = _ref.parent;
              return [_c('v-chip', {
                key: JSON.stringify(item),
                staticClass: "my-1 bluish_gray_100",
                attrs: {
                  "small": "",
                  "label": ""
                }
              }, [_vm._v(" " + _vm._s(item) + " "), _c('XIcon', {
                staticClass: "cursor-pointer ml-3",
                attrs: {
                  "size": "16"
                },
                on: {
                  "click": function ($event) {
                    return parent.selectItem(item);
                  }
                }
              })], 1)];
            }
          }]),
          model: {
            value: _vm.emailsList,
            callback: function ($$v) {
              _vm.emailsList = $$v;
            },
            expression: "emailsList"
          }
        })];
      },
      proxy: true
    }])
  }), _c('FormSettingsOptionWithInput', {
    attrs: {
      "always-enabled": ""
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-text-field', {
          staticClass: "m-0",
          attrs: {
            "disabled": !_vm.settingsObj.notifications.email_notifications.enabled,
            "outlined": ""
          },
          model: {
            value: _vm.settingsObj.notifications.email_notifications.email_subject,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.notifications.email_notifications, "email_subject", $$v);
            },
            expression: "settingsObj.notifications.email_notifications.email_subject"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" Your email subject ")]), _c('FormSettingsOptionTemplate', {
    staticClass: "mb-8",
    attrs: {
      "value": _vm.settingsObj.notifications.email_notifications.include_responses_content
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        var _vm$currentSubscripti2;
        return [_c('FormParametersHeader', {
          attrs: {
            "allowed-only-with-payment": !((_vm$currentSubscripti2 = _vm.currentSubscriptionFeatures) !== null && _vm$currentSubscripti2 !== void 0 && _vm$currentSubscripti2.email_notifications),
            "disabled": !_vm.settingsObj.notifications.email_notifications.enabled
          },
          model: {
            value: _vm.settingsObj.notifications.email_notifications.include_responses_content,
            callback: function ($$v) {
              _vm.$set(_vm.settingsObj.notifications.email_notifications, "include_responses_content", $$v);
            },
            expression: "settingsObj.notifications.email_notifications.include_responses_content"
          }
        }, [_vm._v(" Include responses content ")])];
      },
      proxy: true
    }, {
      key: "hint",
      fn: function () {
        return [_c('FormParametersHint', [_vm._v("Include response content in notification email")])];
      },
      proxy: true
    }])
  }), _c('FormSettingsOptionWithInput', {
    attrs: {
      "always-enabled": ""
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('div', {
          staticClass: "gray_100 bluish_gray_500--text pa-5 rounded-lg"
        }, [_c('p', {
          staticClass: "mb-3"
        }, [_vm._v("Your form has a new response:")]), _c('p', {
          staticClass: "mb-3"
        }, [_vm._v("Log in to view or download your responses at " + _vm._s(_vm.responsesPageURL))]), _c('p', [_vm._v("🤍 Formsly Team")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" Email preview ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }