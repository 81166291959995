<template>
    <v-container fluid class="h-full d-flex align-center justify-center">
        <v-slide-y-transition mode="out-in">
            <LoginLayoutWithImage v-if="signUpStage === signUpStages.SIGNUP">
                <template #title>
                    Create a FREE account
                </template>
                <template #form>
                    <v-form
                            ref="signUpForm"
                            v-model="valid"
                            lazy-validation
                    >
                        <ErrorAlertComponent/>
                        <div class="mb-2">Name <span class="error--text">*</span></div>
                        <v-text-field
                                placeholder="Enter your name"
                                class="rounded-lg"
                                v-model="name"
                                @keydown="$refs.signUpForm.resetValidation()"
                                :rules="nameRules"
                                outlined
                                :disabled="isLoading"
                        />
                        <div class="mb-2">Email <span class="error--text">*</span></div>
                        <v-text-field
                                placeholder="Enter your email"
                                class="rounded-lg"
                                v-model="email"
                                @keydown="$refs.signUpForm.resetValidation()"
                                :rules="emailRules"
                                outlined
                                :disabled="isLoading"
                        />
                        <div class="mb-2">Password <span class="error--text">*</span></div>
                        <v-text-field
                                placeholder="Create a password"
                                class="rounded-lg"
                                v-model="password"
                                @keydown="$refs.signUpForm.resetValidation()"
                                :rules="passwordRules"
                                :messages="!password ? `Must be at least ${constants.MIN_PASS_LENGTH} characters` : ''"
                                type="password"
                                outlined
                                :disabled="isLoading"
                        />
                        <div class="mb-2">Confirm Password <span class="error--text">*</span></div>
                        <v-text-field
                                placeholder="Confirm a password"
                                class="rounded-lg"
                                v-model="confirmPassword"
                                @keydown="$refs.signUpForm.resetValidation()"
                                :rules="[password === confirmPassword || 'Passwords must match']"
                                type="password"
                                outlined
                                :disabled="isLoading"
                        />
                        <v-btn class="mb-4"
                               color="blue_400"
                               depressed
                               large
                               @click="signUp"
                               rounded
                               block
                        >Create account
                        </v-btn>
                        <v-btn
                                @click="signUpGoogle"
                                class="mb-4 dark_gray--text"
                                outlined
                                depressed
                                large
                                rounded
                                block
                        >
                            <img class="mr-4" draggable="false" src="/images/GoogleLogo.svg"/>
                            <span>Sign up with Google</span>
                        </v-btn>
                    </v-form>
                </template>
                <template #jump-to>
                    Already have an account?
                    <span
                            class="ml-2 blue_400--text font-weight-bold cursor-pointer"
                            @click="goToLogin"
                    >Log in</span>
                </template>
                <template #additional-bottom>
                    <v-row justify="center">
                        <v-col cols="3">
                            <v-divider/>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="mt-1">
                        <v-col>
                            <p class="gray_400--text caption">

                                <TermOfUse  :is-visible="dialogTermsVisible">
                                    <template #activator="{on}">
                                        By clicking ‘Create account’ or signing up, you agree to the <span v-on="on" class="blue_400--text cursor-pointer d-inline">Terms of Use</span>
                                    </template>
                                </TermOfUse>
                            </p>
                            <p class="gray_400--text caption">

                                <PrivacyPolicy  :is-visible="dialogPolicyVisible">
                                    <template #activator="{on}" style="display: inline">
                                        and <span v-on="on" class="blue_400--text cursor-pointer">Privacy Notice.</span>
                                    </template>
                                </PrivacyPolicy>
                                You also agree to receive information and offers
                                relevant to our services via email. You can opt-out of these emails in
                                your My Account page anytime.
                            </p>
                        </v-col>
                    </v-row>
                </template>
            </LoginLayoutWithImage>
            <VerificationTemplate v-if="signUpStage === signUpStages.VERIFICATION">
                <template #icon>
                    <div class="pa-6 d-flex rounded-circle blue_100">
                        <MailIcon class="blue_400--text"/>
                    </div>
                </template>
                <template #title>
                    Check your email
                </template>
                <template #description>
                    We sent a verification code to {{user.username}}
                </template>
                <template #code-input>
                    <ErrorAlertComponent/>
                    <v-otp-input
                            v-model="verificationCode"
                            hide-spin-buttons
                            length="6"
                            type="number"
                    />
                </template>
                <template #action>
                    <v-btn
                            @click="verifyEmail"
                            :disabled="verificationCode.length !== 6"
                            block
                            depressed
                            rounded
                            color="blue_400"
                    >
                        Verify email
                    </v-btn>
                </template>
                <template v-if="!codeResent" #secondary-action>
                    Didn't receive the email?
                    <span @click="resendConfirmationCode" class="blue_400--text cursor-pointer">Click to resend</span>
                </template>
            </VerificationTemplate>
            <VerificationTemplate v-if="signUpStage === signUpStages.VERIFIED">
                <template #icon>
                    <div class="pa-6 d-flex rounded-circle green_100">
                        <CheckCircleIcon class="green_500--text"/>
                    </div>
                </template>
                <template #title>
                    Email verified
                </template>
                <template #description>
                    Your password has been successfully reset.<br>
                    Click below to log in magically.
                </template>
                <template #action>
                    <v-btn
                            @click="continueLogin"
                            block
                            depressed
                            rounded
                            color="blue_400"
                    >
                        Continue
                    </v-btn>
                </template>
            </VerificationTemplate>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import ErrorAlertComponent from "@/components/Alert/ErrorAlertComponent"
    import {alert_mixin} from "@/components/Alert/alert_mixin"
    import {resend_code_mixin} from "@/components/LoginComponents/resend_code_mixin"
    import {MailIcon, CheckCircleIcon} from 'vue-feather-icons'
    import {mapGetters} from '@/xstore'

    import LoginLayoutWithImage from "@/components/LoginComponents/LoginLayoutWithImage"
    import VerificationTemplate from "@/components/LoginComponents/VerificationTemplate"
    import TermOfUse from "@/components/Elements/TermOfUse"
    import PrivacyPolicy from "@/components/Elements/PrivacyPolicy"

    export default {
        name: "SignUp",
        components: {
            TermOfUse,
            PrivacyPolicy,
            ErrorAlertComponent,
            MailIcon, CheckCircleIcon,
            LoginLayoutWithImage,
            VerificationTemplate
        },
        mixins: [alert_mixin, resend_code_mixin],
        computed: {
            ...mapGetters(['isLoading'])
        },
        data: vm => ({
            name: '',
            email: '',
            password: '',
            confirmPassword: '',

            verificationCode: '',

            signUpStage: 1,
            signUpStages: {
                SIGNUP: 1,
                VERIFICATION: 2,
                VERIFIED: 3
            },

            nameRules: [
                v => !!v || 'Name is required'
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => vm.constants.EMAIL_REGEX.test(v) || 'Email must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= vm.constants.MIN_PASS_LENGTH || `Must be at least ${vm.constants.MIN_PASS_LENGTH} characters`,
            ],


            user: undefined,

            valid: true,

            dialogTermsVisible : false,
            dialogPolicyVisible: false
        }),
        methods: {
            signUp() {
                if (this.$refs.signUpForm.validate()) {
                    this.user = {
                        username: this.email,
                        password: this.password
                    }
                    this.promiseWithAnimation(
                        this.AuthActions.signUp(this.name, this.email, this.password)
                            .then(() => {
                                this.signUpStage = this.signUpStages.VERIFICATION
                                // this.resendCountdownTimer()
                            })
                            .catch(e => this.emitErrorAlert(e.message))
                    )
                }
            },
            verifyEmail() {
                this.promiseWithAnimation(
                    this.AuthActions.confirmSignUp(this.user.username, this.verificationCode)
                        .then(() => {
                            this.signUpStage = this.signUpStages.VERIFIED
                        })
                        .catch(e => this.emitErrorAlert(e.message))
                )
            },
            continueLogin() {
                this.promiseWithAnimation(
                    this.AuthActions.signIn(this.user.username, this.user.password)
                        .catch(e => {
                            if (e.message === 'Incorrect username or password.')
                                this.goToLogin()
                            console.log(e)
                        })
                )
            },
            resendConfirmationCode() {
                this.promiseWithAnimation(
                    this.AuthActions.resendConfirmationCode(this.email)
                        .then(() => this.codeResent = true)
                        .catch(e => console.log(e))
                )
            },
            goToLogin() {
                this.$router.push({name: this.constants.PAGE_NAME_LOGIN})
            },
            signUpGoogle() {
                this.AuthActions.signInGoogle()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>