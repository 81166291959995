import { assert, forEachValue } from '../util'
import _, { isArray, isFunction } from 'lodash'
import { effectScope } from '@vue/composition-api'


export default class Module {
    constructor(moduleDefinition, runtime, path) {
        this.runtime = runtime

        this._children = Object.create(null)

        this._path = path

        assert(isFunction(moduleDefinition), `module definition must be a function (${path.join('/')})`)

        this._moduleDefinition = moduleDefinition

        this.actionContextExtensions = []

        this._moduleScope = effectScope(true)
        this._createdHook = undefined

        this._rawModule = this.generateModuleContent()

        this._initialState = this._rawModule.state
        this.state = _.cloneDeep(this._rawModule.state)
    }

    generateModuleContent() {
        return this._moduleScope.run(() => {
            const rawModule = {
                namespaced: true,
                modules: {},
                state: {},
                stateInitializers: {},
                mutations: {},
                actions: {},
                getters: {}
            }

            const extendModule = (moduleExtension, initializerKey = 'Unknown') => {
                const assignExtensionPartToModule = (target, source) => Object.assign(target, Object.fromEntries(Object.entries(source || {}).map(([key, value]) => {
                    value._devtools_initializerKey = initializerKey
                    return [key, value]
                })))

                Object.assign(rawModule.modules, moduleExtension.modules || {})
                Object.assign(rawModule.state, moduleExtension.state || {})

                Object.assign(rawModule.stateInitializers, Object.fromEntries(Object.keys(moduleExtension.state || {}).map(key => [key, initializerKey])))
                assignExtensionPartToModule(rawModule.mutations, moduleExtension.mutations)
                assignExtensionPartToModule(rawModule.actions, moduleExtension.actions)
                assignExtensionPartToModule(rawModule.getters, moduleExtension.getters)
            }

            const staticModule = this._moduleDefinition({
                useExtension: (extension, args = {}) => {
                    const baseState = args.state && Object.freeze(args.state)

                    const tryToCallSBGenerator = generator => {
                        if (isFunction(generator)) {
                            if (generator.requiresStateArg && !baseState)
                                throw new Error(`SBGenerator with key ${generator.key} requires 'state' arg`)

                            extendModule(generator({
                                ...args,
                                module: this
                            }), generator.key)
                        }
                    }

                    tryToCallSBGenerator(extension.SBGenerator)

                    if (isArray(extension.SBGenerators))
                        extension.SBGenerators.forEach(generator => tryToCallSBGenerator(generator))

                    if (extension.additionalActionContext)
                        this.actionContextExtensions.push(extension.additionalActionContext)
                }
            }) || {}

            extendModule(staticModule, 'native')

            if (staticModule.created)
                this._createdHook = staticModule.created

            return rawModule
        })
    }

    afterInstallation(store) {
        if (this._createdHook)
            this._moduleScope.run(() => {
                this._createdHook({localContext: this.context.local, parentContext: this.context.parent, store})
            })
    }

    beforeDestroy() {
        Object.values(this._children).forEach(child => child.beforeDestroy())
        this._moduleScope.stop()
    }

    get namespaced() {
        return !!this._rawModule.namespaced
    }

    addChild(key, module) {
        this._children[key] = module
    }

    removeChild(key) {
        delete this._children[key]
    }

    getChild(key) {
        return this._children[key]
    }

    hasChild(key) {
        return key in this._children
    }

    update(rawModule) {
        this._rawModule.namespaced = rawModule.namespaced
        if (rawModule.actions) {
            this._rawModule.actions = rawModule.actions
        }
        if (rawModule.mutations) {
            this._rawModule.mutations = rawModule.mutations
        }
        if (rawModule.getters) {
            this._rawModule.getters = rawModule.getters
        }
    }

    forEachChild(fn) {
        forEachValue(this._children, fn)
    }

    forEachGetter(fn) {
        if (this._rawModule.getters) {
            forEachValue(this._rawModule.getters, fn)
        }
    }

    forEachAction(fn) {
        if (this._rawModule.actions) {
            forEachValue(this._rawModule.actions, fn)
        }
    }

    forEachMutation(fn) {
        if (this._rawModule.mutations) {
            forEachValue(this._rawModule.mutations, fn)
        }
    }
}
