<template>
    <svg width="8" height="12" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8.99994L5 12.9999L9 8.99994" :stroke="asc ? selectedColor : neutralColor" stroke-width="1.33" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M9 4.99994L5 0.999938L1 4.99994" :stroke="desc ? selectedColor : neutralColor" stroke-width="1.33" stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>
</template>

<script>
    export default {
        name: "SortDirectionIcon",
        props: {
            asc: Boolean,
            desc: Boolean
        },
        data: () => ({
            selectedColor: '#252628',
            neutralColor: '#8A8F99'
        })
    }
</script>