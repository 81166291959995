<template>
  <InteractiveHomePageStylesProvider :page-obj="pageObj">
    <InteractiveHomePageDisplayManager
        :page-obj="pageObj"
        v-if="pageObj.layout"
        :image-url="pageObj.imageUrl"
    >
      <template #title>
        <span v-html="pageObj.title"></span>
      </template>
      <template #subtitle>
        <span v-html="pageObj.subtitle"></span>
      </template>
      <template #btn>
        <InteractiveFormActionButtonBlock with-press-enter @click="$emit('go-to-next-page')">
          <template #button-content>
            <span v-html="pageObj.buttonText"></span>
          </template>
        </InteractiveFormActionButtonBlock>
      </template>
    </InteractiveHomePageDisplayManager>
  </InteractiveHomePageStylesProvider>
</template>

<script>

export default {
  components: {
    InteractiveHomePageDisplayManager: () => import("@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageDisplayManager"),
    InteractiveHomePageStylesProvider: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveHomePageStylesProvider'),
    InteractiveFormActionButtonBlock: () => import("@/components/FormGenericComponents/InteractiveForm/InteractiveFormActionButtonBlock"),
  },
  props: {
    pageObj: {
      type: Object,
      required: true
    }
  },
}
</script>
