var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "content-class": "interactive-new-field-selection-dialog",
      "max-width": "730",
      "close-on-content-click": false,
      "offset-x": "",
      "nudge-right": "28",
      "nudge-top": "15",
      "attach": ".form-composition-card"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-tooltip', {
          attrs: {
            "value": _vm.tooltip,
            "color": "bluish_gray_500",
            "content-class": "tooltip-right",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var tooltipOn = _ref2.on;
              return [_c('v-btn', _vm._g({
                staticClass: "v-btn--primary",
                attrs: {
                  "unused": tooltipOn,
                  "color": "dark_gray",
                  "text": "",
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function ($event) {
                    _vm.tooltip = false;
                  }
                }
              }, on), [_c('PlusCircleIcon', {
                staticClass: "plus-icon",
                attrs: {
                  "stroke-width": "1.5"
                }
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" Add new question ")])];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-card', {
          ref: "card",
          staticClass: "v-card",
          style: _vm.fixedDialogHeight,
          attrs: {
            "min-width": "700"
          }
        }, [_c('v-card-title', [_c('span', [_vm._v("Add New Question")]), _c('v-spacer'), _c('SearchBarComponent', {
          attrs: {
            "lowercase": ""
          },
          on: {
            "~input": function ($event) {
              return _vm.setFixedDialogHeight.apply(null, arguments);
            }
          },
          model: {
            value: _vm.searchQuery,
            callback: function ($$v) {
              _vm.searchQuery = $$v;
            },
            expression: "searchQuery"
          }
        })], 1), _c('v-card-text', [_c('v-container', {
          staticClass: "interactive-new-field-selection-dialog-container"
        }, [_vm._l(_vm.filteredFieldsGroups, function (fieldsGroup, groupKey) {
          return [_c('v-row', {
            key: groupKey,
            staticClass: "interactive-new-field-selection-dialog-row"
          }, _vm._l(fieldsGroup, function (fieldConst) {
            return _c('v-col', {
              key: fieldConst.FIELD_TYPE,
              attrs: {
                "cols": "4"
              }
            }, [_c('div', {
              staticClass: "interactive-new-field-selection-field-tile",
              on: {
                "click": function ($event) {
                  return _vm.addNewInteractiveField(fieldConst.FIELD_TYPE);
                }
              }
            }, [_c(fieldConst.ICON_COMPONENT, {
              tag: "component",
              attrs: {
                "width": "36",
                "height": "36"
              }
            }), _c('span', [_vm._v(_vm._s(fieldConst.READABLE_NAME))]), _c('v-spacer'), _vm.getCurrentSubscriptionFeatures && fieldConst.ASSOCIATE_WITH_PAID_FEATURE && !_vm.getCurrentSubscriptionFeatures[fieldConst.ASSOCIATE_WITH_PAID_FEATURE] ? _c('f-hover-tooltip', {
              attrs: {
                "max-width": "250",
                "top": "",
                "text": "You can try this paid feature, but you'll need to remove it to publish your form"
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function (_ref3) {
                  var on = _ref3.on;
                  return [_c('f-badge', _vm._g({
                    attrs: {
                      "purple": ""
                    },
                    on: {
                      "click": function ($event) {
                        $event.stopPropagation();
                        return _vm.upgradeToProFromModal.apply(null, arguments);
                      }
                    }
                  }, on), [_vm._v(" Paid ")])];
                }
              }], null, true)
            }) : _vm._e()], 1)]);
          }), 1), groupKey !== _vm.filteredFieldsGroups.length - 1 ? _c('v-divider', {
            key: groupKey + 'divider',
            staticClass: "interactive-new-field-selection-dialog-container-divider"
          }) : _vm._e()];
        })], 2)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }