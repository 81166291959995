var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.5",
      "y": "0.5",
      "width": "25",
      "height": "25",
      "rx": "7.5",
      "fill": "#F8F0E2",
      "stroke": "#F8F0E2"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.3333 15.1822V17.0795C17.3348 17.219 17.3059 17.3572 17.2486 17.4845C17.1914 17.6118 17.1071 17.7253 17.0016 17.817C16.8961 17.9088 16.772 17.9767 16.6376 18.0161C16.5032 18.0554 16.3619 18.0654 16.2234 18.0451C14.3026 17.7993 12.5185 16.9239 11.1522 15.5568C9.78585 14.1897 8.91504 12.4087 8.67691 10.4943C8.65666 10.3567 8.6665 10.2163 8.70575 10.0828C8.745 9.94934 8.81274 9.82588 8.90433 9.7209C8.99592 9.61591 9.1092 9.53187 9.23642 9.47453C9.36364 9.4172 9.50179 9.38791 9.64142 9.38869H11.5171C11.6369 9.38796 11.7528 9.43147 11.8423 9.51083C11.9318 9.59018 11.9886 9.69974 12.0018 9.81837C12.0499 10.4511 12.2142 11.0697 12.4865 11.6433C12.5332 11.752 12.5382 11.8739 12.5007 11.986C12.4632 12.0981 12.3857 12.1926 12.2829 12.2516L11.8661 12.4882C11.806 12.5211 11.7536 12.5664 11.7124 12.6209C11.6712 12.6755 11.642 12.7382 11.6269 12.8048C11.6118 12.8714 11.611 12.9405 11.6247 13.0074C11.6384 13.0743 11.6661 13.1376 11.7062 13.1931C12.1857 13.8927 12.7922 14.4969 13.4946 14.9746C13.5503 15.0144 13.6138 15.0421 13.681 15.0557C13.7482 15.0693 13.8175 15.0686 13.8844 15.0535C13.9513 15.0384 14.0142 15.0094 14.069 14.9684C14.1238 14.9273 14.1692 14.8751 14.2023 14.8153L14.4397 14.4001C14.5025 14.2963 14.6021 14.2199 14.7189 14.1859C14.8356 14.1519 14.9608 14.1629 15.0698 14.2166C15.6457 14.4878 16.2667 14.6515 16.9019 14.6994C17.021 14.7125 17.131 14.7691 17.2107 14.8583C17.2903 14.9474 17.334 15.0628 17.3333 15.1822Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }