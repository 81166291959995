import FSPThemesActions from '@/utils/actions/fsp_themes_actions'

import { SMAGableExtensions } from '@/xstore/utils/smagable'
import _ from 'lodash'


export default ({useExtension}) => {
    {
        const state = {
            fsp_themes_registry: {}
        }

        useExtension(SMAGableExtensions.SGable, {state})
    }

    {
        const state = {
            public_themes_ids: [],
            private_themes_ids: []
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }


    return {
        mutations: {
            UPDATE_FSP_THEMES_REGISTRY: (state, themeOrThemes) => {
                if (!_.isArray(themeOrThemes))
                    themeOrThemes = [themeOrThemes]
                Object.assign(state.fsp_themes_registry, Object.fromEntries(themeOrThemes.map(themeObj => [themeObj.id, themeObj])))
            }
        },
        actions: {
            fetchAllThemes: ({commit}) => Promise.all([
                FSPThemesActions.listPublicThemes(),
                FSPThemesActions.listPrivateThemes()
            ])
                .then(([publicThemes, privateThemes]) => {
                    commit('SET_PUBLIC_THEMES_IDS', publicThemes.map(({id}) => id))
                    commit('SET_PRIVATE_THEMES_IDS', privateThemes.map(({id}) => id))

                    commit('UPDATE_FSP_THEMES_REGISTRY', [publicThemes, privateThemes])
                }),
            fetchThemesListById: ({commit}, themesList) => {
                const uniqueThemesIds = _.uniq(themesList)

                return Promise.all(uniqueThemesIds.map(themeId => FSPThemesActions.getTheme(themeId).then(themeObj => commit('UPDATE_FSP_THEMES_REGISTRY', themeObj))))
            }
        }
    }
}