var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-scale-transition', {
    attrs: {
      "origin": "center",
      "mode": "out-in"
    }
  }, [_vm.coverHovered && !_vm.getFormJSON.cover_text.content && !_vm.isEditorOpened ? _c('AddBadge', {
    staticClass: "static-form-badge-add-header",
    on: {
      "click": function ($event) {
        _vm.isEditorOpened = true;
      }
    }
  }, [_vm._v(" Add header text ")]) : _vm.getFormJSON.cover_text.content || _vm.isEditorOpened ? _c('MoveableComponentWithEditor', {
    ref: "moveable-editor",
    attrs: {
      "selector": _vm.selector,
      "content": _vm.getFormJSON.cover_text.content,
      "translate": _vm.getFormJSON.cover_text.translate,
      "height": _vm.getFormJSON.cover_text.height,
      "width": _vm.getFormJSON.cover_text.width,
      "opened-from-outside": _vm.isEditorOpened
    },
    on: {
      "content": _vm.setTextContent,
      "place": _vm.setTranslatesAndHeight,
      "close-editor": function ($event) {
        _vm.isEditorOpened = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }