var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#EBF1FC"
    }
  }), _c('rect', {
    attrs: {
      "x": "7.22222",
      "y": "7.22217",
      "width": "11.5556",
      "height": "11.5556",
      "rx": "3",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.8338 13.3611L12.3981 14.8056L15.4074 11.7964",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }