var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selection-field-view selection-field-view--full-single-answer"
  }, [_c('div', {
    staticClass: "selection-field-view__layout"
  }, [!_vm.submissionObj.value.length ? _c('p', [_vm._v("-")]) : _vm._e(), _vm._l([].concat(_vm.submissionObj.value).sort(), function (optionIndex) {
    return _c('p', {
      key: optionIndex
    }, [_vm._v(" " + _vm._s(_vm.sourceObj.content.options[optionIndex]) + " ")]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }