var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._g(_vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (activatorData) {
        return [_vm._t("activator", null, null, activatorData)];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-card', [_c('v-card-title', [_vm._t("title"), _vm.withCloseBtn ? [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "disabled": _vm.bind.persistent,
            "icon": ""
          },
          on: {
            "click": _vm.closeDialog
          }
        }, [_c('XIcon')], 1)] : _vm._e()], 2), _c('v-card-text', [_vm._t("content")], 2), _c('v-card-actions', [_vm._t("actions")], 2)], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-dialog', _vm.bind, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }