import XStore from '../xstore'
import user_store from "./user/user_store"
import form_edit_store from './form_edit/form_edit_store'
import forms_list_store from './forms_list/forms_list_store'
import submissions_analytics_store from '@/store/submissions_analytics/submissions_analytics_store'
import form_templates_store from '@/store/form_templates/form_templates_store'
import fsp_themes_store from '@/store/fsp_themes/fsp_themes_store'


const store = XStore.createStore({
    state: {
        loading: false,
    },
    mutations: {
        START_LOADING: (state) => state.loading = true,
        STOP_LOADING: (state) => state.loading = false,
    },
    actions: {
        startLoading({commit}) {
            commit('START_LOADING')
        },
        stopLoading({commit}) {
            commit('STOP_LOADING')
        },
    },
    getters: {
        isLoading: state => state.loading,
    }
})

store.registerModule(['user'], user_store)
store.registerModule(['edit'], form_edit_store)
store.registerModule(['FSP_themes'], fsp_themes_store)
store.registerModule(['forms_list'], forms_list_store)
store.registerModule(['submissions'], submissions_analytics_store)
store.registerModule(['form_templates'], form_templates_store)

export default store