var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-checkbox', {
    staticClass: "checkbox-item",
    attrs: {
      "label": _vm.inputConst.label,
      "input-value": _vm.inputValue,
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.onInput($event);
      }
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }