var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-editable-field-constructor__popup"
  }, [_c('transition', {
    attrs: {
      "name": _vm.transitionName,
      "mode": "out-in"
    },
    on: {
      "after-leave": function ($event) {
        return _vm.changeNameTransition();
      }
    }
  }, [_vm.currentPopup === _vm.allPopups.actions ? _c('StaticFieldActions', {
    attrs: {
      "conditional-logic-support": !!_vm.fieldTypeConst.CONDITIONAL_LOGIC
    },
    on: {
      "open-properties": _vm.openProperties,
      "duplicate-field": _vm.duplicateField,
      "open-conditional-logic-editor": _vm.openConditionalLogicEditor,
      "remove-field": _vm.removeField
    }
  }) : _vm.currentPopup === _vm.allPopups.properties ? _c('EditableFieldPropertiesDefaultWindow', {
    attrs: {
      "field-obj": _vm.fieldObj,
      "field-id": _vm.fieldId
    },
    on: {
      "close-properties": function () {
        return _vm.closeProperties() || _vm.openActions();
      },
      "change-field-type": _vm.changeFieldType
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }