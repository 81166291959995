import Vue from 'vue'
import GenericTextSummaryFieldView from '@/components/Fields/AnalyticsField/Summary/GenericTextSummaryFieldView'

export default Vue.extend({
    name: 'Summary__Datetime',
    extends: GenericTextSummaryFieldView,
    computed: {
        filteredSubmissions() {
            return this.submissions
                .filter(({submissionObj: {value}}) => value !== null)
                .map(submissionData => {
                    return {...submissionData, submissionObj: {...submissionData, value: this.constants.FIELD_TYPES.DATETIME.UTIL.GEN_READABLE_FROM_SUBMISSION_VALUE(this.versionedSources[submissionData.versionID], submissionData.submissionObj)}}
                })
        }
    }
})