var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "id": "notion"
    }
  }, [_c('path', {
    attrs: {
      "id": "bg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.4305 32.87L2.56122 28.0498C1.63027 26.89 1.125 25.4639 1.125 23.9961V6.54186C1.125 4.34322 2.88403 2.51884 5.1533 2.36391L23.0986 1.13871C24.4024 1.0497 25.6982 1.39623 26.7691 2.12029L33.0741 6.38315C34.2028 7.14621 34.875 8.39361 34.875 9.72479V29.5686C34.875 31.7202 33.1454 33.5017 30.9236 33.6385L11.0062 34.8645C9.23325 34.9736 7.51949 34.2266 6.4305 32.87Z",
      "fill": "white"
    }
  }), _c('g', {
    attrs: {
      "id": "vector"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.6542 15.276V15.0475C12.6542 14.4682 13.1181 13.9878 13.716 13.9479L18.0713 13.6571L24.0942 22.5264V14.7421L22.5439 14.5354V14.4269C22.5439 13.8408 23.0185 13.3573 23.624 13.3262L27.5868 13.1232V13.6935C27.5868 13.9612 27.3878 14.1902 27.1149 14.2364L26.1613 14.3977V27.0041L24.9645 27.4157C23.9646 27.7595 22.8515 27.3915 22.2791 26.5277L16.4319 17.7043V26.1258L18.2316 26.4702L18.2066 26.6371C18.128 27.1601 17.6766 27.5555 17.1304 27.5798L12.6542 27.7791C12.595 27.2168 13.0164 26.7137 13.5978 26.6526L14.1866 26.5908V15.3621L12.6542 15.276Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M23.2593 3.33757L5.31397 4.56277C4.23905 4.63616 3.40582 5.50034 3.40582 6.5418V23.996C3.40582 24.9746 3.74267 25.9253 4.3633 26.6985L8.23258 31.5187C8.85817 32.2981 9.84267 32.7272 10.8612 32.6645L30.7785 31.4385C31.7994 31.3756 32.594 30.5571 32.594 29.5685V9.72473C32.594 9.11311 32.2852 8.53998 31.7666 8.18938L25.4616 3.92652C24.819 3.49208 24.0415 3.28416 23.2593 3.33757ZM6.20384 6.81406C5.95425 6.62927 6.07303 6.2443 6.38658 6.22182L23.38 5.00294C23.9215 4.9641 24.4595 5.1132 24.8974 5.42343L28.307 7.83908C28.4364 7.93079 28.3757 8.12878 28.2154 8.13749L10.2194 9.11622C9.6748 9.14584 9.13723 8.98585 8.7042 8.66525L6.20384 6.81406ZM9.37533 12.1845C9.37533 11.5999 9.84765 11.1169 10.4516 11.084L29.4785 10.0478C30.0671 10.0158 30.5626 10.4684 30.5626 11.0382V28.2209C30.5626 28.8044 30.092 29.2869 29.4892 29.3212L10.5828 30.3969C9.92773 30.4342 9.37533 29.9308 9.37533 29.2966V12.1845Z",
      "fill": "black"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }