<template>
  <svg width="77" height="55" viewBox="0 0 77 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.47852" width="75.913" height="52.0435" rx="7.45652" fill="white"/>
    <path d="M44 1H67.7174C72.844 1 77 5.15597 77 10.2826V44.7174C77 49.844 72.844 54 67.7174 54H44V1Z" fill="#F5F6F8"/>
    <rect x="13" y="18.2168" width="19.8913" height="2.65217" rx="1.32609" fill="currentColor"/>
    <rect x="13" y="23.5215" width="19.8913" height="7.95652" rx="1.32609" fill="currentColor"/>
    <rect x="13" y="34.1289" width="6.63043" height="2.65217" rx="1.32609" fill="currentColor"/>
    <rect class="border" stroke-width="1" x="0.5" y="1.47852" width="75.913" height="52.0435" rx="7.45652" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "LeftLayoutIcon",
}
</script>

<style scoped>

</style>