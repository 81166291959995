var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "showImage": !!_vm.fieldProperties.image_content.url,
      "src": _vm.fieldProperties.image_content.url,
      "width": _vm.fieldProperties.image_size.width + '%',
      "lazy-src": _vm.fieldProperties.image_content.url,
      "aspect-ratio": _vm.fieldProperties.image_content.naturalWidth / _vm.fieldProperties.image_content.naturalHeight / (_vm.fieldProperties.image_size.height / 100)
    },
    scopedSlots: _vm._u([{
      key: "loader",
      fn: function () {
        return [_vm.uploadingInProcess ? _c('div', [_c('f-circle-loader', {
          attrs: {
            "size": "3"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "removeButton",
      fn: function (hover) {
        return [_c('v-scale-transition', {
          attrs: {
            "origin": "center center 0"
          }
        }, [hover && _vm.isEditingActive ? _c('v-btn', {
          staticClass: "image-field-view__img-remove-btn",
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.removeImage
          }
        }, [_c('XIcon', {
          attrs: {
            "size": "14",
            "stroke-width": "2.9"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "addButton",
      fn: function () {
        return [_c('AddOptionButton', {
          attrs: {
            "is-editing-active": _vm.isEditingActive,
            "hidden": !!_vm.fieldProperties.image_content.url
          },
          on: {
            "add-option": _vm.addImage
          }
        }, [_vm._v(" Add image ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }