import router from '@/router'
import constants from '@/constants/constants'
import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            plan_confirmed: false,
            purchase_completed: false
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }

    return {
        actions: {
            confirmPlan: ({commit}) => {
                commit('SET_PLAN_CONFIRMED', true)
                return router.push({name: constants.PAGE_NAME_CHECKOUT_CUSTOMER_DETAILS})
            },
            purchaseCompleted: ({commit}) => {
                commit('SET_PURCHASE_COMPLETED', true)
                return router.push({name: constants.PAGE_NAME_CHECKOUT_PURCHASE_COMPLETED})
            },
            handleLeavingCheckoutPage({commit}) {
                commit('SET_PLAN_CONFIRMED', false)
                commit('SET_PURCHASE_COMPLETED', false)
            }
        },
        getters: {
            getPlanSelectionConfirmationAllowed: ({parentGetters}) => !!parentGetters['selected_plan/getSelectedPrice'],
            getIsCustomerDetailsPageUnlocked: ({getters}) => !!getters.getPlanConfirmed,

            getPurchaseSubmittingAllowed: ({getters, parentGetters}) => getters.getPlanSelectionConfirmationAllowed
                && getters.getIsCustomerDetailsPageUnlocked
                && parentGetters['customer_details/getPaymentMethodIsExist']
                && parentGetters['customer_details/getBillingDetailsIsExist'],

            getIsPurchaseCompletePageUnlocked: ({getters}) => !!getters.getPurchaseCompleted
        }
    }
}