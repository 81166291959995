export const resend_code_mixin = {
    data: () => ({
        resendConfirmationCodeCountdown: 10,
        codeResent: false
    }),
    computed: {
        prettyResendConfirmationCodeCountdown() {
            return this.resendConfirmationCodeCountdown ? (this.resendConfirmationCodeCountdown > 9 ? '0:' : '0:0') + this.resendConfirmationCodeCountdown : ''
        }
    },
    methods: {
        resendCountdownTimer() {
            if (this.resendConfirmationCodeCountdown) {
                setTimeout(() => {
                    this.resendConfirmationCodeCountdown -= 1
                    this.resendCountdownTimer()
                }, 1000)
            }
        }
    }
}