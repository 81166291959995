var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-card-brand-icon"
  }, [_c('img', {
    staticClass: "f-card-brand-icon__icon",
    attrs: {
      "src": _vm.$options.brands[_vm.brand].icon
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }