var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-slide-y-transition', [_vm.menuModel ? _c('div', {
    staticClass: "form-parameters-menu"
  }, [_vm._t("default", null, {
    "active": _vm.menuModel
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }