// if we will move this line, the composition api plugin won't be registered
// eslint-disable-next-line import/order
import store from '../store'

import { AmplifyEventBus } from 'aws-amplify-vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

import constants from '@/constants/constants'
import { promiseWithAnimation } from '@/utils/promise_with_animation'


Vue.use(VueRouter)

AmplifyEventBus.$on('authState', state => {
  if (state === 'signedIn') {
    return promiseWithAnimation(
      store
        .dispatch('user/authUser')
        .then(() => router.push({ name: constants.PAGE_NAME_DASHBOARD }))
        .catch(e => console.log(e))
    )
  } else if (state === 'signedOut') {
    router.push({ name: constants.PAGE_NAME_LOGIN })
  }
})

const DEFAULT_TITLE = 'Formsly'
const FormSubmit = () => import('@/views/FormSubmit/FormSubmit.vue');

const routes = [
  {
    path: '/',
    name: constants.PAGE_NAME_DASHBOARD,
    component: () => import('@/views/Dashboard'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      fadedBackground: true,
    },
  },
  // {
  //     path: '/templates',
  //     name: constants.PAGE_NAME_TEMPLATES,
  //     component: () => import('@/views/Templates'),
  //     meta: {
  //         requiresAuth: true,
  //         title: 'Templates'
  //     }
  // },
  {
    path: '/:formId/edit',
    name: constants.PAGE_NAME_FORM_EDITOR,
    component: () => import('@/views/FormEditor'),
    meta: {
      requiresAuth: true,
      title: 'Form Editor',
      appBar: constants.APP_BAR_FORM_EDITOR,
      fadedBackground: false,
    },
  },
  {
    path: '/:formId/responses',
    name: constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
    component: () => import('@/views/SubmissionsAnalytics'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Analytics',
      fadedBackground: true,
      appBar: constants.APP_BAR_SUBMISSIONS_ANALYTICS,
    },
  },
  {
    path: '/login',
    name: constants.PAGE_NAME_LOGIN,
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      requiresLogout: true,
      title: 'Login',
      appBar: constants.APP_BAR_EMPTY,
      fadedBackground: false,
    },
  },
  {
    path: '/forgot_password',
    name: constants.PAGE_NAME_FORGOT_PASSWORD,
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      requiresLogout: true,
      title: 'Forgot Password',
      appBar: constants.APP_BAR_EMPTY,
      fadedBackground: false,
    },
  },
  {
    path: '/reset_password',
    name: constants.PAGE_NAME_RESET_PASSWORD,
    component: () => import('@/views/Auth/ResetPasswordByLink'),
    meta: {
      requiresLogout: true,
      title: 'Reset Password',
      appBar: constants.APP_BAR_EMPTY,
      fadedBackground: false,
    },
  },
  {
    path: '/signup',
    name: constants.PAGE_NAME_SIGNUP,
    component: () => import('@/views/Auth/SignUp'),
    meta: {
      requiresLogout: true,
      title: 'Sign Up',
      appBar: constants.APP_BAR_EMPTY,
      fadedBackground: false,
    },
  },
  {
    path: '/signup_appsumo',
    name: constants.PAGE_NAME_SIGNUP_APPSUMO,
    component: () => import('@/views/Auth/SignUpAppSumo'),
    meta: {
      requiresLogout: true,
      title: 'Sign Up AppSumo',
      appBar: constants.APP_BAR_EMPTY,
      fadedBackground: false,
    },
  },
  {
    path: '/type_selection',
    name: constants.PAGE_NAME_FORM_TYPE_SELECTION,
    component: () => import('@/views/FormCreation/FormTypeSelection.vue'),
    meta: {
      requiresAuth: true,
      title: 'Type Selection',
      appBar: constants.APP_BAR_BACK_TO_DASHBOARD,
      fadedBackground: true,
    },
  },
  {
    path: '/preset_selection/:form_type',
    name: constants.PAGE_NAME_FORM_PRESET_SELECTION,
    component: () => import('@/views/FormCreation/FormPresetSelection.vue'),
    meta: {
      requiresAuth: true,
      title: 'Preset selection',
      appBar: constants.APP_BAR_BACK_TO_FORM_TYPE_SELECTION,
      fadedBackground: true,
    },
  },
  {
    path: '/ai_prompt/:form_type',
    name: constants.PAGE_NAME_FORM_AI_PROMPT,
    component: () => import('@/views/FormCreation/FormCreationAiPrompt.vue'),
    meta: {
      requiresAuth: true,
      title: 'Formsly AI',
      appBar: constants.APP_BAR_BACK_TO_FORM_TYPE_SELECTION,
      fadedBackground: true,
    },
  },
  // {
  //   path: '/template_selection/:form_type',
  //   name: constants.PAGE_NAME_FORM_TEMPLATE_SELECTION,
  //   component: () => import('@/views/FormCreation/FormTemplateSelection.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     title: 'Template Selection',
  //     // appBar: constants.APP_BAR_TEMPLATE_SELECTION,
  //     fadedBackground: true,
  //   },
  // },
  {
    path: '/form-not-found',
    name: constants.PAGE_NAME_FORM_NOT_FOUND,
    component: () => import('@/views/ClosedForm'),
    meta: {
      appBar: constants.APP_BAR_EMPTY,
    },
  },
  {
    path: '/settings',
    name: constants.PAGE_NAME_SETTINGS,
    component: () => import('@/views/Settings/Settings'),
    children: [
      {
        path: '',
        name: constants.PAGE_NAME_ACCOUNT_DETAILS,
        component: () => import('@/views/Settings/AccountDetails'),
        meta: {
          requiresAuth: true,
          title: 'Your account',
          fadedBackground: true,
        },
      },
      {
        path: 'plan_billing',
        name: constants.PAGE_NAME_PLAN_BILLING,
        component: () => import('@/views/Settings/PlanBilling'),
        meta: {
          requiresAuth: true,
          title: 'Plan and Payments',
          fadedBackground: true,
        },
      },
      // {
      //     path: 'notifications',
      //     name: constants.PAGE_NAME_NOTIFICATIONS,
      //     component: () => import('@/views/Settings/Notifications'),
      //     meta: {
      //         requiresAuth: true,
      //         title: 'Notifications',
      //         fadedBackground: true
      //     }
      // },
      // {
      //     path: 'integrations',
      //     name: constants.PAGE_NAME_INTEGRATIONS,
      //     component: () => import('@/views/Settings/Integrations'),
      //     meta: {
      //         requiresAuth: true,
      //         title: 'Integrations',
      //         fadedBackground: true
      //     }
      // },
      // {
      //     path: 'danger_zone',
      //     name: constants.PAGE_NAME_DANGER_ZONE,
      //     component: () => import('@/views/Settings/DangerZone'),
      //     meta: {
      //         requiresAuth: true,
      //         title: 'Delete account',
      //         fadedBackground: true
      //     }
      // },
    ],
  },
  {
    path: '/checkout',
    name: constants.PAGE_NAME_CHECKOUT,
    component: () => import('@/views/Checkout/Checkout'),
    children: [
      {
        path: '',
        name: constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN,
        component: () => import('@/views/Checkout/CheckoutChoosePlan'),
        meta: {
          requiresAuth: true,
          title: 'Choose a Plan',
          fadedBackground: true,
          appBar: constants.APP_BAR_CHECKOUT,
        },
      },
      {
        path: 'payment',
        name: constants.PAGE_NAME_CHECKOUT_CUSTOMER_DETAILS,
        component: () =>
          import('@/views/Checkout/CheckoutCustomerDetailsSetup'),
        meta: {
          requiresAuth: true,
          title: 'Payment',
          fadedBackground: true,
          appBar: constants.APP_BAR_CHECKOUT,
        },
      },
      {
        path: 'completed',
        name: constants.PAGE_NAME_CHECKOUT_PURCHASE_COMPLETED,
        component: () => import('@/views/Checkout/CheckoutPurchaseCompleted'),
        meta: {
          requiresAuth: true,
          title: 'Congratulations!',
          fadedBackground: true,
          appBar: constants.APP_BAR_CHECKOUT,
        },
      },
    ],
  },
  {
    path: '/:formId',
    name: constants.PAGE_NAME_FORM_SUBMIT,
    component: () => import('@/views/FormSubmit/FormSubmit.vue'),
    props: true,
    meta: {
      title: 'Form Submit',
      appBar: constants.APP_BAR_NONE,
      fadedBackground: false,
    },
  },
  {
    path: '/*',
    name: constants.PAGE_NAME_NOT_FOUND,
    component: () => import('@/views/404NotFound'),
    meta: {
      title: 'Not Found',
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.replaceWithoutRedirecting = (viewName, params) => {
  router.replace({
    name: viewName,
    params: {
      0: router.history.current.path,
      ...params,
    },
    replace: true,
  })
}

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) {
    next()
  } else {
    if (store.getters['user/getCurrentCognitoUser']) {
      next()
    }
    store
      .dispatch('user/authUser')
      .then(() => {
        if (to.matched.some(record => record.meta.requiresAuth)) next()
        else if (to.matched.some(record => record.meta.requiresLogout))
          next({ name: constants.PAGE_NAME_DASHBOARD })
      })
      .catch(() => {
        if (to.matched.some(record => record.meta.requiresAuth))
          next({ name: constants.PAGE_NAME_LOGIN })
        else if (to.matched.some(record => record.meta.requiresLogout)) next()
      })
  }
  router.prevRoute = from
})
router.afterEach(to => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
