<template>
    <f-fluid-container class="interactive-form interactive-form--editable">
        <div>
            <InteractiveFormComposition/>

            <InteractivePagesDisplayManager/>

            <ImagePicker ref="imagePicker"/>
            <FormParameters/>
            <!--<FormSelectDeviceView/>-->
            <ConditionalLogicHandler/>
        </div>
    </f-fluid-container>
</template>

<script>

    import ImagePicker from '@/components/Elements/ImagePicker/ImagePicker'
    export default {
        name: "InteractiveFormEditor",
        components: {
            InteractivePagesDisplayManager: () => import('./InteractivePages/InteractivePagesDisplayManager'),
            // FormSelectDeviceView: () => import('../FormSelectDeviceView/FormSelectDeviceView'),

            InteractiveFormComposition: () => import('./InteractiveFormComposition/InteractiveFormComposition'),
            FormParameters: () => import('../FormParameters/FormParameters'),
            ImagePicker,
            ConditionalLogicHandler: () => import('../ConditionalLogic/ConditionalLogicHandler')
        },
        mounted() {
          this.$store.dispatch('edit/homepage_image_preview/controlImagePickerInstance', this.$refs.imagePicker)
        },
        beforeDestroy() {
          this.$store.dispatch('edit/homepage_image_preview/controlImagePickerInstance')
        }
    }
</script>