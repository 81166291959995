<template>
    <div class="static-main-page">
        <div class="static-main-page__content">
            <StaticFieldsDragManager
                    :page-obj="pageObj"
                    @start-adding-new-field="isNewFieldAdding = true"
                    @stop-adding-new-field="isNewFieldAdding = false"
            />
            <div v-if="!pageObj.rows.length && !isNewFieldAdding" class="static-main-page__empty">
                <img
                        src="/images/EmptyStaticForm.svg"
                        class="img-empty-page"
                        :draggable="false"
                />
                <p class="mb-2">
                    Time to create!
                </p>
                <p class="subtitle-2 grey_500--text font-weight-regular mb-10">
                  Simply drag and drop or choose questions to fill your page
                </p>
            </div>
        </div>

        <StaticMainPageSubmitButtonComponent :page-obj="pageObj"/>
    </div>
</template>

<script>
    import { watch } from '@vue/composition-api/dist/vue-composition-api'
    import { onBeforeUnmount, ref } from '@vue/composition-api'

    export default {
        name: "StaticFormMainPage",
        components: {
            StaticFieldsDragManager: () => import('./StaticFieldsDragManager/StaticFieldsDragManager'),
            StaticMainPageSubmitButtonComponent: () => import('./StaticMainPageSubmitButtonComponent')
        },
        props: {
            pageId: {
                type: String,
                required: true
            }
        },
        setup(props, {root}) {
            const pageObj = ref(undefined)
            const isNewFieldAdding = ref(false)
            const unwatch = watch(
                () => root.$store.getters['edit/pages_navigation/getCurrentPage'],
                v => {
                    pageObj.value = root.Utils.deepCloneObject(v)
                },
                {deep: true, immediate: true}
            )

            onBeforeUnmount(() => {
                unwatch()
            })
            return {
                pageObj,
                isNewFieldAdding
            }
        }
    }
</script>