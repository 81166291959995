<template>
    <div :class="[
        'static-form__page-wrapper',
        (getFormSourceJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE) && ' visible-logo',
        (getFormSourceJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE) && 'invisible-logo',
    ]">
        <LogoBadge :logo-url="getFormSourceJSON.logo_url" />
        <div class="static-form__page">
            <StaticMainPage v-if="isMainPage" :page-id="getCurrentPageID" :key="getCurrentPageID" />
            <StaticFinishPage v-else-if="isFinishPage" />
        </div>
    </div>
</template>

<script>
import { useMapActions, useMapGetters } from '@/xstore'
import { inject, onBeforeUnmount, watch } from '@vue/composition-api/dist/vue-composition-api'
import { computed } from "@vue/composition-api";

export default {
    name: "StaticPagesDisplayManager",
    components: {
        LogoBadge: () => import('../LogoBagde/LogoBadge'),
        StaticMainPage: () => import('./MainPage/StaticMainPage'),
        StaticFinishPage: () => import('./FinishPage/StaticFinishPage')
    },
    setup(props, { root, emit }) {
        const formSubmitStoreModulePath = inject('formSubmitStoreModulePath');
        const getCurrentPageID = computed(() => root.$store.getters[`${formSubmitStoreModulePath}/getCurrentPageID`])
        const unwatch = watch(
            getCurrentPageID,
            () => {
                emit('page-changed')
            }
        )
        onBeforeUnmount(() => {
            unwatch()
        })
        return {
            ...useMapGetters(formSubmitStoreModulePath, [
                'getFormSourceJSON',
                'isMainPage',
                'isFinishPage'
            ]),
            ...useMapActions(formSubmitStoreModulePath, [
                'setCurrentPageID'
            ]),
            getCurrentPageID
        }
    },
    created() {
        this.setCurrentPageID(this.getFormSourceJSON.main_pages[0].page_id)
    }
}
</script>

<style lang="scss" scoped>
.visible-logo .static-form-logo-badge {
    display: block;
}

.invisible-logo .static-form-logo-badge {
    display: none;
}
</style>