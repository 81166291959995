import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import {GenericNumberConditionalLogic} from '@/components/Fields/FieldConstClasses/conditional_logic'
import {Analytics, GenericTextAnalytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithComplexConstructor, FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyNumberInput, FieldPropertyStringInput,
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'

class SliderFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'SLIDER',
            READABLE_NAME: 'Slider',
            ICON_NAME: 'slider',
            FOLDER_NAME: 'Slider',

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            IS_SUBMISSION_EMPTY: ({value}) => value === null,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            CONDITIONAL_LOGIC: new GenericNumberConditionalLogic({
                PARAMS: {
                    ALLOWED_RANGE: fieldObj => Object.values(fieldObj.properties.scale_poles),
                },
            }),

            ANALYTICS: new Analytics({
                FILTER: ({value}, search) => value !== null && value.toString().includes(search),
                SORT: ({value: a}, {value: b}) => a - b,
                FORMAT_TO_CSV_EXPORT: GenericTextAnalytics.DEFAULT_FORMAT_TO_CSV_EXPORT,
                FULL_SINGLE_ANSWER_VIEW: GenericTextAnalytics.DEFAULT_SINGLE_ANSWER_VIEW,
                SHORT_SINGLE_ANSWER_VIEW: GenericTextAnalytics.DEFAULT_SINGLE_ANSWER_VIEW
            }),

            FSP: (() => {
                const TRACK_BORDER_RADIUS = '100px'

                return new FieldFSPDefinition({
                    FSP_FIELD_TYPE: 'Slider',
                    FIELD_VIEW_CLASS: 'slider-field-view',
                    VARIABLES: [
                        {
                            selector: ['value'],
                            properties: {
                                'font-size': {
                                    default: {
                                        fn: '24px'
                                    }
                                },
                                'line-height': {
                                    default: {
                                        fn: '39px'
                                    }
                                },
                                'font-weight': {
                                    default: {
                                        fn: '700'
                                    }
                                },
                                'color': {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                                    },
                                    'default--static': {
                                        fn: '#000',
                                    }
                                }
                            }
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__track-container'],
                            properties: {
                                height: {
                                    default: {
                                        fn: '3px'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__track-container .v-slider__track-background'],
                            properties: {
                                'border-radius': {
                                    default: {
                                        fn: TRACK_BORDER_RADIUS
                                    }
                                },
                                'background-color': {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.4})
                                    },
                                    'default--static': {
                                        fn: 'var(--v-gray_300-base)'
                                    },
                                }
                            }
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__track-container .v-slider__track-fill'],
                            properties: {
                                'background-color': {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                    },
                                    'default--static': {
                                        fn: 'var(--v-gray_500-base)'
                                    },
                                },
                                'border-radius': {
                                    default: {
                                        fn: TRACK_BORDER_RADIUS
                                    }
                                }
                            }
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__thumb-container .v-slider__thumb:after'],
                            properties: {
                                'background-color': {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.1}),
                                    },
                                    'default--static': {
                                        fn: 'fff',
                                    }
                                },
                                color: {
                                    'default--static': {
                                        fn: '#fff',
                                    },
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.BUTTON_TEXT_COLOR.apply(),
                                    }
                                },
                                'font-size': {
                                    default: {
                                        fn: '9px',
                                    }
                                },
                                'border-radius': {
                                    default: {
                                        fn: '50%',
                                    }
                                },
                            },
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__thumb-container .v-slider__thumb'],
                            properties: {
                                width: {
                                    default: {
                                        fn: '24px'
                                    }
                                },
                                height: {
                                    default: {
                                        fn: '24px'
                                    }
                                },
                                'background-color': {
                                    default: {
                                        fn: '#fff',
                                    }
                                },
                                'box-shadow': {
                                    'default--interactive': {
                                        fn: `0 0 0 2px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()}`
                                    },
                                    'default--static': {
                                        fn: '0px 2px 4px rgba(0, 0, 0, 0.36)'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['slider .v-input__control .v-input__slot .v-slider__thumb-container .v-slider__thumb:before'],
                            properties: {
                                display: {
                                    default: {
                                        fn: 'none'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['labels > *'],
                            properties: {
                                color: {
                                    'default--interactive': {
                                        fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.7})
                                    },
                                    'default--static': {
                                        fn: '#7c7c7c',
                                    }
                                },
                                'font-size': {
                                    default: {
                                        fn: '14px'
                                    }
                                },
                                opacity: {
                                    default: {
                                        fn: '1',
                                    }
                                },
                                'font-weight': {
                                    default: {
                                        fn: 500
                                    }
                                },
                                'line-height': {
                                    default: {
                                        fn: '23px'
                                    }
                                }
                            }
                        },
                        {
                            selector: ['labels > span:before'],
                            properties: {
                                opacity: {
                                    default: {
                                        fn: '1'
                                    }
                                }
                            }

                        },
                    ]
                })
            })(),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'scale_poles',
                    name: 'Scale',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        from: new FieldPropertyNumberInput({
                            label: 'From',
                            half_width: true,
                            default: 0,
                            min_value: 0,
                            validator: (selfValue, propValue, fieldProps) =>
                                selfValue <= propValue.to - fieldProps.scale_units
                        }),
                        to: new FieldPropertyNumberInput({
                            label: 'To',
                            half_width: true,
                            default: 100,
                            validator: (selfValue, propValue, fieldProps) =>
                                selfValue >= propValue.from + fieldProps.scale_units
                        })
                    })
                }),
                new CustomFieldPropertyConst({
                    key: 'scale_units',
                    name: 'Scale Units',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyNumberInput({
                            half_width: true,
                            default: 1,
                            validator: (selfValue, propValue, fieldProps) =>
                                selfValue <= fieldProps.scale_poles.to - fieldProps.scale_poles.from
                        })
                    )
                }),
                new CustomFieldPropertyConst({
                    key: 'range_labels',
                    name: 'Range Labels',
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        left_label: new FieldPropertyStringInput({
                            dynamic_label: (s, p, fieldProps) => `Left (${fieldProps.scale_poles.from})`
                        }),
                        right_label: new FieldPropertyStringInput({
                            dynamic_label: (s, p, fieldProps) => `Right (${fieldProps.scale_poles.to})`
                        })
                    })
                })
            ])
        })
    }
}

export default new SliderFieldTypeConst()