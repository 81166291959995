var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown-field-view generic-text-field-view"
  }, [_c('f-autocomplete', {
    staticClass: "generic-text-field-view__input-field",
    attrs: {
      "placeholder": _vm.sourceObj.content.placeholder,
      "items": _vm.formattedOptions,
      "item-value": function (item) {
        return item.index;
      },
      "filter": function (item, search) {
        return item.optionName.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      },
      "value": _vm.submissionObj.value[0],
      "hide-details": "",
      "outlined": _vm.isFormStatic,
      "dense": "",
      "menu-props": {
        bottom: true
      },
      "attach": ""
    },
    on: {
      "input": function ($event) {
        _vm.submissionObj.value = [$event];
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs,
          item = _ref.item;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_vm.isReadyList ? _c('v-list-item-icon', [_c(_vm.currentSelectedReadyList.ICON_COMPONENT.NAME, _vm._b({
          tag: "component"
        }, 'component', _vm.currentSelectedReadyList.ICON_COMPONENT.BIND(item.initialItem, {
          overrideCountryFlagSize: 'm'
        }), false))], 1) : _vm._e(), _c('v-list-item-content', [_vm._v(" " + _vm._s(item.optionName) + " ")])], 1)];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-flex align-center"
        }, [_vm.isReadyList ? _c(_vm.currentSelectedReadyList.ICON_COMPONENT.NAME, _vm._b({
          tag: "component",
          staticClass: "mr-2"
        }, 'component', _vm.currentSelectedReadyList.ICON_COMPONENT.BIND(item.initialItem), false)) : _vm._e(), _vm._v(" " + _vm._s(item.optionName) + " ")], 1)];
      }
    }, {
      key: "append",
      fn: function () {
        return [_c('ChevronDownIcon')];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }