import Vue from "vue"

export default new Vue({
    data: () => ({
        sequences: {}
    }),
    methods: {
        createNewSequence(name, sequence) {
            this.sequences[name] = {
                sequence: sequence,
                step: -1
            }
        },
        createBlankSequence(name, steps) {
            this.createNewSequence(name, new Array(steps).fill(0).map(() => ({})))
        },
        getSequence(name) {
            return this.sequences[name]
        },

        startSequence(name) {
            this.nextStep(name)
        },
        nextStep(name) {
            let sequence = this.getSequence(name)

            if (sequence) {
                sequence.step += 1

                if (sequence.sequence[sequence.step].action)
                    sequence.sequence[sequence.step].action()

                if (sequence.step === sequence.sequence.length - 1)
                    delete this.sequences[name]
            }
            else
                throw 'Unknown sequence'
        },
        setStepAction(name, step, action) {
            let sequence = this.getSequence(name)
            if (sequence)
                sequence.sequence[step].action = action
            else
                throw 'Unknown sequence'
        }
    }
})