var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F5F0FA"
    }
  }), _c('circle', {
    attrs: {
      "cx": "8.78704",
      "cy": "8.7872",
      "r": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "8.78704",
      "cy": "13.0001",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "8.78704",
      "cy": "17.213",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('circle', {
    attrs: {
      "cx": "13",
      "cy": "8.7872",
      "r": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "13",
      "cy": "13.0001",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "13",
      "cy": "17.213",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "17.213",
      "cy": "8.7872",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "17.213",
      "cy": "13.0001",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "17.213",
      "cy": "17.213",
      "rx": "1.2037",
      "ry": "1.2037",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }