import { reactive, ref } from '@vue/composition-api'
import { API } from 'aws-amplify'
import { VAlert, VBtn, VSpacer, VTextField } from 'vuetify/lib/components'

import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

import current_subscription_monitoring_features_store from './current_subscription_modules/current_subscription_monitoring_features_store'

export default ({ useExtension }) => {
  {
    const state = {
      current_subscription_details: null,
    }

    useExtension(ToggleableExtensions.Loadable, { state })
  }

  {
    const state = {
      current_subscription: undefined,
      plan_limits_stats: undefined,
      is_app_sumo_user: undefined,
      app_sumo_promocode_activated: undefined,
    }

    useExtension(SMAGableExtensions.SMGable, { state })
  }

  return {
    modules: {
      monitoring_features: current_subscription_monitoring_features_store,
      confirmation_dialog: generic_dialog_module,
    },
    actions: {
      showAppSumoPromocodeDialog: ({ dispatch }, { closable }) =>
        new Promise(resolve => {
          dispatch('confirmation_dialog/showDialog', ({ closeDialog }) => {
            const dialogProps = reactive({
              withCloseBtn: closable,
              persistent: !closable,
            })

            const errorMessage = ref(null)
            const promocode = ref('')
            const isSubmitting = ref(false)

            return {
              title: 'Enter coupon code',
              content: {
                functional: true,
                render: h => [
                  h(
                    VAlert,
                    {
                      props: {
                        value: !!errorMessage.value,
                        color: 'red',
                        class: 'mx-auto  mb-3',
                        dense: true,
                        dark: true,
                        text: true,
                        dismissible: true,
                      },
                      on: {
                        input: v => {
                          if (v) {
                            return
                          }

                          errorMessage.value = null
                        },
                      },
                    },
                    [errorMessage.value]
                  ),
                  h('div', { class: 'mb-2' }, 'Coupon code'),
                  h(VTextField, {
                    props: {
                      placeholder: 'coupon here',
                      outlined: true,
                      value: promocode.value,
                      disabled: isSubmitting.value,
                    },
                    on: {
                      input: v => (promocode.value = v),
                    },
                  }),
                ],
              },
              actions: {
                functional: true,
                render: h => [
                  h(VSpacer),
                  h(
                    VBtn,
                    {
                      props: {
                        color: 'blue_400',
                        rounded: true,
                        disabled: !promocode.value?.length,
                        loading: isSubmitting.value,
                      },
                      on: {
                        click: async () => {
                          isSubmitting.value = true
                          errorMessage.value = null

                          try {
                            const token = await dispatch(
                              'user/getUserJwtToken',
                              null,
                              { root: true }
                            )

                            return await API.post(
                              'core',
                              '/upgradePlan/redeemAppSumoPromocode',
                              {
                                headers: { Authorization: token },
                                body: { promocode: promocode.value },
                              }
                            )
                              .then(() => {
                                resolve()
                                dispatch('fetchCurrentSubscription')
                                closeDialog()
                              })
                              .catch(e => {
                                if (
                                  !e.response?.data?.error ||
                                  !e.response?.data?.message
                                ) {
                                  errorMessage.value = 'Unhandled error!'
                                  return
                                }

                                errorMessage.value = e.response.data.message
                              })
                          } finally {
                            isSubmitting.value = false
                          }
                        },
                      },
                    },
                    'Activate'
                  ),
                ],
              },
              props: dialogProps,
            }
          })
        }),
      fetchCurrentSubscription: ({
        parentDispatch,
        dispatch,
        commit,
        toggleIsLoading,
      }) =>
        toggleIsLoading(
          (async () => {
            const token = await dispatch('user/getUserJwtToken', null, {
              root: true,
            })

            return API.get('core', '/upgradePlan/currentSubscription', {
              headers: { Authorization: token },
            }).then(currentSubscriptionDetails => {
              if (
                currentSubscriptionDetails.isAppSumoUser &&
                !currentSubscriptionDetails.appSumoPromocodeActivated
              ) {
                return dispatch('showAppSumoPromocodeDialog', {
                  closable: false,
                })
              }

              commit(
                'SET_CURRENT_SUBSCRIPTION',
                currentSubscriptionDetails.subscription
              )
              commit(
                'SET_PLAN_LIMITS_STATS',
                currentSubscriptionDetails.planLimitStats
              )
              commit(
                'SET_IS_APP_SUMO_USER',
                currentSubscriptionDetails.isAppSumoUser
              )
              commit(
                'SET_APP_SUMO_PROMOCODE_ACTIVATED',
                currentSubscriptionDetails.appSumoPromocodeActivated
              )
            })
          })(),
          'current_subscription_details'
        ).then(() =>
          parentDispatch(
            'checkout/selected_plan/setCheckBeforePriceInitialSelection',
            { key: 'subscription_fetched' }
          )
        ),
    },
    getters: {
      getCurrentSubscriptionPlan: ({ getters, parentGetters }) =>
        parentGetters.getPlansObject?.[
        getters.getCurrentSubscription?.productID
        ],
      getCurrentSubscriptionPrice: ({ getters, parentGetters }) =>
        parentGetters.getPricesObject?.[
        getters.getCurrentSubscription?.priceID
        ],
      getCurrentSubscriptionFeatures: ({ getters }) =>
        getters.getCurrentSubscriptionPlan?.features,
    },
  }
}
