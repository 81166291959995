var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-grow-1 d-flex flex-column"
  }, [_c('v-divider', {
    staticStyle: {
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "flex-grow-1 d-flex flex-column justify-center align-center"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "width": "135",
      "src": "/images/analytics_icons/NoResponses.svg",
      "draggable": false
    }
  }), _c('p', {
    staticClass: "title mb-3"
  }, [_vm._v("Let's collect some answers!")]), _c('p', {
    staticClass: "subtitle-2 grey_500--text font-weight-regular"
  }, [_vm._v("Try sharing this form with more people so we can begin analyzing the results")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }