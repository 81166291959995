import {API, graphqlOperation} from 'aws-amplify'
import moment from 'moment'


const getQueryName = gql => gql.match(/query[^{]+{\s*(?<queryName>\w+)[^{]+{/).groups.queryName
const getMutationName = gql => gql.match(/mutation[^{]+{\s*(?<mutationName>\w+)[^{]+{/).groups.mutationName
const processGqlOperation = async (graphql, variables, responseConverter) => {
    const {data: {[responseConverter(graphql)]: response}} = await API.graphql(graphqlOperation(graphql, variables))
    return response
}

export const convertTimestamps = entity => entity ? Object.fromEntries(
    Object.entries(entity)
        .map(([name, value]) => {
            if (['createdAt', 'updatedAt'].includes(name))
                return [name, moment.unix(value)]
            else
                return [name, value]
        })
) : entity

export const escapeRawData = rawData => JSON.stringify(JSON.stringify(rawData)).slice(1, -1).replace(/'/g, "\\'")
export const unescapeRawData = rawData => JSON.parse(rawData)

export async function executeGraphQLMutationCreate(graphql, input) {
    const response = await processGqlOperation(graphql, input, getMutationName)
    return convertTimestamps(response)
}

export async function executeGraphQLMutationUpdate(graphql, input) {
    const response = await processGqlOperation(graphql, input, getMutationName)
    return convertTimestamps(response)
}

export async function executeGraphQLMutationDelete(graphql, input) {
    const response = await processGqlOperation(graphql, input, getMutationName)
    return convertTimestamps(response)
}

export async function executeGraphQLQueryGet(graphql, input) {
    const response = await processGqlOperation(graphql, input, getQueryName)
    return convertTimestamps(response)
}

export async function executeGraphQLQueryList(graphql, input={}, listName, limit, nextTokens=[]) {
    const nextTokensCollection = Object.fromEntries(nextTokens.map(tokenName => [tokenName, null]))
    const data = []

    const executeQuery = () => new Promise((resolve, reject) => {
        processGqlOperation(graphql, {...input, ...(limit ? {limit} : {}), ...nextTokensCollection}, getQueryName)
            .then(response => {
                data.push(...((listName ? response[listName] : response).map(i => convertTimestamps(i))))
                 if (
                     nextTokens.length &&
                     nextTokens.every(tokenName => {
                        nextTokensCollection[tokenName] = response[tokenName]
                        return !!response[tokenName]
                    })
                 )
                     resolve(executeQuery())
                 else
                     resolve()
            })
            .catch(reject)
    })

    await executeQuery()
    return data
}