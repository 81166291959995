<template>
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="42" rx="10" fill="#0196E1"/>
    <path
        d="M16.5789 10.5C13.2217 10.5 10.5 13.2217 10.5 16.5789C10.5 19.9362 13.2217 22.6579 16.5789 22.6579C19.9362 22.6579 22.6579 19.9362 22.6579 16.5789C22.6579 13.2217 19.9362 10.5 16.5789 10.5Z"
        fill="white"/>
    <path
        d="M25.4217 19.3418C22.0645 19.3418 19.3428 22.0635 19.3428 25.4207C19.3428 28.778 22.0645 31.4997 25.4217 31.4997C28.779 31.4997 31.5007 28.778 31.5007 25.4207C31.5007 22.0635 28.779 19.3418 25.4217 19.3418Z"
        fill="white"/>
    <path
        d="M21.0001 11.0527C15.5064 11.0527 11.0527 15.5064 11.0527 21.0001C11.0527 26.4938 15.5064 30.9475 21.0001 30.9475C26.4938 30.9475 30.9475 26.4938 30.9475 21.0001C30.9475 15.5064 26.4938 11.0527 21.0001 11.0527Z"
        fill="white"/>
    <path
        d="M17.1146 25.0432C17.1146 24.4701 17.7165 24.1458 18.1271 24.1458C18.5377 24.1458 19.6833 24.9476 20.7813 24.9476C21.3638 24.9476 22.6719 24.8907 22.6719 23.6821C22.6719 21.7009 17.082 21.854 17.082 18.557C17.082 17.9834 17.2395 15.3545 21.3837 15.3545C22.4978 15.3545 24.5392 15.6734 24.5392 16.7322C24.5392 17.5092 24.0098 17.7098 23.655 17.7098C22.9676 17.7098 22.6907 17.0892 21.287 17.0892C19.3484 17.0892 19.3965 18.2508 19.3965 18.4199C19.3965 20.0872 24.9631 20.0872 24.9631 23.5345C24.9631 26.9283 20.8233 26.7475 20.2215 26.7475C19.6037 26.7475 17.1146 26.4469 17.1146 25.0432Z"
        fill="#0196E1"/>
  </svg>
</template>

<script>
export default {
  name: 'SkypeIcon',
}
</script>

<style scoped>

</style>