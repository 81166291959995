<template>
    <div class="static-form-elements-panel">
        <FormElementsPanelBlocksList/>
        <!--<v-tabs-->
                <!--v-model="tab"-->
                <!--grow-->
                <!--class="blue-tabs"-->
                <!--height="36"-->
                <!--hide-slider-->
        <!--&gt;-->
            <!--<v-tab :ripple="false">-->
                <!--<span>Blocks</span>-->
            <!--</v-tab>-->
            <!--<v-tab :ripple="false">-->
                <!--<span>Layout</span>-->
            <!--</v-tab>-->
            <!--<v-tab :ripple="false">-->
                <!--<span>Integrations</span>-->
            <!--</v-tab>-->
        <!--</v-tabs>-->
        <!--<v-tabs-items v-model="tab">-->
            <!--<v-tab-item>-->
                <!--<FormElementsPanelBlocksList/>-->
            <!--</v-tab-item>-->
            <!--<v-tab-item>-->
                <!--<FormElementsPanelLayout/>-->
            <!--</v-tab-item>-->
            <!--<v-tab-item>-->
                <!--<FormElementsPanelIntegrations/>-->
            <!--</v-tab-item>-->
        <!--</v-tabs-items>-->
    </div>
</template>

<script>
    export default {
        name: "StaticFormElementsPanel",
        components: {
            FormElementsPanelBlocksList: () => import('./Elements/ElementsPanel/FormElementsPanelBlocksList'),
            // FormElementsPanelLayout: () => import('./Elements/ElementsPanel/FormElementsPanelLayout'),
            // FormElementsPanelIntegrations: () => import('./Elements/ElementsPanel/FormElementsPanelIntegrations')
        },
        data: () => ({
            tab: 0
        })
    }
</script>
