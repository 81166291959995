var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('v-spacer'), _c('SummaryChangeSortOrder', {
    model: {
      value: _vm.sortOrder,
      callback: function ($$v) {
        _vm.sortOrder = $$v;
      },
      expression: "sortOrder"
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "opinion-scale-field-view-summary"
  }, [_c('div', {
    staticClass: "opinion-scale-field-view-summary__average-answer"
  }, [_c('span', [_vm._v("Average Answer:")]), _c('span', {
    staticClass: "opinion-scale-field-view-summary__average-value"
  }, [_vm._v(_vm._s(_vm.averageAnswer))])]), _c('div', {
    staticClass: "opinion-scale-field-view-summary__container"
  }, _vm._l(_vm.filteredOptions.shown, function (optionIndex) {
    return _c('div', {
      key: 'shown' + optionIndex,
      staticClass: "opinion-scale-field-view-summary__bar-wrapper"
    }, [_c('div', {
      staticClass: "opinion-scale-field-view-summary__responses-count"
    }, [_vm._v(_vm._s(_vm.optionsWithPercentage[optionIndex].count))]), _c('div', {
      staticClass: "opinion-scale-field-view-summary__percentage"
    }, [_vm._v(_vm._s(_vm.optionsWithPercentage[optionIndex].percentage) + "%")]), _c('VerticalProgressBar', {
      attrs: {
        "height": "138",
        "width": "50",
        "value": _vm.optionsWithPercentage[optionIndex].percentage
      }
    }), _c('div', {
      staticClass: "opinion-scale-field-view-summary__pole-number"
    }, [_vm._v(_vm._s(optionIndex))])], 1);
  }), 0), _vm.filteredOptions.hidden.length ? _c('div', [_c('SummaryHiddenAnswers', {
    attrs: {
      "hidden-count": _vm.filteredOptions.hidden.length
    }
  }, _vm._l(_vm.filteredOptions.hidden, function (poleNumber) {
    return _c('div', {
      key: 'hidden' + poleNumber,
      staticClass: "d-flex align-center my-2"
    }, [_c('span', [_vm._v(_vm._s(_vm.optionsWithPercentage[poleNumber].count) + " responses")]), _c('span', {
      staticClass: "mx-1"
    }, [_vm._v("·")]), _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(poleNumber))])]);
  }), 0)], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }