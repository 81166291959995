<template>
    <v-container class="checkout-choose-plan-page">
        <v-row v-if="getPlansIsLoading || !getPlanSelectionDone"><v-col><f-circle-loader size="5"/></v-col></v-row>
        <template v-else>
            <div class="checkout-page-heading checkout-page-heading--offset-lg">
                <div class="checkout-page-heading__title">
                    <span>Upgrade your plan, unleash your potential.</span>
                </div>
                <div class="checkout-page-heading__subtitle">
                    <span>Choose the best plan for your business.</span>
                </div>
            </div>

            <div class="checkout-choose-plan-page__billing-period-switcher-wrapper">
                <BillingPeriodSwitcher
                        :value="getSelectedBillingPeriod"
                        @input="switchSelectedOption(() => setSelectedBillingPeriod($event))"
                />
            </div>

            <div class="checkout-choose-plan-page__plan-selection-container">
                <div class="checkout-choose-plan-page__plans-gallery-wrapper">
                    <PlansGallery
                            :selected-product="getSelectedProduct"
                            :selected-price="getSelectedPrice"
                            @update:product="switchSelectedOption(() => setSelectedProduct($event))"
                            @update:price="switchSelectedOption(() => setSelectedPrice($event))"
                            is-disclaimer
                            :selected-billing-period="getSelectedBillingPeriod"
                            :switchSelectedOptionTo="switchSelectedOption"
                            @see-all-features="$vuetify.goTo($refs.plansFeaturesTable)"
                    />
                </div>
                <div class="checkout-choose-plan-page__selected-plan-amount-wrapper">
                    <SelectedPlanAmount>
                        <template #button="{ attrs }">
                            <v-btn
                                    v-bind="attrs"
                                    :disabled="!getPlanSelectionConfirmationAllowed"
                                    @click="confirmPlan"
                            >
                                Continue
                            </v-btn>
                        </template>
                        <template #hint="{ component, orderSummary, selectedPriceObj }">
                            <component :is="component">
                                Your plan is billed {{ selectedPriceObj?.recurring_interval + 'ly' }} and will renew for {{ orderSummary?.renewal?.price / 100 }} USD (plus any applicable taxes and minus any discounts) on {{ orderSummary?.renewal?.next_invoice?.timestamp && $options.moment.unix(orderSummary?.renewal?.next_invoice?.timestamp).format('MMM D, YYYY') }}.
                            </component>
                            <component :is="component">
                                You can cancel any time before this date.
                            </component>
                        </template>
                    </SelectedPlanAmount>
                </div>
            </div>

            <div class="checkout-choose-plan-page__plans-features-table-wrapper">
                <PlansFeaturesTable :selected-product="getSelectedProduct" ref="plansFeaturesTable"/>
            </div>
            <v-divider/>
            <div class="checkout-choose-plan-page__security-information">
                <div class="accepted-payment-methods">
                    <span>ACCEPTED PAYMENT METHODS</span>
                    <div class="accepted-payment-methods__brands">
                        <div class="brand" v-for="(brand, index) in stripeSupportedBrands"
                             :key="index">
                            <f-card-brand-icon
                                    :brand="brand"
                            />
                        </div>
                    </div>
                </div>
                <div class="secure-payment-wrapper">
                    <div class="secure-payment">
                        <SecurePaymentIcon/>
                        <div class="secure-payment-info">
                            <span>SSL SECURE PAYMENT</span>
                            <p>Your information is protected by 256-bit <br/> SSL encryption.</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from '@/xstore'
    import moment from 'moment'
    import {stripeSupportedBrands} from '@/components/UIKit/components/CardBrandIcon/CardBrandIcon'

    export default {
        name: "CheckoutChoosePlan",
        moment,
        components: {
            BillingPeriodSwitcher: () => import('@/components/Checkout/ChoosePlan/BillingPeriodSwitcher'),
            PlansGallery: () => import('@/components/Checkout/ChoosePlan/PlansGallery/PlansGallery'),
            SelectedPlanAmount: () => import('@/components/Checkout/ChoosePlan/SelectedPlanAmount'),
            PlansFeaturesTable: () => import('@/components/Checkout/ChoosePlan/PlansFeaturesTable'),
            SecurePaymentIcon: () => import('@/components/Elements/Icons/SecurePayment'),
        },
        setup(props, {root}) {
            const switchSelectedOption = fn => {
                if (!root.$store.getters['user/plan/checkout/getOrderSummaryIsLoading'])
                    fn()
            }

            return {
                switchSelectedOption,
                stripeSupportedBrands
            }
        },
        computed: {
            ...mapGetters('user/plan', [
                'getPlansIsLoading'
            ]),
            ...mapGetters('user/plan/checkout/selected_plan', [
                'getSelectedBillingPeriod',
                'getSelectedProduct',
                'getSelectedPrice',
                'getPlanSelectionDone'
            ]),
            ...mapGetters('user/plan/checkout/navigation', [
                'getPlanSelectionConfirmationAllowed'
            ])
        },
        methods: {
            ...mapActions('user/plan/checkout/selected_plan', [
                'setSelectedBillingPeriod',
                'setSelectedProduct',
                'setSelectedPrice'
            ]),
            ...mapActions('user/plan/checkout/navigation', [
                'confirmPlan'
            ])
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-choose-plan-page {
        padding-bottom: 100px;
        &__billing-period-switcher-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 28px;
        }

        &__plan-selection-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 36px;

            margin-bottom: 40px;
        }

        &__plans-gallery-wrapper {
            margin-top: 32px;
        }

        &__selected-plan-amount-wrapper {
            flex: 0 1 0;
        }

        .v-divider {
            margin: 60px 0 40px;
        }

        &__security-information {
            display: flex;
            column-gap: 100px;

            .accepted-payment-methods {
                span {
                    font-weight: 600;
                    font-size: 12px;
                    margin-bottom: 15px;
                    display: inline-block;
                }

                &__brands {
                    display: flex;
                    column-gap: 10px;

                    .brand {
                        padding: 5px 10px;
                        border: 1px solid var(--v-gray_200-base);
                        border-radius: 4px;
                        width: 55px;
                    }
                }
            }
            .secure-payment-wrapper {

                .secure-payment {
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    &-info {
                        font-size: 12px;
                        span {
                            font-weight: 600;
                        }
                        p {
                           color: var(--v-gray_500-base);
                        }
                    }
                }
            }

        }
    }
</style>