<template>
  <div :class="[
    'static-form',
    'static-form--editable',
    (getFormJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (getFormJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--full-screen visible-header',
    (getFormJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (getFormJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--full-screen invisible-header',
    (getFormJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (getFormJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--compact visible-header',
    (getFormJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (getFormJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--compact invisible-header',
  ]">
    <StaticFormComposition />
    <FormParameters />
    <div class="static-form__container">
      <v-hover v-slot="{ hover }">
        <div class="static-form__cover-wrapper">
          <div class="static-form__cover" :style="{ backgroundImage: 'url(' + getCurrentCoverImage + ')' }">
          </div>
          <div class="moveable-area h-full d-flex justify-center align-center text-h3">
            <FormCoverTextEditor selector=".moveable-area" :cover-hovered="hover" />
          </div>

          <div class="static-form__cover-container-for-elements-attached-to-page">
            <ImagePicker :initial-image="getInitialCoverImage" @preview-image="setCoverImagePreview"
              @cancel="setCoverImagePreview(undefined)" @select-image="setNewCoverImage" key="cover">
              <template #activator="{ on: imagePickerOn }">
                <f-hover-tooltip top text="Change cover" v-slot="{ on: tooltipOn }">
                  <v-btn class="cover-image-btn cover-image-btn--changer v-btn--primary" icon large
                    v-on="{ ...imagePickerOn, ...tooltipOn }">
                    <v-icon size="20px">
                      $image
                    </v-icon>
                  </v-btn>
                </f-hover-tooltip>
              </template>
              <template #title>
                Change cover image
              </template>
            </ImagePicker>
            <AddLogoBadgeButton :cover-hovered="hover" />
          </div>
        </div>
      </v-hover>

      <StaticPagesDisplayManager />

      <!--<FormSelectDeviceView/>-->

      <ConditionalLogicHandler />
    </div>
    <div class="static-form__footer">
      <StaticFormNavigation />
      <FormslyBadge v-if="getFormJSON.settings.general_settings.formsly_branding" :disableClick="true" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from '@/xstore';

export default {
  name: "StaticFormEditor",
  components: {
    StaticPagesDisplayManager: () => import('./StaticPages/StaticPagesDisplayManager'),
    StaticFormComposition: () => import('./StaticFormComposition/StaticFormComposition'),
    FormParameters: () => import('../FormParameters/FormParameters'),
    ImagePicker: () => import('@/components/Elements/ImagePicker/ImagePicker'),
    StaticFormNavigation: () => import('./StaticNavigation/StaticFormNavigation'),
    ConditionalLogicHandler: () => import('../ConditionalLogic/ConditionalLogicHandler'),
    FormCoverTextEditor: () => import('../MoveableLogic/FormCoverTextEditor'),
    AddLogoBadgeButton: () => import('./LogoBadge/AddLogoBadgeButton'),
    FormslyBadge: () => import('@/components/Elements/FormslyBadge'),
  },
  computed: {
    ...mapGetters('edit', [
      'getFormJSON'
    ]),
    ...mapGetters('edit/FSP', [
      'getStylePreferences'
    ]),
    ...mapGetters('edit/cover_image_preview', [
      'getCurrentCoverImage',
      'getInitialCoverImage'
    ])
  },
  methods: {
    ...mapActions('edit/cover_image_preview', {
      setCoverImagePreview: 'setCoverImagePreview',
      cancelCoverImagePreview: 'cancelPreview',
      setNewCoverImage: 'setNewCoverImage'
    }),
    goToHomePage() {
      this.$router.push({ name: this.constants.PAGE_NAME_DASHBOARD })
    }
  },
}
</script>

<style lang="scss" scoped>
.static-form--layout-style--compact.invisible-header {
        padding-top: 100px;
}
.v-responsive {
  overflow: visible;
}

.visible-header .static-form__container .static-form__cover-wrapper {
  display: block;
}

.invisible-header .static-form__container .static-form__cover-wrapper {
  display: none;
}

.static-form-page-main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1024px;

  .static-form-editor-content {
    height: 100%;
    box-shadow: 0px 8px 18px -6px rgb(24 39 75 / 12%), 0px 2px 4px rgb(24 39 75 / 8%);
    background-color: white;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
  }
}
</style>