<template>
    <div
            :class="[
                'formsly-form'
            ]"
            :data-form-width="dataAttributeFormWidth"
    >
        <slot/>
    </div>
</template>

<script>
    import { computed, ref, watch } from '@vue/composition-api'

    export default {
        name: "FormslyFormWrapper",
        props: {
            width: [Number, String]
        },
        setup(props, {root}) {
            const screenWidth = ref(0)

            if (props.width)
                watch(
                    () => props.width,
                    v => screenWidth.value = v,
                    {immediate: true}
                )
            else
                root.Utils.useResizeObserver(() => screenWidth.value = window.innerWidth).observe(() => document.body)

            const dataAttributeFormWidth = computed(() => Array(parseInt(screenWidth.value))
                .fill(null)
                .map((_, index) => index + 1)
                .join(' '))

            return {
                dataAttributeFormWidth
            }
        },
    }
</script>

<style lang="scss">
    .formsly-form {
        display: contents;
    }
</style>