var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "scroller",
    class: ['static-form', 'static-form--submittable', _vm.getFormSourceJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.getFormSourceJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--full-screen visible-header', _vm.getFormSourceJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.getFormSourceJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--full-screen invisible-header', _vm.getFormSourceJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.getFormSourceJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--compact visible-header', _vm.getFormSourceJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.getFormSourceJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--compact invisible-header']
  }, [_c('div', {
    staticClass: "static-form__container"
  }, [_c('div', {
    staticClass: "static-form__cover-wrapper"
  }, [_c('div', {
    staticClass: "static-form__cover",
    style: {
      backgroundImage: 'url(' + _vm.getFormSourceJSON.cover_image + ')'
    }
  }), _c('div', {
    staticClass: "moveable-area h-full d-flex justify-center align-center text-h3"
  }, [_c('span', {
    ref: "textContainer",
    staticClass: "text-container text-container--submittable",
    style: {
      transform: _vm.getFormSourceJSON.cover_text.translate,
      height: _vm.getFormSourceJSON.cover_text.height,
      width: _vm.getFormSourceJSON.cover_text.width
    },
    domProps: {
      "innerHTML": _vm._s(_vm.getFormSourceJSON.cover_text.content)
    }
  })])]), _c('StaticPagesDisplayManager', {
    on: {
      "page-changed": _vm.scrollToTop
    }
  })], 1), _c('div', {
    staticClass: "static-form__footer noselect"
  }), _vm.getFormSourceJSON.settings.general_settings.formsly_branding ? _c('FormslyBadge') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }