<template>
  <f-select-with-virtual-scroll
      :items="filteredItems"
      :item-value="itemValue"
      :placeholder="placeholder"

      :value="value"
      @input="$emit('input', $event)"

      hide-details

      v-on="$listeners"
      v-bind="merge(
               $attrs,
               {
                   listHeight: 250,
                   'menu-props': {
                       offsetY: true,
                       bottom: true,
                       nudgeBottom: 10,
                       maxHeight: 'auto',
                       contentClass: 'generic-submittable-dropdown__menu prevent-interactive-page-scrolling'
                   }
               }
            )"

      :item-height="50"

      class="generic-submittable-dropdown__select"
      :class="inputClass"

      attach
  >
    <template #prepend-item>
      <v-text-field
          class="generic-submittable-dropdown__search-field"
          :placeholder="searchPlaceholder"
          height="40"
          solo
          flat
          hide-details
          persistent-placeholder
          v-model="filterValue"
      >
        <template #prepend-inner>
          <SearchIcon class="generic-submittable-dropdown__search-field-icon"/>
        </template>
      </v-text-field>
    </template>
    <template #item="{on, attrs, item}">
      <slot name="item" :on="on" :attrs="attrs" :item="item" :component="$options.selectItemComponent"/>
    </template>
    <template #selection="{item}">
      <slot name="selection" :item="item"/>
    </template>
    <template #append>
      <ChevronDownIcon/>
    </template>
  </f-select-with-virtual-scroll>
</template>

<script>
    import { computed, ref } from '@vue/composition-api'
    import { VListItem, VListItemContent, VListItemIcon, VListItemTitle } from 'vuetify/lib/components'
    import { merge } from 'lodash'

    const selectItemComponent = {
        functional: true,
        render: (h, ctx) => h(
            VListItem,
            ctx.data,
            [
                ctx.scopedSlots.icon && h(VListItemIcon, null, [ctx.scopedSlots.icon()]),
                ctx.scopedSlots.content && h(VListItemContent, null, [h(VListItemTitle, null, ctx.scopedSlots.content())]),
            ]
        )
    }

    export default {
        name: "SubmittableFieldGenericDropdown",
        selectItemComponent,
        inheritAttrs: false,
        components: {
            SearchIcon: () => import('vue-feather-icons/icons/SearchIcon'),
            ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon')
        },
        props: {
            placeholder: String,
            value: [Number, String],
            inputClass: String,
            items: {
                type: Array,
                required: true
            },
            itemValue: {
                type: Function,
                required: true
            },
            filter: {
                type: Function,
                required: true
            },
            searchPlaceholder: {
                type: String,
                default: 'Search'
            }
        },
        setup(props) {
            // FILTERING
            const filterValue = ref('')
            const filteredItems = computed(() => props.items.filter(item => props.filter(item, filterValue.value)))


            return {
                filterValue,
                filteredItems,

                merge
            }
        }
    }
</script>

<style scoped lang="scss">
  ::v-deep .generic-submittable-dropdown__menu {
    z-index: 10 !important;
  }
  .generic-submittable-dropdown__search-field-icon {
    color: var(--v-gray_300-base);
  }
</style>