import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
const theme = {
  primary: '#3D66F6',
  secondary: '#F3F4F6',
  error: '#F53131',

  grayscale_black: '#262C2E',
  gray_3: '#737E81',
  gray_1: '#E5E7E8',
  grey_900: '#111828',
  secondary_dark_grey_900: '#1B2559',
  gray_900: '#101828',
  gray_700: '#344054',
  grey_700: '#6B7280',
  grey_500: '#9CA2AE',
  grey_500_2: '#667085',
  grey_300: '#A6A6A6',
  grey_200: '#F3F4F6',
  grey_100: '#F2F4F7',
  grey: '#19181A',
  pale: '#F2F5FF',
  paler: '#F9FAFF',
  paler_2: '#F9FAFB',
  borders: '#D0D5DD',

  form_background: '#FAFBFE',

  dark_gray: '#393F41',

  gray_500: '#5D6471',
  gray_400: '#8A8F99',
  gray_300: '#CED2D8',
  gray_200: '#E5EAED',
  gray_100: '#F5F6F8',

  orange_500: '#D8790F',
  orange_400: '#FF8600',
  orange_300: '#FFB15C',
  orange_200: '#FFDDB7',
  orange_100: '#FFF3E5',

  green_500: '#057A55',
  green_400: '#15B57F',
  green_300: '#84E1BC',
  green_200: '#BCF0DA',
  green_100: '#F3FAF7',

  red_500: '#BE0D0D',
  red_400: '#F53131',
  red_300: '#FF7B7B',
  red_200: '#FFAEAE',
  red_100_2: '#FFF1F1',
  red_100: '#FFE7E7',

  blue_500: '#1E429F',
  blue_400: '#3D66F6',
  blue_300: '#76A9FA',
  blue_200: '#C3DDFD',
  blue_100: '#F2F5FF',

  bluish_gray_500: '#101A26',
  bluish_gray_400: '#18273A',
  bluish_gray_300: '#617DA0',
  bluish_gray_200: '#94B0D3',
  bluish_gray_100: '#E2EFFF',
}

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
      // example use - color: var(--v-primary-base)
    },
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  icons: {
    values: {
      tablet: {
        component: () => import('@/components/Elements/Icons/Tablet'),
      },
      desktop: {
        component: () => import('@/components/Elements/Icons/Desktop'),
      },
      image: {
        component: () => import('@/components/Elements/Icons/ImageIcon'),
      },
      add_image: {
        component: () => import('@/components/Elements/Icons/AddImage'),
      },
      feather: {
        component: () => import('@/components/Elements/Icons/Feather'),
      },
      sort_default_order: {
        component: () => import('@/components/Elements/Icons/SortDefaultOrder'),
      },
      sort_descending_order: {
        component: () =>
          import('@/components/Elements/Icons/SortDescendingOrder'),
      },
      create_link: {
        component: () => import('@/components/Elements/Icons/CreateLink'),
      },
      format_bold: {
        component: () => import('@/components/Elements/Icons/FormatBold'),
      },
      format_italic: {
        component: () => import('@/components/Elements/Icons/FormatItalic'),
      },
      format_underline: {
        component: () => import('@/components/Elements/Icons/FormatUnderline'),
      },
      text_align_left: {
        component: () => import('@/components/Elements/Icons/TextAlignLeft'),
      },
      text_align_right: {
        component: () => import('@/components/Elements/Icons/TextAlignRight'),
      },
      text_align_center: {
        component: () => import('@/components/Elements/Icons/TextAlignCenter'),
      },
      load_circle: {
        component: () => import('@/components/Elements/Icons/LoadCircleIcon'),
      },
      telegram: {
        component: () => import('@/components/Elements/Icons/Telegram'),
      },
      linkedin: {
        component: () => import('@/components/Elements/Icons/Linkedin'),
      },
      google_sheets: {
        component: () => import('@/components/Elements/Icons/GoogleSheets'),
      },
      zapier: {
        component: () => import('@/components/Elements/Icons/Zapier'),
      },
      airtable: {
        component: () => import('@/components/Elements/Icons/Airtable'),
      },
      notion: {
        component: () => import('@/components/Elements/Icons/Notion'),
      },
      instagram: {
        component: () => import('@/components/Elements/Icons/Instagram'),
      },
      skype: { component: () => import('@/components/Elements/Icons/Skype') },
      whatsapp: {
        component: () => import('@/components/Elements/Icons/WhatsApp'),
      },
      whatsapp_circle: {
        component: () => import('@/components/Elements/Icons/WhatsAppCircle'),
      },
      twitter: {
        component: () => import('@/components/Elements/Icons/Twitter'),
      },
      facebook: {
        component: () => import('@/components/Elements/Icons/Facebook'),
      },
      stars: {
        component: () => import('@/components/Elements/Icons/Stars'),
      },
    },
  },
  breakpoint: {
    thresholds: {
      sm: 1024,
      md: 1440,
      lg: 1920,
    },
  },
})

export default vuetify

export const checkIsVuetifyColorExist = color =>
  Object.keys(vuetify.userPreset.theme.themes.light).includes(color)

