var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-form__page-wrapper"
  }, [_c('LogoBadgeEditor'), _c('div', {
    staticClass: "static-form__page"
  }, [_vm.getCurrentPage.type === _vm.constants.FORM_PAGES_TYPES.MAIN_PAGE ? _c('StaticMainPage', {
    key: _vm.getCurrentPageId,
    attrs: {
      "page-id": _vm.getCurrentPageId
    }
  }) : _vm.getCurrentPage.type === _vm.constants.FORM_PAGES_TYPES.FINISH_PAGE ? _c('StaticFinishPage') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }