var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-main-page"
  }, [_c('div', {
    staticClass: "static-main-page__content"
  }, [_c('StaticMainPageFieldsLayout')], 1), _c('StaticMainPageSubmitButtonComponent', {
    on: {
      "validate": _vm.validatePage
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }