import { GenericTextFieldConst } from '@/components/Fields/GenericFieldViews/GenericTextField'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyMultipleStringsInput,
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {
    GenericStringConditionalLogic,
} from '@/components/Fields/FieldConstClasses/conditional_logic'
import { GenericTextAnalytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import { email, helpers } from '@vuelidate/validators'

class EmailFieldTypeConst extends GenericTextFieldConst {
    constructor() {
        super({
            FIELD_TYPE: 'EMAIL',
            READABLE_NAME: 'Email',
            ICON_NAME: 'email',
            FOLDER_NAME: 'Email',

            CONDITIONAL_LOGIC: new GenericStringConditionalLogic(),

            ANALYTICS: new GenericTextAnalytics(),

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: {
                        ...FieldSubmitValidation.REQUIRED(fieldObj),
                        ...(fieldObj.properties.email_validation && {email}),
                        ...(fieldObj.properties.work_email_only && {
                            work_email_only: helpers
                                .withMessage(
                                    `This domain not allowed. Allowed domains: ${fieldObj.properties.work_email_only.join(', ')}`,
                                    helpers.regex(new RegExp(`^\\w+([.-]?\\w+)*(?:${fieldObj.properties.work_email_only.length ? fieldObj.properties.work_email_only.join('|') : '.*'})$`))
                                )
                        })
                    }
                })
            }),
            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: 'name@example.com',
                }
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'email_validation',
                    name: 'Email validation',
                    tooltip: 'Verify if an email address is deliverable and valid'
                }),
                new CustomFieldPropertyConst({
                    key: 'work_email_only',
                    name: 'Allow these domains only',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyMultipleStringsInput({
                            placeholder: 'Add email domains (e.g.@gmail.com)',
                            validator: value => value.every(item => /^@\w+\.\w+/.test(item))
                        })
                    )
                })
            ])
        })
    }
}

export default new EmailFieldTypeConst()