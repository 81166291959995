var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "style-preferences-theme-editor-radius-style"
  }, [_c('div', {
    staticClass: "style-preferences-theme-editor-radius-style__layout"
  }, _vm._l(_vm.allowedRadii, function (radius) {
    return _c('div', {
      key: radius,
      staticClass: "style-preferences-theme-editor-radius-style__option-wrapper",
      on: {
        "click": function ($event) {
          return _vm.$emit('input', radius);
        }
      }
    }, [_c('div', {
      class: ['style-preferences-theme-editor-radius-style__option', radius === _vm.value && 'style-preferences-theme-editor-radius-style__option--active']
    }, [_c('div', {
      staticClass: "style-preferences-theme-editor-radius-style__option-content",
      style: {
        borderRadius: radius + 'px'
      }
    })])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }