var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "file-upload-field-view"
  }, [_c('div', {
    staticClass: "file-upload-field-view__wrapper"
  }, [_c('div', {
    staticClass: "file-upload-field-view__layout-area"
  }, [_vm._t("default"), _vm.showFileUploader ? _c('FileUploadArea', {
    staticClass: "file-upload-field-view__file-input",
    attrs: {
      "disabled": _vm.disabled,
      "max-files-count": 1,
      "max-file-size": _vm.limitFileSize,
      "matchBothMimeAndExtension": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('span', {
          staticClass: "file-upload-field-view__file-input__header file-upload-field-view__file-input__header--desktop"
        }, [_vm._v("Choose File(s) or Drag here")]), _c('span', {
          staticClass: "file-upload-field-view__file-input__header file-upload-field-view__file-input__header--mobile"
        }, [_vm._v("Choose File(s)")])];
      },
      proxy: true
    }, {
      key: "subheader",
      fn: function () {
        return [_c('span', {
          staticClass: "file-upload-field-view__file-input__subheader file-upload-field-view__file-input__subheader--desktop"
        }, [_vm._v("Upload or drag your file. Up to 1 file, max " + _vm._s(_vm.limitFileSize) + "mb")]), _c('span', {
          staticClass: "file-upload-field-view__file-input__subheader file-upload-field-view__file-input__subheader--mobile"
        }, [_vm._v("Upload your file. Up to 1 file, max " + _vm._s(_vm.limitFileSize) + "mb")])];
      },
      proxy: true
    }], null, false, 1511020119)
  }) : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }