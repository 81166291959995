<script>
  import { XIcon } from 'vue-feather-icons'

  export default {
    name: 'ImagePicker',
    components: {
      UnsplashBrowser: () => import('./Elements/UnsplashBrowser'),
      // PexelsBrowser: () => import('./Elements/PexelsBrowser'),
      FormslyBrowser: () => import('./Elements/FormslyBrowser'),
      UploadYoursBrowser: () => import('./Elements/UploadYoursBrowser'),
      XIcon,
    },
    props: {
      initialImage: String,
    },
    data: () => ({
      dialog: false,
      tab: 0,
      activeImage: '',
      confirmationDialog: false,
      unsplashImageDownloadUrl: null,
    }),
    methods: {
      openDialog() {
        this.dialog = true
      },
      openConfirmationDialog() {
        if (this.activeImage && this.activeImage !== this.initialImage) {
          this.confirmationDialog = true
        } else this.cancelCover()
      },
      closeDialog() {
        this.dialog = false
      },
      clearImage() {
        this.activeImage = ''
      },
      closeConfirmationDialog() {
        this.confirmationDialog = false
      },
      dialogModelUpdated(v) {
        if (!v) this.cancelCover()
      },
      setImagePreview(v) {
        this.activeImage = v
        this.$emit('preview-image', v)
        this.unsplashImageDownloadUrl = null
      },
      activeUrlReplaced(v) {
        this.activeImage = v
        this.$emit('select-image:active-url-replaced', v)
      },
      forceSetImage(v) {
        this.$emit('select-image', v)
      },
      selectCover() {
        this.forceSetImage(this.activeImage)
        this.tryToSendUnsplashDownloadApi()
        this.closeConfirmationDialog()
        this.closeDialog()
      },
      async tryToSendUnsplashDownloadApi() {
        if (this.unsplashImageDownloadUrl) {
          await fetch(this.unsplashImageDownloadUrl, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization:
                'Client-ID SrjAzCE4ATik3Ap2Uz76LJ9VhYyAkd7WLAii61gtVWA',
            },
          })
        }
      },
      cancelCover() {
        this.$emit('cancel')
        this.closeConfirmationDialog()
        this.closeDialog()
      },
    },
  }
</script>

<template>
  <div>
    <v-fab-transition hide-on-leave>
      <slot
        name="activator"
        :on="{ click: openDialog }"
      />
    </v-fab-transition>
    <slot
      name="remove-btn"
      :on="{ click: clearImage }"
    ></slot>
    <v-dialog
      v-model="dialog"
      content-class="overflow-y-hidden"
      transition="slide-y-transition"
      width="686"
      @input="dialogModelUpdated"
    >
      <v-card>
        <v-card-title>
          <span><slot name="title">Change Image</slot></span>
          <v-spacer></v-spacer>
          <XIcon
            class="cursor-pointer gray_400--text"
            stroke-width="2.2"
            @click="openConfirmationDialog"
          />
        </v-card-title>
        <v-container class="pa-10">
          <div>
            <v-tabs
              v-model="tab"
              class="blue-tabs"
              height="36"
              hide-slider
            >
              <v-tab
                class="mr-1"
                :ripple="false"
              >
                <span>Formsly</span>
              </v-tab>
              <!--                            <v-tab class="mx-1" :ripple="false">-->
              <!--                                <span>Pexels</span>-->
              <!--                            </v-tab>-->
              <v-tab
                class="mx-1"
                :ripple="false"
              >
                <span>Unsplash</span>
              </v-tab>
              <v-tab
                class="ml-1"
                :ripple="false"
              >
                <span>Upload</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              class="overflow-visible"
            >
              <FormslyBrowser
                ref="formsly"
                :value="0"
                :active-image="activeImage"
                @set-active-image="setImagePreview"
              />
              <!--                            <PexelsBrowser-->
              <!--                                    :value="1"-->
              <!--                                    ref="pexels"-->
              <!--                                    :active-image="activeImage"-->
              <!--                                    @set-active-image="handleImagePreview"-->
              <!--                            />-->
              <UnsplashBrowser
                ref="unsplash"
                :value="1"
                :active-image="activeImage"
                @set-active-image="setImagePreview"
                @set-download-url="v => (unsplashImageDownloadUrl = v)"
              />
              <UploadYoursBrowser
                ref="uploadYours"
                :value="2"
                :active-image="activeImage"
                @set-active-image="setImagePreview"
                @set-active-image:active-url-replaced="activeUrlReplaced"
              />
            </v-tabs-items>
            <div
              class="cover-image-select-footer"
              :class="activeImage && 'cover-image-select-footer--active'"
            >
              <v-spacer />
              <v-btn
                color="gray_100 mr-2"
                rounded
                @click="cancelCover"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue_400"
                rounded
                @click="selectCover"
              >
                Select
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <f-dialog
      v-model="confirmationDialog"
      width="690"
    >
      <template #title>Set selected image</template>
      <template #content>
        Please confirm if the selected image should be set.
      </template>
      <template #actions>
        <v-spacer />
        <v-btn
          color="gray_100"
          rounded
          @click="cancelCover"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue_400"
          rounded
          @click="selectCover"
        >
          Confirm
        </v-btn>
      </template>
    </f-dialog>
  </div>
</template>

<style lang="scss">
  .cover-image-btn {
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    background: white;
    position: absolute !important;
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 2px 4px rgba(24, 39, 75, 0.19);
    stroke-width: 0;
    z-index: 1;
    bottom: 16px;

    &--changer {
      right: 16px;
    }
    &--remover {
      left: 16px;
    }
  }

  .cover-image-select-footer {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    align-items: center;
    padding: 40px;

    -webkit-box-shadow: -1px 0px 41px -14px rgba(64, 64, 64, 0.26);
    -moz-box-shadow: -1px 0px 41px -14px rgba(64, 64, 64, 0.26);
    box-shadow: -1px 0px 41px -14px rgba(64, 64, 64, 0.26);

    transform: translateY(200%);
    transition: transform 150ms ease-in-out;

    &--active {
      transform: translateY(0);
    }
  }

  .result-image {
    cursor: pointer;
    border: 3px solid white;
    position: relative;
    transition: box-shadow 0.2s;

    &.active {
      box-shadow: 0 0 0 3px var(--v-blue_400-base);
    }

    &:hover {
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 3px var(--v-blue_400-base);
    }

    .v-responsive__content {
      position: relative;
    }
  }
</style>
