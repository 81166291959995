var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-dialog', {
    attrs: {
      "width": "714"
    },
    model: {
      value: _vm.conditionalLogicEditorDialog,
      callback: function ($$v) {
        _vm.conditionalLogicEditorDialog = $$v;
      },
      expression: "conditionalLogicEditorDialog"
    }
  }, [_vm.conditionalLogicEditorDialog ? _c('ConditionalLogicEditor', {
    on: {
      "close-dialog": function ($event) {
        _vm.conditionalLogicEditorDialog = false;
      }
    }
  }) : _vm._e()], 1), _c('GenericStoreDialog', {
    attrs: {
      "store-module-path": "edit/conditional/confirmation_dialog"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }