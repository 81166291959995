var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.9864 17.5104L14.2949 6.68191C14.4794 5.78941 14.6639 5.61691 16.3154 5.47141L16.4804 4.69141H10.8164L10.6514 5.47141C12.2414 5.61541 12.3434 5.78941 12.1589 6.68191L9.8549 17.5104C9.6704 18.4029 9.4859 18.5754 7.8359 18.7194L7.6709 19.4994H13.3319L13.4969 18.7194C11.9069 18.5769 11.8034 18.4029 11.9879 17.5104H11.9864Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }