var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "f-badge f-badge--rounded",
    class: [_vm.green && 'f-badge--success', _vm.gray && 'f-badge--secondary', _vm.purple && 'f-badge--warn', _vm.white && 'f-badge--white']
  }, _vm.$listeners), [_vm.dot ? _c('div', {
    staticClass: "f-badge__dot"
  }) : _vm._e(), _c('span', [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }