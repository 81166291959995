var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "no-forms h-full",
    attrs: {
      "fluid": ""
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/NoFormsToShow.svg",
      "alt": ""
    }
  }), _c('h2', {
    staticClass: "no-forms__header"
  }, [_vm._v("Create your first form?")]), _c('div', {
    staticClass: "no-forms__description"
  }, [_c('p', [_vm._v("Choose from any of our templates or start one from scratch!")])]), _c('v-btn', {
    attrs: {
      "to": _vm.formCreationPage,
      "rounded": "",
      "color": "blue_400",
      "depressed": "",
      "dark": ""
    }
  }, [_c('PlusIcon', {
    staticClass: "v-btn__prepend-icon"
  }), _vm._v(" Create first form ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }