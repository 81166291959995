var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-country-flag"
  }, [_c('Flag', _vm._b({
    staticClass: "f-country-flag__flag"
  }, 'Flag', _vm.bind, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }