var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "bottom": "",
      "close-on-content-click": false,
      "open-on-click": false,
      "open-on-hover": "",
      "content-class": "app-bar-editor-form__popup",
      "max-width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('f-template-data-transfer', {
          attrs: {
            "publishing": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var dataStash = _ref2.dataStash,
                promiseProcessor = _ref2.promiseProcessor;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "width": "105",
                  "color": "blue_400",
                  "dark": "",
                  "rounded": "",
                  "loading": dataStash.publishing
                },
                on: {
                  "click": function ($event) {
                    _vm.getIsFormPublicationAllowedWithUsedPaidFeatures && promiseProcessor(_vm.publishForm()).then(function () {
                      return _vm.$emit('published');
                    });
                  }
                }
              }, _vm.getIsFormPublicationAllowedWithUsedPaidFeatures ? {} : on), [_vm._v(" Publish ")])];
            }
          }], null, true)
        })];
      }
    }])
  }, [[_c('v-card', {
    staticClass: "app-bar-editor-form-popup__card"
  }, [_c('v-card-title', [_c('div', [_vm._v("There are features in this form that aren't available in your current plan")])]), _c('v-card-text', [_c('div', {
    staticClass: "app-bar-editor-form-popup__card-unpaid-features-section"
  }, [_c('div', {
    staticClass: "app-bar-editor-form-popup__card-unpaid-features-section-title"
  }, [_vm._v("Here is the list of "), _c('span', [_vm._v("Paid")]), _vm._v(" features you are using in this form: ")]), _c('div', {
    staticClass: "app-bar-editor-form-popup__card-unpaid-features-section-list unpaid-features-list"
  }, _vm._l(_vm.unpaidFeatures, function (feature, index) {
    return _c('div', {
      key: index,
      staticClass: "unpaid-features-list__item"
    }, [_c('StarIcon', {
      attrs: {
        "size": "14"
      }
    }), _c('span', [_vm._v(_vm._s(feature.text))])], 1);
  }), 0)]), _c('v-divider'), _c('div', {
    staticClass: "app-bar-editor-form-popup__card-offer"
  }, [_vm._v(" To publish this form, you may need to upgrade to a paid plan to get the most of this form, or you may wish to remove paid features ")])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "width": "100%",
      "rounded": "",
      "dark": ""
    },
    on: {
      "click": _vm.goToCheckout
    }
  }, [_vm._v(" Upgrade my plan ")])], 1)], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }