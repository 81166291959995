import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            hovered_fields_constructors: {}
        }

        useExtension(SMAGableExtensions.SMable, {state})
    }

    return {
        actions: {
            fieldConstructorHoverIn: ({commit}, id) => commit('SET_HOVERED_FIELDS_CONSTRUCTORS', {[id]: true}),
            fieldConstructorHoverOut: ({commit}, id) => commit('SET_HOVERED_FIELDS_CONSTRUCTORS', {[id]: false}),
        },
        getters: {
            getCurrentHoveredFieldConstructorID: ({state}) => {
                for (let [fieldID, isHovered] of Object.entries(state.hovered_fields_constructors))
                    if (isHovered)
                        return fieldID
                return undefined
            }
        }
    }
}