<template>
    <v-combobox
            v-model="localValue"
            @input="handleInput"
            multiple
            deletable-chips
            :placeholder="inputConst.placeholder"
            :label="label"
            class="rounded-lg pa-0"
            append-icon=""
            outlined
            dense
            hide-details
    >
        <template v-slot:selection="{ item, parent }">
            <v-chip
                    class="my-1"
                    :key="JSON.stringify(item)"
                    label
            >
                {{ item }}
                <XIcon class="cursor-pointer ml-3" size="16" @click="parent.selectItem(item)"/>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'
    import {XIcon} from 'vue-feather-icons'

    export default {
        name: "MultipleStrings",

        VALUE_VALIDATOR: value => value.length > 0 ? value : null,
        components: {
            XIcon
        },

        mixins: [field_property_input_mixin],

        data: () => ({
            localValue: []
        }),

        created() {
            this.localValue = this.inputValue
        },

        methods: {
            handleInput() {
                this.$nextTick(() => {
                    if (this.validateInputValue(this.localValue))
                        this.input(this.localValue)
                    else
                        this.localValue = this.inputValue
                })
            }
        }
    }
</script>

<style scoped>

</style>