var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#E9F5F8"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 18.4166C12.2398 17.7682 11.5352 17.0574 10.8935 16.2915C9.93053 15.1413 8.78701 13.4285 8.78701 11.7963C8.78615 10.0915 9.81271 8.55431 11.3877 7.90191C12.9626 7.2495 14.7755 7.61052 15.9803 8.81648C16.7727 9.60528 17.2165 10.6782 17.213 11.7963C17.213 13.4285 16.0694 15.1413 15.1065 16.2915C14.4648 17.0574 13.7601 17.7682 13 18.4166ZM13 8.78699C11.3388 8.78898 9.9927 10.1351 9.99071 11.7963C9.99071 12.498 10.3079 13.7132 11.8173 15.5193C12.1894 15.9635 12.5841 16.3882 13 16.7916C13.4159 16.3887 13.8108 15.9646 14.1832 15.5211C15.6921 13.7125 16.0092 12.4974 16.0092 11.7963C16.0072 10.1351 14.6611 8.78898 13 8.78699ZM13 13.6018C12.0028 13.6018 11.1944 12.7934 11.1944 11.7963C11.1944 10.7991 12.0028 9.9907 13 9.9907C13.9972 9.9907 14.8055 10.7991 14.8055 11.7963C14.8055 12.2751 14.6153 12.7344 14.2767 13.073C13.9381 13.4116 13.4788 13.6018 13 13.6018Z",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }