import OwnersFormActions from '@/utils/actions/owners_form_actions'

export default () => {
    return {
        actions: {
            publishForm: ({parentGetters, parentCommit}) => OwnersFormActions.publishForm(parentGetters.getFormJSON)
                .then(formDbData => {
                    parentCommit('SET_FORM_DB_DATA_INITIAL', formDbData)
                    parentCommit('SET_FORM_JSON_INITIAL', parentGetters.getFormJSON)
                    parentCommit('SET_FORM_VERSION_ID', formDbData.publishedVersionID)
                })
        },
        getters: {
            getIsCurrentFormVersionPublished: ({parentGetters}) => parentGetters.getFormVersionId === parentGetters.getFormDBData?.publishedVersionID,
            isPublishedFormWasChanged: ({getters, parentGetters}) => !getters.getIsCurrentFormVersionPublished || parentGetters.isFormJsonChangedAfterSaving
        }
    }
}