import Vue from 'vue'
import store from '@/store/index'

const promisesWithAnimationEventBus = new Vue()

export function promiseWithAnimation(promise) {
    return new Promise((resolve) => {
        // to avoid interrupting the loading by other function calls
        var needToStop = true
        store.dispatch('startLoading')
        promisesWithAnimationEventBus.$emit('loadingStarted')
        promisesWithAnimationEventBus.$on('loadingStarted', () => needToStop = false)
        promise
            .finally(() => {
                if (needToStop)
                    store.dispatch('stopLoading')
                resolve()
            })
    })
}