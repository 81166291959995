var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveFinishPageTemplate', {
    scopedSlots: _vm._u([{
      key: "emoji",
      fn: function () {
        return [_vm._v(" 👍 ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_c('f-simple-contenteditable-input', {
          attrs: {
            "oveflow-wrap": "",
            "prevent-highlighting": "",
            "multiline": "",
            "placeholder": "Title"
          },
          model: {
            value: _vm.finishTitle,
            callback: function ($$v) {
              _vm.finishTitle = $$v;
            },
            expression: "finishTitle"
          }
        })];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_c('f-simple-contenteditable-input', {
          attrs: {
            "oveflow-wrap": "",
            "prevent-highlighting": "",
            "multiline": "",
            "placeholder": "Additional description"
          },
          model: {
            value: _vm.finishDescription,
            callback: function ($$v) {
              _vm.finishDescription = $$v;
            },
            expression: "finishDescription"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }