<template>
    <div v-if="option" class="dropdown-field-view-single-answer">
        <component
                v-if="isReadyList"
                :is="currentReadyList.ICON_COMPONENT.NAME"
                class="mr-2"
                v-bind="currentReadyList.ICON_COMPONENT.BIND(option)"
        />

        {{option.name ? option.name : option}}
    </div>
    <span v-else>-</span>
</template>

<script>
    import {isString} from 'lodash'
    import Dropdown from '@/components/Fields/FieldsViews/Dropdown'

    export default {
        name: "GenericSingleAnswer__Dropdown",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
        computed: {
            isReadyList() {
                return isString(this.sourceObj.content.options)
            },
            currentReadyList() {
                if (this.isReadyList)
                    return Dropdown.UTIL.READY_LISTS[this.sourceObj.content.options]
                return undefined
            },
            option() {
                if (this.submissionObj.value.length) {
                    if (this.isReadyList)
                        return this.currentReadyList.LIST[this.submissionObj.value[0]]
                    return this.sourceObj.content.options[this.submissionObj.value[0]]
                }

                return undefined
            }
        }
    }
</script>