import constants from '@/constants/constants'
import store from '@/store'
import {
  executeGraphQLMutationCreate,
  executeGraphQLMutationDelete,
  executeGraphQLMutationUpdate,
  executeGraphQLQueryGet,
} from '@/utils/graphql_executors'
import { generateShortId } from '@/utils/misc'
import {
  createForm as createFormMutation,
  deleteForm as deleteFormMutation,
  getForm,
  updateForm as updateFormMutation,
} from '@formsly/shared/api/graphql'

import StorageActions from './storage_actions'

const uploadFormUtil = async (formJSON, formFilePath) =>
  StorageActions.putProtectedObject({
    file: JSON.stringify(formJSON),
    filePath: formFilePath,
  })

const updateFormDBData = ({
  id,
  name,
  path,
  previewImage,
  publishedVersionID,
  ownerID,
  FSPThemeID,
}) =>
  executeGraphQLMutationUpdate(updateFormMutation, {
    updateFormInput: {
      id,
      name,
      path,
      previewImage,
      publishedVersionID,
      ownerID,
      FSPThemeID,
    },
  })

const asyncCreateForm = formType =>
  new Promise((resolve, reject) => {
    const formID = generateShortId()
    const formFilePath = constants.GET_S3_FORM_FILE_PATH(formID)
    const formBaseJSON = constants.FORM_FROM_TYPE[formType].TEMPLATE(formID)

    uploadFormUtil(formBaseJSON, formFilePath)
      .then(({ fullFolderPath }) =>
        executeGraphQLMutationCreate(createFormMutation, {
          createFormInput: {
            id: formID,
            name: '',
            publishedVersionID: '',
            path: fullFolderPath,
            ownerID: store.getters['user/getCurrentCognitoUser'].username,
            FSPThemeID: 'default_theme',
          },
        })
      )
      .then(() => resolve(formBaseJSON))
      .catch(reject)
  })

const syncCreateForm = async ({
  formType,
  formJson,
  formID = formJson ? formJson.id : generateShortId(),
  FSPThemeID = 'default_theme',
}) => {
  const formFilePath = constants.GET_S3_FORM_FILE_PATH(formID)
  const formBaseJSON =
    formJson || constants.FORM_FROM_TYPE[formType].TEMPLATE(formID)

  const credentials = await store.dispatch('user/getAuthCredentials')

  const dynamoDBInput = {
    id: formID,
    name: '',
    publishedVersionID: '',
    path: `users/${credentials.identityId}/protected/forms/${formID}/`, // hardcoded #TODO replace
    ownerID: store.getters['user/getCurrentCognitoUser'].username,
    FSPThemeID,
  }

  uploadFormUtil(formBaseJSON, formFilePath)
  await executeGraphQLMutationCreate(createFormMutation, {
    createFormInput: dynamoDBInput,
  })

  return { formData: dynamoDBInput, formJson: formBaseJSON }
}

const saveFormJSON = formJson =>
  uploadFormUtil(formJson, constants.GET_S3_FORM_FILE_PATH(formJson.id))

const publishForm = formJson =>
  uploadFormUtil(formJson, constants.GET_S3_FORM_FILE_PATH(formJson.id)).then(
    ({ response: { VersionId } }) =>
      updateFormDBData({
        id: formJson.id,
        publishedVersionID: VersionId,
      })
  )

const deleteForm = formID =>
  new Promise((resolve, reject) => {
    Promise.all([
      StorageActions.deleteProtectedFolder(
        constants.GET_S3_FORM_FOLDER_PATH(formID)
      ),
      executeGraphQLMutationDelete(deleteFormMutation, {
        id: formID,
      }),
    ])
      .then(resolve)
      .catch(reject)
  })

const fetchFormData = formID =>
  new Promise((resolve, reject) => {
    executeGraphQLQueryGet(getForm, { id: formID }).then(resolve).catch(reject)
  })

const fetchFormJson = (formFolderPath, versionID) =>
  new Promise((resolve, reject) => {
    StorageActions.getJSONByS3Path(
      formFolderPath + constants.S3_FORM_FILE_NAME,
      versionID
    )
      .then(resolve)
      .catch(reject)
  })

export default {
  asyncCreateForm,
  syncCreateForm,
  saveFormJSON,
  uploadFormUtil,
  updateFormDBData,
  publishForm,
  deleteForm,
  fetchFormData,
  fetchFormJson,
}
