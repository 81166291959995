import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";
import FormattedDateTimeInput from "@/components/Fields/FieldsViews/Datetime/FormattedDateTimeInput";
import constants from "@/constants/constants";

export default {
    name: 'Preview__Datetime',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            FormattedDateTimeInput,
            {
                props: {
                    readonly: true,
                    isFormStaticProp: ctx.props.isFormStatic,
                    layout: constants.FIELD_FROM_TYPE[ctx.props.fieldObj.field_type].UTIL.GEN_INPUT_LAYOUT(ctx.props.fieldObj)
                }
            }
        )
    }
}