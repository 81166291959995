var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-us-state-flag"
  }, [_c('img', {
    staticClass: "f-us-state-flag__flag",
    attrs: {
      "src": '/images/us_states_icons/' + _vm.svgName + '.svg'
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }