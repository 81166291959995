var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-aspect-ratio-keeper', {
    attrs: {
      "width": "16",
      "height": "9"
    }
  }, [_c('div', {
    ref: "pagesDisplayManagerWrapper",
    staticClass: "interactive-form-editor-pages-display-manager-wrapper"
  }, [_c('div', {
    ref: "pagesDisplayManager",
    class: ['interactive-form-editor-pages-display-manager', _vm.getStylePreferencesMenuModel && 'interactive-form-editor-pages-display-manager--zoomed'],
    style: Object.assign({
      backgroundImage: ("url(" + (_vm.getCurrentBackgroundImage) + ")")
    }, _vm.style)
  }, [_vm.getCurrentPageId === undefined ? _c('InteractiveEmptyPage') : _vm.getCurrentPage.type === _vm.constants.FORM_PAGES_TYPES.HOME_PAGE ? _c('InteractiveHomePage', {
    key: _vm.getCurrentPageId,
    attrs: {
      "page-id": _vm.getCurrentPageId,
      "page-obj": _vm.getFormJSON.all_pages[_vm.getCurrentPageId]
    }
  }) : _vm.getCurrentPage.type === _vm.constants.FORM_PAGES_TYPES.MAIN_PAGE ? _c('InteractiveMainPage', {
    key: _vm.getCurrentPageId,
    attrs: {
      "page-id": _vm.getCurrentPageId
    }
  }) : _vm.getCurrentPage.type === _vm.constants.FORM_PAGES_TYPES.FINISH_PAGE ? _c('InteractiveFinishPage', {
    key: _vm.getCurrentPageId
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }