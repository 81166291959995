<template>
    <v-container class="loading-form-screen h-100vh-without-appbar" fluid>
        <LottieAnimation
                path="EditorLoaderIcon.json"
                :width="450"
                :height="450"
        />
        <p class="loading-form-screen__caption">Loading your form...</p>
    </v-container>
</template>

<script>
    export default {
        name: "LoadingFormScreen",
        components: {
            LottieAnimation: () => import('lottie-vuejs/src/LottieAnimation')
        }
    }
</script>

<style lang="scss" scoped>
    .loading-form-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: fixed;
        z-index: 1000;
        width: 100vw;
        background-color: white;

        &__caption {
            position: absolute;
            margin-top: 300px;

            font-weight: 300;
            font-size: 1.25rem;
            line-height: 1.5rem;
            letter-spacing: -0.5px;
            color: var(--v-gray_3-base);
        }
    }
</style>