<template>
    <div class="generic-field__view">
        <component
                :is="fieldTypeConst.EDITABLE_VIEW"
                :field-obj="fieldObj"
                :is-editing-active="isEditingActive"
                v-bind="$attrs"
        />
        <div class="generic-field__error-messages-wrapper"></div>
    </div>
</template>

<script>
    export default {
        name: "EditableFieldViewsGenerator",
        props: {
            fieldObj: {
                type: Object,
                required: true
            },
            isEditingActive: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            fieldTypeConst() {
                return this.constants.FIELD_FROM_TYPE[this.fieldObj.field_type]
            }
        }
    }
</script>

<style scoped>

</style>