var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "interactive-form-submit-footer"
  }, [_vm.getFormSourceJSON.settings.general_settings.formsly_branding ? _c('div', {
    staticClass: "interactive-form-branding noselect",
    style: {
      cursor: 'pointer'
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.constants.PAGE_NAME_DASHBOARD
        });
      }
    }
  }, [_c('span', {
    style: {
      color: _vm.backgroundColorIsLight ? '#000' : '#fff'
    }
  }, [_vm._v("Crafted with")]), _c('LogoComponent', {
    attrs: {
      "light": !_vm.backgroundColorIsLight
    }
  })], 1) : _vm._e(), _vm.isMainPage && _vm.getFormSourceJSON.settings.general_settings.navigation_arrows ? _c('div', {
    staticClass: "interactive-form-navigation-arrows"
  }, [_c('div', {
    class: ['interactive-form-navigation-arrows__btn', (_vm.disabled || _vm.getCurrentMainPageIndex === 0) && 'interactive-form-navigation-arrows__btn--disabled'],
    on: {
      "click": function () {
        return _vm.disabled || _vm.getCurrentMainPageIndex === 0 || _vm.goToPreviousPage();
      }
    }
  }, [_c('ChevronUpIcon')], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('div', {
    class: ['interactive-form-navigation-arrows__btn', (_vm.disabled || _vm.getFormPages.length - 1 === _vm.getCurrentMainPageIndex) && 'interactive-form-navigation-arrows__btn--disabled'],
    on: {
      "click": function () {
        return _vm.disabled || _vm.getFormPages.length - 1 === _vm.getCurrentMainPageIndex || _vm.goToNextPage();
      }
    }
  }, [_c('ChevronDownIcon')], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }