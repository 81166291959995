var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "splitted-analytics-table__inspecting-item__section"
  }, [!_vm.builtinHeader ? _c('p', {
    staticClass: "splitted-analytics-table__inspecting-item__section__header"
  }, [_vm._v(" " + _vm._s(_vm.Utils.stripHtml(_vm.fieldTitle)) + " ")]) : _vm._e(), _c(_vm.componentView, {
    tag: "component",
    attrs: {
      "submission-obj": _vm.submissionObj,
      "source-obj": _vm.sourceObj
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('p', {
          staticClass: "splitted-analytics-table__inspecting-item__section__header splitted-analytics-table__inspecting-item__section__header--builtin"
        }, [_vm._v(" " + _vm._s(_vm.Utils.stripHtml(_vm.fieldTitle)) + " ")])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }