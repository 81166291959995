var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "matrix-field-view-summary"
  }, _vm._l(Object.keys(_vm.formattedSummary), function (chartName) {
    return _c('div', {
      key: chartName,
      staticClass: "matrix-field-view-summary__row-wrapper"
    }, [_c('div', {
      staticClass: "matrix-field-view-summary__row-title"
    }, [_vm._v(" " + _vm._s(chartName) + " ")]), _c('div', {
      staticClass: "matrix-field-view-summary__chart-container"
    }, [_c('canvas', {
      ref: chartName,
      refInFor: true
    })]), _c('div', {
      staticClass: "matrix-field-view-summary__legend"
    }, _vm._l(_vm.formattedSummary[chartName], function (columnData, columnName) {
      return _c('div', {
        key: columnName + columnData.color + chartName,
        staticClass: "matrix-field-view-summary__column-data"
      }, [_c('div', {
        staticClass: "matrix-field-view-summary__column-color",
        style: {
          backgroundColor: columnData.color
        }
      }), _c('span', {
        staticClass: "matrix-field-view-summary__column-name"
      }, [_vm._v(" " + _vm._s(columnName) + " ")]), _c('span', [_vm._v(" " + _vm._s(columnData.count) + " resp.. ")]), _c('span', {
        staticClass: "matrix-field-view-summary__column-percentage"
      }, [_vm._v(" " + _vm._s(columnData.percentage) + "% ")])]);
    }), 0)]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }