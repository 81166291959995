import { GenericTextFieldConst } from '@/components/Fields/GenericFieldViews/GenericTextField'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyNumberInput, FieldPropertySelectInput,
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {
    GenericNumberConditionalLogic,
} from '@/components/Fields/FieldConstClasses/conditional_logic'
import { GenericTextAnalytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import { maxLength, numeric, helpers } from '@vuelidate/validators'

class NumberFieldTypeConst extends GenericTextFieldConst {
    constructor() {
        super({
            FIELD_TYPE: 'NUMBER',
            READABLE_NAME: 'Number',
            ICON_NAME: 'number',
            FOLDER_NAME: 'Number',

            CONDITIONAL_LOGIC: new GenericNumberConditionalLogic(),

            ANALYTICS: new GenericTextAnalytics({
                SORT: ({value: a}, {value: b}) => a < b ? a > b ? 0 : -1 : 1
            }),

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: {
                        ...FieldSubmitValidation.REQUIRED(fieldObj),
                        ...(fieldObj.properties.max_characters && {
                            maxLength: maxLength(fieldObj.properties.max_characters)}),
                            numeric: helpers
                                .withMessage('This entry can only contain numbers', numeric)
                    }
                })
            }),
            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: 'Type your answer here...',
                }
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'max_characters',
                    name: 'Max length',
                    tooltip: 'Choose the maximum allowed length for the answer',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyNumberInput({
                            default: 64
                        })
                    )
                }),
                new CustomFieldPropertyConst({
                    key: 'number_format',
                    name: 'Number Format',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySelectInput({
                            default: 'integer',
                            items: [
                                {key: 'float', readable_name: 'Number with commas'},
                                {key: 'integer', readable_name: 'Whole numbers'}
                            ]
                        })
                    )
                })
            ])
        })
    }
}

export default new NumberFieldTypeConst()