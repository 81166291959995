var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "fieldView",
    staticClass: "smart-paragraph-field-view"
  }, [_vm.popupModel ? _c('div', {
    staticClass: "smart-paragraph-field-view__add-variable-popup generic-field__escape-form-font",
    style: _vm.popupStyle
  }, [_c('div', {
    staticClass: "smart-paragraph-field-view__add-variable-popup__button",
    on: {
      "mousedown": _vm.addVariable
    }
  }, [_c('div', {
    staticClass: "smart-paragraph-field-view__add-variable-popup__plus-icon"
  }, [_c('PlusIcon')], 1), _c('span', [_vm._v("Add input")])]), _c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Add variable"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "smart-paragraph-field-view__add-variable-popup__info"
        }, on), [_c('InfoIcon')], 1)];
      }
    }], null, false, 582719896)
  })], 1) : _vm._e(), _c('span', {
    ref: "content",
    staticClass: "smart-paragraph-field-view__content",
    class: _vm.fieldObj.content.length || 'smart-paragraph-field-view__content--placeholder',
    attrs: {
      "data-placeholder": "Add paragraph text",
      "contenteditable": "true"
    },
    on: {
      "mousedown": _vm.handlePopupPosition,
      "mousemove": _vm.handlePopupPosition,
      "keydown": _vm.handleKeyDown,
      "paste": _vm.processPaste,
      "input": _vm.input,
      "blur": _vm.blur
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }