<template>
  <SharedView
      :rows="rowHeaders"
      :columns="columnHeaders"
      :radio="!fieldProperties.multiple_selection"
      :submission-obj-value="submissionObjValue"
      @toggle-selection:add="addOption"
      @toggle-selection:remove="removeOption"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

export default {
  name: "Submittable__Matrix",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  },
  setup(props, {root}) {
    const submissionObjValue = props.submissionObj.value;
    const rowHeaders = props.sourceObj.content.rows
    const columnHeaders = props.sourceObj.content.columns
    const addOption = ({rowIndex, columnIndex}) => {
      if (props.sourceObj.properties.multiple_selection) {
        submissionObjValue[rowIndex].push(columnIndex)
      } else {
        submissionObjValue[rowIndex].splice(0, submissionObjValue[rowIndex].length, columnIndex)
      }
    }

    const removeOption = ({rowIndex, columnIndex}) => {

      if (props.sourceObj.properties.multiple_selection) {
        root.$delete(submissionObjValue[rowIndex], submissionObjValue[rowIndex].findIndex(index => index === columnIndex))
      } else {
        root.$delete(submissionObjValue[rowIndex], 0)
      }
    }
    return {
      rowHeaders,
      columnHeaders,
      addOption,
      removeOption,
      submissionObjValue
    }
  }
}
</script>