var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-hidden-answers-count"
  }, [_c('v-tooltip', {
    attrs: {
      "content-class": "tooltip-top pa-5",
      "color": "gray_900",
      "top": "",
      "eager": "",
      "transition": "none",
      "max-width": "320",
      "nudge-top": "5"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('InfoIcon'), _vm._v(" " + _vm._s(_vm.hiddenCount) + " Hidden ")], 1)];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('p', {
          staticClass: "subtitle-1 font-weight-semi-bold mb-2"
        }, [_vm._v(_vm._s(_vm.hiddenCount) + " Hidden Responses")]), _c('p', {
          staticClass: "subtitle-2 font-weight-regular text-pre-wrap"
        }, [_vm._v("These responses are hidden because you have removed the options of them in the published form.")]), _vm._t("default")];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }