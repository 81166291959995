<template>
    <div>
        <FormSettingsOptionWithInput always-enabled>
            Embedding Code
            <template #input>
                <div class="gray_100 bluish_gray_500--text pa-5 rounded-lg">
                    <pre class="iframe-code">{{ iframeCode }}</pre>
                </div>
            </template>
        </FormSettingsOptionWithInput>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
    name: "FormEmbededCode",
    components: {
        XIcon: () => import('vue-feather-icons/icons/XIcon'),
        FormSettingsOptionWithInput: () => import('./FormSettingsOptions/FormSettingsOptionWithInput'),
    },
    props: {
        settingsObj: {
            type: Object,
            required: true
        }
    },
    setup(props, { root }) {

        const iframeCode = computed(() => {
            return `<iframe src="${'https://create.formsly.app/' + root.$route.params.formId}" style="position:absolute; top:0; left:0; width:100%; height:100%; border:none;" name="Formsly.app" scrolling="no" frameborder="0" marginheight="0px" marginwidth="0px" allowfullscreen></iframe>`
        })


        return {
            iframeCode
        }
    }
}
</script>
<style scoped>
.iframe-code {
    max-width: 100%;
    white-space: pre-line;
    overflow: auto;
}
</style>