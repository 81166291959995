import {
    getAbsoluteBoundingRect,
    isCursorInsideBoundingRect,
    matrixToArray
} from '@/utils/misc'
import constants from '@/constants/constants'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

const INTERSPACE_HEIGHT = constants.DRAGGABLE_INTERSPACE_ROW_GAP_SIZE
const DRAG_TRANSITION_DURATION = constants.DRAGGABLE_FIELDS_FLOATING_DURATION
const EXPAND_TRANSITION_DURATION = constants.FIELD_EXPAND_DURATION

const useBaseClasses = (
    props,
    {root, refs},
    mousePos
) => {
    class BaseClass{
        constructor() {
            this.refID = root.Utils.generateUUID()
            this.cachedBounding = undefined

            this.calculateBoundingOnCreated()
        }

        get ref() {
            const ref = refs[this.refID]
            return ref instanceof Array ? ref[0] : ref
        }

        get bounding() {
            if (!this.ref)
                return undefined

            const bounding = getAbsoluteBoundingRect(this.ref)
            if (bounding)
                this.cachedBounding = bounding

            return bounding
        }

        get isCursorInside() {
            if (this.ref)
                return isCursorInsideBoundingRect(mousePos.x, mousePos.y, this.bounding)
            return false
        }

        async calculateBoundingOnCreated() {
            await Vue.nextTick()
            if (!this.bounding) {
                await Vue.nextTick()
                this.bounding
            }
        }
    }


    class Element extends BaseClass {
        constructor(width) {
            super()
            this.width = width

            this.index = undefined
            this.row = undefined
        }
    }


    class Field extends Element {
        constructor(fieldID, width) {
            super(width)
            this.fieldID = fieldID

            this.isExpandingTransitionGoing = false
            this.onExpandTransitionEnd = undefined
        }

        get bounding() {
            if (!this.ref)
                return undefined
            const bounding = super.bounding
            const transformMatrix = matrixToArray(getComputedStyle(this.ref).getPropertyValue('transform'))
            if (transformMatrix) {
                const [,,,, translateX, translateY] = matrixToArray(getComputedStyle(this.ref).getPropertyValue('transform'))
                bounding.left -= translateX
                bounding.right -= translateX
                bounding.top -= translateY
                bounding.bottom -= translateY
            }
            return bounding
        }

        hideWithSizeSaving() {
            if (this.ref) {
                const bounding = this.bounding
                this.ref.style.transform = 'scale(0)'
                return bounding
            }
        }

        unhideWithSizeSaving() {
            if (this.ref) {
                this.ref.style.transform = ''
            }
        }

        async positionChanged(layout) {
            if (!this.bounding)
                return


            const that = this
            function resetStyleOnTransitionEnd() {
                if (!that.ref) return
                that.ref.style.transition = ''
                that.ref.style.transform = ''
                that.ref.style.pointerEvents = ''
                that.ref.removeEventListener('transitionend', resetStyleOnTransitionEnd)
                that.ref.removeEventListener('transitioncancel', resetStyleOnTransitionEnd)
            }


            const prevBounding = this.bounding

            if (prevBounding.top < layout.bounding.top) {
                return resetStyleOnTransitionEnd()
            }
            await Vue.nextTick()

            if (this.bounding.top < layout.bounding.top) {
                return resetStyleOnTransitionEnd()
            }

            await Vue.nextTick()

            if (!this.bounding)
                return

            const currentBounding = this.bounding
            if (currentBounding.top < layout.bounding.top)
                return resetStyleOnTransitionEnd()

            if (currentBounding.left !== prevBounding.left || currentBounding.top !== prevBounding.top) {
                this.ref.style.transform = `translate3d(${prevBounding.left - currentBounding.left}px, ${prevBounding.top - currentBounding.top}px, 0)`
                this.ref.style.pointerEvents = 'none'
                getComputedStyle(this.ref).getPropertyValue('transform')

                await Vue.nextTick()
                await Vue.nextTick()
                getComputedStyle(this.ref).getPropertyValue('transform')
                this.ref.style.transition = `transform ${DRAG_TRANSITION_DURATION}ms linear`
                this.ref.style.transform = ''
                await Vue.nextTick()
                await Vue.nextTick()

                this.ref.addEventListener('transitionend', resetStyleOnTransitionEnd)
                this.ref.addEventListener('transitioncancel', resetStyleOnTransitionEnd)
            }
        }

        initializeExpandingHandlers () {
            this.ref.style.transition = `flex ${EXPAND_TRANSITION_DURATION}ms ease`

            this.ref.addEventListener('transitionstart', () => {
                this.isExpandingTransitionGoing = true
            })
            this.ref.addEventListener('transitionend', () => {
                this.isExpandingTransitionGoing = false
                if (this.onExpandTransitionEnd)
                    this.onExpandTransitionEnd()
            })
        }
    }

    class Dropzone extends Element {
        constructor(width) {
            super(width)
        }
    }

    class ForbiddenDropzone extends Dropzone {
        constructor(width) {
            super(width)
        }
    }


    class Row extends BaseClass {
        constructor(elements=[]) {
            super()
            this._elements = ref([])
            this.elements = elements
            this.index = undefined
            this.layout = undefined
        }

        get elements() {
            return this._elements.value
        }
        set elements(v) {
            this._elements.value = v

            this.elements.forEach(element => {
                if (element instanceof Field)
                    element.positionChanged(this.layout)
            })
        }

        get filledWidth() {
            return this.elements.reduce((p, {width}) => p + width, 0)
        }

        get fields() {
            return this.elements.filter(element => element instanceof Field)
        }

        get isInterspace() {
            return this.elements.length === 1 && this.elements[0] instanceof Dropzone
        }


        get elementsBreakpoints() {
            if (this.isCursorInside) {
                let elementBounding,
                    usedWidth = 0,
                    breakpoints = []

                const elementsWithoutDropzoneInTheEnd = this.elements
                    .filter((element, index, self) => !(element instanceof Dropzone && index === self.length - 1))

                for (let [index, element] of elementsWithoutDropzoneInTheEnd.entries()) {
                    elementBounding = element.bounding
                    usedWidth += element.width

                    if (index === elementsWithoutDropzoneInTheEnd.length - 1
                        && elementsWithoutDropzoneInTheEnd[index - 1] instanceof Dropzone) {
                        breakpoints.push({
                            left: elementBounding.left,
                            right: this.bounding.right,
                            insertIndex: this.elements.length,
                            width: constants.STATIC_FORM_COLS_COUNT - this.fields.reduce((p, {width}) => p + width, 0)
                        })

                        return breakpoints
                    } else if (index === elementsWithoutDropzoneInTheEnd.length - 1 && index === 0 && element.width > 1) {
                        breakpoints.push({
                            left: elementBounding.left,
                            right: elementBounding.left + elementBounding.width / element.width,
                            insertIndex: index,
                            width: 1
                        }, {
                            left: elementBounding.left + elementBounding.width / element.width,
                            right: elementBounding.right,
                            insertIndex: index + 1,
                            width: constants.STATIC_FORM_COLS_COUNT - element.width
                        })

                        continue
                    }

                    if (element.width > 1)
                        breakpoints.push({
                            left: elementBounding.left,
                            right: elementBounding.left + elementBounding.width / element.width,
                            insertIndex: index,
                            width: 1
                        }, {
                            left: elementBounding.left + elementBounding.width / element.width,
                            right: elementBounding.right,
                            insertIndex: index + 1,
                            width: 1
                        })
                    else
                        breakpoints.push({
                            left: elementBounding.left,
                            right: elementBounding.right,
                            insertIndex: index,
                            width: 1
                        })
                }

                if (usedWidth < constants.STATIC_FORM_COLS_COUNT) {
                    breakpoints.push({
                        left: elementBounding.right,
                        right: this.bounding.right,
                        insertIndex: this.elements.length,
                        width: constants.STATIC_FORM_COLS_COUNT - this.fields.reduce((p, {width}) => p + width, 0)
                    })
                }

                return breakpoints
            }

            return false

        }

        checkIsRowOverflowed() {
            if (this.elements.reduce((p, {width}) => p + width, 0) > constants.STATIC_FORM_COLS_COUNT) {
                this.layout.moveLastElementToNextRow(this)
                this.checkIsRowOverflowed()
            }
        }

        insertElement(position, element) {
            this.elements.splice(position, 0, element)
            this.checkIsRowOverflowed()
        }

        cleanFromDropzones() {
            this.elements = this.elements.filter(element => !(element instanceof Dropzone))
            if (this.elements.length === 0)
                this.layout.removeRow(this.index)
        }

        quiteAndSoftCleanFromDropzones() {
            this._elements.value = this.elements.filter(element => !(element instanceof Dropzone))
        }

        async insertDropzone() {
            if (this.isCursorInside) {
                if (this.isInterspace)
                    return

                if (mousePos.y <= this.bounding.top + INTERSPACE_HEIGHT) {
                    if (!this.layout.rows[this.index - 1]?.isInterspace)
                        this.layout.insertInterspaceRow(this.index)
                    return this.cleanFromDropzones()
                } else if (mousePos.y >= this.bounding.bottom - INTERSPACE_HEIGHT) {
                    if (!this.layout.rows[this.index + 1]?.isInterspace)
                        this.layout.insertInterspaceRow(this.index + 1)
                    return this.cleanFromDropzones()
                }
                let hoveredElement = this.findHoveredElement()
                if (hoveredElement instanceof Dropzone)
                    return true

                if (this.elements.length === 1 && this.elements[0].width === constants.STATIC_FORM_COLS_COUNT)
                    return

                for (let breakpoint of this.elementsBreakpoints) {
                    if (mousePos.x >= breakpoint.left && mousePos.x <= breakpoint.right) {
                        this.cleanFromDropzones()
                        if (breakpoint.width >= this.layout.draggingField.minWidth) {
                            this.layout.draggingField.dropzoneObj = new Dropzone(breakpoint.width)
                            this.insertElement(breakpoint.insertIndex, this.layout.draggingField.dropzoneObj)
                            this.layout.draggingField.lastDropzoneRowID = this.refID
                            this.layout.draggingField.lastDropzoneInsertIndex = breakpoint.insertIndex
                            this.layout.draggingField.dropzoneWidth = breakpoint.width
                        } else {
                            this.insertElement(breakpoint.insertIndex, new ForbiddenDropzone(breakpoint.width))
                        }
                        break
                    }
                }
            } else {
                if (this.isInterspace) {
                    const func = () => {
                        if (mousePos.y >= this?.bounding?.top - INTERSPACE_HEIGHT
                            && mousePos.y <= this?.bounding?.bottom + INTERSPACE_HEIGHT)
                            return
                        this.cleanFromDropzones()
                    }
                    if (this.bounding)
                        func()
                    else {
                        await Vue.nextTick()
                        func()
                    }
                } else
                    this.cleanFromDropzones()
            }
        }


        findHoveredElement() {
            if (this.isCursorInside)
                for (let element of this.elements)
                    if (element.isCursorInside)
                        return element
            return false
        }
    }

    class Layout extends BaseClass {
        constructor() {
            super()
            this._rows = ref([])
            this.dropzone = undefined
            this.draggingField = undefined
            this.expandingField = undefined

            this.refresh()
        }

        get rows() {
            return this._rows.value
        }
        set rows(v) {
            this._rows.value = v
        }

        refresh() {
            this.rows = props.pageObj.rows
                .map(row => new Row(
                    row.fields.map(field => new Field(field.field_id, field.width))
                ))
            this.afterInteractingWithRows()
        }

        updateFormObject() {
            root.$store.dispatch('edit/pages/changeCurrentFormPage', {
                ...props.pageObj,
                rows: this.rows
                    .map(row => {
                        return {
                            fields: row.elements
                                .map(element => {
                                    return {field_id: element.fieldID, width: element.width}
                                })
                        }
                    })
            })
        }

        removeRow(rowIndex) {
            this.rows.splice(rowIndex, 1)
            this.afterInteractingWithRows()
        }

        createNewRow(position, elements=[]) {
            const newRow = new Row(elements)
            this.rows.splice(position, 0, newRow)
            this.afterInteractingWithRows()
            return newRow
        }

        afterInteractingWithRows() {
            this.rows.forEach((row, index) => {
                row.index = index
                row.layout = this
            })
        }

        insertInterspaceRow(position) {
            this.draggingField.dropzoneObj = new Dropzone(constants.STATIC_FORM_COLS_COUNT)
            const newRow = this.createNewRow(position, [this.draggingField.dropzoneObj])
            this.draggingField.lastDropzoneRowID = newRow.refID
            this.draggingField.lastDropzoneRowIndex = position
            this.draggingField.lastDropzoneInsertIndex = 0
            this.draggingField.dropzoneWidth = constants.STATIC_FORM_COLS_COUNT
        }

        moveLastElementToNextRow(requestingRow) {
            let element = requestingRow.elements.pop()
            let nextRow = this.rows[requestingRow.index + 1]
            if (!nextRow) {
                nextRow = this.createNewRow(requestingRow.index + 1)
            }
            nextRow.insertElement(0, element)
        }

        applyToAllRows(methodName) {
            let index,
                rowsCount = this.rows.length
            for (index = 0; index < this.rows.length; index++) {
                if (rowsCount < this.rows.length) {
                    index--
                    rowsCount = this.rows.length
                    continue
                }
                this.rows[index][methodName]()
            }
        }

        async insertDropzone() {
            const mainFunc = () => {
                if (this.isCursorInside) {
                    this.rows.forEach(row => row.insertDropzone())
                    if (!this.rows.some(row => row.isCursorInside) && ((this.rows.length && !this.rows[this.rows.length - 1].isInterspace) || !this.rows.length)) {
                        this.insertInterspaceRow(this.rows.length)
                    }
                } else
                    this.rows.forEach(row => row.cleanFromDropzones())
            }
            if (!this.rows.length) {
                await Vue.nextTick()
                this.insertInterspaceRow(0)
                Vue.nextTick(mainFunc)
            } else
                mainFunc()
        }


        setDraggingField(field, initialRow, fieldMinWidth) {
            this.draggingField = field
            this.draggingField.minWidth = fieldMinWidth
            this.draggingField.lastDropzoneRowID = initialRow.refID
            initialRow.elements = initialRow.elements.filter((element, index) => {
                if (element.refID === this.draggingField.refID) {
                    this.draggingField.lastDropzoneInsertIndex = index
                    this.draggingField.dropzoneWidth = field.width
                    return false
                }
                return true
            })
        }

        async prepareStopDragging() {
            const field = new Field(this.draggingField.fieldID, this.draggingField.dropzoneWidth)
            this.draggingField.realAlias = field
            this.rows.forEach(row => row.quiteAndSoftCleanFromDropzones())
            let rowForInsertion = this.rows.find(row => row.refID === this.draggingField.lastDropzoneRowID)
            if (!rowForInsertion && this.draggingField.lastDropzoneRowIndex !== undefined)
                rowForInsertion = this.createNewRow(this.draggingField.lastDropzoneRowIndex)
            if (!rowForInsertion)
                rowForInsertion = this.createNewRow(0)
            rowForInsertion.insertElement(this.draggingField.lastDropzoneInsertIndex, field)

            this.rows.forEach(row => row.cleanFromDropzones())
            await Vue.nextTick()

            const fieldBounding = field.hideWithSizeSaving()
            return fieldBounding
        }

        async stopDragging() {
            this.draggingField.realAlias.unhideWithSizeSaving()
            this.draggingField = undefined

            this.updateFormObject()
        }

        setExpandingField(field, initialRow, minWidth) {
            this.expandingField = field
            this.expandingField.initialRow = initialRow
            this.expandingField.minWidth = minWidth
            this.expandingField.initializeExpandingHandlers()
        }

        resizeExpandingField() {
            let newWidth = Math.round((mousePos.x - this.expandingField.bounding.left) /
                (this.expandingField.bounding.width / this.expandingField.width))

            if (
                (newWidth > this.expandingField.width && this.expandingField.initialRow.filledWidth === constants.STATIC_FORM_COLS_COUNT)
                || this.expandingField.minWidth > newWidth
            ) return
            if (newWidth < 1)
                newWidth = 1
            else if (newWidth > constants.STATIC_FORM_COLS_COUNT)
                newWidth = constants.STATIC_FORM_COLS_COUNT

            this.expandingField.width = newWidth
            this.expandingField.initialRow.checkIsRowOverflowed()
        }

        async stopExpanding(that) {
            let self = this
            if (that)
                self = that
            if (self.expandingField && !self.expandingField?.isExpandingTransitionGoing) {
                delete self.expandingField.initialRow
                self.updateFormObject()
                self.expandingField = undefined
            } else if (self.expandingField)
                self.expandingField.onExpandTransitionEnd = () => self.stopExpanding(this)

        }

    }

    return {
        Field,
        Dropzone,
        ForbiddenDropzone,
        Row,
        Layout
    }
}

export default useBaseClasses
