export default {
  PAGE_NAME_NOT_FOUND: 'Not Found',
  PAGE_NAME_DASHBOARD: 'Dashboard',
  PAGE_NAME_SUBMISSIONS_ANALYTICS: 'Analytics',
  PAGE_NAME_TEMPLATES: 'Templates',
  PAGE_NAME_FORM_EDITOR: 'Form Editor',
  PAGE_NAME_FORM_SUBMIT: 'Form Submit',
  PAGE_NAME_FORM_NOT_FOUND: 'Form Not Found',

  PAGE_NAME_LOGIN: 'Login',
  PAGE_NAME_FORGOT_PASSWORD: 'Forgot Password',
  PAGE_NAME_RESET_PASSWORD: 'Reset Password',
  PAGE_NAME_SIGNUP: 'Sign Up',
  PAGE_NAME_SIGNUP_APPSUMO: 'AppSumo Sign Up',

  PAGE_NAME_FORM_TYPE_SELECTION: 'Form Type Selection',
  PAGE_NAME_FORM_PRESET_SELECTION: 'Form Preset Selection',
  PAGE_NAME_FORM_AI_PROMPT: 'Form AI Prompt',
  PAGE_NAME_FORM_TEMPLATE_SELECTION: 'Form Template Selection',

  PAGE_NAME_SETTINGS: 'Settings',
  PAGE_NAME_ACCOUNT_DETAILS: 'Account Details',
  PAGE_NAME_PLAN_BILLING: 'Plan & Billings',
  PAGE_NAME_NOTIFICATIONS: 'Notifications',
  PAGE_NAME_INTEGRATIONS: 'Integrations',
  PAGE_NAME_DANGER_ZONE: 'Danger Zone',

  PAGE_NAME_CHECKOUT: 'Checkout',
  PAGE_NAME_CHECKOUT_CHOOSE_PLAN: 'Checkout Choose Plan',
  PAGE_NAME_CHECKOUT_CUSTOMER_DETAILS: 'Checkout Customer Details',
  PAGE_NAME_CHECKOUT_PURCHASE_COMPLETED: 'Checkout Purchase Completed',
}
