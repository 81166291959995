var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-upload-property"
  }, [_vm.inputValue.url || _vm.loading ? _c('div', {
    staticClass: "media-upload-property-image-manage"
  }, [_c('v-img', {
    staticClass: "media-upload-property-image-manage__image",
    attrs: {
      "src": _vm.loading ? '' : _vm.inputValue.url,
      "height": "180"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('f-circle-loader', {
          attrs: {
            "size": "4"
          }
        })];
      },
      proxy: true
    }], null, false, 379821587)
  }), _c('div', {
    staticClass: "media-upload-property-image-manage__info-row"
  }, [_vm.filename ? _c('span', {
    staticClass: "media-upload-property-image-manage__filename"
  }, [_vm._v(_vm._s(_vm.filename))]) : _c('v-skeleton-loader', {
    attrs: {
      "type": "text",
      "width": "90"
    }
  }), _vm.filename ? _c('span', {
    staticClass: "media-upload-property-image-manage__replace-btn",
    on: {
      "click": _vm.change
    }
  }, [_vm._v("Change")]) : _vm._e()], 1), _c('div', {
    staticClass: "media-upload-property-image-manage__info-row"
  }, [_vm.width ? _c('span', {
    staticClass: "media-upload-property-image-manage__secondary-info"
  }, [_vm._v(_vm._s(_vm.width) + "x" + _vm._s(_vm.height) + "px")]) : _c('v-skeleton-loader', {
    attrs: {
      "type": "text",
      "width": "70"
    }
  }), _vm.size ? _c('span', {
    staticClass: "media-upload-property-image-manage__secondary-info"
  }, [_vm._v(_vm._s(_vm.size))]) : _c('v-skeleton-loader', {
    attrs: {
      "type": "text",
      "width": "60"
    }
  })], 1)], 1) : _c('FileUploadArea', {
    staticClass: "media-upload-property-empty-area",
    attrs: {
      "accept-mime": ['image/jpeg', 'image/png'],
      "max-file-size": _vm.inputConst.max_size,
      "match-both-mime-and-extension": ""
    },
    on: {
      "input": _vm.addFile
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }