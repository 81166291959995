<template>
    <div class="generic-text-field-view phone-field-view">
        <GenericStringSingleLineFieldViewInput
                :value="value"
                v-input-mask="mask"
                :placeholder="placeholder"
                v-on="$listeners"
                ref="phone"
                :disabled="preview"
                :is-form-static-prop="isFormStatic"
        >
          <template #prepend-inner>
            <SubmittableFieldGenericDropdown
                :value="countryCode"
                @change="$emit('change-country', $event)"
                :filter="countryFilter"
                :item-value="item => item.code"
                :items="constants.COUNTRY_LIST"
                :menu-props="{minWidth: 355, nudgeLeft: 12}"
                input-class="phone-field-view__country-select generic-text-field-view__input-field"
                flat
                solo
                background-color="transparent"
                hide-details
                dense
                :disabled="editable || preview"
                @click.native.stop
            >
              <template #item="{on, attrs, item, component}">
                <component v-on="on" v-bind="attrs" :is="component">
                  <template #icon>
                    <f-country-flag
                        :code="item.code"
                        size="m"
                    />
                  </template>
                  <template #content>
                        <span class="phone-field-view__country-name">
                            {{ item.name }}
                        </span>
                    <span class="phone-field-view__country-phone-code">
                            (+{{ item.countryCallingCode }})
                        </span>
                  </template>
                </component>
              </template>
              <template #selection="{item}">
                <f-country-flag
                    :code="item.code"
                    size="l"
                />
              </template>
            </SubmittableFieldGenericDropdown>
          </template>
        </GenericStringSingleLineFieldViewInput>
    </div>
</template>

<script>
    export default {
        name: "SharedView__Phone",
        components: {
            SubmittableFieldGenericDropdown: () => import('@/components/Fields/GenericFieldViews/GenericSubmittableDropdown/SubmittableFieldGenericDropdown'),
            GenericStringSingleLineFieldViewInput: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput')
        },
        props: {
          value:{
            type: String,
            default: '',
          },
          countryCode: {
            type: String,
            required: true,
          },
          placeholder: {
            type: String,
            default: '',
          },
          mask: {
            type: RegExp,
            default: () => /^.*$/
          },
          preview: {
            type: Boolean,
            default: false,
          },
          editable: {
            type: Boolean,
            default: false,
          },
          isFormStatic: {
            type: Boolean,
            default: false,
          }
        },
        methods: {
            countryFilter(item, search) {
                const byCountryName = item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                const byPhoneCode = item.countryCallingCode.replace(' ', '').includes(search.toLocaleLowerCase().replaceAll('+', ''))

                return byCountryName || byPhoneCode
            }
        }
    }
</script>