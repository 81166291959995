<template>
    <GenericFrontView
            :field-properties="fieldProperties"
            :model="submissionObj.value"
            @select-option="selectOption"
    />
</template>

<script>
    import submittable_field_view_mixin from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

    export default {
        name: "Submittable__StarRating",
        components: {
            GenericFrontView: () => import('./GenericFrontView')
        },
        mixins: [submittable_field_view_mixin],
        computed: {
            isEmojiScale() {
                return this.fieldProperties.emoji_scale !== null
            },
            isScaleIcon() {
                return this.fieldProperties.scale_icon !== null
            }
        },
        setup(props) {
            const selectOption = index => {
                if (index === props.submissionObj.value)
                    props.submissionObj.value = null
                else
                    props.submissionObj.value = index
            }
            return {
                selectOption
            }
        }
    }
</script>