import {FieldTypeBasePropertiesConst} from '@/components/Fields/FieldConstClasses/base_properties'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import _ from 'lodash'

const isFunction = require('lodash/isFunction')

class FieldTypeConstError extends Error {
    constructor(fieldConst, message) {
        super(`${fieldConst.FIELD_TYPE}: ${message}`)
        this.name = 'FieldTypeConstError'
    }
}

export class FieldTypeConst {
    constructor({
        FIELD_TYPE,
        READABLE_NAME,
        ICON_NAME,
        FOLDER_NAME,
        WITHOUT_TITLE = false,
        WITHOUT_DESCRIPTION = false,
        STATIC_FORM_MIN_WIDTH = 1,

        FIELD_OBJ_EXTENSION = () => ({}), // BASE TEMPLATE
        EDITABLE_VIEW = () => import(`@/components/Fields/FieldsViews/${FOLDER_NAME}/Editable`),
        SUBMITTABLE_VIEW = () => import(`@/components/Fields/FieldsViews/${FOLDER_NAME}/Submittable`),
        PREVIEW_VIEW = () => import(`@/components/Fields/FieldsViews/${FOLDER_NAME}/Preview`),
        SUBMISSION_TEMPLATE,
        IS_SUBMISSION_EMPTY,
        SUBMIT_VALIDATION,

        WITH_PRESS_ENTER = false,
        AUTO_SCROLL_HANDLER,

        FSP,

        CONDITIONAL_LOGIC,

        ANALYTICS,

        BASE_PROPERTIES,

        ASSOCIATE_WITH_PAID_FEATURE,

        UTIL = {}
    }) {
        this.WITHOUT_TITLE = WITHOUT_TITLE
        this.WITHOUT_DESCRIPTION = WITHOUT_DESCRIPTION
        this.ASSOCIATE_WITH_PAID_FEATURE = ASSOCIATE_WITH_PAID_FEATURE
        this.FIELD_TYPE = FIELD_TYPE
        this.READABLE_NAME = READABLE_NAME
        this.ICON_NAME = ICON_NAME
        this.ICON_COMPONENT = () => import(`@/components/Fields/FieldsViews/${this.FOLDER_NAME}/Icon`)
        this.FOLDER_NAME = FOLDER_NAME

        this.STATIC_FORM_MIN_WIDTH = STATIC_FORM_MIN_WIDTH

        this.FIELD_OBJ_EXTENSION = FIELD_OBJ_EXTENSION
        this.EDITABLE_VIEW = EDITABLE_VIEW
        this.SUBMITTABLE_VIEW = SUBMITTABLE_VIEW
        this.PREVIEW_VIEW = PREVIEW_VIEW
        this.SUBMISSION_TEMPLATE = SUBMISSION_TEMPLATE
        this.IS_SUBMISSION_EMPTY = IS_SUBMISSION_EMPTY
        this.SUBMIT_VALIDATION = SUBMIT_VALIDATION

        this.WITH_PRESS_ENTER = WITH_PRESS_ENTER
        this.AUTO_SCROLL_HANDLER = AUTO_SCROLL_HANDLER

        this.FSP = FSP

        this.CONDITIONAL_LOGIC = CONDITIONAL_LOGIC

        this.ANALYTICS = ANALYTICS
        if (this.ANALYTICS)
            this.ANALYTICS.initializeAsPartOfFieldType(this)

        this.BASE_PROPERTIES = BASE_PROPERTIES

        this.UTIL = UTIL

        if (this.FSP)
            this.FSP = _.isArray(this.FSP) ? this.FSP : [this.FSP]

        this.#validateAfterInitialization()

        if (this.FSP)
            this.FSP.forEach(fsp => fsp.initializeAsPartOfFieldType(this))
    }

    get WITH_SUBMISSION() {
        return !!this.SUBMISSION_TEMPLATE
    }

    #validateAfterInitialization() {
        if (!isFunction(this.FIELD_OBJ_EXTENSION))
            throw new FieldTypeConstError(this, '"FIELD_OBJ_EXTENSION" must be a function')

        if (this.SUBMISSION_TEMPLATE && !isFunction(this.SUBMISSION_TEMPLATE))
            throw new FieldTypeConstError(this, '"SUBMISSION_TEMPLATE" must be a function')

        if (this.FSP && !(this.FSP.every(v => v instanceof FieldFSPDefinition)))
            throw new FieldTypeConstError(this, `"FSP" must be instance of ${FieldFSPDefinition.name}`)

        if (!(this.BASE_PROPERTIES instanceof FieldTypeBasePropertiesConst))
            throw new FieldTypeConstError(this, `"BASE_PROPERTIES" must be instance of ${FieldTypeBasePropertiesConst.name}`)

        if (this.SUBMIT_VALIDATION && !(this.SUBMIT_VALIDATION instanceof FieldSubmitValidation))
            throw new FieldTypeConstError(this, `"SUBMIT_VALIDATION" must be instance of ${FieldSubmitValidation.name}`)
    }

    generateFieldObj() {
        return {
            field_type: this.FIELD_TYPE,
            title: '',
            ...(!this.WITHOUT_DESCRIPTION && {description: ''}),
            ...this.FIELD_OBJ_EXTENSION(),
            properties: this.BASE_PROPERTIES.generatePropertiesObj()
        }
    }

    generateSubmissionObj(...args) {
        return this.SUBMISSION_TEMPLATE(...args)
    }
}