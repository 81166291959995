<template>
    <div class="static-finish-page">
        <div class="static-finish-page__content">
            <div class="static-finish-page__content-emoji">
                <slot name="emoji"></slot>
            </div>
            <div class="static-finish-page__content-title">
                <slot name="title"></slot>
            </div>
            <div class="static-finish-page__content-description">
                <slot name="description"></slot>
            </div>
        </div>
        <div class="static-finish-page__footer-wrapper" v-if="$listeners.redirect && getFormSourceJSON.settings.general_settings.formsly_branding" >
            <div class="static-finish-page__footer">
                <span class="static-finish-page__footer-text">Create your own - it's free, easy & beautiful</span>
                <div class="static-page-action-button-wrapper">
                    <div
                            class="static-page-action-button"
                            @click="redirect"
                    >
                        <span class="static-page-action-button__text">Create a Form</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { useMapGetters } from '@/xstore'
    import { inject } from '@vue/composition-api'

    export default {
        name: 'StaticFinishPageTemplate',
        methods: {
            redirect() {
                if (this.$listeners.redirect) {
                    this.$emit('redirect');
                }
            }
        },
        setup() {
            return {
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getFormSourceJSON',
                ]),
            }
        }
    }
</script>