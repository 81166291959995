var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-scale-transition', {
    attrs: {
      "origin": "center"
    }
  }, [_vm.coverHovered && !_vm.getCurrentLogoURL ? _c('AddBadge', {
    staticClass: "static-form-logo-badge-add-logo-button",
    on: {
      "click": _vm.addLogo
    }
  }, [_vm._v(" Add logo ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }