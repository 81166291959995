var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFormCreationLayout', {
    attrs: {
      "cards": _vm.cards,
      "title": "How would you like it to be created?",
      "caption-above-the-title": "Step 2 of 3",
      "subtitle": "Start from scratch, or let our AI wizard build it."
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }