export default {
    name: "InteractiveHomePageContentHolder",
    functional: true,
    props: {
        align: {
            type: String,
            required: true,
            validator: v => ['left', 'center', 'right'].includes(v)
        }
    },
    render: (h, ctx) => h(
        'div',
        {
            class: [
                'interactive-home-page-content-holder',
                'interactive-home-page-content-holder--align-' + ctx.props.align,
            ]
        },
        [
            h('div', {class: 'interactive-home-page-content-holder__layout'}, [
                h('div', {class: 'interactive-home-page-content-holder__title'}, ctx.scopedSlots?.title?.()),
                h('div', {class: 'interactive-home-page-content-holder__subtitle'}, ctx.scopedSlots?.subtitle?.()),
                h('div', {class: 'interactive-home-page-content-holder__btn'}, ctx.scopedSlots?.btn?.()),
            ])
        ]
    )
}