import { required } from '@vuelidate/validators'

export class FieldSubmitValidation {
    static REQUIRED = fieldObj => (fieldObj.properties.required && {required})
    #RULES

    constructor({
        RULES
    } = {}) {
        this.#RULES = RULES
    }

    genRules(fieldObj) {
        return this.#RULES(fieldObj)
    }
}