var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', _vm._g({
    ref: "inputs",
    attrs: {
      "address-fields": _vm.sourceObj.properties.full_address_fields,
      "placeholders": _vm.fieldConst.UTIL.placeholders,
      "submission-obj": _vm.submissionObj,
      "gen-ref": _vm.genRef
    }
  }, _vm.inputAutofocusOn));
}
var staticRenderFns = []

export { render, staticRenderFns }