<template>
  <div class="paragraph-field-view generic-text-field-view">
    <v-textarea
        v-model="submissionObj.value"
        :placeholder="sourceObj.content.placeholder"
        class="generic-text-field-view__input-field"
        @keydown="handleKeydown"
        ref="input"
        hide-details
        @blur="showHint = false"
        @focus="showHint = true"
        :rows="rowsCount"
        :outlined="isFormStatic"
        auto-grow
    />
    <v-input v-if="showHint" class="paragraph-field-view__hint paragraph-field-view__hint--desktop" messages="Hit SHIFT + ENTER for new line"/>
    <div v-show="!showHint" style="height: 26px"></div>
    <v-input v-if="showHint" class="paragraph-field-view__hint paragraph-field-view__hint--mobile" messages="Hit RETURN for new line"/>
  </div>
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
import {
  use_submittable_field_input_autofocus_and_press_enter_mixin
} from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_input_autofocus_and_press_enter_mixin'

export default {
  name: "Submittable__Paragraph",
  mixins: [submittable_field_view_mixin, use_submittable_field_input_autofocus_and_press_enter_mixin('input')],
  data: () => ({
      rowsCount: 1,
      showHint: false,
  }),
  methods: {
    handleKeydown(e) {
      if (e.shiftKey && (e.which || e.keyCode) === 13) {
        this.rowsCount++;
      } else {
        this.inputAutofocusOn?.keydown?.(e)
      }
    }
  }
}
</script>