var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "style-preferences-templates-store"
  }, [_c('v-tabs', {
    staticClass: "blue-tabs",
    attrs: {
      "grow": "",
      "height": "36",
      "hide-slider": "",
      "show-arrow": false
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("Gallery")])]), _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_c('span', [_vm._v("My Themes")])])], 1), _c('v-tabs-items', {
    staticClass: "thin-scrollbar",
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab-item', _vm._l(_vm.getPublicThemesBasedOnFormType, function (themeObj) {
    return _c('StylePreferencesThemePreview', {
      key: themeObj.id,
      attrs: {
        "theme-obj": themeObj,
        "action-button-text": "Customize"
      },
      scopedSlots: _vm._u([{
        key: "action",
        fn: function () {
          return [_c('v-btn', {
            staticClass: "style-preferences-template-preview__action",
            attrs: {
              "color": "blue_400",
              "text": ""
            },
            on: {
              "click": function ($event) {
                return _vm.startPublicThemeEditing(themeObj);
              }
            }
          }, [_vm._v(" Customize ")])];
        },
        proxy: true
      }], null, true)
    });
  }), 1), _c('v-tab-item', [_c('v-btn', {
    staticClass: "font-weight-semi-bold",
    attrs: {
      "color": "gray_500",
      "text": ""
    },
    on: {
      "click": _vm.startNewPrivateThemeEditing
    }
  }, [_vm._v(" + New Theme ")]), _vm._l(_vm.getPrivateThemesBasedOnFormType, function (themeObj) {
    return _c('StylePreferencesThemePreview', {
      key: themeObj.id,
      attrs: {
        "theme-obj": themeObj,
        "action-button-text": "Edit"
      },
      scopedSlots: _vm._u([{
        key: "action",
        fn: function () {
          return [_c('v-menu', {
            attrs: {
              "transition": "scale-transition",
              "bottom": "",
              "offset-y": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref) {
                var on = _ref.on;
                return [_c('v-btn', _vm._g({
                  attrs: {
                    "color": "blue_400",
                    "icon": "",
                    "small": ""
                  }
                }, on), [_c('MoreHorizontalIcon', {
                  attrs: {
                    "size": "18"
                  }
                })], 1)];
              }
            }, {
              key: "default",
              fn: function () {
                return [_c('v-list', {
                  attrs: {
                    "dense": ""
                  }
                }, [_c('v-list-item', {
                  on: {
                    "click": function ($event) {
                      return _vm.startPrivateThemeEditing(themeObj);
                    }
                  }
                }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Edit")])], 1)], 1), _c('v-list-item', {
                  on: {
                    "click": function ($event) {
                      return _vm.deleteTheme(themeObj);
                    }
                  }
                }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Delete")])], 1)], 1)], 1)];
              },
              proxy: true
            }], null, true)
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }