<template>
    <v-container fluid class="h-full d-flex align-center justify-center">
        <v-slide-y-transition mode="out-in">
            <VerificationTemplate v-if="currentStage === stages.CREATE_NEW_PASSWORD">
                <template #icon>
                    <ErrorAlertComponent/>
                </template>
                <template #code-input>
                    <div class="text-start mb-2">New Password <span class="error--text">*</span></div>
                    <v-text-field
                            v-model="$v.newPassword.$model"
                            placeholder="Create a password"
                            class="rounded-lg"
                            type="password"
                            outlined
                            :disabled="$store.getters.isLoading"
                            :error-messages="$v.newPassword.$errors.map(({$message}) => $message)"
                    />
                    <div class="text-start mb-2">Confirm New Password <span class="error--text">*</span></div>
                    <v-text-field
                            placeholder="Create a password"
                            class="rounded-lg"
                            v-model="$v.confirmNewPassword.$model"
                            type="password"
                            outlined
                            :disabled="$store.getters.isLoading"
                            :error-messages="$v.confirmNewPassword.$errors.map(({$message}) => $message)"
                    />
                </template>
                <template #action>
                    <v-btn
                            block
                            depressed
                            rounded
                            color="blue_400"
                            :disabled="$store.getters.isLoading || !!$v.$silentErrors.length"
                            @click="resetPassword"
                    >
                        Reset Password
                    </v-btn>
                </template>
            </VerificationTemplate>
            <VerificationTemplate v-if="currentStage === stages.PASSWORD_SUCCESSFULLY_RESET">
                <template #icon>
                    <div class="pa-6 d-flex rounded-circle green_100">
                        <CheckCircleIcon class="green_500--text"/>
                    </div>
                </template>
                <template #title>
                    Password has been successfully reset
                </template>
                <template #description>
                    Click below to log in magically.
                </template>
                <template #action>
                    <v-btn
                            block
                            depressed
                            rounded
                            color="blue_400"
                            @click="continueLogin"
                    >
                        Continue
                    </v-btn>
                </template>
            </VerificationTemplate>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import { ErrorAlertEventBus } from '@/components/Alert/alert_mixin'
    import { useVuelidate } from '@vuelidate/core'
    import router from '@/router'
    import { email, minLength, required, sameAs, helpers } from '@vuelidate/validators'
    import { computed, ref } from '@vue/composition-api'

    export default {
        name: "ResetPasswordByLink",
        components: {
            ErrorAlertComponent: () => import('@/components/Alert/ErrorAlertComponent'),
            VerificationTemplate: () => import('@/components/LoginComponents/VerificationTemplate'),

            CheckCircleIcon: () => import('vue-feather-icons/icons/CheckCircleIcon')
        },
        setup(props, {root}) {
            const username = router.currentRoute.query.username
            const code = router.currentRoute.query.confirmation_code
            if (!username || !code || !email.$validator(username) || !/^\d{6}$/g.test(code))
                router.push({name: root.constants.PAGE_NAME_LOGIN})

            const stages = {
                CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
                PASSWORD_SUCCESSFULLY_RESET: 'PASSWORD_SUCCESSFULLY_RESET'
            }
            const currentStage = ref(stages.CREATE_NEW_PASSWORD)


            const newPassword = ref('')
            const confirmNewPassword = ref('')

            const resetPassword = () => $v.value.$validate()
                .then(validationResult => {
                    if (validationResult) {
                        root.promiseWithAnimation(
                            root.AuthActions.forgotPasswordSubmit(username, code, newPassword.value)
                                .then(() => currentStage.value = stages.PASSWORD_SUCCESSFULLY_RESET)
                                .catch(e => ErrorAlertEventBus.$emit('show', e.message))
                        )
                    }
                })
            const continueLogin = () => root.promiseWithAnimation(
                root.AuthActions.signIn(username, newPassword.value)
                    .catch(e => console.log(e))
            )

            const $v = useVuelidate(
                computed(() => ({
                    newPassword: {
                        required: helpers.withMessage('Required', required),
                        minLength: helpers.withMessage(`Must be at least ${root.constants.MIN_PASS_LENGTH} characters`, minLength(root.constants.MIN_PASS_LENGTH))
                    },
                    confirmNewPassword: {
                        required: helpers.withMessage('Required', required),
                        sameAsPassword: helpers.withMessage('Passwords must match', sameAs(newPassword))
                    }
                })),
                {
                    newPassword,
                    confirmNewPassword
                }
            )

            return {
                resetPassword,
                continueLogin,
                stages,
                currentStage,
                $v
            }
        }
    }
</script>