var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "conditional-logic-expression conditional-logic-expression--Options"
  }, [_c('f-autocomplete', {
    ref: "expressionInput",
    staticClass: "conditional-logic-expression__expression-input",
    attrs: {
      "items": _vm.optionsItems,
      "item-value": function (item) {
        return item.index;
      },
      "filter": function (item, search) {
        return item.text.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      },
      "placeholder": "Add option",
      "menu-props": {
        maxWidth: _vm.optionsMenuWidth,
        minWidth: _vm.optionsMenuWidth,
        nudgeRight: _vm.optionsMenuNudgeRight,
        nudgeBottom: 6,
        closeOnClick: true
      },
      "multiple": _vm.expressionObj.operator === 'matches_one_of' ? true : _vm.isMultiple,
      "outlined": "",
      "deletable-chips": "",
      "hide-details": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('f-select', {
          ref: "operatorPicker",
          staticClass: "conditional-logic-expression__operator-picker",
          attrs: {
            "items": _vm.expressionOperators,
            "item-text": function (item) {
              return item.text;
            },
            "item-value": function (item) {
              return item.value;
            },
            "menu-props": {
              nudgeBottom: 54
            },
            "hide-details": "",
            "solo": "",
            "flat": "",
            "dense": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          },
          model: {
            value: _vm.expressionObj.operator,
            callback: function ($$v) {
              _vm.$set(_vm.expressionObj, "operator", $$v);
            },
            expression: "expressionObj.operator"
          }
        })];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var on = _ref.on,
          item = _ref.item,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.Utils.convertIndexToLetter(item.index)) + ". " + _vm._s(item.text) + " ")])], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          parent = _ref2.parent;
        return [_c('v-chip', {
          key: JSON.stringify(item),
          staticClass: "my-1",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_c('span', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(_vm.Utils.convertIndexToLetter(item.index)) + ". " + _vm._s(item.text) + " ")]), _c('XIcon', {
          staticClass: "cursor-pointer ml-1",
          attrs: {
            "size": "12"
          },
          on: {
            "click": function ($event) {
              _vm.isMultiple || _vm.expressionObj.operator === 'matches_one_of' ? parent.selectItem(item) : _vm.expressionObj.statement = [];
            }
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.conditionStatement,
      callback: function ($$v) {
        _vm.conditionStatement = $$v;
      },
      expression: "conditionStatement"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }