<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="file-upload-field-view-summary">
                <f-template-data-transfer
                        v-for="(item, index) in filteredSubmissions"
                        :isIntersected="false"
                        :key="index"
                         #default="{dataStash}"
                >
                    <FullSingleAnswer
                            :submissionObj="item.submissionObj"
                            :sourceObj="versionedSources[item.versionID]"
                            :allowUploading="dataStash.isIntersected"
                            v-intersect.once="() => dataStash.isIntersected = true"
                            class="file-upload-field-view-summary__item"
                    />
                </f-template-data-transfer>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__FileUpload",
        components: {
            FullSingleAnswer: () => import('./FullSingleAnswer'),
        },
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {value}}) => value)
            }
        }
    }
</script>