import constants from '@/constants/constants'
import { deleteSubmissions as deleteSubmissionsMutation } from '@formsly/shared/api/graphql'
import { listSubmissions } from '@formsly/shared/api/graphql'
import {
  convertTimestamps,
  executeGraphQLMutationDelete,
  executeGraphQLQueryGet,
  unescapeRawData,
} from '@/utils/graphql_executors'

import StorageActions from './storage_actions'

const fetchFormSubmissions = ({
  formID,
  offset = 0,
  limit = constants.SUBMISSIONS_LIMIT,
}) =>
  executeGraphQLQueryGet(listSubmissions, {
    formID,
    limit,
    offset,
  }).then(submissions => {
    return {
      count: submissions.count,
      items: submissions.items.map(submission => ({
        ...convertTimestamps(submission),
        rawData: unescapeRawData(
          submission.rawData.replace(/\\([\s\S])/g, '$1')
        ),
      })),
    }
  })

const deleteSubmissions = ids =>
  executeGraphQLMutationDelete(deleteSubmissionsMutation, { ids })

const getAttachment = name =>
  StorageActions.getObject(constants.S3_UPLOADS_FOLDER_PATH + name)

const fetchAllFormSubmissions = ({
  formID,
  offset = 0,
  limit = 1000000,
}) =>
  executeGraphQLQueryGet(listSubmissions, {
    formID,
    limit,
    offset,
  }).then(submissions => {
    return {
      count: submissions.count,
      items: submissions.items.map(submission => ({
        ...convertTimestamps(submission),
        rawData: unescapeRawData(
          submission.rawData.replace(/\\([\s\S])/g, '$1')
        ),
      })),
    }
  })

export default {
  fetchFormSubmissions,
  deleteSubmissions,
  getAttachment,
  fetchAllFormSubmissions,
}
