<template>
    <FormslyFormWrapper width="1920">
        <FSPVariablesInjector :fsp-module-location-path="fspModuleLocationPath">
            <template v-if="formJSON">
                <div v-if="isFormStatic" :class="[
            'static-form',
            (formJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (formJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--full-screen visible-header',
            (formJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN) && (formJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--full-screen invisible-header',
            (formJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (formJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE) && 'static-form--layout-style--compact visible-header',
            (formJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT) && (formJSON.layout_header_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE) && 'static-form--layout-style--compact invisible-header',
        ]" :style="{
            ...(staticFormBackgroundColor && { '--static-form-background-color': staticFormBackgroundColor }),
            transformOrigin: 'top',
            ...staticFormScale
        }">
                    <div class="static-form__container">
                        <div class="static-form__cover-wrapper">
                            <div class="static-form__cover"
                                :style="{ backgroundImage: 'url(' + formJSON.cover_image + ')' }">
                            </div>
                            <!-- <div class="static-form__cover" ref="cover"
                                :style="coverStyle">
                                </div> -->
                            <div class="moveable-area h-full d-flex justify-center align-center text-h3">
                                <span
                                    :style="{ transform: formJSON.cover_text.translate, height: formJSON.cover_text.height, width: formJSON.cover_text.width }"
                                    class="text-container text-container--submittable"
                                    v-html="formJSON.cover_text.content" ref="textContainer"></span>
                            </div>
                        </div>
                        <div :class="[
            'static-form__page-wrapper',
            (formJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE) && ' visible-logo',
            (formJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE) && 'invisible-logo',
        ]">
                            <LogoBadge :logo-url="formJSON.logo_url" />

                            <div class="static-form__page">
                                <div class="static-main-page">
                                    <div class="static-main-page__content">
                                        <div class="static-main-page-fields-layout">
                                            <v-row v-for="(row, rowIndex) in currentPageObj.rows" :key="rowIndex">
                                                <v-col v-for="field in row.fields" :key="field.field_id">
                                                    <div class="static-submittable-field-constructor">
                                                        <GenericField
                                                            :modifiers="['static', 'submittable', `width-${field.width}`]"
                                                            :field-id="field.field_id"
                                                            :field-obj="formJSON.fields[field.field_id]"
                                                            :field-type-const="constants.FIELD_FROM_TYPE[formJSON.fields[field.field_id].field_type]">
                                                            <div class="generic-field__view">
                                                                <component
                                                                    :is="constants.FIELD_FROM_TYPE[formJSON.fields[field.field_id].field_type].PREVIEW_VIEW"
                                                                    :field-obj="formJSON.fields[field.field_id]"
                                                                    :is-form-static="isFormStatic"
                                                                    :is-form-interactive="isFormInteractive" />
                                                                <div class="generic-field__error-messages-wrapper">
                                                                </div>
                                                            </div>
                                                        </GenericField>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="isFormInteractive" class="interactive-form interactive-form--submittable"
                    :style="{ transformOrigin: 'top', ...interactiveFormScale }">
                    <InteractiveHomePage :page-obj="formJSON.all_pages[formJSON.home_page.page_id]" />
                </div>
            </template>
        </FSPVariablesInjector>
    </FormslyFormWrapper>
</template>

<script>
import { computed, onMounted, provide, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
    name: "EmbeddedFormPreview",
    components: {
        GenericField: () => import('@/components/FormGenericComponents/GenericField/GenericField'),
        LogoBadge: () => import('@/components/FormSubmit/StaticForm/LogoBagde/LogoBadge'),
        FSPVariablesInjector: () => import('@/FSP/FSPVariablesInjector'),
        DragHook: () => import('@/components/Elements/Icons/DragHook'),
        FormslyFormWrapper: () => import('@/components/FormGenericComponents/FormslyFormWrapper'),
        InteractiveHomePage: () => import('@/components/FormSubmit/InteractiveForm/Pages/HomePage/InteractiveHomePage'),

    },
    props: {
        staticFormScale: Object,
        interactiveFormScale: Object,
        formJSON: {
            type: Object,
            required: true,
        },
        isFormStatic: {
            type: Boolean,
            required: true,
        },
        isFormInteractive: {
            type: Boolean,
            required: true,
        },
        fspModuleLocationPath: {
            type: String,
        }
    },
    setup(props, { root, refs }) {

        provide('formJSON', props.formJSON)
        provide('isFormStatic', props.isFormStatic)

        const currentPageObj = computed(() => {
            if (props.isFormStatic)
                return props.formJSON.all_pages?.[props.formJSON?.main_pages?.[0]?.page_id]
            else if (props.isFormInteractive)
                return props.formJSON.all_pages?.[props.formJSON?.home_page?.page_id]
        })

        const staticFormBackgroundColor = ref(undefined)

        onMounted(async () => {
            await Vue.nextTick()

            /* Preload the cover image */
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = props.formJSON.cover_image;
            document.head.appendChild(link);

            if (props.isFormStatic) {
                root.Utils.getAverageColorFromImageURL(props.formJSON.cover_image)
                    .then(color => staticFormBackgroundColor.value = color)
                if (refs.textContainer && refs.textContainer.querySelectorAll) {
                    const usedFonts = Array.from(refs.textContainer.querySelectorAll('font')).filter(elem => elem.hasAttribute('face')).map(elem => elem.getAttribute('face'))
                    usedFonts.forEach(font => {
                        root.constants.TEXT_EDITOR_CONSTANTS.FONTS.forEach(item => {
                            if (font === item.name)
                                root.Utils.addFontFamilyURL(item.url)
                        })
                    })
                }
            }
        })

        return {
            currentPageObj,
            staticFormBackgroundColor,
        }
    }
}
</script>

<style lang="scss" scoped>

.visible-header .static-form__container .static-form__cover-wrapper,
.visible-logo .static-form-logo-badge {
    display: block;
}

.invisible-header .static-form__container .static-form__cover-wrapper,
.invisible-logo .static-form-logo-badge {
    display: none;
}
</style>