<template>
    <v-menu
            v-model="dialog"
            content-class="interactive-new-field-selection-dialog"
            max-width="730"
            :close-on-content-click="false"
            offset-x
            nudge-right="28"
            nudge-top="15"
            attach=".form-composition-card"
    >
        <template #activator="{ on }">
            <v-tooltip
                    :value="tooltip"
                    color="bluish_gray_500"
                    content-class="tooltip-right"
                    right
            >
                <template #activator="{ on: tooltipOn }">
                    <v-btn
                            v-on="on"
                            @click="tooltip = false"
                            class="v-btn--primary"
                            :unused="tooltipOn"
                            color="dark_gray"
                            text
                            icon
                            small
                    >
                        <PlusCircleIcon class="plus-icon" stroke-width="1.5"/>
                    </v-btn>
                </template>
                Add new question
            </v-tooltip>
        </template>
        <template #default>
            <v-card min-width="700" ref="card" class="v-card" :style="fixedDialogHeight">
                <v-card-title>
                    <span>Add New Question</span>
                    <v-spacer/>
                    <SearchBarComponent
                            v-model="searchQuery"
                            lowercase
                            @input.once="setFixedDialogHeight"
                    />
                </v-card-title>
                <v-card-text>
                    <v-container class="interactive-new-field-selection-dialog-container">
                        <template
                                v-for="(fieldsGroup, groupKey) in filteredFieldsGroups"
                        >
                            <v-row
                                    class="interactive-new-field-selection-dialog-row"
                                    :key="groupKey"
                            >
                                <v-col
                                        v-for="fieldConst in fieldsGroup"
                                        :key="fieldConst.FIELD_TYPE"
                                        cols="4"
                                >
                                    <div
                                            @click="addNewInteractiveField(fieldConst.FIELD_TYPE)"
                                            class="interactive-new-field-selection-field-tile"
                                    >
                                        <component
                                                :is="fieldConst.ICON_COMPONENT"
                                                width="36"
                                                height="36"
                                        />
                                        <span>{{fieldConst.READABLE_NAME}}</span>
                                        <v-spacer/>
                                        <f-hover-tooltip
                                                v-if="getCurrentSubscriptionFeatures && fieldConst.ASSOCIATE_WITH_PAID_FEATURE &&
                                                        !getCurrentSubscriptionFeatures[fieldConst.ASSOCIATE_WITH_PAID_FEATURE]"
                                                max-width="250"
                                                top
                                                text="You can try this paid feature, but you'll need to remove it to publish your form"
                                                v-slot="{on}"
                                        >
                                            <f-badge v-on="on"
                                                     purple
                                                     @click.stop="upgradeToProFromModal"
                                            >
                                                Paid
                                            </f-badge>
                                        </f-hover-tooltip>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class="interactive-new-field-selection-dialog-container-divider"
                                       v-if="groupKey !==  filteredFieldsGroups.length - 1"
                                       :key="groupKey + 'divider'"></v-divider>
                        </template>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>
    </v-menu>
</template>

<script>
    import {PlusCircleIcon} from 'vue-feather-icons'
    import {mapGetters} from "@/xstore";

    export default {
        name: "InteractiveNewFieldSelectionDialog",
        components: {
            PlusCircleIcon,
            SearchBarComponent: () => import('@/components/Elements/SearchBarComponent')
        },
        data: () => ({
            dialog: false,
            searchQuery: '',
            tooltip: false,
            fixedDialogHeight: {
                height: 'auto'
            },
        }),
        computed: {
            ...mapGetters('user/plan/current_subscription', [
                'getCurrentSubscriptionFeatures',
            ]),
            filteredFieldsGroups() {
                return this.constants.FIELD_TYPES_BY_GROUPS
                    .map(group => group
                        .filter(field => field.READABLE_NAME.toLowerCase().includes(this.searchQuery.toLowerCase()))
                    )
                    .filter(group => group.length)
            }
        },
        mounted() {
            if (this.SequenceDirector.getSequence(this.constants.NEW_FORM_TUTORIAL)) {
                this.SequenceDirector.setStepAction(this.constants.NEW_FORM_TUTORIAL, 1, () => {
                    this.tooltip = true
                })
            }

        },
        methods: {
            addNewInteractiveField(fieldType) {
                this.$store.dispatch('edit/fields/createNewFormField', fieldType)
                    .then(newFieldId =>
                        this.$store.dispatch('edit/pages/addNewFormMainPage', {
                            ...this.constants.FORM_TYPES.INTERACTIVE_FORM.MAIN_PAGE_TEMPLATE(),
                            field_id: newFieldId
                        })
                    )
                    .then(newPageId => {
                        this.dialog = false
                        this.$store.dispatch('edit/pages_navigation/setCurrentPageId', newPageId)
                    })
            },
            setFixedDialogHeight() {
                this.fixedDialogHeight.height = this.$refs.card.$el.clientHeight + 'px';
            },
            upgradeToProFromModal() {
                this.$store.dispatch('user/plan/current_subscription/monitoring_features/monitoring_features_dialog/showDialog', true);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .interactive-new-field-selection-dialog {
        -webkit-box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 82px -4px rgba(24, 39, 75, 0.12) !important;
        -moz-box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 82px -4px rgba(24, 39, 75, 0.12) !important;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 82px -4px rgba(24, 39, 75, 0.12) !important;
        z-index: 20 !important;

        .interactive-new-field-selection-dialog-container {
            margin: 0;
            padding: 0;

            &-divider {
                border-color: #F2F2F2;
            }

            .interactive-new-field-selection-dialog-row {
                margin: 0;
                padding: 0;

                .interactive-new-field-selection-field-tile {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 8px;
                    padding: 5px;

                    .f-badge {
                        cursor: pointer;
                    }
                    
                    &:hover {
                        box-shadow: 0px 3px 8px 1px rgba(24, 39, 75, 0.06);
                    }

                    span {
                        margin-left: 15px;
                        font-size: .875rem;
                        font-weight: 500;
                        color: var(--v-bluish_gray_400-base);
                    }
                }

                .col {
                    padding: 0;
                    flex: 0 0 30%;
                    justify-content: space-between;
                    margin-right: 20px;
                }

                .col:nth-child(n+4) {
                    margin-top: 12px;
                }
            }
        }

        .v-card {

            &__title {
                padding: 38px 40px 0;

                .search-bar {
                    height: 24px;
                }
            }

            &__text {
                padding: 38px 40px 40px;
            }

            ::v-deep &__text {
            }
        }

        .v-divider {
            margin: 19px 0;
        }
    }
</style>