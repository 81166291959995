var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select', {
    staticClass: "flex-grow-0 summary-change-sort-order",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "items": _vm.options,
      "item-value": function (item) {
        return item.value;
      },
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "menu-props": {
        nudgeBottom: 38,
        left: true
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', [_vm._v(_vm._s(item.icon))])];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item,
          on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }