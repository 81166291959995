<script>
  import { mapGetters } from '@/xstore'

  export default {
    name: 'FormPreviewCard',
    components: {
      ArrowRightIcon: () => import('vue-feather-icons/icons/ArrowRightIcon'),
      FormActionsComponent: () =>
        import('@/components/Dashboard/FormActionsComponent'),
      EmbeddedFormPreview: () =>
        import(
          '@/components/Dashboard/EmbeddedFormPreview/EmbeddedFormPreview'
        ),
      ShareFormMenu: () => import('@/components/Elements/ShareFormMenu'),
    },
    props: {
      formData: {
        type: Object,
        required: true,
      },
      staticFormScale: Object,
      interactiveFormScale: Object,
    },
    data: () => ({
      shareModalVisible: false,
    }),
    computed: {
      ...mapGetters('forms_list', ['getFormsLoadingStatuses']),
      formCreationDate() {
        return this.formData.createdAt.format('MMM D, YYYY')
      },
      responsesCount() {
        return this.$store.getters[
          `forms_list/${this.formData.id}/metrics/getTotalResponsesMetric`
        ]
      },
      formJson() {
        return this.$store.getters[
          `forms_list/${this.formData.id}/preview/getFormJson`
        ]
      },
      shareLink() {
        return (
          window.location.origin +
          this.$router.resolve({
            name: this.constants.PAGE_NAME_FORM_SUBMIT,
            params: { formId: this.formData.id },
          }).href
        )
      },
    },
    methods: {
      startEditingForm() {
        this.$router.push({
          name: this.constants.PAGE_NAME_FORM_EDITOR,
          params: {
            formId: this.formData.id,
          },
        })
      },
      goToSubmissionsAnalytics() {
        this.$router.push({
          name: this.constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
          params: {
            formId: this.formData.id,
          },
        })
      },
      handleLoaderUpdate(loaderValue) {
        this.$emit('update-loader-from-child1', loaderValue)
      }
    },
  }
</script>

<template>
  <v-card
    class="form-preview-card"
    tile
  >
    <f-aspect-ratio-keeper
      width="16"
      height="9"
    >
      <div
        class="embedded-dashboard-form-preview__form-container"
        @click="startEditingForm"
      >
        <div class="embedded-dashboard-form-preview__form-scaler">
          <EmbeddedFormPreview
            v-if="getFormsLoadingStatuses[formData.id]"
            :fsp-module-location-path="`forms_list/${formData.id}/preview/FSP`"
            :form-j-s-o-n="formJson"
            :is-form-static="
              $store.getters[`forms_list/${formData.id}/preview/isFormStatic`]
            "
            :is-form-interactive="
              $store.getters[
                `forms_list/${formData.id}/preview/isFormInteractive`
              ]
            "
            :static-form-scale="staticFormScale"
            :interactive-form-scale="interactiveFormScale"
          />
        </div>
      </div>
    </f-aspect-ratio-keeper>
    <div class="form-preview-card__body">
      <div class="form-preview-card__info-container">
        <div
          class="form-preview-card__info"
          @click="startEditingForm"
        >
          <div class="form-preview-card__date">
            {{ formCreationDate }}
          </div>
          <div class="form-preview-card__name">
            {{ formData.name.length ? formData.name : 'Untitled' }}
          </div>
        </div>
        <FormActionsComponent
          v-if="getFormsLoadingStatuses[formData.id]"
          :form-data="formData"
          :form-json="formJson"
          @share="shareModalVisible = true"
          @update-loader="handleLoaderUpdate"
        />
      </div>
      <div class="form-preview-card__footer">
        <f-hover-tooltip
          v-slot="slotProps"
          bottom
          text="View Responses"
        >
          <div
            class="form-preview-card__responses-btn"
            v-on="slotProps.on"
            @click="goToSubmissionsAnalytics"
          >
            <span
              v-visible="responsesCount !== undefined"
              class="d-flex align-center"
            >
              <span class="mr-1">{{ responsesCount }}</span>
              Responses
            </span>
            <ArrowRightIcon size="12" />
          </div>
        </f-hover-tooltip>
      </div>
    </div>
    <v-dialog
      v-model="shareModalVisible"
      width="776"
    >
      <ShareFormMenu
        :share-link="shareLink"
        :fsp-module-location-path="`forms_list/${formData.id}/preview/FSP`"
        :form-j-s-o-n="formJson"
        :is-form-static="
          $store.getters[`forms_list/${formData.id}/preview/isFormStatic`]
        "
        :is-form-interactive="
          $store.getters[`forms_list/${formData.id}/preview/isFormInteractive`]
        "
        @input="shareModalVisible = false"
      >
        <template #title>
          {{ formData.name.length ? formData.name : 'Untitled' }}
        </template>
      </ShareFormMenu>
    </v-dialog>
  </v-card>
</template>

<style lang="scss" scoped>
  .form-preview-card {
    box-shadow: 0px 16px 26px rgba(0, 0, 0, 0.04) !important;
    transition: all 0.2s ease-in-out !important;
    padding: 0 !important;

    .form-preview-card-embedded-form-preview-wrapper {
      display: flex;
      width: 100%;
      min-width: 0;
      max-width: 100%;

      &__sizer-width {
        height: 0;
        padding-bottom: 56.25%;
        flex-grow: 1;
      }

      &__responsive-width {
        cursor: pointer;
        margin-left: -100%;
        max-width: 100%;
        flex-grow: 1;
        position: relative;
        overflow: hidden;
      }

      &__sizer-height {
        height: 100%;
      }

      &__responsive-height {
        width: 100%;
        height: 100%;
        display: table;
        top: 0;
        position: absolute;
        zoom: 0.3;
        max-height: 100%;
        left: 0;
        pointer-events: none;
      }
    }

    &:hover {
      box-shadow: 0 8px 26px rgba(100, 110, 145, 0.2) !important;
      transform: scale(1.03);
    }

    &__body {
      padding: 16px 30px;
      min-height: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .form-preview-card__info-container {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .form-preview-card__info {
          flex-grow: 1;
          min-width: 0;
          cursor: pointer;

          .form-preview-card__date {
            font-size: 0.875rem;
            color: var(--v-grey_500-base);
            line-height: 24px;
          }

          .form-preview-card__name {
            font-weight: 500;
            color: var(--v-bluish_gray_500-base);
            line-height: 28px;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .form-preview-card__footer {
        margin-top: 13px;
        display: flex;

        .form-preview-card__responses-btn {
          border-radius: 6px;
          color: black;
          cursor: pointer;
          padding: 0 9px;
          margin-left: -9px;

          display: flex;
          align-items: center;

          &:hover {
            color: var(--v-blue_400-base);
            background-color: var(--v-blue_100-base);

            svg {
              opacity: 1;
            }
          }

          span {
            font-size: 0.875rem;
            line-height: 30px;
          }

          svg {
            opacity: 0;
            margin-left: 5px;
          }
        }
      }
    }

    .embedded-dashboard-form-preview {
      &__form-container {
        display: flex;
        height: 100%;
        justify-content: center;
        cursor: pointer;
      }

      &__form-scaler {
        width: 1920px;
        height: 1080px;
        transform-origin: top;

        flex: 1 0 1920px;

        pointer-events: none;
      }
    }
  }
  .v-dialog {
    .share-form-menu {
      padding: 32px !important;
      ::v-deep & > * {
        padding: 0 !important;
      }
    }
  }
</style>
