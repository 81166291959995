var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "star-rating-field-view star-rating-field-view--short-single-answer-analytics"
  }, [_vm.submissionObj.value === null ? _c('div', {
    staticClass: "star-rating-field-view__layout"
  }, [_c('span', {
    staticClass: "star-rating-field-view__item"
  }, [_vm._v("-")])]) : _vm.isEmojiScale ? _c('div', {
    staticClass: "star-rating-field-view__layout star-rating-field-view__layout--emoji-scale"
  }, [_c('span', {
    staticClass: "star-rating-field-view__item"
  }, [_vm._v(" " + _vm._s(_vm.fieldConstProperties.emoji_scale.input_group.default.sets[_vm.sourceObj.properties.emoji_scale][_vm.submissionObj.value]) + " ")])]) : _vm.isScaleIcon ? _c('div', {
    staticClass: "star-rating-field-view__layout star-rating-field-view__layout--scale-icon"
  }, [_c('span', {
    staticClass: "star-rating-field-view__item"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.submissionObj.value + 1) + "/5")]), _c('LottieIconPlayer', {
    attrs: {
      "json": _vm.fieldConstProperties.scale_icon.input_group.default.emojis[_vm.sourceObj.properties.scale_icon],
      "active": ""
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }