<template>
    <div class="checkout-selected-plan-amount">
        <div>
            <div class="checkout-selected-plan-amount__heading">
                <div class="checkout-selected-plan-amount__title">
                    <span>Your new plan</span>
                </div>
            </div>
            <div class="checkout-selected-plan-amount__content-wrapper">
                <f-circle-loader
                        v-if="getOrderSummaryIsLoading"
                        class="checkout-selected-plan-amount__loader"
                        size="4"
                />
                <div
                        v-else
                        class="checkout-selected-plan-amount__content"
                >
                    <div class="checkout-selected-plan-amount__lines-wrapper">
                        <div class="checkout-selected-plan-amount__lines">
                            <div class="checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--plan">
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__plan-name">{{ {free: 'Free', pro: 'Pro', business: 'Business'}?.[getSelectedPriceObj?.productID] }}</span>&nbsp;
                                    <span class="checkout-selected-plan-amount-line-item__billing-period">({{ $options.upperFirst(getSelectedPriceObj?.recurring_interval) + 'ly' }})</span>
                                </div>
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__price">${{ newPlanInitialPrice / 100 }} USD</span>
                                </div>
                            </div>
                            <div class="checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--responses">
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__responses-count">{{responsesPerMonth}} responses / mo</span>
                                </div>
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__price"></span>
                                </div>
                            </div>
                            <div
                                    v-if="getOrderSummaryLineItems?.prorated_adjustment"
                                    class="checkout-selected-plan-amount-line-item__prorated-adjustment"
                            >
                                <div class="checkout-selected-plan-amount-line-item">
                                    <div>
                                        <span class="checkout-selected-plan-amount-line-item__name">Prorated adjustment</span>
                                    </div>
                                    <div>
                                        <span class="checkout-selected-plan-amount-line-item__value">-${{ getOrderSummaryLineItems?.prorated_adjustment?.amount / 100 }}</span>
                                    </div>
                                </div>
                                <div class="checkout-selected-plan-amount-line-item__prorated-adjustment-hint">
                                    <span>({{ getOrderSummaryLineItems?.prorated_adjustment?.period.start | timestampToDate }} - {{ getOrderSummaryLineItems?.prorated_adjustment?.period.end | timestampToDate }})</span>
                                </div>
                            </div>
                            <div
                                    v-if="getOrderSummaryLineItems?.available_credit_balance"
                                    class="checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--available-credits"
                            >
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__name">Available credits</span>
                                </div>
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__value">-${{ getOrderSummaryLineItems?.available_credit_balance / 100 }}</span>
                                </div>
                            </div>
                            <div class="checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--tax">
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__name">Tax</span>
                                </div>
                                <div>
                                    <span class="checkout-selected-plan-amount-line-item__value">No tax</span>
                                </div>
                            </div>
                            <div
                                    v-if="getSelectedBillingPeriod === 'year'"
                                    class="checkout-selected-plan-amount__line-discount checkout-selected-plan-amount__line-discount--success"
                            >
                                <span>Yearly discount (-20%)</span>
                                <span>-${{ yearlyDiscount / 100 }} USD</span>
                            </div>
                        </div>
                    </div>

                    <v-divider/>
                    <div class="checkout-selected-plan-amount__total">
                        <div>
                            <span>Total due today</span>
                            <span>(excl. tax)</span>
                        </div>
                        <div>
                            <span>${{ getOrderSummaryLineItems?.total / 100 }} USD</span>
                        </div>
                    </div>
                    <v-divider/>

                    <div class="checkout-selected-plan-amount__confirm-btn-wrapper">
                        <slot
                                name="button"
                                :attrs="{
                                    color: 'blue_400',
                                    rounded: true,
                                    block: true
                                }"
                        />
                    </div>

                    <div
                            v-if="getOrderSummaryLineItems?.credit_balance_after"
                            class="checkout-selected-plan-amount__credit-balance-after"
                    >
                        <div>
                            <span>Credit balance after</span>
                        </div>
                        <div>
                            <span>${{ getOrderSummaryLineItems?.credit_balance_after/ 100 }}</span>
                        </div>
                    </div>

                    <slot
                            name="hint"
                            :component="{name: 'SelectedPlanAmount__Hint', functional: true, render: (h, ctx) => h('p', {class: 'checkout-selected-plan-amount__hint'}, ctx.children)}"
                            :order-summary="getOrderSummary"
                            :selected-price-obj="getSelectedPriceObj"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import { upperFirst } from 'lodash'
    import moment from 'moment'

    export default {
        name: "SelectedPlanAmount",
        upperFirst,
        computed: {
            ...mapGetters('user/plan', [
                'getPlansObject',
                'getPricesObject'
            ]),
            ...mapGetters('user/plan/checkout', [
                'getOrderSummary',
                'getOrderSummaryIsLoading',
                'getOrderSummaryLineItems',
                'getOrderSummaryRenewal'
            ]),
            ...mapGetters('user/plan/checkout/selected_plan', [
                'getSelectedBillingPeriod',
                'getSelectedPrice'
            ]),
            newPlanInitialPrice() {
                if (this.getSelectedBillingPeriod === 'month')
                    return this.getSelectedPriceObj?.amount
                return this.getPlansObject?.[this.getSelectedPriceObj.productID]?.prices?.month?.amount * 12
            },
            yearlyDiscount() {
                return this.newPlanInitialPrice - this.getSelectedPriceObj?.amount
            },
            getSelectedPriceObj() {
                return this.getPricesObject?.[this.getSelectedPrice]
            },
            responsesPerMonth() {
                return this.getPlansObject[this.getSelectedPriceObj.productID].features.responses_per_month
            },
        },
        filters: {
            timestampToDate(value) {
                return moment.unix(value).format('MMM D, YYYY')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-selected-plan-amount {
        & > div {
            border-radius: 10px;
            box-shadow:
                0 0 0 1px var(--v-gray_300-base),
                0px 5px 10px rgba(0, 0, 0, 0.02),
                0px 7px 24px rgba(155, 155, 155, 0.12);
            background-color: white;

            padding: 20px;
            min-width: calc(244px + 20px * 2);
        }

        &__heading {
            margin-bottom: 24px;
        }

        &__content-wrapper {
            display: flex;
            min-height: 330px;
        }

        &__loader {
            align-self: center;
        }

        &__content {
            flex-grow: 1;
        }

        &__title {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.04em;
            font-weight: 600;
        }

        &__lines-wrapper {}

        &__lines {
            .checkout-selected-plan-amount-line-item {
                justify-content: space-between;

                &, & > * {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                * {
                    font-size: 13px;
                    line-height: 16px;
                }

                &--plan {
                    margin-bottom: 8px;
                }

                &--responses {
                    margin-bottom: 17px;
                }

                &--available-credits, &--tax {
                    margin-bottom: 16px;
                }

                &__plan-name {
                    font-weight: 600;
                }

                &__price {
                    font-weight: 500;
                }

                &--available-credits {
                    .checkout-selected-plan-amount-line-item__value {
                        font-weight: 500;
                    }
                }
            }

            .checkout-selected-plan-amount-line-item__prorated-adjustment {
                margin-bottom: 16px;

                .checkout-selected-plan-amount-line-item {
                    margin-bottom: 8px;

                    color: var(--v-green_500-base);

                    &__value {
                        font-weight: 500;
                    }
                }

                .checkout-selected-plan-amount-line-item__prorated-adjustment-hint {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                    color: var(--v-gray_400-base);
                }
            }
        }

        &__line-discount {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: 8px;
            border-radius: 8px;

            margin-bottom: 24px;

            * {
                font-size: 13px;
                line-height: 16px;
            }

            *:first-child {
                font-weight: 400;
            }

            *:last-child {
                font-weight: 500;
            }

            &--success {
                background-color: var(--v-green_100-base);
                color: var(--v-green_500-base);
            }
        }

        &__total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;

            *:first-child {
                display: flex;
                flex-direction: column;
                line-height: 17px;

                *:first-child {
                    font-size: 16px;
                }

                *:last-child {
                    font-size: 12px;
                }
            }

            *:last-child {
                display: flex;
                align-items: center;

                font-size: 16px;
                font-weight: 500;
            }
        }

        &__confirm-btn-wrapper {
            margin: 24px 0;
        }

        &__credit-balance-after {
            margin-bottom: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            color: var(--v-green_500-base);

            & > * {
                font-size: 13px;
                line-height: 16px;

                &:first-child {
                    font-weight: 400;
                }

                &:last-child {
                    font-weight: 500;
                }
            }
        }

        &__hint {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            color: var(--v-gray_400-base);

            &:not(:last-child) {
                margin-bottom: 12px !important;
            }
        }
    }
</style>