export default {
    name: 'AddBadge',
    functional: true,
    render(h, ctx) {
        return h(
            'div',
            {
                class: ['static-form-badge-add', ctx.data.staticClass],
                on: {
                    ...ctx.listeners,
                }
            },
            [h(
                'span',
                null,
                ctx.scopedSlots.default?.(),
            )]
        )
    }
}