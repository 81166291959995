var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "smart-paragraph-field-view__submittable-variable-container",
    class: _vm.error && 'smart-paragraph-field-view__submittable-variable-container--error'
  }, [_c('span', {
    ref: "variableInput",
    attrs: {
      "contenteditable": !_vm.preview
    },
    on: {
      "paste": _vm.processPaste,
      "input": _vm.input
    }
  }, [_vm._v("​")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }