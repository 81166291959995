<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
        <path d="M18.75 1.5H5.25C4.42266 1.5 3.75 2.17266 3.75 3V21C3.75 21.8273 4.42266 22.5 5.25 22.5H18.75C19.5773 22.5 20.25 21.8273 20.25 21V3C20.25 2.17266 19.5773 1.5 18.75 1.5ZM18.5625 20.8125H5.4375V3.1875H18.5625V20.8125ZM11.0625 18.375C11.0625 18.6236 11.1613 18.8621 11.3371 19.0379C11.5129 19.2137 11.7514 19.3125 12 19.3125C12.2486 19.3125 12.4871 19.2137 12.6629 19.0379C12.8387 18.8621 12.9375 18.6236 12.9375 18.375C12.9375 18.1264 12.8387 17.8879 12.6629 17.7121C12.4871 17.5363 12.2486 17.4375 12 17.4375C11.7514 17.4375 11.5129 17.5363 11.3371 17.7121C11.1613 17.8879 11.0625 18.1264 11.0625 18.375Z"
              fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "Tablet"
    }
</script>

<style scoped>

</style>