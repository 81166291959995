var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.submissionObj.value ? _c('div', {
    staticClass: "file-upload-field-view-full-single-answer"
  }, [_vm.submissionObj.value.isImage ? _c('v-menu', {
    attrs: {
      "nudge-top": "25",
      "nudge-right": "75",
      "open-on-hover": "",
      "top": "",
      "eager": "",
      "transition": "slide-y-reverse-transition",
      "open-delay": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('img', _vm._g(_vm._b({
          staticClass: "file-upload-field-view-full-single-answer__img-sub",
          attrs: {
            "src": _vm.imgDataURL
          }
        }, 'img', attrs, false), on))];
      }
    }], null, false, 4101805556)
  }, [_c('v-card', {
    staticClass: "pa-6"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": _vm.imgDataURL,
      "max-width": "300",
      "max-height": "300"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('f-circle-loader', {
          attrs: {
            "size": "3"
          }
        })];
      },
      proxy: true
    }], null, false, 446112212)
  })], 1)], 1) : _vm._e(), _c('span', {
    staticClass: "file-upload-field-view-full-single-answer__file-name"
  }, [_vm._v(" " + _vm._s(_vm.submissionObj.value.originalName) + " ")]), _c('span', {
    staticClass: "file-upload-field-view-full-single-answer__file-size"
  }, [_vm._v(" " + _vm._s(_vm.submissionObj.value.size) + " ")]), _c('DownloadIcon', {
    staticClass: "file-upload-field-view-full-single-answer__download",
    attrs: {
      "size": "18"
    },
    on: {
      "click": function ($event) {
        return _vm.saveFile(_vm.submissionObj.value.fileName);
      }
    }
  })], 1) : _c('span', [_vm._v(" - ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }