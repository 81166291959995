var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-choice-field-view"
  }, [_c('div', {
    staticClass: "image-choice-field-view__layout"
  }, [_vm._l(_vm.formattedOptions, function (_ref) {
    var option = _ref.option,
      index = _ref.index;
    return _c('v-hover', {
      key: index,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var hover = _ref2.hover;
          return [_c('div', {
            staticClass: "image-choice-field-view__card-wrapper generic-interactive-transparent-blurry-card"
          }, [_c('div', {
            class: ['image-choice-field-view__image-choice-card', hover && 'generic-interactive-transparent-blurry-card__hover', !_vm.editable && _vm.selectedOptions.includes(index) && "image-choice-field-view__image-choice-card--active", !_vm.editable && _vm.selectedOptions.includes(index) && "generic-interactive-transparent-blurry-card__active"],
            on: {
              "click": function ($event) {
                _vm.preview ? '' : _vm.$emit('toggle-options', index);
              }
            }
          }, [_c('div', {
            staticClass: "image-choice-field-view__image-choice-card__wrapper generic-interactive-transparent-blurry-card__card"
          }, [_c('v-scale-transition', {
            attrs: {
              "origin": "center center 0"
            }
          }, [_vm.editable && hover && _vm.isEditingActive ? _c('v-btn', {
            staticClass: "image-choice-field-view__image-choice-card__remove-btn",
            attrs: {
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.$emit('remove-option', index);
              }
            }
          }, [_c('XIcon', {
            attrs: {
              "size": "14",
              "stroke-width": "2.9"
            }
          })], 1) : _vm._e()], 1), _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image-wrapper"
          }, [_c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image"
          }, [_c('v-scale-transition', {
            attrs: {
              "origin": "center center 0"
            }
          }, [_vm.editable && hover && option.image ? _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image-edit-icon",
            on: {
              "click": function ($event) {
                return _vm.$emit('change-image', index);
              }
            }
          }, [_c('Edit2Icon', {
            attrs: {
              "size": "16",
              "strokeWidth": "1.5"
            }
          })], 1) : _vm._e()]), _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__anchor-letter-wrapper"
          }, [_c('div', {
            staticClass: "image-choice-field-view__image-choice-card__anchor-letter"
          }, [_vm._v(" " + _vm._s(_vm.Utils.convertIndexToLetter(index)) + " ")])]), !_vm.editable && _vm.selectedOptions.includes(index) ? _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__active-checkbox-wrapper"
          }, [_c('f-checkbox', {
            attrs: {
              "value": true,
              "readonly": ""
            }
          })], 1) : _vm._e(), _vm.editable && _vm.currentLoadingImageIndex === index ? _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--loading"
          }, [_c('f-circle-loader', {
            attrs: {
              "size": "3"
            }
          })], 1) : _vm.editable && option.image || !_vm.editable ? _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--loaded",
            style: {
              backgroundImage: 'url(' + option.image + ')'
            }
          }) : _vm.editable ? _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__image-content image-choice-field-view__image-choice-card__image-content--empty",
            on: {
              "click": function ($event) {
                return _vm.$emit('start-adding-image', index);
              }
            }
          }, [_c('v-icon', [_vm._v("$add_image")])], 1) : _vm._e()], 1)]), _c('div', {
            staticClass: "image-choice-field-view__image-choice-card__label-wrapper"
          }, [option.label !== undefined ? _c('f-simple-contenteditable-input', {
            staticClass: "image-choice-field-view__image-choice-card__label",
            attrs: {
              "placeholder": _vm.editable ? 'Label' : '',
              "overflow-wrap": "",
              "prevent-highlighting": "",
              "readonly": !_vm.editable
            },
            model: {
              value: option.label,
              callback: function ($$v) {
                _vm.$set(option, "label", $$v);
              },
              expression: "option.label"
            }
          }) : _vm._e()], 1)], 1)])])];
        }
      }], null, true)
    });
  }), _vm._t("addOptionCard")], 2), _vm._t("addButton")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }