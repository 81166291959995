import { SelectionFieldTypeConst } from '@/components/Fields/GenericFieldViews/GenericSelectionFieldView'

class SingleSelectionFieldTypeConst extends SelectionFieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'SINGLE_SELECTION',
            READABLE_NAME: 'Multiple choice',
            ICON_NAME: 'single_selection',
            FOLDER_NAME: 'SingleSelection',
            CONDITIONAL_LOGIC_MULTIPLE: () => false,

            AUTO_SCROLL_HANDLER: submissionObj => submissionObj.value.length
        })
    }
}

export default new SingleSelectionFieldTypeConst()