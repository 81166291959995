const FONTS = [
    {
        "name": "ABeeZee",
        "url": "https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap"
    },
    {
        "name": "Abel",
        "url": "https://fonts.googleapis.com/css2?family=Abel&display=swap"
    },
    {
        "name": "Abhaya Libre",
        "url": "https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Abril Fatface",
        "url": "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap"
    },
    {
        "name": "Aclonica",
        "url": "https://fonts.googleapis.com/css2?family=Aclonica&display=swap"
    },
    {
        "name": "Acme",
        "url": "https://fonts.googleapis.com/css2?family=Acme&display=swap"
    },
    {
        "name": "Actor",
        "url": "https://fonts.googleapis.com/css2?family=Actor&display=swap"
    },
    {
        "name": "Adamina",
        "url": "https://fonts.googleapis.com/css2?family=Adamina&display=swap"
    },
    {
        "name": "Advent Pro",
        "url": "https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Aguafina Script",
        "url": "https://fonts.googleapis.com/css2?family=Aguafina+Script&display=swap"
    },
    {
        "name": "Akaya Kanadaka",
        "url": "https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&display=swap"
    },
    {
        "name": "Akaya Telivigala",
        "url": "https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap"
    },
    {
        "name": "Akronim",
        "url": "https://fonts.googleapis.com/css2?family=Akronim&display=swap"
    },
    {
        "name": "Akshar",
        "url": "https://fonts.googleapis.com/css2?family=Akshar:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Aladin",
        "url": "https://fonts.googleapis.com/css2?family=Aladin&display=swap"
    },
    {
        "name": "Alata",
        "url": "https://fonts.googleapis.com/css2?family=Alata&display=swap"
    },
    {
        "name": "Alatsi",
        "url": "https://fonts.googleapis.com/css2?family=Alatsi&display=swap"
    },
    {
        "name": "Albert Sans",
        "url": "https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Aldrich",
        "url": "https://fonts.googleapis.com/css2?family=Aldrich&display=swap"
    },
    {
        "name": "Alef",
        "url": "https://fonts.googleapis.com/css2?family=Alef:wght@400;700&display=swap"
    },
    {
        "name": "Alegreya",
        "url": "https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Alegreya SC",
        "url": "https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Alegreya Sans",
        "url": "https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Alegreya Sans SC",
        "url": "https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Aleo",
        "url": "https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Alex Brush",
        "url": "https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
    },
    {
        "name": "Alfa Slab One",
        "url": "https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap"
    },
    {
        "name": "Alice",
        "url": "https://fonts.googleapis.com/css2?family=Alice&display=swap"
    },
    {
        "name": "Alike",
        "url": "https://fonts.googleapis.com/css2?family=Alike&display=swap"
    },
    {
        "name": "Alike Angular",
        "url": "https://fonts.googleapis.com/css2?family=Alike+Angular&display=swap"
    },
    {
        "name": "Allan",
        "url": "https://fonts.googleapis.com/css2?family=Allan:wght@400;700&display=swap"
    },
    {
        "name": "Allerta",
        "url": "https://fonts.googleapis.com/css2?family=Allerta&display=swap"
    },
    {
        "name": "Allerta Stencil",
        "url": "https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap"
    },
    {
        "name": "Allison",
        "url": "https://fonts.googleapis.com/css2?family=Allison&display=swap"
    },
    {
        "name": "Allura",
        "url": "https://fonts.googleapis.com/css2?family=Allura&display=swap"
    },
    {
        "name": "Almarai",
        "url": "https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap"
    },
    {
        "name": "Almendra",
        "url": "https://fonts.googleapis.com/css2?family=Almendra:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Almendra Display",
        "url": "https://fonts.googleapis.com/css2?family=Almendra+Display&display=swap"
    },
    {
        "name": "Almendra SC",
        "url": "https://fonts.googleapis.com/css2?family=Almendra+SC&display=swap"
    },
    {
        "name": "Alumni Sans",
        "url": "https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Alumni Sans Inline One",
        "url": "https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One:ital@0;1&display=swap"
    },
    {
        "name": "Amarante",
        "url": "https://fonts.googleapis.com/css2?family=Amarante&display=swap"
    },
    {
        "name": "Amaranth",
        "url": "https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Amatic SC",
        "url": "https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap"
    },
    {
        "name": "Amethysta",
        "url": "https://fonts.googleapis.com/css2?family=Amethysta&display=swap"
    },
    {
        "name": "Amiko",
        "url": "https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap"
    },
    {
        "name": "Amiri",
        "url": "https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Amita",
        "url": "https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap"
    },
    {
        "name": "Anaheim",
        "url": "https://fonts.googleapis.com/css2?family=Anaheim&display=swap"
    },
    {
        "name": "Andada Pro",
        "url": "https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Andika",
        "url": "https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Anek Bangla",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Devanagari",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Gujarati",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Gurmukhi",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Kannada",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Latin",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Malayalam",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Odia",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Odia:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Tamil",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Anek Telugu",
        "url": "https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Angkor",
        "url": "https://fonts.googleapis.com/css2?family=Angkor&display=swap"
    },
    {
        "name": "Annie Use Your Telescope",
        "url": "https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap"
    },
    {
        "name": "Anonymous Pro",
        "url": "https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Antic",
        "url": "https://fonts.googleapis.com/css2?family=Antic&display=swap"
    },
    {
        "name": "Antic Didone",
        "url": "https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap"
    },
    {
        "name": "Antic Slab",
        "url": "https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap"
    },
    {
        "name": "Anton",
        "url": "https://fonts.googleapis.com/css2?family=Anton&display=swap"
    },
    {
        "name": "Antonio",
        "url": "https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Anybody",
        "url": "https://fonts.googleapis.com/css2?family=Anybody:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Arapey",
        "url": "https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&display=swap"
    },
    {
        "name": "Arbutus",
        "url": "https://fonts.googleapis.com/css2?family=Arbutus&display=swap"
    },
    {
        "name": "Arbutus Slab",
        "url": "https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap"
    },
    {
        "name": "Architects Daughter",
        "url": "https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap"
    },
    {
        "name": "Archivo",
        "url": "https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Archivo Black",
        "url": "https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap"
    },
    {
        "name": "Archivo Narrow",
        "url": "https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Are You Serious",
        "url": "https://fonts.googleapis.com/css2?family=Are+You+Serious&display=swap"
    },
    {
        "name": "Aref Ruqaa",
        "url": "https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&display=swap"
    },
    {
        "name": "Arima",
        "url": "https://fonts.googleapis.com/css2?family=Arima:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Arima Madurai",
        "url": "https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@100;200;300;400;500;700;800;900&display=swap"
    },
    {
        "name": "Arimo",
        "url": "https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Arizonia",
        "url": "https://fonts.googleapis.com/css2?family=Arizonia&display=swap"
    },
    {
        "name": "Armata",
        "url": "https://fonts.googleapis.com/css2?family=Armata&display=swap"
    },
    {
        "name": "Arsenal",
        "url": "https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Artifika",
        "url": "https://fonts.googleapis.com/css2?family=Artifika&display=swap"
    },
    {
        "name": "Arvo",
        "url": "https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Arya",
        "url": "https://fonts.googleapis.com/css2?family=Arya:wght@400;700&display=swap"
    },
    {
        "name": "Asap",
        "url": "https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Asap Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Asar",
        "url": "https://fonts.googleapis.com/css2?family=Asar&display=swap"
    },
    {
        "name": "Asset",
        "url": "https://fonts.googleapis.com/css2?family=Asset&display=swap"
    },
    {
        "name": "Assistant",
        "url": "https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Astloch",
        "url": "https://fonts.googleapis.com/css2?family=Astloch:wght@400;700&display=swap"
    },
    {
        "name": "Asul",
        "url": "https://fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap"
    },
    {
        "name": "Athiti",
        "url": "https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Atkinson Hyperlegible",
        "url": "https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Atma",
        "url": "https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Atomic Age",
        "url": "https://fonts.googleapis.com/css2?family=Atomic+Age&display=swap"
    },
    {
        "name": "Aubrey",
        "url": "https://fonts.googleapis.com/css2?family=Aubrey&display=swap"
    },
    {
        "name": "Audiowide",
        "url": "https://fonts.googleapis.com/css2?family=Audiowide&display=swap"
    },
    {
        "name": "Autour One",
        "url": "https://fonts.googleapis.com/css2?family=Autour+One&display=swap"
    },
    {
        "name": "Average",
        "url": "https://fonts.googleapis.com/css2?family=Average&display=swap"
    },
    {
        "name": "Average Sans",
        "url": "https://fonts.googleapis.com/css2?family=Average+Sans&display=swap"
    },
    {
        "name": "Averia Gruesa Libre",
        "url": "https://fonts.googleapis.com/css2?family=Averia+Gruesa+Libre&display=swap"
    },
    {
        "name": "Averia Libre",
        "url": "https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Averia Sans Libre",
        "url": "https://fonts.googleapis.com/css2?family=Averia+Sans+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Averia Serif Libre",
        "url": "https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Azeret Mono",
        "url": "https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "B612",
        "url": "https://fonts.googleapis.com/css2?family=B612:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "B612 Mono",
        "url": "https://fonts.googleapis.com/css2?family=B612+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "BIZ UDGothic",
        "url": "https://fonts.googleapis.com/css2?family=BIZ+UDGothic:wght@400;700&display=swap"
    },
    {
        "name": "BIZ UDMincho",
        "url": "https://fonts.googleapis.com/css2?family=BIZ+UDMincho&display=swap"
    },
    {
        "name": "BIZ UDPGothic",
        "url": "https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&display=swap"
    },
    {
        "name": "BIZ UDPMincho",
        "url": "https://fonts.googleapis.com/css2?family=BIZ+UDPMincho&display=swap"
    },
    {
        "name": "Babylonica",
        "url": "https://fonts.googleapis.com/css2?family=Babylonica&display=swap"
    },
    {
        "name": "Bad Script",
        "url": "https://fonts.googleapis.com/css2?family=Bad+Script&display=swap"
    },
    {
        "name": "Bahiana",
        "url": "https://fonts.googleapis.com/css2?family=Bahiana&display=swap"
    },
    {
        "name": "Bahianita",
        "url": "https://fonts.googleapis.com/css2?family=Bahianita&display=swap"
    },
    {
        "name": "Bai Jamjuree",
        "url": "https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Bakbak One",
        "url": "https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap"
    },
    {
        "name": "Ballet",
        "url": "https://fonts.googleapis.com/css2?family=Ballet&display=swap"
    },
    {
        "name": "Baloo 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Bhai 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Bhaijaan 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Bhaina 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Chettan 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Da 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Paaji 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Tamma 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Tammudu 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Baloo Thambi 2",
        "url": "https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Balsamiq Sans",
        "url": "https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Balthazar",
        "url": "https://fonts.googleapis.com/css2?family=Balthazar&display=swap"
    },
    {
        "name": "Bangers",
        "url": "https://fonts.googleapis.com/css2?family=Bangers&display=swap"
    },
    {
        "name": "Barlow",
        "url": "https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Barlow Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Barlow Semi Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Barriecito",
        "url": "https://fonts.googleapis.com/css2?family=Barriecito&display=swap"
    },
    {
        "name": "Barrio",
        "url": "https://fonts.googleapis.com/css2?family=Barrio&display=swap"
    },
    {
        "name": "Basic",
        "url": "https://fonts.googleapis.com/css2?family=Basic&display=swap"
    },
    {
        "name": "Baskervville",
        "url": "https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap"
    },
    {
        "name": "Battambang",
        "url": "https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&display=swap"
    },
    {
        "name": "Baumans",
        "url": "https://fonts.googleapis.com/css2?family=Baumans&display=swap"
    },
    {
        "name": "Bayon",
        "url": "https://fonts.googleapis.com/css2?family=Bayon&display=swap"
    },
    {
        "name": "Be Vietnam Pro",
        "url": "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Beau Rivage",
        "url": "https://fonts.googleapis.com/css2?family=Beau+Rivage&display=swap"
    },
    {
        "name": "Bebas Neue",
        "url": "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
    },
    {
        "name": "Belgrano",
        "url": "https://fonts.googleapis.com/css2?family=Belgrano&display=swap"
    },
    {
        "name": "Bellefair",
        "url": "https://fonts.googleapis.com/css2?family=Bellefair&display=swap"
    },
    {
        "name": "Belleza",
        "url": "https://fonts.googleapis.com/css2?family=Belleza&display=swap"
    },
    {
        "name": "Bellota",
        "url": "https://fonts.googleapis.com/css2?family=Bellota:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Bellota Text",
        "url": "https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "BenchNine",
        "url": "https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&display=swap"
    },
    {
        "name": "Benne",
        "url": "https://fonts.googleapis.com/css2?family=Benne&display=swap"
    },
    {
        "name": "Bentham",
        "url": "https://fonts.googleapis.com/css2?family=Bentham&display=swap"
    },
    {
        "name": "Berkshire Swash",
        "url": "https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap"
    },
    {
        "name": "Besley",
        "url": "https://fonts.googleapis.com/css2?family=Besley:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Beth Ellen",
        "url": "https://fonts.googleapis.com/css2?family=Beth+Ellen&display=swap"
    },
    {
        "name": "Bevan",
        "url": "https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap"
    },
    {
        "name": "BhuTuka Expanded One",
        "url": "https://fonts.googleapis.com/css2?family=BhuTuka+Expanded+One&display=swap"
    },
    {
        "name": "Big Shoulders Display",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Big Shoulders Inline Display",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Display:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Big Shoulders Inline Text",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Big Shoulders Stencil Display",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Big Shoulders Stencil Text",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Big Shoulders Text",
        "url": "https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Bigelow Rules",
        "url": "https://fonts.googleapis.com/css2?family=Bigelow+Rules&display=swap"
    },
    {
        "name": "Bigshot One",
        "url": "https://fonts.googleapis.com/css2?family=Bigshot+One&display=swap"
    },
    {
        "name": "Bilbo",
        "url": "https://fonts.googleapis.com/css2?family=Bilbo&display=swap"
    },
    {
        "name": "Bilbo Swash Caps",
        "url": "https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&display=swap"
    },
    {
        "name": "BioRhyme",
        "url": "https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap"
    },
    {
        "name": "BioRhyme Expanded",
        "url": "https://fonts.googleapis.com/css2?family=BioRhyme+Expanded:wght@200;300;400;700;800&display=swap"
    },
    {
        "name": "Birthstone",
        "url": "https://fonts.googleapis.com/css2?family=Birthstone&display=swap"
    },
    {
        "name": "Birthstone Bounce",
        "url": "https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@400;500&display=swap"
    },
    {
        "name": "Biryani",
        "url": "https://fonts.googleapis.com/css2?family=Biryani:wght@200;300;400;600;700;800;900&display=swap"
    },
    {
        "name": "Bitter",
        "url": "https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Black And White Picture",
        "url": "https://fonts.googleapis.com/css2?family=Black+And+White+Picture&display=swap"
    },
    {
        "name": "Black Han Sans",
        "url": "https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap"
    },
    {
        "name": "Black Ops One",
        "url": "https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap"
    },
    {
        "name": "Blaka",
        "url": "https://fonts.googleapis.com/css2?family=Blaka&display=swap"
    },
    {
        "name": "Blaka Hollow",
        "url": "https://fonts.googleapis.com/css2?family=Blaka+Hollow&display=swap"
    },
    {
        "name": "Blinker",
        "url": "https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap"
    },
    {
        "name": "Bodoni Moda",
        "url": "https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Bokor",
        "url": "https://fonts.googleapis.com/css2?family=Bokor&display=swap"
    },
    {
        "name": "Bona Nova",
        "url": "https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Bonbon",
        "url": "https://fonts.googleapis.com/css2?family=Bonbon&display=swap"
    },
    {
        "name": "Bonheur Royale",
        "url": "https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap"
    },
    {
        "name": "Boogaloo",
        "url": "https://fonts.googleapis.com/css2?family=Boogaloo&display=swap"
    },
    {
        "name": "Bowlby One",
        "url": "https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap"
    },
    {
        "name": "Bowlby One SC",
        "url": "https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap"
    },
    {
        "name": "Brawler",
        "url": "https://fonts.googleapis.com/css2?family=Brawler:wght@400;700&display=swap"
    },
    {
        "name": "Bree Serif",
        "url": "https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap"
    },
    {
        "name": "Brygada 1918",
        "url": "https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Bubblegum Sans",
        "url": "https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap"
    },
    {
        "name": "Bubbler One",
        "url": "https://fonts.googleapis.com/css2?family=Bubbler+One&display=swap"
    },
    {
        "name": "Buenard",
        "url": "https://fonts.googleapis.com/css2?family=Buenard:wght@400;700&display=swap"
    },
    {
        "name": "Bungee",
        "url": "https://fonts.googleapis.com/css2?family=Bungee&display=swap"
    },
    {
        "name": "Bungee Hairline",
        "url": "https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap"
    },
    {
        "name": "Bungee Inline",
        "url": "https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap"
    },
    {
        "name": "Bungee Outline",
        "url": "https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap"
    },
    {
        "name": "Bungee Shade",
        "url": "https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap"
    },
    {
        "name": "Butcherman",
        "url": "https://fonts.googleapis.com/css2?family=Butcherman&display=swap"
    },
    {
        "name": "Butterfly Kids",
        "url": "https://fonts.googleapis.com/css2?family=Butterfly+Kids&display=swap"
    },
    {
        "name": "Cabin",
        "url": "https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Cabin Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Cabin Sketch",
        "url": "https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&display=swap"
    },
    {
        "name": "Caesar Dressing",
        "url": "https://fonts.googleapis.com/css2?family=Caesar+Dressing&display=swap"
    },
    {
        "name": "Cagliostro",
        "url": "https://fonts.googleapis.com/css2?family=Cagliostro&display=swap"
    },
    {
        "name": "Cairo",
        "url": "https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Caladea",
        "url": "https://fonts.googleapis.com/css2?family=Caladea:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Calistoga",
        "url": "https://fonts.googleapis.com/css2?family=Calistoga&display=swap"
    },
    {
        "name": "Calligraffitti",
        "url": "https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap"
    },
    {
        "name": "Cambay",
        "url": "https://fonts.googleapis.com/css2?family=Cambay:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Cambo",
        "url": "https://fonts.googleapis.com/css2?family=Cambo&display=swap"
    },
    {
        "name": "Candal",
        "url": "https://fonts.googleapis.com/css2?family=Candal&display=swap"
    },
    {
        "name": "Cantarell",
        "url": "https://fonts.googleapis.com/css2?family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Cantata One",
        "url": "https://fonts.googleapis.com/css2?family=Cantata+One&display=swap"
    },
    {
        "name": "Cantora One",
        "url": "https://fonts.googleapis.com/css2?family=Cantora+One&display=swap"
    },
    {
        "name": "Capriola",
        "url": "https://fonts.googleapis.com/css2?family=Capriola&display=swap"
    },
    {
        "name": "Caramel",
        "url": "https://fonts.googleapis.com/css2?family=Caramel&display=swap"
    },
    {
        "name": "Carattere",
        "url": "https://fonts.googleapis.com/css2?family=Carattere&display=swap"
    },
    {
        "name": "Cardo",
        "url": "https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Carme",
        "url": "https://fonts.googleapis.com/css2?family=Carme&display=swap"
    },
    {
        "name": "Carrois Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Carrois+Gothic&display=swap"
    },
    {
        "name": "Carrois Gothic SC",
        "url": "https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap"
    },
    {
        "name": "Carter One",
        "url": "https://fonts.googleapis.com/css2?family=Carter+One&display=swap"
    },
    {
        "name": "Castoro",
        "url": "https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap"
    },
    {
        "name": "Catamaran",
        "url": "https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Caudex",
        "url": "https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Caveat",
        "url": "https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Caveat Brush",
        "url": "https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap"
    },
    {
        "name": "Cedarville Cursive",
        "url": "https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap"
    },
    {
        "name": "Ceviche One",
        "url": "https://fonts.googleapis.com/css2?family=Ceviche+One&display=swap"
    },
    {
        "name": "Chakra Petch",
        "url": "https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Changa",
        "url": "https://fonts.googleapis.com/css2?family=Changa:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Changa One",
        "url": "https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&display=swap"
    },
    {
        "name": "Chango",
        "url": "https://fonts.googleapis.com/css2?family=Chango&display=swap"
    },
    {
        "name": "Charis SIL",
        "url": "https://fonts.googleapis.com/css2?family=Charis+SIL:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Charm",
        "url": "https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap"
    },
    {
        "name": "Charmonman",
        "url": "https://fonts.googleapis.com/css2?family=Charmonman:wght@400;700&display=swap"
    },
    {
        "name": "Chathura",
        "url": "https://fonts.googleapis.com/css2?family=Chathura:wght@100;300;400;700;800&display=swap"
    },
    {
        "name": "Chau Philomene One",
        "url": "https://fonts.googleapis.com/css2?family=Chau+Philomene+One:ital@0;1&display=swap"
    },
    {
        "name": "Chela One",
        "url": "https://fonts.googleapis.com/css2?family=Chela+One&display=swap"
    },
    {
        "name": "Chelsea Market",
        "url": "https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap"
    },
    {
        "name": "Chenla",
        "url": "https://fonts.googleapis.com/css2?family=Chenla&display=swap"
    },
    {
        "name": "Cherish",
        "url": "https://fonts.googleapis.com/css2?family=Cherish&display=swap"
    },
    {
        "name": "Cherry Cream Soda",
        "url": "https://fonts.googleapis.com/css2?family=Cherry+Cream+Soda&display=swap"
    },
    {
        "name": "Cherry Swash",
        "url": "https://fonts.googleapis.com/css2?family=Cherry+Swash:wght@400;700&display=swap"
    },
    {
        "name": "Chewy",
        "url": "https://fonts.googleapis.com/css2?family=Chewy&display=swap"
    },
    {
        "name": "Chicle",
        "url": "https://fonts.googleapis.com/css2?family=Chicle&display=swap"
    },
    {
        "name": "Chilanka",
        "url": "https://fonts.googleapis.com/css2?family=Chilanka&display=swap"
    },
    {
        "name": "Chivo",
        "url": "https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
    },
    {
        "name": "Chonburi",
        "url": "https://fonts.googleapis.com/css2?family=Chonburi&display=swap"
    },
    {
        "name": "Cinzel",
        "url": "https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Cinzel Decorative",
        "url": "https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap"
    },
    {
        "name": "Clicker Script",
        "url": "https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap"
    },
    {
        "name": "Coda",
        "url": "https://fonts.googleapis.com/css2?family=Coda:wght@400;800&display=swap"
    },
    {
        "name": "Codystar",
        "url": "https://fonts.googleapis.com/css2?family=Codystar:wght@300;400&display=swap"
    },
    {
        "name": "Coiny",
        "url": "https://fonts.googleapis.com/css2?family=Coiny&display=swap"
    },
    {
        "name": "Combo",
        "url": "https://fonts.googleapis.com/css2?family=Combo&display=swap"
    },
    {
        "name": "Comfortaa",
        "url": "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Comforter",
        "url": "https://fonts.googleapis.com/css2?family=Comforter&display=swap"
    },
    {
        "name": "Comforter Brush",
        "url": "https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap"
    },
    {
        "name": "Comic Neue",
        "url": "https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Coming Soon",
        "url": "https://fonts.googleapis.com/css2?family=Coming+Soon&display=swap"
    },
    {
        "name": "Commissioner",
        "url": "https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Concert One",
        "url": "https://fonts.googleapis.com/css2?family=Concert+One&display=swap"
    },
    {
        "name": "Condiment",
        "url": "https://fonts.googleapis.com/css2?family=Condiment&display=swap"
    },
    {
        "name": "Content",
        "url": "https://fonts.googleapis.com/css2?family=Content:wght@400;700&display=swap"
    },
    {
        "name": "Contrail One",
        "url": "https://fonts.googleapis.com/css2?family=Contrail+One&display=swap"
    },
    {
        "name": "Convergence",
        "url": "https://fonts.googleapis.com/css2?family=Convergence&display=swap"
    },
    {
        "name": "Cookie",
        "url": "https://fonts.googleapis.com/css2?family=Cookie&display=swap"
    },
    {
        "name": "Copse",
        "url": "https://fonts.googleapis.com/css2?family=Copse&display=swap"
    },
    {
        "name": "Corben",
        "url": "https://fonts.googleapis.com/css2?family=Corben:wght@400;700&display=swap"
    },
    {
        "name": "Corinthia",
        "url": "https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&display=swap"
    },
    {
        "name": "Cormorant",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Cormorant Garamond",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Cormorant Infant",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Cormorant SC",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Cormorant Unicase",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant+Unicase:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Cormorant Upright",
        "url": "https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Courgette",
        "url": "https://fonts.googleapis.com/css2?family=Courgette&display=swap"
    },
    {
        "name": "Courier Prime",
        "url": "https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Cousine",
        "url": "https://fonts.googleapis.com/css2?family=Cousine:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Coustard",
        "url": "https://fonts.googleapis.com/css2?family=Coustard:wght@400;900&display=swap"
    },
    {
        "name": "Covered By Your Grace",
        "url": "https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap"
    },
    {
        "name": "Crafty Girls",
        "url": "https://fonts.googleapis.com/css2?family=Crafty+Girls&display=swap"
    },
    {
        "name": "Creepster",
        "url": "https://fonts.googleapis.com/css2?family=Creepster&display=swap"
    },
    {
        "name": "Crete Round",
        "url": "https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&display=swap"
    },
    {
        "name": "Crimson Pro",
        "url": "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Crimson Text",
        "url": "https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap"
    },
    {
        "name": "Croissant One",
        "url": "https://fonts.googleapis.com/css2?family=Croissant+One&display=swap"
    },
    {
        "name": "Crushed",
        "url": "https://fonts.googleapis.com/css2?family=Crushed&display=swap"
    },
    {
        "name": "Cuprum",
        "url": "https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Cute Font",
        "url": "https://fonts.googleapis.com/css2?family=Cute+Font&display=swap"
    },
    {
        "name": "Cutive",
        "url": "https://fonts.googleapis.com/css2?family=Cutive&display=swap"
    },
    {
        "name": "Cutive Mono",
        "url": "https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap"
    },
    {
        "name": "DM Mono",
        "url": "https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap"
    },
    {
        "name": "DM Sans",
        "url": "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
    },
    {
        "name": "DM Serif Display",
        "url": "https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap"
    },
    {
        "name": "DM Serif Text",
        "url": "https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap"
    },
    {
        "name": "Damion",
        "url": "https://fonts.googleapis.com/css2?family=Damion&display=swap"
    },
    {
        "name": "Dancing Script",
        "url": "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Dangrek",
        "url": "https://fonts.googleapis.com/css2?family=Dangrek&display=swap"
    },
    {
        "name": "Darker Grotesque",
        "url": "https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "David Libre",
        "url": "https://fonts.googleapis.com/css2?family=David+Libre:wght@400;500;700&display=swap"
    },
    {
        "name": "Dawning of a New Day",
        "url": "https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap"
    },
    {
        "name": "Days One",
        "url": "https://fonts.googleapis.com/css2?family=Days+One&display=swap"
    },
    {
        "name": "Dekko",
        "url": "https://fonts.googleapis.com/css2?family=Dekko&display=swap"
    },
    {
        "name": "Dela Gothic One",
        "url": "https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap"
    },
    {
        "name": "Delius",
        "url": "https://fonts.googleapis.com/css2?family=Delius&display=swap"
    },
    {
        "name": "Delius Swash Caps",
        "url": "https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&display=swap"
    },
    {
        "name": "Delius Unicase",
        "url": "https://fonts.googleapis.com/css2?family=Delius+Unicase:wght@400;700&display=swap"
    },
    {
        "name": "Della Respira",
        "url": "https://fonts.googleapis.com/css2?family=Della+Respira&display=swap"
    },
    {
        "name": "Denk One",
        "url": "https://fonts.googleapis.com/css2?family=Denk+One&display=swap"
    },
    {
        "name": "Devonshire",
        "url": "https://fonts.googleapis.com/css2?family=Devonshire&display=swap"
    },
    {
        "name": "Dhurjati",
        "url": "https://fonts.googleapis.com/css2?family=Dhurjati&display=swap"
    },
    {
        "name": "Didact Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap"
    },
    {
        "name": "Diplomata",
        "url": "https://fonts.googleapis.com/css2?family=Diplomata&display=swap"
    },
    {
        "name": "Diplomata SC",
        "url": "https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap"
    },
    {
        "name": "Do Hyeon",
        "url": "https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap"
    },
    {
        "name": "Dokdo",
        "url": "https://fonts.googleapis.com/css2?family=Dokdo&display=swap"
    },
    {
        "name": "Domine",
        "url": "https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Donegal One",
        "url": "https://fonts.googleapis.com/css2?family=Donegal+One&display=swap"
    },
    {
        "name": "Dongle",
        "url": "https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap"
    },
    {
        "name": "Doppio One",
        "url": "https://fonts.googleapis.com/css2?family=Doppio+One&display=swap"
    },
    {
        "name": "Dorsa",
        "url": "https://fonts.googleapis.com/css2?family=Dorsa&display=swap"
    },
    {
        "name": "Dosis",
        "url": "https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "DotGothic16",
        "url": "https://fonts.googleapis.com/css2?family=DotGothic16&display=swap"
    },
    {
        "name": "Dr Sugiyama",
        "url": "https://fonts.googleapis.com/css2?family=Dr+Sugiyama&display=swap"
    },
    {
        "name": "Duru Sans",
        "url": "https://fonts.googleapis.com/css2?family=Duru+Sans&display=swap"
    },
    {
        "name": "Dynalight",
        "url": "https://fonts.googleapis.com/css2?family=Dynalight&display=swap"
    },
    {
        "name": "EB Garamond",
        "url": "https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Eagle Lake",
        "url": "https://fonts.googleapis.com/css2?family=Eagle+Lake&display=swap"
    },
    {
        "name": "East Sea Dokdo",
        "url": "https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&display=swap"
    },
    {
        "name": "Eater",
        "url": "https://fonts.googleapis.com/css2?family=Eater&display=swap"
    },
    {
        "name": "Economica",
        "url": "https://fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Eczar",
        "url": "https://fonts.googleapis.com/css2?family=Eczar:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Edu NSW ACT Foundation",
        "url": "https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Edu QLD Beginner",
        "url": "https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Edu SA Beginner",
        "url": "https://fonts.googleapis.com/css2?family=Edu+SA+Beginner:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Edu TAS Beginner",
        "url": "https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Edu VIC WA NT Beginner",
        "url": "https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400;500;600;700&display=swap"
    },
    {
        "name": "El Messiri",
        "url": "https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Electrolize",
        "url": "https://fonts.googleapis.com/css2?family=Electrolize&display=swap"
    },
    {
        "name": "Elsie",
        "url": "https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&display=swap"
    },
    {
        "name": "Elsie Swash Caps",
        "url": "https://fonts.googleapis.com/css2?family=Elsie+Swash+Caps:wght@400;900&display=swap"
    },
    {
        "name": "Emblema One",
        "url": "https://fonts.googleapis.com/css2?family=Emblema+One&display=swap"
    },
    {
        "name": "Emilys Candy",
        "url": "https://fonts.googleapis.com/css2?family=Emilys+Candy&display=swap"
    },
    {
        "name": "Encode Sans",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Encode Sans Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Encode Sans Expanded",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Encode Sans SC",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Encode Sans Semi Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Encode Sans Semi Expanded",
        "url": "https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Engagement",
        "url": "https://fonts.googleapis.com/css2?family=Engagement&display=swap"
    },
    {
        "name": "Englebert",
        "url": "https://fonts.googleapis.com/css2?family=Englebert&display=swap"
    },
    {
        "name": "Enriqueta",
        "url": "https://fonts.googleapis.com/css2?family=Enriqueta:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Ephesis",
        "url": "https://fonts.googleapis.com/css2?family=Ephesis&display=swap"
    },
    {
        "name": "Epilogue",
        "url": "https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Erica One",
        "url": "https://fonts.googleapis.com/css2?family=Erica+One&display=swap"
    },
    {
        "name": "Esteban",
        "url": "https://fonts.googleapis.com/css2?family=Esteban&display=swap"
    },
    {
        "name": "Estonia",
        "url": "https://fonts.googleapis.com/css2?family=Estonia&display=swap"
    },
    {
        "name": "Euphoria Script",
        "url": "https://fonts.googleapis.com/css2?family=Euphoria+Script&display=swap"
    },
    {
        "name": "Ewert",
        "url": "https://fonts.googleapis.com/css2?family=Ewert&display=swap"
    },
    {
        "name": "Exo",
        "url": "https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Exo 2",
        "url": "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Expletus Sans",
        "url": "https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Explora",
        "url": "https://fonts.googleapis.com/css2?family=Explora&display=swap"
    },
    {
        "name": "Fahkwang",
        "url": "https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Familjen Grotesk",
        "url": "https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Fanwood Text",
        "url": "https://fonts.googleapis.com/css2?family=Fanwood+Text:ital@0;1&display=swap"
    },
    {
        "name": "Farro",
        "url": "https://fonts.googleapis.com/css2?family=Farro:wght@300;400;500;700&display=swap"
    },
    {
        "name": "Farsan",
        "url": "https://fonts.googleapis.com/css2?family=Farsan&display=swap"
    },
    {
        "name": "Fascinate",
        "url": "https://fonts.googleapis.com/css2?family=Fascinate&display=swap"
    },
    {
        "name": "Fascinate Inline",
        "url": "https://fonts.googleapis.com/css2?family=Fascinate+Inline&display=swap"
    },
    {
        "name": "Faster One",
        "url": "https://fonts.googleapis.com/css2?family=Faster+One&display=swap"
    },
    {
        "name": "Fasthand",
        "url": "https://fonts.googleapis.com/css2?family=Fasthand&display=swap"
    },
    {
        "name": "Fauna One",
        "url": "https://fonts.googleapis.com/css2?family=Fauna+One&display=swap"
    },
    {
        "name": "Faustina",
        "url": "https://fonts.googleapis.com/css2?family=Faustina:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Federant",
        "url": "https://fonts.googleapis.com/css2?family=Federant&display=swap"
    },
    {
        "name": "Federo",
        "url": "https://fonts.googleapis.com/css2?family=Federo&display=swap"
    },
    {
        "name": "Felipa",
        "url": "https://fonts.googleapis.com/css2?family=Felipa&display=swap"
    },
    {
        "name": "Fenix",
        "url": "https://fonts.googleapis.com/css2?family=Fenix&display=swap"
    },
    {
        "name": "Festive",
        "url": "https://fonts.googleapis.com/css2?family=Festive&display=swap"
    },
    {
        "name": "Finger Paint",
        "url": "https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap"
    },
    {
        "name": "Finlandica",
        "url": "https://fonts.googleapis.com/css2?family=Finlandica:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Fira Code",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Fira Mono",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap"
    },
    {
        "name": "Fira Sans",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Fira Sans Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Fira Sans Extra Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Fjalla One",
        "url": "https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap"
    },
    {
        "name": "Fjord One",
        "url": "https://fonts.googleapis.com/css2?family=Fjord+One&display=swap"
    },
    {
        "name": "Flamenco",
        "url": "https://fonts.googleapis.com/css2?family=Flamenco:wght@300;400&display=swap"
    },
    {
        "name": "Flavors",
        "url": "https://fonts.googleapis.com/css2?family=Flavors&display=swap"
    },
    {
        "name": "Fleur De Leah",
        "url": "https://fonts.googleapis.com/css2?family=Fleur+De+Leah&display=swap"
    },
    {
        "name": "Flow Block",
        "url": "https://fonts.googleapis.com/css2?family=Flow+Block&display=swap"
    },
    {
        "name": "Flow Circular",
        "url": "https://fonts.googleapis.com/css2?family=Flow+Circular&display=swap"
    },
    {
        "name": "Flow Rounded",
        "url": "https://fonts.googleapis.com/css2?family=Flow+Rounded&display=swap"
    },
    {
        "name": "Fondamento",
        "url": "https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap"
    },
    {
        "name": "Fontdiner Swanky",
        "url": "https://fonts.googleapis.com/css2?family=Fontdiner+Swanky&display=swap"
    },
    {
        "name": "Forum",
        "url": "https://fonts.googleapis.com/css2?family=Forum&display=swap"
    },
    {
        "name": "Francois One",
        "url": "https://fonts.googleapis.com/css2?family=Francois+One&display=swap"
    },
    {
        "name": "Frank Ruhl Libre",
        "url": "https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;700;900&display=swap"
    },
    {
        "name": "Fraunces",
        "url": "https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Freckle Face",
        "url": "https://fonts.googleapis.com/css2?family=Freckle+Face&display=swap"
    },
    {
        "name": "Fredericka the Great",
        "url": "https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap"
    },
    {
        "name": "Fredoka",
        "url": "https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Fredoka One",
        "url": "https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap"
    },
    {
        "name": "Freehand",
        "url": "https://fonts.googleapis.com/css2?family=Freehand&display=swap"
    },
    {
        "name": "Fresca",
        "url": "https://fonts.googleapis.com/css2?family=Fresca&display=swap"
    },
    {
        "name": "Frijole",
        "url": "https://fonts.googleapis.com/css2?family=Frijole&display=swap"
    },
    {
        "name": "Fruktur",
        "url": "https://fonts.googleapis.com/css2?family=Fruktur&display=swap"
    },
    {
        "name": "Fugaz One",
        "url": "https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap"
    },
    {
        "name": "Fuggles",
        "url": "https://fonts.googleapis.com/css2?family=Fuggles&display=swap"
    },
    {
        "name": "Fuzzy Bubbles",
        "url": "https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap"
    },
    {
        "name": "GFS Didot",
        "url": "https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap"
    },
    {
        "name": "GFS Neohellenic",
        "url": "https://fonts.googleapis.com/css2?family=GFS+Neohellenic:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Gabriela",
        "url": "https://fonts.googleapis.com/css2?family=Gabriela&display=swap"
    },
    {
        "name": "Gaegu",
        "url": "https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&display=swap"
    },
    {
        "name": "Gafata",
        "url": "https://fonts.googleapis.com/css2?family=Gafata&display=swap"
    },
    {
        "name": "Galada",
        "url": "https://fonts.googleapis.com/css2?family=Galada&display=swap"
    },
    {
        "name": "Galdeano",
        "url": "https://fonts.googleapis.com/css2?family=Galdeano&display=swap"
    },
    {
        "name": "Galindo",
        "url": "https://fonts.googleapis.com/css2?family=Galindo&display=swap"
    },
    {
        "name": "Gamja Flower",
        "url": "https://fonts.googleapis.com/css2?family=Gamja+Flower&display=swap"
    },
    {
        "name": "Gantari",
        "url": "https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Gayathri",
        "url": "https://fonts.googleapis.com/css2?family=Gayathri:wght@100;400;700&display=swap"
    },
    {
        "name": "Gelasio",
        "url": "https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Gemunu Libre",
        "url": "https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Genos",
        "url": "https://fonts.googleapis.com/css2?family=Genos:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Gentium Book Basic",
        "url": "https://fonts.googleapis.com/css2?family=Gentium+Book+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Gentium Book Plus",
        "url": "https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Gentium Plus",
        "url": "https://fonts.googleapis.com/css2?family=Gentium+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Geo",
        "url": "https://fonts.googleapis.com/css2?family=Geo:ital@0;1&display=swap"
    },
    {
        "name": "Georama",
        "url": "https://fonts.googleapis.com/css2?family=Georama:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Geostar",
        "url": "https://fonts.googleapis.com/css2?family=Geostar&display=swap"
    },
    {
        "name": "Geostar Fill",
        "url": "https://fonts.googleapis.com/css2?family=Geostar+Fill&display=swap"
    },
    {
        "name": "Germania One",
        "url": "https://fonts.googleapis.com/css2?family=Germania+One&display=swap"
    },
    {
        "name": "Gideon Roman",
        "url": "https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap"
    },
    {
        "name": "Gidugu",
        "url": "https://fonts.googleapis.com/css2?family=Gidugu&display=swap"
    },
    {
        "name": "Gilda Display",
        "url": "https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap"
    },
    {
        "name": "Girassol",
        "url": "https://fonts.googleapis.com/css2?family=Girassol&display=swap"
    },
    {
        "name": "Give You Glory",
        "url": "https://fonts.googleapis.com/css2?family=Give+You+Glory&display=swap"
    },
    {
        "name": "Glass Antiqua",
        "url": "https://fonts.googleapis.com/css2?family=Glass+Antiqua&display=swap"
    },
    {
        "name": "Glegoo",
        "url": "https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap"
    },
    {
        "name": "Gloria Hallelujah",
        "url": "https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap"
    },
    {
        "name": "Glory",
        "url": "https://fonts.googleapis.com/css2?family=Glory:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Gluten",
        "url": "https://fonts.googleapis.com/css2?family=Gluten:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Goblin One",
        "url": "https://fonts.googleapis.com/css2?family=Goblin+One&display=swap"
    },
    {
        "name": "Gochi Hand",
        "url": "https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap"
    },
    {
        "name": "Goldman",
        "url": "https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap"
    },
    {
        "name": "Gorditas",
        "url": "https://fonts.googleapis.com/css2?family=Gorditas:wght@400;700&display=swap"
    },
    {
        "name": "Gothic A1",
        "url": "https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Gotu",
        "url": "https://fonts.googleapis.com/css2?family=Gotu&display=swap"
    },
    {
        "name": "Goudy Bookletter 1911",
        "url": "https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap"
    },
    {
        "name": "Gowun Batang",
        "url": "https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap"
    },
    {
        "name": "Gowun Dodum",
        "url": "https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap"
    },
    {
        "name": "Graduate",
        "url": "https://fonts.googleapis.com/css2?family=Graduate&display=swap"
    },
    {
        "name": "Grand Hotel",
        "url": "https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap"
    },
    {
        "name": "Grandstander",
        "url": "https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Grape Nuts",
        "url": "https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap"
    },
    {
        "name": "Gravitas One",
        "url": "https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap"
    },
    {
        "name": "Great Vibes",
        "url": "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap"
    },
    {
        "name": "Grechen Fuemen",
        "url": "https://fonts.googleapis.com/css2?family=Grechen+Fuemen&display=swap"
    },
    {
        "name": "Grenze",
        "url": "https://fonts.googleapis.com/css2?family=Grenze:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Grenze Gotisch",
        "url": "https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Grey Qo",
        "url": "https://fonts.googleapis.com/css2?family=Grey+Qo&display=swap"
    },
    {
        "name": "Griffy",
        "url": "https://fonts.googleapis.com/css2?family=Griffy&display=swap"
    },
    {
        "name": "Gruppo",
        "url": "https://fonts.googleapis.com/css2?family=Gruppo&display=swap"
    },
    {
        "name": "Gudea",
        "url": "https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Gugi",
        "url": "https://fonts.googleapis.com/css2?family=Gugi&display=swap"
    },
    {
        "name": "Gulzar",
        "url": "https://fonts.googleapis.com/css2?family=Gulzar&display=swap"
    },
    {
        "name": "Gupter",
        "url": "https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap"
    },
    {
        "name": "Gurajada",
        "url": "https://fonts.googleapis.com/css2?family=Gurajada&display=swap"
    },
    {
        "name": "Gwendolyn",
        "url": "https://fonts.googleapis.com/css2?family=Gwendolyn:wght@400;700&display=swap"
    },
    {
        "name": "Habibi",
        "url": "https://fonts.googleapis.com/css2?family=Habibi&display=swap"
    },
    {
        "name": "Hachi Maru Pop",
        "url": "https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap"
    },
    {
        "name": "Hahmlet",
        "url": "https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Halant",
        "url": "https://fonts.googleapis.com/css2?family=Halant:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Hammersmith One",
        "url": "https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap"
    },
    {
        "name": "Hanalei",
        "url": "https://fonts.googleapis.com/css2?family=Hanalei&display=swap"
    },
    {
        "name": "Hanalei Fill",
        "url": "https://fonts.googleapis.com/css2?family=Hanalei+Fill&display=swap"
    },
    {
        "name": "Handlee",
        "url": "https://fonts.googleapis.com/css2?family=Handlee&display=swap"
    },
    {
        "name": "Hanuman",
        "url": "https://fonts.googleapis.com/css2?family=Hanuman:wght@100;300;400;700;900&display=swap"
    },
    {
        "name": "Happy Monkey",
        "url": "https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap"
    },
    {
        "name": "Harmattan",
        "url": "https://fonts.googleapis.com/css2?family=Harmattan:wght@400;700&display=swap"
    },
    {
        "name": "Headland One",
        "url": "https://fonts.googleapis.com/css2?family=Headland+One&display=swap"
    },
    {
        "name": "Heebo",
        "url": "https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Henny Penny",
        "url": "https://fonts.googleapis.com/css2?family=Henny+Penny&display=swap"
    },
    {
        "name": "Hepta Slab",
        "url": "https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Herr Von Muellerhoff",
        "url": "https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap"
    },
    {
        "name": "Hi Melody",
        "url": "https://fonts.googleapis.com/css2?family=Hi+Melody&display=swap"
    },
    {
        "name": "Hina Mincho",
        "url": "https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap"
    },
    {
        "name": "Hind",
        "url": "https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Hind Guntur",
        "url": "https://fonts.googleapis.com/css2?family=Hind+Guntur:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Hind Madurai",
        "url": "https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Hind Siliguri",
        "url": "https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Hind Vadodara",
        "url": "https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Holtwood One SC",
        "url": "https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap"
    },
    {
        "name": "Homemade Apple",
        "url": "https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap"
    },
    {
        "name": "Homenaje",
        "url": "https://fonts.googleapis.com/css2?family=Homenaje&display=swap"
    },
    {
        "name": "Hubballi",
        "url": "https://fonts.googleapis.com/css2?family=Hubballi&display=swap"
    },
    {
        "name": "Hurricane",
        "url": "https://fonts.googleapis.com/css2?family=Hurricane&display=swap"
    },
    {
        "name": "IBM Plex Mono",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "IBM Plex Sans",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "IBM Plex Sans Arabic",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Sans Condensed",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "IBM Plex Sans Devanagari",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Sans Hebrew",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Sans KR",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Sans Thai",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Sans Thai Looped",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "IBM Plex Serif",
        "url": "https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "IM Fell DW Pica",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+DW+Pica:ital@0;1&display=swap"
    },
    {
        "name": "IM Fell DW Pica SC",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+DW+Pica+SC&display=swap"
    },
    {
        "name": "IM Fell Double Pica",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+Double+Pica:ital@0;1&display=swap"
    },
    {
        "name": "IM Fell Double Pica SC",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+Double+Pica+SC&display=swap"
    },
    {
        "name": "IM Fell English",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+English:ital@0;1&display=swap"
    },
    {
        "name": "IM Fell English SC",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap"
    },
    {
        "name": "IM Fell French Canon",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon:ital@0;1&display=swap"
    },
    {
        "name": "IM Fell French Canon SC",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon+SC&display=swap"
    },
    {
        "name": "IM Fell Great Primer",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer:ital@0;1&display=swap"
    },
    {
        "name": "IM Fell Great Primer SC",
        "url": "https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer+SC&display=swap"
    },
    {
        "name": "Ibarra Real Nova",
        "url": "https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Iceberg",
        "url": "https://fonts.googleapis.com/css2?family=Iceberg&display=swap"
    },
    {
        "name": "Iceland",
        "url": "https://fonts.googleapis.com/css2?family=Iceland&display=swap"
    },
    {
        "name": "Imbue",
        "url": "https://fonts.googleapis.com/css2?family=Imbue:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Imperial Script",
        "url": "https://fonts.googleapis.com/css2?family=Imperial+Script&display=swap"
    },
    {
        "name": "Imprima",
        "url": "https://fonts.googleapis.com/css2?family=Imprima&display=swap"
    },
    {
        "name": "Inconsolata",
        "url": "https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Inder",
        "url": "https://fonts.googleapis.com/css2?family=Inder&display=swap"
    },
    {
        "name": "Indie Flower",
        "url": "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap"
    },
    {
        "name": "Ingrid Darling",
        "url": "https://fonts.googleapis.com/css2?family=Ingrid+Darling&display=swap"
    },
    {
        "name": "Inika",
        "url": "https://fonts.googleapis.com/css2?family=Inika:wght@400;700&display=swap"
    },
    {
        "name": "Inknut Antiqua",
        "url": "https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Inria Sans",
        "url": "https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Inria Serif",
        "url": "https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Inspiration",
        "url": "https://fonts.googleapis.com/css2?family=Inspiration&display=swap"
    },
    {
        "name": "Inter",
        "url": "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Irish Grover",
        "url": "https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap"
    },
    {
        "name": "Island Moments",
        "url": "https://fonts.googleapis.com/css2?family=Island+Moments&display=swap"
    },
    {
        "name": "Istok Web",
        "url": "https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Italiana",
        "url": "https://fonts.googleapis.com/css2?family=Italiana&display=swap"
    },
    {
        "name": "Italianno",
        "url": "https://fonts.googleapis.com/css2?family=Italianno&display=swap"
    },
    {
        "name": "Itim",
        "url": "https://fonts.googleapis.com/css2?family=Itim&display=swap"
    },
    {
        "name": "Jacques Francois",
        "url": "https://fonts.googleapis.com/css2?family=Jacques+Francois&display=swap"
    },
    {
        "name": "Jacques Francois Shadow",
        "url": "https://fonts.googleapis.com/css2?family=Jacques+Francois+Shadow&display=swap"
    },
    {
        "name": "Jaldi",
        "url": "https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&display=swap"
    },
    {
        "name": "JetBrains Mono",
        "url": "https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Jim Nightshade",
        "url": "https://fonts.googleapis.com/css2?family=Jim+Nightshade&display=swap"
    },
    {
        "name": "Joan",
        "url": "https://fonts.googleapis.com/css2?family=Joan&display=swap"
    },
    {
        "name": "Jockey One",
        "url": "https://fonts.googleapis.com/css2?family=Jockey+One&display=swap"
    },
    {
        "name": "Jolly Lodger",
        "url": "https://fonts.googleapis.com/css2?family=Jolly+Lodger&display=swap"
    },
    {
        "name": "Jomhuria",
        "url": "https://fonts.googleapis.com/css2?family=Jomhuria&display=swap"
    },
    {
        "name": "Jomolhari",
        "url": "https://fonts.googleapis.com/css2?family=Jomolhari&display=swap"
    },
    {
        "name": "Josefin Sans",
        "url": "https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Josefin Slab",
        "url": "https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Jost",
        "url": "https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Joti One",
        "url": "https://fonts.googleapis.com/css2?family=Joti+One&display=swap"
    },
    {
        "name": "Jua",
        "url": "https://fonts.googleapis.com/css2?family=Jua&display=swap"
    },
    {
        "name": "Judson",
        "url": "https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Julee",
        "url": "https://fonts.googleapis.com/css2?family=Julee&display=swap"
    },
    {
        "name": "Julius Sans One",
        "url": "https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap"
    },
    {
        "name": "Junge",
        "url": "https://fonts.googleapis.com/css2?family=Junge&display=swap"
    },
    {
        "name": "Jura",
        "url": "https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Just Another Hand",
        "url": "https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap"
    },
    {
        "name": "Just Me Again Down Here",
        "url": "https://fonts.googleapis.com/css2?family=Just+Me+Again+Down+Here&display=swap"
    },
    {
        "name": "K2D",
        "url": "https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Kadwa",
        "url": "https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&display=swap"
    },
    {
        "name": "Kaisei Decol",
        "url": "https://fonts.googleapis.com/css2?family=Kaisei+Decol:wght@400;500;700&display=swap"
    },
    {
        "name": "Kaisei HarunoUmi",
        "url": "https://fonts.googleapis.com/css2?family=Kaisei+HarunoUmi:wght@400;500;700&display=swap"
    },
    {
        "name": "Kaisei Opti",
        "url": "https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;500;700&display=swap"
    },
    {
        "name": "Kaisei Tokumin",
        "url": "https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700;800&display=swap"
    },
    {
        "name": "Kalam",
        "url": "https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap"
    },
    {
        "name": "Kameron",
        "url": "https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap"
    },
    {
        "name": "Kanit",
        "url": "https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Kantumruy",
        "url": "https://fonts.googleapis.com/css2?family=Kantumruy:wght@300;400;700&display=swap"
    },
    {
        "name": "Kantumruy Pro",
        "url": "https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Karantina",
        "url": "https://fonts.googleapis.com/css2?family=Karantina:wght@300;400;700&display=swap"
    },
    {
        "name": "Karla",
        "url": "https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Karma",
        "url": "https://fonts.googleapis.com/css2?family=Karma:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Katibeh",
        "url": "https://fonts.googleapis.com/css2?family=Katibeh&display=swap"
    },
    {
        "name": "Kaushan Script",
        "url": "https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap"
    },
    {
        "name": "Kavivanar",
        "url": "https://fonts.googleapis.com/css2?family=Kavivanar&display=swap"
    },
    {
        "name": "Kavoon",
        "url": "https://fonts.googleapis.com/css2?family=Kavoon&display=swap"
    },
    {
        "name": "Kdam Thmor Pro",
        "url": "https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap"
    },
    {
        "name": "Keania One",
        "url": "https://fonts.googleapis.com/css2?family=Keania+One&display=swap"
    },
    {
        "name": "Kelly Slab",
        "url": "https://fonts.googleapis.com/css2?family=Kelly+Slab&display=swap"
    },
    {
        "name": "Kenia",
        "url": "https://fonts.googleapis.com/css2?family=Kenia&display=swap"
    },
    {
        "name": "Khand",
        "url": "https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Khmer",
        "url": "https://fonts.googleapis.com/css2?family=Khmer&display=swap"
    },
    {
        "name": "Khula",
        "url": "https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap"
    },
    {
        "name": "Kings",
        "url": "https://fonts.googleapis.com/css2?family=Kings&display=swap"
    },
    {
        "name": "Kirang Haerang",
        "url": "https://fonts.googleapis.com/css2?family=Kirang+Haerang&display=swap"
    },
    {
        "name": "Kite One",
        "url": "https://fonts.googleapis.com/css2?family=Kite+One&display=swap"
    },
    {
        "name": "Kiwi Maru",
        "url": "https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap"
    },
    {
        "name": "Klee One",
        "url": "https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&display=swap"
    },
    {
        "name": "Knewave",
        "url": "https://fonts.googleapis.com/css2?family=Knewave&display=swap"
    },
    {
        "name": "KoHo",
        "url": "https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Kodchasan",
        "url": "https://fonts.googleapis.com/css2?family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Koh Santepheap",
        "url": "https://fonts.googleapis.com/css2?family=Koh+Santepheap:wght@100;300;400;700;900&display=swap"
    },
    {
        "name": "Kolker Brush",
        "url": "https://fonts.googleapis.com/css2?family=Kolker+Brush&display=swap"
    },
    {
        "name": "Kosugi",
        "url": "https://fonts.googleapis.com/css2?family=Kosugi&display=swap"
    },
    {
        "name": "Kosugi Maru",
        "url": "https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap"
    },
    {
        "name": "Kotta One",
        "url": "https://fonts.googleapis.com/css2?family=Kotta+One&display=swap"
    },
    {
        "name": "Koulen",
        "url": "https://fonts.googleapis.com/css2?family=Koulen&display=swap"
    },
    {
        "name": "Kranky",
        "url": "https://fonts.googleapis.com/css2?family=Kranky&display=swap"
    },
    {
        "name": "Kreon",
        "url": "https://fonts.googleapis.com/css2?family=Kreon:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Kristi",
        "url": "https://fonts.googleapis.com/css2?family=Kristi&display=swap"
    },
    {
        "name": "Krona One",
        "url": "https://fonts.googleapis.com/css2?family=Krona+One&display=swap"
    },
    {
        "name": "Krub",
        "url": "https://fonts.googleapis.com/css2?family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Kufam",
        "url": "https://fonts.googleapis.com/css2?family=Kufam:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Kulim Park",
        "url": "https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap"
    },
    {
        "name": "Kumar One",
        "url": "https://fonts.googleapis.com/css2?family=Kumar+One&display=swap"
    },
    {
        "name": "Kumar One Outline",
        "url": "https://fonts.googleapis.com/css2?family=Kumar+One+Outline&display=swap"
    },
    {
        "name": "Kumbh Sans",
        "url": "https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Kurale",
        "url": "https://fonts.googleapis.com/css2?family=Kurale&display=swap"
    },
    {
        "name": "La Belle Aurore",
        "url": "https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap"
    },
    {
        "name": "Lacquer",
        "url": "https://fonts.googleapis.com/css2?family=Lacquer&display=swap"
    },
    {
        "name": "Laila",
        "url": "https://fonts.googleapis.com/css2?family=Laila:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Lakki Reddy",
        "url": "https://fonts.googleapis.com/css2?family=Lakki+Reddy&display=swap"
    },
    {
        "name": "Lalezar",
        "url": "https://fonts.googleapis.com/css2?family=Lalezar&display=swap"
    },
    {
        "name": "Lancelot",
        "url": "https://fonts.googleapis.com/css2?family=Lancelot&display=swap"
    },
    {
        "name": "Langar",
        "url": "https://fonts.googleapis.com/css2?family=Langar&display=swap"
    },
    {
        "name": "Lateef",
        "url": "https://fonts.googleapis.com/css2?family=Lateef&display=swap"
    },
    {
        "name": "Lato",
        "url": "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
    },
    {
        "name": "Lavishly Yours",
        "url": "https://fonts.googleapis.com/css2?family=Lavishly+Yours&display=swap"
    },
    {
        "name": "League Gothic",
        "url": "https://fonts.googleapis.com/css2?family=League+Gothic&display=swap"
    },
    {
        "name": "League Script",
        "url": "https://fonts.googleapis.com/css2?family=League+Script&display=swap"
    },
    {
        "name": "League Spartan",
        "url": "https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Leckerli One",
        "url": "https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap"
    },
    {
        "name": "Ledger",
        "url": "https://fonts.googleapis.com/css2?family=Ledger&display=swap"
    },
    {
        "name": "Lekton",
        "url": "https://fonts.googleapis.com/css2?family=Lekton:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Lemon",
        "url": "https://fonts.googleapis.com/css2?family=Lemon&display=swap"
    },
    {
        "name": "Lemonada",
        "url": "https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Lexend",
        "url": "https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Deca",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Exa",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Giga",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Giga:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Mega",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Mega:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Peta",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Peta:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Tera",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Tera:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Lexend Zetta",
        "url": "https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Libre Barcode 128",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+128&display=swap"
    },
    {
        "name": "Libre Barcode 128 Text",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap"
    },
    {
        "name": "Libre Barcode 39",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap"
    },
    {
        "name": "Libre Barcode 39 Extended",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended&display=swap"
    },
    {
        "name": "Libre Barcode 39 Extended Text",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended+Text&display=swap"
    },
    {
        "name": "Libre Barcode 39 Text",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap"
    },
    {
        "name": "Libre Barcode EAN13 Text",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Barcode+EAN13+Text&display=swap"
    },
    {
        "name": "Libre Baskerville",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Libre Bodoni",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Libre Caslon Display",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap"
    },
    {
        "name": "Libre Caslon Text",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Libre Franklin",
        "url": "https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Licorice",
        "url": "https://fonts.googleapis.com/css2?family=Licorice&display=swap"
    },
    {
        "name": "Life Savers",
        "url": "https://fonts.googleapis.com/css2?family=Life+Savers:wght@400;700;800&display=swap"
    },
    {
        "name": "Lilita One",
        "url": "https://fonts.googleapis.com/css2?family=Lilita+One&display=swap"
    },
    {
        "name": "Lily Script One",
        "url": "https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap"
    },
    {
        "name": "Limelight",
        "url": "https://fonts.googleapis.com/css2?family=Limelight&display=swap"
    },
    {
        "name": "Linden Hill",
        "url": "https://fonts.googleapis.com/css2?family=Linden+Hill:ital@0;1&display=swap"
    },
    {
        "name": "Literata",
        "url": "https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Liu Jian Mao Cao",
        "url": "https://fonts.googleapis.com/css2?family=Liu+Jian+Mao+Cao&display=swap"
    },
    {
        "name": "Livvic",
        "url": "https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"
    },
    {
        "name": "Lobster",
        "url": "https://fonts.googleapis.com/css2?family=Lobster&display=swap"
    },
    {
        "name": "Lobster Two",
        "url": "https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Londrina Outline",
        "url": "https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap"
    },
    {
        "name": "Londrina Shadow",
        "url": "https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap"
    },
    {
        "name": "Londrina Sketch",
        "url": "https://fonts.googleapis.com/css2?family=Londrina+Sketch&display=swap"
    },
    {
        "name": "Londrina Solid",
        "url": "https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap"
    },
    {
        "name": "Long Cang",
        "url": "https://fonts.googleapis.com/css2?family=Long+Cang&display=swap"
    },
    {
        "name": "Lora",
        "url": "https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Love Light",
        "url": "https://fonts.googleapis.com/css2?family=Love+Light&display=swap"
    },
    {
        "name": "Love Ya Like A Sister",
        "url": "https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap"
    },
    {
        "name": "Loved by the King",
        "url": "https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap"
    },
    {
        "name": "Lovers Quarrel",
        "url": "https://fonts.googleapis.com/css2?family=Lovers+Quarrel&display=swap"
    },
    {
        "name": "Luckiest Guy",
        "url": "https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap"
    },
    {
        "name": "Lusitana",
        "url": "https://fonts.googleapis.com/css2?family=Lusitana:wght@400;700&display=swap"
    },
    {
        "name": "Lustria",
        "url": "https://fonts.googleapis.com/css2?family=Lustria&display=swap"
    },
    {
        "name": "Luxurious Roman",
        "url": "https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap"
    },
    {
        "name": "Luxurious Script",
        "url": "https://fonts.googleapis.com/css2?family=Luxurious+Script&display=swap"
    },
    {
        "name": "M PLUS 1",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "M PLUS 1 Code",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "M PLUS 1p",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap"
    },
    {
        "name": "M PLUS 2",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "M PLUS Code Latin",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+Code+Latin:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "M PLUS Rounded 1c",
        "url": "https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap"
    },
    {
        "name": "Ma Shan Zheng",
        "url": "https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap"
    },
    {
        "name": "Macondo",
        "url": "https://fonts.googleapis.com/css2?family=Macondo&display=swap"
    },
    {
        "name": "Macondo Swash Caps",
        "url": "https://fonts.googleapis.com/css2?family=Macondo+Swash+Caps&display=swap"
    },
    {
        "name": "Mada",
        "url": "https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap"
    },
    {
        "name": "Magra",
        "url": "https://fonts.googleapis.com/css2?family=Magra:wght@400;700&display=swap"
    },
    {
        "name": "Maiden Orange",
        "url": "https://fonts.googleapis.com/css2?family=Maiden+Orange&display=swap"
    },
    {
        "name": "Maitree",
        "url": "https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Major Mono Display",
        "url": "https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap"
    },
    {
        "name": "Mako",
        "url": "https://fonts.googleapis.com/css2?family=Mako&display=swap"
    },
    {
        "name": "Mali",
        "url": "https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Mallanna",
        "url": "https://fonts.googleapis.com/css2?family=Mallanna&display=swap"
    },
    {
        "name": "Mandali",
        "url": "https://fonts.googleapis.com/css2?family=Mandali&display=swap"
    },
    {
        "name": "Manjari",
        "url": "https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap"
    },
    {
        "name": "Manrope",
        "url": "https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Mansalva",
        "url": "https://fonts.googleapis.com/css2?family=Mansalva&display=swap"
    },
    {
        "name": "Manuale",
        "url": "https://fonts.googleapis.com/css2?family=Manuale:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Marcellus",
        "url": "https://fonts.googleapis.com/css2?family=Marcellus&display=swap"
    },
    {
        "name": "Marcellus SC",
        "url": "https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap"
    },
    {
        "name": "Marck Script",
        "url": "https://fonts.googleapis.com/css2?family=Marck+Script&display=swap"
    },
    {
        "name": "Margarine",
        "url": "https://fonts.googleapis.com/css2?family=Margarine&display=swap"
    },
    {
        "name": "Markazi Text",
        "url": "https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Marko One",
        "url": "https://fonts.googleapis.com/css2?family=Marko+One&display=swap"
    },
    {
        "name": "Marmelad",
        "url": "https://fonts.googleapis.com/css2?family=Marmelad&display=swap"
    },
    {
        "name": "Martel",
        "url": "https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700;800;900&display=swap"
    },
    {
        "name": "Martel Sans",
        "url": "https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap"
    },
    {
        "name": "Marvel",
        "url": "https://fonts.googleapis.com/css2?family=Marvel:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Mate",
        "url": "https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap"
    },
    {
        "name": "Mate SC",
        "url": "https://fonts.googleapis.com/css2?family=Mate+SC&display=swap"
    },
    {
        "name": "Maven Pro",
        "url": "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap"
    },
    {
        "name": "McLaren",
        "url": "https://fonts.googleapis.com/css2?family=McLaren&display=swap"
    },
    {
        "name": "Mea Culpa",
        "url": "https://fonts.googleapis.com/css2?family=Mea+Culpa&display=swap"
    },
    {
        "name": "Meddon",
        "url": "https://fonts.googleapis.com/css2?family=Meddon&display=swap"
    },
    {
        "name": "MedievalSharp",
        "url": "https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap"
    },
    {
        "name": "Medula One",
        "url": "https://fonts.googleapis.com/css2?family=Medula+One&display=swap"
    },
    {
        "name": "Meera Inimai",
        "url": "https://fonts.googleapis.com/css2?family=Meera+Inimai&display=swap"
    },
    {
        "name": "Megrim",
        "url": "https://fonts.googleapis.com/css2?family=Megrim&display=swap"
    },
    {
        "name": "Meie Script",
        "url": "https://fonts.googleapis.com/css2?family=Meie+Script&display=swap"
    },
    {
        "name": "Meow Script",
        "url": "https://fonts.googleapis.com/css2?family=Meow+Script&display=swap"
    },
    {
        "name": "Merienda",
        "url": "https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap"
    },
    {
        "name": "Merienda One",
        "url": "https://fonts.googleapis.com/css2?family=Merienda+One&display=swap"
    },
    {
        "name": "Merriweather",
        "url": "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
    },
    {
        "name": "Merriweather Sans",
        "url": "https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Metal",
        "url": "https://fonts.googleapis.com/css2?family=Metal&display=swap"
    },
    {
        "name": "Metal Mania",
        "url": "https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap"
    },
    {
        "name": "Metamorphous",
        "url": "https://fonts.googleapis.com/css2?family=Metamorphous&display=swap"
    },
    {
        "name": "Metrophobic",
        "url": "https://fonts.googleapis.com/css2?family=Metrophobic&display=swap"
    },
    {
        "name": "Michroma",
        "url": "https://fonts.googleapis.com/css2?family=Michroma&display=swap"
    },
    {
        "name": "Milonga",
        "url": "https://fonts.googleapis.com/css2?family=Milonga&display=swap"
    },
    {
        "name": "Miltonian",
        "url": "https://fonts.googleapis.com/css2?family=Miltonian&display=swap"
    },
    {
        "name": "Miltonian Tattoo",
        "url": "https://fonts.googleapis.com/css2?family=Miltonian+Tattoo&display=swap"
    },
    {
        "name": "Mina",
        "url": "https://fonts.googleapis.com/css2?family=Mina:wght@400;700&display=swap"
    },
    {
        "name": "Mingzat",
        "url": "https://fonts.googleapis.com/css2?family=Mingzat&display=swap"
    },
    {
        "name": "Miniver",
        "url": "https://fonts.googleapis.com/css2?family=Miniver&display=swap"
    },
    {
        "name": "Miriam Libre",
        "url": "https://fonts.googleapis.com/css2?family=Miriam+Libre:wght@400;700&display=swap"
    },
    {
        "name": "Mirza",
        "url": "https://fonts.googleapis.com/css2?family=Mirza:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Miss Fajardose",
        "url": "https://fonts.googleapis.com/css2?family=Miss+Fajardose&display=swap"
    },
    {
        "name": "Mitr",
        "url": "https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Mochiy Pop One",
        "url": "https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap"
    },
    {
        "name": "Mochiy Pop P One",
        "url": "https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap"
    },
    {
        "name": "Modak",
        "url": "https://fonts.googleapis.com/css2?family=Modak&display=swap"
    },
    {
        "name": "Modern Antiqua",
        "url": "https://fonts.googleapis.com/css2?family=Modern+Antiqua&display=swap"
    },
    {
        "name": "Mogra",
        "url": "https://fonts.googleapis.com/css2?family=Mogra&display=swap"
    },
    {
        "name": "Mohave",
        "url": "https://fonts.googleapis.com/css2?family=Mohave:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Molengo",
        "url": "https://fonts.googleapis.com/css2?family=Molengo&display=swap"
    },
    {
        "name": "Monda",
        "url": "https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap"
    },
    {
        "name": "Monofett",
        "url": "https://fonts.googleapis.com/css2?family=Monofett&display=swap"
    },
    {
        "name": "Monoton",
        "url": "https://fonts.googleapis.com/css2?family=Monoton&display=swap"
    },
    {
        "name": "Monsieur La Doulaise",
        "url": "https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap"
    },
    {
        "name": "Montaga",
        "url": "https://fonts.googleapis.com/css2?family=Montaga&display=swap"
    },
    {
        "name": "Montagu Slab",
        "url": "https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "MonteCarlo",
        "url": "https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap"
    },
    {
        "name": "Montez",
        "url": "https://fonts.googleapis.com/css2?family=Montez&display=swap"
    },
    {
        "name": "Montserrat",
        "url": "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Montserrat Alternates",
        "url": "https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Montserrat Subrayada",
        "url": "https://fonts.googleapis.com/css2?family=Montserrat+Subrayada:wght@400;700&display=swap"
    },
    {
        "name": "Moo Lah Lah",
        "url": "https://fonts.googleapis.com/css2?family=Moo+Lah+Lah&display=swap"
    },
    {
        "name": "Moon Dance",
        "url": "https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap"
    },
    {
        "name": "Moul",
        "url": "https://fonts.googleapis.com/css2?family=Moul&display=swap"
    },
    {
        "name": "Moulpali",
        "url": "https://fonts.googleapis.com/css2?family=Moulpali&display=swap"
    },
    {
        "name": "Mountains of Christmas",
        "url": "https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap"
    },
    {
        "name": "Mouse Memoirs",
        "url": "https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap"
    },
    {
        "name": "Mr Bedfort",
        "url": "https://fonts.googleapis.com/css2?family=Mr+Bedfort&display=swap"
    },
    {
        "name": "Mr Dafoe",
        "url": "https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap"
    },
    {
        "name": "Mr De Haviland",
        "url": "https://fonts.googleapis.com/css2?family=Mr+De+Haviland&display=swap"
    },
    {
        "name": "Mrs Saint Delafield",
        "url": "https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap"
    },
    {
        "name": "Mrs Sheppards",
        "url": "https://fonts.googleapis.com/css2?family=Mrs+Sheppards&display=swap"
    },
    {
        "name": "Ms Madi",
        "url": "https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap"
    },
    {
        "name": "Mukta",
        "url": "https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Mukta Mahee",
        "url": "https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Mukta Malar",
        "url": "https://fonts.googleapis.com/css2?family=Mukta+Malar:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Mukta Vaani",
        "url": "https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Mulish",
        "url": "https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Murecho",
        "url": "https://fonts.googleapis.com/css2?family=Murecho:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "MuseoModerno",
        "url": "https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "My Soul",
        "url": "https://fonts.googleapis.com/css2?family=My+Soul&display=swap"
    },
    {
        "name": "Mystery Quest",
        "url": "https://fonts.googleapis.com/css2?family=Mystery+Quest&display=swap"
    },
    {
        "name": "NTR",
        "url": "https://fonts.googleapis.com/css2?family=NTR&display=swap"
    },
    {
        "name": "Nanum Brush Script",
        "url": "https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap"
    },
    {
        "name": "Nanum Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap"
    },
    {
        "name": "Nanum Gothic Coding",
        "url": "https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&display=swap"
    },
    {
        "name": "Nanum Myeongjo",
        "url": "https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap"
    },
    {
        "name": "Nanum Pen Script",
        "url": "https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap"
    },
    {
        "name": "Neonderthaw",
        "url": "https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap"
    },
    {
        "name": "Nerko One",
        "url": "https://fonts.googleapis.com/css2?family=Nerko+One&display=swap"
    },
    {
        "name": "Neucha",
        "url": "https://fonts.googleapis.com/css2?family=Neucha&display=swap"
    },
    {
        "name": "Neuton",
        "url": "https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap"
    },
    {
        "name": "New Rocker",
        "url": "https://fonts.googleapis.com/css2?family=New+Rocker&display=swap"
    },
    {
        "name": "New Tegomin",
        "url": "https://fonts.googleapis.com/css2?family=New+Tegomin&display=swap"
    },
    {
        "name": "News Cycle",
        "url": "https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap"
    },
    {
        "name": "Newsreader",
        "url": "https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Niconne",
        "url": "https://fonts.googleapis.com/css2?family=Niconne&display=swap"
    },
    {
        "name": "Niramit",
        "url": "https://fonts.googleapis.com/css2?family=Niramit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Nixie One",
        "url": "https://fonts.googleapis.com/css2?family=Nixie+One&display=swap"
    },
    {
        "name": "Nobile",
        "url": "https://fonts.googleapis.com/css2?family=Nobile:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
    },
    {
        "name": "Nokora",
        "url": "https://fonts.googleapis.com/css2?family=Nokora:wght@100;300;400;700;900&display=swap"
    },
    {
        "name": "Norican",
        "url": "https://fonts.googleapis.com/css2?family=Norican&display=swap"
    },
    {
        "name": "Nosifer",
        "url": "https://fonts.googleapis.com/css2?family=Nosifer&display=swap"
    },
    {
        "name": "Notable",
        "url": "https://fonts.googleapis.com/css2?family=Notable&display=swap"
    },
    {
        "name": "Nothing You Could Do",
        "url": "https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap"
    },
    {
        "name": "Noticia Text",
        "url": "https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Noto Emoji",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Noto Kufi Arabic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Music",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Music&display=swap"
    },
    {
        "name": "Noto Naskh Arabic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Nastaliq Urdu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400;700&display=swap"
    },
    {
        "name": "Noto Rashi Hebrew",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Rashi+Hebrew:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Noto Sans Adlam",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Adlam:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Adlam Unjoined",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Adlam+Unjoined:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Anatolian Hieroglyphs",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Anatolian+Hieroglyphs&display=swap"
    },
    {
        "name": "Noto Sans Arabic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Armenian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Avestan",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Avestan&display=swap"
    },
    {
        "name": "Noto Sans Balinese",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Balinese:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Bamum",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Bamum:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Bassa Vah",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Bassa+Vah&display=swap"
    },
    {
        "name": "Noto Sans Batak",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Batak&display=swap"
    },
    {
        "name": "Noto Sans Bengali",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Bhaiksuki",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Bhaiksuki&display=swap"
    },
    {
        "name": "Noto Sans Brahmi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Brahmi&display=swap"
    },
    {
        "name": "Noto Sans Buginese",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Buginese&display=swap"
    },
    {
        "name": "Noto Sans Buhid",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Buhid&display=swap"
    },
    {
        "name": "Noto Sans Canadian Aboriginal",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Canadian+Aboriginal:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Carian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Carian&display=swap"
    },
    {
        "name": "Noto Sans Caucasian Albanian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Caucasian+Albanian&display=swap"
    },
    {
        "name": "Noto Sans Chakma",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Chakma&display=swap"
    },
    {
        "name": "Noto Sans Cham",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Cham:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Cherokee",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Cherokee:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Coptic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Coptic&display=swap"
    },
    {
        "name": "Noto Sans Cuneiform",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Cuneiform&display=swap"
    },
    {
        "name": "Noto Sans Cypriot",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Cypriot&display=swap"
    },
    {
        "name": "Noto Sans Deseret",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Deseret&display=swap"
    },
    {
        "name": "Noto Sans Devanagari",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Display",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Noto Sans Duployan",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Duployan&display=swap"
    },
    {
        "name": "Noto Sans Egyptian Hieroglyphs",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Egyptian+Hieroglyphs&display=swap"
    },
    {
        "name": "Noto Sans Elbasan",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Elbasan&display=swap"
    },
    {
        "name": "Noto Sans Elymaic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Elymaic&display=swap"
    },
    {
        "name": "Noto Sans Georgian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Glagolitic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Glagolitic&display=swap"
    },
    {
        "name": "Noto Sans Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Gothic&display=swap"
    },
    {
        "name": "Noto Sans Grantha",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Grantha&display=swap"
    },
    {
        "name": "Noto Sans Gujarati",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Gunjala Gondi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Gunjala+Gondi&display=swap"
    },
    {
        "name": "Noto Sans Gurmukhi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Gurmukhi:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans HK",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Noto Sans Hanifi Rohingya",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Hanifi+Rohingya:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Hanunoo",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Hanunoo&display=swap"
    },
    {
        "name": "Noto Sans Hatran",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Hatran&display=swap"
    },
    {
        "name": "Noto Sans Hebrew",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Imperial Aramaic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Imperial+Aramaic&display=swap"
    },
    {
        "name": "Noto Sans Indic Siyaq Numbers",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Indic+Siyaq+Numbers&display=swap"
    },
    {
        "name": "Noto Sans Inscriptional Pahlavi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Inscriptional+Pahlavi&display=swap"
    },
    {
        "name": "Noto Sans Inscriptional Parthian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Inscriptional+Parthian&display=swap"
    },
    {
        "name": "Noto Sans JP",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Noto Sans Javanese",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Javanese:wght@400;700&display=swap"
    },
    {
        "name": "Noto Sans KR",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Noto Sans Kaithi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Kaithi&display=swap"
    },
    {
        "name": "Noto Sans Kannada",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Kannada:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Kayah Li",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Kayah+Li:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Kharoshthi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Kharoshthi&display=swap"
    },
    {
        "name": "Noto Sans Khmer",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Khmer:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Khojki",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Khojki&display=swap"
    },
    {
        "name": "Noto Sans Khudawadi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Khudawadi&display=swap"
    },
    {
        "name": "Noto Sans Lao",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Lepcha",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Lepcha&display=swap"
    },
    {
        "name": "Noto Sans Limbu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Limbu&display=swap"
    },
    {
        "name": "Noto Sans Linear A",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Linear+A&display=swap"
    },
    {
        "name": "Noto Sans Linear B",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Linear+B&display=swap"
    },
    {
        "name": "Noto Sans Lisu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Lisu:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Lycian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Lycian&display=swap"
    },
    {
        "name": "Noto Sans Lydian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Lydian&display=swap"
    },
    {
        "name": "Noto Sans Mahajani",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mahajani&display=swap"
    },
    {
        "name": "Noto Sans Malayalam",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Malayalam:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Mandaic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mandaic&display=swap"
    },
    {
        "name": "Noto Sans Manichaean",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Manichaean&display=swap"
    },
    {
        "name": "Noto Sans Marchen",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Marchen&display=swap"
    },
    {
        "name": "Noto Sans Masaram Gondi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Masaram+Gondi&display=swap"
    },
    {
        "name": "Noto Sans Math",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Math&display=swap"
    },
    {
        "name": "Noto Sans Mayan Numerals",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mayan+Numerals&display=swap"
    },
    {
        "name": "Noto Sans Medefaidrin",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Medefaidrin:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Meetei Mayek",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Meetei+Mayek:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Meroitic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Meroitic&display=swap"
    },
    {
        "name": "Noto Sans Miao",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Miao&display=swap"
    },
    {
        "name": "Noto Sans Modi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Modi&display=swap"
    },
    {
        "name": "Noto Sans Mongolian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mongolian&display=swap"
    },
    {
        "name": "Noto Sans Mono",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Mro",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Mro&display=swap"
    },
    {
        "name": "Noto Sans Multani",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Multani&display=swap"
    },
    {
        "name": "Noto Sans Myanmar",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans N Ko",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+N+Ko&display=swap"
    },
    {
        "name": "Noto Sans Nabataean",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Nabataean&display=swap"
    },
    {
        "name": "Noto Sans New Tai Lue",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+New+Tai+Lue&display=swap"
    },
    {
        "name": "Noto Sans Newa",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Newa&display=swap"
    },
    {
        "name": "Noto Sans Nushu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Nushu&display=swap"
    },
    {
        "name": "Noto Sans Ogham",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Ogham&display=swap"
    },
    {
        "name": "Noto Sans Ol Chiki",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Ol+Chiki:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Old Hungarian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Hungarian&display=swap"
    },
    {
        "name": "Noto Sans Old Italic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Italic&display=swap"
    },
    {
        "name": "Noto Sans Old North Arabian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+North+Arabian&display=swap"
    },
    {
        "name": "Noto Sans Old Permic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Permic&display=swap"
    },
    {
        "name": "Noto Sans Old Persian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Persian&display=swap"
    },
    {
        "name": "Noto Sans Old Sogdian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Sogdian&display=swap"
    },
    {
        "name": "Noto Sans Old South Arabian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+South+Arabian&display=swap"
    },
    {
        "name": "Noto Sans Old Turkic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Old+Turkic&display=swap"
    },
    {
        "name": "Noto Sans Oriya",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Oriya:wght@100;400;700;900&display=swap"
    },
    {
        "name": "Noto Sans Osage",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Osage&display=swap"
    },
    {
        "name": "Noto Sans Osmanya",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Osmanya&display=swap"
    },
    {
        "name": "Noto Sans Pahawh Hmong",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Pahawh+Hmong&display=swap"
    },
    {
        "name": "Noto Sans Palmyrene",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Palmyrene&display=swap"
    },
    {
        "name": "Noto Sans Pau Cin Hau",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Pau+Cin+Hau&display=swap"
    },
    {
        "name": "Noto Sans Phags Pa",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Phags+Pa&display=swap"
    },
    {
        "name": "Noto Sans Phoenician",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Phoenician&display=swap"
    },
    {
        "name": "Noto Sans Psalter Pahlavi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Psalter+Pahlavi&display=swap"
    },
    {
        "name": "Noto Sans Rejang",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Rejang&display=swap"
    },
    {
        "name": "Noto Sans Runic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Runic&display=swap"
    },
    {
        "name": "Noto Sans SC",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Noto Sans Samaritan",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Samaritan&display=swap"
    },
    {
        "name": "Noto Sans Saurashtra",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Saurashtra&display=swap"
    },
    {
        "name": "Noto Sans Sharada",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Sharada&display=swap"
    },
    {
        "name": "Noto Sans Shavian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Shavian&display=swap"
    },
    {
        "name": "Noto Sans Siddham",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Siddham&display=swap"
    },
    {
        "name": "Noto Sans Sinhala",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Sinhala:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Sogdian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Sogdian&display=swap"
    },
    {
        "name": "Noto Sans Sora Sompeng",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Sora+Sompeng:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Soyombo",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Soyombo&display=swap"
    },
    {
        "name": "Noto Sans Sundanese",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Sundanese:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Syloti Nagri",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Syloti+Nagri&display=swap"
    },
    {
        "name": "Noto Sans Symbols",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Symbols 2",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols+2&display=swap"
    },
    {
        "name": "Noto Sans Syriac",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Syriac:wght@100;400;900&display=swap"
    },
    {
        "name": "Noto Sans TC",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Noto Sans Tagalog",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tagalog&display=swap"
    },
    {
        "name": "Noto Sans Tagbanwa",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tagbanwa&display=swap"
    },
    {
        "name": "Noto Sans Tai Le",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tai+Le&display=swap"
    },
    {
        "name": "Noto Sans Tai Tham",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tai+Tham:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Sans Tai Viet",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tai+Viet&display=swap"
    },
    {
        "name": "Noto Sans Takri",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Takri&display=swap"
    },
    {
        "name": "Noto Sans Tamil",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Tamil Supplement",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil+Supplement&display=swap"
    },
    {
        "name": "Noto Sans Telugu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Telugu:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Thaana",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Thaana:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Thai",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Thai Looped",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Sans Tifinagh",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tifinagh&display=swap"
    },
    {
        "name": "Noto Sans Tirhuta",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Tirhuta&display=swap"
    },
    {
        "name": "Noto Sans Ugaritic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Ugaritic&display=swap"
    },
    {
        "name": "Noto Sans Vai",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Vai&display=swap"
    },
    {
        "name": "Noto Sans Wancho",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Wancho&display=swap"
    },
    {
        "name": "Noto Sans Warang Citi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Warang+Citi&display=swap"
    },
    {
        "name": "Noto Sans Yi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Yi&display=swap"
    },
    {
        "name": "Noto Sans Zanabazar Square",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans+Zanabazar+Square&display=swap"
    },
    {
        "name": "Noto Serif",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Noto Serif Ahom",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Ahom&display=swap"
    },
    {
        "name": "Noto Serif Armenian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Armenian:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Balinese",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Balinese&display=swap"
    },
    {
        "name": "Noto Serif Bengali",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Devanagari",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Display",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Noto Serif Dogra",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Dogra&display=swap"
    },
    {
        "name": "Noto Serif Ethiopic",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Ethiopic:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Georgian",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Grantha",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Grantha&display=swap"
    },
    {
        "name": "Noto Serif Gujarati",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Gujarati:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Gurmukhi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Gurmukhi:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Hebrew",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Hebrew:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif JP",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap"
    },
    {
        "name": "Noto Serif KR",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap"
    },
    {
        "name": "Noto Serif Kannada",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Kannada:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Khmer",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Khmer:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Lao",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Malayalam",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Malayalam:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Myanmar",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Myanmar:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Nyiakeng Puachue Hmong",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Nyiakeng+Puachue+Hmong:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Serif SC",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200;300;400;500;600;700;900&display=swap"
    },
    {
        "name": "Noto Serif Sinhala",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Sinhala:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif TC",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&display=swap"
    },
    {
        "name": "Noto Serif Tamil",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Tamil:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Noto Serif Tangut",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Tangut&display=swap"
    },
    {
        "name": "Noto Serif Telugu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Telugu:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Thai",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Tibetan",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Tibetan:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Noto Serif Yezidi",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Serif+Yezidi:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Noto Traditional Nushu",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Traditional+Nushu&display=swap"
    },
    {
        "name": "Nova Cut",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Cut&display=swap"
    },
    {
        "name": "Nova Flat",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Flat&display=swap"
    },
    {
        "name": "Nova Mono",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap"
    },
    {
        "name": "Nova Oval",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Oval&display=swap"
    },
    {
        "name": "Nova Round",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Round&display=swap"
    },
    {
        "name": "Nova Script",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Script&display=swap"
    },
    {
        "name": "Nova Slim",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Slim&display=swap"
    },
    {
        "name": "Nova Square",
        "url": "https://fonts.googleapis.com/css2?family=Nova+Square&display=swap"
    },
    {
        "name": "Numans",
        "url": "https://fonts.googleapis.com/css2?family=Numans&display=swap"
    },
    {
        "name": "Nunito",
        "url": "https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Nunito Sans",
        "url": "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Nuosu SIL",
        "url": "https://fonts.googleapis.com/css2?family=Nuosu+SIL&display=swap"
    },
    {
        "name": "Odibee Sans",
        "url": "https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap"
    },
    {
        "name": "Odor Mean Chey",
        "url": "https://fonts.googleapis.com/css2?family=Odor+Mean+Chey&display=swap"
    },
    {
        "name": "Offside",
        "url": "https://fonts.googleapis.com/css2?family=Offside&display=swap"
    },
    {
        "name": "Oi",
        "url": "https://fonts.googleapis.com/css2?family=Oi&display=swap"
    },
    {
        "name": "Old Standard TT",
        "url": "https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Oldenburg",
        "url": "https://fonts.googleapis.com/css2?family=Oldenburg&display=swap"
    },
    {
        "name": "Ole",
        "url": "https://fonts.googleapis.com/css2?family=Ole&display=swap"
    },
    {
        "name": "Oleo Script",
        "url": "https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap"
    },
    {
        "name": "Oleo Script Swash Caps",
        "url": "https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap"
    },
    {
        "name": "Oooh Baby",
        "url": "https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap"
    },
    {
        "name": "Open Sans",
        "url": "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Oranienbaum",
        "url": "https://fonts.googleapis.com/css2?family=Oranienbaum&display=swap"
    },
    {
        "name": "Orbitron",
        "url": "https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Oregano",
        "url": "https://fonts.googleapis.com/css2?family=Oregano:ital@0;1&display=swap"
    },
    {
        "name": "Orelega One",
        "url": "https://fonts.googleapis.com/css2?family=Orelega+One&display=swap"
    },
    {
        "name": "Orienta",
        "url": "https://fonts.googleapis.com/css2?family=Orienta&display=swap"
    },
    {
        "name": "Original Surfer",
        "url": "https://fonts.googleapis.com/css2?family=Original+Surfer&display=swap"
    },
    {
        "name": "Oswald",
        "url": "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Outfit",
        "url": "https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Over the Rainbow",
        "url": "https://fonts.googleapis.com/css2?family=Over+the+Rainbow&display=swap"
    },
    {
        "name": "Overlock",
        "url": "https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap"
    },
    {
        "name": "Overlock SC",
        "url": "https://fonts.googleapis.com/css2?family=Overlock+SC&display=swap"
    },
    {
        "name": "Overpass",
        "url": "https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Overpass Mono",
        "url": "https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Ovo",
        "url": "https://fonts.googleapis.com/css2?family=Ovo&display=swap"
    },
    {
        "name": "Oxanium",
        "url": "https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Oxygen",
        "url": "https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap"
    },
    {
        "name": "Oxygen Mono",
        "url": "https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap"
    },
    {
        "name": "PT Mono",
        "url": "https://fonts.googleapis.com/css2?family=PT+Mono&display=swap"
    },
    {
        "name": "PT Sans",
        "url": "https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "PT Sans Caption",
        "url": "https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&display=swap"
    },
    {
        "name": "PT Sans Narrow",
        "url": "https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap"
    },
    {
        "name": "PT Serif",
        "url": "https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "PT Serif Caption",
        "url": "https://fonts.googleapis.com/css2?family=PT+Serif+Caption:ital@0;1&display=swap"
    },
    {
        "name": "Pacifico",
        "url": "https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
    },
    {
        "name": "Padauk",
        "url": "https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&display=swap"
    },
    {
        "name": "Palanquin",
        "url": "https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Palanquin Dark",
        "url": "https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Pangolin",
        "url": "https://fonts.googleapis.com/css2?family=Pangolin&display=swap"
    },
    {
        "name": "Paprika",
        "url": "https://fonts.googleapis.com/css2?family=Paprika&display=swap"
    },
    {
        "name": "Parisienne",
        "url": "https://fonts.googleapis.com/css2?family=Parisienne&display=swap"
    },
    {
        "name": "Passero One",
        "url": "https://fonts.googleapis.com/css2?family=Passero+One&display=swap"
    },
    {
        "name": "Passion One",
        "url": "https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap"
    },
    {
        "name": "Passions Conflict",
        "url": "https://fonts.googleapis.com/css2?family=Passions+Conflict&display=swap"
    },
    {
        "name": "Pathway Gothic One",
        "url": "https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap"
    },
    {
        "name": "Patrick Hand",
        "url": "https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap"
    },
    {
        "name": "Patrick Hand SC",
        "url": "https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap"
    },
    {
        "name": "Pattaya",
        "url": "https://fonts.googleapis.com/css2?family=Pattaya&display=swap"
    },
    {
        "name": "Patua One",
        "url": "https://fonts.googleapis.com/css2?family=Patua+One&display=swap"
    },
    {
        "name": "Pavanam",
        "url": "https://fonts.googleapis.com/css2?family=Pavanam&display=swap"
    },
    {
        "name": "Paytone One",
        "url": "https://fonts.googleapis.com/css2?family=Paytone+One&display=swap"
    },
    {
        "name": "Peddana",
        "url": "https://fonts.googleapis.com/css2?family=Peddana&display=swap"
    },
    {
        "name": "Peralta",
        "url": "https://fonts.googleapis.com/css2?family=Peralta&display=swap"
    },
    {
        "name": "Permanent Marker",
        "url": "https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
    },
    {
        "name": "Petemoss",
        "url": "https://fonts.googleapis.com/css2?family=Petemoss&display=swap"
    },
    {
        "name": "Petit Formal Script",
        "url": "https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap"
    },
    {
        "name": "Petrona",
        "url": "https://fonts.googleapis.com/css2?family=Petrona:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Philosopher",
        "url": "https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Piazzolla",
        "url": "https://fonts.googleapis.com/css2?family=Piazzolla:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Piedra",
        "url": "https://fonts.googleapis.com/css2?family=Piedra&display=swap"
    },
    {
        "name": "Pinyon Script",
        "url": "https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap"
    },
    {
        "name": "Pirata One",
        "url": "https://fonts.googleapis.com/css2?family=Pirata+One&display=swap"
    },
    {
        "name": "Plaster",
        "url": "https://fonts.googleapis.com/css2?family=Plaster&display=swap"
    },
    {
        "name": "Play",
        "url": "https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap"
    },
    {
        "name": "Playball",
        "url": "https://fonts.googleapis.com/css2?family=Playball&display=swap"
    },
    {
        "name": "Playfair Display",
        "url": "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Playfair Display SC",
        "url": "https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap"
    },
    {
        "name": "Plus Jakarta Sans",
        "url": "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Podkova",
        "url": "https://fonts.googleapis.com/css2?family=Podkova:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Poiret One",
        "url": "https://fonts.googleapis.com/css2?family=Poiret+One&display=swap"
    },
    {
        "name": "Poller One",
        "url": "https://fonts.googleapis.com/css2?family=Poller+One&display=swap"
    },
    {
        "name": "Poly",
        "url": "https://fonts.googleapis.com/css2?family=Poly:ital@0;1&display=swap"
    },
    {
        "name": "Pompiere",
        "url": "https://fonts.googleapis.com/css2?family=Pompiere&display=swap"
    },
    {
        "name": "Pontano Sans",
        "url": "https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap"
    },
    {
        "name": "Poor Story",
        "url": "https://fonts.googleapis.com/css2?family=Poor+Story&display=swap"
    },
    {
        "name": "Poppins",
        "url": "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Port Lligat Sans",
        "url": "https://fonts.googleapis.com/css2?family=Port+Lligat+Sans&display=swap"
    },
    {
        "name": "Port Lligat Slab",
        "url": "https://fonts.googleapis.com/css2?family=Port+Lligat+Slab&display=swap"
    },
    {
        "name": "Potta One",
        "url": "https://fonts.googleapis.com/css2?family=Potta+One&display=swap"
    },
    {
        "name": "Pragati Narrow",
        "url": "https://fonts.googleapis.com/css2?family=Pragati+Narrow:wght@400;700&display=swap"
    },
    {
        "name": "Praise",
        "url": "https://fonts.googleapis.com/css2?family=Praise&display=swap"
    },
    {
        "name": "Prata",
        "url": "https://fonts.googleapis.com/css2?family=Prata&display=swap"
    },
    {
        "name": "Preahvihear",
        "url": "https://fonts.googleapis.com/css2?family=Preahvihear&display=swap"
    },
    {
        "name": "Press Start 2P",
        "url": "https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"
    },
    {
        "name": "Pridi",
        "url": "https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Princess Sofia",
        "url": "https://fonts.googleapis.com/css2?family=Princess+Sofia&display=swap"
    },
    {
        "name": "Prociono",
        "url": "https://fonts.googleapis.com/css2?family=Prociono&display=swap"
    },
    {
        "name": "Prompt",
        "url": "https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Prosto One",
        "url": "https://fonts.googleapis.com/css2?family=Prosto+One&display=swap"
    },
    {
        "name": "Proza Libre",
        "url": "https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Public Sans",
        "url": "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Puppies Play",
        "url": "https://fonts.googleapis.com/css2?family=Puppies+Play&display=swap"
    },
    {
        "name": "Puritan",
        "url": "https://fonts.googleapis.com/css2?family=Puritan:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Purple Purse",
        "url": "https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap"
    },
    {
        "name": "Qahiri",
        "url": "https://fonts.googleapis.com/css2?family=Qahiri&display=swap"
    },
    {
        "name": "Quando",
        "url": "https://fonts.googleapis.com/css2?family=Quando&display=swap"
    },
    {
        "name": "Quantico",
        "url": "https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Quattrocento",
        "url": "https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap"
    },
    {
        "name": "Quattrocento Sans",
        "url": "https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Questrial",
        "url": "https://fonts.googleapis.com/css2?family=Questrial&display=swap"
    },
    {
        "name": "Quicksand",
        "url": "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Quintessential",
        "url": "https://fonts.googleapis.com/css2?family=Quintessential&display=swap"
    },
    {
        "name": "Qwigley",
        "url": "https://fonts.googleapis.com/css2?family=Qwigley&display=swap"
    },
    {
        "name": "Qwitcher Grypen",
        "url": "https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@400;700&display=swap"
    },
    {
        "name": "Racing Sans One",
        "url": "https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap"
    },
    {
        "name": "Radio Canada",
        "url": "https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Radley",
        "url": "https://fonts.googleapis.com/css2?family=Radley:ital@0;1&display=swap"
    },
    {
        "name": "Rajdhani",
        "url": "https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Rakkas",
        "url": "https://fonts.googleapis.com/css2?family=Rakkas&display=swap"
    },
    {
        "name": "Raleway",
        "url": "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Raleway Dots",
        "url": "https://fonts.googleapis.com/css2?family=Raleway+Dots&display=swap"
    },
    {
        "name": "Ramabhadra",
        "url": "https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap"
    },
    {
        "name": "Ramaraja",
        "url": "https://fonts.googleapis.com/css2?family=Ramaraja&display=swap"
    },
    {
        "name": "Rambla",
        "url": "https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Rammetto One",
        "url": "https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap"
    },
    {
        "name": "Rampart One",
        "url": "https://fonts.googleapis.com/css2?family=Rampart+One&display=swap"
    },
    {
        "name": "Ranchers",
        "url": "https://fonts.googleapis.com/css2?family=Ranchers&display=swap"
    },
    {
        "name": "Rancho",
        "url": "https://fonts.googleapis.com/css2?family=Rancho&display=swap"
    },
    {
        "name": "Ranga",
        "url": "https://fonts.googleapis.com/css2?family=Ranga:wght@400;700&display=swap"
    },
    {
        "name": "Rasa",
        "url": "https://fonts.googleapis.com/css2?family=Rasa:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Rationale",
        "url": "https://fonts.googleapis.com/css2?family=Rationale&display=swap"
    },
    {
        "name": "Ravi Prakash",
        "url": "https://fonts.googleapis.com/css2?family=Ravi+Prakash&display=swap"
    },
    {
        "name": "Readex Pro",
        "url": "https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Recursive",
        "url": "https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Red Hat Display",
        "url": "https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Red Hat Mono",
        "url": "https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Red Hat Text",
        "url": "https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Red Rose",
        "url": "https://fonts.googleapis.com/css2?family=Red+Rose:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Redacted",
        "url": "https://fonts.googleapis.com/css2?family=Redacted&display=swap"
    },
    {
        "name": "Redacted Script",
        "url": "https://fonts.googleapis.com/css2?family=Redacted+Script:wght@300;400;700&display=swap"
    },
    {
        "name": "Redressed",
        "url": "https://fonts.googleapis.com/css2?family=Redressed&display=swap"
    },
    {
        "name": "Reem Kufi",
        "url": "https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500;600;700&display=swap"
    },
    {
        "name": "Reenie Beanie",
        "url": "https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap"
    },
    {
        "name": "Reggae One",
        "url": "https://fonts.googleapis.com/css2?family=Reggae+One&display=swap"
    },
    {
        "name": "Revalia",
        "url": "https://fonts.googleapis.com/css2?family=Revalia&display=swap"
    },
    {
        "name": "Rhodium Libre",
        "url": "https://fonts.googleapis.com/css2?family=Rhodium+Libre&display=swap"
    },
    {
        "name": "Ribeye",
        "url": "https://fonts.googleapis.com/css2?family=Ribeye&display=swap"
    },
    {
        "name": "Ribeye Marrow",
        "url": "https://fonts.googleapis.com/css2?family=Ribeye+Marrow&display=swap"
    },
    {
        "name": "Righteous",
        "url": "https://fonts.googleapis.com/css2?family=Righteous&display=swap"
    },
    {
        "name": "Risque",
        "url": "https://fonts.googleapis.com/css2?family=Risque&display=swap"
    },
    {
        "name": "Road Rage",
        "url": "https://fonts.googleapis.com/css2?family=Road+Rage&display=swap"
    },
    {
        "name": "Roboto",
        "url": "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
    },
    {
        "name": "Roboto Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
    },
    {
        "name": "Roboto Flex",
        "url": "https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900;1000&display=swap"
    },
    {
        "name": "Roboto Mono",
        "url": "https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Roboto Serif",
        "url": "https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Roboto Slab",
        "url": "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Rochester",
        "url": "https://fonts.googleapis.com/css2?family=Rochester&display=swap"
    },
    {
        "name": "Rock Salt",
        "url": "https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap"
    },
    {
        "name": "RocknRoll One",
        "url": "https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap"
    },
    {
        "name": "Rokkitt",
        "url": "https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Romanesco",
        "url": "https://fonts.googleapis.com/css2?family=Romanesco&display=swap"
    },
    {
        "name": "Ropa Sans",
        "url": "https://fonts.googleapis.com/css2?family=Ropa+Sans:ital@0;1&display=swap"
    },
    {
        "name": "Rosario",
        "url": "https://fonts.googleapis.com/css2?family=Rosario:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Rosarivo",
        "url": "https://fonts.googleapis.com/css2?family=Rosarivo:ital@0;1&display=swap"
    },
    {
        "name": "Rouge Script",
        "url": "https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap"
    },
    {
        "name": "Rowdies",
        "url": "https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap"
    },
    {
        "name": "Rozha One",
        "url": "https://fonts.googleapis.com/css2?family=Rozha+One&display=swap"
    },
    {
        "name": "Rubik",
        "url": "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Rubik Beastly",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Beastly&display=swap"
    },
    {
        "name": "Rubik Bubbles",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap"
    },
    {
        "name": "Rubik Glitch",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Glitch&display=swap"
    },
    {
        "name": "Rubik Microbe",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Microbe&display=swap"
    },
    {
        "name": "Rubik Mono One",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap"
    },
    {
        "name": "Rubik Moonrocks",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap"
    },
    {
        "name": "Rubik Puddles",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Puddles&display=swap"
    },
    {
        "name": "Rubik Wet Paint",
        "url": "https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap"
    },
    {
        "name": "Ruda",
        "url": "https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Rufina",
        "url": "https://fonts.googleapis.com/css2?family=Rufina:wght@400;700&display=swap"
    },
    {
        "name": "Ruge Boogie",
        "url": "https://fonts.googleapis.com/css2?family=Ruge+Boogie&display=swap"
    },
    {
        "name": "Ruluko",
        "url": "https://fonts.googleapis.com/css2?family=Ruluko&display=swap"
    },
    {
        "name": "Rum Raisin",
        "url": "https://fonts.googleapis.com/css2?family=Rum+Raisin&display=swap"
    },
    {
        "name": "Ruslan Display",
        "url": "https://fonts.googleapis.com/css2?family=Ruslan+Display&display=swap"
    },
    {
        "name": "Russo One",
        "url": "https://fonts.googleapis.com/css2?family=Russo+One&display=swap"
    },
    {
        "name": "Ruthie",
        "url": "https://fonts.googleapis.com/css2?family=Ruthie&display=swap"
    },
    {
        "name": "Rye",
        "url": "https://fonts.googleapis.com/css2?family=Rye&display=swap"
    },
    {
        "name": "STIX Two Text",
        "url": "https://fonts.googleapis.com/css2?family=STIX+Two+Text:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Sacramento",
        "url": "https://fonts.googleapis.com/css2?family=Sacramento&display=swap"
    },
    {
        "name": "Sahitya",
        "url": "https://fonts.googleapis.com/css2?family=Sahitya:wght@400;700&display=swap"
    },
    {
        "name": "Sail",
        "url": "https://fonts.googleapis.com/css2?family=Sail&display=swap"
    },
    {
        "name": "Saira",
        "url": "https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Saira Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Saira Extra Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Saira Semi Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Saira Stencil One",
        "url": "https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap"
    },
    {
        "name": "Salsa",
        "url": "https://fonts.googleapis.com/css2?family=Salsa&display=swap"
    },
    {
        "name": "Sanchez",
        "url": "https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap"
    },
    {
        "name": "Sancreek",
        "url": "https://fonts.googleapis.com/css2?family=Sancreek&display=swap"
    },
    {
        "name": "Sansita",
        "url": "https://fonts.googleapis.com/css2?family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Sansita Swashed",
        "url": "https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Sarabun",
        "url": "https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Sarala",
        "url": "https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap"
    },
    {
        "name": "Sarina",
        "url": "https://fonts.googleapis.com/css2?family=Sarina&display=swap"
    },
    {
        "name": "Sarpanch",
        "url": "https://fonts.googleapis.com/css2?family=Sarpanch:wght@400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Sassy Frass",
        "url": "https://fonts.googleapis.com/css2?family=Sassy+Frass&display=swap"
    },
    {
        "name": "Satisfy",
        "url": "https://fonts.googleapis.com/css2?family=Satisfy&display=swap"
    },
    {
        "name": "Sawarabi Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Sawarabi+Gothic&display=swap"
    },
    {
        "name": "Sawarabi Mincho",
        "url": "https://fonts.googleapis.com/css2?family=Sawarabi+Mincho&display=swap"
    },
    {
        "name": "Scada",
        "url": "https://fonts.googleapis.com/css2?family=Scada:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Scheherazade New",
        "url": "https://fonts.googleapis.com/css2?family=Scheherazade+New:wght@400;700&display=swap"
    },
    {
        "name": "Schoolbell",
        "url": "https://fonts.googleapis.com/css2?family=Schoolbell&display=swap"
    },
    {
        "name": "Scope One",
        "url": "https://fonts.googleapis.com/css2?family=Scope+One&display=swap"
    },
    {
        "name": "Seaweed Script",
        "url": "https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap"
    },
    {
        "name": "Secular One",
        "url": "https://fonts.googleapis.com/css2?family=Secular+One&display=swap"
    },
    {
        "name": "Sedgwick Ave",
        "url": "https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap"
    },
    {
        "name": "Sedgwick Ave Display",
        "url": "https://fonts.googleapis.com/css2?family=Sedgwick+Ave+Display&display=swap"
    },
    {
        "name": "Sen",
        "url": "https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap"
    },
    {
        "name": "Send Flowers",
        "url": "https://fonts.googleapis.com/css2?family=Send+Flowers&display=swap"
    },
    {
        "name": "Sevillana",
        "url": "https://fonts.googleapis.com/css2?family=Sevillana&display=swap"
    },
    {
        "name": "Seymour One",
        "url": "https://fonts.googleapis.com/css2?family=Seymour+One&display=swap"
    },
    {
        "name": "Shadows Into Light",
        "url": "https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap"
    },
    {
        "name": "Shadows Into Light Two",
        "url": "https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap"
    },
    {
        "name": "Shalimar",
        "url": "https://fonts.googleapis.com/css2?family=Shalimar&display=swap"
    },
    {
        "name": "Shanti",
        "url": "https://fonts.googleapis.com/css2?family=Shanti&display=swap"
    },
    {
        "name": "Share",
        "url": "https://fonts.googleapis.com/css2?family=Share:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Share Tech",
        "url": "https://fonts.googleapis.com/css2?family=Share+Tech&display=swap"
    },
    {
        "name": "Share Tech Mono",
        "url": "https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap"
    },
    {
        "name": "Shippori Antique",
        "url": "https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap"
    },
    {
        "name": "Shippori Antique B1",
        "url": "https://fonts.googleapis.com/css2?family=Shippori+Antique+B1&display=swap"
    },
    {
        "name": "Shippori Mincho",
        "url": "https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Shippori Mincho B1",
        "url": "https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Shojumaru",
        "url": "https://fonts.googleapis.com/css2?family=Shojumaru&display=swap"
    },
    {
        "name": "Short Stack",
        "url": "https://fonts.googleapis.com/css2?family=Short+Stack&display=swap"
    },
    {
        "name": "Shrikhand",
        "url": "https://fonts.googleapis.com/css2?family=Shrikhand&display=swap"
    },
    {
        "name": "Siemreap",
        "url": "https://fonts.googleapis.com/css2?family=Siemreap&display=swap"
    },
    {
        "name": "Sigmar One",
        "url": "https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap"
    },
    {
        "name": "Signika",
        "url": "https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Signika Negative",
        "url": "https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Simonetta",
        "url": "https://fonts.googleapis.com/css2?family=Simonetta:ital,wght@0,400;0,900;1,400;1,900&display=swap"
    },
    {
        "name": "Single Day",
        "url": "https://fonts.googleapis.com/css2?family=Single+Day&display=swap"
    },
    {
        "name": "Sintony",
        "url": "https://fonts.googleapis.com/css2?family=Sintony:wght@400;700&display=swap"
    },
    {
        "name": "Sirin Stencil",
        "url": "https://fonts.googleapis.com/css2?family=Sirin+Stencil&display=swap"
    },
    {
        "name": "Six Caps",
        "url": "https://fonts.googleapis.com/css2?family=Six+Caps&display=swap"
    },
    {
        "name": "Skranji",
        "url": "https://fonts.googleapis.com/css2?family=Skranji:wght@400;700&display=swap"
    },
    {
        "name": "Slabo 13px",
        "url": "https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap"
    },
    {
        "name": "Slabo 27px",
        "url": "https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap"
    },
    {
        "name": "Slackey",
        "url": "https://fonts.googleapis.com/css2?family=Slackey&display=swap"
    },
    {
        "name": "Smokum",
        "url": "https://fonts.googleapis.com/css2?family=Smokum&display=swap"
    },
    {
        "name": "Smooch",
        "url": "https://fonts.googleapis.com/css2?family=Smooch&display=swap"
    },
    {
        "name": "Smooch Sans",
        "url": "https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Smythe",
        "url": "https://fonts.googleapis.com/css2?family=Smythe&display=swap"
    },
    {
        "name": "Sniglet",
        "url": "https://fonts.googleapis.com/css2?family=Sniglet:wght@400;800&display=swap"
    },
    {
        "name": "Snippet",
        "url": "https://fonts.googleapis.com/css2?family=Snippet&display=swap"
    },
    {
        "name": "Snowburst One",
        "url": "https://fonts.googleapis.com/css2?family=Snowburst+One&display=swap"
    },
    {
        "name": "Sofadi One",
        "url": "https://fonts.googleapis.com/css2?family=Sofadi+One&display=swap"
    },
    {
        "name": "Sofia",
        "url": "https://fonts.googleapis.com/css2?family=Sofia&display=swap"
    },
    {
        "name": "Solway",
        "url": "https://fonts.googleapis.com/css2?family=Solway:wght@300;400;500;700;800&display=swap"
    },
    {
        "name": "Song Myung",
        "url": "https://fonts.googleapis.com/css2?family=Song+Myung&display=swap"
    },
    {
        "name": "Sonsie One",
        "url": "https://fonts.googleapis.com/css2?family=Sonsie+One&display=swap"
    },
    {
        "name": "Sora",
        "url": "https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Sorts Mill Goudy",
        "url": "https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap"
    },
    {
        "name": "Source Code Pro",
        "url": "https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Source Sans 3",
        "url": "https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Source Sans Pro",
        "url": "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
    },
    {
        "name": "Source Serif 4",
        "url": "https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Source Serif Pro",
        "url": "https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
    },
    {
        "name": "Space Grotesk",
        "url": "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Space Mono",
        "url": "https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Special Elite",
        "url": "https://fonts.googleapis.com/css2?family=Special+Elite&display=swap"
    },
    {
        "name": "Spectral",
        "url": "https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Spectral SC",
        "url": "https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    },
    {
        "name": "Spicy Rice",
        "url": "https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap"
    },
    {
        "name": "Spinnaker",
        "url": "https://fonts.googleapis.com/css2?family=Spinnaker&display=swap"
    },
    {
        "name": "Spirax",
        "url": "https://fonts.googleapis.com/css2?family=Spirax&display=swap"
    },
    {
        "name": "Splash",
        "url": "https://fonts.googleapis.com/css2?family=Splash&display=swap"
    },
    {
        "name": "Spline Sans",
        "url": "https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Spline Sans Mono",
        "url": "https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Squada One",
        "url": "https://fonts.googleapis.com/css2?family=Squada+One&display=swap"
    },
    {
        "name": "Square Peg",
        "url": "https://fonts.googleapis.com/css2?family=Square+Peg&display=swap"
    },
    {
        "name": "Sree Krushnadevaraya",
        "url": "https://fonts.googleapis.com/css2?family=Sree+Krushnadevaraya&display=swap"
    },
    {
        "name": "Sriracha",
        "url": "https://fonts.googleapis.com/css2?family=Sriracha&display=swap"
    },
    {
        "name": "Srisakdi",
        "url": "https://fonts.googleapis.com/css2?family=Srisakdi:wght@400;700&display=swap"
    },
    {
        "name": "Staatliches",
        "url": "https://fonts.googleapis.com/css2?family=Staatliches&display=swap"
    },
    {
        "name": "Stalemate",
        "url": "https://fonts.googleapis.com/css2?family=Stalemate&display=swap"
    },
    {
        "name": "Stalinist One",
        "url": "https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap"
    },
    {
        "name": "Stardos Stencil",
        "url": "https://fonts.googleapis.com/css2?family=Stardos+Stencil:wght@400;700&display=swap"
    },
    {
        "name": "Stick",
        "url": "https://fonts.googleapis.com/css2?family=Stick&display=swap"
    },
    {
        "name": "Stick No Bills",
        "url": "https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Stint Ultra Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Stint+Ultra+Condensed&display=swap"
    },
    {
        "name": "Stint Ultra Expanded",
        "url": "https://fonts.googleapis.com/css2?family=Stint+Ultra+Expanded&display=swap"
    },
    {
        "name": "Stoke",
        "url": "https://fonts.googleapis.com/css2?family=Stoke:wght@300;400&display=swap"
    },
    {
        "name": "Strait",
        "url": "https://fonts.googleapis.com/css2?family=Strait&display=swap"
    },
    {
        "name": "Style Script",
        "url": "https://fonts.googleapis.com/css2?family=Style+Script&display=swap"
    },
    {
        "name": "Stylish",
        "url": "https://fonts.googleapis.com/css2?family=Stylish&display=swap"
    },
    {
        "name": "Sue Ellen Francisco",
        "url": "https://fonts.googleapis.com/css2?family=Sue+Ellen+Francisco&display=swap"
    },
    {
        "name": "Suez One",
        "url": "https://fonts.googleapis.com/css2?family=Suez+One&display=swap"
    },
    {
        "name": "Sulphur Point",
        "url": "https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap"
    },
    {
        "name": "Sumana",
        "url": "https://fonts.googleapis.com/css2?family=Sumana:wght@400;700&display=swap"
    },
    {
        "name": "Sunflower",
        "url": "https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500;700&display=swap"
    },
    {
        "name": "Sunshiney",
        "url": "https://fonts.googleapis.com/css2?family=Sunshiney&display=swap"
    },
    {
        "name": "Supermercado One",
        "url": "https://fonts.googleapis.com/css2?family=Supermercado+One&display=swap"
    },
    {
        "name": "Sura",
        "url": "https://fonts.googleapis.com/css2?family=Sura:wght@400;700&display=swap"
    },
    {
        "name": "Suranna",
        "url": "https://fonts.googleapis.com/css2?family=Suranna&display=swap"
    },
    {
        "name": "Suravaram",
        "url": "https://fonts.googleapis.com/css2?family=Suravaram&display=swap"
    },
    {
        "name": "Suwannaphum",
        "url": "https://fonts.googleapis.com/css2?family=Suwannaphum:wght@100;300;400;700;900&display=swap"
    },
    {
        "name": "Swanky and Moo Moo",
        "url": "https://fonts.googleapis.com/css2?family=Swanky+and+Moo+Moo&display=swap"
    },
    {
        "name": "Syncopate",
        "url": "https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap"
    },
    {
        "name": "Syne",
        "url": "https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Syne Mono",
        "url": "https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap"
    },
    {
        "name": "Syne Tactile",
        "url": "https://fonts.googleapis.com/css2?family=Syne+Tactile&display=swap"
    },
    {
        "name": "Tai Heritage Pro",
        "url": "https://fonts.googleapis.com/css2?family=Tai+Heritage+Pro:wght@400;700&display=swap"
    },
    {
        "name": "Tajawal",
        "url": "https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap"
    },
    {
        "name": "Tangerine",
        "url": "https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap"
    },
    {
        "name": "Tapestry",
        "url": "https://fonts.googleapis.com/css2?family=Tapestry&display=swap"
    },
    {
        "name": "Taprom",
        "url": "https://fonts.googleapis.com/css2?family=Taprom&display=swap"
    },
    {
        "name": "Tauri",
        "url": "https://fonts.googleapis.com/css2?family=Tauri&display=swap"
    },
    {
        "name": "Taviraj",
        "url": "https://fonts.googleapis.com/css2?family=Taviraj:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Teko",
        "url": "https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Telex",
        "url": "https://fonts.googleapis.com/css2?family=Telex&display=swap"
    },
    {
        "name": "Tenali Ramakrishna",
        "url": "https://fonts.googleapis.com/css2?family=Tenali+Ramakrishna&display=swap"
    },
    {
        "name": "Tenor Sans",
        "url": "https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap"
    },
    {
        "name": "Text Me One",
        "url": "https://fonts.googleapis.com/css2?family=Text+Me+One&display=swap"
    },
    {
        "name": "Texturina",
        "url": "https://fonts.googleapis.com/css2?family=Texturina:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Thasadith",
        "url": "https://fonts.googleapis.com/css2?family=Thasadith:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "The Girl Next Door",
        "url": "https://fonts.googleapis.com/css2?family=The+Girl+Next+Door&display=swap"
    },
    {
        "name": "The Nautigal",
        "url": "https://fonts.googleapis.com/css2?family=The+Nautigal:wght@400;700&display=swap"
    },
    {
        "name": "Tienne",
        "url": "https://fonts.googleapis.com/css2?family=Tienne:wght@400;700;900&display=swap"
    },
    {
        "name": "Tillana",
        "url": "https://fonts.googleapis.com/css2?family=Tillana:wght@400;500;600;700;800&display=swap"
    },
    {
        "name": "Timmana",
        "url": "https://fonts.googleapis.com/css2?family=Timmana&display=swap"
    },
    {
        "name": "Tinos",
        "url": "https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Tiro Bangla",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Devanagari Hindi",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Devanagari Marathi",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Marathi:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Devanagari Sanskrit",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Sanskrit:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Gurmukhi",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Gurmukhi:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Kannada",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Kannada:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Tamil",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Tamil:ital@0;1&display=swap"
    },
    {
        "name": "Tiro Telugu",
        "url": "https://fonts.googleapis.com/css2?family=Tiro+Telugu:ital@0;1&display=swap"
    },
    {
        "name": "Titan One",
        "url": "https://fonts.googleapis.com/css2?family=Titan+One&display=swap"
    },
    {
        "name": "Titillium Web",
        "url": "https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
    },
    {
        "name": "Tomorrow",
        "url": "https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Tourney",
        "url": "https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Trade Winds",
        "url": "https://fonts.googleapis.com/css2?family=Trade+Winds&display=swap"
    },
    {
        "name": "Train One",
        "url": "https://fonts.googleapis.com/css2?family=Train+One&display=swap"
    },
    {
        "name": "Trirong",
        "url": "https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Trispace",
        "url": "https://fonts.googleapis.com/css2?family=Trispace:wght@100;200;300;400;500;600;700;800&display=swap"
    },
    {
        "name": "Trocchi",
        "url": "https://fonts.googleapis.com/css2?family=Trocchi&display=swap"
    },
    {
        "name": "Trochut",
        "url": "https://fonts.googleapis.com/css2?family=Trochut:ital,wght@0,400;0,700;1,400&display=swap"
    },
    {
        "name": "Truculenta",
        "url": "https://fonts.googleapis.com/css2?family=Truculenta:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Trykker",
        "url": "https://fonts.googleapis.com/css2?family=Trykker&display=swap"
    },
    {
        "name": "Tulpen One",
        "url": "https://fonts.googleapis.com/css2?family=Tulpen+One&display=swap"
    },
    {
        "name": "Turret Road",
        "url": "https://fonts.googleapis.com/css2?family=Turret+Road:wght@200;300;400;500;700;800&display=swap"
    },
    {
        "name": "Twinkle Star",
        "url": "https://fonts.googleapis.com/css2?family=Twinkle+Star&display=swap"
    },
    {
        "name": "Ubuntu",
        "url": "https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
    },
    {
        "name": "Ubuntu Condensed",
        "url": "https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap"
    },
    {
        "name": "Ubuntu Mono",
        "url": "https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Uchen",
        "url": "https://fonts.googleapis.com/css2?family=Uchen&display=swap"
    },
    {
        "name": "Ultra",
        "url": "https://fonts.googleapis.com/css2?family=Ultra&display=swap"
    },
    {
        "name": "Uncial Antiqua",
        "url": "https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap"
    },
    {
        "name": "Underdog",
        "url": "https://fonts.googleapis.com/css2?family=Underdog&display=swap"
    },
    {
        "name": "Unica One",
        "url": "https://fonts.googleapis.com/css2?family=Unica+One&display=swap"
    },
    {
        "name": "UnifrakturMaguntia",
        "url": "https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap"
    },
    {
        "name": "Unkempt",
        "url": "https://fonts.googleapis.com/css2?family=Unkempt:wght@400;700&display=swap"
    },
    {
        "name": "Unlock",
        "url": "https://fonts.googleapis.com/css2?family=Unlock&display=swap"
    },
    {
        "name": "Unna",
        "url": "https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Updock",
        "url": "https://fonts.googleapis.com/css2?family=Updock&display=swap"
    },
    {
        "name": "Urbanist",
        "url": "https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "VT323",
        "url": "https://fonts.googleapis.com/css2?family=VT323&display=swap"
    },
    {
        "name": "Vampiro One",
        "url": "https://fonts.googleapis.com/css2?family=Vampiro+One&display=swap"
    },
    {
        "name": "Varela",
        "url": "https://fonts.googleapis.com/css2?family=Varela&display=swap"
    },
    {
        "name": "Varela Round",
        "url": "https://fonts.googleapis.com/css2?family=Varela+Round&display=swap"
    },
    {
        "name": "Varta",
        "url": "https://fonts.googleapis.com/css2?family=Varta:wght@300;400;500;600;700&display=swap"
    },
    {
        "name": "Vast Shadow",
        "url": "https://fonts.googleapis.com/css2?family=Vast+Shadow&display=swap"
    },
    {
        "name": "Vazirmatn",
        "url": "https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        "name": "Vesper Libre",
        "url": "https://fonts.googleapis.com/css2?family=Vesper+Libre:wght@400;500;700;900&display=swap"
    },
    {
        "name": "Viaoda Libre",
        "url": "https://fonts.googleapis.com/css2?family=Viaoda+Libre&display=swap"
    },
    {
        "name": "Vibes",
        "url": "https://fonts.googleapis.com/css2?family=Vibes&display=swap"
    },
    {
        "name": "Vibur",
        "url": "https://fonts.googleapis.com/css2?family=Vibur&display=swap"
    },
    {
        "name": "Vidaloka",
        "url": "https://fonts.googleapis.com/css2?family=Vidaloka&display=swap"
    },
    {
        "name": "Viga",
        "url": "https://fonts.googleapis.com/css2?family=Viga&display=swap"
    },
    {
        "name": "Voces",
        "url": "https://fonts.googleapis.com/css2?family=Voces&display=swap"
    },
    {
        "name": "Volkhov",
        "url": "https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap"
    },
    {
        "name": "Vollkorn",
        "url": "https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Vollkorn SC",
        "url": "https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap"
    },
    {
        "name": "Voltaire",
        "url": "https://fonts.googleapis.com/css2?family=Voltaire&display=swap"
    },
    {
        "name": "Vujahday Script",
        "url": "https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap"
    },
    {
        "name": "Waiting for the Sunrise",
        "url": "https://fonts.googleapis.com/css2?family=Waiting+for+the+Sunrise&display=swap"
    },
    {
        "name": "Wallpoet",
        "url": "https://fonts.googleapis.com/css2?family=Wallpoet&display=swap"
    },
    {
        "name": "Walter Turncoat",
        "url": "https://fonts.googleapis.com/css2?family=Walter+Turncoat&display=swap"
    },
    {
        "name": "Warnes",
        "url": "https://fonts.googleapis.com/css2?family=Warnes&display=swap"
    },
    {
        "name": "Water Brush",
        "url": "https://fonts.googleapis.com/css2?family=Water+Brush&display=swap"
    },
    {
        "name": "Waterfall",
        "url": "https://fonts.googleapis.com/css2?family=Waterfall&display=swap"
    },
    {
        "name": "Wellfleet",
        "url": "https://fonts.googleapis.com/css2?family=Wellfleet&display=swap"
    },
    {
        "name": "Wendy One",
        "url": "https://fonts.googleapis.com/css2?family=Wendy+One&display=swap"
    },
    {
        "name": "Whisper",
        "url": "https://fonts.googleapis.com/css2?family=Whisper&display=swap"
    },
    {
        "name": "WindSong",
        "url": "https://fonts.googleapis.com/css2?family=WindSong:wght@400;500&display=swap"
    },
    {
        "name": "Wire One",
        "url": "https://fonts.googleapis.com/css2?family=Wire+One&display=swap"
    },
    {
        "name": "Work Sans",
        "url": "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    },
    {
        "name": "Xanh Mono",
        "url": "https://fonts.googleapis.com/css2?family=Xanh+Mono:ital@0;1&display=swap"
    },
    {
        "name": "Yaldevi",
        "url": "https://fonts.googleapis.com/css2?family=Yaldevi:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Yanone Kaffeesatz",
        "url": "https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap"
    },
    {
        "name": "Yantramanav",
        "url": "https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap"
    },
    {
        "name": "Yatra One",
        "url": "https://fonts.googleapis.com/css2?family=Yatra+One&display=swap"
    },
    {
        "name": "Yellowtail",
        "url": "https://fonts.googleapis.com/css2?family=Yellowtail&display=swap"
    },
    {
        "name": "Yeon Sung",
        "url": "https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap"
    },
    {
        "name": "Yeseva One",
        "url": "https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap"
    },
    {
        "name": "Yesteryear",
        "url": "https://fonts.googleapis.com/css2?family=Yesteryear&display=swap"
    },
    {
        "name": "Yomogi",
        "url": "https://fonts.googleapis.com/css2?family=Yomogi&display=swap"
    },
    {
        "name": "Yrsa",
        "url": "https://fonts.googleapis.com/css2?family=Yrsa:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Yuji Boku",
        "url": "https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap"
    },
    {
        "name": "Yuji Mai",
        "url": "https://fonts.googleapis.com/css2?family=Yuji+Mai&display=swap"
    },
    {
        "name": "Yuji Syuku",
        "url": "https://fonts.googleapis.com/css2?family=Yuji+Syuku&display=swap"
    },
    {
        "name": "Yusei Magic",
        "url": "https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap"
    },
    {
        "name": "ZCOOL KuaiLe",
        "url": "https://fonts.googleapis.com/css2?family=ZCOOL+KuaiLe&display=swap"
    },
    {
        "name": "ZCOOL QingKe HuangYou",
        "url": "https://fonts.googleapis.com/css2?family=ZCOOL+QingKe+HuangYou&display=swap"
    },
    {
        "name": "ZCOOL XiaoWei",
        "url": "https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap"
    },
    {
        "name": "Zen Antique",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Antique&display=swap"
    },
    {
        "name": "Zen Antique Soft",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Antique+Soft&display=swap"
    },
    {
        "name": "Zen Dots",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap"
    },
    {
        "name": "Zen Kaku Gothic Antique",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@300;400;500;700;900&display=swap"
    },
    {
        "name": "Zen Kaku Gothic New",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap"
    },
    {
        "name": "Zen Kurenaido",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Kurenaido&display=swap"
    },
    {
        "name": "Zen Loop",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Loop:ital@0;1&display=swap"
    },
    {
        "name": "Zen Maru Gothic",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap"
    },
    {
        "name": "Zen Old Mincho",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;700;900&display=swap"
    },
    {
        "name": "Zen Tokyo Zoo",
        "url": "https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap"
    },
    {
        "name": "Zeyada",
        "url": "https://fonts.googleapis.com/css2?family=Zeyada&display=swap"
    },
    {
        "name": "Zhi Mang Xing",
        "url": "https://fonts.googleapis.com/css2?family=Zhi+Mang+Xing&display=swap"
    },
    {
        "name": "Zilla Slab",
        "url": "https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
    },
    {
        "name": "Zilla Slab Highlight",
        "url": "https://fonts.googleapis.com/css2?family=Zilla+Slab+Highlight:wght@400;700&display=swap"
    }
]

const FONT_URL_BY_NAME = Object.fromEntries(FONTS.map(({name, url}) => [name, url]))

const FONT_SIZES = [
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
    '26px',
    '28px',
    '30px',
    '32px',
    '34px',
    '36px',
    '38px',
    '40px',
    '42px',
    '44px',
    '46px',
    '48px',
    '50px',
    '52px',
    '54px',
    '56px',
    '58px',
    '60px',
    '62px',
    '64px',
    '66px',
    '68px',
    '70px',
    '72px',
    '74px',
    '76px',
    '78px',
    '80px',
    '82px',
    '84px',
    '86px',
    '88px',
    '90px',
    '92px',
    '94px',
    '96px',
    '98px',
    '100px',
    '102px',
    '104px',
    '106px',
    '108px',
    '110px',
    '112px',
    '114px',
    '116px',
    '118px',
    '120px',
    '122px',
    '124px',
    '126px',
    '128px',
    '130px',
    '132px',
    '134px',
    '136px',
    '140px',
    '144px',
    '146px',
    '148px',
    '150px',
    '156px',
    '158px',
    '160px',
    '168px',
    '170px',
    '172px',
    '174px',
    '176px',
    '178px',
    '180px',
];

const TEXT_ALIGN = [
    {
        text: 'Left',
        value: 'JustifyLeft',
        icon: '$text_align_left',
    },
    {
        text: 'Center',
        value: 'JustifyCenter',
        icon: '$text_align_center',
    },
    {
        text: 'Right',
        value: 'JustifyRight',
        icon: '$text_align_right',
    }
];

export default {
    FONTS,
    FONT_URL_BY_NAME,
    FONT_SIZES,
    TEXT_ALIGN
}