var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-warning-container"
  }, [_c('AppBarEmpty'), _c('div', {
    staticClass: "mobile-warning"
  }, [_vm._v(" The Mobile version of Formsly is not ready yet. Please sign in from from your computer or tablet to access the app. ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }