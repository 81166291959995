var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.submissions.length,
      "submissions-count": _vm.totalResponse
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "slider-field-view-summary"
  }, [_c('span', {
    staticClass: "slider-field-view-summary__label"
  }, [_vm._v("Average Answer:")]), _c('span', {
    staticClass: "slider-field-view-summary__average-value"
  }, [_vm._v(_vm._s(_vm.averageAnswer))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }