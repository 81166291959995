<template>
    <div
            :class="[
                'style-preferences-template-preview',
                selected && 'style-preferences-template-preview--selected'
            ]"
    >
        <div class="style-preferences-template-preview__wrapper">
            <div
                    class="style-preferences-template-preview__card"
                    @click="!selected && selectTheme()"
            >
                <v-img
                        class="style-preferences-template-preview__image"
                        :src="previewImage"
                >
                    <div class="style-preferences-template-preview__image-content-wrapper">
                        <div class="style-preferences-template-preview-image-content">
                            <div class="style-preferences-template-preview-image-content__question" :style="questionColorPreviewStyle">
                                Question
                            </div>
                            <div class="style-preferences-template-preview-image-content__answer" :style="answerColorPreviewStyle">
                                Answer
                            </div>
                            <div class="style-preferences-template-preview-image-content__button" :style="buttonColorPreviewStyle"></div>
                        </div>
                    </div>
                </v-img>
                <div class="style-preferences-template-preview__footer">
                    <div class="style-preferences-template-preview__name">
                        {{ name || 'Warm green' }}
                    </div>
                    <div class="style-preferences-template-preview__action">
                        <slot name="action"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "StylePreferencesThemePreview",
        props: {
            themeObj: {
                type: Object,
                required: true
            },

            actionButtonText: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('edit/FSP_themes/selected', [
                'getSelectedThemeId'
            ]),
            name() {
                return this.themeObj.name
            },
            previewImage() {
                return this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.BACKGROUND_IMAGE.JSON_NAME]
            },
            selected() {
                return this.themeObj.id === this.getSelectedThemeId
            },

            questionColorPreviewStyle() {
                return {
                    color: this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.QUESTION_COLOR.JSON_NAME],
                    'font-family': this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]
                }
            },
            answerColorPreviewStyle() {
                return {
                    color: this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.ANSWER_COLOR.JSON_NAME],
                    'font-family': this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]
                }
            },
            buttonColorPreviewStyle() {
                return {backgroundColor: this.themeObj.rawData[this.constants.FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.JSON_NAME]}
            },
        },
        methods: {
            selectTheme() {
                this.$store.dispatch('edit/FSP_themes/selected/selectTheme', this.themeObj)
            }
        }
    }
</script>

<style lang="scss">
    .style-preferences-template-preview {
        padding: 2px;

        &:not(&:last-of-type) {
            margin-bottom: 16px;
        }

        &__wrapper {
            padding: 3px;
            transition: box-shadow .3s ease;

            border-radius: 11px;
        }

        &--selected {
            .style-preferences-template-preview__wrapper {
                box-shadow: 0 0 0 2px var(--v-blue_400-base);
            }
        }

        &__card {
            cursor: pointer;

            height: 110px;
            border-radius: 8px;
            overflow: hidden;

            display: flex;
            flex-direction: column;
        }

        &__image {
            flex-shrink: 1 !important;
        }

        &__image-content-wrapper {
            width: 60%;
            height: 100%;

            .style-preferences-template-preview-image-content {
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 30px;
                justify-content: center;

                &__question {
                    font-size: 16px;
                    font-weight: 600;
                }

                &__answer {
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 500;
                    margin-bottom: 6px;
                    margin-top: 2px;
                }

                &__button {
                    height: 12px;
                    width: 33px;
                    border-radius: 4px;
                }
            }
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--v-blue_100-base);

            min-height: 31px;
            padding-left: 22px;
            padding-right: 11px;
        }

        &__name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            font-weight: 400;
            font-size: .75rem;
            color: var(--v-bluish_gray_500-base)
        }

        &__action {
            & > .v-btn {
                padding: 5px !important;
                height: auto !important;

                span {
                    font-weight: 600 !important;
                    font-size: .75rem !important;
                }
            }
        }
    }
</style>