var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full d-flex align-center justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    attrs: {
      "id": "g_id_onload",
      "data-client_id": "1084066207419-0gnh5okq4iuoatdp3maqe9tsu1sqjsac.apps.googleusercontent.com"
    }
  }), _c('LoginLayoutWithImage', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Log in")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_vm._v("Welcome back! Please enter your details")];
      },
      proxy: true
    }, {
      key: "form",
      fn: function () {
        return [_c('v-form', {
          ref: "form",
          attrs: {
            "lazy-validation": ""
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('ErrorAlertComponent'), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Email")]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Enter your email",
            "rules": _vm.emailRules,
            "outlined": "",
            "required": ""
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.form.resetValidation();
            }
          },
          model: {
            value: _vm.signInEmail,
            callback: function ($$v) {
              _vm.signInEmail = $$v;
            },
            expression: "signInEmail"
          }
        }), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Password")]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Enter your password",
            "rules": _vm.passwordRules,
            "outlined": "",
            "type": "password",
            "required": ""
          },
          model: {
            value: _vm.signInPassword,
            callback: function ($$v) {
              _vm.signInPassword = $$v;
            },
            expression: "signInPassword"
          }
        }), _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', [_c('v-checkbox', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "subtitle-2"
              }, [_vm._v("Remember me")])];
            },
            proxy: true
          }])
        })], 1), _c('v-col', {
          staticClass: "text-end"
        }, [_c('span', {
          staticClass: "subtitle-2 cursor-pointer text--secondary",
          on: {
            "click": _vm.goToForgotPassword
          }
        }, [_vm._v(" Forgot Password ")])])], 1), _c('v-row', [_c('v-col', {
          staticClass: "mb-n3"
        }, [_c('v-btn', {
          attrs: {
            "color": "blue_400",
            "large": "",
            "rounded": "",
            "block": ""
          },
          on: {
            "click": _vm.signIn
          }
        }, [_vm._v(" Sign in ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
          staticClass: "dark_gray--text",
          attrs: {
            "outlined": "",
            "large": "",
            "rounded": "",
            "block": ""
          },
          on: {
            "click": _vm.signInGoogle
          }
        }, [_c('img', {
          staticClass: "mr-4",
          attrs: {
            "draggable": "false",
            "src": "/images/GoogleLogo.svg"
          }
        }), _c('span', [_vm._v("Sign in with Google")])])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "jump-to",
      fn: function () {
        return [_vm._v(" Don't have an account? "), _c('span', {
          staticClass: "ml-2 blue_400--text font-weight-bold cursor-pointer",
          on: {
            "click": _vm.goToSignUp
          }
        }, [_vm._v(" Sign Up ")])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }