var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('AppBarLogoComponent')];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "checkout-navigation-btn",
          attrs: {
            "active-class": "checkout-navigation-btn--active",
            "exact": "",
            "text": "",
            "disabled": _vm.getPurchaseCompleted,
            "to": {
              name: _vm.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN
            }
          }
        }, [_vm._v(" 1. Choose a plan ")]), _c('v-btn', {
          staticClass: "checkout-navigation-btn",
          attrs: {
            "active-class": "checkout-navigation-btn--active",
            "disabled": !_vm.getIsCustomerDetailsPageUnlocked || _vm.getPurchaseCompleted,
            "exact": "",
            "text": "",
            "to": {
              name: _vm.constants.PAGE_NAME_CHECKOUT_CUSTOMER_DETAILS
            }
          }
        }, [_vm._v(" 2. Payment ")]), _c('v-btn', {
          staticClass: "checkout-navigation-btn",
          attrs: {
            "active-class": "checkout-navigation-btn--active",
            "disabled": !_vm.getPurchaseCompleted,
            "exact": "",
            "text": "",
            "to": {
              name: _vm.constants.PAGE_NAME_CHECKOUT_PURCHASE_COMPLETED
            }
          }
        }, [_vm._v(" 3. Confirmation ")])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('XBtnToDashboard')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }