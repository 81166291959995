<template>
    <v-menu
            content-class="page-actions-menu"
            :value="menuModel"
            @input="toggleMenu"
            :close-on-content-click="false"
            rounded="lg"
            nudge-top="17"
            nudge-right="17"
            offset-x
            transition="slide-y-transition"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                    class="page-actions-menu__activator-btn"
                    v-show="hover || menuModel"
                    v-on="on"
                    icon
            >
                <svg width="2" height="12" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1" cy="1" r="1" fill="currentColor"/>
                    <circle cx="1" cy="6" r="1" fill="currentColor"/>
                    <circle cx="1" cy="11" r="1" fill="currentColor"/>
                </svg>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item @click="renamePage">
                <v-list-item-icon>
                    <Edit3Icon stroke-width="1.5" size="22"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Rename
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="gray_100"/>
            <v-list-item @click="removePage" class="red_400--text">
                <v-list-item-icon>
                    <Trash2Icon stroke-width="1.5" size="22"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Remove
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import {Edit3Icon, Trash2Icon} from 'vue-feather-icons'

    export default {
        name: "PageActions",
        props: ['hover'],
        data: () => ({
            menuModel: false
        }),
        components: {
            Edit3Icon,
            Trash2Icon
        },
        methods: {
            toggleMenu(value) {
                this.menuModel = value
            },
            renamePage() {
                this.menuModel = false
                this.$emit('rename-page')
            },
            removePage() {
                this.menuModel = false
                this.$emit('remove-page')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-actions-menu {
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;

        .v-list-item {
            padding-left: 14px;
        }
    }

    .page-actions-menu__activator-btn {
        color: var(--v-bluish_gray_500-base) !important;
        width: 24px;
        height: 24px;
        margin-right: 9px !important;

        &:hover {
            &:before {
                opacity: 0.2;
                background-color: var(--v-bluish_gray_200-base);
            }

        }
    }
</style>