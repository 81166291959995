export default {
    data: () => ({
        _acceptMimeTypesList: [],
        _acceptExtensionsList: [],
        _maxFilesCount: undefined,
        _maxFileSize: undefined, // at bytes
        _matchBothMimeAndExtension: false
    }),
    computed: {
        multiple() {
            return this._maxFilesCount > 1
        }
    },
    methods: {
        loadValidationParams({mimeTypes, extensions, maxFilesCount, maxFileSize, matchBothMimeAndExtension}) {
            this._acceptMimeTypesList = mimeTypes ? mimeTypes : []
            this._acceptExtensionsList = extensions ? extensions : []
            this._maxFilesCount = maxFilesCount
            this._maxFileSize = maxFileSize
            this._matchBothMimeAndExtension = matchBothMimeAndExtension
        },
        validate(filesList) {
            let that = this
            let validateFileType = file => !that._acceptMimeTypesList.length || that._acceptMimeTypesList.includes(file.type)
            let validateFileExt = file => !that._acceptExtensionsList.length || that._acceptExtensionsList.includes(that.Utils.getFileExtension(file.name))

            return filesList.length <= this._maxFilesCount
                && filesList.every(file => {
                    if (that._matchBothMimeAndExtension) {
                        if (!validateFileType(file) || !validateFileExt(file))
                            return false
                    } else {
                        if (!validateFileType(file) && !validateFileExt(file))
                            return false
                    }

                    if (file.size > that._maxFileSize) return false

                    return true
                })
        }
    }
}