<template>
    <f-select
            v-model="targetObj.field_id"
            class="conditional-logic-editor__target-field"
            :items="getFilteredEnumeratedFieldsList"
            :item-value="item => item.fieldId"
            :item-disabled="checkIfFieldInaccessible"
            :menu-props="{
                nudgeBottom: 46,
                contentClass: 'conditional-logic-editor__target-items'
            }"
            :error-messages="errors"
            outlined
            hide-details="auto"
            dense
    >
        <template #selection="{item}">
            <component
                :is="item.fieldConst.ICON_COMPONENT"
                width="30"
                height="30"
                class="mr-3"
            />
            <span>
                <span class="mr-2 grey_700--text">{{ item.formattedLocation }}:</span>
                <span>{{ item.fieldObj.title }}</span>
            </span>
        </template>
        <template #item="{on, attrs, item}">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                    <component
                        :is="item.fieldConst.ICON_COMPONENT"
                        width="30"
                        height="30"
                    />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="mr-2 grey_700--text">{{ item.formattedLocation }}:</span>
                        <span>{{ item.fieldObj.title }}</span>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="attrs.disabled">
                    <f-hover-tooltip
                            v-if="item.conditioned"
                            top
                            text="This Question already has Conditional Logic"
                            v-slot="{on}"
                    ><span v-on="on">Conditioned</span></f-hover-tooltip>
                    <f-hover-tooltip
                            v-else-if="item.used_in_this_statement"
                            top
                            text="This Question is already used in this Conditional Logic statement"
                            v-slot="{on}"
                    ><span v-on="on">Conditioned</span></f-hover-tooltip>
                    <f-hover-tooltip
                            v-else-if="item.targeted"
                            top
                            text="This Question is already used in another Conditional Logic statement"
                            v-slot="{on}"
                    ><span v-on="on">Conditioned</span></f-hover-tooltip>
                    <f-hover-tooltip
                            v-else-if="item.hidden"
                            top
                            text="This question is hidden"
                            v-slot="{on}"
                    ><span v-on="on">This question will not be shown</span></f-hover-tooltip>
                    <f-hover-tooltip
                            v-else-if="item.shown"
                            top
                            text="This Question isn't hidden in Block Properties"
                            v-slot="{on}"
                    ><span v-on="on">Not hidden</span></f-hover-tooltip>
                </v-list-item-action>
            </v-list-item>
        </template>
        <template #message="{key, message}">
            <TargetErrorMessageFieldRequired
                    v-if="message === 'targetedFieldNotRequired'"
                    :field-id="targetObj.field_id"
            />
        </template>
    </f-select>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import { useVuelidate } from '@vuelidate/core'
    import { required, helpers } from '@vuelidate/validators'
    import { computed } from '@vue/composition-api'
    import { FieldsEnumerationGettersUtils } from '@/store/util_modules/fields_enumeration_getters'

    export default {
        name: "ConditionalTargetSelect",
        components: {
            TargetErrorMessageFieldRequired: () => import('./TargetErrorMessageFieldRequired')
        },
        props: {
            conditionFieldId: String,
            targetObj: Object,
            anotherTargets: Array
        },
        setup(props, {root}) {
            const $v = useVuelidate(
                computed(() => ({
                    targetObj: {
                        action: {required},
                        field_id: {
                            required,
                            targetedFieldNotRequired: value => !helpers.req(value) || !root.$store.getters['edit/conditional/getTargetedAndConditionedFields'][value].fieldObj.properties.required
                        }
                    }
                })),
                {
                    targetObj: props.targetObj
                }
            )

            const errors = computed(() => $v.value.$silentErrors
                .filter(err => err.$validator === 'targetedFieldNotRequired')
                .map(() => 'targetedFieldNotRequired'))

            return {
                $v,
                errors
            }
        },
        computed: {
            ...mapGetters('edit', [
                'getFormJSON',
                FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName
            ]),
            ...mapGetters('edit/conditional', [
                'getCurrentConditionalFieldId',
                'getTargetedAndConditionedFields'
            ]),
            getFilteredEnumeratedFieldsList() {
                this.targetObj
                return this[FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName]
                    .filter(({fieldId}) => fieldId !== this.getCurrentConditionalFieldId)
                    .map(({fieldId, fieldObj}) => ({
                        ...this.getTargetedAndConditionedFields[fieldId],
                        hidden: fieldObj.properties.hide,
                        shown: !fieldObj.properties.hide,
                        targeted: this.getTargetedAndConditionedFields[fieldId].targeted && this.targetObj.field_id !== fieldId && this.getTargetedAndConditionedFields[fieldId]?.target_owner !== this.conditionFieldId,
                        used_in_this_statement: this.anotherTargets.some(({field_id}) => field_id === fieldId)
                    }))
            }
        },
        watch: {
            ['targetObj.action']() {
                if (this.targetObj.field_id && this.checkIfFieldInaccessible(this.getTargetedAndConditionedFields[this.targetObj.field_id]))
                    this.targetObj.field_id = undefined
            }
        },
        methods: {
            checkIfFieldInaccessible(item) {
                return (this.targetObj.action === 'hide' && item.fieldObj.properties.hide)
                || (this.targetObj.action === 'show' && !item.fieldObj.properties.hide)
                || (this.targetObj.action === 'jump_to' && item.fieldObj.properties.hide)
                || item.conditioned
                || (item.targeted && this.targetObj.field_id !== item.fieldId && item?.target_owner !== this.conditionFieldId)
                || this.anotherTargets.some(({field_id}) => field_id === item.fieldId)
            }
        }
    }
</script>