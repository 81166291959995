var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "650",
      "persistent": _vm.isLoading
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "font-weight-medium",
          attrs: {
            "color": "grey_900",
            "text": ""
          }
        }, on), [_vm._v("Change ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Change Your password "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('XIcon')], 1)], 1), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('p', {
    staticClass: "v-card__subheader"
  }, [_vm._v("Current:")]), _c('v-text-field', {
    attrs: {
      "type": _vm.showCurrentPassword ? 'text' : 'password',
      "rules": [function (v) {
        return !!v || 'Current password is required';
      }, function (v) {
        return v !== null && v.length >= _vm.constants.MIN_PASS_LENGTH || 'Incorrect password';
      }],
      "error-messages": _vm.currentPasswordErrors,
      "hide-details": "auto",
      "validate-on-blur": "",
      "outlined": ""
    },
    on: {
      "keydown": function () {
        return _vm.currentPasswordErrors = [];
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [!_vm.showCurrentPassword ? _c('EyeIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "16"
          },
          on: {
            "click": function ($event) {
              _vm.showCurrentPassword = true;
            }
          }
        }) : _c('EyeOffIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "16"
          },
          on: {
            "click": function ($event) {
              _vm.showCurrentPassword = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPassword,
      callback: function ($$v) {
        _vm.currentPassword = $$v;
      },
      expression: "currentPassword"
    }
  }), _c('p', {
    staticClass: "v-card__subheader"
  }, [_vm._v("New:")]), _c('v-text-field', {
    attrs: {
      "rules": [function (v) {
        return !!v || 'New password is required';
      }, function (v) {
        return v !== null && v.length >= _vm.constants.MIN_PASS_LENGTH || ("Make it more than " + (_vm.constants.MIN_PASS_LENGTH) + " characters for extra security");
      }],
      "type": "password",
      "hide-details": "auto",
      "validate-on-blur": "",
      "outlined": ""
    },
    model: {
      value: _vm.newPassword,
      callback: function ($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('p', {
    staticClass: "v-card__subheader"
  }, [_vm._v("Confirm:")]), _c('v-text-field', {
    attrs: {
      "rules": [_vm.newPassword === _vm.confirmPassword || 'Passwords must match'],
      "type": "password",
      "hide-details": "auto",
      "validate-on-blur": "",
      "outlined": ""
    },
    model: {
      value: _vm.confirmPassword,
      callback: function ($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-5 px-7",
    attrs: {
      "color": "gray_100",
      "depressed": "",
      "rounded": "",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "px-7",
    attrs: {
      "color": "blue_400",
      "depressed": "",
      "rounded": "",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.updatePassword
    }
  }, [_vm._v(" Update Password ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }