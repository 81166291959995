var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "rows": _vm.rowHeaders,
      "columns": _vm.columnHeaders,
      "radio": !_vm.fieldProperties.multiple_selection,
      "submission-obj-value": _vm.submissionObjValue
    },
    on: {
      "toggle-selection:add": _vm.addOption,
      "toggle-selection:remove": _vm.removeOption
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }