<template>
    <div class="form-parameters-subheader">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "FormParametersSubheader"
    }
</script>

<style lang="scss" scoped>
    .form-parameters-subheader {
        font-weight: 500;
        font-size: .75rem;
        color: var(--v-gray_400-base);
        margin-bottom: 12px;
    }
</style>