<template>
    <svg width="78" height="55" viewBox="0 0 78 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.608643" y="1.47852" width="75.913" height="52.0435" rx="7.45652" fill="white"/>
        <rect x="43.8695" y="18.2168" width="19.8913" height="2.65217" rx="1.32609" fill="currentColor"/>
        <rect x="43.8695" y="23.5215" width="19.8913" height="7.95652" rx="1.32609" fill="currentColor"/>
        <rect x="43.8695" y="34.1289" width="6.63043" height="2.65217" rx="1.32609" fill="currentColor"/>
        <path d="M34.15 2H8.00002C4.13403 2 1.00002 5.13401 1.00002 9V46C1.00002 49.866 4.13403 53 8.00002 53H34.15V2Z"
              fill="#F5F6F8"/>
        <rect class="border" x="0.608643" stroke-width="1" y="1.47852" width="75.913" height="52.0435" rx="7.45652" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: 'RightLayoutIcon',
    }
</script>

<style scoped>

</style>