var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7 8.25C7 7.83579 7.33579 7.5 7.75 7.5H22.25C22.6642 7.5 23 7.83579 23 8.25C23 8.66421 22.6642 9 22.25 9H7.75C7.33579 9 7 8.66421 7 8.25Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11 14.25C11 13.8358 11.3358 13.5 11.75 13.5H22.25C22.6642 13.5 23 13.8358 23 14.25C23 14.6642 22.6642 15 22.25 15H11.75C11.3358 15 11 14.6642 11 14.25Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17 20.25C17 19.8358 17.3358 19.5 17.75 19.5H22.25C22.6642 19.5 23 19.8358 23 20.25C23 20.6642 22.6642 21 22.25 21H17.75C17.3358 21 17 20.6642 17 20.25Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }