<template>
    <div
            ref="draggableField"
            style="background-color: white"
            class="static-editable-field-constructor static-editable-field-constructor--dragging-active"
    >
        <div class="static-editable-field-constructor__drag-hook">
            <DragHook/>
        </div>
        <component
            :is="fieldConst.ICON_COMPONENT"
            class="static-editable-field-constructor__field-type-icon"
            width="36"
            height="36"
        />
        <span>{{ fieldConst.READABLE_NAME }}</span>
    </div>
</template>

<script>
    import { watch, onMounted, reactive } from '@vue/composition-api'
    import Vue from 'vue'

    export default {
        name: "DraggableField",
        components: {
            DragHook: () => import('@/components/Elements/Icons/DragHook')
        },
        props: {
            fieldType: {
                type: String,
                required: true
            },
            event: {
                type: MouseEvent,
                required: true
            },
            initialBounding: {
                type: Object,
                required: true
            },
            mousePos: {
                type: Object,
                required: true
            },
            finalDropzoneBounding: [Boolean, Object],
            fadeOut: Boolean,
            messagesToDragManager: Object
        },
        setup(props, {root, refs}) {
            const RESIZING_START_TIMEOUT = 50

            const initialOffsetTop = props.event.pageY - props.initialBounding.top
            const initialOffsetLeft = props.event.pageX - props.initialBounding.left

            const initialHeight = props.initialBounding.height
            const initialWidth = props.initialBounding.width

            const finalHeight = 104
            const finalWidth = 384


            const finalOffsetTop = initialOffsetTop / (initialHeight / finalHeight)
            const finalOffsetLeft = initialOffsetLeft / (initialWidth / finalWidth)

            onMounted(async () => {
                const fieldEl = refs.draggableField
                Object.assign(fieldEl.style, {
                    transform: `translate3d(${-initialOffsetLeft}px, ${-initialOffsetTop}px, 0)`,

                    height: initialHeight + 'px',
                    width: initialWidth + 'px',
                    border: '1px var(--v-blue_400-base) solid',
                    boxShadow: '0px 4px 4px rgba(158, 166, 168, 0.25)'
                })
                await new Promise(resolve => setTimeout(resolve, RESIZING_START_TIMEOUT))

                Object.assign(fieldEl.style, {
                    transition: `all ${root.constants.DRAGGABLE_FIELD_INITIAL_RESIZING_DURATION}ms linear`,
                    transitionProperty: 'height, width, transform',

                    transformOrigin: `${finalOffsetLeft}px ${finalOffsetTop}px`,
                    transform: `translate3d(${-finalOffsetLeft}px, ${-finalOffsetTop}px, 0) rotate(2deg)`,

                    height: finalHeight + 'px',
                    width: finalWidth + 'px'
                })
            })

            Vue.set(props.messagesToDragManager, 'draggableFieldCenterCoords', reactive({x:0,y:0}))

            watch(
                props.mousePos,
                v => {
                    props.messagesToDragManager.draggableFieldCenterCoords.x = v.x - finalOffsetLeft + finalWidth / 2
                    props.messagesToDragManager.draggableFieldCenterCoords.y = v.y - finalOffsetTop + finalHeight / 2
                }
            )

            watch(
                () => props.finalDropzoneBounding,
                v => {
                    if (v) {
                        const fieldEl = refs.draggableField
                        Object.assign(fieldEl.style, {
                            transition: `all ${root.constants.DRAGGABLE_FIELD_DROP_DURATION}ms linear`,
                            height: v.height + 'px',
                            width: v.width + 'px',
                            transform: `translate3d(${props.finalDropzoneBounding.left - props.mousePos.x}px, ${props.finalDropzoneBounding.top - props.mousePos.y}px, 0) rotate(0)`
                        })
                    }
                }
            )

            watch(
                () => props.fadeOut,
                async v => {
                    if (v) {
                        const fieldEl = refs.draggableField
                        Object.assign(fieldEl.style, {
                            transition: `all ${root.constants.DRAGGABLE_FIELD_DROP_DURATION}ms linear`,
                            opacity: '0'
                        })
                    }
                }
            )

            const fieldConst = root.constants.FIELD_FROM_TYPE[props.fieldType]

            return {
                fieldConst
            }
        }
    }
</script>