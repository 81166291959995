var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-plans-features-table"
  }, [_c('div', {
    staticClass: "checkout-plans-features-table__table"
  }, [_vm._m(0), _c('div', {
    staticClass: "checkout-plans-features-table__table-body"
  }, _vm._l(_vm.formattedFeatures, function (feature) {
    var _vm$getPlansObject, _vm$getPlansObject$bu, _vm$getPlansObject$bu2, _vm$getPlansObject2, _vm$getPlansObject2$p, _vm$getPlansObject2$p2, _vm$getPlansObject3, _vm$getPlansObject3$f, _vm$getPlansObject3$f2;
    return _c('div', {
      key: feature.id,
      staticClass: "checkout-plans-features-table__table-row"
    }, [_c('div', [_c('span', [_vm._v(_vm._s(feature.readableName))])]), _c('div', [_c(feature.component, {
      tag: "component",
      attrs: {
        "value": (_vm$getPlansObject = _vm.getPlansObject) === null || _vm$getPlansObject === void 0 ? void 0 : (_vm$getPlansObject$bu = _vm$getPlansObject.business) === null || _vm$getPlansObject$bu === void 0 ? void 0 : (_vm$getPlansObject$bu2 = _vm$getPlansObject$bu.features) === null || _vm$getPlansObject$bu2 === void 0 ? void 0 : _vm$getPlansObject$bu2[feature.id]
      }
    })], 1), _c('div', [_c(feature.component, {
      tag: "component",
      attrs: {
        "value": (_vm$getPlansObject2 = _vm.getPlansObject) === null || _vm$getPlansObject2 === void 0 ? void 0 : (_vm$getPlansObject2$p = _vm$getPlansObject2.pro) === null || _vm$getPlansObject2$p === void 0 ? void 0 : (_vm$getPlansObject2$p2 = _vm$getPlansObject2$p.features) === null || _vm$getPlansObject2$p2 === void 0 ? void 0 : _vm$getPlansObject2$p2[feature.id]
      }
    })], 1), _c('div', [_c(feature.component, {
      tag: "component",
      attrs: {
        "value": (_vm$getPlansObject3 = _vm.getPlansObject) === null || _vm$getPlansObject3 === void 0 ? void 0 : (_vm$getPlansObject3$f = _vm$getPlansObject3.free) === null || _vm$getPlansObject3$f === void 0 ? void 0 : (_vm$getPlansObject3$f2 = _vm$getPlansObject3$f.features) === null || _vm$getPlansObject3$f2 === void 0 ? void 0 : _vm$getPlansObject3$f2[feature.id]
      }
    })], 1)]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-plans-features-table__table-head"
  }, [_c('div', {
    staticClass: "checkout-plans-features-table__table-row"
  }, [_c('div'), _c('div', [_c('span', [_vm._v("Business")])]), _c('div', [_c('span', [_vm._v("Pro")])]), _c('div', [_c('span', [_vm._v("Free")])])])]);
}]

export { render, staticRenderFns }