var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['static-form', 'static-form--editable', _vm.getFormJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.getFormJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--full-screen visible-header', _vm.getFormJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && _vm.getFormJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--full-screen invisible-header', _vm.getFormJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.getFormJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE && 'static-form--layout-style--compact visible-header', _vm.getFormJSON.layout_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT && _vm.getFormJSON.layout_header_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE && 'static-form--layout-style--compact invisible-header']
  }, [_c('StaticFormComposition'), _c('FormParameters'), _c('div', {
    staticClass: "static-form__container"
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('div', {
          staticClass: "static-form__cover-wrapper"
        }, [_c('div', {
          staticClass: "static-form__cover",
          style: {
            backgroundImage: 'url(' + _vm.getCurrentCoverImage + ')'
          }
        }), _c('div', {
          staticClass: "moveable-area h-full d-flex justify-center align-center text-h3"
        }, [_c('FormCoverTextEditor', {
          attrs: {
            "selector": ".moveable-area",
            "cover-hovered": hover
          }
        })], 1), _c('div', {
          staticClass: "static-form__cover-container-for-elements-attached-to-page"
        }, [_c('ImagePicker', {
          key: "cover",
          attrs: {
            "initial-image": _vm.getInitialCoverImage
          },
          on: {
            "preview-image": _vm.setCoverImagePreview,
            "cancel": function ($event) {
              return _vm.setCoverImagePreview(undefined);
            },
            "select-image": _vm.setNewCoverImage
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var imagePickerOn = _ref2.on;
              return [_c('f-hover-tooltip', {
                attrs: {
                  "top": "",
                  "text": "Change cover"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref3) {
                    var tooltipOn = _ref3.on;
                    return [_c('v-btn', _vm._g({
                      staticClass: "cover-image-btn cover-image-btn--changer v-btn--primary",
                      attrs: {
                        "icon": "",
                        "large": ""
                      }
                    }, Object.assign({}, imagePickerOn, tooltipOn)), [_c('v-icon', {
                      attrs: {
                        "size": "20px"
                      }
                    }, [_vm._v(" $image ")])], 1)];
                  }
                }], null, true)
              })];
            }
          }, {
            key: "title",
            fn: function () {
              return [_vm._v(" Change cover image ")];
            },
            proxy: true
          }], null, true)
        }), _c('AddLogoBadgeButton', {
          attrs: {
            "cover-hovered": hover
          }
        })], 1)])];
      }
    }])
  }), _c('StaticPagesDisplayManager'), _c('ConditionalLogicHandler')], 1), _c('div', {
    staticClass: "static-form__footer"
  }, [_c('StaticFormNavigation'), _vm.getFormJSON.settings.general_settings.formsly_branding ? _c('FormslyBadge', {
    attrs: {
      "disableClick": true
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }