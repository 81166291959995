var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['static-form__page-wrapper', _vm.getFormSourceJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE && ' visible-logo', _vm.getFormSourceJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE && 'invisible-logo']
  }, [_c('LogoBadge', {
    attrs: {
      "logo-url": _vm.getFormSourceJSON.logo_url
    }
  }), _c('div', {
    staticClass: "static-form__page"
  }, [_vm.isMainPage ? _c('StaticMainPage', {
    key: _vm.getCurrentPageID,
    attrs: {
      "page-id": _vm.getCurrentPageID
    }
  }) : _vm.isFinishPage ? _c('StaticFinishPage') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }