var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['f-checkbox', _vm.radio && 'f-checkbox--radio', _vm.value ? 'f-checkbox--value-true' : 'f-checkbox--value-false', _vm.disabled && 'f-checkbox--disabled', _vm.readonly && 'f-checkbox--readonly', _vm.hasLabel && 'f-checkbox--has-label']
  }, [_c('div', {
    staticClass: "f-checkbox__control"
  }, [_c('div', {
    staticClass: "f-checkbox__slot",
    on: {
      "click": _vm.toggleValue
    }
  }, [_c('div', {
    staticClass: "f-checkbox__input-wrapper"
  }, [_c('div', {
    staticClass: "f-checkbox__input-control"
  }, [_vm.value ? _c('div', {
    staticClass: "f-checkbox__icon-wrapper"
  }, [_c('div', {
    staticClass: "f-checkbox__icon"
  }, [_vm.radio ? _c('svg', {
    attrs: {
      "width": "8",
      "height": "8",
      "viewBox": "0 0 8 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "4",
      "cy": "4",
      "r": "4",
      "fill": "currentColor"
    }
  })]) : _c('svg', {
    attrs: {
      "width": "12",
      "height": "9",
      "viewBox": "0 0 12 9",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11 1.5L4.56707 7.75L1 4.26786",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])]) : _vm._e()])]), _vm.$scopedSlots.label ? _c('div', {
    staticClass: "f-checkbox__label"
  }, [_vm._t("label")], 2) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }