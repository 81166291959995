<template>
    <v-toolbar-items>
        <v-btn
                class="navigation-btn"
                active-class="navigation-btn--active"
                exact text
                :to="{name: constants.PAGE_NAME_FORM_EDITOR, params: {formId: $route.params.formId}}">
            <EditIcon stroke-width="1.5"/>
            <span>Editor</span>
        </v-btn>
        <v-btn
                class="navigation-btn"
                active-class="navigation-btn--active"
                exact text
                :to="{name: constants.PAGE_NAME_SUBMISSIONS_ANALYTICS, params: {formId: $route.params.formId}}"
        >
            <ActivityIcon stroke-width="1.5"/>
            <span>Responses</span>
            <f-badge v-if="getTotalResponsesMetric" white style="margin-left: 10px;">
                {{getTotalResponsesMetric}}
            </f-badge>
        </v-btn>
    </v-toolbar-items>
</template>

<script>
    import {EditIcon, ActivityIcon} from 'vue-feather-icons'
    import {mapGetters} from '@/xstore'

    export default {
        name: "FormEditorAndResponsesNavigationComponent",
        components: {
            EditIcon, ActivityIcon
        },
        computed: {
            ...mapGetters('submissions/metrics', [
                'getTotalResponsesMetric'
            ])
        }
    }
</script>