var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#E9F5F8"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.8148 14.8057V17.2131C17.8024 17.3834 17.7235 17.542 17.5951 17.6547C17.4668 17.7673 17.2992 17.8249 17.1287 17.8149H8.87129C8.70079 17.8249 8.53323 17.7673 8.40484 17.6547C8.27645 17.542 8.19754 17.3834 8.18518 17.2131V14.8057",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 14.8054V8.18506M13 8.18506L15.4074 10.5925M13 8.18506L10.5926 10.5925",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }