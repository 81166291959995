var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-template-data-transfer', {
    attrs: {
      "waitForLoad": true
    },
    on: {
      "mounted": function (dataStash) {
        return dataStash.wait(function () {
          dataStash.waitForLoad = false;
          if (_vm.isCurrentEditingThemeChanged) { _vm.$refs.themeNameEditor.focus(); }
        }, 300);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var dataStash = _ref.dataStash;
        return [_c('div', {
          staticClass: "style-preferences-theme-editor"
        }, [_c('div', {
          staticClass: "style-preferences-theme-editor__header"
        }, [_c('ChevronLeftIcon', {
          staticClass: "style-preferences-theme-editor__quit-button",
          on: {
            "click": function ($event) {
              return _vm.processStylePreferencesExit(_vm.quitThemeEditor);
            }
          }
        }), _c('f-template-data-transfer', {
          attrs: {
            "focused": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var dataStash = _ref2.dataStash;
              return [_c('div', {
                class: ['style-preferences-theme-editor__theme-name-wrapper', dataStash.focused && 'style-preferences-theme-editor__theme-name-wrapper--focused']
              }, [_c('f-hover-tooltip', {
                attrs: {
                  "top": "",
                  "text": "Edit Theme name here"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref3) {
                    var on = _ref3.on;
                    return [_c('f-simple-contenteditable-input', {
                      ref: "themeNameEditor",
                      staticClass: "style-preferences-theme-editor__theme-name",
                      attrs: {
                        "value": _vm.getCurrentEditingTheme.name,
                        "placeholder": "Theme name",
                        "overflow-wrap": "",
                        "mask": /^.{0,75}$/g
                      },
                      on: {
                        "input": function ($event) {
                          return _vm.updateCurrentEditingThemeName($event);
                        },
                        "mouseenter": on.mouseenter,
                        "mouseleave": on.mouseleave,
                        "update:focused": function ($event) {
                          dataStash.focused = $event;
                        }
                      }
                    })];
                  }
                }], null, true)
              })], 1)];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "style-preferences-theme-editor__content thin-scrollbar"
        }, [_c('FormParametersSubheader', [_vm._v("Font")]), _c('FontPickerComponent', {
          staticClass: "mb-6",
          attrs: {
            "value": _vm.getCurrentEditingThemeStyles[_vm.constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME],
            "outlined": "",
            "menu-props": {
              nudgeBottom: 55,
              minWidth: 250,
              maxHeight: 'auto',
              maxWidth: 274
            }
          },
          on: {
            "input": function ($event) {
              var _vm$updateCurrentEdit;
              return _vm.updateCurrentEditingThemeStyle((_vm$updateCurrentEdit = {}, _vm$updateCurrentEdit[_vm.constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME] = $event, _vm$updateCurrentEdit));
            }
          }
        }), _c('FormParametersSubheader', [_vm._v("Colors")]), _vm._l(_vm.colorVariables, function (primaryColorVariable) {
          return _c('StylePreferencesThemeEditorColorOption', {
            key: primaryColorVariable.GETTER_BASE_NAME,
            attrs: {
              "value": _vm.getCurrentEditingThemeStyles[primaryColorVariable.JSON_NAME]
            },
            on: {
              "input": function ($event) {
                var _vm$updateCurrentEdit2;
                return _vm.updateCurrentEditingThemeStyle((_vm$updateCurrentEdit2 = {}, _vm$updateCurrentEdit2[primaryColorVariable.JSON_NAME] = $event, _vm$updateCurrentEdit2));
              }
            }
          }, [_vm._v(" " + _vm._s(primaryColorVariable.READABLE_NAME) + " ")]);
        }), _vm.isFormInteractive ? [_c('FormParametersSubheader', [_vm._v("Homepage colors")]), _c('StylePreferencesThemeEditorColorOption', {
          attrs: {
            "value": _vm.interactiveHomePageStyles.TITLE_COLOR
          },
          on: {
            "input": function ($event) {
              return _vm.updateInteractiveHomePageStyles({
                TITLE_COLOR: $event
              });
            }
          }
        }, [_vm._v("Title color")]), _c('StylePreferencesThemeEditorColorOption', {
          attrs: {
            "value": _vm.interactiveHomePageStyles.DESCRIPTION_COLOR
          },
          on: {
            "input": function ($event) {
              return _vm.updateInteractiveHomePageStyles({
                DESCRIPTION_COLOR: $event
              });
            }
          }
        }, [_vm._v("Description color")]), _c('StylePreferencesThemeEditorColorOption', {
          attrs: {
            "value": _vm.interactiveHomePageStyles.BUTTON_BACKGROUND_COLOR
          },
          on: {
            "input": function ($event) {
              return _vm.updateInteractiveHomePageStyles({
                BUTTON_BACKGROUND_COLOR: $event
              });
            }
          }
        }, [_vm._v("Button color")]), _c('StylePreferencesThemeEditorColorOption', {
          attrs: {
            "value": _vm.interactiveHomePageStyles.BUTTON_TEXT_COLOR
          },
          on: {
            "input": function ($event) {
              return _vm.updateInteractiveHomePageStyles({
                BUTTON_TEXT_COLOR: $event
              });
            }
          }
        }, [_vm._v("Button text color")])] : _vm._e(), _c('FormParametersSubheader', [_vm._v("Button style")]), _c('StylePreferencesThemeEditorBorderRadiusStyle', {
          attrs: {
            "value": _vm.getCurrentEditingThemeStyles[_vm.constants.FSP.PRIMARY_VARIABLES.BORDER_RADIUS.JSON_NAME]
          },
          on: {
            "input": function ($event) {
              var _vm$updateCurrentEdit3;
              return _vm.updateCurrentEditingThemeStyle((_vm$updateCurrentEdit3 = {}, _vm$updateCurrentEdit3[_vm.constants.FSP.PRIMARY_VARIABLES.BORDER_RADIUS.JSON_NAME] = $event, _vm$updateCurrentEdit3));
            }
          }
        }), _vm.isFormInteractive ? [_c('FormParametersSubheader', [_vm._v("Background")]), _c('StylePreferencesBackgroundImagePicker')] : _vm._e()], 2), _c('div', {
          class: ['style-preferences-theme-editor__actions', !dataStash.waitForLoad && _vm.isCurrentEditingThemeChanged && 'style-preferences-theme-editor__actions--shown']
        }, [_vm.isCurrentEditingThemeAlreadyExist ? [_c('v-btn', {
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": _vm.quitThemeEditor
          }
        }, [_vm._v(" Cancel ")]), _c('f-template-data-transfer', {
          attrs: {
            "loading": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var dataStash = _ref4.dataStash,
                promiseProcessor = _ref4.promiseProcessor;
              return [_c('v-btn', {
                attrs: {
                  "color": "blue_400",
                  "rounded": "",
                  "loading": dataStash.loading
                },
                on: {
                  "click": function ($event) {
                    _vm.savePrivateThemeChanges(function (promise) {
                      return promiseProcessor(promise);
                    });
                  }
                }
              }, [_vm._v(" Save ")])];
            }
          }], null, true)
        })] : [_c('v-btn', {
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": _vm.quitThemeEditor
          }
        }, [_vm._v(" Reset ")]), _c('f-template-data-transfer', {
          attrs: {
            "loading": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var dataStash = _ref5.dataStash,
                promiseProcessor = _ref5.promiseProcessor;
              return [_c('v-btn', {
                attrs: {
                  "color": "blue_400",
                  "rounded": "",
                  "loading": dataStash.loading
                },
                on: {
                  "click": function ($event) {
                    promiseProcessor(_vm.saveNewPrivateTheme());
                  }
                }
              }, [_vm._v(" Save as new style ")])];
            }
          }], null, true)
        })]], 2), _c('GenericStoreDialog', {
          attrs: {
            "store-module-path": "edit/FSP_themes/editing/save_theme_with_related_forms_confirmation_dialog"
          }
        })], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }