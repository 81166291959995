<script>
import { base64DataURLToBlob, scaleImage } from '@/utils/misc'
import { mapActions, mapGetters } from '@/xstore'

export const logoBadgeEditorMixin = {
  computed: {
    ...mapGetters('edit', ['getFormJSON']),
    getCurrentLogoURL() {
      return this.getFormJSON?.logo_url
    },
  },
  methods: {
    ...mapActions('edit', ['changeFormAttrs']),
    addLogo() {
      this.fileInputEventBus.$emit('trigger-input', {
        mimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        maxFilesCount: 1,
        maxFileSize: 5,
        callback: file => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = async () => {
            const scaledImageFile = base64DataURLToBlob(
              await scaleImage(reader.result, 200, 200)
            )
            this.StorageActions.uploadPublicAttachment(scaledImageFile).then(
              url => {
                this.changeFormAttrs({ logo_url: url })
              }
            )
          }
        },
      })
    },
    removeLogo() {
      this.changeFormAttrs({ logo_url: null })
    },
  },
}

export default {
  name: 'AddLogoBadgeButton',
  components: {
    AddBadge: () => import('./Badge/AddBadge'),
  },
  mixins: [logoBadgeEditorMixin],
  props: {
    coverHovered: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<template>
  <v-scale-transition origin="center">
    <AddBadge
      v-if="coverHovered && !getCurrentLogoURL"
      class="static-form-logo-badge-add-logo-button" @click="addLogo">
      Add logo
    </AddBadge>
  </v-scale-transition>
</template>

<style lang="scss" scoped>
.static-form-logo-badge-add-logo-button {
  left: 16px;
  bottom: 16px;
}
.invisible-logo .v-image__image.v-image__image--cover {
    display: none;
  }
  .formsly-form .static-form__page-wrapper .static-form-logo-badge.invisible-log {    
    left: 0;
    right: 0;
    margin: 0 auto;
  }
</style>
