import GenericSelectionSubmittableFieldView from '@/components/Fields/GenericFieldViews/GenericSelectionFieldView/GenericSelectionSubmittableFieldView'

export default {
    extends: GenericSelectionSubmittableFieldView,
    name: "Submittable__MultipleSelection",
    methods: {
        optionInput(index, model) {
            if (model.value.includes(index))
                model.value = model.value.filter(mIndex =>  mIndex !== index)
            else
                model.value.push(index)
        }
    }
}