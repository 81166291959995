<template>
    <FormSettingsOptionTemplate :value="enabled" :always-enabled="alwaysEnabled">
        <template #header v-if="$slots.default">
          <FormParametersHeader v-model="enabled"
                                :always-enabled="alwaysEnabled"
                                :allowedOnlyWithPayment="allowedOnlyWithPayment"
          >
            <slot></slot>
          </FormParametersHeader>
        </template>
        <template #extension>
            <slot name="input"></slot>
        </template>
    </FormSettingsOptionTemplate>
</template>

<script>
    import {useVuelidate} from '@vuelidate/core'

    export default {
        name: "FormSettingsOptionWithInput",
        components: {
            FormSettingsOptionTemplate: () => import('./FormSettingsOptionTemplate'),
            FormParametersHeader: () => import('../../Elements/FormParametersHeader'),
        },
        props: {
            alwaysEnabled: {
                type: Boolean,
                default: false
            },
            value: [String, Number, Array, Boolean],
            defaultValue: [String, Number, Array],
            allowedOnlyWithPayment: {
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            enabled: vm.alwaysEnabled || !!vm.value,
        }),
        setup() {
            return {
                $v: useVuelidate(),
            }
        },
        watch: {
            enabled: {
                immediate: true,
                handler(v) {
                    if (!this.alwaysEnabled) {
                        if (v)
                            this.$emit('reset')
                        if (v && !this.value)
                            this.$emit('input', this.defaultValue)
                        if (!v)
                            this.$emit('input', null)
                    }
                }
            }
        }
    }
</script>