<template>
    <v-data-table
            v-model="selectedSubmissions"
            class="data-table"

            :headers="tableHeaders"
            :items="formattedSubmissions"
            :items-per-page="-1"
            :search="searchQuery"
            :custom-filter="filterFunction"
            item-key="submissionID"

            disable-pagination
            hide-default-footer

            show-select

            :header-props="{sortIcon: null}"

            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
    >
        <template #header.data-table-select="{ props: { value }, on }">
            <v-simple-checkbox
                    color="blue_400"
                    :ripple="false"
                    :value="value"
                    v-on="on"
            />
        </template>
        <template #item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox
                    color="blue_400"
                    :ripple="false"
                    :value="isSelected"
                    @input="select"
            />
        </template>
        <template v-for="header in tableHeaders" v-slot:[getTableHeaderName(header.value)]="{ header: headerData }">
            {{ Utils.stripHtml(headerData.text) || '-' }}
            <SortDirectionIcon
                    v-if="headerData.sortable"
                    class="ml-1"
                    :asc="sortBy === headerData.value && sortDesc === false"
                    :desc="sortBy === headerData.value && sortDesc === true"
                    :key="header.value"
            />
        </template>
        <template v-for="header in tableHeaders" v-slot:[getTableItemName(header.value)]="{ value, item }">
            <v-skeleton-loader v-if="item.loading.value" type="text" :key="header.value" width="80%"/>
            <component
                    v-else-if="value && value.fieldTypeConst"
                    :is="value.fieldTypeConst.ANALYTICS.VIEWS.SHORT_SINGLE_ANSWER_VIEW"
                    :submission-obj="value.submissionObj"
                    :source-obj="value.sourceObj"
                    :key="header.value"
            />
            <span
                    v-else-if="header.value === 'submissionDate'"
                    class="text-no-wrap"
                    :key="header.value"
            >{{ value.fromNow() }}</span>
            <span v-else :key="header.value">-</span>
        </template>
    </v-data-table>
</template>

<script>
    import { computed, ref } from '@vue/composition-api'

    export default {
        name: "TableView",
        components: {
            SortDirectionIcon: () => import('@/components/Elements/SortDirectionIcon')
        },
        props: {
            value: Array, // selectedSubmissions from AllResponsesView
            formattedSubmissions: Array,
            searchQuery: String
        },
        setup(props, { root, emit }) {
            const selectedSubmissions = computed({
                get: () => props.value,
                set: v => emit('input', v)
            })


            /** TABLE UTILS MODULE **/
            const tableHeaders = computed(() => [
                {
                    text: 'Date',
                    value: 'submissionDate',
                    width: 220,
                    align: 'center',
                    sort: (a, b) => b.diff(a),
                    filterable: false
                },

                // compile all unique fields from all versions of form
                ...(
                    !root.$store.getters['submissions/areSubmissionsReadyForFormatting']
                        ? Object.entries(root.$store.getters['edit/getFormJSON'].fields)
                            .map(([fieldID, fieldObj]) => ({
                                text: fieldObj.title,
                                value: fieldID
                            }))
                        : Object.entries(root.$store.getters['submissions/getVersionedFormSources'])
                            .map(([, formVersionSource]) => Object.entries(formVersionSource.fields))
                            .flat()
                            .filter(([fieldID, fieldObj], index, self) => self.findIndex(([id]) => id === fieldID) === index && root.constants.FIELD_FROM_TYPE[fieldObj.field_type].WITH_SUBMISSION)
                            .map(([fieldID, fieldObj]) => ({
                                align: 'left',
                                text: fieldObj.title,
                                value: fieldID,
                                filterable: root.constants.FIELD_FROM_TYPE[fieldObj.field_type].ANALYTICS.FILTERABLE,
                                sortable: root.constants.FIELD_FROM_TYPE[fieldObj.field_type].ANALYTICS.SORTABLE,
                                ...(root.constants.FIELD_FROM_TYPE[fieldObj.field_type].ANALYTICS.SORTABLE && ({
                                    sort: (valueA, valueB) => valueA && valueB
                                        ? root.constants.FIELD_FROM_TYPE[fieldObj.field_type].ANALYTICS.SORT(valueA.submissionObj, valueB.submissionObj, fieldObj)
                                        : valueA
                                            ? 1
                                            : valueB
                                                ? -1
                                                : 0
                                }))
                            }))
                )
            ])
            const getTableHeaderName = fieldID => `header.${fieldID}`
            const getTableItemName = fieldID => `item.${fieldID}`
            const tableUtilsModule = {tableHeaders, getTableHeaderName, getTableItemName}


            /**  SORTING **/
            const sortBy = ref(undefined)
            const sortDesc = ref(false)
            const updateSortBy = v => sortBy.value = v
            const updateSortDesc = v => sortDesc.value = v
            const sortingModule = {sortBy, sortDesc, updateSortBy, updateSortDesc}

            const filterFunction = (value, search, items) => Object.values(items)
                    .filter(v => typeof v === 'object' && 'submissionObj' in v && v.fieldTypeConst.ANALYTICS.FILTERABLE)
                    .some(v => v.fieldTypeConst.ANALYTICS.FILTER(v.submissionObj, search))

            return {
                selectedSubmissions,
                filterFunction,
                ...tableUtilsModule,
                ...sortingModule
            }
        }
    }
</script>

<style scoped>

</style>