var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-template-data-transfer', {
    attrs: {
      "isMounted": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var dataStash = _ref.dataStash;
        return [_c('GenericSelectionSummaryFieldView', _vm._b({
          attrs: {
            "optionsFormatter": _vm.optionsFormatter
          },
          on: {
            "mounted": function ($event) {
              dataStash.isMounted = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function (_ref2) {
              var option = _ref2.option;
              return [_vm.optionsType !== 'SimpleArray' ? _c(_vm.$options.Dropdown.UTIL.READY_LISTS[_vm.optionsType].ICON_COMPONENT.NAME, _vm._b({
                tag: "component",
                staticClass: "mr-2"
              }, 'component', _vm.$options.Dropdown.UTIL.READY_LISTS[_vm.optionsType].ICON_COMPONENT.BIND(_vm.$options.Dropdown.UTIL.READY_LISTS[_vm.optionsType].LIST[option.index]), false)) : _vm._e()];
            }
          }], null, true)
        }, 'GenericSelectionSummaryFieldView', _vm.$attrs, false))];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }