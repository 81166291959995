export const CONFETTI_CONSTANTS = {
    "nm": "Confetti 1",
    "mn": "",
    "layers": [{
        "ty": 3,
        "nm": "Null 3",
        "mn": "",
        "sr": 1,
        "st": 0,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [50, 50, 0], "ix": 1},
            "s": {"a": 0, "k": [204.19921875, 204.19921875, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 260, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {"a": 0, "k": 0, "ix": 11}
        },
        "ef": [],
        "ind": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 50",
        "mn": "",
        "sr": 1,
        "st": 14,
        "op": 60,
        "ip": 14,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [41, 41, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [50, 50, 0], "ix": 2},
            "r": {"a": 0, "k": 487, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 14}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 19
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 41}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 57
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-19.362, 34.498], [7.899, -25.433], [37.607, 53.867], [11.905, 108.592]],
                        "o": [[0, 0], [42.457, -75.648], [-9.025, 29.061], [-47.221, -67.637], [-3.807, -34.72]],
                        "v": [[1, 2], [-48.536, -23.607], [-76.476, -100.021], [27.098, -130.112], [-120.809, -222.715]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.9216, 0.5294, 0.5294], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 14}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 49
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 17}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 54
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 2,
        "parent": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 49",
        "mn": "",
        "sr": 1,
        "st": 13,
        "op": 60,
        "ip": 13,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [70, 70, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [50, 50, 0], "ix": 2},
            "r": {"a": 0, "k": 353, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 13}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 18
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 40}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 56
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [8.506, -58.529], [28.328, 67.272], [11.905, 108.592]],
                        "o": [[0, 0], [9.966, -26.338], [-12.365, 85.083], [-32.013, -76.024], [-3.807, -34.72]],
                        "v": [[1, 2], [-48.536, -23.607], [-76.476, -100.021], [27.098, -130.112], [-120.809, -222.715]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.7647, 0.4314, 0.4314], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 13}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 48
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 16}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 53
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 3,
        "parent": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 48",
        "mn": "",
        "sr": 1,
        "st": 12,
        "op": 60,
        "ip": 12,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [70, 70, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [50, 50, 0], "ix": 2},
            "r": {"a": 0, "k": 187, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 17
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 39}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 55
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [8.254, -31.239], [20.685, 70.001], [17.795, 30.055]],
                        "o": [[0, 0], [9.966, -26.338], [-7.439, 28.154], [-10.769, -36.442], [-17.795, -30.055]],
                        "v": [[1, 2], [2.692, -79.782], [-49.416, -90.653], [-14.589, -135.23], [-105.31, -168.421]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [1, 0.7961, 0.7961], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 47
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 15}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 52
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 4,
        "parent": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 47",
        "mn": "",
        "sr": 1,
        "st": 11,
        "op": 60,
        "ip": 11,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [58, 58, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [50, 50, 0], "ix": 2},
            "r": {"a": 0, "k": 203, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 16
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 38}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 54
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [-1, 38], [35.009, -27.828], [-11, 31]],
                        "o": [[0, 0], [9.966, -26.338], [1, -38], [-39, 31], [11, -31]],
                        "v": [[1, 2], [48, -39], [29, -112], [136, -107], [103, -176]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.9176, 0.3216, 0.3216], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 46
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 14}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 51
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 5,
        "parent": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 46",
        "mn": "",
        "sr": 1,
        "st": 10,
        "op": 60,
        "ip": 10,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [100, 100, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [50, 50, 0], "ix": 2},
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 15
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 37}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 53
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [-1, 38], [35.009, -27.828], [-11, 31]],
                        "o": [[0, 0], [9.966, -26.338], [1, -38], [-39, 31], [11, -31]],
                        "v": [[1, 2], [48, -39], [29, -112], [136, -107], [103, -176]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.651, 0.9725, 0.8196], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 45
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 13}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 50
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 6,
        "parent": 1
    }, {
        "ty": 4,
        "nm": "Shape Layer 45",
        "mn": "",
        "sr": 1,
        "st": 9,
        "op": 60,
        "ip": 9,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [82.080078125, 82.080078125, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 487, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 14
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 36}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 52
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-19.362, 34.498], [7.899, -25.433], [37.607, 53.867], [11.905, 108.592]],
                        "o": [[0, 0], [42.457, -75.648], [-9.025, 29.061], [-47.221, -67.637], [-3.807, -34.72]],
                        "v": [[1, 2], [-48.536, -23.607], [-76.476, -100.021], [27.098, -130.112], [-120.809, -222.715]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.9216, 0.5294, 0.5294], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 44
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 49
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 7
    }, {
        "ty": 4,
        "nm": "Shape Layer 43",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [140.13671875, 140.13671875, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 353, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [8.506, -58.529], [28.328, 67.272], [11.905, 108.592]],
                        "o": [[0, 0], [9.966, -26.338], [-12.365, 85.083], [-32.013, -76.024], [-3.807, -34.72]],
                        "v": [[1, 2], [-48.536, -23.607], [-76.476, -100.021], [27.098, -130.112], [-120.809, -222.715]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.7647, 0.4314, 0.4314], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 43
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 48
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 8
    }, {
        "ty": 4,
        "nm": "Shape Layer 42",
        "mn": "",
        "sr": 1,
        "st": 7,
        "op": 60,
        "ip": 7,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [140.13671875, 140.13671875, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 187, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 12
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 34}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 50
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [8.254, -31.239], [20.685, 70.001], [17.795, 30.055]],
                        "o": [[0, 0], [9.966, -26.338], [-7.439, 28.154], [-10.769, -36.442], [-17.795, -30.055]],
                        "v": [[1, 2], [2.692, -79.782], [-49.416, -90.653], [-14.589, -135.23], [-105.31, -168.421]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [1, 0.7961, 0.7961], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 42
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 47
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 9
    }, {
        "ty": 4,
        "nm": "Shape Layer 44",
        "mn": "",
        "sr": 1,
        "st": 6,
        "op": 60,
        "ip": 6,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [116.11328125, 116.11328125, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 203, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 11
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 33}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 49
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [-1, 38], [35.009, -27.828], [-11, 31]],
                        "o": [[0, 0], [9.966, -26.338], [1, -38], [-39, 31], [11, -31]],
                        "v": [[1, 2], [48, -39], [29, -112], [136, -107], [103, -176]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.9176, 0.3216, 0.3216], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 41
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 46
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 10
    }, {
        "ty": 4,
        "nm": "Shape Layer 41",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [200.1953125, 200.1953125, 100], "ix": 6},
            "sk": {"a": 0, "k": 0},
            "p": {"a": 0, "k": [512.5, 512.5, 0], "ix": 2},
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Shape 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": false,
                        "i": [[0, 0], [-14, 37], [-1, 38], [35.009, -27.828], [-11, 31]],
                        "o": [[0, 0], [9.966, -26.338], [1, -38], [-39, 31], [11, -31]],
                        "v": [[1, 2], [48, -39], [29, -112], [136, -107], [103, -176]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "st",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Stroke",
                "nm": "Stroke 1",
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 5, "ix": 5},
                "d": [],
                "c": {"a": 0, "k": [0.651, 0.9725, 0.8196], "ix": 3}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }, {
            "ty": "tm",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Filter - Trim",
            "nm": "Trim Paths 1",
            "ix": 2,
            "e": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 40
                }],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "s": {
                "a": 1,
                "k": [{"o": {"x": 0.139, "y": 0}, "i": {"x": 0, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [100], "t": 45
                }],
                "ix": 1
            },
            "m": 1
        }],
        "ind": 11
    }, {
        "ty": 4,
        "nm": "Shape Layer 40",
        "mn": "",
        "sr": 1,
        "st": 6,
        "op": 60,
        "ip": 6,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.27},
                    "s": [280.32949218749997, 175.765478515625, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [299.74042968749995, 163.01103515625002, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.158},
                    "i": {"x": 0.833, "y": 1.242},
                    "s": [217.52021484374998, 160.812890625, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [245.93994140625, 252.15, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.361},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [135.001708984375, 228.22065429687498, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.46},
                    "s": [155.8080078125, 279.8009765625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [172.718505859375, 236.694921875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -1.604},
                    "i": {"x": 0.833, "y": -0.039},
                    "s": [265.292822265625, 169.058935546875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 0.895},
                    "s": [260.720361328125, 170.41425781249998, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.399},
                    "i": {"x": 0.833, "y": 1.337},
                    "s": [208.32524414062502, 209.35625, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [194.499755859375, 135.90458984375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.607},
                    "i": {"x": 0.833, "y": 1.652},
                    "s": [264.2337890625, 248.61455078125002, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [275.32460937499997, 146.857275390625, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.207},
                    "i": {"x": 0.833, "y": 1.036},
                    "s": [177.415087890625, 213.11992187500002, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [111.34262695312499, 259.95161132812495, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.634},
                    "i": {"x": 0.833, "y": 0.262},
                    "s": [206.02900390625, 187.6951171875, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [195.024267578125, 186.469921875, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.154},
                    "i": {"x": 0.833, "y": 0.703},
                    "s": [108.63398437500001, 246.03603515625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.116},
                    "i": {"x": 0.833, "y": 0.998},
                    "s": [138.905517578125, 225.41591796875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.002},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [216.59331054687502, 121.99301757812499, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.135},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [140.9375, 98.9765625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 1.486},
                    "i": {"x": 0.833, "y": 0.585},
                    "s": [169.87373046875, 194.509765625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 1.234},
                    "s": [171.593408203125, 223.932470703125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [178.44609375, 194.960205078125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 1.375},
                    "s": [152.314599609375, 126.657568359375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [170.60444335937498, 128.397265625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [70.038330078125, 144.36284179687502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.257},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [195.88310546875002, 166.712646484375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 0.901},
                    "s": [256.131884765625, 134.9876953125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.536},
                    "i": {"x": 0.833, "y": 0.553},
                    "s": [207.592529296875, 133.772509765625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.782},
                    "s": [198.647802734375, 162.975, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [159.609716796875, 140.410986328125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.203},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [96.400048828125, 81.957958984375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.106},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [52.501220703125, 3.26318359375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 1.052},
                    "s": [71.82607421875, 36.601708984375, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.032},
                    "i": {"x": 0.833, "y": 0.639},
                    "s": [91.679443359375, 121.064111328125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.99},
                    "s": [59.526074218750004, 74.907080078125, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.011},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [-47.7025390625, 27.75908203125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.06},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [46.60947265625, 31.724951171875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.379},
                    "i": {"x": 0.833, "y": 1.57},
                    "s": [-8.169970703125001, 103.4208984375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [-23.613037109375, 142.909423828125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.061},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [97.377001953125, 86.358251953125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 1.129},
                    "s": [27.31064453125, 4.5384277343749995, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [-36.813916015625, 65.966357421875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.028},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [126.501416015625, 133.57431640624998, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.146},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [4.526416015625, 48.66748046875, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [48.7916015625, -4.7206054687500005, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.314},
                    "i": {"x": 0.833, "y": 0.521},
                    "s": [87.4052734375, 108.7060546875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [79.29736328125, 26.730078125000002, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.359},
                    "i": {"x": 0.833, "y": 0.265},
                    "s": [40.757763671875004, 107.282666015625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [48.0228515625, 36.583691406250004, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 1.068},
                    "s": [104.8703125, 16.11572265625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [168.980859375, -0.9689453124999999, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [52.232958984375, 28.061376953125, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.647},
                    "s": [540.42724609375, 614.825830078125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.109},
                    "i": {"x": 0.833, "y": 0.769},
                    "s": [536.545458984375, 610.497607421875, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [524.261474609375, 596.309765625, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [506.99863281250003, 567.956103515625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [497.447314453125, 532.5035156250001, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [499.34916992187505, 500.366162109375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [508.51010742187503, 474.98740234375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [520.92021484375, 455.858740234375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [534.30126953125, 441.548779296875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [547.5602050781249, 430.778271484375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [560.256591796875, 422.590283203125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [572.222265625, 416.3341796875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [583.435205078125, 411.56552734375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [593.861376953125, 407.9259765625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [603.4447265625, 405.10322265625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [612.23330078125, 402.923095703125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [620.28115234375, 401.25747070312497, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [627.6403320312501, 400.008251953125, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [634.3548828125, 399.099365234375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [640.46484375, 398.474755859375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [646.01025390625, 398.08837890625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [651.0231445312501, 397.9001953125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [655.5375488281251, 397.88217773437503, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [659.581494140625, 398.00429687499997, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [663.17900390625, 398.24453125, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [666.35810546875, 398.58486328125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [669.146826171875, 399.007275390625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [671.569189453125, 399.49375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [673.6492187499999, 400.02827148437495, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [675.4109374999999, 400.600830078125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [676.8763671875, 401.19340820312505, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [678.0695312500001, 401.79599609375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [679.0144531249999, 402.390576171875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [679.73115234375, 402.969140625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [680.245654296875, 403.51767578125003, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [680.57998046875, 404.02016601562497, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [680.762158203125, 404.464599609375, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [680.814208984375, 404.83896484375003, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [680.7661621093749, 405.125244140625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [680.642041015625, 405.313427734375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.197},
                    "i": {"x": 0.833, "y": 0.799},
                    "s": [680.469873046875, 405.38750000000005, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.143},
                    "i": {"x": 0.833, "y": 0.778},
                    "s": [680.3517578125, 405.333447265625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.133},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [680.37978515625, 405.14125976562497, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [680.4798828125, 404.832958984375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [680.5179199218751, 404.46059570312497, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [680.4958984375, 404.040185546875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [680.417822265625, 403.59174804687495, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [680.2876953125, 403.123291015625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [680.109521484375, 402.64882812499997, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [679.889306640625, 402.182373046875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [679.6310546875, 401.73193359375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [679.340771484375, 401.305517578125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [679.020458984375, 400.91513671875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [678.676123046875, 400.564794921875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 11
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 33}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 49
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 12
    }, {
        "ty": 4,
        "nm": "Shape Layer 39",
        "mn": "",
        "sr": 1,
        "st": 11,
        "op": 60,
        "ip": 11,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [118.80190429687501, 192.43574218749998, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.259},
                    "i": {"x": 0.833, "y": 0.969},
                    "s": [179.66328125, 86.16806640625, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.05},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [208.609521484375, 132.743505859375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 1.424},
                    "s": [190.579931640625, 179.817431640625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.819},
                    "s": [178.48012695312502, 83.713671875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [252.13798828125, 149.2576171875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [338.57231445312505, 159.501611328125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [259.755419921875, 204.793798828125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.266},
                    "i": {"x": 0.833, "y": 0.747},
                    "s": [163.61162109375, 199.776904296875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 1.055},
                    "s": [186.576025390625, 235.695947265625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [233.4037109375, 169.237109375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -1.179},
                    "i": {"x": 0.833, "y": 0.473},
                    "s": [155.69990234375, 155.06728515625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [160.82890625, 224.348876953125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.364},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [188.141552734375, 110.76806640625, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 1.832},
                    "s": [183.052587890625, 91.33710937500001, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 1.069},
                    "s": [189.739111328125, 136.042724609375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [116.331494140625, 237.17939453125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.102},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [250.39228515624998, 194.900146484375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.104},
                    "i": {"x": 0.833, "y": 1.408},
                    "s": [190.215576171875, 186.29375, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.982},
                    "s": [216.9396484375, 127.9888671875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.022},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [59.496044921875004, 184.70620117187502, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [183.75126953125, 181.07465820312498, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.177},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [300.771435546875, 111.378662109375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [263.31289062499997, 159.889990234375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [229.071484375, 208.203125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.141},
                    "i": {"x": 0.833, "y": 1.464},
                    "s": [198.46162109375, 224.8193359375, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [209.854736328125, 197.73291015625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 1.54},
                    "i": {"x": 0.833, "y": 1.398},
                    "s": [135.091796875, 135.78046874999998, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [130.81562499999998, 21.058544921875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.911},
                    "i": {"x": 0.833, "y": -3.208},
                    "s": [155.49370117187502, 144.094580078125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [153.42768554687498, 202.933984375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.443},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [51.1138671875, 78.51860351562499, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.077},
                    "i": {"x": 0.833, "y": 1.134},
                    "s": [67.319677734375, 61.145654296875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.683},
                    "s": [58.891455078125006, 128.21708984375002, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [80.82685546875001, 108.09145507812501, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 0.787},
                    "s": [142.40693359375, 59.2578125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.137},
                    "i": {"x": 0.833, "y": 1.078},
                    "s": [74.8330078125, 77.73183593750001, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [-30.67392578125, -20.602099609375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.139},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [173.595361328125, 79.95200195312499, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 1.669},
                    "i": {"x": 0.833, "y": 1.314},
                    "s": [97.08071289062501, 55.145800781249996, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.73},
                    "s": [93.0587890625, -10.890625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.12},
                    "i": {"x": 0.833, "y": 1.035},
                    "s": [112.211474609375, 66.258642578125, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [155.17739257812502, 124.459423828125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.324},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [93.909619140625, 146.9353515625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.222},
                    "i": {"x": 0.833, "y": 0.037},
                    "s": [106.44985351562501, 51.744482421875006, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.024},
                    "s": [114.00322265625, -23.769189453124998, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.019},
                    "i": {"x": 0.833, "y": 0.891},
                    "s": [193.763037109375, -27.636962890625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.356},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [91.18496093750001, -4.122021484375001, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [59.892431640625006, 66.82119140625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.587},
                    "s": [533.938916015625, 599.84521484375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [533.7627441406249, 604.363623046875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [531.49453125, 622.1149414062501, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [522.227490234375, 653.5015625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [505.04472656250005, 685.580859375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [484.45263671875, 709.8145019531249, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [463.71040039062495, 726.388671875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [444.211376953125, 737.359375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [426.3919921875, 744.496337890625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [410.2962890625, 749.040771484375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [395.824169921875, 751.81748046875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [382.819482421875, 753.377001953125, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [371.11005859375, 754.09169921875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [360.533740234375, 754.2078124999999, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [350.95839843749997, 753.8955078125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [342.27392578125, 753.27490234375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [334.39223632812497, 752.424072265625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [327.23525390625, 751.407080078125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [320.74091796875, 750.26396484375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [314.85517578125, 749.024755859375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [309.531982421875, 747.7134765625001, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [304.73330078124997, 746.342138671875, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [300.42709960937503, 744.92275390625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [296.529296875, 743.4693359375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [292.995849609375, 742.017919921875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [289.79873046875, 740.596533203125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [286.919921875, 739.2372070312499, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [284.34140625000003, 737.9619628906249, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [282.045166015625, 736.79482421875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [280.01518554687505, 735.751806640625, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [278.23544921875, 734.8489257812499, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [276.69194335937505, 734.1001953125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [275.37666015625, 733.513623046875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [274.277587890625, 733.0972167968749, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.194},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [273.384716796875, 732.856982421875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [272.68803710937505, 732.794921875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [272.183544921875, 732.91103515625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.81},
                    "s": [271.859228515625, 733.2033203125001, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.81},
                    "s": [271.711083984375, 733.6677734375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.819},
                    "s": [271.7291015625, 734.29638671875, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [271.9052734375, 735.0791503906249, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [272.16552734375, 735.9860351562501, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [272.435791015625, 736.9870117187501, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [272.712060546875, 738.0620605468749, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [272.996337890625, 739.199169921875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [273.28461914062495, 740.376318359375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [273.57290039062497, 741.569482421875, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [273.8591796875, 742.758642578125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [274.14145507812503, 743.91376953125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 16
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 38}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 54
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 13
    }, {
        "ty": 4,
        "nm": "Shape Layer 38",
        "mn": "",
        "sr": 1,
        "st": 7,
        "op": 60,
        "ip": 7,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.684},
                    "s": [201.45654296875, 139.450048828125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 1.351},
                    "s": [209.23212890624998, 138.2388671875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.905},
                    "s": [230.91728515625002, 139.778369140625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.695},
                    "i": {"x": 0.833, "y": 1.398},
                    "s": [117.856982421875, 176.494189453125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [102.44794921875, 72.400634765625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.06},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [191.4587890625, 111.75302734375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.042},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [139.586181640625, 195.897119140625, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.285},
                    "i": {"x": 0.833, "y": 0.246},
                    "s": [174.087841796875, 164.626611328125, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [188.37578125000002, 113.360595703125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.077},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [303.30190429687497, 209.01191406249998, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [243.54360351562502, 247.53349609375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.987},
                    "s": [183.51503906250002, 147.013427734375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.015},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [132.218994140625, 198.763916015625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.223},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [175.66337890625, 132.711474609375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.113},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [201.5166015625, 97.14677734375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.477},
                    "i": {"x": 0.833, "y": 1.102},
                    "s": [190.5298828125, 217.998681640625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 2.575},
                    "s": [192.16147460937498, 180.0556640625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 1.108},
                    "s": [188.53593750000002, 116.916064453125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [260.67431640625, 131.798583984375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [95.09077148437501, 249.757666015625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.43},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [210.3412109375, 166.236181640625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 1.17},
                    "s": [238.066259765625, 215.79052734375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [269.49492187500005, 151.972265625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.205},
                    "i": {"x": 0.833, "y": 1.094},
                    "s": [173.92368164062498, 199.6267578125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [108.635986328125, 192.205517578125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.139},
                    "i": {"x": 0.833, "y": 0.9},
                    "s": [247.919873046875, 128.887744140625, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.496},
                    "i": {"x": 0.833, "y": 0.39},
                    "s": [195.64287109375, 79.499560546875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.097},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [185.09658203125, 150.384716796875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.019},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [118.417529296875, 216.53525390625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.405},
                    "i": {"x": 0.833, "y": 0.407},
                    "s": [172.68046875, 252.860693359375, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.097},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [163.41342773437498, 191.28862304687502, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.089},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [106.72412109375, 62.146630859375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 1.098},
                    "s": [134.13486328124998, 84.82275390625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [162.57260742187498, 156.96113281249998, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [100.636181640625, 27.789111328125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.933},
                    "i": {"x": 0.833, "y": 1.43},
                    "s": [64.024462890625, 114.189404296875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.97},
                    "s": [60.434960937499994, 108.175537109375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.046},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [82.57255859375, 90.722509765625, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -2.661},
                    "i": {"x": 0.833, "y": 17.435},
                    "s": [68.36269531250001, -22.814257812500003, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 0.997},
                    "s": [68.793115234375, 18.083642578124998, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.004},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [-16.746337890625, -48.064892578125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.375},
                    "i": {"x": 0.833, "y": 0.892},
                    "s": [65.305712890625, -31.368603515625, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.364},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [88.7505859375, -13.8935546875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.476},
                    "s": [95.7013671875, -29.164453124999998, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 1.098},
                    "s": [101.50703125, 17.194775390625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [132.206982421875, 131.18398437500002, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 1.665},
                    "i": {"x": 0.833, "y": 1.638},
                    "s": [65.533935546875, 156.37656249999998, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.718},
                    "s": [62.0205078125, 26.015380859375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [92.4181640625, -80.848876953125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.313},
                    "i": {"x": 0.833, "y": 0.25},
                    "s": [165.025, -57.94453125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [149.772119140625, 3.923828125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.112},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [27.660986328125, 44.1630859375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [79.63569335937501, 18.363916015625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.591},
                    "s": [525.3845703124999, 599.246630859375, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [531.264306640625, 597.00244140625, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [553.586083984375, 586.682373046875, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [591.697265625, 562.7169921875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [627.7584472656249, 528.0411621093749, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [650.7869140625, 490.958984375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [661.311181640625, 456.03291015625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [662.2921386718749, 425.294921875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [656.718701171875, 399.479736328125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [647.145361328125, 378.54130859375005, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [635.514013671875, 361.891064453125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [623.10791015625, 348.730224609375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [610.7238281250001, 338.332080078125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [598.79619140625, 330.084033203125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [587.52119140625, 323.49160156249997, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [576.9869140625, 318.188427734375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [567.219384765625, 313.912255859375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [558.2166015625, 310.45888671875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [549.960546875, 307.682177734375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [542.41318359375, 305.468017578125, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [535.532470703125, 303.72431640625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [529.2783691406249, 302.385009765625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [523.6128417968749, 301.396044921875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [518.499853515625, 300.71538085937505, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [513.90537109375, 300.30698242187503, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [509.79936523437505, 300.142822265625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [506.1498046875, 300.194873046875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [502.932666015625, 300.439111328125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [500.117919921875, 300.855517578125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [497.67954101562503, 301.42006835937497, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [495.5875, 302.11474609375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [493.823779296875, 302.917529296875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [492.362353515625, 303.8083984375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [491.185205078125, 304.763330078125, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [490.272314453125, 305.7623046875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [489.60166015625003, 306.78129882812505, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [489.1572265625, 307.79228515625005, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [488.918994140625, 308.7732421875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [488.870947265625, 309.69013671874995, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [488.99106445312503, 310.5189453125, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [489.26533203125, 311.22563476562505, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [489.581640625, 311.834228515625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [489.875927734375, 312.518896484375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [490.15019531250005, 313.279638671875, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [490.4064453125, 314.088427734375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [490.648681640625, 314.925244140625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [490.87490234375, 315.774072265625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [491.089111328125, 316.612890625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [491.29130859375, 317.427685546875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [491.48349609375003, 318.204443359375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [491.66767578125, 318.93115234375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [491.84584960937497, 319.593798828125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [492.018017578125, 320.184375, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 12
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 34}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 50
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 14
    }, {
        "ty": 4,
        "nm": "Shape Layer 37",
        "mn": "",
        "sr": 1,
        "st": 9,
        "op": 60,
        "ip": 9,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 2.237},
                    "s": [120.984033203125, 139.750341796875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [114.181396484375, 127.942822265625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.136},
                    "i": {"x": 0.833, "y": 0.869},
                    "s": [221.946533203125, 141.722265625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.23},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [180.94453125, 234.70097656250002, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.615},
                    "s": [157.663818359375, 237.545751953125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [178.872509765625, 182.167724609375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.09},
                    "i": {"x": 0.833, "y": 0.759},
                    "s": [255.76953125, 251.50537109375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [218.80947265625, 283.614697265625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [149.07744140625, 130.37319335937502, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.45},
                    "i": {"x": 0.833, "y": 0.468},
                    "s": [92.90463867187499, 189.13251953125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [101.67919921875, 123.30830078125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.209},
                    "i": {"x": 0.833, "y": 1.179},
                    "s": [148.903271484375, 120.507568359375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [135.44414062500002, 179.51313476562498, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.577},
                    "i": {"x": 0.833, "y": -0.318},
                    "s": [177.80747070312498, 238.9130859375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 1.032},
                    "s": [184.96245117187502, 141.91645507812498, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [291.013916015625, 195.310546875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 1.509},
                    "i": {"x": 0.833, "y": 1.702},
                    "s": [144.0365234375, 229.930322265625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.886},
                    "s": [135.446142578125, 194.343603515625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.312},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [216.365087890625, 185.17265625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 1.302},
                    "s": [245.8638671875, 216.49521484375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [274.0513671875, 212.14697265625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.168},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [143.64414062499998, 229.806201171875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -10.728},
                    "i": {"x": 0.833, "y": 4.698},
                    "s": [186.832275390625, 162.04208984374998, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 1.281},
                    "s": [186.499951171875, 94.398095703125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [201.60468749999998, 88.22607421875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -7.898},
                    "i": {"x": 0.833, "y": -6.847},
                    "s": [135.4921875, 176.658349609375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.084},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [136.182861328125, 304.499072265625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.209},
                    "i": {"x": 0.833, "y": 0.562},
                    "s": [200.499609375, 274.77607421874995, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [182.17973632812502, 190.87021484375, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [104.15361328125, 174.2419921875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -3.839},
                    "i": {"x": 0.833, "y": -2.601},
                    "s": [180.90048828124998, 171.297119140625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [179.268896484375, 64.90932617187501, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.669},
                    "i": {"x": 0.833, "y": 1.446},
                    "s": [110.44375, 168.130029296875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [118.07119140625001, 176.72041015625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.325},
                    "i": {"x": 0.833, "y": 1.109},
                    "s": [69.64794921875, 162.14619140625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [79.5416015625, 5.43330078125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -1.292},
                    "i": {"x": 0.833, "y": 4.228},
                    "s": [56.759375, -1.9859375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [58.138720703125, 24.387792968750002, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [3.273193359375, -40.41943359375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.991},
                    "i": {"x": 0.833, "y": 2.207},
                    "s": [47.652490234375, 3.257177734375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [44.209130859374994, 0.784765625, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.338},
                    "i": {"x": 0.833, "y": 0.444},
                    "s": [97.503125, 83.63959960937501, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [86.95883789062499, 91.67143554687499, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [27.176513671875, 146.004443359375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [-25.727099609375003, 84.34228515625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [53.788476562499994, -45.698583984375006, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.832},
                    "i": {"x": 0.833, "y": 2.014},
                    "s": [121.510546875, -56.819433593750006, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [129.047900390625, 17.154736328125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.093},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [29.823095703125002, 17.71728515625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.162},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [76.72685546875, -16.690283203125, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [60.811328125, 19.881396484375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.583},
                    "s": [528.665771484375, 600.87421875, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.763},
                    "s": [527.840966796875, 606.501708984375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [524.401611328125, 629.0116699218751, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [521.132421875, 670.7984375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [524.3555664062501, 717.3798828125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [535.724658203125, 756.5901367187499, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [553.137646484375, 786.060888671875, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [573.58359375, 806.636962890625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [594.50400390625, 820.2962890625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [614.4794921875, 829.146923828125, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [632.935498046875, 834.81044921875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [649.7519042968751, 838.383935546875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [665.0308105468749, 840.5860839843749, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [678.912353515625, 841.875341796875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [691.532666015625, 842.547998046875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [703.015869140625, 842.8042480468749, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [713.478076171875, 842.776220703125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [723.0173828125, 842.552001953125, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [731.7218750000001, 842.187646484375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [739.665625, 841.7251953125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [746.9046875, 841.18466796875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [753.4931152343751, 840.57607421875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [759.470947265625, 839.90341796875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [764.872216796875, 839.1626953125001, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [769.7489746093751, 838.4199707031249, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [774.1552734375, 837.7453125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [778.1211425781249, 837.134716796875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [781.680615234375, 836.5821777343749, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [784.8597167968751, 836.0816894531249, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [787.68447265625, 835.62724609375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [790.1789062500001, 835.2128417968751, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [792.363037109375, 834.8324707031251, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [794.2568847656249, 834.480126953125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [795.8784667968749, 834.151806640625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [797.243798828125, 833.8415039062501, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [798.36689453125, 833.5472167968751, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [799.2637695312501, 833.262939453125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [799.9484375, 832.988671875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.2},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [800.4289062500001, 832.7204101562501, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.2},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [800.72119140625, 832.4601562500001, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [800.83330078125, 832.20390625, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [800.863330078125, 831.9296386718751, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [800.907373046875, 831.613330078125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [800.963427734375, 831.258984375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [801.0395019531251, 830.872607421875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [801.131591796875, 830.460205078125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [801.2457031250001, 830.02978515625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [801.3838378906249, 829.5873535156251, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [801.54599609375, 829.144921875, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [801.7341796875, 828.70849609375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [801.952392578125, 828.2900878906249, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 14
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 36}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 52
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 15
    }, {
        "ty": 4,
        "nm": "Shape Layer 36",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [223.86240234375, 218.96162109374998, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.414},
                    "i": {"x": 0.833, "y": 0.087},
                    "s": [200.305419921875, 213.05185546875, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [194.375634765625, 81.981982421875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.117},
                    "i": {"x": 0.833, "y": 0.773},
                    "s": [135.358056640625, 142.633154296875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.132},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [159.9, 218.9095703125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.381},
                    "i": {"x": 0.833, "y": 1.215},
                    "s": [202.30537109375, 189.0404296875, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [214.166943359375, 275.238525390625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.477},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [171.76357421875, 202.54560546875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.039},
                    "i": {"x": 0.833, "y": 1.674},
                    "s": [178.067724609375, 227.043505859375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [173.75551757812502, 263.4490234375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.914},
                    "i": {"x": 0.833, "y": -0.583},
                    "s": [212.96176757812498, 221.422021484375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [209.6845703125, 100.48203125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.225},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [150.721044921875, 187.43686523437498, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.244},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [116.05522460937499, 206.11508789062498, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 2.53},
                    "i": {"x": 0.833, "y": 6.07},
                    "s": [98.061669921875, 209.402294921875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 0.801},
                    "s": [97.449072265625, 207.59052734375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.143},
                    "i": {"x": 0.833, "y": 0.786},
                    "s": [135.346044921875, 215.30205078125002, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.136},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [188.09550781250002, 123.38637695312501, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.898},
                    "s": [271.00839843750003, 234.46875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.448},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [189.49287109374998, 165.683642578125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.276},
                    "i": {"x": 0.833, "y": 0.146},
                    "s": [170.868701171875, 171.37919921875, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.778},
                    "s": [175.18291015625, 141.047607421875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [215.073828125, 208.797705078125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.792},
                    "s": [281.220361328125, 213.005810546875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [236.524755859375, 257.132861328125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [169.717578125, 130.98779296875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.038},
                    "i": {"x": 0.833, "y": 0.673},
                    "s": [210.26313476562498, 154.73095703125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [182.3138671875, 96.62626953125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.747},
                    "i": {"x": 0.833, "y": 0.647},
                    "s": [100.55410156250001, 75.343505859375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.109},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [108.764111328125, 200.86796875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 1.244},
                    "s": [135.358056640625, 182.1056640625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [163.96396484375, 187.20263671875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.101},
                    "i": {"x": 0.833, "y": 0.785},
                    "s": [51.448193359375004, 179.89150390625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.136},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [102.4119140625, 151.6279296875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.034},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [182.87841796875, 122.03505859375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.231},
                    "i": {"x": 0.833, "y": 1.534},
                    "s": [125.59052734375, 114.930126953125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [140.77734375, 134.769482421875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.24},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [28.36767578125, 117.79091796875001, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.419},
                    "i": {"x": 0.833, "y": 1.32},
                    "s": [57.327929687499996, 38.263330078125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.869},
                    "s": [64.52294921875, 44.715624999999996, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.228},
                    "i": {"x": 0.833, "y": 1.092},
                    "s": [29.731005859375003, 62.36083984375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.899},
                    "s": [9.6333984375, 20.586083984374998, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.472},
                    "i": {"x": 0.833, "y": 1.075},
                    "s": [51.900634765625, 65.237646484375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.799},
                    "s": [60.965478515625, 19.8193359375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [43.69462890625, 171.417236328125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.674},
                    "s": [19.234765625, 49.286083984375, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 1.022},
                    "s": [-2.580517578125, 132.32109375000002, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.018},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [-66.17255859375, 119.63271484375001, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [14.492138671874999, 29.3166015625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.683},
                    "i": {"x": 0.833, "y": 0.273},
                    "s": [89.185009765625, 25.118505859375002, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [81.06308593749999, 34.401562500000004, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 1.953},
                    "i": {"x": 0.833, "y": 2.637},
                    "s": [18.339892578125, -15.421044921875001, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.935},
                    "s": [15.5431640625, 32.948144531249994, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.3},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [73.25947265625, -53.11982421875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [60.7232421875, 23.61904296875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.604},
                    "s": [537.5264160156249, 608.38154296875, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.775},
                    "s": [542.00478515625, 605.17041015625, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.133},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [553.5220214843749, 589.14677734375, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [518.55791015625, 583.939697265625, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [477.72207031249997, 587.148828125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [442.1193359375, 589.9635742187501, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [412.436376953125, 591.571142578125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [387.476025390625, 592.0936523437499, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [366.151220703125, 591.743310546875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [347.6751953125, 590.6882812499999, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [331.49140625, 589.060693359375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [317.20146484375, 586.9606445312501, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [304.507080078125, 584.464208984375, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [293.168017578125, 581.6694824218749, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [283.02211914062497, 578.64052734375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [273.933251953125, 575.435400390625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [265.789306640625, 572.100146484375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [258.49418945312505, 568.680810546875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [251.971826171875, 565.213427734375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [246.15415039062498, 561.742041015625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [240.977099609375, 558.298681640625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [236.380615234375, 554.9093750000001, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [232.31464843749998, 551.6021484375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [228.73115234375, 548.3970214843749, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [225.58808593749998, 545.316015625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [222.84140625, 542.3711425781249, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [220.45908203125, 539.572412109375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [218.403076171875, 536.9318359375001, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [216.64936523437498, 534.45341796875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [215.16591796875, 532.1431640625, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [213.93271484374998, 530.003076171875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [212.927734375, 528.03916015625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [212.13095703125, 526.2494140625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [211.5263671875, 524.63583984375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [211.095947265625, 523.20244140625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [210.8296875, 521.94521484375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [210.711572265625, 520.8681640625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [210.73159179687502, 519.9712890625001, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [210.877734375, 519.252587890625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [211.139990234375, 518.716064453125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [211.50634765625, 518.3597167968751, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [211.9267578125, 518.119482421875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [212.337158203125, 517.929296875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.197},
                    "i": {"x": 0.833, "y": 0.863},
                    "s": [212.671484375, 517.769140625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.214},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [212.905712890625, 517.6370117187499, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.252},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [213.04384765625002, 517.534912109375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.742},
                    "s": [213.087890625, 517.462841796875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.123},
                    "i": {"x": 0.833, "y": 0.781},
                    "s": [213.03583984375, 517.4248046875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.801},
                    "s": [212.893701171875, 517.422802734375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [212.6634765625, 517.454833984375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [212.34716796875, 517.52490234375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [211.95278320312502, 517.62900390625, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [211.48432617187498, 517.769140625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [210.943798828125, 517.943310546875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [210.343212890625, 518.1515136718749, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 16
    }, {
        "ty": 4,
        "nm": "Shape Layer 35",
        "mn": "",
        "sr": 1,
        "st": 4,
        "op": 60,
        "ip": 4,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.112},
                    "s": [205.366357421875, 228.03447265625002, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.048},
                    "i": {"x": 0.833, "y": 0.982},
                    "s": [178.8544921875, 215.70844726562498, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": -0.023},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [241.03515625, 88.488330078125, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [192.297607421875, 160.987060546875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.038},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [139.46806640625, 271.80517578125, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 1.054},
                    "s": [175.62333984375, 207.7146484375, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [136.22490234375002, 235.81806640624998, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.085},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [201.2923828125, 165.8337890625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [169.04692382812502, 177.46513671875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.581},
                    "i": {"x": 0.833, "y": 2.653},
                    "s": [142.244775390625, 242.9330078125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [137.760400390625, 196.22343750000002, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.52},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [231.19355468749998, 85.17910156250001, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.255},
                    "i": {"x": 0.833, "y": -0.015},
                    "s": [218.28896484375, 96.29995117187501, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [212.030859375, 269.62705078125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.189},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [142.0986328125, 245.509521484375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.021},
                    "i": {"x": 0.833, "y": 1.191},
                    "s": [163.4875, 243.055126953125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.88},
                    "s": [146.33076171874998, 215.634375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.271},
                    "i": {"x": 0.833, "y": 1.105},
                    "s": [202.82587890624998, 115.863037109375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 1.034},
                    "s": [227.97841796875, 214.23701171874998, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.024},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [171.261083984375, 107.594970703125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.094},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [251.090966796875, 102.37788085937501, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [213.63642578125, 104.944384765625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [166.39833984375, 163.2072265625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.689},
                    "i": {"x": 0.833, "y": 0.571},
                    "s": [225.425927734375, 235.259521484375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 1.176},
                    "s": [219.059716796875, 248.3623046875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 0.971},
                    "s": [192.641943359375, 154.00625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.044},
                    "i": {"x": 0.833, "y": 0.758},
                    "s": [274.71201171875003, 170.58642578125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 0.901},
                    "s": [221.017626953125, 171.9037109375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.527},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [118.986083984375, 102.405908203125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 0.802},
                    "s": [99.811376953125, 143.964453125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 1.11},
                    "s": [122.637646484375, 108.18154296875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [154.11435546875, 165.491455078125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.103},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [81.20322265624999, 131.84663085937498, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [113.881103515625, 117.148291015625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 1.025},
                    "s": [140.22480468749998, 158.144287109375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.019},
                    "i": {"x": 0.833, "y": 1.03},
                    "s": [108.8341796875, 97.80141601562501, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [149.64999999999998, 98.510107421875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 1.102},
                    "s": [93.94365234375, 83.9298828125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [49.382177734375006, 45.916796875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [148.288671875, 117.088232421875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -1.08},
                    "i": {"x": 0.833, "y": 0.782},
                    "s": [39.506542968750004, 69.369677734375, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.772},
                    "s": [47.29814453125, 83.223193359375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.131},
                    "i": {"x": 0.833, "y": 1.128},
                    "s": [59.850390625, 98.72431640625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [81.631640625, 52.176904296874994, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [26.37373046875, 159.68378906249998, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.153},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [-26.379736328125, 18.522070312500002, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.32},
                    "i": {"x": 0.833, "y": 1.831},
                    "s": [-7.77158203125, 117.01816406249999, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 0.879},
                    "s": [-11.617333984375, 103.14462890624999, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.266},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [30.555810546875, 61.247753906250004, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 1.125},
                    "s": [49.770556640625, 44.31923828125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [62.631103515625, 47.252099609375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.282},
                    "i": {"x": 0.833, "y": 1.155},
                    "s": [30.439697265625, 4.276171875, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 1.018},
                    "s": [16.9365234375, 30.900146484375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.015},
                    "i": {"x": 0.833, "y": 1.07},
                    "s": [55.488134765625, -53.324023437499996, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [8.80458984375, -64.056494140625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [94.64833984375, 104.469921875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.58},
                    "s": [540.221044921875, 604.49375, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.763},
                    "s": [542.259033203125, 602.730029296875, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [550.4890625, 595.591064453125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [565.041259765625, 581.8296386718749, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [580.36220703125, 565.661865234375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [593.0666015625001, 550.98955078125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [603.298583984375, 538.5133789062501, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [611.80888671875, 527.969091796875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [619.212109375, 519.068408203125, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [626.03876953125, 511.67919921875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [632.983544921875, 506.171826171875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [640.518896484375, 506.171826171875, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [644.454736328125, 512.27578125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [646.5187500000001, 518.5418945312499, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [647.958154296875, 524.3275390625, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [649.11328125, 529.602685546875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [650.1182617187501, 534.3993652343751, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [651.03916015625, 538.755615234375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [651.9120117187499, 542.715478515625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [652.7568359375, 546.3009765625001, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [653.581640625, 549.542138671875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [654.392431640625, 552.46298828125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [655.18720703125, 555.083544921875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [655.965966796875, 557.42783203125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [656.718701171875, 559.511865234375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [657.443408203125, 561.35166015625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [658.1260742187501, 562.965234375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [658.756689453125, 564.36259765625, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [659.273193359375, 565.565771484375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [659.537451171875, 566.6248046875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [659.5634765625, 567.5537109375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [659.3652832031249, 568.3625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [658.962890625, 569.065185546875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [658.3703125, 569.665771484375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [657.6055664062501, 570.17626953125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [656.68466796875, 570.598681640625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [655.6236328125001, 570.9390136718749, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [654.44248046875, 571.2032714843749, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [653.1552246093751, 571.39345703125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [651.7818847656249, 571.515576171875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [650.336474609375, 571.5696289062499, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [648.841015625, 571.60166015625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [647.31552734375, 571.6597167968749, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [645.7780273437501, 571.739794921875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [644.24453125, 571.837890625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [642.733056640625, 571.9540039062499, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [641.26162109375, 572.08212890625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [639.8482421875001, 572.220263671875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [638.512939453125, 572.36240234375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [637.27373046875, 572.504541015625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [636.1486328125001, 572.644677734375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [635.1556640624999, 572.7748046875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [634.31484375, 572.888916015625, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.2},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [633.6441894531249, 572.9830078125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.232},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [633.163720703125, 573.049072265625, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [632.8954589843751, 573.081103515625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 4}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 9
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 31}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 47
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 17
    }, {
        "ty": 4,
        "nm": "Shape Layer 34",
        "mn": "",
        "sr": 1,
        "st": 10,
        "op": 60,
        "ip": 10,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.023},
                    "s": [184.8763671875, 161.3333984375, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.018},
                    "i": {"x": 0.833, "y": 1.063},
                    "s": [126.601513671875, 166.4583984375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [201.25234375000002, 224.521044921875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.11},
                    "i": {"x": 0.833, "y": 0.799},
                    "s": [69.748046875, 238.29047851562498, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [126.46738281249999, 147.165576171875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.917},
                    "i": {"x": 0.833, "y": 1.12},
                    "s": [206.655615234375, 52.30302734375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [214.66943359375, 199.47060546875002, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.987},
                    "s": [195.10234375000002, 200.6837890625, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.016},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [224.036572265625, 247.79375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 1.079},
                    "s": [199.672802734375, 217.59028320312498, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [226.060546875, 172.574365234375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.304},
                    "i": {"x": 0.833, "y": 1.27},
                    "s": [174.76650390625, 160.75283203125, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.89},
                    "s": [155.36357421875, 92.8505859375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.348},
                    "i": {"x": 0.833, "y": 1.303},
                    "s": [237.73393554687502, 82.6486328125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [263.64921875, 112.559814453125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.244},
                    "i": {"x": 0.833, "y": 0.997},
                    "s": [143.39389648437498, 116.6818359375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.003},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [174.02978515625, 273.092431640625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.032},
                    "i": {"x": 0.833, "y": 0.554},
                    "s": [144.50698242187502, 262.800390625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [165.78173828125, 218.77143554687498, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.64},
                    "i": {"x": 0.833, "y": 1.416},
                    "s": [258.38408203125, 187.797216796875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [272.25961914062503, 209.882763671875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [189.09648437500002, 188.325732421875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.038},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [117.67080078125, 148.98735351562502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.243},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [166.81875, 142.6271484375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.338},
                    "i": {"x": 0.833, "y": 0.695},
                    "s": [192.43173828125, 196.049267578125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 1.062},
                    "s": [200.82392578124998, 175.557275390625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [223.135693359375, 147.241650390625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [184.137646484375, 194.195458984375, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.007},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [127.3642578125, 148.0724609375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.232},
                    "i": {"x": 0.833, "y": 1.09},
                    "s": [179.63125, 137.50014648437502, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [208.89780273437498, 179.95156250000002, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.032},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [148.17255859374998, 106.914306640625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 0.81},
                    "s": [191.8671875, 180.3359375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [156.79296875, 76.925048828125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.899},
                    "s": [111.84311523437499, 109.18251953125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.487},
                    "i": {"x": 0.833, "y": 0.701},
                    "s": [57.67626953125, 168.68056640625, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [46.50537109375, 113.953173828125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.611},
                    "i": {"x": 0.833, "y": 0.701},
                    "s": [17.535107421875, 162.222265625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.116},
                    "i": {"x": 0.833, "y": 1.089},
                    "s": [12.958642578125, 24.489892578125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 0.758},
                    "s": [1.11708984375, 93.69140625, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [25.641015625, 78.358447265625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [72.42265625, 99.67724609375, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.242},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [34.85400390625, 8.442236328124999, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 0.67},
                    "s": [44.46337890625, 47.780615234375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [50.144921874999994, 30.057324218749997, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 1.216},
                    "s": [66.931298828125, 34.884033203125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.985},
                    "s": [85.3072265625, 7.229052734375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.019},
                    "i": {"x": 0.833, "y": 1.012},
                    "s": [19.364892578125, -72.08632812500001, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.01},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [73.321533203125, 83.0810546875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [11.783496093750001, 66.1525390625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.622},
                    "s": [543.0337890625, 608.219384765625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.107},
                    "i": {"x": 0.833, "y": 0.767},
                    "s": [546.447119140625, 605.89912109375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [556.83125, 595.543017578125, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [570.7408203125, 573.105126953125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [579.7736328125, 545.24794921875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [583.4091796875, 519.7951171875, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [584.08984375, 498.23408203125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [583.150927734375, 480.062353515625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [581.217041015625, 464.65732421875003, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [578.6565429687499, 451.474462890625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [575.6576171875, 440.083349609375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [572.33037109375, 430.18769531249995, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [568.728857421875, 421.557275390625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [564.881103515625, 414.03994140625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [560.777099609375, 407.5255859375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [556.360791015625, 401.9521484375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [551.488037109375, 397.42373046875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [545.7644531249999, 394.59296875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [539.85869140625, 396.2986328125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [536.225146484375, 400.744970703125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [533.6526367187499, 405.281396484375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [531.5465820312501, 409.57558593749997, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [529.698779296875, 413.57949218749997, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [528.0271484374999, 417.287109375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [526.49365234375, 420.700439453125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [525.078271484375, 423.827490234375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [523.781005859375, 426.67426757812495, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [522.6018554687499, 429.244775390625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [521.548828125, 431.54501953125003, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [520.63193359375, 433.572998046875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [519.865185546875, 435.32470703125, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [519.2585937499999, 436.80214843749997, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.194},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [518.83017578125, 438.009326171875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.205},
                    "i": {"x": 0.833, "y": 0.862},
                    "s": [518.595947265625, 438.94423828125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.21},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [518.569921875, 439.598876953125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.802},
                    "s": [518.7721191406249, 439.9732421875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [519.216552734375, 440.05532226562497, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [519.845166015625, 440.00327148437503, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [520.589892578125, 439.91518554687497, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [521.42470703125, 439.79106445312505, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [522.323583984375, 439.630908203125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [523.264501953125, 439.490771484375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [524.2314453125, 439.42470703124997, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [525.20439453125, 439.42270507812503, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [526.163330078125, 439.482763671875, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [527.092236328125, 439.594873046875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [527.97509765625, 439.755029296875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [528.79990234375, 439.955224609375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [529.554638671875, 440.189453125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [530.2312988281251, 440.451708984375, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 15
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 37}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 53
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 18
    }, {
        "ty": 4,
        "nm": "Shape Layer 33",
        "mn": "",
        "sr": 1,
        "st": 1,
        "op": 60,
        "ip": 1,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.161},
                    "s": [177.58525390625002, 250.86274414062498, 100],
                    "t": 1
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.987},
                    "s": [202.29736328125, 131.422216796875, 100],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": -0.015},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [129.77060546875, 239.483642578125, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 1.03},
                    "s": [190.92626953125, 172.11591796875, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [260.704345703125, 127.68857421874999, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": -0.229},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [165.695654296875, 275.77705078124995, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.219},
                    "i": {"x": 0.833, "y": 1.05},
                    "s": [191.06240234375, 229.762158203125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.544},
                    "s": [206.6015625, 161.339404296875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 1.032},
                    "s": [181.81337890625, 198.06923828125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [70.98725585937501, 219.7984375, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.056},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [223.8744140625, 225.47397460937498, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 1.085},
                    "s": [132.355126953125, 265.0505859375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [50.172949218750006, 175.637353515625, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.328},
                    "i": {"x": 0.833, "y": 0.884},
                    "s": [216.204931640625, 130.297119140625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.298},
                    "i": {"x": 0.833, "y": 1.34},
                    "s": [182.6021484375, 134.43115234375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [169.56342773437498, 105.7912109375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -2.065},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [235.85209960937502, 231.403759765625, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 2.695},
                    "s": [233.281591796875, 185.92138671875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.902},
                    "s": [237.09731445312502, 191.87919921875002, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.556},
                    "i": {"x": 0.833, "y": 1.407},
                    "s": [155.67988281249998, 115.60478515625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.863},
                    "s": [141.31787109375, 121.702734375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.213},
                    "i": {"x": 0.833, "y": 1.073},
                    "s": [225.874365234375, 131.414208984375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 0.871},
                    "s": [280.191357421875, 166.10605468749998, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.235},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [178.26191406249998, 79.98403320312501, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.202},
                    "i": {"x": 0.833, "y": 1.03},
                    "s": [122.33134765625, 230.27265625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [83.12109375, 282.96806640625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.674},
                    "i": {"x": 0.833, "y": 2.086},
                    "s": [136.65732421875, 214.70146484375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [130.76157226562498, 169.391259765625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [213.510302734375, 115.722900390625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -3.288},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [132.138916015625, 130.657470703125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.143},
                    "i": {"x": 0.833, "y": -1.723},
                    "s": [134.148876953125, 92.948681640625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [133.40815429687498, 102.64814453125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.778},
                    "s": [109.95327148437501, 142.57109375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.133},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [130.981787109375, 70.5087890625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.878},
                    "s": [165.947900390625, 74.438623046875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.264},
                    "i": {"x": 0.833, "y": 0.906},
                    "s": [101.148681640625, 82.746728515625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.747},
                    "i": {"x": 0.833, "y": 2.063},
                    "s": [71.19545898437501, 65.3837890625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [67.435791015625, 46.88974609375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.331},
                    "i": {"x": 0.833, "y": 1.055},
                    "s": [119.150244140625, 141.788330078125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [136.58525390625, 44.405322265625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.055},
                    "i": {"x": 0.833, "y": 1.215},
                    "s": [107.59697265625, 52.65537109375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [125.12006835937501, -15.779394531249999, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [62.316796875, 7.409228515625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.72},
                    "i": {"x": 0.833, "y": 1.261},
                    "s": [-1.17314453125, 119.52060546874999, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.063},
                    "i": {"x": 0.833, "y": 1.131},
                    "s": [5.411279296875, 50.70546875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [-21.8052734375, 99.16474609375, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.242},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [48.265087890625004, 25.298681640625002, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 1.541},
                    "s": [30.331591796875, 42.351318359375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [16.1357421875, 32.990185546875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.881},
                    "s": [122.45546875, 55.804443359375, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.278},
                    "i": {"x": 0.833, "y": 1.037},
                    "s": [50.875634765625, -28.6359375, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.026},
                    "i": {"x": 0.833, "y": 0.927},
                    "s": [20.23173828125, 17.120703125, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.597},
                    "i": {"x": 0.833, "y": 0.809},
                    "s": [64.54296875, 10.12587890625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [59.113671874999994, 73.605810546875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.422},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [52.080810546875, 60.511035156249996, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.252},
                    "i": {"x": 0.833, "y": 0.716},
                    "s": [50.351123046875, 36.75986328125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 1.719},
                    "s": [49.498291015625, 109.276611328125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [47.43828125, 70.87314453125, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [67.26962890624999, 22.80625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.635},
                    "s": [541.358154296875, 608.515673828125, 0],
                    "t": 1
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.798},
                    "s": [544.997705078125, 605.29853515625, 0],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.803},
                    "s": [558.0464355468749, 595.751220703125, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [580.0138671875, 599.2486328125, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [576.692626953125, 630.6232421875001, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [570.3244140625001, 657.715673828125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [564.480712890625, 680.34775390625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [559.455810546875, 699.5805175781251, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [555.1576171875, 716.2267578125001, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [551.447998046875, 730.90107421875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [548.21884765625, 744.0018554687499, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [545.3860839843751, 755.809375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [542.88564453125, 766.529833984375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [540.669482421875, 776.319384765625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [538.69755859375, 785.2941406250001, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [536.945849609375, 793.54619140625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [535.3943359374999, 801.1436035156249, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [534.025, 808.1404296874999, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [532.8318359375, 814.576708984375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [531.80283203125, 820.48447265625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [530.935986328125, 825.8857421875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [530.227294921875, 830.8025390624999, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [529.678759765625, 835.246875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [529.28837890625, 839.228759765625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [529.06015625, 842.758203125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [528.9980957031249, 845.8372070312499, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [529.106201171875, 848.4677734375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [529.386474609375, 850.65390625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [529.84892578125, 852.389599609375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [530.49755859375, 853.670849609375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [531.324365234375, 854.5296875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [532.23125, 855.1703125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [533.1761718749999, 855.652783203125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [534.1331054687499, 855.9931152343751, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [535.07802734375, 856.2073242187499, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [535.9949218749999, 856.3074218749999, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [536.861767578125, 856.3074218749999, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [537.666552734375, 856.2193359375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [538.3932617187501, 856.0491699218751, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [539.0298828125, 855.8069335937499, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [539.57041015625, 855.500634765625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [540.0068359375, 855.1943359375, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.202},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [540.341162109375, 854.9520996093751, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.227},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [540.5673828125, 854.77392578125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.281},
                    "i": {"x": 0.833, "y": 0.799},
                    "s": [540.6854980468751, 854.655810546875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.748},
                    "s": [540.693505859375, 854.5917480468751, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.125},
                    "i": {"x": 0.833, "y": 0.791},
                    "s": [540.597412109375, 854.579736328125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.808},
                    "s": [540.397216796875, 854.6117675781251, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [540.1029296875, 854.683837890625, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [539.720556640625, 854.7859375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [539.2601074218751, 854.91005859375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [538.73359375, 855.04619140625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [538.155029296875, 855.184326171875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [537.5404296874999, 855.314453125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [536.9078125, 855.420556640625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [536.2751953124999, 855.4926269531251, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [535.664599609375, 855.516650390625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [535.100048828125, 855.474609375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [534.60556640625, 855.3504882812499, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 1}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 6
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 28}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 44
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 19
    }, {
        "ty": 4,
        "nm": "Shape Layer 32",
        "mn": "",
        "sr": 1,
        "st": 4,
        "op": 60,
        "ip": 4,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [293.376220703125, 114.073291015625, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 7.109},
                    "i": {"x": 0.833, "y": 2.984},
                    "s": [205.50849609374998, 227.804248046875, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 1.082},
                    "s": [204.465478515625, 229.29970703125, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.769},
                    "s": [230.32670898437502, 135.554248046875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [179.096728515625, 208.55146484375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.029},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [88.184033203125, 240.858984375, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 1.068},
                    "s": [155.797998046875, 237.89609374999998, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 1.009},
                    "s": [215.704443359375, 239.719873046875, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [107.062451171875, 135.69638671875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -2.064},
                    "i": {"x": 0.833, "y": -1.061},
                    "s": [228.06450195312502, 198.980126953125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [223.369921875, 177.302978515625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.025},
                    "i": {"x": 0.833, "y": 0.95},
                    "s": [111.907177734375, 72.821044921875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.128},
                    "i": {"x": 0.833, "y": 1.094},
                    "s": [197.72890625, 200.99609375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.97},
                    "s": [163.90590820312502, 217.348046875, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.047},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [235.988232421875, 171.92172851562498, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.192},
                    "i": {"x": 0.833, "y": 1.034},
                    "s": [189.883251953125, 117.27041015625001, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.024},
                    "i": {"x": 0.833, "y": 0.781},
                    "s": [154.500732421875, 162.43647460937498, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [204.46748046875, 191.99931640625002, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.026},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [285.58662109375, 220.65126953125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.797},
                    "s": [223.992529296875, 98.80039062499999, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 1.009},
                    "s": [163.43544921875002, 185.70717773437502, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [76.1302734375, 264.35390625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.143},
                    "i": {"x": 0.833, "y": 1.012},
                    "s": [172.42421875, 176.56025390625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 1.088},
                    "s": [136.995654296875, 152.88916015625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [177.57124023437498, 125.38632812499999, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.935},
                    "s": [94.0517578125, 120.11318359375, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.298},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [219.96259765624998, 142.05458984375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.881},
                    "s": [192.41572265625, 110.381689453125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.278},
                    "i": {"x": 0.833, "y": 1.231},
                    "s": [170.59443359375, 181.2568359375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 1.189},
                    "s": [161.247314453125, 70.33261718749999, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [196.54775390625, 130.18901367187502, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.209},
                    "i": {"x": 0.833, "y": 0.966},
                    "s": [81.14516601562501, 120.123193359375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.059},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [114.04326171875, 105.5669921875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.244},
                    "i": {"x": 0.833, "y": 0.747},
                    "s": [94.762451171875, 51.32607421875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [99.66323242187501, 118.946044921875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 1.061},
                    "s": [109.628955078125, 37.973046875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.035},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [121.4064453125, 27.340673828125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.384},
                    "i": {"x": 0.833, "y": -0.945},
                    "s": [101.04057617187499, 14.141796875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [95.38706054687499, 36.541650390625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.927},
                    "s": [-30.88212890625, 116.73388671875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.604},
                    "i": {"x": 0.833, "y": 1.52},
                    "s": [54.371044921875, 54.150830078125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [44.036962890625, 26.876220703125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.54},
                    "i": {"x": 0.833, "y": 0.588},
                    "s": [118.809912109375, 18.764306640624998, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 1.055},
                    "s": [108.81015624999999, 17.28486328125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [69.353662109375, 21.158642578125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.881},
                    "s": [135.07177734375, -17.148730468750003, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.276},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [70.050341796875, -57.383984375000004, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -1.801},
                    "i": {"x": 0.833, "y": 3.321},
                    "s": [41.8568359375, -30.295556640624998, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [43.104052734374996, -6.0018554687500005, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.256},
                    "i": {"x": 0.833, "y": 1.331},
                    "s": [7.1189453125, 74.85302734375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [-10.233984375, 96.808447265625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 1.482},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [75.99013671875, 130.89970703125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.163},
                    "s": [81.129150390625, 119.48056640625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 1.082},
                    "s": [86.0279296875, 107.574951171875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [130.33916015625, -2.5845214843749997, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [42.235205078125006, 11.457177734375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.569},
                    "s": [536.33125, 617.043994140625, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.761},
                    "s": [535.580517578125, 612.431494140625, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [541.202001953125, 592.8644042968749, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [562.35263671875, 561.0513671875, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [589.9275390624999, 528.425537109375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [615.1281250000001, 500.770556640625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [636.3868652343749, 477.80615234375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [654.2562988281251, 458.277099609375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [669.44111328125, 441.21044921875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [682.46982421875, 425.96157226562497, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [693.738818359375, 412.05400390625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [703.520361328125, 399.175439453125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [711.9826171875, 387.171728515625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [719.211669921875, 376.01484375, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [725.267578125, 365.58867187500005, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [730.174365234375, 355.80712890625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [733.918017578125, 346.624169921875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [736.4625, 338.039794921875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [737.77177734375, 330.0740234375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [737.8418457031249, 322.852978515625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [736.7748046875, 316.50078125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [734.79287109375, 311.103515625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [732.184326171875, 306.651171875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [729.2194335937501, 303.05166015624997, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [726.1083984375, 300.1728515625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [722.97734375, 297.880615234375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [719.896337890625, 296.054833984375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [716.909423828125, 294.607421875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [714.0486328124999, 293.464306640625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [711.327978515625, 292.571435546875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [708.757470703125, 291.882763671875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [706.343115234375, 291.36025390625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [704.0949218750001, 290.96987304687497, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [702.0208984375, 290.689599609375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [700.1310546875, 290.49140625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [698.44541015625, 290.35927734375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [696.96796875, 290.26918945312497, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [695.712744140625, 290.201123046875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.196},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [694.699755859375, 290.141064453125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.218},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [693.9550292968751, 290.068994140625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [693.502587890625, 289.96889648437497, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [693.284375, 289.804736328125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [693.14423828125, 289.57851562499997, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [692.916015625, 289.35029296875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [692.59970703125, 289.12607421875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [692.2033203125001, 288.90786132812497, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [691.73486328125, 288.695654296875, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [691.2023437500001, 288.495458984375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [690.61376953125, 288.303271484375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [689.97314453125, 288.12509765625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [689.2884765625, 287.958935546875, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [688.565771484375, 287.8087890625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [687.8150390625, 287.670654296875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [687.040283203125, 287.54853515624995, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [686.24951171875, 287.442431640625, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [685.4467285156251, 287.350341796875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 4}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 9
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 31}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 47
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 20
    }, {
        "ty": 4,
        "nm": "Shape Layer 31",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.272},
                    "s": [176.598291015625, 196.27548828125, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [197.53471679687502, 99.94150390624999, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": -0.333},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [108.30166015625, 143.449951171875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.111},
                    "i": {"x": 0.833, "y": 1.856},
                    "s": [126.157080078125, 211.07192382812502, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [118.485595703125, 238.74091796875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.071},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [204.919921875, 210.623486328125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [158.28642578125002, 143.76225585937502, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.579},
                    "i": {"x": 0.833, "y": 1.893},
                    "s": [204.169189453125, 232.0423828125, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [211.88671875, 227.804248046875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.042},
                    "i": {"x": 0.833, "y": 1.033},
                    "s": [121.49453125000001, 89.917724609375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.024},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [181.647216796875, 123.90888671875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [97.3669921875, 213.274072265625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.867},
                    "i": {"x": 0.833, "y": 1.029},
                    "s": [221.8244140625, 209.73662109375002, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 1.309},
                    "s": [210.90576171875, 182.31186523437498, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [225.67216796875, 204.97597656250002, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [156.194384765625, 237.525732421875, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.493},
                    "i": {"x": 0.833, "y": 0.73},
                    "s": [247.23920898437498, 257.74746093749997, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.12},
                    "i": {"x": 0.833, "y": 0.684},
                    "s": [234.0763671875, 165.347314453125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [204.557568359375, 191.02236328125002, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.886},
                    "s": [122.30532226562501, 263.639208984375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.309},
                    "i": {"x": 0.833, "y": 0.995},
                    "s": [195.700927734375, 158.704833984375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.561},
                    "s": [222.755322265625, 102.44794921875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [197.38657226562498, 170.91474609374998, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.031},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [89.197021484375, 136.14482421875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.425},
                    "i": {"x": 0.833, "y": 1.03},
                    "s": [167.74765625, 132.75151367187502, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 1.079},
                    "s": [186.91435546875, 105.973388671875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [160.858935546875, 156.10830078125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.24},
                    "i": {"x": 0.833, "y": 1.459},
                    "s": [211.67851562500002, 93.32504882812499, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [238.694873046875, 133.32407226562498, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -4.839},
                    "i": {"x": 0.833, "y": -0.338},
                    "s": [62.727197265624994, 136.459130859375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [65.706103515625, 127.1560546875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -1.049},
                    "i": {"x": 0.833, "y": 0.316},
                    "s": [110.565869140625, 70.38266601562499, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.095},
                    "i": {"x": 0.833, "y": 1.082},
                    "s": [107.262646484375, 87.58544921875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [83.44340820312499, 19.89541015625, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [130.80361328125, 50.67744140625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 1.135},
                    "s": [78.662744140625, 70.52480468750001, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [143.958447265625, 44.949853515625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.192},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [-27.47080078125, 100.323876953125, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.78},
                    "s": [24.353759765625, 109.96328125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [72.16440429687499, 14.037695312499999, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.369},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [150.693017578125, -22.185644531250002, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.024},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [136.23291015625, 35.528662109375, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.033},
                    "i": {"x": 0.833, "y": 0.142},
                    "s": [147.43984375, 64.69111328125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.915},
                    "s": [139.41801757812502, -31.572802734375003, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 4.378},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [64.837255859375, -48.671484375000006, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -3.24},
                    "i": {"x": 0.833, "y": 216.656},
                    "s": [63.389843750000004, -45.460351562499994, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [63.42587890625, 0.8388183593749999, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.235},
                    "i": {"x": 0.833, "y": 0.616},
                    "s": [-30.519775390625, 53.666357421875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [-5.9197753906249995, 79.759814453125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.581},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [82.8728515625, 183.50703125, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.15},
                    "i": {"x": 0.833, "y": 1.754},
                    "s": [97.75136718750001, 96.74838867187499, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [92.44619140625, 49.490283203125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.871},
                    "s": [145.7982421875, 16.478076171875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.235},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [46.89375, 21.006494140625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [-7.545361328125, 48.639453124999996, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.6},
                    "s": [549.7683593749999, 613.084130859375, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [548.385009765625, 605.9972167968749, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [539.87470703125, 579.8136718750001, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [522.18544921875, 532.80380859375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [500.63642578125, 481.367626953125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [480.326611328125, 437.140478515625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [462.224951171875, 400.809033203125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [446.06118164062497, 370.72568359375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [431.44091796875, 345.406982421875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [418.05185546875, 323.80791015625005, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [405.6677734375, 305.18173828125003, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [394.12050781249997, 288.995947265625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [383.28994140625, 274.850146484375, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [373.083984375, 262.44404296875, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [363.43857421875, 251.54140625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [354.3056640625, 241.948046875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [345.64921875, 233.5078125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [337.451220703125, 226.09257812500002, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [329.69565429687503, 219.59223632812498, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [322.37451171875, 213.906689453125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [315.48779296875, 208.95185546874998, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [309.035498046875, 204.645654296875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [303.021630859375, 200.9240234375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [297.456201171875, 197.71889648437502, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [292.355224609375, 194.97822265625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [287.726708984375, 192.64794921875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [283.45654296875, 190.6740234375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [279.4806640625, 189.010400390625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [275.78706054687495, 187.613037109375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [272.3697265625, 186.44189453125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [269.22265625, 185.466943359375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [266.33583984375, 184.65615234375, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [263.703271484375, 183.981494140625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [261.31494140625, 183.42294921875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [259.166845703125, 182.95849609375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [257.248974609375, 182.572119140625, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [255.55732421874998, 182.251806640625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [254.081884765625, 181.98354492187502, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [252.820654296875, 181.759326171875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [251.767626953125, 181.57314453125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [250.918798828125, 181.42099609375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [250.1640625, 181.270849609375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [249.40131835937498, 181.098681640625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [248.6345703125, 180.9044921875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [247.87382812500002, 180.690283203125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [247.12109375, 180.45805664062502, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [246.386376953125, 180.209814453125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [245.673681640625, 179.949560546875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [244.99101562500002, 179.67729492187502, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [244.348388671875, 179.401025390625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [243.747802734375, 179.12275390625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [243.203271484375, 178.848486328125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [242.718798828125, 178.58222656249998, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [242.306396484375, 178.327978515625, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [241.970068359375, 178.09375, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 21
    }, {
        "ty": 4,
        "nm": "Shape Layer 30",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [124.443408203125, 193.27456054687502, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.13},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [200.50761718750002, 224.83134765625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.78},
                    "i": {"x": 0.833, "y": -0.255},
                    "s": [170.840673828125, 143.56806640625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [167.29121093749998, 223.828369140625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 2.881},
                    "i": {"x": 0.833, "y": 3.317},
                    "s": [117.36650390624999, 201.19428710937498, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [115.87905273437501, 152.470751953125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.051},
                    "i": {"x": 0.833, "y": 1.128},
                    "s": [158.708837890625, 139.510107421875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [132.210986328125, 114.99619140625, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.092},
                    "i": {"x": 0.833, "y": 1.189},
                    "s": [199.564697265625, 191.392724609375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [167.485400390625, 227.347802734375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [272.295654296875, 222.296875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.873},
                    "i": {"x": 0.833, "y": 0.746},
                    "s": [147.70610351562502, 232.3466796875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [158.55869140625, 207.664599609375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.055},
                    "i": {"x": 0.833, "y": 1.027},
                    "s": [180.742333984375, 200.60971679687498, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [167.38730468749998, 167.8037109375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.603},
                    "s": [185.082568359375, 236.857080078125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.909},
                    "s": [198.723876953125, 179.937548828125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 1.02},
                    "i": {"x": 0.833, "y": 3.481},
                    "s": [249.987890625, 52.05078125, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [254.54833984375, 179.06669921875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -2.332},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [114.24946289062501, 158.917041015625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.572},
                    "s": [119.08818359374999, 89.06689453125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.638},
                    "s": [123.498486328125, 106.81220703125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [141.73828125, 133.52626953125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 1.094},
                    "s": [202.7337890625, 140.5291015625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.89},
                    "s": [272.09345703124995, 124.66562499999999, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.345},
                    "i": {"x": 0.833, "y": 1.08},
                    "s": [124.315283203125, 126.47138671875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [77.23134765625001, 178.92055664062502, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.213},
                    "i": {"x": 0.833, "y": 0.898},
                    "s": [169.63349609374998, 161.49355468750002, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.457},
                    "i": {"x": 0.833, "y": 1.645},
                    "s": [143.610107421875, 161.203271484375, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [137.79843749999998, 40.581591796875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.053},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [188.610009765625, 126.505419921875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.065},
                    "i": {"x": 0.833, "y": 1.499},
                    "s": [157.643798828125, 141.44399414062502, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [175.01474609375, 125.69462890625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 1.658},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [53.518212890625, 89.281103515625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.609},
                    "i": {"x": 0.833, "y": 8.954},
                    "s": [47.087939453125, 130.985791015625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [46.066943359374996, 97.09672851562499, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.407},
                    "i": {"x": 0.833, "y": 1.153},
                    "s": [144.46494140625, 28.277587890625, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [127.726611328125, 94.348046875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.742},
                    "s": [175.16689453125002, 169.62548828125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.123},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [130.88369140625, 26.932275390624998, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.497},
                    "i": {"x": 0.833, "y": 0.536},
                    "s": [38.091162109375, 32.858056640625, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 1.076},
                    "s": [51.42216796875, 5.947802734375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [112.3416015625, 58.5291015625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.906},
                    "i": {"x": 0.833, "y": 0.444},
                    "s": [-3.781689453125, 33.198388671874994, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [5.9978515625, 18.91845703125, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.017},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [61.493994140625, 141.78232421875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.111},
                    "i": {"x": 0.833, "y": 0.484},
                    "s": [15.182812499999999, 76.506640625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [35.026171874999996, -13.639306640625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.143},
                    "i": {"x": 0.833, "y": 0.773},
                    "s": [138.00664062500002, 40.92392578125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.132},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [100.145703125, 22.95439453125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.027},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [34.996142578125, 58.064648437500004, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [84.3623046875, 138.91752929687502, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.613},
                    "s": [547.5021484375, 601.8191406249999, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [546.6212890625, 598.593994140625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [542.76552734375, 587.04072265625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [534.96591796875, 566.402587890625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [524.51572265625, 544.31904296875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [511.15068359375, 528.0111328125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [495.777685546875, 532.22724609375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [488.25234375, 545.2939941406249, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [483.417626953125, 557.37578125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [479.7400390625, 568.116259765625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [476.687060546875, 577.6916015625001, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [474.024462890625, 586.253955078125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [471.610107421875, 593.9474609375001, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [469.35791015625, 600.884228515625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [467.205810546875, 607.142333984375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [465.11376953125, 612.79384765625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [463.045751953125, 617.89482421875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [460.971728515625, 622.4893066406249, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [458.87568359375, 626.60732421875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [456.73759765625, 630.27490234375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [454.541455078125, 633.5160644531251, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [452.28525390625, 636.3328125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [449.9669921875, 638.733154296875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [447.588671875, 640.723095703125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [445.152294921875, 642.312646484375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [442.66787109374997, 643.513818359375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [440.17343750000003, 644.32861328125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [437.721044921875, 644.7810546875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [435.35073242187497, 644.923193359375, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [433.096533203125, 644.815087890625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [430.97246093750005, 644.52080078125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [428.99453125, 644.098388671875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [427.17275390624997, 643.5999023437499, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [425.50712890625, 643.065380859375, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [423.99365234375, 642.5248535156251, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [422.64033203125, 642.0043457031251, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [421.453173828125, 641.52587890625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [420.40415039062503, 641.10146484375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [419.339111328125, 640.7190917968751, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [418.262060546875, 640.3867675781249, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [417.191015625, 640.114501953125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [416.10595703125, 639.88427734375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [414.97885742187503, 639.680078125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [413.83173828125, 639.50791015625, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [412.6826171875, 639.3717773437501, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [411.54951171875, 639.27568359375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [410.4484375, 639.21962890625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [409.389404296875, 639.2076171875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [408.388427734375, 639.2396484375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [407.45351562499997, 639.317724609375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [406.59467773437495, 639.441845703125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [405.819921875, 639.610009765625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 22
    }, {
        "ty": 4,
        "nm": "Shape Layer 29",
        "mn": "",
        "sr": 1,
        "st": 12,
        "op": 60,
        "ip": 12,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.332},
                    "s": [129.26611328125, 171.030859375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.095},
                    "i": {"x": 0.833, "y": 0.875},
                    "s": [140.02060546874998, 218.118798828125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.249},
                    "i": {"x": 0.833, "y": 1.206},
                    "s": [215.424169921875, 65.726123046875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 0.969},
                    "s": [253.30913085937502, 121.588623046875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.048},
                    "i": {"x": 0.833, "y": 1},
                    "s": [121.656689453125, 171.677490234375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [204.97998046875, 219.8865234375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.877},
                    "i": {"x": 0.833, "y": 1.333},
                    "s": [121.892919921875, 248.75869140625, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [113.16840820312501, 166.95087890625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.133},
                    "i": {"x": 0.833, "y": 1.322},
                    "s": [156.7208984375, 194.711962890625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [139.9205078125, 149.04541015625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.033},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [221.6642578125, 177.527197265625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.984},
                    "i": {"x": 0.833, "y": 0.066},
                    "s": [163.06708984374998, 222.070654296875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.003},
                    "s": [167.641552734375, 80.56259765624999, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.002},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [214.33110351562502, 123.226220703125, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.206},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [166.21015625, 113.04228515625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.217},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [133.39013671875, 66.538916015625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 1.158},
                    "s": [112.98422851562499, 115.1923828125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [96.89052734375001, 143.634130859375, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.218},
                    "i": {"x": 0.833, "y": 0.754},
                    "s": [143.56005859375, 163.4634765625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 1.096},
                    "s": [172.342138671875, 80.044091796875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.99},
                    "s": [228.372802734375, 104.34580078125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.012},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [107.60698242187499, 172.402197265625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.077},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [213.70849609375, 264.03759765625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.532},
                    "i": {"x": 0.833, "y": 0.204},
                    "s": [158.58671875, 212.831640625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 0.915},
                    "s": [166.05, 134.88759765625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 3.696},
                    "i": {"x": 0.833, "y": 0.454},
                    "s": [229.87626953125, 195.65087890625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 1.872},
                    "s": [231.34970703125, 215.58833007812498, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [239.527685546875, 209.85673828125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.17},
                    "i": {"x": 0.833, "y": 1.157},
                    "s": [145.808251953125, 152.8291015625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [176.622314453125, 145.7822265625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.07},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [87.92978515624999, 117.18833007812499, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.193},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [135.986669921875, 135.081787109375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.3},
                    "i": {"x": 0.833, "y": 1.471},
                    "s": [172.418212890625, 141.241796875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [186.407861328125, 224.985498046875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.633},
                    "i": {"x": 0.833, "y": 0.526},
                    "s": [93.323046875, 93.781494140625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 1.045},
                    "s": [79.20327148437501, 121.5666015625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.969},
                    "s": [13.062744140625, 89.08891601562499, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.05},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [115.06025390625, 162.892919921875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.48},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [51.08583984375, 64.85126953125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 1.966},
                    "s": [60.549072265625, 62.567041015625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [67.63198242187501, 74.398583984375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -1.48},
                    "i": {"x": 0.833, "y": -0.996},
                    "s": [-21.547021484375, 88.7826171875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [-16.794384765624997, 19.729248046875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 1.177},
                    "i": {"x": 0.833, "y": 1.194},
                    "s": [92.272021484375, 70.83310546874999, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 1.01},
                    "s": [100.578125, 26.255615234375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [72.9271484375, 59.095654296875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.252},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [103.9013671875, 127.554443359375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [119.186279296875, 75.11328125, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.577},
                    "s": [542.194970703125, 612.515576171875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [541.137939453125, 608.511669921875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [536.437353515625, 592.3198730468749, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [531.01806640625, 561.7860839843751, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [552.857373046875, 546.3089843749999, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [579.44130859375, 560.212548828125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [600.263623046875, 573.8478515625, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [617.434375, 585.521240234375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [632.0145996093751, 595.3408203125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [644.638916015625, 603.566845703125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [655.73974609375, 610.4515625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [665.609375, 616.18916015625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [674.456005859375, 620.923779296875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [682.42978515625, 624.771533203125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [689.650830078125, 627.822509765625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [696.221240234375, 630.152783203125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [702.20908203125, 631.822412109375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [707.6784179687501, 632.8854492187501, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [712.62724609375, 633.3699218749999, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [717.037548828125, 633.295849609375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [720.93935546875, 632.7192871093749, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [724.3346679687501, 631.7083007812499, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [727.239501953125, 630.3509765625, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [729.69189453125, 628.743408203125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [731.7298828124999, 626.977685546875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [733.405517578125, 625.147900390625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [734.774853515625, 623.3201171875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [735.899951171875, 621.556396484375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [736.832861328125, 619.8927734375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [737.621630859375, 618.357275390625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [738.306298828125, 616.96591796875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [738.914892578125, 615.7287109375001, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [739.475439453125, 614.653662109375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [740.00595703125, 613.740771484375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [740.520458984375, 612.9860351562501, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [741.02294921875, 612.389453125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [741.51943359375, 611.94501953125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [742.0119140625, 611.64072265625, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [742.494384765625, 611.47255859375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [742.9588378906251, 611.434521484375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [743.39326171875, 611.52060546875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [743.8176757812499, 611.662744140625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [744.2420898437499, 611.79287109375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [744.65048828125, 611.908984375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [745.016845703125, 612.0050781250001, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.204},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [745.317138671875, 612.0791503906249, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.285},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [745.525341796875, 612.1251953125001, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [745.613427734375, 612.13720703125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 17
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 39}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 55
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 23
    }, {
        "ty": 4,
        "nm": "Shape Layer 28",
        "mn": "",
        "sr": 1,
        "st": 3,
        "op": 60,
        "ip": 3,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [189.58896484375, 245.549560546875, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.6},
                    "i": {"x": 0.833, "y": 1.272},
                    "s": [254.826611328125, 260.20786132812503, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.732},
                    "s": [265.348876953125, 171.32915039062502, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [220.47109375, 100.15571289062501, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": -0.066},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [121.17021484375, 270.982373046875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.209},
                    "i": {"x": 0.833, "y": 1.066},
                    "s": [176.506201171875, 273.23056640625, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.966},
                    "s": [213.18798828125, 221.29990234375, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.056},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [147.385791015625, 114.56577148437499, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.27},
                    "i": {"x": 0.833, "y": 1.484},
                    "s": [186.65009765624998, 134.649365234375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [177.3890625, 252.80463867187498, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.221},
                    "i": {"x": 0.833, "y": 1.185},
                    "s": [240.3865234375, 105.9033203125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [278.46967773437495, 138.47309570312498, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 6.732},
                    "i": {"x": 0.833, "y": -1.441},
                    "s": [155.9681640625, 160.346435546875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.941},
                    "s": [154.43266601562502, 215.580322265625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.198},
                    "i": {"x": 0.833, "y": 0.598},
                    "s": [110.98227539062499, 280.047216796875, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [123.834814453125, 205.466455078125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.337},
                    "i": {"x": 0.833, "y": 0.557},
                    "s": [172.92470703125, 154.01625976562502, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 1.048},
                    "s": [189.05244140624998, 187.49492187500002, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [258.604296875, 158.913037109375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.385},
                    "i": {"x": 0.833, "y": 1.003},
                    "s": [149.3857421875, 253.521337890625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.003},
                    "i": {"x": 0.833, "y": 0.783},
                    "s": [119.172265625, 193.99326171875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.987},
                    "s": [150.472802734375, 143.69619140625002, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.016},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [200.66777343750002, 99.37294921875, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.026},
                    "i": {"x": 0.833, "y": 1.196},
                    "s": [158.52065429687502, 106.0794921875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [190.7060546875, 167.23916015625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.251},
                    "i": {"x": 0.833, "y": 0.998},
                    "s": [82.722705078125, 184.197705078125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.002},
                    "i": {"x": 0.833, "y": 1.333},
                    "s": [109.63095703125, 165.055029296875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.996},
                    "s": [83.25322265625, 55.7583984375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.004},
                    "i": {"x": 0.833, "y": 0.941},
                    "s": [215.039794921875, 103.5490234375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.201},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [88.912744140625, 118.45957031249999, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.19},
                    "i": {"x": 0.833, "y": 0.637},
                    "s": [125.864794921875, 241.51762695312502, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [114.60380859375, 127.850732421875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 2.63},
                    "i": {"x": 0.833, "y": 3.767},
                    "s": [76.8189453125, 101.5150390625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [75.58173828125, 138.25288085937498, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [117.875, 171.65947265625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.007},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [166.162109375, 111.80107421874999, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.184},
                    "i": {"x": 0.833, "y": 1.235},
                    "s": [121.5986328125, 59.752294921875006, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.881},
                    "s": [135.46816406250002, 117.1142578125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.279},
                    "i": {"x": 0.833, "y": 1.257},
                    "s": [82.53452148437499, 2.6866210937500004, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.063},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [60.002539062500006, 68.164501953125, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 2.356},
                    "i": {"x": 0.833, "y": 3.558},
                    "s": [152.142431640625, 82.974951171875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [155.51972656249998, 69.87216796875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -1.086},
                    "i": {"x": 0.833, "y": 1.665},
                    "s": [48.451269531250006, 65.569970703125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [56.0787109375, 57.351953125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [-12.4521484375, 78.610693359375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.024},
                    "i": {"x": 0.833, "y": 1},
                    "s": [61.54404296875, 149.856201171875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [4.0499511718750005, 100.772314453125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.326},
                    "i": {"x": 0.833, "y": 0.727},
                    "s": [61.60009765625, 138.93955078125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.12},
                    "i": {"x": 0.833, "y": 0.902},
                    "s": [49.888671875, 49.05185546875, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.55},
                    "i": {"x": 0.833, "y": 2.108},
                    "s": [23.244677734375003, 13.106787109374999, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [18.488037109375, 68.384716796875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.138},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [86.486376953125, 73.62783203125, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [60.917431640625, 74.456640625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.039},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [32.66787109375, 87.621484375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.6},
                    "i": {"x": 0.833, "y": -0.408},
                    "s": [51.912646484375, 103.61508789062499, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [55.015673828125, 81.189208984375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [104.3578125, 40.375390625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.613},
                    "s": [541.658447265625, 611.370458984375, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [539.5624023437499, 605.760986328125, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [530.5155761718751, 585.929638671875, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [505.138818359375, 556.132568359375, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [463.5802734375, 545.90859375, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [427.61518554687495, 557.874267578125, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [401.4236328125, 575.755712890625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [381.986669921875, 594.113623046875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [367.21025390625, 611.618701171875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [355.84116210937503, 628.0207031250001, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [347.12666015625, 643.331640625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [340.60029296874995, 657.6295898437501, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [335.93974609375005, 670.9345703125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [332.93681640625005, 683.280615234375, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [331.453369140625, 694.627685546875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [331.3552734375, 704.93173828125, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [332.50039062499997, 714.1767578125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [334.704541015625, 722.30068359375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [337.723486328125, 729.257470703125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [341.29697265625, 735.0951660156251, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [345.19677734375, 739.921875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [349.234716796875, 743.8737304687501, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [353.27265625, 747.08486328125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [357.2205078125, 749.681396484375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [361.026220703125, 751.7734375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [364.66176757812497, 753.453076171875, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [368.10712890625, 754.786376953125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [371.35429687500005, 755.835400390625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [374.397265625, 756.640185546875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [377.238037109375, 757.2427734375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [379.87460937500003, 757.669189453125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [382.30498046875, 757.945458984375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [384.5271484375, 758.0936035156251, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [386.535107421875, 758.131640625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [388.320849609375, 758.07158203125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [389.87436523437503, 757.9314453125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [391.191650390625, 757.7232421875001, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [392.260693359375, 757.4609863281249, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [393.065478515625, 757.156689453125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.206},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [393.585986328125, 756.818359375, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [393.802197265625, 756.4620117187501, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [393.78818359375, 756.10166015625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [393.770166015625, 755.665234375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [393.802197265625, 755.1427246093749, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [393.88828125000003, 754.54814453125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [394.030419921875, 753.8955078125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [394.230615234375, 753.200830078125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [394.490869140625, 752.4761230468749, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [394.815185546875, 751.7354003906249, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [395.19956054687503, 750.986669921875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [395.64599609375, 750.2439453124999, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [396.152490234375, 749.515234375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [396.71904296875, 748.810546875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [397.34165039062503, 748.1358886718749, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [398.01630859375, 747.499267578125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [398.739013671875, 746.9046875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [399.507763671875, 746.3601562499999, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 3}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 8
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 30}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 46
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 24
    }, {
        "ty": 4,
        "nm": "Shape Layer 27",
        "mn": "",
        "sr": 1,
        "st": 1,
        "op": 60,
        "ip": 1,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": -0.282},
                    "s": [144.48295898437502, 205.8568359375, 100],
                    "t": 1
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [151.193505859375, 226.210693359375, 100],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.304},
                    "i": {"x": 0.833, "y": 1.019},
                    "s": [247.7296875, 307.5720703125, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.693},
                    "s": [284.127197265625, 188.0154296875, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.114},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [239.407568359375, 130.40522460937498, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": -0.276},
                    "i": {"x": 0.833, "y": 0.769},
                    "s": [119.4525390625, 210.611474609375, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 1.003},
                    "s": [147.253662109375, 243.0611328125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.003},
                    "i": {"x": 0.833, "y": 1.032},
                    "s": [196.53374023437502, 251.871728515625, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [145.427880859375, 133.980712890625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.315},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [216.3791015625, 117.2423828125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.612},
                    "i": {"x": 0.833, "y": 1.479},
                    "s": [241.95405273437498, 234.965234375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [245.98798828124998, 161.619677734375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [218.747412109375, 206.841796875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 12.53},
                    "i": {"x": 0.833, "y": 3.633},
                    "s": [168.82871093749998, 226.75322265625002, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.72},
                    "s": [168.494384765625, 203.926953125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.119},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [179.389013671875, 273.71503906249995, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.007},
                    "i": {"x": 0.833, "y": 0.905},
                    "s": [205.058056640625, 244.320361328125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.667},
                    "i": {"x": 0.833, "y": 2.566},
                    "s": [181.48305664062502, 123.71669921875001, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 1.025},
                    "s": [178.11376953125, 166.848779296875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.019},
                    "i": {"x": 0.833, "y": 0.879},
                    "s": [244.80283203125, 156.794970703125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.27},
                    "i": {"x": 0.833, "y": 1.099},
                    "s": [157.74589843750002, 204.461474609375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.927},
                    "s": [118.83793945312499, 224.493017578125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.587},
                    "i": {"x": 0.833, "y": 1.136},
                    "s": [204.20322265624998, 226.42890625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [193.59687499999998, 128.75761718750002, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.183},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [221.51611328125, 197.008203125, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.252},
                    "s": [212.76557617187498, 193.7650390625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [204.86186523437502, 242.250341796875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.205},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [141.82236328125, 182.231787109375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.063},
                    "i": {"x": 0.833, "y": 1.045},
                    "s": [98.482080078125, 103.60908203125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [123.234228515625, 115.264453125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 1.115},
                    "s": [85.121044921875, 160.626708984375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.048},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [39.052099609375006, 124.809765625, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.45},
                    "i": {"x": 0.833, "y": 0.463},
                    "s": [148.947314453125, 93.53125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [131.79057617187502, 62.430908203125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.12},
                    "i": {"x": 0.833, "y": 0.744},
                    "s": [38.4935546875, 104.311767578125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [76.768896484375, 136.78544921875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 1.166},
                    "i": {"x": 0.833, "y": 1.615},
                    "s": [155.878076171875, 77.9880859375, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [161.96601562499998, 3.7076171875000004, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [110.92822265625, 139.341943359375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 2.748},
                    "i": {"x": 0.833, "y": 4.375},
                    "s": [63.820263671875004, 12.486181640625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [62.346826171875, 23.01044921875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.59},
                    "i": {"x": 0.833, "y": 1.257},
                    "s": [123.478466796875, 112.48974609375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.063},
                    "i": {"x": 0.833, "y": 0.875},
                    "s": [133.536279296875, -30.85810546875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.252},
                    "i": {"x": 0.833, "y": 0.777},
                    "s": [92.420166015625, 64.57099609375, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.133},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [72.09033203125, -18.452001953125002, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [37.991064453125, 34.065234374999996, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [101.26679687500001, 86.950830078125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [6.40625, 100.447998046875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.121},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [64.01845703125001, 145.315771484375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [40.489501953125, 86.65053710937501, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.084},
                    "i": {"x": 0.833, "y": 0.531},
                    "s": [14.8625, -74.91909179687501, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [27.648974609375, 56.981591796875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.872},
                    "i": {"x": 0.833, "y": -0.207},
                    "s": [86.76064453125001, 85.01694335937499, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.09},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [81.601611328125, 95.715380859375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.092},
                    "i": {"x": 0.833, "y": 1.074},
                    "s": [12.023730468750001, 120.655712890625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [45.04794921875, 112.65590820312501, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.12},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [-17.354931640625, 120.287353515625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [8.282080078124999, 19.196728515625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [37.068164062499996, 109.717041015625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.551},
                    "s": [529.682763671875, 607.38056640625, 0],
                    "t": 1
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [531.5065429687501, 603.670947265625, 0],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [538.475341796875, 586.92060546875, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [547.86650390625, 554.494970703125, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [555.83427734375, 517.731103515625, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [562.6369140625, 485.675830078125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [569.325439453125, 459.242041015625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [576.400341796875, 437.4748046875, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [584.059814453125, 419.4251953125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [592.323876953125, 404.46860351562503, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [601.1144531250001, 392.16259765625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [610.259375, 382.17685546875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [619.550439453125, 374.199072265625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [628.7654296875, 367.93095703125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [637.7241699218749, 363.05820312500003, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [646.29453125, 359.29052734375, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [654.3964355468751, 356.373681640625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [661.979833984375, 354.10146484374997, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [669.026708984375, 352.309716796875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [675.5470703125001, 350.8703125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [681.5529296875001, 349.68515625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [687.0623046875, 348.68818359375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [692.08720703125, 347.82734375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [696.641650390625, 347.072607421875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [700.73564453125, 346.40195312500003, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [704.381201171875, 345.8013671875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [707.58232421875, 345.27084960937503, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [710.347021484375, 344.810400390625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [712.7313476562499, 344.3759765625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [714.90947265625, 343.8154296875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [716.905419921875, 343.1427734375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [718.731201171875, 342.37802734375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [720.39482421875, 341.539208984375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [721.90830078125, 340.650341796875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [723.2736328125, 339.72744140625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [724.498828125, 338.79052734375, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [725.593896484375, 337.855615234375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [726.5628417968751, 336.94072265625005, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [727.4056640625, 336.05986328125005, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [728.124365234375, 335.223046875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [728.7189453125001, 334.444287109375, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [729.26748046875, 333.743603515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [729.83603515625, 333.135009765625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [730.424609375, 332.628515625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [731.02119140625, 332.226123046875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [731.623779296875, 331.929833984375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [732.2223632812501, 331.737646484375, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [732.8109375, 331.65156249999995, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [733.381494140625, 331.667578125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [733.926025390625, 331.77768554687503, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [734.4365234375, 331.977880859375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [734.90498046875, 332.25615234375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [735.3213867187501, 332.6044921875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [735.679736328125, 333.01088867187497, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [735.968017578125, 333.45932617187503, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [736.17822265625, 333.9337890625, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [736.300341796875, 334.416259765625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [736.324365234375, 334.88671875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [736.2703125, 335.3431640625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 1}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 6
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 28}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 44
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 25
    }, {
        "ty": 4,
        "nm": "Shape Layer 26",
        "mn": "",
        "sr": 1,
        "st": 4,
        "op": 60,
        "ip": 4,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [195.008251953125, 132.239013671875, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": -0.148},
                    "i": {"x": 0.833, "y": 0.753},
                    "s": [89.221044921875, 152.442724609375, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 1.004},
                    "s": [127.350244140625, 146.25068359375, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.004},
                    "i": {"x": 0.833, "y": 0.971},
                    "s": [202.08916015625, 195.45869140625, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.045},
                    "i": {"x": 0.833, "y": 0.792},
                    "s": [123.730712890625, 140.511083984375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [174.784521484375, 129.35019531249998, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.12},
                    "i": {"x": 0.833, "y": 0.68},
                    "s": [251.21909179687498, 223.4580078125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.883},
                    "s": [219.84648437500002, 208.349267578125, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.289},
                    "i": {"x": 0.833, "y": 1.123},
                    "s": [130.773583984375, 209.58247070312498, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [94.5962890625, 268.33779296875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.445},
                    "i": {"x": 0.833, "y": 0.754},
                    "s": [184.013525390625, 217.742431640625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [204.5916015625, 280.91806640625, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.059},
                    "i": {"x": 0.833, "y": 0.598},
                    "s": [244.86689453124998, 220.96357421874998, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [221.3359375, 124.43540039062499, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.102},
                    "i": {"x": 0.833, "y": 0.992},
                    "s": [131.450244140625, 155.09130859375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.009},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [171.909716796875, 130.769580078125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.062},
                    "i": {"x": 0.833, "y": 0.389},
                    "s": [135.56025390625, 99.931494140625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 0.983},
                    "s": [156.382568359375, 195.244482421875, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.021},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [288.22919921874995, 226.81728515625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.034},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [182.588134765625, 161.91396484375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 1.004},
                    "s": [257.31904296875, 236.152392578125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.004},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [163.825830078125, 149.80815429687502, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.245},
                    "i": {"x": 0.833, "y": 0.701},
                    "s": [261.4630859375, 208.105029296875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [236.7109375, 235.399658203125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [172.538330078125, 183.2587890625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.857},
                    "i": {"x": 0.833, "y": 2.239},
                    "s": [119.72880859374999, 162.9029296875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 1.06},
                    "s": [124.409375, 203.956982421875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.035},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [50.154931640625, 124.0169921875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.64},
                    "i": {"x": 0.833, "y": 1.113},
                    "s": [177.851513671875, 128.351220703125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.048},
                    "i": {"x": 0.833, "y": 0.878},
                    "s": [196.97016601562498, 120.20927734375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.265},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [151.98427734375002, 90.6484375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.487},
                    "s": [131.330126953125, 96.81645507812499, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.1},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [145.283740234375, 63.52197265625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [217.17587890625, 53.718408203124994, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [128.255126953125, 150.03837890625, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.226},
                    "i": {"x": 0.833, "y": 0.807},
                    "s": [45.632519531250004, 51.42216796875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 1.039},
                    "s": [67.90224609375, 12.826513671875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [97.172802734375, 107.891259765625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.114},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [54.09677734375, -19.697216796875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 1.415},
                    "s": [72.30654296875001, 62.783251953125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [88.44829101562499, -21.134619140625002, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [-8.081884765625, -8.622412109375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.027},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [136.643310546875, 53.374072265625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.164},
                    "i": {"x": 0.833, "y": 0.863},
                    "s": [27.55888671875, 84.76870117187501, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.213},
                    "i": {"x": 0.833, "y": 1.171},
                    "s": [64.304736328125, 96.976611328125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [87.967822265625, 90.430224609375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.238},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [15.74736328125, -83.683642578125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.767},
                    "i": {"x": 0.833, "y": -0.765},
                    "s": [34.495654296875, -10.832568359375, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [36.781884765625, 73.88608398437499, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.066},
                    "i": {"x": 0.833, "y": 1.047},
                    "s": [82.914892578125, 83.17514648437499, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 1.142},
                    "s": [57.10771484375, 99.33291015625001, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.053},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [97.348974609375, 97.47109375000001, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.344},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [-11.49521484375, 105.05649414062499, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [9.715478515625, 43.408349609375, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.082},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [31.546777343749998, 94.37607421875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [20.5400390625, 36.441552734375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.589},
                    "s": [539.5083496093749, 612.61767578125, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [543.9146484375, 615.7587402343751, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [560.677001953125, 628.8915527343751, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [592.626171875, 651.31943359375, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [629.896533203125, 673.479052734375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [663.5994140625, 690.479638671875, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [692.341455078125, 703.043896484375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [716.865380859375, 712.5111328125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [738.00400390625, 719.836279296875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [756.4099609375, 725.641943359375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [772.671826171875, 730.254443359375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [787.234033203125, 733.893994140625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [800.36484375, 736.77880859375, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [812.2744628906249, 739.06904296875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [823.123046875, 740.88681640625, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [833.042724609375, 742.334228515625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [842.135595703125, 743.48935546875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [850.4877441406251, 744.4142578125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [858.1732421875, 745.16298828125, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [865.246142578125, 745.777587890625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [871.7584960937501, 746.294091796875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [877.7523437499999, 746.7445312499999, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [883.263720703125, 747.150927734375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [888.320654296875, 747.535302734375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [892.95517578125, 747.911669921875, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [897.1873046875, 748.296044921875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [901.0370605468751, 748.696435546875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [904.52646484375, 749.120849609375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [907.66552734375, 749.569287109375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [910.472265625, 750.049755859375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [912.952685546875, 750.554248046875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [915.122802734375, 751.0847656249999, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [916.9886230468751, 751.63330078125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [918.558154296875, 752.19384765625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [919.83740234375, 752.75439453125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [920.834375, 753.30693359375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [921.5550781249999, 753.83544921875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.2},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [921.99951171875, 754.325927734375, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [922.1776855468751, 754.7603515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [922.089599609375, 755.122705078125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [921.789306640625, 755.491064453125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [921.4169433593751, 755.947509765625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [921.0666015625, 756.492041015625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [920.7502929687499, 757.1166503906251, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [920.4740234375, 757.813330078125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [920.243798828125, 758.570068359375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [920.067626953125, 759.3788574218751, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [919.947509765625, 760.231689453125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [919.8894531249999, 761.1205566406251, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [919.8954589843751, 762.037451171875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [919.967529296875, 762.974365234375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [920.103662109375, 763.923291015625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [920.307861328125, 764.8782226562499, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [920.57412109375, 765.83115234375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [920.90244140625, 766.7740722656249, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [921.288818359375, 767.702978515625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 4}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 9
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 31}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 47
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 26
    }, {
        "ty": 4,
        "nm": "Shape Layer 25",
        "mn": "",
        "sr": 1,
        "st": 10,
        "op": 60,
        "ip": 10,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [221.55415039062498, 232.94326171875, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.239},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [119.67475585937501, 197.206396484375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [65.17958984375, 238.21640625, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.327},
                    "i": {"x": 0.833, "y": 1.283},
                    "s": [146.12255859375, 217.07177734375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [129.678515625, 280.76591796875005, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.643},
                    "i": {"x": 0.833, "y": 1.471},
                    "s": [201.894970703125, 230.34672851562502, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [212.65546875, 129.168017578125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -10.254},
                    "i": {"x": 0.833, "y": 7.839},
                    "s": [141.0576171875, 214.89365234375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 1.07},
                    "s": [141.63618164062498, 179.61923828124998, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [93.68740234375001, 71.427685546875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 1.002},
                    "s": [181.94150390625, 162.65668945312498, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.002},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [282.06318359375, 208.45737304687498, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.196},
                    "i": {"x": 0.833, "y": 1.056},
                    "s": [179.30292968749998, 193.7890625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.034},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [210.006884765625, 238.28847656250002, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.041},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [158.500634765625, 155.8880859375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 1.075},
                    "s": [192.90620117187498, 110.86015624999999, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.864},
                    "s": [225.069580078125, 222.86943359375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.216},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [163.90390625, 159.36748046875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.063},
                    "i": {"x": 0.833, "y": 1.136},
                    "s": [125.47041015625, 210.6294921875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [147.36577148437502, 183.773291015625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [89.85366210937501, 180.45805664062502, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.445},
                    "i": {"x": 0.833, "y": 0.864},
                    "s": [175.066796875, 157.06523437500002, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.216},
                    "i": {"x": 0.833, "y": 0.687},
                    "s": [194.711962890625, 178.06572265625, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.114},
                    "i": {"x": 0.833, "y": 1.172},
                    "s": [207.02197265625, 155.25146484375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 1.025},
                    "s": [240.94306640625, 127.83071289062501, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.019},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [137.08974609375, 82.710693359375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.226},
                    "i": {"x": 0.833, "y": 0.781},
                    "s": [271.96533203125, 125.100048828125, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [235.687939453125, 196.4076171875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [176.694384765625, 123.61860351562501, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.097},
                    "i": {"x": 0.833, "y": 1.161},
                    "s": [119.666748046875, 107.08847656249999, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [145.96640625, 70.462744140625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -1.932},
                    "i": {"x": 0.833, "y": 0.014},
                    "s": [68.76708984375, 53.934619140624996, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.166},
                    "s": [71.960205078125, 72.4947265625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 1.01},
                    "s": [106.5439453125, 51.528271484375004, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [3.229150390625, 21.875341796875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [118.497607421875, 54.29296875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 28.082},
                    "i": {"x": 0.833, "y": 32.334},
                    "s": [48.799609375, 98.2958984375, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [48.59140625, 96.952587890625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.066},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [126.797705078125, 113.66689453125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 1.325},
                    "i": {"x": 0.833, "y": -0.402},
                    "s": [83.191162109375, -24.49189453125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.891},
                    "s": [80.26630859375, -28.758056640625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.357},
                    "i": {"x": 0.833, "y": 1.348},
                    "s": [33.971142578125004, 22.52197265625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [19.887402343749997, 52.9796875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.372},
                    "i": {"x": 0.833, "y": 1.223},
                    "s": [92.76650390625001, 31.20244140625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [113.777001953125, 68.324658203125, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.133},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [36.447558593749996, 12.838525390625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.239},
                    "i": {"x": 0.833, "y": 1.098},
                    "s": [66.2806640625, 52.853564453125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [82.246240234375, 105.45087890625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [47.538378906249996, 105.356787109375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [21.6611328125, -37.418505859374996, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.59},
                    "s": [540.032861328125, 597.6190429687499, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [544.483203125, 595.7291992187501, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [561.5798828125, 587.52919921875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [591.4810546875, 569.805908203125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [623.42021484375, 548.132763671875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [651.213330078125, 529.2543457031251, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [674.904443359375, 514.5299804687501, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [695.47451171875, 503.369091796875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [713.6021972656249, 495.00693359375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [729.7119140625, 488.802880859375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [744.11796875, 484.242431640625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [757.052587890625, 480.9251953125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [768.701953125, 478.55087890625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [779.214208984375, 476.87724609375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [788.711474609375, 475.72412109375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [797.2958496093751, 474.94536132812505, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [805.0534179687501, 474.4228515625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [812.0642578125, 474.060498046875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [818.4004394531249, 473.80625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [824.19609375, 473.808251953125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [829.5232910156251, 474.064501953125, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [834.4380859375, 474.52895507812497, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [838.98251953125, 475.16757812500003, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [843.1986328125, 475.94633789062505, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [847.11845703125, 476.83920898437503, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [850.776025390625, 477.82216796875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [854.19736328125, 478.8751953125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [857.406494140625, 479.97626953124995, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [860.425439453125, 481.109375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [863.2662109375, 482.260498046875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [865.94482421875, 483.413623046875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [868.4692871093749, 484.554736328125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [870.84560546875, 485.673828125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [873.07978515625, 486.760888671875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [875.173828125, 487.801904296875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [877.127734375, 488.7888671875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [878.939501953125, 489.711767578125, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [880.605126953125, 490.564599609375, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [882.1186035156251, 491.337353515625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [883.4739257812499, 492.022021484375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [884.657080078125, 492.61459960937503, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [885.7361328124999, 493.11708984375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [886.769140625, 493.53349609375005, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [887.736083984375, 493.85781249999997, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [888.6149414062501, 494.08203125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [889.3836914062499, 494.2021484375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.192},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [890.0163085937501, 494.21416015625005, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.809},
                    "s": [890.48876953125, 494.11005859375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [890.799072265625, 493.8818359375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [891.1173828125001, 493.49946289062495, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 15
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 37}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 53
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 27
    }, {
        "ty": 4,
        "nm": "Shape Layer 24",
        "mn": "",
        "sr": 1,
        "st": 7,
        "op": 60,
        "ip": 7,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.856},
                    "s": [135.245947265625, 202.71376953125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.076},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [124.283251953125, 121.252294921875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.062},
                    "i": {"x": 0.833, "y": 0.899},
                    "s": [247.84379882812502, 179.3830078125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.485},
                    "i": {"x": 0.833, "y": 0.652},
                    "s": [176.86054687499998, 221.86044921875, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.11},
                    "i": {"x": 0.833, "y": 0.902},
                    "s": [162.120166015625, 179.52314453125, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.569},
                    "i": {"x": 0.833, "y": 1.494},
                    "s": [115.264453125, 176.30600585937498, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [107.224609375, 202.4375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 1.035},
                    "s": [162.89892578125, 229.389794921875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [230.50888671875, 164.53251953125002, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.235},
                    "i": {"x": 0.833, "y": 1.154},
                    "s": [134.297021484375, 221.75234375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 1.037},
                    "s": [159.509619140625, 233.44975585937502, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.026},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [87.855712890625, 159.24736328125002, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.301},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [191.60092773437498, 99.53911132812499, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.043},
                    "i": {"x": 0.833, "y": 0.783},
                    "s": [231.38374023437498, 203.456494140625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [205.07807617187498, 213.582373046875, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.087},
                    "i": {"x": 0.833, "y": 0.875},
                    "s": [162.972998046875, 275.15644531249995, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.25},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [183.553076171875, 217.362060546875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 1.4},
                    "s": [193.815087890625, 131.90668945312498, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [202.80185546875, 197.9931640625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.902},
                    "i": {"x": 0.833, "y": 1.186},
                    "s": [150.681005859375, 124.363330078125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [155.09130859375, 211.023876953125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.393},
                    "i": {"x": 0.833, "y": 1.167},
                    "s": [140.83740234375, 222.296875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 0.394},
                    "s": [136.99765625, 226.358837890625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.097},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [148.54892578125, 159.2513671875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [220.96757812500002, 153.7259765625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.029},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [149.319677734375, 197.59077148437498, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 1.005},
                    "s": [202.667724609375, 165.047021484375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.004},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [103.669140625, 99.206787109375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.43},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [208.16708984375, 81.92392578124999, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 6.077},
                    "i": {"x": 0.833, "y": 22.316},
                    "s": [233.265576171875, 119.166259765625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [233.613916015625, 90.0798828125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.226},
                    "i": {"x": 0.833, "y": 0.915},
                    "s": [144.01250000000002, 168.99287109375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 4.525},
                    "i": {"x": 0.833, "y": -0.19},
                    "s": [91.5533203125, 42.123095703125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.09},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [90.568359375, 64.703125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.16},
                    "i": {"x": 0.833, "y": 2.147},
                    "s": [77.50161132812501, 29.859130859375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [81.969970703125, 20.041552734375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [16.01162109375, 9.0728515625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.078},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [97.9916015625, -1.8157714843750001, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [55.610253906249994, 47.53037109375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.033},
                    "i": {"x": 0.833, "y": 0.789},
                    "s": [1.895849609375, 42.681640625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.138},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [40.531542968749996, 110.89619140625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.317},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [99.87744140625, 13.246923828125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.533},
                    "s": [87.525390625, -3.55546875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [76.024169921875, 27.596923828125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.234},
                    "i": {"x": 0.833, "y": 0.517},
                    "s": [23.020458984375, 32.844042968749996, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [36.96005859375, -17.915478515625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.068},
                    "i": {"x": 0.833, "y": 1.022},
                    "s": [103.743212890625, 58.77734375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.996},
                    "s": [66.84921875, 2.720654296875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.004},
                    "i": {"x": 0.833, "y": 0.888},
                    "s": [113.400634765625, -6.037890625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.324},
                    "i": {"x": 0.833, "y": 0.486},
                    "s": [68.989306640625, 76.06220703125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 1.009},
                    "s": [53.5962890625, 126.57548828125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [-26.021386718749998, 85.058984375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [62.3408203125, 63.970410156250004, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.604},
                    "s": [530.59765625, 603.4507324218749, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [526.499658203125, 601.7350585937501, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [512.261767578125, 592.91845703125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [489.327392578125, 572.856884765625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [468.58515625, 546.0487304687499, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [453.55048828125, 520.3796874999999, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [441.993212890625, 498.29814453125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [432.307763671875, 479.78208007812503, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [423.72939453124997, 464.20087890625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [415.927783203125, 450.98598632812497, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [408.756787109375, 439.69296875000003, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [402.154345703125, 429.981494140625, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [396.09042968750003, 421.577294921875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [390.547021484375, 414.274169921875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [385.506103515625, 407.89394531249997, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [380.957666015625, 402.3044921875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [376.88369140625, 397.3876953125, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [373.270166015625, 393.0474609375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [370.095068359375, 389.205712890625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [367.34038085937505, 385.79638671875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [364.98408203125, 382.759423828125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [363.004150390625, 380.04677734374997, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [361.374560546875, 377.618408203125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [360.069287109375, 375.43427734375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [359.064306640625, 373.466357421875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [358.32958984375, 371.6826171875, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [357.83110351562505, 370.06103515625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [357.5408203125, 368.573583984375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [357.420703125, 367.20625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [357.438720703125, 365.939013671875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [357.5568359375, 364.755859375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [357.741015625, 363.644775390625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [357.94921875, 362.59575195312505, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [358.143408203125, 361.59677734375003, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [358.2775390625, 360.637841796875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [358.311572265625, 359.7109375, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [358.30556640625, 358.78403320312503, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [358.445703125, 357.8130859375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [358.707958984375, 356.83413085937497, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [359.060302734375, 355.877197265625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [359.4787109375, 354.97031250000003, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [359.885107421875, 354.10346679687495, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [360.209423828125, 353.258642578125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [360.433642578125, 352.465869140625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [360.54775390625, 351.7431640625, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [360.541748046875, 351.112548828125, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [360.405615234375, 350.5900390625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [360.133349609375, 350.1896484375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [359.72495117187503, 349.925390625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [359.176416015625, 349.8052734375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [358.48974609375, 349.83530273437503, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [357.6689453125, 350.01748046874997, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [356.72202148437503, 350.35380859375, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 12
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 34}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 50
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 28
    }, {
        "ty": 4,
        "nm": "Shape Layer 23",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.657},
                    "s": [244.59462890625, 189.08247070312498, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.11},
                    "i": {"x": 0.833, "y": 0.995},
                    "s": [204.54755859375, 227.896337890625, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": -0.005},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [79.6697265625, 199.760888671875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -5.85},
                    "i": {"x": 0.833, "y": 3.373},
                    "s": [197.114306640625, 117.5306640625, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [195.464697265625, 196.64384765625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [244.08212890625, 171.81962890625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [191.47880859375, 170.78862304687502, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.109},
                    "i": {"x": 0.833, "y": 0.37},
                    "s": [156.23842773437502, 153.73798828124998, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 1.019},
                    "s": [171.529345703125, 205.486474609375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [271.8732421875, 207.752685546875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.12},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [148.5048828125, 196.481689453125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.019},
                    "i": {"x": 0.833, "y": 1.067},
                    "s": [199.17431640625, 231.631982421875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [158.04619140625, 240.39252929687498, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.978},
                    "s": [232.112451171875, 95.343017578125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.029},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [180.4080078125, 138.481103515625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 1.011},
                    "s": [218.631298828125, 260.980615234375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.01},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [162.048095703125, 294.585400390625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.043},
                    "i": {"x": 0.833, "y": 1.048},
                    "s": [225.932421875, 234.394677734375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 1.002},
                    "s": [183.69521484375, 226.67314453125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.002},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [250.150048828125, 215.01376953125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 1.871},
                    "i": {"x": 0.833, "y": -0.03},
                    "s": [181.96953125, 173.299072265625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.06},
                    "s": [178.79243164062498, 193.81708984374998, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.035},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [142.67119140625002, 254.68447265625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.007},
                    "i": {"x": 0.833, "y": 1.064},
                    "s": [204.70170898437502, 295.79257812500003, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 1.023},
                    "s": [147.24365234375, 157.66181640625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.018},
                    "i": {"x": 0.833, "y": 0.89},
                    "s": [249.1390625, 145.23369140625002, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.347},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [119.13222656250001, 180.357958984375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.254},
                    "i": {"x": 0.833, "y": 0.122},
                    "s": [78.076171875, 180.341943359375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [88.21005859374999, 144.60107421875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.262},
                    "i": {"x": 0.833, "y": 1.05},
                    "s": [184.878369140625, 76.875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 1.058},
                    "s": [161.579638671875, 81.48349609374999, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.034},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [198.85800781249998, 86.54042968750001, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.238},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [135.6203125, 192.54384765625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.16},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [101.54106445312499, 138.35297851562498, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.787},
                    "s": [113.220458984375, 143.69819335937498, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.137},
                    "i": {"x": 0.833, "y": 1.453},
                    "s": [124.379345703125, 101.40693359375001, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [141.69624023437498, 38.2052734375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [30.15341796875, -23.348779296875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -1.554},
                    "i": {"x": 0.833, "y": -0.296},
                    "s": [119.858935546875, -43.84677734375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [115.29248046875, -17.89345703125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 1.049},
                    "s": [48.859667968749996, -40.685693359375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [-12.2439453125, 50.086865234375, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 1.626},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [84.86279296875, 22.139599609374997, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.013},
                    "i": {"x": 0.833, "y": 1.274},
                    "s": [90.109912109375, -3.36328125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 1.577},
                    "s": [85.553466796875, 49.282080078125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [105.084521484375, 80.35639648437501, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [-49.674462890624994, 36.451562499999994, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 1.154},
                    "i": {"x": 0.833, "y": 0.149},
                    "s": [58.583154296875, 45.390283203124994, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 1.047},
                    "s": [67.00737304687499, 50.85361328125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 0.891},
                    "s": [144.631103515625, -2.7106445312500003, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.355},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [23.028466796875, 26.347705078125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.26},
                    "i": {"x": 0.833, "y": 0.127},
                    "s": [-14.31396484375, 71.856103515625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [-5.243115234375001, 127.42031250000001, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.867},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [80.73476562500001, 126.41733398437499, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [89.88168945312499, 93.79550781249999, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.598},
                    "s": [524.089306640625, 604.6078613281251, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [521.91318359375, 599.03642578125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [515.564990234375, 577.0609863281251, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [509.86943359375, 535.7646972656249, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [514.319775390625, 489.71376953124997, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [528.0411621093749, 451.646630859375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [545.306005859375, 422.47617187500003, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [562.755029296875, 399.72998046875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [579.139013671875, 381.22392578125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [594.037548828125, 365.5806640625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [607.39658203125, 351.86728515625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [619.2361328124999, 339.493212890625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [629.580224609375, 328.06206054687505, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [638.440869140625, 317.307568359375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [645.808056640625, 307.05756835937495, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [651.6537597656251, 297.24399414062503, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [655.977978515625, 287.87685546875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [658.83876953125, 279.05224609375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [660.398291015625, 270.878271484375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [660.884765625, 263.42900390625005, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [660.54443359375, 256.7224609375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [659.589501953125, 250.734619140625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [658.18212890625, 245.41142578125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [656.646630859375, 240.636767578125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [655.1571777343751, 236.33256835937502, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [653.747802734375, 232.46279296875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [652.43251953125, 228.997412109375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [651.211328125, 225.910400390625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [650.07421875, 223.18173828125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [649.007177734375, 220.789404296875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [648.0001953125001, 218.723388671875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [647.033251953125, 216.969677734375, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [646.092333984375, 215.520263671875, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [645.163427734375, 214.36513671875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [644.2365234375, 213.498291015625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [643.30361328125, 212.91171875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [642.352685546875, 212.593408203125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [641.3777343749999, 212.5353515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [640.374755859375, 212.729541015625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [639.349755859375, 213.165966796875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [638.304736328125, 213.83662109374998, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [637.18564453125, 214.663427734375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [635.9404296875, 215.5703125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [634.585107421875, 216.54926757812498, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [633.14169921875, 217.586279296875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [631.6342285156251, 218.671337890625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [630.084716796875, 219.7904296875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [628.527197265625, 220.925537109375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [626.99169921875, 222.05864257812502, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [625.5142578125, 223.171728515625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [624.130908203125, 224.240771484375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [622.8856933593751, 225.24575195312502, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [621.81865234375, 226.16064453125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [620.91376953125, 226.96743164062502, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [620.14501953125, 227.656103515625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 29
    }, {
        "ty": 4,
        "nm": "Shape Layer 22",
        "mn": "",
        "sr": 1,
        "st": 2,
        "op": 60,
        "ip": 2,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [156.17236328125, 198.70185546875, 100],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": -0.017},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [256.716455078125, 146.072509765625, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": -0.687},
                    "i": {"x": 0.833, "y": 1.337},
                    "s": [173.55732421875, 158.26640625, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [182.5541015625, 214.751513671875, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 1.041},
                    "s": [137.21987304687502, 243.835888671875, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [94.482177734375, 172.0158203125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.24},
                    "i": {"x": 0.833, "y": 0.787},
                    "s": [158.268408203125, 108.08945312499999, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.137},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [192.12944335937502, 138.134765625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [244.71875, 159.049169921875, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.111},
                    "i": {"x": 0.833, "y": 0.768},
                    "s": [182.26381835937502, 192.16748046875, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [209.10200195312498, 229.49990234375002, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [257.034765625, 202.44150390625, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.071},
                    "i": {"x": 0.833, "y": 0.99},
                    "s": [193.99326171875, 166.60654296874998, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.011},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [227.96240234375, 227.095556640625, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [198.015185546875, 234.09438476562502, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.077},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [237.321533203125, 167.849755859375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.612},
                    "s": [216.87958984375, 103.36484374999999, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 1.07},
                    "s": [199.26640625, 240.39252929687498, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.987},
                    "s": [134.87758789062502, 289.332275390625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.016},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [252.9908203125, 171.94975585937502, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [153.413671875, 207.772705078125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 1.349},
                    "i": {"x": 0.833, "y": 1.779},
                    "s": [244.318359375, 235.84809570312498, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [250.30219726562498, 204.36137695312502, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 1.135},
                    "s": [188.36376953125, 224.5791015625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [133.484228515625, 183.589111328125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.024},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [277.53076171875, 303.966552734375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.153},
                    "i": {"x": 0.833, "y": 1.1},
                    "s": [165.74970703124998, 207.434375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [205.238232421875, 171.00483398437498, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [118.441552734375, 171.21103515625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.081},
                    "i": {"x": 0.833, "y": 0.781},
                    "s": [22.52998046875, 114.3275390625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.97},
                    "s": [71.20947265625, 150.87919921875, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.047},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [150.67299804687502, 88.127978515625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 1.081},
                    "i": {"x": 0.833, "y": -0.16},
                    "s": [99.787353515625, 106.74814453125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.09},
                    "i": {"x": 0.833, "y": 1.019},
                    "s": [95.53520507812499, 93.9396484375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [40.623632812500006, 174.48222656250002, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.252},
                    "i": {"x": 0.833, "y": 0.992},
                    "s": [108.283642578125, 144.695166015625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.683},
                    "s": [91.49326171874999, 156.264453125, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 1.156},
                    "s": [106.76416015625, 143.289794921875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [149.627978515625, 104.98642578125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.096},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [26.43779296875, -11.116845703125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [83.819775390625, -35.506640625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.027},
                    "i": {"x": 0.833, "y": 0.774},
                    "s": [142.79931640625, -14.884521484375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.132},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [98.369970703125, -48.219042968749996, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.056},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [22.459912109374997, -8.518310546875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.282},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [67.91225585937501, 32.039257812500004, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -41.355},
                    "i": {"x": 0.833, "y": 26.628},
                    "s": [86.94682617187499, 21.55703125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 2.023},
                    "s": [86.90878906249999, 6.47431640625, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [98.718310546875, 114.68388671875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.255},
                    "i": {"x": 0.833, "y": 0.757},
                    "s": [-58.078662109374996, 90.2080078125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 0.807},
                    "s": [-19.4029296875, 44.050976562500004, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 1.012},
                    "s": [54.5892578125, 28.341650390625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.01},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [152.00830078125, 65.84423828125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [40.741748046874996, 61.317822265625004, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.041},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [-48.399218749999996, 54.88955078125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [11.27900390625, 45.552441406250004, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.092},
                    "i": {"x": 0.833, "y": 0.972},
                    "s": [119.69677734375, 145.12958984375, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.043},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [68.240576171875, 127.7326171875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [102.133642578125, 77.14326171875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.555},
                    "s": [526.87802734375, 599.514892578125, 0],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [522.48974609375, 599.84521484375, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [503.469189453125, 601.6669921875, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [468.459033203125, 607.64482421875, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [430.518017578125, 618.795703125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [399.06533203124997, 632.9775390625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [374.79165039062497, 648.3465332031251, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [356.261572265625, 663.9837890625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [342.163818359375, 679.37880859375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [331.46137695312495, 694.2212890625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [323.377490234375, 708.333056640625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [317.4697265625, 721.583984375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [313.255615234375, 733.934033203125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [310.340771484375, 745.393212890625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [308.4048828125, 755.9895507812499, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [307.205712890625, 765.769091796875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.5470703125, 774.7818847656249, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.272802734375, 783.083984375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.260791015625, 790.725439453125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.416943359375, 797.75830078125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.66518554687497, 804.224609375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.94345703125, 810.16640625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [307.2037109375, 815.621728515625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [307.41191406250005, 820.62060546875, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [307.5400390625, 825.195068359375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [307.566064453125, 829.3771484375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [307.477978515625, 833.19287109375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [307.2677734375, 836.6682617187499, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [306.9314453125, 839.82333984375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [306.47099609375005, 842.680126953125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [305.892431640625, 845.256640625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [305.20576171875, 847.56689453125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [304.42299804687497, 849.62890625, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [303.558154296875, 851.4586914062501, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [302.63525390625, 853.06826171875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [301.672314453125, 854.46962890625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [300.69736328125, 855.6788085937501, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [299.73642578125003, 856.7078124999999, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [298.82353515625, 857.5646484375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [297.98872070312495, 858.26533203125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [297.264013671875, 858.7978515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [296.64140625, 859.160205078125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.193},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [296.12890625, 859.4224609375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.202},
                    "i": {"x": 0.833, "y": 0.864},
                    "s": [295.72451171874997, 859.588623046875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.214},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [295.42822265625, 859.660693359375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.201},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [295.234033203125, 859.6466796875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.791},
                    "s": [295.141943359375, 859.5505859375, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.803},
                    "s": [295.145947265625, 859.37841796875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [295.2400390625, 859.136181640625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [295.42221679687503, 858.827880859375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [295.68447265625, 858.4595214843749, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [296.02080078125005, 858.04111328125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [296.42319335937503, 857.5786621093749, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [296.883642578125, 857.0781738281249, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [297.394140625, 856.5496582031251, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [297.944677734375, 855.997119140625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [298.52724609374997, 855.432568359375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [299.129833984375, 854.8620117187501, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 2}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 7
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 29}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 45
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 30
    }, {
        "ty": 4,
        "nm": "Shape Layer 21",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": -5.266},
                    "s": [202.67373046875, 96.53017578125001, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.084},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [201.922998046875, 113.09033203125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [146.15458984375, 200.677783203125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.233},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [198.10126953124998, 237.9521484375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.185},
                    "i": {"x": 0.833, "y": 0.692},
                    "s": [227.06552734375, 198.924072265625, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.114},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [218.05473632812502, 104.77421875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.07},
                    "i": {"x": 0.833, "y": 1.185},
                    "s": [193.70498046875, 190.71206054687502, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 1.076},
                    "s": [206.92387695312502, 156.808984375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [164.28427734375, 156.07626953125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.254},
                    "i": {"x": 0.833, "y": 0.435},
                    "s": [246.042041015625, 161.79384765625, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [225.874365234375, 178.750390625, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.016},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [109.33466796875, 268.18764648437497, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.871},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [206.64560546874998, 167.6515625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.032},
                    "i": {"x": 0.833, "y": 1.079},
                    "s": [216.9396484375, 136.383056640625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [209.52841796875, 213.70849609375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 1.481},
                    "s": [223.94248046874998, 216.793505859375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [242.050146484375, 265.28681640625, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [119.342431640625, 122.4474609375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.141},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [253.81162109375, 210.623486328125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [203.96298828125, 236.740966796875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": -0.059},
                    "i": {"x": 0.833, "y": 1.119},
                    "s": [152.106396484375, 181.17275390625002, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.983},
                    "s": [182.375927734375, 258.892578125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.022},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [108.9462890625, 96.38803710937499, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.244},
                    "i": {"x": 0.833, "y": 1.036},
                    "s": [166.962890625, 163.773779296875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [197.138330078125, 195.07231445312502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 3.172},
                    "i": {"x": 0.833, "y": -4.522},
                    "s": [154.100341796875, 197.10029296874998, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [152.939208984375, 150.77109375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.032},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [77.147265625, 199.492626953125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.978},
                    "s": [131.90468750000002, 147.60400390625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.03},
                    "i": {"x": 0.833, "y": 1.051},
                    "s": [184.12163085937502, 148.49287109375, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.032},
                    "i": {"x": 0.833, "y": 1.063},
                    "s": [145.572021484375, 135.8685546875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [207.772705078125, 171.43525390625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.353},
                    "i": {"x": 0.833, "y": 0.65},
                    "s": [98.426025390625, 77.89999999999999, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.109},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [119.30439453125, 71.191455078125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.154},
                    "i": {"x": 0.833, "y": 0.678},
                    "s": [186.135595703125, 111.366650390625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [162.66269531249998, 97.40302734375001, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.985},
                    "s": [95.32700195312499, 48.963769531249994, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.018},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [33.338525390624994, 87.47734375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.225},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [84.45639648437499, 95.7013671875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.07},
                    "i": {"x": 0.833, "y": 1.962},
                    "s": [70.646923828125, 25.6330078125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [78.146240234375, 80.318359375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.257},
                    "i": {"x": 0.833, "y": 1.027},
                    "s": [-15.903515625, 49.870654296875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.741},
                    "s": [-60.949462890625, 12.5642578125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.123},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [-1.547509765625, -9.6974609375, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.147},
                    "i": {"x": 0.833, "y": 0.692},
                    "s": [123.93491210937499, 103.68515625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.114},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [78.538623046875, 134.14287109375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.035},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [-43.840771484375004, 110.42172851562499, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [42.439404296875004, -17.27685546875, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.025},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [132.855615234375, 60.87939453125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.08},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [63.53798828125, 94.6283203125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.182},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [98.97055664062499, 51.906640625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [87.83769531249999, 34.091259765625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.611},
                    "s": [537.061962890625, 612.93798828125, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [537.1, 618.877783203125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [538.5534179687501, 640.618994140625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [545.3000000000001, 679.49091796875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [561.257568359375, 719.9704101562501, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [584.8045410156251, 749.437158203125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [610.637744140625, 767.07236328125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [634.8813964843749, 777.04208984375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [656.38037109375, 782.9958984374999, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [675.24677734375, 787.0258300781251, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [691.828955078125, 790.216943359375, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [706.459228515625, 793.15380859375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [719.399853515625, 796.1647460937501, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [730.863037109375, 799.441943359375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [741.0189453125, 803.09951171875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [749.95166015625, 807.16748046875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [757.7412597656249, 811.625830078125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [764.491845703125, 816.430517578125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [770.3115234375, 821.50947265625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [775.266357421875, 826.6685058593749, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [779.42041015625, 831.68740234375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [782.889794921875, 836.486083984375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [785.7846191406251, 841.01650390625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [788.202978515625, 845.242626953125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [790.23095703125, 849.14443359375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [791.940625, 852.721923828125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [793.3920410156251, 855.9791015625, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [794.63125, 858.9299804687499, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [795.698291015625, 861.5845703125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [796.6191894531249, 863.95888671875, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [797.42197265625, 866.0689453125001, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [798.1226562500001, 867.9267578125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [798.7392578125, 869.550341796875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [799.2817871093749, 870.9617187499999, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [799.7622558593749, 872.176904296875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [800.182666015625, 873.20791015625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [800.55302734375, 874.06474609375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [800.8753417968751, 874.7594238281249, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.194},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [801.1496093750001, 875.299951171875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.203},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [801.3818359375, 875.6983398437501, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [801.5680175781249, 875.9585937500001, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [801.724169921875, 876.176806640625, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [801.864306640625, 876.4390625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [801.98642578125, 876.74736328125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [802.088525390625, 877.09970703125, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [802.1706054687501, 877.49609375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [802.2306640625, 877.93251953125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [802.268701171875, 878.406982421875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [802.2847167968749, 878.91748046875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [802.2566894531251, 879.4900390624999, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [802.168603515625, 880.1466796875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [802.0304687500001, 880.8513671874999, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 92, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 31
    }, {
        "ty": 4,
        "nm": "Shape Layer 20",
        "mn": "",
        "sr": 1,
        "st": 6,
        "op": 60,
        "ip": 6,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.05},
                    "s": [227.732177734375, 188.77216796874998, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [284.9259765625, 85.0830078125, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.241},
                    "i": {"x": 0.833, "y": 0.888},
                    "s": [193.15244140625, 96.099755859375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.325},
                    "i": {"x": 0.833, "y": 1.204},
                    "s": [144.7412109375, 187.364794921875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [128.026904296875, 170.454296875, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -1.529},
                    "i": {"x": 0.833, "y": -0.459},
                    "s": [185.711181640625, 148.01640625000002, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 0.995},
                    "s": [182.7302734375, 57.2638671875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.992},
                    "s": [133.49824218749998, 114.89609375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.995},
                    "s": [179.533154296875, 143.041552734375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.005},
                    "i": {"x": 0.833, "y": 0.731},
                    "s": [137.688330078125, 103.9814453125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 1.035},
                    "s": [177.01469726562502, 149.33769531250002, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.935},
                    "s": [264.71826171875, 178.269921875, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.305},
                    "i": {"x": 0.833, "y": 0.575},
                    "s": [140.08466796875, 252.876708984375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [166.83876953125, 196.461669921875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.391},
                    "i": {"x": 0.833, "y": 0.313},
                    "s": [276.503759765625, 147.70009765625002, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.095},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [257.226953125, 184.710205078125, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [117.47661132812499, 244.148193359375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.109},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [245.26328125, 293.808642578125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [189.89326171875, 157.51767578125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [122.27329101562499, 156.85703125, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [182.3458984375, 190.12548828125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.068},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [107.755126953125, 86.274169921875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.888},
                    "s": [148.8572265625, 211.4462890625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.324},
                    "i": {"x": 0.833, "y": 0.814},
                    "s": [182.35791015625, 133.57231445312502, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 1.498},
                    "s": [193.94121093750002, 110.998291015625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.992},
                    "s": [208.25517578125, 173.1208984375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.009},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [108.379736328125, 182.33989257812502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.017},
                    "i": {"x": 0.833, "y": 0.906},
                    "s": [198.900048828125, 82.050048828125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.764},
                    "i": {"x": 0.833, "y": 1.666},
                    "s": [123.70869140625, 99.58515625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.993},
                    "s": [114.49970703125001, 93.75947265625001, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.008},
                    "i": {"x": 0.833, "y": 1.011},
                    "s": [197.290478515625, 75.323486328125, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [121.484521484375, 61.626123046875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [206.903857421875, 105.885302734375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.282},
                    "i": {"x": 0.833, "y": 1.098},
                    "s": [114.0072265625, 44.961865234375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [75.033203125, 58.6251953125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.106},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [160.03212890625, 101.22275390624999, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 1.804},
                    "i": {"x": 0.833, "y": -5.816},
                    "s": [122.69370117187499, 145.33579101562498, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.084},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [120.88393554687501, 108.67001953124999, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.155},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [-25.242626953125, 149.039404296875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.231},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [25.891259765625, 88.29814453125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 1.094},
                    "s": [54.795458984374996, 76.957080078125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [96.868505859375, 84.800732421875, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.898},
                    "i": {"x": 0.833, "y": 1.231},
                    "s": [7.413232421875, 6.52236328125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 1.188},
                    "s": [15.01064453125, -26.8341796875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.899},
                    "s": [-13.663330078125, -22.648095703125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.485},
                    "i": {"x": 0.833, "y": 1.382},
                    "s": [79.53159179687499, 62.094580078125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [98.864453125, 116.1373046875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.055},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [-9.06484375, 131.01181640624998, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [55.7583984375, 13.97763671875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [150.200537109375, 10.820556640625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.146},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [57.009619140625, 40.145166015625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 1.164},
                    "s": [90.91269531249999, 43.68662109375, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [110.9802734375, 62.94541015625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [51.356103515624994, -46.38125, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.574},
                    "s": [548.5091308593751, 516.9283203125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.763},
                    "s": [545.8004882812501, 520.069384765625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [533.6786621093751, 532.093115234375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [507.705322265625, 549.762353515625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [474.484912109375, 560.1745117187501, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [444.2654296875, 559.243603515625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [420.492236328125, 550.903466796875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [402.722900390625, 539.139990234375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [389.580078125, 526.295458984375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [379.79853515625, 513.525, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [372.405322265625, 501.323095703125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [366.727783203125, 489.891943359375, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [362.28945312499997, 479.28159179687503, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [358.760009765625, 469.480029296875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [355.901220703125, 460.44921875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [353.544921875, 452.147119140625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [351.5669921875, 444.519677734375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [349.875341796875, 437.5228515625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [348.40590820312497, 431.110595703125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [347.104638671875, 425.246875, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [345.939501953125, 419.89565429687497, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [344.88247070312497, 415.026904296875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [343.91552734375, 410.60859375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [343.024658203125, 406.618701171875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [342.20185546875, 403.03720703125, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [341.447119140625, 399.836083984375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [340.75844726562497, 396.99931640625005, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [340.13784179687497, 394.502880859375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [339.58930664062495, 392.33076171874995, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [339.1228515625, 390.46494140625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [338.74248046875, 388.88740234374995, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [338.46420898437503, 387.5861328125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.193},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [338.296044921875, 386.543115234375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [338.25200195312505, 385.746337890625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [338.34409179687503, 385.17978515625, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [338.58632812499997, 384.8314453125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [338.8986328125, 384.74135742187497, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [339.244970703125, 384.897509765625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.819},
                    "s": [339.61533203125, 385.25185546874997, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [339.99970703125, 385.77236328125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [340.39609375000003, 386.42099609375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [340.794482421875, 387.095654296875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [341.19287109375, 387.700244140625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [341.58525390625, 388.21875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [341.965625, 388.629150390625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [342.331982421875, 388.917431640625, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [342.6783203125, 389.07358398437503, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [343.004638671875, 389.085595703125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [343.304931640625, 388.9474609375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [343.57719726562505, 388.65517578125, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [343.82143554687497, 388.208740234375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.819},
                    "s": [344.037646484375, 387.608154296875, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [344.221826171875, 386.85942382812505, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [344.3759765625, 385.9685546875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 11
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 33}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 49
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 32
    }, {
        "ty": 4,
        "nm": "Shape Layer 19",
        "mn": "",
        "sr": 1,
        "st": 11,
        "op": 60,
        "ip": 11,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 2.45},
                    "s": [129.47832031250002, 59.207763671875, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [127.362255859375, 63.36982421875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 1.096},
                    "s": [166.32626953124998, 190.15351562499998, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 1.044},
                    "s": [197.0482421875, 136.82548828125002, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [130.77958984375, 93.4751953125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.117},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [232.178515625, 163.73173828125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 1.191},
                    "s": [189.919287109375, 269.33876953125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [153.964208984375, 165.087060546875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.576},
                    "i": {"x": 0.833, "y": 1.592},
                    "s": [272.439794921875, 144.466943359375, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.95},
                    "s": [292.47133789062497, 188.9443359375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.126},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [130.12294921875002, 265.18071289062505, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 1.163},
                    "s": [194.671923828125, 304.505078125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [253.86767578125, 236.736962890625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.273},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [78.914990234375, 204.509521484375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.231},
                    "i": {"x": 0.833, "y": 1.013},
                    "s": [119.806884765625, 187.96337890625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 1.068},
                    "s": [142.8193359375, 95.16884765625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [116.30947265625, 89.72353515625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.234},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [164.430419921875, 177.6533203125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 1.37},
                    "s": [191.0904296875, 141.51606445312498, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [213.005810546875, 82.758740234375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [93.765478515625, 190.31767578125002, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.417},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [200.677783203125, 152.891162109375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 1.202},
                    "s": [182.8744140625, 93.33906250000001, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [172.33212890624998, 144.687158203125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.069},
                    "i": {"x": 0.833, "y": 1.193},
                    "s": [208.429345703125, 102.161669921875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [188.74614257812502, 124.371337890625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.063},
                    "i": {"x": 0.833, "y": 0.717},
                    "s": [254.13193359374998, 186.359814453125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 0.99},
                    "s": [216.87358398437502, 132.016796875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.012},
                    "i": {"x": 0.833, "y": 0.969},
                    "s": [127.570458984375, 88.36220703125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.049},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [206.04301757812502, 97.437060546875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.147},
                    "i": {"x": 0.833, "y": 1.464},
                    "s": [156.6888671875, 143.48198242187502, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.902},
                    "s": [174.52226562500002, 150.851171875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.554},
                    "i": {"x": 0.833, "y": 1.234},
                    "s": [57.466064453125, 221.92451171875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [36.749853515625, 93.04477539062499, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 1.091},
                    "s": [115.62880859375001, 79.903955078125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [183.156689453125, 162.60263671874998, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.137},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [41.79677734375, 97.80141601562501, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.108},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [95.22890625, 7.65546875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.035},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [72.00224609375, 23.917333984375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 1.412},
                    "s": [88.422265625, 8.82861328125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [105.252685546875, 31.58681640625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.254},
                    "i": {"x": 0.833, "y": 0.454},
                    "s": [5.1189941406249995, 120.391455078125, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [29.827099609374997, 55.2619140625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.082},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [167.052978515625, 62.9333984375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 1.046},
                    "s": [97.701318359375, 17.833398437499998, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [41.650634765625, 98.462060546875, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.065},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [128.755615234375, 81.917919921875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.079},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [79.91796875, 37.764843750000004, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [104.924365234375, -7.116943359375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.51},
                    "s": [547.968603515625, 507.69130859375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.1},
                    "i": {"x": 0.833, "y": 0.759},
                    "s": [551.327880859375, 507.3509765625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [567.9541015625, 508.678271484375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [598.441845703125, 516.569970703125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [629.912548828125, 532.3974121093751, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [653.695751953125, 551.956494140625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [669.88955078125, 571.98603515625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [680.521923828125, 591.022607421875, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [687.37060546875, 608.5697265625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [691.7008300781249, 624.5373046875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [694.363427734375, 638.9994140625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [695.93896484375, 652.082177734375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [696.829833984375, 663.949755859375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [697.3183105468751, 674.7502929687499, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [697.602587890625, 684.60390625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [697.8087890625001, 693.6407226562501, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [697.9369140625, 702.044921875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [698.0330078124999, 709.86455078125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [698.1291015625, 717.13564453125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [698.247216796875, 723.884228515625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [698.39736328125, 730.13232421875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [698.591552734375, 735.89794921875, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [698.8277832031249, 741.1951171874999, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [699.1060546875, 746.0318359375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [699.4263671875, 750.422119140625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [699.78271484375, 754.3739746093751, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [700.169091796875, 757.893408203125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [700.58349609375, 760.9904296875001, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [701.01591796875, 763.673046875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [701.4623535156251, 765.953271484375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [701.91279296875, 767.847119140625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [702.365234375, 769.3625976562499, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.196},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [702.80966796875, 770.51572265625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.204},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [703.244091796875, 771.322509765625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.205},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [703.6604980468751, 771.794970703125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.806},
                    "s": [704.056884765625, 771.955126953125, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.146},
                    "i": {"x": 0.833, "y": 0.804},
                    "s": [704.42724609375, 771.822998046875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [704.767578125, 771.418603515625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [705.077880859375, 770.765966796875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [705.350146484375, 769.893115234375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [705.58837890625, 768.828076171875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [705.770556640625, 767.670947265625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [705.8806640625, 766.521826171875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [705.9227050781251, 765.412744140625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [705.894677734375, 764.3737304687501, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [705.8366210937501, 763.434814453125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [705.862646484375, 762.5879882812501, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [705.9667480468751, 761.8352539062499, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [706.1369140625, 761.176611328125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 16
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 38}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 54
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 33
    }, {
        "ty": 4,
        "nm": "Shape Layer 18",
        "mn": "",
        "sr": 1,
        "st": 12,
        "op": 60,
        "ip": 12,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.085},
                    "s": [192.033349609375, 195.58681640625, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [239.940087890625, 201.81489257812498, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.082},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [143.35986328125, 95.18886718750001, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 1.108},
                    "s": [192.005322265625, 148.518896484375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.971},
                    "s": [233.28559570312498, 252.672509765625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.046},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [138.69931640625, 164.75273437500002, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 1.014},
                    "s": [199.8029296875, 126.01093750000001, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.012},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [311.213623046875, 202.60566406249998, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.787},
                    "i": {"x": 0.833, "y": 0.797},
                    "s": [180.846435546875, 286.0630859375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 1.16},
                    "s": [193.326611328125, 286.07509765624997, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [211.23808593750002, 290.189111328125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [158.905029296875, 249.561474609375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.007},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [107.302685546875, 242.258349609375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [155.03125, 189.02041015625002, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [193.50478515625, 95.26894531250001, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.869},
                    "s": [152.0763671875, 156.62480468750002, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.23},
                    "i": {"x": 0.833, "y": 1.159},
                    "s": [189.046435546875, 233.41972656250002, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [210.0208984375, 82.34433593749999, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -4.52},
                    "i": {"x": 0.833, "y": -2.162},
                    "s": [149.139501953125, 171.46728515625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [150.240576171875, 274.9642578125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.097},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [190.96430664062498, 156.37456054687502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -1.248},
                    "i": {"x": 0.833, "y": 3.564},
                    "s": [172.14194335937498, 212.88369140625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 1.138},
                    "s": [173.32109375, 118.243359375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [135.906591796875, 154.716943359375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -1.951},
                    "i": {"x": 0.833, "y": 0.048},
                    "s": [235.34760742187498, 225.5240234375, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [231.2736328125, 235.2455078125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.052},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [188.82822265625, 143.986474609375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [215.02978515625, 116.63779296875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 1.225},
                    "i": {"x": 0.833, "y": -1.313},
                    "s": [180.01162109375002, 100.6181640625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [177.45512695312502, 113.2705078125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.303},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [109.076416015625, 147.82822265625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.806},
                    "s": [83.15512695312499, 127.8447265625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.146},
                    "i": {"x": 0.833, "y": 1.052},
                    "s": [117.89501953125, 24.68408203125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.032},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [163.893896484375, 163.61162109375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.543},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [89.0869140625, 194.78603515625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.202},
                    "i": {"x": 0.833, "y": 1.018},
                    "s": [99.046630859375, 59.35390625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.015},
                    "i": {"x": 0.833, "y": 1.415},
                    "s": [106.05947265625001, 39.662695312500006, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 1.126},
                    "s": [97.569189453125, 39.616650390625004, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [148.334716796875, 1.619580078125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.229},
                    "i": {"x": 0.833, "y": 0.788},
                    "s": [20.5560546875, 125.80673828124999, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.137},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [54.68935546875, 93.040771484375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 2.212},
                    "i": {"x": 0.833, "y": 3.588},
                    "s": [107.32670898437499, 138.0947265625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [109.38671875, 16.9205078125, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.428},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [43.336279296875, 83.33330078124999, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.037},
                    "i": {"x": 0.833, "y": 1.031},
                    "s": [54.102783203125, 41.786767578125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 1.011},
                    "s": [46.637499999999996, 52.152880859374996, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.01},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [56.885498046875, 53.496191406250006, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [45.26015625, 64.500927734375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.544},
                    "s": [536.253173828125, 511.036572265625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.761},
                    "s": [534.323291015625, 506.20986328125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [523.92314453125, 485.359521484375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [499.49931640625, 449.292333984375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [464.59125976562495, 415.699560546875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [427.87143554687503, 395.235595703125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [393.87026367187497, 387.17973632812505, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [364.50361328125, 388.47099609375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [340.390087890625, 396.076416015625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [321.38754882812503, 407.4134765625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [306.829345703125, 420.54228515625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [295.856640625, 434.193603515625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [287.66464843750003, 447.60869140625005, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [281.58872070312503, 460.37514648437497, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [277.096337890625, 472.3087890625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [273.787109375, 483.33955078125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [271.370751953125, 493.443408203125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [269.61904296875, 502.640380859375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [268.367822265625, 510.95849609375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [267.482958984375, 518.443798828125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [266.86435546875, 525.154345703125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [266.429931640625, 531.144189453125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [266.12163085937505, 536.465380859375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [265.89140625, 541.17197265625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [265.69921875, 545.316015625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [265.51904296875, 548.953564453125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [265.32485351562497, 552.1326660156251, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [265.10263671875, 554.90537109375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [264.840380859375, 557.327734375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [264.59013671875, 559.439794921875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [264.407958984375, 561.265576171875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [264.279833984375, 562.8270996093751, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [264.20175781250003, 564.148388671875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [264.16572265625, 565.245458984375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [264.163720703125, 566.136328125, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [264.19174804687503, 566.83701171875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.204},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [264.24580078125, 567.359521484375, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.222},
                    "i": {"x": 0.833, "y": 0.869},
                    "s": [264.319873046875, 567.7138671875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.23},
                    "i": {"x": 0.833, "y": 0.811},
                    "s": [264.41396484375, 567.9080566406251, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [264.52207031250003, 567.9541015625, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [264.644189453125, 567.856005859375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [264.77631835937495, 567.719873046875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [264.920458984375, 567.641796875, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [265.07460937499997, 567.619775390625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [265.232763671875, 567.643798828125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [265.394921875, 567.7118652343751, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [265.557080078125, 567.81396484375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [265.715234375, 567.944091796875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 17
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 39}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 55
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 34
    }, {
        "ty": 4,
        "nm": "Shape Layer 17",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [98.534130859375, 207.82475585937502, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.166},
                    "i": {"x": 0.833, "y": 1.111},
                    "s": [194.02529296875002, 185.78125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.048},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [162.140185546875, 123.016015625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.607},
                    "i": {"x": 0.833, "y": 1.606},
                    "s": [236.600830078125, 122.74575195312501, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [248.444384765625, 215.51625976562502, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.013},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [150.508837890625, 118.47958984375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -62.917},
                    "i": {"x": 0.833, "y": -66.49},
                    "s": [235.58984375, 174.15390625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [235.47773437499998, 188.22763671875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.154},
                    "i": {"x": 0.833, "y": 0.649},
                    "s": [144.44692382812502, 179.633251953125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.109},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [176.38408203125, 136.63730468749998, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [279.154345703125, 159.94404296875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.681},
                    "i": {"x": 0.833, "y": 1.246},
                    "s": [183.35888671875, 244.08212890625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 1.031},
                    "s": [193.80107421875, 218.31499023437502, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 0.909},
                    "s": [152.49677734375, 243.63569335937498, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 1.035},
                    "i": {"x": 0.833, "y": 2.265},
                    "s": [209.23613281250002, 189.494873046875, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 1.009},
                    "s": [214.202978515625, 229.60200195312498, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [133.804541015625, 213.0318359375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -4.987},
                    "i": {"x": 0.833, "y": -2.408},
                    "s": [223.145703125, 213.76455078125002, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [221.678271484375, 158.0181640625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.884},
                    "s": [163.087109375, 237.381591796875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.293},
                    "i": {"x": 0.833, "y": 1.215},
                    "s": [203.8388671875, 114.0072265625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [220.004638671875, 156.662841796875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.066},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [162.04208984374998, 274.766064453125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 12.816},
                    "i": {"x": 0.833, "y": -6.827},
                    "s": [194.423681640625, 204.06508789062502, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.084},
                    "i": {"x": 0.833, "y": 1.453},
                    "s": [194.635888671875, 257.01874999999995, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [214.33110351562502, 155.509716796875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.013},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [87.61347656250001, 163.8158203125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.143},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [197.330517578125, 193.514794921875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -2.31},
                    "i": {"x": 0.833, "y": 2.435},
                    "s": [156.879052734375, 190.84819335937502, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [158.28842773437498, 123.82080078125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.407},
                    "i": {"x": 0.833, "y": 0.227},
                    "s": [132.62539062500002, 125.38833007812501, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 1.092},
                    "s": [136.9896484375, 88.8146484375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.901},
                    "s": [173.130908203125, 86.95883789062499, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.541},
                    "i": {"x": 0.833, "y": 0.779},
                    "s": [97.236865234375, 19.200732421875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.776},
                    "s": [83.411376953125, 102.397900390625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.133},
                    "i": {"x": 0.833, "y": 1.092},
                    "s": [60.545068359374994, -16.40400390625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.935},
                    "s": [21.973437500000003, 114.97216796875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.287},
                    "i": {"x": 0.833, "y": 0.606},
                    "s": [103.17465820312499, 115.510693359375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.918},
                    "s": [84.90683593749999, 59.07763671875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -5.253},
                    "i": {"x": 0.833, "y": -8.496},
                    "s": [16.818408203125, -48.511328125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.084},
                    "i": {"x": 0.833, "y": 0.961},
                    "s": [17.881445312500002, 43.5544921875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.072},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [137.974609375, 58.951513671875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.094},
                    "i": {"x": 0.833, "y": 1.049},
                    "s": [73.501708984375, 104.65009765625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.899},
                    "s": [103.82329101562499, 106.71611328124999, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.481},
                    "i": {"x": 0.833, "y": 1.136},
                    "s": [55.53017578125, 118.417529296875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 1.062},
                    "s": [45.414306640625, 18.1296875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.035},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [72.00224609375, 8.238037109375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.285},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [25.687060546875, -62.294775390625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [6.5804199218749995, -9.923681640625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [32.163378906249996, 55.602246093750004, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [53.71640625, 90.177978515625, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [79.931982421875, 70.5328125, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.628},
                    "s": [551.4359863281251, 519.620947265625, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.107},
                    "i": {"x": 0.833, "y": 0.767},
                    "s": [557.9923828125, 521.5328125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [581.39521484375, 525.4186035156249, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [623.934716796875, 528.401513671875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [670.9065429687499, 524.661865234375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [710.086767578125, 512.962451171875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [739.18115234375, 495.4833984375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [759.1386230468751, 475.19960937499997, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [772.0211914062501, 454.597509765625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [779.9929687499999, 435.02041015625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [784.699560546875, 416.98081054687503, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [787.248046875, 400.57080078125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [788.3631347656251, 385.658251953125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [788.5112792968749, 372.093017578125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [787.99677734375, 359.732958984375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [787.0298339843749, 348.453955078125, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [785.760595703125, 338.14589843749997, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [784.295166015625, 328.7126953125, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [782.7396484375, 320.07626953125003, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [781.1601074218751, 312.1685546875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [779.59658203125, 304.92348632812497, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [778.08310546875, 298.291015625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [776.6396972656249, 292.223095703125, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [775.2843750000001, 286.681689453125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [774.03515625, 281.628759765625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [772.896044921875, 277.03427734375003, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [771.879052734375, 272.872216796875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [770.9841796874999, 269.11455078125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [770.2154296875001, 265.74125976562505, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [769.572802734375, 262.730322265625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [769.056298828125, 260.063720703125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [768.663916015625, 257.7234375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [768.391650390625, 255.69145507812502, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [768.2334960937501, 253.9517578125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [768.18544921875, 252.49033203125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [768.239501953125, 251.291162109375, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [768.3876464843751, 250.340234375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [768.623876953125, 249.62353515625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [768.936181640625, 249.12705078124998, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [769.316552734375, 248.836767578125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [769.7509765625, 248.736669921875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [770.201416015625, 248.7306640625, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [770.625830078125, 248.71665039062498, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [771.0142089843749, 248.688623046875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [771.3565429687501, 248.63857421875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [771.63681640625, 248.55849609375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [771.8450195312499, 248.43637695312498, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [771.9771484375001, 248.27421875000002, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [772.155322265625, 248.19013671875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [772.38955078125, 248.204150390625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [772.667822265625, 248.312255859375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [772.9681152343751, 248.50244140625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 35
    }, {
        "ty": 4,
        "nm": "Shape Layer 16",
        "mn": "",
        "sr": 1,
        "st": 9,
        "op": 60,
        "ip": 9,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.563},
                    "s": [184.82431640625, 188.02744140625, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 1.109},
                    "s": [190.692041015625, 84.26621093749999, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 1.054},
                    "s": [215.560302734375, 213.0478515625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [158.20634765625, 165.051025390625, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.475},
                    "i": {"x": 0.833, "y": 0.393},
                    "s": [253.04287109375, 149.603955078125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.097},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [238.89306640625, 114.11333007812499, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.053},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [149.90625, 163.86787109375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [204.3193359375, 187.29072265625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [258.522216796875, 103.851318359375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.389},
                    "i": {"x": 0.833, "y": 0.863},
                    "s": [176.9005859375, 162.61865234375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.212},
                    "i": {"x": 0.833, "y": 1.102},
                    "s": [154.662890625, 120.011083984375, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 0.698},
                    "s": [140.210791015625, 176.10380859375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.995},
                    "s": [172.376171875, 141.19775390625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [256.652392578125, 188.537939453125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.627},
                    "i": {"x": 0.833, "y": 1.376},
                    "s": [177.8455078125, 113.4306640625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [165.76572265625, 252.00986328125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.002},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [232.308642578125, 167.677587890625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.033},
                    "i": {"x": 0.833, "y": 0.9},
                    "s": [166.956884765625, 229.19760742187498, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.508},
                    "i": {"x": 0.833, "y": 1.069},
                    "s": [213.746533203125, 95.90156250000001, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.758},
                    "s": [222.927490234375, 156.76894531250002, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 1.163},
                    "s": [206.107080078125, 169.03291015624998, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.9},
                    "s": [174.095849609375, 163.64365234375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.493},
                    "i": {"x": 0.833, "y": 1.429},
                    "s": [268.7822265625, 205.84482421875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [288.051025390625, 207.832763671875, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.171},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [169.509375, 193.222509765625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -1.171},
                    "i": {"x": 0.833, "y": -0.547},
                    "s": [208.36127929687498, 235.12739257812498, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 0.772},
                    "s": [205.78076171875, 135.0837890625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.131},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [160.44453125, 111.642919921875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [81.68369140625, 110.6439453125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.966},
                    "s": [136.803466796875, 117.28242187500001, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.058},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [199.28242187499998, 104.071533203125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.792},
                    "s": [162.51054687500002, 46.84169921875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [109.04238281250001, 134.084814453125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.427},
                    "i": {"x": 0.833, "y": 1.334},
                    "s": [29.150439453125, 7.058886718749999, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [9.78955078125, 125.052001953125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.192},
                    "i": {"x": 0.833, "y": 1.066},
                    "s": [106.682080078125, 20.562060546875003, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [181.162744140625, 93.06279296874999, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.284},
                    "i": {"x": 0.833, "y": 1.078},
                    "s": [47.734570312500004, -11.579296874999999, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.862},
                    "s": [-7.5413574218749995, 18.886425781249997, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.211},
                    "i": {"x": 0.833, "y": 0.96},
                    "s": [99.78334960937501, 84.25820312500001, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.077},
                    "i": {"x": 0.833, "y": 0.76},
                    "s": [169.923779296875, 98.97055664062499, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [133.37812499999998, 60.266796875, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.222},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [64.673095703125, 53.76845703125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.117},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [23.388818359375, -1.07705078125, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 10.387},
                    "i": {"x": 0.833, "y": 2.198},
                    "s": [40.529541015625, 35.104248046875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 0.475},
                    "s": [40.66767578125, -91.41318359374999, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 1.442},
                    "s": [38.53759765625, -17.887451171875, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [27.232568359374998, 64.985400390625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.071},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [98.4560546875, 27.6029296875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [59.884423828125, 70.30258789062499, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [101.6111328125, 147.5919921875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.587},
                    "s": [551.576123046875, 522.7099609375, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.774},
                    "s": [547.89052734375, 518.9462890625, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.132},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [531.180224609375, 508.47607421875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [526.932080078125, 543.96669921875, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [531.340380859375, 584.9226562499999, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [535.22216796875, 620.5654296875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [537.742626953125, 650.234375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [539.075927734375, 675.136669921875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [539.476318359375, 696.353369140625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [539.1540039062501, 714.6692382812499, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [538.2591308593751, 730.646826171875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [536.9158203125, 744.68251953125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [535.216162109375, 757.07861328125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [533.240234375, 768.071337890625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [531.05009765625, 777.832861328125, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [528.705810546875, 786.513330078125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [526.257421875, 794.236865234375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [523.7449707031251, 801.11357421875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [521.210498046875, 807.2275390625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [518.6900390625, 812.662841796875, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [516.21162109375, 817.491552734375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [513.799267578125, 821.7837402343749, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [511.472998046875, 825.5974609375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [509.246826171875, 828.9927734375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [507.13076171875, 832.0197265625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [505.13081054687495, 834.724365234375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [503.24697265624997, 837.150732421875, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [501.479248046875, 839.3368652343751, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [499.81962890625, 841.32080078125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [498.260107421875, 843.13056640625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [496.79067382812497, 844.7961914062499, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [495.441357421875, 846.3236816406251, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [494.29624023437503, 847.68701171875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [493.34130859375, 848.894189453125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [492.55654296875, 849.95322265625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [491.921923828125, 850.8701171875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [491.42343750000003, 851.646875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [491.04306640625003, 852.29150390625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.197},
                    "i": {"x": 0.833, "y": 0.862},
                    "s": [490.764794921875, 852.802001953125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.211},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [490.574609375, 853.1843749999999, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.193},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [490.46049804687505, 853.438623046875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [490.34438476562497, 853.616796875, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.814},
                    "s": [490.15019531250005, 853.7609375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [489.879931640625, 853.871044921875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [489.527587890625, 853.94912109375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [489.09316406249997, 853.9911621093751, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [488.578662109375, 853.99716796875, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [487.986083984375, 853.9711425781251, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [487.31943359375, 853.9130859375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [486.584716796875, 853.827001953125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [485.7859375, 853.7108886718751, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 14
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 36}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 52
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 36
    }, {
        "ty": 4,
        "nm": "Shape Layer 15",
        "mn": "",
        "sr": 1,
        "st": 6,
        "op": 60,
        "ip": 6,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.755},
                    "s": [165.16513671874998, 166.892822265625, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 1.13},
                    "s": [185.31479492187498, 244.068115234375, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [224.324853515625, 161.80585937499998, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.103},
                    "i": {"x": 0.833, "y": 0.879},
                    "s": [124.37734375, 102.7001953125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.269},
                    "i": {"x": 0.833, "y": 0.612},
                    "s": [169.125, 192.55986328125002, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [189.24663085937502, 238.20639648437498, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.155},
                    "i": {"x": 0.833, "y": 0.7},
                    "s": [262.78837890625, 123.5685546875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [237.065283203125, 58.062646484375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.826},
                    "i": {"x": 0.833, "y": 1.355},
                    "s": [170.316162109375, 128.72958984375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.982},
                    "s": [162.828857421875, 248.74267578125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.023},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [202.2212890625, 130.31513671874998, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 1.722},
                    "i": {"x": 0.833, "y": -1.675},
                    "s": [171.30712890625, 104.467919921875, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.895},
                    "s": [169.735595703125, 91.110888671875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.406},
                    "i": {"x": 0.833, "y": 1.611},
                    "s": [120.855908203125, 153.0052734375, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [108.23359375, 173.97573242187502, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.399},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [213.466259765625, 205.32431640625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.815},
                    "s": [195.3025390625, 71.00927734375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.903},
                    "s": [180.602197265625, 166.88081054687498, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.578},
                    "i": {"x": 0.833, "y": 3.319},
                    "s": [162.7427734375, 161.723779296875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [159.73583984375, 240.748876953125, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.122},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [246.41240234375, 115.78896484375001, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.07},
                    "i": {"x": 0.833, "y": 1.232},
                    "s": [211.18203125, 191.841162109375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 1.055},
                    "s": [230.274658203125, 142.38691406249998, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [158.04619140625, 116.70185546875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 1.606},
                    "i": {"x": 0.833, "y": 1.622},
                    "s": [277.65888671874995, 106.9603515625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [284.207275390625, 193.282568359375, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 1.001},
                    "s": [228.793212890625, 209.938818359375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.001},
                    "i": {"x": 0.833, "y": 1.034},
                    "s": [178.019677734375, 242.99106445312498, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.024},
                    "i": {"x": 0.833, "y": 0.803},
                    "s": [229.37177734374998, 94.67436523437499, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [157.08525390625, 111.92119140625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.086},
                    "i": {"x": 0.833, "y": 0.8},
                    "s": [58.7373046875, 59.103662109375, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.143},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [107.04042968750001, 81.753759765625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.176},
                    "i": {"x": 0.833, "y": 0.752},
                    "s": [174.86459960937498, 41.496484375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [153.06132812500002, 75.331494140625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [110.097412109375, 175.737451171875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.403},
                    "i": {"x": 0.833, "y": 1.044},
                    "s": [32.501708984375, 50.927685546875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 1.502},
                    "s": [45.7966796875, 113.785009765625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [25.46884765625, -15.79140625, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.373},
                    "i": {"x": 0.833, "y": 0.475},
                    "s": [168.26616210937502, 76.824951171875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.099},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [142.180712890625, 71.051318359375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.133},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [3.8958007812499997, 31.632861328125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.14},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [57.189794921875, 16.069677734375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.452},
                    "i": {"x": 0.833, "y": 0.248},
                    "s": [135.1958984375, 99.82138671875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [123.058056640625, 1.4814453125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.542},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [25.687060546875, 7.869677734375, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 1.066},
                    "s": [38.667724609375, -55.956591796875, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [54.891552734375004, 67.3056640625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 1.067},
                    "s": [25.865234375, -19.2748046875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.982},
                    "s": [64.71513671875, 2.9068359375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.022},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [-5.453320312500001, 121.204248046875, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [49.938720703125, -11.95166015625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.035},
                    "i": {"x": 0.833, "y": 0.875},
                    "s": [95.054736328125, -2.99892578125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.25},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [63.235693359375, 121.764794921875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [47.29013671875, 41.074072265625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.63},
                    "s": [551.508056640625, 515.629052734375, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.767},
                    "s": [550.1847656250001, 512.6321289062499, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [543.23798828125, 503.609326171875, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [529.4205078125, 488.492578125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [513.142626953125, 472.7091796875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [498.42626953125, 459.52431640625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [485.96811523437503, 448.78984375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [475.4798828125, 439.78505859375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [466.65327148437495, 431.92138671875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [459.342138671875, 424.69033203125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [453.89082031249995, 417.40322265624997, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [453.9068359375, 409.47348632812503, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [460.1369140625, 405.08120117187497, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [466.57919921875003, 402.722900390625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [472.547021484375, 401.1833984375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [478.000341796875, 400.13637695312497, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [482.96518554687503, 399.45571289062497, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [487.477587890625, 399.08134765625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [491.5755859375, 398.98525390625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [495.333251953125, 399.02329101562503, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [498.790625, 399.095361328125, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [501.95771484375, 399.183447265625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [504.842529296875, 399.271533203125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [507.453076171875, 399.347607421875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [509.791357421875, 399.40166015625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [511.8673828125, 399.427685546875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [513.68115234375, 399.41767578125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [515.2406738281251, 399.365625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [516.545947265625, 399.27353515625003, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [517.6009765625, 399.13540039062497, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.194},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [518.4177734375, 398.95322265625003, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [519.000341796875, 398.722998046875, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [519.3606933593751, 398.450732421875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.809},
                    "s": [519.50283203125, 398.138427734375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.808},
                    "s": [519.43876953125, 397.786083984375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [519.178515625, 397.39970703125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [518.7300781250001, 396.9873046875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [518.1094726562501, 396.552880859375, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [517.326708984375, 396.104443359375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [516.397802734375, 395.65000000000003, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [515.33876953125, 395.19956054687503, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [514.213671875, 394.75712890625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [513.08056640625, 394.33271484374995, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [511.95947265625, 393.938330078125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [510.868408203125, 393.585986328125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [509.82539062499995, 393.289697265625, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [508.85244140625, 393.065478515625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [507.9755859375, 392.92734375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [507.216845703125, 392.8953125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [506.612255859375, 393.033447265625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [506.163818359375, 393.36376953125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [505.86752929687503, 393.8642578125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [505.711376953125, 394.51689453125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [505.68735351562503, 395.29765625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 11
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 33}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 49
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Polystar 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sr",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Star",
                "nm": "Polystar Path 1",
                "ix": 1,
                "d": 1,
                "pt": {"a": 0, "k": 3, "ix": 3},
                "p": {"a": 0, "k": [6, 32], "ix": 4},
                "or": {"a": 0, "k": 6.599, "ix": 7},
                "os": {"a": 0, "k": 0, "ix": 9},
                "r": {"a": 0, "k": 0, "ix": 5},
                "sy": 2
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.3922, 0.898, 0.6549], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-7.382, -25.677], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 37
    }, {
        "ty": 4,
        "nm": "Shape Layer 14",
        "mn": "",
        "sr": 1,
        "st": 7,
        "op": 60,
        "ip": 7,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [197.4966796875, 141.64619140625, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [104.91635742187499, 145.295751953125, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.779},
                    "s": [167.677587890625, 161.723779296875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.134},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [219.76640625, 240.420556640625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.028},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [306.108642578125, 164.47646484375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.257},
                    "i": {"x": 0.833, "y": 0.679},
                    "s": [241.5556640625, 118.954052734375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [210.61948242187498, 137.57622070312502, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 1.14},
                    "i": {"x": 0.833, "y": 1.545},
                    "s": [122.4474609375, 202.61166992187498, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [115.49267578125, 169.991845703125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.256},
                    "i": {"x": 0.833, "y": 0.643},
                    "s": [167.93984375000002, 125.70263671875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.109},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [155.053271484375, 171.737548828125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 1.087},
                    "s": [112.6859375, 203.4705078125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [64.28471679687499, 248.83876953125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.178},
                    "i": {"x": 0.833, "y": 1.091},
                    "s": [163.0150390625, 259.02470703125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 1.009},
                    "s": [131.498291015625, 135.578271484375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.008},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [197.378564453125, 77.03916015625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.096},
                    "i": {"x": 0.833, "y": 0.732},
                    "s": [124.7697265625, 112.55380859375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [158.48662109375, 162.71474609375002, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.276},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [233.33564453125, 171.7515625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.377},
                    "i": {"x": 0.833, "y": 1.592},
                    "s": [215.99072265625, 241.82192382812502, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 1.083},
                    "s": [219.1337890625, 223.55610351562498, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 0.998},
                    "s": [193.69296874999998, 185.78125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.002},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [244.45649414062498, 117.5546875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.084},
                    "i": {"x": 0.833, "y": 1.192},
                    "s": [194.79404296875, 140.7052734375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [219.456103515625, 204.52353515625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.063},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [138.020654296875, 243.58964843750002, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.428},
                    "i": {"x": 0.833, "y": 1.514},
                    "s": [184.54804687499998, 149.88623046875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [195.797021484375, 200.485595703125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.175},
                    "i": {"x": 0.833, "y": 0.734},
                    "s": [115.14833984375, 181.947509765625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [141.203759765625, 123.43442382812499, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [198.215380859375, 44.601513671875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.773},
                    "i": {"x": 0.833, "y": 1.045},
                    "s": [136.91557617187502, 68.87519531250001, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [129.51035156249998, 150.02436523437498, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.82},
                    "i": {"x": 0.833, "y": 8.194},
                    "s": [140.907470703125, 190.80214843750002, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [142.19672851562498, 165.455419921875, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.168},
                    "i": {"x": 0.833, "y": 0.654},
                    "s": [29.614892578124998, 45.73662109375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.11},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [66.955322265625, 50.439208984375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [184.74223632812502, 92.7625, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.884},
                    "i": {"x": 0.833, "y": 0.981},
                    "s": [30.127392578125, 98.01962890625, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.024},
                    "i": {"x": 0.833, "y": 0.761},
                    "s": [14.027685546874999, 62.551025390625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 1.054},
                    "s": [26.521874999999998, 77.20732421875, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [49.824609375, 6.534375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.09},
                    "i": {"x": 0.833, "y": 0.6},
                    "s": [11.487207031250001, 24.706103515625, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [29.955224609374998, -3.27119140625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.091},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [100.127685546875, 27.77509765625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.46},
                    "i": {"x": 0.833, "y": 2.185},
                    "s": [66.60498046875, 20.56005859375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.078},
                    "i": {"x": 0.833, "y": 0.869},
                    "s": [71.75, -3.45537109375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.228},
                    "i": {"x": 0.833, "y": 1.164},
                    "s": [-6.56240234375, 112.187451171875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [-51.768505859375004, 39.240283203124996, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.139},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [82.610595703125, -25.967333984375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.118},
                    "i": {"x": 0.833, "y": 1.325},
                    "s": [32.159375, 89.2150390625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [52.99169921875, 60.1787109375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [-49.161962890625, -4.382275390625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.588},
                    "s": [554.286767578125, 502.14990234375, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.763},
                    "s": [552.016552734375, 499.4712890625, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [541.586376953125, 490.308349609375, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [519.1544921875001, 478.072412109375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [491.669677734375, 470.87939453125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [466.957568359375, 468.959521484375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [446.39951171875, 469.80234375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [429.260791015625, 471.958447265625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [414.762646484375, 474.75517578125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [402.350537109375, 477.860205078125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [391.5740234375, 481.111376953125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [382.134814453125, 484.42661132812503, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [373.7966796875, 487.773876953125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [366.407470703125, 491.14716796875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [359.861083984375, 494.572509765625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [354.09946289062503, 498.12998046875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [349.23671875, 501.97373046875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [345.94951171875005, 506.496142578125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [347.05859375, 510.94648437499995, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [350.696142578125, 512.954443359375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [354.26762695312505, 513.839306640625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [357.4767578125, 514.231689453125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [360.301513671875, 514.3578125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [362.75390625, 514.323779296875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [364.86196289062497, 514.187646484375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [366.65371093749997, 513.987451171875, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [368.1591796875, 513.7532226562499, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [369.41240234375005, 513.50498046875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [370.44140625, 513.25673828125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [371.27822265625, 513.022509765625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [371.944873046875, 512.81630859375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [372.4833984375, 512.650146484375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.822},
                    "s": [372.9318359375, 512.554052734375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [373.354248046875, 512.64013671875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [373.762646484375, 512.910400390625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [374.159033203125, 513.3448242187501, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [374.54541015625, 513.92138671875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [374.92177734374997, 514.6200683593751, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [375.2861328125, 515.4228515625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [375.636474609375, 516.31171875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [375.97080078125, 517.2666503906249, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [376.3431640625, 518.265625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [376.8076171875, 519.288623046875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [377.3521484375, 520.321630859375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [377.966748046875, 521.346630859375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [378.64541015625, 522.355615234375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [379.37412109375003, 523.33056640625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [380.14287109375, 524.2634765624999, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [380.9396484375, 525.142333984375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [381.75244140625, 525.95712890625, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [382.56723632812503, 526.699853515625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [383.372021484375, 527.360498046875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [384.15078125, 527.933056640625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 12
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 34}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 50
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 38
    }, {
        "ty": 4,
        "nm": "Shape Layer 13",
        "mn": "",
        "sr": 1,
        "st": 10,
        "op": 60,
        "ip": 10,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.884},
                    "s": [300.33901367187497, 168.76865234375, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.295},
                    "i": {"x": 0.833, "y": 0.718},
                    "s": [231.29765625, 189.7291015625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [204.093115234375, 168.94682617187502, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.243},
                    "i": {"x": 0.833, "y": 1.035},
                    "s": [139.095703125, 134.301025390625, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [105.21464843749999, 126.729638671875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.012},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [153.49775390625, 160.77685546875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.197},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [111.50478515625, 203.02607421875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 1.152},
                    "s": [80.848876953125, 241.99208984375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [39.6787109375, 224.09462890625, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.102},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [156.1603515625, 251.75361328125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [103.707177734375, 151.35966796875, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.135},
                    "i": {"x": 0.833, "y": 1.062},
                    "s": [166.0580078125, 95.79946289062501, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.035},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [142.21474609375, 92.394140625, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.302},
                    "i": {"x": 0.833, "y": 0.784},
                    "s": [183.701220703125, 93.96567382812499, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.136},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [199.504638671875, 115.362548828125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -1.133},
                    "i": {"x": 0.833, "y": 0.883},
                    "s": [224.66318359374998, 244.3904296875, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.289},
                    "i": {"x": 0.833, "y": 4.25},
                    "s": [222.93950195312502, 240.90703125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 1.223},
                    "s": [222.2408203125, 235.52978515625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [250.162060546875, 192.389697265625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": -0.648},
                    "i": {"x": 0.833, "y": 1.488},
                    "s": [147.39179687499998, 149.635986328125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [159.10322265625, 194.043310546875, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.337},
                    "i": {"x": 0.833, "y": 0.44},
                    "s": [78.802880859375, 166.82275390625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [94.732421875, 180.23784179687502, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.099},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [185.87534179687498, 195.24848632812498, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.051},
                    "i": {"x": 0.833, "y": 0.719},
                    "s": [144.174658203125, 296.66142578125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 1.036},
                    "s": [170.035888671875, 230.06044921875002, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [231.491845703125, 113.648876953125, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -14.6},
                    "i": {"x": 0.833, "y": -3.33},
                    "s": [143.606103515625, 126.725634765625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [144.10458984375, 107.1044921875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 1.296},
                    "s": [169.525390625, 222.2248046875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [192.23955078125, 249.52744140625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.241},
                    "i": {"x": 0.833, "y": 1.082},
                    "s": [88.804638671875, 136.367041015625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [34.01318359375, 66.43681640625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.028},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [142.75927734375, 63.297753906249994, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [61.185693359374994, 102.117626953125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.066},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [-5.691552734375, 166.95888671875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.084},
                    "i": {"x": 0.833, "y": 1.188},
                    "s": [31.572802734375003, 73.31953125000001, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [13.058740234375, 51.916650390625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.823},
                    "i": {"x": 0.833, "y": 1.308},
                    "s": [73.28349609375, 57.237841796875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [67.74609375, 117.36250000000001, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 1.022},
                    "s": [93.737451171875, 39.31435546875, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.018},
                    "i": {"x": 0.833, "y": 0.667},
                    "s": [112.40366210937499, -6.944775390625, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.111},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [88.76259765625001, 16.898486328125003, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.227},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [17.8013671875, 60.8033203125, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 1.016},
                    "s": [-23.280712890625, 101.85537109375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.013},
                    "i": {"x": 0.833, "y": 1.012},
                    "s": [52.821533203125, 17.701269531250002, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [-37.975048828125004, 70.75302734374999, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.244},
                    "i": {"x": 0.833, "y": 1.128},
                    "s": [66.166552734375, 130.24506835937498, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [39.622656250000006, -33.628808593749994, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [107.0484375, -2.7586914062499996, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.569},
                    "s": [557.23564453125, 512.405908203125, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.103},
                    "i": {"x": 0.833, "y": 0.794},
                    "s": [554.759228515625, 509.591162109375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.14},
                    "i": {"x": 0.833, "y": 0.801},
                    "s": [545.33203125, 497.41127929687497, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.143},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [548.352978515625, 475.690087890625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [579.8537109375, 478.112451171875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [606.9341308593749, 483.57978515625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [629.315966796875, 488.538623046875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [648.066259765625, 492.670654296875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [664.021826171875, 496.04794921875003, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [677.8212890625, 498.796630859375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [689.8950683593749, 501.018798828125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [700.549462890625, 502.800537109375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [710.018701171875, 504.20390625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [718.48896484375, 505.282958984375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [726.1564453125, 506.147802734375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [733.225341796875, 506.93857421875003, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [739.767724609375, 507.669287109375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [745.8496582031249, 508.34794921875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [751.515185546875, 508.9765625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [756.804345703125, 509.555126953125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [761.74716796875, 510.087646484375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [766.3716796875, 510.57211914062503, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [770.6958984375, 511.00454101562497, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [774.741845703125, 511.384912109375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [778.5215332031249, 511.71123046875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [782.04697265625, 511.98149414062505, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [785.328173828125, 512.189697265625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [788.3711425781249, 512.3378417968751, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [791.185888671875, 512.419921875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [793.7724121093751, 512.437939453125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [796.134716796875, 512.389892578125, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [798.2748046875, 512.27578125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [800.19267578125, 512.093603515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [801.89033203125, 511.845361328125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [803.365771484375, 511.533056640625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [804.614990234375, 511.15869140625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [805.6399902343751, 510.722265625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [806.4347656250001, 510.231787109375, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [806.9953125, 509.6892578125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [807.31962890625, 509.10068359375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [807.401708984375, 508.474072265625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [807.2996093749999, 507.807421875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [807.0673828125, 507.108740234375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [806.6930175781249, 506.384033203125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [806.2185546875, 505.699365234375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [805.7220703125, 505.148828125, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [805.2095703125, 504.72841796874997, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [804.679052734375, 504.438134765625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [804.1365234374999, 504.27197265625, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [803.581982421875, 504.22792968749997, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 15
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 37}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 53
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 39
    }, {
        "ty": 4,
        "nm": "Shape Layer 12",
        "mn": "",
        "sr": 1,
        "st": 11,
        "op": 60,
        "ip": 11,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [140.879443359375, 146.340771484375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.387},
                    "i": {"x": 0.833, "y": 1.011},
                    "s": [163.061083984375, 99.62519531250001, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 0.535},
                    "s": [169.143017578125, 121.612646484375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.814},
                    "s": [162.288330078125, 163.84384765625, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [130.90371093750002, 145.7982421875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 1.16},
                    "s": [92.256005859375, 197.82900390625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [46.5173828125, 135.43212890625, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.086},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [180.321923828125, 170.1419921875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.806},
                    "i": {"x": 0.833, "y": -0.171},
                    "s": [114.53173828125, 136.5732421875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.09},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [120.69775390625, 134.25498046875, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.06},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [201.154248046875, 158.95107421875, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.013},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [154.480712890625, 125.592529296875, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.233},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [194.94218750000002, 68.556884765625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 1.123},
                    "s": [217.43413085937502, 203.29033203125002, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [242.630712890625, 223.30385742187502, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.031},
                    "i": {"x": 0.833, "y": 0.983},
                    "s": [180.245849609375, 196.38759765625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.021},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [225.750244140625, 169.6775390625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.751},
                    "s": [189.40078125, 206.3533203125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.125},
                    "i": {"x": 0.833, "y": 0.906},
                    "s": [153.7099609375, 223.48203125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.74},
                    "i": {"x": 0.833, "y": 1.547},
                    "s": [82.63662109375, 157.083251953125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [73.61181640625, 154.468701171875, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 2.649},
                    "i": {"x": 0.833, "y": 0.441},
                    "s": [141.91445312500002, 117.22236328125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 0.52},
                    "s": [144.1326171875, 238.618798828125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.101},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [156.794970703125, 273.04638671875, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.031},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [217.145849609375, 151.8841796875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [173.174951171875, 202.835888671875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.079},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [93.643359375, 149.36171875, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [134.505224609375, 180.0556640625, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.027},
                    "i": {"x": 0.833, "y": 0.699},
                    "s": [171.97578124999998, 213.922705078125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [143.594091796875, 216.9396484375, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 2.984},
                    "i": {"x": 0.833, "y": 2.929},
                    "s": [69.449755859375, 122.329345703125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 1.084},
                    "s": [67.319677734375, 97.53115234375001, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 1.001},
                    "s": [118.765869140625, 72.98720703125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.001},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [15.63525390625, 185.687158203125, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.168},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [119.999072265625, 74.502685546875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.335},
                    "i": {"x": 0.833, "y": 1.33},
                    "s": [85.34726562499999, 89.413232421875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [73.85205078125, 81.9359375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.04},
                    "i": {"x": 0.833, "y": 0.915},
                    "s": [130.84765625, 119.38247070312501, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 3.347},
                    "i": {"x": 0.833, "y": 0.366},
                    "s": [92.231982421875, 85.6515625, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 0.284},
                    "s": [91.24501953125001, -22.58603515625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [84.724658203125, 62.234716796875, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.033},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [35.19833984375, 63.193652343749996, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 1.171},
                    "i": {"x": 0.833, "y": 4.784},
                    "s": [70.55483398437501, 140.531103515625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 0.95},
                    "s": [73.265478515625, 81.631640625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.123},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [-52.4751953125, 25.80517578125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [-1.5855468750000001, 145.47993164062498, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.335},
                    "i": {"x": 0.833, "y": 0.804},
                    "s": [93.028759765625, -3.629541015625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [124.34331054687499, 38.571630859375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [166.72265625, 41.830810546875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.583},
                    "s": [558.31669921875, 526.7298828125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [553.56005859375, 527.8509765625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [533.7347167968751, 523.188427734375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [501.227001953125, 503.489208984375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [467.774365234375, 477.44580078125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [439.46875, 453.644580078125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [416.0859375, 433.65908203124997, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [396.3546875, 416.97280273437497, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [379.28603515625, 402.91708984375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [364.22133789062497, 390.9814453125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [350.68212890625, 380.78349609375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [338.35810546875, 372.06699218750003, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [327.01103515625, 364.641748046875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [316.454736328125, 358.3736328125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [306.55307617187503, 353.182568359375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [297.21796875, 349.028515625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [288.38935546875, 345.91147460937503, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [280.071240234375, 343.85346679687495, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [272.27963867187503, 342.88251953125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [265.144677734375, 342.990625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [258.78447265625, 344.07167968749997, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [253.29111328125, 345.895458984375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [248.64658203125, 348.181689453125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [244.7587890625, 350.668115234375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [241.505615234375, 353.162548828125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [238.75893554687502, 355.5548828125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [236.416650390625, 357.79707031249995, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [234.406689453125, 359.86508789062503, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [232.6669921875, 361.746923828125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [231.147509765625, 363.440576171875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [229.81621093750002, 364.95205078125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [228.64306640625, 366.2853515625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [227.6140625, 367.452490234375, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [226.7091796875, 368.461474609375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [225.92041015625, 369.3203125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [225.23974609375, 370.031005859375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [224.65717773437498, 370.6095703125, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [224.164697265625, 371.064013671875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.197},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [223.7623046875, 371.39833984375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [223.44199218749998, 371.61455078125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.196},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [223.203759765625, 371.71064453125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [223.02558593749998, 371.77470703125, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [222.883447265625, 371.884814453125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [222.77734375, 372.036962890625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [222.703271484375, 372.221142578125, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [222.65522460937498, 372.43134765625, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [222.63120117187498, 372.65957031249997, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [222.623193359375, 372.893798828125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [222.627197265625, 373.126025390625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 11}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 16
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 38}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 54
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 40
    }, {
        "ty": 4,
        "nm": "Shape Layer 11",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [98.99658203125, 296.849609375, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": -0.246},
                    "i": {"x": 0.833, "y": 1.038},
                    "s": [192.653955078125, 118.7078125, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.026},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [168.94482421875, 77.930029296875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.391},
                    "i": {"x": 0.833, "y": 1.66},
                    "s": [203.334375, 125.65058593750001, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [212.65146484374998, 115.78095703125001, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.472},
                    "i": {"x": 0.833, "y": 0.162},
                    "s": [129.50834960937502, 70.61689453125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [141.984521484375, 115.418603515625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.098},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [254.89667968749998, 149.599951171875, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [203.11416015625, 199.396533203125, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.314},
                    "i": {"x": 0.833, "y": 1.386},
                    "s": [147.529931640625, 136.22089843749998, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 1.064},
                    "s": [159.19931640625, 155.005224609375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [93.433154296875, 86.116015625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.543},
                    "i": {"x": 0.833, "y": 0.358},
                    "s": [209.884765625, 76.512646484375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [194.379638671875, 133.67841796875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [90.40419921875001, 116.87001953125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.142},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [202.18125, 184.13564453125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.105},
                    "i": {"x": 0.833, "y": 0.782},
                    "s": [160.8849609375, 200.79790039062502, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [179.16279296875, 125.06401367187499, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 1.187},
                    "s": [208.70361328125, 136.87954101562502, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [245.385400390625, 175.969677734375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -1.303},
                    "i": {"x": 0.833, "y": -0.546},
                    "s": [126.23515624999999, 140.90947265625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [133.396142578125, 69.806103515625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.233},
                    "i": {"x": 0.833, "y": 0.782},
                    "s": [259.05874023437497, 144.162646484375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.806},
                    "s": [225.946435546875, 180.39799804687502, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.146},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [172.6244140625, 229.0154296875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.514},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [101.881396484375, 165.014990234375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 1.248},
                    "s": [111.7490234375, 88.176025390625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.895},
                    "s": [99.36093749999999, 107.32470703125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.404},
                    "i": {"x": 0.833, "y": 0.992},
                    "s": [148.655029296875, 228.729150390625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.009},
                    "i": {"x": 0.833, "y": 0.123},
                    "s": [161.459521484375, 156.943115234375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [149.86821289062502, 151.78208007812498, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.176},
                    "i": {"x": 0.833, "y": 0.802},
                    "s": [39.442480468750006, 152.67495117187502, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.144},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [74.859033203125, 160.686767578125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.243},
                    "i": {"x": 0.833, "y": 0.507},
                    "s": [123.530517578125, 83.17314453125, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.1},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [111.084375, 200.55166015625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 1.039},
                    "s": [49.970751953124996, 155.39760742187502, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.026},
                    "i": {"x": 0.833, "y": 1.03},
                    "s": [0.750732421875, 136.182861328125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 1.01},
                    "s": [72.801025390625, 66.374755859375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.009},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [-25.066455078125003, 113.5267578125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.219},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [85.02294921875, -8.594384765625, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [152.875146484375, 66.90927734374999, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.108},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [26.864208984375, 74.246435546875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.028},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [81.749755859375, 33.45263671875, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.176},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [40.791796875, 68.328662109375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 1.367},
                    "i": {"x": 0.833, "y": 3.32},
                    "s": [53.9486328125, 3.377294921875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 1.176},
                    "s": [54.803466796875, 73.90009765625, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [30.171435546875, 82.64462890624999, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.019},
                    "i": {"x": 0.833, "y": 0.862},
                    "s": [106.81220703125, 132.90566406250002, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.21},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [44.4994140625, 162.312353515625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.242},
                    "i": {"x": 0.833, "y": 1.145},
                    "s": [3.4573730468750004, -18.119677734375, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.053},
                    "i": {"x": 0.833, "y": 0.874},
                    "s": [-18.081640625, 55.702343750000004, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.245},
                    "i": {"x": 0.833, "y": 0.791},
                    "s": [41.04404296875, 7.469287109375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [71.52978515625, 8.754541015625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [117.346484375, 74.55874023437501, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [60.100634765625, 64.15659179687499, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.595},
                    "s": [562.338623046875, 527.124267578125, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [555.52197265625, 529.2303222656251, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [529.75283203125, 539.039892578125, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [483.365576171875, 558.65302734375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [432.56201171875, 582.1699707031249, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [388.793310546875, 604.0813476562499, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [352.714111328125, 623.390185546875, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [322.69482421875, 640.42080078125, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [297.27602539062497, 655.617626953125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [275.434716796875, 669.32900390625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [256.44619140625, 681.815185546875, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [239.803955078125, 693.26435546875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [225.129638671875, 703.82265625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [212.152978515625, 713.6001953125, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [200.661767578125, 722.68505859375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [190.495849609375, 731.1453125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [181.531103515625, 739.041015625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [173.67343749999998, 746.4162109375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [166.82675781249998, 753.304931640625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [160.82089843749998, 759.72119140625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [155.5677734375, 765.689013671875, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [150.997314453125, 771.230419921875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [147.04345703125, 776.3654296875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [143.64814453125, 781.108056640625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [140.763330078125, 785.47431640625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [138.33896484375, 789.47421875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [136.32500000000002, 793.123779296875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [134.685400390625, 796.4350097656251, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [133.37812499999998, 799.419921875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [132.36513671875, 802.09453125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [131.61640625, 804.46484375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [131.10190429687498, 806.540869140625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [130.79160156249998, 808.334619140625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [130.657470703125, 809.8541015625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [130.6794921875, 811.111328125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [130.829638671875, 812.1123046875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [131.089892578125, 812.867041015625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [131.438232421875, 813.379541015625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [131.856640625, 813.659814453125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [132.32509765625, 813.711865234375, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [132.827587890625, 813.539697265625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [133.32207031250002, 813.255419921875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [133.768505859375, 812.959130859375, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [134.15087890625, 812.648828125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [134.451171875, 812.314501953125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [134.649365234375, 811.9521484375, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [134.72744140625, 811.5517578125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [134.66337890625002, 811.10732421875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [134.44716796875, 810.6348632812501, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [134.116845703125, 810.23046875, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [133.696435546875, 809.9121582031249, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [133.207958984375, 809.68193359375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [132.667431640625, 809.5417968749999, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [132.09287109375, 809.49375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [131.50029296875002, 809.539794921875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 41
    }, {
        "ty": 4,
        "nm": "Shape Layer 10",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [255.12490234375, 181.358935546875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.22},
                    "i": {"x": 0.833, "y": 1.119},
                    "s": [180.36796875000002, 56.086718749999996, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [134.75546875, 162.926953125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.141},
                    "i": {"x": 0.833, "y": 0.746},
                    "s": [245.31533203125, 139.67626953125, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [204.2392578125, 198.00517578125002, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.21},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [120.187255859375, 180.26186523437502, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.051},
                    "i": {"x": 0.833, "y": 1.429},
                    "s": [144.0765625, 233.129443359375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 0.886},
                    "s": [129.29814453125, 127.009912109375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.307},
                    "i": {"x": 0.833, "y": 1.12},
                    "s": [220.196826171875, 89.349169921875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [254.121923828125, 119.76484375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.316},
                    "i": {"x": 0.833, "y": 0.971},
                    "s": [171.3751953125, 147.02744140624998, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.045},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [141.680224609375, 141.74228515625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [160.93500976562498, 193.64892578125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 1.79},
                    "i": {"x": 0.833, "y": 0.179},
                    "s": [180.754345703125, 176.017724609375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 1.589},
                    "s": [181.7212890625, 117.25239257812501, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.073},
                    "i": {"x": 0.833, "y": 0.878},
                    "s": [190.283642578125, 108.948291015625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.262},
                    "i": {"x": 0.833, "y": 1.33},
                    "s": [121.1982421875, 137.09375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.901},
                    "s": [88.96079101562499, 72.51474609375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.525},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [248.80073242187498, 95.7814453125, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.486},
                    "i": {"x": 0.833, "y": -1.352},
                    "s": [278.978173828125, 92.972705078125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.883},
                    "s": [274.561865234375, 202.4935546875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.29},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [154.34458007812498, 207.452392578125, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.222},
                    "i": {"x": 0.833, "y": 1.16},
                    "s": [105.943359375, 135.33603515625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.876},
                    "s": [76.856982421875, 132.82158203125002, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.255},
                    "i": {"x": 0.833, "y": 1.058},
                    "s": [161.855908203125, 177.006689453125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.034},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [203.22626953124998, 218.26494140625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.446},
                    "i": {"x": 0.833, "y": 1.242},
                    "s": [132.90766601562498, 190.281640625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.892},
                    "s": [116.73388671875, 135.027734375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.364},
                    "i": {"x": 0.833, "y": 1.044},
                    "s": [179.909521484375, 228.462890625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.587},
                    "s": [198.6578125, 106.786181640625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.104},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [170.01787109375002, 190.091455078125, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 1.495},
                    "i": {"x": 0.833, "y": 1.439},
                    "s": [56.585205078125, 219.572216796875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 1.035},
                    "s": [49.888671875, 153.27753906249998, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [91.835595703125, 113.61284179687499, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.138},
                    "i": {"x": 0.833, "y": 0.603},
                    "s": [32.23544921875, 130.413232421875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [54.679345703124994, -11.016748046875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [139.19179687500002, 104.27373046875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -1.056},
                    "i": {"x": 0.833, "y": 1.395},
                    "s": [26.720068359375, 111.879150390625, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [34.948095703125, 53.085791015625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.906},
                    "s": [-12.239941406249999, 92.41015625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.74},
                    "i": {"x": 0.833, "y": 1.079},
                    "s": [29.911181640625003, 71.992236328125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 1.225},
                    "s": [35.262402343750004, 89.593408203125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 1.151},
                    "s": [24.82822265625, 44.477392578125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [63.37783203125, 49.900683593749996, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.115},
                    "i": {"x": 0.833, "y": 0.879},
                    "s": [-44.8037109375, 133.584326171875, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.269},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [0.54052734375, 61.652148437499996, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [20.866357421875, 15.977587890625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [40.773779296875, 52.3470703125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 1.012},
                    "s": [61.788281250000004, -13.823486328125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [86.99086914062501, 39.088134765625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.017},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [58.13271484375, 49.696484375000004, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [82.122119140625, 31.897119140625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.609},
                    "s": [544.75947265625, 510.640185546875, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [541.562353515625, 511.272802734375, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [530.1552246093751, 515.2246582031249, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [509.80537109375, 523.49072265625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [488.094189453125, 534.499462890625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [472.238720703125, 548.2548828125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [476.935302734375, 563.3275878906251, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [490.17021484375, 570.284375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [502.324072265625, 574.6626464843749, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [513.1025878906249, 577.997900390625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [522.7099609375, 580.7906250000001, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [531.312353515625, 583.255029296875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [539.063916015625, 585.505224609375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [546.08076171875, 587.607275390625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [552.448974609375, 589.605224609375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [558.238623046875, 591.52109375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [563.5137695312501, 593.37890625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [568.3124511718751, 595.188671875, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [572.664697265625, 596.96240234375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [576.59453125, 598.7041015625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [580.12197265625, 600.42177734375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [583.24501953125, 602.1134277343749, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [585.961669921875, 603.773046875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [588.271923828125, 605.39462890625, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [590.1777832031249, 606.9701660156251, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [591.675244140625, 608.4916503906251, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [592.75830078125, 609.91904296875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [593.436962890625, 611.1982910156249, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [593.745263671875, 612.291357421875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [593.7272460937501, 613.168212890625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [593.428955078125, 613.8708984375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [592.91845703125, 614.4674804687501, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [592.26181640625, 614.965966796875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [591.5130859375, 615.3863769531249, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [590.718310546875, 615.7487304687501, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [589.909521484375, 616.067041015625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [589.11875, 616.35732421875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [588.37001953125, 616.6255859375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [587.68134765625, 616.8818359375, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [587.0707519531251, 617.1300781250001, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [586.5482421875, 617.372314453125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [586.105810546875, 617.65859375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [585.7294433593751, 618.03095703125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [585.4271484375, 618.4854003906249, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [585.20693359375, 619.013916015625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [585.0687988281251, 619.6125000000001, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [585.016748046875, 620.271142578125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [585.0547851562501, 620.9838378906251, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [585.17890625, 621.7345703125001, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [585.387109375, 622.517333984375, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [585.67939453125, 623.314111328125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [586.04775390625, 624.114892578125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 42
    }, {
        "ty": 4,
        "nm": "Shape Layer 9",
        "mn": "",
        "sr": 1,
        "st": 10,
        "op": 60,
        "ip": 10,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [219.942578125, 140.314892578125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [173.635400390625, 136.63730468749998, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [129.9748046875, 178.944580078125, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.797},
                    "s": [98.438037109375, 294.26708984375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [140.431005859375, 189.19658203125, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -1.941},
                    "i": {"x": 0.833, "y": 2.944},
                    "s": [200.885986328125, 168.610498046875, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 1.003},
                    "s": [198.39755859375, 128.76962890625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.002},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [258.92060546875, 206.715673828125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.977},
                    "s": [196.553759765625, 146.20463867187502, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.032},
                    "i": {"x": 0.833, "y": 0.969},
                    "s": [141.179736328125, 146.440869140625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.048},
                    "i": {"x": 0.833, "y": 0.819},
                    "s": [181.138720703125, 151.73203125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.879},
                    "s": [155.83203125, 148.59296875, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.269},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [126.257177734375, 128.97783203125002, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.698},
                    "s": [112.95419921874999, 146.14057617187498, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.807},
                    "s": [137.616259765625, 158.40654296875002, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [202.36142578124998, 150.857177734375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 1.265},
                    "i": {"x": 0.833, "y": 1.698},
                    "s": [287.498486328125, 109.056396484375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 0.73},
                    "s": [293.50234375, 128.0689453125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 0.888},
                    "s": [237.17539062499998, 185.76123046875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.324},
                    "i": {"x": 0.833, "y": 1.05},
                    "s": [110.89619140625, 118.50361328125001, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [67.12548828125, 207.524462890625, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.22},
                    "i": {"x": 0.833, "y": 1.028},
                    "s": [137.23588867187502, 152.37265624999998, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.021},
                    "i": {"x": 0.833, "y": 0.9},
                    "s": [180.10771484375, 168.065966796875, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.497},
                    "i": {"x": 0.833, "y": 2.007},
                    "s": [122.9119140625, 221.72031249999998, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.077},
                    "i": {"x": 0.833, "y": 0.966},
                    "s": [111.388671875, 113.983203125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.058},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [262.10771484375, 227.688134765625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.086},
                    "i": {"x": 0.833, "y": 1.106},
                    "s": [173.04682617187498, 154.08833007812498, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.866},
                    "s": [216.79951171874998, 190.49785156250002, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.221},
                    "i": {"x": 0.833, "y": 1.04},
                    "s": [117.204345703125, 178.45610351562502, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.947},
                    "s": [56.807421875, 132.40717773437498, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.148},
                    "i": {"x": 0.833, "y": 0.89},
                    "s": [146.108544921875, 88.063916015625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.339},
                    "i": {"x": 0.833, "y": 1.128},
                    "s": [113.981201171875, 110.83212890625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 1.199},
                    "s": [103.51298828125, 25.843212890625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [130.01884765625, 131.9927734375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.238},
                    "i": {"x": 0.833, "y": 1.252},
                    "s": [40.29931640625, 144.797265625, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.063},
                    "i": {"x": 0.833, "y": 0.97},
                    "s": [63.53798828125, 75.24941406250001, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.046},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [-29.96123046875, 145.880322265625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.373},
                    "i": {"x": 0.833, "y": 1.07},
                    "s": [30.111376953125, 118.19931640625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 1.075},
                    "s": [47.40625, 152.2125, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 1.071},
                    "s": [15.59521484375, 33.74892578125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.941},
                    "s": [75.9220703125, 2.178125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.205},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [-35.500634765625, 23.346777343750002, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.814},
                    "s": [-3.2551757812499997, 112.81406249999999, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [36.86796875, 69.844140625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.441},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [86.138037109375, 117.17231445312501, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [97.60322265625, 34.74189453125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.259},
                    "i": {"x": 0.833, "y": 1.206},
                    "s": [111.32861328125, 35.666796874999996, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 0.986},
                    "s": [117.84296875, 9.4732421875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.017},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [95.25693359375, -34.87802734375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [113.989208984375, 41.868847656250004, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.607},
                    "s": [560.274609375, 511.040576171875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.106},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [556.1505859375, 513.17265625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [539.964794921875, 519.200537109375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [509.46103515625, 526.263427734375, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [493.0490234375, 505.35703125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [505.83349609375, 478.464794921875, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [518.55791015625, 457.115966796875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [529.458544921875, 439.25253906250003, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [538.607470703125, 423.80146484374995, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [546.2569335937501, 410.114111328125, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [552.6411621093749, 397.75004882812505, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [557.954345703125, 386.410986328125, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [562.3246093749999, 375.8947265625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [565.69189453125, 366.17124023437503, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [568.116259765625, 357.17646484374995, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [569.6877929687499, 348.842333984375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [570.486572265625, 341.1248046875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [570.5806640625, 333.987841796875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [570.034130859375, 327.429443359375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [568.91103515625, 321.45161132812495, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [567.289453125, 316.05634765625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [565.261474609375, 311.263671875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [562.9271972656251, 307.07158203125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [560.4007324218751, 303.4640625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [557.790185546875, 300.41708984375003, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [555.19365234375, 297.88662109375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [552.6952148437499, 295.818603515625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [550.358935546875, 294.164990234375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [548.228857421875, 292.871728515625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [546.3330078125, 291.88876953125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [544.69541015625, 291.174072265625, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [543.32607421875, 290.6916015625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.196},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [542.22900390625, 290.4033203125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [541.40419921875, 290.281201171875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.226},
                    "i": {"x": 0.833, "y": 0.86},
                    "s": [540.845654296875, 290.293212890625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.205},
                    "i": {"x": 0.833, "y": 0.798},
                    "s": [540.54736328125, 290.41733398437503, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.789},
                    "s": [540.493310546875, 290.62353515625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.138},
                    "i": {"x": 0.833, "y": 0.804},
                    "s": [540.66748046875, 290.8837890625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.814},
                    "s": [541.05185546875, 291.178076171875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.151},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [541.6304199218749, 291.48237304687495, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [542.3831542968751, 291.77265625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [543.223974609375, 291.99287109375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [544.07080078125, 292.09096679687497, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [544.931640625, 292.0369140625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [545.7904785156251, 291.8447265625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [546.631298828125, 291.52841796875003, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [547.4380859375, 291.10800781250003, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [548.1928222656251, 290.59750976562503, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [548.885498046875, 290.01494140625005, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [549.502099609375, 289.372314453125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 10}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 15
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 37}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 53
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Rectangle 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "sh",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Group",
                "nm": "Path 1",
                "ix": 1,
                "d": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "c": true,
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[0.012, 10.621], [-5.695, 5.34], [-2.557, -7.377], [3.15, -2.096]]
                    },
                    "ix": 2
                }
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [1, 1, 1], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [-3.421, 4.043], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 43
    }, {
        "ty": 4,
        "nm": "Shape Layer 8",
        "mn": "",
        "sr": 1,
        "st": 9,
        "op": 60,
        "ip": 9,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [242.78486328125, 138.581201171875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.239},
                    "i": {"x": 0.833, "y": 0.683},
                    "s": [204.97797851562498, 150.468798828125, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [184.70419921875, 124.10908203125, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.026},
                    "i": {"x": 0.833, "y": 0.931},
                    "s": [127.850732421875, 209.742626953125, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.401},
                    "i": {"x": 0.833, "y": 0.274},
                    "s": [171.030859375, 170.16801757812502, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 1.092},
                    "s": [163.60361328124998, 216.36909179687498, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [106.3197265625, 197.27846679687502, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.286},
                    "i": {"x": 0.833, "y": 1.078},
                    "s": [226.827294921875, 263.58115234375003, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [276.34760742187495, 202.84189453125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 1.209},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [180.341943359375, 167.28520507812502, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.061},
                    "i": {"x": 0.833, "y": 2.383},
                    "s": [173.235009765625, 125.58251953125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [177.32900390625, 172.133935546875, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.181},
                    "i": {"x": 0.833, "y": 0.633},
                    "s": [105.29873046875, 239.683837890625, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [128.026904296875, 151.62192382812498, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 1.9},
                    "i": {"x": 0.833, "y": -0.114},
                    "s": [205.49248046875, 220.132763671875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.09},
                    "i": {"x": 0.833, "y": 0.968},
                    "s": [209.04594726562502, 223.652197265625, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.051},
                    "i": {"x": 0.833, "y": 0.984},
                    "s": [252.984814453125, 173.51127929687502, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.02},
                    "i": {"x": 0.833, "y": 1.465},
                    "s": [225.71621093750002, 171.901708984375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.895},
                    "s": [247.675634765625, 177.27495117187502, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.407},
                    "i": {"x": 0.833, "y": 1.153},
                    "s": [103.27675781250001, 95.629296875, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [66.070458984375, 205.48046875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.892},
                    "i": {"x": 0.833, "y": 1.199},
                    "s": [171.569384765625, 212.73554687499998, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 1.078},
                    "s": [162.55458984375, 117.10024414062501, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [193.04833984375, 227.6861328125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [134.15087890625, 162.636669921875, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.963},
                    "s": [198.80595703125, 220.7833984375, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.067},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [118.797900390625, 213.746533203125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 1.189},
                    "s": [163.07309570312498, 201.426513671875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.058},
                    "i": {"x": 0.833, "y": 0.974},
                    "s": [212.65146484374998, 110.413720703125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.038},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [50.343115234375, 116.67983398437501, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": -0.061},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [161.795849609375, 89.813623046875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.137},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [97.53515625, 26.2416015625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.058},
                    "s": [121.856884765625, 58.094677734375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.034},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [146.164599609375, 87.755615234375, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 1.288},
                    "s": [104.888330078125, 103.811279296875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [129.868701171875, 19.002539062500002, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.193},
                    "i": {"x": 0.833, "y": 0.756},
                    "s": [18.690234375, 116.49365234375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 1.038},
                    "s": [52.272998046875, 129.035888671875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.026},
                    "i": {"x": 0.833, "y": 1.014},
                    "s": [116.9140625, 150.306640625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.012},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [23.04248046875, 70.602880859375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.026},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [132.62939453125, 33.4646484375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.377},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [49.33212890625, -32.0232421875, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [64.398828125, 80.320361328125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.193},
                    "i": {"x": 0.833, "y": 1.214},
                    "s": [81.00703125, 148.454833984375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.906},
                    "s": [75.994140625, 135.081787109375, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.711},
                    "i": {"x": 0.833, "y": -0.566},
                    "s": [93.85756835937501, 48.53134765625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 1.073},
                    "s": [96.227880859375, 91.5853515625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 0.938},
                    "s": [138.41704101562502, 15.557177734375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": -0.247},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [59.0736328125, -64.190625, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [79.07514648437501, 34.147314453125, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [54.06474609375, 69.299609375, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.626},
                    "s": [558.27265625, 512.1396484375, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.107},
                    "i": {"x": 0.833, "y": 0.768},
                    "s": [552.1626953125, 513.831298828125, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [531.944970703125, 522.84609375, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [502.22597656249997, 548.4230468750001, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [492.54052734375, 589.39501953125, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [504.76044921874995, 623.918701171875, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [522.4917480468749, 648.40458984375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [540.4092285156249, 666.073828125, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [557.2676757812501, 679.084521484375, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [572.862890625, 688.719921875, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [587.2369140625, 695.748779296875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [600.4838378906251, 700.6595703125, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [612.69375, 703.772607421875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [623.9527343750001, 705.3061035156251, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [634.2507812499999, 705.414208984375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [643.571875, 704.2470703125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [651.92001953125, 701.958837890625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [659.2591796875, 698.743701171875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [665.559326171875, 694.855908203125, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [670.8885253906251, 690.55771484375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [675.368896484375, 686.079345703125, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [679.142578125, 681.6009765625, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [682.34970703125, 677.2547363281251, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [685.11640625, 673.12470703125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [687.544775390625, 669.250927734375, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [689.714892578125, 665.65341796875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [691.684814453125, 662.336181640625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [693.49658203125, 659.297216796875, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [695.1942382812499, 656.4884765625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [696.85185546875, 653.811865234375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [698.46943359375, 651.269384765625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [700.032958984375, 648.8710449218751, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [701.534423828125, 646.6228515624999, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [702.955810546875, 644.530810546875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [704.285107421875, 642.604931640625, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [705.506298828125, 640.851220703125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [706.611376953125, 639.26767578125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [707.5863281249999, 637.8603027343751, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [708.419140625, 636.635107421875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [709.101806640625, 635.598095703125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [709.626318359375, 634.7552734375, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [709.9966796875, 634.0265625000001, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [710.21689453125, 633.33388671875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [710.2869628906251, 632.683251953125, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [710.2088867187499, 632.086669921875, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [709.98466796875, 631.550146484375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [709.622314453125, 631.0796875000001, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [709.1318359375, 630.68330078125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [708.52724609375, 630.360986328125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [707.820556640625, 630.1227539062501, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [707.0337890625001, 629.9686035156251, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 9}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 14
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 36}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 52
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 44
    }, {
        "ty": 4,
        "nm": "Shape Layer 7",
        "mn": "",
        "sr": 1,
        "st": 5,
        "op": 60,
        "ip": 5,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.747},
                    "s": [134.2990234375, 259.75341796875, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 0.927},
                    "s": [124.78173828125, 218.60927734375, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": -0.593},
                    "i": {"x": 0.833, "y": 1.16},
                    "s": [219.64228515625, 165.951904296875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.95},
                    "s": [207.95087890624998, 126.4833984375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": -0.126},
                    "i": {"x": 0.833, "y": 0.652},
                    "s": [242.062158203125, 217.15385742187502, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.11},
                    "i": {"x": 0.833, "y": 0.996},
                    "s": [228.496923828125, 114.842041015625, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": -0.004},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [185.454931640625, 154.26650390625002, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.717},
                    "s": [226.33681640625002, 129.65849609375002, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [189.831201171875, 209.67656250000002, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": -0.095},
                    "i": {"x": 0.833, "y": 0.686},
                    "s": [102.185693359375, 232.993310546875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.935},
                    "s": [143.1796875, 248.356298828125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.292},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [256.586328125, 192.037353515625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [231.38374023437498, 165.37734375, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.223},
                    "i": {"x": 0.833, "y": 0.512},
                    "s": [203.991015625, 150.242578125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.1},
                    "i": {"x": 0.833, "y": 0.973},
                    "s": [187.69912109375, 187.977392578125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.041},
                    "i": {"x": 0.833, "y": 0.898},
                    "s": [108.60595703125, 262.99257812499997, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.465},
                    "i": {"x": 0.833, "y": 0.724},
                    "s": [161.67373046875, 175.713427734375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.119},
                    "i": {"x": 0.833, "y": 0.924},
                    "s": [173.2490234375, 182.3939453125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.912},
                    "i": {"x": 0.833, "y": 0.904},
                    "s": [200.03115234375002, 244.32636718749998, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.63},
                    "i": {"x": 0.833, "y": 11.64},
                    "s": [197.78896484375, 171.957763671875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 1.179},
                    "s": [197.44663085937498, 239.683837890625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.057},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [241.485595703125, 199.624755859375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.428},
                    "i": {"x": 0.833, "y": 1.129},
                    "s": [103.0205078125, 126.008935546875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [69.587890625, 199.868994140625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [154.580810546875, 262.550146484375, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.319},
                    "i": {"x": 0.833, "y": 1.072},
                    "s": [230.65703125, 157.17333984375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 0.783},
                    "s": [257.51523437500003, 226.014501953125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.135},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [207.588525390625, 157.0412109375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.662},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [127.454345703125, 155.63583984375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 1.628},
                    "s": [136.40908203125002, 219.640283203125, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.074},
                    "i": {"x": 0.833, "y": 1.039},
                    "s": [126.6435546875, 165.68964843749998, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [209.98486328125, 103.0125, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.727},
                    "i": {"x": 0.833, "y": 1.219},
                    "s": [87.35122070312501, 52.0908203125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.892},
                    "s": [99.963525390625, 145.531982421875, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.367},
                    "i": {"x": 0.833, "y": 1.243},
                    "s": [54.202880859375, 19.46298828125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [40.769775390625, 94.678369140625, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [93.36708984375, 30.62587890625, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.284},
                    "i": {"x": 0.833, "y": 0.362},
                    "s": [146.97939453125002, 33.162353515625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [134.813525390625, -45.854736328125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.315},
                    "i": {"x": 0.833, "y": 1.174},
                    "s": [53.890576171875004, 12.680371093749999, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [24.8322265625, 102.54404296875, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.264},
                    "i": {"x": 0.833, "y": 1.142},
                    "s": [114.415625, 36.873974609375004, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.933},
                    "s": [92.91064453125, 26.862207031249998, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.344},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [150.99130859375, 65.63803710937499, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 1.055},
                    "s": [139.674267578125, -24.19560546875, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.033},
                    "i": {"x": 0.833, "y": 1.398},
                    "s": [130.421240234375, 26.972314453125, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.884},
                    "s": [145.8142578125, 117.66279296875, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.296},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [56.8314453125, 123.070068359375, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 1.317},
                    "i": {"x": 0.833, "y": 3.369},
                    "s": [21.971435546875, 22.704150390625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 1.072},
                    "s": [19.617138671875, 55.015673828125, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.039},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [88.892724609375, 82.39638671875001, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [-40.389404296875, -28.309619140625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 1.504},
                    "i": {"x": 0.833, "y": 0.193},
                    "s": [50.144921874999994, 39.894921875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [55.4541015625, 28.1794921875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [101.53305664062499, 99.50307617187501, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.638},
                    "s": [540.417236328125, 514.38583984375, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.768},
                    "s": [535.031982421875, 511.8673828125, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.825},
                    "s": [516.5019042968751, 504.698388671875, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [482.316552734375, 495.699609375, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [443.967138671875, 488.322412109375, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [410.506494140625, 482.004248046875, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [382.82749023437503, 475.6640625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [359.97519531250003, 468.847412109375, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [341.00869140624997, 461.392138671875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [325.30937500000005, 453.30825195312497, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [312.4408203125, 444.69785156250003, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [302.08671875, 435.74912109375003, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [293.940771484375, 426.68828125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [287.70068359375, 417.75556640625, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [283.058154296875, 409.151171875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [279.712890625, 401.0232421875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [277.400634765625, 393.465869140625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [275.94921875, 386.4169921875, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [275.19448242187497, 379.810546875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [274.97226562500003, 373.646533203125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [275.15244140625003, 367.916943359375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [275.62890625, 362.61176757812495, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [276.317578125, 357.72099609375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [277.148388671875, 353.22861328125003, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [278.065283203125, 349.122607421875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [279.02021484375, 345.38896484375005, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [279.979150390625, 342.011669921875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [280.90805664062503, 338.980712890625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [281.78090820312497, 336.28408203125, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [282.5796875, 333.907763671875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [283.286376953125, 331.84375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [283.88896484375005, 330.08002929687495, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [284.379443359375, 328.60458984375, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.192},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [284.751806640625, 327.409423828125, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.201},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [285.004052734375, 326.4865234375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.218},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [285.1341796875, 325.82387695312497, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.243},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [285.14418945312497, 325.40546875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [285.044091796875, 325.2212890625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.791},
                    "s": [284.837890625, 325.26132812500003, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.139},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [284.53359374999997, 325.51557617187495, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [284.147216796875, 325.972021484375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [283.69677734375, 326.540576171875, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [283.20830078125, 327.13916015625, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [282.69780273437505, 327.75576171874997, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [282.185302734375, 328.382373046875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [281.692822265625, 329.00698242187497, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [281.2423828125, 329.619580078125, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [280.735888671875, 330.2341796875, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [280.107275390625, 330.85478515625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [279.38457031250005, 331.46137695312495, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [278.59980468749995, 332.0359375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [277.77700195312497, 332.564453125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [276.940185546875, 333.030908203125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [276.111376953125, 333.42529296875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [275.31259765625, 333.73759765625, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 5}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 10
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 32}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 48
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 45
    }, {
        "ty": 4,
        "nm": "Shape Layer 6",
        "mn": "",
        "sr": 1,
        "st": 12,
        "op": 60,
        "ip": 12,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [239.3294921875, 207.74267578125, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.32},
                    "i": {"x": 0.833, "y": 1.117},
                    "s": [151.12143554687498, 195.22246093750002, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [120.019091796875, 186.42788085937502, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [194.96621093750002, 133.23798828125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -2.464},
                    "i": {"x": 0.833, "y": -2.744},
                    "s": [265.164697265625, 95.3490234375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [262.86845703125005, 162.9189453125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.42},
                    "i": {"x": 0.833, "y": 1.123},
                    "s": [161.968017578125, 236.0703125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.05},
                    "i": {"x": 0.833, "y": 1.005},
                    "s": [136.959619140625, 178.6943359375, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.004},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [198.80595703125, 194.635888671875, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -3.282},
                    "i": {"x": 0.833, "y": -0.384},
                    "s": [133.50224609375002, 122.473486328125, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.766},
                    "s": [135.11982421875, 194.58984375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [160.35244140625, 143.974462890625, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.201},
                    "i": {"x": 0.833, "y": 1.241},
                    "s": [206.091064453125, 247.315283203125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [238.37255859375, 165.44340820312502, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.378},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [112.559814453125, 145.612060546875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.089},
                    "i": {"x": 0.833, "y": 1.764},
                    "s": [135.306005859375, 225.313818359375, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 0.911},
                    "s": [124.32729492187501, 257.58330078125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 1.415},
                    "i": {"x": 0.833, "y": 1.018},
                    "s": [235.972216796875, 232.16850585937502, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.015},
                    "i": {"x": 0.833, "y": 0.145},
                    "s": [242.96103515624998, 260.718359375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [234.42070312500002, 165.70166015625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [155.389599609375, 100.94448242187501, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.172},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [241.35947265625, 196.88007812499998, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 1},
                    "s": [213.27607421875, 190.39775390625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0},
                    "i": {"x": 0.833, "y": 1.217},
                    "s": [192.97026367187502, 174.29404296875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [213.1599609375, 90.658447265625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.179},
                    "i": {"x": 0.833, "y": 1.104},
                    "s": [140.33090820312498, 237.057275390625, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 1.008},
                    "s": [163.445458984375, 171.703515625, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [111.390673828125, 234.0203125, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 1.675},
                    "i": {"x": 0.833, "y": -0.011},
                    "s": [168.13803710937498, 106.44985351562501, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.17},
                    "s": [171.10893554687502, 98.38999023437499, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 0.916},
                    "s": [204.205224609375, 54.819482421875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 7.85},
                    "i": {"x": 0.833, "y": 1.131},
                    "s": [103.763232421875, 45.314208984375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": -1.033},
                    "s": [102.686181640625, 141.78232421875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 0.9},
                    "s": [105.4548828125, 20.08359375, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.491},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [170.232080078125, 14.055712890625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 1.048},
                    "s": [183.4669921875, 78.0681640625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [197.47465820312502, 24.934326171875, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 0.425},
                    "s": [175.337060546875, 7.773583984375, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.097},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [158.820947265625, 51.256005859375, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.932},
                    "s": [61.437939453125, 82.51650390625001, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.362},
                    "i": {"x": 0.833, "y": 0.381},
                    "s": [-28.06337890625, 56.79541015625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 1.031},
                    "s": [-11.30302734375, 7.2911132812499995, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 0.941},
                    "s": [96.4220703125, 78.90097656249999, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.197},
                    "i": {"x": 0.833, "y": 0.726},
                    "s": [-51.099853515625, 63.58603515625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.12},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [-7.23505859375, 50.38916015625, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 2.415},
                    "i": {"x": 0.833, "y": 2.387},
                    "s": [93.120849609375, -16.86845703125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.079},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [96.708349609375, 92.27602539062501, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [33.410595703125, -9.090869140625001, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.603},
                    "s": [538.567431640625, 506.824462890625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [542.583349609375, 502.70244140625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [556.244677734375, 485.884033203125, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [578.740625, 453.564501953125, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [600.832177734375, 415.935791015625, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [617.9228515625, 382.050732421875, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [630.757373046875, 353.26665039062505, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [640.6270019531249, 328.7927734375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [648.42861328125, 307.75424804687503, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [654.736767578125, 289.472412109375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [659.9318359375, 273.44277343749997, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [664.2700683593749, 259.2849609375, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [667.9276367187499, 246.710693359375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [671.03466796875, 235.49375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [673.6892578125, 225.45595703125, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [675.95947265625, 216.449169921875, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [677.9013671875, 208.349267578125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [679.5589843749999, 201.0521484375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [680.968359375, 194.46772460937498, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [682.1535156250001, 188.51591796875, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [683.1404785156251, 183.12666015625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [683.9452636718751, 178.23588867187502, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [684.587890625, 173.78554687500002, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [685.078369140625, 169.72158203125002, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [685.4166992187501, 165.99794921875002, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [685.5368164062501, 162.638671875, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [685.458740234375, 159.61171875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [685.218505859375, 156.8810546875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [684.850146484375, 154.408642578125, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [684.3896972656249, 152.16845703125, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [683.861181640625, 150.13046875, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [683.2946289062501, 148.270654296875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [682.712060546875, 146.568994140625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [682.1374999999999, 145.00747070312502, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [681.58896484375, 143.5720703125, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [681.082470703125, 142.248779296875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [680.63203125, 141.027587890625, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [680.253662109375, 139.90048828125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [679.95537109375, 138.865478515625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [679.74716796875, 137.9185546875, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [679.6310546875, 137.055712890625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [679.623046875, 136.198876953125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [679.733154296875, 135.26796875, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [679.957373046875, 134.275, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [680.2957031249999, 133.235986328125, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [680.742138671875, 132.16494140625, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [681.288671875, 131.0798828125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [681.9312988281249, 130.000830078125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 12}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 17
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 39}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 55
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 46
    }, {
        "ty": 4,
        "nm": "Shape Layer 5",
        "mn": "",
        "sr": 1,
        "st": 8,
        "op": 60,
        "ip": 8,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.87},
                    "s": [239.139306640625, 111.20849609375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.231},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [214.09086914062502, 139.530126953125, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.135},
                    "i": {"x": 0.833, "y": 1.287},
                    "s": [199.925048828125, 188.83222656249998, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [205.33232421875002, 182.72626953125, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.301},
                    "i": {"x": 0.833, "y": 0.741},
                    "s": [181.302880859375, 157.97412109375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.123},
                    "i": {"x": 0.833, "y": 1.394},
                    "s": [172.11191406249998, 141.063623046875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [152.775048828125, 152.09038085937502, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.413},
                    "i": {"x": 0.833, "y": 1.334},
                    "s": [263.64121093750003, 79.24130859375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.067},
                    "i": {"x": 0.833, "y": 0.923},
                    "s": [291.65854492187503, 128.48935546875, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.941},
                    "i": {"x": 0.833, "y": 0.286},
                    "s": [151.2916015625, 232.244580078125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.094},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [162.712744140625, 180.9205078125, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.88},
                    "s": [249.18710937499998, 184.003515625, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.275},
                    "i": {"x": 0.833, "y": 1.062},
                    "s": [135.21992187499998, 85.06899414062501, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.861},
                    "s": [85.53945312500001, 138.2068359375, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.208},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [172.1599609375, 134.04477539062498, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -1.187},
                    "i": {"x": 0.833, "y": -1.537},
                    "s": [230.118505859375, 161.139208984375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.086},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [226.318798828125, 161.16923828125, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [114.41162109375, 178.93857421875, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.754},
                    "i": {"x": 0.833, "y": 1.098},
                    "s": [182.41396484375, 253.701513671875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.045},
                    "i": {"x": 0.833, "y": 1.09},
                    "s": [175.64335937500002, 252.9748046875, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [190.37973632812498, 294.91171875000003, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.913},
                    "i": {"x": 0.833, "y": -0.56},
                    "s": [159.793896484375, 256.724462890625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.088},
                    "i": {"x": 0.833, "y": 1.128},
                    "s": [156.72490234375, 234.2125, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.051},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [102.30581054687501, 126.085009765625, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.177},
                    "i": {"x": 0.833, "y": 0.755},
                    "s": [240.42856445312498, 142.57109375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [196.161376953125, 202.9099609375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.054},
                    "i": {"x": 0.833, "y": 0.958},
                    "s": [110.395703125, 170.095947265625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.086},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [162.26030273437502, 116.30546874999999, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -1.655},
                    "i": {"x": 0.833, "y": 1.51},
                    "s": [136.711376953125, 125.02998046875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 1.243},
                    "s": [137.9345703125, 116.371533203125, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 1.116},
                    "s": [129.2220703125, 183.42695312499998, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.048},
                    "i": {"x": 0.833, "y": 1.007},
                    "s": [163.365380859375, 83.08305664062499, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.007},
                    "i": {"x": 0.833, "y": 1.027},
                    "s": [81.721728515625, 97.665283203125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [170.6064453125, 93.13486328124999, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.112},
                    "i": {"x": 0.833, "y": 0.945},
                    "s": [53.077783203125, 0.5465332031250001, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.158},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [103.17465820312499, 71.852099609375, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.318},
                    "i": {"x": 0.833, "y": -0.358},
                    "s": [85.87778320312499, -19.675195312499998, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [89.46728515625, -21.356835937499998, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.095},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [144.374853515625, -7.385205078125, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -1.25},
                    "i": {"x": 0.833, "y": 2.789},
                    "s": [118.68979492187499, 29.58486328125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.08},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [120.295361328125, 16.718310546875003, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.698},
                    "s": [84.22016601562501, 1.059033203125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.115},
                    "i": {"x": 0.833, "y": 0.95},
                    "s": [45.262158203125004, 6.5523925781250005, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.126},
                    "i": {"x": 0.833, "y": 0.627},
                    "s": [-57.151757812499994, 93.611328125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.107},
                    "i": {"x": 0.833, "y": 0.957},
                    "s": [-16.450048828125002, 20.754248046875002, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.089},
                    "i": {"x": 0.833, "y": 0.796},
                    "s": [125.26220703125, 28.80810546875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.988},
                    "s": [56.697314453125, 99.823388671875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.015},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [-42.407373046875, 83.137109375, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.202},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [42.002978515625, 8.840625000000001, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.005},
                    "i": {"x": 0.833, "y": 0.939},
                    "s": [101.3388671875, 82.6966796875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.223},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [37.92099609375, 42.7216796875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [55.147802734375, 34.055224609374996, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.549},
                    "s": [545.0197265625001, 514.676123046875, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.761},
                    "s": [543.5302734375, 510.82636718749995, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [535.224169921875, 494.580517578125, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [516.8762695312499, 465.88051757812497, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [494.42636718750003, 435.2806640625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [474.82924804687497, 408.7107421875, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [459.44023437500005, 386.06865234375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [447.6287109375, 366.383447265625, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [438.605908203125, 348.986474609375, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [431.715185546875, 333.461328125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [426.43203125, 319.49770507812497, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [422.34604492187503, 306.857373046875, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [419.15092773437505, 295.35415039062497, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [416.584423828125, 284.879931640625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [414.446337890625, 275.414697265625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [412.632568359375, 266.846337890625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [411.07304687500005, 259.074755859375, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [409.71572265624997, 252.015869140625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [408.52255859375003, 245.59160156250002, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [407.47153320312503, 239.73588867187502, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [406.546630859375, 234.39067382812502, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [405.73583984375, 229.50390625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [405.03515625, 225.03154296875002, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [404.442578125, 220.93354492187498, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.956103515625, 217.17988281249998, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.575732421875, 213.73452148437502, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.303466796875, 210.57143554687502, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.14130859375, 207.664599609375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.0892578125, 204.993994140625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.14731445312503, 202.53959960937502, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.31748046875, 200.29140625, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.59375, 198.235400390625, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [403.978125, 196.3595703125, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [404.46259765625, 194.659912109375, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [405.045166015625, 193.13041992187502, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [405.7158203125, 191.769091796875, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [406.466552734375, 190.56992187499998, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [407.28935546875, 189.534912109375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [408.17021484375, 188.66806640625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [409.09711914062495, 187.97138671875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [410.054052734375, 187.446875, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [411.031005859375, 187.028466796875, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [412.01796874999997, 186.648095703125, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [413.02294921875, 186.33979492187498, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [414.081982421875, 186.139599609375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [415.16904296875003, 186.0375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [416.264111328125, 186.02548828125, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [417.34116210937503, 186.091552734375, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [418.38017578125, 186.2296875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [419.36513671875, 186.42988281249998, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [420.27802734375, 186.68212890625, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [421.1068359375, 186.97841796875, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 8}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 13
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 35}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 51
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 47
    }, {
        "ty": 4,
        "nm": "Shape Layer 4",
        "mn": "",
        "sr": 1,
        "st": 2,
        "op": 60,
        "ip": 2,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.052},
                    "s": [183.000537109375, 147.94833984375, 100],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.032},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [131.78857421875, 184.01552734375002, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 1.046},
                    "s": [215.105859375, 185.983447265625, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [304.45302734375, 198.419580078125, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": -0.195},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [165.567529296875, 100.83437500000001, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 1.029},
                    "s": [207.162109375, 108.55791015625, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.022},
                    "i": {"x": 0.833, "y": 0.79},
                    "s": [242.0041015625, 131.05185546875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.138},
                    "i": {"x": 0.833, "y": 0.94},
                    "s": [194.91416015624998, 181.89946289062502, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": -0.209},
                    "i": {"x": 0.833, "y": 0.855},
                    "s": [123.240234375, 162.47050781250002, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.196},
                    "i": {"x": 0.833, "y": 1.023},
                    "s": [143.68818359375, 170.240087890625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.018},
                    "i": {"x": 0.833, "y": 1.369},
                    "s": [158.8109375, 93.46318359375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [139.4400390625, 197.9931640625, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.384},
                    "i": {"x": 0.833, "y": 1.223},
                    "s": [244.62265624999998, 178.412060546875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.061},
                    "i": {"x": 0.833, "y": 0.92},
                    "s": [273.803125, 161.2693359375, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -1.772},
                    "i": {"x": 0.833, "y": -0.268},
                    "s": [166.624560546875, 193.923193359375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 1.041},
                    "s": [171.4392578125, 208.58149414062498, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [239.920068359375, 172.22402343750002, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.286},
                    "i": {"x": 0.833, "y": 1.101},
                    "s": [137.35, 84.40634765624999, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.046},
                    "i": {"x": 0.833, "y": 0.862},
                    "s": [95.1328125, 87.37924804687499, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.21},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [188.34174804687498, 167.79970703125, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": -0.12},
                    "i": {"x": 0.833, "y": 0.63},
                    "s": [249.47138671875, 90.98076171875, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.959},
                    "s": [224.460986328125, 148.32470703125, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": -0.082},
                    "i": {"x": 0.833, "y": 0.798},
                    "s": [138.47509765625, 210.48334960937498, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.142},
                    "i": {"x": 0.833, "y": 0.989},
                    "s": [181.7853515625, 247.32929687499998, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": -0.013},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [243.4134765625, 191.675, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.88},
                    "s": [190.28764648437502, 284.927978515625, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.275},
                    "i": {"x": 0.833, "y": 0.702},
                    "s": [139.1517578125, 228.014453125, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.116},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [116.944091796875, 222.32890625, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.069},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [59.612158203125, 197.53271484375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 0.682},
                    "s": [90.90268554687499, 112.37563476562501, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.99},
                    "s": [69.049365234375, 167.36728515625, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.012},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [7.585400390625001, 136.699365234375, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [61.43994140625, 160.886962890625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 1.11},
                    "s": [93.381103515625, 64.805224609375, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [64.74916992187501, 2.2421875, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -2.466},
                    "i": {"x": 0.833, "y": -0.962},
                    "s": [131.23203125, 74.10029296875001, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.087},
                    "i": {"x": 0.833, "y": 1.026},
                    "s": [129.059912109375, 24.40380859375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 1.059},
                    "s": [80.078125, 74.79697265625, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.034},
                    "i": {"x": 0.833, "y": 0.936},
                    "s": [144.5970703125, 67.25361328125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": -0.269},
                    "i": {"x": 0.833, "y": 0.744},
                    "s": [34.499658203125, -17.298876953125, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.124},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [60.55908203125, -3.873779296875, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.017},
                    "i": {"x": 0.833, "y": 0.994},
                    "s": [114.45166015625, -9.020800781250001, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": -0.006},
                    "i": {"x": 0.833, "y": 0.998},
                    "s": [47.143994140625, 5.3171875, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.002},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [109.929248046875, -4.8767578125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.138},
                    "i": {"x": 0.833, "y": 1.044},
                    "s": [48.509326171875, 60.591113281249996, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.029},
                    "i": {"x": 0.833, "y": 0.98},
                    "s": [71.603857421875, 101.773291015625, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": -0.027},
                    "i": {"x": 0.833, "y": 1.284},
                    "s": [36.389501953125, 20.992480468750003, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [62.947412109375, 32.73994140625, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": -0.427},
                    "i": {"x": 0.833, "y": 0.369},
                    "s": [-54.100781250000004, 112.45371093749999, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [-34.97412109375, 55.464111328125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.306},
                    "i": {"x": 0.833, "y": 1.145},
                    "s": [90.630419921875, -13.771435546874999, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.053},
                    "i": {"x": 0.833, "y": 0.872},
                    "s": [137.65830078125, 51.8826171875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.239},
                    "i": {"x": 0.833, "y": 1.048},
                    "s": [8.920703125000001, 89.91572265625, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [-60.1947265625, 14.830468750000001, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": -0.101},
                    "i": {"x": 0.833, "y": 1.082},
                    "s": [48.789599609374996, 124.70966796875, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.041},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [-0.470458984375, 68.81914062499999, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.394},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [97.33896484375, 72.47670898437501, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [123.5525390625, 0.462451171875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.599},
                    "s": [548.4150390625, 521.206494140625, 0],
                    "t": 2
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.765},
                    "s": [546.2609375, 524.974169921875, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [537.426318359375, 538.93779296875, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [517.73310546875, 561.7880859375, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [491.29130859375, 582.610400390625, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [465.884521484375, 597.681103515625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [443.97314453125, 609.1863281249999, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [425.43505859375, 618.81572265625, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [409.681689453125, 627.3740722656249, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [396.19453125, 635.2197265625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [384.55517578125, 642.51884765625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [374.4533203125, 649.353515625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [365.642724609375, 655.7677734375001, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [357.931201171875, 661.7896484375, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [351.168603515625, 667.4371582031249, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [345.230810546875, 672.7283203125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [340.021728515625, 677.6771484374999, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [335.457275390625, 682.2976562499999, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [331.47138671875, 686.603857421875, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [328.002001953125, 690.607763671875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [325.00107421875003, 694.319384765625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [322.42656250000005, 697.750732421875, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [320.23642578125, 700.909814453125, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [318.39462890625, 703.8046386718751, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [316.87314453125003, 706.44521484375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [315.633935546875, 708.8375488281249, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [314.6509765625, 710.991650390625, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [313.892236328125, 712.9115234375, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [313.327685546875, 714.607177734375, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [312.93129882812497, 716.0806152343749, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [312.673046875, 717.33583984375, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [312.52490234375, 718.378857421875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.194},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [312.458837890625, 719.211669921875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [312.44482421875, 719.83828125, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [312.56293945312495, 720.384814453125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [312.847216796875, 720.905322265625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [313.26962890625, 721.391796875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [313.808154296875, 721.8422363281251, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [314.4427734375, 722.246630859375, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [315.157470703125, 722.6009765624999, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [315.930224609375, 722.899267578125, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.837},
                    "s": [316.710986328125, 723.1875488281249, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.171},
                    "i": {"x": 0.833, "y": 0.838},
                    "s": [317.44770507812495, 723.5138671875, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [318.13037109375, 723.8662109375, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [318.752978515625, 724.236572265625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [319.309521484375, 724.6149414062501, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [319.79399414062505, 724.9953125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [320.202392578125, 725.36767578125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [320.534716796875, 725.7260253906251, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [320.786962890625, 726.0623535156251, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.185},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [320.963134765625, 726.36865234375, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [321.065234375, 726.638916015625, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [321.09326171875, 726.869140625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [321.05522460937505, 727.0513183593749, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [320.95712890625, 727.179443359375, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.816},
                    "s": [320.80698242187503, 727.24951171875, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.152},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [320.610791015625, 727.255517578125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [320.382568359375, 727.1974609375001, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 2}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 7
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 29}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 45
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 48
    }, {
        "ty": 4,
        "nm": "Shape Layer 3",
        "mn": "",
        "sr": 1,
        "st": 7,
        "op": 60,
        "ip": 7,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [243.21728515625, 177.64731445312498, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.91},
                    "s": [182.3298828125, 176.490185546875, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 1.162},
                    "i": {"x": 0.833, "y": 0.429},
                    "s": [134.9556640625, 216.33505859375, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.098},
                    "i": {"x": 0.833, "y": 1.033},
                    "s": [131.294091796875, 214.118896484375, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.024},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [109.85517578125001, 97.044677734375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.733},
                    "s": [139.894482421875, 148.51689453125002, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.121},
                    "i": {"x": 0.833, "y": 1.032},
                    "s": [167.03095703124998, 216.4912109375, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.023},
                    "i": {"x": 0.833, "y": 0.978},
                    "s": [226.69316406250002, 251.259130859375, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.03},
                    "i": {"x": 0.833, "y": 0.937},
                    "s": [144.310791015625, 216.21494140624998, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.261},
                    "i": {"x": 0.833, "y": 0.63},
                    "s": [204.965966796875, 195.881103515625, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.108},
                    "i": {"x": 0.833, "y": 0.922},
                    "s": [190.29365234375, 149.652001953125, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -1.116},
                    "i": {"x": 0.833, "y": 0.758},
                    "s": [139.802392578125, 111.29458007812501, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": -0.253},
                    "s": [143.309814453125, 66.47685546875, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [149.9943359375, 166.988916015625, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.069},
                    "i": {"x": 0.833, "y": 0.895},
                    "s": [243.79384765625002, 147.00942382812502, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.41},
                    "i": {"x": 0.833, "y": 0.929},
                    "s": [192.313623046875, 129.81064453125, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": -0.493},
                    "i": {"x": 0.833, "y": -2.344},
                    "s": [179.18681640625, 183.907421875, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.085},
                    "i": {"x": 0.833, "y": 1},
                    "s": [181.082666015625, 192.473779296875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0},
                    "i": {"x": 0.833, "y": 0.913},
                    "s": [255.3130859375, 132.170947265625, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 1.831},
                    "i": {"x": 0.833, "y": 1.203},
                    "s": [180.7283203125, 194.119384765625, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.059},
                    "i": {"x": 0.833, "y": 1.094},
                    "s": [177.1728515625, 244.87290039062498, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.044},
                    "i": {"x": 0.833, "y": 0.722},
                    "s": [189.382763671875, 182.716259765625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.119},
                    "i": {"x": 0.833, "y": 0.905},
                    "s": [163.329345703125, 221.95654296875, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.689},
                    "i": {"x": 0.833, "y": 0.81},
                    "s": [102.4279296875, 151.28359375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [94.04575195312499, 186.34580078124998, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 1.272},
                    "s": [83.311279296875, 173.339111328125, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.064},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [70.44072265625, 248.916845703125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.532},
                    "i": {"x": 0.833, "y": 1.499},
                    "s": [125.304248046875, 176.710400390625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.071},
                    "i": {"x": 0.833, "y": 0.951},
                    "s": [117.877001953125, 52.693408203125, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -0.121},
                    "i": {"x": 0.833, "y": 1.072},
                    "s": [169.80166015625, 96.311962890625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.038},
                    "i": {"x": 0.833, "y": 0.946},
                    "s": [148.65302734375, 94.02773437500001, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -0.15},
                    "i": {"x": 0.833, "y": 0.594},
                    "s": [187.949365234375, 119.08818359374999, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [173.913671875, 119.258349609375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.224},
                    "i": {"x": 0.833, "y": 1.076},
                    "s": [119.642724609375, 48.65546875, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [87.391259765625, 56.759375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [148.943310546875, 88.119970703125, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": -0.523},
                    "i": {"x": 0.833, "y": 1.401},
                    "s": [72.46669921875, 68.370703125, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.069},
                    "i": {"x": 0.833, "y": 0.943},
                    "s": [82.976953125, 102.04755859375, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": -0.183},
                    "i": {"x": 0.833, "y": 1.109},
                    "s": [21.867333984375, 107.96733398437499, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.047},
                    "i": {"x": 0.833, "y": 1.042},
                    "s": [40.973974609375, 156.466650390625, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.028},
                    "i": {"x": 0.833, "y": 1.003},
                    "s": [-3.153076171875, 26.99033203125, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.003},
                    "i": {"x": 0.833, "y": 0.927},
                    "s": [62.995458984375, 79.34140624999999, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": -0.568},
                    "i": {"x": 0.833, "y": 0.201},
                    "s": [-5.6695312499999995, 151.74003906250002, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.093},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [3.1150390625, 101.64516601562501, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.302},
                    "i": {"x": 0.833, "y": 0.966},
                    "s": [78.59267578125001, -51.171923828125, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.057},
                    "i": {"x": 0.833, "y": 0.108},
                    "s": [107.30869140624999, 6.9207519531249995, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.092},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [90.21201171874999, 67.291650390625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.07},
                    "i": {"x": 0.833, "y": 0.964},
                    "s": [-75.769921875, -16.590185546875002, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": -0.063},
                    "i": {"x": 0.833, "y": 1.116},
                    "s": [14.440087890625, 113.640869140625, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [-37.08818359375, 76.1943359375, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.416},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [86.42832031249999, 112.539794921875, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.168},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [117.36650390624999, -29.090380859375003, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [147.690087890625, 1.987939453125, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.551},
                    "s": [545.285986328125, 516.678076171875, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.762},
                    "s": [540.54736328125, 518.31767578125, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.128},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [519.588916015625, 524.8560546875001, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [479.315625, 531.580615234375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [433.943359375, 528.4916015625, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [396.2185546875, 516.02744140625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [367.23828125, 499.873681640625, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [344.634228515625, 483.469677734375, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [326.25229492187503, 468.13271484374997, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [310.7111328125, 454.31723632812503, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [297.06181640625, 442.097314453125, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [284.691748046875, 431.458935546875, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [273.18251953125, 422.36806640625, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [262.27587890625, 414.83271484375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [251.941796875, 409.001025390625, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [242.13422851562498, 404.885009765625, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [232.857177734375, 402.440625, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [224.204736328125, 401.559765625, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [216.277001953125, 402.0322265625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [209.14204101562498, 403.575732421875, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [202.805859375, 405.89599609375, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [197.232421875, 408.724755859375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [192.363671875, 411.851806640625, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.173},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [188.113525390625, 415.122998046875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [184.40791015625, 418.430224609375, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [181.17875976562502, 421.69541015625, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [178.3740234375, 424.856494140625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [175.945654296875, 427.875439453125, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [173.85361328125, 430.72021484375, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [172.057861328125, 433.372802734375, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [170.53037109375, 435.81318359374995, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [169.247119140625, 438.02734375, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [168.182080078125, 440.00927734375, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [167.31923828125, 441.74697265624997, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.186},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [166.63857421875, 443.23442382812505, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.19},
                    "i": {"x": 0.833, "y": 0.857},
                    "s": [166.13007812499998, 444.465625, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.199},
                    "i": {"x": 0.833, "y": 0.865},
                    "s": [165.77373046875, 445.43857421875003, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.219},
                    "i": {"x": 0.833, "y": 0.882},
                    "s": [165.555517578125, 446.15126953125, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.284},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [165.463427734375, 446.59970703125, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.751},
                    "s": [165.49345703125, 446.781884765625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.125},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [165.63359375000002, 446.69179687499997, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [165.81376953125, 446.393505859375, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.149},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [165.9619140625, 445.94907226562503, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.161},
                    "i": {"x": 0.833, "y": 0.831},
                    "s": [166.0900390625, 445.372509765625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.834},
                    "s": [166.26220703125, 444.76191406249995, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.836},
                    "s": [166.472412109375, 444.147314453125, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.17},
                    "i": {"x": 0.833, "y": 0.839},
                    "s": [166.700634765625, 443.54472656250005, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.172},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [166.932861328125, 442.9681640625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [167.157080078125, 442.431640625, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [167.35927734375, 441.94716796875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [167.52744140625, 441.520751953125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.192},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [167.653564453125, 441.164404296875, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [167.72563476562502, 440.88413085937503, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 7}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 12
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 34}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 50
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.4588, 0.6745, 0.5686], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 49
    }, {
        "ty": 4,
        "nm": "Shape Layer 2",
        "mn": "",
        "sr": 1,
        "st": 6,
        "op": 60,
        "ip": 6,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.867},
                    "s": [238.520703125, 211.860693359375, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.222},
                    "i": {"x": 0.833, "y": 0.795},
                    "s": [218.046728515625, 179.54316406249998, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.141},
                    "i": {"x": 0.833, "y": 0.672},
                    "s": [205.730712890625, 246.02802734375, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 0.908},
                    "s": [187.78520507812502, 277.9591796875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.904},
                    "i": {"x": 0.833, "y": 0.347},
                    "s": [135.153857421875, 177.39707031249998, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 1.047},
                    "s": [129.81064453125, 90.1359375, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.03},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [93.2990234375, 108.720068359375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 1.068},
                    "s": [150.61894531250002, 312.142529296875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.037},
                    "i": {"x": 0.833, "y": 0.917},
                    "s": [89.112939453125, 280.289453125, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 270.204},
                    "i": {"x": 0.833, "y": 152.322},
                    "s": [200.545654296875, 190.5298828125, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.083},
                    "i": {"x": 0.833, "y": 1.025},
                    "s": [200.57968749999998, 129.518359375, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 1.006},
                    "s": [138.120751953125, 166.112060546875, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.006},
                    "i": {"x": 0.833, "y": 0.991},
                    "s": [219.660302734375, 154.116357421875, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": -0.01},
                    "i": {"x": 0.833, "y": 0.999},
                    "s": [132.11689453125, 88.6705078125, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.001},
                    "i": {"x": 0.833, "y": 0.952},
                    "s": [210.301171875, 174.27001953125, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": -0.115},
                    "i": {"x": 0.833, "y": 0.907},
                    "s": [132.62939453125, 177.631298828125, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.774},
                    "i": {"x": 0.833, "y": -0.395},
                    "s": [165.18715820312502, 107.294677734375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.089},
                    "i": {"x": 0.833, "y": 1.013},
                    "s": [169.114990234375, 99.64521484375, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 0.914},
                    "s": [230.91728515625002, 110.473779296875, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 2.433},
                    "i": {"x": 0.833, "y": 3.517},
                    "s": [159.769873046875, 137.89052734375, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.081},
                    "i": {"x": 0.833, "y": 0.948},
                    "s": [157.247412109375, 254.3521484375, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.137},
                    "i": {"x": 0.833, "y": 0.885},
                    "s": [236.000244140625, 178.800439453125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.305},
                    "i": {"x": 0.833, "y": 0.672},
                    "s": [206.271240234375, 198.8900390625, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.112},
                    "i": {"x": 0.833, "y": 0.965},
                    "s": [195.116357421875, 147.33173828124998, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": -0.06},
                    "i": {"x": 0.833, "y": 1.366},
                    "s": [162.31635742187498, 134.00874023437498, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.944},
                    "s": [181.366943359375, 164.402392578125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": -0.173},
                    "i": {"x": 0.833, "y": 0.794},
                    "s": [78.73081054687499, 162.136181640625, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.14},
                    "i": {"x": 0.833, "y": 0.967},
                    "s": [112.10537109375, 200.15126953125, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": -0.055},
                    "i": {"x": 0.833, "y": 0.774},
                    "s": [161.28935546875002, 142.501025390625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.132},
                    "i": {"x": 0.833, "y": 1.053},
                    "s": [131.598388671875, 101.00654296875, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.032},
                    "i": {"x": 0.833, "y": 0.93},
                    "s": [80.8068359375, 156.45864257812502, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": -0.444},
                    "i": {"x": 0.833, "y": 0.919},
                    "s": [163.96396484375, 156.7208984375, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": -2.408},
                    "i": {"x": 0.833, "y": 8.691},
                    "s": [150.82314453125, 151.862158203125, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.082},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [151.261572265625, 95.395068359375, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": -0.069},
                    "i": {"x": 0.833, "y": 1.151},
                    "s": [110.25556640625, 105.032470703125, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.054},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [132.74150390625, 162.15620117187498, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.912},
                    "s": [69.57587890625, 80.616650390625, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 1.584},
                    "i": {"x": 0.833, "y": 1.421},
                    "s": [5.19306640625, 118.7638671875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.07},
                    "i": {"x": 0.833, "y": 1.174},
                    "s": [1.617578125, 122.557568359375, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.056},
                    "i": {"x": 0.833, "y": 1.039},
                    "s": [23.25869140625, 110.798095703125, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.027},
                    "i": {"x": 0.833, "y": 0.955},
                    "s": [-43.596533203125, -39.148193359375, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": -0.096},
                    "i": {"x": 0.833, "y": 1.083},
                    "s": [54.89755859375, 16.870458984375, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.042},
                    "i": {"x": 0.833, "y": 0.896},
                    "s": [9.202978515625, 133.15590820312502, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.425},
                    "i": {"x": 0.833, "y": 1.087},
                    "s": [100.337890625, 111.230517578125, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.043},
                    "i": {"x": 0.833, "y": 0.942},
                    "s": [122.55356445312499, -38.14521484375, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": -0.186},
                    "i": {"x": 0.833, "y": 1.717},
                    "s": [77.01513671875, -9.681445312500001, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.075},
                    "i": {"x": 0.833, "y": 0.956},
                    "s": [91.08686523437501, 35.682812500000004, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": -0.092},
                    "i": {"x": 0.833, "y": 0.926},
                    "s": [-44.056982421875006, -13.258935546875, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.679},
                    "i": {"x": 0.833, "y": 1.362},
                    "s": [20.067578124999997, 77.769873046875, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.068},
                    "i": {"x": 0.833, "y": 0.873},
                    "s": [13.05673828125, 84.344287109375, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.243},
                    "i": {"x": 0.833, "y": 0.708},
                    "s": [50.53330078125, 84.15610351562499, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.117},
                    "i": {"x": 0.833, "y": 0.976},
                    "s": [70.122412109375, 4.21611328125, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": -0.034},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [119.07216796875001, 11.833544921875, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [84.418359375, 114.057275390625, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.592},
                    "s": [546.03271484375, 515.81123046875, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.105},
                    "i": {"x": 0.833, "y": 0.764},
                    "s": [546.29296875, 520.926220703125, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.129},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [548.529150390625, 540.781591796875, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [555.451904296875, 576.612548828125, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [567.627783203125, 615.310302734375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [582.590380859375, 647.497705078125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [598.47587890625, 672.522119140625, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [614.421435546875, 691.8129394531251, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [629.96259765625, 706.6614257812499, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [644.8291015625, 718.0685546875001, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [658.8808105468751, 726.8210937499999, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [672.045654296875, 733.509619140625, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [684.3056152343751, 738.6085937500001, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [695.68271484375, 742.474365234375, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [706.2169921875001, 745.3912109375, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [715.95849609375, 747.567333984375, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [724.9592773437499, 749.1708984375, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [733.275390625, 750.3260253906251, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [740.95888671875, 751.1288085937499, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [748.051806640625, 751.651318359375, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [754.59619140625, 751.949609375, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [760.62607421875, 752.06572265625, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [766.16748046875, 752.031689453125, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [771.242431640625, 751.8715332031251, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [775.8749511718751, 751.6012695312501, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [780.079052734375, 751.2349121093749, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [783.870751953125, 750.782470703125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [787.258056640625, 750.245947265625, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [790.24697265625, 749.633349609375, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [792.9115722656251, 748.9687011718751, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [795.34794921875, 748.300048828125, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [797.5541015624999, 747.643408203125, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [799.5320312499999, 747.0147949218749, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [801.2797363281251, 746.422216796875, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [802.797216796875, 745.8796875, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.851},
                    "s": [804.082470703125, 745.393212890625, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.189},
                    "i": {"x": 0.833, "y": 0.856},
                    "s": [805.135498046875, 744.972802734375, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.198},
                    "i": {"x": 0.833, "y": 0.864},
                    "s": [805.95830078125, 744.620458984375, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.215},
                    "i": {"x": 0.833, "y": 0.881},
                    "s": [806.5488769531249, 744.34619140625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.276},
                    "i": {"x": 0.833, "y": 0.888},
                    "s": [806.9092285156249, 744.147998046875, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.327},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [807.043359375, 744.031884765625, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.754},
                    "s": [807.03134765625, 743.9838378906251, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 0.789},
                    "s": [806.953271484375, 743.98583984375, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.138},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [806.8031249999999, 744.04189453125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.145},
                    "i": {"x": 0.833, "y": 0.813},
                    "s": [806.58291015625, 744.147998046875, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.818},
                    "s": [806.296630859375, 744.30615234375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.154},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [805.9442871093751, 744.5123535156249, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [805.5278808593749, 744.764599609375, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.158},
                    "i": {"x": 0.833, "y": 0.826},
                    "s": [805.055419921875, 745.056884765625, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.828},
                    "s": [804.5329101562501, 745.3852050781251, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.162},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [803.966357421875, 745.7455566406251, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [803.36376953125, 746.1279296875, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [802.737158203125, 746.526318359375, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [802.096533203125, 746.93271484375, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 6}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 11
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 33}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 49
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 50
    }, {
        "ty": 4,
        "nm": "Shape Layer 1",
        "mn": "",
        "sr": 1,
        "st": 3,
        "op": 60,
        "ip": 3,
        "hd": false,
        "cl": "",
        "ln": "",
        "ddd": 0,
        "bm": 0,
        "hasMask": false,
        "ao": 0,
        "ks": {
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 1.013},
                    "s": [188.53193359375, 177.533203125, 100],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.011},
                    "i": {"x": 0.833, "y": 0.854},
                    "s": [125.43637695312499, 207.6826171875, 100],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.195},
                    "i": {"x": 0.833, "y": 1},
                    "s": [198.019189453125, 180.68828125, 100],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0},
                    "i": {"x": 0.833, "y": 0.962},
                    "s": [252.36420898437498, 165.98193359375, 100],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": -0.071},
                    "i": {"x": 0.833, "y": 1.12},
                    "s": [198.22539062500002, 158.504638671875, 100],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.049},
                    "i": {"x": 0.833, "y": 0.877},
                    "s": [227.46791992187502, 242.42451171874998, 100],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.258},
                    "i": {"x": 0.833, "y": 0.808},
                    "s": [155.966162109375, 214.4732421875, 100],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.147},
                    "i": {"x": 0.833, "y": 1.024},
                    "s": [121.908935546875, 148.05844726562498, 100],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.019},
                    "i": {"x": 0.833, "y": 1.02},
                    "s": [77.56767578125, 81.39140625, 100],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.997},
                    "s": [134.925634765625, 218.939599609375, 100],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": -0.003},
                    "i": {"x": 0.833, "y": 0.787},
                    "s": [63.511962890625, 305.063623046875, 100],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.137},
                    "i": {"x": 0.833, "y": 0.983},
                    "s": [132.64541015625, 177.49716796875, 100],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": -0.021},
                    "i": {"x": 0.833, "y": 0.996},
                    "s": [239.839990234375, 120.57363281250001, 100],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": -0.004},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [153.99423828125, 163.409423828125, 100],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": -0.135},
                    "i": {"x": 0.833, "y": 0.975},
                    "s": [235.621875, 248.43637695312498, 100],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": -0.036},
                    "i": {"x": 0.833, "y": 1.215},
                    "s": [204.39541015625, 103.765234375, 100],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.06},
                    "i": {"x": 0.833, "y": 0.928},
                    "s": [226.270751953125, 92.50224609375, 100],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": -0.543},
                    "i": {"x": 0.833, "y": 0.706},
                    "s": [147.830224609375, 200.009130859375, 100],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.116},
                    "i": {"x": 0.833, "y": 0.753},
                    "s": [158.26240234375, 125.05600585937499, 100],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.126},
                    "i": {"x": 0.833, "y": 1.064},
                    "s": [184.65615234375, 100.856396484375, 100],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.883},
                    "s": [236.528759765625, 117.94707031249999, 100],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.287},
                    "i": {"x": 0.833, "y": 1.141},
                    "s": [144.899365234375, 203.8068359375, 100],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.052},
                    "i": {"x": 0.833, "y": 0.893},
                    "s": [107.4408203125, 236.85307617187502, 100],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.378},
                    "i": {"x": 0.833, "y": 0.925},
                    "s": [208.133056640625, 208.873779296875, 100],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": -0.756},
                    "i": {"x": 0.833, "y": 0.059},
                    "s": [236.61284179687502, 178.49814453125, 100],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.091},
                    "i": {"x": 0.833, "y": 1.036},
                    "s": [233.78608398437498, 225.0115234375, 100],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.025},
                    "i": {"x": 0.833, "y": 1.158},
                    "s": [204.685693359375, 145.01948242187498, 100],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.055},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [246.394384765625, 171.709521484375, 100],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.435},
                    "i": {"x": 0.833, "y": 1.064},
                    "s": [125.4263671875, 76.012158203125, 100],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.036},
                    "i": {"x": 0.833, "y": 0.949},
                    "s": [96.7263671875, 157.199365234375, 100],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": -0.131},
                    "i": {"x": 0.833, "y": 0.545},
                    "s": [147.57998046875, 204.545556640625, 100],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.102},
                    "i": {"x": 0.833, "y": 0.979},
                    "s": [127.81069335937501, 126.068994140625, 100],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": -0.028},
                    "i": {"x": 0.833, "y": 0.921},
                    "s": [39.620654296875, 128.471337890625, 100],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": -1.621},
                    "i": {"x": 0.833, "y": 0.758},
                    "s": [105.80922851562501, 176.23994140625, 100],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 1.306},
                    "s": [102.57207031249999, 100.96049804687499, 100],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.065},
                    "i": {"x": 0.833, "y": 0.889},
                    "s": [96.416064453125, 118.99609375, 100],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.334},
                    "i": {"x": 0.833, "y": 1.538},
                    "s": [125.184130859375, 122.645654296875, 100],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.072},
                    "i": {"x": 0.833, "y": 0.759},
                    "s": [134.76347656250002, 174.07783203124998, 100],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.127},
                    "i": {"x": 0.833, "y": 0.954},
                    "s": [63.371826171875, 97.37099609375, 100],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": -0.1},
                    "i": {"x": 0.833, "y": 0.875},
                    "s": [-71.75600585937501, 39.692724609375006, 100],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.249},
                    "i": {"x": 0.833, "y": 1.076},
                    "s": [-10.452197265625, 126.521435546875, 100],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.04},
                    "i": {"x": 0.833, "y": 1.027},
                    "s": [20.35986328125, 46.066943359374996, 100],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.02},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [-38.679736328125, -43.644580078124996, 100],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": -0.321},
                    "i": {"x": 0.833, "y": 1.313},
                    "s": [39.442480468750006, -19.45498046875, 100],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.066},
                    "i": {"x": 0.833, "y": 0.894},
                    "s": [23.344775390625, 71.83608398437501, 100],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.392},
                    "i": {"x": 0.833, "y": 1.021},
                    "s": [99.96552734375, 59.488037109375, 100],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.016},
                    "i": {"x": 0.833, "y": 0.685},
                    "s": [120.67373046875, -2.0279785156249996, 100],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.113},
                    "i": {"x": 0.833, "y": 0.849},
                    "s": [94.85654296874999, 26.6419921875, 100],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.187},
                    "i": {"x": 0.833, "y": 0.934},
                    "s": [23.140576171874997, 31.77099609375, 100],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": -0.311},
                    "i": {"x": 0.833, "y": 0.35},
                    "s": [-34.761914062500004, -30.40166015625, 100],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.096},
                    "i": {"x": 0.833, "y": 0.953},
                    "s": [-22.531982421875, 85.917822265625, 100],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": -0.107},
                    "i": {"x": 0.833, "y": 1.05},
                    "s": [60.701220703125, 102.66616210937501, 100],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.031},
                    "i": {"x": 0.833, "y": 0.897},
                    "s": [24.321728515624997, 52.373095703125, 100],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.44},
                    "i": {"x": 0.833, "y": 1.244},
                    "s": [82.7287109375, 20.39189453125, 100],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.062},
                    "i": {"x": 0.833, "y": 0.878},
                    "s": [96.38203125, 79.45151367187499, 100],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.262},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [42.729687500000004, 70.52880859375, 100],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [17.755322265625, 68.851171875, 100],
                    "t": 59
                }],
                "ix": 6
            },
            "sk": {"a": 0, "k": 0},
            "p": {
                "a": 1,
                "k": [{
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.625},
                    "s": [548.15078125, 519.837158203125, 0],
                    "t": 3
                }, {
                    "o": {"x": 0.167, "y": 0.107},
                    "i": {"x": 0.833, "y": 0.767},
                    "s": [554.476953125, 519.0243652343751, 0],
                    "t": 4
                }, {
                    "o": {"x": 0.167, "y": 0.13},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [576.6265625, 516.21162109375, 0],
                    "t": 5
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [615.778759765625, 507.485107421875, 0],
                    "t": 6
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [656.27626953125, 489.479541015625, 0],
                    "t": 7
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.847},
                    "s": [685.5508300781249, 464.262939453125, 0],
                    "t": 8
                }, {
                    "o": {"x": 0.167, "y": 0.183},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [702.9578125, 437.17250976562497, 0],
                    "t": 9
                }, {
                    "o": {"x": 0.167, "y": 0.181},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [712.77939453125, 411.94990234375, 0],
                    "t": 10
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [718.6931640624999, 389.628125, 0],
                    "t": 11
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [722.763134765625, 370.03300781250005, 0],
                    "t": 12
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [726.04833984375, 352.78818359375003, 0],
                    "t": 13
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [729.101318359375, 337.555322265625, 0],
                    "t": 14
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [732.220361328125, 324.07016601562503, 0],
                    "t": 15
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [735.567626953125, 312.126513671875, 0],
                    "t": 16
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [739.2251953125, 301.56220703124995, 0],
                    "t": 17
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [743.20107421875, 292.30517578125, 0],
                    "t": 18
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [747.4532226562501, 284.29135742187503, 0],
                    "t": 19
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [752.02568359375, 277.384619140625, 0],
                    "t": 20
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [756.852392578125, 271.4888671875, 0],
                    "t": 21
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [761.797216796875, 266.52001953125, 0],
                    "t": 22
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [766.738037109375, 262.36796875000005, 0],
                    "t": 23
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.84},
                    "s": [771.5767578125, 258.92060546875, 0],
                    "t": 24
                }, {
                    "o": {"x": 0.167, "y": 0.174},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [776.23330078125, 256.067822265625, 0],
                    "t": 25
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [780.651611328125, 253.713525390625, 0],
                    "t": 26
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.841},
                    "s": [784.7916503906249, 251.77163085937502, 0],
                    "t": 27
                }, {
                    "o": {"x": 0.167, "y": 0.175},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [788.6313964843749, 250.170068359375, 0],
                    "t": 28
                }, {
                    "o": {"x": 0.167, "y": 0.176},
                    "i": {"x": 0.833, "y": 0.842},
                    "s": [792.1608398437501, 248.848779296875, 0],
                    "t": 29
                }, {
                    "o": {"x": 0.167, "y": 0.177},
                    "i": {"x": 0.833, "y": 0.843},
                    "s": [795.37197265625, 247.76171875, 0],
                    "t": 30
                }, {
                    "o": {"x": 0.167, "y": 0.178},
                    "i": {"x": 0.833, "y": 0.844},
                    "s": [798.2667968750001, 246.866845703125, 0],
                    "t": 31
                }, {
                    "o": {"x": 0.167, "y": 0.179},
                    "i": {"x": 0.833, "y": 0.845},
                    "s": [800.8453125, 246.1361328125, 0],
                    "t": 32
                }, {
                    "o": {"x": 0.167, "y": 0.18},
                    "i": {"x": 0.833, "y": 0.846},
                    "s": [803.113525390625, 245.5435546875, 0],
                    "t": 33
                }, {
                    "o": {"x": 0.167, "y": 0.182},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [805.0734375, 245.069091796875, 0],
                    "t": 34
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.85},
                    "s": [806.737060546875, 244.6947265625, 0],
                    "t": 35
                }, {
                    "o": {"x": 0.167, "y": 0.188},
                    "i": {"x": 0.833, "y": 0.853},
                    "s": [808.11640625, 244.408447265625, 0],
                    "t": 36
                }, {
                    "o": {"x": 0.167, "y": 0.193},
                    "i": {"x": 0.833, "y": 0.859},
                    "s": [809.223486328125, 244.200244140625, 0],
                    "t": 37
                }, {
                    "o": {"x": 0.167, "y": 0.203},
                    "i": {"x": 0.833, "y": 0.868},
                    "s": [810.0683105468751, 244.060107421875, 0],
                    "t": 38
                }, {
                    "o": {"x": 0.167, "y": 0.226},
                    "i": {"x": 0.833, "y": 0.887},
                    "s": [810.6588867187501, 243.98203125, 0],
                    "t": 39
                }, {
                    "o": {"x": 0.167, "y": 0.317},
                    "i": {"x": 0.833, "y": 0.82},
                    "s": [811.0052246093751, 243.96201171874998, 0],
                    "t": 40
                }, {
                    "o": {"x": 0.167, "y": 0.155},
                    "i": {"x": 0.833, "y": 0.714},
                    "s": [811.117333984375, 243.996044921875, 0],
                    "t": 41
                }, {
                    "o": {"x": 0.167, "y": 0.118},
                    "i": {"x": 0.833, "y": 0.805},
                    "s": [811.0052246093751, 244.08012695312502, 0],
                    "t": 42
                }, {
                    "o": {"x": 0.167, "y": 0.146},
                    "i": {"x": 0.833, "y": 0.829},
                    "s": [810.680908203125, 244.2142578125, 0],
                    "t": 43
                }, {
                    "o": {"x": 0.167, "y": 0.163},
                    "i": {"x": 0.833, "y": 0.83},
                    "s": [810.240478515625, 244.376416015625, 0],
                    "t": 44
                }, {
                    "o": {"x": 0.167, "y": 0.164},
                    "i": {"x": 0.833, "y": 0.832},
                    "s": [809.780029296875, 244.552587890625, 0],
                    "t": 45
                }, {
                    "o": {"x": 0.167, "y": 0.165},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [809.3055664062499, 244.74277343749998, 0],
                    "t": 46
                }, {
                    "o": {"x": 0.167, "y": 0.166},
                    "i": {"x": 0.833, "y": 0.835},
                    "s": [808.827099609375, 244.94296875, 0],
                    "t": 47
                }, {
                    "o": {"x": 0.167, "y": 0.169},
                    "i": {"x": 0.833, "y": 0.852},
                    "s": [808.350634765625, 245.15517578125, 0],
                    "t": 48
                }, {
                    "o": {"x": 0.167, "y": 0.191},
                    "i": {"x": 0.833, "y": 0.858},
                    "s": [807.8921875000001, 245.37939453125, 0],
                    "t": 49
                }, {
                    "o": {"x": 0.167, "y": 0.201},
                    "i": {"x": 0.833, "y": 0.848},
                    "s": [807.571875, 245.609619140625, 0],
                    "t": 50
                }, {
                    "o": {"x": 0.167, "y": 0.184},
                    "i": {"x": 0.833, "y": 0.823},
                    "s": [807.417724609375, 245.83984375, 0],
                    "t": 51
                }, {
                    "o": {"x": 0.167, "y": 0.157},
                    "i": {"x": 0.833, "y": 0.81},
                    "s": [807.4157226562501, 246.06806640625, 0],
                    "t": 52
                }, {
                    "o": {"x": 0.167, "y": 0.148},
                    "i": {"x": 0.833, "y": 0.812},
                    "s": [807.5498535156249, 246.290283203125, 0],
                    "t": 53
                }, {
                    "o": {"x": 0.167, "y": 0.15},
                    "i": {"x": 0.833, "y": 0.817},
                    "s": [807.8061035156251, 246.502490234375, 0],
                    "t": 54
                }, {
                    "o": {"x": 0.167, "y": 0.153},
                    "i": {"x": 0.833, "y": 0.821},
                    "s": [808.1724609375, 246.706689453125, 0],
                    "t": 55
                }, {
                    "o": {"x": 0.167, "y": 0.156},
                    "i": {"x": 0.833, "y": 0.824},
                    "s": [808.634912109375, 246.896875, 0],
                    "t": 56
                }, {
                    "o": {"x": 0.167, "y": 0.159},
                    "i": {"x": 0.833, "y": 0.827},
                    "s": [809.179443359375, 247.075048828125, 0],
                    "t": 57
                }, {
                    "o": {"x": 0.167, "y": 0.16},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [809.7920410156249, 247.235205078125, 0],
                    "t": 58
                }, {
                    "o": {"x": 0.167, "y": 0.167},
                    "i": {"x": 0.833, "y": 0.833},
                    "s": [810.4626953125, 247.379345703125, 0],
                    "t": 59
                }],
                "ix": 2
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "sa": {"a": 0, "k": 0},
            "o": {
                "a": 1,
                "k": [{"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [0], "t": 3}, {
                    "o": {
                        "x": 0.333,
                        "y": 0
                    }, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 8
                }, {"o": {"x": 0.333, "y": 0}, "i": {"x": 0.667, "y": 1}, "s": [100], "t": 30}, {
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    }, "i": {"x": 0.833, "y": 0.833}, "s": [0], "t": 46
                }],
                "ix": 11
            }
        },
        "ef": [],
        "shapes": [{
            "ty": "gr",
            "bm": 0,
            "cl": "",
            "ln": "",
            "hd": false,
            "mn": "ADBE Vector Group",
            "nm": "Ellipse 1",
            "ix": 1,
            "cix": 2,
            "np": 3,
            "it": [{
                "ty": "el",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Shape - Ellipse",
                "nm": "Ellipse Path 1",
                "d": 1,
                "p": {"a": 0, "k": [0, 0], "ix": 3},
                "s": {"a": 0, "k": [10, 10], "ix": 2}
            }, {
                "ty": "fl",
                "bm": 0,
                "cl": "",
                "ln": "",
                "hd": false,
                "mn": "ADBE Vector Graphic - Fill",
                "nm": "Fill 1",
                "c": {"a": 0, "k": [0.8314, 0.4784, 0.4784], "ix": 4},
                "r": 1,
                "o": {"a": 0, "k": 100, "ix": 5}
            }, {
                "ty": "tr",
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "r": {"a": 0, "k": 0, "ix": 6},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "o": {"a": 0, "k": 100, "ix": 7}
            }]
        }],
        "ind": 51
    }],
    "ddd": 0,
    "h": 1025,
    "w": 1025,
    "meta": {"a": "", "k": "", "d": "", "g": "@lottiefiles/toolkit-js 0.18.1", "tc": "#ffffff"},
    "v": "5.7.12",
    "fr": 30,
    "op": 60,
    "ip": 0,
    "assets": []
}