<template>
    <div class="form-composition-card form-composition-card--static">
        <div class="form-composition-card__header">
            Pages
        </div>
        <div class="form-composition-card__content">
            <FormPagesListDragManager
                    :page-name-getter="pageNameGetter"
                    :page-icon-getter="pageIconGetter"
                    @current-page-changed="blurAllNameEditors"
            >
                <template #main-page="{ pageData: { number, name, pageId }, pageIndex, hover, isDragging }">
                    <div class="page-tab static-page-tab">
                        <div class="static-page-tab-prepend static-page-tab-number">
                            {{number}}
                        </div>
                        <div class="static-page-tab-name">
                            <PageNameEditor
                                    :ref="'pageNameEditor' + pageIndex"
                                    :page-name="name"
                                    @input="changePageName(pageId, $event)"
                            />
                            <PageActions
                                    v-if="!isDragging && getFormJSON.main_pages.length !== 1"
                                    :hover="hover"
                                    @rename-page="$refs['pageNameEditor' + pageIndex].startEditing()"
                                    @remove-page="removePage(pageId)"
                            />
                        </div>
                    </div>
                </template>
                <template #finish-page>
                    <div class="page-tab static-page-tab">
                        <div class="static-page-tab-prepend static-page-tab-icon">
                            {{constants.FORM_PAGES_ICONS.FINISH_PAGE}}
                        </div>
                        <div class="static-page-tab-name">
                            {{getFormJSON.all_pages[getFormJSON.finish_page.page_id].name}}
                        </div>
                    </div>
                </template>
            </FormPagesListDragManager>
        </div>
        <div class="form-composition-card__actions">
            <v-btn
                    class="justify-start px-0"
                    color="blue_400"
                    block
                    depressed
                    text
                    tile
                    height="36"
                    @click="addNewPage"
            >
                <span class="subtitle-2 d-flex justify-center align-center static-page-tab-prepend">
                    <PlusIcon size="22"/>
                </span>
                <span class="subtitle-2">
                    New Page
                </span>
            </v-btn>
        </div>
        <GenericStoreDialog store-module-path="edit/pages/confirmation_dialog"/>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import {PlusIcon} from 'vue-feather-icons'

    export default {
        name: "StaticFormPagesList",
        components: {
            PlusIcon,
            PageNameEditor: () => import('./Elements/PageNameEditor'),
            FormPagesListDragManager: () => import('@/components/FormEditor/FormComposition/FormPagesListDragManager'),
            PageActions: () => import('./Elements/PageActions'),
            GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog')
        },
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
            ...mapGetters('edit/pages_navigation', ['getCurrentPageId']),
        },
        methods: {
            pageNameGetter(pageObj) {
                return pageObj.name
            },
            pageIconGetter() {
                return undefined
            },

            blurAllNameEditors() {
                Object.entries(this.$refs).forEach(([ref, component]) => {
                    if (ref.startsWith('pageNameEditor') && component)
                        component.stopEditing()
                })
            },

            addNewPage() {
                this.$store.dispatch(
                    'edit/pages/addNewFormMainPage', this.constants.FORM_TYPES.STATIC_FORM.MAIN_PAGE_TEMPLATE()
                )
                    .then(newPageId => this.$store.dispatch('edit/pages_navigation/setCurrentPageId', newPageId))
                    .then(() => this.$store.dispatch('edit/pages/setPreviousMainPageButtonSettings'))
            },
            removePage(pageId) {
                this.$store.dispatch('edit/pages/removeFormPage', pageId)
            },


            changePageName(pageId, newPageName) {
                this.$store.dispatch('edit/pages/changeFormPage', {
                    pageID: pageId,
                    pageObj: {...this.getFormJSON.all_pages[pageId], name: newPageName}
                })
            }
        },
    }
</script>

<style lang="scss">
    .form-composition-card.form-composition-card--static {
        .static-page-tab {
            display: flex;
            height: 100%;

            .static-page-tab-name {
                flex-grow: 1;
            }

            &, & > * {
                display: flex;
                align-items: center;
            }

            .static-page-tab-icon {
                font-size: 20px;
            }
        }
    }

    .static-page-tab-prepend {
        width: 46px;
        justify-content: center;
    }
</style>