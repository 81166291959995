var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-row-with-progress"
  }, [_vm._t("prepend"), _c('div', {
    staticClass: "summary-row-with-progress__attrs"
  }, [_vm.label ? _c('span', {
    staticClass: "summary-row-with-progress__label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('v-spacer'), _c('span', {
    staticClass: "summary-row-with-progress__count"
  }, [_vm._v(_vm._s(_vm.count) + " resp..")]), _c('span', {
    staticClass: "summary-row-with-progress__percentage"
  }, [_vm._v(_vm._s(_vm.percentage) + "%")]), _c('div', {
    staticClass: "summary-row-with-progress__progress-row"
  }, [_vm._t("prepend-inner"), _c('v-progress-linear', {
    attrs: {
      "height": "8",
      "color": "blue_400",
      "background-color": "blue_100",
      "value": _vm.percentage
    }
  })], 2)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }