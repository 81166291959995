<template>
  <v-container
      class="h-full pa-0"
      fluid
      :class="getIsFormLoadingScreenShown && 'h-100vh-without-appbar overflow-hidden'"
  >
    <FormslyFormWrapper width="1920">
      <FSPVariablesInjector fsp-module-location-path="edit/FSP"
      >
        <v-slide-y-transition mode="out-in">
          <LoadingFormScreen v-if="getIsFormLoadingScreenShown"/>
        </v-slide-y-transition>
        <template v-if="getFormJSON">
          <v-overlay v-if="getOverlay" :value="getOverlay" z-index="15"></v-overlay>
          <StaticFormEditor
              v-if="getFormJSON.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE"
          />

          <InteractiveFormEditor v-else-if="getFormJSON.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE"

          />

        </template>
      </FSPVariablesInjector>
    </FormslyFormWrapper>
  </v-container>
</template>

<script>
import {mapGetters} from "@/xstore";
import store from '@/store'
import constants from '@/constants/constants'
import {computed, provide} from '@vue/composition-api'

export default {
  name: "FormEditor",
  components: {
    StaticFormEditor: () => import('@/components/FormEditor/StaticForm/StaticFormEditor'),
    InteractiveFormEditor: () => import('@/components/FormEditor/InteractiveForm/InteractiveFormEditor'),
    LoadingFormScreen: () => import('@/components/FormEditor/LoadingFormScreen'),
    FSPVariablesInjector: () => import('@/FSP/FSPVariablesInjector'),
    FormslyFormWrapper: () => import('@/components/FormGenericComponents/FormslyFormWrapper')
  },
  setup(props, {root}) {
    const formJSON = computed(() => root.$store.getters['edit/getFormJSON'])
    provide('formJSON', formJSON)
  },
  computed: {
    ...mapGetters('edit', [
      'getFormJSON'
    ]),
    ...mapGetters('edit/loading_screen', [
      'getIsFormLoadingScreenShown'
    ]),
    ...mapGetters('edit/overlay', [
      'getOverlay'
    ])
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('edit/processFormEditorEntry', {previousRoute: from, formID: to.params.formId})
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== constants.PAGE_NAME_SUBMISSIONS_ANALYTICS) {
      store.dispatch('edit/RESET')
      store.dispatch('submissions/RESET')
    }
    next()
  }
}
</script>