var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M112.192 62.4465C112.192 89.2027 90.5016 110.893 63.7453 110.893C36.9891 110.893 15.2988 89.2027 15.2988 62.4465C15.2988 35.6902 36.9891 14 63.7453 14C90.5016 14 112.192 35.6902 112.192 62.4465Z",
      "fill": "#E3EAFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M37.8065 23.3333L40.5319 20.0206C40.686 19.8333 40.5787 19.5495 40.3393 19.5108L36.0237 18.8138C35.783 18.775 35.5916 19.0132 35.6815 19.2399L37.2718 23.2495C37.36 23.4719 37.6545 23.5181 37.8065 23.3333Z",
      "fill": "#2FBAEA"
    }
  }), _c('path', {
    attrs: {
      "d": "M123.307 91.1537C123.307 92.8954 121.895 94.3074 120.154 94.3074C118.412 94.3074 117 92.8954 117 91.1537C117 89.412 118.412 88 120.154 88C121.895 88 123.307 89.412 123.307 91.1537Z",
      "fill": "#E6EBFB"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 34.5C8 36.433 6.433 38 4.5 38C2.567 38 1 36.433 1 34.5C1 32.567 2.567 31 4.5 31C6.433 31 8 32.567 8 34.5Z",
      "fill": "#E6EBFB"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.8825 104.436C27.8825 105.565 26.9679 106.479 25.8397 106.479C24.7115 106.479 23.7969 105.565 23.7969 104.436C23.7969 103.308 24.7115 102.394 25.8397 102.394C26.9679 102.394 27.8825 103.308 27.8825 104.436Z",
      "fill": "#5BC2FC"
    }
  }), _c('circle', {
    attrs: {
      "cx": "94.1333",
      "cy": "20.1333",
      "r": "1.13325",
      "fill": "url(#paint0_linear_8104_41338)"
    }
  }), _c('rect', {
    attrs: {
      "x": "111.623",
      "y": "27",
      "width": "70.2616",
      "height": "96.893",
      "rx": "6",
      "transform": "rotate(90 111.623 27)",
      "fill": "url(#paint1_linear_8104_41338)",
      "stroke": "black",
      "stroke-opacity": "0.08"
    }
  }), _c('rect', {
    attrs: {
      "width": "40.2304",
      "height": "2.83313",
      "rx": "1.41656",
      "transform": "matrix(-1 0 0 1 70.2305 48)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "18.6986",
      "height": "3.39975",
      "rx": "1.69988",
      "transform": "matrix(-1 0 0 1 59.4648 54.833)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "32.0179",
      "height": "3.4614",
      "rx": "1.7307",
      "transform": "matrix(-1 0 0 1 66.125 62.2329)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "x": "41.8984",
      "y": "73.6943",
      "width": "16.4321",
      "height": "4.533",
      "rx": "2.2665",
      "fill": "url(#paint2_linear_8104_41338)"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_8104_41338)"
    }
  }, [_c('rect', {
    attrs: {
      "width": "5.96202",
      "height": "40.5788",
      "rx": "1",
      "transform": "matrix(-0.866025 -0.5 -0.5 0.866025 89.4531 70.9805)",
      "fill": "url(#paint3_linear_8104_41338)"
    }
  })]), _c('rect', {
    attrs: {
      "width": "3.98483",
      "height": "8.8174",
      "rx": "0.5",
      "transform": "matrix(-0.866025 -0.5 -0.5 0.866025 88.0098 71.4805)",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M97.205 65.7085C97.2648 65.5242 97.5255 65.5242 97.5854 65.7085L98.6295 68.922C98.6563 69.0044 98.7331 69.0602 98.8198 69.0602H102.199C102.392 69.0602 102.473 69.3081 102.316 69.422L99.5826 71.4081C99.5125 71.459 99.4832 71.5493 99.51 71.6317L100.554 74.8453C100.614 75.0295 100.403 75.1828 100.246 75.0689L97.5127 73.0828C97.4426 73.0319 97.3477 73.0319 97.2776 73.0828L94.544 75.0689C94.3873 75.1828 94.1764 75.0295 94.2362 74.8453L95.2804 71.6317C95.3072 71.5493 95.2778 71.459 95.2077 71.4081L92.4741 69.422C92.3174 69.3081 92.3979 69.0602 92.5917 69.0602H95.9706C96.0573 69.0602 96.134 69.0044 96.1608 68.922L97.205 65.7085Z",
      "fill": "#FFB11A"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.1308 58.4395C90.1757 58.3013 90.3712 58.3013 90.4161 58.4395L91.1992 60.8497C91.2193 60.9115 91.2769 60.9534 91.3419 60.9534H93.8761C94.0214 60.9534 94.0818 61.1393 93.9642 61.2247L91.914 62.7143C91.8614 62.7525 91.8395 62.8202 91.8595 62.882L92.6426 65.2922C92.6875 65.4303 92.5294 65.5453 92.4118 65.4599L90.3616 63.9703C90.309 63.9321 90.2378 63.9321 90.1853 63.9703L88.1351 65.4599C88.0175 65.5453 87.8593 65.4303 87.9042 65.2922L88.6873 62.882C88.7074 62.8202 88.6854 62.7525 88.6329 62.7143L86.5826 61.2247C86.4651 61.1393 86.5255 60.9534 86.6708 60.9534H89.205C89.27 60.9534 89.3276 60.9115 89.3477 60.8497L90.1308 58.4395Z",
      "fill": "#FFB11A"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.9797 76.9576C93.0246 76.8194 93.2202 76.8194 93.2651 76.9576L93.7283 78.3835C93.7484 78.4453 93.806 78.4871 93.871 78.4871H95.3703C95.5156 78.4871 95.576 78.6731 95.4584 78.7585L94.2455 79.6397C94.1929 79.6779 94.1709 79.7456 94.191 79.8074L94.6543 81.2333C94.6992 81.3715 94.541 81.4864 94.4235 81.401L93.2106 80.5198C93.158 80.4816 93.0868 80.4816 93.0342 80.5198L91.8213 81.401C91.7037 81.4864 91.5456 81.3715 91.5905 81.2333L92.0538 79.8074C92.0739 79.7456 92.0519 79.6779 91.9993 79.6397L90.7864 78.7585C90.6688 78.6731 90.7292 78.4871 90.8745 78.4871H92.3738C92.4388 78.4871 92.4964 78.4453 92.5164 78.3835L92.9797 76.9576Z",
      "fill": "#FFB11A"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_8104_41338",
      "x": "60.3672",
      "y": "68.3652",
      "width": "32.7207",
      "height": "45.3916",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_8104_41338"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_8104_41338",
      "result": "shape"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint0_linear_8104_41338",
      "x1": "93.0574",
      "y1": "20.117",
      "x2": "95.2562",
      "y2": "20.117",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FF66A9"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#F53689"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_8104_41338",
      "x1": "147.676",
      "y1": "27",
      "x2": "147.676",
      "y2": "125.719",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint2_linear_8104_41338",
      "x1": "41.8639",
      "y1": "75.9611",
      "x2": "58.3266",
      "y2": "75.9611",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3D66F6"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#6789FF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint3_linear_8104_41338",
      "x1": "2.98101",
      "y1": "0",
      "x2": "2.98101",
      "y2": "40.5788",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#4269F1"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.46875",
      "stop-color": "#2FBBEB"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.979167",
      "stop-color": "#BA65FD"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }