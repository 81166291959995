var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11 4H3.94444C3.69396 4 3.45374 4.0995 3.27662 4.27662C3.0995 4.45374 3 4.69396 3 4.94444V20.0556C3 20.306 3.0995 20.5463 3.27662 20.7234C3.45374 20.9005 3.69396 21 3.94444 21H19.0556C19.306 21 19.5463 20.9005 19.7234 20.7234C19.9005 20.5463 20 20.306 20 20.0556V12.5",
      "stroke-width": "1.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.2156 11.8064L10 14M18.2421 9.8541C16.1262 12.2561 12.2156 11.8064 12.2156 11.8064C12.2156 11.8064 11.7836 7.41921 13.9106 5.02818C14.0951 4.81233 14.3031 4.61737 14.531 4.44688L16.6469 5.22561L17.1676 3.22943C18.0858 3.01993 19.0314 2.95332 19.9703 3.03201C19.9703 3.03201 20.3691 7.41921 18.2421 9.8541Z",
      "stroke-width": "1.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }