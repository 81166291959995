var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-main-page__footer-wrapper"
  }, [_c('div', {
    staticClass: "static-main-page-footer"
  }, [_c('div', {
    staticClass: "static-page-action-button-wrapper"
  }, [_c('div', {
    staticClass: "static-page-action-button",
    on: {
      "click": _vm.executeButtonAction
    }
  }, [_c('span', {
    staticClass: "static-page-action-button__text static-page-action-button__text--readonly"
  }, [_vm._v(_vm._s(_vm.getCurrentPage.submit_button.properties.button_text || 'Next'))])])]), _c('div', {
    staticClass: "static-main-page-footer__disclaimer"
  }, [_vm._v(" Never submit passwords through Forms ")])]), _c('div', {
    staticClass: "static-main-page-footer-transition-helper"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }