<template>
    <p class="form-parameters-hint">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        name: "FormParametersHint"
    }
</script>

<style lang="scss" scoped>
    .form-parameters-hint {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #8A8F99;
        margin-top: 6px;
    }
</style>