import FSPThemesActions from '@/utils/actions/fsp_themes_actions'
import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            theme_obj: undefined
        }
        useExtension(SMAGableExtensions.SMGable, {state})
    }

    return {
        actions: {
            pushThemeObj: ({commit}, themeObj) => commit('SET_THEME_OBJ', FSPThemesActions.unescapeThemesRawData(themeObj))
        },
        getters: {
            getCurrentStylePreferences: ({getters}) => getters.getThemeObj?.rawData
        }
    }
}