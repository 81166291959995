<template>
    <div>
        <PropsRenderer :vnodes="shown"/>
        <div class="action action--show" v-if="!showAll && $slots.default.length > limitation">
            <InfoIcon size="20"/>
            <p>You see options that got response here. But you can check <span class="action__show-button" @click="showAll = true">All Options</span></p>
        </div>
        <v-expand-transition>
            <div v-show="showAll">
                <PropsRenderer :vnodes="hidden"/>
            </div>
        </v-expand-transition>
        <span class="action action--hide" v-if="showAll && $slots.default.length > limitation" @click="showAll = false">Hide</span>
    </div>
</template>

<script>
    import {InfoIcon} from 'vue-feather-icons'

    export default {
        name: 'Iterator',
        components: {
            InfoIcon,
            PropsRenderer: () => import('@/utils/PropsRenderer.js')
        },
        props: {
            limitation: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                showAll: false,
            }
        },
        computed: {
            shown() {
                return this.$slots.default.slice(0, this.limitation);
            },
            hidden() {
                return this.$slots.default.slice(this.limitation);
            }
        }
    }
</script>

<style scoped lang="scss">
    .action {
        color: var(--v-gray_400-base);
        font-size: 14px;
        cursor: pointer;

        &--show {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &__show-button {
            color: var(--v-blue_400-base);
        }
    }
</style>