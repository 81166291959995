<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3151 19.4991C15.4741 19.4991 17.4331 17.8686 17.4331 15.2646C17.4331 13.3056 15.9571 11.8401 13.9471 11.6856V11.6031C14.7169 11.4872 15.4199 11.1001 15.9293 10.5114C16.4387 9.92283 16.7209 9.17154 16.7251 8.39313C16.7251 6.12813 14.9821 4.70312 12.2041 4.70312H5.76465V19.4991H12.3151ZM8.86215 7.01012H11.4061C12.8506 7.01012 13.6816 7.68663 13.6816 8.87613C13.6816 10.1271 12.7381 10.8561 11.0866 10.8561H8.86215V7.00862V7.01012ZM8.86215 17.1921V12.8961H11.4571C13.2826 12.8961 14.2771 13.6341 14.2771 15.0186C14.2771 16.4331 13.3126 17.1921 11.5291 17.1921H8.86065H8.86215Z"
              fill="currentColor"/>
    </svg>
</template>

<script>
    export default {}
</script>

<style scoped>

</style>