import { render, staticRenderFns } from "./ActionsList.vue?vue&type=template&id=88ca1814&scoped=true&"
import script from "./ActionsList.vue?vue&type=script&lang=js&"
export * from "./ActionsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_react@18.2.0_vue-templa_wkiss7gp4bcqnlea7oiy3v6evi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ca1814",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.6.14_vuetify@2.6.3_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VList,VListItem,VListItemContent,VListItemTitle})
