<template>
  <v-scale-transition origin="center" mode="out-in">
    <AddBadge class="static-form-badge-add-header"
              v-if="coverHovered && !getFormJSON.cover_text.content && !isEditorOpened"
              @click="isEditorOpened = true"
    >
      Add header text
    </AddBadge>
    <MoveableComponentWithEditor
        v-else-if="getFormJSON.cover_text.content || isEditorOpened"
        :selector="selector"
        :content="getFormJSON.cover_text.content"
        :translate="getFormJSON.cover_text.translate"
        :height="getFormJSON.cover_text.height"
        :width="getFormJSON.cover_text.width"
        @content="setTextContent"
        @place="setTranslatesAndHeight"
        ref="moveable-editor"
        :opened-from-outside="isEditorOpened"
        @close-editor="isEditorOpened = false"
    />
  </v-scale-transition>
</template>

<script>
    import {debounce} from 'lodash'
    import {mapGetters} from '@/xstore';
    import MoveableComponentWithEditor from "@/components/Elements/MoveableComponentWithEditor";

    export default {
        name: 'FormCoverTextEditor',
        props: {
          selector: {
            type: String,
            required: true,
          },
          coverHovered: {
            type: Boolean,
            default: false,
          },
        },
        data: () => ({
            isEditorOpened: false,
        }),
        components: {
          MoveableComponentWithEditor,
          AddBadge: () => import('@/components/FormEditor/StaticForm/LogoBadge/Badge/AddBadge')
        },
        methods: {
            setTextContent: debounce(function (content) {
                this.$store.dispatch('edit/changeFormAttrs', {cover_text: {...this.getFormJSON.cover_text, content}});
            }, 1000),
            setTranslatesAndHeight: debounce(function (data) {
                this.$store.dispatch('edit/changeFormAttrs', {cover_text: {...this.getFormJSON.cover_text, ...data}});
            }, 1000),
        },
        computed: {
            ...mapGetters('edit', ['getFormJSON']),
        }
    }
</script>