import { VAutocomplete } from 'vuetify/lib/components'

export default {
    extends: VAutocomplete,
    props: {
        appendIcon: {
            type: String,
            default: 'mdi-chevron-down'
        }
    }
}