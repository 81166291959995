var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('v-spacer'), _c('div', {
    staticClass: "smart-paragraph-field-view__summary-toggle-responses-visibility d-flex align-center subtitle-2 gray_400--text font-weight-regular cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.showHiddenAnswers = !_vm.showHiddenAnswers;
      }
    }
  }, [!_vm.showHiddenAnswers ? _c('EyeIcon', {
    attrs: {
      "size": "20",
      "stroke-width": "1"
    }
  }) : _c('EyeOffIcon', {
    attrs: {
      "size": "20",
      "stroke-width": "1"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.showHiddenAnswers ? 'Hide' : 'Show') + " Hidden responses")])], 1)], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "smart-paragraph-field-view-summary"
  }, [_c('div', {
    staticClass: "smart-paragraph-field-view-summary__content"
  }, [_vm._l(_vm.latestSourceObj.content, function (element, key) {
    return [element.type === 'text' ? [_vm._v(_vm._s(element.value))] : element.type === 'break' ? _c('br', {
      key: key
    }) : element.type === 'variable' ? _c('span', {
      key: key,
      staticClass: "smart-paragraph-field-view-summary__variable-in-content",
      style: {
        color: _vm.formattedVariables[_vm.getKey(element.variable_id, _vm.latestSourceObj.properties.variables_list[element.variable_id])].color
      }
    }, [_vm._v(_vm._s(_vm.latestSourceObj.properties.variables_list[element.variable_id]))]) : _vm._e()];
  })], 2), _c('div', {
    staticClass: "smart-paragraph-field-view-summary__table-wrapper"
  }, [_c('table', {
    staticClass: "smart-paragraph-field-view-summary__table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.sortedVariables, function (varAttrs) {
    return _c('th', {
      key: varAttrs.key + 'th' + _vm.showHiddenAnswers,
      class: varAttrs.hidden && 'smart-paragraph-field-view-summary__hidden-cell',
      style: !varAttrs.hidden && {
        color: _vm.formattedVariables[varAttrs.key].color
      }
    }, [_c('span', {
      staticClass: "d-flex align-center"
    }, [_vm._v(" " + _vm._s(_vm.formattedVariables[varAttrs.key].name) + " "), _c('v-spacer'), _c('v-tooltip', {
      attrs: {
        "top": "",
        "content-class": "tooltip-top pa-5",
        "transition": "none",
        "max-width": "320",
        "nudge-top": "10",
        "color": "gray_900"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [varAttrs.hidden ? _c('InfoIcon', _vm._g({
            staticClass: "mr-n2",
            attrs: {
              "size": "20",
              "stroke-width": "1"
            }
          }, on)) : _vm._e()];
        }
      }, {
        key: "default",
        fn: function () {
          return [_c('p', {
            staticClass: "subtitle-1 font-weight-semi-bold mb-2"
          }, [_vm._v("Hidden Variable Responses")]), _c('p', {
            staticClass: "subtitle-2 font-weight-regular text-pre-wrap mb-0"
          }, [_vm._v("These Variables were removed from the latest published version of your form, therefor we hide them from the default view of responses summary")])];
        },
        proxy: true
      }], null, true)
    })], 1)]);
  }), 0)]), _c('tbody', _vm._l(_vm.filteredSubmissions, function (_ref2) {
    var submissionID = _ref2.submissionID;
    return _c('tr', {
      key: submissionID
    }, _vm._l(_vm.sortedVariables, function (_ref3) {
      var hidden = _ref3.hidden,
        key = _ref3.key;
      return _c('td', {
        key: key + 'td',
        class: hidden && 'smart-paragraph-field-view-summary__hidden-cell',
        domProps: {
          "innerHTML": _vm._s(_vm.formattedVariables[key].answers[submissionID] && !_vm.formattedVariables[key].answers[submissionID].empty ? _vm.formattedVariables[key].answers[submissionID].text : '-')
        }
      });
    }), 0);
  }), 0)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }