var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded-pill white form-parameters",
    class: {
      'form-parameters-style-preferences-btn-hovered': _vm.stylePreferencesButtonHovered,
      'form-parameters-settings-btn-hovered': _vm.settingsButtonHovered,
      'form-parameters-integrations-btn-hovered': _vm.integrationsButtonHovered
    }
  }, [_c('f-hover-tooltip', {
    attrs: {
      "left": "",
      "text": "Style"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "form-parameters-style-preferences-btn v-btn--primary",
          attrs: {
            "color": "gray_3",
            "depressed": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.setStylePreferencesMenuModel(true);
            },
            "mouseover": function ($event) {
              _vm.stylePreferencesButtonHovered = true;
            },
            "mouseout": function ($event) {
              _vm.stylePreferencesButtonHovered = false;
            }
          }
        }, on), [_c('v-icon', {
          staticClass: "gray_3--text"
        }, [_vm._v("$feather")])], 1)];
      }
    }])
  }), _c('br'), _c('f-hover-tooltip', {
    attrs: {
      "left": "",
      "text": "Settings"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mt-3 form-parameters-settings-btn v-btn--primary",
          attrs: {
            "color": "gray_3",
            "depressed": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.$store.dispatch('edit/FSP_themes/processStylePreferencesExit', function () {
                return _vm.settingsMenuValue = true;
              });
            },
            "mouseover": function ($event) {
              _vm.settingsButtonHovered = true;
            },
            "mouseout": function ($event) {
              _vm.settingsButtonHovered = false;
            }
          }
        }, on), [_c('SettingsIcon', {
          attrs: {
            "stroke-width": "1.6"
          }
        })], 1)];
      }
    }])
  }), _c('br'), _c('f-hover-tooltip', {
    attrs: {
      "left": "",
      "text": "Integrations"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mt-3 form-parameters-integrations-btn v-btn--primary",
          attrs: {
            "color": "gray_3",
            "depressed": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.$store.dispatch('edit/FSP_themes/processStylePreferencesExit', function () {
                return _vm.integrationsMenuValue = true;
              });
            },
            "mouseover": function ($event) {
              _vm.integrationsButtonHovered = true;
            },
            "mouseout": function ($event) {
              _vm.integrationsButtonHovered = false;
            }
          }
        }, on), [_c('span', {
          staticClass: "smalldot"
        }), _c('BoxIcon', {
          attrs: {
            "stroke-width": "1.6"
          }
        })], 1)];
      }
    }])
  }), _c('FormParametersMenuTemplate', {
    attrs: {
      "value": _vm.getStylePreferencesMenuModel,
      "min-width": 320
    }
  }, [_c('FormStylePreferences', {
    on: {
      "close": function ($event) {
        _vm.$store.dispatch('edit/FSP_themes/processStylePreferencesExit', function () {
          return _vm.setStylePreferencesMenuModel(false);
        });
      }
    }
  })], 1), _c('FormParametersMenuTemplate', {
    attrs: {
      "min-width": 420
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var active = _ref4.active;
        return [_c('FormSettings', {
          attrs: {
            "active": active
          },
          on: {
            "close": function ($event) {
              _vm.settingsMenuValue = false;
            }
          }
        })];
      }
    }]),
    model: {
      value: _vm.settingsMenuValue,
      callback: function ($$v) {
        _vm.settingsMenuValue = $$v;
      },
      expression: "settingsMenuValue"
    }
  }), _c('FormParametersMenuTemplate', {
    attrs: {
      "min-width": 420
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var active = _ref5.active;
        return [_c('FormIntegrations', {
          attrs: {
            "active": active,
            "formId": _vm.$route.params.formId
          },
          on: {
            "close": function ($event) {
              _vm.integrationsMenuValue = false;
            }
          }
        })];
      }
    }]),
    model: {
      value: _vm.integrationsMenuValue,
      callback: function ($$v) {
        _vm.integrationsMenuValue = $$v;
      },
      expression: "integrationsMenuValue"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }