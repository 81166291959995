import Desktop from './InteractiveHomePageLayouts/Views/Desktop.js'
import Mobile from './InteractiveHomePageLayouts/Views/Mobile.js'
import _ from 'lodash'

export default {
    name: 'InteractiveHomePageDisplayManager',
    functional: true,
    render(h, ctx) {
        return h(
            'div',
            {
                style: {
                    height: '100%',
                }
            },
            [
                h(
                    'div',
                    {
                        class: 'interactive-form-home-page__desktop-view-wrapper',
                    },
                    [h(Desktop, _.cloneDeep(ctx.data))]
                ),
                h(
                    'div',
                    {
                        class: 'interactive-form-home-page__mobile-view-wrapper',
                    },
                    [h(Mobile, _.cloneDeep(ctx.data))]
                ),
            ]
        )
    }
}