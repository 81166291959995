import Vue from 'vue'

const ErrorAlertEventBus = new Vue()

export const alert_mixin = {
    methods: {
        emitErrorAlert(errorText) {
            ErrorAlertEventBus.$emit('show', errorText)
        }
    }
}

export {ErrorAlertEventBus}