import GenericSelectionSubmittableFieldView from '@/components/Fields/GenericFieldViews/GenericSelectionFieldView/GenericSelectionSubmittableFieldView'

export default {
  extends: GenericSelectionSubmittableFieldView,
  name: 'Submittable__SingleSelection',
  computed: {
    radio() {
      return true
    },
  },
  methods: {
    optionInput(index, model) {
      model.value = [index]
    },
  },
}
