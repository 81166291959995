import {executeGraphQLQueryList} from '@/utils/graphql_executors'
import {listForms} from "@formsly/shared/api/graphql"
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ModuleableExtensions, ModuleableUtils } from '@/xstore/utils/moduleable'
import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'

import forms_list_single_form_store from './modules/forms_list_single_form_store'
import { StoreableExtensions, StoreableUtils } from '@/xstore/utils/storeable'
import _ from 'lodash'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

export default ({useExtension}) => {
    {
        const state = {
            forms_loading_statuses: {}
        }

        useExtension(SMAGableExtensions.SGable, {state})
    }

    {
        const state = {
            forms_list: []
        }

        useExtension(SMAGableExtensions.SMGable, {state})
        useExtension(ToggleableExtensions.Loadable, {state})
    }

    useExtension(StoreableExtensions.Default)
    useExtension(PathableExtensions.Default)
    useExtension(ModuleableExtensions.Default)

    return {
        mutations: {
            UPDATE_FORM_LOADING_STATUS: (state, status) => state.forms_loading_statuses = {...state.forms_loading_statuses, ...status}
        },
        actions: {
            setFormsList: async ({commit, dispatch, getters}, formsList) => {
                const store = getters[StoreableUtils.storeGetterName]
                const selfModule = getters[ModuleableUtils.moduleGetterName]
                const selfPath = getters[PathableUtils.pathGetterName]


                Object.keys(selfModule._children)
                    .forEach(key => {
                        if (!formsList.some(({id}) => id === key))
                            store.unregisterModule([...selfPath, key])
                    })


                dispatch('FSP_themes/fetchThemesListById', formsList.map(({FSPThemeID}) => FSPThemeID), {root: true})

                await dispatch('user/getAuthCredentials', null, {root: true})

                formsList.slice()
                    .forEach(formDbData => {
                        if (!(formDbData.id in selfModule._children)) {
                            store.registerModule([...selfPath, formDbData.id], forms_list_single_form_store)

                            dispatch(formDbData.id + '/initialSetFormDbData', formDbData)
                                .then(() => commit('UPDATE_FORM_LOADING_STATUS', {[formDbData.id]: true}))
                        } else {
                            dispatch(formDbData.id + '/replaceFormDbData', formDbData)
                        }
                    })


                commit('SET_FORMS_LIST', formsList)
            },
            fetchFormsList: ({dispatch, toggleIsLoading}) => toggleIsLoading(
                executeGraphQLQueryList(listForms)
                    .then(data => dispatch('setFormsList', Array.from(data).sort(({createdAt: a}, {createdAt: b}) => b - a)))
                    .catch(err => {
                        dispatch('setFormsList', [])
                        console.log(err)
                    }),
                'forms_list'
            ),
            fetchFormsListIfNotFetchedYet: ({getters, dispatch}) => {
                if (_.isEmpty(getters.getFormsList))
                    dispatch('fetchFormsList')
            },
            overwriteFormDataInFormsList: ({dispatch, getters}, {id: formID, attrs}) => {
                dispatch('setFormsList', getters.getFormsList.map(formData => formData.id === formID ? {...formData, ...attrs} : formData))
            },
            removeFormDataFromFormsList: ({dispatch, getters}, formID) => {
                dispatch('setFormsList', getters.getFormsList.filter(formData => formData.id !== formID))
            }
        },
    }
}
