import OwnersFormActions from '@/utils/actions/owners_form_actions'
import _, { debounce } from 'lodash'
import constants from '@/constants/constants'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'
import { watch } from '@vue/composition-api'

export default ({useExtension}) => {
    {
        const state = {
            form_json: false,
            form_db_data: false,
        }

        useExtension(ToggleableExtensions.Custom('saving'), {state})
        useExtension(ToggleableExtensions.Custom('saved'), {state})
    }

    return {
        created: ({localContext, parentContext}) => {
            watch(
                () => parentContext.getters.getFormJSON,
                debounce(
                    (newValue, oldValue) => {
                        if (!_.isEmpty(oldValue)) {
                            localContext.dispatch('saveFormJSON')
                        }
                    },
                    constants.FORM_AUTOSAVE_TIMEOUT,
                    {maxWait: constants.FORM_AUTOSAVE_TIMEOUT}
                ),
                {deep: true}
            )

            watch(
                () => parentContext.getters.getFormDBData,
                debounce(
                    (newValue, oldValue) => {
                        if (!_.isEmpty(oldValue)) {
                            localContext.dispatch('saveFormDBData')
                        }
                    },
                    constants.FORM_AUTOSAVE_TIMEOUT,
                    {maxWait: constants.FORM_AUTOSAVE_TIMEOUT}
                ),
                {deep: true}
            )
        },
        actions: {
            saveFormDBData: async ({parentGetters, parentCommit, toggleIsSaving, toggleIsSaved}) => {
                if (!parentGetters.isFormDbDataChangedAfterSaving)
                    return

                await toggleIsSaving(
                    OwnersFormActions.updateFormDBData(parentGetters.getFormDBData)
                        .then(() => parentCommit('SET_FORM_DB_DATA_INITIAL', parentGetters.getFormDBData)),
                    'form_db_data'
                )

                await toggleIsSaved(
                    new Promise(resolve => setTimeout(resolve, 1000)),
                    'form_db_data'
                )
            },
            saveFormJSON: async ({parentGetters, parentCommit, toggleIsSaving, toggleIsSaved}) => {
                if (!parentGetters.isFormJsonChangedAfterSaving)
                    return

                await toggleIsSaving(
                    OwnersFormActions.saveFormJSON(parentGetters.getFormJSON)
                        .then(response => {
                            parentCommit('SET_FORM_JSON_INITIAL', parentGetters.getFormJSON)
                            parentCommit('SET_FORM_VERSION_ID', response.response.VersionId)
                        }),
                    'form_json'
                )

                await toggleIsSaved(
                    new Promise(resolve => setTimeout(resolve, 1000)),
                    'form_json'
                )
            },
        },
        getters: {
            isFormSaving: ({getters}) => getters.getFormJsonIsSaving || getters.getFormDbDataIsSaving,
            isFormSaved: ({getters}) => !getters.isFormSaving && (getters.getFormJsonIsSaved || getters.getFormDbDataIsSaved)
        }
    }
}