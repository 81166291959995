<template>
  <div class="image-field-view">
    <slot name="loader"></slot>
    <v-hover v-slot="{ hover }">
      <v-img
          v-if="showImage"
          transition="none"
          eager
          v-bind="{
          ...$attrs
          }"
      >
        <slot :hover="hover" name="removeButton"></slot>
      </v-img>
    </v-hover>
    <slot name="addButton"></slot>
  </div>
</template>

<script>
export default {
  name: 'SharedView__Image',
  props: {
    showImage: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>