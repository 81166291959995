var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormParametersContentTemplate', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "dark_gray--text ml-2"
        }, [_vm._v("$feather")]), _vm._v(" Style ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "style-preferences-content"
        }, [_vm.isFormStatic ? _c('div', {
          staticClass: "style-preferences-content__layout-style"
        }, [_c('FormParametersSubheader', [_vm._v("Layout")]), _c('div', {
          staticClass: "style-preferences-content__layout-style-selector"
        }, [_c('f-checkbox', {
          attrs: {
            "value": _vm.currentLayoutStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN,
            "radio": ""
          },
          on: {
            "input": function ($event) {
              return _vm.changeFormAttrs({
                layout_style: _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', [_vm._v("Full Screen")]), _c('v-spacer'), _c('LayoutFullScreenImage', {
                attrs: {
                  "active": _vm.currentLayoutStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN
                }
              })];
            },
            proxy: true
          }], null, false, 3122961878)
        }), _c('f-checkbox', {
          attrs: {
            "value": _vm.currentLayoutStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT,
            "radio": ""
          },
          on: {
            "input": function ($event) {
              return _vm.changeFormAttrs({
                layout_style: _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', [_vm._v("Compact")]), _c('v-spacer'), _c('LayoutCompactImage', {
                attrs: {
                  "active": _vm.currentLayoutStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT
                }
              })];
            },
            proxy: true
          }], null, false, 552773425)
        })], 1), _c('div', {
          staticClass: "mt-3"
        }, [_c('FormParametersSubheader', [_vm._v("HEADER")])], 1), _c('div', {
          staticClass: "style-preferences-content__layout-style-selector"
        }, [_c('f-checkbox', {
          attrs: {
            "value": _vm.currentLayoutHeaderStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE,
            "checkbox": ""
          },
          on: {
            "input": _vm.onChangeVisible
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', [_vm._v("Show Header")]), _c('v-spacer')];
            },
            proxy: true
          }], null, false, 1541952000)
        })], 1), _c('div', {
          staticClass: "style-preferences-content__layout-style-selector"
        }, [_c('f-checkbox', {
          attrs: {
            "value": _vm.currentLayoutLogoStyle === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE,
            "checkbox": ""
          },
          on: {
            "input": _vm.onChangeVisibleLogo
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', [_vm._v("Show Logo")]), _c('v-spacer')];
            },
            proxy: true
          }], null, false, 3052449204)
        })], 1)], 1) : _vm._e(), _vm.isFormInteractive ? _c('div', {
          staticClass: "style-preferences-content__layout-style style-preferences-content__layout-style--interactive",
          class: !_vm.isHomePageHasImage && 'style-preferences-content__layout-style--interactive--homepage-image-picker'
        }, [_c('FormParametersSubheader', [_vm._v("Homepage " + _vm._s(_vm.isHomePageHasImage ? 'layout' : 'image'))]), _vm.isHomePageHasImage ? _c('div', {
          staticClass: "style-preferences-content__layout-style-types"
        }, _vm._l(_vm.constants.FORM_TYPES.INTERACTIVE_FORM.INTERACTIVE_HOME_PAGE_LAYOUTS, function (_ref) {
          var key = _ref.key,
            icon = _ref.icon;
          return _c('div', {
            key: key,
            class: ['style-preferences-content__layout-style-types-icon', _vm.constants.FORM_TYPES.INTERACTIVE_FORM.INTERACTIVE_HOME_PAGE_LAYOUTS_BY_KEY[_vm.getFormJSON.all_pages[_vm.getFormJSON.home_page.page_id].layout].key === key && 'style-preferences-content__layout-style-types-icon--selected'],
            on: {
              "click": function ($event) {
                return _vm.$store.dispatch('edit/pages/changeFormPage', {
                  pageID: _vm.getFormJSON.home_page.page_id,
                  pageObj: Object.assign({}, _vm.getFormJSON.all_pages[_vm.getFormJSON.home_page.page_id], {
                    layout: key
                  })
                });
              }
            }
          }, [_c(icon, {
            tag: "component"
          })], 1);
        }), 0) : _c('v-btn', {
          staticClass: "style-preferences-content__layout-style-add-homepage-image-btn gray_500--text",
          attrs: {
            "color": "gray_100",
            "rounded": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$store.dispatch('edit/homepage_image_preview/openImagePicker');
            }
          }
        }, [_vm._v(" Add ")])], 1) : _vm._e(), _c('v-divider'), _c('v-slide-x-reverse-transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [_vm.isThemeEditorActive ? _c('StylePreferencesThemeEditor') : _c('StylePreferencesThemesStore')], 1)], 1), _c('GenericStoreDialog', {
          attrs: {
            "store-module-path": "edit/FSP_themes/confirmation_dialog"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }