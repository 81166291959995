var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-editable-field-constructor__actions"
  }, [_c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Edit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "gray_500--text v-btn--primary",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.openProperties
          }
        }, on), [_c('Edit2Icon', {
          attrs: {
            "stroke-width": "1.7",
            "size": "20"
          }
        })], 1)];
      }
    }])
  }), _c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Duplicate field"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "gray_500--text mt-2  v-btn--primary",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.duplicateField
          }
        }, on), [_c('CopyIcon', {
          attrs: {
            "stroke-width": "1.7",
            "size": "20"
          }
        })], 1)];
      }
    }])
  }), _vm.conditionalLogicSupport ? _c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Conditional logic"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          staticClass: "gray_500--text mt-2  v-btn--primary",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.addConditionalLogic
          }
        }, on), [_c('GitMergeIcon', {
          attrs: {
            "stroke-width": "1.7",
            "size": "20"
          }
        })], 1)];
      }
    }], null, false, 2624423924)
  }) : _vm._e(), _c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Delete"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-btn', _vm._g({
          staticClass: "gray_500--text mt-2 v-btn--error",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.removeField
          }
        }, on), [_c('TrashIcon', {
          attrs: {
            "stroke-width": "1.7",
            "size": "20"
          }
        })], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }