<template>
    <InteractiveFieldConstructorTemplate
            :field-number="getExistingFields[fieldId].index + 1"
            :field-type-const="constants.FIELD_FROM_TYPE[getFormSourceJSON.fields[fieldId].field_type]"
            submittable
    >
        <template #field-view>
            <SubmittableInteractiveFieldConstructor
                    :field-id="fieldId"
            />
        </template>
        <template #button-content>
          {{ (getExistingFields[fieldId].index + 1 === Object.keys(getExistingFields).length) ? 'Submit' : 'OK' }}
          <ChevronRightIcon/>
        </template>
    </InteractiveFieldConstructorTemplate>
</template>

<script>
    import { useMapGetters } from '@/xstore'
    import { inject, computed } from '@vue/composition-api/dist/vue-composition-api'

    export default {
        name: "InteractiveMainPage",
        components: {
            InteractiveFieldConstructorTemplate: () => import('@/components/FormGenericComponents/InteractiveForm/InteractiveFieldConstructorTemplate'),
            SubmittableInteractiveFieldConstructor: () => import('../../Field/SubmittableInteractiveFieldConstructor'),
            ChevronRightIcon: () => import('vue-feather-icons/icons/ChevronRightIcon'),
        },
        props: {
            pageId: {
                type: String,
                required: true
            }
        },
        setup(props) {
            const formSubmitStoreModulePath = inject('formSubmitStoreModulePath')

            const getters = {
                ...useMapGetters(formSubmitStoreModulePath, [
                  'getMainPagesWithIndexes',
                  'getFormSourceJSON'
                ]),
                ...useMapGetters(formSubmitStoreModulePath + '/conditional', [
                  'getExistingFields'
                ]),
            }

            return {
                ...getters,
                fieldId: computed(() => getters.getFormSourceJSON.value.all_pages[props.pageId].field_id)
            }
        },
    }
</script>