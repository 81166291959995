var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  }), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "generic-text-summary-field-view"
  }, _vm._l(_vm.filteredSubmissions, function (_ref, submissionIndex) {
    var submissionObj = _ref.submissionObj;
    return _c('p', {
      key: submissionIndex,
      staticClass: "generic-text-summary-field-view__answer"
    }, [_vm._v(" " + _vm._s('• ' + submissionObj.value) + " ")]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }