var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('v-snackbar', {
    attrs: {
      "value": _vm.show,
      "color": _vm.color,
      "rounded": "pill",
      "bottom": "",
      "min-width": "1"
    }
  }, [_c('span', {
    staticClass: "snackbar-icon mr-2"
  }, [_vm._v(" " + _vm._s(_vm.emoji) + " ")]), _vm._v(" " + _vm._s(_vm.text) + " ")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }