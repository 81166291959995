var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#EBF1FC"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.51128 17.4423L10.2915 13.662L11.6156 14.9861L13.6017 13L18.044 17.4423",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.5556 12.2141V17.3784C18.5556 18.0031 18.069 18.5555 17.4136 18.5555H7.14198C6.48653 18.5555 6 18.0031 6 17.3784V7.89925C6 7.27458 6.48653 6.72217 7.14198 6.72217H12.3045C12.2872 6.8866 12.2784 7.05335 12.2784 7.22203C12.2784 7.3908 12.2872 7.55764 12.3045 7.72217H7.14198C7.08832 7.72217 7 7.77603 7 7.89925V17.3784C7 17.5016 7.08832 17.5555 7.14198 17.5555H17.4136C17.4672 17.5555 17.5556 17.5016 17.5556 17.3784V12.2759C17.6019 12.277 17.6484 12.2776 17.695 12.2776C17.9879 12.2776 18.2753 12.2559 18.5556 12.2141Z",
      "fill": "#393F41"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.1667 8.08883L16.5208 9.38883L18.7778 7.22217",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }