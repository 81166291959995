var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isExist ? _c('div', {
    staticClass: "editable-field-property"
  }, [_c('div', {
    staticClass: "editable-field-property__switcher-row"
  }, [_c('div', {
    staticClass: "editable-field-property__header"
  }, [_c('span', {
    staticClass: "editable-field-property__name"
  }, [_vm._v(" " + _vm._s(_vm.propertyConst.name) + " ")]), _vm.propertyConst.tooltip ? _c('v-tooltip', {
    staticStyle: {
      "position": "absolute"
    },
    attrs: {
      "bottom": "",
      "attach": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('AlertCircleIcon', _vm._g({
          staticClass: "gray_400--text ml-2 cursor-pointer",
          attrs: {
            "size": "19"
          }
        }, on))];
      }
    }], null, false, 3632057)
  }, [_vm._v(" " + _vm._s(_vm.propertyConst.tooltip) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "editable-field-property__switch-wrapper"
  }, [_vm.propertyConst.switch_prepend ? _c(_vm.propertyConst.switch_prepend, {
    tag: "component",
    attrs: {
      "field-id": _vm.fieldId
    }
  }) : _vm._e(), !_vm.propertyConst.always_enabled ? _c('f-switch', {
    attrs: {
      "value": _vm.isPropertyEnabled
    },
    on: {
      "input": function (v) {
        return v ? _vm.enableProperty() : _vm.disableProperty();
      }
    }
  }) : _vm._e()], 1)]), _vm.isPropertyEnabled && !_vm.isPropertyWithoutConstructor ? _c('div', {
    staticClass: "editable-field-property__input-group"
  }, [_vm.isPropertyEnabled && !_vm.isPropertyWithoutConstructor ? _c('div', {
    staticClass: "editable-field-property__input-group__grid"
  }, _vm._l(Object.entries(_vm.propertyConst.input_group.inputGroup), function (_ref2, index) {
    var inputName = _ref2[0],
      inputConst = _ref2[1];
    return _c('div', {
      key: index,
      staticClass: "editable-field-property__input-group__grid__item",
      class: [inputConst.half_width && 'editable-field-property__input-group__grid__item--half-width', inputConst.new_line && 'editable-field-property__input-group__grid__item--new-line']
    }, [_c(inputConst.component, {
      key: _vm.propertyName + inputName,
      tag: "component",
      attrs: {
        "property-name": _vm.propertyName,
        "property-value": _vm.propertyValue,
        "field-properties-obj": _vm.fieldPropertiesObj,
        "input-name": inputName,
        "input-const": inputConst,
        "input-value": _vm.isPropertyWithSimpleConstructor ? _vm.propertyValue : _vm.propertyValue[inputName]
      },
      on: {
        "input": function ($event) {
          return _vm.changePropertyInputValue(inputName, $event);
        }
      }
    })], 1);
  }), 0) : _vm._e()]) : _vm._e(), _vm.propertyConst.caption ? [_vm.propertyConst.link_in_caption ? _c('div', {
    staticClass: "editable-field-property__caption",
    on: {
      "click": _vm.upgradeToProFromModal
    }
  }, [_vm._v(" " + _vm._s(_vm.propertyConst.caption) + " "), _c('span', {
    staticClass: "editable-field-property__caption--link"
  }, [_vm._v(" " + _vm._s(_vm.propertyConst.linked_caption_part) + " ")])]) : typeof _vm.propertyConst.caption === 'function' ? _c(_vm.propertyConst.caption, {
    tag: "component",
    attrs: {
      "field-id": _vm.fieldId,
      "property-value": _vm.propertyValue
    }
  }) : _vm._e()] : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }