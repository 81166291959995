var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm._t("icon")], 2)], 1)], 1), _c('v-row', {
    staticClass: "text-h4 font-weight-bold"
  }, [_c('v-col', [_vm._t("title")], 2)], 1), _c('v-row', {
    staticClass: "text--secondary"
  }, [_c('v-col', [_vm._t("description")], 2)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', [_vm._t("code-input")], 2)], 1), _c('v-row', [_c('v-col', [_vm._t("action")], 2)], 1), _c('v-row', [_c('v-col', [_vm._t("secondary-action")], 2)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "subtitle-2 gray_500--text",
    attrs: {
      "depressed": "",
      "text": "",
      "rounded": ""
    },
    on: {
      "click": _vm.goToLogin
    }
  }, [_c('ArrowLeftIcon', {
    staticClass: "mr-4"
  }), _vm._v(" Back to log in ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }