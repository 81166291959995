var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveHomePage', {
    attrs: {
      "page-obj": _vm.pageObj
    },
    on: {
      "go-to-next-page": _vm.goToNextPage
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }