export const PathableUtils = Object.freeze({
    pathGetterName: 'getSelfPath',
    namespaceGetterName: 'getSelfNamespace'
})


const DefaultPathableSBGenerator = ({module}) => ({getters: {
    [PathableUtils.pathGetterName]: () => module._path,
    [PathableUtils.namespaceGetterName]: () => module._path.join('/')
}})
DefaultPathableSBGenerator.key = 'Pathable'

export const PathableExtensions = Object.freeze({
    Default: {
        SBGenerator: DefaultPathableSBGenerator
    }
})