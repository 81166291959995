<template>
    <v-dialog v-bind="bind" v-on="$listeners">
        <template #activator="activatorData">
            <slot name="activator" v-bind="activatorData"/>
        </template>
        <template #default>
            <v-card>
                <v-card-title>
                   <slot name="title"/>
                    <template v-if="withCloseBtn">
                        <v-spacer/>
                        <v-btn :disabled="bind.persistent" icon @click="closeDialog"><XIcon/></v-btn>
                    </template>
                </v-card-title>
                <v-card-text>
                    <slot name="content"/>
                </v-card-text>
                <v-card-actions>
                    <slot name="actions"/>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
    export default {
        name: "Dialog",
        inheritAttrs: false,
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            withCloseBtn: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            bind() {
                return {
                    ...this.$attrs
                }
            }
        },
        methods: {
            closeDialog() {
                this.$emit('input', false)
            }
        }
    }
</script>