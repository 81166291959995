<template>
    <div>
        <FormParametersSubheader>General Settings</FormParametersSubheader>
        <FormSettingsOptionTemplate v-if="'progress_bar' in settingsObj.general_settings"
            :value="settingsObj.general_settings.progress_bar">
            <template #header>
                <FormParametersHeader v-model="settingsObj.general_settings.progress_bar">
                    Progress bar
                </FormParametersHeader>
            </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionTemplate :value="settingsObj.general_settings.formsly_branding">
            <template #header>
                <FormParametersHeader v-model="settingsObj.general_settings.formsly_branding"
                    :allowed-only-with-payment="!getCurrentSubscriptionFeatures?.disable_formsly_branding">
                    Formsly branding
                </FormParametersHeader>
            </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionTemplate v-if="'navigation_arrows' in settingsObj.general_settings"
            :value="settingsObj.general_settings.navigation_arrows">
            <template #header>
                <FormParametersHeader v-model="settingsObj.general_settings.navigation_arrows">
                    Navigation arrows
                </FormParametersHeader>
            </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionTemplate v-if="'auto_scroll' in settingsObj.general_settings"
            :value="settingsObj.general_settings.auto_scroll">
            <template #header>
                <FormParametersHeader v-model="settingsObj.general_settings.auto_scroll">
                    Auto scroll
                </FormParametersHeader>
            </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionWithInput v-model="settingsObj.general_settings.redirect_upon_completion" default-value=""
            @reset="resetFieldState('redirectUponCompletionURL')"
            :allowed-only-with-payment="!getCurrentSubscriptionFeatures?.redirect_upon_completion">
            Redirect on completion
            <template #input>
                <v-text-field v-model="settingsObj.general_settings.redirect_upon_completion" placeholder="Add URL"
                    outlined :error-messages="redirectUponCompletionErrors" hide-details="auto" />
            </template>
        </FormSettingsOptionWithInput>

        <FormParametersSubheader>Notifications</FormParametersSubheader>
        <FormSettingsOptionNavigator @navigate="openEmailNotifications">Email notifications
        </FormSettingsOptionNavigator>

        <FormParametersSubheader>Publishing</FormParametersSubheader>
        <FormSettingsOptionWithInput v-model="settingsObj.publishing.close_date" @reset="resetFieldState('closeDate')"
            :allowed-only-with-payment="!getCurrentSubscriptionFeatures?.auto_close_forms">
            Schedule end date
            <template #input>
                <v-menu min-width="340" max-width="340" :close-on-content-click="false" :close-on-click="false" offset-y
                    :value="isDateTimePickerOpened" offset-overflow>
                    <template #activator="{ on }">
                        <v-text-field :value="readableDate" placeholder="Add Date and time" outlined readonly
                            :error-messages="closeDateErrors" hide-details="auto" v-if="on"
                            @click="isDateTimePickerOpened = true">
                            <template #prepend-inner>
                                <CalendarIcon />
                            </template>
                        </v-text-field>
                    </template>
                    <!--                    <template #default>-->
                    <!--                        <f-date-time date-time-->
                    <!--                                     return-timestamp-->
                    <!--                                     v-model="settingsObj.publishing.close_date"-->
                    <!--                                     @close="isDateTimePickerOpened = false">-->

                    <!--                        </f-date-time>-->
                    <!--                    </template>-->
                </v-menu>
            </template>
        </FormSettingsOptionWithInput>
        <FormSettingsOptionWithInput v-model="settingsObj.publishing.responses_number_limit" :default-value="20"
            @reset="resetFieldState('responsesNumberLimit')"
            :allowed-only-with-payment="!getCurrentSubscriptionFeatures?.limit_responses_number">
            Limit response number
            <template #input>
                <v-text-field v-model="settingsObj.publishing.responses_number_limit" type="number"
                    placeholder="Add Number" outlined :error-messages="responsesNumberLimitErrors"
                    hide-details="auto" />
            </template>
        </FormSettingsOptionWithInput>

        <FormSettingsOptionNavigator @navigate="openEmbedForm">Embed your form
        </FormSettingsOptionNavigator>

        <FormParametersSubheader>Access</FormParametersSubheader>
        <FormSettingsOptionTemplate :value="settingsObj.access.open_to_responses">
            <template #header>
                <FormParametersHeader v-model="settingsObj.access.open_to_responses">
                    Open to responses
                </FormParametersHeader>
            </template>
        </FormSettingsOptionTemplate>
        <FormSettingsOptionWithInput v-model="settingsObj.access.access_password"
            @reset="resetFieldState('accessPassword')">
            Require password
            <template #input>
                <v-text-field v-model="settingsObj.access.access_password" placeholder="Add Password" outlined
                    :error-messages="accessPasswordErrors" hide-details="auto" />
            </template>
        </FormSettingsOptionWithInput>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, url, minValue, maxValue, numeric, helpers } from '@vuelidate/validators'
import { computed } from '@vue/composition-api'
import Vue from 'vue'
import { mapGetters } from '@/xstore'
import moment from 'moment'

export default {
    name: "GeneralFormSettings",
    components: {
        FormParametersSubheader: () => import('../Elements/FormParametersSubheader'),
        FormSettingsOptionTemplate: () => import('./FormSettingsOptions/FormSettingsOptionTemplate'),
        FormSettingsOptionWithInput: () => import('./FormSettingsOptions/FormSettingsOptionWithInput'),
        FormSettingsOptionNavigator: () => import('./FormSettingsOptions/FormSettingsOptionNavigator'),
        CalendarIcon: () => import('vue-feather-icons/icons/CalendarIcon'),
        FormParametersHeader: () => import('../Elements/FormParametersHeader'),
    },
    props: {
        settingsObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDateTimePickerOpened: false,
        }
    },
    computed: {
        ...mapGetters('user/plan/current_subscription', [
            'getCurrentSubscriptionFeatures'
        ])
    },
    setup(props) {
        const passwordValidation = (v) => v.length >= 4;
        const rules = computed(() => ({
            ...(props.settingsObj.general_settings.redirect_upon_completion !== null && ({
                redirectUponCompletionURL: { required, url }
            })),
            ...(props.settingsObj.publishing.close_date !== null && ({
                closeDate: { required }
            })),
            ...(props.settingsObj.publishing.responses_number_limit !== null && ({
                responsesNumberLimit: {
                    required,
                    minValue: minValue(10),
                    maxValue: maxValue(50),
                    numeric,
                },
            })),
            ...(props.settingsObj.access.access_password !== null && ({
                accessPassword: {
                    required,
                    password_format: helpers
                        .withMessage('The password should contains at least four characters', passwordValidation)
                }
            }))
        }))
        const $v = useVuelidate(
            rules,
            {
                redirectUponCompletionURL: computed(() => {
                    const url = props.settingsObj.general_settings.redirect_upon_completion
                    if (url) {
                        if (url.startsWith('https://') || url.startsWith('http://')) {
                            return url
                        } else {
                            return 'https://' + url
                        }
                    } else {
                        return '';
                    }
                }),
                closeDate: computed(() => props.settingsObj.publishing.close_date),
                responsesNumberLimit: computed(() => props.settingsObj.publishing.responses_number_limit),
                accessPassword: computed(() => props.settingsObj.access.access_password),
            },
        )
        const redirectUponCompletionErrors = computed(() => $v.value?.redirectUponCompletionURL?.$errors?.map(({ $message }) => $message) || [])
        const closeDateErrors = computed(() => $v.value?.closeDate?.$errors?.map(({ $message }) => $message) || [])
        const responsesNumberLimitErrors = computed(() => $v.value?.responsesNumberLimit?.$errors?.map(({ $message }) => $message) || [])
        const accessPasswordErrors = computed(() => $v.value?.accessPassword?.$errors?.map(({ $message }) => $message) || [])

        const resetFieldState = (key) => Vue.nextTick(() => $v.value[key].$reset());

        const readableDate = computed(() => {
            return moment(props.settingsObj?.publishing?.close_date).format('YYYY[-]MM[-]DD HH[:]mm')
        })

        return {
            $v,
            redirectUponCompletionErrors,
            closeDateErrors,
            responsesNumberLimitErrors,
            accessPasswordErrors,
            resetFieldState,
            readableDate
        }
    },
    methods: {
        openEmailNotifications() {
            this.$emit('open-email-notifications')
        },
        openEmbedForm() {
            this.$emit('open-enbed-form')
        }

    }
}
</script>