const storeGetterName = 'getStore'

const generator = () => ({getters: {[storeGetterName]: () => require('@/store').default}})
generator.key = 'Storeable'

export const StoreableExtensions = Object.freeze({
    Default: {
        SBGenerator: generator
    }
})

export const StoreableUtils = Object.freeze({
    storeGetterName
})
