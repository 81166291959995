<template>
    <div class="address-field-view-full-single-answer">
        <span v-if="isAddressFieldEmpty">-</span>
        <div
                v-for="(value, key) in getActiveAddressFields"
                class="address-field-view-full-single-answer__item"
                :key="key"
                v-else
        >
            <span>{{ key[0].toUpperCase() + key.slice(1) }}: </span>
            <template v-if="key === 'country'">
                <span>{{ constants.COUNTRY_NAMES[value] }}</span>
                <f-country-flag size="l" :code="value"/>
            </template>
            <span v-else>{{ value }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FullSingleAnswer__Address",
        props: {
            submissionObj: Object
        },
        computed: {
            getActiveAddressFields() {
                return Object.fromEntries(Object.entries(this.submissionObj.value).filter(item => item[1]))
            },
            isAddressFieldEmpty() {
                return !Object.values(this.getActiveAddressFields).length;
            },
        }
    }
</script>