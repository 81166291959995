import SharedView from "@/components/Fields/FieldsViews/Matrix/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'Preview__Matrix',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    columns: ctx.props.fieldObj.content.columns,
                    rows: ctx.props.fieldObj.content.rows,
                    preview: true,
                    radio: !ctx.props.fieldObj.properties.multiple_selection,
                }
            }
        )
    }
}