<template>
    <span>{{submissionObj.value ? submissionObj.value.originalName : '-'}}</span>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__FileUpload",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
    }
</script>