import { Store, createStore } from './store'
import { storeKey, useStore } from './injectKey'
import { useMapGetters, mapGetters, useMapActions, mapActions, createNamespacedHelpers } from './helpers'

export default {
    version: '__VERSION__',
    Store,
    storeKey,
    createStore,
    useStore,
    useMapGetters,
    mapGetters,
    useMapActions,
    mapActions,
    createNamespacedHelpers,
}

export {
    Store,
    storeKey,
    createStore,
    useStore,
    useMapGetters,
    mapGetters,
    useMapActions,
    mapActions,
    createNamespacedHelpers,
}
