<template>
  <v-list dense>
    <v-list-item :disabled="!isSelected"
                 :href="selectedSubmissionObjUrl"
                 :download="selectedSubmissionTitle"
    >
      <v-list-item-content>
        <v-list-item-title>
          Export selected
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :href="allSubmissionsObjUrl"
                 download="All responses"
    >
      <v-list-item-content>
        <v-list-item-title>
          Export all responses
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>
    <v-list-item v-if="getCurrentAllResponsesView === getAllResponsesViews.SPLITTED"
                 @click="printSubmissions"
    >
      <v-list-item-content>
        <v-list-item-title>
          Print
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import {computed} from "@vue/composition-api";
import {mapGetters} from "@/xstore";

export default {
  name: 'ActionsList',
  props: {
    selectedSubmissionsForCsv: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    selectedSubmissionTitle: {
      type: String,
      required: true,
    },
    submissionsForCsv: {
      type: Array,
      required: true,
    },
  },
  setup(props, {root}) {
    const selectedSubmissionObjUrl = computed(() => {
      return root.Utils.createCsvFromArray(props.selectedSubmissionsForCsv)
    })
    const allSubmissionsObjUrl = computed(() => {
      return root.Utils.createCsvFromArray(props.submissionsForCsv)
    })

    const printSubmissions = () => window.print()

    return {
      selectedSubmissionObjUrl,
      allSubmissionsObjUrl,
      printSubmissions
    }
  },
  computed: {
    ...mapGetters('submissions', [
      'getCurrentAllResponsesView',
      'getAllResponsesViews'
    ])
  }
}
</script>

<style scoped lang="scss">

</style>