<template>
    <StaticFinishPageTemplate>
        <template #emoji>
            👍
        </template>
        <template #title>
            <f-simple-contenteditable-input
                    oveflow-wrap
                    prevent-highlighting
                    multiline
                    v-model="finishTitle"
                    placeholder="Title"
            />
        </template>
        <template #description>
            <f-simple-contenteditable-input
                oveflow-wrap
                prevent-highlighting
                multiline
                v-model="finishDescription"
                placeholder="Additional description"
            />
        </template>
    </StaticFinishPageTemplate>
</template>

<script>
    import {finish_page_edit_mixin} from "../../../../FormGenericComponents/Mixins/finish_page_mixin";

    export default {
        name: "StaticFinishPage",
        mixins: [finish_page_edit_mixin],
        components: {
            StaticFinishPageTemplate: () => import('@/components/FormGenericComponents/StaticForm/StaticFinishPageTemplate'),
        }
    }
</script>

<style scoped>

</style>