<template>
    <div class="d-flex align-center">
        <LogoComponent @click.native="goToDashboard" class="logo-link ml-6" />
        <f-badge
            gray
            style="margin-left: 5px; font-size: 0.6rem"
        >
            BETA
        </f-badge>
    </div>
</template>

<script>
    import constants from '@/constants/constants'

    export default {
        name: "AppBarLogoComponent",
        methods: {
          goToDashboard() {
            if (this.$route.name !== constants.PAGE_NAME_DASHBOARD) {
              this.$router.push({name: constants.PAGE_NAME_DASHBOARD})
            }
          }
        },
        components: {
            LogoComponent: () => import('@/components/Elements/LogoComponent')
        }
    }
</script>
<style scoped lang="scss">
  .logo-link {
    cursor: pointer;
  }
</style>