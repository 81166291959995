var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormPreview', {
    attrs: {
      "external-disabled": _vm.externalOpenFormDisabled
    },
    on: {
      "external-open": _vm.openInNewTab,
      "device-change": _vm.onDeviceChange,
      "close": _vm.closeFormSubmitPreview
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', {
          staticClass: "mx-3 v-btn--transparent-with-icon v-btn--text-hoverable",
          attrs: {
            "text": "",
            "disabled": _vm.getIsFormFetching,
            "rounded": "",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              _vm.openFormSubmitPreview(function () {
                return on.click($event);
              });
            }
          }
        }, [_c('v-icon', {
          staticClass: "v-btn__prepend-icon",
          attrs: {
            "size": "18"
          }
        }, [_vm._v("mdi-eye")]), _vm._v(" Preview ")], 1)];
      }
    }, {
      key: "default",
      fn: function (_ref2) {
        var active = _ref2.active;
        return [!_vm.getIsFormFetching && _vm.getFormDBData && active ? _c('FormSubmit', {
          attrs: {
            "form-id": _vm.getFormDBData.id,
            "embedded": "",
            "store-module-path": "edit/submit_preview"
          }
        }) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }