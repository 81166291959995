import * as components from './components'
import * as directives from './directives'
import { kebabCase } from 'lodash'
import { isRef } from '@vue/composition-api'

export default class FormslyUIKit {
    static components = Object.fromEntries(
        Object.entries(components)
            .map(([name, component]) => {
                name = kebabCase('F' + name)
                return [name, {...component, name}]
            })
    )
    static directives = directives

    constructor() {

    }
}

function install(vue, args = {}) {
    if (install.installed) return
    install.installed = true

    const components = {...FormslyUIKit.components, ...args.components} || {}
    const directives = {...FormslyUIKit.directives, ...args.directives} || {}

    for (const name in directives) {
        const directive = directives[name]
        vue.directive(name, directive)
    }

    (function registerComponents(components) {
        if (components) {
            for (const key in components) {
                const component = components[key]

                if (component) {
                    vue.component(key, component)
                }
            }

            return true
        }

        return false
    })(components)


    if (vue.$_formsly_ui_kit_installed) return
    vue.$_formsly_ui_kit_installed = true


    vue.mixin({
        methods: {
            promiseWithVariableToggler(variable, promise) {
                return new Promise((resolve) => {
                    const toggleVariable = variable => {
                        if (isRef(variable))
                            variable.value = !variable.value
                        else
                            this[variable] = !this[variable]
                    }
                    toggleVariable(variable)
                    promise.finally(() => {
                        toggleVariable(variable)
                        this.$nextTick(resolve)
                    })
                })

            }
        }
    })
}

FormslyUIKit.install = install