<template>
    <EditableFieldPropertiesWindowTemplate @close-properties="closeProperties">
        <f-select
                class="editable-field-properties__change-field-type-select rounded-lg mb-6"
                attach=".editable-field-properties__change-field-type-select"
                :value="fieldObj.field_type"
                @input="changeFieldType"
                :menu-props="{
                    nudgeBottom:42
                }"
                item-color="blue_100"
                :items="constants.ALL_FIELD_TYPES"
                :item-value="item => item.FIELD_TYPE"
                outlined
                hide-details
                dense
        >
            <template v-slot:selection="{item}">
                <component
                    :is="item.ICON_COMPONENT"
                    width="26"
                    height="26"
                />
                <span class="ml-3 black--text">{{item.READABLE_NAME}}</span>
            </template>
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" @click="processFieldTypeChanging(on.click)">
                    <v-list-item-icon>
                        <component
                            :is="item.ICON_COMPONENT"
                            width="26"
                            height="26"
                        />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.READABLE_NAME }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </f-select>
        <EditableFieldPropertiesListGenerator
                :field-id="fieldId"
                :field-properties-obj="fieldObj.properties"
                :field-base-properties="constants.FIELD_FROM_TYPE[fieldObj.field_type].BASE_PROPERTIES"
                :key="fieldObj.field_type"
        />
    </EditableFieldPropertiesWindowTemplate>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "EditableFieldPropertiesDefaultWindow",
        components: {
            EditableFieldPropertiesWindowTemplate: () => import('./EditableFieldPropertiesWindowTemplate'),
            EditableFieldPropertiesListGenerator: () => import('./EditableFieldPropertiesListGenerator')
        },
        props: {
            fieldObj: Object,
            fieldId: String
        },
        computed: {
            ...mapGetters('edit/conditional', ['getTargetedAndConditionedFields']),
            visibilityControlledByConditionalLogic() {
                return this.getTargetedAndConditionedFields[this.fieldId]?.targeted
            },
        },
        methods: {
            processFieldTypeChanging(cb) {
                if (this.getTargetedAndConditionedFields[this.fieldId].conditioned)
                    this.$store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                        header: 'Change Field Visibility',
                        text: 'This block has a conditional logic, by changing its type it will remove the conditional logic applied to it.',
                        confirmBtnText: `Yes, Change field type`,
                        callback: cb
                    })
                else
                    cb()
            },
            showConditionOwningThisField() {
                this.$store.dispatch('edit/conditional/openConditionalLogicEditor', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
            },
            confirmVisibilityChange(visibilityValue) {
                this.$store.dispatch('edit/conditional/removeConditionForField', this.getTargetedAndConditionedFields[this.fieldId].target_owner)
                this.fieldObj.properties.hide = visibilityValue
            },
            closeProperties() {
                this.$emit('close-properties')
            },
            changeFieldType(fieldType) {
                this.$emit('change-field-type', fieldType)
            }
        }
    }
</script>