import {mapGetters, useMapGetters} from '@/xstore';
import {debounce} from 'lodash'

const finish_page_edit_mixin = {
    setup() {
        return {
            ...useMapGetters('edit', [
                'getFormSourceJSON'
            ])
        }
    },
    computed: {
        ...mapGetters('edit', ['getFormJSON']),
        finishTitle: {
            get: function () {
                return this.getFormJSON?.all_pages?.finish_page.title || this.getFormSourceJSON?.all_pages?.finish_page.title
            },
            set: debounce(function (v) {
                this.$store.dispatch('edit/changeFormAttrs', {
                    all_pages: {
                        ...this.getFormJSON.all_pages,
                        finish_page: {...this.getFormJSON.all_pages.finish_page, title: v}
                    }
                })
            }, 1000)
        },
        finishDescription: {
            get: function () {
                return this.getFormJSON?.all_pages?.finish_page.description !== undefined ? this.getFormJSON?.all_pages?.finish_page.description : this.getFormSourceJSON?.all_pages?.finish_page.description
            },
            set: debounce(function (v) {
                this.$store.dispatch('edit/changeFormAttrs', {
                    all_pages: {
                        ...this.getFormJSON.all_pages,
                        finish_page: {...this.getFormJSON.all_pages.finish_page, description: v}
                    }
                })
            }, 1000)
        }
    },
}

const finish_page_submit_mixin = {
    setup() {
        return {
            ...useMapGetters('submit', [
                'getFormSourceJSON'
            ])
        }
    },
    computed: {
        ...mapGetters('edit', ['getFormJSON']),
        finishTitle: {
            get: function () {
                return this.getFormJSON?.all_pages?.finish_page.title || this.getFormSourceJSON?.all_pages?.finish_page.title
            },
        },
        finishDescription: {
            get: function () {
                return this.getFormJSON?.all_pages?.finish_page.description !== undefined ? this.getFormJSON?.all_pages?.finish_page.description : this.getFormSourceJSON?.all_pages?.finish_page.description
            },
        }
    },
    methods: {
        redirect() {
            window.location.href = window.location.origin;
        }
    },
}

export {finish_page_edit_mixin, finish_page_submit_mixin}