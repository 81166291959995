import constants from '@/constants/constants'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { getAverageColorFromImageURL } from '@/utils/misc'

export default ({useExtension}) => {
    {
        const state = {
            background_image_preview: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        actions: {
            setNewBackgroundImage: ({commit, dispatch}, newImage) => {
                dispatch('edit/FSP_themes/editing/updateCurrentEditingThemeStyle', {[constants.FSP.PRIMARY_VARIABLES.BACKGROUND_IMAGE.JSON_NAME]: newImage}, {root: true})
                    .then(() => {
                        if (newImage)
                            return getAverageColorFromImageURL(newImage)
                        return '#FFFFFF'
                    })
                    .then(v => dispatch('edit/FSP_themes/editing/updateCurrentEditingThemeStyle', {[constants.FSP.PRIMARY_VARIABLES.BACKGROUND_COLOR.JSON_NAME]: v}, {root: true}))
                commit('SET_BACKGROUND_IMAGE_PREVIEW', undefined)
            }
        },
        getters: {
            getThemeBackgroundImage: ({parentGetters}) => parentGetters['FSP/getStylePreferences'][constants.FSP.PRIMARY_VARIABLES.BACKGROUND_IMAGE.JSON_NAME],
            getCurrentBackgroundImage: ({getters}) => getters.getBackgroundImagePreview || getters.getThemeBackgroundImage
        }
    }
}