import constants from '@/constants/constants'

export const FieldsEnumerationGettersUtils = Object.freeze({
    enumeratedFieldsListGetterName: 'getEnumeratedFieldsList',
    allFieldsWithEnumerationGetterName: 'getAllFieldsWithEnumeration'
})

export const useFieldsEnumerationGettersExtension = ({
    isFormStaticGetterName,
    isFormInteractiveGetterName,
    formJSONGetterName,
    currentPageIdGetterName
}) => Object.freeze({
    SBGenerator: (() => {
        const generator = () => ({
            getters: {
                [FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName]: ({getters}) => {
                    let fieldsList = []
                    if (getters[isFormStaticGetterName])
                        getters[formJSONGetterName].main_pages.forEach(({page_id}, pageIndex) => {
                            getters[formJSONGetterName].all_pages[page_id].rows.forEach(({fields}) => {
                                fields.forEach(({field_id}) => {
                                    fieldsList.push({
                                        fieldId: field_id,
                                        fieldObj: getters[formJSONGetterName].fields[field_id],
                                        fieldConst: constants.FIELD_FROM_TYPE[getters[formJSONGetterName].fields[field_id].field_type],
                                        pageIndex: pageIndex,
                                        questionIndex: fieldsList.length,
                                        formattedLocation: `Question ${fieldsList.length + 1}${page_id === getters?.[currentPageIdGetterName] ? '' : ` (Page ${pageIndex + 1})`}`
                                    })
                                })
                            })
                        })
                    else if (getters[isFormInteractiveGetterName])
                        getters[formJSONGetterName].main_pages.forEach(({page_id}, pageIndex) => {
                            let pageObj = getters[formJSONGetterName].all_pages[page_id]

                            fieldsList.push({
                                fieldId: pageObj.field_id,
                                fieldObj: getters[formJSONGetterName].fields[pageObj.field_id],
                                fieldConst: constants.FIELD_FROM_TYPE[getters[formJSONGetterName].fields[pageObj.field_id].field_type],
                                pageIndex: pageIndex,
                                questionIndex: fieldsList.length,
                                formattedLocation: `Question ${fieldsList.length + 1}`
                            })
                        })

                    return fieldsList
                },
                [FieldsEnumerationGettersUtils.allFieldsWithEnumerationGetterName]: ({getters}) => Object.fromEntries(
                    getters[FieldsEnumerationGettersUtils.enumeratedFieldsListGetterName].map(field => [field.fieldId, field])
                )
            }
        })

        generator.key = 'FieldsEnumerationGettersExtension'

        return generator
    })()

})