<template>
    <div>
        <f-circle-loader v-if="getPaymentMethodIsLoading && !editingActive" size="4"/>
        <template v-else>
            <div v-if="getPaymentMethodIsExist && !editingActive">
                <div class="mb-6">
                    <CheckoutCustomerDetailsSetupInfoGroup
                            name="Credit card"
                            :value="`${upperFirst(getPaymentMethod?.card?.brand)} ending in ${getPaymentMethod?.card?.last4}`"
                    />
                </div>

                <v-btn
                        color="gray_100"
                        rounded
                        @click="editPaymentMethod"
                >Edit billing details
                </v-btn>
            </div>
            <component
                    v-else
                    :is="$options.PaymentMethodCardForm.form"
                    v-slot="{ attrs, submit, loading, reset }"
            >
                <v-row>
                    <v-col>
                        <component :is="$options.PaymentMethodCardForm.inputs.name" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <component :is="$options.PaymentMethodCardForm.inputs.card" v-bind="attrs"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <f-template-data-transfer>
                        <v-btn
                                class="mr-4"
                                color="blue_400"
                                rounded
                                :loading="loading"
                                @click="submit(paymentMethod => $store.dispatch('user/plan/checkout/customer_details/updatePaymentMethod', paymentMethod).then(cancelEditing))"
                        >Save
                        </v-btn>
                        </f-template-data-transfer>
                        <v-btn
                                color="gray_100"
                                rounded
                                :disabled="loading"
                                @click="() => {
                                    reset()
                                    cancelEditing()
                                }"
                        >Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </component>
        </template>
    </div>
</template>

<script>
    import PaymentMethodCardForm from '@/components/Settings/PlansAndBilling/PaymentMethodCard/PaymentMethodCardForm'
    import { mapGetters } from '@/xstore'
    import { upperFirst } from 'lodash'

    export default {
        name: "CheckoutCardSetup",
        PaymentMethodCardForm,
        components: {
            CheckoutCustomerDetailsSetupInfoGroup: () => import('./Elements/CheckoutCustomerDetailsSetupInfoGroup')
        },
        data: () => ({
            editingActive: false
        }),
        computed: {
            ...mapGetters('user/plan/checkout/customer_details', [
                'getPaymentMethodIsExist',
                'getPaymentMethod',
                'getPaymentMethodIsLoading'
            ])
        },
        methods: {
            upperFirst,
            editPaymentMethod() {
                this.editingActive = true
            },
            cancelEditing() {
                this.editingActive = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>