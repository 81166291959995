var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-toolbar-items', [_c('v-btn', {
    staticClass: "navigation-btn",
    attrs: {
      "active-class": "navigation-btn--active",
      "exact": "",
      "text": "",
      "to": {
        name: _vm.constants.PAGE_NAME_FORM_EDITOR,
        params: {
          formId: _vm.$route.params.formId
        }
      }
    }
  }, [_c('EditIcon', {
    attrs: {
      "stroke-width": "1.5"
    }
  }), _c('span', [_vm._v("Editor")])], 1), _c('v-btn', {
    staticClass: "navigation-btn",
    attrs: {
      "active-class": "navigation-btn--active",
      "exact": "",
      "text": "",
      "to": {
        name: _vm.constants.PAGE_NAME_SUBMISSIONS_ANALYTICS,
        params: {
          formId: _vm.$route.params.formId
        }
      }
    }
  }, [_c('ActivityIcon', {
    attrs: {
      "stroke-width": "1.5"
    }
  }), _c('span', [_vm._v("Responses")]), _vm.getTotalResponsesMetric ? _c('f-badge', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "white": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.getTotalResponsesMetric) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }