<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.96951 3.70312H6.12451V13.3091C6.12451 16.4666 8.32951 18.7436 12 18.7436C15.6705 18.7436 17.8755 16.4666 17.8755 13.3091V3.70312H16.0305V13.1876C16.0305 15.4226 14.5635 17.0426 12 17.0426C9.43651 17.0426 7.96951 15.4226 7.96951 13.1876V3.70312Z"
              fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 21.5H5.25V20H18.75V21.5Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {}
</script>

<style scoped>

</style>