<template>
    <div style="display: contents">
        <slot :attrs="attrs" :submit="validate" :reset="reset" :loading="loading"/>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import stripe from '@/utils/stripe'
    import { watch, computed, ref, set, shallowReactive } from '@vue/composition-api'

    export default {
        name: "PaymentMethodCardForm",
        setup(props, {root}) {
            const paymentMethod = shallowReactive({
                cardElement: (() => {
                    const elements = stripe.elements({
                        fonts: [
                            {cssSrc: 'https://fonts.googleapis.com/css2?family=Inter'}
                        ],
                        locale: 'en'
                    })

                    return elements.create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                color: root.$vuetify.theme.themes.light.gray_900,
                                fontWeight: 400,
                                fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
                                fontSize: '16px',
                                fontSmoothing: 'antialiased',

                                ':focus': {
                                    color: root.$vuetify.theme.themes.light.gray_900,
                                },

                                '::placeholder': {
                                    color: root.$vuetify.theme.themes.light.gray_500_2,
                                },

                                ':focus::placeholder': {
                                    color: root.$vuetify.theme.themes.light.gray_500_2,
                                },
                            },
                            invalid: {
                                color: root.$vuetify.theme.themes.light.red_400,
                                ':focus': {
                                    color: root.$vuetify.theme.themes.light.red_400,
                                },
                                '::placeholder': {
                                    color: root.$vuetify.theme.themes.light.red_300,
                                },
                            },
                        },
                        classes: {
                            base: 'stripe-card-element',
                            focus: 'stripe-card-element--focus',
                            invalid: 'stripe-card-element--error'
                        }
                    })
                })()
            })

            const cardErrorMessage = ref(undefined)
            paymentMethod.cardElement.on('change', e => {
                if (e.error)
                    cardErrorMessage.value = e.error.message
                else
                    cardErrorMessage.value = undefined
            })


            const genPaymentMethodState = () => {
                Object.entries({
                    name: undefined,
                }).map(([key, value]) => set(paymentMethod, key, value))

                paymentMethod.cardElement.clear()
            }

            genPaymentMethodState()

            const loading = ref(false)


            watch(
                loading,
                v => {
                    paymentMethod.cardElement.update({disabled: v})
                }
            )

            const $v = useVuelidate(
                computed(() => ({
                    name: {required},
                })),
                paymentMethod,
                {$rewardEarly: true}
            )

            const attrs = {
                bind: {
                    name: computed(() => ({
                        props: {
                            value: paymentMethod.name,
                            dense: true,
                            outlined: true,
                            hideDetails: 'auto',
                            disabled: loading.value,
                            errorMessages: $v.value.name.$errors.map(({$message}) => $message)
                        },
                        on: {
                            input: v => paymentMethod.name = v
                        }
                    })),
                    cardElement: computed(() => ({
                        cardElement: paymentMethod.cardElement,
                        errorMessages: cardErrorMessage.value
                    }))
                }
            }

            const validate = fn => {
                loading.value = true

                return (async () => {
                    let validationResult = await $v.value.$validate()


                    if (paymentMethod.cardElement._empty || (!paymentMethod.cardElement._complete && !cardErrorMessage.value)) {
                        cardErrorMessage.value = 'Value is required'
                        validationResult = false
                    }

                    if (validationResult)
                        // console.log(true, fn)
                        return fn(paymentMethod)
                })()
                    .then(() => loading.value = false)
            }

            const reset = genPaymentMethodState

            return {
                $v,
                attrs,
                loading,
                validate,
                reset,
            }
        }
    }
</script>

<style lang="scss">
    .stripe-card-element {
        padding: 12px;

        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--v-gray_300-base);

        &--focus:not(&--error) {
            border-color: var(--v-blue_400-base);
        }

        &--error {
            border-color: var(--v-error-base);
        }
    }
</style>