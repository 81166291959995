<script>
  import { computed, onBeforeUnmount, ref } from '@vue/composition-api'

  export default {
    name: 'FormCreationAiPrompt',
    components: {
      GenericFormCreationLayout: () =>
        import('@/components/FormCreation/GenericFormCreationLayout.vue'),
    },
    setup() {
      const placeholders = [
        'Online Event Registration Form',
        'Customer Feedback Form ',
        'A job application form for a multinational company',
        'Product Feedback Survey',
        'Customer Satisfaction Survey',
      ]
      const currentPlaceholderIndex = ref(0)
      const currentPlaceholder = computed(
        () => placeholders[currentPlaceholderIndex.value]
      )
      const placholderSliderInterval = setInterval(() => {
        currentPlaceholderIndex.value =
          (currentPlaceholderIndex.value + 1) % placeholders.length
      }, 2500)
      onBeforeUnmount(() => {
        clearInterval(placholderSliderInterval)
      })

      const promptInput = ref('')
      const minPromptLength = 1
      const maxPromptLength = 120

      const isPromptValid = computed(() => {
        const input = promptInput.value

        return (
          input &&
          minPromptLength <= input.length &&
          input.length <= maxPromptLength
        )
      })

      const isLoading = ref(false)

      return {
        currentPlaceholder,
        promptInput,

        minPromptLength,
        maxPromptLength,

        isPromptValid,

        isLoading,
      }
    },
    methods: {
      afterFormCreation(formID) {
        this.SequenceDirector.createBlankSequence(
          this.constants.NEW_FORM_TUTORIAL,
          2
        )
        this.$nextTick(() =>
          this.$router.push({
            name: this.constants.PAGE_NAME_FORM_EDITOR,
            params: {
              formId: formID,
            },
          })
        )
      },
      createSurvey() {
        this.isLoading = true

        this.$store
          .dispatch('form_templates/createAiForm', {
            isStatic: this.$route.params.form_type === 'static',
            formPrompt: this.promptInput,
          })
          .then(({ formID }) => {
            this.afterFormCreation(formID)
          })
          .catch(e => {
            const errorMessage = (() => {
              if (!e?.response?.data) {
                return
              }

              try {
                const response = JSON.parse(e.response.data)
                if (response.error && response.message) {
                  return response.message
                }
              } catch {
                //
              }

              return 'Unhandled error'
            })()

            this.snackbarEventBus.$emit('snackbar', {
              emoji: this.constants.EMOJI.CRYING_FACE,
              color: this.constants.SNACKBAR_CONSTANTS.COLORS.ERROR,
              text: errorMessage,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>

<template>
  <GenericFormCreationLayout
    title="Tell Formsly AI your form or survey needs -<br>and it'll make it happen!"
    caption-above-the-title="Step 3 of 3"
  >
    <div class="create-form-wrapper">
      <v-text-field
        v-model="promptInput"
        :placeholder="currentPlaceholder"
        outlined
        class="create-form-wrapper__input"
        hint="Describe in a clear and simple language for high quality results"
        persistent-hint
        :loading="isLoading"
        :disabled="isLoading"
        :maxlength="maxPromptLength"
        @keydown.enter="createSurvey"
      >
        <template #prepend-inner>
          <v-icon
            class="create-form__input-icon"
            :class="isPromptValid && 'create-form__input-icon--active'"
          >
            $stars
          </v-icon>
        </template>
        <template #append>
          <v-btn
            class="create-form-wrapper__button white--text"
            :class="isPromptValid && 'create-form-wrapper__button--active'"
            text-color="white"
            color="#D3D6DC"
            :style="{ ...(isLoading && { opacity: 0.7 }) }"
            @click="createSurvey"
          >
            Create
          </v-btn>
        </template>
      </v-text-field>
      <span
        v-if="promptInput.length <= maxPromptLength"
        class="create-form-wrapper__counter"
      >
        {{ promptInput.length }}/{{ maxPromptLength }}
      </span>
      <v-tooltip
        v-else
        bottom
      >
        <template #activator="{ on }">
          <span
            class="create-form-wrapper__error"
            style="cursor: pointer"
            v-on="on"
          >
            Max characters {{ promptInput.length }}/{{ maxPromptLength }}
          </span>
        </template>
        <div class="create-form-wrapper__tooltip">
          For now we are allowing up to 120 characters only. Clear and simple
          descriptions lead to great results!
        </div>
      </v-tooltip>
    </div>
  </GenericFormCreationLayout>
</template>

<style lang="scss" scoped>
  .create-form-wrapper {
    position: relative;
    margin-top: 60px;

    &__step-count {
      margin-bottom: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #9ca2ae;
    }

    &__input {
      display: flex;
      position: relative;
      align-items: center;
      width: 820px;
      border-radius: 49px;
      padding: 10px 12px 10px 24px;
      font-size: 20px;
      gap: 28px;
      margin-bottom: 30px;

      ::v-deep .v-input__slot {
        margin-bottom: 12px;
        height: 60px;
      }
    }

    &__counter {
      position: absolute;
      top: 67px;
      right: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #b5b8be;
    }

    &__error {
      color: #fc4a60;
      position: absolute;
      top: 67px;
      right: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__tooltip {
      width: 300px;
      white-space: break-spaces;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }

    &__title {
      font-weight: 400;
      font-size: 38px;
      line-height: 120%;
      letter-spacing: -1px;
      text-align: center;
      margin-bottom: 60px;
    }

    img {
      margin-right: 10px;
      margin-left: 12px;
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      gap: 10px;
      width: 83px;
      height: 40px;
      border-radius: 100px;
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          85.64deg,
          #2fbbeb 23.1%,
          #6171ff 79.01%,
          #3d66f6 95.17%
        );
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
      }

      &:not(&--active) {
        pointer-events: none;
      }

      &--active {
        &:hover:before {
          opacity: 1;
        }
        &:before {
          opacity: 1;
        }
      }
    }

    .v-input__icon--prepend-inner .v-icon {
      color: #5d6471;
      font-size: 24px;
    }
  }
</style>
