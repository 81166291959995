var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-composition"
  }, [_c('div', {
    class: ['form-composition-btn', _vm.elementsPanelMenuModel && 'form-composition-btn--active', 'rounded-pill', 'white', 'mb-5'],
    attrs: {
      "id": "static_form_elements_panel_button"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "nudge-right": "60",
      "attach": "#static_form_elements_panel_button",
      "allow-overflow": "",
      "color": "bluish_gray_500",
      "content-class": "tooltip-right",
      "right": ""
    },
    model: {
      value: _vm.elementsPanelTooltip,
      callback: function ($$v) {
        _vm.elementsPanelTooltip = $$v;
      },
      expression: "elementsPanelTooltip"
    }
  }, [_vm._v(" Add questions ")]), _c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "Add questions"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "gray_3",
            "depressed": "",
            "text": "",
            "icon": "",
            "x-large": ""
          },
          on: {
            "click": _vm.elementsPanelOpen
          }
        }, _vm.elementsPanelTooltip ? undefined : on), [_c('svg', {
          attrs: {
            "width": "20",
            "height": "20",
            "viewBox": "0 0 20 20",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            "d": "M10.8002 1.6667C10.8002 1.22487 10.442 0.866699 10.0002 0.866699C9.55836 0.866699 9.20019 1.22487 9.20019 1.6667V9.20002H1.6667C1.22487 9.20002 0.866699 9.55819 0.866699 10C0.866699 10.4418 1.22487 10.8 1.6667 10.8H9.20019V18.3334C9.20019 18.7752 9.55836 19.1334 10.0002 19.1334C10.442 19.1334 10.8002 18.7752 10.8002 18.3334V10.8H18.3334C18.7752 10.8 19.1334 10.4418 19.1334 10C19.1334 9.55819 18.7752 9.20002 18.3334 9.20002H10.8002V1.6667Z",
            "fill": "currentColor"
          }
        })])])];
      }
    }])
  })], 1), _c('div', {
    class: ['form-composition-btn', _vm.pagesMenuModel && 'form-composition-btn--active', 'rounded-pill', 'white']
  }, [_c('f-hover-tooltip', {
    attrs: {
      "right": "",
      "text": "All pages"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "gray_3",
            "depressed": "",
            "text": "",
            "icon": "",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              _vm.pagesMenuModel = true;
            }
          }
        }, on), [_c('svg', {
          attrs: {
            "width": "24",
            "height": "24",
            "viewBox": "0 0 24 24",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z",
            "stroke": "currentColor",
            "stroke-width": "1.6",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }), _c('path', {
          attrs: {
            "d": "M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z",
            "stroke": "currentColor",
            "stroke-width": "1.6",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })])])];
      }
    }])
  })], 1), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "close-on-click": true,
      "attach": ".form-composition",
      "content-class": "form-composition-menu",
      "eager": "",
      "min-width": "340",
      "nudge-right": "80",
      "right": "",
      "transition": "slide-y-transition"
    },
    model: {
      value: _vm.elementsPanelMenuModel,
      callback: function ($$v) {
        _vm.elementsPanelMenuModel = $$v;
      },
      expression: "elementsPanelMenuModel"
    }
  }, [_c('StaticFormElementsPanel')], 1), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "close-on-click": true,
      "attach": ".form-composition",
      "content-class": "form-composition-menu",
      "eager": "",
      "min-width": "250",
      "nudge-right": "80",
      "right": "",
      "transition": "slide-y-transition"
    },
    model: {
      value: _vm.pagesMenuModel,
      callback: function ($$v) {
        _vm.pagesMenuModel = $$v;
      },
      expression: "pagesMenuModel"
    }
  }, [_c('StaticFormPagesList')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }