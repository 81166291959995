<template>
  <SharedView
      :address-fields="sourceObj.properties.full_address_fields"
      :placeholders="fieldConst.UTIL.placeholders"
      :submission-obj="submissionObj"
      :gen-ref="genRef"
      v-on="inputAutofocusOn"
      ref="inputs"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
import {
  use_submittable_field_input_autofocus_and_press_enter_mixin
} from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_input_autofocus_and_press_enter_mixin'

export default {
  name: 'Submittable__Address',
  components: {
    SharedView: () => import('./SharedView'),
  },
  mixins: [submittable_field_view_mixin, use_submittable_field_input_autofocus_and_press_enter_mixin('inputs', 'firstInput')],
  computed: {
    firstInputName() {
      return (this.sourceObj.properties.full_address_fields.street && 'street')
          || (this.sourceObj.properties.full_address_fields.zip && 'zip')
          || (this.sourceObj.properties.full_address_fields.city && 'city')
          || (this.sourceObj.properties.full_address_fields.postal_code && 'postal_code')
          || (this.sourceObj.properties.full_address_fields.country && 'country')
    },
  },
  methods: {
    genRef(key) {
      if (this.firstInputName === key)
        return 'firstInput'
      return use_submittable_field_input_autofocus_and_press_enter_mixin()
    }
  }
}
</script>