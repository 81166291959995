<template>
    <div class="f-country-flag">
        <Flag class="f-country-flag__flag" v-bind="bind"/>
    </div>
</template>

<script>
    import {install} from 'vue-flagpack'

    const VueSimulator = {
        response: undefined,

        component(name, comp) {
            this.response = comp
        }
    }

    install(VueSimulator, {})

    const Flag = VueSimulator.response

    export default {
        name: "CountryFlag",
        inheritAttrs: false,
        props: {
            ...Flag.props
        },
        components: {
            Flag
        },
        computed: {
            bind() {
                return {
                    ...this.$attrs,
                    ...this.$props
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .f-country-flag {
        display: flex;
        align-items: center;
        justify-content: center;

        &__flag {
            max-width: 100%;
            flex-grow: 1;
        }
    }
</style>