<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="submissions.length"
                    :submissions-count="totalResponse"
            />
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="slider-field-view-summary">
                <span class="slider-field-view-summary__label">Average Answer:</span>
                <span class="slider-field-view-summary__average-value">{{averageAnswer}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__Slider",
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        computed: {
            averageAnswer() {
                return Math.round(this.submissions.reduce((sum, {submissionObj: {value}}) => sum + value, 0) / this.submissions.length)
            },

        },
    }
</script>