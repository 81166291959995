<template>
    <div class="style-preferences-background-image">
        <div class="style-preferences-background-image__preview-wrapper">
            <v-img
                    class="style-preferences-background-image__preview"
                    :src="getCurrentBackgroundImage"
                    :aspect-ratio="1920/1080"
            >
                <ImagePicker
                        :initial-image="getThemeBackgroundImage"
                        @preview-image="setBackgroundImagePreview"
                        @cancel="setBackgroundImagePreview(undefined)"
                        @select-image="setNewBackgroundImage"
                        key="cover"
                >
                    <template #activator="{on}">
                        <v-btn
                                class="style-preferences-background-image__edit-image-btn v-btn--primary"
                                icon small
                                v-on="on"
                        >
                            <Edit2Icon size="14"/>
                        </v-btn>
                    </template>
                    <template #title>
                        Edit background
                    </template>
                </ImagePicker>
            </v-img>
        </div>
        <v-btn v-if="false"> <!-- #TODO templates -->
            <RotateCwIcon/>
            Reset to original
        </v-btn>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from '@/xstore'

    export default {
        name: "StylePreferencesBackgroundImagePicker",
        components: {
            ImagePicker: () => import('@/components/Elements/ImagePicker/ImagePicker'),
            Edit2Icon: () => import('vue-feather-icons/icons/Edit2Icon'),
            RotateCwIcon: () => import('vue-feather-icons/icons/RotateCwIcon')
        },
        computed: {
            ...mapGetters('edit/background_image_preview', [
                'getCurrentBackgroundImage',
                'getThemeBackgroundImage'
            ])
        },
        methods: {
            ...mapActions('edit/background_image_preview', {
                setBackgroundImagePreview: 'setBackgroundImagePreview',
                setNewBackgroundImage: 'setNewBackgroundImage'
            })
        },
    }
</script>

<style lang="scss" scoped>
    .style-preferences-background-image {
        margin-top: 11px;

        &__preview-wrapper {
            border: 1px var(--v-gray_1-base) solid;
            padding: 7px;

            &, & > * {
                border-radius: 8px;
            }
        }

        &__preview {
            position: relative;
        }

        &__edit-image-btn {
            position: absolute;
            top: 8px;
            right: 11px;

            background-color: white;
        }
    }
</style>