<template>
    <div class="static-main-page">
        <div class="static-main-page__content">
            <StaticMainPageFieldsLayout/>
        </div>
        <StaticMainPageSubmitButtonComponent @validate="validatePage"/>
    </div>
</template>

<script>
    import {inject} from '@vue/composition-api'
    import {useMapActions, useMapGetters} from '@/xstore'

    export default {
        name: "StaticMainPage",
        components: {
            StaticMainPageFieldsLayout: () => import('./StaticMainPageFieldsLayout'),
            StaticMainPageSubmitButtonComponent: () => import('./StaticMainPageSubmitButtonComponent')
        },
        setup(props, {root}) {
            const $v = inject('fieldsValidation')
            const actions = useMapActions(inject('formSubmitStoreModulePath'), [
                'validateCurrentMainPage',
            ])
            const getters = useMapGetters(inject('formSubmitStoreModulePath'), [
                'getFieldsGroupedByPages',
                'getCurrentPageID'
            ])

            function validatePage(callback) {
                actions.validateCurrentMainPage()
                    .then(validationResult => {
                        if (validationResult) {
                            callback()
                        } else {
                            for (const fieldID of getters.getFieldsGroupedByPages.value[getters.getCurrentPageID.value])
                                if ($v?.value?.[fieldID]?.$error) {
                                    const fieldEl = document.querySelector(`.static-form.static-form--submittable`)?.getElementsByClassName(`generic-field--${fieldID}`)?.[0]

                                    if (fieldEl)
                                        root.$vuetify.goTo(fieldEl, {
                                            duration: 300,
                                            offset: 250,
                                            container: document.querySelector(`.v-application`)
                                        })
                                }
                        }
                    })
            }

            return {
                validatePage
            }
        },
    }
</script>