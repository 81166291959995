var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "opinion-scale-field-view"
  }, [_c('div', {
    staticClass: "opinion-scale-field-view__wrapper"
  }, [_c('div', {
    staticClass: "opinion-scale-field-view__range"
  }, _vm._l(_vm.opinion_scale_range, function (item, index) {
    return _c('div', {
      key: index,
      class: ['opinion-scale-field-view__tile-wrapper', !_vm.editable && item.selected && 'opinion-scale-field-view__tile-wrapper--selected', !_vm.editable && item.active && 'opinion-scale-field-view__tile-wrapper--active']
    }, [_c('div', {
      staticClass: "opinion-scale-field-view__tile",
      on: {
        "click": function ($event) {
          return _vm.$emit('select', index);
        }
      }
    }, [_vm._v(_vm._s(item.number) + " ")])]);
  }), 0), _c('div', {
    staticClass: "opinion-scale-field-view__labels opinion-scale-field-view__labels--desktop"
  }, [_vm.editable || !_vm.editable && _vm.labels.start ? _c('f-simple-contenteditable-input', {
    staticClass: "opinion-scale-field-view__label opinion-scale-field-view__label--from",
    attrs: {
      "mask": _vm.regexp_mask,
      "placeholder": "Label",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.labels.start,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "start", $$v);
      },
      expression: "labels.start"
    }
  }) : _vm._e(), _vm.editable || !_vm.editable && _vm.labels.end ? _c('f-simple-contenteditable-input', {
    staticClass: "opinion-scale-field-view__label opinion-scale-field-view__label--end",
    attrs: {
      "mask": _vm.regexp_mask,
      "placeholder": "Label",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.labels.end,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "end", $$v);
      },
      expression: "labels.end"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "opinion-scale-field-view__labels opinion-scale-field-view__labels--mobile"
  }, [_vm.editable || !_vm.editable && _vm.labels.start ? _c('div', {
    staticClass: "opinion-scale-field-view__label opinion-scale-field-view__label--from"
  }, [_c('f-simple-contenteditable-input', {
    attrs: {
      "mask": _vm.regexp_mask,
      "placeholder": "Label",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.labels.start,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "start", $$v);
      },
      expression: "labels.start"
    }
  }), _c('span', [_vm._v("→ " + _vm._s(_vm.scalePoles.from))])], 1) : _vm._e(), _vm.editable || !_vm.editable && _vm.labels.end ? _c('div', {
    staticClass: "opinion-scale-field-view__label opinion-scale-field-view__label--end"
  }, [_c('f-simple-contenteditable-input', {
    attrs: {
      "mask": _vm.regexp_mask,
      "placeholder": "Label",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.labels.end,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "end", $$v);
      },
      expression: "labels.end"
    }
  }), _c('span', [_vm._v("→ " + _vm._s(_vm.scalePoles.to))])], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }