<template>
    <f-template-data-transfer :imageLoaded="false" :errorWhileLoading="false" v-slot="{dataStash}">
        <div v-if="logoUrl && !dataStash.errorWhileLoading" class="static-form-logo-badge">
            <div class="static-form-logo-badge__background">
                <v-img
                        class="static-form-logo-badge__image"
                        :src="logoUrl"
                        @error="dataStash.errorWhileLoading = false"
                        @load="dataStash.imageLoaded = true"
                >
                    <template #placeholder>
                        <f-circle-loader size="3"/>
                    </template>
                </v-img>
            </div>
        </div>
    </f-template-data-transfer>
</template>

<script>
    export default {
        name: "LogoBadge",
        props: {
            logoUrl: String
        }
    }
</script>