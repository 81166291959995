import constants from '@/constants/constants'

export default {
    name: "GenericSingleAnswer__Datetime",
    functional: true,
    props: {
        submissionObj: {
            type: Object,
            required: true
        },
        sourceObj: {
            type: Object,
            required: true
        }
    },
    render: (h, ctx) => h(
        'span',
        null,
        constants.FIELD_TYPES.DATETIME.UTIL.GEN_READABLE_FROM_SUBMISSION_VALUE(ctx.props.sourceObj, ctx.props.submissionObj) || '-'
    )
}