<template>
  <div class="star-rating-field-view star-rating-field-view--full-single-answer-analytics">
    <span v-if="!submissionObj.value">-</span>
    <div
        v-else-if="isEmojiScale"
        class="star-rating-field-view__layout star-rating-field-view__layout--emoji-scale"
    >
      <div
          v-for="(emoji, index) in fieldConstProperties.emoji_scale.input_group.default.sets[sourceObj.properties.emoji_scale]"
          class="star-rating-field-view__item"
          :class="index === submissionObj.value && 'star-rating-field-view__item--selected'"
          :key="'emoji-scale' + index"
      >{{ emoji }}
      </div>
    </div>
    <div
        v-else-if="isScaleIcon"
        class="star-rating-field-view__layout star-rating-field-view__layout--scale-icon"
    >
      <div
          v-for="(_, index) in submissionObj.value + 1"
          class="star-rating-field-view__item"
          :key="'scale-icon' + index"
      >
        <LottieIconPlayer
            :json="fieldConstProperties.scale_icon.input_group.default.emojis[sourceObj.properties.scale_icon]"
            :active="submissionObj.value !== null && index <= submissionObj.value"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullSingleAnswer__StarRating",
  components: {
    LottieIconPlayer: () => import('../Icons/LottieIconPlayer')
  },
  props: {
    sourceObj: Object,
    submissionObj: Object
  },
  computed: {
    isEmojiScale() {
      return this.sourceObj.properties.emoji_scale !== null
    },
    isScaleIcon() {
      return this.sourceObj.properties.scale_icon !== null
    },
    fieldConstProperties() {
      return this.constants.FIELD_FROM_TYPE[this.sourceObj.field_type].BASE_PROPERTIES
    }
  }
}
</script>

<style scoped>

</style>