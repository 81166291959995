import { FieldPropertyInputGroup } from './property_input_group'
import store from '@/store'

class FieldPropertyConst {
    constructor({
        key,
        name,
        tooltip,
        always_enabled = false,
        enabled_by_default = false,
        input_group,
        caption,
        link_in_caption = false,
        linked_caption_part = null,
        switch_prepend,
        validator,
        meta,
        existence_condition,

        excluded_from_field_template = false
    }) {
        this.key = key
        this.name = name
        this.tooltip = tooltip
        this.always_enabled = always_enabled
        this.enabled_by_default = enabled_by_default
        this.input_group = input_group
        this.caption = caption
        this.link_in_caption = link_in_caption
        this.linked_caption_part = linked_caption_part
        this.switch_prepend = switch_prepend
        this.validator = validator
        this.meta = meta
        this.existence_condition = existence_condition

        this.excluded_from_field_template = excluded_from_field_template

        this.#validateAfterInitialization()
    }

    #throwError = function (message) {
        throw new Error(`${this.name}: ${message}`)
    }

    generatePropertyObj() {
        if (this.excluded_from_field_template)
            return {}

        let propertyValue = null

        if (!this.input_group)
            propertyValue = this.enabled_by_default
        else if (this.enabled_by_default)
            propertyValue = this.input_group.generatePropertyValue()

        return {[this.key]: propertyValue}
    }

    #validateAfterInitialization = function () {
        if (!this.key)
            this.#throwError('"key" is required')
        if (this.input_group && !(this.input_group instanceof FieldPropertyInputGroup))
            this.#throwError(`"input_group" must be instance of ${FieldPropertyInputGroup.name}`)
    }
}

export class CustomFieldPropertyConst extends FieldPropertyConst {}

export class RequiredFieldPropertyConst extends FieldPropertyConst {
    constructor() {
        super({
            key: 'required',
            name: 'Required',
            switch_prepend: () => import('@/components/Fields/EditableField/EditableFieldProperties/FieldPropertiesAdditionalComponents/FieldVisibilityControlledByConditionalLogicIcon'),
            validator: (newValue, fieldId, cb) => {
                if (store.getters['edit/conditional/getTargetedAndConditionedFields'][fieldId]?.targeted) {
                    store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                        header: 'Make field Required',
                        text: 'This block visibility is controlled by a conditional logic, by enabling "Required" property it will remove the conditional logic applied to it',
                        confirmBtnText: 'Yes, make it Required',
                        callback: () => store.dispatch('edit/conditional/removeConditionForField', store.getters['edit/conditional/getTargetedAndConditionedFields'][fieldId].target_owner).then(cb)
                    })
                    return false
                }

                return true
            },
            caption: () => import('@/components/Fields/EditableField/EditableFieldProperties/FieldPropertiesAdditionalComponents/BlockVisibilityIsControlledByConditionalLogicCaption')
        })
    }
}

export class DividerFieldPropertyConst extends FieldPropertyConst {
    constructor() {
        super({
            key: 'divider',
            excluded_from_field_template: true
        })
    }
}

export class HideFieldPropertyConst extends FieldPropertyConst {
    constructor() {
        super({
            name: 'Hide',
            key: 'hide',
            default: false,
            caption: () => import('@/components/Fields/EditableField/EditableFieldProperties/FieldPropertiesAdditionalComponents/HidePropertyCaption'),
            switch_prepend: () => import('@/components/Fields/EditableField/EditableFieldProperties/FieldPropertiesAdditionalComponents/FieldVisibilityControlledByConditionalLogicIcon'),
            validator: (newValue, fieldId, cb) => {
                if (store.getters['edit/conditional/getTargetedAndConditionedFields'][fieldId]?.targeted) {
                    store.dispatch('edit/conditional/openConfirmVisibilityChangeDialog', {
                        header: `${newValue ? 'Hide' : 'Show'} this question?`,
                        text: 'Just letting you know that changing this setting will remove the logic tied to this section.',
                        confirmBtnText: `${newValue ? 'Hide' : 'Show'} anyway`,
                        callback: () => store.dispatch('edit/conditional/removeConditionForField', store.getters['edit/conditional/getTargetedAndConditionedFields'][fieldId].target_owner).then(cb)
                    })
                    return false
                }

                return true
            },
        })
    }
}