var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "mx-auto  mb-3",
    attrs: {
      "transition": "fade",
      "color": "red",
      "dense": "",
      "dark": "",
      "text": "",
      "dismissible": "",
      "max-width": "300"
    },
    model: {
      value: _vm.alertModel,
      callback: function ($$v) {
        _vm.alertModel = $$v;
      },
      expression: "alertModel"
    }
  }, [_vm._v(_vm._s(_vm.alertText) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }