var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('v-dialog', {
    attrs: {
      "value": true,
      "hide-overlay": "",
      "transition": "none",
      "eager": "",
      "content-class": "form-preview-dialog",
      "persistent": "",
      "no-click-animation": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('v-overlay', {
          staticClass: "form-preview-overlay",
          attrs: {
            "absolute": "",
            "color": "#00061C",
            "opacity": "0.7",
            "z-index": "1",
            "value": _vm.dialog
          }
        }), _c('v-slide-y-transition', [_vm.dialog ? _c('div', {
          staticClass: "form-preview-dialog__container",
          staticStyle: {
            "position": "relative",
            "z-index": "5"
          }
        }, [_c('div', {
          staticClass: "form-preview-dialog__header"
        }, [_c('div', {
          staticClass: "form-preview-dialog__live-preview-hint"
        }, [_vm._v("Live Preview")]), _c('div', [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center",
            "align-content": "center"
          }
        }, [_c('v-col', {
          staticClass: "d-flex justify-center"
        }, _vm._l(_vm.deviceViews, function (view, index) {
          return _c('f-hover-tooltip', {
            key: view.type,
            attrs: {
              "top": "",
              "text": view.text
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref) {
                var on = _ref.on;
                return [_c('div', _vm._g({
                  staticClass: "form-preview-dialog__device-icon-btn",
                  class: _vm.selectedView === index && 'form-preview-dialog__device-icon-btn--active',
                  on: {
                    "click": function ($event) {
                      return _vm.changeDeviceView(index);
                    }
                  }
                }, on), [_c('v-icon', [_vm._v(" " + _vm._s(view.icon) + " ")])], 1)];
              }
            }], null, true)
          });
        }), 1)], 1)], 1), _c('div', {
          staticClass: "d-flex flex-row justify-end"
        }, [!_vm.externalDisabled ? _c('div', {
          class: ['form-preview-dialog__external-link-btn'],
          on: {
            "click": _vm.externalOpen
          }
        }, [_c('ExternalLinkIcon', {
          attrs: {
            "stroke-width": "1.5"
          }
        })], 1) : _vm._e(), _c('div', {
          staticClass: "form-preview-dialog__close-btn",
          on: {
            "click": function ($event) {
              _vm.dialog = false;
              _vm.$emit('close');
            }
          }
        }, [_c('XIcon')], 1)])]), _c('div', {
          staticClass: "form-preview-dialog__form-view-container"
        }, [_c('f-aspect-ratio-keeper', {
          attrs: {
            "width": _vm.deviceViews[_vm.selectedView].aspectRatio.w,
            "height": _vm.deviceViews[_vm.selectedView].aspectRatio.h,
            "depends-on": "height"
          }
        }, [_c('div', {
          staticClass: "form-preview-dialog__form-view-content",
          class: ("form-preview-dialog__form-view-content--" + (_vm.deviceViews[_vm.selectedView].type))
        }, [_c('FormslyFormWrapper', {
          attrs: {
            "width": _vm.deviceViews[_vm.selectedView].width
          }
        }, [_vm._t("default", null, {
          "active": _vm.dialog
        })], 2)], 1)])], 1)]) : _vm._e()])];
      },
      proxy: true
    }], null, true)
  }), _vm._t("activator", null, {
    "on": {
      click: function () {
        return _vm.dialog = !_vm.dialog;
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }