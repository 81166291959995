var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select', {
    staticClass: "conditional-logic-editor__target-field",
    attrs: {
      "items": _vm.getFilteredEnumeratedFieldsList,
      "item-value": function (item) {
        return item.fieldId;
      },
      "item-disabled": _vm.checkIfFieldInaccessible,
      "menu-props": {
        nudgeBottom: 46,
        contentClass: 'conditional-logic-editor__target-items'
      },
      "error-messages": _vm.errors,
      "outlined": "",
      "hide-details": "auto",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(item.fieldConst.ICON_COMPONENT, {
          tag: "component",
          staticClass: "mr-3",
          attrs: {
            "width": "30",
            "height": "30"
          }
        }), _c('span', [_c('span', {
          staticClass: "mr-2 grey_700--text"
        }, [_vm._v(_vm._s(item.formattedLocation) + ":")]), _c('span', [_vm._v(_vm._s(item.fieldObj.title))])])];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs,
          item = _ref2.item;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c(item.fieldConst.ICON_COMPONENT, {
          tag: "component",
          attrs: {
            "width": "30",
            "height": "30"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('span', {
          staticClass: "mr-2 grey_700--text"
        }, [_vm._v(_vm._s(item.formattedLocation) + ":")]), _c('span', [_vm._v(_vm._s(item.fieldObj.title))])])], 1), attrs.disabled ? _c('v-list-item-action', [item.conditioned ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "This Question already has Conditional Logic"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('span', _vm._g({}, on), [_vm._v("Conditioned")])];
            }
          }], null, true)
        }) : item.used_in_this_statement ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "This Question is already used in this Conditional Logic statement"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('span', _vm._g({}, on), [_vm._v("Conditioned")])];
            }
          }], null, true)
        }) : item.targeted ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "This Question is already used in another Conditional Logic statement"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var on = _ref5.on;
              return [_c('span', _vm._g({}, on), [_vm._v("Conditioned")])];
            }
          }], null, true)
        }) : item.hidden ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "This question is hidden"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('span', _vm._g({}, on), [_vm._v("This question will not be shown")])];
            }
          }], null, true)
        }) : item.shown ? _c('f-hover-tooltip', {
          attrs: {
            "top": "",
            "text": "This Question isn't hidden in Block Properties"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref7) {
              var on = _ref7.on;
              return [_c('span', _vm._g({}, on), [_vm._v("Not hidden")])];
            }
          }], null, true)
        }) : _vm._e()], 1) : _vm._e()], 1)];
      }
    }, {
      key: "message",
      fn: function (_ref8) {
        var key = _ref8.key,
          message = _ref8.message;
        return [message === 'targetedFieldNotRequired' ? _c('TargetErrorMessageFieldRequired', {
          attrs: {
            "field-id": _vm.targetObj.field_id
          }
        }) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.targetObj.field_id,
      callback: function ($$v) {
        _vm.$set(_vm.targetObj, "field_id", $$v);
      },
      expression: "targetObj.field_id"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }