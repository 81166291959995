var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full d-flex align-center justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.signUpStage === _vm.signUpStages.SIGNUP ? _c('LoginLayoutWithImage', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Create a FREE account ")];
      },
      proxy: true
    }, {
      key: "form",
      fn: function () {
        return [_c('v-form', {
          ref: "signUpForm",
          attrs: {
            "lazy-validation": ""
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('ErrorAlertComponent'), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Name "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Enter your name",
            "rules": _vm.nameRules,
            "outlined": "",
            "disabled": _vm.isLoading
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.signUpForm.resetValidation();
            }
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Email "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Enter your email",
            "rules": _vm.emailRules,
            "outlined": "",
            "disabled": _vm.isLoading
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.signUpForm.resetValidation();
            }
          },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Password "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Create a password",
            "rules": _vm.passwordRules,
            "messages": !_vm.password ? ("Must be at least " + (_vm.constants.MIN_PASS_LENGTH) + " characters") : '',
            "type": "password",
            "outlined": "",
            "disabled": _vm.isLoading
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.signUpForm.resetValidation();
            }
          },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('div', {
          staticClass: "mb-2"
        }, [_vm._v("Confirm Password "), _c('span', {
          staticClass: "error--text"
        }, [_vm._v("*")])]), _c('v-text-field', {
          staticClass: "rounded-lg",
          attrs: {
            "placeholder": "Confirm a password",
            "rules": [_vm.password === _vm.confirmPassword || 'Passwords must match'],
            "type": "password",
            "outlined": "",
            "disabled": _vm.isLoading
          },
          on: {
            "keydown": function ($event) {
              return _vm.$refs.signUpForm.resetValidation();
            }
          },
          model: {
            value: _vm.confirmPassword,
            callback: function ($$v) {
              _vm.confirmPassword = $$v;
            },
            expression: "confirmPassword"
          }
        }), _c('v-btn', {
          staticClass: "mb-4",
          attrs: {
            "color": "blue_400",
            "depressed": "",
            "large": "",
            "rounded": "",
            "block": ""
          },
          on: {
            "click": _vm.signUp
          }
        }, [_vm._v("Create account ")]), _c('v-btn', {
          staticClass: "mb-4 dark_gray--text",
          attrs: {
            "outlined": "",
            "depressed": "",
            "large": "",
            "rounded": "",
            "block": ""
          },
          on: {
            "click": _vm.signUpGoogle
          }
        }, [_c('img', {
          staticClass: "mr-4",
          attrs: {
            "draggable": "false",
            "src": "/images/GoogleLogo.svg"
          }
        }), _c('span', [_vm._v("Sign up with Google")])])], 1)];
      },
      proxy: true
    }, {
      key: "jump-to",
      fn: function () {
        return [_vm._v(" Already have an account? "), _c('span', {
          staticClass: "ml-2 blue_400--text font-weight-bold cursor-pointer",
          on: {
            "click": _vm.goToLogin
          }
        }, [_vm._v("Log in")])];
      },
      proxy: true
    }, {
      key: "additional-bottom",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-divider')], 1)], 1), _c('v-row', {
          staticClass: "mt-1",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', [_c('p', {
          staticClass: "gray_400--text caption"
        }, [_c('TermOfUse', {
          attrs: {
            "is-visible": _vm.dialogTermsVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_vm._v(" By clicking ‘Create account’ or signing up, you agree to the "), _c('span', _vm._g({
                staticClass: "blue_400--text cursor-pointer d-inline"
              }, on), [_vm._v("Terms of Use")])];
            }
          }], null, false, 1976130010)
        })], 1), _c('p', {
          staticClass: "gray_400--text caption"
        }, [_c('PrivacyPolicy', {
          attrs: {
            "is-visible": _vm.dialogPolicyVisible
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_vm._v(" and "), _c('span', _vm._g({
                staticClass: "blue_400--text cursor-pointer"
              }, on), [_vm._v("Privacy Notice.")])];
            }
          }], null, false, 1271285207)
        }), _vm._v(" You also agree to receive information and offers relevant to our services via email. You can opt-out of these emails in your My Account page anytime. ")], 1)])], 1)];
      },
      proxy: true
    }], null, false, 4272008938)
  }) : _vm._e(), _vm.signUpStage === _vm.signUpStages.VERIFICATION ? _c('VerificationTemplate', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-6 d-flex rounded-circle blue_100"
        }, [_c('MailIcon', {
          staticClass: "blue_400--text"
        })], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Check your email ")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_vm._v(" We sent a verification code to " + _vm._s(_vm.user.username) + " ")];
      },
      proxy: true
    }, {
      key: "code-input",
      fn: function () {
        return [_c('ErrorAlertComponent'), _c('v-otp-input', {
          attrs: {
            "hide-spin-buttons": "",
            "length": "6",
            "type": "number"
          },
          model: {
            value: _vm.verificationCode,
            callback: function ($$v) {
              _vm.verificationCode = $$v;
            },
            expression: "verificationCode"
          }
        })];
      },
      proxy: true
    }, {
      key: "action",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "disabled": _vm.verificationCode.length !== 6,
            "block": "",
            "depressed": "",
            "rounded": "",
            "color": "blue_400"
          },
          on: {
            "click": _vm.verifyEmail
          }
        }, [_vm._v(" Verify email ")])];
      },
      proxy: true
    }, !_vm.codeResent ? {
      key: "secondary-action",
      fn: function () {
        return [_vm._v(" Didn't receive the email? "), _c('span', {
          staticClass: "blue_400--text cursor-pointer",
          on: {
            "click": _vm.resendConfirmationCode
          }
        }, [_vm._v("Click to resend")])];
      },
      proxy: true
    } : null], null, true)
  }) : _vm._e(), _vm.signUpStage === _vm.signUpStages.VERIFIED ? _c('VerificationTemplate', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-6 d-flex rounded-circle green_100"
        }, [_c('CheckCircleIcon', {
          staticClass: "green_500--text"
        })], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Email verified ")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_vm._v(" Your password has been successfully reset."), _c('br'), _vm._v(" Click below to log in magically. ")];
      },
      proxy: true
    }, {
      key: "action",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "block": "",
            "depressed": "",
            "rounded": "",
            "color": "blue_400"
          },
          on: {
            "click": _vm.continueLogin
          }
        }, [_vm._v(" Continue ")])];
      },
      proxy: true
    }], null, false, 1172195594)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }