var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['style-preferences-template-preview', _vm.selected && 'style-preferences-template-preview--selected']
  }, [_c('div', {
    staticClass: "style-preferences-template-preview__wrapper"
  }, [_c('div', {
    staticClass: "style-preferences-template-preview__card",
    on: {
      "click": function ($event) {
        !_vm.selected && _vm.selectTheme();
      }
    }
  }, [_c('v-img', {
    staticClass: "style-preferences-template-preview__image",
    attrs: {
      "src": _vm.previewImage
    }
  }, [_c('div', {
    staticClass: "style-preferences-template-preview__image-content-wrapper"
  }, [_c('div', {
    staticClass: "style-preferences-template-preview-image-content"
  }, [_c('div', {
    staticClass: "style-preferences-template-preview-image-content__question",
    style: _vm.questionColorPreviewStyle
  }, [_vm._v(" Question ")]), _c('div', {
    staticClass: "style-preferences-template-preview-image-content__answer",
    style: _vm.answerColorPreviewStyle
  }, [_vm._v(" Answer ")]), _c('div', {
    staticClass: "style-preferences-template-preview-image-content__button",
    style: _vm.buttonColorPreviewStyle
  })])])]), _c('div', {
    staticClass: "style-preferences-template-preview__footer"
  }, [_c('div', {
    staticClass: "style-preferences-template-preview__name"
  }, [_vm._v(" " + _vm._s(_vm.name || 'Warm green') + " ")]), _c('div', {
    staticClass: "style-preferences-template-preview__action"
  }, [_vm._t("action")], 2)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }