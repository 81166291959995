import '@/analytics'
import '@/utils/composition_api_setup'

import { Amplify } from 'aws-amplify'
import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'

import constants from '@/constants/constants'
import AuthActions from '@/utils/actions/auth_actions'
import FormSubmitActions from '@/utils/actions/form_submit_actions'
import OwnersFormActions from '@/utils/actions/owners_form_actions'
import StorageActions from '@/utils/actions/storage_actions'
import SubmissionsActions from '@/utils/actions/submissions_actions'
import Utils from '@/utils/misc'
import { promiseWithAnimation } from '@/utils/promise_with_animation'
import sequence_director from '@/utils/sequence_director'

import App from './App.vue'
import awsconfig from './aws-exports'
import formsly_ui_kit from './plugins/formsly_ui_kit'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueSignaturePad)

axios.defaults.baseURL = awsconfig.aws_cloud_logic_custom.find(
  api => api.name === 'core'
).endpoint

Vue.prototype.axios = axios

Vue.config.productionTip = false

Vue.prototype.AuthActions = AuthActions
Vue.prototype.OwnersFormActions = OwnersFormActions
Vue.prototype.StorageActions = StorageActions
Vue.prototype.SubmissionsActions = SubmissionsActions
Vue.prototype.FormSubmitActions = FormSubmitActions
Vue.prototype.constants = constants
Vue.prototype.promiseWithAnimation = promiseWithAnimation
Vue.prototype.Utils = Utils

Vue.prototype.draggableEventBus = new Vue()
Vue.prototype.fileInputEventBus = new Vue()
Vue.prototype.snackbarEventBus = new Vue()
Vue.prototype.SequenceDirector = sequence_director

_.mixin({
  defaultsDeepWithClone(object, ...sources) {
    return _.defaultsDeep(_.cloneDeep(object), ...sources)
  },
})

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const [localRedirectSignIn, productionRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, productionRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(',')

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
}
//console.log('Update Console', updatedAwsConfig)
Amplify.configure(updatedAwsConfig)

Object.defineProperty(constants, 'MIN_PASS_LENGTH', {
  value:
    Amplify._config.aws_cognito_password_protection_settings
      .passwordPolicyMinLength,
  writable: true,
  enumerable: true,
  configurable: true,
})

Vue.prototype.$store = store

// if (!localStorage.getItem('countryCode')) {
//    fetch('http://ip-api.com/json')
//     .then(res => res.json())
//     .then(response => {
//         localStorage.setItem('countryCode', response.countryCode)
//     })
//        .catch(e => console.log(`During getting country code - ${e.message}`))
// }

new Vue({
  router,
  vuetify,
  formsly_ui_kit,
  beforeCreate() {
    store.install(this)
  },
  render: h => h(App),
}).$mount('#app')
