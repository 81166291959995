var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": "",
      "scale-poles": _vm.fieldProperties.scale_poles,
      "labels": _vm.fieldProperties.labels
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }