import {mapGetters} from '@/xstore'

export const editable_form_navigation_mixin = {
    computed: {
        ...mapGetters('edit', ['getPagesList', 'getCurrentPageAbsoluteIndex']),
        isFirstPage() {
            return this.getCurrentPageAbsoluteIndex === 0
        },
        isLastPage() {
            return this.getCurrentPageAbsoluteIndex === this.getPagesList.length - 1
        },
        currentPageNumber() {
            return this.getCurrentPageAbsoluteIndex + 1
        },
        pagesCount() {
            return this.getPagesList.length
        }
    },
    methods: {
        prevPage() {
            if (this.getCurrentPageAbsoluteIndex !== 0)
                this.$store.dispatch('edit/pages_navigation/setCurrentPageId', this.getPagesList[this.getCurrentPageAbsoluteIndex - 1].page_id)
        },
        nextPage() {
            if (this.getCurrentPageAbsoluteIndex !== this.getPagesList.length - 1)
                this.$store.dispatch('edit/pages_navigation/setCurrentPageId', this.getPagesList[this.getCurrentPageAbsoluteIndex + 1].page_id)
        }
    }
}