var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-list-item', _vm._g({
          attrs: {
            "disabled": _vm.isLoading
          }
        }, on), [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "red_400--text"
        }, [_vm._v("Delete")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Delete \"" + _vm._s(_vm.formData.name || 'Untitled') + "\"? "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('XIcon')], 1)], 1), _c('v-card-text', [_vm._v(" Are you sure you want to delete this form? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": _vm.deleteForm
    }
  }, [_vm._v(" Delete ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }