<template>
    <div
            :class="[
                'f-checkbox',
                radio && 'f-checkbox--radio',
                value ? 'f-checkbox--value-true' : 'f-checkbox--value-false',
                disabled && 'f-checkbox--disabled',
                readonly && 'f-checkbox--readonly',
                hasLabel && 'f-checkbox--has-label'
            ]"
    >
        <div class="f-checkbox__control">
            <div class="f-checkbox__slot" @click="toggleValue">
                <div class="f-checkbox__input-wrapper">
                    <div class="f-checkbox__input-control">
                        <div v-if="value" class="f-checkbox__icon-wrapper">
                          <div class="f-checkbox__icon">
                            <svg v-if="radio" width="8" height="8" viewBox="0 0 8 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <circle cx="4" cy="4" r="4" fill="currentColor"/>
                            </svg>
                            <svg v-else width="12" height="9" viewBox="0 0 12 9"
                                 xmlns="http://www.w3.org/2000/svg" fill="none">
                              <path d="M11 1.5L4.56707 7.75L1 4.26786" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="f-checkbox__label" v-if="$scopedSlots.label">
                    <slot name="label"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Checkbox",
        props: {
            value: {
                type: Boolean,
                default: false
            },
            radio: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasLabel() {
                return !!this.$slots?.label
            }
        },
        methods: {
            toggleValue() {
                if (!this.disabled && !this.readonly)
                    this.$emit('input', !this.value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .f-checkbox {
        &__control {
            display: flex;
            align-items: center;
        }

        &__slot {
            display: flex;
            align-items: center;
            justify-content: center;
            //flex: 1 1 auto;
            max-width: 100%;
        }

        &__input-control {
            width: 20px;
            height: 20px;
            border-radius: 4px;
        }
        &__icon-wrapper {
            width: 100%;
            height: 100%;
        }
        &__icon {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
        &__label {
            display: flex;
            align-items: center;
            flex: inherit;
            max-width: 100%;
            min-width: 0;
        }

        &--radio {
            .f-checkbox__input-control {
                border-radius: 100px;
            }
        }

        &--value-false {
            .f-checkbox__input-control {
                box-shadow: 0px 0px 0px 1.5px var(--v-gray_300-base) inset;
            }
        }

        &--value-true {
            .f-checkbox__input-control {
                background-color: var(--v-blue_400-base);
            }
        }

        &--disabled {
            &.f-checkbox--value-false {
                .f-checkbox__input-control {
                    box-shadow: 0px 0px 0px 1.5px var(--v-gray_200-base) inset;
                }
            }

            &.f-checkbox--value-true {
                .f-checkbox__input-control {
                    background-color: var(--v-gray_500-base);
                }
            }
        }

        &--has-label {
            .f-checkbox__input-control {
                margin-right: 15px;
            }
        }

        &:not(.f-checkbox--disabled):not(.f-checkbox--readonly) {
            .f-checkbox__slot {
                cursor: pointer;
            }
        }
    }
</style>