import FSPThemesActions from '@/utils/actions/fsp_themes_actions'

import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import form_edit_fsp_themes_selected_theme_module from './fsp_themes_modules/form_edit_fsp_themes_selected_theme_module'
import form_edit_fsp_themes_editing_theme_module
    from '@/store/form_edit/modules/fsp_themes_modules/form_edit_fsp_themes_editing_theme_module'
import constants from '@/constants/constants'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { VBtn, VSpacer } from 'vuetify/lib/components'
import { reactive } from '@vue/composition-api'

export const generateNewThemeObj = (name = 'My Theme') => ({
    name,
    rawData: constants.FSP.DEFAULT_FSP_OBJECT()
})

export default ({useExtension}) => {
    {
        const state = {
            public_themes: [],
            private_themes: []
        }
        useExtension(SMAGableExtensions.SMGable, {state})
    }

    {
        const state = {
            style_preferences_menu_model: false
        }
        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        modules: {
            confirmation_dialog: generic_dialog_module,
            selected: form_edit_fsp_themes_selected_theme_module,
            editing: form_edit_fsp_themes_editing_theme_module
        },
        actions: {
            fetchAllThemes: ({commit}) => Promise.all([
                FSPThemesActions.listPublicThemes(),
                FSPThemesActions.listPrivateThemes()
            ])
                .then(([publicThemes, privateThemes]) => {
                    commit('SET_PUBLIC_THEMES', publicThemes)
                    commit('SET_PRIVATE_THEMES', privateThemes)
                }),
            fetchCurrentFormTheme: ({dispatch}, {FSPThemeID}) => dispatch('selected/fetchCurrentFormTheme', FSPThemeID),

            deleteTheme: ({commit, dispatch, getters, parentGetters}, themeObj) => {
                let mainText
                if (themeObj.relatedForms.some(({id}) => id !== parentGetters.getFormDBData.id))
                    mainText = 'Other forms are using this theme that you want to delete, by deleting it, the Default Theme will be applied on other forms that are using the same theme'
                else
                    mainText = 'Just letting you know it\'ll be deleted from your theme gallery for good.'

                dispatch('confirmation_dialog/showDialog', ({closeDialog}) => {
                    const dialogProps = reactive({
                        withCloseBtn: true,
                        persistent: false
                    })

                    return {
                        title: `Delete this theme "${themeObj.name}"?`,
                        content: mainText,
                        actions: {
                            functional: true,
                            render: h => [
                                h(VSpacer),
                                h(VBtn, {
                                    props: {
                                        color: 'gray_100',
                                        rounded: true,
                                        disabled: dialogProps.persistent
                                    },
                                    on: {
                                        click: closeDialog
                                    }
                                }, 'Not now'),
                                h(VBtn, {
                                    props: {
                                        color: 'red_400',
                                        rounded: true,
                                        loading: dialogProps.persistent
                                    },
                                    on: {
                                        click: async () => {
                                            dialogProps.persistent = true

                                            if (themeObj.id === getters['selected/getSelectedThemeId'])
                                                await dispatch('selected/selectTheme', {id: 'default_theme'})
                                            await FSPThemesActions.deleteTheme(themeObj.id)

                                            commit('SET_PRIVATE_THEMES', getters.getPrivateThemes.filter(({id}) => id !== themeObj.id))

                                            dialogProps.persistent = false

                                            closeDialog()
                                        }
                                    }
                                }, 'Delete anyway')
                            ]
                        },
                        props: dialogProps
                    }
                })
            },

            processStylePreferencesExit: ({getters, dispatch}, callback) => {
                if (getters['editing/isThemeEditorActive'] && getters['editing/isCurrentEditingThemeChanged'])
                    dispatch('confirmation_dialog/showDialog', ({closeDialog}) => {
                        const localCallback = () => {
                            callback()
                            dispatch('editing/quitThemeEditor')
                            closeDialog()
                        }

                        const dialogProps = reactive({
                            withCloseBtn: true,
                            persistent: false
                        })

                        return {
                            title: 'Let\'s save your changes?',
                            content: 'Just making sure you save your changes before leaving!',
                            actions: {
                                functional: true,
                                render: h => [
                                    h(VSpacer),
                                    h(VBtn, {
                                        props: {
                                            color: 'gray_100',
                                            rounded: true,
                                            disabled: dialogProps.persistent
                                        },
                                        on: {
                                            click: localCallback
                                        }
                                    }, 'Not now'),
                                    h(VBtn, {
                                        props: {
                                            color: 'blue_400',
                                            rounded: true,
                                            loading: dialogProps.persistent
                                        },
                                        on: {
                                            click: async () => {
                                                dialogProps.persistent = true

                                                await new Promise(resolve => {
                                                    if (getters['editing/isCurrentEditingThemeAlreadyExist'])
                                                        dispatch('editing/savePrivateThemeChanges', promise => promise.then(localCallback).then(resolve))
                                                    else
                                                        dispatch('editing/saveNewPrivateTheme').then(localCallback).then(resolve)
                                                })


                                                dialogProps.persistent = false
                                            }
                                        }
                                    }, 'Save changes')
                                ]
                            },
                            props: dialogProps
                        }
                    })
                else
                    callback()
            }
        },
        getters: {
            getCurrentStylePreferences: ({getters}) => (getters['editing/isThemeEditorActive']
                ? getters['editing/getCurrentEditingThemeStyles']
                : getters['selected/getCurrentSelectedThemeStyles']) || {}
        }
    }
}