<template>
    <v-container class="no-forms h-full" fluid>
        <img src="/images/NoFormsToShow.svg" alt="">
        <h2 class="no-forms__header">Create your first form?</h2>
        <div class="no-forms__description">
            <p>Choose from any of our templates or start one from scratch!</p>
        </div>
        <v-btn
                        :to="formCreationPage"
                        rounded
                        color="blue_400"
                        depressed
                        dark
        >
            <PlusIcon class="v-btn__prepend-icon"/>
            Create first form
        </v-btn>
    </v-container>
</template>

<script>

    import {PlusIcon} from 'vue-feather-icons'

    export default {
        components: {
            PlusIcon,
        },
        computed: {
            formCreationPage() {
                return {name: this.constants.PAGE_NAME_FORM_TYPE_SELECTION}
            },
        }
    }
</script>

<style scoped lang="scss">
    .no-forms {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
            margin-bottom: 25px;
        }

        &__header {
            font-size: 19px;
            font-weight: 500;
            color: var(--v-gray_900-base);
            line-height: 38px;
            margin-bottom: 6px;
        }

        &__description {
            font-weight: 300;
            color: var(--v-gray_400-base);
            font-size: 14px;
            margin-bottom: 52px;
            text-align: center;

            p {
                margin: 0;
            }
        }
    }
</style>