import twemoji from 'twemoji'

export const Emoji = {
    inserted: (el, binding) => {
        twemoji.parse(el, {
            folder: 'svg',
            ext: '.svg'
        });

        let img = el.querySelector('.emoji');
        img.setAttribute('draggable', false)
        if (binding.value && 'width' in binding.value)
            img.style.width = binding.value.width;

        if (binding.modifiers.svg) {
            let img = el.querySelector('.emoji');
            fetch(img.src)
                .then(response => response.text())
                .then(data => {
                    const parser = new DOMParser();
                    const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
                    svg.classList = img.classList;
                    svg.style.width = img.style.width;
                    img.parentNode.replaceChild(svg, img);
                })
        }
    }
}

export default Emoji