import { render, staticRenderFns } from "./AccountDetails.vue?vue&type=template&id=7a973f44&scoped=true&"
import script from "./AccountDetails.vue?vue&type=script&lang=js&"
export * from "./AccountDetails.vue?vue&type=script&lang=js&"
import style0 from "./AccountDetails.vue?vue&type=style&index=0&id=7a973f44&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_react@18.2.0_vue-templa_wkiss7gp4bcqnlea7oiy3v6evi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a973f44",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.6.14_vuetify@2.6.3_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VDialog,VDivider,VForm,VSpacer,VTextField})
