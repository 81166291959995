import FSPThemesActions from '@/utils/actions/fsp_themes_actions'
import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            selected_theme_id: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }
    {
        const state = {
            current_themes_store_tab: 2
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        actions: {
            fetchCurrentFormTheme: ({commit}, themeID) => FSPThemesActions.getTheme(themeID)
                .then(themeObj => commit('SET_SELECTED_THEME_ID', themeObj.id)),
            selectTheme: ({commit, dispatch}, themeObj) => {
                commit('SET_SELECTED_THEME_ID', themeObj.id)
                return Promise.all([
                    dispatch('edit/updateFormDBData', {FSPThemeID: themeObj.id}, {root: true}),
                    dispatch('edit/changeFormAttrs', {FSPThemeID: themeObj.id}, {root: true})
                ])
            }
        },
        getters: {
            getCurrentSelectedTheme: ({getters, parentGetters}) => parentGetters.getPrivateThemes.find(({id}) => id === getters.getSelectedThemeId)
                || parentGetters.getPublicThemes.find(({id}) => id === getters.getSelectedThemeId)
                || {},
            getCurrentSelectedThemeStyles: ({getters}) => getters.getCurrentSelectedTheme?.rawData,
        }
    }
}