<template>
    <v-dialog
            width="600"
            v-model="dialog"
    >
        <template #activator="{ on }">
            <v-list-item
                    v-on="on"
                    :disabled="isLoading"
            >
                <v-list-item-content>
                    <v-list-item-title class="red_400--text">Delete</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title>
                Delete "{{ formData.name || 'Untitled' }}"?
                <v-spacer/>
                <v-btn
                        icon
                        @click="dialog = false"
                >
                    <XIcon/>
                </v-btn>
            </v-card-title>
            <v-card-text>
                Are you sure you want to delete this form?
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                        color="gray_100"
                        rounded
                        @click="dialog = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                        color="blue_400"
                        rounded
                        @click="deleteForm"
                >
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {XIcon} from 'vue-feather-icons'
    import { mapGetters } from '@/xstore'

    export default {
        name: "DeleteFormModal",
        components: {
            XIcon
        },
        props: {
            formData: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            dialog: false
        }),
        computed: {
            ...mapGetters(['isLoading']),
        },
        methods: {
            deleteForm() {
                this.OwnersFormActions.deleteForm(this.formData.id)
                this.$store.dispatch('forms_list/removeFormDataFromFormsList', this.formData.id)
            }
        }
    }
</script>