var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-main-page"
  }, [_c('div', {
    staticClass: "static-main-page__content"
  }, [_c('StaticFieldsDragManager', {
    attrs: {
      "page-obj": _vm.pageObj
    },
    on: {
      "start-adding-new-field": function ($event) {
        _vm.isNewFieldAdding = true;
      },
      "stop-adding-new-field": function ($event) {
        _vm.isNewFieldAdding = false;
      }
    }
  }), !_vm.pageObj.rows.length && !_vm.isNewFieldAdding ? _c('div', {
    staticClass: "static-main-page__empty"
  }, [_c('img', {
    staticClass: "img-empty-page",
    attrs: {
      "src": "/images/EmptyStaticForm.svg",
      "draggable": false
    }
  }), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Time to create! ")]), _c('p', {
    staticClass: "subtitle-2 grey_500--text font-weight-regular mb-10"
  }, [_vm._v(" Simply drag and drop or choose questions to fill your page ")])]) : _vm._e()], 1), _c('StaticMainPageSubmitButtonComponent', {
    attrs: {
      "page-obj": _vm.pageObj
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }