<script>
  export default {
    name: 'GenericSelectionFieldFrontView',
    props: {
      optionsList: {
        type: Array,
        required: true,
      },
      model: {
        type: Array,
        default: () => [],
      },
      radio: {
        type: Boolean,
        default: false,
      },
      optionInput: {
        type: Function,
        default: () => null,
      },
      fieldObj: {
        type: Object,
        required: true,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      isFormStatic: {
        type: Boolean,
        required: true,
      },
      isFormInteractive: {
        type: Boolean,
        required: true,
      },
      isPreview: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <div class="selection-field-view">
    <div class="selection-field-view__layout-wrapper">
      <div class="selection-field-view__layout">
        <template v-if="isFormStatic">
          <div
            v-for="index of optionsList"
            :key="index"
            class="selection-field-view__option-wrapper"
          >
            <div
              :class="[
                'selection-field-view__selection-field-option',
                model.includes(index) &&
                  'selection-field-view__selection-field-option--active',
              ]"
            >
              <f-checkbox
                :value="model.includes(index)"
                :radio="radio"
                :disabled="editable || isPreview"
                @input="optionInput(index)"
              >
                <template #label>
                  <f-template-data-transfer
                    v-slot="{ dataStash }"
                    :focused="false"
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                        class="selection-field-view__selection-field-option__label-wrapper"
                      >
                        <div
                          class="selection-field-view__selection-field-option__label-input-wrapper"
                        >
                          <f-simple-contenteditable-input
                            ref="optionNameInput"
                            v-model="fieldObj.content.options[index]"
                            class="selection-field-view__selection-field-option__label"
                            placeholder="Choice"
                            prevent-highlighting
                            overflow-wrap
                            :mask="/^.{0,240}$/g"
                            :readonly="!editable"
                            @update:focused="dataStash.focused = $event"
                            @keydown.enter="$emit('insert-option', index + 1)"
                          />
                        </div>
                        <div
                          class="selection-field-view__selection-field-option__option-append-wrapper"
                        >
                          <slot
                            name="option-append"
                            :index="index"
                            :hover="hover"
                            :focused="dataStash.focused"
                            :active="model.includes(index)"
                          />
                        </div>
                      </div>
                    </v-hover>
                  </f-template-data-transfer>
                </template>
              </f-checkbox>
            </div>
          </div>
        </template>

        <template v-else-if="isFormInteractive">
          <div
            v-for="index of optionsList"
            :key="index"
            class="selection-field-view__selection-field-view__option-wrapper generic-interactive-transparent-blurry-card"
          >
            <f-template-data-transfer
              v-slot="{ dataStash }"
              :focused="false"
            >
              <v-hover v-slot="{ hover }">
                <div
                  :class="[
                    'selection-field-view__selection-field-option',
                    model.includes(index) &&
                      'selection-field-view__selection-field-option--active',
                    model.includes(index) &&
                      'generic-interactive-transparent-blurry-card__active',
                    hover &&
                      'generic-interactive-transparent-blurry-card__hover',
                  ]"
                  @click="optionInput(index)"
                >
                  <div
                    class="selection-field-view__selection-field-option__wrapper generic-interactive-transparent-blurry-card__card"
                  >
                    <div
                      class="selection-field-view__selection-field-option__anchor-letter-wrapper"
                    >
                      <div
                        class="selection-field-view__selection-field-option__anchor-letter"
                      >
                        {{ Utils.convertIndexToLetter(index) }}
                      </div>
                    </div>
                    <div
                      class="selection-field-view__selection-field-option__label-wrapper"
                    >
                      <f-simple-contenteditable-input
                        ref="optionNameInput"
                        v-model="fieldObj.content.options[index]"
                        class="selection-field-view__selection-field-option__label"
                        placeholder="Choice"
                        prevent-highlighting
                        overflow-wrap
                        :mask="/^.{0,240}$/g"
                        :readonly="!editable"
                        @update:focused="dataStash.focused = $event"
                        @keydown.enter="$emit('insert-option', index + 1)"
                      />
                    </div>
                    <div
                      class="selection-field-view__selection-field-option__option-append-wrapper"
                    >
                      <slot
                        name="option-append"
                        :index="index"
                        :hover="hover"
                        :focused="dataStash.focused"
                        :active="model.includes(index)"
                      />
                    </div>
                  </div>
                </div>
              </v-hover>
            </f-template-data-transfer>
          </div>
        </template>
      </div>
    </div>
    <slot name="extension" />
  </div>
</template>
