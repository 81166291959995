var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-4 form-parameters-card"
  }, [_c('v-card-title', {
    staticClass: "pb-0"
  }, [_c('div', {
    staticClass: "form-parameters-header"
  }, [_vm._t("title")], 2), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$parent.$emit('close');
      }
    }
  }, [_c('XIcon', {
    staticClass: "gray_400--text",
    attrs: {
      "size": "19"
    }
  })], 1)], 1), _c('v-card-text', [_vm._t("content")], 2), _vm.$slots.actions ? _c('v-card-actions', [_vm._t("actions")], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }