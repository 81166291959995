import { generateUUID } from '@/utils/misc'
import { VInput } from 'vuetify/lib/components'

export default {
    name: 'PaymentMethodCardForm_Name',
    functional: true,
    props: {
        bind: {
            type: Object,
            required: true
        }
    },
    render(h, ctx) {
        const cardElementContainerID = 'stripeCard-' + generateUUID()

        return [
            h('p', {class: {'subtitle-2': true, 'mb-1': true}}, 'Card'),
            h(
                'f-template-data-transfer',
                {
                    on: {
                        mounted: () => ctx.props.bind.cardElement.value.cardElement.mount('#' + cardElementContainerID)
                    },
                    scopedSlots: {
                        default: () => h('div', {attrs: {id: cardElementContainerID}})
                    }
                }
            ),
            h(
                VInput,
                {
                    props: {
                        errorMessages: ctx.props.bind.cardElement.value.errorMessages,
                        hideDetails: 'auto'
                    }
                }
            )
        ]
    }
}
