<template>
  <div>
    <div v-if="inspectingSubmission" class="splitted-analytics-table">
      <div class="splitted-analytics-table__items-list">
        <div class="splitted-analytics-table__items-header">
          <v-simple-checkbox color="blue_400" :value="areAllSubmissionsSelected" :ripple="false"
            @click="toggleAllSubmissionsSelection" />
          <span>Submissions</span>
        </div>
        <div :style="{ height: '800px', overflowY: 'scroll' }" ref="scrollContainer" @scroll="handleScroll">
          <div v-for="submission in filteredSubmissions" class="splitted-analytics-table__item"
            :class="{ 'splitted-analytics-table__item--inspecting': inspectingSubmission.submissionID === submission.submissionID }"
            :key="submission.submissionID" @click="setInspectingSubmission(submission)">
            <v-simple-checkbox color="blue_400"
              :value="!!selectedSubmissions.find(s => s.submissionID === submission.submissionID)" :ripple="false"
              @click.stop="toggleSubmissionSelection(submission)" />
            <div class="splitted-analytics-table__item__preview">
              <span class="splitted-analytics-table__item__title">
                <v-skeleton-loader v-if="submission.loading.value" type="heading" width="100" />
                <span v-else>{{ submission.submissionTitle }}</span>
              </span>
              <span class="splitted-analytics-table__item__subtitle">
                <v-skeleton-loader v-if="submission.loading.value" type="text" :width="Math.random() * 50 + 100" />
                <span v-else> {{ getDateFormated(submission.submissionDate) }} </span>
              </span>
            </div>
          </div>
          <f-circle-loader v-if="getIsSubmissionsListExtendingInProgress" size="3" />
        </div>
      </div>
      <div class="splitted-analytics-table__inspecting-item"
        :key="'inspecting-item-' + inspectingSubmission.submissionID + inspectingSubmission.loading.value">
        <div class="splitted-analytics-table__inspecting-item__actions" v-if="!selectedSubmissions.length">
          <f-hover-tooltip top text="Download submission" v-slot="{ on }">
            <v-btn class="v-btn--primary v-btn--thin-icon" v-on="on" icon :disabled="isAnyLoading"
              :href="inspectingSubmissionObjUrl" :download="inspectingSubmission.submissionTitle">
              <DownloadIcon />
            </v-btn>
          </f-hover-tooltip>
          <f-hover-tooltip top text="Print submission" v-slot="{ on }">
            <v-btn class="v-btn--primary v-btn--thin-icon" v-on="on" icon :disabled="isAnyLoading"
              @click="printInspectingSubmission">
              <PrinterIcon />
            </v-btn>
          </f-hover-tooltip>
          <f-hover-tooltip top text="Delete" v-slot="{ on }">
            <v-btn v-on="on" class="v-btn--error v-btn--thin-icon" icon :disabled="isAnyLoading"
              @click="deleteInspectingSubmission">
              <TrashIcon />
            </v-btn>
          </f-hover-tooltip>
        </div>
        <div class="splitted-analytics-table__inspecting-item__section">
          <p class="splitted-analytics-table__inspecting-item__section__header">
            <v-skeleton-loader v-if="inspectingSubmission.loading.value" width="100" type="heading" />
            <span v-else>Submission Details</span>
          </p>
          <span>
            <v-skeleton-loader v-if="inspectingSubmission.loading.value" :width="Math.random() * 50 + 150"
              type="text" />
            <span v-else>{{ inspectingSubmission.submissionDate.format('M/D/YYYY, hh:mm A') }}</span>
          </span>
        </div>
        <template v-if="inspectingSubmission.loading.value">
          <div v-for="fieldID in Object.keys($store.getters['edit/getFormJSON'].fields)"
            class="splitted-analytics-table__inspecting-item__section" :key="fieldID">
            <p class="splitted-analytics-table__inspecting-item__section__header">
              <v-skeleton-loader width="100" type="heading" />
            </p>
            <v-skeleton-loader :width="Math.random() * 50 + 150" type="text" />
          </div>
        </template>
        <template v-else>
          <template
            v-for="[fieldID, fieldData] in Object.entries($store.getters['submissions/getVersionedFormSources'][inspectingSubmission.publishedVersionID].fields).filter(([_fieldID]) => inspectingSubmission[_fieldID])">
            <InspectingItemSection :field-title="fieldData.title || '-'"
              :component-view="inspectingSubmission[fieldID].fieldTypeConst.ANALYTICS.VIEWS.FULL_SINGLE_ANSWER_VIEW"
              :submission-obj="inspectingSubmission[fieldID].submissionObj"
              :source-obj="inspectingSubmission[fieldID].sourceObj" :key="fieldID" />
          </template>
        </template>
      </div>
    </div>
    <span v-else>Nothing to display</span>
    <Teleport to="body" v-if="Object.values($store.getters['submissions/getVersionedFormSources']).length">
      <div id="analytics-print-view">
        <div v-for="(submission, index) in submissionsToPrint" :key="index"
          class="splitted-analytics-table splitted-analytics-table--print-view">
          <div class="splitted-analytics-table__inspecting-item__section">
            <p class="splitted-analytics-table__inspecting-item__section__header">
              <span>Submission Details</span>
            </p>
            <span>{{ submission.submissionDate.format('M/D/YYYY, hh:mm A') }}</span>
          </div>
          <template
            v-for="[fieldID, fieldData] in Object.entries($store.getters['submissions/getVersionedFormSources'][submission.publishedVersionID].fields).filter(([_fieldID]) => submission[_fieldID])">
            <InspectingItemSection :field-title="fieldData.title || '-'"
              :component-view="submission[fieldID].fieldTypeConst.ANALYTICS.VIEWS.FULL_SINGLE_ANSWER_VIEW"
              :submission-obj="submission[fieldID].submissionObj" :source-obj="submission[fieldID].sourceObj"
              :key="fieldID" />
          </template>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount, watch, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { mapGetters } from '@/xstore'
import Teleport from 'vue2-teleport';
import moment from "moment";


export default {
  name: "SplittedView",
  components: {
    DownloadIcon: () => import('vue-feather-icons/icons/DownloadIcon'),
    PrinterIcon: () => import('vue-feather-icons/icons/PrinterIcon'),
    TrashIcon: () => import('vue-feather-icons/icons/TrashIcon'),
    InspectingItemSection: () => import('./Elements/InspectingItemSection'),
    Teleport
  },
  props: {
    value: Array, // selectedSubmissions from AllResponsesView
    formattedSubmissions: Array,
    searchQuery: String,
    getIsSubmissionsListExtendingInProgress: Boolean,
  },
  computed: {
    ...mapGetters({
      isAnyLoading: 'submissions/isAnyLoading',
    }),
    ...mapGetters('user', [
      'getCurrentCognitoUser',
    ]),
  },
  methods: {
    handleScroll() {      
      const scrollContainer = this.$refs.scrollContainer;
      const scrollTop = scrollContainer.scrollTop;
      const clientHeight = scrollContainer.clientHeight;
      const scrollHeight = scrollContainer.scrollHeight;
      const tolerance = 1;
      //if (this.getCurrentCognitoUser.attributes.email === 'amroshahbari@gmail.com') {
        console.log('userEmail', this.getCurrentCognitoUser.attributes.email);
        if (scrollTop + clientHeight >= scrollHeight - tolerance) {
          this.$store.dispatch('submissions/loadMoreSubmissions', {
            formID: this.$route.params.formId,
          })
        }
      //}
    }
  },
  setup(props, { root, emit }) {

    const filteredSubmissions = computed(() => !props.searchQuery
      ? props.formattedSubmissions
      : props.formattedSubmissions
        .filter(submission => Object.values(submission)
          .filter(v => typeof v === 'object' && 'submissionObj' in v && v.fieldTypeConst.ANALYTICS.FILTERABLE)
          .some(v => v.fieldTypeConst.ANALYTICS.FILTER(v.submissionObj, props.searchQuery)))
    )

    /** SUBMISSIONS SELECTION MODULE **/
    const selectedSubmissions = computed({
      get: () => props.value,
      set: v => emit('input', v)
    })
    const areAllSubmissionsSelected = computed(() => selectedSubmissions.value.length === props.formattedSubmissions.length)
    const toggleSubmissionSelection = submission => {
      if (selectedSubmissions.value.find(s => s.submissionID === submission.submissionID))
        selectedSubmissions.value
          .splice(selectedSubmissions.value.findIndex(s => s.submissionID === submission.submissionID), 1)
      else
        selectedSubmissions.value.push(root.Utils.deepCloneObject(submission))
    }
    const toggleAllSubmissionsSelection = () => {
      if (areAllSubmissionsSelected.value)
        selectedSubmissions.value = []
      else
        selectedSubmissions.value = root.Utils.deepCloneObject(props.formattedSubmissions)
    }
    const submissionsSelectionModule = {
      areAllSubmissionsSelected,
      toggleSubmissionSelection,
      toggleAllSubmissionsSelection
    }
    const getDateFormated = computed(() => (date) => {
      let diffDays = moment().diff(date, 'days');
      if (diffDays >= 2 && diffDays <= 6) {
        return diffDays + ' days ago';
      } else {
        let otherDates = moment(date).fromNow();
        let getOtherDates = function () {
          return '[' + otherDates + ']';
        };
        return moment(date).calendar(null, {
          sameDay: '[Today, ]hh:mm a',
          lastDay: '[Yesterday, ]hh:mm a',
          lastWeek: getOtherDates,
          sameElse: 'MMM DD, YYYY'
        });
      }
    })


    /** INSPECTING SUBMISSION MODULE **/
    const onlyInspectingSubmissionIsPrinted = ref(false);
    const inspectingSubmissionID = ref(undefined)
    const inspectingSubmission = computed(() => props.formattedSubmissions.find(({ submissionID }) => submissionID === inspectingSubmissionID.value))
    const setInspectingSubmission = submission => inspectingSubmissionID.value = submission ? submission.submissionID : undefined
    watch(filteredSubmissions, v => {
      if (!v.find(({ submissionID }) => submissionID === inspectingSubmissionID.value))
        setInspectingSubmission(v[0])
    }, { deep: true })
    onBeforeMount(() => setInspectingSubmission(filteredSubmissions.value[0]))
    const deleteInspectingSubmission = () => {
      selectedSubmissions.value = selectedSubmissions.value.filter(s => s.submissionID !== inspectingSubmissionID.value)
      root.$store.dispatch('submissions/deleteSubmission', inspectingSubmissionID.value)
    }
    const inspectingSubmissionFieldHeaders = computed(() => {
      return [
        {
          title: 'Date',
          fieldId: 'submissionDate',
        },
        ...(Object.values(root.$store.getters['submissions/getVersionedFormSources']).length ?
          Object.entries(root.$store.getters['submissions/getVersionedFormSources'][inspectingSubmission.value.publishedVersionID].fields)
            .filter(([, fieldObj]) => root.constants.FIELD_FROM_TYPE[fieldObj.field_type].WITH_SUBMISSION)
            .map(([fieldId, fieldObj]) => ({ title: fieldObj.title, fieldId }))
          : []
        )
      ]
    }
    )
    const inspectingSubmissionToCsv = computed(() => {
      return [
        inspectingSubmissionFieldHeaders.value.map(headerObj => headerObj.title),
        inspectingSubmissionFieldHeaders.value.map(({ fieldId }) => {
          if (fieldId === "submissionDate") {
            return inspectingSubmission.value[fieldId].fromNow();
          } else {
            return inspectingSubmission.value[fieldId].fieldTypeConst.ANALYTICS.FORMAT_TO_CSV_EXPORT(inspectingSubmission.value[fieldId].submissionObj, inspectingSubmission.value[fieldId].sourceObj) || '-'
          }
        })
      ]
    })

    const printInspectingSubmission = () => {
      onlyInspectingSubmissionIsPrinted.value = true;
      root.$nextTick(() => window.print())
    }

    const submissionsToPrint = computed(() => {
      return onlyInspectingSubmissionIsPrinted.value ? [inspectingSubmission.value] : props.formattedSubmissions
    })

    const inspectingSubmissionObjUrl = computed(() => root.Utils.createCsvFromArray(inspectingSubmissionToCsv.value))
    const inspectingSubmissionModule = {
      inspectingSubmissionID,
      inspectingSubmission,
      deleteInspectingSubmission,
      setInspectingSubmission,
      inspectingSubmissionToCsv,
      inspectingSubmissionObjUrl,
      onlyInspectingSubmissionIsPrinted,
      printInspectingSubmission
    }

    const handleAfterPrint = () => onlyInspectingSubmissionIsPrinted.value = false

    onMounted(() => {
      document.addEventListener('afterprint', handleAfterPrint)
    })
    onBeforeUnmount(() => {
      document.removeEventListener('afterprint', handleAfterPrint)
    })

    return {
      filteredSubmissions,
      selectedSubmissions,
      submissionsToPrint,
      getDateFormated,
      ...submissionsSelectionModule,
      ...inspectingSubmissionModule
    }
  },
}
</script>

<style lang="scss">
.splitted-analytics-table {
  display: flex;
  border: 1px solid #E3E3E3;
  border-radius: 8px;

  &__items-list {
    min-width: 300px;
    border-right: inherit;

    .splitted-analytics-table__items-header,
    .splitted-analytics-table__item {
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid #E3E3E3;
      }
    }

    .splitted-analytics-table__items-header {
      align-items: center;
      column-gap: 19px;
      padding: 19px 14px;
      font-size: .8125rem;
      color: var(--v-grey_700-base);
    }

    .splitted-analytics-table__item {
      cursor: pointer;
      column-gap: 16px;
      padding: 18px 14px 12px;

      &__preview {
        display: flex;
        flex-direction: column;
        color: var(--v-grey_900-base);

        .splitted-analytics-table__item__title,
        .splitted-analytics-table__item__subtitle {
          font-size: .875rem;
          line-height: 140%;
        }

        .splitted-analytics-table__item__title {
          font-weight: 500;
          margin-bottom: 5px;
        }

        .splitted-analytics-table__item__subtitle {
          font-weight: 400;
        }
      }

      &.splitted-analytics-table__item--inspecting {
        background-color: #FAFAFA;
        color: var(--v-grey_700-base);
      }
    }
  }

  .splitted-analytics-table__inspecting-item {
    margin: 40px;
    position: relative;
    flex-grow: 1;

    &__actions {
      position: absolute;
      top: 0;
      right: 0;

      .v-btn {
        margin: 0 2px;
      }
    }

    .splitted-analytics-table__inspecting-item__section {
      &:not(:last-child) {
        margin-bottom: 26px;
      }

      .splitted-analytics-table__inspecting-item__section__header {
        font-size: .875rem;
        line-height: 140%;
        color: var(--v-grey_500-base);
        margin-bottom: 10px !important;

        &--builtin {
          margin-bottom: 0;
        }
      }
    }
  }
}

#analytics-print-view {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;

  .splitted-analytics-table--print-view {
    padding: 40px;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .splitted-analytics-table__inspecting-item__section {
      &:not(:last-child) {
        margin-bottom: 26px;
      }

      .splitted-analytics-table__inspecting-item__section__header {
        font-size: .875rem;
        line-height: 140%;
        color: var(--v-grey_500-base);
        margin-bottom: 10px !important;
      }

      .switcher {
        display: none;
      }
    }
  }
}

.circle-loader-container {
  height: 60px !important;
}
</style>