var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-parameters-option",
    on: {
      "click": _vm.navigate
    }
  }, [_c('div', {
    staticClass: "form-parameters-option-header d-flex align-center cursor-pointer"
  }, [_c('span', {
    staticClass: "bluish_gray_500--text"
  }, [_vm._t("default")], 2), _c('v-spacer'), _c('ChevronRightIcon', {
    attrs: {
      "size": "20"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }