var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "78",
      "height": "55",
      "viewBox": "0 0 78 55",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.739136",
      "y": "1.47852",
      "width": "75.913",
      "height": "52.0435",
      "rx": "7.45652",
      "fill": "#F5F6F8"
    }
  }), _c('rect', {
    attrs: {
      "x": "28.75",
      "y": "18.2168",
      "width": "19.8913",
      "height": "2.65217",
      "rx": "1.32609",
      "fill": "currentColor"
    }
  }), _c('rect', {
    attrs: {
      "x": "28.75",
      "y": "23.5215",
      "width": "19.8913",
      "height": "7.95652",
      "rx": "1.32609",
      "fill": "currentColor"
    }
  }), _c('rect', {
    attrs: {
      "x": "35.3805",
      "y": "34.1289",
      "width": "6.63043",
      "height": "2.65217",
      "rx": "1.32609",
      "fill": "currentColor"
    }
  }), _c('rect', {
    staticClass: "border",
    attrs: {
      "stroke-width": "1",
      "x": "0.739136",
      "y": "1.47852",
      "width": "75.913",
      "height": "52.0435",
      "rx": "7.45652",
      "stroke": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }