var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_6300_39994)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M30.4211 0H3.57895C1.60235 0 0 1.69137 0 3.77778V6.2963C0 8.38271 1.60235 10.0741 3.57895 10.0741H30.4211C32.3977 10.0741 34 8.38271 34 6.2963V3.77778C34 1.69137 32.3977 0 30.4211 0Z",
      "fill": "url(#paint0_radial_6300_39994)"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.4216 13.2227H20.8778C18.9012 13.2227 17.2988 14.914 17.2988 17.0004V19.519C17.2988 21.6054 18.9012 23.2967 20.8778 23.2967H30.4216C32.3982 23.2967 34.0006 21.6054 34.0006 19.519V17.0004C34.0006 14.914 32.3982 13.2227 30.4216 13.2227Z",
      "fill": "#C55BFC"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.1228 23.9258H3.57895C1.60235 23.9258 0 25.6172 0 27.7036V30.2221C0 32.3085 1.60235 33.9999 3.57895 33.9999H13.1228C15.0994 33.9999 16.7018 32.3085 16.7018 30.2221V27.7036C16.7018 25.6172 15.0994 23.9258 13.1228 23.9258Z",
      "fill": "#2FBBEB"
    }
  })]), _c('defs', [_c('radialGradient', {
    attrs: {
      "id": "paint0_radial_6300_39994",
      "cx": "0",
      "cy": "0",
      "r": "1",
      "gradientUnits": "userSpaceOnUse",
      "gradientTransform": "translate(17 5.03704) rotate(90) scale(5.03704 17)"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3F66F0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#4D72F5"
    }
  })], 1), _c('clipPath', {
    attrs: {
      "id": "clip0_6300_39994"
    }
  }, [_c('rect', {
    attrs: {
      "width": "34",
      "height": "34",
      "fill": "white"
    }
  })])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }