var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['star-rating-field-view', _vm.isEmojiScale && 'star-rating-field-view--emoji-scale', _vm.isScaleIcon && 'star-rating-field-view--scale-icon']
  }, [_c('div', {
    staticClass: "star-rating-field-view__layout"
  }, [_vm.isEmojiScale ? _vm._l(_vm.fieldConst.BASE_PROPERTIES.emoji_scale.input_group.default.sets[_vm.fieldProperties.emoji_scale], function (emoji, index) {
    return _c('div', {
      key: 'emoji-scale' + _vm.fieldProperties.emoji_scale + index,
      staticClass: "star-rating-field-view__item-wrapper"
    }, [_c('div', {
      staticClass: "star-rating-field-view__item",
      class: index === _vm.model && 'star-rating-field-view__item--selected',
      on: {
        "click": function ($event) {
          return _vm.$emit('select-option', index);
        }
      }
    }, [_vm._v(_vm._s(emoji))])]);
  }) : _vm.isScaleIcon ? _vm._l(5, function (index) {
    return _c('div', {
      key: 'scale-icon' + _vm.fieldProperties.scale_icon + (index - 1),
      staticClass: "star-rating-field-view__item-wrapper"
    }, [_c('div', {
      staticClass: "star-rating-field-view__item",
      on: {
        "click": function ($event) {
          return _vm.$emit('select-option', index - 1);
        }
      }
    }, [_c('LottieIconPlayer', {
      attrs: {
        "json": _vm.fieldConst.BASE_PROPERTIES.scale_icon.input_group.default.emojis[_vm.fieldProperties.scale_icon],
        "active": _vm.model !== null && index - 1 <= _vm.model
      }
    })], 1)]);
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }