<template>
    <div class="interactive-form-submit-footer">
        <div v-if="getFormSourceJSON.settings.general_settings.formsly_branding"
             @click="$router.push({name: constants.PAGE_NAME_DASHBOARD})"
             class="interactive-form-branding noselect"
             :style="{cursor: 'pointer'}"
        >
            <span
                :style="{color: backgroundColorIsLight ? '#000' : '#fff'}"
            >Crafted with</span>
            <LogoComponent :light="!backgroundColorIsLight"/>
        </div>
        <div v-if="isMainPage && getFormSourceJSON.settings.general_settings.navigation_arrows" class="interactive-form-navigation-arrows">
            <div
                    :class="[
                        'interactive-form-navigation-arrows__btn',
                        (disabled || getCurrentMainPageIndex === 0) && 'interactive-form-navigation-arrows__btn--disabled'
                    ]"
                    @click="() => (disabled || getCurrentMainPageIndex === 0) || goToPreviousPage()"
            >
                <ChevronUpIcon/>
            </div>
            <v-divider vertical/>
            <div
                    :class="[
                        'interactive-form-navigation-arrows__btn',
                        (disabled || getFormPages.length - 1 === getCurrentMainPageIndex) && 'interactive-form-navigation-arrows__btn--disabled'
                    ]"
                    @click="() => (disabled || getFormPages.length - 1 === getCurrentMainPageIndex) || goToNextPage()"
            >
                <ChevronDownIcon/>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMapActions, useMapGetters } from '@/xstore'
    import { inject } from '@vue/composition-api'
    import constants from "@/constants/constants";

    export default {
        name: "NavigationArrows",
        components: {
            ChevronUpIcon: () => import('vue-feather-icons/icons/ChevronUpIcon'),
            ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon'),
            LogoComponent: () => import('@/components/Elements/LogoComponent')
        },
        props: {
            disabled: Boolean,
        },
        setup(props, {root}) {
            return {
                goToDashboard: () => root.$router.push({name: constants.PAGE_NAME_DASHBOARD}),
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getFormSourceJSON',
                    'isMainPage',
                    'getCurrentMainPageIndex',
                    'getFormPages'
                ]),
                ...useMapGetters(inject('formSubmitStoreModulePath') + '/FSP', {
                    backgroundColorIsLight: constants.FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.GETTERS.RAW.name,
                }),
                ...useMapActions(inject('formSubmitStoreModulePath'), [
                    'goToPreviousPage',
                    'goToNextPage'
                ])
            }
        }
    }
</script>

<style lang="scss" scoped>
    .interactive-form-submit-footer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 4px;
        width: 100%;
        bottom: 30px;
        padding: 0 20px;

        .interactive-form-branding {
            display: flex;
            align-items: center;
            font-size: 11px;

            --FSP__Secondary__InteractiveFormBranding__color--default: calc(var(--FSP__SubPrimary__BackgroundColorIsLight--BINARY) * 255);

            color: rgb(var(--FSP__Secondary__InteractiveFormBranding__color--default), var(--FSP__Secondary__InteractiveFormBranding__color--default), var(--FSP__Secondary__InteractiveFormBranding__color--default));
            gap: 12px;

            img {
                width: 86px;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .interactive-form-navigation-arrows {
            border-radius: var(--FSP__Primary__BorderRadius--PX);
            overflow: hidden;
            box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.08);
            display: flex;
            height: 34px;

            .v-divider {
                border-color: #e9e9e9;
                margin: 0 !important;
            }

            &__btn {
                display: flex;
                align-items: center;
                padding: 0 11px;
                position: relative;
                cursor: pointer;
                background: #FFFFFF;
                box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.08);

                svg {
                    width: 28px;
                    height: 28px;
                    z-index: 1;
                    color: #595959;
                }

                &:before {
                    background-color: white;
                }

                &:before, &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }

                &:not(.interactive-form-navigation-arrows__btn--disabled):hover:after {
                    background-color: rgba(var(--FSP__Primary__ButtonBackgroundColor--RGB), 0.1);
                }

                &--disabled {
                    cursor: default;

                    svg {
                        color: var(--v-gray_300-base);
                    }
                }
            }
        }
    }

    @include form-responsive(sm-and-down) {

      .interactive-form-submit-footer {
        justify-content: space-between;
        column-gap: 0;
        background-color: var(--FSP__SubPrimary__AverageBackgroundImageColor);
        bottom: 0;
        padding: 13px 20px;
      }
      .interactive-page-scroller--homepage {
        .interactive-form-submit-footer {
            display: none;
        }
      }
    }
</style>