var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "red_400--text"
  }, [_vm._v(" We can't set this condition since it's a required question. "), _c('v-menu', {
    attrs: {
      "max-width": "450",
      "min-width": "450",
      "close-on-content-click": false,
      "auto": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "blue_400--text cursor-pointer"
        }, on), [_vm._v(" Edit requirements ")])];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-card', [_c('v-card-text', {
          staticClass: "pa-6"
        }, [_c('div', {
          staticClass: "mb-3 nowrap-ellipsis-text"
        }, [_c('span', {
          staticClass: "grey_700--text"
        }, [_vm._v(" " + _vm._s(_vm.getTargetedAndConditionedFields[_vm.fieldId].formattedLocation) + " ")]), _c('span', {
          staticClass: "subtitle-1 bluish_gray_400--text"
        }, [_vm._v(" " + _vm._s(_vm.getTargetedAndConditionedFields[_vm.fieldId].fieldObj.title) + " ")])]), _c('div', [_c('span', {
          staticClass: "subtitle-1 bluish_gray_400--text mr-4"
        }, [_vm._v("Required")]), _c('f-switch', {
          model: {
            value: _vm.requiredModel,
            callback: function ($$v) {
              _vm.requiredModel = $$v;
            },
            expression: "requiredModel"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }