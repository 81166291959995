var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.submissionObj.value ? _c('span', [_vm._v("-")]) : _vm.isTypingAllowed ? _c('span', [_vm._v(_vm._s(_vm.submissionObj.value))]) : _vm.signatureDataURL ? _c('img', {
    staticStyle: {
      "{height": "34px",
      "contain": "style",
      "position": "center",
      "max-width": "20%}"
    },
    attrs: {
      "src": _vm.signatureDataURL
    }
  }) : _c('f-circle-loader', {
    staticStyle: {
      "justify-content": "left",
      "padding-left": "110px"
    },
    attrs: {
      "size": "3"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }