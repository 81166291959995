import { SMAGableExtensions } from '@/xstore/utils/smagable'

export default ({useExtension}) => {
    {
        const state = {
            cover_image_preview: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        actions: {
            setNewCoverImage: ({commit, parentDispatch}, newImage) => {
                parentDispatch('changeFormAttrs', {cover_image: newImage})
                commit('SET_COVER_IMAGE_PREVIEW')
            }
        },
        getters: {
            getCurrentCoverImage: ({getters, parentGetters}) => getters.getCoverImagePreview || parentGetters.getFormJSON?.cover_image,
            getInitialCoverImage: ({parentGetters}) => parentGetters.getFormJSON?.cover_image,
        }
    }
}