var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": ""
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.4268 19.807L21.0089 14.8125V3.9375C21.0089 3.52266 20.6737 3.1875 20.2589 3.1875H3.75886C3.34402 3.1875 3.00886 3.52266 3.00886 3.9375V14.8125L1.5909 19.807C1.41511 20.2969 1.77605 20.8125 2.29636 20.8125H21.7214C22.2417 20.8125 22.6026 20.2969 22.4268 19.807ZM4.69636 4.875H19.3214V14.1328H4.69636V4.875V4.875ZM10.0471 19.125L10.237 18.2578H13.7596L13.9495 19.125H10.0471V19.125ZM15.2971 19.125L14.8495 17.093C14.8307 17.0062 14.7534 16.9453 14.6667 16.9453H9.3323C9.24324 16.9453 9.16824 17.0062 9.14949 17.093L8.70183 19.125H3.53855L4.52996 15.6328H19.4878L20.4792 19.125H15.2971Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }