<template>
  <div :style="styles">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'InteractiveHomePageDisplayManager',
    props: {
      pageObj: {
        type: Object,
        required: true
      }
    },
    computed: {
      styles() {
        return {
          display: 'contents',
          '--InteractiveHomePage_TitleColor': this.pageObj.styles.TITLE_COLOR,
          '--InteractiveHomePage_DescriptionColor': this.pageObj.styles.DESCRIPTION_COLOR,
          '--InteractiveHomePage_ButtonBackgroundColor': this.pageObj.styles.BUTTON_BACKGROUND_COLOR,
          '--InteractiveHomePage_ButtonTextColor': this.pageObj.styles.BUTTON_TEXT_COLOR,
        }
      }
    }
  }
</script>
