<template>
    <div class="emoji-set-select-property">
        <div
                v-for="(set, index) in inputConst.sets"
                class="emoji-set-select-property__item background-gray_100-selectable"
                :class="inputValue === index && 'background-gray_100-selectable--selected'"
                :key="index"

                @click="input(index)"
        >
            <span
                    class="emoji-set-select-property__emoji"
                    v-for="emoji in set"
                    :key="emoji + index"
            >{{emoji}}</span>
        </div>
    </div>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "EmojiSetSelect",
        mixins: [field_property_input_mixin]
    }
</script>

<style lang="scss">
    .emoji-set-select-property {
        &__item {
            display: flex;
            align-items: center;
            height: 45px;
            padding: 0 20px;
            cursor: pointer;
            margin: 5px 0;

            &:before {
                border-radius: 100px;
            }

            & > * {
                width: 20%;
                text-align: center;
            }
        }

        &__emoji {
            font-size: 24px;
        }
    }
</style>