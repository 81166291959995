<script>
  export default {
    name: 'LoginLayoutWithImage',
  }
</script>

<template>
  <v-row align="center">
    <v-col
      cols="12"
      md="6"
      lg="6"
      xl="6"
    >
      <v-row>
        <v-container style="max-width: 450px">
          <v-row class="mb-5">
            <v-col>
              <p class="text-h4 font-weight-bold">
                <slot name="title"></slot>
              </p>
            </v-col>
          </v-row>
          <v-row
            v-if="$slots.description"
            class="mt-n6 mb-2"
          >
            <v-col>
              <p class="text--secondary font-weight-medium">
                <slot name="description"></slot>
              </p>
            </v-col>
          </v-row>
          <slot name="form"></slot>
          <v-row justify="center">
            <p class="ma-4 font-weight-light">
              <slot name="jump-to"></slot>
            </p>
          </v-row>
          <slot name="additional-bottom"></slot>
        </v-container>
      </v-row>
    </v-col>
    <v-col
      v-if="$router.currentRoute.path === '/signup_appsumo'"
      cols="12"
      md="6"
      lg="6"
      xl="6"
      class="d-none d-md-flex justify-center"
    >
      <div>
        <img
          draggable="false"
          style="max-width: 50vw"
          src="/images/LoginImgAppSumo.png"
        />
      </div>
    </v-col>
    <v-col
      v-else
      cols="12"
      md="6"
      lg="6"
      xl="6"
      class="d-none d-md-flex justify-end"
    >
      <div class="mr-n6">
        <img
          draggable="false"
          style="max-width: 50vw"
          src="/images/LoginImg.png"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style scoped></style>
