var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-scale-transition', {
    attrs: {
      "origin": "center"
    }
  }, [_vm.getCurrentLogoURL ? _c('div', {
    class: ['static-form-logo-badge', _vm.getFormJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE && 'visible-logo', _vm.getFormJSON.layout_logo_style === _vm.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE && 'invisible-logo']
  }, [_c('v-menu', {
    attrs: {
      "content-class": "dropdown-menu-list",
      "min-width": "200",
      "nudge-bottom": "8",
      "nudge-left": "164",
      "offset-y": "",
      "offset-x": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          value = _ref.value;
        return [_c('div', {
          staticClass: "static-form-logo-badge__background"
        }, [_c('f-template-data-transfer', {
          attrs: {
            "image-loaded": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var dataStash = _ref2.dataStash;
              return [_c('v-hover', {
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref3) {
                    var hover = _ref3.hover;
                    return [_c('v-img', {
                      class: ['static-form-logo-badge__image', value && 'static-form-logo-badge__image--edit-menu-active', dataStash.imageLoaded && hover && !value && 'static-form-logo-badge__image--edit-overlay-active'],
                      attrs: {
                        "src": _vm.getCurrentLogoURL
                      },
                      on: {
                        "load": function ($event) {
                          dataStash.imageLoaded = true;
                        }
                      },
                      scopedSlots: _vm._u([{
                        key: "placeholder",
                        fn: function () {
                          return [_c('f-circle-loader', {
                            attrs: {
                              "size": "3"
                            }
                          })];
                        },
                        proxy: true
                      }, {
                        key: "default",
                        fn: function () {
                          return [dataStash.imageLoaded && hover && !value ? _c('div', _vm._g({
                            staticClass: "static-form-logo-badge__image-edit-overlay"
                          }, on), [_c('Edit2Icon'), _c('p', [_vm._v("Edit")])], 1) : _vm._e()];
                        },
                        proxy: true
                      }], null, true)
                    })];
                  }
                }], null, true)
              })];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": _vm.addLogo
          }
        }, [_c('v-list-item-icon', [_c('Edit2Icon', {
          attrs: {
            "size": "16"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Change logo")])], 1)], 1), _c('v-list-item', {
          on: {
            "click": _vm.removeLogo
          }
        }, [_c('v-list-item-icon', [_c('TrashIcon', {
          attrs: {
            "size": "16"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Remove")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 1220117073)
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }