<template>
    <div v-if="submitButtonObj" class="static-main-page__footer-wrapper"
         :class="[getFormJSON.layout_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN && 'static-main-page__footer-wrapper--full-screen']">
        <div class="static-main-page-footer">
            <v-hover v-slot="{hover}">
                <div class="static-page-action-button-padding-wrapper">
                    <div
                            @click="enableEditing"
                            v-click-outside="disableEditing"
                            class="static-page-action-button-wrapper"
                            :class="(isEditingActive || hover) && 'static-page-action-button-wrapper--editing-active'"
                    >
                        <div class="static-page-action-button">
                            <f-simple-contenteditable-input
                                    v-model="submitButtonObj.properties.button_text"
                                    class="static-page-action-button__text static-page-action-button__text--editable"
                                    placeholder="Submit"
                                    ref="actionButtonTextInput"
                                    prevent-highlighting
                                    overflow-wrap
                                    :mask="/^.{0,20}$/g"
                                    :readonly="!isBtnTextEditing"
                                    @dblclick="editBtnText"
                                    @keydown.enter="confirmBtnText"
                                    v-click-outside="confirmBtnText"
                            />
                        </div>
                        <v-slide-y-transition mode="out-in">
                            <div
                                    v-if="(isEditingActive || hover) && !isPropertiesVisible"
                                    class="static-main-page-footer__popup-actions"
                            >
                                <f-hover-tooltip
                                        top
                                        text="Edit button properties here"
                                        v-slot="{on}"
                                >
                                    <v-btn
                                            v-on="on"
                                            @click="openProperties"
                                            class="v-btn--primary grey_900--text"
                                            icon
                                    >
                                        <Edit3Icon stroke-width="1" size="20"/>
                                    </v-btn>
                                </f-hover-tooltip>
                            </div>
                            <EditableFieldPropertiesWindowTemplate
                                    v-else-if="isEditingActive && isPropertiesVisible"
                                    class="static-main-page-footer__popup-properties"
                                    @close-properties="closeProperties"
                            >
                                <EditableFieldPropertiesListGenerator
                                        :field-properties-obj="submitButtonObj.properties"
                                        :field-base-properties="constants.STATIC_PAGE_SUBMIT_BUTTON.BASE_PROPERTIES"
                                />
                            </EditableFieldPropertiesWindowTemplate>
                        </v-slide-y-transition>
                    </div>
                </div>
            </v-hover>
          <div
              class="static-main-page-footer__disclaimer"
          >
            Never submit passwords through Forms
          </div>
        </div>
        <div class="static-main-page-footer-transition-helper"></div>
    </div>
</template>

<script>
    import {Edit3Icon} from 'vue-feather-icons'
    import {debounce} from 'lodash'
    import {mapGetters} from '@/xstore'

    export default {
        name: "StaticMainPageSubmitButtonComponent",
        components: {
            Edit3Icon,
            EditableFieldPropertiesWindowTemplate: () => import('@/components/Fields/EditableField/EditableFieldProperties/EditableFieldPropertiesWindowTemplate'),
            EditableFieldPropertiesListGenerator: () => import('@/components/Fields/EditableField/EditableFieldProperties/EditableFieldPropertiesListGenerator'),
        },
        props: {
            pageObj: Object
        },
        data: () => ({
            submitButtonObj: undefined,
            isEditingActive: false,
            isBtnTextEditing: false,
            isPropertiesVisible: false
        }),
        computed: {
            ...mapGetters('edit', [
                'getFormJSON'
            ]),
        },
        watch: {
            submitButtonObj: {
                deep: true,
                handler: debounce(function (newValue, oldValue) {
                    if (oldValue !== undefined)
                        this.$store.dispatch('edit/pages/changeCurrentFormPage', {
                            ...this.pageObj,
                            submit_button: this.Utils.deepCloneObject(newValue)
                        })
                }, 500)
            }
        },
        created() {
            this.submitButtonObj = this.Utils.deepCloneObject(this.pageObj.submit_button)
        },
        methods: {
            editBtnText() {
                this.isBtnTextEditing = true
                this.$refs.actionButtonTextInput.focus()
            },
            confirmBtnText() {
                this.isBtnTextEditing = false
            },
            enableEditing() {
                this.isEditingActive = true
            },
            disableEditing() {
                this.isEditingActive = false
                this.closeProperties()
            },

            openProperties() {
                this.isPropertiesVisible = true
            },
            closeProperties() {
                this.isPropertiesVisible = false
            },
        }
    }
</script>