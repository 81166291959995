var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-choose-plan-billing-period-switcher"
  }, [_c('span', {
    class: ['checkout-choose-plan-billing-period-switcher__label', _vm.value === 'month' && 'checkout-choose-plan-billing-period-switcher__label--active-month'],
    on: {
      "click": function ($event) {
        return _vm.$emit('input', 'month');
      }
    }
  }, [_vm._v("Monthly")]), _c('f-switch', {
    staticClass: "checkout-choose-plan-billing-period-switcher__switch",
    attrs: {
      "value": _vm.value === 'year'
    },
    on: {
      "input": function (v) {
        return _vm.$emit('input', v ? 'year' : 'month');
      }
    }
  }), _c('span', {
    class: ['checkout-choose-plan-billing-period-switcher__label', _vm.value === 'year' && 'checkout-choose-plan-billing-period-switcher__label--active-year'],
    on: {
      "click": function ($event) {
        return _vm.$emit('input', 'year');
      }
    }
  }, [_vm._v("Yearly "), _c('span', {
    staticClass: "checkout-choose-plan-billing-period-switcher__discount"
  }, [_vm._v("-20%")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }