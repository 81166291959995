<template>
    <span>{{submissionObj.value === null ? '-' : submissionObj.value}}</span>
</template>

<script>
    export default {
        name: "GenericTextSingleAnswerFieldView",
        props: {
            submissionObj: Object
        }
    }
</script>]