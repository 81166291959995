var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "#1976D2"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.125 17.4453H22.8594V14.6016C22.8594 13.8167 23.4964 13.1797 24.2812 13.1797H25.7031V9.625H22.8594C20.5033 9.625 18.5938 11.5346 18.5938 13.8906V17.4453H15.75V21H18.5938V32.375H22.8594V21H25.7031L27.125 17.4453Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }