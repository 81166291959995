var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-list-item', _vm._g({}, on), [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Rename")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Rename Form \"" + _vm._s(_vm.currentName) + "\" "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('XIcon')], 1)], 1), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "v-card__subheader"
  }, [_vm._v("New Name")]), _c('v-text-field', {
    directives: [{
      name: "input-mask",
      rawName: "v-input-mask",
      value: _vm.Utils.getRangedLetterOrNumberRegex({
        expression: _vm.constants.ANY_LETTER_OR_NUMBER_REGEX,
        from: 0,
        to: 75,
        isSpaceAllowed: true
      }),
      expression: "Utils.getRangedLetterOrNumberRegex({\n                            expression: constants.ANY_LETTER_OR_NUMBER_REGEX,\n                            from: 0,\n                            to: 75,\n                            isSpaceAllowed: true,\n                        })"
    }],
    attrs: {
      "placeholder": "Enter new form name",
      "outlined": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }],
      "hide-details": "auto"
    },
    on: {
      "keydown": function ($event) {
        return _vm.$refs.form.resetValidation();
      }
    },
    model: {
      value: _vm.newName,
      callback: function ($$v) {
        _vm.newName = $$v;
      },
      expression: "newName"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": _vm.renameForm
    }
  }, [_vm._v(" Rename ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }