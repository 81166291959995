<template>
    <v-menu
            nudge-top="15"
            nudge-right="75"
            open-on-hover
            top
            eager
            transition="slide-y-reverse-transition"
            open-delay="200"
    >
        <template v-slot:activator="{ on }">
            <span
                    v-on="on"
                    class="cursor-pointer"
            >View</span>
        </template>
        <v-card class="pa-6">
            <FullSingleAnswer
                    :submissionObj="submissionObj"
                    :sourceObj="sourceObj"
            />
        </v-card>

    </v-menu>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__Matrix",
        components: {
            FullSingleAnswer: () => import('./FullSingleAnswer'),
        },
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
        computed: {
            answers() {
                return Object.values(this.submissionObj.value).filter(item => item.length).length ?
                    Object.values(this.submissionObj.value).filter(item => item.length).map(item => item[0]).join(', ')
                    : '-';
            },
        },
    }
</script>

<style scoped>

</style>