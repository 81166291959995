import { useMapActions, useMapGetters } from '@/xstore'
import {debounce} from 'lodash'

const deepEqual = require('deep-equal')

import { computed, inject } from '@vue/composition-api'

export default {
    components: {
        GenericField: () => import('@/components/FormGenericComponents/GenericField/GenericField'),
        SubmittableFieldViewsGenerator: () => import('@/components/FormSubmit/SubmittableFields/SubmittableFieldViewsGenerator')
    },
    props: {
        fieldId: {
            type: String,
            required: true
        },
        fieldWidth: Number
    },
    setup(props) {
        const $v = inject('fieldsValidation')

        const isErrorState = computed(() => $v?.value?.[props.fieldId]?.$error)

        return {
            isErrorState,

            ...useMapGetters(inject('formSubmitStoreModulePath'), [
                'getFormSourceJSON'
            ]),
            ...useMapGetters(inject('formSubmitStoreModulePath') + '/submission', [
                'getFormSubmission'
            ]),
            ...useMapActions(inject('formSubmitStoreModulePath') + '/submission', [
                'updateFormSubmissionField'
            ])
        }
    },
    data: vm => ({
        submissionObj: vm.fieldId in vm.getFormSubmission ? vm.Utils.deepCloneObject(vm.getFormSubmission[vm.fieldId]) : undefined
    }),
    computed: {
        sourceObj() {
            return this.getFormSourceJSON.fields[this.fieldId]
        },
        fieldTypeConst() {
            return this.constants.FIELD_FROM_TYPE[this.sourceObj.field_type]
        },
    },
    watch: {
        submissionObj: {
            deep: true,
            handler: debounce(function (v) {
                if (!deepEqual(v, this.getFormSubmission[this.fieldId]))
                    this.updateFormSubmissionField({
                        fieldID: this.fieldId,
                        updatedSubmission: this.Utils.deepCloneObject(v)
                    })
            }, 300, {maxWait: 1000})
        }
    }
}