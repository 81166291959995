var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveFieldConstructorTemplate', {
    attrs: {
      "field-number": _vm.getCurrentMainPageIndex + 1,
      "field-type-const": _vm.fieldTypeConst,
      "editable": ""
    },
    scopedSlots: _vm._u([{
      key: "field-view",
      fn: function () {
        return [_c('GenericStoreDialog', {
          attrs: {
            "store-module-path": "edit/fields/confirmation_dialog"
          }
        }), _c('GenericField', {
          attrs: {
            "modifiers": ['interactive', 'editable'],
            "field-id": _vm.fieldId,
            "field-obj": _vm.fieldObj,
            "field-type-const": _vm.fieldTypeConst
          }
        }, [_c('EditableFieldViewsGenerator', {
          attrs: {
            "field-obj": _vm.fieldObj,
            "field-id": _vm.fieldId
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "extension",
      fn: function () {
        return [_c('InteractiveFieldActions', {
          attrs: {
            "field-id": _vm.fieldId,
            "field-obj": _vm.fieldObj
          },
          on: {
            "remove-field": _vm.removePageWithField,
            "change-field-type": _vm.changeFieldType,
            "duplicate-field": _vm.duplicateField
          }
        })];
      },
      proxy: true
    }, {
      key: "button-content",
      fn: function () {
        return [_vm._t("button-content", function () {
          return [_c('span', {
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(_vm.actionButtonName))]), _c('ChevronRightIcon')];
        })];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }