var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "conditional-logic-expression conditional-logic-expression--String"
  }, [_c('v-combobox', {
    directives: [{
      name: "input-mask",
      rawName: "v-input-mask",
      value: _vm.regex,
      expression: "regex"
    }],
    staticClass: "conditional-logic-expression__expression-input",
    attrs: {
      "placeholder": "Add statement",
      "error-messages": _vm.stringErrors,
      "multiple": "",
      "outlined": "",
      "append-icon": "",
      "deletable-chips": "",
      "dense": ""
    },
    on: {
      "change": _vm.handleStatementsCount,
      "update:search-input": _vm.updateStringInputModel
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('f-select', {
          staticClass: "conditional-logic-expression__operator-picker",
          attrs: {
            "items": _vm.expressionOperators,
            "item-text": function (item) {
              return _vm.expressionObj.options.multiple_statements ? item.text_multiple : item.text_single;
            },
            "item-value": function (item) {
              return item.value;
            },
            "menu-props": {
              nudgeBottom: 54
            },
            "hide-details": "",
            "solo": "",
            "flat": "",
            "dense": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          },
          model: {
            value: _vm.expressionObj.operator,
            callback: function ($$v) {
              _vm.$set(_vm.expressionObj, "operator", $$v);
            },
            expression: "expressionObj.operator"
          }
        })];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          key: JSON.stringify(item),
          staticClass: "my-1",
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item) + " "), _c('XIcon', {
          staticClass: "cursor-pointer ml-1",
          attrs: {
            "size": "12"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.expressionObj.statement,
      callback: function ($$v) {
        _vm.$set(_vm.expressionObj, "statement", $$v);
      },
      expression: "expressionObj.statement"
    }
  }), _vm.is_case_sensitive ? _c('v-checkbox', {
    staticClass: "mb-2",
    attrs: {
      "ripple": false,
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "bluish_gray_500--text d-flex align-center ml-2"
        }, [_vm._v(" Capital case sensitive ")]), _c('f-hover-tooltip', {
          attrs: {
            "right": "",
            "text": "Allowing this will create different responses for the same word (Ex: 'Joe' and 'joe')"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('InfoIcon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "size": "16"
                }
              }, on))];
            }
          }], null, false, 2354226441)
        })];
      },
      proxy: true
    }], null, false, 773252208),
    model: {
      value: _vm.expressionObj.options.case_sensitive,
      callback: function ($$v) {
        _vm.$set(_vm.expressionObj.options, "case_sensitive", $$v);
      },
      expression: "expressionObj.options.case_sensitive"
    }
  }) : _vm._e(), _c('v-checkbox', {
    attrs: {
      "ripple": false,
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "bluish_gray_500--text d-flex align-center ml-2"
        }, [_vm._v(" Multiple options ")]), _c('f-hover-tooltip', {
          attrs: {
            "right": "",
            "text": "This allows you to collect more response options"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('InfoIcon', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "size": "16"
                }
              }, on))];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.expressionObj.options.multiple_statements,
      callback: function ($$v) {
        _vm.$set(_vm.expressionObj.options, "multiple_statements", $$v);
      },
      expression: "expressionObj.options.multiple_statements"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }