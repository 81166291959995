<template>
    <v-text-field
            class="f-solo-search-bar"
            v-bind="bind"
            v-on="$listeners"
    >
        <template #prepend-inner>
            <SearchIcon/>
        </template>
        <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData"/>
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName"/>
        </template>
    </v-text-field>
</template>

<script>
    import { kebabCase } from 'lodash'

    export default {
        name: "SoloSearchInput",
        inheritAttrs: false,
        components: {
            SearchIcon: () => import('vue-feather-icons/icons/SearchIcon')
        },
        computed: {
            defaultBind() {
                return {
                    rounded: true,
                    'hide-details': true
                }
            },
            bind() {
                return {
                    ...Object.fromEntries(Object.entries(this.defaultBind).map(([name, value]) => [kebabCase(name), value])),
                    ...Object.fromEntries(Object.entries(this.$attrs).map(([name, value]) => [kebabCase(name), value]))
                }
            }
        }
    }
</script>

<style lang="scss">
    .f-solo-search-bar.v-text-field {
        .v-input__control {
            .v-input__slot {
                background-color: #f9f9f9;
                padding: 0 13px;

                .v-input__prepend-inner {
                    svg {
                        width: 21px;
                        height: 21px;
                        color: var(--v-gray_400-base);

                        &.feather-search {
                            transform: matrix(-1, 0, 0, 1, 0, 0);
                        }

                        margin-right: 4px;
                    }
                }

                .v-text-field__slot {
                    input {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                        color: var(--v-gray_400-base);
                    }
                }
            }
        }
    }
</style>