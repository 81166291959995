var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "value": _vm.sourceObj.properties.allow_typing ? _vm.submissionObj.value : '',
      "placeholder": _vm.isPaintingStarted && !_vm.sourceObj.properties.allow_typing ? '' : _vm.sourceObj.content.placeholder,
      "allow-typing": _vm.sourceObj.properties.allow_typing
    },
    on: {
      "input": function ($event) {
        _vm.sourceObj.properties.allow_typing ? _vm.setValue($event) : '';
      }
    },
    scopedSlots: _vm._u([{
      key: "pad",
      fn: function () {
        return [!_vm.sourceObj.properties.allow_typing ? _c('VueSignaturePad', {
          ref: "signaturePad",
          staticClass: "signature-field-view__layout-canvas",
          attrs: {
            "width": "100%",
            "height": "100%",
            "options": {
              onBegin: _vm.onBegin,
              onEnd: _vm.onEnd,
              penColor: '#000',
              maxWidth: 1.5
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "icon",
      fn: function () {
        return [_c('v-icon', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.submissionObj.value && !_vm.sourceObj.properties.allow_typing,
            expression: "submissionObj.value && !sourceObj.properties.allow_typing"
          }],
          staticClass: "signature-field-view__layout-reset-btn",
          on: {
            "pointerdown": _vm.resetSignature
          }
        }, [_vm._v(" mdi-arrow-u-left-top ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }