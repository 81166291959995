<template>
    <span>
        <span v-if="cover" id="cover"></span>
        <span
                id="draggable-node"
                :style="style"
        >
            <span id="draggable-component">
                <component
                        v-if="draggableComponent"
                        :is="draggableComponent"
                        v-bind="draggableComponentBind"
                />
            </span>
        </span>
    </span>
</template>

<script>
    export default {
        name: "DraggableNode",
        data: () => ({
            isDragging: false,
            draggableComponent: undefined,
            draggableComponentBind: undefined,
            elWidth: undefined,
            elHeight: undefined,
            elOffsetLeft: undefined,
            elOffsetTop: undefined,
            permittedArea: undefined,
            left: undefined,
            top: undefined,
            enabledAxles: undefined,
            scroll: undefined,
            callbackBeforeStopDragging: undefined,
            callbackAfterStopDragging: undefined,
            pauseBeforeStopDragging: undefined,

            cover: false,


            clientX: undefined,
            clientY: undefined
        }),
        computed: {
            style() {
                return {
                    position: 'fixed',
                    zIndex: 1000,
                    transform: 'matrix(1, 0, 0, 1, ' + this.left + ', ' + this.top + ')'
                }
            }
        },
        methods: {
            recursiveScrollDependsOnClientY(initialClientYValue, scrollTopValue) {
                if (initialClientYValue === this.clientY && this.scroll) {
                    this.scroll.element.scrollBy({left: 0, top: scrollTopValue, behavior: 'smooth'})
                    setTimeout(() => {
                        this.recursiveScrollDependsOnClientY(initialClientYValue, scrollTopValue)
                    }, 100)
                }
            },
            changeCursor(cursor) {
                if (cursor) {
                    this.cover = true
                    this.$nextTick(() => {
                        document.getElementById('cover').style.cursor = cursor
                        document.getElementsByTagName('body')[0].setAttribute('onselectstart', 'return false')
                        document.getElementsByTagName('body')[0].classList.add('noselect')
                    })
                }
                else if (this.cover) {
                    document.getElementById('cover').style.cursor = 'default'
                    document.getElementsByTagName('body')[0].removeAttribute('onselectstart')
                        document.getElementsByTagName('body')[0].classList.remove('noselect')
                    this.cover = false
                }
            },
            getPermittedArea() {
                if (!this.permittedArea) {
                    return false
                }
                if (this.permittedArea instanceof Function) {
                    return this.permittedArea()
                }
                else if (this.permittedArea instanceof Object) {
                    return this.permittedArea
                }
                throw 'Wrong type of permittedArea'
            },
            startDragging(
                {
                    el,
                    el: {component, bind},
                    boundingRect,
                    permittedArea,
                    event,
                    scroll,
                    customOffsets,
                    enabledAxles,
                    callbackBeforeStopDragging,
                    callbackAfterStopDragging,
                    pauseBeforeStopDragging
                }
            ) {
                if (!el && !component)
                    throw 'el required'
                this.isDragging = true
                this.elWidth = boundingRect.width
                this.elHeight = boundingRect.height
                this.permittedArea = permittedArea
                this.elOffsetLeft = customOffsets && customOffsets.left !== undefined ? customOffsets.left : event.clientX - boundingRect.left
                this.elOffsetTop = customOffsets && customOffsets.top !== undefined ? customOffsets.top : event.clientY - boundingRect.top


                this.left = event.clientX - this.elOffsetLeft
                this.top = event.clientY - this.elOffsetTop

                if (component) {
                    this.draggableComponent = component
                    this.draggableComponentBind = bind
                }
                this.scroll = scroll
                this.enabledAxles = enabledAxles
                this.callbackBeforeStopDragging = callbackBeforeStopDragging
                this.callbackAfterStopDragging = callbackAfterStopDragging
                this.pauseBeforeStopDragging = pauseBeforeStopDragging

                this.$nextTick(() =>
                    this.draggableEventBus.$emit('mousemove', {
                        absoluteLeft: event.pageX, absoluteTop: event.pageY,
                        fixedLeft: event.clientX, fixedTop: event.clientY,
                        isInPermittedArea: {x: true, y: true}
                    })
                )
            },
            mouseMove(event) {
                if (event.path && event.path[0].tagName === 'HTML') {
                    this.stopDragging(event)
                    return
                }
                this.clientX = event.clientX
                this.clientY = event.clientY

                let permittedArea = this.getPermittedArea()

                // let isInPermittedArea = permittedArea ? {
                //     x: event.clientX >= permittedArea.left && event.clientX <= permittedArea.right,
                //     y: event.clientY >= permittedArea.top && event.clientY <= permittedArea.bottom
                // } : {x: true, y: true}

                let isInPermittedArea = permittedArea ? {
                    x: event.pageX >= permittedArea.left && event.pageX <= permittedArea.right,
                    y: event.pageY >= permittedArea.top && event.pageY <= permittedArea.bottom
                } : {x: true, y: true}

                this.draggableEventBus.$emit('mousemove', {
                    absoluteLeft: event.pageX, absoluteTop: event.pageY,
                    fixedLeft: event.clientX, fixedTop: event.clientY,
                    isInPermittedArea,
                    event
                })
                if (this.isDragging) {
                    let movementX = this.enabledAxles.x ? event.movementX : 0
                    let movementY = this.enabledAxles.y ? event.movementY : 0
                    let currentAbsoluteX = event.pageX - this.elOffsetLeft
                    let currentAbsoluteY = event.pageY - this.elOffsetTop

                    if (movementX) {
                        if (permittedArea) {
                            if (currentAbsoluteX < permittedArea.left)
                                this.left = permittedArea.left
                            else if (currentAbsoluteX + this.elWidth > permittedArea.right)
                                this.left = permittedArea.right - this.elWidth
                            else
                                this.left = event.clientX - this.elOffsetLeft
                        }
                        else
                            this.left = event.clientX - this.elOffsetLeft
                    }

                    if (movementY) {
                        if (permittedArea) {
                            if (currentAbsoluteY < permittedArea.top)
                                this.top = permittedArea.top - window.scrollY
                            else if (currentAbsoluteY + this.elHeight > permittedArea.bottom)
                                this.top = permittedArea.bottom - this.elHeight - window.scrollY
                            else
                                this.top = event.clientY - this.elOffsetTop
                        }
                        else
                            this.top = event.clientY - this.elOffsetTop
                    }

                    if (this.scroll) {
                        if (this.Utils.isCursorInsideBoundingRect(event.clientX, event.clientY, this.scroll.topScrollActivationZone))
                            this.recursiveScrollDependsOnClientY(event.clientY, -this.scroll.offset)
                        else if (this.Utils.isCursorInsideBoundingRect(event.clientX, event.clientY, this.scroll.bottomScrollActivationZone))
                            this.recursiveScrollDependsOnClientY(event.clientY, this.scroll.offset)
                    }

                }
            },
            scrolled() {
                // let currentAbsoluteX = this.clientX + window.scrollX - this.elOffsetLeft
                let currentAbsoluteY = this.clientY + window.scrollY - this.elOffsetTop
                let permittedArea = this.getPermittedArea()
                if (permittedArea) {
                    if (currentAbsoluteY < permittedArea.top)
                        this.top = permittedArea.top - window.scrollY
                    else if (currentAbsoluteY + this.elHeight > permittedArea.bottom)
                        this.top = permittedArea.bottom - this.elHeight - window.scrollY
                    else
                        this.top = this.clientY - this.elOffsetTop
                }

                let isInPermittedArea = permittedArea ? {
                    x: this.clientX >= permittedArea.left && this.clientX <= permittedArea.right,
                    y: this.clientY >= permittedArea.top && this.clientY <= permittedArea.bottom
                } : {x: true, y: true}

                this.draggableEventBus.$emit('mousemove', {
                    absoluteLeft: this.clientX + window.scrollX,
                    absoluteTop: this.clientY + window.scrollY,
                    fixedLeft: this.clientX,
                    fixedTop: this.clientY,
                    isInPermittedArea: isInPermittedArea
                })
            },
            stopDragging(e) {
                this.changeCursor(false)

                if (this.isDragging) {
                    if (this.callbackBeforeStopDragging)
                        this.callbackBeforeStopDragging()
                    this.isDragging = false
                    setTimeout(() => {
                        if (this.callbackAfterStopDragging)
                            this.callbackAfterStopDragging()
                        for (let dataName of Object.keys(this.$data))
                            this[dataName] = undefined
                    }, this.pauseBeforeStopDragging ? this.pauseBeforeStopDragging : 0)
                } else
                    this.draggableEventBus.$emit('mouseup', e)
            },
            visibilitychange(event) {
                this.draggableEventBus.$emit('visibilitychange', {event: event, state: document.visibilityState})
                if (this.isDragging)
                    this.stopDragging(event)
            }
        },
        mounted() {
            this.draggableEventBus.$on('start', this.startDragging)
            this.draggableEventBus.$on('changecursor', this.changeCursor)

            document.onmousemove = this.mouseMove
            document.onmouseleave = this.stopDragging
            document.onmouseup = this.stopDragging
            document.onscroll = this.scrolled
            document.onvisibilitychange = this.visibilitychange
        },
        beforeDestroy() {
            this.draggableEventBus.$off('start', this.startDragging)
            this.draggableEventBus.$off('changecursor', this.changeCursor)

            document.onmousemove = null
            document.onmouseleave = null
            document.onmouseup = null
            document.onscroll = null
            document.onvisibilitychange = null
        }
    }
</script>

<style lang="scss" scoped>
    #cover {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9999999999999;
    }
</style>