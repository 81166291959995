import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import VueGtag from 'vue-gtag'
// import Hotjar from 'vue-hotjar'

import router from './router'

if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG_ID },
      appName: 'Formsly',
    },
    router
  )

  // Vue.use(Hotjar, {
  //   id: process.env.VUE_APP_HOTJAR_SITE_ID,
  //   isProduction: true,
  // })

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    enabled: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  })
}

export function setCurrentUserIdentityForAnalytics(identity) {
  if (!identity) {
    return Sentry.setUser(null)
  }

  const { identityId, userSub, email } = identity
  Sentry.setUser({ id: identityId, username: userSub, email })
}
