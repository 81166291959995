var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "conditional-logic-expression conditional-logic-expression--Datetime"
  }, [_c('f-date-time-picker', _vm._b({
    attrs: {
      "as-menu": "",
      "menu-props": {
        nudgeBottom: 10
      },
      "return-timestamp": ""
    },
    on: {
      "input": function ($event) {
        _vm.expressionObj.statement = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          staticClass: "conditional-logic-expression__expression-input cursor-pointer",
          attrs: {
            "value": _vm.readableDatetime,
            "placeholder": "Choose date/time",
            "readonly": "",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          scopedSlots: _vm._u([{
            key: "prepend-inner",
            fn: function () {
              return [_c('f-select', {
                ref: "operatorPicker",
                staticClass: "conditional-logic-expression__operator-picker",
                attrs: {
                  "items": _vm.expressionOperators,
                  "item-text": function (item) {
                    return item.text;
                  },
                  "item-value": function (item) {
                    return item.value;
                  },
                  "menu-props": {
                    nudgeBottom: 54
                  },
                  "hide-details": "",
                  "solo": "",
                  "flat": "",
                  "dense": ""
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                  }
                },
                model: {
                  value: _vm.expressionObj.operator,
                  callback: function ($$v) {
                    _vm.$set(_vm.expressionObj, "operator", $$v);
                  },
                  expression: "expressionObj.operator"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, on))];
      }
    }])
  }, 'f-date-time-picker', _vm.fieldConst.UTIL.GEN_PARTS_BIND_FOR_DATETIME_PICKER(_vm.getConditionalField), false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }