<template>
  <div>
    <v-menu
        nudge-top="25"
        nudge-right="75"
        open-on-hover
        top
        eager
        transition="slide-y-reverse-transition"
        open-delay="200"
    >
      <template v-slot:activator="{ on }">
        <span class="address-field-view-short-single-answer" v-on="on">{{ getAddressValues || '-' }}</span>
      </template>
      <v-card class="pa-6">
        <FullSingleAnswer :submissionObj="submissionObj"/>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ShortSingleAnswer__Address',
  components: {
    FullSingleAnswer: () => import('./FullSingleAnswer'),
  },
  props: {
    submissionObj: Object
  },
  computed: {
    getAddressValues() {
      return Object.entries(this.submissionObj.value)
          .map(([key, value]) => key === 'country' ? this.constants.COUNTRY_NAMES[value] : value)
          .filter(item => item)
          .join(', ')
    }
  }
}
</script>