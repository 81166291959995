var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-form-elements-panel-blocks-list"
  }, [_c('f-solo-search-input', {
    attrs: {
      "placeholder": "Search",
      "height": "36"
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('div', {
    staticClass: "static-form-elements-panel-blocks-list__hint"
  }, [_vm._v(" Drag or click to add questions ")]), _c('div', {
    ref: "tilesContainer",
    staticClass: "static-form-elements-panel-blocks-list__scrollable-container thin-scrollbar"
  }, _vm._l(_vm.filteredFieldsGroups, function (fieldsGroup, groupKey) {
    return _c('div', {
      key: groupKey,
      staticClass: "static-form-elements-panel-blocks-list__fields-group"
    }, _vm._l(fieldsGroup, function (fieldConst) {
      return _c('div', {
        key: fieldConst.FIELD_TYPE,
        ref: fieldConst.FIELD_TYPE,
        refInFor: true,
        staticClass: "static-form-elements-panel-blocks-list__field-tile noselect",
        on: {
          "mousedown": function ($event) {
            return _vm.fieldTileMousedown($event, fieldConst.FIELD_TYPE);
          },
          "mousemove": _vm.fieldTileMousemove,
          "mouseup": _vm.fieldTileMouseup
        }
      }, [_c(fieldConst.ICON_COMPONENT, {
        tag: "component",
        attrs: {
          "width": "36",
          "height": "36"
        }
      }), _c('span', [_vm._v(_vm._s(fieldConst.READABLE_NAME))]), _c('v-spacer'), _vm.getCurrentSubscriptionFeatures && fieldConst.ASSOCIATE_WITH_PAID_FEATURE && !_vm.getCurrentSubscriptionFeatures[fieldConst.ASSOCIATE_WITH_PAID_FEATURE] ? [_c('f-hover-tooltip', {
        attrs: {
          "max-width": "250",
          "top": "",
          "text": "You can try this paid feature, but you'll need to remove it to publish your form"
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function (_ref) {
            var on = _ref.on;
            return [_c('f-badge', _vm._g({
              attrs: {
                "purple": ""
              },
              on: {
                "click": function ($event) {
                  $event.stopPropagation();
                  return _vm.upgradeToProFromModal.apply(null, arguments);
                },
                "mousedown": function ($event) {
                  $event.stopPropagation();
                },
                "mouseup": function ($event) {
                  $event.stopPropagation();
                }
              }
            }, on), [_vm._v(" Paid ")])];
          }
        }], null, true)
      })] : _vm._e()], 2);
    }), 0);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }