var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('v-row', [_c('v-container', {
    staticStyle: {
      "max-width": "450px"
    }
  }, [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('v-col', [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._t("title")], 2)])], 1), _vm.$slots.description ? _c('v-row', {
    staticClass: "mt-n6 mb-2"
  }, [_c('v-col', [_c('p', {
    staticClass: "text--secondary font-weight-medium"
  }, [_vm._t("description")], 2)])], 1) : _vm._e(), _vm._t("form"), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('p', {
    staticClass: "ma-4 font-weight-light"
  }, [_vm._t("jump-to")], 2)]), _vm._t("additional-bottom")], 2)], 1)], 1), _vm.$router.currentRoute.path === '/signup_appsumo' ? _c('v-col', {
    staticClass: "d-none d-md-flex justify-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('div', [_c('img', {
    staticStyle: {
      "max-width": "50vw"
    },
    attrs: {
      "draggable": "false",
      "src": "/images/LoginImgAppSumo.png"
    }
  })])]) : _c('v-col', {
    staticClass: "d-none d-md-flex justify-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('div', {
    staticClass: "mr-n6"
  }, [_c('img', {
    staticStyle: {
      "max-width": "50vw"
    },
    attrs: {
      "draggable": "false",
      "src": "/images/LoginImg.png"
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }