<template>
    <div class="static-form-elements-panel-blocks-list">
        <f-solo-search-input
                v-model="searchQuery"
                placeholder="Search"
                height="36"
        />
        <div class="static-form-elements-panel-blocks-list__hint">
            Drag or click to add questions
        </div>
        <div
                class="static-form-elements-panel-blocks-list__scrollable-container thin-scrollbar"
                ref="tilesContainer"
        >
            <div
                    v-for="(fieldsGroup, groupKey) in filteredFieldsGroups"
                    class="static-form-elements-panel-blocks-list__fields-group"
                    :key="groupKey"
            >
                <div
                        v-for="fieldConst in fieldsGroup"
                        class="static-form-elements-panel-blocks-list__field-tile noselect"
                        :ref="fieldConst.FIELD_TYPE"
                        :key="fieldConst.FIELD_TYPE"
                        @mousedown="fieldTileMousedown($event, fieldConst.FIELD_TYPE)"
                        @mousemove="fieldTileMousemove"
                        @mouseup="fieldTileMouseup"
                >
<!--                        @mousedown="startDragging($event, $refs[fieldConst.FIELD_TYPE][0], fieldConst.FIELD_TYPE)"-->
                    <component
                        :is="fieldConst.ICON_COMPONENT"
                        width="36"
                        height="36"
                    />
                    <span>{{fieldConst.READABLE_NAME}}</span>
                    <v-spacer/>
                    <template v-if="getCurrentSubscriptionFeatures && fieldConst.ASSOCIATE_WITH_PAID_FEATURE &&
                                 !getCurrentSubscriptionFeatures[fieldConst.ASSOCIATE_WITH_PAID_FEATURE]">
                        <f-hover-tooltip
                                max-width="250"
                                top
                                text="You can try this paid feature, but you'll need to remove it to publish your form"
                                v-slot="{on}"
                        >
                            <f-badge v-on="on"
                                     purple
                                     @click.stop="upgradeToProFromModal"
                                     @mousedown.stop
                                     @mouseup.stop
                            >
                                Paid
                            </f-badge>
                        </f-hover-tooltip>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, ref } from '@vue/composition-api'
    import { onBeforeMount, onBeforeUnmount } from '@vue/composition-api/dist/vue-composition-api'
    import {mapGetters} from "@/xstore";

    export default {
        name: "FormElementsPanelBlocksList",
        computed: {
            ...mapGetters('user/plan/current_subscription', [
                'getCurrentSubscriptionFeatures',
            ])
        },
        methods: {
          upgradeToProFromModal() {
            this.$store.dispatch('user/plan/current_subscription/monitoring_features/monitoring_features_dialog/showDialog', true);
          }
        },
        setup(props, {root, refs}) {
            const searchQuery = ref('')

            const filteredFieldsGroups = computed(() => root.constants.FIELD_TYPES_BY_GROUPS
                .map(group => group
                    .filter(field => field.READABLE_NAME.toLowerCase().includes(searchQuery.value.toLowerCase()))
                )
                .filter(group => group.length))

            /* eslint-disable */

            const startDragging = (event, tileRef, fieldType) => {
                root.draggableEventBus.$emit('start-adding-new-field', {
                    event: event,
                    bounding: root.Utils.getAbsoluteBoundingRect(tileRef),
                    fieldType: fieldType
                })
            }

            const requiredOffset = 20
            let selectedFieldType
            let mousedownInitialPosition


            onBeforeMount(() => {
                root.draggableEventBus.$on('mousemove', fieldTileMousemove)
                root.draggableEventBus.$on('mouseup', fieldTileMouseup)

            })
            onBeforeUnmount(() => {
                root.draggableEventBus.$off('mousemove', fieldTileMousemove)
                root.draggableEventBus.$off('mouseup', fieldTileMouseup)
            })


            const fieldTileMousedown = (e, fieldType) => {
                mousedownInitialPosition = {left: e.clientX, top: e.clientY}
                selectedFieldType = fieldType
            }

            function fieldTileMousemove({fixedTop, fixedLeft, event}) {
                if (mousedownInitialPosition && selectedFieldType) {
                    if (Math.abs(fixedLeft - mousedownInitialPosition.left) >= requiredOffset || Math.abs(fixedTop - mousedownInitialPosition.left) >= requiredOffset) {
                        startDragging(event, refs[selectedFieldType][0], selectedFieldType)
                        selectedFieldType = mousedownInitialPosition = undefined
                    }
                }
            }

            async function fieldTileMouseup(e) {
                try {
                    if (mousedownInitialPosition && selectedFieldType) {
                        if (Math.abs(e.clientX - mousedownInitialPosition.left) <= requiredOffset || Math.abs(e.clientY - mousedownInitialPosition.left) <= requiredOffset) {
                            const newFieldId = await root.$store.dispatch('edit/fields/createNewFormField', selectedFieldType)
                            await root.$store.dispatch('edit/pages/changeCurrentFormPage', {
                                ...root.$store.getters['edit/pages_navigation/getCurrentPage'],
                                rows: [
                                    ...root.$store.getters['edit/pages_navigation/getCurrentPage'].rows,
                                    {fields: [{field_id: newFieldId, width: root.constants.STATIC_FORM_COLS_COUNT}]}
                                ]
                            })

                            setTimeout(() => root.$vuetify.goTo(document.querySelector(`.generic-field--${newFieldId}`), {
                                duration: 300,
                                offset: 250
                            }), 200)
                        }
                    }
                    selectedFieldType = mousedownInitialPosition = undefined
                } catch (e) {
                    selectedFieldType = mousedownInitialPosition = undefined
                }
            }


            return {
                searchQuery,
                filteredFieldsGroups,

                fieldTileMouseup,
                fieldTileMousemove,
                fieldTileMousedown
            }
        }
    }
</script>

<style lang="scss" scoped>
    .static-form-elements-panel-blocks-list {
        padding: 0 0 10px;

        &__hint {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: var(--v-gray_400-base);
            text-align: left;
            margin: 24px auto 14px;
        }

        &__scrollable-container {
            max-height: calc(100vh - 120px - 40px - 36px - 20px - 52px - 100px);
            overflow-y: auto;
        }

        &__fields-group {
            &:not(:last-child):not(:only-child) {
                margin-bottom: 16px;
            }
        }

        &__field-tile {
            display: flex;
            padding: 7px;
            align-items: center;
            cursor: pointer;
            border-radius: 6px;
            font-weight: 500;

            .f-badge {
              cursor: pointer;
            }


            &:hover {
                background-color: var(--v-blue_100-base);
            }

            span {
                margin-left: 15px;
                font-size: .875rem;
                color: var(--v-bluish_gray_400-base);
            }
        }
    }
</style>