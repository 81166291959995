<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
            <v-spacer/>
            <div class="smart-paragraph-field-view__summary-toggle-responses-visibility d-flex align-center subtitle-2 gray_400--text font-weight-regular cursor-pointer" @click="showHiddenAnswers = !showHiddenAnswers">
                <EyeIcon v-if="!showHiddenAnswers" size="20" stroke-width="1"/>
                <EyeOffIcon v-else size="20" stroke-width="1"/>
                <span class="ml-2">{{ showHiddenAnswers ? 'Hide' : 'Show' }} Hidden responses</span>
            </div>
        </div>
        <div class="summary-analytics-view__field-view-wrapper">
            <div class="smart-paragraph-field-view-summary">
                <div class="smart-paragraph-field-view-summary__content">
                    <template v-for="(element, key) in latestSourceObj.content">
                        <template v-if="element.type === 'text'">{{ element.value }}</template>
                        <br v-else-if="element.type === 'break'" :key="key">
                        <span
                                v-else-if="element.type === 'variable'"
                                class="smart-paragraph-field-view-summary__variable-in-content"
                                :style="{color: formattedVariables[getKey(element.variable_id, latestSourceObj.properties.variables_list[element.variable_id])].color}"
                                :key="key"
                        >{{ latestSourceObj.properties.variables_list[element.variable_id] }}</span>
                    </template>
                </div>

                <div class="smart-paragraph-field-view-summary__table-wrapper">
                    <table class="smart-paragraph-field-view-summary__table">
                        <thead>
                            <tr>
                                <th
                                        v-for="varAttrs in sortedVariables"
                                        :key="varAttrs.key + 'th' + showHiddenAnswers"
                                        :class="varAttrs.hidden && 'smart-paragraph-field-view-summary__hidden-cell'"
                                        :style="!varAttrs.hidden && {color: formattedVariables[varAttrs.key].color}"
                                >
                                    <span class="d-flex align-center">
                                        {{formattedVariables[varAttrs.key].name}}
                                        <v-spacer/>
                                        <v-tooltip
                                                 top
                                                 content-class="tooltip-top pa-5"
                                                 transition="none"
                                                 max-width="320"
                                                 nudge-top="10"
                                                 color="gray_900"
                                        >
                                            <template #activator="{ on }">
                                                <InfoIcon v-if="varAttrs.hidden" class="mr-n2" size="20" stroke-width="1" v-on="on"/>
                                            </template>
                                            <template #default>
                                                <p class="subtitle-1 font-weight-semi-bold mb-2">Hidden Variable Responses</p>
                                                <p class="subtitle-2 font-weight-regular text-pre-wrap mb-0">These Variables were removed from the latest published version of your form, therefor we hide them from the default view of responses summary</p>
                                            </template>
                                        </v-tooltip>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                    v-for="{submissionID} in filteredSubmissions"
                                    :key="submissionID"
                            >
                                <td
                                        v-for="{hidden, key} in sortedVariables"
                                        :class="hidden && 'smart-paragraph-field-view-summary__hidden-cell'"
                                        :key="key + 'td'"
                                        v-html="(formattedVariables[key].answers[submissionID] && !formattedVariables[key].answers[submissionID].empty) ? formattedVariables[key].answers[submissionID].text : '-'"
                                >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__SmartParagraph",
        components: {
            EyeIcon: () => import('vue-feather-icons/icons/EyeIcon'),
            EyeOffIcon: () => import('vue-feather-icons/icons/EyeOffIcon'),
            InfoIcon: () => import('vue-feather-icons/icons/InfoIcon')
        },
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        data: () => ({
            showHiddenAnswers: false
        }),
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj}) => Object.values(submissionObj.value).some(answer => !this.fieldTypeConst.UTIL.ANSWER_TO_VARIABLE_IS_EMPTY(answer)))
            },
            sortedVariables() {
                const result = []

                this.latestSourceObj.content
                    .forEach(element => {
                        if (element.type === 'variable')
                            result.push({
                                key: this.getKey(element.variable_id, this.latestSourceObj.properties.variables_list[element.variable_id]),
                                hidden: false
                            })
                    })

                if (this.showHiddenAnswers)
                    Object.entries(this.formattedVariables)
                        .forEach(([key, {id, answers}]) => {
                            if (!(id in this.latestSourceObj.properties.variables_list) && Object.values(answers).every(a => !a.empty))
                                result.push({
                                    key: key,
                                    hidden: true
                                })
                        })

                return result
            },
            formattedVariables() {
                const result = {}

                this.submissions
                    .forEach(
                        ({submissionObj, versionID, submissionID}) => {
                            Object.entries(this.versionedSources[versionID].properties.variables_list)
                                .forEach(
                                    ([variableID, variableName]) => {
                                        if (!(this.getKey(variableID, variableName) in result))
                                            result[this.getKey(variableID, variableName)] = {
                                                name: variableName,
                                                id: variableID,
                                                color: this.Utils.stringToColor(this.getKey(variableID, variableName)),
                                                answers: {}
                                            }
                                    }
                                )

                            Object.entries(submissionObj.value)
                                .forEach(([variableID, answer]) => {
                                    result[this.getKey(variableID, this.versionedSources[versionID].properties.variables_list[variableID])].answers[submissionID] = {
                                        text: answer,
                                        empty: this.fieldTypeConst.UTIL.ANSWER_TO_VARIABLE_IS_EMPTY(answer)
                                    }
                                })
                        }
                    )

                return result
            }
        },
        methods: {
            getKey(variableID, variableName) {
                return variableID + variableName
            }
        }
    }
</script>