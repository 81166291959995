import { VTextField, VInput } from 'vuetify/lib/components'
import Vue from 'vue'

const GenericStringSingleLineFieldViewInput = Vue.extend({
    name: "GenericStringSingleLineFieldViewInput",
    extends: VTextField,
    props: {
        hideDetails: {
            type: Boolean,
            default: true,
        },
        assignGenericViewClass: {
            type: Boolean,
            default: false
        },
        isFormStaticProp: {
            type: Boolean,
            default: false,
        }
    },
    inject: [
        'isFormStatic'
    ],
    computed: {
        classes() {
            return {
                ...VTextField.options.computed.classes.call(this),
                'generic-text-field-view__input-field': true
            }
        },
        outlined() {
            return this.isFormStatic.value || this.isFormStaticProp
        }
    },
    render(h) {
        if (this.assignGenericViewClass)
            return h(
                'div',
                {class: 'generic-text-field-view'},
                [VInput.options.render.call(this, h)]
            )
        return VInput.options.render.call(this, h)
    }
})

delete GenericStringSingleLineFieldViewInput.options.props.outlined

export default GenericStringSingleLineFieldViewInput