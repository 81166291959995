<template>
    <v-tab-item class="change-cover-browser-window">
        <slot name="header"></slot>
        <v-container class="change-cover-browser-container" @scroll="$emit('infinite-scroll', $event)">
            <slot name="default"></slot>
        </v-container>
    </v-tab-item>
</template>

<script>
    export default {
        name: "BrowserTemplate",
        props: {
            activeImage: String
        }
    }
</script>

<style lang="scss" scoped>
    .change-cover-browser-window {
        transition: padding 150ms ease-in-out;

        &--image-selected {
            padding-bottom: 58px;
        }

        .change-cover-browser-container {
            height: 540px;
            overflow-y: auto;
            transition: padding 150ms ease-in-out;
        }
    }
</style>