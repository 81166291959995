<template>
    <svg width="123" height="105" viewBox="0 0 123 105" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M109.443 49.1048C109.443 75.3651 88.1547 96.6534 61.8943 96.6534C35.6339 96.6534 14.3457 75.3651 14.3457 49.1048C14.3457 22.8444 35.6339 1.55615 61.8943 1.55615C88.1547 1.55615 109.443 22.8444 109.443 49.1048Z"
              fill="#E3EAFF"/>
        <path d="M3.75488 71.7262L6.42969 68.4749C6.58093 68.2911 6.4757 68.0125 6.24069 67.9745L2.00508 67.2905C1.7688 67.2523 1.58099 67.4862 1.66924 67.7086L3.23004 71.6439C3.31662 71.8622 3.60568 71.9075 3.75488 71.7262Z"
              fill="#2FBAEA"/>
        <path d="M14.5225 16.0953C14.5225 17.8047 13.1367 19.1905 11.4273 19.1905C9.71782 19.1905 8.33203 17.8047 8.33203 16.0953C8.33203 14.3858 9.71782 13 11.4273 13C13.1367 13 14.5225 14.3858 14.5225 16.0953Z"
              fill="#E6EBFB"/>
        <path d="M83.3419 102.005C83.3419 103.112 82.4443 104.01 81.337 104.01C80.2297 104.01 79.332 103.112 79.332 102.005C79.332 100.898 80.2297 100 81.337 100C82.4443 100 83.3419 100.898 83.3419 102.005Z"
              fill="#C55BFC"/>
        <circle cx="106.108" cy="17.1274" r="1.11225" fill="url(#paint0_linear_3361_30629)"/>
        <rect x="98.3223" y="1" width="91.2043" height="73.9645" rx="6" transform="rotate(90 98.3223 1)"
              fill="url(#paint1_linear_3361_30629)" stroke="black" stroke-opacity="0.08"/>
        <rect x="35.332" y="25" width="9" height="9" rx="4.5" fill="#2FBBEB"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M41.3218 28.1251C41.465 28.2826 41.4549 28.5277 41.2993 28.6726L39.1 30.7213C39.0225 30.7934 38.9191 30.8301 38.8141 30.8227C38.709 30.8152 38.6117 30.7643 38.5449 30.6819L37.79 29.7507C37.6559 29.5853 37.6797 29.3411 37.8431 29.2054C38.0066 29.0696 38.2478 29.0937 38.3819 29.2591L38.8797 29.8733L40.7809 28.1024C40.9365 27.9574 41.1787 27.9676 41.3218 28.1251Z"
              fill="white"/>
        <rect width="19.6623" height="3" rx="1.5" transform="matrix(-1 0 0 1 67.9941 28)" fill="#E3E9F7"/>
        <rect width="24.4695" height="3" rx="1.5" transform="matrix(-1 0 0 1 60.5059 13)" fill="#E3E9F7"/>
        <rect width="24.4695" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 60.5059 63.8408)" fill="#E3E9F7"/>
        <rect width="11.1225" height="3" rx="1.5" transform="matrix(-1 0 0 1 47.1562 19.3511)" fill="#E3E9F7"/>
        <rect width="24.4695" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 60.5078 41.0405)" fill="#E3E9F7"/>
        <rect width="11.1225" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 47.1602 45.4893)" fill="#E3E9F7"/>
        <rect x="36.0371" y="51.6069" width="7.22961" height="3.33674" rx="1.66837" fill="#E3E9F7"/>
        <rect x="46.6035" y="51.6069" width="7.22961" height="3.33674" rx="1.66837" fill="#E3E9F7"/>
        <rect x="57.1719" y="51.6069" width="7.22961" height="3.33674" rx="1.66837" fill="#E3E9F7"/>
        <rect x="36.0352" y="75.521" width="13.347" height="4.44899" rx="2.2245" fill="url(#paint2_linear_3361_30629)"/>
        <defs>
            <linearGradient id="paint0_linear_3361_30629" x1="105.052" y1="17.1115" x2="107.21" y2="17.1115"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF66A9"/>
                <stop offset="1" stop-color="#F53689"/>
            </linearGradient>
            <linearGradient id="paint1_linear_3361_30629" x1="145.122" y1="1" x2="145.122" y2="76.3587"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#FEFEFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_3361_30629" x1="36.0071" y1="77.7458" x2="49.3789" y2="77.7458"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#3D66F6"/>
                <stop offset="1" stop-color="#6789FF"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "StaticFormImage"
    }
</script>