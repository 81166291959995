import { Auth } from 'aws-amplify'

import constants from '@/constants/constants'
import store from '@/store'

import {
  concatTypedArrays,
  generateUUID,
  getFileExtension,
  getFileFolderPath,
  getFileNameFromPath,
} from '../misc'

const {
  S3Client,
  GetObjectCommand,
  ListObjectVersionsCommand,
  PutObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  ListObjectsV2Command,
  CopyObjectCommand,
} = require('@aws-sdk/client-s3')

const STORAGE_ACCESS_LEVELS = {
  Public: 'public',
  Protected: 'protected',
  Private: 'private',
}

const STORAGE_ACTION_TYPES = {
  get: GetObjectCommand,
  put: PutObjectCommand,
  delete: DeleteObjectCommand,
}

const streamToBinary = stream =>
  new Promise((resolve, reject) => {
    if (!(stream instanceof ReadableStream)) {
      reject(
        `Expected stream to be instance of ReadableStream, but got ${typeof stream}`
      )
    }
    let response = new Uint8Array()

    const reader = stream.getReader()
    const processRead = ({ done, value }) => {
      if (done) {
        resolve(response)
        return
      }

      response = concatTypedArrays(response, value)

      reader.read().then(processRead)
    }

    reader.read().then(processRead)
  })

function Uint8ToString(u8a) {
  var CHUNK_SZ = 0x8000
  var c = []
  for (var i = 0; i < u8a.length; i += CHUNK_SZ)
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
  return c.join('')
}

const streamToJSON = async stream =>
  new Promise((resolve, reject) => {
    streamToBinary(stream)
      .then(binary => {
        const decoder = new TextDecoder('utf8')
        return JSON.parse(decoder.decode(binary))
      })
      .then(resolve)
      .catch(reject)
  })

const streamToImage = async stream =>
  new Promise((resolve, reject) => {
    streamToBinary(stream)
      .then(binary => btoa(Uint8ToString(binary)))
      .then(resolve)
      .catch(reject)
  })

let s3SystemClockOffset = null

const executeS3Action = async (action, actionInput) => {
  const credentials = await store.dispatch('user/getAuthCredentials')
  const s3 = new S3Client({
    region: Auth._config.aws_user_files_s3_bucket_region,
    credentials,
  })

  if (s3SystemClockOffset !== null) {
    s3.config.systemClockOffset = s3SystemClockOffset
  }

  if ('Metadata' in actionInput) {
    for (let [key, value] of Object.entries(actionInput.Metadata)) {
      actionInput.Metadata[key] = encodeURI(value)
    }
  }

  return new Promise((resolve, reject) =>
    s3.send(
      new action({
        Bucket: Auth._config.aws_user_files_s3_bucket,
        ...actionInput,
      }),
      (err, data) => {
        if (err) {
          console.error(
            `Error while processing S3 action.\n\nAction: ${action}\nStatus code: ${err.$metadata.httpStatusCode}\n${err.Code}: ${err.message}\nAction input: ${actionInput}`
          )
          switch (err.Code) {
            case 'RequestTimeTooSkewed':
              s3SystemClockOffset = Math.abs(
                Date.now() - Date.parse(err.ServerTime)
              )
              return executeS3Action(action, actionInput)
                .then(resolve)
                .catch(reject)
            case 'ExpiredToken':
              return store
                .dispatch('user/getAuthCredentials', { forceRefresh: true })
                .then(() =>
                  executeS3Action(action, actionInput)
                    .then(resolve)
                    .catch(reject)
                )

            default:
              return reject(err)
          }
        }
        resolve(data)
      }
    )
  ).then(response => {
    if ('Metadata' in response) {
      for (let [key, value] of Object.entries(response.Metadata)) {
        response.Metadata[key] = decodeURI(value)
      }
    }
    return response
  })
}

/**
 putPublicObject({file: File | text, fileName: String})
 putProtectedObject({file: File | text, fileName: String})
 putPrivateObject({file: File | text, fileName: String})
 **/
const GENERATED_PUT_COMMANDS = Object.fromEntries(
  Object.entries(STORAGE_ACCESS_LEVELS).map(([levelName, levelValue]) => [
    `put${levelName}Object`,

    /**
             file: File
             filePath: e.g. attachment (file extension will be taken from file)

             OR

             file: raw text
             filePath e.g. form.json (file extension must be specified)
             **/
    ({ file, filePath, initialFilename }) =>
      new Promise((resolve, reject) => {
        filePath = filePath ? filePath : generateUUID()
        let contentType,
          fileExtension = ''

        if (file instanceof Blob) {
          const fileTypeArr = file.type.split('/')

          if (fileTypeArr[0] === 'image') {
            fileExtension = `.${fileTypeArr[1]}`
          } else {
            fileExtension = getFileExtension(file.name)
            fileExtension = fileExtension.length
              ? `.${fileExtension}`
              : fileExtension
          }
          contentType = file.type
        }

        store
          .dispatch('user/getAuthCredentials')
          .then(credentials => {
            const fullFilePath = `users/${credentials.identityId}/${levelValue}/${filePath}${fileExtension}`
            const fullFolderPath = getFileFolderPath(fullFilePath)

            return executeS3Action(STORAGE_ACTION_TYPES.put, {
              Key: fullFilePath,
              Body: file,
              ...(initialFilename && {
                Metadata: { 'initial-filename': file.name || '' },
              }),
              ...(contentType && { ContentType: contentType }),
            }).then(response => {
              return {
                fullFilePath,
                fullFolderPath,
                fileUrl: constants.S3_BASE_URL + fullFilePath,
                response,
              }
            })
          })
          .then(resolve)
          .catch(reject)
      }),
  ])
)

/**
 deletePublicObjects(filePath1, filePath2, ...)
 deleteProtectedObjects(filePath1, filePath2, ...)
 deletePrivateObjects(filePath1, filePath2, ...)
 **/
const GENERATED_DELETE_COMMANDS = Object.fromEntries(
  Object.entries(STORAGE_ACCESS_LEVELS).map(([levelName, levelValue]) => [
    `delete${levelName}Objects`,
    async function () {
      return new Promise((resolve, reject) => {
        const pathsToDelete = Array.prototype.slice.call(arguments)

        store
          .dispatch('user/getAuthCredentials')
          .then(credentials =>
            executeS3Action(DeleteObjectsCommand, {
              Delete: {
                Objects: pathsToDelete.map(path => ({
                  Key: `users/${credentials.identityId}/${levelValue}/${path}`,
                })),
              },
            })
          )
          .then(resolve)
          .catch(reject)
      })
    },
  ])
)

/**
 deletePublicFolder(folderPath)
 deleteProtectedFolder(folderPath)
 deletePrivateFolder(folderPath)
 **/
const GENERATED_DELETE_FOLDERS_COMMANDS = Object.fromEntries(
  Object.entries(STORAGE_ACCESS_LEVELS).map(([levelName, levelValue]) => [
    `delete${levelName}Folder`,
    folderPath =>
      new Promise((resolve, reject) => {
        store
          .dispatch('user/getAuthCredentials')
          .then(credentials => {
            const fullFolderPath = `users/${credentials.identityId}/${levelValue}/${folderPath}`
            return listObjects(fullFolderPath)
          })
          .then(rawObjects =>
            deleteObjectsByFullS3Paths(...rawObjects.map(object => object.Key))
          )
          .then(resolve)
          .catch(reject)
      }),
  ])
)

/**
 deletePublicFolder(folderPath)
 deleteProtectedFolder(folderPath)
 deletePrivateFolder(folderPath)
 **/
const GENERATED_LIST_OBJECTS_COMMANDS = Object.fromEntries(
  Object.entries(STORAGE_ACCESS_LEVELS).map(([levelName, levelValue]) => [
    `list${levelName}Objects`,
    folderPath =>
      new Promise((resolve, reject) => {
        store
          .dispatch('user/getAuthCredentials')
          .then(credentials => {
            const fullFolderPath = `users/${credentials.identityId}/${levelValue}/${folderPath}`
            return listObjects(fullFolderPath)
          })
          .then(resolve)
          .catch(reject)
      }),
  ])
)

const listObjects = async folderPath =>
  new Promise((resolve, reject) => {
    executeS3Action(ListObjectsV2Command, { Prefix: folderPath })
      .then(({ Contents }) => resolve(Contents))
      .catch(reject)
  })

const getJSONByS3Path = (path, versionID) =>
  new Promise((resolve, reject) => {
    executeS3Action(STORAGE_ACTION_TYPES.get, {
      Key: path,
      ResponseCacheControl: 'no-cache',
      ...(versionID && { VersionId: versionID }),
    })
      .then(response =>
        Promise.all([response.VersionId, streamToJSON(response.Body)])
      )
      .then(([versionID, json]) => resolve({ versionID, json }))
      .catch(reject)
  })

const getImageByS3Path = (path, fromCache = false) =>
  new Promise((resolve, reject) => {
    executeS3Action(STORAGE_ACTION_TYPES.get, {
      Key: path,
      ContentEncoding: 'base64',
      ...(!fromCache && { ResponseCacheControl: 'no-cache' }),
    })
      .then(response => Promise.all([response, streamToImage(response.Body)]))
      .then(([response, base64encoded]) =>
        resolve({
          dataURL: `data:${
            response.ContentType || 'image/png'
          };base64,${base64encoded}`,
          size: response.ContentLength,
          contentType: response.ContentType,
          initialFilename: response.Metadata['initial-filename'],
        })
      )
      .catch(reject)
  })

const deleteObjectsByFullS3Paths = async function () {
  return new Promise((resolve, reject) => {
    const pathsToDelete = Array.prototype.slice.call(arguments)

    executeS3Action(DeleteObjectsCommand, {
      Delete: {
        Objects: pathsToDelete.map(path => ({ Key: path })),
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

const uploadPublicAttachment = attachment =>
  new Promise((resolve, reject) => {
    const fileName = generateUUID() + generateUUID()

    GENERATED_PUT_COMMANDS.putPublicObject({
      file: attachment,
      filePath: fileName,
      initialFilename: true,
    })
      .then(({ fileUrl }) => resolve(fileUrl))
      .catch(reject)
  })

const listObjectVersions = async objectPath => {
  return new Promise((resolve, reject) => {
    executeS3Action(ListObjectVersionsCommand, {
      Prefix: objectPath,
    })
      .then(resolve)
      .catch(reject)
  })
}

const copyObject = (currentPath, newPath) =>
  executeS3Action(CopyObjectCommand, {
    CopySource: Auth._config.aws_user_files_s3_bucket + '/' + currentPath,
    Key: newPath,
  }).then(r => ({
    previousPath: currentPath,
    currentPath: newPath,
    response: r,
  }))

const moveObject = (currentPath, newPath) =>
  copyObject(currentPath, newPath)
    .then(() =>
      executeS3Action(DeleteObjectCommand, {
        Key: currentPath,
      })
    )
    .then(r => ({
      previousPath: currentPath,
      currentPath: newPath,
      response: r,
    }))

const putObject = ({ file, filePath, contentType, initialFilename }) =>
  executeS3Action(STORAGE_ACTION_TYPES.put, {
    Key: filePath,
    Body: file,
    ...(contentType && { ContentType: contentType }),
    ...(initialFilename && {
      Metadata: { 'initial-filename': initialFilename },
    }),
  })

const getObject = async path => {
  const response = await executeS3Action(STORAGE_ACTION_TYPES.get, {
    Key: path,
    ResponseCacheControl: 'no-cache',
  })
  const binary = await streamToBinary(response.Body)
  return {
    blob: new Blob([binary], { type: response.ContentType }),
    name: getFileNameFromPath(path),
    response,
  }
}

export default {
  executeS3Action,
  ...GENERATED_PUT_COMMANDS,
  ...GENERATED_DELETE_COMMANDS,
  ...GENERATED_DELETE_FOLDERS_COMMANDS,
  ...GENERATED_LIST_OBJECTS_COMMANDS,
  listObjects,
  getJSONByS3Path,
  getImageByS3Path,
  uploadPublicAttachment,
  deleteObjectsByFullS3Paths,
  putObject,
  getObject,

  listObjectVersions,
  moveObject,
  copyObject,
}
