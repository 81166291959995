import FSP from '@/constants/fsp_constants'
import _ from 'lodash'

export default {
    name: 'GenericField',
    functional: true,
    props: {
        modifiers: {
            type: Array,
            default: () => []
        },
        fieldId: {
            type: String,
            required: true
        },
        fieldObj: {
            type: Object,
            required: true
        },
        preview: {
            type: Boolean,
            default: false,
        },
        fieldTypeConst: {
            type: Object,
            required: true,
        }
    },
    render: (h, ctx) => {
        const isEditable = ctx.props.modifiers.includes('editable')
        const isSubmittable = ctx.props.modifiers.includes('submittable')

        const defaultTitleAndDescriptionBind = {
            props: {
                preventHighlighting: true,
                overflowWrap: true,
                multiline: true,
                readonly: isSubmittable
            }
        }

        const modifiers = [
            ...ctx.props.modifiers,
            ctx.props.fieldId,
            ctx.props.fieldObj?.properties?.required && 'required'
        ]

     
        return h(
            'div',
            {
                class: [
                    'generic-field',
                    ...modifiers.filter(key => key).map((key) => FSP.GENERIC_FIELD_CLASS._baseClass + '--' + key)
                ]
            },
            [
                ...(!ctx.props.fieldTypeConst.WITHOUT_TITLE ? [h(
                    'f-simple-contenteditable-input',
                    _.defaultsDeep(_.cloneDeep(defaultTitleAndDescriptionBind), {
                        class: 'generic-field__title',
                        props: {
                            value: ctx.props.fieldObj.title || (isSubmittable && '...') || '',
                            placeholder: isEditable ? 'Your question here' : '',
                        },
                        on: {
                            input: v => ctx.props.fieldObj.title = v
                        }
                    })
                )] : []),
                ...(isEditable && !ctx.props.fieldTypeConst.WITHOUT_DESCRIPTION || isSubmittable && !ctx.props.fieldTypeConst.WITHOUT_DESCRIPTION && ctx.props.fieldObj.description ? [h(
                    'f-simple-contenteditable-input',
                    _.defaultsDeep(_.cloneDeep(defaultTitleAndDescriptionBind), {
                        class: 'generic-field__description',
                        props: {
                            value: ctx.props.fieldObj.description,
                            placeholder: isEditable ? 'Description (Optional)' : '',
                        },
                        on: {
                            input: v => ctx.props.fieldObj.description = v
                        }
                    })
                )] : []),

                h('div', { class: 'mb-3' }), // New spacer bellow question title/subtitle

                ctx.children
            ]
        )
    }
}