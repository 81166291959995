import Utils from '@/utils/misc'
import {EditFormJsonManageExtension} from './form_edit_form_json_manage_mixin'
import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import constants from '@/constants/constants'
import {VSpacer} from 'vuetify/lib/components'

export default ({useExtension}) => {
    useExtension(EditFormJsonManageExtension)

    return {
        modules: {
            confirmation_dialog: generic_dialog_module,
        },
        mutations: {
            CREATE_FIELD: (_, {formJson, payload: {fieldID, fieldObj}}) => formJson.fields = {
                ...formJson.fields,
                [fieldID]: fieldObj
            },
            UPDATE_FIELD: (state, {formJson, payload: {fieldID, fieldObj}}) => formJson.fields[fieldID] = fieldObj,
            DELETE_FIELD: (state, {formJson, payload: fieldID}) => delete formJson.fields[fieldID]
        },
        actions: {
            createNewFormField: ({commitFormJsonManageMutation}, fieldType) => {
                let newFieldId = Utils.generateUUID()
                commitFormJsonManageMutation('CREATE_FIELD', {
                    fieldID: newFieldId,
                    fieldObj: constants.FIELD_FROM_TYPE[fieldType].generateFieldObj()
                })
                return newFieldId
            },
            changeFieldType: ({getters, dispatch, rootGetters, commitFormJsonManageMutation}, {fieldID, newType}) => dispatch('createNewFormField', newType)
                .then(async newFieldID => {
                    const isContentMustStaySame = (getters.getFormJSON.fields[fieldID].field_type === 'MULTIPLE_SELECTION' || getters.getFormJSON.fields[fieldID].field_type === 'SINGLE_SELECTION')
                        && (getters.getFormJSON.fields[newFieldID].field_type === 'SINGLE_SELECTION' || getters.getFormJSON.fields[newFieldID].field_type === 'MULTIPLE_SELECTION')
                    commitFormJsonManageMutation('UPDATE_FIELD', {
                        fieldID: newFieldID,
                        fieldObj: {
                            ...getters.getFormJSON.fields[newFieldID],
                            title: getters.getFormJSON.fields[fieldID].title,
                            description: getters.getFormJSON.fields[fieldID].description,
                            content: isContentMustStaySame? getters.getFormJSON.fields[fieldID].content : getters.getFormJSON.fields[newFieldID].content
                        }
                    })

                    const newFieldTypeConst = constants.FIELD_FROM_TYPE[newType]
                    const currentEditingFormClone = Utils.deepCloneObject(getters.getFormJSON)

                    if (rootGetters['edit/conditional/getTargetedAndConditionedFields'][fieldID].targeted) {
                        const fieldTargetedObj = rootGetters['edit/conditional/getTargetedAndConditionedFields'][fieldID]
                        const targetOwnerClone = Utils.deepCloneObject(currentEditingFormClone.fields[fieldTargetedObj.target_owner])
                        if (fieldTargetedObj.targeted_if)
                            commitFormJsonManageMutation('UPDATE_FIELD', {
                                fieldID: fieldTargetedObj.target_owner,
                                fieldObj: {
                                    ...targetOwnerClone,
                                    condition: {
                                        ...targetOwnerClone.condition,
                                        if_target: {...targetOwnerClone.condition.if_target, field_id: newFieldID}
                                    }
                                }
                            })
                        else if (fieldTargetedObj.targeted_else)
                            commitFormJsonManageMutation('UPDATE_FIELD', {
                                fieldID: fieldTargetedObj.target_owner,
                                fieldObj: {
                                    ...targetOwnerClone,
                                    condition: {
                                        ...targetOwnerClone.condition,
                                        else_target: {
                                            ...targetOwnerClone.condition.else_target,
                                            field_id: newFieldID
                                        }
                                    }
                                }
                            })
                    }

                    commitFormJsonManageMutation('DELETE_FIELD', fieldID)

                    if (rootGetters['edit/isFormStatic'])
                        currentEditingFormClone.main_pages
                            .some(({page_id}) => {
                                return currentEditingFormClone.all_pages[page_id].rows.some(({fields}, rowIndex) => fields
                                    .some((field, fieldIndex) => {
                                        if (field.field_id === fieldID) {
                                            const emptySpaceForNewField = constants.STATIC_FORM_COLS_COUNT - fields
                                                .reduce((accum, {
                                                    field_id,
                                                    width
                                                }) => field_id === fieldID ? accum : accum + width, 0)
                                            if (
                                                ('STATIC_FORM_MIN_WIDTH' in newFieldTypeConst && newFieldTypeConst.STATIC_FORM_MIN_WIDTH <= emptySpaceForNewField)
                                                || !('STATIC_FORM_MIN_WIDTH' in newFieldTypeConst)
                                            ) {
                                                currentEditingFormClone.all_pages[page_id].rows[rowIndex].fields[fieldIndex] = {
                                                    field_id: newFieldID,
                                                    width: newFieldTypeConst.STATIC_FORM_MIN_WIDTH > field.width ? newFieldTypeConst.STATIC_FORM_MIN_WIDTH : field.width
                                                }
                                                dispatch('edit/pages/changeFormPage', {
                                                    pageID: page_id,
                                                    pageObj: currentEditingFormClone.all_pages[page_id]
                                                }, {root: true})
                                            } else {
                                                currentEditingFormClone.all_pages[page_id].rows[rowIndex].fields = currentEditingFormClone.all_pages[page_id].rows[rowIndex].fields
                                                    .filter(({field_id}) => field_id !== fieldID)
                                                currentEditingFormClone.all_pages[page_id].rows.splice(fieldIndex === 0 ? rowIndex : rowIndex + 1, 0, {
                                                    fields: [{
                                                        field_id: newFieldID,
                                                        width: newFieldTypeConst.STATIC_FORM_MIN_WIDTH
                                                    }]
                                                })
                                                dispatch('edit/pages/changeFormPage', {
                                                    pageID: page_id,
                                                    pageObj: currentEditingFormClone.all_pages[page_id]
                                                }, {root: true})
                                            }

                                            return true
                                        }
                                    })
                                )
                            })
                    else if (rootGetters['edit/isFormInteractive'])
                        currentEditingFormClone.main_pages
                            .some(({page_id}) => {
                                if (currentEditingFormClone.all_pages[page_id].field_id === fieldID) {
                                    currentEditingFormClone.all_pages[page_id].field_id = newFieldID
                                    dispatch('edit/pages/changeFormPage', {
                                        pageID: page_id,
                                        pageObj: currentEditingFormClone.all_pages[page_id]
                                    }, {root: true})
                                    return true
                                }
                            })
                }),
            changeFormField: ({commitFormJsonManageMutation}, {fieldID, fieldObj}) => commitFormJsonManageMutation('UPDATE_FIELD', {
                fieldID,
                fieldObj
            }),
            duplicateField: async ({getters, dispatch, rootGetters, commitFormJsonManageMutation}, fieldID) => {
                const currentEditingFormClone = Utils.deepCloneObject(getters.getFormJSON)
                const initialField = currentEditingFormClone.fields[fieldID]
                const newFieldTypeConst = constants.FIELD_FROM_TYPE[initialField.field_type]

                let newFieldID = Utils.generateUUID()
                const duplicatedField = Utils.deepCloneObject(getters.getFormJSON.fields[fieldID]);

                commitFormJsonManageMutation('CREATE_FIELD', {fieldID: newFieldID, fieldObj: duplicatedField})
                let newPageId = Utils.generateUUID()

                if (rootGetters['edit/isFormStatic'])
                    currentEditingFormClone.main_pages
                        .some(({page_id}) => {
                            return currentEditingFormClone.all_pages[page_id].rows
                                .some(({fields}, rowIndex) => fields.some((field, fieldIndex) => {
                                    if (field.field_id === fieldID) {
                                        const emptySpaceForNewField = constants.STATIC_FORM_COLS_COUNT - fields
                                            .reduce((accum, {width}) => accum + width, 0)

                                        if (
                                            ('STATIC_FORM_MIN_WIDTH' in newFieldTypeConst && newFieldTypeConst.STATIC_FORM_MIN_WIDTH <= emptySpaceForNewField)
                                            || emptySpaceForNewField !== 0
                                        ) {
                                            currentEditingFormClone.all_pages[page_id].rows[rowIndex].fields.splice(fieldIndex + 1, 0, {
                                                field_id: newFieldID,
                                                width: 1
                                            })
                                        } else {
                                            currentEditingFormClone.all_pages[page_id].rows.splice(rowIndex + 1, 0, {
                                                fields: [{
                                                    field_id: newFieldID,
                                                    width: getters.getFormJSON.all_pages[page_id].rows[rowIndex].fields[fieldIndex].width || constants.STATIC_FORM_COLS_COUNT
                                                }]
                                            })
                                        }

                                        dispatch('edit/pages/changeFormPage', {
                                            pageID: page_id,
                                            pageObj: currentEditingFormClone.all_pages[page_id]
                                        }, {root: true})

                                        return true
                                    }
                                }))
                        })
                else if (rootGetters['edit/isFormInteractive']) {
                    let duplicatingPageIndex = currentEditingFormClone.main_pages
                        .findIndex(({page_id}) => currentEditingFormClone.all_pages[page_id].field_id === fieldID)

                    commitFormJsonManageMutation('edit/pages/CREATE_FORM_PAGE', {
                        pageID: newPageId, pageObj: {
                            ...constants.FORM_TYPES.INTERACTIVE_FORM.MAIN_PAGE_TEMPLATE(),
                            field_id: newFieldID
                        }
                    }, {root: true})
                    commitFormJsonManageMutation('edit/pages/ADD_MAIN_PAGE', {
                        index: duplicatingPageIndex + 1,
                        pageObj: {page_id: newPageId}
                    }, {root: true})

                    await dispatch('edit/pages_navigation/setCurrentPageId', newPageId, {root: true})
                }
            },
            shallowRemoveFormField: ({commitFormJsonManageMutation}, fieldID) => commitFormJsonManageMutation('DELETE_FIELD', fieldID),

            removeFormField: async ({dispatch, getters, rootGetters}, fieldId) => {
                const removeField = async (closeDialog) => {
                    const currentEditingFormClone = Utils.deepCloneObject(getters.getFormJSON)

                    dispatch('shallowRemoveFormField', fieldId)

                    if (rootGetters['edit/isFormStatic']) {
                        for (let page of currentEditingFormClone.main_pages)
                            for (let [rowIndex, row] of Object.entries(currentEditingFormClone.all_pages[page.page_id].rows))
                                for (let [fieldIndex, field] of Object.entries(row.fields))
                                    if (field.field_id === fieldId) {
                                        row.fields.splice(fieldIndex, 1)
                                        if (!row.fields.length)
                                            currentEditingFormClone.all_pages[page.page_id].rows.splice(rowIndex, 1)
                                        await dispatch('edit/pages/changeFormPage', {
                                            pageID: page.page_id,
                                            pageObj: currentEditingFormClone.all_pages[page.page_id]
                                        }, {root: true})
                                        closeDialog ? closeDialog() : ''
                                        return
                                    }
                    } else if (rootGetters['edit/isFormInteractive']) {
                        currentEditingFormClone.main_pages.some((page, pageIndex) => {
                            if (currentEditingFormClone.all_pages[page.page_id].field_id === fieldId) {
                                dispatch('edit/pages/shallowRemoveFormPage', {
                                    pageIndex,
                                    pageID: page.page_id
                                }, {root: true})
                                closeDialog ? closeDialog() : ''
                                dispatch('edit/pages_navigation/setCurrentPageId', rootGetters['edit/getFormJSON'].home_page.page_id, {root: true})
                                return false
                            }
                        })
                    }
                }

                if (rootGetters['submissions/getSubmissionsList'].some(({rawData}) => fieldId in rawData)) {
                    dispatch('confirmation_dialog/showDialog', ({closeDialog, genActionButton}) => {
                        const dialogProps = {
                            withCloseBtn: true,
                        }
                        return {
                            title: 'Remove field?',
                            content: 'If you remove this field all collected responses to this question will be deleted as well.',
                            actions: {
                                functional: true,
                                render: h => [
                                    h(VSpacer),
                                    h(genActionButton({
                                        color: 'gray_100',
                                        onClick: closeDialog,
                                        text: 'Cancel'
                                    })),
                                    h(genActionButton({
                                        color: 'red_400',
                                        onClick: () => removeField(closeDialog),
                                        text: 'Remove'
                                    }))
                                ]
                            },
                            props: dialogProps
                        }
                    })
                } else {
                    removeField()
                }
            }
        }
    }
}