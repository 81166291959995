import { VSelect } from 'vuetify/lib/components'

export default {
    extends: VSelect,
    props: {
        appendIcon: {
            type: String,
            default: 'mdi-chevron-down'
        }
    }
}