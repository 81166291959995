<script>
  import { alert_mixin } from '@/components/Alert/alert_mixin'
  import ErrorAlertComponent from '@/components/Alert/ErrorAlertComponent'
  import LoginLayoutWithImage from '@/components/LoginComponents/LoginLayoutWithImage'

  export default {
    name: 'LoginView',
    components: {
      ErrorAlertComponent,
      LoginLayoutWithImage,
    },
    mixins: [alert_mixin],
    data: vm => ({
      signInEmail: '',
      signInPassword: '',
      valid: true,
      emailRules: [
        v => !!v || 'Email is required',
        v => vm.constants.EMAIL_REGEX.test(v) || 'Email must be valid',
      ],
      passwordRules: [v => !!v || 'Password is required'],
    }),
    computed: {
      forgotPasswordPage() {
        return { name: this.constants.PAGE_NAME_FORGOT_PASSWORD }
      },
    },
    methods: {
      signIn() {
        if (this.$refs.form.validate())
          this.promiseWithAnimation(
            this.AuthActions.signIn(
              this.signInEmail,
              this.signInPassword
            ).catch(e => this.emitErrorAlert(e.message))
          )
      },
      goToForgotPassword() {
        this.$router.push({ name: this.constants.PAGE_NAME_FORGOT_PASSWORD })
      },
      goToSignUp() {
        this.$router.push({ name: this.constants.PAGE_NAME_SIGNUP })
      },
      signInGoogle() {
        this.AuthActions.signInGoogle()
      },
    },
  }
</script>

<template>
  <v-container
    fluid
    class="h-full d-flex align-center justify-center"
  >
    <div
      id="g_id_onload"
      data-client_id="1084066207419-0gnh5okq4iuoatdp3maqe9tsu1sqjsac.apps.googleusercontent.com"
    ></div>
    <LoginLayoutWithImage>
      <template #title>Log in</template>
      <template #description>Welcome back! Please enter your details</template>
      <template #form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <ErrorAlertComponent />
          <div class="mb-2">Email</div>
          <v-text-field
            v-model="signInEmail"
            placeholder="Enter your email"
            class="rounded-lg"
            :rules="emailRules"
            outlined
            required
            @keydown="$refs.form.resetValidation()"
          />
          <div class="mb-2">Password</div>
          <v-text-field
            v-model="signInPassword"
            placeholder="Enter your password"
            class="rounded-lg"
            :rules="passwordRules"
            outlined
            type="password"
            required
          />
          <v-row align="center">
            <v-col>
              <v-checkbox
                class="ma-0"
                hide-details
              >
                <template #label>
                  <span class="subtitle-2">Remember me</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="text-end">
              <span
                class="subtitle-2 cursor-pointer text--secondary"
                @click="goToForgotPassword"
              >
                Forgot Password
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mb-n3">
              <v-btn
                color="blue_400"
                large
                rounded
                block
                @click="signIn"
              >
                Sign in
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                outlined
                class="dark_gray--text"
                large
                rounded
                block
                @click="signInGoogle"
              >
                <img
                  class="mr-4"
                  draggable="false"
                  src="/images/GoogleLogo.svg"
                />
                <span>Sign in with Google</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #jump-to>
        Don't have an account?
        <span
          class="ml-2 blue_400--text font-weight-bold cursor-pointer"
          @click="goToSignUp"
        >
          Sign Up
        </span>
      </template>
    </LoginLayoutWithImage>
  </v-container>
</template>
