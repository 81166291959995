import BillingDetailsForm from './BillingDetailsForm'

import BillingDetailsForm_Country from './Inputs/BillingDetailsForm_Country'
import BillingDetailsForm_City from './Inputs/BillingDetailsForm_City'
import BillingDetailsForm_Name from './Inputs/BillingDetailsForm_Name'
import BillingDetailsForm_Line1 from './Inputs/BillingDetailsForm_Line1'
import BillingDetailsForm_PostalCode from './Inputs/BillingDetailsForm_PostalCode'

export default {
    form: BillingDetailsForm,

    name: BillingDetailsForm_Name,
    country: BillingDetailsForm_Country,
    city: BillingDetailsForm_City,
    line1: BillingDetailsForm_Line1,
    postal_code: BillingDetailsForm_PostalCode
}