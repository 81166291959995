<template>
    <span v-if="!submissionObj.value">-</span>
    <span v-else-if="isTypingAllowed">{{ submissionObj.value }}</span>
    <v-menu v-else
            nudge-top="25"
            nudge-right="75"
            open-on-hover
            top
            eager
            transition="slide-y-reverse-transition"
            open-delay="200"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-img
                    v-bind="attrs"
                    v-on="on"
                    max-width="200"
                    max-height="34"
                    contain
                    :src="signatureDataURL"
                    class="cursor-pointer"
            >
                <template #placeholder>
                    <f-circle-loader size="3"/>
                </template>
            </v-img>
        </template>
        <v-card class="pa-6">
            <v-img
                    contain
                    :src="signatureDataURL"
            >
                <template #placeholder>
                    <f-circle-loader size="3"/>
                </template>
            </v-img>
        </v-card>

    </v-menu>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__Signature",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            signatureDataURL: undefined
        }),
        computed: {
            isTypingAllowed() {
                return this.sourceObj.properties.allow_typing
            }
        },
        created() {
            if (!this.isTypingAllowed && this.submissionObj.value !== null)
                this.StorageActions.getImageByS3Path(this.constants.S3_UPLOADS_FOLDER_PATH + this.submissionObj.value)
                    .then(response => this.signatureDataURL = response.dataURL)
        }
    }
</script>