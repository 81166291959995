<template>
    <v-container class="not-found h-full" fluid>
        <svg class="not-found__image" width="183" height="151" viewBox="0 0 183 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M158.22 76.8444C158.22 117.507 125.256 150.47 84.5939 150.47C43.9316 150.47 10.9683 117.507 10.9683 76.8444C10.9683 36.1821 43.9316 3.21875 84.5939 3.21875C125.256 3.21875 158.22 36.1821 158.22 76.8444Z"
                  fill="#E3EAFF"/>
            <rect x="1.31201" y="12.8746" width="167.368" height="122.307" rx="10"
                  fill="url(#paint0_linear_2891_29913)" stroke="black" stroke-opacity="0.08"/>
            <path d="M103.397 88.6521C100.756 99.0466 90.1877 105.332 79.7932 102.69C69.3987 100.049 63.1137 89.4807 65.7552 79.0862C68.3968 68.6916 78.9646 62.4066 89.3592 65.0482C99.7537 67.6898 106.039 78.2576 103.397 88.6521Z"
                  stroke="#A4B4E4" stroke-width="2"/>
            <path d="M76 90.9999C79.0618 87.2255 87.0522 84.3044 94 91" stroke="#A4B4E4" stroke-width="2"
                  stroke-linecap="round"/>
            <path d="M80.377 79.3394C80.0283 80.7113 78.6335 81.5409 77.2615 81.1922C75.8896 80.8436 75.06 79.4487 75.4087 78.0768C75.7573 76.7048 77.1522 75.8753 78.5241 76.2239C79.8961 76.5726 80.7256 77.9674 80.377 79.3394Z"
                  fill="#A4B4E4"/>
            <path d="M93.5899 79.3394C93.2412 80.7113 91.8464 81.5409 90.4744 81.1922C89.1025 80.8436 88.2729 79.4487 88.6216 78.0768C88.9702 76.7048 90.3651 75.8753 91.737 76.2239C93.109 76.5726 93.9385 77.9674 93.5899 79.3394Z"
                  fill="#A4B4E4"/>
            <circle cx="15.9678" cy="26.5305" r="2" fill="#FC5BCF"/>
            <circle cx="23.9678" cy="26.5305" r="2" fill="#4D72F5"/>
            <circle cx="31.9678" cy="26.5305" r="2" fill="#2FBBEB"/>
            <line x1="1.31201" y1="38.1235" x2="168.68" y2="38.1235" stroke="#EBEBEB"/>
            <path d="M178.779 113.014C177.244 112.762 176.204 111.314 176.455 109.779C176.706 108.244 178.155 107.204 179.689 107.455C181.224 107.706 182.265 109.154 182.014 110.689C181.762 112.224 180.314 113.265 178.779 113.014Z"
                  fill="#E4EAFE"/>
            <path d="M32.2544 6.01361C30.7195 5.76228 29.6789 4.31422 29.9302 2.77927C30.1816 1.24432 31.6296 0.203737 33.1646 0.455062C34.6995 0.706387 35.7401 2.15445 35.4888 3.6894C35.2375 5.22435 33.7894 6.26493 32.2544 6.01361Z"
                  fill="#E4EAFE"/>
            <defs>
                <linearGradient id="paint0_linear_2891_29913" x1="87.1926" y1="12.8746" x2="87.1926" y2="137.487"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="#FEFEFF"/>
                </linearGradient>
            </defs>
        </svg>
        <p class="not-found__message">We couldn't find what you are looking for</p>
        <v-btn
                color="blue_400"
                rounded
                outlined
                :to="{name: constants.PAGE_NAME_DASHBOARD}"
        >Go to Dashboard</v-btn>
    </v-container>
</template>

<script>
    export default {
        name: "NotFound404"
    }
</script>

<style lang="scss" scoped>
    .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__image {
            margin-bottom: 25px;
        }

        &__message {
            font-size: 19px;
            line-height: 200%;
            letter-spacing: -4%;
            font-weight: 500;
            color: var(--v-gray_900-base);
            margin-bottom: 40px;
        }
    }
</style>