import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import { FieldPropertyNumberInput } from '@/components/Fields/FieldConstClasses/field_property_input'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'


class TextFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'TEXT',
            READABLE_NAME: 'Text',
            WITHOUT_TITLE: true,
            WITHOUT_DESCRIPTION: true,
            FOLDER_NAME: 'Text',

            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    text: ''
                }
            }),
            FSP: new FieldFSPDefinition({
                FSP_FIELD_TYPE: 'Text',
                FIELD_VIEW_CLASS: 'text-field-view',
                VARIABLES: [
                    {
                        selector: ['.ql-container'],
                        external: true,
                        properties: {
                            border: {
                                default: {
                                    fn: 'none',
                                }
                            }
                        }
                    },
                    {
                        selector: ['.ql-toolbar'],
                        external: true,
                        properties: {
                            background: {
                                default: {
                                    fn: '#fff',
                                }
                            },
                            border: {
                                default: {
                                    fn: 'none',
                                }
                            }
                        }
                    },
                    {
                        selector: ['.quillWrapper'],
                        external: true,
                        properties: {
                            border: {
                                default: {
                                    fn: '1px solid #fff'
                                },
                            },
                            'border-radius': {
                                default: {
                                    fn: '8px',
                                }
                            },
                        }
                    },
                    {
                        selector: ['.ql-editor'],
                        external: true,
                        properties: {
                            'min-height': {
                                'default--editable': {
                                    fn: '60px',
                                },
                                'default--submittable': {
                                    fn: 'auto',
                                }
                            },
                            padding: {
                                'default--submittable': {
                                    fn: 0,
                                }
                            },

                        }
                    }
                ]
            }),
            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'max_text',
                    name: 'Max length',
                    tooltip: 'Choose the maximum allowed length for the answer',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyNumberInput({
                            default: 64
                        })
                    )
                }),
                new CustomFieldPropertyConst({
                    key: 'autocomplete',
                    name: 'Autocomplete'
                })
            ]),
        })
    }
}


export default new TextFieldTypeConst()