<template>
    <span class="red_400--text">
       We can't set this condition since it's a required question.
        <v-menu
                max-width="450"
                min-width="450"
                :close-on-content-click="false"
                auto
        >
            <template #activator="{on}">
                <span class="blue_400--text cursor-pointer" v-on="on">
                    Edit requirements
                </span>
            </template>
            <template #default>
                <v-card>
                    <v-card-text class="pa-6">
                        <div class="mb-3 nowrap-ellipsis-text">
                            <span class="grey_700--text">
                                {{ getTargetedAndConditionedFields[fieldId].formattedLocation }}
                            </span>
                            <span class="subtitle-1 bluish_gray_400--text">
                                {{ getTargetedAndConditionedFields[fieldId].fieldObj.title }}
                            </span>
                        </div>
                        <div>
                            <span class="subtitle-1 bluish_gray_400--text mr-4">Required</span>
                            <f-switch v-model="requiredModel"/>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </v-menu>
    </span>
</template>

<script>
    import { mapGetters } from '@/xstore'

    export default {
        name: "TargetErrorMessageFieldRequired",
        props: {
            fieldId: String
        },
        computed: {
            ...mapGetters('edit', [
                'getFormJSON'
            ]),
            ...mapGetters('edit/conditional', [
                'getTargetedAndConditionedFields'
            ]),
            requiredModel: {
                get() {
                    return this.getFormJSON.fields[this.fieldId].properties.required
                },
                set(v) {
                    this.$store.dispatch('edit/fields/changeFormField', {
                        fieldID: this.fieldId,
                        fieldObj: {
                            ...this.getFormJSON.fields[this.fieldId],
                            properties: {
                                ...this.getFormJSON.fields[this.fieldId].properties,
                                required: v
                            }
                        }
                    })
                }
            }
        }
    }
</script>