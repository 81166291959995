var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "star-rating-field-view star-rating-field-view--full-single-answer-analytics"
  }, [!_vm.submissionObj.value ? _c('span', [_vm._v("-")]) : _vm.isEmojiScale ? _c('div', {
    staticClass: "star-rating-field-view__layout star-rating-field-view__layout--emoji-scale"
  }, _vm._l(_vm.fieldConstProperties.emoji_scale.input_group.default.sets[_vm.sourceObj.properties.emoji_scale], function (emoji, index) {
    return _c('div', {
      key: 'emoji-scale' + index,
      staticClass: "star-rating-field-view__item",
      class: index === _vm.submissionObj.value && 'star-rating-field-view__item--selected'
    }, [_vm._v(_vm._s(emoji) + " ")]);
  }), 0) : _vm.isScaleIcon ? _c('div', {
    staticClass: "star-rating-field-view__layout star-rating-field-view__layout--scale-icon"
  }, _vm._l(_vm.submissionObj.value + 1, function (_, index) {
    return _c('div', {
      key: 'scale-icon' + index,
      staticClass: "star-rating-field-view__item"
    }, [_c('LottieIconPlayer', {
      attrs: {
        "json": _vm.fieldConstProperties.scale_icon.input_group.default.emojis[_vm.sourceObj.properties.scale_icon],
        "active": _vm.submissionObj.value !== null && index <= _vm.submissionObj.value
      }
    })], 1);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }