export default {
    name: 'InteractiveHomePageImageHolder',
    functional: true,
    props: {
        imageUrl: String,
        highlightImageArea: {
            type: Boolean,
            default: false,
        },
    },
    render: (h, ctx) => h(
        'div',
        {
            class: {
                'interactive-form-home-page__image': true,
                'interactive-form-home-page__image--hovered': ctx.props.highlightImageArea,
            },
        },
        [
            ctx.props.imageUrl &&
            h(
                'img',
                {
                    attrs: {
                        src: ctx.props.imageUrl,
                    }
                },
            ),
            ctx.scopedSlots?.['image-content']?.()
        ]
    )
}