<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49057 9.79687L1.50943 9.79687C1.37432 9.79687 1.24475 9.71785 1.14921 9.5772C1.05367 9.43654 1 9.24578 1 9.04687C1 8.84795 1.05367 8.65719 1.14921 8.51654C1.24475 8.37588 1.37432 8.29687 1.50943 8.29687L8.49057 8.29687C8.62568 8.29687 8.75525 8.37588 8.85079 8.51654C8.94633 8.65719 9 8.84795 9 9.04687C9 9.24578 8.94633 9.43654 8.85079 9.5772C8.75525 9.71785 8.62568 9.79687 8.49057 9.79687Z"
              stroke="none"/>
        <path d="M8.49057 13.5L1.50943 13.5C1.37432 13.5 1.24475 13.421 1.14921 13.2803C1.05367 13.1397 1 12.9489 1 12.75C1 12.5511 1.05367 12.3603 1.14921 12.2197C1.24475 12.079 1.37432 12 1.50943 12L8.49057 12C8.62568 12 8.75525 12.079 8.85079 12.2197C8.94633 12.3603 9 12.5511 9 12.75C9 12.9489 8.94633 13.1397 8.85079 13.2803C8.75525 13.421 8.62568 13.5 8.49057 13.5Z"
              stroke="none"/>
        <path d="M8.49057 17L1.50943 17C1.37432 17 1.24475 16.921 1.14921 16.7803C1.05367 16.6397 1 16.4489 1 16.25C1 16.0511 1.05367 15.8603 1.14921 15.7197C1.24475 15.579 1.37432 15.5 1.50943 15.5L8.49057 15.5C8.62568 15.5 8.75525 15.579 8.85079 15.7197C8.94633 15.8603 9 16.0511 9 16.25C9 16.4489 8.94633 16.6397 8.85079 16.7803C8.75525 16.921 8.62568 17 8.49057 17Z"
              stroke="none"/>
        <path d="M22.6 8C22.6 7.66863 22.3314 7.4 22 7.4H13C12.6686 7.4 12.4 7.66863 12.4 8V17C12.4 17.3314 12.6686 17.6 13 17.6H22C22.3314 17.6 22.6 17.3314 22.6 17V8Z"
              fill="none" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5 12.5L16.4877 14L19.5 11.5" fill="none" stroke-width="1.2" stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>

</template>

<script>
    export default {
        name: "SortDefaultOrderIcon"
    }
</script>