<template>
    <div class="interactive-form-action-button-block">
        <div class="interactive-form-action-button-block__button" @click="$emit('click')">
            <slot name="button-content">
            </slot>
        </div>
        <span v-if="withPressEnter" class="interactive-form-action-button-block__press-enter">
            Press <span class="font-weight-bold">Enter ↵</span>
        </span>
    </div>
</template>

<script>

    export default {
        name: "InteractiveFormActionButtonBlock",
        props: {
            withPressEnter: {
                type: Boolean,
                default: false
            }
        },
    }
</script>