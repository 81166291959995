var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "47",
      "height": "47",
      "viewBox": "0 0 47 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "46",
      "y": "1",
      "width": "45",
      "height": "45",
      "rx": "6",
      "transform": "rotate(90 46 1)",
      "fill": "url(#paint0_linear_2720_29722)",
      "stroke": "#E2E8F4"
    }
  }), _c('circle', {
    attrs: {
      "cx": "14.0695",
      "cy": "24.1989",
      "r": "3.06947",
      "fill": "#2FBBEB"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M15.6198 23.0816C15.7461 23.2205 15.7373 23.4367 15.6 23.5645L13.66 25.3717C13.5916 25.4353 13.5004 25.4677 13.4077 25.4611C13.3151 25.4546 13.2292 25.4096 13.1703 25.337L12.5044 24.5155C12.3861 24.3696 12.407 24.1542 12.5512 24.0345C12.6954 23.9147 12.9082 23.9359 13.0265 24.0819L13.4656 24.6236L15.1427 23.0615C15.2799 22.9337 15.4936 22.9426 15.6198 23.0816Z",
      "fill": "white"
    }
  }), _c('rect', {
    attrs: {
      "width": "17.3445",
      "height": "1.87508",
      "rx": "0.937539",
      "transform": "matrix(-1 0 0 1 37 23.2388)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "21.7933",
      "height": "1.84",
      "rx": "0.92",
      "transform": "matrix(-1 0 0 1 33.0996 12)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "10.1293",
      "height": "1.84168",
      "rx": "0.920842",
      "transform": "matrix(-1 0 0 1 21.4375 15.9902)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "x": "11",
      "y": "32.1792",
      "width": "8.90147",
      "height": "2.45558",
      "rx": "1.22779",
      "fill": "url(#paint1_linear_2720_29722)"
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_2720_29722",
      "x1": "69.0907",
      "y1": "1",
      "x2": "69.0907",
      "y2": "46.8482",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_2720_29722",
      "x1": "10.9813",
      "y1": "33.4071",
      "x2": "19.8993",
      "y2": "33.4071",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3D66F6"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#6789FF"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }