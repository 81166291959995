import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

import constants from '@/constants/constants'

export default {
  async signIn(email, password) {
    return new Promise((resolve, reject) => {
      Auth.signIn(email, password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, password, {
              email,
            }).then(() => {
              AmplifyEventBus.$emit('authState', 'signedIn')
              resolve(user)
            })
          } else {
            AmplifyEventBus.$emit('authState', 'signedIn')
            resolve(user)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  async signUp(name, email, password) {
    return new Promise((resolve, reject) => {
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          picture: constants.DEFAULT_AVATAR,
          name,
        },
      })
        .then(user => {
          resolve(user.user.username)
        })
        .catch(e => {
          if (e.name === 'UsernameExistsException') {
            console.log(e.message)
            Auth.resendSignUp(email)
              .then(() => resolve(email))
              .catch(e => {
                if (e.message === 'User is already confirmed.')
                  reject({ message: 'User is already exist' })
                reject(e)
              })
          } else reject(e)
        })
    })
  },
  async confirmSignUp(username, signUpConfirmationCode, appSumoUser = false) {
    const options = appSumoUser
      ? {
          clientMetadata: {
            appSumoUser: 'true',
          },
        }
      : {}

    return Auth.confirmSignUp(username, signUpConfirmationCode, options)
  },
  async resendConfirmationCode(email) {
    return Auth.resendSignUp(email)
  },
  async signOut() {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(() => {
          AmplifyEventBus.$emit('authState', 'signedOut')
          resolve()
        })
        .catch(e => reject(e))
    })
  },
  async forgotPassword(username) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(username)
        .then(() => resolve())
        .catch(e => reject(e))
    })
  },
  async forgotPasswordSubmit(username, code, newPassword) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => resolve())
        .catch(e => reject(e))
    })
  },
  // async deleteAccount() {
  //     return new Promise((resolve, reject) => {
  //         executeGraphQLMutationDelete(deleteUser, {input: {id: store.getters.getCurrentCognitoUser.username}})
  //             .then(() => {
  //                 return Auth.currentAuthenticatedUser()
  //             })
  //             .then(user => {
  //                 user.deleteUser((error, data) => {
  //                     console.log(data)
  //                     if (error)
  //                         throw error
  //                 })
  //             })
  //             .then(() => {
  //                 return store.dispatch('authUser')
  //             })
  //             .then(() => {
  //                 return AmplifyEventBus.$emit('authState', 'signedOut')
  //             })
  //             .then(() => resolve())
  //             .catch(e => reject(e))
  //     })
  // },

  // async changeEmail() {
  //     // eslint-disable-next-line no-unused-vars
  //     return new Promise((resolve, reject) => {
  //         this.updateUserAttrs({email: 'fdasfasfdsfdsfsda@gmail.com'})
  //     })
  // },
  async changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPassword, newPassword))
        .then(resolve)
        .catch(reject)
    })
  },

  async updateUserAttrs(attrs) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.updateUserAttributes(user, attrs)
        })
        .then(resolve)
        .catch(reject)
    })
  },

  async signInGoogle() {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({ provider: 'Google' }).then(resolve).catch(reject)
    })
  },
}
