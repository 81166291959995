import SharedView from "@/components/Fields/FieldsViews/Address/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";
import constants from "@/constants/constants";

export default {
    name: 'Preview__Address',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        return h(
            SharedView,
            {
                props: {
                    preview: true,
                    addressFields: ctx.props.fieldObj.properties.full_address_fields,
                    placeholders: constants.FIELD_FROM_TYPE.ADDRESS.UTIL.placeholders,
                    isFormStaticProp: ctx.props.isFormStatic,
                }
            }
        )
    }
}