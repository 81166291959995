import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { FieldTypeBasePropertiesConstWithHide } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithComplexConstructor,
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyImageUploadInput,
    FieldPropertyNumberInput
} from '@/components/Fields/FieldConstClasses/field_property_input'

class ImageFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'IMAGE',
            READABLE_NAME: 'Image',
            ICON_NAME: 'image_video',

            FOLDER_NAME: 'Image',

            ASSOCIATE_WITH_PAID_FEATURE: 'video_image_fields',

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithHide([
                new CustomFieldPropertyConst({
                    key: 'image_content',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyImageUploadInput({
                            default: {
                                url: '',
                                naturalWidth: 0,
                                naturalHeight: 0
                            },
                            max_size: 10
                        })
                    )
                }),
                new CustomFieldPropertyConst({
                    key: 'image_size',
                    name: 'Image Size',
                    enabled_by_default: true,
                    always_enabled: true,
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        width: new FieldPropertyNumberInput({
                            label: 'Width',
                            half_width: true,
                            default: 100,
                            append_inner: '%',
                            validator: selfValue => 0 <= selfValue && selfValue <= 100
                        }),
                        // height:  new FieldPropertyNumberInput({
                        //     label: 'Height',
                        //     half_width: true,
                        //     default: 100,
                        //     append_inner: '%',
                        //     validator: selfValue => 0 <= selfValue && selfValue <= 100
                        // })
                    })
                })
            ])
        })
    }
}

export default new ImageFieldTypeConst()