<template>
    <div>
        <div class="d-flex align-center">
            <slot name="header"/>
            <v-spacer/>
            <div class="switcher d-flex align-center">
                <span class="caption mr-2 gray_500--text">Show Full Text</span>
                <f-switch v-model="showFullText"/>
            </div>
        </div>
        <div class="smart-paragraph-field-view-full-single-answer">
            <span v-if="showFullText" class="smart-paragraph-field-view-full-single-answer__content">
                <template v-for="(element, key) in sourceObj.content">
                    <template v-if="element.type === 'text'">{{ element.value }}</template>
                    <br v-else-if="element.type === 'break'" :key="key">
                    <span
                            v-else-if="element.type === 'variable'"
                            class="smart-paragraph-field-view-full-single-answer-variable-container"
                            :key="key"
                            v-html="submissionObj.value[element.variable_id]"
                    ></span>
                </template>
            </span>
            <div v-else class="smart-paragraph-field-view-full-single-answer__variables-table-wrapper">
                <table>
                    <tbody>
                        <tr
                                v-for="({variable_id}) in variablesList"
                                :key="'tr-' + variable_id"
                        >
                            <td class="smart-paragraph-field-view-full-single-answer__table-variable-name">
                                {{ sourceObj.properties.variables_list[variable_id] || 'Untitled variable' }}
                            </td>
                            <td class="smart-paragraph-field-view-full-single-answer__table-variable-value"
                                v-html="submissionObj.value[variable_id]"
                            >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        BUILTIN_HEADER: true,
        name: "FullSingleAnswer__SmartParagraph",
        props: {
            submissionObj: Object,
            sourceObj: Object
        },
        data: () => ({
            showFullText: true
        }),
        computed: {
            variablesList() {
                return this.sourceObj.content
                    .filter(({type}) => type === 'variable')
            }
        }
    }
</script>