var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.option ? _c('div', {
    staticClass: "dropdown-field-view-single-answer"
  }, [_vm.isReadyList ? _c(_vm.currentReadyList.ICON_COMPONENT.NAME, _vm._b({
    tag: "component",
    staticClass: "mr-2"
  }, 'component', _vm.currentReadyList.ICON_COMPONENT.BIND(_vm.option), false)) : _vm._e(), _vm._v(" " + _vm._s(_vm.option.name ? _vm.option.name : _vm.option) + " ")], 1) : _c('span', [_vm._v("-")]);
}
var staticRenderFns = []

export { render, staticRenderFns }