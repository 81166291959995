<template>
    <div class="summary-hidden-answers-count">
        <v-tooltip
                content-class="tooltip-top pa-5"
                color="gray_900"
                top
                eager
                transition="none"
                max-width="320"
                nudge-top="5"
        >
            <template #activator="{ on }">
                <span v-on="on">
                    <InfoIcon/>
                    {{hiddenCount}} Hidden
                </span>
            </template>
            <template #default>
                <p class="subtitle-1 font-weight-semi-bold mb-2">{{hiddenCount}} Hidden Responses</p>
                <p class="subtitle-2 font-weight-regular text-pre-wrap">These responses are hidden because you have removed the options of them in the published form.</p>
                <slot/>
            </template>
        </v-tooltip>
    </div>
</template>

<script>
    export default {
        name: "SummaryHiddenAnswers",
        components: {
            InfoIcon: () => import('vue-feather-icons/icons/InfoIcon')
        },
        props: {
            hiddenCount: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-hidden-answers-count {
        display: flex;
        align-items: center;
        justify-content: end;
        color: var(--v-gray_400-base);
        font-size: 13px;

        cursor: help;

        * {
            display: flex;
            align-items: center;
        }

        svg {
            width: 19px;
            stroke-width: 1;
            margin-right: 7px;
        }
    }
</style>