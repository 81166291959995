<template>
  <FormParametersContentTemplate>
    <template #title>
      <v-icon class="dark_gray--text ml-2">$feather</v-icon>
      Style
    </template>
    <template #content>
      <div class="style-preferences-content">
        <div v-if="isFormStatic" class="style-preferences-content__layout-style">
          <FormParametersSubheader>Layout</FormParametersSubheader>
          <div class="style-preferences-content__layout-style-selector">
            <f-checkbox :value="currentLayoutStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN"
              @input="changeFormAttrs({ layout_style: constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN })"
              radio>
              <template #label>
                <span>Full Screen</span>
                <v-spacer />
                <LayoutFullScreenImage
                  :active="currentLayoutStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.FULL_SCREEN" />
              </template>
            </f-checkbox>
            <f-checkbox :value="currentLayoutStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT"
              @input="changeFormAttrs({ layout_style: constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT })" radio>
              <template #label>
                <span>Compact</span>
                <v-spacer />
                <LayoutCompactImage
                  :active="currentLayoutStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_STYLES.COMPACT" />
              </template>
            </f-checkbox>
          </div>
          <div class="mt-3">
            <FormParametersSubheader>HEADER</FormParametersSubheader>
          </div>

          <div class="style-preferences-content__layout-style-selector">
            <f-checkbox
                :value="currentLayoutHeaderStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE"
                @input="onChangeVisible"
                checkbox
            >
              <template #label>
                <span>Show Header</span>
                <v-spacer/>
              </template>
            </f-checkbox>
          </div>

          <div class="style-preferences-content__layout-style-selector">
            <f-checkbox
                :value="currentLayoutLogoStyle === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE"
                @input="onChangeVisibleLogo"
                checkbox
            >
              <template #label>
                <span>Show Logo</span>
                <v-spacer/>
              </template>
            </f-checkbox>
          </div>
        </div>
        <div v-if="isFormInteractive"
          class="style-preferences-content__layout-style style-preferences-content__layout-style--interactive"
          :class="!isHomePageHasImage && 'style-preferences-content__layout-style--interactive--homepage-image-picker'">
          <FormParametersSubheader>Homepage {{ isHomePageHasImage ? 'layout' : 'image' }}</FormParametersSubheader>
          <div v-if="isHomePageHasImage" class="style-preferences-content__layout-style-types">
            <div v-for="({ key, icon }) in constants.FORM_TYPES.INTERACTIVE_FORM.INTERACTIVE_HOME_PAGE_LAYOUTS" :class="[
          'style-preferences-content__layout-style-types-icon',
          constants.FORM_TYPES.INTERACTIVE_FORM.INTERACTIVE_HOME_PAGE_LAYOUTS_BY_KEY[getFormJSON.all_pages[getFormJSON.home_page.page_id].layout].key === key && 'style-preferences-content__layout-style-types-icon--selected'
        ]" :key="key"
              @click="$store.dispatch('edit/pages/changeFormPage', { pageID: getFormJSON.home_page.page_id, pageObj: { ...getFormJSON.all_pages[getFormJSON.home_page.page_id], layout: key } })">
              <component :is="icon"></component>
            </div>
          </div>
          <v-btn v-else class="style-preferences-content__layout-style-add-homepage-image-btn gray_500--text"
            color="gray_100" rounded @click="$store.dispatch('edit/homepage_image_preview/openImagePicker')">
            Add
          </v-btn>
        </div>
        <v-divider />
        <v-slide-x-reverse-transition mode="out-in">
          <StylePreferencesThemeEditor v-if="isThemeEditorActive" />
          <StylePreferencesThemesStore v-else />
        </v-slide-x-reverse-transition>
      </div>

      <GenericStoreDialog store-module-path="edit/FSP_themes/confirmation_dialog" />
    </template>
  </FormParametersContentTemplate>
</template>

<script>
import { mapActions, mapGetters } from '@/xstore';

export default {
  name: "FormStylePreferences",
  components: {
    FormParametersContentTemplate: () => import('./Elements/FormParametersContentTemplate'),
    FormParametersSubheader: () => import('./Elements/FormParametersSubheader'),
    LayoutFullScreenImage: () => import('./Elements/LayoutFullScreenImage'),
    LayoutCompactImage: () => import('./Elements/LayoutCompactImage'),

    StylePreferencesThemesStore: () => import('./FormStylePreferencesElements/StylePreferencesThemesStore'),
    StylePreferencesThemeEditor: () => import('./FormStylePreferencesElements/StylePreferencesThemeEditor'),
    ImagePicker: () => import('@/components/Elements/ImagePicker/ImagePicker'),
    GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog'),
    FormParametersHeader: () => import('./Elements/FormParametersHeader'),
  },
  computed: {
    ...mapGetters('edit', [
      'getFormJSON',
      'isFormStatic',
      'isFormInteractive',
      'isHomePageHasImage'
    ]),
    ...mapGetters('edit/FSP', [
      'getStylePreferences',
    ]),
    ...mapGetters('edit/FSP_themes/editing', [
      'isThemeEditorActive'
    ]),

    currentLayoutStyle() {
      if (this.isFormStatic)
        return this.getFormJSON.layout_style
      return ''
    },

    currentLayoutHeaderStyle() {
      if (this.isFormStatic)
        return this.getFormJSON.layout_header_style
      return '';
    },
    currentLayoutLogoStyle() {
      if (this.isFormStatic)
        return this.getFormJSON.layout_logo_style
      return ''
    },
  },
  methods: {
    ...mapActions('edit', [
      'updateStylePreferences',
      'changeFormAttrs'
    ]),
    onChangeVisible(event) {
      if (event) {
        this.changeFormAttrs({layout_header_style: this.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.VISIBLE})
      } else {
        this.changeFormAttrs({layout_header_style: this.constants.FORM_TYPES.STATIC_FORM.LAYOUT_HEADER_STYLES.INVISIBLE})
      }
    },
    onChangeVisibleLogo(event) {
      if (event) {
        this.changeFormAttrs({layout_logo_style: this.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE})
      } else {
        this.changeFormAttrs({layout_logo_style: this.constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE})
      }
    }
  }
}
</script>

<style lang="scss">
.show-header .pb-2, .show-logo .pb-2  {
   padding-bottom: 0 !important;
}
.form-parameters-option.mb-8.show-header {
    margin-top: 20px;
}
.style-preferences-content {
  padding: 3px;
  width: 300px;

  display: flex;
  flex-direction: column;
  min-height: 1px;

  .form-parameters-option.mb-8 {
    margin-bottom: 15px !important;
  }

  &__layout-style {
    padding-top: 20px;
    padding-bottom: 20px;


    &--interactive {
      &--homepage-image-picker {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-parameters-subheader {
          margin-bottom: 0 !important;
          font-size: 14px !important;
          font-weight: 400 !important;
        }

        .style-preferences-content__layout-style-add-homepage-image-btn {
          padding: 4px 18px !important;
          height: fit-content !important;

          span {
            font-size: 12px !important;
            line-height: 12px !important;
          }

        }
      }

      .style-preferences-content__layout-style-types {
        display: flex;
        column-gap: 20px;
        flex-wrap: wrap;
        row-gap: 13px;

        &-icon {
          flex: calc(33.333% - 20px);
          color: #CED2D8;
          cursor: pointer;

          rect {
            transition: color 0.3s;
            color: var(--v-gray_300-base);
          }

          path {
            transition: color 0.3s;
            color: var(--v-gray_100-base);
          }

          &--selected {
            rect {
              color: var(--v-blue_400-base);
            }

            path {
              color: var(--v-blue_100-base);
            }

            .border {
              stroke-width: 2;
            }
          }
        }
      }
    }
  }

  &__layout-style-selector {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 15px;

    .f-checkbox {
      &__slot {
        flex-grow: 1;
      }

      &__label {
        font-size: 14px;
        line-height: 17px;
        color: var(--v-grayscale_black-base);
      }
    }
  }
}
</style>