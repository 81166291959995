<template>
  <svg width="78" height="53" viewBox="0 0 78 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.913086" y="0.5" width="76" height="52" rx="7.45652" fill="white"/>
    <path
        d="M49 16C49 14.8954 48.1046 14 47 14H31C29.8954 14 29 14.8954 29 16V24C29 25.1046 29.8954 26 31 26H47C48.1046 26 49 25.1046 49 24V16Z"
        fill="currentColor"/>
    <rect x="29.0543" y="29" width="19.8913" height="2.65217" rx="1.32609" fill="currentColor"/>
    <rect x="29" y="33.6523" width="20" height="2.65" rx="1.325" fill="currentColor"/>
    <rect x="35.6848" y="38.3027" width="6.63043" height="2.65217" rx="1.32609" fill="currentColor"/>
    <rect class="border" x="0.913086" y="0.5" width="76" height="52" rx="7.45652" stroke-width="1" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ContentCenterSmallImageCenter',
}
</script>

<style scoped>

</style>