export default {
    props: {
        propertyName: {
            type: String,
            required: true
        },
        propertyValue: [String, Number, Boolean, Array, Object],
        fieldPropertiesObj: {
            type: Object,
            required: true
        },
        inputName: {
            type: String,
            required: true
        },
        inputConst: {
            type: Object,
            required: true
        },
        inputValue: [String, Number, Boolean, Array, Object]
    },
    computed: {
        label() {
            if (this.inputConst.label)
                return this.inputConst.label
            else if (this.inputConst.dynamic_label)
                return this.inputConst.dynamic_label(this.inputValue, this.propertyValue, this.fieldPropertiesObj)
        }
    },
    methods: {
        validateInputValue(value) {
            if (this.inputConst.validator)
                return this.inputConst.validator(value, this.propertyValue, this.fieldPropertiesObj)
            return true
        },
        input(value) {
            if (this.$options.VALUE_VALIDATOR)
                value = this.$options.VALUE_VALIDATOR(value)

            this._input(value)
        },
        _input(value) {
            this.$emit('input', value)
        }
    }
}