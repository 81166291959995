/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteForm = /* GraphQL */ `
  mutation DeleteForm($id: String!) {
    deleteForm(id: $id) {
      id
      name
      path
      previewImage
      publishedVersionID
      ownerID
      createdAt
      updatedAt
      FSPThemeID
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm($createFormInput: CreateFormInput!) {
    createForm(createFormInput: $createFormInput) {
      id
      name
      path
      previewImage
      publishedVersionID
      ownerID
      createdAt
      updatedAt
      FSPThemeID
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm($updateFormInput: UpdateFormInput!) {
    updateForm(updateFormInput: $updateFormInput) {
      id
      name
      path
      previewImage
      publishedVersionID
      ownerID
      createdAt
      updatedAt
      FSPThemeID
    }
  }
`;
export const createSubmissionMetrics = /* GraphQL */ `
  mutation CreateSubmissionMetrics($formID: String!, $started: Boolean!) {
    createSubmissionMetrics(formID: $formID, started: $started) {
      id
      formID
      createdAt
      startedAt
      submitted
    }
  }
`;
export const updateSubmissionMetrics = /* GraphQL */ `
  mutation UpdateSubmissionMetrics(
    $id: String!
    $startedAt: Int
    $submitted: Boolean
  ) {
    updateSubmissionMetrics(
      id: $id
      startedAt: $startedAt
      submitted: $submitted
    ) {
      id
      formID
      createdAt
      startedAt
      submitted
    }
  }
`;
export const deleteSubmissions = /* GraphQL */ `
  mutation DeleteSubmissions($ids: [String!]!) {
    deleteSubmissions(ids: $ids) {
      id
      formID
      publishedVersionID
      rawData
      formOwnerID
      createdAt
    }
  }
`;
export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission($createSubmissionInput: CreateSubmissionInput!) {
    createSubmission(createSubmissionInput: $createSubmissionInput) {
      id
      formID
      publishedVersionID
      rawData
      formOwnerID
      createdAt
    }
  }
`;
export const createFSPTheme = /* GraphQL */ `
  mutation CreateFSPTheme($name: String!, $rawData: String!) {
    createFSPTheme(name: $name, rawData: $rawData) {
      id
      name
      ownerID
      rawData
      relatedForms {
        id
        name
        path
        previewImage
        publishedVersionID
        ownerID
        createdAt
        updatedAt
        FSPThemeID
      }
    }
  }
`;
export const updateFSPTheme = /* GraphQL */ `
  mutation UpdateFSPTheme($id: String!, $name: String!, $rawData: String!) {
    updateFSPTheme(id: $id, name: $name, rawData: $rawData) {
      id
      name
      ownerID
      rawData
      relatedForms {
        id
        name
        path
        previewImage
        publishedVersionID
        ownerID
        createdAt
        updatedAt
        FSPThemeID
      }
    }
  }
`;
export const deleteFSPTheme = /* GraphQL */ `
  mutation DeleteFSPTheme($id: String!) {
    deleteFSPTheme(id: $id) {
      id
      name
      ownerID
      rawData
      relatedForms {
        id
        name
        path
        previewImage
        publishedVersionID
        ownerID
        createdAt
        updatedAt
        FSPThemeID
      }
    }
  }
`;
export const createStripeProduct = /* GraphQL */ `
  mutation CreateStripeProduct($input: StripeProductInput!) {
    createStripeProduct(input: $input) {
      id
      name
      features
      prices {
        id
        productID
        name
        amount
        currency
        recurring_interval
      }
    }
  }
`;
export const updateStripeProduct = /* GraphQL */ `
  mutation UpdateStripeProduct($input: StripeProductInput!) {
    updateStripeProduct(input: $input) {
      id
      name
      features
      prices {
        id
        productID
        name
        amount
        currency
        recurring_interval
      }
    }
  }
`;
export const deleteStripeProduct = /* GraphQL */ `
  mutation DeleteStripeProduct($id: String!) {
    deleteStripeProduct(id: $id) {
      id
      name
      features
      prices {
        id
        productID
        name
        amount
        currency
        recurring_interval
      }
    }
  }
`;
export const createStripePrice = /* GraphQL */ `
  mutation CreateStripePrice($input: StripePriceInput!) {
    createStripePrice(input: $input) {
      id
      productID
      name
      amount
      currency
      recurring_interval
    }
  }
`;
export const updateStripePrice = /* GraphQL */ `
  mutation UpdateStripePrice($input: StripePriceInput!) {
    updateStripePrice(input: $input) {
      id
      productID
      name
      amount
      currency
      recurring_interval
    }
  }
`;
export const deleteStripePrice = /* GraphQL */ `
  mutation DeleteStripePrice($id: String!) {
    deleteStripePrice(id: $id) {
      id
      productID
      name
      amount
      currency
      recurring_interval
    }
  }
`;
