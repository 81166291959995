<template>
    <div class="generic-field__view">
        <component
                :is="fieldTypeConst.SUBMITTABLE_VIEW"
                :field-id="fieldId"
                :source-obj="sourceObj"
                :submission-obj="submissionObj"
                :field-width="fieldWidth"
        />
        <div class="generic-field__error-messages-wrapper">
            <v-slide-y-transition>
                <div v-if="currentErrorMessage" class="generic-field-error-message generic-field__escape-form-font">
                    <span class="generic-field-error-message__text">
                        {{ currentErrorMessage }}
                    </span>
                </div>
            </v-slide-y-transition>
        </div>
    </div>
</template>

<script>
    import { computed, inject } from '@vue/composition-api'
    import { required as requiredValidator } from '@vuelidate/validators'

    export default {
        name: "SubmittableFieldViewsGenerator",
        props: {
            fieldId: {
                type: String,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            },
            submissionObj: {
                type: Object
            },
            fieldWidth: Number
        },
        computed: {
            fieldTypeConst() {
                return this.constants.FIELD_FROM_TYPE[this.sourceObj.field_type]
            }
        },
        setup(props) {
            const $v = inject('fieldsValidation')

            const currentErrorMessage = computed(() => {
                const errorMessages = $v?.value?.[props.fieldId]?.$errors
                if (!errorMessages?.length)
                    return undefined
                const error = errorMessages[0]

                if (error?.$params?.type === requiredValidator.$params.type)
                    return 'Please fill this in'

                return error.$message
            })

            return {
                currentErrorMessage
            }
        }
    }
</script>