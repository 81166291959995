<template>
    <BrowserTemplate @infinite-scroll="handleScroll($event)">
        <template #header>
            <f-solo-search-input
                    v-model="searchUnsplashQuery"
                    class="mt-6 mb-4"
                    placeholder="Search free high-resolution photos from Unsplash"
                    @input="debounceUnsplashSearch"
                    height="40"
            />
            <v-input v-if="isErrorLimitRequest" error-messages="An error occurred, please try again later"/>
        </template>
        <template #default>
            <v-row align="center" justify="center">
                <v-col align-self="center" cols="12" md="6" lg="6" xl="6" sm="6" v-for="(image, index) in unsplashResults"
                       :key="image.id + index">
                  <v-hover v-slot="{ hover }">
                    <v-img class="rounded-lg result-image" width="288px" height="160px"
                           :src="image.urls.raw + '&w=300'" @click="setActiveImage(image)"
                           :class="activeImage !== imageIdentifier(image) || 'active'">
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <f-circle-loader size="5"/>
                        </v-row>
                      </template>
                      <template>
                          <span v-if="hover" class="image-author-name">by <a :href="image.user.links.html + '?' + unsplashUtmAttribution" target="_blank">
                          {{ image.user.first_name }} {{ image.user.last_name }}</a></span>
                      </template>
                    </v-img>
                  </v-hover>
                </v-col>

            </v-row>
        </template>
    </BrowserTemplate>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "UnsplashBrowser",
        data: () => ({
            searchUnsplashQuery: '',
            unsplashResults: [],
            isErrorLimitRequest: false,
            unsplashUtmAttribution: 'utm_source=formsly&utm_medium=referral'
        }),
        props: ['activeImage'],
        components: {
            BrowserTemplate: () => import('./BrowserTemplate')
        },
        methods: {
            handleScroll: _.debounce(async function(e) {
              if (e.target.clientHeight + e.target.scrollTop + 10 >= e.target.scrollHeight) {
                const randomImages = await this.fetchApi(`https://api.unsplash.com/photos/random?query=${this.searchUnsplashQuery ? this.searchUnsplashQuery : 'abstract'}&orientation=landscape&count=4`)
                this.unsplashResults.splice(this.unsplashResults.length -1, 0, ...randomImages);
              }
            }, 10),
            imageIdentifier(image) {
                return image.urls.raw + '&w=1800'
            },
            setActiveImage(image) {
                if (this.imageIdentifier(image) === this.activeImage)
                    this.$emit('set-active-image', '')
                else
                    this.$emit('set-active-image', this.imageIdentifier(image))
                this.$emit('set-download-url', image.links.download_location);
            },
            async fetchApi(url) {
                try {
                    const dataFetch = await fetch(url, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            Authorization: 'Client-ID SrjAzCE4ATik3Ap2Uz76LJ9VhYyAkd7WLAii61gtVWA'
                        }
                    });
                    this.isErrorLimitRequest = false;
                    return await dataFetch.json();
                } catch (error) {
                    if (error.message.includes("Rate Limit Exceeded")) {
                        this.isErrorLimitRequest = true
                        console.log("An error occurred, please try again later");
                    } else {
                        console.log(error.message);
                    }
                }
            },

            debounceUnsplashSearch: _.debounce(function () {
                this.searchUnsplashPhotos()
            }, 1000),
            async defaultUnsplashPhotos() {
                if (this.unsplashResults.length === 0) {
                    const data = await this.fetchApi('https://api.unsplash.com/search/photos?page=1&query=abstract&orientation=landscape&per_page=6')
                    this.unsplashResults = data['results']
                }
            },
            async searchUnsplashPhotos() {
                const data = await this.fetchApi(`https://api.unsplash.com/search/photos?page=1&query=${this.searchUnsplashQuery}&orientation=landscape&per_page=6`)
                this.unsplashResults = data['results']
            }
        },
        mounted() {
            this.defaultUnsplashPhotos()
        }
    }
</script>

<style lang="scss" scoped>
    .search-bar {
        margin: 1.5rem 0;
        font-size: 14px;
        background: #f9f9f9;

        ::v-deep .v-input__slot {
            min-height: 36px !important;
        }

        ::v-deep .v-input__prepend-inner {
            align-self: inherit;
        }
    }
    .v-image {
      ::v-deep .v-responsive__content {
        .image-author-name {
          position: absolute;
          bottom: 15px;
          left: 15px;
          font-size: 12px;
          font-weight: 700;
          color: #fff;

          & > * {
            color: inherit;
            text-decoration: inherit;
          }
        }
      }
    }
</style>
