<template>
    <v-tooltip
            :content-class="position"
            :open-delay="delay"
            open-on-hover
            v-bind="$props"
            transition="none"
    >
    <template #activator="{ on }">
        <slot :on="on"></slot>
    </template>
        {{text}}
    </v-tooltip>
</template>

<script>
    export default {
        name: 'f-hover-tooltip',
        props: {
            top: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            bottom: {
                type: Boolean,
                default: false,
            },
            text: {
                type: String,
                required: true,
            },
            maxWidth: {
                type: [Number, String],
                default: 'auto',
            }
        },
        data() {
            return {
                delay: 1,
            }
        },
        computed: {
            position() {
                if (this.top) {
                    return 'tooltip-top';
                } else if (this.bottom) {
                    return 'tooltip-bottom';
                } else if (this.left) {
                    return 'tooltip-left';
                } else {
                    return 'tooltip-right';
                }
            },
        },

    }
</script>

<style scoped lang="scss">
    .v-tooltip__content {
        white-space: normal !important;
    }
</style>