<template>
    <div class="static-form__page-wrapper">
        <LogoBadgeEditor/>
        <div class="static-form__page">
            <StaticMainPage
                    v-if="getCurrentPage.type === constants.FORM_PAGES_TYPES.MAIN_PAGE"
                    :page-id="getCurrentPageId"
                    :key="getCurrentPageId"
            />
            <StaticFinishPage
                    v-else-if="getCurrentPage.type === constants.FORM_PAGES_TYPES.FINISH_PAGE"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "StaticPagesDisplayManager",
        components: {
            StaticMainPage: () => import('./MainPage/StaticMainPage'),
            StaticFinishPage: () => import('./FinishPage/StaticFinishPage'),
            LogoBadgeEditor: () => import('../LogoBadge/LogoBadgeEditor')
        },
        computed: {
            ...mapGetters('edit', [
                'getFormJSON'
            ]),
            ...mapGetters('edit/pages_navigation', [
                'getCurrentPageId',
                'getCurrentPage'
            ]),
        },
        created() {
            this.$store.dispatch('edit/pages_navigation/setCurrentPageId', this.getFormJSON.main_pages[0].page_id)
        }
    }
</script>