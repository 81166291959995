var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "editable": "",
      "columns": _vm.columns,
      "rows": _vm.rows,
      "radio": !_vm.fieldProperties.multiple_selection
    },
    on: {
      "remove-column": function ($event) {
        return _vm.removeColumn($event);
      },
      "remove-row": function ($event) {
        return _vm.removeRow($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "addColumnButton",
      fn: function () {
        return [_c('div', {
          class: ['generic-field__escape-form-font', 'matrix-field-view__add-column-button', 'visible-when-field-editing', !_vm.isEditingActive && 'visible-when-field-editing--hidden'],
          on: {
            "click": _vm.appendColumn
          }
        }, [_c('span', [_vm._v("+")])])];
      },
      proxy: true
    }, {
      key: "addOptionButton",
      fn: function () {
        return [_c('GenericAddOptionButton', {
          attrs: {
            "is-editing-active": _vm.isEditingActive
          },
          on: {
            "add-option": _vm.appendRow
          }
        }, [_vm._v("Add question ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }