import { render, staticRenderFns } from "./StaticMainPageSubmitButtonComponent.vue?vue&type=template&id=3a5c69f5&"
import script from "./StaticMainPageSubmitButtonComponent.vue?vue&type=script&lang=js&"
export * from "./StaticMainPageSubmitButtonComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_react@18.2.0_vue-templa_wkiss7gp4bcqnlea7oiy3v6evi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.6.14_vuetify@2.6.3_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VHover,VSlideYTransition})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.6.14_vuetify@2.6.3_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
