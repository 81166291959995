<template>
    <div>
        <template
                v-for="(propertyObj, index) in fieldBaseProperties.propertiesArray"
        >
            <v-divider
                    v-if="propertyObj.key === 'divider'"
                    :key="index"
            />
            <EditableFieldPropertyConstructor
                    v-else
                    :field-id="fieldId"
                    :property-name="propertyObj.key"
                    :property-value="fieldPropertiesObj[propertyObj.key]"
                    :property-const="propertyObj"
                    :field-properties-obj="fieldPropertiesObj"
                    :key="index"
                    @input="propertyValueChange(propertyObj.key, $event)"
            />
        </template>
    </div>
</template>

<script>
    export default {
        name: "EditableFieldPropertiesListGenerator",
        components: {
            EditableFieldPropertyConstructor: () => import('./Utils/EditableFieldPropertyConstructor')
        },
        props: {
            fieldId: String,
            fieldPropertiesObj: {
                type: Object,
                required: true
            },
            fieldBaseProperties: {
                type: Object,
                required: true
            },
        },
        methods: {
            propertyValueChange(propertyName, value) {
                this.fieldPropertiesObj[propertyName] = value
            }
        }
    }
</script>

<style scoped>

</style>