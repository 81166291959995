<template>
    <span
            class="flex-grow-1"
            @dblclick="startEditing"
    >
        <v-text-field
                :value="pageName"
                @input="changePageName"
                @keydown.enter="stopEditing"
                @blur="stopEditing"
                ref="pageNameInput"
                placeholder="Add page name"
                class="page-name-input"
                :disabled="!isEditing"
                background-color="transparent"
                dense
                full-width
                flat
                solo
                hide-details
        />
    </span>
</template>

<script>
    export default {
        name: "PageNameEditor",
        props: {
            pageName: String
        },
        data: () => ({
            isEditing: false
        }),
        methods: {
            startEditing() {
                this.isEditing = true
                this.$nextTick(this.$refs.pageNameInput.focus)
            },
            changePageName(v) {
                this.$emit('input', v)
            },
            stopEditing() {
                this.isEditing = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-name-input {
        max-width: 160px;

        ::v-deep .v-input__control {
            min-height: 36px!important;

            .v-input__slot {
                padding: 0 !important;
                min-height: 36px !important;

                .v-text-field__slot input {
                    padding: 0 !important;
                    font-size: .875rem;
                    color: var(--v-dark_gray-base);
                    text-overflow: ellipsis;
                    transition: font-weight 100ms linear;
                }
            }
        }
    }
</style>