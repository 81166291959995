import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {FieldPropertySmartParagraphVariables} from '@/components/Fields/FieldConstClasses/field_property_input'
import {Analytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from '@/constants/fsp_constants';

class SmartParagraphFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'SMART_PARAGRAPH',
            READABLE_NAME: 'Smart Paragraph',
            ICON_NAME: 'smart_paragraph',
            FOLDER_NAME: 'SmartParagraph',

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                    if (Object.values(submissionObj.value).some(value => !!value)) {
                        return Object.keys(fieldObj.properties.variables_list)
                            .map(variable_key => {
                                return `${fieldObj.properties.variables_list[variable_key]} - ${submissionObj.value[variable_key] ? submissionObj.value[variable_key] : 'no answer'}`
                            })
                            .join('\n')
                    }
                    return null;
                }
            }),

            FIELD_OBJ_EXTENSION: () => ({
                content: []
            }),

            UTIL: {
                ANSWER_TO_VARIABLE_IS_EMPTY: answer => !answer?.length
            },

            SUBMISSION_TEMPLATE: sourceObj => ({
                value: Object.fromEntries(
                    Object.keys(sourceObj.properties.variables_list)
                        .map(variable_id => [variable_id, null])
                )
            }),

            IS_SUBMISSION_EMPTY: ({value}) => Object.values(value).every(v => !v?.length),

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: Object.fromEntries(
                        Object.keys(fieldObj.properties.variables_list)
                            .map(variable_id => [variable_id, FieldSubmitValidation.REQUIRED(fieldObj)])
                    )
                })
            }),

            ASSOCIATE_WITH_PAID_FEATURE: 'smart_paragraph_field',

            FSP: new FieldFSPDefinition({
                FSP_FIELD_TYPE: 'SmartParagraph',
                FIELD_VIEW_CLASS: 'smart-paragraph-field-view',
                GENERIC_STATES_CONNECTORS: {
                    'editable-variable-container-focused': {
                        internal: '.smart-paragraph-field-view__editable-variable-container---focused'
                    },
                },
                VARIABLES: [
                    {
                        selector: ['content'],
                        properties: {
                            'font-size': {
                                default: {
                                    fn: '1rem',
                                }
                            },
                            'line-height': {
                                default: {
                                    fn: '2',
                                }
                            },
                            color: {
                                'default--static': {
                                    fn: '#000000',
                                },
                                'default--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                                }
                            }
                        }
                    },
                    {
                        selector: ['content--placeholder::before'],
                        properties: {
                            'font-weight': {
                                default: {
                                    fn: '400',
                                }
                            }
                        }
                    },
                    {
                        selector: ['empty-text-block'],
                        properties: {
                            'min-width': {
                                default: {
                                    fn: '1px',
                                }
                            },
                            'min-height': {
                                default: {
                                    fn: '17px',
                                }
                            }
                        }
                    },
                    {
                        selector: ['editable-variable-container'],
                        properties: {
                            color: {
                                default: {
                                    fn: '#000',
                                }
                            },
                            'background-color': {
                                default: {
                                    fn: '#fff',
                                }
                            },
                            'box-shadow': {
                                default: {
                                    fn: '0px 1px 3px rgba(0, 0, 0, 0.27)'
                                },
                                'editable-variable-container-focused': {
                                    fn: '0px 1px 3px rgba(61, 102, 246, 0.49)',
                                }
                            },
                            'border-radius': {
                                default: {
                                    fn: '25px'
                                }
                            },
                            padding: {
                                default: {
                                    fn: '1px 10px',
                                }
                            },
                            height: {
                                default: {
                                    fn: '24px',
                                }
                            },
                            margin: {
                                default: {
                                    fn: '0 6px'
                                }
                            },
                            border: {
                                default: {
                                    fn: '1px solid transparent',
                                },
                                'editable-variable-container-focused': {
                                    fn: '1px solid var(--v-blue_400-base)',
                                }
                            },
                        }
                    },
                    {
                        selector: ['editable-variable-container svg'],
                        properties: {
                            width: {
                                default: {
                                    fn: '13px',
                                }
                            },
                            height: {
                                default: {
                                    fn: '13px'
                                }
                            }
                        }
                    },
                    {
                        selector: ['editable-variable-field'],
                        properties: {
                            'font-size': {
                                default: {
                                    fn: '1rem',
                                },

                            },
                            'line-height': {
                                default: {
                                    fn: '1',
                                }
                            },
                            'font-weight': {
                                default: {
                                    fn: '500',
                                },
                            },
                            'margin-right': {
                                default: {
                                    fn: '8px',
                                }
                            },
                        }
                    },
                    {
                        selector: ['editable-variable-field--placeholder:before'],
                        properties: {
                            'font-weight': {
                                default: {
                                    fn: '400',
                                }
                            },
                            color: {
                                default: {
                                    fn: 'var(--v-grey_500-base)',
                                }
                            }
                        }
                    },
                    {
                        selector: ['add-variable-popup'],
                        properties: {
                            height: {
                                default: {
                                    fn: '24px',
                                }
                            },
                            'border-radius': {
                                default: {
                                    fn: '100px'
                                }
                            },
                            padding: {
                                default: {
                                    fn: '1px 6px 1px 3px',
                                }
                            },
                            'background-color': {
                                default: {
                                    fn: '#fff',
                                }
                            },
                            'box-shadow': {
                                default: {
                                    fn: '0px 1px 3px rgba(0, 0, 0, 0.27)'
                                }
                            },
                            'margin-bottom': {
                                default: {
                                    fn: '6px',
                                }
                            }
                        }
                    },
                    {
                        selector: ['add-variable-popup__button'],
                        properties: {
                            'font-size': {
                                default: {
                                    fn: '.75rem',
                                }
                            },
                            'line-height': {
                                default: {
                                    fn: '16px'
                                }
                            },
                            padding: {
                                default: {
                                    fn: '3px'
                                }
                            },
                            height: {
                                default: {
                                    fn: '16px'
                                }
                            },
                            'border-radius': {
                                default: {
                                    fn: '100px'
                                }
                            },
                        }
                    },
                    {
                        selector: ['add-variable-popup__button:hover'],
                        properties: {
                            'background-color': {
                                default: {
                                    fn: 'var(--v-blue_100-base)',
                                }
                            },
                            color: {
                                default: {
                                    fn: 'var(--v-blue_400-base)',
                                }
                            }
                        }
                    },
                    {
                        selector: ['add-variable-popup__plus-icon'],
                        properties: {
                            'background-color': {
                                default: {
                                    fn: 'var(--v-blue_400-base)'
                                }
                            },
                            width: {
                                default: {
                                    fn: '16px'
                                }
                            },
                            height: {
                                default: {
                                    fn: '16px'
                                }
                            },
                            'border-radius': {
                                default: {
                                    fn: '100px'
                                }
                            },
                            'margin-right': {
                                default: {
                                    fn: '5px'
                                }
                            }
                        }
                    },
                    {
                        selector: ['add-variable-popup__plus-icon svg'],
                        properties: {
                            width: {
                                default: {
                                    height: '14px',
                                }
                            },
                            height: {
                                default: {
                                    height: '14px',
                                }
                            },
                            color: {
                                default: {
                                    fn: '#fff'
                                }
                            },
                        }
                    },
                    {
                        selector: ['add-variable-popup__info'],
                        properties: {
                            'margin-left': {
                                default: {
                                    fn: '12px'
                                }
                            },
                        }
                    },
                    {
                        selector: ['add-variable-popup__info svg'],
                        properties: {
                            width: {
                                default: {
                                    fn: '16px',
                                }
                            },
                            height: {
                                default: {
                                    fn: '16px',
                                }
                            },
                            color: {
                                default: {
                                    color: '#000',
                                }
                            }
                        }
                    },
                    {
                        selector: ['submittable-variable-container'],
                        properties: {
                            height: {
                                default: {
                                    fn: '18px',
                                }
                            },
                            'min-width': {
                                default: {
                                    fn: '100px',
                                }
                            },
                            margin: {
                                default: {
                                    fn: '0 4px'
                                }
                            },
                            'border-bottom': {
                                default: {
                                    fn: '1px solid var(--v-bluish_gray_500-base)'
                                }
                            },
                            'caret-color': {
                                default: {
                                    fn: 'black !important'
                                }
                            },
                        }
                    },
                    {
                        selector: ['submittable-variable-container--error'],
                        properties: {
                            'border-bottom': {
                                default: {
                                    fn: '1px solid var(--v-red_400-base)'
                                }
                            }
                        }
                    }
                ]
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'variables_list',
                    name: 'Variables of the paragraph',
                    caption: 'Change in Smart Paragraph to see here',
                    enabled_by_default: true,
                    always_enabled: true,
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySmartParagraphVariables()
                    )
                })
            ])
        })
    }
}

export default new SmartParagraphFieldTypeConst()