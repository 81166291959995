<template>
    <f-select-with-virtual-scroll
            :items="filteredFonts"
            :item-value="(item) => item.name"
            :item-text="(item) => item.name"


            :value="value"
            @input="$emit('input', $event)"

            hide-details


            v-on="$listeners"
            v-bind="{
                ...$attrs
            }"
    >
        <template #prepend-item>
            <v-text-field
                    placeholder="Search fonts"
                    height="40"
                    solo
                    flat
                    hide-details
                    v-model="fontFilter"
            >
                <template #prepend-inner>
                    <SearchIcon class="gray_400--text mr-2" size="20"/>
                </template>
            </v-text-field>
        </template>
        <template #item="{on, attrs, item}">
            <f-template-data-transfer :loaded="false" v-slot="{dataStash}">
                <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content
                            v-intersect.once="() => {
                                if (item.url) {
                                    Utils.addFontFamilyURL(item.url).then(() => dataStash.loaded = true)
                                } else
                                    dataStash.loaded = true
                            }"
                    >
                        <v-list-item-title v-if="dataStash.loaded" :style="{fontFamily: item.name}">{{ item.name }}</v-list-item-title>
                        <v-skeleton-loader type="text" v-else></v-skeleton-loader>
                    </v-list-item-content>
                </v-list-item>
            </f-template-data-transfer>
        </template>
    </f-select-with-virtual-scroll>
</template>

<script>
    export default {
        name: "FontPickerComponent",
        components: {
            SearchIcon: () => import('vue-feather-icons/icons/SearchIcon')
        },
        props: {
            value: {
                type: String
            }
        },
        data: () => ({
            fontFilter: ''
        }),
        computed: {
            filteredFonts() {
                return this.constants.TEXT_EDITOR_CONSTANTS.FONTS.filter(item => item.name.toLowerCase().includes(this.fontFilter.toLowerCase()))
            }
        }
    }
</script>