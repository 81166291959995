export const EditFormJsonManageExtension = {
    SBGenerator: (() => {
        const generator = () => ({
            getters: {
                getFormJSON: ({rootGetters}) => rootGetters['edit/getFormJSON']
            }
        })

        generator.key = 'EditFormJsonManageExtension'

        return generator
    })(),
    additionalActionContext: ctx => ({
        commitFormJsonManageMutation: (mutation, mutationPayload, options) => ctx.commit(mutation, {
            formJson: ctx.getters.getFormJSON,
            payload: mutationPayload
        }, options)
    })
}