import SharedView from "@/components/Fields/FieldsViews/Image/SharedView";
import {preview_field_view_mixin} from "@/components/Fields/PreviewField/preview_field_view_mixin";

export default {
    name: 'SharedView__Image',
    functional: true,
    mixins: [preview_field_view_mixin],
    render(h, ctx) {
        const fieldProperties = ctx.props.fieldObj.properties
        return h(
           SharedView,
            {
                props: {
                    src: fieldProperties.image_content.url,
                    lazySrc: fieldProperties.image_content.url,
                    width: fieldProperties.image_size.width + '%',
                    aspectRatio: (fieldProperties.image_content.naturalWidth / fieldProperties.image_content.naturalHeight) / (fieldProperties.image_size.height / 100),
                }
            }
        )
    },
}