<template>
    <v-container class="settings" fluid>
        <v-row class="settings-header">
            <v-col>
                <span>
                    Settings
                </span>
            </v-col>
        </v-row>
        <v-divider class="my-6"/>
        <div class="settings-body">
            <div class="settings-tabs-container">
                <v-tabs vertical hide-slider>
                    <v-tab
                            v-for="settingPage in settingsPagesList"
                            exact-path
                            :to="settingPage.to"
                            :key="settingPage.to"
                    >{{settingPage.name}}</v-tab>
                </v-tabs>
            </div>
            <v-container class="settings-view" fluid>
                <router-view/>
            </v-container>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "Settings",
        computed: {
            settingsPagesList() {
                let settingsRoute = this.$router.options.routes.find(route => route.name === this.constants.PAGE_NAME_SETTINGS)
                return settingsRoute.children
                    .map(child => ({
                        name: child.meta.title,
                        to: settingsRoute.path + '/' + child.path
                    }))
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    .settings {
        max-width: 1660px;
        @media #{map-get($display-breakpoints, 'md-and-down')} {
            padding: 12px 40px 0;
        }
        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            padding: 12px 280px 0;
        }

        &-header {

        padding-top: 53px;
            font-weight: 500;
            font-size: 1.875rem;
            line-height: 2.25rem;
            /*margin-top: 40px;*/
        }

        &-body {
            margin-top: 40px;
            display: flex;

            .settings-tabs-container {
                margin-top: 2px;

                .v-tabs {
                    .v-tab {
                        border-radius: 6px;
                        height: 36px;
                        padding: 0 12px;

                        &:not(:last-child) {
                            margin-bottom: 8px;
                        }

                        &:not(.v-tab--active) {
                            color: var(--v-grey_500_2-base);
                        }
                        &.v-tab--active {
                            color: var(--v-blue_400-base);
                            background-color: var(--v-blue_100-base);
                        }
                        &::before {
                            opacity: 0!important;
                        }
                    }
                }
            }

            .settings-view {
                padding: 0;

                ::v-deep.container {
                    padding: 0 0 0 125px;

                    .settings-view__title {
                        font-size: 1.5rem;
                        font-weight: 500;
                        line-height: 2.375rem;
                        margin-bottom: 4px;
                        color: var(--v-bluish_gray_500-base);
                    }

                    .settings-view__subtitle {
                        line-height: 1.5rem;
                        margin: 0;
                        color: var(--v-grey_500_2-base);
                    }

                    .settings-view__header {
                        font-size: 1.125rem;
                        font-weight: 500;
                        line-height: 1.75rem;
                        margin-bottom: 4px;
                        color: var(--dark_gray-base);
                    }

                    .settings-view__subheader {
                        font-size: .875rem;
                        line-height: 1.25rem;
                        margin: 0;
                        color: var(--v-grey_500_2-base);
                    }

                    .v-divider {
                        margin: 44px 0;
                    }
                }
            }
        }
    }
</style>