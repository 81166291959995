<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="zapier">
      <path id="zapier_2"
        d="M21.3245 21.3321C19.1874 22.1363 16.7926 22.1377 14.6555 21.3326C13.8503 19.1978 13.8501 16.8034 14.6542 14.6683C16.7908 13.8628 19.188 13.8626 21.3246 14.6682C22.1295 16.8027 22.1292 19.1976 21.3245 21.3321ZM33.531 15.375H24.3315L30.8362 8.87406C29.8153 7.44098 28.555 6.1821 27.1211 5.16186L20.6159 11.6628V2.46923C18.8798 2.17735 17.0992 2.1778 15.3631 2.46923V11.6628L8.85792 5.16186C7.42431 6.1814 6.16362 7.44202 5.1427 8.87406L11.6483 15.375H2.44893C2.22185 17.1621 2.14808 18.8415 2.44893 20.6249H11.6485L5.14282 27.1259C6.16621 28.5611 7.42177 29.8158 8.85792 30.8385L15.3631 24.3371V33.5312C17.0994 33.822 18.8794 33.8221 20.6158 33.5312V24.3371L27.1216 30.8385C28.5561 29.8173 29.8142 28.5595 30.8362 27.1259L24.3305 20.6249H33.531C33.823 18.8914 33.823 17.1085 33.531 15.375Z"
        fill="#FF4A00" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Zapier"
}
</script>

<style scoped></style>
