<template>
    <AppBarTemplate>
        <template #left>
            <BackToDashboardComponent/>
        </template>
        <template #center>
            <AppBarLogoComponent/>
        </template>
    </AppBarTemplate>
</template>

<script>
    export default {
        name: "AppBarBackToDashboard",
        components: {
            AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
            AppBarTemplate: () => import('../AppBarTemplate'),
            BackToDashboardComponent: () => import('../Elements/BackToDashboardComponent')
        }
    }
</script>