<template>
    <div class="conditional-logic-expression conditional-logic-expression--Options">
        <f-autocomplete
                v-model="conditionStatement"
                :items="optionsItems"
                :item-value="item => item.index"
                :filter="(item, search) => item.text.toLocaleLowerCase().includes(search.toLocaleLowerCase())"
                class="conditional-logic-expression__expression-input"
                ref="expressionInput"
                placeholder="Add option"
                :menu-props="{
                    maxWidth: optionsMenuWidth,
                    minWidth: optionsMenuWidth,
                    nudgeRight: optionsMenuNudgeRight,
                    nudgeBottom: 6,
                    closeOnClick: true
                }"
                :multiple="expressionObj.operator === 'matches_one_of' ? true : isMultiple"
                outlined
                deletable-chips
                hide-details
                dense
        >
            <template #prepend-inner>
                <f-select
                        v-model="expressionObj.operator"
                        class="conditional-logic-expression__operator-picker"
                        ref="operatorPicker"
                        :items="expressionOperators"
                        :item-text="item => item.text"
                        :item-value="item => item.value"
                        :menu-props="{
                            nudgeBottom: 54
                        }"
                        hide-details
                        solo flat
                        dense
                        @click.stop
                />
            </template>
            <template #item="{on, item, attrs}">
                <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ Utils.convertIndexToLetter(item.index) }}. {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item, parent }">
                <v-chip
                        class="my-1"
                        :key="JSON.stringify(item)"
                        label
                        small
                >
                    <span class="text-truncate">{{ Utils.convertIndexToLetter(item.index) }}. {{ item.text }} </span>
                    <XIcon class="cursor-pointer ml-1" size="12" @click="isMultiple || expressionObj.operator === 'matches_one_of' ? parent.selectItem(item) : expressionObj.statement = []"/>
                </v-chip>
            </template>
        </f-autocomplete>
    </div>
</template>

<script>
    import {computed, onBeforeUnmount, onMounted, ref, watch} from '@vue/composition-api'

    export default {
        name: "Options__ConditionalExpression",
        components: {
            XIcon: () => import('vue-feather-icons/icons/XIcon')
        },
        props: {
            expressionObj: Object
        },
        setup(props, {root, refs}) {
            const expressionOperators = [
                {
                    text: 'Matches',
                    value: 'matches'
                },
                {
                    text: 'Matches one of these',
                    value: 'matches_one_of'
                },
                {
                    text: 'Doesn\'t Match',
                    value: 'doesnt_match'
                }
            ]

            if (!props.expressionObj.operator)
                props.expressionObj.operator = expressionOperators[0].value

            const optionsItems = computed(
                () => root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.VALUE_FORMATTER(root.$store.getters['edit/conditional/getConditionalField'])
            )

            const isMultiple = computed(
                () => root.$store.getters['edit/conditional/getConditionalFieldConst']
                    .CONDITIONAL_LOGIC.PARAMS.MULTIPLE(root.$store.getters['edit/conditional/getConditionalField'])
            )

            const conditionStatement = computed({
                get: () => isMultiple.value || props.expressionObj.operator === 'matches_one_of' ? props.expressionObj.statement : props.expressionObj.statement[0],
                set: (v) => {
                    if (isMultiple.value || props.expressionObj.operator === 'matches_one_of') {
                        props.expressionObj.statement = v
                    } else {
                        props.expressionObj.statement.splice(0, 1, v)
                    }

                }
            })

            const optionsMenuWidth = ref(0)
            const optionsMenuNudgeRight = ref(0)
            let unwatch

            onMounted(() => {
                setTimeout(() =>
                    unwatch = watch(
                        () => props.expressionObj,
                        () => {
                            optionsMenuWidth.value = refs.expressionInput.$el.getBoundingClientRect().width - refs.operatorPicker.$el.getBoundingClientRect().width
                            optionsMenuNudgeRight.value = refs.operatorPicker.$el.getBoundingClientRect().width
                        },
                        {deep: true, immediate: true}
                    ), 300)
            })
            onBeforeUnmount(() => {
                unwatch()
            })

            return {
                expressionOperators,
                optionsItems,
                optionsMenuWidth,
                optionsMenuNudgeRight,
                isMultiple,
                conditionStatement
            }
        }
    }
</script>