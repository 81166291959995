<template>
    <span
            class="smart-paragraph-field-view__submittable-variable-container"
            :class="error && 'smart-paragraph-field-view__submittable-variable-container--error'"
    >
        <span
                :contenteditable="!preview"
                ref="variableInput"

                @paste="processPaste"
                @input="input"
        >&#8203;</span>
    </span>
</template>

<script>
    import { computed, onMounted } from '@vue/composition-api'
    import Vue from 'vue'

    export default {
        name: "SubmittableVariableContainer",
        props: {
            value: String,
            error: Boolean,
            preview: Boolean,
        },
        setup(props, {refs, emit}) {
            onMounted(async () => {
                if (props.value)
                    refs.variableInput.innerHTML = props.value

                await Vue.nextTick()

                handleMinWidth()
            })
            const processPaste = e => {
                e.preventDefault()
                e.stopPropagation()
                let data = (e.originalEvent || e).clipboardData.getData('text/plain')
                if (data)
                    document.execCommand("insertText", false, data.replace('\n', ' '))
            }

            const handleMinWidth = () => {
                let range = document.createRange()
                range.selectNodeContents(refs.variableInput)
                let {width: textWidth} = range.getBoundingClientRect()
                if (textWidth < 100)
                    refs.variableInput.style.paddingRight = 100 - textWidth + 'px'
                else
                    refs.variableInput.style.paddingRight = 0
            }

            const input = () => {
                handleMinWidth()

                if (refs.variableInput.innerText) {
                    if (refs.variableInput.innerHTML.includes('\u200B')) {
                        let selection = window.getSelection()
                        let range = document.createRange()
                        refs.variableInput.innerHTML = refs.variableInput.innerHTML.replace('\u200B', '')
                        range.selectNodeContents(refs.variableInput)
                        selection.removeAllRanges()
                        selection.addRange(range)
                        selection.collapseToEnd()
                    }
                }
                else {
                    refs.variableInput.appendChild(document.createTextNode('\u200B'))
                }

                model.value = refs.variableInput.innerHTML.replace('\u200B', '') || null
            }

            const model = computed({
                get: () => props.value,
                set: v => emit('input', v)
            })

            return {
                processPaste,
                input,
            }
        }
    }
</script>