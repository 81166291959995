<template>
    <div>
        <f-circle-loader v-if="getBillingDetailsIsLoading" size="4"/>
        <div v-else-if="getBillingDetailsIsExist" class="billing-details-card">
            <div>
                <p>{{ name }}</p>
                <div class="d-flex align-center">
                    <span>{{ countryName }}</span>
                    <f-country-flag v-if="countryCode" class="ml-1" size="s" :code="countryCode"/>
                </div>
                <p>{{ city }}</p>
                <p>{{ street }}</p>
                <p>{{ postal_code }}</p>
            </div>
            <v-btn
                    rounded
                    outlined
                    @click="updateBillingDetails"
            >Edit
            </v-btn>
        </div>
        <v-btn
                v-else
                rounded
                outlined
                @click="updateBillingDetails"
        >Add Billing Address
        </v-btn>

        <UpdateBillingDetailsDialog ref="updateBillingDetailsDialog"/>
    </div>
</template>

<script>
    import {mapGetters} from '@/xstore'

    export default {
        name: "BillingDetails",
        components: {
            UpdateBillingDetailsDialog: () => import('./UpdateBillingDetailsDialog')
        },
        computed: {
            ...mapGetters('user/plan/customer_details', [
                'getBillingDetailsIsExist',
                'getBillingDetails',
                'getBillingDetailsIsLoading'
            ]),
            name() {
                return this.getBillingDetails?.name
            },
            countryCode() {
                return this.getBillingDetails?.billing_address?.country
            },
            countryName() {
                return this.constants.COUNTRY_NAMES?.[this.countryCode]
            },
            city() {
                return this.getBillingDetails?.billing_address?.city
            },
            street() {
                return this.getBillingDetails?.billing_address?.line1
            },
            postal_code() {
                return this.getBillingDetails?.billing_address?.postal_code
            }
        },
        methods: {
            updateBillingDetails() {
                this.$refs.updateBillingDetailsDialog.openDialog()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .billing-details-card {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        font-size: 14px;
        border: 1px solid #E4E7EC;
        border-radius: 8px;
        margin-top: 25px;
    }
</style>