<template>
    <div class="checkout-plans-features-table">
        <div class="checkout-plans-features-table__table">
            <div class="checkout-plans-features-table__table-head">
                <div class="checkout-plans-features-table__table-row">
                    <div></div>
                    <div><span>Business</span></div>
                    <div><span>Pro</span></div>
                    <div><span>Free</span></div>
                </div>
            </div>
            <div class="checkout-plans-features-table__table-body">
                <div
                        v-for="feature in formattedFeatures"
                        class="checkout-plans-features-table__table-row"
                        :key="feature.id"
                >
                    <div><span>{{ feature.readableName }}</span></div>
                    <div><component :is="feature.component" :value="getPlansObject?.business?.features?.[feature.id]"/></div>
                    <div><component :is="feature.component" :value="getPlansObject?.pro?.features?.[feature.id]"/></div>
                    <div><component :is="feature.component" :value="getPlansObject?.free?.features?.[feature.id]"/></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from '@/xstore'
    import { MinusIcon, CheckIcon } from 'vue-feather-icons'

    const featuresOrder = ['forms_count', 'questions_per_form', 'responses_per_month', 'conditional_logic_per_form', 'static_form_pages', 'premium_templates', 'hidden_fields', 'video_image_fields', 'analytics', 'customize_home_finish_pages', 'redirect_upon_completion', 'recieve_file_uploades', 'summary_analytics', 'disable_formsly_branding', 'email_notifications', 'analytics_share', 'auto_close_forms', 'file_uploader_field', 'smart_paragraph_field', 'signature_field', 'limit_responses_number']
    const featuresReadableNames = {
        analytics: 'Analytics & Metrics',
        analytics_share: 'Analytics & Reports share',
        auto_close_forms: 'Auto-close forms',
        conditional_logic_per_form: 'Conditional Logic per form',
        customize_home_finish_pages: 'Custom Start & End screens',
        disable_formsly_branding: 'Remove Formsly Branding',
        email_notifications: 'Advanced Email notifications',
        file_uploader_field: 'File uploader',
        forms_count: 'Number of Forms',
        hidden_fields: 'Hidden fields',
        limit_responses_number: 'Limit responses number',
        premium_templates: 'Premium templates',
        questions_per_form: 'Questions per Form',
        recieve_file_uploades: 'Receive file uploades',
        redirect_upon_completion: 'Redirect on completion',
        responses_per_month: 'Monthly response limit',
        signature_field: 'e-Signature',
        smart_paragraph_field: 'Smart Paragraph',
        static_form_pages: 'Static Form Pages',
        summary_analytics: 'Advanced Analytics Summary',
        video_image_fields: 'Video & Image blocks'
    }

    export default {
        name: "PlansFeaturesTable",
        props: {
            selectedProduct: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapGetters('user/plan', [
                'getPlansObject'
            ]),
            formattedFeatures() {
                return featuresOrder
                    .map(featureID => {
                        let component

                        if (['forms_count', 'questions_per_form', 'responses_per_month', 'conditional_logic_per_form', 'static_form_pages'].includes(featureID))
                            component = {
                                props: {
                                    value: [Number]
                                },
                                render(h, ctx) {
                                    return h('span', {}, isFinite(ctx.props.value) ? ctx.props.value.toLocaleString() : 'Unlimited')
                                }
                            }
                        else if (['recieve_file_uploades'].includes(featureID))
                            component = {
                                props: {
                                    value: [Number]
                                },
                                render(h, ctx) {
                                    if (ctx.props.value > 0)
                                        return h('span', {}, Math.round(ctx.props.value / (1024 ** 3)).toLocaleString() + ' GB')
                                    return h(MinusIcon)
                                }
                            }
                        else
                            component = {
                                props: {
                                    value: [Boolean]
                                },
                                render(h, ctx) {
                                    if (ctx.props.value)
                                        return h(CheckIcon)
                                    return h(MinusIcon)
                                }
                            }

                        return {
                            id: featureID,
                            readableName: featuresReadableNames[featureID],
                            component: Vue.extend({
                                name: `PlansFeaturesTableCell__${featureID}`,
                                functional: true,
                                ...component
                            })
                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-plans-features-table {
        &__table {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr .5fr;

            & > * {
                display: contents;
            }
        }

        &__table-head {
            .checkout-plans-features-table__table-row {
                font-size: 18px;
                font-weight: 500;

                & > * {
                    height: 66px;

                    //position: sticky;
                    //top: 80px;
                }
            }
        }

        &__table-body {
            .checkout-plans-features-table__table-row {
                & > * {
                    height: 46px;

                    &:first-child {
                        padding-left: 19px;
                    }
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -0.04em;
                }

                svg.feather {
                    width: 20px;
                    height: 20px;

                    &.feather-check {
                        color: var(--v-blue_400-base);
                    }

                    &.feather-minus {
                        color: var(--v-gray_300-base);
                    }
                }

                &:nth-child(odd) {
                    & > *, &::after {
                        background-color: #f9f9f9;
                    }
                }
            }
        }

        &__table-row {
            display: contents;

            & > * {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    justify-content: center;
                }
            }

            &::after {
                content: "";
            }
        }
    }
</style>