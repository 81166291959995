var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', {
    staticClass: "rounded-lg pa-0",
    attrs: {
      "multiple": "",
      "deletable-chips": "",
      "placeholder": _vm.inputConst.placeholder,
      "label": _vm.label,
      "append-icon": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.handleInput
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          key: JSON.stringify(item),
          staticClass: "my-1",
          attrs: {
            "label": ""
          }
        }, [_vm._v(" " + _vm._s(item) + " "), _c('XIcon', {
          staticClass: "cursor-pointer ml-3",
          attrs: {
            "size": "16"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }