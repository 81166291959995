<template>
    <v-menu
            v-if="asMenu"
            v-model="menuModel"
            v-bind="menuBind"
    >
        <template #activator="bind">
            <slot name="activator" v-bind="bind"/>
        </template>
        <template #default>
            <DateTimePicker :key="'picker' + menuModel" v-bind="$attrs" v-on="$listeners"/>
        </template>
    </v-menu>

    <DateTimePicker
            v-else
            v-bind="$attrs"
            v-on="$listeners"
    />
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "DateTimePickerWithMenu",
        components: {
            DateTimePicker: () => import('./DateTimePicker')
        },
        props: {
            asMenu: {
                type: Boolean,
                default: false
            },
            menuProps: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            menuModel: false
        }),
        computed: {
            menuBind() {
                if (this.asMenu)
                    return _.defaultsDeepWithClone(this.menuProps, {
                        minWidth: 340,
                        maxWidth: 340,
                        closeOnContentClick: false,
                        offsetY: true,
                        bottom: true,
                        nudgeBottom: 50,
                        offsetOverflow: true,
                    })
                return {}
            }
        }
    }
</script>