<template>
    <v-text-field
            v-model="localValue"
            @input="inputNumber"
            type="number"
            :min="minValue"
            :label="label"
            step="1"
            class="editable-field-property-input-number rounded-lg"
            hide-details
            outlined
            dense
    >
        <template #append>
            {{inputConst.append_inner}}
        </template>
    </v-text-field>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "Number",

        VALUE_VALIDATOR: value => value >= 0 ? value : null,

        mixins: [field_property_input_mixin],

        data: () => ({
            defaultMinValue: 1,
            localValue: undefined,
            oldLocalValue: undefined
        }),
        computed: {
            minValue() {
                return this.inputConst.min_value !== undefined ? this.inputConst.min_value : this.defaultMinValue
            }
        },
        created() {
            this.localValue = this.oldLocalValue = this.inputValue
        },
        methods: {
            inputNumber() {
                this.$nextTick(() => {
                    if (!/^[0-9]$|^[1-9]\d*$/.test(this.localValue) || !this.validateInputValue(this.localValue))
                        this.localValue = this.oldLocalValue
                    else if (this.localValue <= this.minValue)
                        this.localValue = this.minValue

                    this.oldLocalValue = this.localValue
                    this.input(parseInt(this.localValue))
                })
            }
        }
    }
</script>

<style lang="scss">
    .editable-field-property-input-number {
        .v-input__append-inner {
            color: var(--v-gray_400-base) !important;
        }
    }
</style>