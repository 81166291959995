var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-saving-status"
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": "",
      "group": ""
    }
  }, [_vm.isFormSaving ? _c('div', {
    key: "saving",
    staticClass: "form-saving-status"
  }, [_vm._v("Saving...")]) : _vm.isFormSaved ? _c('div', {
    key: "saved",
    staticClass: "form-saving-status__saved"
  }, [_c('CheckIcon', {
    attrs: {
      "size": "20",
      "stroke-width": "1.5"
    }
  }), _vm._v(" Changes were saved ")], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }