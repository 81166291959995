import { reactive, computed, watch, effectScope, set } from '@vue/composition-api'
import { forEachValue, isObject, isPromise, assert, partial } from './util'

export function genericSubscribe(fn, subs, options) {
    if (subs.indexOf(fn) < 0) {
        options && options.prepend
            ? subs.unshift(fn)
            : subs.push(fn)
    }
    return () => {
        const i = subs.indexOf(fn)
        if (i > -1) {
            subs.splice(i, 1)
        }
    }
}

export function resetStore(store, hot) {
    store._actions = Object.create(null)
    store._mutations = Object.create(null)
    store._wrappedGetters = Object.create(null)
    store._modulesNamespaceMap = Object.create(null)
    const state = store.state
    // init all modules
    installModule(store, state, [], store._modules.root, true)
    // reset state
    resetStoreState(store, state, hot)

    // afterModuleInstallation(store, store._modules.root)
}

export function resetStoreState(store, state, hot) {
    const oldState = store._state
    const oldScope = store._scope

    // state = _.cloneDeep(state)

    // bind store public getters
    store.getters = {}
    // reset local getters cache
    store._makeLocalGettersCache = Object.create(null)
    const wrappedGetters = store._wrappedGetters
    const computedObj = {}
    const computedCache = {}

    // create a new effect scope and create computed object inside it to avoid
    // getters (computed) getting destroyed on component unmount.
    const scope = effectScope(true)

    // store._modules.root.beforeDestroy()

    scope.run(() => {
        forEachValue(wrappedGetters, (fn, key) => {
            // use computed to leverage its lazy-caching mechanism
            // direct inline function use will lead to closure preserving oldState.
            // using partial to return function with only arguments preserved in closure environment.
            computedObj[key] = partial(fn, store)
            computedCache[key] = computed(() => computedObj[key]())
            Object.defineProperty(store.getters, key, {
                get: () => computedCache[key].value,
                enumerable: true // for local getters
            })
        })
    })

    store._state = reactive({
        data: state
    })


    // register the newly created effect scope to the store so that we can
    // dispose the effects when this method runs again in the future.
    store._scope = scope

    // enable strict mode for new state
    if (store.strict) {
        enableStrictMode(store)
    }

    if (oldState) {
        if (hot) {
            // dispatch changes in all subscribed watchers
            // to force getter re-evaluation for hot reloading.
            store._withCommit(() => {
                oldState.data = null
            })
        }
    }

    // dispose previously registered effect scope if there is one.
    if (oldScope) {
        oldScope.stop()
    }
}

export function installModule(store, rootState, path, module, hot) {
    const isRoot = !path.length
    const namespace = store._modules.getNamespace(path)

    // register in namespace map
    if (module.namespaced) {
        if (store._modulesNamespaceMap[namespace]
            // && __DEV__
        ) {
            console.error(`[xstore] duplicate namespace ${namespace} for the namespaced module ${path.join('/')}`)
        }
        store._modulesNamespaceMap[namespace] = module
    }

    // set state
    if (!isRoot && !hot) {
        const parentState = getNestedState(rootState, path.slice(0, -1))
        const moduleName = path[path.length - 1]
        store._withCommit(() => {
            // if (__DEV__) {
            if (moduleName in parentState) {
                console.warn(
                    `[xstore] state field "${moduleName}" was overridden by a module with the same name at "${path.join('.')}"`
                )
            }
            set(parentState, moduleName, module.state)
        })
    }

    const ctx = makeLocalContext(store, namespace, path, !isRoot)

    module.context = ctx



    module.forEachMutation((mutation, key) => {
        const namespacedType = namespace + key
        registerMutation(store, namespacedType, mutation, ctx.local)
    })

    module.forEachAction((action, key) => {
        const type = action.root ? key : namespace + key
        const handler = action.handler || action

        const ctxCustomizer = actionCtx => {
            const ctxExtension = {}
            module.actionContextExtensions.forEach(e => Object.assign(ctxExtension, e(actionCtx)))
            return ctxExtension
        }

        registerAction(store, type, handler, ctx, ctxCustomizer)
    })

    module.forEachGetter((getter, key) => {
        const namespacedType = namespace + key
        registerGetter(store, namespacedType, getter, ctx)
    })


    module.forEachChild((child, key) => {
        installModule(store, rootState, path.concat(key), child, hot)
    })
}

export function afterModuleInstallation(store, module) {
    module.afterInstallation(store)

    module.forEachChild((child) => {
        afterModuleInstallation(store, child)
    })

}

/**
 * make localized dispatch, commit, getters and state
 * if there is no namespace, just use root ones
 */
export function makeLocalContext(store, namespace, path, withParentCtx = false) {
    const noNamespace = namespace === ''

    const local = {
        dispatch: noNamespace ? store.dispatch : (_type, _payload, _options) => {
            const args = unifyObjectStyle(_type, _payload, _options)
            const {payload, options} = args
            let {type} = args

            if (!options || !options.root) {
                type = namespace + type
                if (
                    // __DEV__ &&
                    !store._actions[type]) {
                    console.error(`[xstore] unknown local action type: ${args.type}, global type: ${type}`)
                    return
                }
            }

            return store.dispatch(type, payload)
        },

        commit: noNamespace ? store.commit : (_type, _payload, _options) => {
            const args = unifyObjectStyle(_type, _payload, _options)
            const {payload, options} = args
            let {type} = args

            if (!options || !options.root) {
                type = namespace + type
                if (
                    // __DEV__ &&
                    !store._mutations[type]) {
                    console.error(`[xstore] unknown local mutation type: ${args.type}, global type: ${type}`)
                    return
                }
            }

            store.commit(type, payload, options)
        }
    }

    // getters and state object must be gotten lazily
    // because they will be changed by state update
    Object.defineProperties(local, {
        getters: {
            get: noNamespace
                ? () => store.getters
                : () => makeLocalGetters(store, namespace)
        },
        state: {
            get: () => getNestedState(store.state, path)
        }
    })

    let parent = {}

    if (withParentCtx && path.length)
        parent = store._modules.get(path.slice(0, -1)).context.local

    return {local, parent}
}

export function makeLocalGetters(store, namespace) {
    if (!store._makeLocalGettersCache[namespace]) {
        const gettersProxy = {}
        const splitPos = namespace.length
        Object.keys(store.getters).forEach(type => {
            // skip if the target getter is not match this namespace
            if (type.slice(0, splitPos) !== namespace) return

            // extract local getter type
            const localType = type.slice(splitPos)

            // Add a port to the getters proxy.
            // Define as getter property because
            // we do not want to evaluate the getters in this time.
            Object.defineProperty(gettersProxy, localType, {
                get: () => store.getters[type],
                enumerable: true
            })
        })
        store._makeLocalGettersCache[namespace] = gettersProxy
    }

    return store._makeLocalGettersCache[namespace]
}

function registerMutation(store, type, handler, local) {
    const entry = store._mutations[type] || (store._mutations[type] = [])
    entry.push(function wrappedMutationHandler(payload) {
        handler.call(store, local.state, payload)
    })
}

function registerAction(store, type, handler, ctx, customizer) {
    const entry = store._actions[type] || (store._actions[type] = [])
    entry.push(function wrappedActionHandler(payload) {
        const actionCtx = {
            dispatch: ctx.local.dispatch,
            commit: ctx.local.commit,
            rootGetters: store.getters,
            parentDispatch: ctx.parent.dispatch,
            parentCommit: ctx.parent.commit,
            parentGetters: ctx.parent.getters,
        }

        Object.defineProperties(actionCtx, {
            getters: {
                get: () => ctx.local.getters
            }
        })

        Object.assign(actionCtx, customizer(actionCtx) || {})

        let res = handler.call(store, actionCtx, payload)
        if (!isPromise(res)) {
            res = Promise.resolve(res)
        }
        if (store._devtoolHook) {
            return res.catch(err => {
                store._devtoolHook.emit('vuex:error', err)
                throw err
            })
        } else {
            return res
        }
    })
}

export const genGetterContext = (store, ctx) => ({
    state: ctx.local.state,
    getters: ctx.local.getters,
    rootState: store.state,
    rootGetters: store.getters,

    parentState: ctx.parent.state,
    parentGetters: ctx.parent.getters
})

function registerGetter(store, type, rawGetter, ctx) {
    if (store._wrappedGetters[type]) {
        // if (__DEV__) {
        console.error(`[xstore] duplicate getter key: ${type}`)
        // }
        return
    }
    store._wrappedGetters[type] = function wrappedGetter(store) {
        return rawGetter(genGetterContext(store, ctx))
    }
}

function enableStrictMode(store) {
    watch(() => store._state.data, () => {
        // if (__DEV__) {
        assert(store._committing, `do not mutate vuex store state outside mutation handlers.`)
        // }
    }, {deep: true, flush: 'sync'})
}

export function getNestedState(state, path) {
    return path.reduce((state, key) => state[key], state)
}

export function unifyObjectStyle(type, payload, options) {
    if (isObject(type) && type.type) {
        options = payload
        payload = type
        type = type.type
    }

    // if (__DEV__) {
    assert(typeof type === 'string', `expects string as the type, but found ${typeof type}.`)
    // }

    return {type, payload, options}
}
