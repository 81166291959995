<template>
  <div class="opinion-scale-field-view">
    <div class="opinion-scale-field-view__wrapper">
      <div class="opinion-scale-field-view__range">
        <div :class="['opinion-scale-field-view__tile-wrapper',
                      !editable && item.selected && 'opinion-scale-field-view__tile-wrapper--selected',
                      !editable && item.active && 'opinion-scale-field-view__tile-wrapper--active',
                     ]"
             v-for="(item, index) in opinion_scale_range"
             :key="index"
        >
          <div
              @click="$emit('select', index)"
              class="opinion-scale-field-view__tile"
          >{{ item.number }}
          </div>
        </div>
      </div>
      <div class="opinion-scale-field-view__labels opinion-scale-field-view__labels--desktop">
        <f-simple-contenteditable-input
            v-if="editable || !editable && labels.start"
            :mask="regexp_mask"
            placeholder="Label"
            v-model="labels.start"
            :readonly="!editable"
            class="opinion-scale-field-view__label opinion-scale-field-view__label--from"
        />
        <f-simple-contenteditable-input
            v-if="editable || !editable && labels.end"
            :mask="regexp_mask"
            placeholder="Label"
            v-model="labels.end"
            :readonly="!editable"
            class="opinion-scale-field-view__label opinion-scale-field-view__label--end"
        />
      </div>
      <div class="opinion-scale-field-view__labels opinion-scale-field-view__labels--mobile">
        <div class="opinion-scale-field-view__label opinion-scale-field-view__label--from"
             v-if="editable || !editable && labels.start"
        >
          <f-simple-contenteditable-input
              :mask="regexp_mask"
              placeholder="Label"
              v-model="labels.start"
              :readonly="!editable"
          />
          <span>→ {{scalePoles.from}}</span>
        </div>
        <div class="opinion-scale-field-view__label opinion-scale-field-view__label--end"
             v-if="editable || !editable && labels.end"
        >
          <f-simple-contenteditable-input
              :mask="regexp_mask"
              placeholder="Label"
              v-model="labels.end"
              :readonly="!editable"
          />
          <span>→ {{scalePoles.to}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SharedView__OpinionScale',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    scalePoles: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    submissionValue: {
      type: Number,
    }
  },
  computed: {
    opinion_scale_range() {
      const {from: start, to: end} = this.scalePoles;
      let range = [];

      if (!this.editable) {
        for (let from = +start; from <= +end; from++) {
          range.push({
            number: from,
            selected: (+start === 0 ? from : from - 1) === this.submissionValue,
            active: (+start === 0 ? from : from - 1) <= this.submissionValue && this.submissionValue !== null,
          })
        }
        return range;
      } else {
        for (let number = this.scalePoles.from; number <= this.scalePoles.to; number++) {
          range.push({number});
        }
        return range;
      }
    },
    regexp_mask() {
      return this.Utils.getRangedLetterOrNumberRegex({
        expression: this.constants.ANY_LETTER_OR_NUMBER_REGEX,
        from: 0,
        to: 15,
        isSpaceAllowed: true,
      })
    }
  },
}
</script>

<style scoped>

</style>