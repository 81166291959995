<script>
  import Vue from 'vue'

  import {
    editable_field_view_mixin,
    useEditableFieldViewExtension,
  } from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

  export default {
    name: 'GenericEditable__Selection',
    components: {
      GenericFrontView: () => import('./GenericSelectionFieldFrontView'),
      XIcon: () => import('vue-feather-icons/icons/XIcon'),
      AddOptionButton: () =>
        import(
          '@/components/Fields/EditableField/EditableFieldViewUtils/GenericAddOptionButton'
        ),
    },
    mixins: [editable_field_view_mixin],
    setup(props, context) {
      const { refs } = context
      const extension = useEditableFieldViewExtension(props, context)

      const insertOption = insertIndex => {
        if (extension.isConditioned.value) {
          props.fieldObj.condition.expression.statement =
            props.fieldObj.condition.expression.statement.map(s =>
              s >= insertIndex ? s + 1 : s
            )
        }
        props.fieldObj.content.options.splice(insertIndex, 0, '')
        Vue.nextTick(() => {
          refs.genericFrontView.$refs.optionNameInput[insertIndex].focus()
        })
      }
      const removeOption = index => {
        if (extension.isConditioned.value) {
          if (
            !props.fieldObj.condition.expression.statement.filter(
              s => s !== index
            ).length
          ) {
            extension.removeOption(() => {
              props.fieldObj.content.options.splice(index, 1)
              context.root.$delete(props.fieldObj, 'condition')
            })
          } else if (
            props.fieldObj.condition.expression.statement.includes(index)
          ) {
            extension.changeCondition(() => {
              props.fieldObj.condition.expression.statement =
                props.fieldObj.condition.expression.statement
                  .filter(s => s !== index)
                  .map(s => (s > index ? s - 1 : s))
              props.fieldObj.content.options.splice(index, 1)
            })
          } else {
            props.fieldObj.condition.expression.statement =
              props.fieldObj.condition.expression.statement
                .filter(s => s !== index)
                .map(s => (s > index ? s - 1 : s))
            props.fieldObj.content.options.splice(index, 1)
          }
          return
        }
        props.fieldObj.content.options.splice(index, 1)
      }

      return {
        ...extension,
        insertOption,
        removeOption,
      }
    },
    computed: {
      radio() {
        return false
      },
    },
  }
</script>

<template>
  <GenericFrontView
    ref="genericFrontView"
    :options-list="fieldObj.content.options.map((_, index) => index)"
    :radio="radio"
    editable
    :field-obj="fieldObj"
    :is-form-static="isFormStatic"
    :is-form-interactive="isFormInteractive"
    @insert-option="insertOption"
  >
    <template #option-append="{ hover, focused, index }">
      <v-scale-transition origin="center center 0">
        <XIcon
          v-if="(hover || focused) && fieldObj.content.options.length > 1"
          key="remove-option-btn"
          style="cursor: pointer"
          @click="removeOption(index)"
        />
      </v-scale-transition>
    </template>
    <template #extension>
      <AddOptionButton
        :is-editing-active="isEditingActive"
        @add-option="insertOption(fieldObj.content.options.length)"
      />
    </template>
  </GenericFrontView>
</template>
