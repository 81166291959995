<template>
    <span v-html="data"></span>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__SmartParagraph",
        props: {
            submissionObj: Object
        },
        computed: {
            data() {
                return Object.entries(this.submissionObj.value)
                    .map(([, answer]) => answer || '-')
                    .join(', ')
            }
        }
    }
</script>