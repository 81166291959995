export default {
    functional: true,
    props: {
        vnodes: {
            type: Array,
            required: true,
        }
    },
    render(h, context) {
        return context.props.vnodes;
    }
}