var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "h-full d-flex align-center justify-center flex-column"
  }, [_c('img', {
    attrs: {
      "src": "/images/EmptyInteractiveForm.svg",
      "width": "220",
      "draggable": false
    }
  }), _c('p', {
    staticClass: "mt-2 dark_gray--text"
  }, [_vm._v(" Start creating ")]), _c('p', {
    staticClass: "subtitle-2 grey_500--text font-weight-regular"
  }, [_vm._v(" Add questions to bring your form to life ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }