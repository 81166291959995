import { PathableExtensions, PathableUtils } from '@/xstore/utils/pathable'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'
import StorageActions from '@/utils/actions/storage_actions'
import constants from '@/constants/constants'
import { generateShortId, generateUUID } from '@/utils/misc'
import _ from 'lodash'

const genTemplateDataPath = templateId => `form_templates/${templateId}/templateData.json`

export default ({useExtension}) => {
    {
        const state = {
            form_template_data: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
        useExtension(ToggleableExtensions.Custom('fetching'), {state})
    }

    {
        const state = {
            static_form_source_json: undefined,
            interactive_form_source_json: undefined
        }

        useExtension(SMAGableExtensions.SMGable, {state})
    }

    useExtension(PathableExtensions.Default)

    return {
        actions: {
            fetchTemplateData: ({commit, getters, toggleIsFetching}) => toggleIsFetching(
                StorageActions.getJSONByS3Path(genTemplateDataPath(getters.getFormTemplateId))
                    .then(templateData => commit('SET_FORM_TEMPLATE_DATA', templateData.json)),
                'form_template_data'
            )
                .then(() => {
                    // static
                    const genFormJson = formType => {
                        const formJson = formType.TEMPLATE(generateShortId())
                        formJson.fields = _.cloneDeep(getters.getFormTemplateData.fields)
                        return formJson
                    }

                    {
                        const formJson = genFormJson(constants.FORM_TYPES.STATIC_FORM)
                        formJson.all_pages[formJson.main_pages[0].page_id].rows = getters.getFormTemplateData.fields_order
                            .map(fieldID => ({fields: [{field_id: fieldID, width: 2}]}))

                        commit('SET_STATIC_FORM_SOURCE_JSON', formJson)
                    }

                    // interactive
                    {
                        const formJson = genFormJson(constants.FORM_TYPES.INTERACTIVE_FORM)
                        formJson.all_pages.home_page.title = getters.getFormTemplateData.homepage_title || formJson.all_pages.home_page.title;
                        formJson.all_pages.home_page.subtitle = getters.getFormTemplateData.homepage_subtitle || formJson.all_pages.home_page.subtitle;
                        formJson.all_pages.home_page.imageUrl = getters.getFormTemplateData.homepage_image || formJson.all_pages.home_page.imageUrl;
                        getters.getFormTemplateData.fields_order.forEach(fieldID => {
                            const newMainPage = constants.FORM_TYPES.INTERACTIVE_FORM.MAIN_PAGE_TEMPLATE()
                            newMainPage.field_id = fieldID

                            const pageID = generateUUID()
                            formJson.all_pages[pageID] = newMainPage
                            formJson.main_pages.push({page_id: pageID})

                            commit('SET_INTERACTIVE_FORM_SOURCE_JSON', formJson)
                        })
                    }
                })
        },
        getters: {
            getFormTemplateId: ({getters}) => getters[PathableUtils.pathGetterName].at(-1)
        }
    }
}