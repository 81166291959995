<script>
    import {PlusIcon} from 'vue-feather-icons'

    export default {
        name: 'GenericAddOptionButton',
        functional: true,
        props: {
            hidden: {
                type: Boolean,
                default: false,
            },
            isEditingActive: {
                type: Boolean,
                default: true,
            }
        },
        render: (h, ctx) => h(
            'div',
            {
                class: {
                    'generic-add-option-button': true,
                    'visible-when-field-editing': true,
                    'generic-field__escape-form-font': true,
                    'visible-when-field-editing--hidden': ctx.props.hidden || !ctx.props.isEditingActive
                },
                on: {
                    click: ctx.listeners['add-option']
                }
            },
            [
                ctx.scopedSlots.icon?.() || h(PlusIcon, {props: {size: '16', strokeWidth: '1.5'}}),
                h(
                    'span',
                    null,
                    ctx.scopedSlots.default?.() || 'Add option'
                )
            ]
        )
    }
</script>

<style scoped lang="scss">
    .generic-field {
        .generic-add-option-button {
            display: flex;
            padding: 4px 12px 4px 8px;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            gap: 5px;
            width: fit-content;
            border-radius: 4px;
        }

        &--static {
            .generic-add-option-button {
                color: black;
                background: transparent;
                position: absolute;
                padding: 5px 0;
                bottom: 16px;
            }
        }

        &--interactive {
            .generic-add-option-button {
                margin-top: 20px;

                background: rgba(255, 255, 255, 0.2);

                --FSP__Secondary__InteractiveFormBranding__color--default: calc((1 - var(--FSP__SubPrimary__BackgroundImageIsLight--BINARY)) * 255);

                color: rgb(var(--FSP__Secondary__InteractiveFormBranding__color--default), var(--FSP__Secondary__InteractiveFormBranding__color--default), var(--FSP__Secondary__InteractiveFormBranding__color--default));
            }
        }
    }
</style>