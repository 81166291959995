<template>
    <div class="summary-answers-block-heading">
        <component
            :is="fieldTypeConst.ICON_COMPONENT"
            class="summary-answers-block-heading__icon"
        />
        <div class="summary-answers-block-heading__text-block">
            <p class="summary-answers-block-heading__title">
                {{Utils.stripHtml(sourceObj.title) || '-'}}
            </p>
            <p class="summary-answers-block-heading__subtitle">
                <span class="summary-answers-block-heading__answers-count">{{ answered }}</span>
                of {{ submissionsCount }} answered
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SummaryFieldViewHeading",
        props: {
            sourceObj: {
                type: Object,
                required: true
            },
            answered: {
                type: [Number, String],
                required: true
            },
            submissionsCount: {
                type: [Number, String],
                required: true
            }
        },
        computed: {
            fieldTypeConst() {
                return this.constants.FIELD_FROM_TYPE[this.sourceObj.field_type]
            }
        }
    }
</script>

<style scoped>

</style>