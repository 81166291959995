<template>
  <SharedView
      :showImage="!!fieldProperties.image_content.url"
      :src="fieldProperties.image_content.url"
      :width="fieldProperties.image_size.width + '%'"
      :lazy-src="fieldProperties.image_content.url"
      :aspect-ratio="(fieldProperties.image_content.naturalWidth / fieldProperties.image_content.naturalHeight) / (fieldProperties.image_size.height / 100)"
  >
    <template #loader>
      <div v-if="uploadingInProcess">
        <f-circle-loader size="3"/>
      </div>
    </template>
    <template #removeButton="hover">
      <v-scale-transition origin="center center 0">
        <v-btn
            v-if="hover && isEditingActive"
            class="image-field-view__img-remove-btn"
            icon
            @click="removeImage"
        >
          <XIcon size="14" stroke-width="2.9"/>
        </v-btn>
      </v-scale-transition>
    </template>
    <template #addButton>
      <AddOptionButton :is-editing-active="isEditingActive"
                       :hidden="!!fieldProperties.image_content.url"
                       @add-option="addImage"
      >
        Add image
      </AddOptionButton>
    </template>
  </SharedView>
</template>

<script>
    import
    {XIcon} from 'vue-feather-icons'
    import editable_field_view_mixin
        from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: "Editable__Image",
        mixins: [editable_field_view_mixin],
        components: {
            AddOptionButton: () => import('@/components/Fields/EditableField/EditableFieldViewUtils/GenericAddOptionButton'),
            XIcon,
            SharedView: () => import('./SharedView'),
        },
        data() {
            return {
                uploadingInProcess: false,
            }
        },
        methods: {
            removeImage() {
                this.fieldProperties.image_content.url = '';
            },
            addImage() {
                this.fileInputEventBus.$emit('trigger-input', {
                    mimeTypes: ['image/png', 'image/jpg', 'image/jpeg'],
                    maxFilesCount: 1,
                    maxFileSize: 10,
                    callback: file => {
                        this.uploadingInProcess = true;
                        this.StorageActions.uploadPublicAttachment(file)
                            .then(url => {
                                this.fieldProperties.image_content.url = url
                                this.uploadingInProcess = false;
                            })
                    }
                })
            },
        }
    }
</script>