<template>
  <div class="address-field-view generic-text-field-view">
    <div class="address-field-view__item__wrapper"
        v-for="({key, mask}, index) in getAddressFields"
          v-bind:key="index">
      <GenericStringSingleLineFieldViewInput
          v-if="editable || preview || key !== 'country'"
          class="address-field-view__item"
          :key="index"
          :ref="editable || preview ? '' : genRef(key)"
          autocomplete="off"
          :is-form-static-prop="isFormStaticProp"
          :placeholder="placeholders[key]"
          :disabled="editable || preview"
          :value="submissionObj?.value[key]"
          @input="setValue($event, key)"
          v-input-mask="mask || /.*/"
          v-on="{
            ...$listeners
          }"
      >
        <template v-if="key === 'country'" #append>
          <ChevronDownIcon/>
        </template>
      </GenericStringSingleLineFieldViewInput>
      <f-autocomplete
          v-else
          :placeholder="placeholders.country"
          :items="constants.COUNTRY_LIST"
          :item-value="item => item.code"
          :filter="(item, search) => item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())"
          class="address-field-view__item generic-text-field-view__input-field"
          :value="submissionObj?.value?.country"
          @input="setValue($event, 'country')"
          hide-details
          :disabled="preview"
          :outlined="isFormStatic.value || isFormStaticProp"
          dense
          :ref="editable || preview ? '' : genRef('country')"
          v-on="{
            ...$listeners,
          }"
      >
        <template #item="{on, attrs, item}">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-icon>
              <f-country-flag size="m" :code="item.code"/>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #selection="{item}">
                  <span class="d-flex align-center">
                      <f-country-flag size="l" class="mr-2" :code="item.code"/>
                      {{ item.name }}
                  </span>
        </template>
        <template #append>
          <ChevronDownIcon/>
        </template>
      </f-autocomplete>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SharedView__Address',
  components: {
    GenericStringSingleLineFieldViewInput: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput'),
    ChevronDownIcon: () => import('vue-feather-icons/icons/ChevronDownIcon'),
  },
  inject: ['isFormStatic'],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    firstInputName: {
      type: String,
      default: null,
    },
    addressFields: {
      type: Object,
      required: true,
    },
    placeholders: {
      type: Object,
      required: true,
    },
    submissionObj: {
      type: Object,
      default: () => {
      },
    },
    genRef: {
      type: Function,
    },
    isFormStaticProp: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setValue(v, key) {
      this.submissionObj.value[key] = v;
    },
  },
  computed: {
    getAddressFields() {
      if (this.editable)
        return Object.entries(this.addressFields).filter(value => value[1]).map(([key]) => ({key}));
      else
        return Object.entries(this.addressFields).filter((item) => item[1]).map(([key]) => {
          return {
            key,
            mask: key === 'postal_code' ? /^[0-9a-zA-Z]{0,10}$/ : null,
          }
        });
    }
  }
}
</script>