var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F5F0FA"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip0_3_6367)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M14.8056 15.4076C16.1351 15.4076 17.213 14.3298 17.213 13.0002C17.213 11.6706 16.1351 10.5928 14.8056 10.5928C13.476 10.5928 12.3981 11.6706 12.3981 13.0002C12.3981 14.3298 13.476 15.4076 14.8056 15.4076Z",
      "fill": "#393F41"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17.9563 13.8002H19.1389C19.5807 13.8002 19.9389 13.442 19.9389 13.0002C19.9389 12.5584 19.5807 12.2002 19.1389 12.2002L17.9562 12.2002C18.021 12.4562 18.0554 12.7242 18.0554 13.0003C18.0554 13.2763 18.021 13.5443 17.9563 13.8002ZM11.6544 13.8002C11.5896 13.5443 11.5552 13.2763 11.5552 13.0003C11.5552 12.7242 11.5896 12.4562 11.6544 12.2002L6.5 12.2002C6.05817 12.2002 5.7 12.5584 5.7 13.0002C5.7 13.442 6.05817 13.8002 6.5 13.8002H11.6544Z",
      "fill": "#393F41"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_3_6367"
    }
  }, [_c('rect', {
    attrs: {
      "width": "14.4444",
      "height": "14.4444",
      "fill": "white",
      "transform": "translate(5.77777 5.77783)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }