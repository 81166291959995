var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "emoji-set-select-property"
  }, _vm._l(_vm.inputConst.sets, function (set, index) {
    return _c('div', {
      key: index,
      staticClass: "emoji-set-select-property__item background-gray_100-selectable",
      class: _vm.inputValue === index && 'background-gray_100-selectable--selected',
      on: {
        "click": function ($event) {
          return _vm.input(index);
        }
      }
    }, _vm._l(set, function (emoji) {
      return _c('span', {
        key: emoji + index,
        staticClass: "emoji-set-select-property__emoji"
      }, [_vm._v(_vm._s(emoji))]);
    }), 0);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }