import '@/utils/composition_api_setup'

import Utils, { HEXtoRGB, getBrightnessOfColor, getAverageColorFromImageURL } from '@/utils/misc'
import { isArray, isString } from 'lodash'

class Category {
    constructor({KEY, SUFFIX, APPLY = () => null}) {
        this.KEY = KEY
        this.SUFFIX = SUFFIX
        this._APPLY = APPLY
    }

    apply(...args) {
        return this._APPLY(...args)
    }
}

const CATEGORIES = {
    COLOR: new Category({
        KEY: 'color',
        SUFFIX: 'Color',
        APPLY: (primaryVariable, {
            opacity,
            asList = false
        } = {}) => {
            if (opacity !== undefined) {
                if (!asList)
                    return `rgba(var(${primaryVariable.COMPOSED_NAMES.RGB}), ${opacity})`
                return [`var(${primaryVariable.COMPOSED_NAMES.R})`, `var(${primaryVariable.COMPOSED_NAMES.G})`, `var(${primaryVariable.COMPOSED_NAMES.B})`, opacity]
            }

            if (!asList)
                return `var(${primaryVariable.COMPOSED_NAMES.RAW})`
            return [`var(${primaryVariable.COMPOSED_NAMES.R})`, `var(${primaryVariable.COMPOSED_NAMES.G})`, `var(${primaryVariable.COMPOSED_NAMES.B})`, 1]
        }
    }),
    BOOLEAN: new Category({
        KEY: 'boolean',
        SUFFIX: 'Boolean',
        APPLY: (primaryVariable, {
            asColors: {
                true: colorIfTrue,
                false: colorIfFalse
            } = {}
        } = {}) => {
            let variable = `var(${primaryVariable.COMPOSED_NAMES.RAW})`
            
            if (colorIfTrue && colorIfFalse) {
                if (
                    !(colorIfTrue instanceof BaseColorPrimaryVariable) && (!isArray(colorIfTrue) || colorIfTrue.length !== 4)
                    || !(colorIfFalse instanceof BaseColorPrimaryVariable) && (!isArray(colorIfFalse) || colorIfFalse.length !== 4)
                )
                    throw Error('Applied colors must be arrays [r, g, b, a] or Primary variables')

                colorIfTrue = colorIfTrue instanceof BaseColorPrimaryVariable ? colorIfTrue.apply({asList: true}) : colorIfTrue
                colorIfFalse = colorIfFalse instanceof BaseColorPrimaryVariable ? colorIfFalse.apply({asList: true}) : colorIfFalse

                const composedBinaryVarName = `var(${primaryVariable.COMPOSED_NAMES.BINARY})`
                const createCalcExpression = (t, f) => {
                    let firstPart,
                        secondPart

                    const applyPart = (variable, value) => {
                        if (value > 0 || isString(value))
                            return `(${variable} * ${value})`
                        else
                            return
                    }

                    firstPart = applyPart(composedBinaryVarName, t)
                    secondPart = applyPart(`(1 - ${composedBinaryVarName})`, f)

                    if (firstPart && secondPart)
                        return `calc(${firstPart} + ${secondPart})`
                    return ((firstPart || secondPart) && `calc(${firstPart || secondPart})`)
                        || '0'
                }

                const [rT, gT, bT, aT] = colorIfTrue
                const [rF, gF, bF, aF] = colorIfFalse
                
                const r = createCalcExpression(rT, rF),
                    g = createCalcExpression(gT, gF),
                    b = createCalcExpression(bT, bF),
                    a = createCalcExpression(aT, aF)
                
                variable = `rgba(${r}, ${g}, ${b}, ${a})`
            }

            return variable
        }
    }),
    INTEGER: new Category({
        KEY: 'integer',
        SUFFIX: 'Integer',
        APPLY: (primaryVariable, {
            calc,
            px,
            max
        } = {}) => {
            if (px && max)
                max = isString(max) ? max : max + 'px'
            let variable = `var(${primaryVariable.COMPOSED_NAMES[px ? 'PX' : 'RAW']})`

            if (max)
                variable = `min(${variable}, ${max})`

            if (calc !== undefined)
                return `calc(${calc(variable)})`
            return variable
        }
    }),
    URL: new Category({
        KEY: 'url',
        SUFFIX: 'Url'
    })
}

const CATEGORY_BY_KEY = Object.fromEntries(
    Object.values(CATEGORIES)
        .map(obj => [obj.KEY, obj])
)

class PrimaryVariable {
    constructor({
        KEY,
        READABLE_NAME,
        GETTER_BASE_NAME,
        JSON_NAME,
        CATEGORY,
        SUB_VARIABLES,
        ADDITIONAL_GETTERS = {}
    }, sub) {
        this.KEY = KEY
        this.READABLE_NAME = READABLE_NAME
        this.GETTER_BASE_NAME = GETTER_BASE_NAME
        this.JSON_NAME = JSON_NAME
        this.CATEGORY = CATEGORY
        this.GETTERS = {
            RAW: {
                name: `get${GETTER_BASE_NAME}`,
                value: v => v,
                composed_suffix: ''
            },
        }
        Object.entries(ADDITIONAL_GETTERS)
            .forEach(([additionalGetterKey, additionalGetterGen]) => Object.assign(this.GETTERS, {[additionalGetterKey]: additionalGetterGen(this.GETTERS.RAW)}))
        this.PARENT_GETTERS = {}
        this.SUB_VARIABLES = SUB_VARIABLES || {}
        this.sub = sub

        Object.values(this.SUB_VARIABLES)
            .forEach(subVariable => subVariable.PARENT_GETTERS = this.GETTERS)
    }

    apply(options) {
        return this.CATEGORY.apply(this, options)
    }

    getSubVariables() {
        return [
            this,
            ...Object.values(this.SUB_VARIABLES)
                .map(subVariable => subVariable.getSubVariables())
                .flat()
        ]
    }

    getSubVariablesAsObject() {
        const result =  {
            [this.KEY]: this
        }


        Object.values(this.SUB_VARIABLES)
            .forEach(subVariable => Object.assign(result, subVariable.getSubVariablesAsObject()))
        return result
    }

    get COMPOSED_NAMES() {
        return Object.fromEntries(
            Object.entries(this.GETTERS)
                .map(([getterKey, {composed_suffix}]) => [getterKey, `--FSP__${this.sub ? 'SubPrimary' : 'Primary'}__${this.GETTER_BASE_NAME}${composed_suffix}`])
        )
    }
}

const SubPrimaryVariableMixin = SuperPrimaryClass => class extends SuperPrimaryClass {
    constructor(options) {
        const {WATCHING_PARENT_GETTER_PREFIX, VALUE_GETTER, ...restOptions} = options
        super(restOptions, true)


        this.WATCHING_PARENT_GETTER_PREFIX = WATCHING_PARENT_GETTER_PREFIX
        this.VALUE_GETTER = VALUE_GETTER
    }

    get PARENT_GETTER() {
        return this.PARENT_GETTERS[this.WATCHING_PARENT_GETTER_PREFIX]
    }
}

class BaseColorPrimaryVariable extends PrimaryVariable {
    constructor(options, sub) {
        const {ADDITIONAL_GETTERS = {}} = options
        ADDITIONAL_GETTERS.RGB = rawGetterObj => ({
            name: rawGetterObj.name + '--RGB',
            value: v => v ? HEXtoRGB(v, {list: true}) : v,
            composed_suffix: '--RGB'
        })
        ADDITIONAL_GETTERS.R = rawGetterObj => ({
            name: rawGetterObj.name + '--R',
            value: v => v ? HEXtoRGB(v, {list: true})[0] : v,
            composed_suffix: '--R'
        })
        ADDITIONAL_GETTERS.G = rawGetterObj => ({
            name: rawGetterObj.name + '--G',
            value: v => v ? HEXtoRGB(v, {list: true})[1] : v,
            composed_suffix: '--G'
        })
        ADDITIONAL_GETTERS.B = rawGetterObj => ({
            name: rawGetterObj.name + '--B',
            value: v => v ? HEXtoRGB(v, {list: true})[2] : v,
            composed_suffix: '--B'
        })
        super({...options, ADDITIONAL_GETTERS, CATEGORY: CATEGORIES.COLOR}, sub)
    }
}

class ColorSuperPrimaryVariable extends BaseColorPrimaryVariable {
    constructor(options) {
        super(options, false)
    }
}

class ColorSubPrimaryVariable extends SubPrimaryVariableMixin(BaseColorPrimaryVariable) {}

class BaseStringPrimaryVariable extends PrimaryVariable {}

class StringSuperPrimaryVariable extends BaseStringPrimaryVariable {}

class StringSubPrimaryVariable extends SubPrimaryVariableMixin(BaseStringPrimaryVariable) {}


class BaseBooleanPrimaryVariable extends PrimaryVariable {
    constructor(options, sub) {
        const {ADDITIONAL_GETTERS = {}} = options
        ADDITIONAL_GETTERS.BINARY = rawGetterObj => ({
            name: rawGetterObj.name + '--BINARY',
            value: v => v ? 1 : 0,
            composed_suffix: '--BINARY'
        })
        ADDITIONAL_GETTERS.INVERTED = rawGetterObj => ({
            name: rawGetterObj.name + '--INVERTED',
            value: v => !v,
            composed_suffix: '--INVERTED'
        })
        super({...options, ADDITIONAL_GETTERS, CATEGORY: CATEGORIES.BOOLEAN}, sub)
    }
}

class BooleanSubPrimaryVariable extends SubPrimaryVariableMixin(BaseBooleanPrimaryVariable) {}


class BaseUrlPrimaryVariable extends PrimaryVariable {
    constructor(options, sub) {
        const {ADDITIONAL_GETTERS = {}} = options
        ADDITIONAL_GETTERS.URL = rawGetterObj => ({
            name: rawGetterObj.name + '--URL',
            value: v => `url(${v})`,
            composed_suffix: '--URL'
        })

        super({...options, ADDITIONAL_GETTERS, CATEGORY: CATEGORIES.URL}, sub)
    }
}

class UrlSuperPrimaryVariable extends BaseUrlPrimaryVariable {}

class BaseIntegerPrimaryVariable extends PrimaryVariable {
    constructor(options) {
        const {ADDITIONAL_GETTERS = {}} = options
        ADDITIONAL_GETTERS.PX = rawGetterObj => ({
            name: rawGetterObj.name + '--PX',
            value: v => `${v}px`,
            composed_suffix: '--PX'
        })
        super({...options, ADDITIONAL_GETTERS, CATEGORY: CATEGORIES.INTEGER}, false)
    }
}

class IntegerSuperPrimaryVariable extends BaseIntegerPrimaryVariable {}

const PRIMARY_VARIABLES = {
    FORM_FONT: new StringSuperPrimaryVariable({
        KEY: 'FORM_FONT',
        READABLE_NAME: 'FormFont',
        GETTER_BASE_NAME: 'FormFont',
        JSON_NAME: 'form_font'
    }),
    QUESTION_COLOR: new ColorSuperPrimaryVariable({
        KEY: 'QUESTION_COLOR',
        READABLE_NAME: 'Question',
        GETTER_BASE_NAME: 'QuestionColor',
        JSON_NAME: 'question_color'
    }),
    ANSWER_COLOR: new ColorSuperPrimaryVariable({
        KEY: 'ANSWER_COLOR',
        READABLE_NAME: 'Answer',
        GETTER_BASE_NAME: 'AnswerColor',
        JSON_NAME: 'answer_color'
    }),
    BUTTON_BACKGROUND_COLOR: new ColorSuperPrimaryVariable({
        KEY: 'BUTTON_BACKGROUND_COLOR',
        READABLE_NAME: 'Button fill',
        GETTER_BASE_NAME: 'ButtonBackgroundColor',
        JSON_NAME: 'button_background_color'
    }),
    BUTTON_TEXT_COLOR: new ColorSuperPrimaryVariable({
        KEY: 'BUTTON_TEXT_COLOR',
        READABLE_NAME: 'Button text',
        GETTER_BASE_NAME: 'ButtonTextColor',
        JSON_NAME: 'button_text_color'
    }),
    BORDER_RADIUS: new IntegerSuperPrimaryVariable({
        KEY: 'BORDER_RADIUS',
        READABLE_NAME: 'Border Radius',
        GETTER_BASE_NAME: 'BorderRadius',
        JSON_NAME: 'border_radius'
    }),
    BACKGROUND_COLOR: new ColorSuperPrimaryVariable({
        KEY: 'BACKGROUND_COLOR',
        GETTER_BASE_NAME: 'BackgroundColor',
        READABLE_NAME: 'Choice labels',
        JSON_NAME: 'background_color',
    }),
    BACKGROUND_IMAGE: new UrlSuperPrimaryVariable({
        KEY: 'BACKGROUND_IMAGE',
        READABLE_NAME: 'Background Image',
        GETTER_BASE_NAME: 'BackgroundImage',
        JSON_NAME: 'background_image',
        SUB_VARIABLES: {
            BACKGROUND_IMAGE_AVERAGE_COLOR_FORMATTER: new StringSubPrimaryVariable({
                KEY: 'BACKGROUND_IMAGE_AVERAGE_COLOR_FORMATTER',
                GETTER_BASE_NAME: 'BackgroundImageAverageColorFormatter',
                WATCHING_PARENT_GETTER_PREFIX: 'RAW',
                VALUE_GETTER: async (v) => {
                    if (v)
                        return await getAverageColorFromImageURL(v)
                    return '#FFFFFF'
                },
                SUB_VARIABLES: {
                    BACKGROUND_IMAGE_AVERAGE_COLOR: new ColorSubPrimaryVariable({
                        KEY: 'BACKGROUND_IMAGE_AVERAGE_COLOR',
                        GETTER_BASE_NAME: 'AverageBackgroundImageColor',
                        WATCHING_PARENT_GETTER_PREFIX: 'RAW',
                        VALUE_GETTER: v => v,
                        SUB_VARIABLES: {
                            BACKGROUND_IMAGE_IS_LIGHT: new BooleanSubPrimaryVariable({
                                KEY: 'BACKGROUND_IMAGE_IS_LIGHT',
                                GETTER_BASE_NAME: 'BackgroundImageIsLight',
                                WATCHING_PARENT_GETTER_PREFIX: 'RAW',
                                VALUE_GETTER: v => getBrightnessOfColor(v) > 150
                            })
                        }
                    })
                }
            })
        }
    })
}


const PRIMARY_VARIABLE_FROM_JSON_NAME = Object.fromEntries(
    Object.values(PRIMARY_VARIABLES)
        .map(valueObj => [valueObj.JSON_NAME, valueObj])
)

const ALL_PRIMARY_VARIABLES_ARRAY = Object.values(PRIMARY_VARIABLES)
    .map(superPrimaryVariable => superPrimaryVariable.getSubVariables())
    .flat()

const ALL_PRIMARY_VARIABLES_OBJECT = (() => {
    const result = {}
    Object.values(PRIMARY_VARIABLES)
        .forEach(superPrimaryVariable => Object.assign(result, superPrimaryVariable.getSubVariablesAsObject()))
    return result
})()

const GENERIC_FIELD_CLASS = {
    _baseClass: 'generic-field',
    _FSPKeyName: 'generic-field',
    _allowedModifiers: ['interactive', 'static', 'editable', 'submittable', 'editing-active'],

    genClassesList(options) {
        return Object.entries(options)
            .filter(([modifierName, modifierIsRequested]) => modifierIsRequested && this._allowedModifiers.includes(modifierName))
            .map(([modifierName]) => this._baseClass + '--' + modifierName)
    },

    apply(options) {
        return this.genClassesList(options)
            .reduce((accum, v) => accum + '.' + v, '.' + this._baseClass)
    },

    applyAsFSPConnector(options) {
        return {
            [this._FSPKeyName]: {
                class: this.apply(options),
                raw: options
            }
        }
    }
}


const VARIABLES_INJECTOR_CLASS = FSPModuleLocation => `FSP_Variables_Injector--${FSPModuleLocation}`.replaceAll(/[^-_\w]/g, '')


const SECONDARY_VARIABLE_NAME_COMPOSER = ({
    parentKey,
    elementKey,
    propertyKey,
    stateKey
}) => `--FSP__Secondary__${parentKey}__${elementKey}__${propertyKey}--${stateKey}`.replaceAll(/[^-_\w]/g, '')

const DEFAULT_FSP_OBJECT = () => ({
    [PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]: 'Inter',
    [PRIMARY_VARIABLES.QUESTION_COLOR.JSON_NAME]: '#000000',
    [PRIMARY_VARIABLES.ANSWER_COLOR.JSON_NAME]: '#EF477A',
    [PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.JSON_NAME]: '#EF477A',
    [PRIMARY_VARIABLES.BUTTON_TEXT_COLOR.JSON_NAME]: '#FFFFFF',
    [PRIMARY_VARIABLES.BORDER_RADIUS.JSON_NAME]: 6,
    [PRIMARY_VARIABLES.BACKGROUND_COLOR.JSON_NAME]: '#dcdcdc',
    [PRIMARY_VARIABLES.BACKGROUND_IMAGE.JSON_NAME]: Utils.getBase64ImageFromColor('#dcdcdc')
})


export default {
    SECONDARY_VARIABLE_NAME_COMPOSER,
    CATEGORIES,
    CATEGORY_BY_KEY,
    PRIMARY_VARIABLES,
    ALL_PRIMARY_VARIABLES_ARRAY,
    ALL_PRIMARY_VARIABLES_OBJECT,
    PRIMARY_VARIABLE_FROM_JSON_NAME,
    GENERIC_FIELD_CLASS,
    VARIABLES_INJECTOR_CLASS,
    DEFAULT_FSP_OBJECT
}