import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { GenericTextFieldFSPDefinition } from '@/components/Fields/GenericFieldViews/GenericTextField'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst, DividerFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import { GenericOptionsConditionalLogic } from '@/components/Fields/FieldConstClasses/conditional_logic'
import { COUNTRY_LIST } from '@/constants/country_list_constants'
import { US_STATES_LIST } from '@/constants/us_states_constants'
import { Analytics, GenericTextAnalytics } from '@/components/Fields/FieldConstClasses/analytics'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import { FieldPropertySelectInput } from '@/components/Fields/FieldConstClasses/field_property_input'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import constants from '@/constants/constants'
import { GenericSubmittableDropdownFSP } from '@/components/Fields/GenericFieldViews/GenericSubmittableDropdown'
import {FieldFSPDefinition} from '@/components/Fields/FieldConstClasses/fsp_field_definition'
import FSP from "@/constants/fsp_constants";
import {isString} from 'lodash'
import Dropdown from '@/components/Fields/FieldsViews/Dropdown'

class DropdownFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'DROPDOWN',
            READABLE_NAME: 'Dropdown',
            ICON_NAME: 'dropdown',
            FOLDER_NAME: 'Dropdown',

            CONDITIONAL_LOGIC: new GenericOptionsConditionalLogic({
                PARAMS: {
                    MULTIPLE: () => false,
                    VALUE_FORMATTER: fieldObj => {
                        if (typeof fieldObj.content.options === 'object') {
                            return fieldObj.content.options.map((option, index) => ({index, text: option || ''}))
                        } else {
                            return constants[fieldObj.content.options].map((option, index) => ({
                                index,
                                text: option.name || ''
                            }))
                        }
                    }
                }
            }),

            ANALYTICS: new Analytics({
                SORT: GenericTextAnalytics.DEFAULT_SORT,
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                    const isReadyList = isString(fieldObj.content.options)
                    const currentReadyList = Dropdown.UTIL.READY_LISTS[fieldObj.content.options]
                    if (submissionObj.value.length) {
                        if (isReadyList)
                            return currentReadyList.LIST[submissionObj.value[0]].name
                        return fieldObj.content.options[submissionObj.value[0]]
                    }
                },
                SHORT_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/Dropdown/Analytics/GenericSingleAnswer'),
                FULL_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/Dropdown/Analytics/GenericSingleAnswer')
            }),

            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: 'Type or select an option',
                    options: [],
                }
            }),

            SUBMISSION_TEMPLATE: () => ({
                value: []
            }),

            AUTO_SCROLL_HANDLER: submissionObj => submissionObj.value.length,

            IS_SUBMISSION_EMPTY: ({value}) => !value?.length,

            UTIL: {
                READY_LISTS: {
                    COUNTRIES: {
                        EMOJI: '🌎',
                        ICON_COMPONENT: {
                            NAME: 'f-country-flag',
                            BIND: (item, {overrideCountryFlagSize = 'l'} = {}) => ({code: item.code, size: overrideCountryFlagSize})
                        },
                        READABLE_NAME: 'List of countries',
                        LIST: COUNTRY_LIST
                    },
                    US_STATES: {
                        EMOJI: '📍',
                        ICON_COMPONENT: {
                            NAME: 'f-us-state-flag',
                            BIND: item => ({code: item.code})
                        },
                        READABLE_NAME: 'List of US states',
                        LIST: US_STATES_LIST
                    }
                }
            },

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            FSP: [
                GenericSubmittableDropdownFSP,
                GenericTextFieldFSPDefinition,
                new FieldFSPDefinition({
                    FSP_FIELD_TYPE: 'Dropdown',
                    FIELD_VIEW_CLASS: 'dropdown-field-view',
                    VARIABLES: [
                        {
                            selector: ['options-count'],
                            properties: {
                                color: {
                                    'default--editable--interactive': {
                                        fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                                            asColors: {
                                                true: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                                                    opacity: 0.5,
                                                    asList: true
                                                }),
                                                false: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({
                                                    opacity: 0.7,
                                                    asList: true
                                                })
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    ]
                })
            ],

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'randomize',
                    name: 'Randomize answers',
                }),
                new DividerFieldPropertyConst(),
                new CustomFieldPropertyConst({
                    key: 'sort_options',
                    name: 'Sort Options',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertySelectInput({
                            items: [
                                {key: 'default', readable_name: 'Default'},
                                {key: 'alphabet', readable_name: 'Alphabet'}
                            ]
                        })
                    )
                })
            ]),
        })
    }
}



export default new DropdownFieldTypeConst()