<template>
  <div class="matrix-field-view">
    <div class="matrix-field-view__layout matrix-field-view__layout--desktop">
      <div class="matrix-field-view__table-wrapper">
        <table>
          <thead class="matrix-field-view__table-head">
          <tr class="matrix-field-view__table-row">
            <td style="width: 300px"></td>
            <f-template-data-transfer
                v-for="(column, columnIndex) in columns"
                :key="'column' + columnIndex"
                :focused="false"
                v-slot="{ dataStash }"
            >
              <v-hover v-slot="{hover}">
                <td :class="[
                              'matrix-field-view__column-cell',
                               editable && 'matrix-field-view__remove-column-btn'
                            ]"
                >
                  <div>
                    <XIcon
                        :class="[
                                'matrix-field-view__remove-icon',
                                (editable && rows.length !== 1 && (dataStash.focused || hover)) && 'matrix-field-view__remove-icon--visible'
                              ]"
                        @click="$emit('remove-column', columnIndex)"
                    />
                    <f-simple-contenteditable-input
                        v-model="column.text"
                        prevent-highlighting
                        :placeholder="editable ? 'Title' : ''"
                        :readonly="!editable"
                        @update:focused="dataStash.focused = $event"
                    />
                  </div>
                </td>
              </v-hover>
            </f-template-data-transfer>
          </tr>
          </thead>
          <tbody>
          <f-template-data-transfer
              v-for="(row, rowIndex) in rows"
              :key="'row' + rowIndex"
              :focused="false"
              v-slot="{ dataStash }"
          >
            <v-hover v-slot="{hover}">
              <tr class="matrix-field-view__table-row">
                <td class="matrix-field-view__column-cell matrix-field-view__row-text">
                  <f-simple-contenteditable-input
                      v-model="row.text"
                      prevent-highlighting
                      :readonly="!editable"
                      :placeholder="editable ? 'Question here..' : ''"
                      @update:focused="dataStash.focused = $event"
                  />
                  <XIcon
                      :class="[
                                  'matrix-field-view__remove-row-button',
                                  'matrix-field-view__remove-icon',
                                  editable && rows.length !== 1 && (dataStash.focused || hover) && 'matrix-field-view__remove-icon--visible'
                              ]"
                      @click="$emit('remove-row', rowIndex)"
                  />
                </td>
                <td
                    v-for="(_, columnIndex) in columns.length"
                    class="matrix-field-view__column-cell"
                    :key="'row' + rowIndex + 'column' + columnIndex"
                >
                  <f-checkbox
                      :radio="radio"
                      :disabled="editable || preview"
                      @input="(e) => e ? $emit('toggle-selection:add', {rowIndex, columnIndex}) : $emit('toggle-selection:remove', {rowIndex, columnIndex})"
                      :value="editable || preview ? false : submissionObjValue[rowIndex].includes(columnIndex)"
                  />
                </td>
              </tr>
            </v-hover>
          </f-template-data-transfer>
          </tbody>
        </table>
      </div>
      <slot name="addColumnButton"></slot>
    </div>
    <slot name="addOptionButton"></slot>
    <MobileView
      v-if="!editable && !preview"
      :columns="columns"
      :rows="rows"
      :submission-obj-value="submissionObjValue"
      @toggle-selection="(e) => e.state ? $emit('toggle-selection:add', e) : $emit('toggle-selection:remove', e)"
    />
  </div>
</template>

<script>
export default {
  name: 'SharedView__Matrix',
  components: {
    XIcon: () => import('vue-feather-icons/icons/XIcon'),
    MobileView: () => import('../Matrix/MobileView'),
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    radio: {
      type: Boolean,
      required: true,
    },
    submissionObjValue: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    submissionObjValue: {
      deep: true,
      handler: (v) => console.log(v),
    }
  }
}
</script>