var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "static-form-editor-navigation"
  }, [_c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "disabled": _vm.isFirstPage,
      "depressed": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('ChevronLeftIcon', {
    attrs: {
      "color": "bluish_gray_500--text",
      "size": "20"
    }
  })], 1), _c('FileIcon', {
    staticClass: "mr-2 gray_400--text",
    attrs: {
      "size": "18"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.currentPageNumber) + " ")]), _c('span', {
    staticClass: "mx-1 gray_400--text"
  }, [_vm._v(" / ")]), _c('span', {
    staticClass: "gray_400--text"
  }, [_vm._v(" " + _vm._s(_vm.pagesCount) + " ")]), _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "disabled": _vm.isLastPage,
      "depressed": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('ChevronRightIcon', {
    attrs: {
      "size": "20"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }