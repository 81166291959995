<template>
    <f-fluid-container
            :class="[
                'interactive-form',
                'interactive-form--submittable',
            ]"
    >
        <InteractiveFormPagesDisplayManager/>
    </f-fluid-container>
</template>

<script>
    export default {
        name: "InteractiveFormSubmit",
        components: {
            InteractiveFormPagesDisplayManager: () => import('./Pages/InteractiveFormPagesDisplayManager')
        },
    }
</script>
