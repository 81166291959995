<template>
    <FormPreview
            :external-disabled="externalOpenFormDisabled"
            @external-open="openInNewTab"
            @device-change="onDeviceChange"
            @close="closeFormSubmitPreview"
    >
        <template #activator="{on}">
            <v-btn
                    class="mx-3 v-btn--transparent-with-icon v-btn--text-hoverable"
                    text
                    :disabled="getIsFormFetching"
                    @click="openFormSubmitPreview(() => on.click($event))"
                    rounded
                    outlined
            >
                <v-icon size="18" class="v-btn__prepend-icon">mdi-eye</v-icon>
                Preview
            </v-btn>
        </template>
        <template #default="{active}">
            <FormSubmit
                    v-if="!getIsFormFetching && getFormDBData && active"
                    :form-id="getFormDBData.id"
                    embedded
                    store-module-path="edit/submit_preview"
            />
        </template>
    </FormPreview>
</template>

<script>
    import { mapActions, mapGetters } from '@/xstore'

    export default {
        name: "PreviewButton",
        components: {
            FormPreview: () => import('@/components/Elements/FormPreview'),
            FormSubmit: () => import('@/views/FormSubmit/FormSubmit'),
        },
        computed: {
            ...mapGetters('edit', [
                'getFormDBData',
                'getIsFormFetching',
            ]),
            externalOpenFormDisabled() {
                return !this.getFormDBData?.publishedVersionID
            }
        },
        methods: {
            ...mapActions('edit', [
                'openFormSubmitPreview',
                'closeFormSubmitPreview'
            ]),
            openInNewTab() {
                window.open(this.$router.resolve({name: this.constants.PAGE_NAME_FORM_SUBMIT, params: {formId: this.getFormDBData.id}}).href, '_blank')
            },
            onDeviceChange() {
                // this.$store.dispatch(`submit/device/set${deviceType}View`)
            },
        }
    }
</script>
<style scoped lang="scss">
    .v-btn--text-hoverable {
        &:before {
            background-color: #F5F6FB !important;
        }
    }
    .v-btn__prepend-icon {
        color: #9EA6A8 !important;
    }
</style>