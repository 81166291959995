var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-field-view"
  }, [_vm._t("loader"), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_vm.showImage ? _c('v-img', _vm._b({
          attrs: {
            "transition": "none",
            "eager": ""
          }
        }, 'v-img', Object.assign({}, _vm.$attrs), false), [_vm._t("removeButton", null, {
          "hover": hover
        })], 2) : _vm._e()];
      }
    }], null, true)
  }), _vm._t("addButton")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }