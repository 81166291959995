<template>
    <div class="progress-linear-vertical" :style="containerStyle">
        <div class="progress-linear-vertical__background" :style="backgroundStyle"></div>
        <div class="progress-linear-vertical__determinate" :style="determineStyle"></div>
    </div>
</template>

<script>
    export default {
        name: "VerticalProgressBar",
        props: {
            width: {
                type: [String, Number],
                default: 30
            },
            height: {
                type: [String, Number],
                default: 100
            },
            value: {
                type: Number,
                default: 50
            }
        },
        computed: {
            containerStyle() {
                return {
                    width: this.width + 'px',
                    height: this.height + 'px'
                }
            },
            backgroundStyle() {
                return {
                    height: (1 - this.value / 100) * this.height + 'px'
                }
            },
            determineStyle() {
                return {
                    height: (this.value / 100) * this.height + 'px'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .progress-linear-vertical {
        overflow: hidden;
        border-radius: 5px;

        &__background {
            background-color: var(--v-blue_100-base);
        }

        &__determinate {
            background-color: var(--v-blue_400-base);
        }
    }
</style>