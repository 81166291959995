var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paragraph-field-view generic-text-field-view"
  }, [_c('v-textarea', {
    ref: "input",
    staticClass: "generic-text-field-view__input-field",
    attrs: {
      "placeholder": _vm.sourceObj.content.placeholder,
      "hide-details": "",
      "rows": _vm.rowsCount,
      "outlined": _vm.isFormStatic,
      "auto-grow": ""
    },
    on: {
      "keydown": _vm.handleKeydown,
      "blur": function ($event) {
        _vm.showHint = false;
      },
      "focus": function ($event) {
        _vm.showHint = true;
      }
    },
    model: {
      value: _vm.submissionObj.value,
      callback: function ($$v) {
        _vm.$set(_vm.submissionObj, "value", $$v);
      },
      expression: "submissionObj.value"
    }
  }), _vm.showHint ? _c('v-input', {
    staticClass: "paragraph-field-view__hint paragraph-field-view__hint--desktop",
    attrs: {
      "messages": "Hit SHIFT + ENTER for new line"
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showHint,
      expression: "!showHint"
    }],
    staticStyle: {
      "height": "26px"
    }
  }), _vm.showHint ? _c('v-input', {
    staticClass: "paragraph-field-view__hint paragraph-field-view__hint--mobile",
    attrs: {
      "messages": "Hit RETURN for new line"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }