<template>
    <f-template-data-transfer :waitForLoad="true" v-slot="{dataStash}" @mounted="dataStash => dataStash.wait(() => {dataStash.waitForLoad = false; if (isCurrentEditingThemeChanged) $refs.themeNameEditor.focus()}, 300)">
        <div class="style-preferences-theme-editor">
            <div class="style-preferences-theme-editor__header">
                <ChevronLeftIcon
                        class="style-preferences-theme-editor__quit-button"
                        @click="processStylePreferencesExit(quitThemeEditor)"
                />
                <f-template-data-transfer :focused="false" v-slot="{dataStash}">
                    <div
                            :class="[
                                'style-preferences-theme-editor__theme-name-wrapper',
                                dataStash.focused && 'style-preferences-theme-editor__theme-name-wrapper--focused'
                            ]"
                    >
                        <f-hover-tooltip top text="Edit Theme name here" v-slot="{on}">
                            <f-simple-contenteditable-input
                                    :value="getCurrentEditingTheme.name"
                                    ref="themeNameEditor"
                                    class="style-preferences-theme-editor__theme-name"
                                    placeholder="Theme name"
                                    overflow-wrap
                                    :mask="/^.{0,75}$/g"
                                    @input="updateCurrentEditingThemeName($event)"
                                    @mouseenter="on.mouseenter"
                                    @mouseleave="on.mouseleave"
                                    @update:focused="dataStash.focused = $event"
                            />
                        </f-hover-tooltip>
                    </div>
                </f-template-data-transfer>
            </div>
            <div class="style-preferences-theme-editor__content thin-scrollbar">
                <FormParametersSubheader>Font</FormParametersSubheader>
                <FontPickerComponent
                        :value="getCurrentEditingThemeStyles[constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]"
                        @input="updateCurrentEditingThemeStyle({[constants.FSP.PRIMARY_VARIABLES.FORM_FONT.JSON_NAME]: $event})"

                        class="mb-6"
                        outlined
                        :menu-props="{
                            nudgeBottom: 55,
                            minWidth: 250,
                            maxHeight: 'auto',
                            maxWidth: 274,
                        }"
                />
                <FormParametersSubheader>Colors</FormParametersSubheader>
                <StylePreferencesThemeEditorColorOption
                        v-for="primaryColorVariable in colorVariables"
                        :value="getCurrentEditingThemeStyles[primaryColorVariable.JSON_NAME]"
                        @input="updateCurrentEditingThemeStyle({[primaryColorVariable.JSON_NAME]: $event})"
                        :key="primaryColorVariable.GETTER_BASE_NAME"
                >
                    {{ primaryColorVariable.READABLE_NAME }}
                </StylePreferencesThemeEditorColorOption>
                <template v-if="isFormInteractive">
                    <FormParametersSubheader>Homepage colors</FormParametersSubheader>
                    <StylePreferencesThemeEditorColorOption
                        :value="interactiveHomePageStyles.TITLE_COLOR"
                        @input="updateInteractiveHomePageStyles({TITLE_COLOR: $event})"
                    >Title color</StylePreferencesThemeEditorColorOption>
                    <StylePreferencesThemeEditorColorOption
                        :value="interactiveHomePageStyles.DESCRIPTION_COLOR"
                        @input="updateInteractiveHomePageStyles({DESCRIPTION_COLOR: $event})"
                    >Description color</StylePreferencesThemeEditorColorOption>
                    <StylePreferencesThemeEditorColorOption
                        :value="interactiveHomePageStyles.BUTTON_BACKGROUND_COLOR"
                        @input="updateInteractiveHomePageStyles({BUTTON_BACKGROUND_COLOR: $event})"
                    >Button color</StylePreferencesThemeEditorColorOption>
                    <StylePreferencesThemeEditorColorOption
                        :value="interactiveHomePageStyles.BUTTON_TEXT_COLOR"
                        @input="updateInteractiveHomePageStyles({BUTTON_TEXT_COLOR: $event})"
                    >Button text color</StylePreferencesThemeEditorColorOption>
                </template>
                <FormParametersSubheader>Button style</FormParametersSubheader>
                <StylePreferencesThemeEditorBorderRadiusStyle
                        :value="getCurrentEditingThemeStyles[constants.FSP.PRIMARY_VARIABLES.BORDER_RADIUS.JSON_NAME]"
                        @input="updateCurrentEditingThemeStyle({[constants.FSP.PRIMARY_VARIABLES.BORDER_RADIUS.JSON_NAME]: $event})"
                />
                <template v-if="isFormInteractive">
                    <FormParametersSubheader>Background</FormParametersSubheader>
                    <StylePreferencesBackgroundImagePicker/>
                </template>
            </div>

            <div
                    :class="[
                        'style-preferences-theme-editor__actions',
                        !dataStash.waitForLoad && isCurrentEditingThemeChanged && 'style-preferences-theme-editor__actions--shown'
                    ]"
            >
                <template v-if="isCurrentEditingThemeAlreadyExist">
                    <v-btn color="gray_100" rounded @click="quitThemeEditor">
                        Cancel
                    </v-btn>
                    <f-template-data-transfer :loading="false" v-slot="{dataStash, promiseProcessor}">
                        <v-btn
                                color="blue_400"
                                rounded
                                :loading="dataStash.loading"
                                @click="savePrivateThemeChanges(promise => promiseProcessor(promise))"
                        >
                            Save
                        </v-btn>
                    </f-template-data-transfer>
                </template>
                <template v-else>
                    <v-btn color="gray_100" rounded @click="quitThemeEditor">
                        Reset
                    </v-btn>
                    <f-template-data-transfer :loading="false" v-slot="{dataStash, promiseProcessor}">
                        <v-btn
                                color="blue_400"
                                rounded
                                :loading="dataStash.loading"
                                @click="promiseProcessor(saveNewPrivateTheme())"
                        >
                            Save as new style
                        </v-btn>
                    </f-template-data-transfer>
                </template>
            </div>
            <GenericStoreDialog store-module-path="edit/FSP_themes/editing/save_theme_with_related_forms_confirmation_dialog"/>
        </div>
    </f-template-data-transfer>
</template>

<script>
    import {mapGetters, mapActions} from '@/xstore'

    export default {
        name: "StylePreferencesThemeEditor",
        components: {
            ChevronLeftIcon: () => import('vue-feather-icons/icons/ChevronLeftIcon'),

            FormParametersSubheader: () => import('../Elements/FormParametersSubheader'),
            StylePreferencesThemeEditorColorOption: () => import('./ThemeEditorElements/StylePreferencesThemeEditorColorOption'),
            StylePreferencesThemeEditorBorderRadiusStyle: () => import('./ThemeEditorElements/StylePreferencesThemeEditorBorderRadiusStyle'),
            StylePreferencesBackgroundImagePicker: () => import('./ThemeEditorElements/StylePreferencesBackgroundImagePicker'),
            GenericStoreDialog: () => import('@/components/Elements/GenericStoreDialog'),
            FontPickerComponent: () => import('@/components/FontPickerComponent'),
        },
        computed: {
            ...mapGetters('edit', [
                'isFormInteractive',
                'getFormJSON'
            ]),
            ...mapGetters('edit/FSP_themes/editing', [
                'getCurrentEditingTheme',
                'getCurrentEditingThemeStyles',

                'isCurrentEditingThemeChanged',
                'isCurrentEditingThemeAlreadyExist'
            ]),
            colorVariables() {
              if (this.isFormInteractive) {
                return Object.values(this.constants.FSP.PRIMARY_VARIABLES)
                    .filter(variable => variable.CATEGORY === this.constants.FSP.CATEGORIES.COLOR)
              } else {
                return Object.values(this.constants.FSP.PRIMARY_VARIABLES)
                    .filter(variable => {
                        const isColor = variable.CATEGORY === this.constants.FSP.CATEGORIES.COLOR
                        const allowedColors = [
                            this.constants.FSP.ALL_PRIMARY_VARIABLES_OBJECT.BUTTON_TEXT_COLOR,
                            this.constants.FSP.ALL_PRIMARY_VARIABLES_OBJECT.BUTTON_BACKGROUND_COLOR
                        ]

                        return isColor && allowedColors.some(v => variable === v)
                    })
              }

            },
            interactiveHomePageStyles() {
                if (this.isFormInteractive)
                    return this.getFormJSON.all_pages[this.getFormJSON.home_page.page_id]?.styles || {}
                return {}
            }
        },
        methods: {
            ...mapActions('edit/FSP_themes', [
                'processStylePreferencesExit'
            ]),
            ...mapActions('edit/FSP_themes/editing', [
                'quitThemeEditor',
                'updateCurrentEditingThemeStyle',
                'updateCurrentEditingThemeName',
                'saveNewPrivateTheme',
                'savePrivateThemeChanges'
            ]),
            ...mapActions('edit/pages', [
                'changeFormPage'
            ]),
            updateInteractiveHomePageStyles(styles) {
                return this.changeFormPage({
                    pageID: this.getFormJSON.home_page.page_id,
                    pageObj: {
                        ...this.getFormJSON.all_pages[this.getFormJSON.home_page.page_id],
                        styles: {
                            ...this.getFormJSON.all_pages[this.getFormJSON.home_page.page_id].styles,
                            ...styles
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .style-preferences-theme-editor {
        display: flex;
        flex-direction: column;
        min-height: 0;

        &__header {
            margin: 15px 0;

            display: flex;
            align-items: center;
            color: var(--v-bluish_gray_500-base);
        }

        &__quit-button {
            margin-right: 6px;
            cursor: pointer;
            flex-shrink: 0;
        }

        &__theme-name-wrapper {
            border-radius: 4px;
            min-width: 0;
            display: flex;

            &:hover, &--focused {
                background-color: var(--v-gray_100-base);
            }
        }

        &__theme-name {
            min-width: 0;
            padding: 5px 8px;
            font-weight: 500;
            font-size: 1rem;
        }

        &__content {
            transition: padding-bottom .2s ease;
            padding: 0 4px;
            overflow-y: auto;
        }

        &__actions {
            padding-top: 20px;
            margin-bottom: -60px;
            background-color: white;

            width: 100%;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            column-gap: 17px;

            transition: margin-bottom .2s ease;

            & > * {
                flex-grow: 1;
            }

            &--shown {
                margin-bottom: 0;
            }
        }
    }
</style>
