var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BrowserTemplate', [_c('v-row', [_c('v-col', {
    attrs: {
      "align-self": "center",
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "upload-yours-cover-area-wrapper"
  }, [_c('FileUploadArea', {
    attrs: {
      "vertical": "",
      "max-file-size": 10,
      "max-files-count": 1,
      "accept-mime": ['image/jpeg', 'image/png']
    },
    on: {
      "input": _vm.inputCover
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Drop Image or choose file...")];
      },
      proxy: true
    }, {
      key: "subheader",
      fn: function () {
        return [_vm._v("Max size 10mb")];
      },
      proxy: true
    }])
  })], 1)]), _vm._l(_vm.uploadedCoversList, function (image, index) {
    return _c('v-col', {
      key: image.url ? image.url.toLocaleString() : index,
      attrs: {
        "align-self": "center",
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var hover = _ref.hover;
          return [_c('v-img', {
            staticClass: "rounded-lg result-image",
            class: _vm.activeImage === image.url && 'active',
            attrs: {
              "width": "288px",
              "height": "160px",
              "src": image.url
            },
            on: {
              "load": function ($event) {
                image.loaded = true;
              },
              "click": function ($event) {
                return _vm.setActiveImage(image);
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('v-scale-transition', {
                  attrs: {
                    "origin": "center"
                  }
                }, [hover && image.loaded ? _c('v-btn', {
                  staticClass: "image-floating-remove-button v-btn--error",
                  attrs: {
                    "icon": "",
                    "small": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.stopPropagation();
                      return _vm.removeCover(index);
                    }
                  }
                }, [_c('TrashIcon', {
                  attrs: {
                    "size": "18"
                  }
                })], 1) : _vm._e()], 1)];
              },
              proxy: true
            }, {
              key: "placeholder",
              fn: function () {
                return [_c('v-row', {
                  staticClass: "fill-height ma-0",
                  attrs: {
                    "align": "center",
                    "justify": "center"
                  }
                }, [_c('f-circle-loader', {
                  attrs: {
                    "size": "5"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          })];
        }
      }], null, true)
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }