<script>
  import { computed, ref } from '@vue/composition-api'
  import moment from 'moment'

  import constants from '@/constants/constants'
  import store from '@/store'
  import { mapGetters } from '@/xstore'

  export default {
    name: 'SubmissionsAnalytics',
    components: {
      AllResponsesView: () =>
        import(
          '@/components/SubmissionsAnalytics/AllResponsesView/AllResponsesView'
        ),
      SummaryView: () =>
        import('@/components/SubmissionsAnalytics/SummaryView/SummaryView'),
      SubmissionsToolbarActionsComponent: () =>
        import(
          '../components/SubmissionsAnalytics/SubmissionsToolbarActionsComponent'
        ),
      SubmissionsExportComponent: () =>
        import('../components/SubmissionsAnalytics/SubmissionsExportComponent'),
      GenericStoreDialog: () =>
        import('@/components/Elements/GenericStoreDialog'),
    },
    props: {
      formId: {
        type: String,
        required: true,
      },
    },
    setup(props, { root }) {
      const formattedSubmissions = computed(() =>
        root.$store.getters['submissions/getSubmissionsList']
          .map(submissionInfo => ({
            submissionID: submissionInfo.id,
            submissionDate: submissionInfo.createdAt,
            publishedVersionID: submissionInfo.publishedVersionID,
            loading: computed(() =>
              root.$store.getters[
                'submissions/getNewFetchingSubmissionsIds'
              ].includes(submissionInfo.id)
            ),
            ...Object.fromEntries(
              Object.entries(submissionInfo.rawData).map(
                ([fieldID, fieldSubmissionObj]) => {
                  if (
                    root.$store.getters[
                      'submissions/getNewFetchingSubmissionsIds'
                    ].includes(submissionInfo.id)
                  )
                    return [fieldID, undefined]
                  const field =
                    root.$store.getters['submissions/getVersionedFormSources'][
                      submissionInfo.publishedVersionID
                    ].fields[fieldID]
                  return [
                    fieldID,
                    {
                      submissionObj: fieldSubmissionObj,
                      sourceObj: field,
                      fieldTypeConst:
                        root.constants.FIELD_FROM_TYPE[field.field_type],
                    },
                  ]
                }
              )
            ),
          }))
          .sort((a, b) => b.submissionDate.diff(a.submissionDate))
          .map((submissionInfo, submissionIndex) => ({
            ...submissionInfo,
            submissionTitle: `Response ${
              root.$store.getters['submissions/getCount'] - submissionIndex
            }`,
          }))
      )
      const selectedSubmissionHeaders = computed(() => [
        {
          title: 'Date',
          fieldId: 'submissionDate',
        },
        ...(isSelected.value
          ? // Object.entries(root.$store.getters['submissions/getVersionedFormSources'])
            selectedSubmissions.value
              .map(submissionObj => submissionObj.publishedVersionID)
              .map(
                publishedVersionID =>
                  root.$store.getters['submissions/getVersionedFormSources'][
                    publishedVersionID
                  ]
              )
              .map(formObj => {
                return Object.entries(formObj.fields)
              })
              .flat()
              .filter(
                ([fieldID, fieldObj], index, self) =>
                  self.findIndex(([id]) => id === fieldID) === index &&
                  root.constants.FIELD_FROM_TYPE[fieldObj.field_type]
                    .WITH_SUBMISSION
              )
              .map(([fieldId, fieldObj]) => ({
                title: fieldObj.title,
                fieldId,
              }))
          : selectedSubmissions.value),
      ])
      const submissionHeaders = computed(() => [
        {
          title: 'Date',
          fieldId: 'submissionDate',
        },
        ...formattedSubmissions.value
          .map(submissionObj => submissionObj.publishedVersionID)
          .map(
            publishedVersionID =>
              root.$store.getters['submissions/getVersionedFormSources'][
                publishedVersionID
              ]
          )
          .map(formObj => {
            return formObj?.fields ? Object.entries(formObj.fields) : []
          })
          .flat()
          .filter(
            ([fieldID, fieldObj], index, self) =>
              self.findIndex(([id]) => id === fieldID) === index &&
              root.constants.FIELD_FROM_TYPE[fieldObj.field_type]
                .WITH_SUBMISSION
          )
          .map(([fieldId, fieldObj]) => ({ title: fieldObj.title, fieldId })),
      ])

      const selectedSubmissionsForCsv = computed(() => {
        return [
          selectedSubmissionHeaders.value.map(headerObj => headerObj.title),
          ...(isSelected.value
            ? selectedSubmissions.value.map(submissionObj => {
                return selectedSubmissionHeaders.value.map(({ fieldId }) => {
                  if (Object.keys(submissionObj).includes(fieldId)) {
                    if (fieldId === 'submissionDate') {
                      return moment(submissionObj[fieldId]).format(
                        'MM/DD/YYYY, hh:mm A'
                      )
                    } else {
                      return (
                        submissionObj[
                          fieldId
                        ].fieldTypeConst.ANALYTICS.FORMAT_TO_CSV_EXPORT(
                          submissionObj[fieldId].submissionObj,
                          submissionObj[fieldId].sourceObj
                        ) || '-'
                      )
                    }
                  } else {
                    return '-'
                  }
                })
              })
            : []),
        ]
      })
      const submissionsForCsv = computed(() => {
        return [
          submissionHeaders.value.map(headerObj => headerObj.title),
          ...formattedSubmissions.value.map(submissionObj => {
            return submissionHeaders.value.map(({ fieldId }) => {
              if (Object.keys(submissionObj).includes(fieldId)) {
                if (fieldId === 'submissionDate') {
                  return moment(submissionObj[fieldId]).format(
                    'MM/DD/YYYY, hh:mm A'
                  )
                } else {
                  return (
                    submissionObj[
                      fieldId
                    ].fieldTypeConst.ANALYTICS.FORMAT_TO_CSV_EXPORT(
                      submissionObj[fieldId].submissionObj,
                      submissionObj[fieldId].sourceObj
                    ) || '-'
                  )
                }
              } else {
                return '-'
              }
            })
          }),
        ]
      })

      const selectedSubmissions = ref([])
      const selectedSubmissionsCount = computed(
        () => selectedSubmissions.value.length
      )
      const isSelected = computed(() => !!selectedSubmissionsCount.value)
      const selectedSubmissionTitle = computed(() =>
        !selectedSubmissionsCount.value
          ? ''
          : selectedSubmissionsCount.value === 1
          ? selectedSubmissions.value[0].submissionTitle
          : 'Selected responses'
      )
      const deleteSelectedSubmissions = () => {
        root.$store.dispatch('submissions/deleteSubmissions', {
          ids: selectedSubmissions.value.map(
            ({ submissionID }) => submissionID
          ),
          onBeforeDelete: () => (selectedSubmissions.value = []),
        })
      }

      const selectionModule = {
        selectedSubmissions,
        isSelected,
        selectedSubmissionsCount,
        deleteSelectedSubmissions,
      }

      const searchQuery = ref('')

      return {
        ...selectionModule,
        searchQuery,
        formattedSubmissions,
        submissionsForCsv,
        selectedSubmissionsForCsv,
        selectedSubmissionTitle,
      }
    },
    computed: {
      ...mapGetters('edit', ['getFormDBData', 'getFormJSON']),
      ...mapGetters('edit/loading_screen', ['getIsFormLoadingScreenShown']),
      ...mapGetters('submissions', [
        'isAnyLoading',
        'isUpdatingAnyNewSubmissions',
        'getAnalyticsView',
        'getNumberOfPages',
        'areThereLeftUnloadedSubmissions',
        'getIsSubmissionsListExtendingInProgress',
        'getCurrentAllResponsesView',
        'getAllResponsesViews',
      ]),
      ...mapGetters('submissions/metrics', [
        'getFormMetricsIsLoading',

        'getTotalResponsesMetric',
        'getAllViewsMetric',
        'getCompletionRateMetric',
        'getAverageTimeMetric',
        'getDropOffsMetric',
      ]),
      ...mapGetters('user', [
        'getCurrentCognitoUser',
      ]),

      briefInformation() {
        const averageTime = moment.duration(
          Math.round(this.getAverageTimeMetric),
          'seconds'
        )
        const formattedAverageTime =
          (averageTime.hours() ? averageTime.hours() + 'h' : '') +
          ' ' +
          (averageTime.minutes() ? averageTime.minutes() + 'm' : '') +
          ' ' +
          averageTime.seconds() +
          's'

        return [
          {
            name: 'Responses',
            icon: '/images/analytics_icons/TotalResponses.svg',
            value: this.getTotalResponsesMetric,
          },
          {
            name: 'Views',
            icon: '/images/analytics_icons/AllViews.svg',
            value: this.getAllViewsMetric,
          },
          {
            name: 'Completion rate',
            icon: '/images/analytics_icons/CompletionRate.svg',
            value: Math.round(this.getCompletionRateMetric) + '%',
          },
          {
            name: 'Average time',
            icon: '/images/analytics_icons/AverageTime.svg',
            value: formattedAverageTime,
          },
          {
            name: 'Drop-offs',
            icon: '/images/analytics_icons/DropOffs.svg',
            value: this.getDropOffsMetric,
          },
        ]
      },
    },
    beforeRouteEnter(to, from, next) {
      store.dispatch('submissions/processSubmissionsAnalyticsEntry', {
        previousRoute: from,
        formID: to.params.formId,
      })
      next()
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== constants.PAGE_NAME_FORM_EDITOR) {
        store.dispatch('edit/RESET')
        store.dispatch('submissions/RESET')
      }
      next()
    },
  }
</script>

<template>
  <div class="submissions-analytics">
    <div class="submissions-analytics__head">
      <div class="submissions-analytics__head__left-part">
        <span
          class="submissions-analytics__form-name mr-4"
          style="height: 36px"
        >
          <v-skeleton-loader
            v-if="!getFormDBData || getIsFormLoadingScreenShown"
            type="image"
            width="250"
            height="32"
          />
          <span v-else>{{ getFormDBData.name || 'Untitled' }}</span>
        </span>
        <template
          v-if="
            !getIsFormLoadingScreenShown && getFormDBData && getFormJSON?.id
          "
        >
          <f-badge
            v-if="
              !!(
                getFormDBData?.publishedVersionID?.length &&
                getFormJSON?.settings?.access?.open_to_responses
              )
            "
            green
            dot
          >
            Active
          </f-badge>
          <f-badge
            v-else
            gray
            dot
          >
            Closed
          </f-badge>
        </template>
      </div>
      <div></div>
    </div>

    <div class="submissions-analytics__brief-information">
      <div
        v-for="brief in briefInformation"
        :key="brief.icon"
        class="brief-card"
      >
        <img :src="brief.icon" />
        <div class="brief_card__info-container">
          <span class="brief-card__name">{{ brief.name }}</span>
          <span class="brief-card__value">
            <v-skeleton-loader
              v-if="isAnyLoading || getFormMetricsIsLoading"
              type="image"
              tile
              width="60"
              height="21"
            />
            <span v-else>{{ brief.value }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="submissions-analytics__body">
      <div class="submissions-analytics__toolbar">
        <v-tabs
          :value="getAnalyticsView"
          class="blue-tabs submissions-analytics__analytics-type-choice"
          hide-slider
          height="36"
          :show-arrow="false"
          @change="v => $store.dispatch('submissions/setAnalyticsView', v)"
        >
          <v-tab :ripple="false">
            <span>All responses</span>
          </v-tab>
          <v-tab :ripple="false">
            <span>Overview</span>
          </v-tab>
        </v-tabs>
        <div
          v-if="getAnalyticsView === 0"
          class="d-flex"
        >
          <SubmissionsToolbarActionsComponent
            v-model="searchQuery"
            :is-selected="isSelected"
            :selected-submissions-for-csv="selectedSubmissionsForCsv"
            :selected-submission-title="selectedSubmissionTitle"
            :submissions-for-csv="submissionsForCsv"
            @delete-selected="deleteSelectedSubmissions"
          />

          <span class="ml-0">
            <SubmissionsExportComponent
              :disabled="isAnyLoading"
              :is-selected="isSelected"
            />
          </span>
        </div>
      </div>
      <AllResponsesView
        v-if="getAnalyticsView === 0"
        v-model="selectedSubmissions"
        :is-selected="isSelected"
        :search-query="searchQuery"
        :formatted-submissions="formattedSubmissions"
      />
      <SummaryView v-else-if="getAnalyticsView === 1"
      :total-response="this.getTotalResponsesMetric"
      :formID="$route.params.formId"
      />


    </div>
    <div
    v-if="!isAnyLoading && areThereLeftUnloadedSubmissions && getCurrentAllResponsesView === getAllResponsesViews.TABLE && getAnalyticsView !== 1"
      class="submissions-analytics__pagination"
    >
      <v-btn
        outlined
        class="submissions-analytics__pagination-btn v-btn--text-hoverable"
        :loading="getIsSubmissionsListExtendingInProgress"
        @click="
          () =>
            $store.dispatch('submissions/loadMoreSubmissions', {
              formID: $route.params.formId,
            })
        "
      >
        Load more submissions
      </v-btn>
    </div>
    <GenericStoreDialog store-module-path="submissions/confirmation_dialog" />
  </div>
</template>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .submissions-analytics {
    min-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 55px 40px;

      .submissions-analytics__brief-information {
        column-gap: 16px;

        .brief-card {
          column-gap: 12px;
          padding: 15px 5px 16px 17px;

          &__name {
            font-size: 0.75rem;
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      padding: 55px 61px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 55px 120px;
    }

    &__head {
      display: flex;
      margin-bottom: 32px;

      &__left-part {
        display: flex;
        align-items: center;
      }

      .submissions-analytics__form-name {
        font-size: 1.875rem;
        font-weight: 500;
        color: var(--v-bluish_gray_500-base);
        line-height: 2.25rem;

        display: flex;
        align-items: center;
      }
    }

    &__brief-information {
      display: flex;
      column-gap: 28px;
      margin-bottom: 85px;

      .brief-card {
        width: 20%;

        display: flex;
        align-items: center;
        column-gap: 16px;

        background-color: white;
        box-shadow: 0px 3px 10px rgba(208, 210, 218, 0.25);
        padding: 26px 15px 20px;
        border-radius: 10px;

        &__icon {
          background-color: #f7f9ff;
          border-radius: 1000px;
        }

        &__info-container {
          display: flex;
          flex-direction: column;
        }

        &__name {
          font-size: 0.875rem;
          line-height: 140%;
          color: #9ea8c6;
          white-space: nowrap;
        }

        &__value {
          font-size: 1.625rem;
          min-height: 2rem;
          line-height: 2rem;
          font-weight: 500;
          letter-spacing: -2%;
          color: var(--v-secondary_dark_grey_900-base);
          white-space: nowrap;

          display: flex;
          align-items: center;
        }
      }
    }

    &__body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .submissions-analytics__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        height: 40px;

        .v-btn.v-btn--icon {
          margin: 0 2px;
        }
      }

      .submissions-analytics__content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
      }
    }
  }
</style>
