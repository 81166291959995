<template>
    <GenericStringSingleLineFieldViewInput
            v-model="submissionObj.value"
            :placeholder="sourceObj.content.placeholder"

            assign-generic-view-class
            ref="input"
            v-on="inputAutofocusOn"
    />
</template>

<script>
    import submittable_field_view_mixin from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
    import {
        use_submittable_field_input_autofocus_and_press_enter_mixin
    } from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_input_autofocus_and_press_enter_mixin'

    export default {
        name: "Submittable__ShortAnswer",
        mixins: [submittable_field_view_mixin, use_submittable_field_input_autofocus_and_press_enter_mixin('input')],
        components: {
            GenericStringSingleLineFieldViewInput: () => import('@/components/Fields/GenericFieldViews/GenericTextField/GenericStringSingleLineFieldViewInput')
        }
    }
</script>