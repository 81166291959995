<template>
    <div class="app-bar_profile">
        <!--        <BellIcon class="app-bar_profile__notifications-icon"/>-->
        <div class="support-btn">
            <v-fade-transition>
                <f-hover-tooltip left text="Support" v-slot="{ on }">
                    <v-btn v-on="on" class="navigation-btn support" active-class="navigation-btn--active" exact text
                        @click="toggleCrispChat">
                        <MessageCircleIcon stroke-width="1.5" size="16" />
                        <!-- <span class="support-nav">Support</span> -->
                    </v-btn>
                </f-hover-tooltip>
            </v-fade-transition>
        </div>
        <v-menu v-model="menuModel" min-width="240" nudge-bottom="25px" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <div class="app-bar_profile__user-info" v-on="on">
                    <span class="app-bar_profile__name">
                        {{ shortenedName }}
                    </span>
                    <v-avatar size="40px">
                        <img :src="getCurrentCognitoUser ? getCurrentCognitoUser.attributes.picture : ''" />
                    </v-avatar>
                    <ChevronDownIcon size="16" class="dropdown-menu-chevron"
                        :class="{ 'dropdown-menu-chevron--up': menuModel }" />
                </div>
            </template>
            <v-list dense>
                <template v-for="(option, key) in profileDropdownOptions">
                    <v-divider v-if="option === 'divider'" :key="key" />
                    <v-list-item v-else :key="key" @click="option.action">
                        <v-list-item-icon>
                            <component :is="option.icon" stroke-width="1.5" size="16" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ option.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>

    </div>
</template>

<script>
import { ChevronDownIcon, SettingsIcon, StarIcon, MessageCircleIcon } from 'vue-feather-icons'
import { mapGetters } from '@/xstore'

export default {
    name: "ProfileComponent",
    components: {
        ChevronDownIcon, SettingsIcon, StarIcon, MessageCircleIcon,
        BellIcon: () => import('vue-feather-icons/icons/BellIcon')
    },
    data: () => ({
        menuModel: false
    }),
    computed: {
        ...mapGetters('user', [
            'getCurrentCognitoUser'
        ]),
        shortenedName() {
            if (this.getCurrentCognitoUser) {
                let firstName = this.getCurrentCognitoUser.attributes.name.split(' ')[0]
                return firstName.length > 12
                    ? firstName.slice(0, 12) + '…'
                    : firstName
            }
            return ''
        },
        profileDropdownOptions() {
            return [
                {
                    name: 'Account settings',
                    icon: () => import('vue-feather-icons/icons/SettingsIcon'),
                    action: () => this.$router.push({ name: this.constants.PAGE_NAME_ACCOUNT_DETAILS })
                },
                {
                    name: 'Upgrade plan',
                    icon: () => import('vue-feather-icons/icons/StarIcon'),
                    action: () => this.$router.push({ name: this.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN })
                },
                // {
                //     name: 'Invite',
                //     icon: () => import('vue-feather-icons/icons/HeartIcon'),
                //     action: () => console.log('#TODO')
                // },
                // {
                //     name: 'Help',
                //     icon: () => import('vue-feather-icons/icons/LifeBuoyIcon'),
                //     action: () => console.log('#TODO')
                // },
                'divider',
                {
                    name: 'Sign out',
                    icon: () => import('vue-feather-icons/icons/LogOutIcon'),
                    action: () => this.promiseWithAnimation(this.AuthActions.signOut())
                },
            ]
        }
    },
    mounted() {
        window.$crisp.push(['on', 'chat:closed', this.handleChatClosed]);
    },
    methods: {
        toggleCrispChat() {
            const crispIcon = document.querySelector(".crisp-client");
            if (!$crisp.is('chat:opened')) {
                $crisp.push(['do', 'chat:show']);
                $crisp.push(['do', 'chat:open']);
                if (crispIcon) {
                    crispIcon.style.display = "block";
                }
            } else {
                $crisp.push(['do', 'chat:close']);
            }
        },
        handleChatClosed() {
            const crispIcon = document.querySelector(".crisp-client");
            if (crispIcon) {
                crispIcon.style.display = "none";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.app-bar_profile {
    display: flex;
    align-items: center;

    &__notifications-icon {
        margin-right: 30px;
        color: #9EA6A8;
    }

    &__user-info {
        display: flex;
        align-items: center;
        cursor: pointer;

        .app-bar_profile__name {
            font-size: .875rem;
            margin-right: 15px;
        }

        .dropdown-menu-chevron {
            margin-left: 8px;
            margin-right: 5px;
            color: var(--v-gray_400-base);
        }
    }

    .support-nav {
        padding-left: 4px;
    }

    button.navigation-btn.support:hover {
        background-color: var(--v-blue_100-base);
    }

    button.navigation-btn.support {
        padding: 0 !important;
        min-width: auto !important;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 100%;
    }
}
</style>