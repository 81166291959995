<template>
    <div>
        <div class="d-flex">
            <SummaryFieldViewHeading
                    :source-obj="latestSourceObj"
                    :answered="filteredSubmissions.length"
                    :submissions-count="totalResponse"
            />
            <v-spacer/>
            <SummaryChangeSortOrder v-model="sortOrder"/>
        </div>

        <div class="summary-analytics-view__field-view-wrapper">
            <div class="image-choice-field-view-summary">
                <SummaryRowWithProgress
                        v-for="optionKey in sortedOptionsWithPercentage.shown"
                        :label="optionsWithPercentage[optionKey].label"
                        :key="optionKey"
                        :count="optionsWithPercentage[optionKey].count"
                        :percentage="optionsWithPercentage[optionKey].percentage"
                >
                    <template #prepend>
                        <v-img
                                class="image-choice-field-view-summary__image"
                                :src="optionsWithPercentage[optionKey].image"
                                aspect-ratio="1"
                        />
                    </template>
                </SummaryRowWithProgress>
                <div v-if="sortedOptionsWithPercentage.hidden.length">
                    <SummaryHiddenAnswers :hidden-count="sortedOptionsWithPercentage.hidden.length">
                        <div
                                v-for="optionKey in sortedOptionsWithPercentage.hidden"
                                class="d-flex align-center my-2"
                                :key="optionKey"
                        >
                            <span>{{ optionsWithPercentage[optionKey].count }} responses</span>
                            <span class="mx-1">·</span>
                            <span v-if="optionsWithPercentage[optionKey].label"
                                  class="mr-2">{{ optionsWithPercentage[optionKey].label }}</span>
                            <v-img
                                    class="flex-grow-0 rounded-sm"
                                    width="50"
                                    :src="optionsWithPercentage[optionKey].image"
                                    aspect-ratio="1"
                            />
                        </div>
                    </SummaryHiddenAnswers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import summary_answer_field_view_mixin
        from '@/components/Fields/AnalyticsField/Summary/Utils/summary_answer_field_view_mixin'

    export default {
        name: "Summary__ImageChoice",
        components: {
            SummaryRowWithProgress: () => import('@/components/Fields/AnalyticsField/Summary/Utils/SummaryRowWithProgress')
        },
        props:{
            totalResponse:
            {
                type: Number,
                default: 0
            },
        },
        mixins: [summary_answer_field_view_mixin],
        data: () => ({
            sortOrder: summary_answer_field_view_mixin.data().SORT_ORDER.DEFAULT
        }),
        computed: {
            filteredSubmissions() {
                return this.submissions
                    .filter(({submissionObj: {selected_options}}) => selected_options.length)
            },
            sortedOptionsWithPercentage() {
                const latestVersionOptionsKeys = this.latestSourceObj.content.options
                    .map(option => this.toKey(option))
                    .filter((option, index, self) => self.findIndex(fOption => option === fOption) === index)

                const result = {
                    shown: Array(latestVersionOptionsKeys.length),
                    hidden: []
                }

                Object.keys(this.optionsWithPercentage)
                    .forEach(key => {
                        if (latestVersionOptionsKeys.includes(key))
                            result.shown[latestVersionOptionsKeys.indexOf(key)] = key
                        else
                            result.hidden.push(key)
                    })

                if (this.sortOrder === this.SORT_ORDER.DESCENDING)
                    result.shown = result.shown.sort((a, b) => this.optionsWithPercentage[b].count - this.optionsWithPercentage[a].count)

                return result
            },
            optionsWithPercentage() {
                const result = {}

                this.submissions.forEach(
                    ({submissionObj, versionID}) => {
                        this.versionedSources[versionID].content.options
                            .forEach((option, index) => {
                                if (!(this.toKey(option) in result))
                                    result[this.toKey(option)] = {
                                        count: 0,
                                        percentage: 0,
                                        image: option.image,
                                        label: option.label,
                                        initialIndex: index
                                    }
                            })

                        submissionObj.selected_options
                            .forEach(optionIndex => {
                                result[this.toKey(this.versionedSources[versionID].content.options[optionIndex])].count++
                            })
                    }
                )

                Object.values(result)
                    .forEach((optionData, _, self) => {
                        optionData.percentage = Math.round(optionData.count / self.reduce((accum, {count}) => accum + count, 0) * 100) || 0
                    })

                return result
            }
        },
        methods: {
            toKey(option) {
                return (option.image || 'EmptyImage') + option.label
            },
        }
    }
</script>