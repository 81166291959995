<template>
  <div class="text-field-view">
    <slot name="editor"></slot>
    <div v-if="!editable" class="ql-editor" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: 'SharedView__Text',
  components: {
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: [String, HTMLElement],
    }
  },
}
</script>
