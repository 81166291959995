import deepEqual from 'deep-equal'

export class Analytics {
    #SORT
    #FILTER
    _FORMAT_TO_CSV_EXPORT

    constructor(options) {
        this.VIEWS = {}

        if (options) {
            const {
                SORT = false,
                FILTER = false,
                SHORT_SINGLE_ANSWER_VIEW,
                FULL_SINGLE_ANSWER_VIEW,
                SUMMARY_ANSWER_VIEW,
                FORMAT_TO_CSV_EXPORT,
            } = options
            this.#SORT = SORT
            this.#FILTER = FILTER
            this._FORMAT_TO_CSV_EXPORT = FORMAT_TO_CSV_EXPORT
            this.VIEWS = {
                SHORT_SINGLE_ANSWER_VIEW,
                FULL_SINGLE_ANSWER_VIEW,
                SUMMARY_ANSWER_VIEW
            }
        }

    }

    #throwError(message) {
        throw new Error(`Analytics error: ${message}`)
    }

    get SORTABLE() {
        return !!this.#SORT
    }
    get FILTERABLE() {
        return !!this.#FILTER
    }
    SORT(a, b, sourceObj) {
        if (!this.SORTABLE)
            this.#throwError('You\'re trying to sort not sortable')


        const generatedSubmissionObj = this.SUBMISSION_GENERATOR(sourceObj)

        return a && b && !deepEqual(a, generatedSubmissionObj) && !deepEqual(b, generatedSubmissionObj)
            ? this.#SORT(a, b)
            : a && !deepEqual(a, generatedSubmissionObj)
                ? 1
                : b && !deepEqual(b, generatedSubmissionObj)
                    ? -1
                    : 0
    }
    FILTER(submissionObj, search) {
        return this.#FILTER(submissionObj, search)
    }

    FORMAT_TO_CSV_EXPORT(submissionObj, fieldObj) {
        return this._FORMAT_TO_CSV_EXPORT(submissionObj, fieldObj);
    }

    initializeAsPartOfFieldType(fieldTypeConst) {
        if (!this._FORMAT_TO_CSV_EXPORT) {
            this.#throwError(`Format method was not passed in ${fieldTypeConst.FOLDER_NAME}`);
        }
        this.FOLDER_NAME = fieldTypeConst.FOLDER_NAME
        this.SUBMISSION_GENERATOR = sourceObj => fieldTypeConst.generateSubmissionObj(sourceObj)
        if (!this.VIEWS.SHORT_SINGLE_ANSWER_VIEW)
            this.VIEWS.SHORT_SINGLE_ANSWER_VIEW = () => import(`@/components/Fields/FieldsViews/${this.FOLDER_NAME}/Analytics/ShortSingleAnswer`)

        if (!this.VIEWS.FULL_SINGLE_ANSWER_VIEW)
            this.VIEWS.FULL_SINGLE_ANSWER_VIEW = () => import(`@/components/Fields/FieldsViews/${this.FOLDER_NAME}/Analytics/FullSingleAnswer`)

        if (!this.VIEWS.SUMMARY_ANSWER_VIEW)
            this.VIEWS.SUMMARY_ANSWER_VIEW = () => import(`@/components/Fields/FieldsViews/${this.FOLDER_NAME}/Analytics/Summary`)
    }
}


export class GenericTextAnalytics extends Analytics {
    static DEFAULT_SINGLE_ANSWER_VIEW = () => import('@/components/Fields/AnalyticsField/GenericTextSingleAnswerFieldView')

    static DEFAULT_FILTER({value}, search) {
        return value !== null && value.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    }

    static DEFAULT_SORT({value: a}, {value: b}) {
        return a.length && b.length
            ? b.toLocaleString().localeCompare(a.toLocaleString())
            : a.length
                ? 1
                : b.length
                    ? -1
                    : 0
    }

    static DEFAULT_FORMAT_TO_CSV_EXPORT = submissionObj => submissionObj.value;

    constructor(options) {
        super({
            FORMAT_TO_CSV_EXPORT: GenericTextAnalytics.DEFAULT_FORMAT_TO_CSV_EXPORT,
            FILTER: GenericTextAnalytics.DEFAULT_FILTER,
            SORT: GenericTextAnalytics.DEFAULT_SORT,
            SHORT_SINGLE_ANSWER_VIEW: GenericTextAnalytics.DEFAULT_SINGLE_ANSWER_VIEW,
            FULL_SINGLE_ANSWER_VIEW: GenericTextAnalytics.DEFAULT_SINGLE_ANSWER_VIEW,
            SUMMARY_ANSWER_VIEW: () => import('@/components/Fields/AnalyticsField/Summary/GenericTextSummaryFieldView'),
            ...options
        })
    }
}