var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.531 8.25H6C4.80653 8.25 3.66193 8.72411 2.81802 9.56802C1.97411 10.4119 1.5 11.5565 1.5 12.75C1.5 13.9435 1.97411 15.0881 2.81802 15.932C3.66193 16.7759 4.80653 17.25 6 17.25H10.5C11.2201 17.2504 11.9298 17.0779 12.5694 16.7471C13.2091 16.4164 13.76 15.9369 14.1759 15.349C14.5918 14.7612 14.8606 14.0821 14.9596 13.3688C15.0586 12.6555 14.985 11.9289 14.745 11.25H13.5C13.371 11.25 13.245 11.265 13.125 11.2965C13.378 11.7533 13.5074 12.2681 13.5004 12.7902C13.4934 13.3123 13.3502 13.8235 13.0851 14.2733C12.82 14.7232 12.442 15.096 11.9886 15.355C11.5353 15.6139 11.0221 15.7501 10.5 15.75H6C5.20435 15.75 4.44129 15.4339 3.87868 14.8713C3.31607 14.3087 3 13.5456 3 12.75C3 11.9544 3.31607 11.1913 3.87868 10.6287C4.44129 10.0661 5.20435 9.75 6 9.75H8.3025C8.6295 9.186 9.045 8.679 9.5325 8.25H9.531Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.146 9.74891H10.5C11.046 9.74891 11.559 9.89441 12 10.1509C12.4558 9.88691 12.9733 9.74822 13.5 9.74891H13.854C13.3503 9.18491 12.7127 8.75676 12 8.50391C11.2872 8.75653 10.6496 9.18471 10.146 9.74891Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.4999 8.25C12.7798 8.24962 12.0701 8.42207 11.4305 8.75285C10.7908 9.08364 10.2399 9.5631 9.82401 10.151C9.40809 10.7388 9.13934 11.4179 9.04031 12.1312C8.94128 12.8445 9.01488 13.5711 9.25492 14.25H10.9019C10.6386 13.7939 10.5 13.2766 10.5 12.75C10.5 12.2234 10.6386 11.7061 10.9019 11.25C11.1652 10.794 11.5439 10.4153 11.9999 10.152C12.456 9.88864 12.9733 9.75001 13.4999 9.75H17.9999C18.7956 9.75 19.5586 10.0661 20.1212 10.6287C20.6838 11.1913 20.9999 11.9544 20.9999 12.75C20.9999 13.5457 20.6838 14.3087 20.1212 14.8713C19.5586 15.4339 18.7956 15.75 17.9999 15.75H15.6974C15.3711 16.3131 14.9557 16.8197 14.4674 17.25H17.9999C18.5909 17.25 19.176 17.1336 19.722 16.9075C20.268 16.6813 20.764 16.3498 21.1819 15.932C21.5998 15.5141 21.9312 15.018 22.1574 14.4721C22.3835 13.9261 22.4999 13.3409 22.4999 12.75C22.4999 12.1591 22.3835 11.5739 22.1574 11.0279C21.9312 10.482 21.5998 9.98588 21.1819 9.56802C20.764 9.15016 20.268 8.81869 19.722 8.59254C19.176 8.3664 18.5909 8.25 17.9999 8.25H13.4999Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 16.9947C12.7125 16.7415 13.35 16.3134 13.854 15.7497H13.5C12.9733 15.7503 12.4558 15.6116 12 15.3477C11.5442 15.6116 11.0267 15.7503 10.5 15.7497H10.146C10.644 16.3062 11.28 16.7397 12 16.9947Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }