<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 8.25C7 7.83579 7.33579 7.5 7.75 7.5H22.25C22.6642 7.5 23 7.83579 23 8.25C23 8.66421 22.6642 9 22.25 9H7.75C7.33579 9 7 8.66421 7 8.25Z"
              fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9 14.25C9 13.8358 9.33579 13.5 9.75 13.5H20.25C20.6642 13.5 21 13.8358 21 14.25C21 14.6642 20.6642 15 20.25 15H9.75C9.33579 15 9 14.6642 9 14.25Z"
              fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 20.25C12 19.8358 12.3358 19.5 12.75 19.5H17.25C17.6642 19.5 18 19.8358 18 20.25C18 20.6642 17.6642 21 17.25 21H12.75C12.3358 21 12 20.6642 12 20.25Z"
              fill="currentColor"/>
    </svg>

</template>

<script>
    export default {}
</script>

<style scoped>

</style>