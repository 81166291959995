<script>
  import { computed, onMounted, onUpdated, ref } from '@vue/composition-api'
  import { PlusIcon } from 'vue-feather-icons'

  import { mapGetters } from '@/xstore'
  import LoaderOverlay from '../components/Dashboard/Elements/LoaderOverlay.vue'


  const formatScaleToStylelike = scale => ({ transform: `scale(${scale})` })

  export default {
    name: 'DashboardView',
    components: {
      PlusIcon,
      FormPreviewCard: () => import('@/components/Dashboard/FormPreviewCard'),
      SearchBarComponent: () =>
        import('@/components/Elements/SearchBarComponent'),
      NoFormsView: () => import('@/components/Dashboard/NoForms'),
      LoaderOverlay: () => import('@/components/Dashboard/Elements/LoaderOverlay'),
    },
    setup(props, { root }) {
      const formCardWidth = ref(1920)

      const resizeObserver = root.Utils.useResizeObserver(
        ([
          {
            contentRect: { width },
          },
        ]) => (formCardWidth.value = width)
      )

      const staticFormScale = computed(() =>
        formatScaleToStylelike(formCardWidth.value / 1300)
      )
      const interactiveFormScale = computed(() =>
        formatScaleToStylelike(formCardWidth.value / 1920)
      )

      const formCardWrappers = ref([])

      function refreshFormCardResizeObserver() {
        resizeObserver.unobserveAll()

        if (!formCardWrappers.value.length) {
          return
        }

        const firstCardWrapper = formCardWrappers.value[0]
        const firstCard = firstCardWrapper.firstElementChild
        if (!firstCard) {
          return
        }

        resizeObserver.observe(firstCard)
      }

      onMounted(() => {
        refreshFormCardResizeObserver()
      })

      onUpdated(() => {
        refreshFormCardResizeObserver()
      })

      return {
        formCardWrappers,

        staticFormScale,
        interactiveFormScale,
      }
    },
    data: () => ({
      formsSearchQuery: '',
      isLoading: false,
    }),
    computed: {
      ...mapGetters('user', ['getCurrentCognitoUser']),
      ...mapGetters('forms_list', ['getFormsList', 'getFormsListIsLoading']),
      formCreationPage() {
        return { name: this.constants.PAGE_NAME_FORM_TYPE_SELECTION }
      },
      filteredFormsList() {
        return this.getFormsList.filter(formData =>
          formData.name.toLocaleLowerCase().includes(this.formsSearchQuery)
        )
      },
      isDashboardLoading() {
        return !this.getFormsList.length && this.getFormsListIsLoading
      },
      isFormListEmpty() {
        if (!this.getFormsList.length) {
          return {
            'justify-content': 'center',
          }
        } else {
          return ''
        }
      },
    },
    beforeCreate() {
      this.$store.dispatch('forms_list/fetchFormsList')
    },
    methods: {
      routeToFormCreationPage() {
        this.$store.dispatch(
          'user/plan/current_subscription/monitoring_features/validatePaidFeature',
          {
            featureName: 'forms_count',
            actualValue: this.filteredFormsList.length,
            initialMethod: () => this.$router.push(this.formCreationPage),
            validator: (actualValue, featureValue) =>
              actualValue < featureValue,
          }
        )
      },
      handleLoaderUpdate(loaderValue) {
       this.isLoading = loaderValue
      }
    },
  }
</script>

<template>
  <v-container
    class="dashboard"
    fluid
  >
  <LoaderOverlay
         :visible="isLoading"
        />
    <v-sheet class="faded" />
    <v-row class="dashboard-head">
      <v-col>
        <p class="dashboard-head__title">Your forms</p>
        <p class="dashboard-head__subtitle">
          {{
            getFormsListIsLoading
              ? !getFormsList.length
                ? 'No forms to display'
                : getFormsList.length + ' Forms'
              : ''
          }}
        </p>
      </v-col>
      <v-col class="d-flex justify-end align-start">
        <SearchBarComponent
          v-model="formsSearchQuery"
          class="mr-6"
          lowercase
        />
        <v-btn
          rounded
          color="blue_400"
          depressed
          dark
          @click="routeToFormCreationPage"
        >
          <PlusIcon class="v-btn__prepend-icon" />
          New Form
        </v-btn>
      </v-col>
    </v-row>
    <v-slide-y-transition
      tag="div"
      class="dashboard-body"
      mode="out-in"
      group
      hide-on-leave
      :style="isFormListEmpty"
    >
      <div
        v-if="isDashboardLoading"
        key="loader"
        class="dashboard-body__loader"
      >
        <f-circle-loader size="5" />
      </div>
      <no-forms-view
        v-else-if="!getFormsList.length"
        key="no-forms"
      />
      <div
        v-else
        key="form-preview-grid"
        class="dashboard-body__form-previews-grid"
      >
        <span
          v-for="formData in filteredFormsList"
          :key="formData.id"
          ref="formCardWrappers"
          style="display: contains"
        >
          <FormPreviewCard
            :form-data="formData"
            :static-form-scale="staticFormScale"
            :interactive-form-scale="interactiveFormScale"
            @update-loader-from-child1="handleLoaderUpdate"
          />
        </span>
      </div>
    </v-slide-y-transition>
  </v-container>
</template>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/styles.sass';

  .dashboard {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      padding: 0 32px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 0 80px;
    }

    &-head {
      flex-grow: 0;
      margin-top: 0;
      align-items: center;

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        min-height: 154px;
      }

      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        min-height: 160px;
      }

      &__title {
        font-size: 2.25rem;
        font-weight: 500;
        color: var(--v-bluish_gray_500-base);
        letter-spacing: -1px;
        line-height: 2.4rem;
        margin-bottom: 8px;
      }

      &__subtitle {
        color: var(--v-grey_500-base);
        margin-bottom: 0;
        min-height: 24px;
      }
    }

    &-body {
      max-width: 100%;
      margin-top: 12px;
      margin-bottom: 50px;

      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &__loader {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      &__form-previews-grid {
        width: 100%;
        flex-grow: 0;
        display: grid;

        @media #{map-get($display-breakpoints, 'xs-only')} {
          grid-row-gap: 20px;
        }

        @media #{map-get($display-breakpoints, 'sm-only')} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: 20px;
          grid-row-gap: 30px;
        }

        @media #{map-get($display-breakpoints, 'md-only')} {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-column-gap: 24px;
          grid-row-gap: 30px;
        }

        @media #{map-get($display-breakpoints, 'lg-only')} {
          grid-template-columns: repeat(4, minmax(0, 1fr));
          grid-column-gap: 63px;
          grid-row-gap: 76px;
        }

        @media #{map-get($display-breakpoints, 'xl-only')} {
          grid-template-columns: repeat(5, minmax(0, 1fr));
          grid-column-gap: 86px;
          grid-row-gap: 76px;
        }
      }
    }    
  }
</style>

