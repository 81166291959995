var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('SummaryFieldViewHeading', {
    attrs: {
      "source-obj": _vm.latestSourceObj,
      "answered": _vm.filteredSubmissions.length,
      "submissions-count": _vm.totalResponse
    }
  })], 1), _c('div', {
    staticClass: "summary-analytics-view__field-view-wrapper"
  }, [_c('div', {
    staticClass: "address-field-view-summary"
  }, _vm._l(_vm.filteredSubmissions, function (_ref, index) {
    var value = _ref.submissionObj.value;
    return _c('div', {
      key: index,
      staticClass: "address-field-view-summary__row-wrapper"
    }, [value.country ? _c('f-country-flag', {
      attrs: {
        "code": value.country,
        "size": "l"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(Object.entries(value).filter(function (item) {
      return item[0] !== 'country' && item[1];
    }).map(function (item) {
      return item[1];
    }).join(', ')))])], 1);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }