var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-fluid-container', {
    staticClass: "interactive-form interactive-form--editable"
  }, [_c('div', [_c('InteractiveFormComposition'), _c('InteractivePagesDisplayManager'), _c('ImagePicker', {
    ref: "imagePicker"
  }), _c('FormParameters'), _c('ConditionalLogicHandler')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }