<script>
import router from '@/router'
import constants from '@/constants/constants'

export default {
  name: 'FormslyBadge',
  functional: true,
    props: {
        disableClick: {
            type: Boolean,
            default: false
        },

    },
  render: (h, {props}) => h(
      'div',
      {
        class: {
          'formsly-badge': true,
        },
        style: {
           cursor: props.disableClick ? 'initial' : 'pointer'
        },
        on:  props.disableClick ? undefined : {
            click: () => router.push({name: constants.PAGE_NAME_DASHBOARD})
        }
      },
      [
        h(
            'span',
            'Crafted with'
        ),
        h(
            'img',
            {
              attrs: {
                src: '/images/Logo.svg',
                alt: 'Logo',
                width: '77',
                height: '20'
              }
            }
        )
      ]
  ),
}
</script>

<style scoped lang="scss">
.formsly-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  align-self: flex-end;

  span {
    font-size: .875rem;
    line-height: 1.2;
    color: var(--v-dark_gray--text);
    margin-right: 7px;
  }

  img {
    height: 20px;
  }
}
</style>