var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "42",
      "height": "42",
      "rx": "10",
      "fill": "url(#paint0_radial_895_38194)"
    }
  }), _c('path', {
    attrs: {
      "d": "M25.244 10.5H16.756C13.3064 10.5 10.5 13.3064 10.5 16.756V25.244C10.5 28.6936 13.3064 31.5 16.756 31.5H25.244C28.6936 31.5 31.5 28.6936 31.5 25.244V16.756C31.5 13.3064 28.6935 10.5 25.244 10.5ZM29.3874 25.244C29.3874 27.5323 27.5323 29.3874 25.244 29.3874H16.756C14.4677 29.3874 12.6126 27.5323 12.6126 25.244V16.756C12.6126 14.4676 14.4677 12.6126 16.756 12.6126H25.244C27.5323 12.6126 29.3874 14.4676 29.3874 16.756V25.244Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M21.0007 15.5684C18.0058 15.5684 15.5693 18.0049 15.5693 20.9997C15.5693 23.9945 18.0058 26.431 21.0007 26.431C23.9955 26.431 26.432 23.9945 26.432 20.9997C26.432 18.0048 23.9955 15.5684 21.0007 15.5684ZM21.0007 24.3185C19.1678 24.3185 17.6819 22.8326 17.6819 20.9997C17.6819 19.1668 19.1678 17.681 21.0007 17.681C22.8336 17.681 24.3194 19.1668 24.3194 20.9997C24.3194 22.8326 22.8336 24.3185 21.0007 24.3185Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.4431 16.9105C27.1618 16.9105 27.7445 16.3279 27.7445 15.6091C27.7445 14.8903 27.1618 14.3076 26.4431 14.3076C25.7243 14.3076 25.1416 14.8903 25.1416 15.6091C25.1416 16.3279 25.7243 16.9105 26.4431 16.9105Z",
      "fill": "white"
    }
  }), _c('defs', [_c('radialGradient', {
    attrs: {
      "id": "paint0_radial_895_38194",
      "cx": "0",
      "cy": "0",
      "r": "1",
      "gradientUnits": "userSpaceOnUse",
      "gradientTransform": "translate(8.75 42) rotate(-51.953) scale(51.1108)"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFD854"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.337112",
      "stop-color": "#F34D55"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.712134",
      "stop-color": "#AF39A4"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#4168C9"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }