var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('p', {
    staticClass: "settings-view__title"
  }, [_vm._v("Plan and payments")]), _c('p', {
    staticClass: "settings-view__subtitle mb-8"
  }, [_vm._v("Easily manage your plan and payments")]), _c('v-row', {
    staticClass: "mb-15"
  }, [_c('v-col', [_c('CurrentPlanCard')], 1), _c('v-col', [_c('PaymentMethodCard')], 1)], 1), _c('p', {
    staticClass: "settings-view__header mb-4"
  }, [_vm._v("Your billing details")]), _c('BillingDetails')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }