<template>
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-on="$listeners">
        <circle cx="7.43896" cy="1.5" r="1.5" transform="rotate(90 7.43896 1.5)" fill="currentColor"/>
        <circle cx="2.43896" cy="1.5" r="1.5" transform="rotate(90 2.43896 1.5)" fill="currentColor"/>
        <circle cx="7.43896" cy="7.5" r="1.5" transform="rotate(90 7.43896 7.5)" fill="currentColor"/>
        <circle cx="2.43896" cy="7.5" r="1.5" transform="rotate(90 2.43896 7.5)" fill="currentColor"/>
        <circle cx="7.43896" cy="13.5" r="1.5" transform="rotate(90 7.43896 13.5)" fill="currentColor"/>
        <circle cx="2.43896" cy="13.5" r="1.5" transform="rotate(90 2.43896 13.5)" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        inheritAttrs: true
    }
</script>