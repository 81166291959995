<template>
    <f-fluid-container :class="[
        'interactive-page-scroller',
        getFormSourceJSON?.all_pages[currentPageId]?.type === constants.FORM_PAGES_TYPES.HOME_PAGE && 'interactive-page-scroller--homepage'
        ]"
                       @wheel="wheel" @touchmove="onTouchMove">
        <f-fluid-container
                v-if="previousPageId"
                :style="previousPageStyle"
        >
            <slot :page-id="previousPageId"/>
        </f-fluid-container>
        <f-fluid-container
                :style="currentPageStyle"
                ref="currentPage"
        >
            <slot :page-id="currentPageId"/>
        </f-fluid-container>
        <f-fluid-container
                v-if="nextPageId"
                :style="nextPageStyle"
        >
            <slot :page-id="nextPageId"/>
        </f-fluid-container>
    </f-fluid-container>
</template>

<script>
    import { animate, timingFunctions } from '@/utils/animations'
    import { debounce } from 'lodash'
    import {ref, watch, inject, provide, computed, onMounted, onBeforeUnmount} from '@vue/composition-api'
    import {useMapGetters} from "@/xstore";

    const SCROLL_ANIMATION = {
        DURATION: 500,
        TIMING: timingFunctions.easeInOutCubic,

        PREVIOUS: progress => ({
            transform: `translateY(-${100 - progress * 100}%)`,
        }),
        NEXT: progress => ({
            transform: `translateY(-${progress * 100}%)`,
        })
    }

    export default {
        name: "InteractivePageScroller",
        props: {
            previousPageId: String,
            currentPageId: String,
            nextPageId: String,
            scrollingActive: Boolean
        },
        setup(props, {emit, root}) {
            provide('scroller:scrollingActive', computed(() => props.scrollingActive))


            /** SCROLLING ANIMATION **/
            const previousPageStyle = ref({})
            const currentPageStyle = ref({})
            const nextPageStyle = ref({})

            const currentAnimation = SCROLL_ANIMATION

            const transitionEnd = () => {
                previousPageStyle.value = currentPageStyle.value = nextPageStyle.value = {
                    transition: 'none'
                }
                lastTouchY = 0;
                emit('scrolled', props.previousPageId || props.nextPageId)
            }

            watch(() => props.previousPageId, async v => {
                if (v) {
                    emit('scroll-started')

                    await animate({
                        fn: progress => {
                            previousPageStyle.value = currentPageStyle.value = currentAnimation.PREVIOUS(progress)
                        },
                        timing: currentAnimation.TIMING,
                        duration: currentAnimation.DURATION
                    })


                    transitionEnd()
                }
            })

            watch(() => props.nextPageId, async v =>  {
                if (v) {
                    emit('scroll-started')

                    await animate({
                        fn: progress => {
                            currentPageStyle.value = nextPageStyle.value = currentAnimation.NEXT(progress)
                        },
                        timing: currentAnimation.TIMING,
                        duration: currentAnimation.DURATION
                    })


                    transitionEnd()
                }
            })
            const scrollingAnimationModule = {
                previousPageStyle,
                currentPageStyle,
                nextPageStyle
            }


            /** WHEEL SCROLLING **/
            let debouncedWheelEventsQueue = []

            const formSubmitStoreModulePath = inject('formSubmitStoreModulePath')

            const debouncedScrollEmitter = debounce(function (scrollDown) {
                if (!props.scrollingActive) {
                    if (scrollDown && root.$store.getters[formSubmitStoreModulePath + '/getFormPages'].length - 1 !== root.$store.getters[formSubmitStoreModulePath + '/getCurrentMainPageIndex'])
                        root.$store.dispatch(formSubmitStoreModulePath + '/goToNextPage')
                    else if (!scrollDown && root.$store.getters[formSubmitStoreModulePath + '/getCurrentMainPageIndex'] !== 0)
                        root.$store.dispatch(formSubmitStoreModulePath + '/goToPreviousPage')
                }
            }, 800, {leading: true, maxWait: 1000, trailing: false})

            const debouncedWheelHandler = debounce(function() {
                if (debouncedWheelEventsQueue.length) {
                    let amount = debouncedWheelEventsQueue.reduce((acc, deltaY) => {
                        if ((acc >= 0 && deltaY >= 0) || (acc <= 0 && deltaY <= 0))
                            return acc + deltaY
                        return 0
                    }, 0)
                    if (Math.abs(amount) > 200)
                        debouncedScrollEmitter(amount > 0)
                }
                debouncedWheelEventsQueue = []
            }, 200, {leading: true, maxWait: 400, trailing: true})

            let scrollTimeout = 0;
            let lastTouchY = 0;

            const checkIsScrollAllowed = (path) => {

              for (let el of path) {
                if (el.classList.contains('interactive-page-scroller'))
                  break

                if ('prevent-interactive-page-scrolling' in el.dataset || el.classList.contains('prevent-interactive-page-scrolling'))
                  return false;
              }
              return true
            }

            const wheel = event => {

                const path = (event.path || (event.composedPath && event.composedPath())).slice()

                const isScrollAllowed = checkIsScrollAllowed(path);

                if (scrollTimeout <= performance.now() && isScrollAllowed) {
                  debouncedWheelHandler()
                  debouncedWheelEventsQueue.push(event.deltaY)
                }
            }

            const onTouchMove = event => {
              const path = (event.path || (event.composedPath && event.composedPath())).slice()

              const isScrollAllowed = checkIsScrollAllowed(path);
              if (isScrollAllowed && scrollTimeout <= performance.now()) {
                debouncedWheelHandler()
                debouncedWheelEventsQueue.push(lastTouchY - event.changedTouches[0].clientY)
              }

              lastTouchY = event.changedTouches[0].clientY
            }

            const wheelScrollingModule = {
                wheel,
                onTouchMove,
            }

            const onScroll = (e) => {
              const clientHeight = e.target.clientHeight
              const scrollTop = e.target.scrollTop;
              const scrollHeight = e.target.scrollHeight;
              if (scrollTop > 0 && scrollTop + clientHeight < scrollHeight) {
                debouncedWheelEventsQueue = []
                scrollTimeout = performance.now() + 1000;
              }
            }

            onMounted(() => document.addEventListener('scroll', onScroll, {capture: true}))

            onBeforeUnmount(() => document.removeEventListener('scroll', onScroll, {capture: true}))


            return {
                ...scrollingAnimationModule,
                ...wheelScrollingModule,
                ...useMapGetters(inject('formSubmitStoreModulePath'), [
                    'getFormSourceJSON',
                ]),
            }
        },
    }
</script>

<style lang="scss" scoped>
    .interactive-page-scroller {
        overflow: hidden;
    }
</style>