<template>
    <div class="date-time-field">
        <FormattedDateTimeField
                :layout="formattedDateTimeFieldLayout"
                :value="formattedDateTimeFieldValue"
                @input="handleDateTimeInputFromField"
        >
            <template #prepend-inner>
                <f-date-time-picker
                        as-menu
                        :menu-props="{contentClass: 'date-time-field__picker-menu prevent-interactive-page-scrolling', attach: true, bottom: false, top: true, nudgeBottom: 0, nudgeTop: 50}"

                        v-bind="dateTimePickerBind"
                        return-object
                        return-partially
                        return-timestamp
                        @input="handleDateTimeInputFromPickerOrSubmissionValue"
                >
                    <template #activator="{on}">
                        <CalendarIcon v-on="on"/>
                    </template>
                </f-date-time-picker>
            </template>
        </FormattedDateTimeField>
    </div>
</template>

<script>
    import { submittable_field_view_mixin, useSubmittableFieldViewExtension }
        from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
    import moment from 'moment'
    import { computed, ref } from '@vue/composition-api'
    import _ from 'lodash'
    import { CalendarIcon } from 'vue-feather-icons'

    export default {
        name: "Submittable__Datetime",
        components: {
            CalendarIcon,
            FormattedDateTimeField: () => import('./FormattedDateTimeInput')
        },
        mixins: [submittable_field_view_mixin],
        setup(props) {
            const extension = useSubmittableFieldViewExtension(props)


            const formattedDateTimeFieldLayout = extension.fieldConst.UTIL.GEN_INPUT_LAYOUT(props.sourceObj)
            const formattedDateTimeFieldValue = ref(extension.fieldConst.UTIL.GEN_ALL_INPUTS_WITH_ORDER(props.sourceObj).map(() => ''))
            const handleDateTimeInputFromField = v => {
                formattedDateTimeFieldValue.value = v
                const allInputs = extension.fieldConst.UTIL.GEN_ALL_INPUTS_WITH_ORDER(props.sourceObj)

                const resultingDuration = v.reduce((accum, inputValue, index) => {
                    return accum + allInputs[index].SET_MOMENT_DURATION(parseInt(inputValue) || 0).asSeconds()
                }, 0)

                if (v.every(t => t.length) && props.submissionObj.value !== resultingDuration)
                    props.submissionObj.value = resultingDuration
            }


            const dateTimePickerBind = computed(() => extension.fieldConst.UTIL.GEN_PARTS_BIND_FOR_DATETIME_PICKER(props.sourceObj))


            /**
             * @param v timestamp or Object ({date, time}|{date}|{time})
             */
            const handleDateTimeInputFromPickerOrSubmissionValue = v => {
                const resultingFieldValue = []

                const pushDate = momentDate => resultingFieldValue.push(...extension.fieldConst.UTIL.DATE_FORMATS[props.sourceObj.properties.date_format].PARTS_ORDER.map(datePart => datePart.EXTRACT_FROM_MOMENT(momentDate)))
                const pushTime = momentDuration => resultingFieldValue.push(...extension.fieldConst.UTIL.DEFAULT_TIME_ORDER.map(timePart => timePart.EXTRACT_FROM_MOMENT(momentDuration)))

                const checkIfValueIsPlainObjAndGetAttr = attr => {
                    if (_.isPlainObject(v)) {
                        return v[attr]
                    }
                    return v
                }

                extension.fieldConst.UTIL.DISPLAYS[props.sourceObj.properties.display].PARTS_ORDER.forEach(displayPart => {
                    if (displayPart === extension.fieldConst.UTIL.DISPLAY_PARTS.DATE) {
                        pushDate(moment.unix(checkIfValueIsPlainObjAndGetAttr('date')) || 0)
                    }
                    else if (displayPart === extension.fieldConst.UTIL.DISPLAY_PARTS.TIME) {
                        pushTime(moment.duration(checkIfValueIsPlainObjAndGetAttr('time') || 0, 'seconds'))
                    }
                })

                handleDateTimeInputFromField(resultingFieldValue)
            }

            if (props.submissionObj.value !== null)
                handleDateTimeInputFromPickerOrSubmissionValue(props.submissionObj.value)



            return {
                ...extension,
                formattedDateTimeFieldLayout,
                formattedDateTimeFieldValue,
                handleDateTimeInputFromField,

                dateTimePickerBind,
                handleDateTimeInputFromPickerOrSubmissionValue
            }
        }
    }
</script>