var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "fileform",
    staticClass: "file-upload-area",
    class: [_vm.isActive ? 'file-upload-area--active ' + (_vm.activeClass ? _vm.activeClass : '') : '', _vm.disabled && 'file-upload-area--disabled'],
    on: {
      "drag": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      },
      "dragstart": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      },
      "dragend": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      },
      "dragover": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      },
      "click": _vm.inputFileFromExplorer,
      "dragenter": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.dragEnter.apply(null, arguments);
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.dragLeave.apply(null, arguments);
      },
      "drop": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.drop.apply(null, arguments);
      }
    }
  }, [_c('div', {
    class: ['file-upload-area__content', _vm.vertical && 'file-upload-area__content--vertical']
  }, [_c('div', {
    staticClass: "file-upload-area__icon-container"
  }, [_c('UploadIcon')], 1), _c('div', {
    staticClass: "file-upload-area__text-container"
  }, [_c('span', {
    staticClass: "file-upload-area__header"
  }, [_vm._t("header", function () {
    return [_vm._v("Choose File(s) or Drag here")];
  })], 2), _c('span', {
    staticClass: "file-upload-area__subheader"
  }, [_vm._t("subheader", function () {
    return [_vm._v("Max size " + _vm._s(_vm.maxFileSize) + "mb")];
  })], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }