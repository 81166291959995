import constants from '@/constants/constants'
import { updatedDiff } from 'deep-object-diff'
import { watch } from '@vue/composition-api'

export default () => {
    return {
        created: ({localContext, parentContext}) => {
            watch(
                () => localContext.getters.getFieldsWithExistenceStatus,
                (n, o) => {
                    const difference = updatedDiff(n, o)


                    for (const fieldID of Object.keys(difference))
                        if (
                            difference[fieldID]
                            && 'exist' in difference[fieldID]
                            && difference[fieldID].exist === true
                        ) {
                            const fieldObj = parentContext.getters.getFormSourceJSON.fields[fieldID]

                            if (constants.FIELD_FROM_TYPE[fieldObj.field_type].WITH_SUBMISSION) {
                                parentContext.dispatch('submission/updateFormSubmissionField', {
                                    fieldID,
                                    updatedSubmission: constants.FIELD_FROM_TYPE[fieldObj.field_type].generateSubmissionObj(fieldObj)
                                })
                            }
                        }
                },
                {deep: true}
            )
        },
        getters: {
            getFieldsWithExistenceStatus: ({parentGetters}) => {
                if (!parentGetters.getFormSourceJSON?.fields)
                    return {}

                const toReturn = Object.fromEntries(
                    Object.keys(parentGetters.getFormSourceJSON.fields).map(fieldID => [fieldID, {
                        exist: !parentGetters.getFormSourceJSON.fields[fieldID].properties.hide,
                        title: parentGetters.getFormSourceJSON.fields[fieldID].title
                    }])
                )

                let nextJumpToFieldIndex

                parentGetters.getEnumeratedFieldsList.forEach(({fieldId: fieldID}, currentFieldIndex) => {
                    const submissionObj = parentGetters['submission/getFormSubmission'][fieldID]

                    if (nextJumpToFieldIndex !== undefined && currentFieldIndex < nextJumpToFieldIndex) {
                        toReturn[fieldID].exist = false
                    } else {
                        const fieldObj = parentGetters.getFormSourceJSON.fields[fieldID]
                        if (fieldObj?.condition) {
                            const conditionalResult = constants.FIELD_FROM_TYPE[fieldObj.field_type].CONDITIONAL_LOGIC.VALIDATE(fieldObj.condition, submissionObj, fieldObj)
                            if (fieldObj.condition?.if_target) {
                                if (fieldObj.condition.if_target?.action === 'hide')
                                    toReturn[fieldObj.condition.if_target.field_id].exist = !conditionalResult
                                else if (fieldObj.condition.if_target?.action === 'show')
                                    toReturn[fieldObj.condition.if_target.field_id].exist = conditionalResult
                                else if (fieldObj.condition.if_target?.action === 'jump_to')
                                    if (conditionalResult) {
                                        nextJumpToFieldIndex = Math.max(parentGetters.getAllFieldsWithEnumeration[fieldObj.condition.if_target.field_id].questionIndex, nextJumpToFieldIndex || 0)
                                    }
                                // toReturn[fieldID].jump_to = conditionalResult ? fieldObj.condition.if_target.field_id : false
                            }
                            if (fieldObj.condition?.else_target) {
                                if (fieldObj.condition.else_target?.action === 'hide')
                                    toReturn[fieldObj.condition.else_target.field_id].exist = conditionalResult
                                else if (fieldObj.condition.else_target?.action === 'show')
                                    toReturn[fieldObj.condition.else_target.field_id].exist = !conditionalResult
                                else if (fieldObj.condition.else_target?.action === 'jump_to')
                                    if (!conditionalResult) {
                                        nextJumpToFieldIndex = Math.max(parentGetters.getAllFieldsWithEnumeration[fieldObj.condition.else_target.field_id].questionIndex, nextJumpToFieldIndex || 0)
                                    }
                                // toReturn[fieldID].jump_to = !conditionalResult ? fieldObj.condition.else_target.field_id : toReturn[fieldID].jump_to
                            }
                        }
                    }
                })

                return toReturn
            },
            getExistingFields: ({getters, parentGetters}) => {
                const existingFields = Object.fromEntries(Object.entries(getters.getFieldsWithExistenceStatus).filter(([, {exist}]) => exist))

                let counter = 0

                parentGetters.getEnumeratedFieldsList.forEach(({fieldId}) => {
                    if (fieldId in existingFields) {
                        existingFields[fieldId].index = counter
                        counter++
                    }
                })

                return existingFields
            }
        }
    }
}