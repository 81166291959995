var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-flex align-center justify-center h-full",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-item-group', [_c('v-container', [_c('v-row', [_c('v-col', [_c('p', {
    staticClass: "text-center grey_500--text",
    staticStyle: {
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "13px",
      "line-height": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.captionAboveTheTitle) + " ")])])], 1), _c('v-row', [_c('v-col', [_c('p', {
    staticClass: "text-h4 bluish_gray_500--text text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  })])], 1), _vm.subtitle ? _c('v-row', {
    staticClass: "mt-0 mb-10",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', [_c('p', {
    staticClass: "text-h6 gray_400--text font-weight-light text-center"
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")])])], 1) : _vm._e(), _vm.cards ? [_c('v-row', _vm._l(_vm.cards, function (card, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      staticClass: "type-card rounded-lg d-flex flex-column align-center justify-center",
      attrs: {
        "ripple": false,
        "rounded": "",
        "flat": "",
        "width": "17rem",
        "height": "21rem"
      },
      on: {
        "click": function () {
          var _card$onSelect;
          return (_card$onSelect = card.onSelect) === null || _card$onSelect === void 0 ? void 0 : _card$onSelect.call(card);
        }
      }
    }, [_c(card.imageComponent, {
      tag: "component"
    }), _c('v-card-title', {
      staticClass: "mb-3 bluish_gray_500--text"
    }, [_vm._v(" " + _vm._s(card.title) + " ")]), _c('v-card-subtitle', {
      staticClass: "font-weight-light gray_400--text px-3"
    }, [_vm._v(" " + _vm._s(card.subtitle) + " ")])], 1)], 1);
  }), 1)] : _vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }