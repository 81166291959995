<template>
    <div class="date-time-field">
        <FormattedDateTimeField :layout="fieldConst.UTIL.GEN_INPUT_LAYOUT(fieldObj)" disabled>
            <template #prepend-inner>
                <CalendarIcon/>
            </template>
        </FormattedDateTimeField>
    </div>
</template>

<script>
    import editable_field_view_mixin from '@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin'

    export default {
        name: "Editable__Datetime",
        components: {
            CalendarIcon: () => import('vue-feather-icons/icons/CalendarIcon'),
            FormattedDateTimeField: () => import('./FormattedDateTimeInput')
        },
        mixins: [editable_field_view_mixin]
    }
</script>