import billing_details_form_mixin from './billing_details_form_mixin'
import { VTextField } from 'vuetify/lib/components'

export default {
    name: "BillingDetailsForm_Line1",
    mixins: [billing_details_form_mixin],
    render(h, ctx) {
        return [
            h('p', {class: {'subtitle-2': true, 'mb-1': true}}, 'Address'),
            h(VTextField, {...ctx.props.bind.line1.value})
        ]
    }
}