<script>
import { logoBadgeEditorMixin } from './AddLogoBadgeButton'

export default {
  name: 'LogoBadgeEditor',
  components: {
    Edit2Icon: () => import('vue-feather-icons/icons/Edit2Icon'),
    TrashIcon: () => import('vue-feather-icons/icons/TrashIcon'),
  },
  mixins: [logoBadgeEditorMixin],
}
</script>

<template>
  <v-scale-transition origin="center">
    <div
      v-if="getCurrentLogoURL"
      :class="[
        'static-form-logo-badge',
        (getFormJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.VISIBLE) && 'visible-logo',
        (getFormJSON.layout_logo_style === constants.FORM_TYPES.STATIC_FORM.LAYOUT_LOGO_STYLES.INVISIBLE) && 'invisible-logo',]">
      <v-menu content-class="dropdown-menu-list" min-width="200" nudge-bottom="8" nudge-left="164" offset-y offset-x
        bottom>
        <template #activator="{ on, value }">
          <div class="static-form-logo-badge__background">
            <f-template-data-transfer v-slot="{ dataStash }" :image-loaded="false">
              <v-hover v-slot="{ hover }">
                <v-img :class="[
        'static-form-logo-badge__image',
        value && 'static-form-logo-badge__image--edit-menu-active',
        dataStash.imageLoaded &&
        hover &&
        !value &&
        'static-form-logo-badge__image--edit-overlay-active',
      ]" :src="getCurrentLogoURL" @load="dataStash.imageLoaded = true">
                  <template #placeholder>
                    <f-circle-loader size="3" />
                  </template>
                  <template #default>
                    <div v-if="dataStash.imageLoaded && hover && !value"
                      class="static-form-logo-badge__image-edit-overlay" v-on="on">
                      <Edit2Icon />
                      <p>Edit</p>
                    </div>
                  </template>
                </v-img>
              </v-hover>
            </f-template-data-transfer>
          </div>
        </template>
        <template #default>
          <v-list dense>
            <v-list-item @click="addLogo">
              <v-list-item-icon>
                <Edit2Icon size="16" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Change logo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="removeLogo">
              <v-list-item-icon>
                <TrashIcon size="16" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Remove</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </div>
  </v-scale-transition>
</template>
