<template>
    <v-text-field
            v-model="localValue"
            :label="label"
            outlined
            hide-details
            dense
            @input="handleInput"
    />
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "String",

        VALUE_VALIDATOR: value => value.length > 0 ? value : null,

        mixins: [field_property_input_mixin],

        data: () => ({
            localValue: ''
        }),

        watch: {
            inputValue(v) {
                if (v !== this.localValue)
                    this.localValue = v
            }
        },

        created() {
            this.localValue = this.inputValue
        },

        methods: {
            handleInput() {
                this.$nextTick(() => {
                    if (this.validateInputValue(this.localValue))
                        this.input(this.localValue)
                    else
                        this.localValue = this.inputValue
                })
            }
        }
    }
</script>

<style scoped>

</style>