<script>
  export default {
    name: 'App',
    components: {
      AppBar: () => import('@/components/AppBar/AppBar'),
      Snackbar: () => import('@/components/Snackbar'),
      DraggableNode: () => import('@/components/DraggableNode'),
      FileInput: () => import('@/components/Elements/FileInputs/FileInput'),
      ProOfferDialog: () => import('@/components/Elements/ProOfferDialog'),
      MobileWarning: () => import('@/components/Elements/MobileWarning'),
      GenericStoreDialog: () =>
        import('@/components/Elements/GenericStoreDialog'),
    },
    computed: {
      fadedBackground() {
        return this.$route.meta.fadedBackground
      },
      showMobileWarning() {
        return (
          this.constants.PAGE_NAME_FORM_SUBMIT !== this.$route.name &&
          this.constants.PAGE_NAME_SIGNUP !== this.$route.name &&
          this.constants.PAGE_NAME_LOGIN !== this.$route.name &&
          this.$vuetify.breakpoint.width <= 390
        )
      },
    },
    mounted() {
    this.manageCrispChat(this.$route);
    this.$router.beforeEach((to, from, next) => {
      this.manageCrispChat(to);
      next();
    });
  },
  watch: {
    $route(to) {
      this.manageCrispChat(to);
    }
  },
  methods: {
    manageCrispChat(route) {
      const isExcludedRoute = this.isFormSubmitRoute(route);

      if (!isExcludedRoute) {
        if (!window.CRISP_WEBSITE_ID) {
          window.$crisp = [];
          window.CRISP_WEBSITE_ID = "0ea25416-56f0-4ddf-b04c-cc39a8815c70";
          const script = document.createElement("script");
          script.id = "crisp-chat-script";
          script.src = "https://client.crisp.chat/l.js";
          script.async = 1;
          document.getElementsByTagName("head")[0].appendChild(script);
        }
      } else {
        const crispScript = document.querySelector('#crisp-chat-script');
        if (crispScript) {
          crispScript.remove();
        }

        if (window.$crisp) {
          delete window.$crisp;
        }
        if (window.CRISP_WEBSITE_ID) {
          delete window.CRISP_WEBSITE_ID;
        }
      }
    },
    isFormSubmitRoute(route) {
      return route.name ===  this.constants.PAGE_NAME_FORM_SUBMIT && /^[a-zA-Z0-9]+$/.test(route.params.formId);
    }
  }
}
</script>

<template>
  <v-app>
    <MobileWarning v-if="showMobileWarning" />
    <template v-else>
      <DraggableNode />
      <AppBar />
      <v-main>
        <v-container
          fluid
          class="h-full pa-0"
          :class="!fadedBackground || 'faded'"
        >
          <GenericStoreDialog
            store-module-path="user/plan/current_subscription/confirmation_dialog"
          />
          <v-slide-y-transition mode="out-in">
            <router-view />
          </v-slide-y-transition>
        </v-container>
      </v-main>
      <Snackbar />
      <FileInput />
      <ProOfferDialog />
    </template>
  </v-app>
</template>

<style lang="scss">
  @import 'scss/main';
</style>
