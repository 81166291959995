<template>
    <svg width="74" height="32" viewBox="0 0 74 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="74" height="32" rx="8" :fill="backgroundFill"/>
        <rect x="8" y="8" width="16" height="16" rx="4" :fill="blocksFill"/>
        <rect x="29" y="10" width="37" height="4" rx="2" :fill="blocksFill"/>
        <rect x="29" y="17" width="21" height="4" rx="2" :fill="blocksFill"/>
    </svg>
</template>

<script>
    export default {
        name: "LayoutFullScreenImage",
        props: ['active'],
        computed: {
            backgroundFill() {
                return this.active ? this.$vuetify.theme.themes.light.blue_100 : this.$vuetify.theme.themes.light.gray_100
            },
            blocksFill() {
                return this.active ? this.$vuetify.theme.themes.light.blue_400 : this.$vuetify.theme.themes.light.gray_400
            }
        }
    }
</script>

<style scoped>

</style>