var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBarTemplate', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('BackToDashboardComponent')];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('AppBarLogoComponent')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }