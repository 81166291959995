var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M116.445 58.1043C116.445 84.3646 95.1566 105.653 68.8963 105.653C42.6359 105.653 21.3477 84.3646 21.3477 58.1043C21.3477 31.8439 42.6359 10.5557 68.8963 10.5557C95.1566 10.5557 116.445 31.8439 116.445 58.1043Z",
      "fill": "#E3EAFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.7549 80.7257L13.4297 77.4744C13.5809 77.2906 13.4757 77.012 13.2407 76.974L9.00508 76.29C8.7688 76.2518 8.58099 76.4857 8.66924 76.7082L10.23 80.6434C10.3166 80.8617 10.6057 80.907 10.7549 80.7257Z",
      "fill": "#2FBAEA"
    }
  }), _c('path', {
    attrs: {
      "d": "M21.5225 25.0953C21.5225 26.8047 20.1367 28.1905 18.4273 28.1905C16.7178 28.1905 15.332 26.8047 15.332 25.0953C15.332 23.3858 16.7178 22 18.4273 22C20.1367 22 21.5225 23.3858 21.5225 25.0953Z",
      "fill": "#E6EBFB"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.3419 111.005C90.3419 112.112 89.4443 113.01 88.337 113.01C87.2297 113.01 86.332 112.112 86.332 111.005C86.332 109.898 87.2297 109 88.337 109C89.4443 109 90.3419 109.898 90.3419 111.005Z",
      "fill": "#C55BFC"
    }
  }), _c('circle', {
    attrs: {
      "cx": "113.108",
      "cy": "26.1269",
      "r": "1.11225",
      "fill": "url(#paint0_linear_8104_41339)"
    }
  }), _c('rect', {
    attrs: {
      "x": "104.965",
      "y": "10",
      "width": "91.2043",
      "height": "73.9645",
      "rx": "6",
      "transform": "rotate(90 104.965 10)",
      "fill": "url(#paint1_linear_8104_41339)",
      "stroke": "black",
      "stroke-opacity": "0.08"
    }
  }), _c('rect', {
    attrs: {
      "x": "42",
      "y": "27.1475",
      "width": "9",
      "height": "9",
      "rx": "4.5",
      "fill": "#2FBBEB"
    }
  }), _c('rect', {
    attrs: {
      "width": "32",
      "height": "3",
      "rx": "1.5",
      "transform": "matrix(-1 0 0 1 74 42.1475)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "18.6986",
      "height": "3.39975",
      "rx": "1.69988",
      "transform": "matrix(-1 0 0 1 60.6992 49.1475)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "width": "27",
      "height": "3",
      "rx": "1.5",
      "transform": "matrix(-1 0 0 1 69 56.5474)",
      "fill": "#E3E9F7"
    }
  }), _c('rect', {
    attrs: {
      "x": "42",
      "y": "65.5469",
      "width": "16.4321",
      "height": "4.533",
      "rx": "2.2665",
      "fill": "url(#paint2_linear_8104_41339)"
    }
  }), _c('g', {
    attrs: {
      "filter": "url(#filter0_d_8104_41339)"
    }
  }, [_c('rect', {
    attrs: {
      "width": "5.96202",
      "height": "40.5788",
      "rx": "1",
      "transform": "matrix(-0.866025 -0.5 -0.5 0.866025 80.4531 73.98)",
      "fill": "url(#paint3_linear_8104_41339)"
    }
  })]), _c('rect', {
    attrs: {
      "width": "3.98483",
      "height": "8.8174",
      "rx": "0.5",
      "transform": "matrix(-0.866025 -0.5 -0.5 0.866025 79.0098 74.48)",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M88.205 68.708C88.2648 68.5237 88.5255 68.5237 88.5854 68.708L89.6295 71.9215C89.6563 72.0039 89.7331 72.0597 89.8198 72.0597H93.1987C93.3924 72.0597 93.473 72.3076 93.3162 72.4215L90.5826 74.4076C90.5125 74.4585 90.4832 74.5488 90.51 74.6312L91.5541 77.8448C91.614 78.029 91.4031 78.1823 91.2464 78.0684L88.5127 76.0823C88.4426 76.0314 88.3477 76.0314 88.2776 76.0823L85.544 78.0684C85.3873 78.1823 85.1764 78.029 85.2362 77.8448L86.2804 74.6312C86.3072 74.5488 86.2778 74.4585 86.2077 74.4076L83.4741 72.4215C83.3174 72.3076 83.3979 72.0597 83.5917 72.0597H86.9706C87.0573 72.0597 87.134 72.0039 87.1608 71.9215L88.205 68.708Z",
      "fill": "#FFB11A"
    }
  }), _c('path', {
    attrs: {
      "d": "M81.1308 61.4391C81.1757 61.3009 81.3712 61.3009 81.4161 61.4391L82.1992 63.8492C82.2193 63.911 82.2769 63.9529 82.3419 63.9529H84.8761C85.0214 63.9529 85.0818 64.1388 84.9642 64.2242L82.914 65.7138C82.8614 65.752 82.8395 65.8197 82.8595 65.8815L83.6426 68.2917C83.6875 68.4299 83.5294 68.5448 83.4118 68.4594L81.3616 66.9698C81.309 66.9316 81.2378 66.9316 81.1853 66.9698L79.1351 68.4594C79.0175 68.5448 78.8593 68.4299 78.9042 68.2917L79.6873 65.8815C79.7074 65.8197 79.6854 65.752 79.6329 65.7138L77.5826 64.2242C77.4651 64.1388 77.5255 63.9529 77.6708 63.9529H80.205C80.27 63.9529 80.3276 63.911 80.3477 63.8492L81.1308 61.4391Z",
      "fill": "#FFB11A"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.9797 79.9571C84.0246 79.8189 84.2202 79.8189 84.2651 79.9571L84.7283 81.383C84.7484 81.4448 84.806 81.4866 84.871 81.4866H86.3703C86.5156 81.4866 86.576 81.6726 86.4584 81.758L85.2455 82.6392C85.1929 82.6774 85.1709 82.7451 85.191 82.8069L85.6543 84.2328C85.6992 84.371 85.541 84.4859 85.4235 84.4005L84.2106 83.5193C84.158 83.4811 84.0868 83.4811 84.0342 83.5193L82.8213 84.4005C82.7037 84.4859 82.5456 84.371 82.5905 84.2328L83.0538 82.8069C83.0739 82.7451 83.0519 82.6774 82.9993 82.6392L81.7864 81.758C81.6688 81.6726 81.7292 81.4866 81.8745 81.4866H83.3738C83.4388 81.4866 83.4964 81.4448 83.5164 81.383L83.9797 79.9571Z",
      "fill": "#FFB11A"
    }
  }), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_d_8104_41339",
      "x": "51.3672",
      "y": "71.3647",
      "width": "32.7207",
      "height": "45.3916",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "in": "SourceAlpha",
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
      "result": "hardAlpha"
    }
  }), _c('feOffset', {
    attrs: {
      "dy": "4"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "2"
    }
  }), _c('feComposite', {
    attrs: {
      "in2": "hardAlpha",
      "operator": "out"
    }
  }), _c('feColorMatrix', {
    attrs: {
      "type": "matrix",
      "values": "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in2": "BackgroundImageFix",
      "result": "effect1_dropShadow_8104_41339"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "effect1_dropShadow_8104_41339",
      "result": "shape"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint0_linear_8104_41339",
      "x1": "112.052",
      "y1": "26.111",
      "x2": "114.21",
      "y2": "26.111",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FF66A9"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#F53689"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_8104_41339",
      "x1": "151.764",
      "y1": "10",
      "x2": "151.764",
      "y2": "85.3587",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FEFEFF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint2_linear_8104_41339",
      "x1": "41.9654",
      "y1": "67.8137",
      "x2": "58.4282",
      "y2": "67.8137",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#3D66F6"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#6789FF"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint3_linear_8104_41339",
      "x1": "2.98101",
      "y1": "0",
      "x2": "2.98101",
      "y2": "40.5788",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#4269F1"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.46875",
      "stop-color": "#2FBBEB"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.979167",
      "stop-color": "#BA65FD"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }