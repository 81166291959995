var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.5",
      "y": "0.5",
      "width": "25",
      "height": "25",
      "rx": "7.5",
      "fill": "#F8F0E2",
      "stroke": "#F8F0E2"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.90047 10.1112H7.90459L6.46861 11.0337V11.9929L7.81996 11.1297H7.85381V15.889H8.90047V10.1112Z",
      "fill": "#393F41"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.3675 15.889H14.3284V15.0144H11.8119V14.9749L12.8078 13.9621C13.9306 12.8844 14.241 12.3597 14.241 11.708C14.241 10.7403 13.4539 10.0322 12.2915 10.0322C11.1461 10.0322 10.3336 10.7432 10.3336 11.8406H11.3295C11.3295 11.251 11.7019 10.8814 12.2774 10.8814C12.8276 10.8814 13.2366 11.2171 13.2366 11.7616C13.2366 12.244 12.9432 12.5882 12.3733 13.1666L10.3675 15.1329V15.889Z",
      "fill": "#393F41"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.3753 15.968C18.611 15.968 19.5109 15.2599 19.5081 14.2837C19.5109 13.5615 19.0595 13.0424 18.2499 12.9268V12.8816C18.8762 12.7462 19.2965 12.2807 19.2937 11.6318C19.2965 10.7516 18.5461 10.0322 17.3922 10.0322C16.2694 10.0322 15.4005 10.7008 15.3779 11.6685H16.3851C16.402 11.1833 16.8534 10.8814 17.3866 10.8814C17.9254 10.8814 18.2837 11.2087 18.2809 11.6939C18.2837 12.1989 17.8662 12.5346 17.2681 12.5346H16.7575V13.3415H17.2681C17.9988 13.3415 18.4332 13.7082 18.4304 14.2301C18.4332 14.7408 17.9903 15.0906 17.3725 15.0906C16.7913 15.0906 16.3427 14.7887 16.3174 14.3176H15.2566C15.2848 15.2937 16.1565 15.968 17.3753 15.968Z",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }