<template>
  <SharedView
    :options="sourceObj.content.options"
    :randomize-options="sourceObj.properties.randomize"
    :selected-options="selectedOptions"
    @toggle-options="toggleOptionSelection($event)"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'
import {computed} from '@vue/composition-api'

export default {
  name: "Submittable__ImageChoice",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  },
  setup(props) {

    /** OPTIONS SELECTION **/
    const selectedOptions = computed({
      get: () => props.submissionObj.selected_options,
      set: v => props.submissionObj.selected_options = v
    })
    const toggleOptionSelection = optionIndex => {
      if (props.sourceObj.properties.multiple_selection)
        selectedOptions.value.includes(optionIndex)
            ? selectedOptions.value.splice(selectedOptions.value.findIndex(v => v === optionIndex), 1)
            : selectedOptions.value.push(optionIndex)
      else
        selectedOptions.value.includes(optionIndex)
            ? selectedOptions.value = []
            : selectedOptions.value = [optionIndex]
    }
    const optionsSelectionModule = {selectedOptions, toggleOptionSelection}

    return {
      ...optionsSelectionModule
    }
  }
}
</script>

<style scoped>

</style>