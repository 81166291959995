var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InteractiveHomePageStylesProvider', {
    attrs: {
      "page-obj": _vm.pageObj
    }
  }, [_vm.pageObj.layout ? _c('InteractiveHomePageDisplayManager', {
    attrs: {
      "page-obj": _vm.pageObj,
      "image-url": _vm.pageObj.imageUrl
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.pageObj.title)
          }
        })];
      },
      proxy: true
    }, {
      key: "subtitle",
      fn: function () {
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.pageObj.subtitle)
          }
        })];
      },
      proxy: true
    }, {
      key: "btn",
      fn: function () {
        return [_c('InteractiveFormActionButtonBlock', {
          attrs: {
            "with-press-enter": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('go-to-next-page');
            }
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('span', {
                domProps: {
                  "innerHTML": _vm._s(_vm.pageObj.buttonText)
                }
              })];
            },
            proxy: true
          }], null, false, 1965462925)
        })];
      },
      proxy: true
    }], null, false, 1755858419)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }