<template>
    <div>
        <v-checkbox
                v-model="localValue"
                :label="inputConst.label"
                :input-value="inputValue"
                class="checkbox-item"
                @change="onInput($event)"
                hide-details
        >

        </v-checkbox>
    </div>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: 'Checkbox',
        mixins: [field_property_input_mixin],
        data() {
            return {
                localValue: null,
            }
        },
        methods: {
            onInput(value) {
                this.$nextTick(() => {
                    if (value || this.inputConst.validator(this.fieldPropertiesObj)) {
                        this.input(value);
                    } else {
                        this.localValue = this.inputValue
                    }
                })

            },
        },
        created() {
            this.localValue = this.inputValue
        },
    };
</script>

<style scoped lang="scss">
    ::v-deep .checkbox-item .v-label {
        color: #000;
        margin-left: 5px;
    }
</style>