var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    staticClass: "f-solo-search-bar",
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('SearchIcon')];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
      return {
        key: scopedSlotName,
        fn: function (slotData) {
          return [_vm._t(scopedSlotName, null, null, slotData)];
        }
      };
    }), _vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'v-text-field', _vm.bind, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }