var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "contents"
    }
  }, [_vm._t("default", null, {
    "attrs": _vm.attrs,
    "submit": _vm.validate,
    "reset": _vm.reset,
    "loading": _vm.loading
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }