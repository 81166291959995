var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select', {
    staticClass: "conditional-logic-editor__target-action-picker",
    attrs: {
      "items": _vm.actions,
      "item-value": function (item) {
        return item.value;
      },
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "menu-props": {
        nudgeBottom: 38
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(item.icon, {
          tag: "component",
          attrs: {
            "size": "16"
          }
        }), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.text))])];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs,
          item = _ref2.item;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c(item.icon, {
          tag: "component",
          attrs: {
            "size": "16"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.targetObj.action,
      callback: function ($$v) {
        _vm.$set(_vm.targetObj, "action", $$v);
      },
      expression: "targetObj.action"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }