var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "open-on-click": false,
      "open-on-hover": "",
      "close-on-content-click": false,
      "content-class": "app-bar-form-editor-form-published-popup",
      "offset-y": "",
      "bottom": ""
    },
    on: {
      "input": _vm.resetCopyState
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, _vm.shareFormVisible ? {} : on), [_c('v-btn', {
          attrs: {
            "rounded": "",
            "disabled": ""
          }
        }, [_vm._v(" Published ")])], 1)];
      }
    }]),
    model: {
      value: _vm.menuModel,
      callback: function ($$v) {
        _vm.menuModel = $$v;
      },
      expression: "menuModel"
    }
  }, [[_c('v-card', {
    staticClass: "app-bar-form-editor-form-published-popup__card"
  }, [_c('v-card-title', {
    staticClass: "app-bar-form-editor-form-published-popup__card-title"
  }, [_vm._v(" Link ")]), _c('v-card-text', {
    staticClass: "app-bar-form-editor-form-published-popup__card-body"
  }, [_c('span', {
    ref: "publishedLink",
    staticClass: "app-bar-form-editor-form-published-popup__card-body-link",
    on: {
      "click": _vm.openLink
    }
  }, [_vm._v(" " + _vm._s(_vm.submitLink) + " ")]), _c('v-btn', {
    staticClass: "app-bar-form-editor-form-published-popup__card-body-btn",
    attrs: {
      "width": "60",
      "max-height": "24",
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": _vm.copy
    }
  }, [_vm._v(" " + _vm._s(_vm.copied ? 'Copied' : 'Copy') + " ")])], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }