<template>
  <div>
    <span class="form-integrarion-title">INTEGRATE WITH</span>
    <IntegrationApp
      v-for="item in appsList"
      :icon="item.icon"
      :key="item.title"
      :title="item.title"
      :subtitle="item.subtitle"
      :disabled="item.disabled"
      :connected="item.connected"
      @navigate="openConnectSettings"
    />
  </div>
</template>

<script>
export default {
  name: 'GeneralFormIntergrations',
  props: {
    appsList: {
      required: true,
    },
  },
  components: {
    IntegrationApp: () => import('./IntegrationApp.vue'),
  },
  methods: {
    openConnectSettings(title) {
      this.$emit('open-connect-settings', title)
    },
  },
}
</script>
<style lang="scss" >
.form-integrarion-title {
  color: var(--v-grey_3-base);
  font-size: 12px;
  font-weight: 400;
}
</style>

