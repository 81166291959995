var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.showMobileWarning ? _c('MobileWarning') : [_c('DraggableNode'), _c('AppBar'), _c('v-main', [_c('v-container', {
    staticClass: "h-full pa-0",
    class: !_vm.fadedBackground || 'faded',
    attrs: {
      "fluid": ""
    }
  }, [_c('GenericStoreDialog', {
    attrs: {
      "store-module-path": "user/plan/current_subscription/confirmation_dialog"
    }
  }), _c('v-slide-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1)], 1), _c('Snackbar'), _c('FileInput'), _c('ProOfferDialog')]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }