<template>
    <f-select
            v-model="targetObj.action"
            class="conditional-logic-editor__target-action-picker"
            :items="actions"
            :item-value="item => item.value"
            solo flat dense hide-details
            :menu-props="{
                nudgeBottom: 38
            }"
    >
        <template #selection="{item}">
            <component :is="item.icon" size="16"/>
            <span class="font-weight-medium">{{ item.text }}</span>
        </template>
        <template #item="{on, attrs, item}">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                    <component :is="item.icon" size="16"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </f-select>
</template>

<script>
    export default {
        name: "ConditionalThenActionSelect",
        props: {
            targetObj: Object,
            actions: Array
        }
    }
</script>