var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-select-with-virtual-scroll', _vm._g(_vm._b({
    staticClass: "generic-submittable-dropdown__select",
    class: _vm.inputClass,
    attrs: {
      "items": _vm.filteredItems,
      "item-value": _vm.itemValue,
      "placeholder": _vm.placeholder,
      "value": _vm.value,
      "hide-details": "",
      "item-height": 50,
      "attach": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-text-field', {
          staticClass: "generic-submittable-dropdown__search-field",
          attrs: {
            "placeholder": _vm.searchPlaceholder,
            "height": "40",
            "solo": "",
            "flat": "",
            "hide-details": "",
            "persistent-placeholder": ""
          },
          scopedSlots: _vm._u([{
            key: "prepend-inner",
            fn: function () {
              return [_c('SearchIcon', {
                staticClass: "generic-submittable-dropdown__search-field-icon"
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filterValue,
            callback: function ($$v) {
              _vm.filterValue = $$v;
            },
            expression: "filterValue"
          }
        })];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs,
          item = _ref.item;
        return [_vm._t("item", null, {
          "on": on,
          "attrs": attrs,
          "item": item,
          "component": _vm.$options.selectItemComponent
        })];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._t("selection", null, {
          "item": item
        })];
      }
    }, {
      key: "append",
      fn: function () {
        return [_c('ChevronDownIcon')];
      },
      proxy: true
    }], null, true)
  }, 'f-select-with-virtual-scroll', _vm.merge(_vm.$attrs, {
    listHeight: 250,
    'menu-props': {
      offsetY: true,
      bottom: true,
      nudgeBottom: 10,
      maxHeight: 'auto',
      contentClass: 'generic-submittable-dropdown__menu prevent-interactive-page-scrolling'
    }
  }), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }