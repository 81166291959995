var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('f-hover-tooltip', {
    attrs: {
      "bottom": "",
      "text": _vm.formType
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-tooltip', {
          attrs: {
            "nudge-top": "10",
            "content-class": "tooltip-bottom",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var value = _ref2.value;
              return [_c('v-text-field', _vm._g({
                directives: [{
                  name: "input-mask",
                  rawName: "v-input-mask",
                  value: _vm.Utils.getRangedLetterOrNumberRegex({
                    expression: _vm.constants.ANY_LETTER_OR_NUMBER_REGEX,
                    from: 0,
                    to: 75,
                    isSpaceAllowed: true
                  }),
                  expression: "Utils.getRangedLetterOrNumberRegex({\n                        expression: constants.ANY_LETTER_OR_NUMBER_REGEX,\n                        from: 0,\n                        to: 75,\n                        isSpaceAllowed: true,\n                    })"
                }],
                ref: "formNameInput",
                staticClass: "form-name-input",
                style: _vm.setInputSize,
                attrs: {
                  "placeholder": "Name your form",
                  "flat": "",
                  "solo": "",
                  "hide-details": "",
                  "disabled": !_vm.$route.name === _vm.constants.PAGE_NAME_FORM_EDITOR
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.setOverlay(false);
                  },
                  "focus": function ($event) {
                    return _vm.setOverlay(true);
                  },
                  "blur": function ($event) {
                    return _vm.setOverlay(false);
                  }
                },
                model: {
                  value: _vm.formName,
                  callback: function ($$v) {
                    _vm.formName = $$v;
                  },
                  expression: "formName"
                }
              }, _vm.tooltip || _vm.$route.name === _vm.constants.PAGE_NAME_FORM_EDITOR ? undefined : on), [_vm._v(_vm._s(value))])];
            }
          }, {
            key: "default",
            fn: function () {
              return [_vm._v(" Be bold and clear ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.tooltip,
            callback: function ($$v) {
              _vm.tooltip = $$v;
            },
            expression: "tooltip"
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }