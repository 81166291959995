<template>
    <v-row
            justify="center"
    >
        <v-col cols="4">
            <v-container class="text-center">
                <v-row>
                    <v-col>
                        <v-row justify="center">
                            <slot name="icon"></slot>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="text-h4 font-weight-bold">
                    <v-col>
                        <slot name="title"></slot>
                    </v-col>
                </v-row>
                <v-row class="text--secondary">
                    <v-col>
                        <slot name="description"></slot>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <slot name="code-input"></slot>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <slot name="action"></slot>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <slot name="secondary-action"></slot>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                                @click="goToLogin"
                                class="subtitle-2 gray_500--text"
                                depressed
                                text
                                rounded
                        >
                            <ArrowLeftIcon class="mr-4"/>
                            Back to log in
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import {ArrowLeftIcon} from 'vue-feather-icons'

    export default {
        name: "VerificationTemplate",
        components: {
            ArrowLeftIcon
        },
        methods: {
            goToLogin() {
                this.$router.push({name: this.constants.PAGE_NAME_LOGIN})
            }
        }
    }
</script>

<style scoped>

</style>