import Vue from 'vue'

export const InputMask = {
    inserted: (el, binding, vNode) => {
        let oldValue = ''
        el.oninput = () => {
            Vue.nextTick(() => {
                if (binding.value.test(vNode.componentInstance.internalValue)) {
                    oldValue = vNode.componentInstance.internalValue
                } else
                    vNode.componentInstance.internalValue = oldValue
            })
        }
    }
}

export default InputMask