<template>
  <SharedView
    editable
    v-model="fieldObj.content.placeholder"
    :country-code="fieldObj.content.country"
  />
</template>

<script>
import editable_field_view_mixin from "@/components/Fields/EditableField/EditableFieldViewUtils/editable_field_view_mixin";

export default {
  name: 'Editable__Phone',
  mixins: [editable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  }
}
</script>