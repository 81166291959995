var render = function () {
  var _free$pro$business, _vm$getSelectedPriceO, _vm$getSelectedPriceO2, _vm$getOrderSummaryLi, _vm$getOrderSummaryLi2, _vm$getOrderSummaryLi3, _vm$getOrderSummaryLi4, _vm$getOrderSummaryLi5, _vm$getOrderSummaryLi6, _vm$getOrderSummaryLi7, _vm$getOrderSummaryLi8, _vm$getOrderSummaryLi9, _vm$getOrderSummaryLi10, _vm$getOrderSummaryLi11, _vm$getOrderSummaryLi12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-selected-plan-amount"
  }, [_c('div', [_vm._m(0), _c('div', {
    staticClass: "checkout-selected-plan-amount__content-wrapper"
  }, [_vm.getOrderSummaryIsLoading ? _c('f-circle-loader', {
    staticClass: "checkout-selected-plan-amount__loader",
    attrs: {
      "size": "4"
    }
  }) : _c('div', {
    staticClass: "checkout-selected-plan-amount__content"
  }, [_c('div', {
    staticClass: "checkout-selected-plan-amount__lines-wrapper"
  }, [_c('div', {
    staticClass: "checkout-selected-plan-amount__lines"
  }, [_c('div', {
    staticClass: "checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--plan"
  }, [_c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__plan-name"
  }, [_vm._v(_vm._s((_free$pro$business = {
    free: 'Free',
    pro: 'Pro',
    business: 'Business'
  }) === null || _free$pro$business === void 0 ? void 0 : _free$pro$business[(_vm$getSelectedPriceO = _vm.getSelectedPriceObj) === null || _vm$getSelectedPriceO === void 0 ? void 0 : _vm$getSelectedPriceO.productID]))]), _vm._v("  "), _c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__billing-period"
  }, [_vm._v("(" + _vm._s(_vm.$options.upperFirst((_vm$getSelectedPriceO2 = _vm.getSelectedPriceObj) === null || _vm$getSelectedPriceO2 === void 0 ? void 0 : _vm$getSelectedPriceO2.recurring_interval) + 'ly') + ")")])]), _c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__price"
  }, [_vm._v("$" + _vm._s(_vm.newPlanInitialPrice / 100) + " USD")])])]), _c('div', {
    staticClass: "checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--responses"
  }, [_c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__responses-count"
  }, [_vm._v(_vm._s(_vm.responsesPerMonth) + " responses / mo")])]), _c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__price"
  })])]), (_vm$getOrderSummaryLi = _vm.getOrderSummaryLineItems) !== null && _vm$getOrderSummaryLi !== void 0 && _vm$getOrderSummaryLi.prorated_adjustment ? _c('div', {
    staticClass: "checkout-selected-plan-amount-line-item__prorated-adjustment"
  }, [_c('div', {
    staticClass: "checkout-selected-plan-amount-line-item"
  }, [_c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__name"
  }, [_vm._v("Prorated adjustment")])]), _c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__value"
  }, [_vm._v("-$" + _vm._s(((_vm$getOrderSummaryLi2 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi2 === void 0 ? void 0 : (_vm$getOrderSummaryLi3 = _vm$getOrderSummaryLi2.prorated_adjustment) === null || _vm$getOrderSummaryLi3 === void 0 ? void 0 : _vm$getOrderSummaryLi3.amount) / 100))])])]), _c('div', {
    staticClass: "checkout-selected-plan-amount-line-item__prorated-adjustment-hint"
  }, [_c('span', [_vm._v("(" + _vm._s(_vm._f("timestampToDate")((_vm$getOrderSummaryLi4 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi4 === void 0 ? void 0 : (_vm$getOrderSummaryLi5 = _vm$getOrderSummaryLi4.prorated_adjustment) === null || _vm$getOrderSummaryLi5 === void 0 ? void 0 : _vm$getOrderSummaryLi5.period.start)) + " - " + _vm._s(_vm._f("timestampToDate")((_vm$getOrderSummaryLi6 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi6 === void 0 ? void 0 : (_vm$getOrderSummaryLi7 = _vm$getOrderSummaryLi6.prorated_adjustment) === null || _vm$getOrderSummaryLi7 === void 0 ? void 0 : _vm$getOrderSummaryLi7.period.end)) + ")")])])]) : _vm._e(), (_vm$getOrderSummaryLi8 = _vm.getOrderSummaryLineItems) !== null && _vm$getOrderSummaryLi8 !== void 0 && _vm$getOrderSummaryLi8.available_credit_balance ? _c('div', {
    staticClass: "checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--available-credits"
  }, [_c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__name"
  }, [_vm._v("Available credits")])]), _c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__value"
  }, [_vm._v("-$" + _vm._s(((_vm$getOrderSummaryLi9 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi9 === void 0 ? void 0 : _vm$getOrderSummaryLi9.available_credit_balance) / 100))])])]) : _vm._e(), _c('div', {
    staticClass: "checkout-selected-plan-amount-line-item checkout-selected-plan-amount-line-item--tax"
  }, [_c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__name"
  }, [_vm._v("Tax")])]), _c('div', [_c('span', {
    staticClass: "checkout-selected-plan-amount-line-item__value"
  }, [_vm._v("No tax")])])]), _vm.getSelectedBillingPeriod === 'year' ? _c('div', {
    staticClass: "checkout-selected-plan-amount__line-discount checkout-selected-plan-amount__line-discount--success"
  }, [_c('span', [_vm._v("Yearly discount (-20%)")]), _c('span', [_vm._v("-$" + _vm._s(_vm.yearlyDiscount / 100) + " USD")])]) : _vm._e()])]), _c('v-divider'), _c('div', {
    staticClass: "checkout-selected-plan-amount__total"
  }, [_c('div', [_c('span', [_vm._v("Total due today")]), _c('span', [_vm._v("(excl. tax)")])]), _c('div', [_c('span', [_vm._v("$" + _vm._s(((_vm$getOrderSummaryLi10 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi10 === void 0 ? void 0 : _vm$getOrderSummaryLi10.total) / 100) + " USD")])])]), _c('v-divider'), _c('div', {
    staticClass: "checkout-selected-plan-amount__confirm-btn-wrapper"
  }, [_vm._t("button", null, {
    "attrs": {
      color: 'blue_400',
      rounded: true,
      block: true
    }
  })], 2), (_vm$getOrderSummaryLi11 = _vm.getOrderSummaryLineItems) !== null && _vm$getOrderSummaryLi11 !== void 0 && _vm$getOrderSummaryLi11.credit_balance_after ? _c('div', {
    staticClass: "checkout-selected-plan-amount__credit-balance-after"
  }, [_c('div', [_c('span', [_vm._v("Credit balance after")])]), _c('div', [_c('span', [_vm._v("$" + _vm._s(((_vm$getOrderSummaryLi12 = _vm.getOrderSummaryLineItems) === null || _vm$getOrderSummaryLi12 === void 0 ? void 0 : _vm$getOrderSummaryLi12.credit_balance_after) / 100))])])]) : _vm._e(), _vm._t("hint", null, {
    "component": {
      name: 'SelectedPlanAmount__Hint',
      functional: true,
      render: function (h, ctx) {
        return h('p', {
          class: 'checkout-selected-plan-amount__hint'
        }, ctx.children);
      }
    },
    "orderSummary": _vm.getOrderSummary,
    "selectedPriceObj": _vm.getSelectedPriceObj
  })], 2)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkout-selected-plan-amount__heading"
  }, [_c('div', {
    staticClass: "checkout-selected-plan-amount__title"
  }, [_c('span', [_vm._v("Your new plan")])])]);
}]

export { render, staticRenderFns }