<template>
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.2833 9.10511H21.7151V5.60227H25.218V4.17045H21.7151V0.667613H20.2833V4.17045H16.7805V5.60227H20.2833V9.10511Z"
              fill="currentColor"/>
        <path d="M8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 17L16 12L5 23" stroke="#ACACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14 4.25H5C3.48122 4.25 2.25 5.48122 2.25 7V21C2.25 22.5188 3.48122 23.75 5 23.75H19C20.5188 23.75 21.75 22.5188 21.75 21V11.775L20.25 11.625V21C20.25 21.6904 19.6904 22.25 19 22.25H5C4.30964 22.25 3.75 21.6904 3.75 21V7C3.75 6.30964 4.30964 5.75 5 5.75H14V4.25Z"
              fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "AddImageIcon"
    }
</script>