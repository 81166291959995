<template>
    <span>{{letters}}</span>
</template>

<script>
    export default {
        name: "ShortSingleAnswer__ImageChoice",
        props: {
            submissionObj: Object
        },
        computed: {
            letters() {
                return this.submissionObj.selected_options.length
                    ? this.submissionObj.selected_options.map(optionIndex => this.Utils.convertIndexToLetter(optionIndex)).join(', ')
                    : '-'
            }
        }
    }
</script>