import current_subscriptions_monitoring_features_dialog_store from "./monitoring_features_modules/current_subscriptions_monitoring_features_dialog_store";
import { StoreableExtensions, StoreableUtils } from '@/xstore/utils/storeable'
import _ from 'lodash'

const featuresDialogObj = Object.freeze({
    forms_count: {
        title: 'Number of Forms',
        subtitle: 'Allow more forms and more advanced features that stay true to your brand',
    }
})

export default ({useExtension}) => {
    useExtension(StoreableExtensions.Default)

    return {
        modules: {
            monitoring_features_dialog: current_subscriptions_monitoring_features_dialog_store,
        },
        actions: {
            validatePaidFeature: async ({dispatch, parentGetters, getters}, {
                featureName,
                actualValue,
                initialMethod,
                validator
            }) => {
                if (_.isEmpty(parentGetters['getCurrentSubscriptionFeatures']))
                    await new Promise(resolve => {
                        getters[StoreableUtils.storeGetterName].subscribe(mutation => {
                            if (mutation.type === 'user/plan/current_subscription/SET_CURRENT_SUBSCRIPTION')
                                resolve()
                        })
                    })

                const featureValue = parentGetters['getCurrentSubscriptionFeatures'][featureName]

                const validationResult = await Promise.resolve(validator(actualValue, featureValue))
                if (validationResult)
                    return initialMethod()
                else
                    return dispatch('monitoring_features_dialog/showDialog', featuresDialogObj[featureName])

            },
        },

    }
}