var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericStringSingleLineFieldViewInput', _vm._g({
    ref: "input",
    attrs: {
      "placeholder": _vm.sourceObj.content.placeholder,
      "assign-generic-view-class": ""
    },
    model: {
      value: _vm.submissionObj.value,
      callback: function ($$v) {
        _vm.$set(_vm.submissionObj, "value", _vm._n($$v));
      },
      expression: "submissionObj.value"
    }
  }, _vm.inputAutofocusOn));
}
var staticRenderFns = []

export { render, staticRenderFns }