<template>
    <FormParametersContentTemplate>
        <template #title>
            <div class="form-settings-header">
                <v-slide-x-reverse-transition mode="out-in">
                    <ChevronLeftIcon
                             v-if="emailNotificationsModel"
                             class="cursor-pointer ml-n1"
                             key="email-notifications-icon"
                             @click="closeEmailNotifications"
                    />
                    <ChevronLeftIcon
                    v-else-if="embededCodeModel"
                             class="cursor-pointer ml-n1"
                             key="embeded-code-icon"
                             @click="closeEmbededCode"
                    />
                    <SettingsIcon
                            v-else
                            class="ml-n1"
                            key="general-settings-icon"
                    />
                </v-slide-x-reverse-transition>
                <v-slide-x-reverse-transition mode="out-in">
                    <span v-if="embededCodeModel" key="embeded-code-header">
                        Embed your form
                    </span>
                    <span v-else-if="emailNotificationsModel" key="email-notifications-header">
                        Email notifications
                    </span>
                    <span v-else key="general-settings-header">
                        Settings
                    </span>
                </v-slide-x-reverse-transition>
            </div>
        </template>
        <template #content>
            <div class="form-settings-content thin-scrollbar">
                <v-slide-x-reverse-transition mode="out-in">
                    <FormEmbededCode
                            v-if="embededCodeModel"
                            :settings-obj="settingsObj"
                            key="embeded-code-content"
                    />
                    <FormEmailNotificationsSettings
                            v-else-if="emailNotificationsModel"
                            :settings-obj="settingsObj"
                            key="email-notifications-content"
                    />
                    <GeneralFormSettings
                            v-else
                            :settings-obj="settingsObj"
                            key="general-settings-content"
                            @open-email-notifications="openEmailNotifications"
                            @open-enbed-form="openEmbededCode"
                    />
                </v-slide-x-reverse-transition>
            </div>
        </template>
        <template #actions>
            <div class="form-settings-actions" v-if="!embededCodeModel">
                <v-spacer/>
                <v-btn
                        @click="$emit('close')"
                        class="mr-5 px-7 background-light"
                        depressed
                        plain
                        rounded
                        large
                >
                    Cancel
                </v-btn>
                <v-btn
                        @click="saveSettings"
                        color="blue_400"
                        class="px-10"
                        dark
                        depressed
                        rounded
                        large
                >
                    Save
                </v-btn>
            </div>
        </template>
    </FormParametersContentTemplate>
</template>

<script>
    import {mapGetters} from '@/xstore'
    import { useVuelidate } from '@vuelidate/core'

    export default {
        name: "FormSettings",
        data: vm => ({
            settingsObj: vm.$store.getters['edit/getFormJSON'].settings,
            emailNotificationsModel: false,
            embededCodeModel: false,
        }),
        components: {
            SettingsIcon: () => import('vue-feather-icons/icons/SettingsIcon'),
            ChevronLeftIcon: () => import('vue-feather-icons/icons/ChevronLeftIcon'),
            FormParametersContentTemplate: () => import('./Elements/FormParametersContentTemplate'),

            FormEmailNotificationsSettings: () => import('./FormSettingsElements/FormEmailNotificationsSettings'),
            FormEmbededCode: () => import('./FormSettingsElements/FormEmbededCode'),
            GeneralFormSettings: () => import('./FormSettingsElements/GeneralFormSettings'),
        },
        props: {
            active: Boolean
        },
        setup() {
            return {
                $v: useVuelidate()
            }
        },
        computed: {
            ...mapGetters('edit', ['getFormJSON'])
        },
        watch: {
            active(v) {
                if (v)
                    this.settingsObj = this.Utils.deepCloneObject(this.getFormJSON.settings)
                else
                    this.closeEmailNotifications()
            }
        },
        methods: {

            openEmailNotifications() {
                this.processValidation(() => this.emailNotificationsModel = true)
            },
            closeEmailNotifications() {
                this.processValidation(() => this.emailNotificationsModel = false)
            },
            openEmbededCode() {
                this.embededCodeModel = true;
            },
            closeEmbededCode() {
                this.embededCodeModel = false;
            },
            processValidation(cb) {
                this.$v.$touch()
                this.$v.$validate()
                    .then(r => {
                        if (r)
                            cb()
                    })
            },


            saveSettings() {
                this.processValidation(async () => {
                    await this.$store.dispatch('edit/changeFormAttrs', {settings: this.settingsObj})
                    this.$store.dispatch('edit/save/saveFormJSON')
                    this.$emit('close')
                    this.snackbarEventBus.$emit('snackbar', {
                        emoji: this.constants.EMOJI.HANDS_UP,
                        color: this.constants.SNACKBAR_CONSTANTS.COLORS.SECONDARY,
                        text: 'Changes Successfully Saved'
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .background-light {
        background-color: #F5F6F8;
    }

    .form-settings-header, .form-settings-content, .form-settings-actions {
        padding: 0 20px;
    }

    .form-settings-header {
        padding-top: 10px;
    }
    .form-settings-content {
        width: calc(400px - 2 * 20px);
        overflow-y: auto;
        padding-top: 20px;
    }
    .form-settings-actions {
        flex-grow: 1;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
    }

</style>