<template>
    <AppBarTemplate>
        <template #left>
            <AppBarLogoComponent/>
        </template>
    </AppBarTemplate>
</template>

<script>
    export default {
        name: "AppBarEmpty",
        components: {
            AppBarTemplate: () => import('../AppBarTemplate'),
            AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
        }
    }
</script>
