<template>
    <AppBarTemplate>
        <template #left>
            <BackToTypeSelectionComponent/>
        </template>
        <template #center>
            <AppBarLogoComponent/>
        </template>
        <template #right>
            <XBtnToDashboard/>
        </template>
    </AppBarTemplate>
</template>

<script>
    export default {
        name: "AppBarBackToDashboard",
        components: {
            AppBarLogoComponent: () => import('../Elements/AppBarLogoComponent'),
            AppBarTemplate: () => import('../AppBarTemplate'),
            BackToTypeSelectionComponent: () => import('../Elements/BackToTypeSelectionComponent'),
            XBtnToDashboard: () => import('../Elements/XBtnToDashboard'),
        }
    }
</script>

<style scoped>

</style>