import ShortAnswer from '@/components/Fields/FieldsViews/ShortAnswer'
import Paragraph from '@/components/Fields/FieldsViews/Paragraph'
import Number from '@/components/Fields/FieldsViews/Number'
import Phone from '@/components/Fields/FieldsViews/Phone'
import Email from '@/components/Fields/FieldsViews/Email'
import Datetime from '@/components/Fields/FieldsViews/Datetime'

import SingleSelection from '@/components/Fields/FieldsViews/SingleSelection'
import MultipleSelection from '@/components/Fields/FieldsViews/MultipleSelection'
import Dropdown from '@/components/Fields/FieldsViews/Dropdown'
import ImageChoice from '@/components/Fields/FieldsViews/ImageChoice'

import Slider from '@/components/Fields/FieldsViews/Slider'
import OpinionScale from '@/components/Fields/FieldsViews/OpinionScale'
import StarRating from '@/components/Fields/FieldsViews/StarRating'
import Matrix from '@/components/Fields/FieldsViews/Matrix'

import Text from '@/components/Fields/FieldsViews/Text'
import Image from '@/components/Fields/FieldsViews/Image'
import Signature from '@/components/Fields/FieldsViews/Signature'
import Address from '@/components/Fields/FieldsViews/Address'
import FileUpload from '@/components/Fields/FieldsViews/FileUpload'
import SmartParagraph from '@/components/Fields/FieldsViews/SmartParagraph'

import constants from "./constants";
import {getRangedLetterOrNumberRegex} from "@/utils/misc";
import { FieldTypeBasePropertiesConst } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertySelectInput,
    FieldPropertyStringInput
} from '@/components/Fields/FieldConstClasses/field_property_input'

const FIELD_TYPES = Object.freeze({
    SHORT_ANSWER: ShortAnswer,
    PARAGRAPH: Paragraph,
    NUMBER: Number,
    PHONE: Phone,
    EMAIL: Email,
    DATETIME: Datetime,

    SINGLE_SELECTION: SingleSelection,
    MULTIPLE_SELECTION: MultipleSelection,
    DROPDOWN: Dropdown,
    IMAGE_CHOICE: ImageChoice,

    SLIDER: Slider,
    OPINION_SCALE: OpinionScale,
    STAR_RATING: StarRating,
    MATRIX: Matrix,

    TEXT: Text,
    IMAGE: Image,
    SIGNATURE: Signature,
    ADDRESS: Address,
    FILE_UPLOAD: FileUpload,
    SMART_PARAGRAPH: SmartParagraph
})

const ALL_FIELD_TYPES = Object.values(FIELD_TYPES)
const FIELD_TYPES_BY_GROUPS = [
    [
        FIELD_TYPES.SHORT_ANSWER,
        FIELD_TYPES.PARAGRAPH,
        FIELD_TYPES.NUMBER,
        FIELD_TYPES.PHONE,
        FIELD_TYPES.EMAIL,
        FIELD_TYPES.DATETIME,
    ],
    [
        FIELD_TYPES.SINGLE_SELECTION,
        FIELD_TYPES.MULTIPLE_SELECTION,
        FIELD_TYPES.DROPDOWN,
        FIELD_TYPES.IMAGE_CHOICE
    ],
    [
        FIELD_TYPES.SLIDER,
        FIELD_TYPES.OPINION_SCALE,
        FIELD_TYPES.STAR_RATING,
        FIELD_TYPES.MATRIX
    ],
    [
        FIELD_TYPES.TEXT,
        FIELD_TYPES.IMAGE,
        FIELD_TYPES.SIGNATURE,
        FIELD_TYPES.ADDRESS,
        FIELD_TYPES.FILE_UPLOAD,
        FIELD_TYPES.SMART_PARAGRAPH
    ]
]

const FIELD_FROM_TYPE = Object.freeze(Object.fromEntries(Object.values(FIELD_TYPES).map(value => [value.FIELD_TYPE, value])))

const STATIC_PAGE_SUBMIT_BUTTON = {
    BASE_PROPERTIES: new FieldTypeBasePropertiesConst([
        new CustomFieldPropertyConst({
            key: 'button_text',
            name: 'Button text',
            always_enabled: true,
            enabled_by_default: true,
            input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                new FieldPropertyStringInput({
                    default: 'Submit',
                    validator: v => getRangedLetterOrNumberRegex({
                        expression: constants.ANY_LETTER_OR_NUMBER_REGEX,
                        from: 0,
                        to: 20,
                        isSpaceAllowed: true,
                    }).test(v)
                })
            )
        }),
        new CustomFieldPropertyConst({
            key: 'on_click_action',
            name: 'On click action',
            caption: 'This is basically the form submission',
            always_enabled: true,
            enabled_by_default: true,
            input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                new FieldPropertySelectInput({
                    items: [
                        {key: 'submit_form', readable_name: 'Submit form'},
                        {key: 'go_to_next_page', readable_name: 'Go to next page'},
                    ]
                })
            )
        })
    ])
}
STATIC_PAGE_SUBMIT_BUTTON.TEMPLATE = STATIC_PAGE_SUBMIT_BUTTON.BASE_PROPERTIES.generatePropertiesObj

export default {
    FIELD_TYPES,
    ALL_FIELD_TYPES,
    FIELD_FROM_TYPE,
    STATIC_PAGE_SUBMIT_BUTTON,
    FIELD_TYPES_BY_GROUPS
}