var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "date-time-field"
  }, [_c('FormattedDateTimeField', {
    attrs: {
      "layout": _vm.fieldConst.UTIL.GEN_INPUT_LAYOUT(_vm.fieldObj),
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('CalendarIcon')];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }