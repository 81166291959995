var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "8",
      "height": "12",
      "viewBox": "0 0 10 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1 8.99994L5 12.9999L9 8.99994",
      "stroke": _vm.asc ? _vm.selectedColor : _vm.neutralColor,
      "stroke-width": "1.33",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M9 4.99994L5 0.999938L1 4.99994",
      "stroke": _vm.desc ? _vm.selectedColor : _vm.neutralColor,
      "stroke-width": "1.33",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }