var render = function () {
  var _vm$submissionObj, _vm$submissionObj2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slider-field-view"
  }, [_c('div', {
    staticClass: "slider-field-view__value"
  }, [_vm._v(" " + _vm._s(((_vm$submissionObj = _vm.submissionObj) === null || _vm$submissionObj === void 0 ? void 0 : _vm$submissionObj.value) || _vm.from) + " ")]), _c('v-slider', {
    staticClass: "slider-field-view__slider",
    attrs: {
      "value": ((_vm$submissionObj2 = _vm.submissionObj) === null || _vm$submissionObj2 === void 0 ? void 0 : _vm$submissionObj2.value) || 0,
      "track-color": "none",
      "track-fill-color": "none",
      "thumb-color": "none",
      "disabled": _vm.editable || _vm.preview,
      "hide-details": "",
      "dense": "",
      "step": _vm.scaleUnits,
      "min": !_vm.editable ? _vm.from : 0,
      "max": _vm.to
    },
    on: {
      "input": function ($event) {
        _vm.submissionObj.value = $event;
      }
    }
  }), _vm.labels ? _c('div', {
    staticClass: "slider-field-view__labels"
  }, [_c('f-simple-contenteditable-input', {
    staticClass: "slider-field-view__label-from",
    attrs: {
      "readonly": !_vm.editable,
      "placeholder": _vm.from.toLocaleString()
    },
    model: {
      value: _vm.labels.left_label,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "left_label", $$v);
      },
      expression: "labels.left_label"
    }
  }), _c('f-simple-contenteditable-input', {
    staticClass: "slider-field-view__label-to",
    attrs: {
      "readonly": !_vm.editable,
      "placeholder": _vm.to.toLocaleString()
    },
    model: {
      value: _vm.labels.right_label,
      callback: function ($$v) {
        _vm.$set(_vm.labels, "right_label", $$v);
      },
      expression: "labels.right_label"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }