<script>
    import { VDivider } from 'vuetify/lib/components'

    export default {
        functional: true,
        name: "DividedDisclaimer",
        render: h => h(
            'div',
            {class: 'divided-disclaimer'},
            [
                h(VDivider, {class: 'divided-disclaimer__divider'}),
                h('div', {class: 'divided-disclaimer__hint'}, [
                    h('p', null, 'Prices shown are exclusive of any applicable sales taxes, such as VAT.'),
                    h('p', null, 'Pro subscriptions renew automatically at the end of each billing cycle unless terminated, at which point your credit card will be charged.'),
                ])
            ]
        )
    }
</script>

<style lang="scss">
    .divided-disclaimer {
        &__divider {
            margin: 25px 0;
            width: 193px;
        }

        &__hint {
            font-size: 12px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: -0.04em;
            color: var(--v-gray_400-base);
        }
    }
</style>