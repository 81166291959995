var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editable-field-property__caption"
  }, [_vm._v(" You can hide this question for now. "), _c('BlockVisibilityIsControlledByConditionalLogicCaption', _vm._b({}, 'BlockVisibilityIsControlledByConditionalLogicCaption', _vm.$attrs, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }