<template>
    <div class="selection-field-view selection-field-view--full-single-answer">
        <div class="selection-field-view__layout">
            <p v-if="!submissionObj.value.length">-</p>
            <p
                    v-for="optionIndex in [...submissionObj.value].sort()"
                    :key="optionIndex"
            >
                {{ sourceObj.content.options[optionIndex] }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FullSingleAnswer__GenericSelection",
        props: {
            submissionObj: {
                type: Object,
                required: true
            },
            sourceObj: {
                type: Object,
                required: true
            }
        }
    }
</script>