import store from '@/store'
import constants from '@/constants/constants'
import { inject, provide, computed } from '@vue/composition-api'

const useSubmittableFieldViewExtension = props => {
    const fieldProperties = props.sourceObj.properties
    const fieldConst = constants.FIELD_FROM_TYPE[props.sourceObj.field_type]

    const formSubmitStoreModulePath = inject('formSubmitStoreModulePath')

    const beforeSubmit = func => {
        store.dispatch(`${formSubmitStoreModulePath}/registerBeforeSubmitHook`, {hookID: props.fieldId, hook: func})
    }

    const isFormStatic = computed(() => store.getters[formSubmitStoreModulePath + '/getFormSourceJSON']?.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE)
    const isFormInteractive = computed(() => store.getters[formSubmitStoreModulePath + '/getFormSourceJSON']?.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE)

    provide('isFormStatic', isFormStatic)
    provide('isFormInteractive', isFormInteractive)

    return {
        fieldProperties,
        fieldConst,
        beforeSubmit,
        isFormStatic,
        isFormInteractive,
        formSubmitStoreModulePath
    }
}

const submittable_field_view_mixin = {
    props: {
        fieldId: {
            type: String,
            required: true
        },
        sourceObj: {
            type: Object,
            required: true
        },
        submissionObj: {
            type: Object
        },
        fieldWidth: Number
    }
}

export default {
    ...submittable_field_view_mixin,
    setup(props) {
        return {
            ...useSubmittableFieldViewExtension(props)
        }
    }
}

export {
    submittable_field_view_mixin,
    useSubmittableFieldViewExtension
}