import constants from '@/constants/constants'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { onScopeDispose } from '@vue/composition-api'


export default ({useExtension}) => {
    {
        const state = {
            rules: {}
        }
        useExtension(SMAGableExtensions.SMable, {state})
    }

    let vuelidateInstance

    onScopeDispose(() => {
        vuelidateInstance = null
    })

    return {
        actions: {
            registerVuelidateInstance: (_, $v) => vuelidateInstance = $v,
            unregisterVuelidateInstance: () => vuelidateInstance = undefined,

            setRules: ({commit, getters, parentGetters}) => {
                commit('SET_RULES', Object.fromEntries(
                        Object.keys(getters.getVuelidateState)
                            .map(fieldID => [fieldID, constants.FIELD_FROM_TYPE[parentGetters.getFormSourceJSON.fields[fieldID].field_type]])
                            .filter(([, fieldConst]) => fieldConst.SUBMIT_VALIDATION)
                            .map(([fieldID, fieldConst]) => [fieldID, fieldConst.SUBMIT_VALIDATION.genRules(parentGetters.getFormSourceJSON.fields[fieldID])])
                    )
                )
            },

            validateField: (_, fieldID) => (vuelidateInstance?.value && fieldID in vuelidateInstance.value) ? vuelidateInstance.value[fieldID]?.$validate() : true
        },
        getters: {
            getVuelidateRules: ({state}) => state.rules,
            getVuelidateState: ({parentGetters}) => parentGetters['submission/getFormSubmission']
        }
    }
}