var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.inspectingSubmission ? _c('div', {
    staticClass: "splitted-analytics-table"
  }, [_c('div', {
    staticClass: "splitted-analytics-table__items-list"
  }, [_c('div', {
    staticClass: "splitted-analytics-table__items-header"
  }, [_c('v-simple-checkbox', {
    attrs: {
      "color": "blue_400",
      "value": _vm.areAllSubmissionsSelected,
      "ripple": false
    },
    on: {
      "click": _vm.toggleAllSubmissionsSelection
    }
  }), _c('span', [_vm._v("Submissions")])], 1), _c('div', {
    ref: "scrollContainer",
    style: {
      height: '800px',
      overflowY: 'scroll'
    },
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm._l(_vm.filteredSubmissions, function (submission) {
    return _c('div', {
      key: submission.submissionID,
      staticClass: "splitted-analytics-table__item",
      class: {
        'splitted-analytics-table__item--inspecting': _vm.inspectingSubmission.submissionID === submission.submissionID
      },
      on: {
        "click": function ($event) {
          return _vm.setInspectingSubmission(submission);
        }
      }
    }, [_c('v-simple-checkbox', {
      attrs: {
        "color": "blue_400",
        "value": !!_vm.selectedSubmissions.find(function (s) {
          return s.submissionID === submission.submissionID;
        }),
        "ripple": false
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.toggleSubmissionSelection(submission);
        }
      }
    }), _c('div', {
      staticClass: "splitted-analytics-table__item__preview"
    }, [_c('span', {
      staticClass: "splitted-analytics-table__item__title"
    }, [submission.loading.value ? _c('v-skeleton-loader', {
      attrs: {
        "type": "heading",
        "width": "100"
      }
    }) : _c('span', [_vm._v(_vm._s(submission.submissionTitle))])], 1), _c('span', {
      staticClass: "splitted-analytics-table__item__subtitle"
    }, [submission.loading.value ? _c('v-skeleton-loader', {
      attrs: {
        "type": "text",
        "width": Math.random() * 50 + 100
      }
    }) : _c('span', [_vm._v(" " + _vm._s(_vm.getDateFormated(submission.submissionDate)) + " ")])], 1)])], 1);
  }), _vm.getIsSubmissionsListExtendingInProgress ? _c('f-circle-loader', {
    attrs: {
      "size": "3"
    }
  }) : _vm._e()], 2)]), _c('div', {
    key: 'inspecting-item-' + _vm.inspectingSubmission.submissionID + _vm.inspectingSubmission.loading.value,
    staticClass: "splitted-analytics-table__inspecting-item"
  }, [!_vm.selectedSubmissions.length ? _c('div', {
    staticClass: "splitted-analytics-table__inspecting-item__actions"
  }, [_c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Download submission"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--primary v-btn--thin-icon",
          attrs: {
            "icon": "",
            "disabled": _vm.isAnyLoading,
            "href": _vm.inspectingSubmissionObjUrl,
            "download": _vm.inspectingSubmission.submissionTitle
          }
        }, on), [_c('DownloadIcon')], 1)];
      }
    }], null, false, 3032424983)
  }), _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Print submission"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--primary v-btn--thin-icon",
          attrs: {
            "icon": "",
            "disabled": _vm.isAnyLoading
          },
          on: {
            "click": _vm.printInspectingSubmission
          }
        }, on), [_c('PrinterIcon')], 1)];
      }
    }], null, false, 3405788648)
  }), _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Delete"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--error v-btn--thin-icon",
          attrs: {
            "icon": "",
            "disabled": _vm.isAnyLoading
          },
          on: {
            "click": _vm.deleteInspectingSubmission
          }
        }, on), [_c('TrashIcon')], 1)];
      }
    }], null, false, 95139630)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "splitted-analytics-table__inspecting-item__section"
  }, [_c('p', {
    staticClass: "splitted-analytics-table__inspecting-item__section__header"
  }, [_vm.inspectingSubmission.loading.value ? _c('v-skeleton-loader', {
    attrs: {
      "width": "100",
      "type": "heading"
    }
  }) : _c('span', [_vm._v("Submission Details")])], 1), _c('span', [_vm.inspectingSubmission.loading.value ? _c('v-skeleton-loader', {
    attrs: {
      "width": Math.random() * 50 + 150,
      "type": "text"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.inspectingSubmission.submissionDate.format('M/D/YYYY, hh:mm A')))])], 1)]), _vm.inspectingSubmission.loading.value ? _vm._l(Object.keys(_vm.$store.getters['edit/getFormJSON'].fields), function (fieldID) {
    return _c('div', {
      key: fieldID,
      staticClass: "splitted-analytics-table__inspecting-item__section"
    }, [_c('p', {
      staticClass: "splitted-analytics-table__inspecting-item__section__header"
    }, [_c('v-skeleton-loader', {
      attrs: {
        "width": "100",
        "type": "heading"
      }
    })], 1), _c('v-skeleton-loader', {
      attrs: {
        "width": Math.random() * 50 + 150,
        "type": "text"
      }
    })], 1);
  }) : [_vm._l(Object.entries(_vm.$store.getters['submissions/getVersionedFormSources'][_vm.inspectingSubmission.publishedVersionID].fields).filter(function (_ref4) {
    var _fieldID = _ref4[0];
    return _vm.inspectingSubmission[_fieldID];
  }), function (_ref5) {
    var fieldID = _ref5[0],
      fieldData = _ref5[1];
    return [_c('InspectingItemSection', {
      key: fieldID,
      attrs: {
        "field-title": fieldData.title || '-',
        "component-view": _vm.inspectingSubmission[fieldID].fieldTypeConst.ANALYTICS.VIEWS.FULL_SINGLE_ANSWER_VIEW,
        "submission-obj": _vm.inspectingSubmission[fieldID].submissionObj,
        "source-obj": _vm.inspectingSubmission[fieldID].sourceObj
      }
    })];
  })]], 2)]) : _c('span', [_vm._v("Nothing to display")]), Object.values(_vm.$store.getters['submissions/getVersionedFormSources']).length ? _c('Teleport', {
    attrs: {
      "to": "body"
    }
  }, [_c('div', {
    attrs: {
      "id": "analytics-print-view"
    }
  }, _vm._l(_vm.submissionsToPrint, function (submission, index) {
    return _c('div', {
      key: index,
      staticClass: "splitted-analytics-table splitted-analytics-table--print-view"
    }, [_c('div', {
      staticClass: "splitted-analytics-table__inspecting-item__section"
    }, [_c('p', {
      staticClass: "splitted-analytics-table__inspecting-item__section__header"
    }, [_c('span', [_vm._v("Submission Details")])]), _c('span', [_vm._v(_vm._s(submission.submissionDate.format('M/D/YYYY, hh:mm A')))])]), _vm._l(Object.entries(_vm.$store.getters['submissions/getVersionedFormSources'][submission.publishedVersionID].fields).filter(function (_ref6) {
      var _fieldID = _ref6[0];
      return submission[_fieldID];
    }), function (_ref7) {
      var fieldID = _ref7[0],
        fieldData = _ref7[1];
      return [_c('InspectingItemSection', {
        key: fieldID,
        attrs: {
          "field-title": fieldData.title || '-',
          "component-view": submission[fieldID].fieldTypeConst.ANALYTICS.VIEWS.FULL_SINGLE_ANSWER_VIEW,
          "submission-obj": submission[fieldID].submissionObj,
          "source-obj": submission[fieldID].sourceObj
        }
      })];
    })], 2);
  }), 0)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }