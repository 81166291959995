import Utils from '@/utils/misc'
import deepEqual from 'deep-equal'
import FSPThemesActions from '@/utils/actions/fsp_themes_actions'
import {generateNewThemeObj} from '@/store/form_edit/modules/form_edit_fsp_themes_store'
import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import { SMAGableExtensions } from '@/xstore/utils/smagable'

import { VBtn, VSpacer } from 'vuetify/lib/components'

export default ({useExtension}) => {
    {
        const state = {
            current_editing_theme_initial_copy: undefined,
            current_editing_theme: undefined
        }

        useExtension(SMAGableExtensions.SGable, {state})
    }

    return {
        modules: {
            save_theme_with_related_forms_confirmation_dialog: generic_dialog_module
        },
        mutations: {
            SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY: (state, themeObj) => {
                state.current_editing_theme_initial_copy = Utils.deepCloneObject(themeObj)
                state.current_editing_theme = Utils.deepCloneObject(themeObj)
            },
            SET_CURRENT_EDITING_THEME_WITHOUT_INITIAL_COPY: (state, themeObj) => state.current_editing_theme = Utils.deepCloneObject(themeObj),
            SET_CURRENT_EDITING_THEME: (state, theme) => state.current_editing_theme = Utils.deepCloneObject(theme)
        },
        actions: {
            startPublicThemeEditing: ({commit}, publicThemeObj) => commit('SET_CURRENT_EDITING_THEME', {
                ...publicThemeObj,
                id: null,
                name: publicThemeObj.name + ' (Copy)'
            }),
            startNewPrivateThemeEditing: ({commit}) => commit('SET_CURRENT_EDITING_THEME', generateNewThemeObj()),
            startPrivateThemeEditing: ({commit, parentDispatch}, themeObj) => {
                commit('SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY', themeObj)
                parentDispatch('selected/selectTheme', themeObj)
            },

            quitThemeEditor: ({commit, getters, parentDispatch}) => {
                if (getters.getCurrentEditingThemeID) {
                    parentDispatch('selected/selectTheme', {id: getters.getCurrentEditingThemeID})
                    parentDispatch('selected/setCurrentThemesStoreTab', 1)
                }
                commit('SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY', undefined)
            },
            updateCurrentEditingThemeName: ({commit, getters}, newName) => commit('SET_CURRENT_EDITING_THEME', {
                ...getters.getCurrentEditingTheme,
                name: newName
            }),
            updateCurrentEditingThemeStyle: ({commit, getters}, stylesToUpdate) => commit('SET_CURRENT_EDITING_THEME', {
                ...getters.getCurrentEditingTheme,
                rawData: {
                    ...getters.getCurrentEditingTheme.rawData,
                    ...stylesToUpdate
                }
            }),

            saveNewPrivateTheme: ({commit, getters, parentGetters, parentCommit}) => FSPThemesActions.createTheme(getters.getCurrentEditingTheme)
                .then(savedThemeObj => {
                    parentCommit('SET_PRIVATE_THEMES', [savedThemeObj, ...parentGetters.getPrivateThemes])
                    commit('SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY', savedThemeObj)
                }),
            savePrivateThemeChanges: ({commit, getters, parentGetters, parentDispatch, dispatch, parentCommit, rootGetters}, callWhenUploadingStart) => {
                const confirmSaving = () => callWhenUploadingStart(FSPThemesActions.updateTheme(getters.getCurrentEditingTheme)
                    .then(updatedThemeObj => {
                        parentCommit('SET_PRIVATE_THEMES', parentGetters.getPrivateThemes.map(themeObj => themeObj.id === updatedThemeObj.id ? updatedThemeObj : themeObj))
                        commit('SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY', updatedThemeObj)
                    }))

                const saveAsNewTheme = () => callWhenUploadingStart(FSPThemesActions.createTheme({
                    name: getters.getCurrentEditingTheme.name + ' (Copy)',
                    rawData: getters.getCurrentEditingTheme.rawData
                })
                    .then(createdThemeObj => {
                        parentCommit('SET_PRIVATE_THEMES', [createdThemeObj, ...parentGetters.getPrivateThemes])
                        parentDispatch('selected/selectTheme', createdThemeObj)
                        commit('SET_CURRENT_EDITING_THEME_WITH_INITIAL_COPY', createdThemeObj)
                    }))

                if (getters.getCurrentEditingTheme.relatedForms.some(({id}) => id !== rootGetters['edit/getFormDBData'].id))
                    dispatch('save_theme_with_related_forms_confirmation_dialog/showDialog', ({closeDialog}) => ({
                        title: 'Update theme for other forms too?',
                        content: 'You\'re using this theme with others forms, so any changes will happen on the other forms too – is that okay? You can always save this as a new theme.',
                        actions: {
                            functional: true,
                            render: h => [
                                h(VBtn, {props: {color: 'gray_400', outlined: true, rounded: true}, on: {
                                        click: () => {
                                            saveAsNewTheme()
                                            closeDialog()
                                        }
                                    }
                                }, 'Save new theme'),
                                h(VSpacer),
                                h(VBtn, {props: {color: 'gray_100', rounded: true}, on: {click: closeDialog}}, 'Not now'),
                                h(VBtn, {props: {color: 'blue_400', rounded: true}, on: {
                                        click: () => {
                                            confirmSaving()
                                            closeDialog()
                                        }
                                    }
                                }, 'Update theme')
                            ]
                        },
                        props: {
                            withCloseBtn: true
                        }
                    }))
                else
                    confirmSaving()
            },
        },
        getters: {
            isThemeEditorActive: ({getters}) => !!getters.getCurrentEditingTheme,
            getCurrentEditingThemeID: ({getters}) => getters.getCurrentEditingTheme?.id,
            getCurrentEditingThemeStyles: ({getters}) => getters.getCurrentEditingTheme?.rawData,
            isCurrentEditingThemeChanged: ({getters}) => !deepEqual(getters.getCurrentEditingThemeInitialCopy, getters.getCurrentEditingTheme),
            isCurrentEditingThemeAlreadyExist: ({getters, parentGetters}) => !!parentGetters.getPrivateThemes.find(({id}) => id === getters.getCurrentEditingThemeID),
        }
    }
}