import {FieldTypeConst} from '@/components/Fields/FieldConstClasses/field_type_const'
import {GenericNumberConditionalLogic} from '@/components/Fields/FieldConstClasses/conditional_logic'
import {Analytics, GenericTextAnalytics} from '@/components/Fields/FieldConstClasses/analytics'
import {FieldTypeBasePropertiesConstWithRequired} from '@/components/Fields/FieldConstClasses/base_properties'
import {CustomFieldPropertyConst} from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithComplexConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertySelectInput,
    FieldPropertyStringInput
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {FieldFSPDefinition} from "../../FieldConstClasses/fsp_field_definition";
import FSP from '@/constants/fsp_constants';
import {FieldSubmitValidation} from '@/components/Fields/FieldConstClasses/submit_validation'
import constants from "@/constants/constants";
import {getRangedLetterOrNumberRegex} from "@/utils/misc";

class OpinionScaleFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'OPINION_SCALE',
            READABLE_NAME: 'Opinion scale',
            ICON_NAME: 'opinion_scale',
            FOLDER_NAME: 'OpinionScale',

            STATIC_FORM_MIN_WIDTH: 2,
            FSP: new FieldFSPDefinition({
                FSP_FIELD_TYPE: 'OpinionScale',
                FIELD_VIEW_CLASS: 'opinion-scale-field-view',
                GENERIC_STATES_CONNECTORS: {
                    'selected': {
                        internal: '.opinion-scale-field-view__tile-wrapper.opinion-scale-field-view__tile-wrapper--selected',
                    },
                    'hovered': {
                        internal: '.opinion-scale-field-view__tile-wrapper:hover'
                    },
                },
                VARIABLES: [
                    {
                        selector: ['wrapper'],
                        properties: {
                            'max-width': {
                                default: {
                                    fn: '800px'
                                }
                            }
                        }
                    },
                    {
                        selector: ['range'],
                        properties: {
                            'margin-bottom': {
                                default: {
                                    fn: '5px',
                                }
                            },
                            'column-gap': {
                                default: {
                                    fn: '8px',
                                }
                            },
                            'row-gap': {
                                default: {
                                    fn: '10px',
                                }
                            }
                        }
                    },
                    {
                        selector: ['tile'],
                        properties: {
                            cursor: {
                                'default--submittable': {
                                    fn: 'pointer',
                                }
                            },
                            width: {
                                default: {
                                    fn: '60px',
                                }
                            },
                            height: {
                                default: {
                                    fn: '60px'
                                }
                            },
                            'font-size': {
                                default: {
                                    fn: '20px',
                                }
                            },
                            'font-weight': {
                                default: {
                                    fn: '600',
                                },
                            },
                            'border-radius': {
                                default: {
                                    fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                                        px: true,
                                        max: 12,
                                    }),
                                },
                            },
                            'box-shadow': {
                                'selected--interactive': {
                                    fn: `0 0 0 2px ${FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()}`,
                                },
                                'selected--static': {
                                    fn: `0 0 0 2px ${FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply()}`,
                                },
                            },
                            'background-color': {
                                'default--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.1}),
                                },
                                'hovered--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply({opacity: 0.5}),
                                },
                                'selected--interactive': {
                                    fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_IMAGE_IS_LIGHT.apply({
                                        asColors: {
                                            true: [255, 255, 255, 0.2],
                                            false: [0, 0, 0, 0.2]
                                        }
                                    }),
                                },
                                'default--static': {
                                    fn: 'rgba(0, 0, 0, 0.05)',
                                },
                                'hovered--static': {
                                    fn: 'rgba(0, 0, 0, 0.09)',
                                },
                                'selected--static': {
                                    fn: 'none',
                                },
                            },
                            color: {
                                'default--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                                },
                                // 'default--static': {
                                //     fn: '#000',
                                //     connector: {
                                //         ...FSP.GENERIC_FIELD_CLASS.applyAsFSPConnector({static: true})
                                //     }
                                // },
                            },
                        }
                    },
                    {
                        selector: ['label'],
                        properties: {
                            color: {
                                'default--interactive': {
                                    fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply()
                                },
                                'default-static': {
                                    fn: '#7c7c7c',
                                },
                            },
                            'font-size': {
                                default: {
                                    fn: '14px',
                                },
                            }
                        }
                    },
                ]
            }),

            CONDITIONAL_LOGIC: new GenericNumberConditionalLogic({
                VALIDATE: (conditionObj, submissionObj, sourceObj) => GenericNumberConditionalLogic.DEFAULT_VALIDATE(conditionObj, {value: submissionObj.value + parseInt(sourceObj.properties.scale_poles.from)}),
                PARAMS: {
                    ALLOWED_RANGE: fieldObj => Object.values(fieldObj.properties.scale_poles),
                },
            }),

            SUBMISSION_TEMPLATE: () => ({
                value: null
            }),

            IS_SUBMISSION_EMPTY: ({value}) => value === null,

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: FieldSubmitValidation.REQUIRED(fieldObj)
                })
            }),

            AUTO_SCROLL_HANDLER: submissionObj => submissionObj.value !== null,

            ANALYTICS: new Analytics({
                FILTER: GenericTextAnalytics.DEFAULT_FILTER,
                FORMAT_TO_CSV_EXPORT: (submissionObj, fieldObj) => {
                    return submissionObj.value ?
                        `${submissionObj.value + fieldObj.properties.scale_poles.from}/${fieldObj.properties.scale_poles.to}`
                        : submissionObj.value;
                },
                SORT: ({value: a}, {value: b}) => a < b ? a > b ? 0 : -1 : 1,
                SHORT_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/OpinionScale/Analytics/GenericSingleAnswer'),
                FULL_SINGLE_ANSWER_VIEW: () => import('@/components/Fields/FieldsViews/OpinionScale/Analytics/GenericSingleAnswer')
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'scale_poles',
                    name: 'Scale Poles',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        from: new FieldPropertySelectInput({
                            label: 'From',
                            half_width: true,
                            items: [
                                {key: 0, readable_name: 0},
                                {key: 1, readable_name: 1},
                            ]
                        }),
                        to: new FieldPropertySelectInput({
                            label: 'To',
                            half_width: true,
                            default: 10,
                            items: [
                                {key: 5, readable_name: 5},
                                {key: 6, readable_name: 6},
                                {key: 7, readable_name: 7},
                                {key: 8, readable_name: 8},
                                {key: 9, readable_name: 9},
                                {key: 10, readable_name: 10},
                            ]
                        })
                    })
                }),
                new CustomFieldPropertyConst({
                    key: 'labels',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        start: new FieldPropertyStringInput({
                            dynamic_label: (s, p, fieldProps) => `label ${fieldProps.scale_poles.from}`,
                            validator: v => getRangedLetterOrNumberRegex({
                                expression: constants.ANY_LETTER_OR_NUMBER_REGEX,
                                from: 0,
                                to: 15,
                                isSpaceAllowed: true,
                            }).test(v),
                        }),
                        end: new FieldPropertyStringInput({
                            dynamic_label: (s, p, fieldProps) => `label ${fieldProps.scale_poles.to}`,
                            validator: v => getRangedLetterOrNumberRegex({
                                expression: constants.ANY_LETTER_OR_NUMBER_REGEX,
                                from: 0,
                                to: 15,
                                isSpaceAllowed: true,
                            }).test(v),
                        })
                    })
                })
            ])
        })
    }
}

export default new OpinionScaleFieldTypeConst()