import GenericSelectionPreviewFieldView
    from "@/components/Fields/GenericFieldViews/GenericSelectionFieldView/GenericSelectionPreviewFieldView";

export default {
    extends: GenericSelectionPreviewFieldView,
    name: "Preview__SingleSelection",
    props: {
        radio: {
            type: Boolean,
            default: true,
        }
    }
}