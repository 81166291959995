<template>
    <div class="single-emoji-select-property">
        <div
                v-for="(emoji, index) in inputConst.emojis"
                class="single-emoji-select-property__item background-gray_100-selectable"
                :class="inputValue === index && 'background-gray_100-selectable--selected'"
                :key="index"
                @click="input(index)"
        >
            <LottieIconPlayer :json="emoji" active/>
        </div>
    </div>
</template>

<script>
    import field_property_input_mixin from '../Utils/field_property_input_mixin'

    export default {
        name: "SingleEmojiSelect",
        components: {
            LottieIconPlayer: () => import('@/components/Fields/FieldsViews/StarRating/Icons/LottieIconPlayer')
        },
        mixins: [field_property_input_mixin]
    }
</script>

<style lang="scss" scoped>
    .single-emoji-select-property {
        display: flex;
        justify-content: space-between;

        &__item {
            width: 46px;
            height: 46px;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;
            
            &:before {
                border-radius: 100px;
            }
        }
    }
</style>