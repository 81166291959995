var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visibilityControlledByConditionalLogic ? _c('AlertCircleIcon', {
    staticClass: "red_400--text mr-2",
    attrs: {
      "size": "16"
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }