var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SharedView', {
    attrs: {
      "options": _vm.sourceObj.content.options,
      "randomize-options": _vm.sourceObj.properties.randomize,
      "selected-options": _vm.selectedOptions
    },
    on: {
      "toggle-options": function ($event) {
        return _vm.toggleOptionSelection($event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }