<template>
    <div class="form-composition">
        <div id="static_form_elements_panel_button" :class="
                                                    ['form-composition-btn',
                                                      elementsPanelMenuModel && 'form-composition-btn--active',
                                                     'rounded-pill',
                                                     'white',
                                                     'mb-5',]"
        >
            <v-tooltip
                    v-model="elementsPanelTooltip"
                    nudge-right="60"
                    attach="#static_form_elements_panel_button"
                    allow-overflow
                    color="bluish_gray_500"
                    content-class="tooltip-right"
                    right
            >
                Add questions
            </v-tooltip>
            <f-hover-tooltip right
                             text="Add questions"
                             v-slot="{on}"
            >
                <v-btn v-on="elementsPanelTooltip ? undefined : on"
                       @click="elementsPanelOpen"
                       color="gray_3"
                       depressed
                       text
                       icon
                       x-large
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.8002 1.6667C10.8002 1.22487 10.442 0.866699 10.0002 0.866699C9.55836 0.866699 9.20019 1.22487 9.20019 1.6667V9.20002H1.6667C1.22487 9.20002 0.866699 9.55819 0.866699 10C0.866699 10.4418 1.22487 10.8 1.6667 10.8H9.20019V18.3334C9.20019 18.7752 9.55836 19.1334 10.0002 19.1334C10.442 19.1334 10.8002 18.7752 10.8002 18.3334V10.8H18.3334C18.7752 10.8 19.1334 10.4418 19.1334 10C19.1334 9.55819 18.7752 9.20002 18.3334 9.20002H10.8002V1.6667Z"
                              fill="currentColor"/>
                    </svg>
                </v-btn>
            </f-hover-tooltip>
        </div>
        <div :class="[
                      'form-composition-btn',
                      pagesMenuModel && 'form-composition-btn--active',
                      'rounded-pill',
                      'white']"
        >

            <f-hover-tooltip right
                             text="All pages"
                             v-slot="{on}"
            >
                <v-btn v-on="on"
                       @click="pagesMenuModel = true"
                       color="gray_3"
                       depressed
                       text
                       icon
                       x-large
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z"
                              stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z"
                              stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </v-btn>
            </f-hover-tooltip>
        </div>
        <v-menu
                v-model="elementsPanelMenuModel"
                :close-on-content-click="false"
                :close-on-click="true"
                attach=".form-composition"
                content-class="form-composition-menu"
                eager
                min-width="340"
                nudge-right="80"
                right
                transition="slide-y-transition"
        >
            <StaticFormElementsPanel/>
        </v-menu>
        <v-menu
                v-model="pagesMenuModel"
                :close-on-content-click="false"
                :close-on-click="true"
                attach=".form-composition"
                content-class="form-composition-menu"
                eager
                min-width="250"
                nudge-right="80"
                right
                transition="slide-y-transition"
        >
            <StaticFormPagesList/>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "StaticFormComposition",
        components: {
            StaticFormElementsPanel: () => import('./StaticFormElementsPanel'),
            StaticFormPagesList: () => import('./StaticFormPagesList')
        },
        data: () => ({
            elementsPanelMenuModel: false,
            pagesMenuModel: false,

            elementsPanelTooltip: false
        }),
        watch: {
            elementsPanelMenuModel(v) {
                if (v)
                    this.pagesMenuModel = false
            },
            pagesMenuModel(v) {
                if (v)
                    this.elementsPanelMenuModel = false
            },
        },
        mounted() {
            if (this.SequenceDirector.getSequence(this.constants.NEW_FORM_TUTORIAL)) {
                this.SequenceDirector.setStepAction(this.constants.NEW_FORM_TUTORIAL, 1, () => {
                    this.elementsPanelTooltip = true
                })
            }
        },
        methods: {
            elementsPanelOpen() {
                this.elementsPanelMenuModel = true
                this.elementsPanelTooltip = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .static-form--layout-style--compact.invisible-header {
        padding-top: 100px;
    }
    // .visible-header .form-composition {
    //     display: block;
    // }
    
    // .invisible-header .form-composition {
    //     display: none;
    // }
    .form-composition {
        z-index: 10;
        position: fixed;
        top: 120px;
        left: 30px;

        .form-composition-btn {
            box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 2px 4px rgba(24, 39, 75, 0.08);
            transition: box-shadow .1s;

            &--active {
                box-shadow: 0px 8px 18px rgba(24, 39, 75, 0.2), 0px 2px 8px rgba(39, 63, 121, 0.09);
                background-color: var(--v-paler-base) !important;

                svg {
                    color: var(--v-blue_400-base);
                }
            }

            &:hover {
                box-shadow: 0px 8px 18px rgba(24, 39, 75, 0.12), 0px 2px 8px rgba(39, 63, 121, 0.09);
                background-color: var(--v-paler-base) !important;

                svg {
                    color: var(--v-blue_400-base)
                }
            }
        }

        .form-composition-menu {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
            overflow: visible;
            contain: unset !important;
        }
    }
</style>