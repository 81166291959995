<script>
  export default {
    name: 'GenericFormCreationLayout',
    props: {
      cards: {
        type: Array,
      },
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
      },
      captionAboveTheTitle: {
        type: String,
        required: true,
      },
    },
  }
</script>

<template>
  <v-container
    fluid
    class="d-flex align-center justify-center h-full"
  >
    <v-item-group>
      <v-container>
        <v-row>
          <v-col>
            <p
              class="text-center grey_500--text"
              style="
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
              "
            >
              {{ captionAboveTheTitle }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p
              class="text-h4 bluish_gray_500--text text-center"
              v-html="title"
            ></p>
          </v-col>
        </v-row>
        <v-row
          v-if="subtitle"
          class="mt-0 mb-10"
          justify="center"
        >
          <v-col>
            <p class="text-h6 gray_400--text font-weight-light text-center">
              {{ subtitle }}
            </p>
          </v-col>
        </v-row>
        <template v-if="cards">
          <v-row>
            <v-col
              v-for="(card, index) in cards"
              :key="index"
              cols="12"
              md="6"
            >
              <v-card
                :ripple="false"
                rounded
                flat
                width="17rem"
                class="type-card rounded-lg d-flex flex-column align-center justify-center"
                height="21rem"
                @click="() => card.onSelect?.()"
              >
                <component :is="card.imageComponent" />
                <v-card-title class="mb-3 bluish_gray_500--text">
                  {{ card.title }}
                </v-card-title>
                <v-card-subtitle class="font-weight-light gray_400--text px-3">
                  {{ card.subtitle }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <slot v-else />
      </v-container>
    </v-item-group>
  </v-container>
</template>

<style scoped lang="scss">
  .v-card__subtitle {
    max-width: 180px !important;
  }
</style>
