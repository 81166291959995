<template>
  <div class="file-upload-field-view">
    <div class="file-upload-field-view__wrapper">
      <div class="file-upload-field-view__layout-area">
        <slot></slot>
        <FileUploadArea
            v-if="showFileUploader"
            class="file-upload-field-view__file-input"
            :disabled="disabled"
            :max-files-count="1"
            :max-file-size="limitFileSize"
            matchBothMimeAndExtension
            @input="$emit('input', $event)"
        >
          <template #header>
            <span class="file-upload-field-view__file-input__header file-upload-field-view__file-input__header--desktop">Choose File(s) or Drag here</span>
            <span class="file-upload-field-view__file-input__header file-upload-field-view__file-input__header--mobile">Choose File(s)</span>
          </template>
          <template #subheader>
            <span class="file-upload-field-view__file-input__subheader file-upload-field-view__file-input__subheader--desktop">Upload or drag your file. Up to 1 file, max {{ limitFileSize }}mb</span>
            <span class="file-upload-field-view__file-input__subheader file-upload-field-view__file-input__subheader--mobile">Upload your file. Up to 1 file, max {{ limitFileSize }}mb</span>
          </template>
        </FileUploadArea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharedView__FileUpload',
  components: {
    FileUploadArea: () => import('@/components/Elements/FileInputs/FileUploadArea'),
  },
  props: {
    limitFileSize: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFileUploader: {
      type: Boolean,
      default: true,
    },
    acceptExtensions: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style scoped>

</style>