<template>
    <div class="checkout-choose-plan-billing-period-switcher">
        <span
                :class="[
                    'checkout-choose-plan-billing-period-switcher__label',
                    value === 'month' && 'checkout-choose-plan-billing-period-switcher__label--active-month'
                ]"
                @click="$emit('input', 'month')"
        >Monthly</span>
        <f-switch
                class="checkout-choose-plan-billing-period-switcher__switch"
                :value="value === 'year'"
                @input="v => $emit('input', v ? 'year' : 'month')"
        />
        <span
                :class="[
                    'checkout-choose-plan-billing-period-switcher__label',
                    value === 'year' && 'checkout-choose-plan-billing-period-switcher__label--active-year'
                ]"
                @click="$emit('input', 'year')"
        >Yearly <span class="checkout-choose-plan-billing-period-switcher__discount">-20%</span></span>
    </div>
</template>

<script>
    export default {
        name: "BillingPeriodSwitcher",
        props: {
            value: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-choose-plan-billing-period-switcher {
        display: flex;
        align-items: center;
        justify-content: center;

        &__label {
            cursor: pointer;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            color: var(--v-bluish_gray_300-base);

            &--active-year {
                color: #7B61FF;
            }
            &--active-month {
                color: var(--v-blue_400-base);
            }
        }

        &__discount {
            border: 1px solid #7B61FF;
            border-radius: 30px;
            color: #7B61FF;
            font-size: 9px;
            font-weight: 600;
            padding: 5px 6px;
        }

        &__switch {
            margin: 0 12px;

            ::v-deep input:checked + .f-switch__slider {
                background-color: #7B61FF;
            }
        }
    }
</style>