import FormSubmitActions from '@/utils/actions/form_submit_actions'

import router from "@/router"
import constants from "@/constants/constants"

import form_submit_progress_bar from './modules/form_submit_progress_bar'
import form_submit_fields_validation from '@/store/form_submit/modules/form_submit_fields_validation'
import form_submit_fsp_theme from '@/store/form_submit/modules/form_submit_fsp_theme'
import { useFSPStoreModule } from '@/FSP/fsp_store_module'
import form_submit_conditional_logic_store from './modules/form_submit_conditional_logic_store'
import form_submit_submission_metrics_store from './modules/form_submit_submission_metrics_store'

import { useFieldsEnumerationGettersExtension } from '@/store/util_modules/fields_enumeration_getters'
import { isEmpty } from 'lodash'
import { ResettableExtensions } from '@/xstore/utils/resettable'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import form_submit_submission_store from '@/store/form_submit/modules/form_submit_submission_store'

export default ({ useExtension }) => {
  {
    const state = {
      form_data: undefined,
      form_source_json: undefined,
      current_page_id: undefined
    }
    useExtension(SMAGableExtensions.SMable, { state })
  }

  {
    const state = {
      form_version_id: undefined
    }
    useExtension(SMAGableExtensions.SMAGable, { state })
  }

  {
    const state = {
      with_submit: undefined
    }
    useExtension(SMAGableExtensions.SMGable, { state })
  }

  {
    const state = {
      before_submit_hooks: {}
    }
    useExtension(SMAGableExtensions.SGable, { state })
  }

  useExtension(ResettableExtensions.Default)
  useExtension(useFieldsEnumerationGettersExtension({
    isFormStaticGetterName: 'isFormStatic',
    isFormInteractiveGetterName: 'isFormInteractive',
    formJSONGetterName: 'getFormSourceJSON',
    currentPageIdGetterName: 'getCurrentPageID'
  }))

  return {
    modules: {
      FSP: useFSPStoreModule({
        stylePreferencesGetterName: 'FSP_theme/getCurrentStylePreferences'
      }),

      submission: form_submit_submission_store,

      progress_bar: form_submit_progress_bar,
      fields_validation: form_submit_fields_validation,
      FSP_theme: form_submit_fsp_theme,
      conditional: form_submit_conditional_logic_store,
      submission_metrics: form_submit_submission_metrics_store,
    },
    mutations: {
      REGISTER_BEFORE_SUBMIT_HOOK: (state, { hookID, hook }) => state.before_submit_hooks[hookID] = hook
    },
    actions: {
      setFieldsValidation: ({ dispatch }) => {
        dispatch('fields_validation/setRules')
      },
      fetchFormSourceJSON: ({ commit, dispatch }, formID) =>
        FormSubmitActions.fetchFormData(formID)
          .then(({ data: response }) => {
            if (response.closed) {
              router.replaceWithoutRedirecting(constants.PAGE_NAME_FORM_NOT_FOUND)
            } else if (response.formData && response.formJson) {
              const { formData, formJson } = response
              commit('SET_FORM_DATA', formData)
              commit('SET_FORM_SOURCE_JSON', response.formJson)
              dispatch('setFormVersionId', response.formData?.publishedVersionID)
              commit('SET_WITH_SUBMIT', true)
              return Promise.all([
                dispatch('submission/generateFormSubmission', response.formJson),
                dispatch('FSP_theme/pushThemeObj', response.formData.themeObj)
              ]).then(() => Promise.all([
                dispatch('setFieldsValidation'),
                dispatch('submission_metrics/formSubmitOpened'),
                dispatch('FSP/initializeFSP')
              ]))
            } else {
              router.replaceWithoutRedirecting(constants.PAGE_NAME_NOT_FOUND)
            }
          }),

      pushFormSource: async ({ commit, dispatch }, {
        formJSON,
        formData,
        themeObj,
        withSubmit
      }) => {
        commit('SET_FORM_DATA', formData)
        commit('SET_FORM_SOURCE_JSON', formJSON)

        commit('SET_WITH_SUBMIT', withSubmit)

        await dispatch('submission/generateFormSubmission', formJSON)

        dispatch('setFieldsValidation')

        dispatch('FSP_theme/pushThemeObj', themeObj)

        dispatch('FSP/initializeFSP')
      },


      setCurrentPageID: ({ commit }, pageID) => {
        commit('SET_CURRENT_PAGE_ID', pageID)
      },
      goToPreviousPage: ({ commit, getters }) => {
        let previousPageID

        if (getters.isMainPage) {
          if (getters.isFormStatic) {
            return
          } else if (getters.isFormInteractive) {
            !getters.getFormSourceJSON.main_pages.slice(0, getters.getCurrentMainPageIndex)
              .reverse()
              .some(({ page_id }) => {
                if (
                  !(getters.getFormSourceJSON.all_pages[page_id].field_id in getters['conditional/getFieldsWithExistenceStatus'])
                  || getters['conditional/getFieldsWithExistenceStatus'][getters.getFormSourceJSON.all_pages[page_id].field_id].exist
                ) {
                  previousPageID = page_id
                  return true
                }
              })
          }
        }
        commit('SET_CURRENT_PAGE_ID', previousPageID)
      },
      goToNextPage: ({ commit, getters, dispatch }) => {
        let nextPageID
        const mainPages = getters.getFormSourceJSON.main_pages
        const currentPageID = getters.getCurrentPageID

        if (getters.isHomePage)
          nextPageID = mainPages[0].page_id
        else if (getters.isMainPage) {
          if (getters.isFormStatic) {
            if (mainPages[mainPages.length - 1].page_id === currentPageID)
              return dispatch('goToFinishPage')
            else
              nextPageID = mainPages.find((page, index) => mainPages[index - 1]?.page_id === currentPageID).page_id
          } else if (getters.isFormInteractive) {
            if (
              getters.getCurrentPage.field_id in getters['conditional/getFieldsWithExistenceStatus']
              && getters['conditional/getFieldsWithExistenceStatus'][getters.getCurrentPage.field_id].jump_to
            ) {
              nextPageID = Object.entries(getters.getFormSourceJSON.all_pages)
                .find(([, { field_id }]) => field_id === getters['conditional/getFieldsWithExistenceStatus'][getters.getCurrentPage.field_id].jump_to)[0]
            } else if (!getters.getFormSourceJSON.main_pages.slice(getters.getCurrentMainPageIndex + 1)
              .some(({ page_id }) => {
                if (
                  !(getters.getFormSourceJSON.all_pages[page_id].field_id in getters['conditional/getFieldsWithExistenceStatus'])
                  || getters['conditional/getFieldsWithExistenceStatus'][getters.getFormSourceJSON.all_pages[page_id].field_id].exist
                ) {
                  nextPageID = page_id
                  return true
                }
              })
            ) return dispatch('goToFinishPage')

          }
        }
        commit('SET_CURRENT_PAGE_ID', nextPageID)
      },
      registerBeforeSubmitHook: ({ commit }, { hookID, hook }) => {
        commit('REGISTER_BEFORE_SUBMIT_HOOK', { hookID, hook })
      },

      validateMainPage: ({ getters, dispatch }, pageID) => Promise.all(
        getters.getFieldsGroupedByPages[pageID].map(fieldID => !getters['conditional/getFieldsWithExistenceStatus'][fieldID]?.exist || dispatch('fields_validation/validateField', fieldID))
      )
        .then(results => results.every(result => result)),
      validateCurrentMainPage: ({ dispatch, getters }) => dispatch('validateMainPage', getters.getCurrentPageID),
      goToFinishPage: async ({ commit, getters, dispatch }) => {
        // if interactive form (for static form pages are validated before nextPage action is called)
        if (getters.isFormInteractive) {
          for (let mainPage of getters.getFormPages) {
            const result = await dispatch('validateMainPage', mainPage.page_id)
            if (!result)
              return dispatch('setCurrentPageID', mainPage.page_id)
          }
        }
        commit('SET_CURRENT_PAGE_ID', getters.getFormSourceJSON.finish_page.page_id)
        dispatch('submitForm')
        const { redirect_upon_completion } = getters.getFormSourceJSON.settings.general_settings;
        if (redirect_upon_completion) {
          setTimeout(() => {
            const popup = window.open(redirect_upon_completion, '_blank');
            if (!popup) window.location.href = redirect_upon_completion;
          }, 2000);
        }
      },
      submitForm: async ({ getters }) => {
        if (getters.getWithSubmit) {
          if (!getters.getFormVersionId) throw 'Submit error: form version ID unset'

          await Promise.all(
            Object.values(getters.getBeforeSubmitHooks).map(hook => hook())
          )

          return FormSubmitActions.submitForm(
            getters['submission_metrics/getSubmissionId'],
            getters.getFormData.id,
            getters.getFormVersionId,
            getters['submission/getFormSubmission'],
            getters.getFormData.zapier_status,
            getters.getFormData.webhook_url,

          )
        }
      }
    },
    getters: {
      //getFormSourceJSON: ({ state }) => state.form_source_json,
      getFormSourceJSON: ({ state }) => {
        const formJSON = state.form_source_json;
        if (formJSON && !formJSON?.layout_header_style) {
          formJSON.layout_header_style = 'visible_header';
        }
        return formJSON;
      },
      getFormData: ({ state }) => state.form_data,

      isFormFullyLoaded: ({ getters }) => !!((getters.getFormData || !getters.getWithSubmit) && (getters.getFormSourceJSON && getters['submission/getFormSubmission'])),

      isFormStatic: ({ getters }) => getters.isFormFullyLoaded && getters.getFormSourceJSON.form_type === constants.FORM_TYPES.STATIC_FORM.FORM_TYPE,
      isFormInteractive: ({ getters }) => getters.isFormFullyLoaded && getters.getFormSourceJSON.form_type === constants.FORM_TYPES.INTERACTIVE_FORM.FORM_TYPE,

      getFormPages: ({ getters }) => getters.getFormSourceJSON.main_pages,
      getCurrentPageID: ({ state }) => state.current_page_id,
      getCurrentPage: ({ getters }) => getters.getFormSourceJSON?.all_pages?.[getters.getCurrentPageID],
      getFieldsGroupedByPages: ({ getters }) => {
        if (getters.isFormStatic)
          return Object.fromEntries(
            getters.getFormPages
              .map(({ page_id }) => [
                page_id,
                getters.getFormSourceJSON.all_pages[page_id].rows
                  .map(({ fields }) => fields)
                  .flat()
                  .map(({ field_id }) => field_id)
              ])
          )
        else if (getters.isFormInteractive)
          return Object.fromEntries(
            getters.getFormPages
              .map(({ page_id }) => [
                page_id,
                [getters.getFormSourceJSON.all_pages[page_id].field_id]
              ])
          )
        return []
      },
      getMainPagesWithIndexes: ({ getters }) => Object.fromEntries(getters.getFormPages.map(({ page_id }, index) => [page_id, index])),
      getCurrentMainPageIndex: ({ getters }) => getters.getFormSourceJSON.main_pages.findIndex(p => p.page_id === getters.getCurrentPageID),

      isHomePage: ({ getters }) => getters.getCurrentPage?.type === constants.FORM_PAGES_TYPES.HOME_PAGE,
      isMainPage: ({ getters }) => getters.getCurrentPage?.type === constants.FORM_PAGES_TYPES.MAIN_PAGE,
      isFinishPage: ({ getters }) => getters.getCurrentPage?.type === constants.FORM_PAGES_TYPES.FINISH_PAGE,
    }
  }
}
