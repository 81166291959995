var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFrontView', {
    attrs: {
      "options-list": _vm.sortedOptions,
      "model": _vm.submissionObj.value,
      "radio": _vm.radio,
      "option-input": function (index) {
        return _vm.optionInput(index, _vm.submissionObj);
      },
      "field-obj": _vm.sourceObj,
      "is-form-static": _vm.isFormStatic,
      "is-form-interactive": _vm.isFormInteractive
    },
    scopedSlots: _vm._u([_vm.isFormInteractive ? {
      key: "option-append",
      fn: function (_ref) {
        var active = _ref.active;
        return [active ? _c('CheckIcon', {
          staticClass: "selection-field-view__selection-field-option__check-icon"
        }) : _vm._e()];
      }
    } : null], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }