var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.layout && _vm.layout.rows.length ? _c('div', {
    ref: _vm.layout.refID,
    staticClass: "static-main-page-fields-layout static-main-page-fields-layout--editable"
  }, _vm._l(_vm.layout.rows, function (row) {
    return _c('v-row', {
      key: row.refID,
      ref: row.refID,
      refInFor: true
    }, _vm._l(row.elements, function (element) {
      return _c('v-col', {
        key: element.refID,
        ref: element.refID,
        refInFor: true,
        attrs: {
          "cols": element.width * (12 / _vm.constants.STATIC_FORM_COLS_COUNT)
        }
      }, [element instanceof _vm.Field ? _c('StaticFieldConstructor', {
        key: element.fieldID,
        attrs: {
          "field-id": element.fieldID,
          "field-width": element.width,
          "is-expanding-transition-going": element.isExpandingTransitionGoing
        },
        on: {
          "start-dragging": function ($event) {
            return _vm.startDragging($event, row, element);
          },
          "start-expanding": function ($event) {
            return _vm.startExpanding($event, row, element);
          }
        }
      }) : element instanceof _vm.ForbiddenDropzone ? _c('div', {
        staticClass: "static-main-page-fields-layout__dropzone static-main-page-fields-layout__dropzone--forbidden"
      }) : element instanceof _vm.Dropzone ? _c('div', {
        staticClass: "static-main-page-fields-layout__dropzone",
        class: _vm.closeToDropzoneArea && 'static-main-page-fields-layout__dropzone--close-to-area'
      }) : _vm._e()], 1);
    }), 1);
  }), 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }