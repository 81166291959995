<template>
    <v-dialog
            width="600"
            v-model="dialog"
            @click:outside="closeDialog"
    >
        <template #activator="{ on }">
            <v-list-item v-on="on">
                <v-list-item-content>
                    <v-list-item-title>Rename</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title>
                Rename Form "{{currentName}}"
                <v-spacer/>
                <v-btn
                        icon
                        @click="closeDialog"
                >
                    <XIcon/>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent
                >
                    <div class="v-card__subheader">New Name</div>
                    <v-text-field
                            v-model="newName"
                            @keydown="$refs.form.resetValidation()"
                            v-input-mask="Utils.getRangedLetterOrNumberRegex({
                                expression: constants.ANY_LETTER_OR_NUMBER_REGEX,
                                from: 0,
                                to: 75,
                                isSpaceAllowed: true,
                            })"
                            placeholder="Enter new form name"
                            outlined
                            :rules="[v => !!v || 'Required']"
                            hide-details="auto"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                        color="gray_100"
                        rounded
                        @click="closeDialog"
                >
                    Cancel
                </v-btn>
                <v-btn
                        color="blue_400"
                        rounded
                        @click="renameForm"
                >
                    Rename
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {XIcon} from 'vue-feather-icons'

    export default {
        name: "RenameFormModal",
        components: {
            XIcon
        },
        props: {
            formData: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            dialog: false,
            valid: true,

            currentName: '',
            newName: '',
            formJson: {},
            formJsonFetchPromise: undefined
        }),
        methods: {
            closeDialog() {
                this.newName = ''
                this.$refs.form.resetValidation()
                this.dialog = false
            },
            renameForm() {
                if (this.$refs.form.validate()) {
                    const changedName = this.newName
                    this.closeDialog()
                    this.$store.dispatch('forms_list/overwriteFormDataInFormsList', {
                        id: this.formData.id,
                        attrs: {
                            name: changedName
                        }
                    })
                    this.formJsonFetchPromise
                        .then(() => this.OwnersFormActions.updateFormDBData({id: this.formData.id, name: changedName}))
                        .then(() => this.snackbarEventBus.$emit('snackbar', {
                            emoji: this.constants.EMOJI.HANDS_UP,
                            color: this.constants.SNACKBAR_CONSTANTS.COLORS.SECONDARY,
                            text: 'Form Successfully Renamed'
                        }))
                }
            }
        },
        watch: {
            dialog(v) {
                if (v) {
                    this.currentName = this.formData.name
                    this.newName = this.formData.name;

                    this.formJsonFetchPromise = this.OwnersFormActions.fetchFormJson(this.formData.path)
                        .then(({json: formJson}) => this.formJson = formJson)
                }
                else
                    this.closeDialog()
            }
        },
    }
</script>

<style scoped>

</style>