var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#F5F0FA"
    }
  }), _c('rect', {
    attrs: {
      "x": "8.35714",
      "y": "15.0715",
      "width": "0.714286",
      "height": "3.57143",
      "stroke": "#393F41",
      "stroke-width": "0.714286"
    }
  }), _c('rect', {
    attrs: {
      "x": "12.6429",
      "y": "12.9284",
      "width": "0.714286",
      "height": "5.71429",
      "stroke": "#393F41",
      "stroke-width": "0.714286"
    }
  }), _c('rect', {
    attrs: {
      "x": "16.9285",
      "y": "9.35714",
      "width": "0.714286",
      "height": "9.28571",
      "stroke": "#393F41",
      "stroke-width": "0.714286"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }