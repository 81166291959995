import user_plan_checkout_store from '@/store/user/modules/user_plan_modules/user_plan_checkout_store'
import user_plan_current_subscription_store from '@/store/user/modules/user_plan_modules/user_plan_current_subscription_store'
import user_plan_customer_details_module
    from '@/store/user/modules/user_plan_modules/util_modules/user_plan_customer_details_module'

import { executeGraphQLQueryList } from '@/utils/graphql_executors'
import { listStripeProducts } from '@formsly/shared/api/graphql'
import { isEmpty } from 'lodash'

import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

const parsePlanFeatures = planObject => ({
    ...planObject,
    features: (() => {
        const parsedFeatures = JSON.parse(planObject.features)

        Object.entries(parsedFeatures)
            .forEach(([key, value]) => {
                if (!isNaN(parseInt(value))) {
                    parsedFeatures[key] = parseInt(value)
                    if (parsedFeatures[key] < 0)
                        parsedFeatures[key] = Infinity
                }
                else if (value === 'true' || value === 'false')
                    parsedFeatures[key] = JSON.parse(value)
            })

        return parsedFeatures
    })()
})

export default ({useExtension}) => {
    {
        const state = {plans: undefined}

        useExtension(SMAGableExtensions.SMAGable, {state})
        useExtension(ToggleableExtensions.Loadable, {state})
    }

    return {
        modules: {
            current_subscription: user_plan_current_subscription_store,
            checkout: user_plan_checkout_store,
            customer_details: user_plan_customer_details_module,
        },
        actions: {
            fetchPlans: ({dispatch, toggleIsLoading}) => toggleIsLoading(
                executeGraphQLQueryList(listStripeProducts).then(plans => dispatch('setPlans', plans.map(parsePlanFeatures))),
                'plans'
            )
                .then(() => dispatch('checkout/selected_plan/setCheckBeforePriceInitialSelection', {key: 'plans_fetched'}))
        },
        getters: {
            getPlansObject: ({getters}) => {
                if (!getters.isPlansLoading && !isEmpty(getters.getPlans))
                    return Object.fromEntries(
                        getters.getPlans.map(plan => [
                            plan.id,
                            {
                                ...plan,
                                prices: Object.fromEntries(plan.prices.map(price => [price.recurring_interval, price]))
                            }
                        ])
                    )

                return undefined
            },
            getPricesObject: ({getters}) => getters.getPlansObject && Object.fromEntries(
                Object.values(getters.getPlansObject)
                    .map(({prices}) => Object.values(prices).map(priceObj => [priceObj.id, priceObj]))
                    .flat()
            )
        }
    }
}
