var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenericFormCreationLayout', {
    attrs: {
      "title": "Tell Formsly AI your form or survey needs -<br>and it'll make it happen!",
      "caption-above-the-title": "Step 3 of 3"
    }
  }, [_c('div', {
    staticClass: "create-form-wrapper"
  }, [_c('v-text-field', {
    staticClass: "create-form-wrapper__input",
    attrs: {
      "placeholder": _vm.currentPlaceholder,
      "outlined": "",
      "hint": "Describe in a clear and simple language for high quality results",
      "persistent-hint": "",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading,
      "maxlength": _vm.maxPromptLength
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.createSurvey.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "create-form__input-icon",
          class: _vm.isPromptValid && 'create-form__input-icon--active'
        }, [_vm._v(" $stars ")])];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "create-form-wrapper__button white--text",
          class: _vm.isPromptValid && 'create-form-wrapper__button--active',
          style: Object.assign({}, _vm.isLoading && {
            opacity: 0.7
          }),
          attrs: {
            "text-color": "white",
            "color": "#D3D6DC"
          },
          on: {
            "click": _vm.createSurvey
          }
        }, [_vm._v(" Create ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.promptInput,
      callback: function ($$v) {
        _vm.promptInput = $$v;
      },
      expression: "promptInput"
    }
  }), _vm.promptInput.length <= _vm.maxPromptLength ? _c('span', {
    staticClass: "create-form-wrapper__counter"
  }, [_vm._v(" " + _vm._s(_vm.promptInput.length) + "/" + _vm._s(_vm.maxPromptLength) + " ")]) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "create-form-wrapper__error",
          staticStyle: {
            "cursor": "pointer"
          }
        }, on), [_vm._v(" Max characters " + _vm._s(_vm.promptInput.length) + "/" + _vm._s(_vm.maxPromptLength) + " ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "create-form-wrapper__tooltip"
  }, [_vm._v(" For now we are allowing up to 120 characters only. Clear and simple descriptions lead to great results! ")])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }