<template>
  <div>
    <v-menu
        offset-y
        bottom
        :close-on-content-click="false"
        :open-on-click="false"
        open-on-hover
        content-class="app-bar-editor-form__popup"
        max-width="400"
    >
      <template #activator="{on}">
        <f-template-data-transfer :publishing="false" v-slot="{ dataStash, promiseProcessor }">
          <v-btn
              v-on="getIsFormPublicationAllowedWithUsedPaidFeatures ? {} : on"
              width="105"
              color="blue_400"
              dark
              rounded
              :loading="dataStash.publishing"
              @click="getIsFormPublicationAllowedWithUsedPaidFeatures && promiseProcessor(publishForm()).then(() => $emit('published'))"
          >
            Publish
          </v-btn>
        </f-template-data-transfer>
      </template>
      <template>
        <v-card class="app-bar-editor-form-popup__card">
          <v-card-title>
            <div>There are features in this form that aren't available in your current plan</div>
          </v-card-title>
          <v-card-text>
            <div class="app-bar-editor-form-popup__card-unpaid-features-section">
              <div class="app-bar-editor-form-popup__card-unpaid-features-section-title">Here is the list
                of
                <span>Paid</span>
                features you are using in this form:
              </div>
              <div class="app-bar-editor-form-popup__card-unpaid-features-section-list unpaid-features-list">
                <div v-for="(feature, index) in unpaidFeatures"
                     :key="index"
                     class="unpaid-features-list__item"
                >
                  <StarIcon size="14"/>
                  <span>{{ feature.text }}</span>
                </div>
              </div>
            </div>
            <v-divider/>
            <div class="app-bar-editor-form-popup__card-offer">
              To publish this form, you may need to upgrade to a paid plan to get the most of this
              form, or you may wish to remove paid features
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="goToCheckout" width="100%" rounded dark>
              Upgrade my plan
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
import {mapActions, mapGetters} from '@/xstore';


export default {
  name: 'PublishButton',
  components: {
    StarIcon: () => import('vue-feather-icons/icons/StarIcon'),
  },
  methods: {
    ...mapActions('edit/publish', [
      'publishForm'
    ]),
    goToCheckout() {
      window.open(this.$router.resolve({name: this.constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN}).href, '_blank')
    }
  },
  computed: {
    ...mapGetters('edit/paid_features_monitoring', [
      // Boolean
      'getIsFormPublicationAllowedWithUsedPaidFeatures',
      'getCountFieldsWithConditionalLogicIsMoreThanUserPaid',
      'getUnpaidFieldIsInForm',
      'getCountFieldsInFormMoreThanUserPaid',
      'getCountMainPagesInFormMoreThanUserPaid',
      // Others
      'getCountFieldsWithConditionalLogic',
      'getUnpaidFields',
      'getCountFieldsInForm'
    ]),
    ...mapGetters('user/plan/current_subscription', [
      'getCurrentSubscriptionFeatures',
    ]),
    unpaidFeatures() {
      return [
        {
          isFeatureUnpaid: this.getCountFieldsInFormMoreThanUserPaid,
          text: `A total of more than ${this.getCurrentSubscriptionFeatures?.questions_per_form} questions`,
        },
        {
          isFeatureUnpaid: this.getCountFieldsWithConditionalLogicIsMoreThanUserPaid,
          text: `A total questions with conditional logic is more than ${this.getCurrentSubscriptionFeatures?.conditional_logic_per_form}`
        },
        ...(
            this.getUnpaidFieldIsInForm ?
                this.getUnpaidFields.map(field => ({
                  isFeatureUnpaid: true,
                  text: field.fieldConst.READABLE_NAME
                })) : [{
                  isFeatureUnpaid: false,
                }]
        ),
        {
          isFeatureUnpaid: this.getCountMainPagesInFormMoreThanUserPaid,
          text: `A total pages is more than ${this.getCurrentSubscriptionFeatures?.static_form_pages}`
        }
      ].filter(item => item.isFeatureUnpaid);
    },
  }
}
</script>

<style scoped lang="scss">
div.app-bar-editor-form__popup.v-menu__content {
  padding-top: 30px;
  box-shadow: 0 8px 0px -6px rgb(24 39 75 / 12%), 0 12px 20px -4px rgb(24 39 75 / 12%) !important;

  .v-card {
    padding: 30px;

    &__title, &__text, &__actions {
      padding: 0;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      color: var(--v-bluish_gray_400);
      padding-right: 70px;
      margin-bottom: 20px;
    }

    &__text {
      color: var(--v-gray_500-base);
      margin-bottom: 25px;

      .v-divider {
        margin: 20px 0;
      }

      .app-bar-editor-form-popup__card-offer {

      }
    }

    &__actions {
      .v-btn {
        background: linear-gradient(266.04deg, #2FBBEB 22.52%, #BA65FD 87.84%);
      }
    }
  }

  .app-bar-editor-form-popup__card {
    &-unpaid-features-section {
      padding-right: 70px;

      &-title {
        margin-bottom: 25px;

        span {
          color: #7D63FF;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .unpaid-features-list {
        &__item {
          display: flex;
          align-items: center;
          column-gap: 10px;

          svg {
            color: #7D63FF;
          }

          span {
            color: #393F41;
            font-weight: 500;
          }
        }
      }
    }

  }
}
</style>