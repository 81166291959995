export const US_STATES_LIST = [
    {code: 'AK', name: 'Alaska', svg: 'alaska'},
    {code: 'TX', name: 'Texas',  svg: 'texas'},
    {code: 'AL', name: 'Alabama',  svg: 'alabama'},
    {code: 'AR', name: 'Arkansas',  svg: 'arkansas'},
    {code: 'AZ', name: 'Arizona',  svg: 'arizona'},
    {code: 'CA', name: 'California',  svg: 'california'},
    {code: 'CO', name: 'Colorado',  svg: 'colorado'},
    {code: 'CT', name: 'Connecticut',  svg: 'connecticut'},
    {code: 'DC', name: 'District of Columbia',  svg: 'district_of_columbia'},
    {code: 'DE', name: 'Delaware',  svg: 'delaware'},
    {code: 'FL', name: 'Florida',  svg: 'florida'},
    {code: 'GA', name: 'Georgia',  svg: 'georgia'},
    {code: 'HI', name: 'Hawaii',  svg: 'hawaii'},
    {code: 'IA', name: 'Iowa',  svg: 'iowa'},
    {code: 'ID', name: 'Idaho',  svg: 'idaho'},
    {code: 'IL', name: 'Illinois',  svg: 'illinois'},
    {code: 'IN', name: 'Indiana',  svg: 'alaska'},
    {code: 'KS', name: 'Kansas',  svg: 'indiana'},
    {code: 'KY', name: 'Kentucky',  svg: 'kentucky'},
    {code: 'LA', name: 'Louisiana',  svg: 'louisiana'},
    {code: 'MA', name: 'Massachusetts', svg: 'massachusetts' },
    {code: 'MD', name: 'Maryland',  svg: 'maryland'},
    {code: 'ME', name: 'Maine',  svg: 'maine'},
    {code: 'MI', name: 'Michigan', svg: 'michigan' },
    {code: 'MN', name: 'Minnesota',  svg: 'minnesota'},
    {code: 'MO', name: 'Missouri',  svg: 'missouri'},
    {code: 'MS', name: 'Mississippi',  svg: 'mississippi'},
    {code: 'MT', name: 'Montana',  svg: 'montana'},
    {code: 'NC', name: 'North Carolina',  svg: 'north_carolina'},
    {code: 'ND', name: 'North Dakota',  svg: 'north_dakota'},
    {code: 'NE', name: 'Nebraska',  svg: 'nebraska'},
    {code: 'NH', name: 'New Hampshire',  svg: 'new_hampshire'},
    {code: 'NJ', name: 'New Jersey',  svg: 'new_jersey'},
    {code: 'NM', name: 'New Mexico',  svg: 'new_mexico'},
    {code: 'NV', name: 'Nevada',  svg: 'nevada'},
    {code: 'NY', name: 'New York',  svg: 'new_york'},
    {code: 'OH', name: 'Ohio',  svg: 'ohio'},
    {code: 'OK', name: 'Oklahoma',  svg: 'oklahoma'},
    {code: 'OR', name: 'Oregon',  svg: 'oregon'},
    {code: 'PA', name: 'Pennsylvania',  svg: 'pennsylvania'},
    {code: 'RI', name: 'Rhode Island',  svg: 'rhode_island'},
    {code: 'SC', name: 'South Carolina',  svg: 'south_carolina'},
    {code: 'SD', name: 'South Dakota',  svg: 'south_dakota'},
    {code: 'TN', name: 'Tennessee',  svg: 'tennessee'},
    {code: 'UT', name: 'Utah',  svg: 'utah'},
    {code: 'VA', name: 'Virginia',  svg: 'virginia'},
    {code: 'VT', name: 'Vermont',  svg: 'vermont'},
    {code: 'WA', name: 'Washington',  svg: 'washington'},
    {code: 'WI', name: 'Wisconsin',  svg: 'wisconsin'},
    {code: 'WV', name: 'West Virginia',  svg: 'west_virginia'},
    {code: 'WY', name: 'Wyoming',  svg: 'wyoming'}
]

export const US_STATE_BY_CODE = Object.fromEntries(
    US_STATES_LIST.map(usState => [usState.code, usState])
)

export default {
    US_STATES_LIST,
    US_STATE_BY_CODE
}