var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "interactive-editable-field-footer"
  }, [_c('div', {
    key: _vm.fieldObj.field_type,
    staticClass: "interactive-editable-field-footer__actions"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getCurrentMainPageIndex + 1) + " ")]), _c(_vm.constants.FIELD_FROM_TYPE[_vm.fieldObj.field_type].ICON_COMPONENT, {
    tag: "component",
    staticClass: "ml-3"
  }), _c('v-divider', {
    staticClass: "my-1 mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Edit"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--primary bluish_gray_500--text ",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.openProperties
          }
        }, on), [_c('Edit2Icon', {
          attrs: {
            "stroke-width": "1.6",
            "size": "20"
          }
        })], 1)];
      }
    }])
  }), _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Duplicate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--primary bluish_gray_500--text mx-2",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.duplicateField
          }
        }, on), [_c('CopyIcon', {
          attrs: {
            "stroke-width": "1.6",
            "size": "20"
          }
        })], 1)];
      }
    }])
  }), !!_vm.fieldTypeConst.CONDITIONAL_LOGIC ? _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Conditional logic"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--primary bluish_gray_500--text",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.addConditionalLogic
          }
        }, on), [_c('GitMergeIcon', {
          attrs: {
            "stroke-width": "1.6",
            "size": "20"
          }
        })], 1)];
      }
    }], null, false, 4156352325)
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-1 mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('f-hover-tooltip', {
    attrs: {
      "top": "",
      "text": "Delete"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-btn', _vm._g({
          staticClass: "v-btn--error",
          attrs: {
            "text": "",
            "icon": "",
            "small": ""
          },
          on: {
            "click": _vm.removeField
          }
        }, on), [_c('TrashIcon', {
          attrs: {
            "stroke-width": "1.6",
            "size": "20"
          }
        })], 1)];
      }
    }])
  }), 'required' in _vm.fieldObj.properties ? [_c('v-divider', {
    staticClass: "my-1 mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('span', {
    staticClass: "interactive-editable-field-footer__required mx-2"
  }, [_vm._v("Required")]), _c('f-switch', {
    staticClass: "ml-2",
    model: {
      value: _vm.requiredModel,
      callback: function ($$v) {
        _vm.requiredModel = $$v;
      },
      expression: "requiredModel"
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "interactive-editable-field-footer__properties"
  }, [_c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "editable-field"
    }
  }, [_vm.showProperties ? _c('EditableFieldPropertiesDefaultWindow', {
    attrs: {
      "field-obj": _vm.fieldObj,
      "field-id": _vm.fieldId
    },
    on: {
      "close-properties": _vm.closeProperties,
      "change-field-type": _vm.changeFieldType
    }
  }) : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }