var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "loading-form-screen h-100vh-without-appbar",
    attrs: {
      "fluid": ""
    }
  }, [_c('LottieAnimation', {
    attrs: {
      "path": "EditorLoaderIcon.json",
      "width": 450,
      "height": 450
    }
  }), _c('p', {
    staticClass: "loading-form-screen__caption"
  }, [_vm._v("Loading your form...")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }