import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { GenericTextFieldFSPDefinition } from '@/components/Fields/GenericFieldViews/GenericTextField'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithComplexConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import { FieldPropertyCheckboxInput } from '@/components/Fields/FieldConstClasses/field_property_input'
import { Analytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import constants from "@/constants/constants";

class AddressFieldTypeConst extends FieldTypeConst {
    constructor() {
        super({
            FIELD_TYPE: 'ADDRESS',
            READABLE_NAME: 'Address',
            ICON_NAME: 'full_address',
            FOLDER_NAME: 'Address',

            ANALYTICS: new Analytics({
                FORMAT_TO_CSV_EXPORT: submissionObj => {
                    return Object.entries(submissionObj.value)
                        .map(([key, value]) => key === 'country' ? constants.COUNTRY_NAMES[value] : value)
                        .filter(item => item)
                        .join(', ')
                }
            }),

            STATIC_FORM_MIN_WIDTH: 2,

            FSP: GenericTextFieldFSPDefinition,

            UTIL: {
                placeholders: {
                    street: 'Address',
                    city: 'City',
                    country: 'Country',
                    postal_code: 'ZIP / Postcode'
                }
            },

            SUBMISSION_TEMPLATE: fieldObj => ({
                value: Object.fromEntries(
                    Object.entries(fieldObj.properties.full_address_fields)
                        .filter(([, enabled]) => enabled)
                        .map(([name]) => [name, null])
                )
            }),

            IS_SUBMISSION_EMPTY: ({value}) => Object.values(value).every(v => !v?.length),

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: Object.fromEntries(
                        Object.entries(fieldObj.properties.full_address_fields)
                            .filter(([, enabled]) => enabled)
                            .map(([name]) => [name, FieldSubmitValidation.REQUIRED(fieldObj)])
                    )
                })
            }),

            WITH_PRESS_ENTER: true,

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'full_address_fields',
                    name: 'Include for address',
                    always_enabled: true,
                    enabled_by_default: true,
                    input_group: new FieldPropertyInputGroupWithComplexConstructor({
                        street: new FieldPropertyCheckboxInput({
                            default: true,
                            label: 'Address',
                            validator: (fieldProps) => Object.values(fieldProps.full_address_fields).filter(item => item).length !== 1,
                        }),
                        city: new FieldPropertyCheckboxInput({
                            default: false,
                            label: 'City',
                            validator: (fieldProps) => Object.values(fieldProps.full_address_fields).filter(item => item).length !== 1,
                        }),
                        country: new FieldPropertyCheckboxInput({
                            default: true,
                            label: 'Country',
                            validator: (fieldProps) => Object.values(fieldProps.full_address_fields).filter(item => item).length !== 1,
                        }),
                        postal_code: new FieldPropertyCheckboxInput({
                            default: false,
                            label: 'ZIP / Postcode',
                            validator: (fieldProps) => Object.values(fieldProps.full_address_fields).filter(item => item).length !== 1,
                        })
                    })
                })
            ])
        })
    }
}

export default new AddressFieldTypeConst()