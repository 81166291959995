import FormSubmitActions from '@/utils/actions/form_submit_actions'
import { deepCloneObject } from '@/utils/misc'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
const deepEqual = require('deep-equal')


export default ({useExtension}) => {
    {
        const state = {
            submission_id: undefined,
            initial_empty_submission: undefined,
            submission_metrics_created: false,
            submission_metrics_started: false
        }
        useExtension(SMAGableExtensions.SMGable, {state})
    }

    return {
        actions: {
            formSubmitOpened: async ({commit, getters, dispatch, parentDispatch, parentGetters}) => {
                commit('SET_INITIAL_EMPTY_SUBMISSION', deepCloneObject(parentGetters['submission/getFormSubmission']))
                parentDispatch('registerBeforeSubmitHook', {
                    hookID: 'submissionMetricsBeforeSubmit',
                    hook: () => dispatch('waitForSubmissionID')
                })

                setTimeout(async () => {
                    if (!getters.getSubmissionMetricsCreated) {
                        commit('SET_SUBMISSION_METRICS_CREATED')
                        const {data: {id: submissionID}} = await FormSubmitActions.sendSubmissionMetrics({
                            id: undefined,
                            formID: parentGetters.getFormData.id,
                            started: false
                        })
                        commit('SET_SUBMISSION_ID', submissionID)
                    }
                }, 4000)
            },
            waitForSubmissionID: async ({getters}) => {
                while (!getters.getSubmissionId)
                    await new Promise(resolve => setTimeout(resolve, 200))
            },
            formSubmissionChanged: async ({commit, getters, dispatch, parentGetters}) => {
                if (getters.getInitialEmptySubmission && !getters.getSubmissionMetricsStarted && !deepEqual(getters.getInitialEmptySubmission, parentGetters['submission/getFormSubmission'])) {
                    if (getters.getSubmissionMetricsCreated)
                        await dispatch('waitForSubmissionID')
                    else
                        commit('SET_SUBMISSION_METRICS_CREATED')
                    commit('SET_SUBMISSION_METRICS_STARTED')

                    const {data: {id: submissionID}} = await FormSubmitActions.sendSubmissionMetrics({
                        id: getters.getSubmissionId,
                        formID: parentGetters.getFormData.id,
                        started: true
                    })
                    if (!getters.getSubmissionId)
                        commit('SET_SUBMISSION_ID', submissionID)
                }
            }
        }
    }
}