<template>
  <SharedView
    :scale-units="fieldProperties.scale_units"
    :from="fieldProperties.scale_poles.from"
    :to="fieldProperties.scale_poles.to"
    :labels="fieldProperties.range_labels"
    :submission-obj="submissionObj"
  />
</template>

<script>
import submittable_field_view_mixin
  from '@/components/Fields/SubmittableField/SubmittableFieldViewUtils/submittable_field_view_mixin'

export default {
  name: "Submittable__Slider",
  mixins: [submittable_field_view_mixin],
  components: {
    SharedView: () => import('./SharedView'),
  }
}
</script>