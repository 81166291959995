import _ from 'lodash'
import { CustomSBGenerator } from '@/xstore/utils/state_based_generation'

const _stateNameFormatter = (variableName, suffix) => `#${_.snakeCase(variableName)}_${_.snakeCase(suffix)}`
const _mutationNameFormatter = (variableName, suffix) => `#TOGGLE_${_.toUpper(variableName)}_${_.toUpper(_.snakeCase(suffix))}`
const _getterNameFormatter = (variableName, suffix) => `get${_.upperFirst(_.camelCase(variableName))}Is${_.upperFirst(_.camelCase(suffix))}`

const genToggleableCollectionWithSuffix = suffix => {
    const stateNameFormatter = v => _stateNameFormatter(v, suffix)
    const mutationNameFormatter = v => _mutationNameFormatter(v, suffix)
    const getterNameFormatter = v => _getterNameFormatter(v, suffix)

    const SBGenerator = CustomSBGenerator(
        {
            category: 'state',
            name: v => stateNameFormatter(v),
            value: () => false
        },
        {
            category: 'mutations',
            name: v => mutationNameFormatter(v),
            value: variableName => (state, {status}) => state[stateNameFormatter(variableName)] = status
        },
        {
            category: 'getters',
            name: v => getterNameFormatter(v),
            value: variableName => ({state}) => state[stateNameFormatter(variableName)]
        }
    )

    SBGenerator.key = `Toggleable (${suffix})`

    const additionalActionContext = ctx => ({
        [`toggleIs${_.upperFirst(_.camelCase(suffix))}`]: (fn, ...variables) => {
            const commitAllVariables = payload => variables.forEach(variableName => ctx.commit(mutationNameFormatter(variableName), Object.freeze(payload)))

            commitAllVariables({status: true})
            return Promise.resolve(fn).finally(() => commitAllVariables({status: false}))
        }
    })

    return {
        SBGenerator,
        additionalActionContext
    }
}

export const ToggleableExtensions = Object.freeze({
    Loadable: genToggleableCollectionWithSuffix('loading'),
    Custom: genToggleableCollectionWithSuffix
})

