<template>
    <div class="static-editable-field-constructor__actions">
        <f-hover-tooltip
                right
                text="Edit"
                v-slot="{on}"
        >
            <v-btn
                    v-on="on"
                    @click="openProperties"
                    class="gray_500--text v-btn--primary"
                    text icon small
            >
                <Edit2Icon stroke-width="1.7" size="20"/>
            </v-btn>
        </f-hover-tooltip>
        <f-hover-tooltip
                right
                text="Duplicate field"
                v-slot="{on}"
        >
            <v-btn
                    v-on="on"
                    class="gray_500--text mt-2  v-btn--primary"
                    text icon small
                    @click="duplicateField"
            >
                <CopyIcon stroke-width="1.7" size="20"/>
            </v-btn>
        </f-hover-tooltip>
        <f-hover-tooltip
                v-if="conditionalLogicSupport"
                right
                text="Conditional logic"
                v-slot="{on}"
        >
            <v-btn
                    v-on="on"
                    class="gray_500--text mt-2  v-btn--primary"
                    text icon small
                    @click="addConditionalLogic"
            >
                <GitMergeIcon stroke-width="1.7" size="20"/>
            </v-btn>
        </f-hover-tooltip>
        <f-hover-tooltip
                right
                text="Delete"
                v-slot="{on}"
        >
            <v-btn
                    v-on="on"
                    @click="removeField"
                    class="gray_500--text mt-2 v-btn--error"
                    text icon small
            >
                <TrashIcon stroke-width="1.7" size="20"/>
            </v-btn>
        </f-hover-tooltip>
    </div>
</template>

<script>
    import {Edit2Icon, CopyIcon, TrashIcon, GitMergeIcon} from 'vue-feather-icons'

    export default {
        name: "StaticFieldActions",
        components: {
            Edit2Icon, CopyIcon, TrashIcon, GitMergeIcon
        },
        props: {
            conditionalLogicSupport: Boolean
        },
        methods: {
            openProperties() {
                this.$emit('open-properties')
            },
            duplicateField() {
                this.$emit('duplicate-field')
            },
            addConditionalLogic() {
                this.$emit('open-conditional-logic-editor')
            },
            removeField() {
                this.$emit('remove-field')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .static-editable-field-constructor__actions {
        padding: 7px;
        display: flex;
        flex-direction: column;
        border-radius: 1000px;
    }
</style>