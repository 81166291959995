<template>
    <v-container fluid class="h-full d-flex align-center justify-center">
        <v-slide-y-transition mode="out-in">
            <LoginLayoutWithImage v-if="resetStage === resetStages.ENTER_EMAIL">
                <template #title>
                    Reset Your password
                </template>
                <template #description>
                    Enter your user account's verified email address and we will send you a password reset code
                </template>
                <template #form>
                    <ErrorAlertComponent/>
                    <v-form
                            ref="enterEmailForm"
                            v-model="enterEmailFormValid"
                            lazy-validation
                    >
                        <div class="mb-2">Email</div>
                        <v-text-field
                                placeholder="Enter your email"
                                class="rounded-lg"
                                v-model="email"
                                :rules="emailRules"
                                @keydown="$refs.enterEmailForm.resetValidation()"
                                outlined
                                required
                                :disabled="$store.getters.isLoading"
                        />
                        <v-btn class="mb-4"
                               color="blue_400"
                               depressed
                               large
                               @click="sendPasswordResetEmail"
                               rounded
                               block
                               :disabled="$store.getters.isLoading"
                        >
                            Send password reset email
                        </v-btn>
                    </v-form>
                </template>
                <template #jump-to>
                    Remember your password?
                    <span
                            class="ml-2 blue_400--text font-weight-bold cursor-pointer"
                            @click="goToLogin"
                    >Log in</span>
                </template>
            </LoginLayoutWithImage>
<!--            <VerificationTemplate v-if="resetStage === resetStages.EMAIL_SENT">-->
<!--                <template #icon>-->
<!--                    <div class="pa-6 d-flex rounded-circle blue_100">-->
<!--                        <MailIcon class="blue_400&#45;&#45;text"/>-->
<!--                    </div>-->
<!--                </template>-->
<!--                <template #title>-->
<!--                    Check your email-->
<!--                </template>-->
<!--                <template #description>-->
<!--                    We sent a reset code to {{email}}-->
<!--                </template>-->
<!--                <template #code-input>-->
<!--                    <v-form-->
<!--                            ref="newPasswordForm"-->
<!--                            v-model="newPasswordFormValid"-->
<!--                            lazy-validation-->
<!--                    >-->
<!--                        <v-otp-input-->
<!--                                v-model="resetCode"-->
<!--                                hide-spin-buttons-->
<!--                                length="6"-->
<!--                                type="number"-->
<!--                                :disabled="$store.getters.isLoading"-->
<!--                        />-->
<!--                        <div class="mb-2">New Password <span class="error&#45;&#45;text">*</span></div>-->
<!--                        <v-text-field-->
<!--                                placeholder="Create a password"-->
<!--                                class="rounded-lg"-->
<!--                                v-model="password"-->
<!--                                @keydown="$refs.newPasswordForm.resetValidation()"-->
<!--                                :rules="passwordRules"-->
<!--                                :messages="!password ? `Must be at least ${constants.MIN_PASS_LENGTH} characters` : ''"-->
<!--                                type="password"-->
<!--                                outlined-->
<!--                                :disabled="$store.getters.isLoading"-->
<!--                        />-->
<!--                        <div class="mb-2">Confirm New Password <span class="error&#45;&#45;text">*</span></div>-->
<!--                        <v-text-field-->
<!--                                placeholder="Create a password"-->
<!--                                class="rounded-lg"-->
<!--                                v-model="confirmPassword"-->
<!--                                @keydown="$refs.newPasswordForm.resetValidation()"-->
<!--                                :rules="[password === confirmPassword || 'Passwords must match']"-->
<!--                                type="password"-->
<!--                                outlined-->
<!--                                :disabled="$store.getters.isLoading"-->
<!--                        />-->
<!--                    </v-form>-->
<!--                </template>-->
<!--                <template #action>-->
<!--                    <ErrorAlertComponent/>-->
<!--                    <br/>-->
<!--                    <v-btn-->
<!--                            @click="resetPassword"-->
<!--                            :disabled="resetCode.length !== 6 || $store.getters.isLoading"-->
<!--                            block-->
<!--                            depressed-->
<!--                            rounded-->
<!--                            color="blue_400"-->
<!--                    >-->
<!--                        Verify email-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--                <template v-if="!codeResent" #secondary-action>-->
<!--                    Didn't receive the email?-->
<!--                    <span @click="resendResetCode" class="blue_400&#45;&#45;text cursor-pointer">Click to resend</span>-->
<!--                </template>-->
<!--            </VerificationTemplate>-->
            <VerificationTemplate v-if="resetStage === resetStages.EMAIL_SENT">
                <template #icon>
                    <div class="pa-6 d-flex rounded-circle green_100">
                        <CheckCircleIcon class="green_500--text"/>
                    </div>
                </template>
                <template #title>
                    Reset password link has been successfully sent to your email address
                </template>
<!--                <template #description>-->
<!--                    Click below to log in magically.-->
<!--                </template>-->
<!--                <template #action>-->
<!--                    <v-btn-->
<!--                            @click="continueLogin"-->
<!--                            block-->
<!--                            depressed-->
<!--                            rounded-->
<!--                            color="blue_400"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                </template>-->
            </VerificationTemplate>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import ErrorAlertComponent from "@/components/Alert/ErrorAlertComponent"
    import {alert_mixin} from "@/components/Alert/alert_mixin"
    import {resend_code_mixin} from "@/components/LoginComponents/resend_code_mixin"
    import {CheckCircleIcon} from 'vue-feather-icons'

    import LoginLayoutWithImage from "@/components/LoginComponents/LoginLayoutWithImage"
    import VerificationTemplate from "@/components/LoginComponents/VerificationTemplate"

    export default {
        name: "ForgotPassword",
        components: {
            ErrorAlertComponent,
            CheckCircleIcon,
            LoginLayoutWithImage,
            VerificationTemplate
        },
        mixins: [alert_mixin, resend_code_mixin],
        data: vm => ({
            enterEmailFormValid: true,
            email: '',

            resetStage: 1,
            resetStages: {
                ENTER_EMAIL: 1,
                EMAIL_SENT: 2,
                DONE: 3
            },

            newPasswordFormValid: true,
            resetCode: '',
            password: '',
            confirmPassword: '',

            emailRules: [
                v => !!v || 'Email is required',
                v => vm.constants.EMAIL_REGEX.test(v) || 'Email must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= vm.constants.MIN_PASS_LENGTH || `Must be at least ${vm.constants.MIN_PASS_LENGTH} characters`,
            ],

            resetCodeSent: false
        }),
        methods: {
            sendPasswordResetEmail() {
                if (this.$refs.enterEmailForm.validate())
                    this.promiseWithAnimation(
                        this.AuthActions.forgotPassword(this.email)
                            .then(() => this.resetStage = this.resetStages.EMAIL_SENT)
                            .catch(e => this.emitErrorAlert(e.message))
                    )
            },
            // resetPassword() {
            //     if (this.$refs.newPasswordForm.validate())
            //         this.promiseWithAnimation(
            //             this.AuthActions.forgotPasswordSubmit(this.email, this.resetCode, this.password)
            //                 .then(() => this.resetStage = this.resetStages.DONE)
            //                 .catch(e => this.emitErrorAlert(e.message))
            //         )
            // },
            resendResetCode() {
                this.codeResent = true
                this.promiseWithAnimation(
                    this.AuthActions.forgotPassword(this.email)
                        .catch(e => this.emitErrorAlert(e.message))
                )
            },
            continueLogin() {
                this.promiseWithAnimation(
                    this.AuthActions.signIn(this.email, this.password)
                        .catch(e => console.log(e))
                )
            },
            goToLogin() {
                this.$router.push({name: this.constants.PAGE_NAME_LOGIN})
            }
        }
    }
</script>

<style scoped>

</style>