<template>
    <v-card class="px-4 form-parameters-card">
        <v-card-title class="pb-0">
            <div class="form-parameters-header">
                <slot name="title"></slot>
            </div>
            <v-spacer/>
            <v-btn
                    @click="$parent.$emit('close')"
                    text
                    icon
            >
                <XIcon class="gray_400--text" size="19"/>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <slot name="content"></slot>
        </v-card-text>
        <v-card-actions v-if="$slots.actions">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {XIcon} from 'vue-feather-icons'

    export default {
        name: "FormParametersContentTemplate",
        components: {
            XIcon
        }
    }
</script>

<style lang="scss">
    .form-parameters-header {
        font-size: 18px;
        &, & > * {
            display: flex;
            align-items: center;
        }
        color: var(--v-bluish_gray_500-base);

        svg {
            color: var(--v-dark_gray-base);
            stroke-width: 1.6;
            margin-right: 12px;
        }
    }
    .form-parameters-card.v-card {
        padding: 0!important;
        border-radius: inherit;
        box-shadow: none !important;

        max-height: calc(100vh - 80px - 40px * 2);
        display: flex;
        flex-direction: column;

        .v-card__text {
            flex-shrink: 1;
            display: flex;
            min-height: 1px;
            overflow-y: hidden;
        }

        &__actions {
            padding-top: 18px;
        }
    }

</style>