var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "data-table",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.formattedSubmissions,
      "items-per-page": -1,
      "search": _vm.searchQuery,
      "custom-filter": _vm.filterFunction,
      "item-key": "submissionID",
      "disable-pagination": "",
      "hide-default-footer": "",
      "show-select": "",
      "header-props": {
        sortIcon: null
      },
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc
    },
    on: {
      "update:sort-by": _vm.updateSortBy,
      "update:sort-desc": _vm.updateSortDesc
    },
    scopedSlots: _vm._u([{
      key: "header.data-table-select",
      fn: function (_ref) {
        var value = _ref.props.value,
          on = _ref.on;
        return [_c('v-simple-checkbox', _vm._g({
          attrs: {
            "color": "blue_400",
            "ripple": false,
            "value": value
          }
        }, on))];
      }
    }, {
      key: "item.data-table-select",
      fn: function (_ref2) {
        var isSelected = _ref2.isSelected,
          select = _ref2.select;
        return [_c('v-simple-checkbox', {
          attrs: {
            "color": "blue_400",
            "ripple": false,
            "value": isSelected
          },
          on: {
            "input": select
          }
        })];
      }
    }, _vm._l(_vm.tableHeaders, function (header) {
      return {
        key: _vm.getTableHeaderName(header.value),
        fn: function (_ref3) {
          var headerData = _ref3.header;
          return [_vm._v(" " + _vm._s(_vm.Utils.stripHtml(headerData.text) || '-') + " "), headerData.sortable ? _c('SortDirectionIcon', {
            key: header.value,
            staticClass: "ml-1",
            attrs: {
              "asc": _vm.sortBy === headerData.value && _vm.sortDesc === false,
              "desc": _vm.sortBy === headerData.value && _vm.sortDesc === true
            }
          }) : _vm._e()];
        }
      };
    }), _vm._l(_vm.tableHeaders, function (header) {
      return {
        key: _vm.getTableItemName(header.value),
        fn: function (_ref4) {
          var value = _ref4.value,
            item = _ref4.item;
          return [item.loading.value ? _c('v-skeleton-loader', {
            key: header.value,
            attrs: {
              "type": "text",
              "width": "80%"
            }
          }) : value && value.fieldTypeConst ? _c(value.fieldTypeConst.ANALYTICS.VIEWS.SHORT_SINGLE_ANSWER_VIEW, {
            key: header.value,
            tag: "component",
            attrs: {
              "submission-obj": value.submissionObj,
              "source-obj": value.sourceObj
            }
          }) : header.value === 'submissionDate' ? _c('span', {
            key: header.value,
            staticClass: "text-no-wrap"
          }, [_vm._v(_vm._s(value.fromNow()))]) : _c('span', {
            key: header.value
          }, [_vm._v("-")])];
        }
      };
    })], null, true),
    model: {
      value: _vm.selectedSubmissions,
      callback: function ($$v) {
        _vm.selectedSubmissions = $$v;
      },
      expression: "selectedSubmissions"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }