var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "smart-paragraph-field-view__editable-variable-container",
    class: _vm.focused && 'smart-paragraph-field-view__editable-variable-container--focused',
    attrs: {
      "contenteditable": "false",
      "data-smart-paragraph-variable": "true",
      "data-variable-id": _vm.finalId
    }
  }, [_c('div', {
    ref: "variable",
    staticClass: "smart-paragraph-field-view__editable-variable-field",
    class: _vm.placeholderShown && 'smart-paragraph-field-view__editable-variable-field--placeholder',
    attrs: {
      "contenteditable": "true",
      "data-placeholder": _vm.placeholderShown && 'Variable name'
    },
    on: {
      "paste": _vm.processPaste,
      "input": _vm.input,
      "keydown": _vm.keydown,
      "focus": _vm.focus,
      "blur": _vm.blur
    }
  }, [_vm._v(_vm._s(_vm.variableName))]), _c('XIcon', {
    on: {
      "click": _vm.removeVariable
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }