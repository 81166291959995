var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.asMenu ? _c('v-menu', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (bind) {
        return [_vm._t("activator", null, null, bind)];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('DateTimePicker', _vm._g(_vm._b({
          key: 'picker' + _vm.menuModel
        }, 'DateTimePicker', _vm.$attrs, false), _vm.$listeners))];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.menuModel,
      callback: function ($$v) {
        _vm.menuModel = $$v;
      },
      expression: "menuModel"
    }
  }, 'v-menu', _vm.menuBind, false)) : _c('DateTimePicker', _vm._g(_vm._b({}, 'DateTimePicker', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }