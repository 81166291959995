<template>
    <v-container class="checkout-customer-details-setup-page">
        <div class="checkout-page-heading checkout-page-heading--offset-lg">
            <div class="checkout-page-heading__title">
                <span>Complete your purchase</span>
            </div>
            <div class="checkout-page-heading__subtitle">
                <span>Powered by Stripe</span>
            </div>
        </div>

        <div class="checkout-customer-details-setup-page__layout">
            <div class="checkout-customer-details-setup-page__details-card-wrapper">
                <div class="checkout-customer-details-setup-details-card">
                    <div class="checkout-customer-details-setup-details-card__billing-address-wrapper">
                        <div class="checkout-customer-details-setup-details-card__title">
                            <span>1. Billing details</span>
                        </div>
                        <div class="checkout-customer-details-setup-details-card__content-wrapper">
                            <CheckoutBillingAddressSetup/>
                        </div>
                    </div>
                    <div class="checkout-customer-details-setup-details-card__divider-wrapper">
                        <v-divider/>
                    </div>
                    <div class="checkout-customer-details-setup-details-card__payment-method-wrapper">
                        <div class="checkout-customer-details-setup-details-card__title">
                            <span>2.  Payment method</span>
                        </div>
                        <div class="checkout-customer-details-setup-details-card__content-wrapper">
                            <CheckoutPaymentMethodCardSetup/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="checkout-customer-details-setup-page__selected-plan-amount-wrapper">
                <SelectedPlanAmount>
                    <template #button="{ attrs }">
                        <f-template-data-transfer :loading="false" v-slot="{ dataStash, promiseProcessor }">
                            <v-btn
                                v-bind="attrs"
                                :disabled="!getPurchaseSubmittingAllowed"
                                :loading="dataStash.loading"
                                @click="() => promiseProcessor($store.dispatch('user/plan/checkout/submitSubscriptionChange'))"
                            >
                                <v-icon class="mr-2" size="15">mdi-lock</v-icon>
                                Submit purchase
                            </v-btn>
                        </f-template-data-transfer>
                    </template>
                    <template #hint="{ component, orderSummary, selectedPriceObj }">
                        <component :is="component">
                            By purchasing, you accept the

                            <TermOfUse  :is-visible="dialogTermsVisible">
                                <template #activator="{on}">
                                    <span v-on="on" class="blue_400--text cursor-pointer d-inline">Terms of Use</span> and acknowledge reading
                                </template>
                            </TermOfUse>

                            <PrivacyPolicy  :is-visible="dialogPolicyVisible">
                                <template #activator="{on}">
                                    the <span v-on="on" class="blue_400--text cursor-pointer">Privacy Policy</span>.
                                </template>
                            </PrivacyPolicy>

                            You also agree to auto renewal of your
                            {{ selectedPriceObj?.recurring_interval + 'ly' }}
                            subscription for ${{ orderSummary?.renewal?.price / 100 }}, which can be disabled
                            at any time through your account. Your card details will be saved for future purchases and
                            subscription renewals.
                        </component>
                    </template>
                </SelectedPlanAmount>
            </div>
        </div>
        <DividedDisclaimer/>

    </v-container>
</template>

<script>
    import { mapGetters } from '@/xstore'
    import store from '@/store'
    import constants from '@/constants/constants'

    export default {
        name: "CheckoutCustomerDetailsSetup",
        window,
        data: () => ({
            dialogTermsVisible : false,
            dialogPolicyVisible: false
        }),
        components: {
            TermOfUse: () => import('@/components/Elements/TermOfUse'),
            PrivacyPolicy: () => import('@/components/Elements/PrivacyPolicy'),
            CheckoutBillingAddressSetup: () => import('@/components/Checkout/CustomerDetailsSetup/CheckoutBillingAddressSetup'),
            CheckoutPaymentMethodCardSetup: () => import('@/components/Checkout/CustomerDetailsSetup/CheckoutPaymentMethodCardSetup'),
            SelectedPlanAmount: () => import('@/components/Checkout/ChoosePlan/SelectedPlanAmount'),
            DividedDisclaimer: () => import('@/components/Checkout/DividedDisclaimer')
        },
        computed: {
            ...mapGetters('user/plan/checkout/navigation', [
                'getPurchaseSubmittingAllowed'
            ])
        },
        beforeRouteEnter(to, from, next) {
            if (store.getters['user/plan/checkout/navigation/getIsCustomerDetailsPageUnlocked'])
                return next()
            next({name: constants.PAGE_NAME_CHECKOUT_CHOOSE_PLAN})
        }
    }
</script>

<style lang="scss">
    .checkout-customer-details-setup-page {
        max-width: 800px;
        padding-bottom: 60px !important;
        &__layout {
            display: flex;
            column-gap: 40px;
        }

        &__details-card-wrapper {
            flex: 1 1 auto;
        }

        &__selected-plan-amount-wrapper {
            flex: 0 1 0%;
        }

        .checkout-customer-details-setup-details-card {
            box-shadow: 0 0 0 1px var(--v-gray_300-base);
            border-radius: 8px;

            &__title {
                font-size: 18px;
                line-height: 22px;
                font-weight: 500;
                margin-bottom: 24px;
            }

            $card-padding: 32px;

            &__billing-address-wrapper {
                padding: $card-padding;
            }

            &__divider-wrapper {
                padding: 8px $card-padding;
            }

            &__payment-method-wrapper {
                padding: $card-padding;
            }
        }
    }
</style>