<template>
    <div class="f-date-time-picker">
        <div class="f-date-time-picker__body">
            <template v-if="date && time">
                <v-tabs fixed-tabs v-model="activeTab">
                    <v-tab>
                        <v-icon>mdi-calendar</v-icon>
                    </v-tab>
                    <v-tab :disabled="!selectedDate">
                        <v-icon>mdi-clock-time-four-outline</v-icon>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item>
                        <DatePickerComponent v-model="selectedDate" @input="activeTab = 1"/>
                    </v-tab-item>
                    <v-tab-item>
                        <TimePickerComponent v-model="selectedTime"/>
                    </v-tab-item>
                </v-tabs-items>
            </template>

            <template v-else>
                <DatePickerComponent v-if="date" v-model="selectedDate"/>
                <TimePickerComponent v-else-if="time" v-model="selectedTime"/>
            </template>
        </div>
        <div v-if="mandatory">
            <v-spacer></v-spacer>
            <v-btn
                    color="gray_100"
                    rounded
            >Clear
            </v-btn>
            <v-btn
                    color="blue_400"
                    rounded
            >OK
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { VDatePicker, VTimePicker } from 'vuetify/lib/components'
    import Vue from 'vue'
    import moment from 'moment'
    import _ from 'lodash'

    const DatePickerComponent = Vue.extend({
        extends: VDatePicker,
        props: {
            firstDayOfWeek: {
                type: Number,
                default: 1,
            },
            weekdayFormat: {
                type: Function,
                default: date => ['Su', 'Mo', 'Tu', 'We', 'Th', 'Ft', 'Sa'][new Date(date).getDay(date)]
            },
            showCurrent: {
                type: Boolean,
                default: false,
            },
            showAdjacentMonths: {
                type: Boolean,
                default: true,
            },
            fullWidth: {
                type: Boolean,
                default: true,
            }
        }
    })
    const TimePickerComponent = Vue.extend({
        extends: VTimePicker,
        props: {
            fullWidth: {
                type: Boolean,
                default: true
            }
        }
    })

    export default {
        name: 'DateTimePicker',
        components: {
            DatePickerComponent,
            TimePickerComponent
        },
        props: {
            date: {
                type: Boolean,
                default: false
            },
            time: {
                type: Boolean,
                default: false
            },
            mandatory: {
                type: Boolean,
                default: false
            },
            returnPartially: {
                type: Boolean,
                default: false
            },
            returnObject: {
                type: Boolean,
                default: false
            },
            returnTimestamp: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            activeTab: 0,
            selectedDate: undefined,
            selectedTime: undefined
        }),
        computed: {
            selectedDateUnix() {
                if (this.selectedDate) {
                    return moment.utc(this.selectedDate).unix()
                }
                return this.selectedDate
            },
            selectedTimeUnix() {
                if (this.selectedTime) {
                    const [hours, minutes] = this.selectedTime.split(':').map(_ => parseInt(_))
                    return moment.duration({hours, minutes}).as('seconds')
                }
                return this.selectedTime
            }
        },
        watch: {
            selectedDate: 'handleUpdatedValue',
            selectedTime: 'handleUpdatedValue'
        },
        created() {
            if (!this.date && !this.time)
                throw '[f-date-time-picker]: you must pass "date" or "time" or both of them'
        },
        methods: {
            handleUpdatedValue() {
                if (!this.mandatory) {
                    let result,
                        date,
                        time

                    if (this.date)
                        date = this.returnTimestamp ? this.selectedDateUnix : this.selectedDate

                    if (this.time)
                        time = this.returnTimestamp ? this.selectedTimeUnix : this.selectedTime

                    if (this.returnObject)
                        result = Object.fromEntries(Object.entries({date, time}).filter(([, v]) => v || v === 0))
                    else
                        result = this.returnTimestamp ? ((date || 0) + (time || 0)) : [date, time].join(' ')

                    if (this.returnPartially || ((_.isPlainObject(result) && Object.values(result).every(v => v || v === 0)) || (!_.isPlainObject(result) && (result || result === 0))))
                        this.$emit('input', result)
                }
            }
        },
    }
</script>
<style lang="scss">
    .f-date-time-picker {
        .v-picker {
            padding: 0 !important;

            &__title {
                margin: 12px;
                border-radius: 8px !important;
            }
        }

        .v-tab {
            display: flex;
            align-items: center;
            justify-content: center !important;

        }
    }
</style>