var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('p', {
    staticClass: "settings-view__title"
  }, [_vm._v("Your account")]), _vm.getCurrentCognitoUserIsLoading ? _c('f-circle-loader', {
    attrs: {
      "size": "5"
    }
  }) : [_c('div', {
    staticClass: "setting-input-row-with-append"
  }, [_c('div', {
    staticClass: "setting-input-row-with-append__name"
  }, [_vm._v(" Full name ")]), _c('div', {
    staticClass: "setting-input-row-with-append__input"
  }, [_c('v-form', {
    ref: "nameInputForm",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    ref: "nameInput",
    attrs: {
      "name": "asdsada",
      "rules": _vm.nameRules,
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.localNameCopy,
      callback: function ($$v) {
        _vm.localNameCopy = $$v;
      },
      expression: "localNameCopy"
    }
  })], 1)], 1), _c('div', {
    staticClass: "setting-input-row-with-append__button"
  }, [_vm.showSaveNameButton ? _c('v-btn', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "blue_400",
      "text": "",
      "loading": _vm.isNameSaving
    },
    on: {
      "click": _vm.saveName
    }
  }, [_vm._v(" Save ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "setting-input-row-with-append"
  }, [_c('div', {
    staticClass: "setting-input-row-with-append__name"
  }, [_vm._v(" Your photo "), _c('span', [_vm._v(" You'll see this on your profile. ")])]), _c('div', {
    staticClass: "setting-input-row-with-append__input"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "mr-4",
    attrs: {
      "size": "64px"
    }
  }, [_vm.isAvatarSaving ? _c('f-circle-loader', {
    attrs: {
      "size": "3"
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.getCurrentCognitoUser.attributes.picture
    }
  })], 1), _c('v-btn', {
    staticClass: "font-weight-medium",
    attrs: {
      "text": "",
      "disabled": _vm.isAvatarSaving
    },
    on: {
      "click": _vm.startNewAvatarSelecting
    }
  }, [_vm._v("Change ")]), !_vm.isDefaultAvatar ? _c('v-btn', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "red_400",
      "text": "",
      "disabled": _vm.isAvatarSaving
    },
    on: {
      "click": _vm.deleteAvatar
    }
  }, [_vm._v("Remove ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "setting-input-row-with-append__button"
  })]), _c('v-divider'), _c('p', {
    staticClass: "settings-view__header"
  }, [_vm._v("Login details")]), _c('div', {
    staticClass: "setting-input-row-with-append"
  }, [_c('div', {
    staticClass: "setting-input-row-with-append__name"
  }, [_vm._v(" Email ")]), _c('div', {
    staticClass: "setting-input-row-with-append__input"
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.getCurrentCognitoUser.attributes.email,
      "readonly": "",
      "outlined": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('MailIcon')];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "setting-input-row-with-append__button",
    style: {
      transform: 'scale(0, 0)'
    }
  }, [_c('v-btn', {
    staticClass: "font-weight-medium",
    attrs: {
      "color": "grey_900",
      "text": ""
    }
  }, [_vm._v(" Change ")])], 1)]), _c('div', {
    staticClass: "setting-input-row-with-append"
  }, [_c('div', {
    staticClass: "setting-input-row-with-append__name"
  }, [_vm._v(" Password ")]), _c('div', {
    staticClass: "setting-input-row-with-append__input"
  }, [_c('v-text-field', {
    attrs: {
      "value": "1111111111",
      "type": "password",
      "outlined": "",
      "readonly": "",
      "disabled": "",
      "hide-details": ""
    }
  })], 1), _c('div', {
    staticClass: "setting-input-row-with-append__button"
  }, [_c('ChangePasswordModal')], 1)]), _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.saveChangesDialog,
      callback: function ($$v) {
        _vm.saveChangesDialog = $$v;
      },
      expression: "saveChangesDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Save Changes? "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.saveChangesDialog = false;
      }
    }
  }, [_c('XIcon')], 1)], 1), _c('v-card-text', [_vm._v("Do you want to save your changes before?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "gray_100",
      "rounded": ""
    },
    on: {
      "click": _vm.nextRoute
    }
  }, [_vm._v("Discard ")]), _c('v-btn', {
    attrs: {
      "color": "blue_400",
      "rounded": ""
    },
    on: {
      "click": function () {
        _vm.saveName();
        _vm.nextRoute();
      }
    }
  }, [_vm._v("Save ")])], 1)], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }