import { reactive } from '@vue/composition-api'
import { VBtn, VSpacer } from 'vuetify/lib/components'

import constants from '@/constants/constants'
import generic_dialog_module from '@/store/util_modules/generic_dialog_module'
import OwnersFormActions from '@/utils/actions/owners_form_actions'
import SubmissionsActions from '@/utils/actions/submissions_actions'
import { ResettableExtensions } from '@/xstore/utils/resettable'
import { SMAGableExtensions } from '@/xstore/utils/smagable'
import { ToggleableExtensions } from '@/xstore/utils/toggleable'

import form_metrics_module from '../util_modules/form_metrics_module'

export default ({ useExtension }) => {
  {
    const state = {
      current_page: 1,
      count: 0,
      analytics_view: 0,
      current_all_responses_view: 'splitted',
    }
    useExtension(SMAGableExtensions.SMAGable, { state })
  }

  {
    const state = {
      submissions_list: [],
      all_submissions_list: [],
      submissions_initially_all_fetched: false,
      form_source_versions_list: [],
      all_form_source_versions_list: [],
      versioned_form_sources: {},
      versioned_all_form_sources: {},

      submissions_initially_fetched: false,
      form_source_versions_initially_fetched: false,
      all_form_source_versions_initially_fetched: false,


      new_fetching_submissions_ids: [],
      new_all_fetching_submissions_ids: [],

      loaded_submissions_count: 0,
      is_submissions_list_extending_in_progress: false,
    }
    useExtension(SMAGableExtensions.SMGable, { state })

    useExtension(ToggleableExtensions.Custom('fetching'), {
      state: { submissions_list: [] },
    })
  }

  useExtension(ResettableExtensions.Default)

  return {
    modules: {
      metrics: form_metrics_module,
      confirmation_dialog: generic_dialog_module,
    },
    mutations: {
      PUSH_NEW_SUBMISSIONS_TO_LIST: (state, newSubmissions) => {
        state.submissions_list.push(...newSubmissions)
      },
      SET_ALL_SUBMISSIONS_LIST(state, submissionsList) {
        state.all_submissions_list = submissionsList
      },
    },
    actions: {
      processSubmissionsAnalyticsEntry: async (
        { dispatch, toggleIsFetching },
        { previousRoute, formID }
      ) =>
        toggleIsFetching(
          (async () => {
            if (previousRoute.name !== constants.PAGE_NAME_FORM_EDITOR) {
              await dispatch(
                'edit/prefetchFormBeforeEntrySubmissionsAnalytics',
                formID,
                { root: true }
              )
            } else
              await dispatch(
                'edit/prefetchFormBeforeEntrySubmissionsAnalytics',
                formID,
                { root: true }
              )
            await dispatch('fetchAnalytics', formID)
          })(),
          'submissions_list'
        ),
      prefetchSubmissionsAfterFormEditorEntry: ({ dispatch, rootGetters }) => {
        dispatch('fetchAnalytics', rootGetters['edit/getFormDBData'].id)
      },
      fetchSubmissionsListPerPage({ dispatch, commit }, { formID, page }) {
        commit('SET_CURRENT_PAGE', page)
        dispatch('fetchSubmissionsList', formID)
      },
      fetchSubmissionsList: ({ dispatch, commit, getters }, formID) => {
        return SubmissionsActions.fetchFormSubmissions({
          formID,
          offset: 0,
          limit: constants.SUBMISSIONS_LIMIT,
        }).then(submissionsObj => {
          const { items: submissionsList } = submissionsObj
          const { count: submissionsCount } = submissionsObj
          commit('SET_COUNT', submissionsCount)
          commit('SET_LOADED_SUBMISSIONS_COUNT', submissionsList.length)
          let oldSubmissionsIDs = getters.getSubmissionsList.map(({ id }) => id)
          let newSubmissionsIDs = submissionsList.map(({ id }) => id)

          commit(
            'SET_NEW_FETCHING_SUBMISSIONS_IDS',
            newSubmissionsIDs.filter(id => !oldSubmissionsIDs.includes(id))
          )
          commit('SET_SUBMISSIONS_LIST', submissionsList)

          if (!getters.getSubmissionsInitiallyFetched)
            commit('SET_SUBMISSIONS_INITIALLY_FETCHED', true)

          let uniqueFormPublishedVersionsIDs = submissionsList
            .filter(
              (submission, index, self) =>
                self.findIndex(
                  s => s.publishedVersionID === submission.publishedVersionID
                ) === index
            )
            .map(s => s.publishedVersionID)
          commit(
            'SET_FORM_SOURCE_VERSIONS_LIST',
            uniqueFormPublishedVersionsIDs
          )
          return dispatch('fetchFormSourcesForSubmissions')
        })
      },
      fetchAllSubmissionsList: ({ dispatch, commit, getters }, formID) => {
        return SubmissionsActions.fetchAllFormSubmissions({
          formID,
          offset: 0,
          limit: 1000000,
        }).then(submissionsObj => {
          const { items: submissionsList } = submissionsObj
          const { count: submissionsCount } = submissionsObj
          //commit('SET_COUNT', submissionsCount)
          //commit('SET_LOADED_SUBMISSIONS_COUNT', submissionsList.length)
          let oldSubmissionsIDs = getters.getAllSubmissionsList?.map(({ id }) => id)
          let newSubmissionsIDs = submissionsList.map(({ id }) => id)

          commit(
            'SET_NEW_ALL_FETCHING_SUBMISSIONS_IDS',
            newSubmissionsIDs.filter(id => !oldSubmissionsIDs?.includes(id))
          )

          commit('SET_ALL_SUBMISSIONS_LIST', submissionsList)

          if (!getters.getSubmissionsInitiallyAllFetched)
            commit('SET_SUBMISSIONS_INITIALLY_ALL_FETCHED', true)

          let uniqueFormPublishedVersionsIDs = submissionsList
            .filter(
              (submission, index, self) =>
                self.findIndex(
                  s => s.publishedVersionID === submission.publishedVersionID
                ) === index
            )
            .map(s => s.publishedVersionID)
          commit(
            'SET_ALL_FORM_SOURCE_VERSIONS_LIST',
            uniqueFormPublishedVersionsIDs
          )
          if (getters.getNewAllFetchingSubmissionsIds.length > 0){
            dispatch('metrics/fetchFormMetrics', formID)
          }else{
            commit(
              'SET_NEW_ALL_FETCHING_SUBMISSIONS_IDS', ''
            )
          }
          return dispatch('fetchAllFormSourcesForSubmissions')
        })
      },
      loadMoreSubmissions: async (
        { getters, commit, dispatch },
        { formID }
      ) => {
        if (
          getters.getLoadedSubmissionsCount >= getters.getCount ||
          !getters.getSubmissionsInitiallyFetched ||
          getters.getIsSubmissionsListExtendingInProgress
        ) {
          return
        }

        commit('SET_IS_SUBMISSIONS_LIST_EXTENDING_IN_PROGRESS', true)

        await SubmissionsActions.fetchFormSubmissions({
          formID,
          offset: getters.getLoadedSubmissionsCount,
          limit: constants.SUBMISSIONS_LIMIT,
        })
          .then(submissionsObj => {
            const { items: submissionsList } = submissionsObj

            commit(
              'SET_LOADED_SUBMISSIONS_COUNT',
              getters.getLoadedSubmissionsCount + submissionsList.length
            )

            let uniqueFormPublishedVersionsIDs = [
              ...getters.getSubmissionsList,
              ...submissionsList,
            ]
              .filter(
                (submission, index, self) =>
                  self.findIndex(
                    s => s.publishedVersionID === submission.publishedVersionID
                  ) === index
              )
              .map(s => s.publishedVersionID)
            commit(
              'SET_FORM_SOURCE_VERSIONS_LIST',
              uniqueFormPublishedVersionsIDs
            )
            return dispatch('fetchFormSourcesForSubmissions').then(() =>
              commit('PUSH_NEW_SUBMISSIONS_TO_LIST', submissionsList)
            )
          })
          .finally(() => {
            commit('SET_IS_SUBMISSIONS_LIST_EXTENDING_IN_PROGRESS', false)
          })
      },
      fetchAnalytics: ({ dispatch }, formID) => {
        dispatch('metrics/setFormId', formID).then(() =>
          dispatch('metrics/fetchFormMetrics', formID)
        )
        return dispatch('fetchSubmissionsList', formID)
      },
      fetchFormSourcesForSubmissions: ({ commit, getters, rootGetters }) =>
        Promise.all(
          getters.getFormSourceVersionsList.map(formSourceVersionID =>
            OwnersFormActions.fetchFormJson(
              rootGetters['edit/getFormDBData'].path,
              formSourceVersionID
            ).then(({ json: source }) => ({
              source,
              versionID: formSourceVersionID,
            }))
          )
        ).then(formSourcesList => {
          let versionedFormSources = {}
          formSourcesList.forEach(
            ({ source, versionID }) =>
              (versionedFormSources[versionID] = source)
          )
          commit('SET_VERSIONED_FORM_SOURCES', versionedFormSources)

          if (!getters.getFormSourceVersionsInitiallyFetched)
            commit('SET_FORM_SOURCE_VERSIONS_INITIALLY_FETCHED', true)

          commit('SET_NEW_FETCHING_SUBMISSIONS_IDS', [])
        }),

        fetchAllFormSourcesForSubmissions: ({ commit, getters, rootGetters }) =>
          Promise.all(
            getters.getAllFormSourceVersionsList.map(formSourceVersionID =>
              OwnersFormActions.fetchFormJson(
                rootGetters['edit/getFormDBData'].path,
                formSourceVersionID
              ).then(({ json: source }) => ({
                source,
                versionID: formSourceVersionID,
              }))
            )
          ).then(formSourcesList => {
            let versionedFormSources = {}
            formSourcesList.forEach(
              ({ source, versionID }) =>
                (versionedFormSources[versionID] = source)
            )
            commit('SET_VERSIONED_ALL_FORM_SOURCES', versionedFormSources)

            if (!getters.getFormSourceVersionsInitiallyFetched)
              commit('SET_All_FORM_SOURCE_VERSIONS_INITIALLY_FETCHED', true)

            commit('SET_NEW_ALL_FETCHING_SUBMISSIONS_IDS', [])
          }),

      deleteSubmission: ({ dispatch }, id) =>
        dispatch('deleteSubmissions', { ids: [id] }),
      deleteSubmissions: async (
        { commit, getters, dispatch },
        { ids, onBeforeDelete = () => null }
      ) => {
        dispatch('confirmation_dialog/showDialog', ({ closeDialog }) => {
          const deletingMultipleSubmissions = ids.length > 1

          const dialogProps = reactive({
            withCloseBtn: true,
            persistent: false,
          })

          return {
            title: 'Delete submission?',
            content: `Are you sure you want to delete ${
              deletingMultipleSubmissions
                ? 'these submissions'
                : 'this submission'
            }?`,
            actions: {
              functional: true,
              render: h => [
                h(VSpacer),
                h(
                  VBtn,
                  {
                    props: {
                      color: 'gray_100',
                      rounded: true,
                      disabled: dialogProps.persistent,
                    },
                    on: {
                      click: closeDialog,
                    },
                  },
                  'No'
                ),
                h(
                  VBtn,
                  {
                    props: {
                      color: 'red_400',
                      rounded: true,
                      loading: dialogProps.persistent,
                    },
                    on: {
                      click: async () => {
                        dialogProps.persistent = true

                        await (async () => {
                          await SubmissionsActions.deleteSubmissions(ids)
                          onBeforeDelete?.()
                          commit(
                            'SET_SUBMISSIONS_LIST',
                            getters.getSubmissionsList.filter(
                              s => !ids.includes(s.id)
                            )
                          )
                          dispatch('metrics/fetchFormMetrics')
                        })()

                        dialogProps.persistent = false

                        closeDialog()
                      },
                    },
                  },
                  `Yes, delete ${deletingMultipleSubmissions ? 'them' : 'it'}`
                ),
              ],
            },
            props: dialogProps,
          }
        })
      },
    },
    getters: {
      getAllResponsesViews: () => ({
        TABLE: 'table',
        SPLITTED: 'splitted',
      }),

      areSubmissionsExist: ({ getters }) =>
        getters.getSubmissionsInitiallyFetched &&
        getters.getSubmissionsList.length,
      areSubmissionsReadyForFormatting: ({ getters }) =>
        getters.getFormSourceVersionsInitiallyFetched &&
        getters.areSubmissionsExist,
      showPlaceholdersInsteadAllSubmissions: ({ getters, rootGetters }) =>
        !getters.areSubmissionsReadyForFormatting ||
        rootGetters['edit/loading_screen/getIsFormLoadingScreenShown'],
      isUpdatingAnyNewSubmissions: ({ getters }) =>
        !!(
          getters.getSubmissionsInitiallyFetched &&
          (getters.getNewFetchingSubmissionsIds.length ||
            (getters.showPlaceholdersInsteadAllSubmissions &&
              getters.getSubmissionsList.length))
        ),
      isAnyLoading: ({ getters }) =>
        !getters.getSubmissionsInitiallyFetched ||
        getters.getSubmissionsListIsFetching ||
        getters.isUpdatingAnyNewSubmissions,
      getNumberOfPages: ({ getters }) =>
        Math.ceil(getters.getCount / constants.SUBMISSIONS_LIMIT),
      areThereLeftUnloadedSubmissions: ({ getters }) =>
        getters.getLoadedSubmissionsCount < getters.getCount,
    },
  }
}
