import GenericSelectionEditableFieldView from '@/components/Fields/GenericFieldViews/GenericSelectionFieldView/GenericSelectionEditableFieldView'

export default {
  extends: GenericSelectionEditableFieldView,
  name: 'Editable__SingleSelection',
  computed: {
    radio() {
      return true
    },
  },
}
