import { SMAGableExtensions } from '@/xstore/utils/smagable'

let imagePickerInstance = null;

export default ({useExtension}) => {
    {
        const state = {
            homepage_image_preview: undefined
        }

        useExtension(SMAGableExtensions.SMAGable, {state})
    }

    return {
        actions: {
            controlImagePickerInstance({parentDispatch, parentGetters, dispatch}, ref) {
                if (imagePickerInstance) {
                    imagePickerInstance.$off()
                    imagePickerInstance = null;
                } else {
                    imagePickerInstance = ref;
                    imagePickerInstance.$on('preview-image',(v) => {
                        dispatch('setHomepageImagePreview', v)
                    })
                    imagePickerInstance.$on('cancel', async () => {
                        await dispatch('setHomepageImagePreview', null)
                        imagePickerInstance.closeDialog();
                    })
                    imagePickerInstance.$on('select-image', async (v) => {
                        await parentDispatch('pages/changeFormPage', {
                            pageID: 'home_page',
                            pageObj: {...parentGetters.getFormJSON.all_pages.home_page, imageUrl: v}
                        })
                        imagePickerInstance.closeDialog();
                    })
                }
            },
            openImagePicker() {
                imagePickerInstance.openDialog();
            }
        }
    }
}