<template>
  <div class="submissions-analytics__content">
    <f-circle-loader v-if="!getSubmissionsInitiallyFetched" size="5"/>
    <template v-else>
      <template v-if="areSubmissionsExist">
        <TableView
            v-if="getCurrentAllResponsesView === getAllResponsesViews.TABLE"
            v-model="selectedSubmissions"
            :search-query="searchQuery"
            :formatted-submissions="formattedSubmissions"
        />
        <SplittedView
            v-else-if="getCurrentAllResponsesView === getAllResponsesViews.SPLITTED"
            v-model="selectedSubmissions"
            :search-query="searchQuery"
            :formatted-submissions="formattedSubmissions"
            :get-is-submissions-list-extending-in-progress="getIsSubmissionsListExtendingInProgress"
        />
      </template>
      <NoResponses v-else/>
    </template>
  </div>
</template>

<script>
import {mapGetters} from '@/xstore'

export default {
  name: "AllResponsesView",
  components: {
    TableView: () => import('./TableView'),
    SplittedView: () => import('./SplittedView/SplittedView'),
    NoResponses: () => import('../NoResponses')
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    formattedSubmissions: {
      type: Array,
      required: true,
    }
  },
  computed: {
    selectedSubmissions: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    ...mapGetters('edit', [
      'getFormJSON',
      'getIsFormFetching'
    ]),
    ...mapGetters('submissions', [
      'getSubmissionsInitiallyFetched',
      'isUpdatingAnyNewSubmissions',
      'isAnyLoading',
      'areSubmissionsExist',

      'getCurrentAllResponsesView',
      'getAllResponsesViews',
      'getIsSubmissionsListExtendingInProgress',
    ])
  },
  methods: {
    refreshSubmissions() {
      this.$store.dispatch('submissions/fetchAnalytics', this.$route.params.formId)
    }
  }
}
</script>

<style lang="scss">

</style>