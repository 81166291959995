<template>
    <f-hover-tooltip
            bottom
            :text="formType"
            v-slot="{on}"
    >
        <v-tooltip
                v-model="tooltip"
                nudge-top="10"
                content-class="tooltip-bottom"
                bottom
        >
            <template #activator="{value}">
                <v-text-field
                        v-input-mask="Utils.getRangedLetterOrNumberRegex({
                            expression: constants.ANY_LETTER_OR_NUMBER_REGEX,
                            from: 0,
                            to: 75,
                            isSpaceAllowed: true,
                        })"
                        v-on="tooltip || $route.name === constants.PAGE_NAME_FORM_EDITOR ? undefined : on"
                        v-model="formName"
                        ref="formNameInput"
                        class="form-name-input"
                        placeholder="Name your form"
                        flat
                        @keydown.enter="setOverlay(false)"
                        @focus="setOverlay(true)"
                        @blur="setOverlay(false)"
                        solo
                        hide-details
                        :style="setInputSize"
                        :disabled="!$route.name === constants.PAGE_NAME_FORM_EDITOR"
                >{{value}}</v-text-field>
            </template>
            <template #default>
                Be bold and clear
            </template>

        </v-tooltip>
    </f-hover-tooltip>
</template>

<script>
    import {mapGetters} from "@/xstore"

    export default {
        name: "FormNameEditor",
        data: () => ({
            tooltip: false,
        }),
        computed: {
            ...mapGetters('edit', [
                'getFormJSON',
                'getFormDBData'
            ]),
            formName: {
                get: function () {
                    return this.getFormDBData?.name
                },
                set: function (value) {
                    this.$store.dispatch('edit/updateFormDBData', {name: value})
                }
            },
            formType() {
                let type = this.getFormJSON?.form_type === 'static' ? 'Form' : 'Survey';
                return `Edit ${type} Name`;
            },
            setInputSize() {
                if (this.formName) {
                    return {
                        'max-width': this.formName.length * 12 + 140 + 'px',
                    }
                } else {
                    return {
                        width: 'auto',
                    }
                }

            },
        },
        methods: {
            setOverlay(val) {
                if (!val) {
                    this.tooltip = false
                    this.$refs.formNameInput.blur()
                    if (this.SequenceDirector.getSequence(this.constants.NEW_FORM_TUTORIAL)) {
                        this.SequenceDirector.nextStep(this.constants.NEW_FORM_TUTORIAL)
                    }
                }
                this.$store.dispatch('edit/overlay/setOverlay', val)
            },
        },
        mounted() {
            if (this.SequenceDirector.getSequence(this.constants.NEW_FORM_TUTORIAL)) {
                this.SequenceDirector.setStepAction(this.constants.NEW_FORM_TUTORIAL, 0, () => {
                    this.setOverlay(true)
                    this.tooltip = true
                    this.$nextTick(() => this.$refs.formNameInput.focus())
                })
                this.SequenceDirector.startSequence(this.constants.NEW_FORM_TUTORIAL)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-name-input {
        position: relative;

        ::v-deep .v-input__slot {
            padding: 0 60px !important;

        }

        ::v-deep input {
            text-align: center;
        }
    }
</style>