var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "26",
      "height": "26",
      "rx": "8",
      "fill": "#EBF1FC"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 18.4168C15.9915 18.4168 18.4167 15.9917 18.4167 13.0002C18.4167 10.0086 15.9915 7.5835 13 7.5835C10.0085 7.5835 7.58334 10.0086 7.58334 13.0002C7.58334 15.9917 10.0085 18.4168 13 18.4168Z",
      "stroke": "#393F41",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M13 16.6114C14.9944 16.6114 16.6111 14.9946 16.6111 13.0003C16.6111 11.0059 14.9944 9.38916 13 9.38916C11.0056 9.38916 9.38889 11.0059 9.38889 13.0003C9.38889 14.9946 11.0056 16.6114 13 16.6114Z",
      "fill": "#393F41"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }