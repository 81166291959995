import { GenericTextFieldConst } from '@/components/Fields/GenericFieldViews/GenericTextField'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import {
    FieldPropertyInputGroupWithSimpleConstructor
} from '@/components/Fields/FieldConstClasses/property_input_group'
import {
    FieldPropertyNumberInput,
} from '@/components/Fields/FieldConstClasses/field_property_input'
import {
    GenericStringConditionalLogic
} from '@/components/Fields/FieldConstClasses/conditional_logic'
import { GenericTextAnalytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import { maxLength } from '@vuelidate/validators'

class ParagraphFieldTypeConst extends GenericTextFieldConst {
    constructor() {
        super({
            FIELD_TYPE: 'PARAGRAPH',
            READABLE_NAME: 'Long response',
            ICON_NAME: 'paragraph',
            FOLDER_NAME: 'Paragraph',

            CONDITIONAL_LOGIC: new GenericStringConditionalLogic(),

            ANALYTICS: new GenericTextAnalytics(),

            SUBMIT_VALIDATION: new FieldSubmitValidation({
                RULES: fieldObj => ({
                    value: {
                        ...FieldSubmitValidation.REQUIRED(fieldObj),
                        ...(fieldObj.properties.max_characters && {maxLength: maxLength(fieldObj.properties.max_characters)})
                    }
                })
            }),
            FIELD_OBJ_EXTENSION: () => ({
                content: {
                    placeholder: 'Type your answer here...',
                }
            }),

            BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
                new CustomFieldPropertyConst({
                    key: 'max_characters',
                    name: 'Max length',
                    tooltip: 'Choose the maximum allowed length for the answer',
                    input_group: new FieldPropertyInputGroupWithSimpleConstructor(
                        new FieldPropertyNumberInput({
                            default: 64
                        })
                    )
                })
            ]),
        })
    }
}

export default new ParagraphFieldTypeConst()