import { Analytics } from '@/components/Fields/FieldConstClasses/analytics'
import { FieldTypeBasePropertiesConstWithRequired } from '@/components/Fields/FieldConstClasses/base_properties'
import { FieldTypeConst } from '@/components/Fields/FieldConstClasses/field_type_const'
import { CustomFieldPropertyConst } from '@/components/Fields/FieldConstClasses/property'
import { FieldSubmitValidation } from '@/components/Fields/FieldConstClasses/submit_validation'
import FSP from '@/constants/fsp_constants'

import { FieldFSPDefinition } from '../../FieldConstClasses/fsp_field_definition'

class MatrixFieldTypeConst extends FieldTypeConst {
  constructor() {
    const EMPTY_ROW_TEMPLATE = (text = '') => ({ text })
    const EMPTY_COLUMN_TEMPLATE = (text = '') => ({ text })
    super({
      FIELD_TYPE: 'MATRIX',
      READABLE_NAME: 'Matrix',
      ICON_NAME: 'matrix',
      FOLDER_NAME: 'Matrix',
      STATIC_FORM_MIN_WIDTH: 2,

      FSP: new FieldFSPDefinition({
        FSP_FIELD_TYPE: 'Matrix',
        FIELD_VIEW_CLASS: 'matrix-field-view',
        VARIABLES: [
          {
            selector: [{ oneOf: ['table-row'] }],
            properties: {
              height: {
                default: {
                  fn: '50px',
                },
              },
              color: {
                'default--interactive': {
                  fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                },
                'default--static': {
                  fn: '#000',
                },
              },
              'font-size': {
                default: {
                  fn: '16px',
                },
              },
              'font-weight': {
                default: {
                  fn: 400,
                },
              },
            },
          },
          {
            selector: [{ oneOf: ['table-row::before'] }],
            properties: {
              'border-radius': {
                default: {
                  fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                    px: true,
                    calc: v => `${v} / 2`,
                    max: 12,
                  }),
                },
              },
              'background-color': {
                'default--interactive': {},
                'default--static--header': {
                  fn: 'transparent',
                  connector: {
                    internal: 'thead',
                  },
                },
                'default--static-body': {
                  fn: 'var(--v-gray_100-base)',
                  connector: {
                    internal: 'tbody',
                  },
                },
                'default--static-body--editing-active': {
                  fn: '#fff',
                  connector: {
                    internal: 'tbody',
                  },
                },
              },
            },
          },
          {
            selector: ['row-text'],
            properties: {
              'max-width': {
                default: {
                  fn: '450px',
                },
              },
              'min-width': {
                default: {
                  fn: '150px',
                },
              },
              'background-color': {
                'default--interactive': {},
                'default--static-body': {
                  fn: 'var(--v-gray_100-base)',
                  connector: {
                    internal: 'tbody',
                  },
                },
                'default--static--editable--editing-active-body': {
                  fn: '#fff',
                  connector: {
                    internal: 'tbody',
                  },
                },
              },
            },
          },
          {
            selector: ['remove-icon'],
            properties: {
              width: {
                default: {
                  fn: '15px',
                },
              },
              height: {
                default: {
                  fn: '15px',
                },
              },
              stroke: {
                'default--interactive': {
                  fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                },
              },
            },
          },
          {
            selector: ['remove-row-button'],
            properties: {
              'min-width': {
                default: {
                  fn: '27px',
                },
              },
            },
          },
          {
            selector: [
              '.f-checkbox.f-checkbox--value-true .f-checkbox__input-control',
            ],
            external: true,
            properties: {
              'background-color': {
                'generic-option-active': {
                  fn: FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply(),
                },
              },
            },
          },
          {
            selector: ['.f-checkbox.f-checkbox--value-true .f-checkbox__input-control .f-checkbox__icon'],
            external: true,
            properties: {
              color: {
                'generic-option-active': {
                  fn: FSP.PRIMARY_VARIABLES.QUESTION_COLOR.apply(),
                }
              }
            }
          },
          {
            selector: [
              '.f-checkbox .f-checkbox__control .f-checkbox__input-control',
            ],
            external: true,
            properties: {
              'box-shadow': {
                'default--interactive': {
                  fn: `0px 0px 0px 1.5px ${FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply({opacity: 0.7})} inset`
                },
                //default: {
                 // fn: `0px 0px 0px 1.5px' + rgba(0, 0, 0, 0.1) inset`,
                //},
              },
            },
          },
          {
            selector: ['list-section'],
            properties: {
              'font-size': {
                default: {
                  fn: '16px',
                },
              },
              'font-weight': {
                default: {
                  fn: 400,
                },
              },
              color: {
                'default--static': {
                  fn: '#000',
                },
                'default--interactive': {
                  fn: FSP.PRIMARY_VARIABLES.ANSWER_COLOR.apply(),
                },
              },
            },
          },
          {
            selector: ['list-section-header'],
            properties: {
              'font-weight': {
                default: {
                  fn: '500',
                },
              },
            },
          },
          {
            selector: ['list-section-row-item'],
            properties: {
              'box-shadow': {
                'default--static': {
                  fn: `0px 0px 0px 1.5px var(--v-gray_300-base) inset`,
                },
                'default--interactive': {
                  fn: `0 0 0 2px ${FSP.ALL_PRIMARY_VARIABLES_OBJECT.BUTTON_BACKGROUND_COLOR.apply()}`,
                },
              },
              'border-radius': {
                default: {
                  fn: FSP.PRIMARY_VARIABLES.BORDER_RADIUS.apply({
                    px: true,
                    max: 12,
                  }),
                },
              },
              'background-color': {
                'default--static': {
                  fn: '#fff',
                },
                'default--interactive': {
                  fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BACKGROUND_COLOR.apply({
                    opacity: 0.3,
                  }),
                },
              },
            },
          },
          {
            selector: [
              'list-section-row-item:not(.matrix-field-view__list-section-row-item--selected):hover',
            ],
            properties: {
              'background-color': {
                'hovered--interactive--submittable': {
                  fn: FSP.ALL_PRIMARY_VARIABLES_OBJECT.BUTTON_BACKGROUND_COLOR.apply({
                    opacity: 0.2,
                  }),
                },
              },
            },
          },
          {
            selector: ['list-section-row-item--selected'],
            properties: {

              'box-shadow': {
                'selected--submittable': {
                  fn: `0px 0px 0px 2px ${FSP.PRIMARY_VARIABLES.BUTTON_BACKGROUND_COLOR.apply()}`,
                },
              },
            },
          },
        ],
      }),

      ANALYTICS: new Analytics({
        FORMAT_TO_CSV_EXPORT: (submissionObj, sourceObj) => {
          if (submissionObj.value.flat().length) {
            return submissionObj.value
              .map((answers, index) => {
                return `${sourceObj.content.rows[index].text} - ${
                  answers.length ? answers.join(', ') : 'no option selected'
                }`
              })
              .join('\n')
          }
          return null
        },
      }),

      FIELD_OBJ_EXTENSION: () => ({
        content: {
          rows: [EMPTY_ROW_TEMPLATE('Row 1'), EMPTY_ROW_TEMPLATE('Row 2')],
          columns: [
            EMPTY_COLUMN_TEMPLATE('Column 1'),
            EMPTY_COLUMN_TEMPLATE('Column 2'),
          ],
        },
      }),

      SUBMISSION_TEMPLATE: sourceObj => ({
        value: sourceObj.content.rows.map(() => []),
      }),

      IS_SUBMISSION_EMPTY: ({ value }) =>
        Object.values(value).every(v => !v?.length),

      SUBMIT_VALIDATION: new FieldSubmitValidation({
        RULES: fieldObj => ({
          value: Object.fromEntries(
            Array(fieldObj.content.rows.length)
              .fill(null)
              .map((_, index) => [
                index,
                FieldSubmitValidation.REQUIRED(fieldObj),
              ])
          ),
        }),
      }),

      BASE_PROPERTIES: new FieldTypeBasePropertiesConstWithRequired([
        new CustomFieldPropertyConst({
          key: 'multiple_selection',
          name: 'Multiple selection',
        }),
      ]),

      UTIL: {
        EMPTY_ROW_TEMPLATE,
        EMPTY_COLUMN_TEMPLATE,
      },
    })
  }
}

export default new MatrixFieldTypeConst()

