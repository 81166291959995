<template>
    <div class="editable-field-properties">
        <div class="editable-field-properties__header">
            <div class="d-flex align-center">
                <Edit2Icon class="mr-2" stroke-width="1.8" size="18"/>
                Edit
            </div>
            <v-btn
                    @click="closeProperties"
                    color="gray_400"
                    text icon small
            >
                <XIcon stroke-width="2.2"/>
            </v-btn>
        </div>
        <div class="editable-field-properties__container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import {XIcon, Edit2Icon} from 'vue-feather-icons'

    export default {
        name: "EditableFieldPropertiesWindowTemplate",
        components: {
            XIcon, Edit2Icon
        },
        methods: {
            closeProperties() {
                this.$emit('close-properties')
            }
        }
    }
</script>

<style scoped>

</style>